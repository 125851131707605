import { StoreTypes } from '../../src/util/types';

export const changeArea = (state = { changeArea: [], contentChangeArea: [] }, action) => {
    switch (action.type) {
        case StoreTypes.SAVE_CHANGE_AREA:
            return { ...state, changeArea: action.payload }
        case StoreTypes.SAVE_CONTENT_CHANGE_AREA:
            return { ...state, contentChangeArea: action.payload }
        default:
            return state;
    }
}
