export class PollBreadcrumbKeys {
    static surveys = "surveys";
    static survey = "survey";
    static user = "user";
    static reviewhistory = "reviewhistory";
    static createdhistory = "createdhistory";
    static view = "view";
    static contribute = "contribute";
    static review = "review";
    static sharedSurvey = "sharedSurvey"; // Creator shared response view
};

export const PollBreadcrumbHelper = {
    /**
     * Whether the given path is any path from review history page.
     * @param pathname 
     */
    isReviewHistoryPath(pathname: string) {
        return pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/reviewhistory\/complete/)
            || pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/reviewhistory\/contribute/);
    },
    isCreatedHistoryReviewPath(pathname) {
        return pathname.match(/createdhistory\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/review/);
    },
    isCreatedHistoryDetailsPath(pathname) {
        return pathname.match(/createdhistory\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/) && !PollBreadcrumbHelper.isCreatedHistoryReviewPath(pathname);
    },
    isReviewHistoryViewPath(pathname: string) {
        return pathname.match(/reviewhistory\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/view/);
    },
    isLandingPageViewPath(pathname: string) {
        return pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/view/);
    },
    /**
     * Whether the given path is any action path like complete or contribute from landing page.
     * @param pathname 
     */
    isLandingPageActionPath(pathname) {
        return pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/complete/)
            || pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/contribute/);
    },
    /**
     * Whether the given path is review path (for reviewer) from landing page
     */
    isLandingPageReviewPath(pathname) {
        return pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/review/);
    },
    isCreatorResponseViewPath(pathname) {
        return pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/responses/);
    },
    includeKeys(keys: string[], includeKeys: PollBreadcrumbKeys[]) {
        const newKeys = includeKeys.map(key => PollBreadcrumbKeys[key.toString()]).filter(key => !keys.includes(key));
        return keys.concat(newKeys);
    }
}