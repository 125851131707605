import { GLServiceBase, customErrMsgs, HttpMethod, ServerExceptionCode, getServiceMethodKey, customError } from 'gl-commonui'
import { injectable } from '@app/util/di'
import { IBasicService } from './interface';

@injectable()
export class BasicService extends GLServiceBase<any, any> implements IBasicService {
    serviceRoute = { prefix: 'admin/v1/basic' }
    getUserRoleResources(params) {
        return this.get('userroleresources', params);
    }
}