import { GLGlobal } from 'gl-commonui';
import { UserService } from '../../service/users';
import { InvitationService } from '../../service/school/invitation';

interface Services {
    invitation: InvitationService
    user: UserService
}

export interface InvitingState {
    successText?: string
}

export default {
    namespace: 'inviting',
    state: {
        successText: ""
    },
    reducers: {
        bindUserById(state, action) {
            return { ...state, successText: action.payload };
        },
    },
    effects: {
        *getInfoByCode({ payload: { invitationCode, callback } }, { call, put }, { invitation }: Services) {
            const info = yield call(invitation.getInfoByCode, { invitationCode });
            callback(info && info);
        },
        *getInfoThenBind({ payload: { invitationCode, callback } }, { call, put }, { invitation }: Services) {
            try {
                const info = yield call(invitation.getInfoByCode, { invitationCode });
                yield put(bindById( info , callback ));
            } catch(resp) {
                yield put(bindUserById("fail"));
            }
        },
        *consumeCode({ payload: state }, { call, put }, { invitation }: Services) {
            yield call(invitation.consumeCode, state);
        },
        *registerUser({ payload: { params, callback } }, { call, put }, { user }: Services) {
            try {
                const userId = yield call(user.newUser, params);
                callback(null, userId);
            } catch(resp) {
                callback(true, null);
            }
        },
        *redeemCode({ payload: { email, password, invitationCodeType, invitationCodeId, invitationChannel, callback } }, { call, put }, { user }: Services) {
            try {
                yield call(user.redeemCode, { email, password, invitationCodeType, invitationCodeId, invitationChannel});
                callback(true);
            } catch (resp) {
                callback(false);
            }
        },
        *bindById({ payload: { info, callback } }, { call, put }, { user }: Services) {
            try {
                let logininfo = GLGlobal.loginInfo();
                if (logininfo && logininfo.profile) {
                    yield call(user.bindById, {id: logininfo.profile.sub, invitationCodeType: info.invitationType, invitationCodeId: info.id});
                    yield call(GLGlobal.refreshLoginInfo);
                    yield put(bindUserById("success"));
                    info.email = logininfo.profile.email;
                    callback(info);
                } else {
                    yield put(bindUserById("fail"));
                }
            } catch (resp) {
                yield put(bindUserById("fail"));
            }
        }
    },
    services: {
        invitation: InvitationService,
        user: UserService
    }
}

export function getInfoByCode(state, callback) {
    return { type: 'inviting/getInfoByCode', payload: { ...state, callback } }
}

export function getInfoThenBind(state, callback) {
    return { type: 'inviting/getInfoThenBind', payload: { ...state, callback } }
}

export function consumeCode(state) {
    return { type: 'inviting/consumeCode', payload: state}
}

export function registerUser(state, callback) {
    return { type: 'inviting/registerUser', payload: { ...state, callback } }
}

export function bindById(state, callback) {
    return { type: 'inviting/bindById', payload: { ...state, callback } }
}

export function redeemCode(state, callback) {
    return { type: 'inviting/redeemCode', payload: { ...state, callback } }
}

export function bindUserById(state) {
    return { type: 'inviting/bindUserById', payload: state }
}