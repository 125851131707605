import React, { useState, useRef } from "react";
import { Action, SecondaryTitle, WijmoGrid } from "@app/components";
import { SchoolLocale } from "@app/locales/localeid";
import { GLGlobal, GLUtil, MessageHelper, NotificationType, RoleName, withRouter, PathConfig as CommonPath, StateType, connect } from "gl-commonui";
import { RouteComponentProps } from "react-router-dom";
import * as wjcGrid from "wijmo/wijmo.grid";
import { useEffect } from "react";
import { fmtMsg, isOnlySpecificRole, TYPES } from '@app/util';
import { useService } from "@app/hooks";
import { Constants, ISchoolClassService, SaveStudentCodeRequestModel, StudentCodeValidateStatus } from "@app/service/class";
import { useExtendedState } from "@app/hooks/useExtendedState";
import { CellRangeEventArgs } from "wijmo/wijmo.grid";
import { CodeValidateStatus, PasswordValidateStatus, StudentCodeBinding, StudentCodeUIModel, SaveStudentErrorCode, isPassordValid } from "./utils";
import { Button, Col, message, Row, notification } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { get } from "@app/states/school/class";
import './manage-student-login.less';

const { Grid, Column } = WijmoGrid;

interface ManageStudentLoginProps extends RouteComponentProps<{ regionId: string; schoolId: string; campusId: string; classId: string; }> { 
    regionEnableCodeLogin: boolean;
    disabled: boolean;
    get: (d) => void;
}

export const ManageStudentLogin = withRouter(
    connect(
        ({ school : { current: { regionEnableCodeLogin }}, schoolClass: { model : { disabled } } }: StateType) => ({ regionEnableCodeLogin, disabled }),
        { get }
    )(
    (props: ManageStudentLoginProps) => {
    const classService = useService<ISchoolClassService>(TYPES.ISchoolClassService);
    const [studentData, setStudentData, getStudentLatestData] = useExtendedState<StudentCodeUIModel[]>([]);
    const [schoolCode, setSchoolCode, getSchoolCode] = useExtendedState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [genrateCodeDisabled, setGenerateCodeDisabled] = useState(false);
    const [genratePasswordDisabled, setGeneratePasswordDisabled] = useState(false);
    const { schoolId, classId } = props.match.params;
    const wijmoGridRef = useRef(null);

    
    useEffect(() => {
        const {
            get,
            match: { params },
        } = props;
        get({ schoolId: params.schoolId, id: params.classId });
    }, []);

    useEffect(() => {
        const { regionEnableCodeLogin, disabled } = props;
        // regionEnableCodeLogin & disabled are undefined on initial render therefore explicitly applied check for it.
        if ((disabled != undefined && disabled) || (regionEnableCodeLogin != undefined && !regionEnableCodeLogin)) {
            props.history.push({ pathname: CommonPath.AccessDenied });
        }
    });
    
    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = () => {
        setLoading(true);
        classService.getStudentCodeData({ schoolId, schoolClassId: classId })
            .then(response => {
                setLoading(false);
                if (!response.schoolCode) {
                    MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.ManageStudentSchoolCodeError }));

                }
                setSchoolCode(response.schoolCode);
                const uiData = response.data.map(item => ({
                    ...item,
                    studentCode: item.studentCode ? item.studentCode.toString() : null,
                    codeStatus: item.studentCode ? null : CodeValidateStatus.NaN,
                    passwordStatus: item.password ? null : PasswordValidateStatus.Invalid
                }));
                setStudentData(uiData);
                setIsValid(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const updateData = async (student: StudentCodeUIModel) => {
        const schoolCodeVal = await getSchoolCode();
        if (student.studentCode) {
            student.userName = `${schoolCodeVal}${student.studentCode}`;
        } else {
            student.userName = null;
        }
        validateGrid();
    }

    const cellEditEnded = (flex: wjcGrid.FlexGrid, e: CellRangeEventArgs, student: StudentCodeUIModel) => {
        const students = flex.collectionView.sourceCollection as StudentCodeUIModel[];

        // StudentCode Column,
        if (e.col === 3) {
            let checkServiceDuplicate = true;
            const studentCode = +student.studentCode;

            if (isNaN(studentCode) || !studentCode) {
                student.codeStatus = CodeValidateStatus.NaN;
                checkServiceDuplicate = false;
            }

            // If code duplicated for any other student on grid.
            if (students.filter(s => s.studentId !== student.studentId).some(s => s.studentCode === student.studentCode)) {
                student.codeStatus = CodeValidateStatus.Duplicate;
                checkServiceDuplicate = false;
            }
            if (studentCode < Constants.MIN_STUDENT_CODE || studentCode > Constants.MAX_STUDENT_CODE) {
                student.codeStatus = CodeValidateStatus.InvalidLength;
                checkServiceDuplicate = false;
            }

            if (checkServiceDuplicate) {
                const { studentId } = student;

                classService.validateStudentCodes({ schoolId }, [{ studentId, studentCode }])
                    .then(validationResults => {
                        if (validationResults && validationResults.length) {
                            const validationResult = validationResults[0];
                            switch (validationResult.validationStatus) {
                                case StudentCodeValidateStatus.Valid:
                                    student.codeStatus = CodeValidateStatus.Valid;
                                    break;
                                case StudentCodeValidateStatus.InvalidLength:
                                    student.codeStatus = CodeValidateStatus.InvalidLength;
                                    break;
                                case StudentCodeValidateStatus.Duplicate:
                                    if (validationResult.duplicatedStudentId) {
                                        const duplicatedStudent = students.find(s => s.studentId === validationResult.duplicatedStudentId);

                                        // This basically means duplicated student exists in the same grid and that student's code has been changed locally,
                                        // So the current code is no longer duplicate.
                                        if (duplicatedStudent && duplicatedStudent.studentCode != student.studentCode) {
                                            student.codeStatus = CodeValidateStatus.Valid;

                                            if (duplicatedStudent.codeStatus !== CodeValidateStatus.Valid) {
                                                duplicatedStudent.codeStatus = CodeValidateStatus.Valid;
                                                setStudentData([...students]);
                                            }
                                        } else {
                                            student.codeStatus = CodeValidateStatus.Duplicate;
                                        }
                                    } else {
                                        student.codeStatus = CodeValidateStatus.Duplicate;
                                    }
                                    break;
                                default:
                                    break;
                            }

                            flex.invalidate();
                            validateGrid();
                        }
                    });
            }
        }
        // Password column
        if (e.col === 4) {
            const isValid = isPassordValid(student.password);
            if (!isValid) {
                student.passwordStatus = PasswordValidateStatus.Invalid;
            } else {
                student.passwordStatus = PasswordValidateStatus.Valid;
            }
        }

        updateData(student);
        setStudentData([...students]);
        validateGrid();
    };

    const validateGrid = () => {
        if (!isGridRefValid()) {
            return;
        }

        const students = wijmoGridRef.current.collectionView.sourceCollection as StudentCodeUIModel[];
        const studentWithInvalidCode = students.some(st => st.codeStatus === CodeValidateStatus.InvalidLength || st.codeStatus == CodeValidateStatus.NaN || st.codeStatus == CodeValidateStatus.Duplicate);
        const studentWithInvalidPassword = students.some(st => st.passwordStatus == PasswordValidateStatus.Invalid);

        if (!studentWithInvalidCode && !studentWithInvalidPassword) {
            setIsValid(true);
        }
    }
    const generateCode = () => {
        if (!schoolCode) {
            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.ManageStudentSchoolCodeError }));
            return;
        }
        if (!isGridRefValid()) {
            return;
        }

        const students = wijmoGridRef.current.collectionView.sourceCollection as StudentCodeUIModel[];

        if (!students || !students.length) {
            return;
        }

        const withoutCodeStudents = students.filter(s => !s.studentCode);
        const excludeCodes = students.filter(s => s.studentCode && !isNaN(+s.studentCode)).map(s => +s.studentCode);
        const count = withoutCodeStudents.length;

        if (count > 0) {
            classService.generateStudentsCode({ schoolId, count }, { excludeCodes })
                .then(data => {
                    if (!data.length || (data.length < count && data.some(code => code >= Constants.MAX_STUDENT_CODE))) {
                        MessageHelper.Message(NotificationType.Warning, fmtMsg(SchoolLocale.ManageStudentGenerateCodeInfo))
                        return;
                    }
                    withoutCodeStudents.forEach((s, index) => {
                        s.studentCode = data[index] + "";
                        s.codeStatus = CodeValidateStatus.Valid;
                        s.userName = `${schoolCode}${s.studentCode}`
                    });

                    setStudentData([...students]);
                    wijmoGridRef.current.refresh();
                    validateGrid();
                });
        }
    }
    const generatePassword = () => {
        if (!schoolCode) {
            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.ManageStudentSchoolCodeError }));
            return;
        }
        if (!isGridRefValid()) {
            return;
        }

        const students = wijmoGridRef.current.collectionView.sourceCollection as StudentCodeUIModel[];

        if (!students || !students.length) {
            return;
        }

        students.filter(s => !s.password).forEach(st => {
            st.password = st.englishName.trim().substring(0, 1).toLocaleUpperCase() + 'a@123456';
            st.passwordStatus = PasswordValidateStatus.Valid;
        });

        setStudentData([...students]);
        wijmoGridRef.current.refresh();
        validateGrid();
    }

    const getError = (item: StudentCodeUIModel, property: string): string => {
        if ((property !== StudentCodeBinding.StudentCode && property !== StudentCodeBinding.Password)
            || (item.codeStatus === CodeValidateStatus.Valid && item.passwordStatus === PasswordValidateStatus.Valid)) {
            return null;
        }

        if (property === StudentCodeBinding.StudentCode) {
            switch (item.codeStatus) {
                case CodeValidateStatus.NaN:
                case CodeValidateStatus.InvalidLength:
                    return fmtMsg(SchoolLocale.ManageStudentLoginCodeLengthInvalid);
                case CodeValidateStatus.Duplicate:
                    return fmtMsg(SchoolLocale.ManageStudentLoginCodeDuplicate);
                default:
                    break;
            }
        }
        if (property === StudentCodeBinding.Password) {
            switch (item.passwordStatus) {
                case PasswordValidateStatus.Invalid:
                    return fmtMsg(SchoolLocale.ManageStudentLoginInvalidPassword);
                default:
                    break;
            }
        }
        return null;
    };

    const gridLoaded = (s, e) => {
        if (!isGridRefValid()) {
            return;
        }
        const data = wijmoGridRef.current.collectionView.sourceCollection as StudentCodeUIModel[];
        const isGenerateCodeDisabled = data.every(item => item.studentCode);
        const isGeneratePasswordDisabled = data.every(item => item.password);

        setGenerateCodeDisabled(isGenerateCodeDisabled);
        setGeneratePasswordDisabled(isGeneratePasswordDisabled);
    }

    const initGrid = (flexGrid: wjcGrid.FlexGrid) => {
        wijmoGridRef.current = flexGrid;
    }

    const cancel = () => {
        const { regionId, schoolId, campusId, classId } = props.match.params;
        const studentPath = GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId })
        props.history.push(studentPath);
    }

    const submit = () => {
        if (!isValid) {
            return;
        }

        const data = wijmoGridRef.current.collectionView.sourceCollection as StudentCodeUIModel[];

        const requestData = data.map(item => ({
            studentId: item.studentId,
            studentCode: +item.studentCode,
            userName: item.userName,
            password: item.password
        }) as SaveStudentCodeRequestModel);

        setLoading(true);
        classService.saveStudentCodes({ schoolId }, requestData)
            .then(() => {
                setLoading(false);
                message.success(fmtMsg(SchoolLocale.ManageStudentLoginSuccessMsg));
                //to disable the submit button once saved
                setIsValid(false);
            })
            .catch(reason => {
                setLoading(false);
                const { body } = reason;

                if (body && body.error_code) {
                    switch (body.error_code) {
                        case SaveStudentErrorCode.StudentCodeAlreadyExists:
                            notification.error({ message: fmtMsg(SchoolLocale.ManageStudentErrorCodeExists) });
                            break;
                        case SaveStudentErrorCode.StudentCodeInvalidUserName:
                            notification.error({ message: fmtMsg(SchoolLocale.ManageStudentErrorInvalidUserName) });
                            break;
                        case SaveStudentErrorCode.StudentCodeUserNameAlreadyExists:
                            notification.error({ message: fmtMsg(SchoolLocale.ManageStudentErrorUserNameExists) });
                            break;
                        default:
                            message.error(fmtMsg(SchoolLocale.ManageStudentErrorGeneric));
                            break;
                    }
                } else {
                    message.error(fmtMsg(SchoolLocale.ManageStudentErrorGeneric));
                }
            });
    }

    const beginningEdit = (s, e) => {
        setIsValid(false);
    }

    const isGridRefValid = () => wijmoGridRef && wijmoGridRef.current && wijmoGridRef.current.collectionView && wijmoGridRef.current.collectionView.sourceCollection;

    return (
        <div className="gs-container gs-container--yspace gs-container--fw msl__container">
            <SecondaryTitle title={SchoolLocale.ManageStudentLoginPageHeader}></SecondaryTitle>
            <Grid
                initialized={initGrid}
                itemsSource={studentData}
                bordered={false}
                cellEditEnded={cellEditEnded}
                getError={getError}
                loadedRows={gridLoaded}
                pagination={false}
                beginningEdit={beginningEdit}
                allowExport={!!studentData.length}
                exportOptions={{
                    buttonLocaleId: SchoolLocale.ManageStudentLoginExport,
                    filename: fmtMsg(SchoolLocale.ManageStudentLoginExportedFileName),
                    excludeColumns: [StudentCodeBinding.StudentId]
                }}
                validateEdits={false}
                actions={[
                    <Action disabled={genrateCodeDisabled} textLocaleId={SchoolLocale.ManageStudentLoginGenerateCode} onClick={generateCode}></Action>,
                    <Action disabled={genratePasswordDisabled} textLocaleId={SchoolLocale.ManageStudentLoginGeneratePassword} onClick={generatePassword}></Action>
                ]}
            >
                <Column header="StudentId" visible={false} binding={StudentCodeBinding.StudentId}></Column>
                <Column header={fmtMsg(SchoolLocale.ManageStudentLoginColumnEnglishName)} binding={StudentCodeBinding.EnglishName} isReadOnly={true} />
                <Column header={fmtMsg(SchoolLocale.ManageStudentLoginColumnNativeName)} binding={StudentCodeBinding.Name} isReadOnly={true} />
                <Column header={fmtMsg(SchoolLocale.ManageStudentLoginColumnCode)} binding={StudentCodeBinding.StudentCode} isReadOnly={!schoolCode} isRequired={false} />
                <Column header={fmtMsg(SchoolLocale.ManageStudentLoginColumnPassword)} binding={StudentCodeBinding.Password} isReadOnly={!schoolCode} isRequired={false} />
                <Column header={fmtMsg(SchoolLocale.ManageStudentLoginColumnUsername)} binding={StudentCodeBinding.UserName} isReadOnly={true} />
            </Grid>
            <Row type="flex" align="middle" justify="end" className="msl__btns">
                <Col className="msl__btns__btn">
                    <Button onClick={cancel}>{fmtMsg(SchoolLocale.ManageStudentLoginCancel)}</Button>
                </Col>
                {
                    !isOnlySpecificRole(RoleName.globalHead) &&
                    <Col className="msl__btns__btn">
                        <Button disabled={loading || !isValid} type="primary" onClick={submit}>{fmtMsg(SchoolLocale.ManageStudentLoginSave)}</Button>
                    </Col>
                }
            </Row>
        </div>
    )
}));
