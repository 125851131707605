import React from "react";
import {Icon, Rate} from "antd-min";
import { RateProps } from "antd/lib/rate";
import { mergeClasses } from "@app/util";
import "./decimal-rate.less";

export const DecimalRate = (props) => {
    const { value, type } = props;
    const steralizedValue = (value ? value.toString() : 0).toString();
    const decimalValue = value - parseInt(steralizedValue);

    if (decimalValue === 0 || decimalValue === 0.5) {
        if (type === 1) {
            return <Rate className="decimal-rate" {...props}/>
        } else {
            return <Rate className="decimal-rate" character={<Icon type="like" />} {...props}/>
        }
    } else {
        return <RateListContainer {...props} />
    }
}

const RateListContainer = (props) => {
    const { value, type, count } = props;
    const steralizedValue = (value ? value.toString() : 0).toString();
    const decimalValue = value - parseInt(steralizedValue);

    return (
        <div>
            <ul className="ant-rate ant-rate-disabled decimal-rate" tabIndex={0} role="radiogroup" >
                {[...Array(parseInt(steralizedValue)).keys()]
                    .map(key => type === 1 ? <StarRate key={key} /> : <LikeRate key={key} />)
                    .concat(type === 1 ? <StarRate partial percentage={decimalValue * 100} count={count} /> : <LikeRate partial percentage={decimalValue * 100} count={count} />)
                    .concat([...Array(count - parseInt(steralizedValue) - 1).keys()].map(key => type === 1 ? <StarRate key={key} zero /> : <LikeRate key={key} zero />))}
            </ul>
        </div>
    );
}

interface StarRateProps {
    partial?: boolean;
    zero?: boolean;
    percentage?: number;
    count?: number
}

const StarRate = (props: StarRateProps) => {
    return (
        <li className={mergeClasses("ant-rate-star", props.zero ? "ant-rate-star-zero" : "ant-rate-star-half")}>
            <div
                role="radio"
                aria-checked="true"
                aria-posinset={3}
                aria-setsize={props.count}
                tabIndex={0}
            >
                <div className="ant-rate-star-first" style={{ width: props.percentage ? `${props.percentage}%` : "100%" }}>
                    <i aria-label="icon: star" className="anticon anticon-star">
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            className=""
                            data-icon="star"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
                        </svg>
                    </i>
                </div>
                <div className="ant-rate-star-second">
                    <i aria-label="icon: star" className="anticon anticon-star">
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            className=""
                            data-icon="star"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z" />
                        </svg>
                    </i>
                </div>
            </div>
        </li>
    );
}

const LikeRate = (props: StarRateProps) => {
    return (
        <li className={mergeClasses("ant-rate-star", props.zero ? "ant-rate-star-zero" : "ant-rate-star-half")}>
            <div role="radio" aria-checked="false" aria-posinset={3} aria-setsize={props.count} tabIndex={0}>
                <div className="ant-rate-star-first" style={{ width: props.percentage ? `${props.percentage}%` : "100%" }}>
                    <i aria-label="icon: like" className="anticon anticon-like">
                        <svg viewBox="64 64 896 896" className="" data-icon="like" width="1em" height="1em"
                             fill="currentColor" aria-hidden="true" focusable="false">
                            <path
                                d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path>
                        </svg>
                    </i>
                </div>
                <div className="ant-rate-star-second">
                    <i aria-label="icon: like" className="anticon anticon-like">
                        <svg viewBox="64 64 896 896" className="" data-icon="like" width="1em" height="1em"
                             fill="currentColor" aria-hidden="true" focusable="false">
                            <path
                                d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z"></path>
                        </svg>
                    </i>
                </div>
            </div>
        </li>
    )
}

