import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode } from 'gl-commonui'
import { injectable } from 'inversify';
import { ITrainingService } from './interface';
import { SchoolLocale } from '@app/locales/localeid';

@injectable()
export class TrainingService extends GLServiceBase<any, any> implements ITrainingService {
    serviceRoute = { prefix: 'training/v1/teachercourse' }
    getCourseQuestionnaire(teacherId, questionnaireId, courseVersionId, seriesId) {
        return this.get('teachers/{teacherId}/questionnaire/{questionnaireId}/info', {courseVersionId, seriesId}, {teacherId, questionnaireId});
    }
    getCourseTrainingInfo(teacherId) {
        return this.get('~training/v1/course/markup/users/{userId}', null, {userId: teacherId});
    }
    saveCourseTrainingInfo(trainingInfo) {
        return this.update('~training/v1/course/markup', trainingInfo);
    }
}