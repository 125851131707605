import * as React from 'react';
import { ExecuteState } from '@app/util/enum';
import { FnReturnPromise } from '@app/util/types';


export interface DataType<T> {
    loading?: boolean;
    data?: Array<T> | T;
    state?: ExecuteState;
    error?: string;
}

export type AsyncFnReturn<T> = [DataType<T>, FnReturnPromise<T>, (value: React.SetStateAction<DataType<T>>) => void]

export function useAsyncFn<T>(
    asyncFunction: FnReturnPromise<T>, 
    deps: any[],
): AsyncFnReturn<T> {
    const [data, setData] = React.useState<DataType<T>>({ loading: false, state: ExecuteState.Idle });

    const callback = React.useCallback((...args) => {
        setData({ loading: true, state: ExecuteState.Pending });

        return asyncFunction(...args)
            .then(response => {
                setData({ loading: false, state: ExecuteState.Success, data: response });
            })
            .catch(error => {
                setData({ loading: false, state: ExecuteState.Error, error: error });
            });
    }, deps);

    return [data, callback as any, setData];
}