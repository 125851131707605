import React, { useEffect, useState } from "react";
import {GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import { connect, GLRouteComponentProps, withRouter, GLGlobal } from "gl-commonui";
import { Card, Icon, Modal, Typography, Switch, Input } from "antd-min";
import { Container, Loading, TableWithPerfectScroll } from "@app/components";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SurveyLocale, DashboardLocale } from "@app/locales/localeid";
import { CreatedPollModel, CreatedPollsResponseModel, PollPageParams } from "@app/service/survey";
import { StateType } from "@app/states";
import { getCreatedPolls, getUserPollParticipation, UserPollParticipationState } from "@app/states/survey/survey";
import { CONSTS, DateHelper, fmtMsg, isExitSurvey, isPastDate } from "@app/util";
import { Column } from "@app/components/grid/grid-column";
import { CellType } from "@app/components/gl-grid";
import { WijmoGrid } from "@app/components/grid/wijmo-grid";
import "./created-polls.less";

const { Text } = Typography;

const wijmoMapper = (poll: CreatedPollModel) => {
    return {
        ...poll,
        dueDate: DateHelper.formatDateAsIs(poll.dueDate as string),
        publishedDate: DateHelper.formatDate2Local(poll.publishedDate),
        creationDate: poll.creationDate ? DateHelper.formatDate2Local(poll.creationDate) : null
    };
}

interface CreatedPollsHistoryProps extends GLRouteComponentProps {
    createdPolls: CreatedPollsResponseModel;
    shouldReloadCreatedSurveys: boolean;
    loading: boolean;
    participationLoading: boolean;
    userParticipation: UserPollParticipationState;
    getCreatedPolls: (params?: PollPageParams) => void;
    getPollParticipation: (surveyId: string, surveyInstanceId: string) => void;
}

export const CreatedPollsHistory = withRouter(
    connect(
        ({ survey: { createdPolls, shouldReloadCreatedSurveys, createdPollsLoading, participationLoading, userParticipation } }: StateType) => ({
            createdPolls,
            shouldReloadCreatedSurveys,
            loading: createdPollsLoading,
            participationLoading,
            userParticipation
        }),
        {
            getCreatedPolls,
            getPollParticipation: getUserPollParticipation,
        }
    )((props: CreatedPollsHistoryProps) => {
        const [showParticipation, setShowParticipation] = useState(false);
        const [participationPoll, setParticipationPoll] = useState({} as CreatedPollModel);
        const [showExpired, setShowExpired] = useState(false);
        const [expiredFetched, setExpiredFetched] = useState(false);
        const [filter, setFilter] = useState("");
        const [filteredSource, setFilteredSource] = useState([] as CreatedPollModel[]);

        const {
            loading,
            participationLoading,
            createdPolls,
            userParticipation,
            getCreatedPolls,
            getPollParticipation,
            history,
            match,
            shouldReloadCreatedSurveys,
        } = props;

        const showAll = match.url === SurveyPathConfig.CreatedHistory;

        useEffect(() => {
            if ((!createdPolls.data && !loading)
                || (shouldReloadCreatedSurveys && !loading)
                || (createdPolls.data
                    && (createdPolls.data.length < createdPolls.totalCount || (showExpired && !expiredFetched))
                    && !loading
                    && !shouldReloadCreatedSurveys)) {

                if (showExpired) {
                    setExpiredFetched(true);
                } else {
                    getCreatedPolls();
                }
            }

            if (createdPolls.data) {
                setFilteredSource(createdPolls.data.filter(r => r.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
            }
        }, [createdPolls, loading, shouldReloadCreatedSurveys]);

        /**
         * Every time createdPolls or userParticipation changes, we need to remove and add the listener again
         * because createdPolls and userParticipation is scoped and those values are not updated inside the
         * listener callback.
         */
        useEffect(() => {
            if (createdPolls.data && createdPolls.data.length) {
                document.addEventListener("click", onItemClick, false);
            }

            return () => {
                document.removeEventListener("click", onItemClick, false);
            };
        }, [createdPolls, userParticipation]);

        const goToUserResponse = (userId: string, name: string, responseId: string) => {
            history.push(`${SurveyPathConfig.Home}/${participationPoll.id}/${participationPoll.surveyInstanceId}/createdhistory/${userId}/review/${responseId}`);
        };

        const getPollsDataSource = (polls: CreatedPollModel[]) => {
            if (showAll) {
                if (loading) {
                    return [];
                } else {
                    return showExpired ? polls.map(wijmoMapper) : polls.filter(poll => !poll.surveyInstanceId || !isPastDate(poll.dueDate)).map(wijmoMapper);
                }
            } else {
                return polls.filter(poll => !poll.surveyInstanceId || !isPastDate(poll.dueDate)).slice(0, CONSTS.MySurveyItemDisplayCount);
            }
        };

        const onShowParticipationClick = (poll: CreatedPollModel) => {
            setShowParticipation(true);
            // get pollParticipation all times for Exit Survey, as every exit survey has different survey instances
            if (!hasDataOnClient(poll.id) || isExitSurvey(poll.id)) {
                getPollParticipation(poll.id, poll.surveyInstanceId);
            }
            setParticipationPoll(poll);
        };

        const hasDataOnClient = (pollId: string) => {
            const existed = userParticipation[pollId];
            if (!existed) {
                return false;
            }
            const poll = createdPolls.data.find(s => s.id == pollId);
            if (!poll) {
                return false;
            }
            return existed.totalCount == poll.userCount;
        }

        // const onPollNameClick = (e: any, id: string) => {
        //     e.preventDefault();
        //     props.history.push(`${SurveyPathConfig.Home}/createdhistory/${id}`);
        // };

        const onToggleShowExpired = (checked: boolean) => {
            if (checked && !expiredFetched) {
                getCreatedPolls({ includeExpired: true });
            }
            setShowExpired(checked);
        }

        const renderShowExpired = () => {
            return showAll ?
                <div className="cp-switch">
                    {fmtMsg({ id: SurveyLocale.PollShowExpired })}{" "}
                    <Switch
                        loading={loading}
                        checked={showExpired}
                        onChange={onToggleShowExpired}
                        checkedChildren={<Icon type="eye"/>}
                        unCheckedChildren={<Icon type="eye-invisible"/>}
                        />
                </div>
                : null;
        }

        const renderParticipationModal = () => {
            const modalHeight = window.innerHeight - 300;
            return (
                <Modal
                    title={`${participationPoll.name} - ${participationPoll.completedCount}/${participationPoll.userCount}`}
                    visible={showParticipation}
                    className="notified-users-modal"
                    maskClosable={false}
                    destroyOnClose
                    onOk={() => setShowParticipation(false)}
                    onCancel={() => setShowParticipation(false)}
                    footer={null}
                    width={800}
                    bodyStyle={{ padding: 0 }}
                >
                    <Loading visible={participationLoading} cover maxHeight={modalHeight} />
                    {!participationLoading && (
                        <TableWithPerfectScroll
                            className="cp-table-participation"
                            dataSource={userParticipation[participationPoll.id] ? userParticipation[participationPoll.id].data : []}
                            columns={[
                                {
                                    title: fmtMsg(GSAdminLocale.NotificationTextName),
                                    dataIndex: "name",
                                    width: "40%",
                                    render: (text, { id, name, hasCompleted, surveyResponseId }) =>
                                        hasCompleted ? (
                                            <Text underline>
                                                <a className="cp-action-participation" onClick={() => goToUserResponse(id, name, surveyResponseId)}>
                                                    {text}
                                                </a>
                                            </Text>
                                        ) : (
                                                text
                                            )
                                },
                                {
                                    title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                                    dataIndex: "email",
                                    width: "40%"
                                },
                                {
                                    title: fmtMsg(SurveyLocale.PollCompletedText),
                                    dataIndex: "hasCompleted",
                                    width: "20%",
                                    render: (text: boolean) =>
                                        text ? (
                                            <Icon className="cp-icon" type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                        ) : (
                                                <Icon className="cp-icon" type="clock-circle" />
                                            )
                                }
                            ]}
                            perfectScroll={{ yMax: modalHeight - 38 }}
                            rowKey="id"
                            pagination={false}
                        />
                    )}
                </Modal>
            );
        };

        const flexGridColumns = [
            {
                localeId: SurveyLocale.PollNameColumn,
                dataIndex: "name",
                width: "40*",
                draggable: false,
            },
            {
                localeId: SurveyLocale.PollDueDate,
                dataIndex: "dueDate",
                width: "15*",
                draggable: true,
                align:'right'
            },
            {
                localeId: SurveyLocale.PollPublishedDate,
                dataIndex: "publishedDate",
                width: "15*",
                draggable: true,
                align:'right'
            },
            {
                localeId: SurveyLocale.PollCreationDate,
                dataIndex: "creationDate",
                width: "15*",
                draggable: true,
                align:'right'
            },
            {
                localeId: SurveyLocale.PollParticipation,
                dataIndex: "participation",
                width: "15*",
                draggable: false,
                align:'right'
            }
        ];

        const onItemClick = e => {
            if (e.target && e.target.tagName.toUpperCase() == "A" && e.target.getAttribute("data-poll")) {
                const { column, data, surveyInstanceId } = JSON.parse(e.target.getAttribute("data-poll"));

                switch (column) {
                    // case "name":
                    //     onPollNameClick(e, data);
                    //     break;

                    case "participation":
                        if (createdPolls.data) {
                            if (isExitSurvey(data)) {
                                onShowParticipationClick(createdPolls.data.find(c => c.surveyInstanceId === surveyInstanceId));
                            } else {
                                onShowParticipationClick(createdPolls.data.find(c => c.id === data));
                            }
                        }
                        break;
                    default:
                        break;
                }

                e.preventDefault();
                e.stopPropagation();
            }
        };

        const renderWijmoRow = (s, e) => {
            if (e.panel.cellType == CellType.Cell && !(s.rows[e.row] instanceof GroupRow)) {
                switch (s.columns[e.col].binding) {
                    case "name":
                        e.cell.innerHTML = `<a
                                class="underline"
                                href="${SurveyPathConfig.Home}/createdhistory/${e.panel.rows[e.row].dataItem.id}">
                                    ${e.panel.rows[e.row].dataItem.name}
                            </a><sup>${e.panel.rows[e.row].dataItem.isRecurring ? "Recurring" : ""}</sup>`
                        break;
                    case "participation":
                        e.cell.innerHTML = `<a
                                class="cp-action-participation"
                                data-poll =${JSON.stringify({ column: "participation", data: e.panel.rows[e.row].dataItem.id , surveyInstanceId: e.panel.rows[e.row].dataItem.surveyInstanceId})}>
                                    ${e.panel.rows[e.row].dataItem.completedCount}/${e.panel.rows[e.row].dataItem.userCount}
                            </a>`;
                        break;

                    default:
                        break;
                }
            }
        }

        const setFilterValue = (e: any) => {
            const currentFilterValue = e.target.value.trim();
            if (currentFilterValue.length === 0) {
                onFilterChange("");
            }
            // update filter
            setFilter(e.target.value);
        };

        const onFilterChange = (value: any) => {
            if (!value) {
                setFilteredSource(createdPolls.data);
                return;
            }

            setFilteredSource(createdPolls.data.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) >= 0));
        };

        const formatData = (data) => {
            return data.map((record) => ({
                ...record,
                participation: `${record.completedCount}/${record.userCount}`
            }))
        }

        const getPollsGrid = () => {
            return (<WijmoGrid
                columnLangMap={flexGridColumns.map(({ localeId, dataIndex }) => ({ localeId, dataIndex }))}
                pageSize={0}
                loading={loading}
                selectionMode={SelectionMode.ListBox}
                headersVisibility="Column"
                itemsSource={formatData(getPollsDataSource(filteredSource))}
                formatItem={renderWijmoRow}
                grouping
                groupBarText={fmtMsg({ id: DashboardLocale.LandingTabDragColumnsText })}
                noDataLocaleId={SurveyLocale.MySurveyNoSurveyMessage}
                isReadOnly>
                {flexGridColumns.map(column => <Column
                    allowDragging={column.draggable}
                    binding={column.dataIndex}
                    header={fmtMsg({ id: column.localeId })}
                    width={column.width}
                    align={column.align}/>)}
            </WijmoGrid>);
        }

        const getCardContent = () => (<>
            {getPollsGrid()}
            {renderParticipationModal()}
        </>);

        const polls = (
            <>
                {renderShowExpired()}
                {
                    showAll && <div className="created-polls__search">
                        <Input.Search
                            defaultValue={""}
                            placeholder={GLGlobal.intl.formatMessage({
                                id: GSAdminLocale.Search
                            })}
                            value={filter}
                            onChange={e => setFilterValue(e)}
                            onSearch={value => onFilterChange(value)}
                        />
                    </div>
                }
                <Card
                    className="cp-card"
                    type="inner"
                    size="small"
                    title={`${fmtMsg({ id: SurveyLocale.PollsToView })} ${loading ? "" : `(${getPollsDataSource(filteredSource).length})`}`}
                >
                    {
                        loading ? <Loading visible cover height={292} /> : getCardContent()
                    }
                </Card>
            </>
        );

        return <Container fullWidth className="created-polls">{polls}</Container>;
    })
);
