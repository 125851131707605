import * as React from "react";
import { Icon } from "gl-commonui/lib/antd-min";
import { fmtMsg } from "@app/util";
import { mergeClasses } from "../survey/functions";
import "./result.less";


interface ResultProps {
    type: "info" | "warning";
    textId: string;
    extra?: React.ReactNode | string;
}

export const Result = (props: ResultProps) => {

    const wrap = (icon: string, className: string): React.ReactElement => {
        return <div className="result">
            <div className={mergeClasses("ant-result", className)}>
                <div className="ant-result-icon">
                    <Icon type={icon}></Icon>
                </div>
                <div className="ant-result-title">{fmtMsg({ id: props.textId })}</div>
                {props.extra && <div className="ant-result-extra">{props.extra}</div>}
            </div>
        </div>
    }

    if (props.type == "info") {
        return wrap("exclamation-circle", "ant-result-info");
    }
    return wrap("warning", "ant-result-warning");
}