import * as React from 'react'
import { Component } from 'react';
import { Modal, Select, Upload, Form, Icon, Spin, Input, Radio, Typography, Row, Col, Tooltip } from 'antd-min';
import { ModalProps } from 'antd/lib/modal/Modal';
import { GLForm, GLFormComponentProps, alignPop, FormHelper, ComparisonOperator, MessageHelper, NotificationType } from 'gl-commonui';
import { SchoolLocale } from '@app/locales/localeid';
import { lazyInject, TYPES, textareaValider, fmtMsg } from '@app/util';
import { IVisitationService, MAX_ALLOWED_UPLOAD_VIDEO_SIZE } from '@app/service/coach/visitation';
import { VideoStorageManager } from '@app/util/storage/video-storage-manager';
import { LVAResourceStatus, LVAResourceType } from '@app/util/coach/enum';
import { trimPlainText } from '@app/util/coach/fun';
import { VideoRetentionMsg } from './video-retention-msg';
import { FormattedMessage } from 'react-intl';
import { AttachmentStorageManager } from '@app/util/storage/attachment-storage-manager';

interface VideoModalProps extends ModalProps {
    folder: any
    teacherId?: string
    lvaResources: any[]
    lvaResourceFormId: string
    addVideoFallback: (file, attachmentFile, selectedData, lvaResourceType: LVAResourceType) => void
}
interface VideoModalStates {
    file?: any
    attachmentFile?: any
    visible?: boolean
    classesOptions?: JSX.Element[] | null;
    lvaResourceType: LVAResourceType;
}

@GLForm.create()
export class VideoModal extends Component<VideoModalProps & GLFormComponentProps, VideoModalStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    teacherId
    selectedClassId
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            attachmentFile: {},
            visible: true,
            lvaResourceType: LVAResourceType.Video
        }

        this.onResourceTypeChange = this.onResourceTypeChange.bind(this);
    }
    componentWillMount() {
        const { teacherId, lvaResources } = this.props;
        this.filterClasses(teacherId, lvaResources);
    }
    componentWillReceiveProps(nextProps: any, nextContext: any) {
        !this.state.visible && this.setState({ visible: true });
        const { teacherId, lvaResources } = nextProps;
        !this.selectedClassId && this.filterClasses(teacherId, lvaResources);
    };
    allowClassUploadVideo({ lvaResourceModels }) {
        const calculateSpace = (totalUsed, videoModel) => totalUsed + (videoModel.size || 0);
        const remainingSpace = MAX_ALLOWED_UPLOAD_VIDEO_SIZE - (lvaResourceModels || []).reduce(calculateSpace, 0);
        return [((lvaResourceModels || []).length < 5) && (remainingSpace > 0), remainingSpace];
    }
    filterClasses(teacherId, lvaResources) {
        this.teacherId = teacherId;
        const filteredClasses = lvaResources.filter(lvaResource => this.allowClassUploadVideo(lvaResource)[0]);
        let classesOptions = filteredClasses.map((item, index) => {
            return this.getOption(item.schoolClassResource, index);
        });
        this.setState({
            classesOptions: classesOptions
        });
    }
    getUploadVideoInfo() {
        return {
            accept: VideoStorageManager.acceptedTypes,
            beforeUpload: VideoStorageManager.beforeUpload(() => {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.CommonErrorVideoTypeNotMatch }));
            }, () => {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.CommonErrorVideoSizeExceed }, { size: "10GB" }));
            }),
            customRequest: (data) => {
                //console.log(data);
            },
            data: (file) => {
                this.setState({
                    file: file,
                });
            },
            showUploadList: false,
        };
    }
    delete() {
        this.setState({
            file: {},
        });
    }
    getUploadAttachmentInfo() {
        return {
            accept: AttachmentStorageManager.acceptedTypes,
            beforeUpload: AttachmentStorageManager.beforeUpload(() => {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.CommonErrorAttachmentFileTypeNotMatch }));
            }, () => {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.CommonErrorAttachmentFileSizeExceed }, { size: "100MB" }));
            }),
            customRequest: (data) => {
                //console.log(data);
            },
            data: (file) => {
                this.setState({
                    attachmentFile: file,
                });
            },
            showUploadList: false,
        };
    }
    deleteAttachment() {
        this.setState({
            attachmentFile: {},
        });
    }
    uploadVideosToAdminService(values, callback) {
        const { file, lvaResourceType, attachmentFile } = this.state;
        const { folder, lvaResourceFormId } = this.props;
        const lvaResource = {
            url: "",
            name: lvaResourceType === LVAResourceType.Video ? file.name : (values.link || "").substr(0, 200),
            visitationId: folder,
            schoolClassId: this.selectedClassId,
            status: lvaResourceType === LVAResourceType.Video ? LVAResourceStatus.Uploading : LVAResourceStatus.Completed,
            size: lvaResourceType === LVAResourceType.Video ? file.size : 0,
            externalLink: values.link,
            lvaResourceType
        };
        const formatLVAResource = lvaResourceFormId ? { ...lvaResource, lvaResourceFormId } : lvaResource;
        const formatNote = {
            note: trimPlainText(values.notes),
            attachmentName: attachmentFile.name,
            attachmentUrl: null
        }
        const params = {
            note: formatNote,
            visitationId: folder,
            teacherId: this.teacherId,
            lvaResource: formatLVAResource,
        };
        this.visitationservice.uploadVideos(params).then((data) => {
            callback(data);
        });
    }
    handleSubmit(e) {
        e.preventDefault();
        const { form, lvaResources, addVideoFallback } = this.props;
        const { file, attachmentFile, lvaResourceType } = this.state;
        if (!this.selectedClassId) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationSelectClassTips }));
            return;
        } else if (lvaResourceType === LVAResourceType.Video) {
            const [, remainingSpace] = this.allowClassUploadVideo(lvaResources.find(
                lvaResource => lvaResource.schoolClassResource.schoolClassId === this.selectedClassId)
            );

            if (file.size > remainingSpace) {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.CommonErrorVideoTotalSizeExceed }));
                return;
            }
        }
        if (lvaResourceType === LVAResourceType.Video && !file.name) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationUploadVideoTips }));
            return;
        }
        form.validateFields((err, values) => {
            if (!err) {
                this.setState({ visible: false, file: {}, attachmentFile: {}, lvaResourceType: LVAResourceType.Video });
                this.uploadVideosToAdminService(values, data => {
                    const hasAttachment = attachmentFile && attachmentFile.name;

                    const { lvaResourceFormId, lvaResourceId } = data;
                    const associatedInfo = values.notes || hasAttachment ? {
                        lvaResourceNote: { 
                            note: trimPlainText(values.notes),
                            attachmentName: hasAttachment || "",
                            attachmentUrl: hasAttachment ? lvaResourceId : "",
                            progress: hasAttachment ? 0 : 100
                        }
                    } : null;
                    const selectedResource = lvaResources.find(l => l.schoolClassResource.schoolClassId == this.selectedClassId);
                    const formatSelectedResource = {
                        ...selectedResource,
                        teacherId: this.teacherId,
                        lvaResourceFormId,
                        lvaResourceModel: {
                            url: "",
                            progress: lvaResourceType === LVAResourceType.Video ? 0 : 100,
                            associatedInfo,
                            name: lvaResourceType === LVAResourceType.Video ? file.name : (values.link || "").substr(0, 200),
                            id: lvaResourceId,
                            lvaResourceFormId,
                            schoolClassId: this.selectedClassId,
                            status: lvaResourceType === LVAResourceType.Video ? LVAResourceStatus.Uploading : LVAResourceStatus.Completed,
                            size: lvaResourceType === LVAResourceType.Video ? file.size : 0,
                            externalLink: values.link,
                            lvaResourceType
                        }
                    };
                    this.selectedClassId = null;
                    addVideoFallback(file, attachmentFile, formatSelectedResource, lvaResourceType);
                });
            }
        });
    }
    getOption(item, value) {
        return <Select.Option key={value} value={item.schoolClassId}>{item.schoolClassName}</Select.Option>
    }
    onChange(value) {
        this.selectedClassId = value;
    }
    onCancel(e) {
        this.selectedClassId = null;
        this.setState({ file: {}, attachmentFile: {}, visible: false, lvaResourceType: LVAResourceType.Video });
    }
    onResourceTypeChange(e) {
        const selectedResourceType: LVAResourceType = e.target.value;

        if (selectedResourceType) {
            this.setState({ lvaResourceType: selectedResourceType });
        }
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "notes").concat(this.lengthValidator(SchoolLocale.VisitationNoteText, 500));
    }
    render() {
        const { form } = this.props;
        const { renderFormItem } = FormHelper;
        const { file: { name }, attachmentFile, visible, lvaResourceType } = this.state;
        const uploadVideoInfo = this.getUploadVideoInfo();
        const uploadAttachmentInfo = this.getUploadAttachmentInfo();
        const options = {
            formItemProps: { label: null },
        }
        return (
            <Modal
                width={600}
                className="upload-video"
                wrapClassName="vertical-center-modal"
                okText="OK"
                onOk={(e) => this.handleSubmit(e)}
                onCancel={(e) => this.onCancel(e)}
                cancelText="Cancel"
                destroyOnClose={true}
                visible={visible}
                {...this.props}
            >
                <GLForm form={form} labelCol={{ sm: 5, md: 4 }} wrapperCol={{ sm: 19, md: 20 }}>
                    <Form.Item label={fmtMsg({ id: SchoolLocale.ClassesColumnClass })}>
                        <Select
                            {...alignPop()}
                            size="large"
                            placeholder="select class"
                            onChange={(d) => this.onChange(d)}
                        >
                            {this.state.classesOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item label=" ">
                        <Radio.Group defaultValue={LVAResourceType.Video} onChange={this.onResourceTypeChange}>
                            <Radio value={LVAResourceType.Video}>{fmtMsg({ id: SchoolLocale.VisitationLVAResourceTypeVideo })}</Radio>
                            <Radio value={LVAResourceType.Link}>{fmtMsg({ id: SchoolLocale.VisitationLVAResourceTypeLink })}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        lvaResourceType === LVAResourceType.Link
                            ? renderFormItem({ ...form, options: { formItemProps: { label: SchoolLocale.VisitationLVALinkText } } }, SchoolLocale.VisitationLVALinkText, "link", <Input></Input>, null, true, [])
                            : <Form.Item label={
                                <span className="video-upload-tooltip">
                                    <FormattedMessage id={SchoolLocale.VisitationVideoText} />
                                    <Tooltip
                                        title={<FormattedMessage id={SchoolLocale.VisitationVideoDescription} />}
                                        placement="bottomLeft"
                                        key="video-upload"
                                    >
                                        <Icon type="info-circle" className="contact-info-icon" />
                                    </Tooltip >
                                </span>
                                }>
                                {!name && (
                                    <Upload.Dragger {...uploadVideoInfo}>
                                        <div className="school-avatar-text">
                                            <>{fmtMsg({ id: SchoolLocale.VisitationClickOrDragTips })}</>
                                        </div>
                                    </Upload.Dragger>
                                )}
                                {name && (
                                    <Row type="flex" gutter={8}>
                                        <Col><a>{name}</a></Col>
                                        <Col>
                                            <Icon type="close-circle" title="delete" onClick={() => this.delete()} />
                                        </Col>
                                    </Row>
                                )}
                            </Form.Item>
                    }
                    {
                        renderFormItem(
                            { ...form, options: { formItemProps: { label: fmtMsg({ id: SchoolLocale.VisitationVideoNote }) } } },
                            SchoolLocale.VisitationNoteText,
                            "notes",
                                <Input.TextArea rows={4} />
                                ,
                            null,
                            false,
                            [],
                        )
                    }
                    <Form.Item label={fmtMsg({ id: SchoolLocale.VisitationLVAAttachmentText })}>
                        {!attachmentFile.name && (
                            <Upload.Dragger {...uploadAttachmentInfo}>
                                <div className="school-avatar-text">
                                    <>{fmtMsg({ id: SchoolLocale.VisitationClickOrDragAttachmentTips })}</>
                                </div>
                            </Upload.Dragger>
                        )}
                        {attachmentFile.name && (
                            <Row type="flex" gutter={8}>
                                <Col><a>{attachmentFile.name}</a></Col>
                                <Col>
                                    <Icon type="close-circle" title="delete" onClick={() => this.deleteAttachment()} />
                                </Col>
                            </Row>
                        )}
                    </Form.Item>
                </GLForm>
                {
                    lvaResourceType === LVAResourceType.Video ?
                        <div className="video-retention">
                            <VideoRetentionMsg />
                        </div> : <div className="external-link__note">
                            <Typography.Text>{fmtMsg({ id: SchoolLocale.VisitationLVALinkNote })}</Typography.Text>
                        </div>
                }
            </Modal>
        )
    }
}