import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IStudentSubscriptionService } from './interface';
import { SubscriptionActionRequest } from './model';
import { SchoolLocale } from '../../../locales/localeid';

@injectable()
export class StudentSubscriptionService extends GLServiceBase<any, any> implements IStudentSubscriptionService {
    serviceRoute = { prefix: 'admin/v1/schools', suffix: '{id}' };
    changeStudentSubscriptionUrl = "ChangeStudentSubscription";
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.RegionSettingLimitException, `${this.serviceRoute.prefix}/${this.changeStudentSubscriptionUrl}`)] = SchoolLocale.ClassLicenseLimitInvalid;
        return result;
    })();
    getSubscriptionRequestCount(params) {
        return this.get('GetSubscriptionRequestCount', params);
    }
    changeStudentSubscription(data: SubscriptionActionRequest) {
        return this.update('ChangeStudentSubscription', data, null);
    }
}