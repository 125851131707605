import * as React from 'react';
import { Route, Switch } from 'react-router-dom'

import { PathConfig, AdminPathConfig, SchoolPathConfig, SurveyPathConfig } from "./pathconfig";

import { DashboardPage } from '../page/dashboard';
import { GSSchoolAction, SurveyAction } from '../util';

import { NotificationsPage } from '../page/notifications/notifications';
import { NotificationCreatePage } from '../page/notifications/notificationcreate';
import { NotificationManagePage } from '../page/notifications/notificationscreater';
import { NotificationReviewPage } from '../page/notifications/notificationsreview';
import { NotificationPage } from '../page/notifications/notification';
import { NotificationEditPage } from '../page/notifications/notificationedit';

import { UpcomingEventsPage } from '../page/upcomingevents/upcomingevents';

import { InvitationTemplatesPage, InvitationTemplatesNewPage, InvitationTemplatesEditPage } from '../page/invitation-template/index';

import { GlobalProductPage } from '../page/product/global';
import { GlobalProductNewPage } from '../page/product/global-new';
import { GlobalProductEditPage } from '../page/product/global-edit';
import { LocalProductPage } from '../page/product/local';
import { LocalProductNewPage } from '../page/product/local-new';
import { LocalProductEditPage } from '../page/product/local-edit';

import { SchoolChangeLogPage } from '@app/page/cims/school-change-log';
import { CampusChangeLogPage } from '@app/page/cims/campus-change-log';
import { CampusesPage } from '@app/page/cims/campus';

import { VisitationHistoryPage as SchoolVisitationHistoryPage } from '@app/page/school/visitation/history';

import { PageContainer, UploadStatus } from "../components";
import {
    GLDefaultRoutes, GLRoute, AuthRouteHandler, RoleRouteHandler, RoleName,
    CallbackRouteHandler, showLogo, RoutePipeline,
    ActionRouteHandler, GLUtil, ResourceType, ResourcePermissionType, OwnerRouteHandler, AccessibleRouteHandler, withRouter, GLGlobal, GLLocale
} from 'gl-commonui';

import { BreadcrumbContainer } from '../components/breadcrumb';

import { PendingChangesPage } from '@app/page/cims/pending-changes-page';
import { RegionsPage } from '../page/admin/regions/regions';
import { RegionPage } from '../page/admin/regions/region';
import RegionRewardPointsPage from '../page/admin/regions/reward-points';
import { RegionInvitePage } from '../page/admin/regions/regioninvite';
import { RegionsCreatePage } from '../page/admin/regions/regionscreate';
import { RegionsEditPage } from '../page/admin/regions/regionsedit';
import { RegionOrdersPage } from '../page/admin/regions/orders';
import { UsersPage } from '../page/admin/users/users';
import { UsersDetailPage } from '../page/admin/users/users-detail';
import { SchoolsCreatePage } from '../page/admin/schools/schoolcreate';
import { StudentVerificationPage } from '../page/admin/regions/components/studentverification';
import { SchoolTrainersPage } from '../page/admin/schools/schooltrainer';
import { AcceptInvitationPage } from '../page/admin/acceptinvitation/acceptinvitation';
import { AcceptInvitationCodePage } from '../page/admin/acceptinvitation/acceptinvitationcode';
import { AcceptInvitationSuccessPage } from '../page/admin/acceptinvitation/invitationsuccess';
import { RegisterPage } from '../page/admin/acceptinvitation/register';
import { InvitationsPage as AdminInvitationsPage } from '../page/admin/invitations/invitations';
import { UnAuthorizedPage } from '../page/admin/exceptions/UnAuthorized';

import { GSAdminAction } from "../util";
import { BreadcrumbContainer as AdminBreadcrumbContainer } from '../components/admin/breadcrumb';
import { SearchPage } from '../page/admin/search/search';
import { RedeemExistingPage } from '../page/admin/acceptinvitation/redeemexisting';
import ContentRevisionHistory from '../page/admin/content-revision-history/contentRevisionHistory';


import { ValidateCodePage as SchoolValidateCodePage } from '../page/school/acceptinvitation/validatecode';
import { InvitingPage as SchoolInvitingPage } from '../page/school/acceptinvitation/inviting';
import { InvitedPage as SchoolInvitedPage } from '../page/school/acceptinvitation/invited';
import { RegisterPage as SchoolRegisterPage } from '../page/school/acceptinvitation/register';
import { RedeemExistingPage as SchoolRedeemExistingPage } from '../page/school/acceptinvitation/redeemexisting';

import { CampusNewPage, CampusEditPage, CampusAdminsPage } from '../page/school/campus/index';
import { MovePromoteStudents } from '@app/page/school/campus/move-promote-students';
import { SettingsPage } from '../page/school/settings';
import { AccountManagersPage } from '../page/school/account-managers';
import { SchoolAdminsPage } from '../page/school/admins';
import { SchoolClassesPage, SchoolClassNewPage, SchoolClassEditPage, UnitPlanPage, LicensePage, ClassBulkEditPage } from '../page/school/class';
import { StudentsPage, StudentReportPage } from '../page/school/student';
import { TeachersPage, InvitationsPage as SchoolInvitationsPage, SchoolTeachersPage } from '../page/school/invitation';
import { MaterialNewPage, ClassNewPage, CampusOrdersPage, CampusOrderPage, CampusDraftOrderPage } from '../page/school/material/index';
import { SchoolLicensesPage } from '../page/school/license/list';
import { SchoolLicenseHistoryPage } from '../page/school/license/history';

//import { getSchools } from '@app/states/school/school';
import { ClassesPage } from '../page/school/classes';
import { LicenseHistoryPage } from '../page/school/class/history';
import { StudentsPromotePage } from '../page/school/class/students';
import { MoveStudentPage } from '../page/school/move-student';
import { SchoolPageContainer } from '@app/components/school-page-container';
import { CodeLookupPage } from '@app/page/admin/code-lookup/code-lookup';
import { CreatePollPage, ReviewPollsHistory, FillPollForm } from '@app/page/survey';
import { PollsPage } from '@app/page/survey/polls';
import { PollBreadcrumbContainer } from '@app/components/survey/poll-breadcrumb/poll-breadcrumb';
import { CreatedPollsHistory } from '@app/page/survey/components/created-polls/created-polls-history';
import { ReviewerResponseTabWrapper } from '@app/page/survey/components/review-polls/reviewer-response-tab-wrapper';
import { RegionCoachPage } from '@app/page/admin/regions/regioncoach';
import { CoachDashboard as CoachDashboardPage } from '@app/page/coach/dashboard';
import { TeacherVisitation } from '@app/page/coach/ongoing/teacher-visit';
import { CoachVisitation } from '@app/page/coach/ongoing/coach-visit';
import { TeacherDashboard as TeacherDashboardPage } from '@app/page/coach/teacher/dashboard';
import { SchoolCoachPage } from '@app/page/coach/coach/home';
import { SchoolTeacherPage } from '@app/page/coach/teacher/home';
import { MyActivityPage } from '@app/page/coach/my-activity';
import { MyChatPage } from '@app/page/chat/my-chat';
import { ChatHistoryPage } from '@app/page/chat/chat-history';
import { VisitationNew } from '@app/page/coach/ongoing/visitation-new';
import { StudentManualRegistrationPage } from '@app/page/school/student/registration/student-manual-registration-page';
import { UnitPlanBulkPage } from '@app/page/school/class/unitplanbulk';
import { GroupManagerPage } from '@app/page/school/class/groupmanager';
import { ManageGroupAccess, RemoteTsiAccessibleRouteHandler, StudentAccessibleRouteHandler, StudentManageAccessibleRouteHandler, UserAccessibleRouteHandler } from './route-handler';
import { ManageStudentsPage } from '@app/page/admin/regions/managestudents';
import { MergeStudentsPage } from '@app/page/admin/regions/mergestudents';
import { VerifyStudentsPage } from '@app/page/admin/regions/verifystudents';
import { TagManagerPage } from '@app/page/admin/regions/components/tag-manager/tag-manager';
import { ManageStudentLogin } from '@app/page/school/class/manage-student-login/manage-student-login';
import TalkTimeAccess from "@app/page/admin/regions/components/talk-time/talk-time-access";
import { TermManagerPage } from '@app/page/admin/regions/components/term-manager/term-manager';
import { SchoolLocale } from '@app/locales/localeid';
import BulkAddPointsPage from '@app/page/admin/regions/reward-points/bulk-add-points';

const showLogoHandle = new CallbackRouteHandler(() => showLogo(true), () => showLogo(false));

enum RouteCheckHandleType {
    NoneCheckHanle,
    IsOwnerCheckHandle,
    IsAccessibleCheckHanle,
    IsStudentAccessibleCheckHandle,
    IsRemoteTsiCheckHandle,
    IsUserAccessibleCheckHandle,
    IsStudentVerificationEnable
}

export const AccessDeniedPage = withRouter(function AccessDeniedPage(props) {
    let message = GLGlobal.intl.formatMessage({ id: GLLocale.AccessDeniedDesc });
    if (props.location.pathname === PathConfig.StudentAccessDenied) {
        message = GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentAccessDeniedMessage });
    }
    return <div className='gl-access-denied'>
        <h1>{GLGlobal.intl.formatMessage({ id: GLLocale.AccessDenied })}</h1>
        <p>{message}</p>
    </div>;
})

const RouteWithBreadcrumb = ({ component: Component, handlers, title, showLogo, checkHandleType, ...rest }: any) => {
    let mergedHandlers = showLogo ? [...handlers].concat([showLogoHandle]) : [...handlers];
    return (
        <Route {...rest} render={(props: any) => {
            const key = props.match && props.match.url ? props.match.url : null;
            const { schoolId } = props.match.params;
            const { resourceType, resourceKey, resourcePermissionType, resourceAdditionalKey } = getParamsByRoute(props);
            let actionHandler = mergedHandlers.find(handle => handle.actions);
            if (actionHandler) {
                //set permission check params used in ActionRouteHandler
                actionHandler.resourcePropagation = { resourceType, resourceKey };
            }
            if (checkHandleType) {
                resourceKey && checkHandleType == RouteCheckHandleType.IsOwnerCheckHandle && mergedHandlers.push(new OwnerRouteHandler(resourcePermissionType, resourceKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsAccessibleCheckHanle && mergedHandlers.push(new AccessibleRouteHandler(resourcePermissionType, resourceKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsStudentAccessibleCheckHandle && mergedHandlers.push(new StudentAccessibleRouteHandler(resourceKey, resourceAdditionalKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsRemoteTsiCheckHandle && mergedHandlers.push(new RemoteTsiAccessibleRouteHandler(resourceKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsStudentVerificationEnable && mergedHandlers.push(new StudentManageAccessibleRouteHandler(resourceKey, resourceAdditionalKey));
            }
            return (
                <RoutePipeline {...{ ...props, handlers: mergedHandlers, title }} key={key} >
                    <>
                        <BreadcrumbContainer {...props}></BreadcrumbContainer>
                        {schoolId && <SchoolPageContainer schoolId={schoolId} location={props.location} ><ComponentWrapper {...{ ...props, component: Component }}></ComponentWrapper></SchoolPageContainer>}
                        {!schoolId && <ComponentWrapper {...{ ...props, component: Component }}></ComponentWrapper>}
                    </>
                </RoutePipeline>
            );
        }}>
        </Route>
    );
}

const getParamsByRoute = (props) => {
    const { regionId, schoolId, campusId, classId, templateId, localProductId, studentId, userId, mergedClassId } = props.match.params;
    const resourceAdditionalKey = studentId || userId || mergedClassId || null;
    const resourceKey = localProductId || templateId || classId || campusId || schoolId || regionId;
    const resourceType = (classId && ResourceType.SchoolClass)
        || (campusId && ResourceType.Campus)
        || (schoolId && ResourceType.School)
        || (regionId && ResourceType.Region);
    const resourcePermissionType = (localProductId && ResourcePermissionType.Product)
        || (templateId && ResourcePermissionType.InvitationTemplate)
        || (classId && ResourcePermissionType.SchoolClass)
        || (campusId && ResourcePermissionType.Campus)
        || (schoolId && ResourcePermissionType.School)
        || (regionId && ResourcePermissionType.Region);
    return { resourceType, resourceKey, resourcePermissionType, resourceAdditionalKey };
}

const ComponentWrapper = (props) => {
    const { component: Component, location, match: { params }, history } = props;
    //compatible path process
    const compatiblePaths = new Map<string, string>([
        [AdminPathConfig.Region, PathConfig.Region],
        [AdminPathConfig.School, PathConfig.Schools],
        [AdminPathConfig.SchoolEdit, PathConfig.Settings]
    ]);
    const paths = new Map<string, string>(
        [...compatiblePaths.keys()].map(path => [pathStringify(path, params), compatiblePaths.get(path)])
    );
    if (paths.has(location.pathname)) {
        history.replace(GLUtil.pathStringify(paths.get(location.pathname), params));
    }
    return <Component {...props}></Component>
}

function pathStringify(pathName, params, opts?) {
    try {
        return GLUtil.pathStringify(pathName, params, opts);
    } catch (error) {
        return pathName;
    }
}

const AdminRouteWithBreadcrumb = ({ component: Component, handlers, checkHandleType, ...rest }: any) => {
    const mergedHandlers = handlers.concat([showLogoHandle]);
    return (
        <Route {...rest} render={(props: any) => {
            const key = props.match && props.match.url ? props.match.url : null;
            const { resourceType, resourceKey, resourcePermissionType, resourceAdditionalKey } = getParamsByRoute(props);
            let actionHandler = mergedHandlers.find(handle => handle.actions);
            if (actionHandler) {
                //set permission check params used in ActionRouteHandler
                actionHandler.resourcePropagation = { resourceType, resourceKey };
            }
            if (checkHandleType) {
                resourceKey && checkHandleType == RouteCheckHandleType.IsOwnerCheckHandle && mergedHandlers.push(new OwnerRouteHandler(resourcePermissionType, resourceKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsAccessibleCheckHanle && mergedHandlers.push(new AccessibleRouteHandler(resourcePermissionType, resourceKey));
                resourceAdditionalKey && checkHandleType == RouteCheckHandleType.IsUserAccessibleCheckHandle && mergedHandlers.push(new UserAccessibleRouteHandler(resourceAdditionalKey));
            }
            return (
                <RoutePipeline {...{ ...props, handlers: mergedHandlers }} key={key} >
                    <>
                        <AdminBreadcrumbContainer {...props}></AdminBreadcrumbContainer>
                        <Component {...props}></Component>
                    </>
                </RoutePipeline>
            );
        }}>
        </Route>
    );
}

const RouteWithPollBreadcrumb = ({ component: Component, handlers, checkHandleType, ...rest }: any) => {
    const mergedHandlers = handlers.concat([showLogoHandle]);
    return (
        <Route {...rest} render={(props: any) => {
            const key = props.match && props.match.url ? props.match.url : null;
            const { resourceType, resourceKey, resourcePermissionType } = getParamsByRoute(props);
            let actionHandler = mergedHandlers.find(handle => handle.actions);
            if (actionHandler) {
                //set permission check params used in ActionRouteHandler
                actionHandler.resourcePropagation = { resourceType, resourceKey };
            }
            if (checkHandleType) {
                resourceKey && checkHandleType == RouteCheckHandleType.IsOwnerCheckHandle && mergedHandlers.push(new OwnerRouteHandler(resourcePermissionType, resourceKey));
                resourceKey && checkHandleType == RouteCheckHandleType.IsAccessibleCheckHanle && mergedHandlers.push(new AccessibleRouteHandler(resourcePermissionType, resourceKey));
            }
            return (
                <RoutePipeline {...{ ...props, handlers: mergedHandlers }} key={key} >
                    <>
                        <PollBreadcrumbContainer {...props}></PollBreadcrumbContainer>
                        <Component {...props}></Component>
                    </>
                </RoutePipeline>
            );
        }}>
        </Route>
    );
}
const RouteWithoutBreadcrumb = ({ component: Component, handlers, ...rest }) => {
    return (
        <Route {...rest} render={(props: any) => {
            return (
                <RoutePipeline {...{ ...props, handlers }}>
                    <>
                        <Component {...props}></Component>
                    </>
                </RoutePipeline>
            );
        }}>
        </Route>
    );
}
const MainComponentLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props: any) => {
            return (
                <RoutePipeline {...{ ...props, handlers: [showLogoHandle] }}>
                    <div className='main-component-layout'>
                        <Component {...props}></Component>
                    </div>
                </RoutePipeline>
            );
        }}>
        </Route>
    );
}

class Root extends React.Component {
    componentWillMount() {

    }
    render() { return null }
}
//const handlers = [new AuthRouteHandler()];
const handlers = [new AuthRouteHandler(), new RoleRouteHandler([
    RoleName.systemAdmin,
    RoleName.globalHead,
    RoleName.trainingAdmin,
    RoleName.regionAdmin,
    RoleName.accountManager,
    RoleName.trainer,
    RoleName.schoolAdmin,
    RoleName.campusAdmin,
    RoleName.teacher])]

    const manageGroupHandlers = [new AuthRouteHandler(), new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher]), new ManageGroupAccess()]

const surveyUserHandlers = [
    new AuthRouteHandler(),
    new ActionRouteHandler([
        SurveyAction.ListMySurvey,
    ])
];

const router = (
    <>
        <PageContainer>
            <Switch>
                <GLRoute exact path={SchoolPathConfig.Home} component={DashboardPage} handlers={handlers.concat([new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]).concat([new ActionRouteHandler([GSSchoolAction.Search])])}></GLRoute>
                <AdminRouteWithBreadcrumb exact path={SchoolPathConfig.CoachDashboard} component={CoachDashboardPage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={SchoolPathConfig.TeacherDashboard} component={TeacherDashboardPage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.VisitationNew} component={VisitationNew} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CoachVisitation} component={CoachVisitation} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.TeacherVisitation} component={TeacherVisitation} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <MainComponentLayout exact path={AdminPathConfig.AcceptInvitation} component={AcceptInvitationPage}></MainComponentLayout>
                <MainComponentLayout path={AdminPathConfig.AcceptInvitationCode} component={AcceptInvitationCodePage}></MainComponentLayout>
                <MainComponentLayout path={AdminPathConfig.AcceptInvitationSuccess} component={AcceptInvitationSuccessPage}></MainComponentLayout>
                <MainComponentLayout path={AdminPathConfig.RedeemExisting} component={RedeemExistingPage}></MainComponentLayout>
                <MainComponentLayout path={AdminPathConfig.Register} component={RegisterPage}></MainComponentLayout>
                {/* <MainComponentLayout path={AdminPathConfig.RegisterSuccess} component={RegisterSuccessPage}></MainComponentLayout> */}

                <GLRoute exact path={SchoolPathConfig.ValidateCode} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolValidateCodePage}></GLRoute>
                <GLRoute exact path={SchoolPathConfig.Inviting} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolInvitingPage}></GLRoute>
                <GLRoute exact path={SchoolPathConfig.Invited} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolInvitedPage}></GLRoute>
                <GLRoute path={SchoolPathConfig.RedeemExisting} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolRedeemExistingPage}></GLRoute>
                <GLRoute exact path={SchoolPathConfig.Register} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolRegisterPage}></GLRoute>
                {/* <GLRoute exact path={SchoolPathConfig.RegisterSuccess} handlers={[new CallbackRouteHandler(() => showLogo(true), () => showLogo(false))]} component={SchoolRegisterSuccessPage}></GLRoute> */}

                <AdminRouteWithBreadcrumb exact path={PathConfig.Notifications} component={NotificationsPage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.NotificationCreate} component={NotificationCreatePage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.NotificationEdit} component={NotificationEditPage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.NotificationCreated} component={NotificationManagePage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateNotification])])}></AdminRouteWithBreadcrumb>
                {/* <AdminRouteWithBreadcrumb exact path={PathConfig.NotificationReview} component={NotificationReviewPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ReviewNotification])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.NotificationReviewOne} component={NotificationPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ReviewNotification])])}></AdminRouteWithBreadcrumb> */}

                <AdminRouteWithBreadcrumb exact path={PathConfig.MyChat} component={MyChatPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Chat])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.ChatHistory} component={ChatHistoryPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ChatHistory])])}></AdminRouteWithBreadcrumb>

                <AdminRouteWithBreadcrumb exact path={PathConfig.UpcomingEvents} component={UpcomingEventsPage} handlers={handlers.concat([])}></AdminRouteWithBreadcrumb>

                <AdminRouteWithBreadcrumb exact path={PathConfig.InvitationTemplates} component={InvitationTemplatesPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListInvitationTemplate, GSSchoolAction.InvitationTemplate])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb path={PathConfig.InvitationTemplateCreate} component={InvitationTemplatesNewPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateInvitationTemplate, GSSchoolAction.NewTemplate])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb path={PathConfig.InvitationTemplateEdit} component={InvitationTemplatesEditPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditInvitationTemplate, GSSchoolAction.EditTemplate])])}></AdminRouteWithBreadcrumb>

                <AdminRouteWithBreadcrumb exact path={PathConfig.PendingChanges} component={PendingChangesPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListPendingChanges])])} showLogo={true} />

                <RouteWithBreadcrumb exact path={PathConfig.MyActivity} component={MyActivityPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.TeacherMyActivity, GSSchoolAction.CoachMyActivity])])} ></RouteWithBreadcrumb>

                <RouteWithBreadcrumb exact path={PathConfig.Regions} component={RegionsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListRegion])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Region} component={RegionPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchool])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionRewardPoints} component={RegionRewardPointsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ManagePointsView])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionBulkAddPoints} component={BulkAddPointsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ManagePointsEdit])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionInvite} component={RegionInvitePage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListRegionAdmin])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionCreate} component={RegionsCreatePage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateRegion])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionEdit} component={RegionsEditPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditRegion, GSAdminAction.ViewRegion])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionOrders} component={RegionOrdersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListRegionOrders])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionTalkTimeSetting} component={TalkTimeAccess} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListRegionSchoolTsiSettings])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionTagManager} component={TagManagerPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListRegionSchoolTagView])])} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionTermManager} component={TermManagerPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ManageTerm])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Schools} component={CampusesPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Campuses])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolCreate} component={SchoolsCreatePage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateSchool])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolAdmin} component={SchoolAdminsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolAdmin, GSSchoolAction.AddSchoolAdmin])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.AccountManagers} component={AccountManagersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListSchoolAccountManager])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolLicenses} component={SchoolLicensesPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolBillingHistory])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolLicenseHistory} component={SchoolLicenseHistoryPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolBillingHistory])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolChangeHistory} component={SchoolChangeLogPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolChangeHistory])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolVisitationHistory} component={SchoolVisitationHistoryPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolChangeHistory])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolTrainers} component={SchoolTrainersPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolTrainer])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolTrainer} component={SchoolCoachPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.SchoolTrainerHome])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusNew} component={CampusNewPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.AddCampus])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusEdit} component={CampusEditPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditCampus])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusAdmins} component={CampusAdminsPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.CampusAdmins])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusCart} component={MaterialNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.CampusCart])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolCart} component={MaterialNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.CampusCart])])} />
                <RouteWithBreadcrumb exact path={PathConfig.CampusChangeHistory} component={CampusChangeLogPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchoolChangeHistory])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.MovePromoteStudents} component={MovePromoteStudents} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MovePromoteStudents])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.MovePromoteStudentsFromClass} component={MovePromoteStudents} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MovePromoteStudents])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Classes} component={SchoolClassesPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Classes])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassBulkEdit} component={ClassBulkEditPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.BulkEditClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassNew} component={SchoolClassNewPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.AddClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassStudentsPromote} component={StudentsPromotePage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ClassPromotion])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassEdit} component={SchoolClassEditPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassUnitPlan} component={UnitPlanPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditUnitPlan])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassGroupManager} component={GroupManagerPage} checkHandleType={RouteCheckHandleType.IsRemoteTsiCheckHandle} handlers={manageGroupHandlers.concat([new ActionRouteHandler([GSSchoolAction.EditClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassUnitPlanBulk} component={UnitPlanBulkPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditUnitPlan])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassLicense} component={LicensePage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicenseEntry])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassLicenseHistory} component={LicenseHistoryPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicenseEntry])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassCart} component={ClassNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Cart])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusOrders} component={CampusOrdersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MaterialOrder])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusOrdersItem} component={CampusOrderPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MaterialOrder])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.CampusOrdersDraftItem} component={CampusDraftOrderPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MaterialOrder])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Students} component={StudentsPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.StudentList])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegisterStudents} component={StudentManualRegistrationPage} checkHandleType={RouteCheckHandleType.IsAccessibleCheckHanle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.InviteStudents])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.StudentReport} component={StudentReportPage} checkHandleType={RouteCheckHandleType.IsStudentAccessibleCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.StudentList])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Teachers} component={TeachersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditTeacher])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Settings} component={SettingsPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditSchool, GSSchoolAction.SchoolSetting])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Admins} component={SchoolAdminsPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditSchoolAdmins])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.Invitations} component={SchoolInvitationsPage} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.ClassInvitations} component={SchoolInvitationsPage} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolTeachers} component={SchoolTeachersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListSchoolTeacher])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolTeacher} component={SchoolTeacherPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.SchoolTeacherHome])])} ></RouteWithBreadcrumb>
                {/* <RouteWithBreadcrumb exact path={PathConfig.MoveStudent} component={MoveStudentPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicense])])} ></RouteWithBreadcrumb> */}
                <RouteWithBreadcrumb exact path={PathConfig.ManageStudents} component={ManageStudentsPage} checkHandleType={RouteCheckHandleType.IsStudentVerificationEnable} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ManageStudents])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.MergeStudents} component={MergeStudentsPage} checkHandleType={RouteCheckHandleType.IsStudentVerificationEnable} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.VerificationManageStudents])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.VerifyStudents} component={VerifyStudentsPage} checkHandleType={RouteCheckHandleType.IsStudentVerificationEnable} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.VerificationManageStudents])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.MergeThenVerifyStudents} component={VerifyStudentsPage} checkHandleType={RouteCheckHandleType.IsStudentVerificationEnable} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.VerificationManageStudents])])} showLogo={true}></RouteWithBreadcrumb>

                <AdminRouteWithBreadcrumb exact path={AdminPathConfig.Users} component={UsersPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListUser])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb path={AdminPathConfig.UserEdit} component={UsersDetailPage} checkHandleType={RouteCheckHandleType.IsUserAccessibleCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditUser])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={AdminPathConfig.CodeLookup} component={CodeLookupPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.InvitationCodeLookup])])}></AdminRouteWithBreadcrumb>

                <AdminRouteWithBreadcrumb path={AdminPathConfig.Invitations} component={AdminInvitationsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.Invitation])])}></AdminRouteWithBreadcrumb>

                <RouteWithoutBreadcrumb exact path={PathConfig.StudentAccessDenied} component={AccessDeniedPage} handlers={[]}></RouteWithoutBreadcrumb>
                <RouteWithoutBreadcrumb exact path={AdminPathConfig.GSAdminSearch} component={SearchPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.Search])])} ></RouteWithoutBreadcrumb>

                <RouteWithoutBreadcrumb exact path={AdminPathConfig.ContentRevisionHistory} component={ContentRevisionHistory} handlers={handlers}></RouteWithoutBreadcrumb>

                <AdminRouteWithBreadcrumb exact path={PathConfig.GlobalProducts} component={GlobalProductPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListGlobalProduct])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.GlobalProductsCreate} component={GlobalProductNewPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateGlobalProduct])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.GlobalProductsEdit} component={GlobalProductEditPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditGlobalProduct])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.LocalProducts} component={LocalProductPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListLocalProduct])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.LocalProductsCreate} component={LocalProductNewPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.CreateLocalProduct])])}></AdminRouteWithBreadcrumb>
                <AdminRouteWithBreadcrumb exact path={PathConfig.LocalProductsEdit} component={LocalProductEditPage} checkHandleType={RouteCheckHandleType.IsOwnerCheckHandle} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditLocalProduct])])}></AdminRouteWithBreadcrumb>

                {/*Forwards Compatibility*/}
                <RouteWithBreadcrumb exact path={AdminPathConfig.Region} component={RegionPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListSchool])])} showLogo={true}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={AdminPathConfig.School} component={CampusesPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Campuses])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={AdminPathConfig.SchoolEdit} component={SettingsPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.EditSchool])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Schools} component={CampusesPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Campuses])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.SchoolManageStudentLogin} component={ManageStudentLogin} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Campuses])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusNew} component={CampusNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.AddCampus])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusEdit} component={CampusEditPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditCampus])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusAdmins} component={CampusAdminsPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.CampusAdmins])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusCart} component={MaterialNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.CampusCart])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Classes} component={SchoolClassesPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Classes])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassNew} component={SchoolClassNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.AddClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassStudentsPromote} component={StudentsPromotePage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ClassPromotion])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassEdit} component={SchoolClassEditPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassUnitPlan} component={UnitPlanPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditUnitPlan])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassGroupManager} component={GroupManagerPage} handlers={manageGroupHandlers.concat([new ActionRouteHandler([GSSchoolAction.EditClass])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassLicense} component={LicensePage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicenseEntry])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassLicenseHistory} component={LicenseHistoryPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicenseEntry])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.ClassCart} component={ClassNewPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Cart])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusOrders} component={CampusOrdersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MaterialOrder])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.CampusOrdersItem} component={CampusOrderPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.MaterialOrder])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Students} component={StudentsPage} handlers={handlers.concat([new AuthRouteHandler()])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.StudentReport} component={StudentReportPage} handlers={handlers.concat([new AuthRouteHandler()])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Teachers} component={TeachersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditTeacher])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Settings} component={SettingsPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.SchoolSetting])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Admins} component={SchoolAdminsPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditSchoolAdmins])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.Invitations} component={SchoolInvitationsPage} handlers={handlers.concat([])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.SchoolTeachers} component={SchoolTeachersPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.ListSchoolTeacher])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.SchoolSearch} component={SearchPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.Search])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.SchoolClasses} component={ClassesPage} handlers={handlers.concat([new CallbackRouteHandler(() => showLogo(true), () => showLogo(false)), new ActionRouteHandler([GSSchoolAction.Search])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={SchoolPathConfig.MoveStudent} component={MoveStudentPage} handlers={handlers.concat([new ActionRouteHandler([GSSchoolAction.EditLicense])])} ></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionCoaches} component={RegionCoachPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.ListRegionCoach])])}></RouteWithBreadcrumb>
                <RouteWithBreadcrumb exact path={PathConfig.RegionCoach} component={SchoolCoachPage} handlers={handlers.concat([new ActionRouteHandler([GSAdminAction.RegionCoachHome])])}></RouteWithBreadcrumb>

                {/* Poll routes: order specific routes before generic routes. */}
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ReviewPollFromHistory} component={FillPollForm} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.CreateSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ContributePollFromHistory} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ViewPollFromHistory} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ViewResponseCreatorShared} component={ReviewerResponseTabWrapper} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ReviewPollFromLanding} component={FillPollForm} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.CreateSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ContributePollFromLanding} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.CompletePollFromHistory} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ViewPollFromLanding} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.CompletePollFromLanding} component={FillPollForm} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.DetailsFromHistory} component={CreatePollPage} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.EditSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.CreatedHistory} component={CreatedPollsHistory} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.ListSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.ReviewHistory} component={ReviewPollsHistory} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.Create} component={CreatePollPage} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.CreateSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.DetailsFromLanding} component={CreatePollPage} handlers={[new AuthRouteHandler(), new ActionRouteHandler([SurveyAction.EditSurvey])]}></RouteWithPollBreadcrumb>
                <RouteWithPollBreadcrumb exact path={SurveyPathConfig.Home} component={PollsPage} handlers={surveyUserHandlers}></RouteWithPollBreadcrumb>
                {GLDefaultRoutes}
            </Switch>
        </PageContainer>
        <UploadStatus />
    </>

);

export default router;
