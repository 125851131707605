const TYPES = {
    IParentService: Symbol("IParentService"),
    IInvitationService: Symbol("IInvitationService"),
    IStudentService: Symbol("IStudentService"),
    IDeviceService: Symbol("IDeviceService"),
    IReportService: Symbol("IReportService"),
    ISchoolService: Symbol("ISchoolService"),
    ICampusService: Symbol("ICampusService"),
    ISchoolClassService: Symbol("ISchoolClassService"),
    IProductService: Symbol("IProductService"),
    IMaterialService: Symbol("IMaterialService"),
    IInvitationTemplateService: Symbol("IInvitationTemplateService"),
    ISubscriptionMaterialService: Symbol("ISubscriptionMaterialService"),
    IUserService: Symbol("IUserService"),
    IStudentSubscriptionService: Symbol("IStudentSubscriptionService"),
    INotificationService: Symbol("INotificationService"),
    IRegionService: Symbol("IRegionService"),
    ITalkTimeService: Symbol("ITalkTimeService"),
    IRegionGenericLinkService: Symbol("IRegionGenericLinkService"),
    IContentVersionService: Symbol("IContentVersionService"),
    IRoleService: Symbol("IRoleService"),
    IAccountService: Symbol("IAccountService"),
    IResourceService: Symbol("IResourceService"),
    ISchoolLicenseService: Symbol("ISchoolLicenseService"),
    IRegionGroupService: Symbol("IRegionGroupService"),
    IChangeLogService: Symbol("IChangeLogService"),
    ISurveyService: Symbol("ISurveyService"),
    ISurveyResponseService: Symbol("ISurveyResponseService"),
    IContentRevisionHistoryService: Symbol("IContentRevisionHistoryService"),
    IBasicService: Symbol("IBasicService"),
    ISurveyReportService: Symbol("ISurveyReportService"),
    IStudentRegistrationService: Symbol("IStudentRegistrationService"),
    IVisitationService: Symbol("IVisitationService"),
    IUpcomingEventService: Symbol("IUpcomingEventService"),
    IStudentInvitationService: Symbol("IStudentInvitationService"),
    IChatService: Symbol("IChatService"),
    ITrainingService: Symbol("ITrainingService"),
    IContentResourceService: Symbol("IContentResourceService"),
    ITrainingResourceService: Symbol("ITrainingResourceService"),
    IStatisticsService: Symbol("IStatisticsService"),
    IStudentVerificationService: Symbol("IStudentVerificationService"),
    ICIMSService: Symbol("ICIMSService"),
    ITagManagerService: Symbol("ITagManagerService"),
    ITermManagerService: Symbol("ITermManagerService"),
    IRewardPointsService: Symbol("IRewardPointsService"),
};

export { TYPES };
