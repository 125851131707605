import { InvitationChannel } from "@app/util";
import {InvitationStatus} from "@app/service/admin/acceptinvitation";

export enum StudentDetailsModelProps {
    studentId = "studentId",
    name = "name",
    englishName = "englishName",
    birthday = "birthday",
    gender = "gender",
    parentEmail = "parentEmail",
    parentPhoneNumber = "parentPhoneNumber",
    notes = "notes",
    isChecked = "isChecked"
}

export interface StudentDetailsModel {
    studentId?: string;
    name: string;
    englishName: string;
    birthday: Date;
    gender: string;
    parentEmail: string;
    parentPhoneNumber: string;
    notes: object;
    isUploaded: boolean;
    isDirty: boolean;
}
export interface StudentRegistrationModel {
    studentId?: string;
    name: string;
    englishName: string;
    birthday: Date;
    gender: boolean;
    parentEmail: string;
    parentPhoneNumber?: string;
    phoneCountryCode?: string;
    isOverwrite: boolean;
    isCheckboxDisable: boolean;
    selectedMatchId?: string;
    isChecked: boolean;
    isExistedStudent: boolean;
    hasParent: boolean;
    invitationCodeId: string;
    isExactMatch: boolean;
    isDirty: boolean;
    className: string;
    schoolName: string;
    campusName: string;
    currentUnit: number;
    parentStatus?: InvitationStatus;
}

export interface StudentMatchResponseModel {
    name: string;
    englishName: string;
    birthday: Date;
    gender: boolean;
    parentEmail: string;
    parentPhoneNumber: string;
    phoneCountryCode: string;
    isOverwrite: boolean;
    parentId?: string;
    classId?: string;
    studentId?: string;
    isSuggestionMismatched?: boolean;
    invitationChannel?: InvitationChannel;
    oldParentEmail?: string;
}

export interface StudentMatchResponseWithLicenseInfoModel {
    students: StudentMatchResponseModel[];
    licenseInfo: any;
}
