import { bindingCollection } from 'gl-commonui'
import { TYPES } from './types';
import { IUserService, UserService } from './users/index';
import { INotificationService, NotificationService } from './notifications';
import { ISchoolService, SchoolService } from './schools/index';
import { ISchoolClassService, SchoolClassService } from './class/index';
import { IResourceService, ResourceService } from './resources/index';
import { IInvitationTemplateService, InvitationTemplateService } from './invitation-template/index';
import { IMaterialService, MaterialService, ISubscriptionMaterialService, SubscriptionMaterialService } from './material';

import { IRegionService } from './admin/regions/interface';
import { RegionService } from './admin/regions/service';
import { ISchoolLicenseService, SchoolLicenseService } from './admin/license/index';
import { IInvitationService, InvitationService } from './admin/acceptinvitation/index';
import { IContentVersionService, ContentVersionService } from './admin/contentversion/index';
import { IRoleService, RoleService } from './admin/roles/index';
import { IAccountService, AccountService } from './admin/accountservice/index';
import { ChangeLogService, IChangeLogService } from './admin/changelog';
import { RegionGroupService, IRegionGroupService } from './admin/regiongroup';
import { IContentRevisionHistoryService, ContentRevisionHistoryService } from './admin/contentRevisionHistory';
import { IRegionGenericLinkService, RegionGenericLinkService } from "./admin/genericLink";

import { IParentService, ParentService } from './school/parent/index';
import { IStudentService, StudentService } from './school/student/index';
import { IDeviceService, DeviceService } from './school/device/index';
import { IReportService, ReportService } from './school/report/index';
import { ICampusService, CampusService } from './school/campus/index';
import { IProductService, ProductService } from './school/product';
import { IStudentSubscriptionService, StudentSubscriptionService } from './school/student-subscription';
import { ISurveyService, SurveyService } from './survey';
import { IBasicService, BasicService } from './basic';
import { ISurveyResponseService, SurveyResponseService } from './survey/response';
import { ISurveyReportService, SurveyReportService } from './survey/report';
import { IStudentRegistrationService, StudentRegistrationService } from './school/student-registration';
import { IVisitationService, VisitationService } from './coach/visitation';
import { UpcomingEventService, IUpcomingEventService } from './coach/upcomingevent';
import { IInvitationService as IStudentInvitationService, InvitationService as StudentInvitationService } from './school/invitation';
import { ITrainingService, TrainingService } from './training';
import { IChatService, ChatService, ITrainingResourceService, TrainingResourceService, IContentResourceService, ContentResourceService } from './chat';
import { IStatisticsService, StatisticService } from './school/statistic';
import { IStudentVerificationService, StudentVerificationService } from './admin/studentVerification';
import { CIMSService, ICIMSService } from "./cims";
import { ITagManagerService, TagManagerService } from './tag-manager';
import {TalkTimeService} from "@app/service/admin/talktime";
import { ITermManagerService, TermManagerService } from './term-manager';
import { IRewardPointsService, RewardPointService } from './reward-points';

bindingCollection.push((container) => {
    container.bind<IRegionService>(TYPES.ITalkTimeService).to(TalkTimeService);
    container.bind<IRegionService>(TYPES.IRegionService).to(RegionService);
    container.bind<IUserService>(TYPES.IUserService).to(UserService);
    container.bind<ISchoolService>(TYPES.ISchoolService).to(SchoolService);
    container.bind<ISchoolClassService>(TYPES.ISchoolClassService).to(SchoolClassService);
    container.bind<ISchoolLicenseService>(TYPES.ISchoolLicenseService).to(SchoolLicenseService);
    container.bind<IInvitationService>(TYPES.IInvitationService).to(InvitationService);
    container.bind<IInvitationTemplateService>(TYPES.IInvitationTemplateService).to(InvitationTemplateService);
    container.bind<IContentVersionService>(TYPES.IContentVersionService).to(ContentVersionService);
    container.bind<IRoleService>(TYPES.IRoleService).to(RoleService);
    container.bind<IAccountService>(TYPES.IAccountService).to(AccountService);
    container.bind<IResourceService>(TYPES.IResourceService).to(ResourceService);
    container.bind<IChangeLogService>(TYPES.IChangeLogService).to(ChangeLogService);
    container.bind<IRegionGroupService>(TYPES.IRegionGroupService).to(RegionGroupService);
    container.bind<INotificationService>(TYPES.INotificationService).to(NotificationService);
    container.bind<IParentService>(TYPES.IParentService).to(ParentService);
    container.bind<IStudentService>(TYPES.IStudentService).to(StudentService);
    container.bind<IDeviceService>(TYPES.IDeviceService).to(DeviceService);
    container.bind<IReportService>(TYPES.IReportService).to(ReportService);
    container.bind<ICampusService>(TYPES.ICampusService).to(CampusService);
    container.bind<IProductService>(TYPES.IProductService).to(ProductService);
    container.bind<IMaterialService>(TYPES.IMaterialService).to(MaterialService);
    container.bind<ISubscriptionMaterialService>(TYPES.ISubscriptionMaterialService).to(SubscriptionMaterialService);
    container.bind<IStudentSubscriptionService>(TYPES.IStudentSubscriptionService).to(StudentSubscriptionService);
    container.bind<ISurveyService>(TYPES.ISurveyService).to(SurveyService);
    container.bind<ISurveyResponseService>(TYPES.ISurveyResponseService).to(SurveyResponseService);
    container.bind<IContentRevisionHistoryService>(TYPES.IContentRevisionHistoryService).to(ContentRevisionHistoryService);
    container.bind<IBasicService>(TYPES.IBasicService).to(BasicService);
    container.bind<ISurveyReportService>(TYPES.ISurveyReportService).to(SurveyReportService);
    container.bind<IStudentRegistrationService>(TYPES.IStudentRegistrationService).to(StudentRegistrationService);
    container.bind<IVisitationService>(TYPES.IVisitationService).to(VisitationService);
    container.bind<IUpcomingEventService>(TYPES.IUpcomingEventService).to(UpcomingEventService);
    container.bind<IStudentInvitationService>(TYPES.IStudentInvitationService).to(StudentInvitationService);
    container.bind<IChatService>(TYPES.IChatService).to(ChatService);
    container.bind<ITrainingService>(TYPES.ITrainingService).to(TrainingService);
    container.bind<IContentResourceService>(TYPES.IContentResourceService).to(ContentResourceService);
    container.bind<ITrainingResourceService>(TYPES.ITrainingResourceService).to(TrainingResourceService);
    container.bind<IStatisticsService>(TYPES.IStatisticsService).to(StatisticService);
    container.bind<IStudentVerificationService>(TYPES.IStudentVerificationService).to(StudentVerificationService);
    container.bind<ICIMSService>(TYPES.ICIMSService).to(CIMSService);
    container.bind<IRegionGenericLinkService>(TYPES.IRegionGenericLinkService).to(RegionGenericLinkService);
    container.bind<ITagManagerService>(TYPES.ITagManagerService).to(TagManagerService);
    container.bind<ITermManagerService>(TYPES.ITermManagerService).to(TermManagerService);
    container.bind<IRewardPointsService>(TYPES.IRewardPointsService).to(RewardPointService);

});
