import { GLServiceBase, customErrMsgs, HttpMethod, ServerExceptionCode, getServiceMethodKey, customError } from 'gl-commonui'
import { injectable } from '../../util/di'
import { SchoolHelper } from '../../util'
import { ISchoolClassService } from './index';
import { MovePromoteStudentsLocale, SchoolLocale } from '../../locales/localeid';
import { MoveStudentRequestModel } from '@app/states/school/classModel';
import { movePromoteSaveModel, SaveStudentCodeRequestModel, SchoolClassWithStudentsInfoModel, StudentCodeResponseModel, StudentCodeValidateModel, UpdateSchoolTeacherClassAssociationModel } from './model';

@injectable()
export class SchoolClassService extends GLServiceBase<any, any> implements ISchoolClassService {
    serviceRoute = { prefix: 'admin/v1/schools/{schoolId}/classes', suffix: '{id}' }
    serviceRouteMovePromote = 'admin/v1/classes/savemovepromotechanges'
    //serviceRoute = { prefix: 'admin/v1/schools/{schoolId}/classes' }
    unitPlanUrl = '{id}/plans';
    unitStartDateUrl = '{id}/unitPlan/{currentUnit}';
    unitPlanBulkUrl = '~admin/v1/schools/{schoolId}/campuses/{campusId}/classes/plans/bulk';
    licenseUrl = '{id}/status';
    updateLicenseUrl = '{id}/licenses'
    teacherUrl = '{id}/teachers/{teacherId}';
    BaseClassCanNotUseException = 4811;
    UnitValidInConsecutiveException = 4812;
    EditClassInvalidGSMaxUnitException = 4615;
    EditClassInvalidLSMaxUnitException = 4616;
    InvalidMoveException = 6027;
    EditClassInvalidLicenseTypeException = 6028;
    EditClassInvalidLicenseTypeExceptionForNexusLicenses = 6030;
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, this.serviceRouteMovePromote)] = { id: SchoolLocale.CommonErrorTargetSaveFailed, values: { target: { id: SchoolLocale.ClassTitle } } };
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = { id: SchoolLocale.CommonErrorTargetSaveFailed, values: { target: { id: SchoolLocale.ClassTitle } } };
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ExceedMaxUnitsPerYearException)] = SchoolLocale.ClassUnitValidOverflowCommon;
        result[getServiceMethodKey(this, HttpMethod.Post, this.BaseClassCanNotUseException)] = SchoolLocale.ClassPromoteBaseClassError;
        result[getServiceMethodKey(this, HttpMethod.Post, this.UnitValidInConsecutiveException)] = SchoolLocale.ClassUnitValidInConsecutive;
        result[getServiceMethodKey(this, HttpMethod.Post, this.EditClassInvalidGSMaxUnitException)] = SchoolLocale.ClassMaxUnitError4GS;
        result[getServiceMethodKey(this, HttpMethod.Post, this.EditClassInvalidLSMaxUnitException)] = SchoolLocale.ClassMaxUnitError4LS;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = { id: SchoolLocale.CommonErrorNoTarget, values: { target: { id: SchoolLocale.ClassTitle } } };
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassCampusNull; //{id: SchoolLocale.CommonErrorTargetSaveFailed, values: {target: {id:SchoolLocale.ClassTitle}}};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.UnsuportedOperationException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassLicenseTypeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ExceedMaxUnitsPerYearException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassUnitValidOverflowCommon;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.MinDaysPerUnitException, `${this.serviceRoute.prefix}/${this.unitPlanUrl}`)] = SchoolLocale.UnitPlanValidMinimumDaysCommon;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.RegionSettingLimitException, `${this.serviceRoute.prefix}/${this.licenseUrl}`)] = SchoolLocale.ClassLicenseLimitInvalid;
        result[getServiceMethodKey(this, HttpMethod.Put, this.EditClassInvalidGSMaxUnitException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassMaxUnitError4GS;
        result[getServiceMethodKey(this, HttpMethod.Put, this.EditClassInvalidLSMaxUnitException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassMaxUnitError4LS;
        result[getServiceMethodKey(this, HttpMethod.Put, this.EditClassInvalidLicenseTypeException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassLicenseTypeError;
        result[getServiceMethodKey(this, HttpMethod.Put, this.EditClassInvalidLicenseTypeExceptionForNexusLicenses, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.ClassNexusLicenseTypeError;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = { id: SchoolLocale.CommonErrorNoTarget, values: { target: { id: SchoolLocale.ClassTitle } } };
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.teacherUrl}`)] = SchoolLocale.ClassTeacherNoneDeleteMessage;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = SchoolLocale.ClassDeleteValid;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.RegionSettingLimitException, `${this.serviceRoute.prefix}/${this.updateLicenseUrl}`)] = SchoolLocale.ClassLicenseLimitInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, this.InvalidMoveException, this.serviceRouteMovePromote)] = MovePromoteStudentsLocale.RestrictActiveToDisableWarningMessage;
        return result;
    })();
    getTimeSlotGroupManager(classId) {
        return this.get('~admin/v1/' + classId + '/groups');
    }
    getStudentGroupManager(classId) {
        return this.get('~admin/v1/' + classId + '/groups/students');
    }
    updateGroupManager(classId, data) {
        return this.request.post(this.url('~admin/v1/' + classId + '/groups/save'), data);
    }
    formatSchoolClassList(schoolclasses: any) {
        return schoolclasses;
    }
    getSchoolClasses(params: any) {
        return this.get(params);
    }
    formatSchoolLicenseList(schoolLicenses: any[]) {
        const schoolLicenseType = SchoolHelper.generateSchoolCurriculumType();
        const schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType();
        return schoolLicenses.map(schoolLicense => {
            schoolLicense.licenseType = schoolLicenseType.get(schoolLicense.licenseType.toString());
            schoolLicense.subscriptionType = schoolSubscriptionType.get(schoolLicense.subscriptionType.toString());
            schoolLicense.billingDate = SchoolHelper.formatBillingPeriod(schoolLicense.billingDate);
            return schoolLicense;
        });
    }
    getSchoolLicense(params: { pagingParams: any, routeParams: any }) {
        return this.get('billinghistory/{regionBillingHistoryId}', params.pagingParams, params.routeParams);
    }
    getAccessibleClasses(schoolId, campusId, disabled?) {
        return this.get('~admin/v1/classes/accessibleclasses', { schoolId, campusId, disabled, isDetail: false });
    }
    getAccessibleClassesWithoutFuture(campusId, disabled?) {
        return this.get('~admin/v1/classes/accessibleclasses', { campusId, disabled, isDetail: false, ignoreFutureClass:true });
    }
    isFutureClass(classId):Promise<boolean> {
        return this.get('~admin/v1/classes/isfutureclass', { classId });
    }
    getClassesAndStudentsForMoveAndPromote(campusId, isFuture, isDisabled, classId?):Promise<SchoolClassWithStudentsInfoModel[]> {
        return this.get('~admin/v1/classes/getclassesandstudents', { campusId, classId, isFuture, isDisabled});
    }
    savemovepromotechanges(data: movePromoteSaveModel) {
        return this.create("~admin/v1/classes/savemovepromotechanges", data);
    }
    getUnitPlans(schoolId, id) {
        return this.get(this.unitPlanUrl, null, { schoolId, id });
    }
    getUnitPlansForBulk(options) {
        return this.get(this.unitPlanBulkUrl, options);
    }
    updateUnitPlansForBulk(data, route) {
        return this.update(this.unitPlanBulkUrl, data, route);
    }
    updateUnitPlans(schoolId, id, data) {
        return this.update(this.unitPlanUrl, data, { schoolId, id });
    }
    updateLicense(schoolId, id, data) {
        return this.update(this.licenseUrl, data, { schoolId, id });
    }
    removeStudent(schoolId, id, studentId) {
        return this.update('{id}/RemoveStudent/{studentId}', null, { schoolId, id, studentId });
    }
    createPromoteStudents(schoolId, id, studentIds, isPromotion) {
        return this.create("{id}/students?isPromotion={isPromotion}", studentIds, { schoolId, id, isPromotion });
    }
    getStudentsBy(data, route) {
        return this.getItemsBy('{id}/students', data, route);
    }
    getTeachersBy(data, route) {
        return this.getItemsBy('{id}/teachers', data, route);
    }
    deleteStudentsBy(route) {
        return this.delete('{id}/students/{studentId}', null, route);
    }
    deleteTeacherBy(isPrimary, iscompleteDelete, route, regionId?: string) {
        const url = `${this.serviceRoute.prefix}/${this.teacherUrl}`;
        return this.request.del(url, { isPrimary, iscompleteDelete, regionId }, route)
            .catch(customError(this, HttpMethod.Delete, url));
    }
    getClasses(params) {
        return this.getItemsBy('~admin/v1/classes/accessibleclasses', params);
    }
    getClassesForMoveStudent(params) {
        return this.getItemsBy('~admin/v1/classes/getclassesformovestudent', params);
    }
    getClassLicenseHistory(data, route) {
        return this.get('{id}/licenseHistory', data, route);
    }
    getClassPromotionHistory(data, route) {
        return this.get('{id}/promotionHistory', data, route);
    }
    getAccessible(data) {
        return this.getClasses(data)
    }
    getUserRole(route) {
        return this.get('{id}/users/{userId}/role', null, route);
    }
    getPromotion(params) {
        return this.get('promotion', params);
    }
    getClassListExcel(params) {
        return this.getBlob('~admin/v1/classes/downloadexcel', params);
    }
    moveStudents(data: MoveStudentRequestModel) {
        return this.create("~admin/v1/classes/movestudent", data);
    }
    setMainTeacher(params) {
        return this.update('{id}/teachers/{teacherId}/type', params);
    }
    getClassesForUnitPlanCopy(params) {
        return this.get('plans/candidates', params);
    }
    updateLicenseAndSubscription(schoolId: string, id: string, data) {
        return this.update(this.updateLicenseUrl, data, { schoolId, id });
    }
    getBulkEditClasses(route) {
        return this.get('~admin/v1/schools/{schoolId}/campuses/{campusId}/classes/bulk', null, route);
    }
    updateBulkEditClasses(data, route, query) {
        return this.update(`~admin/v1/schools/{schoolId}/campuses/{campusId}/classes/bulk?offset=${query.offset}`, data, route);
    }
    updateAllowedLessons(data, route) {
        return this.update('~admin/v1/schools/{schoolId}/classes/lessons/allowed', data, route)
    }
    getClassUnits(params: any) {
        return this.get('~admin/v1/schools/{schoolId}/campuses/{campusId}/classes/units', null, params);
    }

    isClassManager(classId) {
        return this.get('is-manager/' + classId);
    }
    deleteDeactivatedStudent(classId, studentId) {
        return this.delete('~admin/v1/classes/{studentId}/class/{schoolClassId}', null, { studentId, schoolClassId: classId })
    }
    getAccessibleClassIds() {
        return this.get('~admin/v1/classes/accessibleClassIds');
    }
    getStudentCodeData(params): Promise<StudentCodeResponseModel> {
        const { schoolId, schoolClassId } = params;
        return this.get('~admin/v1/students/{schoolId}/studentcodes/{schoolClassId}',
            null,
            { schoolId, schoolClassId }
        );
    }
    generateStudentsCode(params: { schoolId: string; count: number }, request: { excludeCodes: number[] } ): Promise<number[]> {
        const { schoolId, count } = params;
        return this.create('~admin/v1/students/{schoolId}/studentcodes/{count}',
            request,
            { schoolId, count }
        );
    }
    validateStudentCodes(params: { schoolId: string }, request: { studentId: string, studentCode: number }[]): Promise<StudentCodeValidateModel[]> {
        return this.create("~admin/v1/students/studentcodes/{schoolId}/validate", request, params);
    }
    saveStudentCodes(params: { schoolId: string }, data: SaveStudentCodeRequestModel[]) {
        return this.update("~admin/v1/students/{schoolId}/studentcodes", data, params);
    }
    getTeacherAssociations(params) {
        return this.get("~admin/v1/schools/{schoolId}/classes/teacher/{teacherId}", params);
    }
    updateTeacherAssociations(params, data: UpdateSchoolTeacherClassAssociationModel) {
        return this.update("~admin/v1/schools/{schoolId}/classes/teacher/{teacherId}", data, params);
    }
    getUnitStartDate(schoolId, id, currentUnit) {
        return this.get(this.unitStartDateUrl, null, { schoolId, id, currentUnit });
    }
}
