import { Message, Link, LinkParams } from '@app/service/coach/chat';
import { createNewMessage, MESSAGE_SENDER } from '@app/service/coach/chat';

export interface ChatMessagesState {
    messages: (Message | Link)[];
    badgeCount: number;
}

export default {
    namespace: "chatMessage",
    state: {
        messages: [],
        badgeCount: {} 
    },
    reducers: {
        addUserMessage(state, { payload }) {
            return { 
                ...state, 
                messages: [ 
                    ...state.messages,
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.CLIENT, payload.from, payload.isSeries, payload.sendAt)
                ]
            };
        },
        addResponseMessage(state, { payload }) {
            return { 
                ...state, 
                messages: [
                    ...state.messages,
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.RESPONSE, payload.from, payload.isSeries, payload.sendAt)
                ], 
            };
        },
        unShiftUserMessage(state, { payload }) {
            return { 
                ...state, 
                messages: [ 
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.CLIENT, payload.from, payload.isSeries, payload.sendAt),
                    ...state.messages
                ]
            };
        },
        unShiftResponseMessage(state, { payload }) {
            return { 
                ...state, 
                messages: [
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.RESPONSE, payload.from, payload.isSeries, payload.sendAt),
                    ...state.messages
                ], 
            };
        },
        concatUserMessage(state, { payload }) {
            return { 
                ...state,
                messages: [ 
                    ...state.messages,
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.CLIENT, payload.from, payload.isSeries, payload.sendAt),
                ]
            };
        },
        concatResponseMessage(state, { payload }) {
            console.log(payload);
            return { 
                ...state,
                messages: [
                    ...state.messages,
                    createNewMessage(payload.id, payload.text, MESSAGE_SENDER.RESPONSE, payload.from, payload.isSeries, payload.sendAt),
                ], 
            };
        },
        dropMessages(state) {
            return {
                ...state,
                messages: []
            };
        },
        setBadgeCount(state, { payload }) {
            return {
                ...state,
                badgeCount: payload.count
            };
        },
        markAllMessagesRead(state) {
            return {
                ...state,
                messages: state.messages.map(message => ({
                    ...message,
                    unread: false
                })),
            };
        },
        resetMessages() {
            return {
                messages: [],
            };
        }

    },
    effects: {},
    services: {}
}
export function toggleChat() {
    return { type: "chatMessage/toggleChat" };
}
export function toggleMessageLoader() {
    return { type: "chatMessage/toggleMessageLoader" };
}
export function addUserMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/addUserMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function addResponseMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/addResponseMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function unShiftUserMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/unShiftUserMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function unShiftResponseMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/unShiftResponseMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function concatUserMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/concatUserMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function concatResponseMessage(id: string, text: string, from: string, isSeries: boolean, sendAt?: Date) {
    return { type: "chatMessage/concatResponseMessage", payload: { id, text, from, isSeries, sendAt } };
}
export function addLinkSnippet(link: LinkParams, id?: string) {
    return { type: "chatMessage/addLinkSnippet", payload: { link, id } };
}
export function dropMessages() {
    return { type: "chatMessage/dropMessages" };
}
export function setBadgeCount(count: any) {
    return { type: "chatMessage/setBadgeCount", payload: { count } };
}
export function markAllMessagesRead() {
    return { type: "chatMessage/markAllMessagesRead" };
}
export function resetMessages() {
    return { type: "chatMessage/resetMessages" };
}