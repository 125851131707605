import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { Icon, Spin, Collapse, Table } from "antd-min";
import { GLForm, GLFormProps, GLUtil } from "gl-commonui";
import { SurveyQuestions } from "../component/survey-questions";
import { SaveCancelBtns } from "@app/components";
import { SchoolLocale } from "@app/locales/localeid";
import { clearErrors } from "@app/util/coach/fun";
import {
    ClassTimeModel,
    IVisitationService,
    TeacherRelatedModel,
    VisitationTeacherSchoolClassModel
} from "@app/service/coach/visitation";
import {
    fmtMsg,
    lazyInject,
    TYPES,
    ContextHelper,
    DateHelper,
    isQuestionEmpty,
    WeekdayAbbr4BackendObj
} from "@app/util";
import {
    VisitationTeacherStage,
    PreVisitationFormStatus,
    SurveyQuestionType,
    ResponseStatusTypeEnum,
    SurveyTodoType,
    VisitationType
} from "@app/util/coach/enum";
import { renderQuestionList } from "@app/util/coach/common";
import { Row } from "gl-commonui/lib/antd-min";
import { Link } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import "../component/progress.less";

interface PrevisitationFormProps extends GLFormProps {
    visitation: TeacherRelatedModel;
    callback: (stage) => void;
    teacherStage: VisitationTeacherStage;
}
interface PrevisitationFormState {
    visible?: boolean;
    spinning?: boolean;
    fillDate?: string;
    isIgnored?: boolean;
    lastFeedback?: any[];
    preVisitationForm?: any;
    showComingSoon?: boolean;
    showRespondBtn?: boolean;
    todoSurveyResponse?: any;
    submitDate?: Date;
}

@GLForm.create()
export class PrevisitationForm extends Component<
    PrevisitationFormProps,
    PrevisitationFormState
> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    userId = ContextHelper.getUserLoginInfo().profile.sub;
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            spinning: false,
            isIgnored: false,
            lastFeedback: [],
            showComingSoon: false,
            showRespondBtn: false,
            preVisitationForm: {},
            todoSurveyResponse: {}
        };
    }
    componentWillMount() {
        this.getTeacherPrevisitationForm();
    }
    showDrawer = () => {
        this.setState({ visible: true });
    };
    onClose = () => {
        this.setState({ visible: false });
    };
    getTeacherPrevisitationForm() {
        const {
            teacherStage,
            visitation: {
                associatedInfo: { preVisitationForm }
            }
        } = this.props;
        const isCurrentStage =
            teacherStage == VisitationTeacherStage.PrevisitationForm;
        if (preVisitationForm.length) {
            const preForm = preVisitationForm.find(
                v => v.teacherId == this.userId
            );
            const { status, includeLastFeedback } = preForm;
            includeLastFeedback && this.getLastFeedback();
            switch (status) {
                case PreVisitationFormStatus.Initialized:
                    this.setState({ showComingSoon: isCurrentStage });
                    return;
                case PreVisitationFormStatus.Pending:
                    this.setState({
                        preVisitationForm: preForm,
                        showRespondBtn: isCurrentStage
                    });
                    isCurrentStage &&
                        this.viewPrevisitationFormResponse(preForm);
                    return;
                case PreVisitationFormStatus.IgnoreInitialized:
                case PreVisitationFormStatus.IgnorePending:
                    this.setState({ isIgnored: true });
                    return;
                case PreVisitationFormStatus.Completed:
                    this.viewPrevisitationFormResponse(preForm);
                    return;
            }
        } else {
            this.setState({ showComingSoon: isCurrentStage });
        }
    }
    viewPrevisitationFormResponse(preForm) {
        const { surveyInstanceId } = preForm;
        const {
            visitation: { id }
        } = this.props;
        const params = {
            surveyInstanceId,
            visitationId: id,
            type: SurveyTodoType.ReadOnlyShared
        };
        this.setState({ spinning: true });
        this.visitationservice
            .viewSurveyReponse(params)
            .then(data => {
                this.setState({
                    spinning: false,
                    showRespondBtn: true,
                    preVisitationForm: preForm,
                    todoSurveyResponse: data ? data : {}
                });
            })
            .catch(() => {
                this.setState({ spinning: false });
            });
    }
    getLastFeedback() {
        const { visitation } = this.props;
        const params = {
            visitationId: visitation.id,
            teacherId: this.userId
        };
        this.visitationservice
            .getPrevisitationLastFeedback(params)
            .then(data => {
                data &&
                    this.setState({
                        fillDate:
                            data &&
                            data.fillDate &&
                            DateHelper.formatDate(data.fillDate, "YYYY/MM/DD"),
                        lastFeedback: this.formatLastFeedback(data)
                    });
            });
    }
    formatLastFeedback(data) {
        return (
            data &&
            data.surveyQuestion.map(q => {
                return {
                    id: q.id,
                    question: q.question,
                    surveyInstanceId: q.surveyInstanceId,
                    surveyResponseAnswer: q.surveyResponseAnswer
                };
            })
        );
    }
    drawerContent() {
        const {
            todoSurveyResponse: {
                surveyQuestion,
                responseStatus,
                surveyInstanceId
            },
            showRespondBtn
        } = this.state;
        const disabled = responseStatus == ResponseStatusTypeEnum.Submitted;
        const { form } = this.props;
        const formClassName = {
            "send-previsitation-form": true,
            "read-only": disabled
        };
        const renderNodeId = surveyQuestion ? surveyQuestion[0].id : null;
        return (
            <div className={classNames(formClassName)}>
                <GLForm form={form}>
                    {surveyQuestion && (
                        <SurveyQuestions
                            form={form}
                            isDisabled={disabled}
                            surveyQuestions={surveyQuestion}
                            surveyInstanceId={surveyInstanceId}
                            renderNodeUnderQue={[
                                {
                                    qId: renderNodeId,
                                    node: this.renderTeacherClasses()
                                }
                            ]}
                        />
                    )}
                    {!disabled && showRespondBtn && (
                        <SaveCancelBtns
                            saveTitle={SchoolLocale.VisitationResponseText}
                            onSubmit={() => this.fillPrevisitationForm(false)}
                            onCancel={() => this.fillPrevisitationForm(true)}
                            cancelTitle={SchoolLocale.VisitationSaveDraft}
                        />
                    )}
                </GLForm>
            </div>
        );
    }
    formatQuestionAnswer(values, formatSurveyQuestions) {
        const entries = Object.entries(values);
        return [...entries].map((v, k) => {
            const questionId = v[0].slice(9);
            const question =
                formatSurveyQuestions &&
                formatSurveyQuestions.find(
                    q => q.surveyQuestionId == questionId
                );
            return {
                id: question && question.id,
                surveyResponseId: question && question.surveyResponseId,
                answer: v[1],
                surveyQuestionId: questionId,
                surveyQuestionTypeId: SurveyQuestionType.Text
            };
        });
    }
    fillPrevisitationForm(saveAsDraft = false) {
        const { form } = this.props;
        const { todoSurveyResponse } = this.state;
        if (saveAsDraft) {
            clearErrors(form);
            this.updatePrevisitation(saveAsDraft, form.getFieldsValue());
        } else {
            form.validateFields((error, values) => {
                const optionalQuestions =
                    todoSurveyResponse &&
                    Array.isArray(todoSurveyResponse.surveyQuestion)
                        ? todoSurveyResponse.surveyQuestion
                              .filter(f => !f.required)
                              .map(m => m.id)
                        : [];
                if (error || isQuestionEmpty(form, values, optionalQuestions))
                    return;
                this.updatePrevisitation(saveAsDraft, values);
            });
        }
    }
    updatePrevisitation(saveAsDraft, values) {
        const { visitation, callback } = this.props;
        const {
            todoSurveyResponse,
            preVisitationForm: { id, surveyAnswerModel }
        } = this.state;
        const userId = ContextHelper.getUserLoginInfo().profile.sub;

        const status = saveAsDraft
            ? PreVisitationFormStatus.Pending
            : PreVisitationFormStatus.Completed;
        const responseStatus = saveAsDraft
            ? ResponseStatusTypeEnum.Draft
            : ResponseStatusTypeEnum.Submitted;
        const params = {
            visitationId: visitation.id,
            changePreVisitationStatusRequestModel: {
                previsitationFormId: id,
                visitationId: visitation.id,
                teacherId: userId,
                status: status,
                visitationType: visitation.type
            },
            todoSurveyRequestModel: {
                id: surveyAnswerModel && surveyAnswerModel.id,
                responseStatus: responseStatus,
                surveyInstanceId: todoSurveyResponse.surveyInstanceId,
                surveyResponseAnswer: this.formatQuestionAnswer(
                    values,
                    surveyAnswerModel && surveyAnswerModel.surveyResponseAnswer
                )
            }
        };
        this.visitationservice.updateTeacherPrevisitation(params).then(data => {
            const infos = {
                ...this.state.preVisitationForm,
                status,
                surveyAnswerModel: data
            };
            this.setState({
                visible: false,
                preVisitationForm: infos,
                todoSurveyResponse: { ...todoSurveyResponse, responseStatus }
            });
            callback(
                visitation.type == VisitationType.OnSite
                    ? VisitationTeacherStage.ViewCoachFeedback
                    : VisitationTeacherStage.VideoManager
            );
        });
    }

    renderPreviousVisitation = (
        lastFeedback: any[],
        className: string,
        fillDate: string,
        note: string
    ) => {
        const Panel = Collapse.Panel;
        const lastVisitHeader = `${fmtMsg({
            id: SchoolLocale.VisitationLastFeedback
        })} (${DateHelper.toLocalStringFromUTC(fillDate)})`;
        return (
            <Collapse>
                <Panel header={lastVisitHeader} key="1">
                    {renderQuestionList(lastFeedback, className)}
                </Panel>
                {note && (
                    <Panel
                        header={fmtMsg({
                            id: SchoolLocale.VisitationMessageFromCoach
                        })}
                        key="2"
                    >
                        {note}
                    </Panel>
                )}
            </Collapse>
        );
    };

    getWeekDay = (day: number) => {
        return typeof day === "number" && day > 0 && day < 8
                    ? WeekdayAbbr4BackendObj[day]
                    : "";
    }

    formatClassTime = (classTime: ClassTimeModel[]) => {
        const removeMilisec = (time: string) =>
            moment(time, "HH:mm").format("HH:mm");
        if (Array.isArray(classTime)) {
            return classTime
                .map(m => {
                    if (m.start && m.end) {
                        return `${removeMilisec(m.start)}~${removeMilisec(m.end)} (${this.getWeekDay(m.daysOfWeek)})`
                    } else if (m.start) {
                        return `${removeMilisec(m.start)}~NA  (${this.getWeekDay(m.daysOfWeek)})`
                    } else if (m.end) {
                        return `NA~${removeMilisec(m.end)} (${this.getWeekDay(m.daysOfWeek)})`
                    } else {
                        return this.getWeekDay(m.daysOfWeek);
                    }
                })
                .join(", ");
        }
        return "";
    };

    renderTeacherClasses = () => {
        const {
            visitation: {
                associatedInfo: { visitationTeacherSchoolClass },
                regionId,
                schoolId,
                campusId
            },
        } = this.props;
        const { todoSurveyResponse } = this.state;
        const surveyQuestion = JSON.parse(
            JSON.stringify(todoSurveyResponse.surveyQuestion)
        );

        const columns = [
            {
                title: "",
                dataIndex: "name",
                key: "select",
                width: 100,
                render: (
                    text: string,
                    record: VisitationTeacherSchoolClassModel,
                    index: number
                ) => {
                    return (
                        <a
                            onClick={() => {
                                if (
                                    Array.isArray(surveyQuestion) &&
                                    surveyQuestion.length
                                ) {
                                    const valueToAppend = `${record.name || '?'}`
                                    + ` | Age: ${record.age || '?'}`
                                    + ` | Unit: ${record.currentUnit || '?'}`
                                    + ` | Students: ${record.studentCount || '?'}`
                                    + ` | Class Time: ${this.formatClassTime(record.classTime) || '?'}`
                                    + ` | Lesson Number: ${record.lessonNumber || '?'}`;
                                    const questionId = "question-" + surveyQuestion[0].id;
                                    const oldValue = this.props.form.getFieldValue(questionId);

                                    const newValue =
                                        oldValue && oldValue.length
                                            ? oldValue + "<br>" + valueToAppend
                                            : valueToAppend;

                                    const answerLength =
                                        surveyQuestion[0].surveyResponseAnswer
                                            .length;
                                    if (answerLength) {
                                        surveyQuestion[0].surveyResponseAnswer[
                                            answerLength - 1
                                        ].answer = newValue;
                                    } else {
                                        surveyQuestion[0].surveyResponseAnswer.push(
                                            { answer: newValue }
                                        );
                                    }
                                    this.props.form.setFieldsValue({
                                        [questionId]: newValue
                                    });

                                    this.setState({
                                        todoSurveyResponse: {
                                            ...todoSurveyResponse,
                                            surveyQuestion: surveyQuestion
                                        }
                                    });
                                }
                            }}
                        >
                            {fmtMsg({
                                id: SchoolLocale.VisitationTeacherClassSelect
                            })}
                        </a>
                    );
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeacherClassName }),
                dataIndex: "name",
                key: "name",
                render: (
                    text: string,
                    record: VisitationTeacherSchoolClassModel,
                    index: number
                ) => {
                    const {id: classId, regionId, schoolId, campusId} = record;
                    if (regionId && schoolId && campusId && classId) {
                        const path = GLUtil.pathStringify(PathConfig.Students, {
                            regionId,
                            schoolId,
                            campusId,
                            classId
                        });
                        return <Link to={path}>{text}</Link>
                    }
                    return text;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeacherClassAge }),
                dataIndex: "age",
                key: "age",
                width: 100
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeacherClassUnit }),
                dataIndex: "currentUnit",
                key: "currentUnit",
                width: 60
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.VisitationTeacherClassStudents
                }),
                dataIndex: "studentCount",
                key: "studentCount",
                width: 100
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.VisitationTeacherClassClassHours
                }),
                dataIndex: "classTime",
                key: "classTime",
                render: (
                    text: string,
                    record: VisitationTeacherSchoolClassModel,
                    index: number
                ) => {
                    return this.formatClassTime(record.classTime);
                }
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.VisitationTeacherClassLessonNumber
                }),
                dataIndex: "lessonNumber",
                key: "lessonNumber",
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={visitationTeacherSchoolClass}
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 3
                }}
            />
        );
    };

    render() {
        const {
            spinning,
            isIgnored,
            showComingSoon,
            fillDate,
            lastFeedback,
            showRespondBtn,
            preVisitationForm: { note },
            todoSurveyResponse: { responseStatus, submitDate }
        } = this.state;

        const formatSubmitDate = DateHelper.toLocalStringFromUTC(
            moment(submitDate)
        );

        const submitted = responseStatus == ResponseStatusTypeEnum.Submitted;

        return (
            <div
                className={`previsitation-form info-container ${showComingSoon &&
                    "disabled"} ${submitted && "submitted"}`}
            >
                <Spin spinning={spinning}>
                    <div className="last-feedback">
                        {fillDate &&
                            this.renderPreviousVisitation(
                                lastFeedback,
                                "question-list-container",
                                fillDate,
                                note
                            )}
                        {showComingSoon && (
                            <div className="no-form-message">
                                <div>
                                    <Icon type="clock-circle" />
                                    {fmtMsg({
                                        id: SchoolLocale.VisitationInProgress
                                    })}
                                </div>
                            </div>
                        )}
                        {isIgnored && (
                            <div className="no-form-message">
                                {fmtMsg({
                                    id: SchoolLocale.VisitationNotSendPrevisit
                                })}
                            </div>
                        )}
                    </div>
                    {showRespondBtn && (
                        <>
                            <Row>
                                <h3>
                                    {fmtMsg({
                                        id:
                                            SchoolLocale.VisitationSendPrevisitationForm
                                    })}
                                </h3>
                            </Row>
                            <Row>
                                <h5>
                                    {fmtMsg({
                                        id:
                                            SchoolLocale.VisitationPrevisitationSurveySubmit
                                    })}
                                    : {formatSubmitDate}
                                </h5>
                            </Row>
                            <div>{this.drawerContent()}</div>
                        </>
                    )}
                </Spin>
            </div>
        );
    }
}
