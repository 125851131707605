import { StudentSubscriptionService, StudentSubscriptionModel } from "../../service/school/student-subscription";
import { GLGlobal } from "gl-commonui";
import { GSSchoolAction, ContextHelper } from "../../util";
import { StateType } from '../index';


export interface StudentSubscriptionState {
    loading: boolean
    dataSource: StudentSubscriptionModel[]
    total: number,
    requestCount: number
}

interface Services {
    studentSubscription: StudentSubscriptionService

}
export default {
    namespace: 'studentSubscription',
    state: {
        loading: true,
        dataSource: [],
        total: 0,
        requestCount: 0
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        }
    },
    effects: {
        *getSubscriptionRequestCount({ payload: { query } }, { call, put, select }, { studentSubscription }: Services) {
            const { data } = yield call(studentSubscription.getSubscriptionRequestCount, { query });
            yield put(reload({ requestCount: data.requestCount }));
        }
    },
    services: {
        studentSubscription: StudentSubscriptionService
    }
}

export function reload(state) {
    return { type: 'studentSubscription/reload', payload: state }
}
export function getSubscriptionRequestCount(state) {
    return { type: 'studentSubscription/getSubscriptionRequestCount', payload: state }
}