import { GLServiceBase, getServiceMethodKey, HttpMethod } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IResourceService } from './index';
import { SchoolLocale } from '@app/locales/localeid';
import { ServerExceptionCode as ExceptionCode } from '@app/util/coach/enum';

@injectable()
export class ResourceService extends GLServiceBase<any, any> implements IResourceService {
    serviceRoute = { prefix: 'admin/v1/resources', suffix: '{resourceId}' }
    removeUserResourcesWithRoleUrl = '{resourceId}/resouceconsumerswithrole/{consumerId}';
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Delete, ExceptionCode.CanNotRemoveCoachError, `${this.serviceRoute.prefix}/${this.removeUserResourcesWithRoleUrl}`)] = SchoolLocale.VisitationCanNotRemoveCoach;
        return result;
    })();
    getBreadcrumbs(d) {
        return this.get('breadcrumbs', d);
    }
    getIdsBy(type, id) {
        return this.get('{resourceId}/userIds', type, id);
    }

    getUserIdsBy(params) {
        return this.get('userIds', params);
    }

    getResources(params) {
        return this.get('resources', params);
    }

    getRegionCoach(params) {
        return this.get('regioncoach', params);
    }
    
    removeBy(data, consumerId) {
        return this.delete('consumers/{consumerId}', data, { consumerId });
    }

    removeUserResources(data, resourceId, consumerId ) {
        return this.delete('{resourceId}/resourceconsumers/{consumerId}', data, { resourceId, consumerId });
    }

    removeUserResourcesWithRole(data, resourceId, consumerId ) {
        return this.delete(this.removeUserResourcesWithRoleUrl, data, { resourceId, consumerId });
    }
    
    canCompletelyRemove(parmas) {
        return this.get("{regionId}/cancompletelyremove/{trainerId}", parmas);
    }
}