import * as React from 'react'
import { Component } from 'react';
import { Form, InputNumber, message, Modal, Select } from "antd-min";
import { guid } from 'inversify';
import { GLGlobal, GLForm, GLFormProps, FormHelper, FormItemsLayout } from 'gl-commonui';
import { GSAdminLocale } from '@app/locales/localeid';
import { CommonHelper, fmtMsg, LITTLE_SEED_MAX_UNIT, MIN_UNIT, isGuid } from '@app/util/index';
import { LocalProductUnitPrice, LocalProductUnitPriceProps } from '@app/service/admin/products/model';
import { SubmitBtns } from '@app/components';
import _ from "lodash";

interface UnitPriceEditProps extends GLFormProps {
    visible: boolean
    startUnit?: number
    endUnit?: number
    maxUnit?: number
    minUnit?: number
    unitPrices: LocalProductUnitPrice[]
    unitPrice: LocalProductUnitPrice
    onSave?: (unitPrice: LocalProductUnitPrice) => void
    onCancel?: () => void
}

interface UnitPriceEditStates {
    startUnitOptions?: JSX.Element[] | null
    endUnitOptions?: JSX.Element[] | null
    id: string
    isAddNew: boolean
    isButtonDisable: boolean
}

@GLForm.create()
export class UnitPriceEdit extends React.Component<UnitPriceEditProps, UnitPriceEditStates> {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        const id = props.unitPrice ? props.unitPrice.id : null;
        const isAddNew = !isGuid(id);
        const { minUnit, maxUnit, unitPrice } = props;
        const { startUnit, endUnit } = unitPrice;
        let activeButton = false;
        activeButton = Math.abs(minUnit) > Math.abs(startUnit) || Math.abs(maxUnit) < Math.abs(endUnit) || Math.abs(minUnit) > Math.abs(endUnit) || Math.abs(maxUnit) < Math.abs(startUnit);
        this.state = {
            id: id,
            isAddNew: isAddNew,
            isButtonDisable: !(!isAddNew && activeButton)
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.visible) {
            const { startUnit, endUnit, maxUnit, minUnit } = nextProps;
            const maxUnitN = maxUnit ? (typeof maxUnit == 'string' ? parseInt(maxUnit, 10) : maxUnit) : null;
            let startOptions, endOptions;
            startOptions = endOptions = [];
            const { isAddNew } = prevState;
            if (maxUnitN) {
                if (isAddNew) {
                    const { startUnitOptions, endUnitOptions } = setUnitOptions(startUnit, endUnit);
                    startOptions = startUnitOptions;
                    endOptions = endUnitOptions;
                } else {
                    const { startUnitOptions, endUnitOptions } = setUnitOptions(minUnit, maxUnit);
                    startOptions = startUnitOptions;
                    endOptions = endUnitOptions;
                }
            }

            return {
                ...prevState,
                startUnitOptions: startOptions,
                endUnitOptions: endOptions
            };
        }
        return null;
    }

    getStartEndUnitsForValidation(startUnit, endUnit) {
        let result = [];
        if (startUnit < 0 && endUnit < 0) {
            result = result.concat(CommonHelper.getRangeArray(endUnit, startUnit));
        }
        else if (startUnit > 0 && endUnit > 0) {
            result = result.concat(CommonHelper.getRangeArray(startUnit, endUnit));
        }
        else if (startUnit < 0 && endUnit > 0) {
            result = result.concat(CommonHelper.getRangeArray(-1 * LITTLE_SEED_MAX_UNIT, startUnit));
            result = result.concat(CommonHelper.getRangeArray(MIN_UNIT, endUnit));
        }

        return result;
    }

    getExistingUnits() {
        const { unitPrices } = this.props;
        if (!unitPrices || unitPrices.length == 0) return [];
        let result = [];
        unitPrices.forEach(unitPrice => {
            result = result.concat(this.getStartEndUnitsForValidation(unitPrice.startUnit, unitPrice.endUnit));
        });
        return result;
    }

    onValidateUnitPrice() {
        const { form, maxUnit } = this.props;
        let unitPrice = null;
        const validateFields = maxUnit ? [LocalProductUnitPriceProps.startUnit, LocalProductUnitPriceProps.endUnit, LocalProductUnitPriceProps.price]
            : [LocalProductUnitPriceProps.price];
        form.validateFields(validateFields, (err, values) => {
            if (!err && !this.unitPriceIsDuplicate(values.startUnit, values.endUnit)) {
                unitPrice = values;
            }
        });
        return unitPrice;
    }

    onValidateUnitPriceEdit() {
        const { form, maxUnit } = this.props;
        let unitPrice = null;
        const validateFields = maxUnit ? [LocalProductUnitPriceProps.startUnit, LocalProductUnitPriceProps.endUnit, LocalProductUnitPriceProps.price]
            : [LocalProductUnitPriceProps.price];
        form.validateFields(validateFields, (err, values) => {
            if (err) return;
            const isDuplicateUnitRange = this.isDuplicatedUnitRange({
                startUnit: values.startUnit,
                endUnit: values.endUnit
            })

            if (isDuplicateUnitRange) {
                message.error(fmtMsg({ id: GSAdminLocale.LocalProductPriceDuplicateMessage }));
                return;
            }

            unitPrice = values;
        });
        return unitPrice;
    }

    isDuplicatedUnitRange(newUnitPrice: LocalProductUnitPrice) {
        const { unitPrices } = this.props;
        const { id, isAddNew } = this.state;

        let filteredUnitPrices: LocalProductUnitPrice[] = [];
        if (isAddNew) {
            filteredUnitPrices = unitPrices;
        } else {
            filteredUnitPrices = _.filter(unitPrices, item => item.id !== id);
        }
        const isDuplicateUnitRange = _.some(filteredUnitPrices, unitPrice =>
            (Math.abs(unitPrice.startUnit) <= Math.abs(newUnitPrice.startUnit) && Math.abs(unitPrice.endUnit) >= Math.abs(newUnitPrice.startUnit)) ||
            (Math.abs(unitPrice.startUnit) <= Math.abs(newUnitPrice.endUnit) && Math.abs(unitPrice.endUnit) >= Math.abs(newUnitPrice.endUnit)) ||
            (Math.abs(unitPrice.startUnit) >= Math.abs(newUnitPrice.startUnit) && Math.abs(unitPrice.endUnit) <= Math.abs(newUnitPrice.endUnit))
        );

        return isDuplicateUnitRange;
    }

    unitPriceIsDuplicate(newStartUnit, newEndUnit) {
        const currentUnits = this.getExistingUnits();
        const newUnits = this.getStartEndUnitsForValidation(newStartUnit, newEndUnit);
        const result = newUnits && currentUnits.filter(unit => newUnits.indexOf(unit) != -1).length > 0;
        if (result) message.error(fmtMsg({ id: GSAdminLocale.LocalProductPriceDuplicateMessage }));
        return result;
    }

    onSave(e) {
        e.preventDefault();
        const { minUnit, maxUnit } = this.props;
        const { isAddNew, id, isButtonDisable } = this.state;
        let unitPrice;
        if (isAddNew) {
            unitPrice = this.onValidateUnitPrice();
        } else {
            unitPrice = this.onValidateUnitPriceEdit();
        }
        if (!unitPrice) return;
        unitPrice.id = isAddNew ? guid() : id;
        const { startUnit, endUnit } = unitPrice;
        if ((Math.abs(minUnit) > Math.abs(startUnit)) && !isButtonDisable) {
            unitPrice.startUnit = minUnit;
        }
        if ((Math.abs(minUnit) > Math.abs(endUnit)) && !isButtonDisable) {
            unitPrice.endUnit = minUnit;
        }
        if ((Math.abs(maxUnit) < Math.abs(endUnit)) && !isButtonDisable) {
            unitPrice.endUnit = maxUnit;
        }
        if ((Math.abs(maxUnit) < Math.abs(startUnit)) && !isButtonDisable) {
            unitPrice.startUnit = maxUnit;
        }
        const { onSave } = this.props;
        if (onSave) { onSave(unitPrice) }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel) { onCancel() };
    }

    onFieldChanged = () => {
        if (!this.state.isButtonDisable) return;
        this.setState({
            isButtonDisable: false
        })
    }

    render() {
        const { form, startUnit, endUnit, maxUnit, unitPrice, minUnit } = this.props;
        const price = unitPrice ? unitPrice.price : null;
        if (Math.abs(minUnit) > Math.abs(unitPrice.startUnit)) {
            unitPrice.startUnit = minUnit;
        }
        if (Math.abs(minUnit) > Math.abs(unitPrice.endUnit)) {
            unitPrice.endUnit = minUnit;
        }
        if (Math.abs(maxUnit) < Math.abs(unitPrice.endUnit)) {
            unitPrice.endUnit = maxUnit;
        }
        if (Math.abs(maxUnit) < Math.abs(unitPrice.startUnit)) {
            unitPrice.startUnit = maxUnit;
        }
        const startUnitInitialValue = (this.state.startUnitOptions && this.state.startUnitOptions.length == 0) ? null : startUnit;
        const endUnitInitialValue = (this.state.endUnitOptions && this.state.endUnitOptions.length == 0) ? null : endUnit;
        const { renderFormItem } = FormHelper;
        const { isAddNew, isButtonDisable } = this.state;
        const modalTitle = isAddNew ? GSAdminLocale.ProductUnitAddPrice : GSAdminLocale.ProductUnitEditPrice;
        const saveButtonTitle = isAddNew ? GSAdminLocale.ProductAdd : GSAdminLocale.ProductEditButton
        return (
            <Modal
                title={fmtMsg({ id: modalTitle })}
                closable={false}
                footer={null}
                className={'localproduct-modal'}
                visible={this.props.visible}
                destroyOnClose={true}
            >
                <GLForm form={form} onSubmit={this.onSave}>
                    <FormItemsLayout colTotal={1}>
                        {maxUnit && renderFormItem(form, GSAdminLocale.ProductStartUnit, LocalProductUnitPriceProps.startUnit,
                            <Select size="large" onChange={() => this.onFieldChanged()}>{this.state.startUnitOptions}</Select>, isAddNew ? startUnitInitialValue : unitPrice.startUnit, maxUnit != null, maxUnit && unitPriceValidator(form, GSAdminLocale.LocalProductPriceStartUnitErrorMessage))}
                        {maxUnit && renderFormItem(form, GSAdminLocale.ProductEndUnit, LocalProductUnitPriceProps.endUnit,
                            <Select size="large" onChange={() => this.onFieldChanged()}>{this.state.endUnitOptions}</Select>, isAddNew ? endUnitInitialValue : unitPrice.endUnit, maxUnit != null, maxUnit && unitPriceValidator(form, GSAdminLocale.LocalProductPriceEndUnitErrorMessage))}
                        {renderFormItem(form, GSAdminLocale.LocalProductPrice, LocalProductUnitPriceProps.price,
                            <InputNumber size="large" onChange={() => this.onFieldChanged()} min={0} max={999999999} precision={2} maxLength={9} />, price, true)}
                    </FormItemsLayout>
                    <Form.Item>
                        <SubmitBtns submitTitle={saveButtonTitle} onCancel={this.onCancel} isDisabled={isButtonDisable} />
                    </Form.Item>
                </GLForm>
            </Modal>
        )
    }
}

export function unitPriceValidator(form, errorMesageId) {
    return [{
        validator: (rule, value, callback) => {
            const { startUnit, endUnit } = form.getFieldsValue([LocalProductUnitPriceProps.startUnit, LocalProductUnitPriceProps.endUnit]) as any;

            if (startUnit && endUnit && startUnit < 0 && endUnit < 0 && Math.abs(startUnit) > Math.abs(endUnit)) {
                callback(fmtMsg({ id: errorMesageId }));
            }
            else if (startUnit && endUnit && ((startUnit > 0 && endUnit > 0) ||
                (startUnit > 0 && endUnit < 0) || (startUnit < 0 && endUnit > 0))
                && startUnit > endUnit) {
                callback(fmtMsg({ id: errorMesageId }));
            }
            else {
                const { startUnit: startErr, endUnit: endErr } = form.getFieldsError([LocalProductUnitPriceProps.startUnit, LocalProductUnitPriceProps.endUnit]) as any;
                if (startErr || endErr) {
                    form.setFieldsValue({ startUnit, endUnit });
                }
            }
            callback();
        }
    }];
}

function setUnitOptions(minUnit: number, maxUnit: number) {

    let startUnitOptions = [];
    let endUnitOptions = [];

    if (minUnit && maxUnit && minUnit < 0 && maxUnit < 0 && Math.abs(minUnit) > Math.abs(maxUnit)) {
        return { startUnitOptions, endUnitOptions };
    }
    else if (minUnit && maxUnit && ((minUnit > 0 && maxUnit > 0) ||
        (minUnit > 0 && maxUnit < 0) || (minUnit < 0 && maxUnit > 0))
        && minUnit > maxUnit) {
        return { startUnitOptions, endUnitOptions };
    }

    if (minUnit < 0 && maxUnit < 0) {
        startUnitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), Math.abs(maxUnit), true, "unitpricestartunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), Math.abs(maxUnit), true, "unitpriceendunit"));
    }
    else if (minUnit > 0 && maxUnit > 0) {
        startUnitOptions.push(getUnitOptionsLocalProduct(minUnit, maxUnit, false, "unitpricestartunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(minUnit, maxUnit, false, "unitpriceendunit"));
    }
    else if (minUnit < 0 && maxUnit > 0) {
        startUnitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), LITTLE_SEED_MAX_UNIT, true, "unitpricestartunit"));
        startUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, maxUnit, false, "unitpricestartunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), LITTLE_SEED_MAX_UNIT, true, "unitpriceendunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, maxUnit, false, "unitpriceendunit"));
    }

    return { startUnitOptions, endUnitOptions };
}

export function getUnitOptionsLocalProduct(minUnit: number, maxUnit: number, isLittleSeed: boolean, keyAppendValue: string) {
    let appendToKey = isLittleSeed ? "ls" : "gs";
    appendToKey = appendToKey + keyAppendValue;
    return [...Array(maxUnit).keys()].filter((unit) => unit >= minUnit - 1).map((unitKey, index) => {
        return CommonHelper.getOption(index + 1 + appendToKey, getUnitValueForLocalProduct(unitKey + 1, isLittleSeed), getUnitTextForLocalProduct(unitKey + 1, isLittleSeed))
    });
}
function getUnitValueForLocalProduct(unit: number, isLittleSeed: boolean) {
    return isLittleSeed ? -unit : unit;
}

export function getUnitTextForLocalProduct(unit: number, isLittleSeed: boolean) {
    return isLittleSeed ? `LS ${unit}` : `GS ${unit}`;
}
