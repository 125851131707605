import React, { Component } from 'react';
import { GLUtil, GLGlobal, withRouter, PathConfig as CommonPath } from 'gl-commonui';
import { MainTitle } from '@app/components/school/school-page-title';
import { Progress } from './component/progress';
import { lazyInject, TYPES, fmtMsg, ContextHelper, DateHelper, GSAdminAction } from '@app/util';
import { IVisitationService, TeacherRelatedModel } from '@app/service/coach/visitation';
import { SchoolPathConfig, PathConfig } from '@app/config/pathconfig';
import { VisitationType, VisitationTeacherStage, ServerExceptionCode } from '@app/util/coach/enum';
import { Row, Col, Icon, Badge } from 'antd-min';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { ICampusService } from '@app/service/school/campus';
import { ISchoolService } from '@app/service/schools';
import { ChatDrawer } from '@app/components/coach/chat/chat-drawer';
import { IAccountService } from '@app/service/admin/accountservice';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ChatBadge } from '@app/components/coach/chat/chat-badge';
import { IChatService, MESSAGE_REFRESH_TIME } from '@app/service/chat';
import { TeacherTabs } from './teacher/teacher-tabs';
import "./teacher-visit.less";
import { VisitProgress } from './component/progress-new';

interface TeacherVisitationProps extends RouteComponentProps<any> {}
interface TeacherVisitationState {
    selectedStage?: VisitationTeacherStage
    teacherStage?: VisitationTeacherStage
    teacherRelated?: TeacherRelatedModel
    coachName?: string
    schoolName?: string
    campusName?: string
    messageVisible?: boolean
    startMessage?: boolean
    badgeCount?: number
}
@withRouter
export class TeacherVisitation extends Component<TeacherVisitationProps, TeacherVisitationState>{
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    @lazyInject(TYPES.IChatService)
    chatservice: IChatService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    id = GLUtil.pathParse(SchoolPathConfig.TeacherVisitation).visitationId;
    constructor(props) {
        super(props);
        this.state = {
            startMessage: false
        }
    }

    flushMessages: any;
    componentWillMount(){
        this.checkAccessPermission().then((accessable) => {
            accessable && this.getTeacherRelatedById().then((data) => {
                data.type == VisitationType.OnSite ? this.getCampusName(data) : this.getSchoolName(data);
                const teacherStage = this.formatTeacherStage(data);
                this.getBadgeCount(data.coachId, data.id);
                this.setState({ 
                    teacherStage,
                    teacherRelated: data
                });

                this.accountservice.getUserById({id: data.coachId}).then(v => {
                    this.setState({
                        coachName: v.name
                    })
                });

                this.flushMessages = setInterval(() => this.getBadgeCount(data.coachId, data.id), MESSAGE_REFRESH_TIME);
            }).catch(({error_code}) => {
                if (error_code == ServerExceptionCode.NotExistedTeacherError) {
                    this.props.history.push({ pathname: CommonPath.AccessDenied });
                }
            });
        });
    }
    checkAccessPermission() {
        return this.visitationservice
            .checkTeacherAccessPermission({ visitationId: this.id })
            .then(accessable => {
                !accessable && this.props.history.push(CommonPath.AccessDenied);
                return accessable;
            });
    }
    componentWillUnmount() {
        clearInterval(this.flushMessages);
    }
    getCoachInfo(coachId) {
        return this.accountservice.getUserById({ id: coachId });
    }
    getSchoolName(data) {
        const { coachId, schoolId } = data;
        const actions = [this.getCoachInfo(coachId), this.schoolService.get({ id: schoolId })];
        Promise.all(actions).then(([coach, school]) => {
            this.setState({ 
                coachName: coach.name,
                schoolName: school.name,
            });
        });
    }
    getCampusName(data) {
        const { coachId, schoolId, campusId  } = data;
        const params = {
            schoolId,
            campusId
        }
        const actions = [this.getCoachInfo(coachId), this.campusService.get(params)];
        Promise.all(actions).then(([coach, campus]) => {
            this.setState({ 
                coachName: coach.name,
                campusName: campus.name,
            });
        });
    }
    getTeacherRelatedById() {
        return this.visitationservice.getTeacherRelatedById({ visitationId: this.id });
    }
    getBadgeCount(senderId, visitationId) {
        this.chatservice.getUnReadMessagesCount({ senderId, visitationId }).then(v => {
            this.setState({
                badgeCount: v
            });
        });
    }
    formatTeacherStage(teacherRelated) {
        const userId = ContextHelper.getUserLoginInfo().profile.sub;
        const { associatedInfo: { visitationTeacher }} = teacherRelated;
        const teacher = visitationTeacher.find(t => t.teacherId == userId);
        return teacher && teacher.stage;
    }
    tempCallback(stage) {
        this.getTeacherRelatedById().then((data) => {
            const teacherStage = this.formatTeacherStage(data);
            this.setState({ 
                teacherStage,
                selectedStage: null,
                teacherRelated: data,
            });
        }).catch(({error_code}) => {
            if (error_code == ServerExceptionCode.NotExistedTeacherError) {
                this.props.history.push({ pathname: CommonPath.AccessDenied });
            }
        });;
    }
    selectedStageCallback(stage) {
        this.setState({ 
            selectedStage: stage,
        });
    }
    showMessage() {
        this.setState({
            messageVisible: true,
            startMessage: true
        });
    }
    onClose() {
        this.setState({
            messageVisible: false
        });
    }
    render() {
        const { badgeCount } = this.state;
        const { teacherRelated, coachName, schoolName, campusName, teacherStage, selectedStage, messageVisible, startMessage } = this.state;
        const isOnSiteVisit = teacherRelated && teacherRelated.type == VisitationType.OnSite;
        const startDate = teacherRelated && DateHelper.formatDate(teacherRelated.startDate, "YYYY/MM/DD");
        const schoolUrl = teacherRelated && !isOnSiteVisit && {pathname: GLUtil.pathStringify(PathConfig.Schools, {regionId: teacherRelated.regionId, schoolId: teacherRelated.schoolId})};
        const campusUrl = isOnSiteVisit && {pathname: GLUtil.pathStringify(PathConfig.Classes, {regionId: teacherRelated.regionId, schoolId: teacherRelated.schoolId, campusId: teacherRelated.campusId})};
        return <div className="ongoing content-layout">
            <MainTitle plain={fmtMsg({ id: SchoolLocale.VisitationRequestVisitation })} />
            <div className="page-content">
                <Row className="info">
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationCoachName})}: 
                        {teacherRelated && <>
                            {GLGlobal.isActionValid(GSAdminAction.SchoolTrainerHome) ? 
                            <Link to={{ pathname: GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId: teacherRelated.regionId, schoolId: teacherRelated.schoolId, trainerId: teacherRelated.coachId }) }} >
                                <span className="coach-name">{coachName}</span>
                            </Link> : <span className="coach-name">{coachName}</span>}
                            {(teacherStage != VisitationTeacherStage.Completed && teacherStage != VisitationTeacherStage.CompletedByCoach) &&
                                <ChatBadge 
                                    count={badgeCount ? badgeCount : 0}
                                    label="ongoing-visit-chat"
                                    handleClick={() => this.showMessage()}
                                />
                            }
                        </>}
                    </Col>
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationDateOfVisit})}: {DateHelper.toLocalStringFromUTC(startDate)}</Col>
                </Row>
                <Row className="info">
                    {
                        teacherRelated && <>
                        {isOnSiteVisit ? 
                        <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationCampusText})}: 
                            <Link to={campusUrl}><span className="campus-name">{campusName}</span></Link><Icon type="environment" /></Col> :
                        <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.CoachSchool})}: 
                            <Link to={schoolUrl}><span className="school-name">{schoolName}</span></Link><Icon type="video-camera" /></Col>
                        }</>
                    }
                    <Col sm={24} xl={12}></Col>
                </Row>
                {teacherRelated && <div className="visitation">
                    <div className="teacher-visit__steps">
                        <VisitProgress
                            isCoach={false}
                            teacherStage={teacherStage}
                            visitation={teacherRelated}
                            selectedStage={selectedStage}
                            selectedStageCallback={(stage) => this.selectedStageCallback(stage)}
                        />
                    </div>
                    <TeacherTabs visitation={teacherRelated} 
                                teacherStage={teacherStage}
                                selectedStage={selectedStage}
                                callback={(stage) => this.tempCallback(stage)} />
                </div>}
                {
                    startMessage &&
                    teacherRelated &&
                    teacherRelated.coachId &&
                    <ChatDrawer 
                        title={fmtMsg({id: GSAdminLocale.ChatHeader}, {name: coachName})}
                        visitationId={teacherRelated.id}
                        receiver={{id: teacherRelated.coachId, name: coachName}}
                        visible={messageVisible}
                        onClose={() => this.onClose()}
                        searchable={false}
                        getBadgeCount={this.getBadgeCount.bind(this)}
                    />
                }
            </div>
        </div>;
    }
}