import React from "react";
import {
    connect,
    GLGlobal,
    withRouter,
    GLRouteComponentProps
} from "gl-commonui";
import { ISurveyService } from "@app/service/survey/surveys";
import { List, Card, Spin } from "antd-min";
import { TYPES } from "@app/util";
import { useService } from "@app/hooks";
import { StateType } from "@app/states";
import {
    toggleFormVisiblility,
    SurveyVisibleMode,
    SurveyFillState,
    setSurveyFillState
} from "@app/states/survey/survey";
import { SurveyLocale } from "@app/locales/localeid";
import "./survey-response-list.less";
import { SurveyInstanceResponseModel } from "@app/service/survey/surveys/models";
import moment from "moment";
import { Loading } from "@app/components/survey/loading/loading";

interface ResponseListProps extends GLRouteComponentProps {
    selectedSurvey: string;
    toggleFormVisiblility: (partialState: any) => void;
    setSurveyFillState: (surveyFillState: SurveyFillState) => void;
}

const ResponseList = (props: ResponseListProps) => {
    const service = useService<ISurveyService>(TYPES.ISurveyService);
    const [responseList, setresponseList] = React.useState(
        [] as SurveyInstanceResponseModel[]
    );
    const [loading, setLoading] = React.useState(true);
    const [isResponseLoading, setIsResponseLoading] = React.useState(true);
    const [totalResponses, setTotalResponses] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const limit = 10;

    const dueDateText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyResponseDueOnText
    });
    const submittedOnText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyResponseSubmittedOnText
    });
    const noResponsesText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyNoResponseMessage
    });

    const showMoreText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.CommonShowMoreText
    });
    const viewText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.CommonViewText
    });

    const responseHeaderText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyCommonResponses
    });
    React.useEffect(() => {
        if (isResponseLoading && (!responseList || responseList.length == 0)) {
            getSurveyUserResponse(true, 0);
        }
    }, [responseList]);

    const getSurveyUserResponse = (isGetCount = false, currentOffset) => {
        service
            .getSurveyResponses({
                surveyId: props.selectedSurvey,
                isGetTotal: isGetCount,
                offset: currentOffset,
                limit: limit
            })
            .then(result => {
                setresponseList(responseList.concat(result.surveyInstances));
                if (isGetCount) {
                    setTotalResponses(result.totalCount);
                }
                setIsResponseLoading(false);
                setLoading(false);
            })
            .catch(ex => {
                setIsResponseLoading(false);
                setLoading(false);
            });
    };

    const showMore = () => {
        const newOffset = offset + 1;
        setOffset(newOffset);
        setLoading(true);
        getSurveyUserResponse(false, newOffset);
    };

    const redirectToResponsePage = (instanceId, responseId) => {
        props.toggleFormVisiblility({
            surveyVisibleMode: SurveyVisibleMode.FillSurveyPage
        });

        props.setSurveyFillState({
            mySurveyTabSection : null,
            selectedSurveyInstanceId : instanceId,
            selectedSurveyResponseId : responseId
        });

    };

    return (
        <>
            <h2>{responseHeaderText}</h2>
            {!isResponseLoading && responseList.length == 0 ? (
                <div className="no-response">{noResponsesText}</div>
            ) : (
                    responseList.map(instance => {
                        return (
                            <div>
                                <Card
                                    title={
                                        instance.surveyInstanceName +
                                        " - " +
                                        dueDateText +
                                        " - " +
                                        moment(instance.dueDate).format("l")
                                    }
                                    className={"response-list-card"}
                                >
                                    <List
                                        size="large"
                                        dataSource={instance.surveyResponses}
                                        locale={{ emptyText: noResponsesText }}
                                        renderItem={item => (
                                            <List.Item
                                                actions={[
                                                    <a
                                                        onClick={ ()=>{
                                                            redirectToResponsePage(instance.instanceId, item.responseId) }
                                                        }
                                                    >
                                                        {viewText}
                                                    </a>
                                                ]}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <span>
                                                            {item.userName +
                                                                " - " +
                                                                submittedOnText +
                                                                " - " +
                                                                moment(
                                                                    item.submitDate
                                                                ).format("l") }
                                                        </span>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                                {responseList.length < totalResponses && (
                                    <div className="response-show-more">
                                        <a onClick={showMore}>{showMoreText}</a>
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            {<Loading visible={isResponseLoading || loading}></Loading>}
        </>
    );
};

export const SurveyResponseList = withRouter(
    connect(
        ({ survey: { selectedSurvey } }: StateType) => ({
            selectedSurvey
        }),
        {
            toggleFormVisiblility,
            setSurveyFillState
        }
    )(ResponseList)
);
