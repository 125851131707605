import { GLGlobal } from "gl-commonui";
import { IContextMenu, MenuOptions } from ".";
import { SurveyLocale, GSAdminLocale } from "@app/locales/localeid";

export class CommonMenuOptions {
    get editMenu(): IContextMenu {
        return {
            id: MenuOptions.EditId,
            text: GLGlobal.intl.formatMessage({ id: SurveyLocale.SurveyCommonEdit }),
            isVisible: true,
        };
    }

    get deleteMenu(): IContextMenu {
        return {
            id: MenuOptions.DeleteId,
            text: GLGlobal.intl.formatMessage({ id: GSAdminLocale.ButtonDelete }),
            isVisible: true,
        };
    }

    get archiveMenu(): IContextMenu {
        return {
            id: MenuOptions.ArchiveId,
            text: GLGlobal.intl.formatMessage({id: SurveyLocale.SurveyCommonArchive}),
            isVisible: true,
        }
    }

    get responseMenu(): IContextMenu {
        return {
            id: MenuOptions.ResponsesId,
            text: GLGlobal.intl.formatMessage({id: SurveyLocale.SurveyCommonResponses}),
            isVisible: true,
        }
    }
}