import React from 'react';
import { Icon } from 'antd-min';
import School from '@app/style/images/school.svg';
import Region from '@app/style/images/region.svg';
import User from '@app/style/images/user.svg';
import InvitationTemplate from '@app/style/images/invitationtemplate.svg';
import Rejct from '@app/style/images/reject.svg';
import Search from '@app/style/images/search.svg';
import './icon.less';
import QLetter from '@app/style/images/qLetter.svg';
import History from '@app/style/images/switcher.svg';
import ArrowUp from '@app/style/images/arrow-circle-up.svg';
import ArrowDown from '@app/style/images/arrow-circle-down.svg';
import ArrowRight from '@app/style/images/arrow-right.svg';
import Male from '@app/style/images/male.svg';
import Female from '@app/style/images/female.svg';
import SetUp from '@app/style/images/setup.svg';
import SchoolVisit from '@app/style/images/schoolvisit.svg';
import VideoManager from '@app/style/images/videomanager.svg';
import SchoolFeedback from '@app/style/images/schoolfeedback.svg';
import TeacherFeedback from '@app/style/images/teacherfeedback.svg';
import SupportQuestionnaire from '@app/style/images/supportquestionnaire.svg';
import CoachFeedback from '@app/style/images/coachfeedback.svg';
import SetUpBold from '@app/style/images/setup-bold.svg';
import SchoolVisitBold from '@app/style/images/schoolvisit-bold.svg';
import VideoManagerBold from '@app/style/images/videomanager-bold.svg';
import SchoolFeedbackBold from '@app/style/images/schoolfeedback-bold.svg';
import TeacherFeedbackBold from '@app/style/images/teacherfeedback-bold.svg';
import SupportQuestionnaireBold from '@app/style/images/supportquestionnaire-bold.svg';
import CoachFeedbackBold from '@app/style/images/coachfeedback-bold.svg';
import ChatHistory from '@app/style/images/chat-history.svg';
import ArrowRightCircle from '@app/style/images/arrow-circle-right.svg';

export const SetUpIcon = props => (
    <Icon component={SetUp} className='svg-icon' {...props} />
);
export const SchoolVisitIcon = props => (
    <Icon component={SchoolVisit} className='svg-icon' {...props} />
);
export const VideoManagerIcon = props => (
    <Icon component={VideoManager} className='svg-icon' {...props} />
);
export const SchoolFeedbackIcon = props => (
    <Icon component={SchoolFeedback} className='svg-icon' {...props} />
);
export const TeacherFeedbackIcon = props => (
    <Icon component={TeacherFeedback} className='svg-icon' {...props} />
);
export const SupportQuestionnaireIcon = props => (
    <Icon component={SupportQuestionnaire} className='svg-icon' {...props} />
);
export const CoachFeedbackIcon = props => (
    <Icon component={CoachFeedback} className='svg-icon' {...props} />
);

export const SetUpBoldIcon = props => (
    <Icon component={SetUpBold} className='svg-icon' {...props} />
);
export const SchoolVisitBoldIcon = props => (
    <Icon component={SchoolVisitBold} className='svg-icon' {...props} />
);
export const VideoManagerBoldIcon = props => (
    <Icon component={VideoManagerBold} className='svg-icon' {...props} />
);
export const SchoolFeedbackBoldIcon = props => (
    <Icon component={SchoolFeedbackBold} className='svg-icon' {...props} />
);
export const TeacherFeedbackBoldIcon = props => (
    <Icon component={TeacherFeedbackBold} className='svg-icon' {...props} />
);
export const SupportQuestionnaireBoldIcon = props => (
    <Icon component={SupportQuestionnaireBold} className='svg-icon' {...props} />
);
export const CoachFeedbackBoldIcon = props => (
    <Icon component={CoachFeedbackBold} className='svg-icon' {...props} />
);

export const SchoolIcon =props => (
    <Icon component={School} className='svg-icon' {...props} />
);

export const RegionIcon =props => (
    <Icon component={Region} className='svg-icon' {...props} />
);

export const UserIcon = props => (
    <Icon component={User} className='svg-icon' {...props} />
);

export const InvitationTemplateIcon = props => (
    <Icon component={InvitationTemplate} className='svg-icon' {...props} />
);

export const RejectIcon = props => (
    <Icon component={Rejct} className='svg-icon' {...props} />
);

export const SearchIcon = props => (
    <Icon component={Search} className='svg-icon' {...props} />
);

export const QLetterIcon = props => (
    <Icon component={QLetter} className='svg-icon' {...props} />
);

export const HistoryIcon = props => (
    <Icon component={History} className='svg-icon' {...props} />
);

export const ArrowUpIcon = props => (
    <Icon component={ArrowUp} className='svg-icon' {...props} />
);

export const ArrowDownIcon = props => (
    <Icon component={ArrowDown} className='svg-icon' {...props} />
);

export const ArrowRightIcon = props => (
    <Icon component={ArrowRight} className='svg-icon' {...props} />
);

export const ArrowRightCircleIcon = props => (
    <Icon component={ArrowRightCircle} className='svg-icon' {...props} />
);

export const MaleIcon = props => (
    <Icon component={Male} className='svg-icon' {...props} />
);

export const FemaleIcon = props => (
    <Icon component={Female} className='svg-icon' {...props} />
);

export const ChatHistoryIcon = props => (
    <Icon component={ChatHistory} className='svg-icon' {...props} />
);