import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { ISchoolLicenseService, SchoolLicensePagingRequest, SchoolLicenseRequest, SchoolLicenseEditModel } from './index';
import { GSAdminLocale } from '../../../locales/localeid';
import { ManualAdjustmentInfoModel, RegionManualAdjustmentCountModel } from '@app/states/school/class';
import { AdjustmentGroupModel, AdjustmentDetailGroupModel, InvoiceRuntimeRegenerationModel, UpdateAdjustmentResultModel } from './model';

@injectable()
export class SchoolLicenseService extends GLServiceBase<SchoolLicenseRequest, SchoolLicensePagingRequest> implements ISchoolLicenseService {
    serviceRoute = { prefix: 'admin/v1/licenses' }
    getClassInvoiceInfoUrl = "{classId}/billingInfo/{regionId}";
    getBillingAdjustmentDetailUrl = "{schoolId}/region/{regionId}/regioninvoice/{regionInvoiceId}";
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServicLicenseNotFound;
        return result;
    })();
    editSchoolLicense(schoolLicense: SchoolLicenseEditModel) {
        return this.update(schoolLicense);
    }
    generateLicenseHistory(params: any) {
        return this.create('{regionId}/history', params)
    }
    getSchoolClassManualAdjustments(params: { regionId: string, schoolClassId: string }) {
        return this.get("{regionId}/futuremanualadjustments/{schoolClassId}", params);
    }
    getRegionManualAdjustments(params: { regionId: string }): Promise<RegionManualAdjustmentCountModel[]> {
        return this.get("{regionId}/futuremanualadjustments", params);
    }
    saveSchoolClassManualAdjustments(data: ManualAdjustmentInfoModel): Promise<UpdateAdjustmentResultModel> {
        return this.create("manualadjustments", data);
    }
    getClassInvoiceInfo(params: any) {
        return this.get(this.getClassInvoiceInfoUrl, params);
    }
    getBillingAdjustmentDetail(params: { schoolId: string, regionId: string, regionInvoiceId: string }) {
        return this.get(this.getBillingAdjustmentDetailUrl, params);
    }
    getSchoolLicenseHistoryForInvoicePeriod(params: { pagingParams: any, routeParams: any }) {
        return this.get('{schoolId}/invoicehistory/{regionInvoiceHistoryId}', params.pagingParams, params.routeParams);
    }
    getAdjustmentDetails(params: { regionId: string, schoolClassId: string }): Promise<AdjustmentGroupModel[]> {
        return this.get("{regionId}/adjustmentdetails/{schoolClassId}", params);
    }
    deleteAdjustment(adjustment: AdjustmentDetailGroupModel, params: { regionId: string, schoolClassId: string }, withLoader?: boolean): Promise<UpdateAdjustmentResultModel> {
        return withLoader
            ? this.create("{regionId}/deleteadjustment/{schoolClassId}", adjustment, params)
            : this.createWithoutLoader("{regionId}/deleteadjustment/{schoolClassId}", adjustment, params);
    }
    getRuntimeRegeneration(params: { regionId: string, schoolId?: string, schoolClassId?: string }): Promise<InvoiceRuntimeRegenerationModel[]> {
        return this.get("{regionId}/runtimeregenerate", params);
    }
    regenerate(params: { regionId: string, schoolId?: string, schoolClassId?: string }): Promise<any> {
        return this.createWithoutLoader("{regionId}/school/{schoolId}/class/{schoolClassId}/regenerateinvoice", null, params);
    }
}