import React from "react";
import { Menu, Dropdown } from "antd-min";
import { IContextMenu, IContextMenuModel } from "@app/util/menu";
import "./key-bob-menu.less"

const { Item } = Menu;

interface KeybobMenyProps {
    item: IContextMenuModel;
    contextMenuClicked: (menu: IContextMenu, item: IContextMenuModel) => void;
}

const contextMenu = (menus: IContextMenu[], item: IContextMenuModel, contextMenuClicked): React.ReactNode => (
    <Menu className="context-menu">
        {menus &&
            menus.map((menu: IContextMenu, index: number) => (
                <Item key={index}>
                    <a onClick={e => contextMenuClicked(menu, item)}>{menu.text}</a>
                </Item>
            ))}
    </Menu>
);

export const KeybobMenu: React.StatelessComponent<KeybobMenyProps> = props => (
    <div className="card-context">
        <Dropdown
            getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            overlay={contextMenu(props.item.contextMenus.filter(menu => menu.isVisible !== false), props.item, props.contextMenuClicked)}
            placement="bottomRight"
            trigger={["click"]}
        >
            <a className="ant-dropdown-link" href="javascript:void(0)">
                <i className="material-icons">more_vert</i>
            </a>
        </Dropdown>
    </div>
);
