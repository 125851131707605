import { StateType } from "@app/states";
import { useStore } from "./useStore";
interface SelectorMapFn<T> {
	(state: StateType): T;
}

export const useSelector = <T>(selector: SelectorMapFn<T>) => {
	const [state] = useStore();
	return selector(state);
};
