import React from 'react'
import { Button } from 'antd-min';

export const IconButton = ({onCancel = null, onSave = null}) => {
    return (
        <div className='icon-button'>
            <Button shape="circle" icon='close' onClick={onCancel} />
            <Button shape="circle" type='primary' icon='check' onClick={onSave} />
        </div>
    )
}