import React, { useEffect, useState } from "react";
import { connect } from "gl-commonui";
import { Row, Col, Card } from "antd-min";
import "./digital-adoption.less";
import { Chart, Geom, Axis, Coord, Label, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import { TYPES, DigitalAdoptionReportType, fmtMsg } from "../../../util/index";
import {
    ISurveyReportService,
    DigitalAdoptionReportModel,
    AdoptionReportChartModel
} from "../../../service/survey/report";
import {
    TodoSurveyResponseModel,
    StatisticsFilterType
} from "@app/service/survey";
import { StateType } from "@app/states";
import { useService } from "@app/hooks";
import { Empty } from "antd";
import { SurveyLocale } from "@app/locales/localeid";

interface DigitalAdoptionReportProps {
    surveyResponse: TodoSurveyResponseModel;
}

const DigitalAdoption = (props: DigitalAdoptionReportProps) => {
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [errorLoadingData, setErrorLoadingData] = useState(false);

    const [surveyResponse, setSurveyResponse] = useState(
        null as TodoSurveyResponseModel
    );
    const reportService = useService<ISurveyReportService>(
        TYPES.ISurveyReportService
    );
    const [reportData, setReportData] = useState(
        [] as DigitalAdoptionReportModel[]
    );

    const [filteredReportData, setFilteredReportData] = useState(
        [] as AdoptionReportChartModel[]
    );

    let defaultRegionAll = "all";
    let selectedRegion: string = defaultRegionAll;

    useEffect(() => {
        if (!surveyResponse) {
            setSurveyResponse(props.surveyResponse);
        } else {
            setRegionDigitalAdoptionReportData();
        }
    }, [surveyResponse]);

    useEffect(() => {
        filteredReport();
    }, [reportData]);

    useEffect(() => {}, [filteredReportData]);

    const setRegionDigitalAdoptionReportData = () => {
        setLoading(true);
        const {
            statisticsFilterTypeId,
            statisticsFilterId,
            submitDate
        } = surveyResponse;
        let regionId = null;
        let regionGroupId = null;

        if (statisticsFilterTypeId) {
            if (statisticsFilterTypeId == StatisticsFilterType.Region) {
                regionId = statisticsFilterId;
            } else {
                regionGroupId = statisticsFilterId;
            }
        }
        const params: any = {
            regionId,
            regionGroupId,
            surveySubmitDate: submitDate
        };
        reportService
            .getDigitalAdoptionReport(params)
            .then(data => {
                setReportData(data);
                setDataLoaded(true);
            })
            .catch(er => {
                setLoading(false);
                setErrorLoadingData(true);
            });
    };

    const filteredReport = () => {
        var filterData = [];

        if (reportData && reportData.length > 0) {
            const regionId =
                selectedRegion === defaultRegionAll ? null : selectedRegion;
            const regionIndex = reportData.findIndex(
                item => item.regionId === regionId
            );
            if (regionIndex >= 0) {
                filterData = reportData[regionIndex].regionAdoptionReport;
            }
            setFilteredReportData(filterData);
        }
        setLoading(false);
    };

    const renderReport = () => {
        const { DataView } = DataSet;

        let paiChartData = [];
        if (filteredReportData && filteredReportData.length > 0) {
            paiChartData = filteredReportData
                .filter(
                    item =>
                        item.reportType ===
                            DigitalAdoptionReportType.GrapeseedLicenses ||
                        item.reportType ===
                            DigitalAdoptionReportType.REPPlayback
                )
                .sort((data1, data2) =>
                    data1.reportType > data2.reportType ? 1 : -1
                )
                .map(record => {
                    switch (record.reportType) {
                        case DigitalAdoptionReport.GrapeseedLicenses:
                            return {
                                item: fmtMsg({
                                    id:
                                        SurveyLocale.SurveyDigitalAdoptionGrapeseedLicenses
                                }),
                                count: record.value
                            };

                        case DigitalAdoptionReport.REPPlayback:
                            return {
                                item: fmtMsg({
                                    id:
                                        SurveyLocale.SurveyDigitalAdoptionREPPlayback
                                }),
                                count: record.value
                            };

                        default:
                            return {
                                item: record.xAxisLabel,
                                count: record.value
                            };
                    }
                });
        }

        const dv = new DataView();
        dv.source(paiChartData).transform({
            type: "percent",
            field: "count",
            dimension: "item",
            as: "percent"
        });

        if (dataLoaded && !filteredReportData.length && !paiChartData.length) {
            return (<Empty></Empty>);
        }

        return (
            <Row type="flex" gutter={30} className="da">
                {((filteredReportData.length === 0 && !dataLoaded) ||
                    filteredReportData.length > 0) && (
                    <Col xs={24} xl={16}>
                        <Card loading={loading}>
                            <Chart
                                height={200}
                                padding={[50, 0, 50, 50]}
                                data={filteredReportData.map(item => {
                                    let xAxisLabel = "";
                                    switch (item.reportType) {
                                        case DigitalAdoptionReportType.GrapeseedLicenses:
                                            xAxisLabel = fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionGrapeseedLicenses });
                                            break;
                            
                                        case DigitalAdoptionReportType.DigitalLicenses:
                                            xAxisLabel =  fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionDigitalLicenses });
                                            break;

                                        case DigitalAdoptionReportType.InvitationsPrinted:
                                            xAxisLabel =  fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionInvitationsPrinted });
                                            break;

                                        case DigitalAdoptionReportType.AccountsLinked:
                                            xAxisLabel =  fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionAccountsLinked });
                                            break;
                            
                                        case DigitalAdoptionReportType.TabletLogin:
                                            xAxisLabel = fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionTabletLogin });
                                            break;
                            
                                        case DigitalAdoptionReportType.REPPlayback:
                                            xAxisLabel = fmtMsg({ id: SurveyLocale.SurveyDigitalAdoptionREPPlayback });
                                            break;
                            
                                        default:
                                            break;
                                    }

                                    return { ...item, xAxisLabel };
                                })}
                                forceFit
                            >
                                <Axis
                                    name="xAxisLabel"
                                    label={{
                                        textStyle: {
                                            textAlign: "center",
                                            fontSize: "11"
                                        }
                                    }}
                                />
                                <Axis name="percentageValue" />
                                <Geom
                                    size={30}
                                    color="#32dadd"
                                    type="interval"
                                    position="xAxisLabel*value"
                                >
                                    <Label
                                        content="percent"
                                        offset={-25}
                                        htmlTemplate={(text, item, index) => {
                                            return `
                                            <div class="chart-label">
                                                <span class="chart-label-value">${
                                                    item._origin.value
                                                }</span>
                                                <span class='chart-label-percent'>${
                                                    index === 0
                                                        ? ""
                                                        : item._origin.percentageValue.toFixed(
                                                              1
                                                          ) + "%"
                                                }</span>
                                            </div>
                                            `;
                                        }}
                                    />
                                </Geom>
                            </Chart>
                        </Card>
                    </Col>
                )}
                {((paiChartData.length === 0 && !dataLoaded) ||
                    paiChartData.length > 0) && (
                    <Col xs={24} lg={12} xl={8}>
                        <Card loading={loading}>
                            <Chart
                                height={200}
                                data={dv}
                                padding={{
                                    top: 25,
                                    right: 0,
                                    bottom: 0,
                                    left: 80
                                }}
                                forceFit
                            >
                                <Coord type="theta" radius={1} />
                                <Legend position="left-top" offsetX={70} />
                                <Geom
                                    type="intervalStack"
                                    position="percent"
                                    color={["item", ["#fbd437", "#4ecb73"]]}
                                    tooltip={[
                                        "item*percent",
                                        (item, percent) => {
                                            percent = percent * 100 + "%";
                                            return {
                                                name: item,
                                                value: percent
                                            };
                                        }
                                    ]}
                                    style={{
                                        lineWidth: 1,
                                        stroke: "#fff"
                                    }}
                                >
                                    <Label
                                        textStyle={{
                                            textAlign: "center",
                                            shadowBlur: 2,
                                            shadowColor: "rgba(0, 0, 0, .45)"
                                        }}
                                        content="percent"
                                        offset={-40}
                                        formatter={(val, item) => {
                                            return `${
                                                item._origin.count === 0
                                                    ? ""
                                                    : item._origin.count
                                            }`;
                                        }}
                                    />
                                </Geom>
                            </Chart>
                        </Card>
                    </Col>
                )}
            </Row>
        );
    };
    return renderReport();
};

export const DigitalAdoptionReport = connect(
    ({}: StateType) => ({}),
    {}
)(DigitalAdoption);
