import React, { Component } from 'react';
import "../component/progress.less";
import { lazyInject, TYPES } from '@app/util';
import { VisitSchool } from './visit-school';
import { VideoManager } from './video-manager';
import { SchoolReview } from './school-review';
import { TeacherReview } from './teacher-review';
import { PrevisitationForm } from './previsitation-form';
import { GLForm, GLFormComponentProps } from 'gl-commonui';
import { VisitationCoachStage } from '@app/util/coach/enum';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { SetUp } from './set-up';

interface CoachTabsProps extends GLFormComponentProps {
    history?: any
    selectedStage: number
    callback?: (stage, isUpdate?) => void
    visitation: VisitationModel
}
interface CoachTabsStates {
    visible: boolean
    status?: number
    recorder?: any
    chatBadgeCounts?: any
}

@GLForm.create()
export class CoachTabs extends Component<CoachTabsProps, CoachTabsStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    constructor(props) {
        super(props);
        this.state = { visible: false }
    }
    renderTabPane() {
        const { visitation, selectedStage, callback, history } = this.props;
        const stage = selectedStage != undefined ? selectedStage : visitation.stage;
        switch(stage) {
            case VisitationCoachStage.ScheduleDate:
                return <SetUp visitation={visitation} callback={callback} history={history}/>;
            case VisitationCoachStage.PrevisitationForm:
                return <PrevisitationForm visitation={visitation} callback={callback}/>;
            case VisitationCoachStage.VisitSchool:
                return <VisitSchool visitation={visitation} callback={callback} />;
            case VisitationCoachStage.VideoManager:
                return <VideoManager visitation={visitation} callback={callback}/>;
            case VisitationCoachStage.TeacherReview:
                return <TeacherReview visitation={visitation} callback={callback} />;
            case VisitationCoachStage.SchoolReview:
            case VisitationCoachStage.Completed:
                return <SchoolReview visitation={visitation} callback={callback} />;
        }
    }
    render() {
        return <div>
            {this.renderTabPane()}
        </div>
    }
}