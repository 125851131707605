import { useState } from "react";
import { OidcProxy } from "gl-commonui";
import { CONSTS } from "@app/util";

export enum PermissionType {
    Poll = 0,
}

/**
 * gets all permissions the current user has for a given type.
 * @param type the type of permission for the user.
 */
export function usePermissions(type): string[] {
    const [permissions, setPermissions] = useState({});

    if (permissions[type]) {
        return permissions[type];
    }

    switch (type) {
        case PermissionType.Poll:
            const values = OidcProxy.getPermissionInfo().filter(p => CONSTS.PollPermissions.some(perm => perm === p))
            setPermissions({ [PermissionType.Poll]: values });
            return values;

        default:
            return [];
    }
}