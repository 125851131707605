import { IRoleService } from './interface';
import { ITGLService, GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di'

@injectable()
export class RoleService extends GLServiceBase<any, any> implements IRoleService {
    serviceRoute = { prefix: 'account/v1/roles' }

    getRoles() {
        return this.get();
    }
}