import { GLServiceBase } from "gl-commonui";
import { injectable } from "../../../util/di";
import {
    GetSurveyRequest, SurveyRequest, ISurveyService,
    SurveyRoleTargetModel, TodoSurveyResponseModel, TodoSurveyRequestModel,
    QuestionAnswerMapResponse, CreatedPollsResponseModel, PollPageParams,
    SurveyAttachmentModel, PollParticipationResponse, ReviewPollsResponseModel,
    KeyValueResponseModel,
    KeyValue
} from ".";

@injectable()
export class SurveyService extends GLServiceBase<GetSurveyRequest, SurveyRequest> implements ISurveyService {
    serviceRoute = { prefix: "admin/v1/survey" }

    getSurveysToReview(params): Promise<ReviewPollsResponseModel> {
        return this.get("reviewsurveys", params);
    }

    getCreatedPollsByUser(params: PollPageParams): Promise<CreatedPollsResponseModel> {
        return this.get("created", params);
    }

    getUserPollParticipation(params): Promise<PollParticipationResponse> {
        this.serviceRoute = { ...this.serviceRoute, itemSuffix: "{id}" }
        return this.get("{id}/participation", { surveyInstanceId: params.surveyInstanceId }, { id: params.id });
    }

    archive(id: string): Promise<any> {
        return this.get("archive", { id });
    }
    submitSurvey(params) {
        return this.create("SubmitSurvey", params);
    }

    errMsgs = (() => {
        const result = {};
        return result;
    })();
    getTargetCount(targets: SurveyRoleTargetModel[]): any {
        return this.create("targetcount", targets);
    }
    getDetail(id): Promise<any> {
        this.serviceRoute = { prefix: "admin/v1/survey", itemSuffix: "{id}" }
        return this.get("{id}/detail", id);
    }
    getSurveyParticipants(id, params): Promise<any> {
        return this.get(`${id}/SurveyParticipants`, params);
    }
    checkIfSurveyNameUnique(surveyId: string, surveyName: string): Promise<any> {
        return this.get('CheckIfSurveyNameUnique', { surveyId, surveyName });
    }
    isCreatorShared(surveyId: string): Promise<boolean> {
        return this.get("{id}/isCreatorShared", {id: surveyId});
    }
    getSurveyResponses(params): Promise<any> {
        return this.get("getSurveyResponses", params);
    }
    getSurveyResponse(instanceId: string, type: number, responseId?: string): Promise<TodoSurveyResponseModel> {
        return this.get("todo", { instanceId, responseId, type });
    }
    fillSurvey(filledSurvey: TodoSurveyRequestModel): Promise<QuestionAnswerMapResponse> {
        return this.create("fill", filledSurvey);
    }
    getSubmittedSurveyHistory(params: any): Promise<any> {
        return this.get("GetSubmittedSurveyHistory", params);
    }
    getSASUrl(): Promise<string> {
        this.serviceRoute = { prefix: "admin/v1/survey/media", suffix: "getSAS" };
        return this.get();
    }
    saveAttachments(attachments: SurveyAttachmentModel[]): Promise<SurveyAttachmentModel[]> {
        this.serviceRoute = { prefix: "admin/v1/survey/media" };
        return this.create("attachments", attachments);
    }
    deleteAttachments(attachments: { id: string }[]): Promise<any> {
        this.serviceRoute = { prefix: "admin/v1/survey/media" };
        return this.create("deleteAttachments", attachments);
    }
    convertToDraft(responseId: string) {
        this.serviceRoute = { prefix: "admin/v1/survey", itemSuffix: "{id}" };
        return this.update("{id}/convertToDraft", { id: responseId });
    }
    review(responseId: string) {
        this.serviceRoute = { ...this.serviceRoute, itemSuffix: "{id}" }
        return this.update("{id}/review", { id: responseId });
    }
    markAsRead(instanceId: string, responseId: string): Promise<boolean> {
        return this.update("{id}/markAsRead/{responseId}", {id: instanceId, responseId})
    }
    getAllStatisticsFilterType(params): Promise<KeyValueResponseModel[]> {
        return this.get("GetAllStatisticsFilterType");
    }
    getAllReportType(params): Promise<KeyValueResponseModel[]> {
        return this.get("GetAllReportType");
    }
    duplicatePoll(params): Promise<KeyValue> {
        return this.create("DuplicateSurvey", params);
    }
    deletePoll(params: KeyValue): Promise<boolean> {
        return this.delete("", params);
    }
    shareSurvey(params: any): Promise<any> {
        return this.update("ShareSurvey",params)
    }
    surveydetail(id): Promise<any> {
        this.serviceRoute = { prefix: "admin/v1/survey", itemSuffix: "{id}" }
        return this.get("{id}/surveydetail", id);
    }
    surveyInstanceDetail(surveyInstanceId): Promise<any> {
        this.serviceRoute = { prefix: "admin/v1/survey", itemSuffix: "{surveyInstanceId}" }
        return this.get("{surveyInstanceId}/surveyinstancedetail", surveyInstanceId);
    }
    downloadExitSurveyData(params): Promise<any> {
        return this.getBlob("downloadexitsurveyreport", params);
    }
}
