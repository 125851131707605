
import { RoleName, PrimaryLanguage, GLGlobal } from 'gl-commonui';
import { Icon } from 'antd-min';
import { InvitationTypeText, RoleNameText, Currency, AppLockState, PollSectionKeys, SurveyAction, EventType, EventStatus, ChangeLogType, SchoolEventType, CampusEventType, EventInfo, LicenseTypes } from './enum';
import { SchoolLocale, GSAdminLocale, LicenseTypeLocale } from '@app/locales/localeid';
import { fmtMsg } from './func';

function generateBillingDays() {
    let billingDays = [];
    for (var i = 1; i <= 28; i++) {
        billingDays.push(i.toString());
    }
    return billingDays;
}

function generateBillingPeriods() {
    let billingPeriod = [];
    for (var i = 1; i <= 12; i++) {
        billingPeriod.push(i.toString());
    }
    return billingPeriod;
}

const DefaultTemplateTag = "Invalid";
const DefaultTemplateTagText = "Tags";

const defaultIndex = 0;
const timezones = {
    "-720": "UTC-12:00",
    "-660": "UTC-11:00",
    "-600": "UTC-10:00",
    "-570": "UTC-09:30",
    "-540": "UTC-09:00",
    "-480": "UTC-08:00",
    "-420": "UTC-07:00",
    "-360": "UTC-06:00",
    "-300": "UTC-05:00",
    "-240": "UTC-04:00",
    "-210": "UTC-03:30",
    "-180": "UTC-03:00",
    "-120": "UTC-02:00",
    "-60": "UTC-01:00",
    "0": "UTC+00:00",
    "60": "UTC+01:00",
    "120": "UTC+02:00",
    "180": "UTC+03:00",
    "210": "UTC+03:30",
    "240": "UTC+04:00",
    "270": "UTC+04:30",
    "300": "UTC+05:00",
    "330": "UTC+05:30",
    "345": "UTC+05:45",
    "360": "UTC+06:00",
    "390": "UTC+06:30",
    "420": "UTC+07:00",
    "480": "UTC+08:00",
    "510": "UTC+08:30",
    "525": "UTC+08:45",
    "540": "UTC+09:00",
    "570": "UTC+09:30",
    "600": "UTC+10:00",
    "630": "UTC+10:30",
    "660": "UTC+11:00",
    "720": "UTC+12:00",
    "765": "UTC+12:45",
    "780": "UTC+13:00",
    "840": "UTC+14:00",
};

const InvitationType = {
    "0": InvitationTypeText.Teacher,
    "1": InvitationTypeText.Parent,
    "2": InvitationTypeText.CampusAdmin,
    "3": InvitationTypeText.SchoolAdmin,
    "4": InvitationTypeText.RegionAdmin,
    "5": InvitationTypeText.TrainingAdmin,
    "6": InvitationTypeText.Trainer,
    "7": InvitationTypeText.GlobalHead,
    "8": InvitationTypeText.SystemAdmin,
    "9": InvitationTypeText.AccountManager,
    "12": InvitationTypeText.Trainer,
    "14": InvitationTypeText.ContentAdmin
}
const InvitationType4GSAdminSite = {
    "0": InvitationTypeText.Teacher,
    "1": InvitationTypeText.Parent,
    "3": InvitationTypeText.SchoolAdmin,
    "4": InvitationTypeText.RegionAdmin,
    "5": InvitationTypeText.TrainingAdmin,
    "6": InvitationTypeText.Trainer,
    "7": InvitationTypeText.GlobalHead,
    "8": InvitationTypeText.SystemAdmin
}
const InvitationType4SchoolSite = {
    "0": InvitationTypeText.Teacher,
    "1": InvitationTypeText.Parent,
    "2": InvitationTypeText.CampusAdmin,
    "3": InvitationTypeText.SchoolAdmin,
}

const InvitationTypeOrdered: Map<number, string> = new Map([
    [7, InvitationTypeText.GlobalHead],
    [8, InvitationTypeText.SystemAdmin],
    [4, InvitationTypeText.RegionAdmin],
    [5, InvitationTypeText.TrainingAdmin],
    [6, InvitationTypeText.Trainer],
    [3, InvitationTypeText.SchoolAdmin],
    [2, InvitationTypeText.CampusAdmin],
    [0, InvitationTypeText.Teacher],
    [1, InvitationTypeText.Parent]
]);

// const Role = {
//     "globalhead": RoleNameText.GlobalHead,
//     "systemadmin": RoleNameText.SchoolAdmin,
//     "regionadmin": RoleNameText.RegionAdmin,
//     "trainingadmin": RoleNameText.TrainingAdmin,
//     "trainer": RoleNameText.Trainer,
//     "schooladmin": RoleNameText.SchoolAdmin,
//     "campusadmin": RoleNameText.CampusAdmin,
//     "teacher": RoleNameText.Teacher,
//     "parent": RoleNameText.Parent
// }

const Role: Map<RoleName, RoleNameText> = new Map([
    [RoleName.globalHead, RoleNameText.GlobalHead],
    [RoleName.systemAdmin, RoleNameText.SystemAdmin],
    [RoleName.trainingAdmin, RoleNameText.TrainingAdmin],
    [RoleName.regionAdmin, RoleNameText.RegionAdmin],
    [RoleName.accountManager, RoleNameText.AccountManager],
    [RoleName.trainer, RoleNameText.Trainer],
    [RoleName.trainingManager, RoleNameText.TrainingManager],
    [RoleName.schoolAdmin, RoleNameText.SchoolAdmin],
    [RoleName.campusAdmin, RoleNameText.CampusAdmin],
    [RoleName.teacher, RoleNameText.Teacher],
    [RoleName.parent, RoleNameText.Parent],
    [RoleName.contentAdmin, RoleNameText.ContentAdmin]
]);

const ErrorCodes = {
    "PASSWORD_ERROR": 461,
    "CAN_NOT_DELETE_OR_MODIFY": 462,
    "EMAIL_HAS_EXISTED": 445,
    "CODE_HAS_BEEN_USED": 463,
    "DATA_ARGUMENT_ERROR": 451,
    "INVALID_GRANT_EXCEPTION": 405,
    "NO_PERMISSION": 433
}

const InvitationTemplateRegion = {
    'DefaultRegion': { id: 'General', name: 'General', englishName: 'General', primaryLanguage: PrimaryLanguage.English }
};
const InvitationTemplateSchool = {
    'DefaultSchool': { id: 'General', name: '　' }
};

const RegionCurrency = Object.keys(Currency).map(c => c);

const AntCheckBox2HtmlCheckedDisabled = '<label class="ant-checkbox-wrapper ant-checkbox-wrapper-checked ant-checkbox-wrapper-disabled"><span class="ant-checkbox ant-checkbox-checked ant-checkbox-disabled"><input type="checkbox" disabled="" class="ant-checkbox-input" value="" checked=""><span class="ant-checkbox-inner"></span></span></label>';
const AntCheckBox2HtmlUnCheckedDisabled = '<label class="ant-checkbox-wrapper ant-checkbox-wrapper-disabled"><span class="ant-checkbox ant-checkbox-disabled"><input type="checkbox" disabled="" class="ant-checkbox-input" value=""><span class="ant-checkbox-inner"></span></span></label>';

const LockState: Map<AppLockState, string> = new Map([
    [AppLockState.Lock, SchoolLocale.UnitPlanAppUnlockStateLock],
    [AppLockState.Unlock, SchoolLocale.UnitPlanAppUnlockStateUnlock],
]);

const LicenseType: Map<LicenseTypes, string> = new Map([
    [LicenseTypes.Classic, LicenseTypeLocale.Classic],
    [LicenseTypes.Connect, LicenseTypeLocale.Connect],
    [LicenseTypes.Nexus, LicenseTypeLocale.Nexus],
    [LicenseTypes.ClassicConnectHybrid, LicenseTypeLocale.HybridConnect],
    [LicenseTypes.ClassicNexusHybrid, LicenseTypeLocale.HybridNexus],
    [LicenseTypes.ConnectNexusHybrid, LicenseTypeLocale.HybridConnectNexus]
]);

const PollPermissionMap = {
    [PollSectionKeys.View]: [SurveyAction.CreateSurvey, SurveyAction.EditSurvey, SurveyAction.DeleteSurvey, SurveyAction.ListSurvey],
    [PollSectionKeys.Review]: [SurveyAction.ListMySurvey]
};

const PollPermissions = Object.values(PollPermissionMap).reduce((acc, x) => (acc.push(...x), acc), []);

const CIMSEventType4Display: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddClass, SchoolLocale.CIMSEventTypeAddClass],
    [EventInfo.PendingChangesPromoteClass, SchoolLocale.CIMSEventTypePromoteClass],
    [EventInfo.PendingChangesDisableClass, SchoolLocale.CIMSEventTypeDisableClass],
    [EventInfo.PendingChangesChangeLicense, SchoolLocale.CIMSEventTypeClassLicenseChange],
    [EventInfo.PendingChangesChangeClassUnitPlan, SchoolLocale.CIMSEventTypeClassUnitPlanChange],
    [EventInfo.PendingChangesChangeClassMandatoryData, SchoolLocale.CIMSEventTypeClassMandatoryDataChange],
    [EventInfo.PendingChangesRemoveStudent, SchoolLocale.CIMSEventTypeRemoveStudent],
    [EventInfo.PendingChangesMoveStudent, SchoolLocale.CIMSEventTypeMoveStudent],
    [EventInfo.PendingChangesEnableClass, SchoolLocale.CIMSEventTypeEnableClass],
    [EventInfo.PendingChangesMoveUnregisteredStudent, SchoolLocale.CIMSEventTypeMoveUnregisteredStudent],
    [EventInfo.PendingChangesAddCampus, SchoolLocale.CIMSEventTypeAddCampus],
    [EventInfo.PendingChangesDisableCampus, SchoolLocale.CIMSEventTypeDisableCampus],
    [EventInfo.PendingChangesChangeCampusMandatoryData, SchoolLocale.CIMSEventTypeCampusMandatoryDataChange],
    [EventInfo.PendingChangesEnableCampus, SchoolLocale.CIMSEventTypeEnableCampus],
    [EventInfo.PendingChangesMaterialOrder, SchoolLocale.CIMSEventTypeCampusAddMaterialOrder],
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolLocale.CIMSEventTypeSchoolMandatoryDataChange],
    [EventInfo.PendingChangesMovePromoteStudents, SchoolLocale.CIMSEventTypeMovePromoteStudents]
]);

const AntIcon2Html4Pending = () => { return `<i aria-label="icon: clock-circle" title="${fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusPending })}" class="anticon anticon-clock-circle cims-event-status cims-event-status-pending"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="clock-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path></svg></i>`; }
const AntIcon2Html4Approved = () => { return `<i aria-label="icon: check-circle" title="${fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusAcknowledged })}" class="anticon anticon-check-circle cims-event-status cims-event-status-acknowledged"><svg viewBox="64 64 896 896" class="" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></i>`; }
const AntIcon2Html4Denied = () => { return `<i aria-label="icon: close-circle" title="${fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusReverted })}" class="anticon anticon-close-circle cims-event-status cims-event-status-reverted"><svg viewBox="64 64 896 896" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></i>`; }

const CIMSEventStatus4Display: Map<EventStatus, () => string> = new Map([
    [EventStatus.Pending, AntIcon2Html4Pending],
    [EventStatus.Approved, AntIcon2Html4Approved],
    [EventStatus.Denied, AntIcon2Html4Denied],
]);

const AntIcon2Html4ApproveDenyAction = (title) => { return `<i aria-label="icon: info-circle-o" title="${title}" class="anticon anticon-info-circle-o gl-icon "><svg viewBox="64 64 896 896" class="" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg></i>`; }

export const CONSTS = {
    ErrorCodes: ErrorCodes,
    Role: Role,
    billingDays: generateBillingDays(),
    billingPeriods: generateBillingPeriods(),
    timeZones: timezones,
    defaultIndex: defaultIndex,
    InvitationType: InvitationType,
    InvitationTypeOrdered: InvitationTypeOrdered,
    DefaultTemplateTag: DefaultTemplateTag,
    DefaultTemplateTagText: DefaultTemplateTagText,
    InvitationType4GSAdminSite: InvitationType4GSAdminSite,
    InvalidTemplateTag: DefaultTemplateTag,
    InvalidTemplateTagText: DefaultTemplateTagText,
    InvitationTemplateRegion: InvitationTemplateRegion,
    InvitationTemplateSchool: InvitationTemplateSchool,
    RegionCurrency: RegionCurrency,
    AntCheckBox2HtmlCheckedDisabled: AntCheckBox2HtmlCheckedDisabled,
    AntCheckBox2HtmlUnCheckedDisabled: AntCheckBox2HtmlUnCheckedDisabled,
    EmptyGuid: "00000000-0000-0000-0000-000000000000",
    SurveyAttachmentContainer: "gl-survey-attachments",
    LockState: LockState,
    LicenseType: LicenseType,
    MySurveyItemDisplayCount: 10,
    PollPermissionMap,
    PollPermissions,
    CIMSEventType4Display,
    CIMSEventStatus4Display,
    AntIcon2Html4ApproveDenyAction,
    MAX_INTEGER_VALUE: 2147483647, // Max integer value in db.
    LittleSEEDMaxUnit: -8,
    MIN_SCHOOL_CODE: 100,
    MAX_SCHOOL_CODE: 999,
    MS_PER_DAY: 86400000, // 1000 * 60 * 60 * 24
}

export const AdjustmentDateFormat = "YYYY-MM-DD";

export const MaxSurveyOptionCount = 20;

export const Colors = ['#8E3FC8', '#C7A0E4', '#00C453', '#81E2AA', '#A67C52', '#C1A48A', '#29ABE2', '#928DB7', '#FFC800', '#FFE585',
    '#FF5919', '#FFAE8E', '#FF0000', '#FF7BAC', '#B66BAC', '#5F8A7F', "#44546A", "#5BFFD5", "#ED7D31", "#A5A5A5", "#4472C4",
    "#70AD47", "#000000", "#FFFF00", "#757171", "#8497B0", "#9BFFE6", "#F4B084", "#C9C9C9", "#8EA9DB", "#A9D08E", "#1F4E78",
    "#833C0C", "#525252", "#806000", "#203764", "#375623", "#C00000", "#FFCDCD", "#FF6D6D", "#8083F4", "#3F43EF", "#0D11A3",
    "#B7C31B", "#808812", "#6C044C", "#F852C5", "#A10771", "#076A7B", "#51DEF5", "#0B9DB5", "#065F6E", "#95CFC8", "#726FF5",
    "#ADABB9", "#F8B56C", "#05473F", "#C6D4B4", "#D78591",];

export const MaxSurveySliderStepCount = 5;

export const langsDateMasks = {
    "MM/DD/YYYY": [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    "DD/MM/YYYY": [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    "YYYY/MM/DD": [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/]
};

export const monthsBG = () => [
    fmtMsg({ id: GSAdminLocale.MonthCompleteJanuary }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteFebruary }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteMarch }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteApril }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteMay }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteJune }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteJuly }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteAugust }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteSeptember }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteOctober }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteNovember }),
    fmtMsg({ id: GSAdminLocale.MonthCompleteDecember })
];

export const daysBG = () => [
    fmtMsg({ id: GSAdminLocale.CommonDaySu }),
    fmtMsg({ id: GSAdminLocale.CommonDayMo }),
    fmtMsg({ id: GSAdminLocale.CommonDayTu }),
    fmtMsg({ id: GSAdminLocale.CommonDayWe }),
    fmtMsg({ id: GSAdminLocale.CommonDayTh }),
    fmtMsg({ id: GSAdminLocale.CommonDayFr }),
    fmtMsg({ id: GSAdminLocale.CommonDaySa })
];

export const CIMSSchoolEventType4Display: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolLocale.CIMSEventTypeSchoolMandatoryDataChange],
    [EventInfo.PendingChangesMovePromoteStudents, SchoolLocale.CIMSEventTypeMovePromoteStudents]
]);

export const CIMSCampusEventType4Display: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddCampus, SchoolLocale.CIMSEventTypeAddCampus],
    [EventInfo.PendingChangesDisableCampus, SchoolLocale.CIMSEventTypeDisableCampus],
    [EventInfo.PendingChangesChangeCampusMandatoryData, SchoolLocale.CIMSEventTypeCampusMandatoryDataChange],
    [EventInfo.PendingChangesEnableCampus, SchoolLocale.CIMSEventTypeEnableCampus],
    [EventInfo.PendingChangesMaterialOrder, SchoolLocale.CIMSEventTypeCampusAddMaterialOrder],
]);

export const getCIMSEvent = (changeLogType: ChangeLogType) => {
    switch (changeLogType) {
        case ChangeLogType.School:
            return CIMSSchoolEventType4Display;
        case ChangeLogType.Campus:
            return CIMSCampusEventType4Display;
        case ChangeLogType.SchoolClass:
            return CIMSEventType4Display;
    }
}

export const MIN_UNIT = 1;
export const GRAPE_SEED_MAX_UNIT = 40;
export const LITTLE_SEED_MAX_UNIT = 8;
export const StudentListItemSplitChar = "$"

export const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2002837_xq1oue870ki.js',
});

// This regex is same as used in email validation while registeration in glas
export const EmailRegex = /^(("")?(""(?!.*\\""@)(.*)""@)|(([0-9a-z]((\.(?!\.))|[-!#\$%&'\*\+\/=\?\^`\{\}\|~\w])*)(?:[0-9a-z_])@))((\[)?(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9][\-a-z0-9]{0,22}[a-z0-9]))$/;

export const STUDENT_VERIFICATION_PG_SIZE = 10;
export const NO_MATCH_SELECTED = "NO_MATCH_SELECTED";

export const WeekdayAbbr4BackendObj = {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wed",
    5: "Thu",
    6: "Fri",
    7: "Sat"
}

export const Checkbox = "checkbox";

export const LicenseTypeValueNameMap = {
    [LicenseTypes.Classic]: "Classic",
    [LicenseTypes.Connect]: "Connect",
    [LicenseTypes.Nexus]: "Nexus",
    [LicenseTypes.ClassicConnectHybrid]: "Classic/Connect Hybrid",
    [LicenseTypes.ClassicNexusHybrid]: "Classic/Nexus Hybrid",
    [LicenseTypes.ConnectNexusHybrid]: "Connect/Nexus Hybrid",
};

export const LicenseTypeNameValueMap = {
    ["Classic"]: LicenseTypes.Classic,
    ["Connect"]: LicenseTypes.Connect,
    ["Nexus"]: LicenseTypes.Nexus,
    ["HybridConnect"]: LicenseTypes.ClassicConnectHybrid,
    ["HybridNexus"]: LicenseTypes.ClassicNexusHybrid,
    ["HybridConnectNexus"]: LicenseTypes.ConnectNexusHybrid,
}

export const ExitSurveyId = "A9DE53F6-58B3-4539-8369-9B692CCBB70F";

export const Firefox = "Firefox";