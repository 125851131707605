import { isArray } from "lodash";
import { DependencyList, useEffect, useState } from "react";

export const useFilter = <T>(
    collection: T[],
    predicate: (value: T, index: number, array: T[]) => boolean,
    deps?: DependencyList
) => {
    const [filteredCollection, setFilteredCollection] = useState<T[]>([]);
    useEffect(() => {
        let result = [];
        if (isArray(collection)) {
            result = collection.filter(predicate);
        }
        setFilteredCollection(result);
    }, [collection, ...deps]);

    return filteredCollection;
};
