import {GLGlobal, maskThrottle, MessageHelper, NotificationType, ResourceType, unmaskThrottle} from 'gl-commonui';
import uniqBy from 'lodash/uniqBy';
import maxBy from 'lodash/maxBy';
import {UserService} from '@app/service/users';
import {BasicService} from '@app/service/basic';
import {
    ChangeLogType,
    CONSTS,
    ContextHelper,
    DateHelper,
    EventInfo,
    fmtMsg,
    GSSchoolAction,
    HybridEventType,
    SchoolHelper
} from '@app/util';
import {
    ChangeEventModel,
    ChangeEventPropsModel,
    ChangeLogModel,
    ChangeModel,
    ChangePropsModel,
    CIMSService,
    LicenseChangePropsModel,
    PendingChangeModel,
    SchoolAuditLogModel
} from '@app/service/cims';
import {CurriculumType} from '@app/service/class';
import {SchoolLocale} from '@app/locales/localeid';
import {StateType} from './index';
import {setCampusClasses, setCampusClasses4Display} from './school/campus';
import {change} from './school/school';
import {
    CampusChangeType2HybridChangeType,
    ClassChangeType2HybridChangeType,
    SchoolChangeType2HybridChangeType
} from '@app/page/cims/component/cims-consts';

interface Services {
    cims: CIMSService
    user: UserService
    basic: BasicService
}

export interface CIMSState {
    schoolAuditLogs?: SchoolAuditLogModel[]
    changeLogs?: ChangeLogModel[]
    changeEventData?: ChangeEventModel;
    pendingChanges?: PendingChangeModel[]
    changeFieldData?: any
    total?: number
    loading?: boolean
    loading4Modal?: boolean
    campusClassLoading?: {}
    refreshData?: {}
    pendingChangesData?: {}
    totalData?: {},
    clearSelected?: boolean;
    failedBulkApproveDeny?: any[];
    loading4ApproveDeny: boolean;
    ownerUpdated: boolean;
}

let defaultRefreshData = {};
let defaultPendingChangesData = {};
let defaultTotalData = {};
(() => {
    defaultRefreshData[`${ChangeLogType.Hybrid}`] = true;
    defaultRefreshData[`${ChangeLogType.School}`] = false;
    defaultRefreshData[`${ChangeLogType.Campus}`] = false;
    defaultRefreshData[`${ChangeLogType.SchoolClass}`] = false;
    defaultPendingChangesData[`${ChangeLogType.Hybrid}`] = [];
    defaultPendingChangesData[`${ChangeLogType.School}`] = [];
    defaultPendingChangesData[`${ChangeLogType.Campus}`] = [];
    defaultPendingChangesData[`${ChangeLogType.SchoolClass}`] = [];
    defaultTotalData[`${ChangeLogType.Hybrid}`] = 0;
    defaultTotalData[`${ChangeLogType.School}`] = 0;
    defaultTotalData[`${ChangeLogType.Campus}`] = 0;
    defaultTotalData[`${ChangeLogType.SchoolClass}`] = 0;
})();

export default {
    namespace: 'cims',
    state: {
        schoolAuditLogs: [],
        changeLogs: [],
        changeEventData: {},
        pendingChanges: [],
        changeFieldData: {},
        total: 0,
        loading: false,
        loading4Modal: false,
        loading4ApproveDeny: false,
        campusClassLoading: {},
        refreshData: defaultRefreshData,
        pendingChangesData: defaultPendingChangesData,
        totalData: defaultTotalData,
        clearSelected: false,
        failedBulkApproveDeny: [],
        ownerUpdated: false,
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setLoading(state, action) {
            return { ...state, loading: action.payload };
        },
        setModalLoading(state, action) {
            return { ...state, loading4Modal: action.payload };
        },
        setApproveDenyLoading(state, action) {
            return {...state, loading4ApproveDeny: action.payload}
        },
        setCampusClassLoading(state, action) {
            return { ...state, campusClassLoading: {...state.campusClassLoading, ...action.payload} };
        },
        resetChangeEventData(state, action) {
            return { ...state, changeEventData: action.payload };
        },
        resetChangeFieldData(state, action) {
            return { ...state, changeFieldData: action.payload };
        },
        setRefreshDataFlag(state, action) {
            return { ...state, refreshData: action.payload };
        }
    },
    effects: {
        *getSchoolAuditLogs({ payload: { query } }, { call, put, all }, { cims, user }: Services) {
            maskThrottle();
            const { schoolId, ...rest } = query;
            try {
                const { data, totalCount: total} = yield call(cims.getSchoolAuditLog, schoolId, rest);
                const userIds = data.filter(d => d.createdBy != null).map(d=>d.createdBy);
                if (userIds.length > 0) {
                    const users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                    yield put(reload({ schoolAuditLogs: formatSchoolAuditLog(data, users), total }));
                }
                else {
                    yield put(reload({ schoolAuditLogs: data, total }));
                }
            } catch (response) {
            }
            unmaskThrottle();
        },
        *getChangeLogs({ payload: { query } }, { call, put, all }, { cims, user }: Services) {
            maskThrottle();
            const { schoolId, ...rest } = query;
            try {
                const { data, totalCount: total} = yield call(cims.getChangeLogs, schoolId, rest);
                const userIds = data.filter(d => d.createdBy != null).map(d=>d.createdBy);
                if (userIds.length > 0) {
                    const users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                    yield put(reload({ changeLogs: formatChangeLog(data, users), total, loading: total > 0 }));
                }
                else {
                    yield put(reload({ changeLogs: data, total, loading: total > 0 }));
                }
            } catch (response) {
            }
            unmaskThrottle();
        },
        *getChangeEventData({ payload: { schoolId, eventId, target } }, { call, put, all }, { cims, user }: Services) {
            yield put(setModalLoading(true));
            try {
                const data = yield call(cims.getChangeEventData, schoolId, eventId, target);
                const userIds = [data.createdBy];
                if (userIds) {
                    const users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                    yield put(reload({ changeEventData: formatChangeEventData(data, users) }));
                }
                else {
                    yield put(reload({ changeEventData: data }));
                }
            } catch (response) {
            }
            yield put(setModalLoading(false));
        },
        *getPendingList({ payload }, { call, put, all }, { cims, user }: Services) {
            maskThrottle();
            try {
                const { groupEnabled, keyword, ...rest } = payload;
                const query = groupEnabled || !keyword ? rest : { keyword, ...rest };
                const result = yield call(cims.getPendingList, query, groupEnabled);
                yield put(reload({
                    pendingChanges: formatPendingList(groupEnabled? result: result.data),
                    total: groupEnabled? result.length : result.totalCount
                }));
        } catch (response) {
        }
            unmaskThrottle();
        },
        *getPendingChanges({ payload }, { call, put, select, all }, { cims, user }: Services) {
            maskThrottle();
            try {
                const { groupEnabled, keyword, target, ...rest } = payload;
                const query = groupEnabled || !keyword ? rest : { keyword, ...rest };
                const result = yield call(cims.getPendingChanges, target, query, groupEnabled);
                let currentPendingChangesData = yield select(
                    (state: StateType) => state.cims.pendingChangesData
                );
                let users = [];
                const userIds = result.data.map(pendingChange => {
                    const changeLog = getPendingChangeLog(pendingChange.changes);
                    return changeLog ? changeLog : null
                }).filter(log => log != null).map(log=>log.createdBy);
                if (userIds.length > 0) {
                    users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                }
                currentPendingChangesData[`${target}`] = formatPendingChange(target, result.data, users);
                let currentTotalData = yield select(
                    (state: StateType) => state.cims.totalData
                );
                currentTotalData[`${target}`] = result.totalCount;
                yield put(reload({
                    pendingChangesData: currentPendingChangesData,
                    totalData: {...currentTotalData}
                }));
        } catch (response) {
        }
            unmaskThrottle();
        },
        *assignOwner({ payload }, { call, put, select, all }, { cims }: Services) {
            maskThrottle();
            try {
                const { changesGroupId, ownerId, ownerName, target } = payload;
                yield call(cims.assignOwner, changesGroupId, ownerId);
                let currentPendingChangesData = yield select(
                    (state: StateType) => state.cims.pendingChangesData
                );
                currentPendingChangesData[`${target}`] = {...currentPendingChangesData}[`${target}`].map(pendingChange => {
                    if (pendingChange.changesGroupId === changesGroupId) {
                        return {...pendingChange, ownerName: ownerName, ownerId: ownerId}
                    }
                    return pendingChange;
                });
                yield put(reload({
                    pendingChangesData: currentPendingChangesData,
                    ownerUpdated: true,
                }));
                MessageHelper.Message(NotificationType.Success, fmtMsg(SchoolLocale.CIMSPendingChangesOwnerAssignedSuccessMessage));
            } catch (response) {
                MessageHelper.Message(NotificationType.Failed, fmtMsg(SchoolLocale.CIMSPendingChangesOwnerAssignedErrorMessage));
            }
            unmaskThrottle();
        },
        *removeOwner({ payload }, { call, put, select, all }, { cims }: Services) {
            maskThrottle();
            try {
                const { changesGroupId, target } = payload;
                yield call(cims.removeOwner, changesGroupId);
                let currentPendingChangesData = yield select(
                    (state: StateType) => state.cims.pendingChangesData
                );
                currentPendingChangesData[`${target}`] = {...currentPendingChangesData}[`${target}`].map(pendingChange => {
                    if (pendingChange.changesGroupId === changesGroupId) {
                        return {...pendingChange, ownerName: null, ownerId: null}
                    }
                    return pendingChange;
                });
                yield put(reload({
                    pendingChangesData: currentPendingChangesData,
                    ownerUpdated: true,
                }));
                MessageHelper.Message(NotificationType.Success, fmtMsg(SchoolLocale.CIMSPendingChangesOwnerRemovedSuccessMessage));
            } catch (response) {
                MessageHelper.Message(NotificationType.Failed, fmtMsg(SchoolLocale.CIMSPendingChangesOwnerRemovedErrorMessage));
            }
            unmaskThrottle();
        },
        *getPendingChangesDetail({ payload: { target, schoolId, campusId, classId, types, isFutureClassChange } }, { call, put, all }, { cims, user }: Services) {
            yield put(setModalLoading(true));
            try {
                const {changeDetail, changeFields} = yield call(cims.getPendingChangesDetail, target, schoolId, campusId, classId, types, isFutureClassChange);
                const userIds = changeDetail.filter(d => d.eventCreatedBy != null).map(d=>d.eventCreatedBy);
                const users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                switch (target) {
                    case ChangeLogType.SchoolClass:
                        if (types.find(type => type == EventInfo.PendingChangesChangeClassUnitPlan)) {
                            const changeFieldData = formatChangeFieldData(changeDetail, users);
                            const units = getUnits(changeFieldData);
                            const unitColumns = getUnitPlanColumns(units);
                            const changeData = formatUnitPlanChangeData(changeFieldData, unitColumns);
                            const unitPlanChanges = formatUnitPlanChanges(changeFieldData, unitColumns);
                            const previousUnits = unitPlanChanges.length > 1 ? unitPlanChanges[unitPlanChanges.length - 1] : [];
                            const currentUnits = unitPlanChanges.length > 0 ? unitPlanChanges[0] : [];
                            yield put(reload({
                                changeFieldData: {
                                    changeData,
                                    unitColumns,
                                    previousUnits,
                                    currentUnits
                            }}));
                        }
                        else if (types.find(type => type == EventInfo.PendingChangesChangeLicense || type == EventInfo.PendingChangesMoveStudent || type == EventInfo.PendingChangesRemoveStudent || type == EventInfo.PendingChangesMoveUnregisteredStudent)) {
                            yield put(reload({ changeFieldData: formatLicenseChangeData(changeDetail, users) }));
                        }
                        else if (types.find(type => type == EventInfo.PendingChangesChangeClassMandatoryData)) {
                            yield put(reload({ changeFieldData: formatChangeFieldData(changeDetail, users, changeFields.map(changes=> changes.changeField)) }));
                        }
                        else {
                            yield put(reload({ changeFieldData: formatChangeFieldData(changeDetail, users) }));
                        }
                        break;
                    case ChangeLogType.School:
                    case ChangeLogType.Campus:
                    default:
                        yield put(reload({ changeFieldData: formatChangeFieldData(changeDetail, users) }));
                        break;
                }

            } catch (response) {
            }
            yield put(setModalLoading(false));
        },
        *getChangeFieldData({ payload: { target, schoolId, campusId, classId, types } }, { call, put, all }, { cims, user }: Services) {
            yield put(setModalLoading(true));
            try {
                const {schoolClassDetail, changeFields} = yield call(cims.getChangeFieldData, schoolId, classId, types);
                const userIds = schoolClassDetail.filter(d => d.eventCreatedBy != null).map(d=>d.eventCreatedBy);
                const users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
                if (types.find(type => type == EventInfo.PendingChangesChangeClassUnitPlan)) {
                    const changeFieldData = formatChangeFieldData(schoolClassDetail, users);
                    const units = getUnits(changeFieldData);
                    const unitColumns = getUnitPlanColumns(units);
                    const changeData = formatUnitPlanChangeData(changeFieldData, unitColumns);
                    yield put(reload({
                        changeFieldData: {
                            changeData,
                            unitColumns,
                    }}));
                }
                else if (types.find(type => type == EventInfo.PendingChangesChangeLicense || type == EventInfo.PendingChangesMoveStudent || type == EventInfo.PendingChangesRemoveStudent || type == EventInfo.PendingChangesMoveUnregisteredStudent)) {
                    yield put(reload({ changeFieldData: formatLicenseChangeData(schoolClassDetail, users) }));
                }
                else if (types.find(type => type == EventInfo.PendingChangesChangeClassMandatoryData)) {
                    yield put(reload({ changeFieldData: formatChangeFieldData(schoolClassDetail, users, changeFields.map(changes=> changes.changeField)) }));
                }
                else {
                    yield put(reload({ changeFieldData: formatChangeFieldData(schoolClassDetail, users) }));
                }
            } catch (response) {
            }
            yield put(setModalLoading(false));
        },
        *approveDenyPending({ payload: { target, schoolId, campusId, classId, types, approve, eventId, data, skipReload, ...rest } }, { call, put }, { cims }: Services) {
            maskThrottle();
            yield put(setApproveDenyLoading(true));
            try {
				if (approve) {
                    yield call(
                        cims.approvePendingChanges,
                        target,
                        schoolId,
                        campusId,
                        classId,
                        types,
                        eventId
                    );
                } else {
                    yield call(
                        cims.denyPendingChanges,
                        target,
                        schoolId,
                        campusId,
                        classId,
                        types,
                        data,
                        eventId
                    );
                }
            } catch (response) {
            }
            unmaskThrottle();
            // while using this api for school/campus change history, we dont need to reload pending changes
            if (skipReload) {
                yield put(setApproveDenyLoading(false));
                return;
            }
            yield put(getPendingChanges({target: ChangeLogType.Hybrid, ...rest}));
            yield put(setApproveDenyLoading(false));
        },
        *bulkApproveDenyPending({ payload: { items, approve, ...rest } }, { call, put }, { cims }: Services) {
            maskThrottle();
            const failedJobs = [];
            for (let i=0;i<items.length;i++) {
                const {target, schoolId, campusId, schoolClassId: classId, types, eventId} = items[i];
                try {
                    if (approve) {
                        yield call(
                            cims.approvePendingChanges,
                            target,
                            schoolId,
                            campusId,
                            classId,
                            types,
                            eventId
                        );
                    } else {
                        yield call(
                            cims.denyPendingChanges,
                            target,
                            schoolId,
                            campusId,
                            classId,
                            types,
                            undefined,
                            eventId
                        );
                    }
                } catch (err) {
                    failedJobs.push(items[i]);
                };
            }
            unmaskThrottle();
            yield put(reload({clearSelected: true, failedBulkApproveDeny: failedJobs}));
            yield put(getPendingChanges({target: ChangeLogType.Hybrid, ...rest}));
        },
        *approveByClass({ payload: { schoolId, campusId, classId } }, { call, put, select }, { cims }: Services) {
            maskThrottle();
            const current = yield select((state: StateType) => state.school.current);
            try {
                const result = GLGlobal.isActionValid(GSSchoolAction.AcknowledgeClass) ?
                    yield call(cims.approveByClass, schoolId, classId) :
                    yield call(cims.approveByEventType, schoolId, classId, [EventInfo.PendingChangesChangeClassMandatoryData, EventInfo.PendingChangesChangeClassUnitPlan]);
                yield put(change({...current, pendingChangesCount: result.pendingChangesCount}));
            } catch (response) {
            }
            unmaskThrottle();
            yield put(getCampusClassList({schoolId, campusId}));
        },
        *approveBySchool({ payload: { schoolId } }, { call, put }, { cims }: Services) {
            maskThrottle();
            try {
                yield call(cims.approveBySchool, schoolId);
            } catch (response) {
            }
            unmaskThrottle();
            window.location.reload();
        },
        *getCampusClassList({ payload: {schoolId, campusId} }, { select, call, put }, { cims, user }: Services) {
            try {
                let campusClassLoading = {};
                campusClassLoading[campusId] = true;
                yield put(setCampusClassLoading(campusClassLoading));
                const data = yield call(cims.getCampusClasses, schoolId, campusId);
                let campusClasses = yield select((state: StateType) => state.campus.campusClasses);
                campusClasses[campusId] = formatCampusClasses(campusId, data);
                yield put(setCampusClasses(campusClasses));
                let campusClasses4Display = yield select((state: StateType) => state.campus.campusClasses4Display);
                campusClasses4Display[campusId] = formatCampusClasses(campusId, data);
                yield put(setCampusClasses4Display(campusClasses4Display));
                campusClassLoading[campusId] = false;
                yield put(setCampusClassLoading(campusClassLoading));
            } catch (response) {
            }
        },
        *queryCampusClassList({ payload: {campusId, filter} }, { select, call, put }, { cims, user }: Services) {
            try {
                let campusClassLoading = {};
                campusClassLoading[campusId] = true;
                yield put(setCampusClassLoading(campusClassLoading));
                const campusClasses = yield select((state: StateType) => state.campus.campusClasses);
                let campusClasses4Display = yield select((state: StateType) => state.campus.campusClasses4Display);
                campusClasses4Display[campusId] = filterCampusClasses(filter, campusClasses[campusId]);
                yield put(setCampusClasses4Display(campusClasses4Display));
                campusClassLoading[campusId] = false;
                yield put(setCampusClassLoading(campusClassLoading));
            } catch (response) {
            }
        }
    },
    services: {
        cims: CIMSService,
        user: UserService,
        basic: BasicService
    }
}

function  formatSchoolAuditLog(logs: any[], users: string[]=[]) {
    return logs.map(log => {
        log.type4Display = fmtMsg({id: CONSTS.CIMSEventType4Display.get(log.type)});
        log.createdBy = log.createdBy ? getUserName(log.createdBy, users) : "";
        log.createdDate = DateHelper.formatDateTime2Local(log.createdDate, false, null, true);
        return log;
    });
}

function formatChangeLog(logs: any[], users: string[]=[]) {
    return logs.map(log => {
        log.type4Display = fmtMsg({id: CONSTS.CIMSEventType4Display.get(log.type)});
        log.createdBy = log.createdBy ? getUserName(log.createdBy, users) : "";
        return log;
    });
}

function  formatChangeEventData(data: any, users: string[]=[]) {
    if (users.length == 0) return data;
    data.createdBy = getUserName(data.createdBy, users);
    data.createdDate = DateHelper.formatDateTime2Local(data.createdDate, true, null, true);
    return data;
}
function getUserName(userId, users) {
    const user = users.find(user => user.id == userId);
    return user ? user.name : userId;
}
function getUnitPlanIndexedData(pendingChange: PendingChangeModel) {
    const plansIndexed = {};
    pendingChange.plans.forEach((plan, index) => {
        plansIndexed[`plansIndexed${index+1}`] = DateHelper.formatDate2Local(plan);
    });
    return plansIndexed;
}
function  formatPendingList(pendingChanges: PendingChangeModel[], users: string[]=[]) {
    const schoolLicenseType = SchoolHelper.generateSchoolCurriculumType();
    return pendingChanges.map(pendingChange => {
        pendingChange.curriculumType4Display = schoolLicenseType.get(`${pendingChange.curriculumType}`);
        pendingChange.startDate4Display = DateHelper.formatDate2Local(pendingChange.startDate);
        pendingChange.startDate4ClientSort = DateHelper.formatDate2LocalDate(pendingChange.startDate);
        pendingChange.startUnit4Display = Math.abs(pendingChange.startUnit);
        pendingChange.endUnit4Display = Math.abs(pendingChange.endUnit);
        pendingChange.updateTime4Display = DateHelper.formatDateTime2Local(pendingChange.updateTime, true);
        pendingChange.updateTime4ClientSort = DateHelper.formatDate2LocalDate(pendingChange.updateTime);
        return {...pendingChange, ...getUnitPlanIndexedData(pendingChange)};
    });
}

function getMaxCaseNoNumber(caseNos: (string | undefined)[]) {
    return caseNos.reduce((max, caseNo) => {
        if (!caseNo) return max;
        const splitNumbers = caseNo.split("-");
        if (splitNumbers.length < 2 || isNaN(parseInt(splitNumbers[0])) || isNaN(parseInt(splitNumbers[1]))) return max;
        return Math.max(max, parseInt(splitNumbers[0]), parseInt(splitNumbers[1]));
    }, 0);
}

function getCaseNo4ClientSort(caseNo: string, numberOfDigits: number) {
    try {
        const splitNumbers = caseNo.split("-");
        const regionNumber = parseInt(splitNumbers[0]);
        const serialNumber = parseInt(splitNumbers[1]);
        if (regionNumber && serialNumber) {
            return `${String(regionNumber).padStart(numberOfDigits, '0')}-${String(serialNumber).padStart(numberOfDigits, '0')}`;
        }
        return caseNo;
    } catch (e) {
        return caseNo;
    }
}

function  formatPendingChange(target: ChangeLogType, pendingChanges: PendingChangeModel[], users: string[]=[]) {
    const result = [...pendingChanges].map(pendingChange => {
        switch (target) {
            case ChangeLogType.Hybrid:
                return formatPendingChange4Hybrid(pendingChange, users);
            case ChangeLogType.Campus:
                return formatPendingChange4Campus(pendingChange, users);
            case ChangeLogType.SchoolClass:
                return {
                    ...formatPendingChange4Class(pendingChange, users),
                    ...getUnitPlanIndexedData(pendingChange)
                };
            case ChangeLogType.School:
            default:
                return formatPendingChange4School(pendingChange, users);
        }
    });
    const maxCaseNoNumber = getMaxCaseNoNumber(pendingChanges.map(pendingChange => pendingChange.caseNo));
    if (maxCaseNoNumber) {
        return result.map(pendingChange => ({
            ...pendingChange,
            caseNo4ClientSort: pendingChange.caseNo ? getCaseNo4ClientSort(pendingChange.caseNo, maxCaseNoNumber.toString().length) : "",
        }))
    }
    return result;
}
function  formatPendingChange4Hybrid(pendingChange: PendingChangeModel, users: string[]=[]) {
    const changeLog = getPendingChangeLog(pendingChange.changes);
    return {
        ...pendingChange,
        changeType4Group: getChangeType4Group(pendingChange),
        updateBy: changeLog.createdBy ? getUserName(changeLog.createdBy, users) : "",
        updateTime4Display: DateHelper.formatDateTime2Local(changeLog.creationDate, true),
        updateTime4ClientSort: DateHelper.formatDate2LocalDate(changeLog.creationDate),
    };
}

function getChangeType4Group(pendingChange: PendingChangeModel): HybridEventType {
    switch (pendingChange.resourceType) {
        case ResourceType.School:
            return SchoolChangeType2HybridChangeType.get(pendingChange.changeType);
        case ResourceType.Campus:
            return CampusChangeType2HybridChangeType.get(pendingChange.changeType);
        case ResourceType.SchoolClass:
        default:
            return ClassChangeType2HybridChangeType.get(pendingChange.changeType);
    }
}

function getPendingChangeLog(changes: ChangeModel[]): ChangeModel {
    return maxBy(changes, ChangePropsModel.creationDate);
}

function  formatPendingChange4School(pendingChange: PendingChangeModel, users: string[]=[]) {
    return {
        ...pendingChange,
        updateTime4Display: DateHelper.formatDateTime2Local(pendingChange.updateTime, true),
        updateTime4ClientSort: DateHelper.formatDate2LocalDate(pendingChange.updateTime),
    };
}
function  formatPendingChange4Campus(pendingChange: PendingChangeModel, users: string[]=[]) {
    return {
        ...pendingChange,
        updateTime4Display: DateHelper.formatDateTime2Local(pendingChange.updateTime, true),
        updateTime4ClientSort: DateHelper.formatDate2LocalDate(pendingChange.updateTime),
    };
}
function  formatPendingChange4Class(pendingChange: PendingChangeModel, users: string[]=[]) {
    const schoolCurriculumType = SchoolHelper.generateSchoolCurriculumType();
    return {
        ...pendingChange,
        ...getUnitPlanIndexedData(pendingChange),
        curriculumType4Display: schoolCurriculumType.get(`${pendingChange.curriculumType}`),
        startDate4Display: DateHelper.formatDate2Local(pendingChange.startDate),
        startDate4ClientSort: DateHelper.formatDate2LocalDate(pendingChange.startDate),
        startUnit4Display: Math.abs(pendingChange.startUnit),
        endUnit4Display: Math.abs(pendingChange.endUnit),
        updateTime4Display: DateHelper.formatDateTime2Local(pendingChange.updateTime, true),
        updateTime4ClientSort: DateHelper.formatDate2LocalDate(pendingChange.updateTime),
    };
}
export function matchedUnitPlanStartDate(filter: string, campusClasses: PendingChangeModel) {
    return [...Array(8).keys()].map(index => campusClasses[`plansIndexed${index+1}`]).filter(startDate => startDate && startDate.toLowerCase().indexOf(filter.toLowerCase()) > -1).length > 0;
}
function filterPendingChange({keyword: filter}: any, pendingChanges: PendingChangeModel[]) {
    return pendingChanges.filter(pendingChange=> filter.length == 0 ||
        pendingChange.schoolName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        pendingChange.campusName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        pendingChange.schoolClassName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        pendingChange.curriculumType4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        (pendingChange.grade && pendingChange.grade.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
        pendingChange.studentCount.toString() == filter ||
        pendingChange.startDate4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        (pendingChange.tsiLessonsPerWeek && pendingChange.tsiLessonsPerWeek.toString() == filter) ||
        pendingChange.startUnit4Display.toString() == filter ||
        pendingChange.endUnit4Display.toString() == filter ||
        matchedUnitPlanStartDate(filter, pendingChange) ||
        pendingChange.updateTime4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1)
}
function  formatChangeFieldData(data: any[], users: string[]=[], changeFields=null) {
    return data.map((eventData, index) => {
        eventData.createdBy = eventData.eventCreatedBy && users.length > 0 ? getUserName(eventData.eventCreatedBy, users) : "";
        eventData.createdDate = DateHelper.formatDateTime2Local(eventData.eventCreatedDate, true, null, true);
        if (changeFields) { eventData['changeFields'] = changeFields; }
        return eventData;
    });
}
function getUnits(unitPlanChangeData) {
    return uniqBy(unitPlanChangeData.filter((upd, index, array) => index == 0 || index == array.length - 1).reduce((pre, cur, idx) => {
        return pre.concat((idx == 0 ? cur.currentPlans : cur.previousPlans).map(plan => {return {unit: plan.unit, curriculumType: cur.curriculumType}}));
    }, []), 'unit').sort((a, b)=> Math.abs(a.unit) - Math.abs(b.unit));
}
function getUnitColumn(log, unit) {
    const unit4Display = Math.abs(unit);
    return log.curriculumType == CurriculumType.GrapeSEED ?
        {
            displayName: fmtMsg({id: SchoolLocale.CIMSPendingChangesGrapeSeedUnitColumn}, {unit: unit4Display}),
            columnName: `U${unit4Display}`
        } :
        {
            displayName: fmtMsg({id: SchoolLocale.CIMSPendingChangesLittleSeedUnitColumn}, {unit: unit4Display}),
            columnName: `LS${unit4Display}`
        };
}

function getUnitPlanColumns(units) {
    return units.map((unit, index) => {
        const unitColumn = getUnitColumn(unit, unit.unit);
        return {
            title: unitColumn.displayName,
            dataIndex: unitColumn.columnName,
            width: 100,
        }
    });
}
function formatUnitPlanLog(unitPlanLog, plans, index) {
    let change = { index };
    plans.forEach(unitPlan => {
        const unitColumn = getUnitColumn(unitPlanLog, unitPlan.unit);
        const startDate = unitPlan.startDate;
        change[unitColumn.columnName] = startDate ? DateHelper.formatDate2Local(unitPlan.startDate) : '　';
    });
    change[ChangeEventPropsModel.schoolClassName] = unitPlanLog.schoolClassName
    change[ChangeEventPropsModel.createdBy] = unitPlanLog.createdBy;
    change[ChangeEventPropsModel.createdDate] = DateHelper.formatDateTime2Local(unitPlanLog.eventCreatedDate, true, null, true);
    return change;
}
function formatUnitPlanChangeData(changeData, unitColumns) {
    return changeData.length > 1 ?
     changeData.filter((upd, index, array) => index == 0 || index == array.length - 1).map((log, index) => {
         const plans = index == 0 ? log.currentPlans : log.previousPlans;
         return formatUnitPlanLog(log, plans, index);
     }).sort((a, b) => -a.createdDate.localeCompare(b.createdDate, 'en')) :
     changeData.map(log => formatUnitPlanLog(log, log.currentPlans, 0))
     .concat(changeData.map(log => formatUnitPlanLog(log, log.previousPlans, 1))).sort((a, b) => -a.createdDate.localeCompare(b.createdDate, 'en'));
}
function formatUnitPlan(changeData, unitPlanLog, plans, dataIndex) {
    return plans.map((unitPlan, index) => {
       const unitColumn = getUnitColumn(unitPlanLog, unitPlan.unit);
        const startDate = unitPlan.startDate;
        const result = {
            index,
            unitTitle: unitColumn.columnName,
            startDate: startDate ? DateHelper.formatDate2Local(unitPlan.startDate) : '　',
            changed: dataIndex == 1 ? false : isUnitChanged(unitPlan.unit, changeData[changeData.length -1].previousPlans, changeData[0].currentPlans),
        }
         return result;
    });
}
function isUnitChanged(unit, previousPlans, currentPlans) {
    const previousPlan = previousPlans.find(plan => plan.unit == unit);
    const currentPlan = currentPlans.find(plan => plan.unit == unit);
    return (previousPlan && currentPlan && previousPlan.startDate != currentPlan.startDate) ||
        (!previousPlan && currentPlan && currentPlan.startDate);
}
function formatUnitPlanChanges(changeData, unitColumns) {
    return changeData.length > 1 ?
     changeData.filter((upd, index, array) => index == 0 || index == array.length - 1).map((log, index) => {
         const plans = index == 0 ? log.currentPlans : log.previousPlans;
         return formatUnitPlan(changeData, log, plans, index);
     }) :
     changeData.map(log => formatUnitPlan(changeData, log, log.currentPlans, 0))
     .concat(changeData.map(log => formatUnitPlan(changeData, log, log.previousPlans, 1)));
}
function formatLicenseChangeData(changeData, users: string[]=[]) {
    return changeData.map((log, index) => {
        log['index'] = index;
        log[LicenseChangePropsModel.eventType4Display] = fmtMsg({id: CONSTS.CIMSEventType4Display.get(log.eventType)});
        log[ChangeEventPropsModel.createdBy] = log.eventCreatedBy ? getUserName(log.eventCreatedBy, users) : "";
        log[ChangeEventPropsModel.createdDate] = DateHelper.formatDateTime2Local(log.eventCreatedDate, true, null, true);
        return log;
    }).sort((a, b) => -a.createdDate.localeCompare(b.createdDate, 'en'));
}
function  formatCampusClasses(campusId: string, campusClasses: PendingChangeModel[], users: string[]=[]) {
    const schoolLicenseType = SchoolHelper.generateSchoolCurriculumType();
    return campusClasses.map((campusClass, index) => {
        campusClass['index'] = index;
        campusClass.curriculumType4Display = schoolLicenseType.get(`${campusClass.curriculumType}`);
        campusClass.startDate4Display = DateHelper.formatDate2Local(campusClass.startDate);
        campusClass.startUnit4Display = Math.abs(campusClass.startUnit);
        campusClass.endUnit4Display = Math.abs(campusClass.endUnit);
        campusClass.updateTime4Display = campusClass.updateTime ? DateHelper.formatDateTime2Local(campusClass.updateTime, true, null, true) : '';
        return {...campusClass, ...getUnitPlanIndexedData(campusClass)};
    });
}
function filterCampusClasses(filter: string, campusClasses: PendingChangeModel[]) {
    return campusClasses.filter(campusClass=> filter.length == 0 ||
        campusClass.schoolClassName.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        campusClass.curriculumType4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        (campusClass.grade && campusClass.grade.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
        campusClass.studentCount.toString() == filter ||
        campusClass.startDate4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        (campusClass.tsiLessonsPerWeek && campusClass.tsiLessonsPerWeek.toString() == filter) ||
        campusClass.startUnit4Display.toString() == filter ||
        campusClass.endUnit4Display.toString() == filter ||
        matchedUnitPlanStartDate(filter, campusClass) ||
        campusClass.updateTime4Display.toLowerCase().indexOf(filter.toLowerCase()) > -1)
}
export function reload(state) {
    return { type: 'cims/reload', payload: state }
}
export function setLoading(state) {
    return { type: 'cims/setLoading', payload: state  }
}
export function setModalLoading(state) {
    return { type: 'cims/setModalLoading', payload: state  }
}
export function setCampusClassLoading(state) {
    return { type: 'cims/setCampusClassLoading', payload: state  }
}
export function setApproveDenyLoading(state) {
    return { type: 'cims/setApproveDenyLoading', payload: state  }
}
export function getChangeLogs(state) {
    return { type: 'cims/getChangeLogs', payload: state }
}
export function getSchoolAuditLogs(state) {
    return { type: 'cims/getSchoolAuditLogs', payload: state }
}
export function getChangeEventData(state) {
    return { type: 'cims/getChangeEventData', payload: state }
}
export function getPendingList(state) {
    return { type: 'cims/getPendingList', payload: state }
}
export function getPendingChanges(state) {
    return { type: 'cims/getPendingChanges', payload: state }
}
export function assignOwner(state) {
    return { type: 'cims/assignOwner', payload: state }
}
export function removeOwner(state) {
    return { type: 'cims/removeOwner', payload: state }
}
export function getPendingChangesDetail(state) {
    return { type: 'cims/getPendingChangesDetail', payload: state }
}
export function getChangeFieldData(state) {
    return { type: 'cims/getChangeFieldData', payload: state}
}
export function approveDenyPending(state) {
    return { type: 'cims/approveDenyPending', payload: state}
}
export function bulkApproveDenyPending(state) {
    return { type: 'cims/bulkApproveDenyPending', payload: state}
}
export function approveByClass(state) {
    return { type: 'cims/approveByClass', payload: state}
}
export function approveBySchool(state) {
    return { type: 'cims/approveBySchool', payload: state}
}
export function resetChangeEventData(state) {
    return { type: 'cims/resetChangeEventData', payload: state}
}
export function resetChangeFieldData(state) {
    return { type: 'cims/resetChangeFieldData', payload: state}
}
export function getCampusClassList(state) {
    return { type: 'cims/getCampusClassList', payload: state}
}
export function queryCampusClassList(state) {
    return { type: 'cims/queryCampusClassList', payload: state}
}
export function setRefreshDataFlag(state) {
    return { type: 'cims/setRefreshDataFlag', payload: state}
}
