import * as React from 'react'
import { Dropdown, Icon, Menu, Avatar, Modal } from 'antd-min';
import { GLGlobal, connect, GLUtil, Logo, alignPop, withRouter } from 'gl-commonui';
import { get, goHome, getSchools, showModal, switchSchool } from '../../states/school/school';
import { getPrimary } from '../../states/school/campus';
import { StateType } from '@app/states';
import { IconFont } from '@app/util/consts';
import { SchoolModel } from '../../service/schools';
import { PathConfig, AdminPathConfig } from '../../config/pathconfig';
import { isGuid } from '../../util/func';
import { SchoolLocale, DashboardLocale } from '../../locales/localeid';
import classNames from "classnames";


interface SchoolInfo {
    id: string
    name: string
    address: string
    phone: string
}
interface SchoolInfoHeaderProps {
    get?: (d?) => void
    getSchools?: () => void
    goHome?: (d) => void
    getPrimary?: (d) => void
    showModal?: (d) => void
    switchSchool?: (d) => void
    current?: SchoolModel
    list?: SchoolModel[]
    isSignin?: boolean
    showLogo?: boolean
    isModalShow?: boolean
}
@connect(({ oidc: { loginInfo, showLogo }, school: { current, list, isModalShow } }: StateType) => ({
    showLogo,
    isSignin: loginInfo && loginInfo.loggedin,
    current,
    list,
    isModalShow
}), { get, getSchools, goHome, getPrimary, showModal, switchSchool })
@withRouter
export class SchoolInfoHeader extends React.Component<SchoolInfoHeaderProps> {
    throttle = true
    grapeseedLogoForcedOnPages = [AdminPathConfig.ContentRevisionHistory,
    AdminPathConfig.ChangeLog,
        "\/change-log\/[0-9]+\/release",
        "\/change-log\/[0-9]+"] // the pages where grapeseed logo has to be shown (Regex)
    componentWillReceiveProps(nextProps) {
        setTimeout(() => {
            if (nextProps.isSignin && !nextProps.showLogo && GLUtil.pathParse({ path: PathConfig.Schools }).schoolId && this.schoolChanged() && this.throttle) {
                this.throttle = false
                this.getHeaderInfo();
                setTimeout(() => this.throttle = true, 1000)
            }
        }, 50)
    }
    schoolChanged() {
        let pre = this.props.current.id;
        let cur = GLUtil.pathParse(PathConfig.Schools).schoolId;
        let changed = pre !== cur && isGuid(pre) && isGuid(cur) || pre == undefined;
        if (changed === false) changed = Object.getOwnPropertyNames(this.props.current).length <= 2
        return changed;
    }
    getHeaderInfo(id = {}) {
        const { get, getSchools, getPrimary } = this.props;
        get(id);
        getPrimary(id);
        getSchools();
    }
    renderSchoolLogo() {
        return (
            <div className="gl-header-logo">
                <Logo />
                <div className="divider"></div>
                <h2>{GLGlobal.intl.formatMessage({ id: DashboardLocale.HeaderSiteTitleGlobal })}</h2>
            </div>
        )
    }
    selectSchool(params) {
        const currentId = GLUtil.pathParse(PathConfig.Schools).schoolId;
        const nextId = params.key;
        if (currentId == nextId) {
            this.props.showModal(false);
        }
        else {
            this.props.switchSchool({ id: nextId });
            this.props.goHome({ id: nextId });
        }
    }
    renderSchools() {
        const currentId = GLUtil.pathParse(PathConfig.Schools).schoolId;
        return <div className="school-menu"><Menu onClick={this.selectSchool.bind(this)}>
            {this.props.list && this.props.list.map(info => {
                const menuItemClass = {
                    'menu-item-active': info.id == currentId
                };
                return <Menu.Item key={info.id} className={classNames(menuItemClass)}>{info.name}</Menu.Item>
            })}
        </Menu></div>;
    }
    renderSchoolName() {
        return <span>{this.props.current.name}</span>;
    }
    renderSchoolInfo() {
        return <div className="school-info-content">
            <IconFont type="iconschool" className="icon-school-info" />
            <div className="school-info-content-text">
                {this.renderSchoolName()}
                <span>{this.props.current.phone}</span>
            </div>
        </div>
    }

    isLogoForcedOnPage = () => {
        let path = this.props.history.location.pathname;
        for (let pattern of this.grapeseedLogoForcedOnPages) {
            let rPattern = new RegExp(pattern, "i");
            if (rPattern.test(path)) {
                return true
            }
        }
        return false;
    }

    render() {
        const { isSignin, showLogo, isModalShow, showModal, history } = this.props;
        return isSignin && !showLogo && !this.isLogoForcedOnPage() ? <>
            <div className='school-info-header'>
                {this.renderSchoolLogo()}
            </div>
            {isModalShow && <Modal
                title={GLGlobal.intl.formatMessage({ id: SchoolLocale.BreadMenuModalTitle })}
                visible
                onCancel={() => showModal(false)}
                footer={null}
                className="schools-modal"
            >
                {this.renderSchools()}
            </Modal>}
        </> : <div className="gl-header-logo"><Logo /><div className="divider"></div><h2>{GLGlobal.intl.formatMessage({ id: DashboardLocale.HeaderSiteTitleGlobal })}</h2></div>
    }
}