import { mergeClasses } from "@app/components/survey/functions";
import React, { FC, useState, useEffect } from "react";
import { ICellTemplateContext } from "wijmo/wijmo.react.grid";
import "./checkbox.less";

interface GridCheckboxProps {
    context: ICellTemplateContext;
    className?: string;
    disabled?: boolean;
}

export const GridCheckbox: FC<GridCheckboxProps> = (props) => {
    const { className, context, ...rest } = props;

    return (
        <input
            {...rest}
            className={mergeClasses("grid-checkbox wj-cell-check", className)}
            defaultChecked={context.item[context.col.binding]}
            type="checkbox"
        />
    );
};
export const GridReadOnlyCheckbox: FC<GridCheckboxProps> = (props) => {
    const { className, context, ...rest } = props;
    return (
        <input
            {...rest}
            className={mergeClasses("grid-checkbox wj-cell-check", className)}
            checked={context.item[context.col.binding]}
            readOnly={true}
            type="checkbox"
        />
    );
};
