import * as React from "react";
import { Component } from "react";
import { loadable } from "gl-commonui";
import classNames from "classnames";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./survey-richtexteditor.less";

let EditorState,
    convertToRaw,
    ContentState,
    Modifier = null;
let htmlToDraft = null;
let draftToHtml = null;
const Editor = loadable({
    loader: () => import("react-draft-wysiwyg"),
    export: ({ Editor }) => Editor,
    forwardRef: true
});

interface SurveyRichTextEditorProps {
    readOnly?: boolean;
    editorHtml?: string;
    toolbarCustomButtons?: JSX.Element[];
    customDecorators?: RichTextDecorator[];
    onValueChanged?: (value: string) => void;
    autoShowToolbar?: boolean
    onFocusHandle?: (event) => void
    onBlurHandle?: (event) => void
}

interface SurveyRichTextEditorStates {
    editorState?: any;
    textState: string;
    loaded: boolean;
    wrapperClassName?: any
    editorClassName?: any
    toolbarClassName?: any
    toolbarStyle?: any
}

export interface RichTextDecorator {
    strategy: (contentBlock, callback, contentState) => void;
    component: Function;
}

export class SurveyRichTextEditor extends Component<
    SurveyRichTextEditorProps,
    SurveyRichTextEditorStates
> {
    domEditor: any;
    loadComponent: Promise<any>;
    constructor(props) {
        super(props);
        this.state = {
            textState: "",
            toolbarStyle: {display: 'none'},
            ...this.getEditorClassName(false),
            loaded: false,
        };
        this.getEditorClassName = this.getEditorClassName.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.setDomEditorRef = this.setDomEditorRef.bind(this);
        this.onFocusHandle = this.onFocusHandle.bind(this);
        this.onBlurHandle = this.onBlurHandle.bind(this);
        this.checkFocusWhiteList = this.checkFocusWhiteList.bind(this);
    }

    private setDomEditorRef(ref) {
        this.domEditor = ref;
    }

    componentDidMount() {
        this.loadComponent = Promise.all([
            import("../../../util/html2draft/library/index"),
            import("draftjs-to-html"),
            import("draft-js")
        ]).then(([html2draft, draftjstohtml, draftjs]) => {
            htmlToDraft = html2draft.default;
            draftToHtml = draftjstohtml.default;
            EditorState = draftjs.EditorState;
            convertToRaw = draftjs.convertToRaw;
            ContentState = draftjs.ContentState;
            Modifier = draftjs.Modifier;
            this.setState({ loaded: true });
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.editorHtml != prevState.textState && prevState.loaded) {
                const contentBlock = nextProps.editorHtml
                    ? htmlToDraft(nextProps.editorHtml)
                    : null;
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks,
                        contentBlock.entityMap
                    );
                    const editorState = EditorState.createWithContent(
                        contentState
                    );
                    return { editorState: editorState, textState: nextProps.editorHtml };
                } else {
                    return { editorState: EditorState.createEmpty(), textState: nextProps.editorHtml };
                }
        }

        return {};
    }

    focusEditor(editorState) {
        if (this.domEditor) {
            this.domEditor.focusEditor();
        }
    }

    getEditorClassName(hasFocus) {
        const { autoShowToolbar } = this.props;
        const focus = hasFocus && autoShowToolbar;
        const blur = !hasFocus && autoShowToolbar;
        return {
            wrapperClassName: {
                'survey-richeditor-wrapper-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            editorClassName: {
                'survey-richeditor-editor-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            toolbarClassName: {
                'survey-richeditor-toolbar-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            toolbarStyle: hasFocus ? {display: 'flex'} : {display: 'none'}
        }
    }

    onFocusHandle(event) {
        this.setState(this.getEditorClassName(true));
    }

    onBlurHandle(event) {
        if (this.domEditor 
            && this.domEditor.wrapper
            && this.domEditor.wrapper.parentNode
            && event.relatedTarget) {
                const isWhiteList = this.checkFocusWhiteList(event.relatedTarget, this.domEditor.wrapper.parentNode)
                
                if (isWhiteList) {
                    return false;
                }
        }

        this.setState(this.getEditorClassName(false));
    }

    setEditorState(templateText) {
        const contentBlock = templateText ? htmlToDraft(templateText) : null;
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState: editorState });
        }
        else {
            this.setState({ editorState: EditorState.createEmpty() });
        }
    }

    onEditorStateChange(editorState) {
        this.setState({ editorState: editorState });
        const { onValueChanged } = this.props;
        if (editorState.getLastChangeType() && onValueChanged) {
            let value = draftToHtml(convertToRaw(editorState.getCurrentContent()), null, null, null);
            onValueChanged(value);
        }
    };

    checkFocusWhiteList(target, ref) { 
        const linkTarget = ref.querySelector("input#linkTarget");
        const openLinkInNewWindow = ref.querySelector("input#openLinkInNewWindow");
        
        // White list link target and open in new window for focus instead of blur.
        if (linkTarget === target) {
            return true;
        } 
        if(openLinkInNewWindow === target) {
            return true;
        }

        return false;
    }

    render() {
        const { editorState, wrapperClassName, editorClassName, toolbarClassName, toolbarStyle } = this.state;
        const { readOnly, autoShowToolbar, onFocusHandle, onBlurHandle } = this.props;
        return <Editor readOnly={readOnly}
            editorState={editorState}
            wrapperClassName={classNames(wrapperClassName)}
            editorClassName={classNames(editorClassName)}
            toolbarClassName={classNames(toolbarClassName)}
            toolbarOnFocus={readOnly}
            toolbar={{
                options: readOnly ? [] : ['inline', 'fontSize', 'textAlign', 'list', 'colorPicker', 'link'],
                inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] }
            }}
            toolbarStyle={
                readOnly || autoShowToolbar ? toolbarStyle : {}
            }
            onEditorStateChange={this.onEditorStateChange}
            onFocus={onFocusHandle ? onFocusHandle : (autoShowToolbar ? this.onFocusHandle : null)}
            onBlur={onBlurHandle ? onBlurHandle : (autoShowToolbar ? this.onBlurHandle : null)}
            toolbarCustomButtons={this.props.toolbarCustomButtons}
            customDecorators={this.props.customDecorators}
            ref={this.setDomEditorRef}
        />
    }
}
