import * as React from "react";
import { mergeClasses } from "@app/util";
import "./container.less";

interface ContainerProps extends React.Props<ContainerProps> {
	/** Container add top/bottom padding by default, So to remove it noPaddingY prop can be used */
	noPaddingY?: boolean;

	/** Whether to add class to container element */
	className?: string;

	/** Whether to make the container in full width of screen. */
	fullWidth?: boolean;

	/** Whether to add height=100% to container element */
	fullHeight?: boolean;

	/** Whether to make the container as left aligned. */
	alignLeft?: boolean;
}

export const Container: React.StatelessComponent<ContainerProps> = (props) => {
	const { noPaddingY, children, fullWidth, fullHeight, alignLeft, className } = props;
	let classes = mergeClasses(
		"gs-container",
		!noPaddingY && "gs-container--yspace",
		fullWidth && "gs-container--fw",
		fullHeight && "gs-container--fh",
		alignLeft && "gs-container--left",
		className
	);
	return <div className={classes}>{children}</div>;
};
