import { GLResponse, GLRequest } from "gl-commonui";

export interface ProductModel {
    id?: string;
    name?: string;
    price?: number;
    currency?: string;
    globalProductId?: string;
    type?: number;
    defaultQuantityToLicenseCount?: boolean;
}
export enum ProductPropsModel {
    id = "id",
    name = "name",
    price = "price",
    currency = "currency",
    globalProductId = "globalProductId",
    type="type",
    defaultQuantityToLicenseCount="defaultQuantityToLicenseCount"
}
export interface ProductRequest extends GLRequest, ProductModel {}
export interface ProductResponse extends GLResponse, ProductModel {}
