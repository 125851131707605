import { SchoolLocale } from '../../locales/localeid';
import { MergeDocHelper, GLUtil, GLGlobal, HttpStatusCode, ServerExceptionCode, NotificationType, MessageHelper } from 'gl-commonui';
import { UserService } from '../../service/users';
import { InvitationService } from '../../service/school/invitation';

interface Services {
    user: UserService,
    invitation: InvitationService
}

export interface AcceptInvitationState {
    valid?: boolean
}

export default {
    namespace: 'acceptinvitation',
    state: {
        valid: true,
    },
    reducers: {
        validateCode(state, action) {
            return { ...state, valid: action.payload };
        },
    },
    effects: {
        *validate({ payload: { invitationType, code, callback } }, { call, put }, { invitation }: Services) {
            const data = yield call(invitation.validate, { invitationType, code });
            data.isApplied ? callback(GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationValidErrMsgIsApplied })) : 
                (data.valid ? callback() : callback(GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationValidErrMsg })));
            yield put(validateCode({ valid: data.valid }));
        },
        *getInfoByCode({ payload: { invitationCode, callback } }, { call, put }, { invitation }: Services) {
            const info = yield call(invitation.getInfoByCode, { invitationCode });
            yield put(bindById( info , callback ));
        },
        *bindById({ payload: { invitationCodeType, invitationCodeId, callback } }, { call, put }, { user }: Services) {
            try {
                yield call(user.bindById, { id: GLGlobal.loginInfo().profile.sub, invitationCodeType: invitationCodeType, invitationCodeId: invitationCodeId });
                callback();
            } catch (resp) {
                // if(resp.body.error_code == ServerExceptionCode.TargetDisabledException) {
                    MessageHelper.Message(NotificationType.Failed, resp.body.error_description)
                // }
            }
        }
    },
    services: {
        user: UserService,
        invitation: InvitationService
    }
}

export function validate(state, callback) {
    return { type: 'acceptinvitation/validate', payload: { ...state, callback } }
}

export function validateCode(state) {
    return { type: 'acceptinvitation/validateCode', payload: state }
}

export function getInfoByCode(state, callback) {
    return { type: 'acceptinvitation/getInfoByCode', payload: { ...state, callback } }
}

export function bindById(state, callback) {
    return { type: 'acceptinvitation/bindById', payload: { ...state, callback } }
}