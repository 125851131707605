import { PathConfig } from '@app/config/pathconfig';
import { maskThrottle, unmaskThrottle, RoleName } from 'gl-commonui';
import { GlobalProductModel, LocalProductModel, ProductService4Global, ProductService4Local } from '@app/service/admin/products/index';
import { ContentVersionService, ContentVersionModel } from '@app/service/admin/contentversion/index';
import { RegionModel, RegionService } from '@app/service/admin/regions';
import { contentVersionIsLittleSeed } from '@app/util/func';
import { StateType } from '../index';

export interface ProductState {
    region: RegionModel
    regions: RegionModel[]
    globalModel: GlobalProductModel
    globalList: GlobalProductModel[]
    globalTotal: number
    globalActiveCnt: number
    contentVersions: ContentVersionModel[]
    localModel: LocalProductModel
    localList: LocalProductModel[]
    localTotal: number
    localActiveCnt: number
    localCandidateList: LocalProductModel[]
    loading: boolean
}
interface Services {
    productGlobal: ProductService4Global,
    productLocal: ProductService4Local,
    content: ContentVersionService,
    region: RegionService
}

export default {
    namespace: 'product',
    state: {
        region: {},
        regions: [],
        globalModel: {},
        globalList: [],
        globalTotal: 0,
        globalActiveCnt: 0,
        contentVersions: [],
        localModel: {},
        localList: [],        
        localTotal: 0,
        localActiveCnt: 0,
        localCandidateList: [],
        loading: false
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        }
    },
    effects: {
        *getGlobalProducts({ payload: { query } }, { all, call, put }, { productGlobal, content }: Services): any {
            maskThrottle();
            try {
                const [{ data: products, totalCount: total, extraData: { activeCnt } }, contentVersions] = yield all([
                    call(productGlobal.getItemsBy, { ...query }),
                    call(content.get),
                ]);
                yield put(reload({ globalList: formatProduct(products, contentVersions), globalTotal: total, globalActiveCnt: activeCnt }));
            } catch (response) {
            }
            unmaskThrottle();
        },
        *getCandidateGlobalProducts(action, { all, call, put }, { productGlobal, content }: Services): any {
            try {
                const [data, contentVersions] = yield all([
                    call(productGlobal.getItemsBy),
                    call(content.get),
                ]);
                yield put(reload({ globalList: formatProduct(data, contentVersions) }));
            } catch (response) {
            }
        },        
        *getContentVersions(action, { call, put }, { content }: Services): any {
            maskThrottle();
            try {
                const contentVersions = yield call(content.get);
                yield put(reload({ contentVersions }));
            } catch (response) {
            }
            unmaskThrottle();
        },        
        *createGlobalProduct({ payload: model }, { call, put }, { productGlobal }: Services): any {
            try {
                yield call(productGlobal.create, model);
                yield put(goToGlobalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *getGlobalProduct({ payload: productId }, { call, put }, { productGlobal }: Services): any {
            maskThrottle()
            try {
                const data = yield call(productGlobal.get, { id: productId });
                yield put(reload({ globalModel: data }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *updateGlobalProduct({ payload: model }, { call, put }, { productGlobal }: Services): any {
            try {
                yield call(productGlobal.update, { ...model }, { id: model.id });
                yield put(goToGlobalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *removeGlobalProduct({ payload: id }, { call, put }, { productGlobal }: Services): any {
            try {
                yield call(productGlobal.delete, { id: id });
                yield put(goToGlobalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *goToGlobalProductList(action, { put }, services, { push, pathStringify }): any {
            yield put(push(pathStringify(PathConfig.GlobalProducts)));
        },
        *getLocalProducts({ payload: { query } }, { call, put }, { productLocal, region }: Services, { pathParse }): any {
            maskThrottle();
            try {
                const { data: products, totalCount: total, extraData: { activeCnt } } = yield call(productLocal.getItemsBy, { ...query });
                yield put(reload({ localList: products, localTotal: total, localActiveCnt: activeCnt }));    
            } catch (response) {
                console.log(response);
            }
            unmaskThrottle();
        },
        *getCandidateLocalProducts(action, { call, put }, { productLocal }: Services, { pathParse }): any {
            try {
                const regionId = pathParse({ path: PathConfig.LocalProducts }).regionId;
                const data = yield call(productLocal.getCandidateLocalProducts, regionId);
                yield put(reload({ localCandidateList: data }));
            } catch (response) {
            }
        },
        *createLocalProduct({ payload: model }, { call, put }, { productLocal }: Services, { pathParse }): any {
            try {
                yield call(productLocal.create, {...model});
                yield put(goToLocalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *getLocalProduct({ payload: productId }, { all, call, put }, { productLocal, productGlobal, content }: Services, { pathParse }): any {
            maskThrottle();
            try {
                const data = yield call(productLocal.get, null, { id: productId });
                const [globalCandidates, localCandidates, contentVersions] = yield all([
                    call(productGlobal.getItemsBy),
                    call(productLocal.getCandidateLocalProducts, data.regionId),
                    call(content.get)
                ]);
                // const contentVersions = [{id: "7a63c149-8113-4fdb-9b44-1e767afea468", name: "GSv4", licenseType: 0},
                // {id: "7a63c149-8113-4fdb-9b44-1e767afea477", name: "LSv1", licenseType: 0}];
                yield put(reload({ localModel: data, globalList:formatProduct(globalCandidates, contentVersions), localCandidateList: localCandidates }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *updateLocalProduct({ payload: model }, { call, put }, { productLocal }: Services, { pathParse }): any {
            try {
                yield call(productLocal.update, { ...model }, {id: model.id});
                yield put(goToLocalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *removeLocalProduct({ payload: id }, { call, put }, { productLocal }: Services, { pathParse }): any {
            try {
                yield call(productLocal.delete, null, { id });
                yield put(goToLocalProductList());
            } catch (response) {
                //console.log(response);
            }
        },
        *goToLocalProductList(action, { put }, services, { push, pathStringify }): any {
            yield put(push(pathStringify(PathConfig.LocalProducts)));
        },
        *getCandidateProducts({ payload: regionId }, { all, call, put }, { productGlobal, productLocal, content }: Services, { pathParse }): any {
            maskThrottle();
            try {
                if (regionId) {
                    const [globalCandidates, localCandidates, contentVersions] = yield all([
                        call(productGlobal.getItemsBy),
                        regionId && call(productLocal.getCandidateLocalProducts, regionId),
                        call(content.get)
                    ]);
                    // const contentVersions = [{id: "7a63c149-8113-4fdb-9b44-1e767afea468", name: "GSv4", licenseType: 0},
                    // {id: "7a63c149-8113-4fdb-9b44-1e767afea477", name: "LSv1", licenseType: 0}];
                    yield put(reload({ globalList:formatProduct(globalCandidates, contentVersions), localCandidateList: localCandidates }));    
                }
                else {
                    const [globalCandidates, contentVersions] = yield all([
                        call(productGlobal.getItemsBy),
                        call(content.get)
                    ]);
                    yield put(reload({ globalList:formatProduct(globalCandidates, contentVersions) }));    
                }
            } catch (response) {
            }
            unmaskThrottle();
        },
        *getRegions(action, { call, put }, { productLocal }: Services): any {
            try {
                const regions = yield call(productLocal.getRegions);
                yield put(reload({ regions: regions }));
            } catch (response) {
            }
        }
    },
    services: {
        productGlobal: ProductService4Global,
        productLocal: ProductService4Local,
        content: ContentVersionService,
        region: RegionService
    }
}

function formatProduct(products: GlobalProductModel[], contentVersions: ContentVersionModel[]) {
    return products.map(product => {
        const contentVersion = contentVersions.find(cv => cv.id == product.versionGroupId);
        product.versionGroupName = contentVersion ? contentVersion.name : '';
        product.isLittleSeed = contentVersionIsLittleSeed(product.versionGroupId, contentVersions);
        return product;
    })
}

export function reload(state) {
    return { type: 'product/reload', payload: state }
}
export function getGlobalProducts(state) {
    return { type: 'product/getGlobalProducts', payload: state }
}
export function getCandidateGlobalProducts(state) {
    return { type: 'product/getCandidateGlobalProducts', payload: state }
}
export function getContentVersions() {
    return { type: 'product/getContentVersions' }
}
export function createGlobalProduct(state) {
    return { type: 'product/createGlobalProduct', payload: state }
}
export function updateGlobalProduct(state) {
    return { type: 'product/updateGlobalProduct', payload: state }
}
export function removeGlobalProduct(state) {
    return { type: 'product/removeGlobalProduct', payload: state }
}
export function getGlobalProduct(state) {
    return { type: 'product/getGlobalProduct', payload: state }
}
export function goToGlobalProductList() {
    return { type: 'product/goToGlobalProductList' }
}

export function getLocalProducts(state) {
    return { type: 'product/getLocalProducts', payload: state }
}
export function getCandidateLocalProducts(state) {
    return { type: 'product/getCandidateLocalProducts', payload: state }
}
export function createLocalProduct(state) {
    return { type: 'product/createLocalProduct', payload: state }
}
export function updateLocalProduct(state) {
    return { type: 'product/updateLocalProduct', payload: state }
}
export function removeLocalProduct(state) {
    return { type: 'product/removeLocalProduct', payload: state }
}
export function getLocalProduct(state) {
    return { type: 'product/getLocalProduct', payload: state }
}
export function goToLocalProductList() {
    return { type: 'product/goToLocalProductList' }
}

export function getCandidateProducts(state) {
    return { type: 'product/getCandidateProducts', payload: state }    
}

export function getRegions(state) {
    return { type: 'product/getRegions', payload: state }
}