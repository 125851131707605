import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IRegionGroupService } from './interface';
import { RegionGroupModel, GetRegionGroupRequest, RegionGroupRequest } from './model';

@injectable()
export class RegionGroupService extends GLServiceBase<RegionGroupRequest, GetRegionGroupRequest> implements IRegionGroupService {
    serviceRoute = { prefix: 'admin/v1/regions/groups' }
    errMsgs = (() => {
        const result = {};
        return result;
    })();
    getRegionGroups(params: any) {
        return this.get('', params);
    }
    createRegionGroup(params: any) {
        return this.create(params);
    }
}