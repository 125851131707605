import { DateHelper } from './../helper';
import { UpcomingEventFeatureType } from './enum';
import * as moment from "moment";
import { GLGlobal, RoleName } from 'gl-commonui';

export function showSuggestAlternate(type) {
    if (type == UpcomingEventFeatureType.VisitationCreation ||
        type == UpcomingEventFeatureType.VisitationCoachReschedule ||
        type == UpcomingEventFeatureType.VisitationSchoolAdminReschedule) {
        return true;
    }
    return false;
}

export function showPreStyle(type) {
    if (type == UpcomingEventFeatureType.VisitationCreation ||
        type == UpcomingEventFeatureType.LVAVideoManageReminder ||
        type == UpcomingEventFeatureType.VisitationCoachReschedule ||
        type == UpcomingEventFeatureType.VisitationSchoolAdminReschedule) {
        return true;
    }
    return false;
}

export function showDeleteInvitation(type) {
    if (type == UpcomingEventFeatureType.VisitationSchoolAdminReschedule) {
        return true;
    }
    return false;
}

export function formatDescription(data) {
    const { upcomingEventFeatureType, description, tentativeStartDate, tentativeEndDate, endDate } = data;
    switch(upcomingEventFeatureType) {
        case UpcomingEventFeatureType.VisitationCreation:
        case UpcomingEventFeatureType.VisitationCoachReschedule:
        case UpcomingEventFeatureType.VisitationSchoolAdminReschedule:
        case UpcomingEventFeatureType.VisitationDelete:
            return description.replace(/{StartDate}/gi, DateHelper.formatDate2Local(tentativeStartDate))
                              .replace(/{EndDate}/gi, DateHelper.formatDate2Local(tentativeEndDate));
        case UpcomingEventFeatureType.VisitationStart:
        case UpcomingEventFeatureType.VisitationStartCoachAcception:
        case UpcomingEventFeatureType.LVAStartToSchoolAdmin:
        case UpcomingEventFeatureType.LVAStartToCoach:
        case UpcomingEventFeatureType.LVAStartToTeacher:
            return description.replace(/{StartDate}/gi, DateHelper.formatDate2Local(tentativeStartDate));
        case UpcomingEventFeatureType.LVAVideoManageReminder:
            return description.replace(/{DueDate}/gi, DateHelper.formatDate2Local(endDate));
        default:
            return description;
    }
}

export function clearErrors(form) {
    const errors = form.getFieldsError();
    const errorValues = Object.values(errors);
    const hasErrors = errorValues.some(err => err);
    if (hasErrors) {
        const values = form.getFieldsValue();
        form.resetFields();
        form.setFieldsValue(values);
    }
}

export function getDiffDays(date) {
    const nowMoment = moment(moment(new Date()).format("YYYY-MM-DD"));
    const dateMoment = moment(moment(date).format("YYYY-MM-DD"));
    return dateMoment.diff(nowMoment, "days");
}

export function formatDate(date, h, m, s, ms) {
    return moment.utc(
        date
            .hours(h)
            .minutes(m)
            .seconds(s)
            .milliseconds(ms)
    );
}

export function formatResponseAnswer(answers) {
    let answerString = "";
    answers.forEach(a => {
        answerString += a.answer ? a.answer : "";
    });
    return answerString;
}

export function trimPlainText(text) {
    return text ? text.replace(/&nbsp;/g, '').trim() : "";
}

export function isCoachAndSelf(action, coachId) {
    const userId = GLGlobal.loginInfo().profile.sub;
    const roles = GLGlobal.loginInfo().profile.roles;
    switch(true) {
        case roles.indexOf(RoleName.systemAdmin) > -1:
        case roles.indexOf(RoleName.trainingAdmin) > -1:
        case roles.indexOf(RoleName.regionAdmin) > -1:
        case roles.indexOf(RoleName.accountManager) > -1:
        case roles.indexOf(RoleName.trainingManager) > -1:
        case roles.indexOf(RoleName.schoolAdmin) > -1:
        case roles.indexOf(RoleName.campusAdmin) > -1:
            return GLGlobal.isActionValid(action);
        case roles.indexOf(RoleName.trainer) > -1:
            return GLGlobal.isActionValid(action);
    }
}