import { GLRequest, GLResponse } from 'gl-commonui'

export interface LinkModel{
    title?: string
    url?: string
}

export interface HitModel {
    hit?: string
    links?: LinkModel[]
}

export interface SearchModel {
    category?: string
    hits?: HitModel[]
    isCategoryLinksVisible?:boolean
}

export enum SearchProps {
    search = 'search',
}

export interface SearchRequest extends GLRequest, SearchModel {
}
export interface SearchResponse extends GLResponse, SearchModel {
}