import * as React from "react";
import { GLGlobal } from "gl-commonui";
import { mergeClasses } from "@app/util";
import "./school-page-title.less";

export interface PageTitleProps {
    title?: string;
    plain?: string;
    className?: string;
}
export const MainTitle: React.FC<PageTitleProps> = ({ title, plain = "", className }) => {
    return (
        <div className={mergeClasses("page-title", "main-title", className)}>
            <h1>{title ? GLGlobal.intl.formatMessage({ id: title }) : plain}</h1>
        </div>
    );
};
export const SecondaryTitle: React.FC<PageTitleProps> = ({ title, plain = "", children, className }) => {
    return (
        <div className={mergeClasses("page-title", "secondary-title", className)}>
            <h1>{title ? GLGlobal.intl.formatMessage({ id: title }) : plain}</h1>
            {children}
        </div>
    );
};
export const ThirdTitle: React.FC<PageTitleProps> = ({ title, plain = "", className }) => {
    return (
        <div className={mergeClasses("page-title", "third-title", className)}>
            <h3>{title ? GLGlobal.intl.formatMessage({ id: title }) : plain}</h3>
        </div>
    );
};
