import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '@app/util/di'
import { IRegionService } from './interface';
import { RegionModel, GetRegionRequest, RegionRequest, RegionAdminModel, DurationModel, SchoolClassBillingInfoModel, EventManagerSettingsModel } from './model';
import { GSAdminLocale } from '@app/locales/localeid';
import { TermResponseModel } from '@app/service/class';

@injectable()
export class RegionService extends GLServiceBase<RegionRequest, GetRegionRequest> implements IRegionService {
    serviceRoute = { prefix: 'admin/v1/regions', itemSuffix: '{id}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceRegionNotFound;
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetDisabledException)] = GSAdminLocale.ServiceRegionDisabled;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.InvalidGrant, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.ServiceRegionNoPermission;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.ServiceRegionNotFound;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.ServiceRegionDisabled;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.ServiceRegionNotSave;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = { id: GSAdminLocale.ErrorDeleteForTargetUsed, values: { name: 'region' } };
        return result;
    })();
    formatRegionList(regions: RegionModel[]) {
        return regions;
    }
    createRegion(regionmodel: RegionModel) {
        return this.create(regionmodel);
    }
    getRegions(params: any) {
        return this.getItemsBy('', params);
    }
    getRegionById(params: any) {
        return this.get('{id}', params);
    }
    editRegion(region: RegionModel) {
        return this.update('{id}', region);
    }
    editRegionAdmins(params: any) {
        return this.get('{id}/admins', params);
    }
    replaceContentversions(regions: RegionModel[], contentversions: any[]) {
        return regions.map(region => {
            contentversions.forEach(contentversion => {
                if (region.gsVersionGroupId == contentversion.id) {
                    region.gsContentversion = contentversion.name;
                }
                if (region.lsVersionGroupId == contentversion.id) {
                    region.lsContentversion = contentversion.name;
                }
            });
            return region;
        });
    }
    concatRegionAdmins(regionadmins: RegionAdminModel[], pendingRegionAdmins: RegionAdminModel[]) {
        return regionadmins.concat(pendingRegionAdmins.map(pending => {
            pending.name += "(Pending)";
            return pending;
        }));
    }
    removeRegionAdmin(params: any) {
        return this.delete('{regionId}/users/{userId}', params);
    }
    exportBillingHistory(params: any) {
        return this.getBlob('{regionId}/invoices/excel', params);
    }
    getDashboardData(params: DurationModel) {
        return this.get('dashboard', params);
    }
    downloadDashboardExcel(params: DurationModel) {
        return this.getBlob('dashboard/excel', params);
    }
    getRegionGroups(params: any) {

    }
    getAccessibleRegions() {
        return this.get('accessibleregions');
    }

    /**
     * if current user is region admin, then return all regionIds which he/she is admin
     */
    getRegionsAsRegionAdmin() {
        return this.get('RegionsAsRegionAdmin');
    }

    getCycleInfo(params: { regionId: string }) {
        return this.get("billingCycles/{regionId}", params);
    }
    getSchoolCycleInvoiceGenerated(params: { regionId: string, schoolClassId?: string }): Promise<SchoolClassBillingInfoModel> {
        return this.get("{regionId}/getschoolcycleinvoicegenerated", params);
    }
    getRegionBillingInfo(params: { id: string }): Promise<RegionModel> {
        return this.get("{id}/billinginfo", params);
    }
    resetAnnual(params) {
        return this.update('{id}/campuses/annual', params);
    }
    getSchools(params) {
        return this.get("{regionId}/schools/units/max", null, params);
    }
    getRegionCountryPhoneCode(regionId: string): Promise<string> {
        return this.get("{id}/countryPhoneCode", {id: regionId});
    }
    getRegionPhoneRegistrationSetting(regionId: string): Promise<boolean> {
        return this.get("{id}/phoneRegistrationSetting", {id: regionId});
    }
    getEnforceUnitPlanSpacing(regionId: string): Promise<boolean> {
        return this.get("enforceunitplanspacing/{id}", null, {id: regionId});
    }
    getEventManagerSettings(regionId: string): Promise<EventManagerSettingsModel[]> {
        return this.get("eventmanagersettings", {id: regionId});
    }
    getRegionTerms(regionId: string): Promise<TermResponseModel[]> {
        return this.get("{id}/terms", {id: regionId});
    }
}
