import React from "react";
import { GLRichTextEditor } from "@app/components";
import { formatResponseAnswer } from "./fun";
import { formatRegionalUrl } from "gl-commonui";

export function renderQuestionList(surveyQuestion, className?) {
    const lastQuestionAnswer = surveyQuestion[surveyQuestion.length -1].surveyResponseAnswer;
    const notExistedAnswer = lastQuestionAnswer.length &&
                             lastQuestionAnswer[0].answer.trim() == "";
    notExistedAnswer && surveyQuestion.pop();
    return <div className={className}>
        {surveyQuestion.map((v, k) => {
            const isLastQuestion = k == surveyQuestion.length -1;
            const title = !notExistedAnswer && isLastQuestion ? v.question : `${k+1}. ${v.question}`;
            return <div className="question" key={k}>
                <div className="title">{title}</div>
                <div className="answer">
                    {!isLastQuestion &&
                        <GLRichTextEditor
                            readOnly = {true}
                            editorHtml = {formatRegionalUrl(formatResponseAnswer(v.surveyResponseAnswer))}
                        />
                    }
                    {isLastQuestion &&
                        formatResources(v.surveyResponseAnswer)
                    }
                </div>
            </div>;
        })}
    </div>
}

export function formatResources(surveyResponseAnswer) {
    if (surveyResponseAnswer.length) {
        const resources = surveyResponseAnswer[0].answer.split("\t");
        return resources.map((v) => {
            return <div dangerouslySetInnerHTML={{ __html: formatRegionalUrl(v) }} />;
        });
    }
}
