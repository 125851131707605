import { WijmoColumnValidatorFn } from "@app/hooks/wijmo-grid/useWijmoValidators";
import { fmtMsg } from "@app/util";
import { ComparisonOperator, FormHelper } from "gl-commonui";
import { isDate, isNumber, isString, toNumber } from "lodash";

export class GridValidators {
    static required(localeId: string): WijmoColumnValidatorFn {
        return (value) => {
            if (!value || !value.length) {
                return FormHelper.ruleForRequire(localeId).message;
            }
            return null;
        };
    }
    static dateRequired(localeId: string): WijmoColumnValidatorFn {
        return (value) => {
            if (!value || !value.toLocaleString().length) {
                return FormHelper.ruleForRequire(localeId).message;
            }
            
            return null;
        };
    }
    static maxLength(length: number, localeId: string): WijmoColumnValidatorFn {
        return (value) => {
            return FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, length).validator(null, value);
        };
    }
    static minLength(length: number, localeId: string): WijmoColumnValidatorFn {
        return (value) => {
            return FormHelper.ruleForCompareLength(localeId, ComparisonOperator.GreaterOrEqualsThan, length).validator(null, value);
        };
    }
    static maxDate(dateOrField: Date | string, localeId: string): WijmoColumnValidatorFn {
        return (value, dataItem) => {
            const maxDate = isDate(dateOrField) ? dateOrField : dataItem[dateOrField] && new Date(dataItem[dateOrField]);
            if (value && maxDate && new Date(value) > maxDate) {
                return fmtMsg(localeId);
            }
            return null;
        };
    }
    static minDate(dateOrField: Date | string, localeId: string): WijmoColumnValidatorFn {
        return (value, dataItem) => {
            const maxDate = isDate(dateOrField) ? dateOrField : new Date(dataItem[dateOrField]);
            if (value && maxDate && new Date(value) < maxDate) {
                return fmtMsg(localeId);
            }
            return null;
        };
    }
    static max(valueOrField: number | string, localeId: string): WijmoColumnValidatorFn {
        return (value, dataItem) => {
            const max = isNumber(valueOrField) ? valueOrField : toNumber(dataItem[valueOrField]);
            if (value > max) {
                return fmtMsg(localeId);
            }
            return null;
        };
    }
    static min(valueOrField: number | string, localeId: string): WijmoColumnValidatorFn {
        return (value, dataItem) => {
            const max = isNumber(valueOrField) ? valueOrField : toNumber(dataItem[valueOrField]);
            if (value < max) {
                return fmtMsg(localeId);
            }
            return null;
        };
    }
}
