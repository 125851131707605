import React from "react";
import {
    connect,
    GLGlobal,
    withRouter,
    GLRouteComponentProps
} from "gl-commonui";
import { ISurveyService } from "@app/service/survey/surveys";
import { List, Card, Spin, Empty } from "antd-min";
import { TYPES } from "@app/util";
import { useService } from "@app/hooks";
import { StateType } from "@app/states";
import {
    SurveyFillState,
    setSurveyFillState,
    MySurveyVisibleMode,
    FillSurveyViewMode
} from "@app/states/survey/survey";
import { SurveyLocale } from "@app/locales/localeid";
import "./my-submitted-survey-list.less";
import { SubmittedSurveyHistoryModel } from "@app/service/survey/surveys/models";
import moment from "moment";
import { Loading } from "@app/components/survey/loading/loading";

interface SubmittedSurveyListProps extends GLRouteComponentProps {
    selectedSurvey: string;
    setSurveyFillState: (surveyFillState: SurveyFillState) => void;
}

const SubmittedSurveyHistoryList = (props: SubmittedSurveyListProps) => {
    const service = useService<ISurveyService>(TYPES.ISurveyService);
    const [historyList, setHistoryList] = React.useState(
        [] as SubmittedSurveyHistoryModel[]
    );
    const [isHistoryLoading, setIsHistoryLoading] = React.useState(true);
    const [totalHistory, setTotalHistory] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    let limit = 25;

    const submittedOnText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyResponseSubmittedOnText
    });
    const noDataText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.SurveyNoHistoryMessage
    });

    const showMoreText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.CommonShowMoreText
    });
    const viewText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.CommonViewText
    });

    const headerText = GLGlobal.intl.formatMessage({
        id: SurveyLocale.MySurveyMyHistory
    });

    const {setSurveyFillState } = props;
    React.useEffect(() => {
        if (isHistoryLoading && (!historyList || historyList.length == 0)) {
            getSubmittedSurveyHistory(true, 0);
        }
    }, [historyList]);

    const getSubmittedSurveyHistory = (isGetCount = false, currentOffset) => {
        service
            .getSubmittedSurveyHistory({
                isGetTotal: isGetCount,
                offset: currentOffset,
                limit: limit
            })
            .then(result => {
                setHistoryList(historyList.concat(result.submittedSurveys));
                if (isGetCount) {
                    setTotalHistory(result.totalCount);
                }
                setIsHistoryLoading(false);
            })
            .catch(ex => {
                setIsHistoryLoading(false);
            });
    };

    const showMore = () => {
        const newOffset = offset + 1;
        setOffset(newOffset);
        setIsHistoryLoading(true);
        getSubmittedSurveyHistory(false, newOffset);
    };

    const redirectToResponsePage = (surveyInstanceId) => {
        setSurveyFillState({
            mySurveyTabSection: MySurveyVisibleMode.FillSurveyPage,
            selectedSurveyInstanceId: surveyInstanceId,
            selectedSurveyResponseId: null,
            fillSurveyViewMode : FillSurveyViewMode.History
        });
    };

    return (
        <>
            <h2>{headerText}</h2>
            {!isHistoryLoading && historyList.length == 0 ? (
                <Empty description={<p className="ant-empty-description"> {noDataText}</p>}> </Empty>
            ) : historyList.length > 0 && (
                            <Card actions= {historyList.length < totalHistory && (
                                                [ <a onClick={showMore}>{showMoreText}</a>]
                                            )}>
                                <List
                                    size="large"
                                    locale={{ emptyText: noDataText }}
                                    dataSource={historyList}
                                    renderItem={item => (
                                        <List.Item
                                            actions={[
                                                <a
                                                    onClick={ ()=>{
                                                        redirectToResponsePage(item.surveyInstanceId) }
                                                    }
                                                >
                                                    {viewText}
                                                </a>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={
                                                    <span>
                                                        {item.surveyName +
                                                            " - " +
                                                            submittedOnText +
                                                            " - " +
                                                            moment(
                                                                item.submitDate
                                                            ).format("l")}
                                                    </span>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Card>
            )}
            {( <Loading visible={isHistoryLoading}></Loading>
            )}
        </>
    );
};

export const MySubmittedSurveyList = withRouter(
    connect(
        ({ survey: { } }: StateType) => ({
        }),
        {
            setSurveyFillState
        }
    )(SubmittedSurveyHistoryList)
);
