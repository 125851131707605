import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IContentRevisionHistoryService, ContentRevisionChangelogResponseModel, ContentRevisionChangelogRequestModel, RevisionChangelogRequest, RevisionChangelogResponse } from './index';

@injectable()
export class ContentRevisionHistoryService extends GLServiceBase<RevisionChangelogRequest, RevisionChangelogResponse> implements IContentRevisionHistoryService {
    serviceRoute = { prefix: 'content/v1/resource' };
    getContentChangeLog(params: ContentRevisionChangelogRequestModel): ContentRevisionChangelogResponseModel{
        this.serviceRoute = { prefix: 'content/v1/resource/GetContentChangeLog' };
        return this.get(params);
    }
}