import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import { NotificationDescription, NotificationTitleItem } from "./notification-item";

import { PathConfig } from "@app/config/pathconfig";
import {
    isHtml
} from "@app/util";
import { NotificationChannel } from "@app/util/enum";
import { parseUrlToAnchor } from "@app/util/func";
import { Icon, List } from "antd";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { INotificationService } from "@app/service/notifications";
import { TYPES } from "@app/service/types";
import { lazyInject } from "@app/util/di";

interface NotificationsProps {
    onHide: () => void;
    onGetNumUnread: () => void;
}

interface NotificationsState {
    loading: boolean;
    notifications: Array<any>;
}

@withRouter
export class Notifications extends Component<
    NotificationsProps,
    NotificationsState
> {
    @lazyInject(TYPES.INotificationService)
    service: INotificationService;

    limitation: number = 5;
    fmt = GLGlobal.intl.formatMessage;
    wrapperRef: any = React.createRef();

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            notifications: []
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getNotifications();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            event.target.getAttribute("id") !== "notification-icon-header" &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.props.onHide();
        }
    }

    getNotifications() {
        this.setState({ loading: true });
        this.service
            .getItemsBy({
                orderBy: "sticky",
                offset: 0,
                limit: this.limitation,
                hideArchived: true,
                channel: [
                    NotificationChannel.BulletinBoard,
                    NotificationChannel.BulletinBoardAndEmail
                ]
            })
            .then(response => {
                let data = response.data;

                this.setState({
                    notifications: data.map(d => {
                        d.title = parseUrlToAnchor(d.title);
                        d.description = isHtml(d.description)
                            ? d.description
                            : parseUrlToAnchor(d.description);
                        return d;
                    })
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    clickMarkAllAsRead() {
        this.service.markAsReadForAll().then(() => {
            this.getNotifications();
            this.props.onGetNumUnread();
        });
    }

    clickClearAll() {
        this.service.markAsArchived().then(() => {
            this.getNotifications();
            this.props.onGetNumUnread();
        });
    }

    markOneAsRead(seen, id) {
        if (!seen) {
            this.service.markAsReadForOne(id).then(() => {
                this.getNotifications();
                this.props.onGetNumUnread();
            });
        }
    }

    clearOne(id: any) {
        this.service.createArchived(id).then(() => {
            this.getNotifications();
            this.props.onGetNumUnread();
        });
    }

    redirectToNotificationsPage = () => {
        this.props.onHide();
        this.props.history.push(
            GLUtil.pathStringify(PathConfig.Notifications, {})
        );
    };

    render() {
        const ArrowRightIcon = () => {
            return (<i aria-label="icon: arrow-right" className="anticon anticon-arrow-right"><svg viewBox="64 64 896 896" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path></svg></i>);
        }

        return (
            <div
                ref={this.wrapperRef}
                className="header-notification-container"
            >
                <div className="header-notification-function">
                    <span>
                        <a
                            href="javascript:void(0)"
                            onClick={() => this.clickClearAll()}
                        >
                            {this.fmt({
                                id: GSAdminLocale.NotificationClearAll
                            })}{" "}
                            <ArrowRightIcon/>
                        </a>
                    </span>
                    <span>
                        <a
                            href="javascript:void(0)"
                            onClick={() => this.clickMarkAllAsRead()}
                        >
                            {this.fmt({
                                id: GSAdminLocale.NotificationMarkAllAsRead
                            })}{" "}
                            <Icon type="check" />
                        </a>
                    </span>
                </div>
                <div className="header-notification-list">
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.notifications}
                        loading={this.state.loading}
                        locale={{
                            emptyText: this.fmt({
                                id: GSAdminLocale.NotificationsNoData
                            })
                        }}
                        renderItem={({
                            title,
                            description,
                            type,
                            id,
                            startDate,
                            endDate,
                            sticky,
                            archived,
                            seen,
                            isAdvanced
                        }) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <NotificationTitleItem
                                            item={{
                                                id,
                                                title,
                                                startDate,
                                                description,
                                                sticky,
                                                archived,
                                                seen
                                            }}
                                            hasPopover={true}
                                            onMarkOneAsRead={id => {
                                                this.markOneAsRead(seen, id);
                                            }}
                                            onClearOne={id => {
                                                this.clearOne(id);
                                            }}
                                        />
                                    }
                                    description={
                                        !isAdvanced && <div
                                            onClick={() =>
                                                this.redirectToNotificationsPage()
                                            }
                                        >
                                            <NotificationDescription
                                                content={description}
                                            />
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    ></List>
                </div>
                    <div
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <a
                            href="javascript:void(0)"
                            onClick={() =>
                                this.redirectToNotificationsPage()
                            }
                        >
                            <FormattedMessage
                                id={SchoolLocale.NotificationsShowMore}
                            />
                        </a>
                    </div>
            </div>
        );
    }
}
