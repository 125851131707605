import React, { Component } from 'react';
import uniq from 'lodash/uniq';
import { Form } from "antd-min";
import { EventType, PendingChangeField, ContextHelper, SchoolEventType, CampusEventType, ChangeLogType, SchoolChangeField, CampusChangeField, HybridChangeField, getCIMSEvent, fmtMsg, HybridEventType, EventInfo } from '@app/util';
import { PendingChangeModel, PendingChangePropsModel } from '@app/service/cims';
import { SchoolLocale } from '@app/locales/localeid';
import { RoleName, ResourceType } from 'gl-commonui';

export const FormItem = Form.Item;
export const FormItemLayout = {
    labelCol: { xm: { span: 5 }, md: { span: 16 } },
    wrapperCol: { xm: { span: 7 }, md: { span: 8 } }
};
export const FormItemLayout4ClassName = {
    labelCol: { xm: { span: 5 }, md: { span: 7 } },
    wrapperCol: { xm: { span: 7 }, md: { span: 17 } }
};
export const FormItemLayout4Licenses = {
    labelCol: { xm: { span: 9 }, md: { span: 18 } },
    wrapperCol: { xm: { span: 3 }, md: { span: 6 } }
};
export const FormItemLayout4Footer = {
    labelCol: { xm: { span: 3 }, md: { span: 10 } },
    wrapperCol: { xm: { span: 9 }, md: { span: 14 } }
};
export const FormItemLayout4ApproveDenyTitle = {
    labelCol: { xm: { span: 9 }, md: { span: 13 } },
    wrapperCol: { xm: { span: 4 }, md: { span: 7 } }
};
export const FormItemLayout4AuditChangeTitle = {
    labelCol: { xm: { span: 9 }, md: { span: 13 } },
    wrapperCol: { xm: { span: 4 }, md: { span: 7 } }
};
export const FormItemLayout4LableText = {
    labelCol: { xm: { span: 5 }, md: { span: 4 } },
    wrapperCol: { xm: { span: 7 }, md: { span: 20 } }
};

export const SchoolEventModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolLocale.CIMSSchoolMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesMovePromoteStudents, SchoolLocale.CIMSSchoolMovePromoteStudentsEventTilte]
]);

export const CampusEventModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddCampus, SchoolLocale.CIMSAddCampusEventTilte],
    [EventInfo.PendingChangesDisableCampus, SchoolLocale.CIMSDisableCampusEventTilte],
    [EventInfo.PendingChangesChangeCampusMandatoryData, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesEnableCampus, SchoolLocale.CIMSEnableCampusEventTilte],
    [EventInfo.PendingChangesMaterialOrder, SchoolLocale.CIMSCampusMaterialOrderEventTitle],
]);

export const EventModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddClass, SchoolLocale.CIMSAddClassEventTilte],
    [EventInfo.PendingChangesPromoteClass, SchoolLocale.CIMSPromoteClassEventTilte],
    [EventInfo.PendingChangesChangeLicense, SchoolLocale.CIMSLicenseUpdateEventTilte],
    [EventInfo.PendingChangesDisableClass, SchoolLocale.CIMSDisableClassEventTilte],
    [EventInfo.PendingChangesChangeClassUnitPlan, SchoolLocale.CIMSUnitPlanChangeEventTitle],
    [EventInfo.PendingChangesChangeClassMandatoryData, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesMoveStudent, SchoolLocale.CIMSMoveStudentEventTitle],
    [EventInfo.PendingChangesRemoveStudent, SchoolLocale.CIMSStudentRemovedEventTitle],
    [EventInfo.PendingChangesEnableClass, SchoolLocale.CIMSEnableClassEventTilte],
    [EventInfo.PendingChangesMoveUnregisteredStudent, SchoolLocale.CIMSMoveUnregisteredStudentEventTitle],
]);

export const EventModalHideClassName: Map<EventInfo, boolean> = new Map([
    [EventInfo.PendingChangesPromoteClass, false],
    [EventInfo.PendingChangesMoveStudent, false],
    [EventInfo.PendingChangesRemoveStudent, false],
    [EventInfo.PendingChangesChangeLicense, false],
    [EventInfo.PendingChangesMoveUnregisteredStudent, false],
]);

export const EventModalHideMoidifier: Map<EventInfo, boolean> = new Map([
    [EventInfo.PendingChangesChangeLicense, false],
]);

export const SchoolEventModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMovePromoteStudents, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'move-promote-students-cims-info': true }]
]);

export const CampusEventModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesAddCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesDisableCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesEnableCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeCampusMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMaterialOrder, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true }],
]);

export const EventModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesAddClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesDisableClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesEnableClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeClassMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeLicense, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMoveStudent, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesRemoveStudent, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMoveUnregisteredStudent, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeClassUnitPlan, { 'align-items-right': false, 'align-items-left': true }],
]);

export const SchoolAttribute2DisplayLabel: Map<PendingChangePropsModel, string> = new Map([
    [PendingChangePropsModel.name, SchoolLocale.CIMSChangeEventSchoolName],
    [PendingChangePropsModel.englishName, SchoolLocale.CIMSChangeEventSchoolEnglishName],
]);

export const CampusAttribute2DisplayLabel: Map<PendingChangePropsModel, string> = new Map([
    [PendingChangePropsModel.name, SchoolLocale.CIMSChangeEventCampusName],
    [PendingChangePropsModel.phone, SchoolLocale.CIMSChangeEventCampusPhone],
    [PendingChangePropsModel.city, SchoolLocale.CIMSChangeEventCampusCity],
    [PendingChangePropsModel.postalCode, SchoolLocale.CIMSChangeEventCampusPostalCode],
    [PendingChangePropsModel.fullAddress, SchoolLocale.CIMSChangeEventCampusFullAddress],
    [PendingChangePropsModel.licenseList,SchoolLocale.CIMSPendingChangesLicenseTypeColumn]
]);

export const ClassAttribute2DisplayLabel: Map<PendingChangePropsModel, string> = new Map([
    [PendingChangePropsModel.name, SchoolLocale.CIMSChangeEventName],
    [PendingChangePropsModel.age, SchoolLocale.CIMSChangeEventAgeGrade],
    [PendingChangePropsModel.curriculumType4Display, SchoolLocale.CIMSChangeEventCurriculumType],
    [PendingChangePropsModel.licenseType4Display, SchoolLocale.CIMSChangeEventLicenseType],
    [PendingChangePropsModel.tsiLessonsPerWeek, SchoolLocale.CIMSChangeEventTSIPerweek],
    [PendingChangePropsModel.startDate4Display, SchoolLocale.CIMSChangeEventStartDate],
    [PendingChangePropsModel.endDate4Display, SchoolLocale.CIMSChangeEventEndDate],
    [PendingChangePropsModel.startUnit4Display, SchoolLocale.CIMSChangeEventStartUnit],
    [PendingChangePropsModel.endUnit4Display, SchoolLocale.CIMSChangeEventEndUnit],
]);

// export const SchoolApproveDenyModalTitleIds: Map<SchoolChangeField, string> = new Map([
//     [SchoolChangeField.Name, SchoolLocale.CIMSSchoolMandatoryAttributeChangeEventTilte],
//     [SchoolChangeField.EnglishName, SchoolLocale.CIMSSchoolMandatoryAttributeChangeEventTilte],
// ]);

// export const CampusApproveDenyModalTitleIds: Map<CampusChangeField, string> = new Map([
//     [CampusChangeField.AddCampus, SchoolLocale.CIMSAddCampusEventTilte],
//     [CampusChangeField.DisableCampus, SchoolLocale.CIMSDisableCampusEventTilte],
//     [CampusChangeField.EnableCampus, SchoolLocale.CIMSEnableCampusEventTilte],
//     [CampusChangeField.Name, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
//     [CampusChangeField.Phone, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
//     [CampusChangeField.City, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
//     [CampusChangeField.PostalCode, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
//     [CampusChangeField.AddressLine1, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
// ]);

// export const ApproveDenyModalTitleIds: Map<PendingChangeField, string> = new Map([
//     [PendingChangeField.AddClass, SchoolLocale.CIMSAddClassEventTilte],
//     [PendingChangeField.DisableClass, SchoolLocale.CIMSDisableClassEventTilte],
//     [PendingChangeField.EnableClass, SchoolLocale.CIMSEnableClassEventTilte],
//     [PendingChangeField.StudentCount, SchoolLocale.CIMSLicenseChangeEventTilte],
//     [PendingChangeField.MoveStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
//     [PendingChangeField.RemoveStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
//     [PendingChangeField.ChangeStudentSubscription, SchoolLocale.CIMSLicenseChangeEventTilte],
//     [PendingChangeField.MoveUnregisteredStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
//     [PendingChangeField.Grade, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.StartDate, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.EndDate, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.StartUnit, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.EndUnit, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.TSILessonsPerWeek, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
//     [PendingChangeField.Plans, SchoolLocale.CIMSUnitPlanChangeEventTitle],
// ]);

export const SchoolApproveDenyModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolLocale.CIMSSchoolMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesMovePromoteStudents, SchoolLocale.CIMSSchoolMovePromoteStudentsEventTilte]
]);

export const CampusApproveDenyModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddCampus, SchoolLocale.CIMSAddCampusEventTilte],
    [EventInfo.PendingChangesDisableCampus, SchoolLocale.CIMSDisableCampusEventTilte],
    [EventInfo.PendingChangesEnableCampus, SchoolLocale.CIMSEnableCampusEventTilte],
    [EventInfo.PendingChangesChangeCampusMandatoryData, SchoolLocale.CIMSCampusMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesMaterialOrder, SchoolLocale.CIMSCampusMaterialOrderEventTitle],
]);

export const ApproveDenyModalTitleIds: Map<EventInfo, string> = new Map([
    [EventInfo.PendingChangesAddClass, SchoolLocale.CIMSAddClassEventTilte],
    [EventInfo.PendingChangesPromoteClass, SchoolLocale.CIMSPromoteClassEventTilte],
    [EventInfo.PendingChangesDisableClass, SchoolLocale.CIMSDisableClassEventTilte],
    [EventInfo.PendingChangesEnableClass, SchoolLocale.CIMSEnableClassEventTilte],
    [EventInfo.PendingChangesChangeLicense, SchoolLocale.CIMSLicenseChangeEventTilte],
    [EventInfo.PendingChangesMoveStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
    [EventInfo.PendingChangesMoveUnregisteredStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
    [EventInfo.PendingChangesRemoveStudent, SchoolLocale.CIMSLicenseChangeEventTilte],
    [EventInfo.PendingChangesChangeClassMandatoryData, SchoolLocale.CIMSMandatoryAttributeChangeEventTilte],
    [EventInfo.PendingChangesChangeClassUnitPlan, SchoolLocale.CIMSUnitPlanChangeEventTitle],
]);

export const ApproveDenyModalHideClassName: Map<EventInfo, boolean> = new Map([
    [EventInfo.PendingChangesPromoteClass, false]
]);

export const ApproveDenyCampusModalHideClassName: Map<EventInfo, boolean> = new Map([
    [EventInfo.PendingChangesMaterialOrder, false]
]);

// export const SchoolApproveDenyModalOptinalStyle: Map<SchoolChangeField, any> = new Map([
//     [SchoolChangeField.Name, {'align-items-right': false, 'align-items-left': true}],
//     [SchoolChangeField.EnglishName, {'align-items-right': false, 'align-items-left': true}],
// ]);

// export const CampusApproveDenyModalOptinalStyle: Map<CampusChangeField, any> = new Map([
//     [CampusChangeField.AddCampus, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.DisableCampus, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.EnableCampus, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.Name, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.Phone, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.City, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.PostalCode, {'align-items-right': false, 'align-items-left': true}],
//     [CampusChangeField.AddressLine1, {'align-items-right': false, 'align-items-left': true}],
// ]);

// export const ApproveDenyModalOptinalStyle: Map<PendingChangeField, any> = new Map([
//     [PendingChangeField.AddClass, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.DisableClass, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.EnableClass, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.Grade, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.StartDate, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.EndDate, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.StartUnit, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.EndUnit, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.TSILessonsPerWeek, {'align-items-right': false, 'align-items-left': true}],
//     [PendingChangeField.StudentCount, {'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true}],
//     [PendingChangeField.MoveStudent, {'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true}],
//     [PendingChangeField.RemoveStudent, {'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true}],
//     [PendingChangeField.ChangeStudentSubscription, {'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true}],
//     [PendingChangeField.MoveUnregisteredStudent, {'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true}],
//     [PendingChangeField.Plans, {'align-items-right': false, 'align-items-left': true, 'unit-plan': true}],
// ]);

export const SchoolApproveDenyModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMovePromoteStudents, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'move-promote-students-cims-info': true }]
]);

export const CampusApproveDenyModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesAddCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesDisableCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesEnableCampus, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeCampusMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesMaterialOrder, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true }],
]);

export const ApproveDenyModalOptinalStyle: Map<EventInfo, any> = new Map([
    [EventInfo.PendingChangesAddClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesPromoteClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesDisableClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesEnableClass, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeClassMandatoryData, { 'align-items-right': false, 'align-items-left': true }],
    [EventInfo.PendingChangesChangeLicense, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true }],
    [EventInfo.PendingChangesMoveStudent, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true }],
    [EventInfo.PendingChangesMoveUnregisteredStudent, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true }],
    [EventInfo.PendingChangesRemoveStudent, { 'align-items-right': false, 'align-items-left': true, 'large-modal': true, 'license-change-approve-deny': true }],
    [EventInfo.PendingChangesChangeClassUnitPlan, { 'align-items-right': false, 'align-items-left': true, 'unit-plan': true }],
]);

export const PendingChangeUnitPlanColumns = [PendingChangePropsModel.plansIndexed1, PendingChangePropsModel.plansIndexed2, PendingChangePropsModel.plansIndexed3, PendingChangePropsModel.plansIndexed4, PendingChangePropsModel.plansIndexed5, PendingChangePropsModel.plansIndexed6, PendingChangePropsModel.plansIndexed7, PendingChangePropsModel.plansIndexed8];

export const PendingChangeColumns = [
    PendingChangePropsModel.schoolName, PendingChangePropsModel.campusName, PendingChangePropsModel.schoolClassName,
    PendingChangePropsModel.grade, PendingChangePropsModel.licenseType4Display, PendingChangePropsModel.studentCount,
    PendingChangePropsModel.startDate4Display, PendingChangePropsModel.startDate4ClientSort, PendingChangePropsModel.tsiLessonsPerWeek,
    PendingChangePropsModel.startUnit4Display, PendingChangePropsModel.endUnit4Display, PendingChangePropsModel.updateTime4Display,
    PendingChangePropsModel.updateTime4ClientSort,
    PendingChangePropsModel.phone, PendingChangePropsModel.city, PendingChangePropsModel.postalCode, PendingChangePropsModel.fullAddress
].concat(PendingChangeUnitPlanColumns);

export const PendingChangeFieldMapped2GridColumn: Map<PendingChangeField, string[]> = new Map([
    [PendingChangeField.AddClass, null],
    [PendingChangeField.PromoteClass, null],
    [PendingChangeField.DisableClass, null],
    [PendingChangeField.EnableClass, null],
    [PendingChangeField.Grade, [PendingChangePropsModel.grade]],
    [PendingChangeField.StartDate, [PendingChangePropsModel.startDate4Display, PendingChangePropsModel.startDate4ClientSort]],
    [PendingChangeField.EndDate, [PendingChangePropsModel.startDate4Display, PendingChangePropsModel.startDate4ClientSort]],
    [PendingChangeField.TSILessonsPerWeek, [PendingChangePropsModel.tsiLessonsPerWeek]],
    [PendingChangeField.StartUnit, [PendingChangePropsModel.startUnit4Display]],
    [PendingChangeField.EndUnit, [PendingChangePropsModel.endUnit4Display]],
    [PendingChangeField.Plans, PendingChangeUnitPlanColumns],
    [PendingChangeField.StudentCount, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.MoveStudent, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.RemoveStudent, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.ChangeStudentSubscription, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.MoveUnregisteredStudent, [PendingChangePropsModel.studentCount]],
]);

export const PendingChangeFieldMapped2ApproveDenyGridColumn4School: Map<SchoolChangeField, string[]> = new Map([
    [SchoolChangeField.Name, [PendingChangePropsModel.schoolName]],
    [SchoolChangeField.EnglishName, [PendingChangePropsModel.schoolEnglishName]],
]);

export const PendingChangeFieldMapped2ApproveDenyGridColumn4Campus: Map<CampusChangeField, string[]> = new Map([
    [CampusChangeField.AddCampus, null],
    [CampusChangeField.DisableCampus, null],
    [CampusChangeField.EnableCampus, null],
    [CampusChangeField.Name, [PendingChangePropsModel.campusName]],
    [CampusChangeField.Phone, [PendingChangePropsModel.phone]],
    [CampusChangeField.City, [PendingChangePropsModel.city]],
    [CampusChangeField.PostalCode, [PendingChangePropsModel.postalCode]],
    [CampusChangeField.FullAddress, [PendingChangePropsModel.fullAddress]],
]);

export const PendingChangeFieldMapped2ApproveDenyGridColumn4Hybrid: Map<HybridChangeField, string[]> = new Map([
    [HybridChangeField.Change, [PendingChangePropsModel.changeType]]
]);

export const PendingChangeFieldMapped2ApproveDenyGridColumn: Map<PendingChangeField, string[]> = new Map([
    [PendingChangeField.AddClass, null],
    [PendingChangeField.PromoteClass, null],
    [PendingChangeField.DisableClass, null],
    [PendingChangeField.EnableClass, null],
    [PendingChangeField.Grade, [PendingChangePropsModel.grade]],
    [PendingChangeField.StartDate, [PendingChangePropsModel.startDate4Display, PendingChangePropsModel.startDate4ClientSort]],
    [PendingChangeField.EndDate, [PendingChangePropsModel.startDate4Display, PendingChangePropsModel.startDate4ClientSort]],
    [PendingChangeField.TSILessonsPerWeek, [PendingChangePropsModel.tsiLessonsPerWeek]],
    [PendingChangeField.StartUnit, [PendingChangePropsModel.startUnit4Display]],
    [PendingChangeField.EndUnit, [PendingChangePropsModel.endUnit4Display]],
    [PendingChangeField.Plans, [PendingChangePropsModel.plansIndexed8]],
    [PendingChangeField.StudentCount, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.MoveStudent, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.RemoveStudent, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.ChangeStudentSubscription, [PendingChangePropsModel.studentCount]],
    [PendingChangeField.MoveUnregisteredStudent, [PendingChangePropsModel.studentCount]],
]);

export const PendingChangeFieldMapped2ApproveDenyGridColumnConfig: Map<ChangeLogType, any> = new Map([
    [ChangeLogType.Hybrid, PendingChangeFieldMapped2ApproveDenyGridColumn4Hybrid],
    [ChangeLogType.School, PendingChangeFieldMapped2ApproveDenyGridColumn4School],
    [ChangeLogType.Campus, PendingChangeFieldMapped2ApproveDenyGridColumn4Campus],
    [ChangeLogType.SchoolClass, PendingChangeFieldMapped2ApproveDenyGridColumn as any]
]);

export const LicenseChangeEventTypes = [EventInfo.PendingChangesChangeLicense, EventInfo.PendingChangesMoveStudent, EventInfo.PendingChangesRemoveStudent, EventInfo.PendingChangesMoveUnregisteredStudent];

export const PendingChangeFieldMapped2EventType4School: Map<SchoolChangeField, EventInfo[]> = new Map([
    [SchoolChangeField.Name, [EventInfo.PendingChangesChangeSchoolMandatoryData]],
    [SchoolChangeField.EnglishName, [EventInfo.PendingChangesChangeSchoolMandatoryData]],
]);

export const PendingChangeFieldMapped2EventType4Campus: Map<CampusChangeField, EventInfo[]> = new Map([
    [CampusChangeField.AddCampus, [EventInfo.PendingChangesAddCampus]],
    [CampusChangeField.DisableCampus, [EventInfo.PendingChangesEnableCampus, EventInfo.PendingChangesDisableCampus]],
    [CampusChangeField.EnableCampus, [EventInfo.PendingChangesEnableCampus, EventInfo.PendingChangesDisableCampus]],
    [CampusChangeField.Name, [EventInfo.PendingChangesChangeCampusMandatoryData]],
    [CampusChangeField.Phone, [EventInfo.PendingChangesChangeCampusMandatoryData]],
    [CampusChangeField.City, [EventInfo.PendingChangesChangeCampusMandatoryData]],
    [CampusChangeField.PostalCode, [EventInfo.PendingChangesChangeCampusMandatoryData]],
    [CampusChangeField.FullAddress, [EventInfo.PendingChangesChangeCampusMandatoryData]],
    [CampusChangeField.LicenseList, [EventInfo.PendingChangesChangeCampusMandatoryData]],
]);

export const PendingChangeFieldMapped2EventType: Map<PendingChangeField, EventInfo[]> = new Map([
    [PendingChangeField.AddClass, [EventInfo.PendingChangesAddClass]],
    [PendingChangeField.PromoteClass, [EventInfo.PendingChangesPromoteClass]],
    [PendingChangeField.DisableClass, [EventInfo.PendingChangesEnableClass, EventInfo.PendingChangesDisableClass]],
    [PendingChangeField.EnableClass, [EventInfo.PendingChangesEnableClass, EventInfo.PendingChangesDisableClass]],
    [PendingChangeField.Grade, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.StartDate, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.EndDate, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.TSILessonsPerWeek, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.StartUnit, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.EndUnit, [EventInfo.PendingChangesChangeClassMandatoryData]],
    [PendingChangeField.Plans, [EventInfo.PendingChangesChangeClassUnitPlan]],
    [PendingChangeField.StudentCount, LicenseChangeEventTypes],
    [PendingChangeField.MoveStudent, LicenseChangeEventTypes],
    [PendingChangeField.RemoveStudent, LicenseChangeEventTypes],
    [PendingChangeField.ChangeStudentSubscription, LicenseChangeEventTypes],
    [PendingChangeField.MoveUnregisteredStudent, LicenseChangeEventTypes],
]);

export const PendingChangeFieldMapped2EventTypeConfig: Map<ChangeLogType, any> = new Map([
    [ChangeLogType.School, PendingChangeFieldMapped2EventType4School],
    [ChangeLogType.Campus, PendingChangeFieldMapped2EventType4Campus],
    [ChangeLogType.SchoolClass, PendingChangeFieldMapped2EventType as any]
]);

export const GridColumnMapped2PendingChangeField: Map<string, PendingChangeField[]> = new Map([
    [PendingChangePropsModel.updateTime4ClientSort, [PendingChangeField.AddClass, PendingChangeField.PromoteClass, PendingChangeField.DisableClass, PendingChangeField.EnableClass]],
    [PendingChangePropsModel.grade, [PendingChangeField.Grade]],
    [PendingChangePropsModel.studentCount, [PendingChangeField.StudentCount, PendingChangeField.MoveStudent, PendingChangeField.RemoveStudent, PendingChangeField.ChangeStudentSubscription, PendingChangeField.MoveUnregisteredStudent]],
    [PendingChangePropsModel.startDate4ClientSort, [PendingChangeField.StartDate, PendingChangeField.EndDate]],
    [PendingChangePropsModel.tsiLessonsPerWeek, [PendingChangeField.TSILessonsPerWeek]],
    [PendingChangePropsModel.startUnit4Display, [PendingChangeField.StartUnit]],
    [PendingChangePropsModel.endUnit4Display, [PendingChangeField.EndUnit]],
    [PendingChangePropsModel.plansIndexed8, [PendingChangeField.Plans]],
]);

export const GridColumnMapped2PendingChangeField4School: Map<string, SchoolChangeField[]> = new Map([
    [PendingChangePropsModel.schoolName, [SchoolChangeField.Name]],
    [PendingChangePropsModel.schoolEnglishName, [SchoolChangeField.EnglishName]],
]);

export const GridColumnMapped2PendingChangeField4Campus: Map<string, CampusChangeField[]> = new Map([
    [PendingChangePropsModel.updateTime4ClientSort, [CampusChangeField.AddCampus, CampusChangeField.DisableCampus, CampusChangeField.EnableCampus]],
    [PendingChangePropsModel.campusName, [CampusChangeField.Name]],
    [PendingChangePropsModel.phone, [CampusChangeField.Phone]],
    [PendingChangePropsModel.city, [CampusChangeField.City]],
    [PendingChangePropsModel.postalCode, [CampusChangeField.PostalCode]],
    [PendingChangePropsModel.fullAddress, [CampusChangeField.FullAddress]],
]);

export const GridColumnMapped2PendingChangeFieldConfig: Map<ChangeLogType, any> = new Map([
    [ChangeLogType.School, GridColumnMapped2PendingChangeField4School],
    [ChangeLogType.Campus, GridColumnMapped2PendingChangeField4Campus],
    [ChangeLogType.SchoolClass, GridColumnMapped2PendingChangeField as any]
]);

export const ResourceType2ChangeLogType: Map<ResourceType, ChangeLogType> = new Map([
    [ResourceType.School, ChangeLogType.School],
    [ResourceType.Campus, ChangeLogType.Campus],
    [ResourceType.SchoolClass, ChangeLogType.SchoolClass]
]);

export const ChangeType2HybridChangeType: Map<ResourceType, ChangeLogType> = new Map([
    [ResourceType.School, ChangeLogType.School],
    [ResourceType.Campus, ChangeLogType.Campus],
    [ResourceType.SchoolClass, ChangeLogType.SchoolClass]
]);

export const SchoolChangeType2HybridChangeType: Map<EventInfo, HybridEventType> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, HybridEventType.SchoolMandatoryDataChange],
    [EventInfo.PendingChangesMovePromoteStudents, HybridEventType.SchoolMovePromoteStudents],
]);

export const CampusChangeType2HybridChangeType: Map<EventInfo, HybridEventType> = new Map([
    [EventInfo.PendingChangesAddCampus, HybridEventType.AddCampus],
    [EventInfo.PendingChangesDisableCampus, HybridEventType.DisableCampus],
    [EventInfo.PendingChangesEnableCampus, HybridEventType.EnableCampus],
    [EventInfo.PendingChangesChangeCampusMandatoryData, HybridEventType.CampusMandatoryDataChange],
    [EventInfo.PendingChangesMaterialOrder, HybridEventType.CampusMaterialOrder],
]);

export const ClassChangeType2HybridChangeType: Map<EventInfo, HybridEventType> = new Map([
    [EventInfo.PendingChangesAddClass, HybridEventType.AddClass],
    [EventInfo.PendingChangesPromoteClass, HybridEventType.PromoteClass],
    [EventInfo.PendingChangesDisableClass, HybridEventType.DisableClass],
    [EventInfo.PendingChangesEnableClass, HybridEventType.EnableClass],
    [EventInfo.PendingChangesChangeClassMandatoryData, HybridEventType.ClassMandatoryDataChange],
    [EventInfo.PendingChangesChangeLicense, HybridEventType.ClassLicenseChange],
    [EventInfo.PendingChangesMoveStudent, HybridEventType.MoveStudent],
    [EventInfo.PendingChangesMoveUnregisteredStudent, HybridEventType.MoveUnregisteredStudent],
    [EventInfo.PendingChangesRemoveStudent, HybridEventType.RemoveStudent],
    [EventInfo.PendingChangesChangeClassUnitPlan, HybridEventType.ClassUnitPlanChange],
]);

export function getChangeFields2Columns(pendingChange: PendingChangeModel, changeFieldConfig: Map<PendingChangeField, string[]>, used4BackgroundColor = true): string[] {
    const hasSpecialChange = pendingChange.changeFields.find(item => item.changeField == PendingChangeField.AddClass
        || item.changeField == PendingChangeField.PromoteClass || item.changeField == PendingChangeField.DisableClass || item.changeField == PendingChangeField.EnableClass);
    const changeFields = hasSpecialChange ?
        pendingChange.changeFields.filter(item => item.changeField == PendingChangeField.AddClass
            || item.changeField == PendingChangeField.PromoteClass || item.changeField == PendingChangeField.DisableClass || item.changeField == PendingChangeField.EnableClass) :
        pendingChange.changeFields;
    return uniq(changeFields.reduce((pre, cur) => {
        const columnNames = changeFieldConfig.has(cur.changeField as PendingChangeField) ? changeFieldConfig.get(cur.changeField as PendingChangeField) : null;
        if (columnNames) return pre.concat(columnNames);
        if (used4BackgroundColor && changeFieldConfig.has(cur.changeField as PendingChangeField) && !columnNames) return pre.concat(PendingChangeColumns);
        if (!used4BackgroundColor && changeFieldConfig.has(cur.changeField as PendingChangeField) && !columnNames) return pre.concat([PendingChangePropsModel.updateTime4ClientSort]);
        return pre;
    }, []));
}

export function getChangeFields2Columns4School(pendingChange: PendingChangeModel, changeFieldConfig: Map<SchoolChangeField, string[]>, used4BackgroundColor = true): string[] {
    return uniq(pendingChange.changeFields.reduce((pre, cur) => {
        const columnNames = changeFieldConfig.has(cur.changeField as SchoolChangeField) ? changeFieldConfig.get(cur.changeField as SchoolChangeField) : null;
        if (columnNames) return pre.concat(columnNames);
        if (used4BackgroundColor && changeFieldConfig.has(cur.changeField as SchoolChangeField) && !columnNames) return pre.concat(PendingChangeColumns);
        if (!used4BackgroundColor && changeFieldConfig.has(cur.changeField as SchoolChangeField) && !columnNames) return pre.concat([PendingChangePropsModel.updateTime4ClientSort]);
        return pre;
    }, []));
}

export function getChangeFields2Columns4Campus(pendingChange: PendingChangeModel, changeFieldConfig: Map<CampusChangeField, string[]>, used4BackgroundColor = true): string[] {
    const hasSpecialChange = pendingChange.changeFields.find(item => item.changeField == CampusChangeField.AddCampus
        || item.changeField == CampusChangeField.DisableCampus || item.changeField == CampusChangeField.EnableCampus);
    const changeFields = hasSpecialChange ?
        pendingChange.changeFields.filter(item => item.changeField == CampusChangeField.AddCampus
            || item.changeField == CampusChangeField.DisableCampus || item.changeField == CampusChangeField.EnableCampus) :
        pendingChange.changeFields;
    return uniq(changeFields.reduce((pre, cur) => {
        const columnNames = changeFieldConfig.has(cur.changeField as CampusChangeField) ? changeFieldConfig.get(cur.changeField as CampusChangeField) : null;
        if (columnNames) return pre.concat(columnNames);
        if (used4BackgroundColor && changeFieldConfig.has(cur.changeField as CampusChangeField) && !columnNames) return pre.concat(PendingChangeColumns);
        if (!used4BackgroundColor && changeFieldConfig.has(cur.changeField as CampusChangeField) && !columnNames) return pre.concat([PendingChangePropsModel.updateTime4ClientSort]);
        return pre;
    }, []));
}

export function getChangeFields2Columns4Hybrid(pendingChange: PendingChangeModel, changeFieldConfig: Map<HybridChangeField, string[]>, used4BackgroundColor = true): string[] {
    return uniq([HybridChangeField.Change].reduce((pre, cur) => {
        const columnNames = changeFieldConfig.has(cur) ? changeFieldConfig.get(cur) : null;
        if (columnNames) return pre.concat(columnNames);
        if (used4BackgroundColor && changeFieldConfig.has(cur) && !columnNames) return pre.concat(PendingChangeColumns);
        if (!used4BackgroundColor && changeFieldConfig.has(cur) && !columnNames) return pre.concat([PendingChangePropsModel.updateTime4ClientSort]);
        return pre;
    }, []));
}

export function getChangeFields2Columns4PendingChanges(pendingChange: PendingChangeModel, target: ChangeLogType, used4BackgroundColor = true): string[] {
    const config = PendingChangeFieldMapped2ApproveDenyGridColumnConfig.get(target);
    switch (target) {
        case ChangeLogType.Hybrid:
            return getChangeFields2Columns4Hybrid(pendingChange, config, used4BackgroundColor)
        case ChangeLogType.Campus:
            return getChangeFields2Columns4Campus(pendingChange, config, used4BackgroundColor);
        case ChangeLogType.SchoolClass:
            return getChangeFields2Columns(pendingChange, config, used4BackgroundColor)
        case ChangeLogType.School:
        default:
            return getChangeFields2Columns4School(pendingChange, config, used4BackgroundColor)
    }
}

export function canApproveDeny(target: ChangeLogType, changeField: PendingChangeField) {
    switch (target) {
        case ChangeLogType.School:
        case ChangeLogType.Campus:
            return (ContextHelper.isUserMatchRole(RoleName.systemAdmin)
                || ContextHelper.isUserMatchRole(RoleName.regionAdmin)
                || ContextHelper.isUserMatchRole(RoleName.accountManager));
        case ChangeLogType.SchoolClass:
        default:
            return (ContextHelper.isUserMatchRole(RoleName.systemAdmin)
                || ContextHelper.isUserMatchRole(RoleName.regionAdmin)
                || ContextHelper.isUserMatchRole(RoleName.accountManager)
                || (ContextHelper.isUserMatchRole(RoleName.trainer) &&
                    (changeField == PendingChangeField.Grade
                        || changeField == PendingChangeField.TSILessonsPerWeek
                        || changeField == PendingChangeField.StartDate
                        || changeField == PendingChangeField.EndDate
                        || changeField == PendingChangeField.StartUnit
                        || changeField == PendingChangeField.EndUnit
                        || changeField == PendingChangeField.Plans)));
    }
}

export function canApproveDeny4ChangeType(target: ChangeLogType, changeType) {
    switch (target) {
        case ChangeLogType.School:
        case ChangeLogType.Campus:
            return (ContextHelper.isUserMatchRole(RoleName.systemAdmin)
                || ContextHelper.isUserMatchRole(RoleName.regionAdmin)
                || ContextHelper.isUserMatchRole(RoleName.accountManager));
        case ChangeLogType.SchoolClass:
        default:
            return (ContextHelper.isUserMatchRole(RoleName.systemAdmin)
                || ContextHelper.isUserMatchRole(RoleName.regionAdmin)
                || ContextHelper.isUserMatchRole(RoleName.accountManager)
                || (ContextHelper.isUserMatchRole(RoleName.trainer) &&
                    (changeType == EventInfo.PendingChangesChangeClassMandatoryData
                        || changeType == EventInfo.PendingChangesChangeClassUnitPlan)));
    }
}

export function getCIMSChangeEventMessage(changeLogType: ChangeLogType, pendingChange: PendingChangeModel) {
    switch (changeLogType) {
        case ChangeLogType.Hybrid:
            switch (pendingChange.resourceType) {
                case ResourceType.School:
                    return fmtMsg({ id: getCIMSEvent(ChangeLogType.School).get(pendingChange.changeType as never) });
                case ResourceType.Campus:
                    if (pendingChange.changeType === EventInfo.PendingChangesMaterialOrder) {
                        return fmtMsg({ id: getCIMSEvent(ChangeLogType.Campus).get(pendingChange.changeType as never) }, { materialRequestId: pendingChange.materialRequestId });
                    }
                    else {
                        return fmtMsg({ id: getCIMSEvent(ChangeLogType.Campus).get(pendingChange.changeType as never) });
                    }
                case ResourceType.SchoolClass:
                default:
                    return fmtMsg({ id: getCIMSEvent(ChangeLogType.SchoolClass).get(pendingChange.changeType as never) });
            }
        default:
            return getCIMSEvent(changeLogType).get(pendingChange.changeType as never);
    }
}

