import { CampusModel } from '@app/service/school/campus/model';
import { PermissionService, RoleName, Role, GLGlobal, ResourceType } from 'gl-commonui';
import { guid } from 'inversify';
import { RoleService } from '@app/service/school/role';
import { UserService } from '@app/service/users';
import { ResourceService } from '@app/service/resources';
import { RegionModel } from '@app/service/admin/regions';
import { ContentVersionService, ContentVersionModel } from '@app/service/admin/contentversion';
import { SchoolModel } from "@app/service/schools";
import { ClassesModel, ClassTimeModel} from '@app/service/class';
import { BasicService } from '@app/service/basic';
import { SchoolHelper, SchoolClassTimeType, ContextHelper, LicenseTypeValueNameMap } from '@app/util';

interface Services {
    resource: ResourceService
    content: ContentVersionService
    permission: PermissionService
    role: RoleService
    user: UserService
    basic: BasicService
}

export type RoleResource = {
    role?: number,
    resources?: [
        {
            id?: string,
            name?: string,
            disabled?: boolean
        }
    ]
};

export type OwnerResouce = {
    resourceId?: string,
    resourceType?: number,
    userId?: string
}

export interface DashboardState {
    regions?: RegionModel[]
    schools?: SchoolModel[]
    campuses?: CampusModel[]
    schoolClasses?: ClassesModel[]
    extraSchoolClasses?: ClassesModel[]
    regions4Export?: RegionModel[]
    schools4Export?: SchoolModel[]
    schoolClasses4Export?: ClassesModel[]    
    ownerRegions?: RoleResource[]
    ownerSchools?: RoleResource[]
    ownerCampuses?: RoleResource[]
    href?: string
    roleResources?: RoleResource[]
    totalCount?: number
    activeCount?: number
    futureCount?: number
    extraTotalCount?: number
    extraData?: {allCnt: number, activeCnt: number, inActiveCnt: number}
    loading?: boolean
    reloadCoachData?: boolean
}

export default {
    namespace: 'dashboard',
    state: {
        regions: [],
        schools: [],
        campuses: [],
        schoolClasses: [],
        extraSchoolClasses: [],
        regions4Export: [],
        schools4Export: [],
        schoolClasses4Export: [],        
        ownerRegions: [],
        ownerSchools: [],
        ownerCampuses: [],
        href: '',
        roleResources: [],
        totalCount: 0,
        activeCount: 0,
        futureCount: 0,
        extraTotalCount: 0,
        extraData: { allCnt: 0, activeCnt: 0, inActiveCnt: 0},
        loading: false,
        reloadCoachData: false,
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setLoading(state, action) {
            return { ...state, loading: action.payload };
        },
        setReloadCoachData(state, action) {
            return { ...state, reloadCoachData: action.payload };
        }
    },
    effects: {
        *getData({ payload: {query} }, { all, put }) {
            const { roleName , ...rest } = query;
            const routeData = {
                userId: ContextHelper.getUserLoginInfo().profile.sub,
                userRole: Role[roleName]
            };
            const mergedQuery = {query: {routeData, queryData: rest}}
            switch (query.roleName) {
                case RoleName.globalHead:
                case RoleName.systemAdmin:
                case RoleName.trainingAdmin:
                    yield put(getRegions(mergedQuery));
                    break;
                case RoleName.regionAdmin:
                    yield put(getRegionsAndSchools(mergedQuery));
                    break;
                case RoleName.accountManager:
                case RoleName.schoolAdmin:
                    yield put(getSchoolsAndClasses(mergedQuery));
                    break;
                case RoleName.campusAdmin:
                yield put(getCampusAndClasses(mergedQuery));
                    break;
                case RoleName.trainer:
                case RoleName.teacher:
                    yield put(getClasses(mergedQuery));
                    break;
            }
        },
        *getRegions({ payload: { query } }, { all, call, put }, { resource, content }: Services, { pathParse }) {
            yield put(setLoading(true));
            try {
                const [{ data: regions, totalCount: totalCount, extraData: extraData}, contentVersions] = yield all([
                    call(resource.getLandingResources, query),
                    call(content.get),
                ]);
                if (query.queryData.isExportData) {
                    yield put(reload({ regions4Export: formatRegion(regions, contentVersions) }));
                }
                else {
                    yield put(reload({ 
                        regions: formatRegion(regions, contentVersions), 
                        totalCount, 
                        extraData
                    }));
                }
            } catch (err) {
				console.error(err);
            }
            if (!query.queryData.isExportData) yield put(setLoading(false));
        },
        *getRegionsAndSchools({ payload: { query } }, { call, put, all }, { resource,user, basic }: Services, { pathParse }) {
            yield put(setLoading(true));
            try {
                let href;
                const params = {
                    userId: GLGlobal.loginInfo().profile.sub,
                    roles: [Role.RegionAdmin]
                }
                const [ ownerRegions, schools ] = yield all([call(basic.getUserRoleResources, params), call(resource.getLandingResources, query)]);
                if (schools.data.length == 0 && ownerRegions.length > 0 && ownerRegions[0].resources.length > 0) {
                    const params = {
                        resourceType: ResourceType.Region,
                        resourceId: ownerRegions[0].resources[0].id
                    }
                    const data = yield call(resource.getResourceId, params);
                    href = `regions/${data.regionId}`;
                }
                const trainerIds = schools.data.filter(d => d.trainerId != null).map(d => d.trainerId);
                let trainers = [];
                if (trainerIds.length > 0) {
                    trainers = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(trainerIds, user)));
                }
                if (query.queryData.isExportData) {
                    yield put(reload({ schools4Export: formatSchool(schools.data, trainers) }));
                }
                else {
                    yield put(reload({ ownerRegions, schools: formatSchool(schools.data, trainers), href, totalCount: schools.totalCount }));                
                }                
            } catch (response) {
            }
            if (!query.queryData.isExportData) yield put(setLoading(false));
        },
        *getSchools({ payload: { query } }, { call, put }, { resource }: Services, { pathParse }) {
            yield put(setLoading(true));
            try {
                const { schools, totalCount } = yield call(resource.getLandingResources, query);
                yield put(reload({ schools: formatSchool(schools), totalCount }));
            } catch (response) {
            }
            yield put(setLoading(false));
        },
        *getSchoolsAndClasses({ payload: { query } }, { call, put, all }, { resource, user, basic }: Services, { pathParse }) {
            yield put(setLoading(true));
            try {
                let href, extraSchoolClasses = [];
                const params = {
                    userId: GLGlobal.loginInfo().profile.sub,
                    roles: [query.routeData.userRole]
                }
                const [ ownerSchools, classes ] = yield all([
                    call(basic.getUserRoleResources, params), 
                    call(resource.getLandingResources, query)
                ]);
                const {data, extraData: {totalCount, activeCount, futureCount}} = classes;
                if (!query.queryData.filterText && !data.length && totalCount == 0 && ownerSchools.length > 0 && ownerSchools[0].resources.length > 0) {
                    const params = {
                        resourceType: ResourceType.School,
                        resourceId: ownerSchools[0].resources[0].id
                    }
                    const [ data, roleResources ] = yield all([
                        call(resource.getResourceId, params),
                        call(resource.getAccessResources, {
                            roles: [Role.SchoolAdmin],
                            includeParent: true 
                        }, {
                            userId: GLGlobal.loginInfo().profile.sub,
                    })]);
                    if (query.routeData.userRole == Role.SchoolAdmin) {
                        extraSchoolClasses = formatExtraSchoolClass(roleResources);
                    }
                    href = `regions/${data.regionId}/schools/${data.schoolId}`;
                }
                const teacherIds = data.filter(d => d.teacherId != null).map(d=>d.teacherId);
                if (teacherIds.length > 0) {
                    const teachers = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(teacherIds, user)));
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, teachers) }));
                    }
                    else {
                        yield put(reload({ ownerSchools, schoolClasses: formatClasses(data, teachers), href: href, totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));
                    }
                }
                else {
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, []) }));
                    }
                    else {
                        yield put(reload({ ownerSchools, schoolClasses: formatClasses(data, []), href: href, totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));
                    }
                }
            } catch (response) {
            }
            if (!query.queryData.isExportData) yield put(setLoading(false));
        },
        *getCampusAndClasses({ payload: { query } }, { call, put, all }, { resource, user, basic }: Services, { pathParse }) {
            yield put(setLoading(true));
            try {
                let href, extraSchoolClasses = [];
                let params = {
                    userId: GLGlobal.loginInfo().profile.sub,
                    roles: [Role.CampusAdmin]
                }
                const [ ownerCampuses, classes ] = yield all([call(basic.getUserRoleResources, params), call(resource.getLandingResources, query)]);
                const {data, extraData: {totalCount, activeCount, futureCount}} = classes;
                if (!query.queryData.filterText && !data.length && totalCount == 0 && ownerCampuses.length > 0 && ownerCampuses[0].resources.length > 0) {
                    let params = {
                        resourceType: ResourceType.Campus,
                        resourceId: ownerCampuses[0].resources[0].id
                    }
                    const [ data, roleResources ] = yield all([
                        call(resource.getResourceId, params),
                        call(resource.getAccessResources, {
                            roles: [Role.CampusAdmin],
                            includeParent: true 
                        }, {
                            userId: GLGlobal.loginInfo().profile.sub,
                    })]);
                    extraSchoolClasses = formatExtraSchoolClass(roleResources);
                    href = `regions/${data.regionId}/schools/${data.schoolId}`;
                }
                const teacherIds = data.filter(d => d.teacherId != null).map(d=>d.teacherId);
                if (teacherIds.length > 0) {
                    const teachers = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(teacherIds, user)));
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, teachers) }));
                    }
                    else {
                        yield put(reload({ ownerCampuses, schoolClasses: formatClasses(data, teachers), href, totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));                   
                    }
                }
                else {
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, []) }));
                    }
                    else {
                        yield put(reload({ ownerCampuses, schoolClasses: formatClasses(data, []), href, totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));
                    }
                }
            } catch (response) {
            }
            if (!query.queryData.isExportData) yield put(setLoading(false));
        },
        *getClasses({ payload: { query } }, { call, put, all }, { resource, user }: Services) {
            yield put(setLoading(true));
            try {
                const { data, extraData: {totalCount, activeCount, futureCount} } = yield call(resource.getLandingResources, query);
                const teacherIds = data.filter(d => d.teacherId != null).map(d=>d.teacherId);
                let extraSchoolClasses = [];
                if (query.routeData.userRole == Role.Teacher && !query.queryData.filterText && totalCount == 0) {
                    const roleResources = yield call(resource.getAccessResources, {
                            roles: [Role.Teacher],
                            includeParent: true 
                        }, {
                            userId: GLGlobal.loginInfo().profile.sub
                    });
                    extraSchoolClasses = formatExtraSchoolClass(roleResources);
                }
                if (teacherIds.length > 0) {
                    const teachers = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(teacherIds, user)));
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, teachers) }));
                    }
                    else {
                        yield put(reload({ schoolClasses: formatClasses(data, teachers), totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));
                    }
                }
                else {
                    if (query.queryData.isExportData) {
                        yield put(reload({ schoolClasses4Export: formatClasses(data, []) }));
                    }
                    else {
                        yield put(reload({ schoolClasses: formatClasses(data, []), totalCount, activeCount, futureCount, extraSchoolClasses, extraTotalCount: extraSchoolClasses.length }));
                    }
                }
            } catch (response) {
            }
            if (!query.queryData.isExportData) yield put(setLoading(false));
        },
        *getRoleResources({ payload: { roles } }, { call, all, put }, { resource, basic }: Services, { pathParse }) {
            yield put(setLoading(true));
            const userId = ContextHelper.getUserLoginInfo().profile.sub;
            const queryRoles = roles ? roles : [...ContextHelper.getUserRoles()];
            try {
                const roleResources = yield call(basic.getUserRoleResources, {userId: userId, roles: queryRoles});
                yield put(reload({ roleResources: formatRoleResource(roleResources) }));
            } catch (response) {
            }
            yield put(setLoading(false));
        },
        *resetSchoolClasses({}, {put}) {
            yield put(reload({schoolClasses: []}))
        }      
    },
    services: {
        resource: ResourceService,
        content: ContentVersionService,
        permission: PermissionService,
        role: RoleService,
        user: UserService,
        basic: BasicService
    }
}

function formatRegion(regions: any[], contentVersions: ContentVersionModel[]) {
    return regions.map(region => {
        region.id = region.regionId;
        region.name = region.regionName;
        region.englishName = region.regionEnglishName;
        const gsContentversion = contentVersions.find(cv => cv.id == region.gsVersion);
        const lsContentversion = contentVersions.find(cv => cv.id == region.lsVersion);
        region.gsContentversion = gsContentversion ? gsContentversion.name : '';
        region.lsContentversion = lsContentversion ? lsContentversion.name : '';
        return region;
    })
}
function  formatSchool(schools: any[],trainers: string[]=[]) {
    const schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType();
    return schools.map(school => {
        school.id = school.schoolId;
        school.name = school.schoolName;
        school.subscriptionTypeText = schoolSubscriptionType.get(school.subscriptionType.toString());;
        school.digitalLicense = school.digitalLicenseCount;
        school.textbookLicense = school.textbookLicenseCount;
        school.littleSeedLicense = school.littleSEEDCount;
        school.trainerName = school.trainerId ? getTrainerName(school.trainerId, trainers) : "";
        return school;
    });
}
function formatRoleResource(roleResources: any[]): RoleResource[] {
    return roleResources.map(roleResource => {
        return {
            role: roleResource.role,
            resource: roleResource.resources.map(resource=> { return {
                id: resource.id,
                name: resource.name
            }})
        }
    });
}
function formatClasses(list: ClassesModel[], teachers: string[]): ClassesModel[] {
    const schoolCurriculumType = SchoolHelper.generateSchoolCurriculumType();
    const result: ClassesModel[] = [];
    list.forEach(item => {
        item.id = guid();
        item.rowSpan = 1;
        item.curriculumTypeText = schoolCurriculumType.get(`${item.curriculumType}`);
        item.licenseTypeText = LicenseTypeValueNameMap[item.licenseType];
        if (item.tsi) {
            result.push({
                ...item,
                tsiDuration: item.tsi.duration,
                tsiCount: item.tsi.count,
                tsiDays: item.tsi.days,
                repDuration: null,
                repCount: null,
                repDays: null,
                teacherName: getTeacherName(item.teacherId, teachers),
                rowSpan: 1
            });
        }
        else {
            result.push({
                ...item,
                tsiDuration: item.tsiTimePerWeek,
                tsiCount: null,
                tsiDays: null,
                repDuration: null,
                repCount: null,
                repDays: null, 
                teacherName: getTeacherName(item.teacherId, teachers)
            });
        }
    });
    return result;
}
function getTeacherName(teacherId, teachers) {
    const teacher = teachers.find(teacher => teacher.id == teacherId);
    return teacher ? teacher.name : teacherId;
}

function getTrainerName(trainerId, trainers) {
    const trainer = trainers.find(trainer => trainer.id == trainerId);
    return trainer ? trainer.name : trainerId;
}

export function formatExtraSchoolClass(roleResouces: any[]) {
    try {
        return roleResouces.filter(roleResource=>!isResourceDisabled(roleResource)).map((roleResource, index)=> {
            return {
                index,
                regionId: getResouceFieldValue(roleResource['region'], 'id'),
                regionName: getResouceFieldValue(roleResource['region'], 'name'),
                schoolId: getResouceFieldValue(roleResource['school'], 'id'),
                schoolName: getResouceFieldValue(roleResource['school'], 'name'),
                campusId: !isSchoolClassDisabled(roleResource) ? getResouceFieldValue(roleResource['campus'], 'id') : null,
                campusName: !isSchoolClassDisabled(roleResource) ? getResouceFieldValue(roleResource['campus'], 'name') : null,
                schoolClassId: !isSchoolClassDisabled(roleResource) ? getResouceFieldValue(roleResource['schoolClass'], 'id') : null,
                schoolClassName: !isSchoolClassDisabled(roleResource) ? getResouceFieldValue(roleResource['schoolClass'], 'name') : null,
            }
        });
    } catch (error) {
        console.log(error);
    }

}

function isSchoolClassDisabled(roleResource) {
    return roleResource.resourceType == ResourceType.SchoolClass &&  roleResource['schoolClass'] && roleResource['schoolClass'].disabled;
}

function isResourceDisabled(roleResouce) {
    return (roleResouce['region'] && roleResouce['region'].disabled) 
        || (roleResouce['school'] && roleResouce['school'].disabled)
        || (roleResouce['campus'] && roleResouce['campus'].disabled)
}

function getResouceFieldValue(roleResouce, fieldName) {
    return roleResouce ? roleResouce[fieldName] : null;
}

function reload(state) {
    return { type: 'dashboard/reload', payload: state }
}
export function setLoading(state) {
    return { type: 'dashboard/setLoading', payload: state  }
}
export function getData(state) {
    return { type: 'dashboard/getData', payload: state }
}
export function getRegions(state) {
    return { type: 'dashboard/getRegions', payload: state }
}
export function getSchools(state) {
    return { type: 'dashboard/getSchools', payload: state }
}
export function getRegionsAndSchools(state) {
    return { type: 'dashboard/getRegionsAndSchools', payload: state }
}
export function getSchoolsAndClasses(state) {
    return { type: 'dashboard/getSchoolsAndClasses', payload: state }
}
export function getCampusAndClasses(state) {
    return { type: 'dashboard/getCampusAndClasses', payload: state }
}
export function getClasses(state?) {
    return { type: 'dashboard/getClasses', payload: state }
}
export function getRoleResources(state) {
    return { type: 'dashboard/getRoleResources', payload: state }
}
export function resetSchoolClasses() {
    return { type: 'dashboard/resetSchoolClasses', payload: {}}
}
export function setReloadCoachData(state) {
    return { type: 'dashboard/setReloadCoachData', payload: state}
}