import { injectable } from "@app/util/di";
import { GLServiceBase } from "gl-commonui";
import { ITermManagerService } from "./interface";
import {CreateOrUpdateTermRequestModel, TermListModel, TermModel} from "./model";

@injectable()
export class TermManagerService extends GLServiceBase<any, any> implements ITermManagerService {

    serviceRoute = {
		prefix: "admin/v1/terms",
	};

    getTerms(regionId: string, disabled?: boolean) {
        return this.get<TermListModel>(`region/${regionId}`, { disabled });
    }
    getTermsForClass(regionId: string, classId?: string) {
        return this.get<TermModel[]>(`region/${regionId}/class`, { classId });
    }
    getTermsForCampus(regionId: string, campusId: string): Promise<TermModel[]> {
        return this.get<TermModel[]>(`region/${regionId}/campus/${campusId}`);
    }
    createTerm(data: CreateOrUpdateTermRequestModel) {
        return this.create(`create`, data);
    }
    updateTerm(data: CreateOrUpdateTermRequestModel) {
        return this.update(`update`, data);
    }
    deleteTerm(termId: string) {
        return this.delete(`${termId}`);
    }
    countAffectClasses(termId: string) {
        return this.get<number>(`${termId}/affect-classes-count`);
    }
}
