import { GLServiceBase } from "gl-commonui";
import { injectable } from "../../../util/di";
import { GetSurveyResponseRequest, SurveyResponseRequest, ISurveyResponseService, SendEmailRequestModel, SendEmailResponseModel, PollGroupResponseModel, PollResponseModel } from ".";

@injectable()
export class SurveyResponseService extends GLServiceBase<GetSurveyResponseRequest, SurveyResponseRequest> implements ISurveyResponseService {
    serviceRoute = { prefix: "admin/v1/survey/response" }

    sendEmail(emailRequest: SendEmailRequestModel): Promise<SendEmailResponseModel> {
        return this.create("email", emailRequest);
    }

    getSurveyResponses(id: string, surveyInstanceId: string | null): Promise<PollResponseModel> {
        return this.get("{id}/{surveyInstanceId}", { id , surveyInstanceId });
    }
    exportSurvey(params: any) {
        return this.getBlob("ExportSurvey", params);
    }
    hasPermissionToExportSurvey(surveyId: any) {
        return this.get("HasPermissionToExportSurvey/{surveyId}", {surveyId});
    }
}
