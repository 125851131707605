import { RegionService, RegionModel } from "@app/service/admin/regions";

interface Services {
    region: RegionService;
}

export interface RegionState {
    accessibleRegions: RegionModel[];
    model: RegionModel;
}

export interface CycleInfoModel {
    invoiceReferenceId?: string;
    startDate: string;
    endDate: string;
    invoiceGenerationDate: string;
    isCurrentCycle: boolean;
    isCurrentInvoiceGenerated?: boolean;
}

export default {
    namespace: "region",
    state: {
        accessibleRegions: [],
        model: {}
    },
    reducers: {
        setAccessibleRegions(state, { payload }) {
            return { ...state, accessibleRegions: payload };
        },
        setCurrentRegion(state, { payload }) {
            return { ...state, model: payload }
        }
    },
    effects: {
        *getAccessibleRegions({ payload }, { call, put }, { region }: Services) {
            const data = yield call(region.getAccessibleRegions, payload);
            yield put(setAccessibleRegions(data));
        },
        *getCurrentRegion({ payload: { id } }, { call, put }, { region }: Services) {
            const data = yield call(region.getRegionById, { id });
            yield put(setCurrentRegion(data));
        }
    },
    services: {
        region: RegionService,
    }
}
export function setCurrentRegion(state) {
    return { type: "region/setCurrentRegion", payload: state };
}
export function getCurrentRegion(state) {
    return { type: "region/getCurrentRegion", payload: state };
}
export function getAccessibleRegions(state) {
    return { type: "region/getAccessibleRegions", payload: state };
}

export function setAccessibleRegions(regions: RegionModel[]) {
    return { type: "region/setAccessibleRegions", payload: regions };
}
