export enum VisitationType {
    OnSite,
    LVA
}

export enum VisitationScheduleMode {
    Force,
    Schedule
}

export enum VisitationRequestTargetType {
    To,
    CC,
}

export enum Week {
    Sunday      = 1,
    Monday      = 2,
    Tuesday     = 3,
    Wednesday   = 4,
    Thursday    = 5,
    Friday      = 6,
    Saturday    = 7
}

export enum VisitationChannelType {
    Preparation,
    Observation,
    Review
}

export enum VisitationVisitRole {
    Coach,
    Teacher
}

export enum VisitationCoachStage {
    ScheduleDate = 0,
    PrevisitationForm = 1,
    VisitSchool = 2,
    VideoManager = 3,
    TeacherReview = 4,
    SchoolReview = 5,
    Completed = 6
}

export enum VisitationTeacherStage {
    ScheduleDate = 0,
    PrevisitationForm = 1,
    VideoManager = 2,
    ViewCoachFeedback  = 3,
    GiveCoachFeedback = 4,
    Completed = 5,
    CompletedByCoach = 6,
}

export enum UpcomingEventRoles {
    All = -1,
    Teacher = 0,
    Parent = 1,
    CampusAdmin = 2,
    SchoolAdmin = 3,
    RegionAdmin = 4,
    TrainingAdmin = 5,
    Trainer = 6,
    GlobalHead = 7,
    AccountManager = 9,
    TrainingManager = 10,
    IndividualUser = 89,
}

export enum UpcomingEventTargetTypes {
    All = 0,
    Region = 1,
    School = 2,
    Campus = 3,
    SchoolClass = 4,
    IndividualUser = 5,
}

export enum UpcomingEventFeatureType {
    VisitationCreation,
    VisitationStart,
    VisitationStartCoachAcception,
    VisitationSchoolAdminReschedule,
    VisitationCoachReschedule,
    VisitationDelete,
    VisitationConfirmDateReminder,
    PrevisitationFormCreate,
    PrevisitationFormReminder,
    TeacherReview,
    SchoolReview,
    CoachReview,
    LVAStartToSchoolAdmin,
    LVAStartToCoach,
    LVAStartToTeacher,
    LVAVideoManageReminder,
    OnSiteStartToTeacher,
    TeacherFinishedQuestionnaire
}

export enum ResponseStatusTypeEnum {
    Draft = 1,
    Submitted,
    Reviewed
}

export enum SurveyReviewType {
    Todo,
    CreatorShared,
    ReceiverShared
}

export enum RecurringType {
    Weekly = 1,
    Monthly = 2
}

export enum SurveyQuestionType {
    Text = 1,
    Option = 2,
    Rating = 3,
    MultChoice = 4,
    Score = 5
}

export enum SurveyTodoType {
    Todo = 1,
    Review,
    CreatorShared,
    ReceiverShared,
    ReadOnlyShared
}

// teacher fill to coach
export enum PreVisitationFormStatus
{
    Initialized,            //0
    Pending,                //SentToTeacher,    //1
    IgnoreInitialized,      //2
    IgnorePending,          //3
    Completed               //4
}

export enum LVAResourceStatus
{
    Uploading,
    Completed
}

export enum LVAResourceFormStatus {
    Pending,
    Sent
}

export enum LVAResourceType {
    Video = 1,
    Link = 2
}

export enum VisitationSchoolNoteStatus
{
    Draft     = 0,
    Completed = 1
}

export enum VisitationSchoolReviewStatus
{
    Draft     = 0,
    Completed = 1
}

//coach fill to teacher
export enum ReviewTeacherFormStatus
{
    Initialized = 0,
    Draft       = 1,
    Completed   = 2,
    Viewed      = 3
}

//teacher fill to coach
export enum ReviewCoachFormStatus
{
    Initialized,
    Draft,
    Completed
}

export enum ServerExceptionCode {
    VisitationRequestInvalid = 5001,
    OnsiteExceedLimitError = 5002,
    LVAExceedLimitError = 5003,
    NoTeachersError = 5004,
    NoPermissionDeleteError = 5005,
    SurveyHasSubmittedError = 5006,
    NoClassesError = 5007,
    VideoIsUploadingError = 5008,
    ItemHasSubmittedError = 5010,
    CanNotDeleteTeachersError = 5011,
    NotExistedTeacherError = 5012,
    NotSendSupportQuestionnaireError = 5014,
    CanNotRemoveCoachError = 5015,
    VideoHasExisted = 445,
    VisitationIsNull = 442,
    ChatAttendersNoneSameSchoolException = 6003,
    ChatAttendersNoneRelatedResources = 6005,
    ChatThreadTitleIsInvalidException = 6006,
    VisitationUploadVideosExceedLimitException = 6007,
}

export enum VisitationStage {
    OnGoing,
    Pending,
    Completed
}
