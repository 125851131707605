import { showHideDrawer } from "@app/actions";
import { HeaderSearch, SchoolInfoHeader,HeaderNotification  } from "@app/components";
import { HeaderMenu } from "@app/components/header-menu/header-menu";
import {
    ChatHistoryIcon, HistoryIcon, InvitationTemplateIcon, RegionIcon, SearchIcon, UserIcon
} from "@app/components/svgicon";
import { warppedUserAvatar } from "@app/components/user-avatar";
import { DashboardLocale, GSAdminLocale } from "@app/locales/localeid";
import { Menu } from "antd-min";
import {
    DrawerHelper, DropDownHelper, GLGlobal, GLUtil, HeaderComponents, LanguageIcon, MasterPagesDefined, RoleName, UserAvatar as UserAvatarCommon
} from "gl-commonui";
import React from "react";
import { Link } from "react-router-dom";
import { fmtMsg } from ".";
import {
    AdminPathConfig, PathConfig, SchoolPathConfig, SurveyPathConfig
} from "../config/pathconfig";
import { GSAdminAction, GSSchoolAction, SurveyAction } from "./enum";

function handleCloseDrawer() {
    showHideDrawer(false);
}
function callback() {
    window.open(
        `${window.location.origin}/#${SchoolPathConfig.SchoolSearch}`,
        "_blank"
    );
}

function redirectHelpPage() {
    window.open(`${GLGlobal.authorityUrl()}/account/help`);
}

function getTraningMenu() {
    return [
        {
            textLocaleId: DashboardLocale.HeaderMenuTrainingTrainer,
            url: process.env.portals["trainer"],
            action: GSSchoolAction.Navigate2TrainingPortal
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuConnect,
            url: process.env.portals["connect"],
            color: "navigator-connect",
            arrow: true
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuCurriculum,
            url: process.env.portals["curriculum"],
            action: GSSchoolAction.Navigate2Curriculum,
            color: "navigator-curriculum",
            arrow: true
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuTrainingTeacher,
            url: process.env.portals["resources"],
            action: GSSchoolAction.SchoolResources,
            color: "navigator-resources",
            arrow: true
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminReport,
            url: process.env.portals["report"],
            action: GSSchoolAction.Navigate2ReportPortal,
            color: "navigator-report",
            arrow: true
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuStudentREP,
            url: process.env.portals["students"],
            action: GSSchoolAction.Navigate2StudentPortal,
            color: "navigator-students",
            arrow: true
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuTrainingDevelopment,
            url: process.env.portals["lms"],
            action: GSSchoolAction.SchoolLMS,
            color: "navigator-lms",
            arrow: true
        }
    ];
}

function getAdminMenu() {
    return [
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminRegion,
            url: AdminPathConfig.Regions,
            action: GSAdminAction.ListRegion,
            icon: <RegionIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminGlobalProduct,
            url: PathConfig.GlobalProducts,
            action: GSAdminAction.ListGlobalProduct,
            iconType: "shopping-cart",
            iconClassName: "svg-icon"
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminLocalProduct,
            url: PathConfig.LocalProducts,
            action: GSAdminAction.ListLocalProduct,
            iconType: "shopping-cart",
            iconClassName: "svg-icon"
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminUser,
            url: AdminPathConfig.Users,
            action: GSAdminAction.ListUser,
            icon: <UserIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuAdminInvitationTemplate,
            url: PathConfig.InvitationTemplates,
            action: [
                GSAdminAction.ListInvitationTemplate,
                GSSchoolAction.InvitationTemplate
            ],
            icon: <InvitationTemplateIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuCodeLookup,
            url: AdminPathConfig.CodeLookup,
            action: GSAdminAction.InvitationCodeLookup,
            icon: <SearchIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuPendingChanges,
            url: PathConfig.PendingChanges,
            action: GSAdminAction.ListPendingChanges,
            icon: <HistoryIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuExternalSurvey,
            url: SurveyPathConfig.Home,
            action: [SurveyAction.ListMySurvey],
            icon: <InvitationTemplateIcon />
        },
        {
            textLocaleId: DashboardLocale.HeaderMenuChatHistory,
            url: PathConfig.ChatHistory,
            action: GSSchoolAction.ChatHistory,
            icon: <ChatHistoryIcon />
        }
    ];
}

function contentHeaderMobile() {
    return (
        <div className="header-mobile">
            <div className="header-mobile-top">
                <UserAvatar
                    isNeedActions={true}
                    allowedRoles={allowedRoles}
                    pageAfterSignin={PathConfig.Dashboard}
                    pageAfterSignout={PathConfig.Dashboard}
                    subMenuRender={() => {
                        if (
                            GLGlobal.isActionValid(
                                GSSchoolAction.TeacherMyActivity
                            ) ||
                            GLGlobal.isActionValid(
                                GSSchoolAction.CoachMyActivity
                            )
                        ) {
                            return (
                                <Menu.Item className="ant-dropdown-menu-item">
                                    <Link
                                        className={"navigation-link"}
                                        to={PathConfig.MyActivity}
                                    >
                                        {fmtMsg({
                                            id:
                                                GSAdminLocale.AvatarMenuMyActivity
                                        })}
                                    </Link>
                                </Menu.Item>
                            );
                        }
                    }}
                />
                <LanguageIcon />
            </div>
            <SchoolInfoHeader />
            <div className="gl-header-right-item gl-header-right-item-wrapper">
                <HeaderSearch />
            </div>
            <HeaderMenu
                textLocaleId={DashboardLocale.HeaderMenuTraining}
                matIcon="article"
                textIcon="solution"
                menuItems={getTraningMenu()}
                location="resource"
            />
            <HeaderMenu
                textLocaleId={DashboardLocale.HeaderMenuAdmin}
                matIcon="settings"
                textIcon="setting"
                menuItems={getAdminMenu()}
                location="administrator"
            />
            <DropDownHelper
                defaultMenuItems={{
                    contact: url => {
                        const { regionId } = GLUtil.pathParse({
                            path: PathConfig.Region
                        });
                        return regionId
                            ? `${url}?site=school&regionId=${regionId}`
                            : url;
                    }
                }}
            />
            <HeaderNotification />
        </div>
    );
}

const UserAvatar = warppedUserAvatar(UserAvatarCommon);
const allowedRoles = [
    RoleName.systemAdmin,
    RoleName.globalHead,
    RoleName.regionAdmin,
    RoleName.trainingAdmin,
    RoleName.trainer,
    RoleName.trainingManager
];

const masterPagesDefined: MasterPagesDefined = {
    appName: DashboardLocale.HeaderSiteTitleGlobal,
    back: {
        props: {
            headerComponents: {
                left: <SchoolInfoHeader />,
                right: [
                    <DrawerHelper componentItems={contentHeaderMobile()} />,
                    <HeaderSearch />,
                    <HeaderMenu
                        textLocaleId={DashboardLocale.HeaderMenuTraining}
                        matIcon="article"
                        textIcon="solution"
                        menuItems={getTraningMenu()}
                        location="resource"
                    />,
                    <HeaderMenu
                        textLocaleId={DashboardLocale.HeaderMenuAdmin}
                        matIcon="settings"
                        textIcon="setting"
                        menuItems={getAdminMenu()}
                        location="administrator"
                    />,
                    <div className="divider" />,
                    <DropDownHelper
                        defaultMenuItems={{
                            contact: url => {
                                const { regionId } = GLUtil.pathParse({
                                    path: PathConfig.Region
                                });
                                return regionId
                                    ? `${url}?site=school&regionId=${regionId}`
                                    : url;
                            }
                        }}
                    />,
                    <HeaderNotification />,
                    <LanguageIcon />,
                    <UserAvatar
                        isNeedActions={true}
                        allowedRoles={allowedRoles}
                        pageAfterSignin={PathConfig.Dashboard}
                        pageAfterSignout={PathConfig.Dashboard}
                        subMenuRender={() => {
                            if (
                                GLGlobal.isActionValid(
                                    GSSchoolAction.TeacherMyActivity
                                ) ||
                                GLGlobal.isActionValid(
                                    GSSchoolAction.CoachMyActivity
                                )
                            ) {
                                return (
                                    <Menu.Item className="ant-dropdown-menu-item">
                                        <Link
                                            className={"navigation-link"}
                                            to={PathConfig.MyActivity}
                                        >
                                            {fmtMsg({
                                                id:
                                                    GSAdminLocale.AvatarMenuMyActivity
                                            })}
                                        </Link>
                                    </Menu.Item>
                                );
                            }
                        }}
                    />
                ]
            } as HeaderComponents,
            styleClass: {
                entirety: {
                    class: "portal-theme"
                }
            }
        }
    }
};

export { masterPagesDefined };

