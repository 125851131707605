import { PermissionService } from "@app/service/permission";
import { IResourceService } from "@app/service/resources";
import { IUserService } from "@app/service/users";
import {hasConnectLicense, isInternalUser, lazyInject, LicenseTypes, TYPES, userHasRole} from "@app/util";
import {
    GLGlobal,
    IRouteHandler,
    OidcProxy,
    PathConfig,
    ResourcePermissionType,
    RoleName,
    RouteHandlerContext
} from "gl-commonui";
import { PathConfig as SchoolPathConfig } from "./pathconfig";
import { SchoolClassService } from "@app/service/class";

export class StudentAccessibleRouteHandler implements IRouteHandler {
    constructor(private classId: string, private studentId: string) {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        StudentAccessibleRouteGuard(
            this.next,
            context,
            this.classId,
            this.studentId
        );
    }
}

async function StudentAccessibleRouteGuard(
    next,
    context,
    classId: string,
    studentId: string
) {
    const loginInfo = OidcProxy.getLoginInfo();
    if (loginInfo.loggedin) {
        const pService = new PermissionService();
        const isValid = await pService.isStudentAccessable({
            classId,
            studentId
        });
        if (isValid) {
            next.invoke(context);
        } else {
            context.history.replace({
                pathname: SchoolPathConfig.StudentAccessDenied
            });
        }
    } else {
        next.invoke(context);
    }
}

export class RemoteTsiAccessibleRouteHandler implements IRouteHandler {
    @lazyInject(TYPES.IUserService)
    userservice: IUserService;
    constructor(private classId: string) {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        RemoteTsiAccessibleRouteGuard(
            this.next,
            context,
            this.userservice,
        );
    }
}

async function RemoteTsiAccessibleRouteGuard(
    next,
    context,
    userservice: IUserService
) {
    const loginInfo = OidcProxy.getLoginInfo();
    if (loginInfo.loggedin) {
        const allowedRoles = isInternalUser() || userHasRole(RoleName.schoolAdmin);
        if(allowedRoles) {
            next.invoke(context);
        } else {
            const remoteTsiPermission = await userservice.getRemoteTsiPermission();
            if (remoteTsiPermission) {
                next.invoke(context);
            } else {
                context.history.replace({ pathname: PathConfig.AccessDenied });
            }
        }

    } else {
        next.invoke(context);
    }
}

export class StudentManageAccessibleRouteHandler implements IRouteHandler {
    constructor(private regionId: string, private classId: string) {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        StudentManageAccessibleRouteGuard(
            this.next,
            context,
            this.regionId,
            this.classId
        );
    }
}

async function StudentManageAccessibleRouteGuard(
    next,
    context,
    regionId: string,
    classId: string
) {
    const loginInfo = OidcProxy.getLoginInfo();
    const pService = new PermissionService();
    if (loginInfo.loggedin) {
        const isValid = await pService.canMangeStudent({ regionId, classId });
        if (isValid) {
            next.invoke(context);
        } else {
            context.history.replace({ pathname: PathConfig.AccessDenied });
        }
    } else {
        next.invoke(context);
    }
}

export class UserAccessibleRouteHandler implements IRouteHandler {
    constructor(private userId: string) {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        UserAccessibleRouteGuard(this.next, context, this.userId);
    }
}

async function UserAccessibleRouteGuard(next, context, userId: string) {
    const loginInfo = OidcProxy.getLoginInfo();
    const selectedUser = JSON.parse(sessionStorage.getItem("selectedUser") || "{}");
    const currentRegionId = selectedUser.regionInfo ? selectedUser.regionInfo.id : "";
    if (loginInfo.loggedin) {
        const pService = new PermissionService();
        const isValid = await pService.canEditUser({
            userId: userId,
            regionId: currentRegionId
        });
        if (isValid) {
            next.invoke(context);
        } else {
            context.history.replace({ pathname: PathConfig.AccessDenied });
        }
    } else {
        next.invoke(context);
    }
}

export class ManageGroupAccess implements IRouteHandler {
    constructor() {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        ManageGroupAccessGuard(
            this.next,
            context
        );
    }
}

async function ManageGroupAccessGuard(
    next,
    context
) {
    const schoolClassId = context.match.params.classId;
    const schoolClassService = new SchoolClassService();
    await schoolClassService.getItemsBy(schoolClassId)
    .then((res) => {
        if(hasConnectLicense(res.licenseType)) {
            next.invoke(context)
        }
        else {
            context.history.replace({ pathname: PathConfig.AccessDenied });
        }
    })
    .catch(() => {
        context.history.replace({ pathname: PathConfig.AccessDenied });
    });

}