import { Container, Loading, TableWithPerfectScroll } from "@app/components";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { SurveyLocale, DashboardLocale, GSAdminLocale } from "@app/locales/localeid";
import { PollPageParams, PollReviewType, ReviewPollModel, ReviewPollsResponseModel, CreatedPollModel } from "@app/service/survey";
import { StateType } from "@app/states";
import { getReviewPolls, getUserPollParticipation, UserPollParticipationState } from "@app/states/survey/survey";
import { DateHelper, fmtMsg, isPastDate, PollListStates } from "@app/util";
import { Card, Switch, Icon, Input, Modal, Typography } from "antd-min";
import { connect, GLRouteComponentProps, withRouter, GLGlobal } from "gl-commonui";
import React, { useEffect, useState } from "react";
import {CellType, GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import { Column } from "@app/components/grid/grid-column";
import { WijmoGrid } from "@app/components/grid/wijmo-grid";
import { mergeClasses } from "@app/components/survey/functions";
import { PollStateRadios } from "@app/components/survey/poll-state-radios/poll-state-radios";
import { IdentityModel } from "@app/service/school/invitation";
import "./review-polls-history.less";

const wijmoMapper = (poll: ReviewPollModel) => {
    return {
        ...poll,
        dueDate: DateHelper.formatDateAsIs(poll.dueDate as string),
        publishedDate: DateHelper.formatDate2Local(poll.publishedDate),
        submitDate: poll.submitDate ? DateHelper.formatDate2Local(poll.submitDate) : null
    };
}

interface ReviewPollsHistoryProps extends GLRouteComponentProps {
    loading: boolean;
    shouldReloadReviewSurveys: boolean;
    reviewPolls: ReviewPollsResponseModel;
    participationLoading: boolean;
    userParticipation: UserPollParticipationState;
    getReviewPolls: (params?: PollPageParams) => void;
    getPollParticipation: (surveyId: string) => void;
}

export const ReviewPollsHistory = withRouter(
    connect(
        ({ survey: { reviewPolls, reviewPollsLoading, shouldReloadReviewSurveys, participationLoading, userParticipation } }: StateType) => ({
            reviewPolls,
            loading: reviewPollsLoading,
            shouldReloadReviewSurveys,
            participationLoading,
            userParticipation
        }),
        {
            getReviewPolls,
            getPollParticipation: getUserPollParticipation
        }
    )((props: ReviewPollsHistoryProps) => {
        const [filterType, setFilterType] = useState(PollListStates.All);
        const [showHistory, setShowHistory] = useState(false);
        const [historyFetched, setHistoryFetched] = useState(false);
        const [filter, setFilter] = useState("");
        const [filteredSource, setFilteredSource] = useState([] as ReviewPollModel[]);
        const [showParticipation, setShowParticipation] = useState(false);
        const [participationPoll, setParticipationPoll] = useState({} as ReviewPollModel);

        const { loading, reviewPolls, getReviewPolls, history, shouldReloadReviewSurveys, getPollParticipation, participationLoading, userParticipation } = props;
        const { Text } = Typography;

        useEffect(() => {
            if ((!reviewPolls.data && !loading)
                || (shouldReloadReviewSurveys && !loading)
                || (reviewPolls.data
                    && (reviewPolls.data.length < reviewPolls.totalCount || (showHistory && !historyFetched))
                    && !loading
                    && !shouldReloadReviewSurveys)
            ) {
                if (showHistory) {
                    setHistoryFetched(true);
                } else {
                    getReviewPolls();
                }
            }

            if (reviewPolls.data) {
                setFilteredSource(reviewPolls.data.filter(r => r.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                    || (r.creator as IdentityModel).name.toLowerCase().indexOf(filter) >= 0));
            }
        }, [reviewPolls, loading]);

        /**
         * Every time reviewPolls changes, we need to remove and add the listener again
         * because reviewPolls is scoped and those values are not updated inside the
         * listener callback.
         */
        useEffect(() => {
            if (reviewPolls.data && reviewPolls.data.length) {
                document.addEventListener("click", onItemClick, false);
            }

            return () => {
                document.removeEventListener("click", onItemClick, false);
            };
        }, [reviewPolls]);

        const goToUserResponse = (userId: string, name: string, responseId: string) => {
            history.push(`${SurveyPathConfig.Home}/${participationPoll.surveyId}/${participationPoll.id}/${userId}/view/${responseId}`);
        };

        const onShowParticipationClick = (poll: ReviewPollModel) => {
            setShowParticipation(true);
            if (!userParticipation[poll.surveyId]) {
                getPollParticipation(poll.surveyId);
            }
            setParticipationPoll(poll);
        };

        const renderParticipationModal = () => {
            const modalHeight = window.innerHeight - 300;
            return (
                <Modal
                    title={`${participationPoll.name} - ${participationPoll.completeByCount}/${participationPoll.totalTodoCount}`}
                    visible={showParticipation}
                    className="notified-users-modal"
                    maskClosable={false}
                    destroyOnClose
                    onOk={() => setShowParticipation(false)}
                    onCancel={() => setShowParticipation(false)}
                    footer={null}
                    width={800}
                    bodyStyle={{ padding: 0 }}
                >
                    <Loading visible={participationLoading} cover height={modalHeight} />
                    {!participationLoading && (
                        <TableWithPerfectScroll
                            className="cp-table-participation"
                            dataSource={userParticipation[participationPoll.surveyId] ? userParticipation[participationPoll.surveyId].data : []}
                            columns={[
                                {
                                    title: fmtMsg(GSAdminLocale.NotificationTextName),
                                    dataIndex: "name",
                                    width: "40%",
                                    render: (text, { id, name, hasCompleted, surveyResponseId }) =>
                                        hasCompleted ? (
                                            <Text underline>
                                                <a className="cp-action-participation" onClick={() => goToUserResponse(id, name, surveyResponseId)}>
                                                    {text}
                                                </a>
                                            </Text>
                                        ) : (
                                                text
                                            )
                                },
                                {
                                    title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                                    dataIndex: "email",
                                    width: "40%"
                                },
                                {
                                    title: fmtMsg(SurveyLocale.PollCompletedText),
                                    dataIndex: "hasCompleted",
                                    width: "20%",
                                    render: (text: boolean) =>
                                        text ? (
                                            <Icon className="cp-icon" type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                        ) : (
                                                <Icon className="cp-icon" type="clock-circle" />
                                            )
                                }
                            ]}
                            perfectScroll={{ y: modalHeight - 38 }}
                            rowKey="id"
                            pagination={false}
                        />
                    )}
                </Modal>
            );
        };

        const onItemClick = e => {
            if (e.target && e.target.tagName.toUpperCase() == "A" && e.target.getAttribute("data-poll")) {
                const { column, instanceId, responseId, type, reviewPoll } = JSON.parse(e.target.getAttribute("data-poll"));

                switch (column) {
                    // case "name":
                    //     if (reviewPolls.data && type!== PollReviewType.CreatorShared) {
                    //         navigateToFillSurvey(reviewPolls.data.find(c => c.id === instanceId
                    //             && c.surveyResponseId === responseId
                    //             && c.surveyReviewType === type));
                    //     }
                    //     else if(reviewPolls.data && type === PollReviewType.CreatorShared) {
                    //         history.push(`${SurveyPathConfig.Home}/${(decodeDataItem(reviewPoll) as ReviewPollModel).surveyId}/${instanceId}/responses`);
                    //     }
                    //     break;

                    case "action":
                        if (reviewPolls.data && type !== PollReviewType.CreatorShared) {
                            navigateToFillSurvey(reviewPolls.data.find(c => c.id === instanceId
                                && c.surveyResponseId === responseId
                                && c.surveyReviewType === type));
                        }
                        else {
                            onShowParticipationClick(decodeDataItem(reviewPoll));
                        }
                        break;
                    default:
                        break;
                }

                e.preventDefault();
                e.stopPropagation();
            }
        };

        const getNameHref = (instanceId, responseId, type, reviewPoll, dataItemEncoded) => {
            const { id, surveyId, surveyResponseId, surveyReviewType, respondedBy } = reviewPoll;
            if (type !== PollReviewType.CreatorShared) {
                switch (surveyReviewType) {
                    case PollReviewType.Todo:
                        return `${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/complete`;
                    case PollReviewType.CreatorShared:
                        return `${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/${respondedBy}/view/${surveyResponseId || ""}`;
                    case PollReviewType.ReceiverShared:
                        return `${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/contribute/${surveyResponseId || ""}`;
                    default:
                        break;
                }
            }
            else if (type === PollReviewType.CreatorShared) {
                return `${SurveyPathConfig.Home}/${(decodeDataItem(dataItemEncoded) as ReviewPollModel).surveyId}/${instanceId}/responses`;
            }
            return "javascript:void(0)";
        }

        const getPollAction = (review: ReviewPollModel) => {
            const type: PollReviewType = review.surveyReviewType;
            switch (type) {
                case PollReviewType.Todo:
                    return fmtMsg({ id: SurveyLocale.MySurveyComplete });
                case PollReviewType.CreatorShared:
                    return (review.completeByCount ? review.completeByCount : 0).toString() + "/" + review.totalTodoCount;
                case PollReviewType.ReceiverShared:
                    return fmtMsg({ id: SurveyLocale.MySurveyContribute });
                default:
                    return "";
            }
        };

        const navigateToFillSurvey = ({ id, surveyId, surveyResponseId, surveyReviewType, respondedBy }: ReviewPollModel) => {
            switch (surveyReviewType) {
                case PollReviewType.Todo:
                    history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/complete`);
                    break;
                case PollReviewType.CreatorShared:
                    history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/${respondedBy}/view/${surveyResponseId || ""}`);
                    break;
                case PollReviewType.ReceiverShared:
                    history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/reviewhistory/contribute/${surveyResponseId || ""}`);
                    break;

                default:
                    break;
            }
        };

        const getPollsDataSource = (polls: ReviewPollModel[]) => {
            if (loading) {
                return [];
            }

            let filteredPolls = showHistory ? polls.map(wijmoMapper) : polls.filter(poll => !poll.isExpired).map(wijmoMapper);

            switch (filterType) {
                case PollListStates.Complete:
                    return filteredPolls.filter(p => p.surveyReviewType === PollReviewType.Todo);

                case PollListStates.Contribute:
                    return filteredPolls.filter(p => p.surveyReviewType === PollReviewType.ReceiverShared);

                case PollListStates.View:
                    return filteredPolls.filter(p => p.surveyReviewType === PollReviewType.CreatorShared);

                default:
                    return filteredPolls;
            }
        };

        const flexGridColumns = [
            {
                localeId: SurveyLocale.PollNameColumn,
                dataIndex: "name",
                width: "40*",
                allowDragging: true,
            },
            {
                localeId: SurveyLocale.PollDueDate,
                dataIndex: "dueDate",
                width: "20*",
                allowDragging: true,
                align: 'right'
            },
            {
                localeId: SurveyLocale.PollSubmittedDate,
                dataIndex: "submitDate",
                width: "20*",
                allowDragging: true,
                align:'right'
            },
            {
                localeId: SurveyLocale.PollAction,
                dataIndex: "surveyReviewType",
                width: "20*",
                align:'left'
            }
        ];

        const onToggleShowHistory = (checked: boolean) => {
            if (checked && !historyFetched) {
                getReviewPolls({ includeExpired: true });
            }
            setShowHistory(checked);
        }

        const setFilterValue = (e: any) => {
            const currentFilterValue = e.target.value.trim();
            if (currentFilterValue.length === 0) {
                onFilterChange("");
            }
            // update filter
            setFilter(e.target.value);
        };

        const onFilterChange = (value: any) => {
            if (!value) {
                setFilteredSource(reviewPolls.data);
                return;
            }

            setFilteredSource(reviewPolls.data.filter(r => r.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
                || (r.creator as IdentityModel).name.toLowerCase().indexOf(value) >= 0));
        };

        const decodeDataItem = (encodedDataItem: string) => {
            return JSON.parse(decodeURIComponent(escape(atob(encodedDataItem))));
        }

        const renderWijmoRow = (s, e) => {
            if (e.panel.cellType == CellType.Cell && !(s.rows[e.row] instanceof GroupRow)) {
                const dataItem: ReviewPollModel = e.panel.rows[e.row].dataItem;
                const dataItemEncoded = btoa(unescape(encodeURIComponent(JSON.stringify(dataItem))));
                switch (s.columns[e.col].binding) {
                    case "name":
                        e.cell.innerHTML = `<a
                                class="underline"
                                href="${getNameHref(dataItem.id, dataItem.surveyResponseId, dataItem.surveyReviewType, dataItem, dataItemEncoded)}">
                                    ${dataItem.surveyDisplayName ? dataItem.surveyDisplayName : dataItem.name}
                            </a><sup>${dataItem.isRecurring ? "Recurring" : ""}</sup>`
                        break;

                    case "dueDate":
                        const isDue = isPastDate(dataItem.dueDate) && dataItem.surveyReviewType === PollReviewType.Todo;
                        const classes = isDue && !dataItem.isExpired ? mergeClasses("ant-typography", "ant-typography-danger") : "";
                        e.cell.innerHTML = `<span class="${classes}">${e.cell.innerHTML}</span>`;
                        break;

                    case "surveyReviewType":
                        e.cell.innerHTML = dataItem.isExpired && dataItem.surveyReviewType !==  PollReviewType.CreatorShared ? "" : `<a
                                class="underline"
                                data-poll=${JSON.stringify({ column: "action", type: dataItem.surveyReviewType, instanceId: dataItem.id, responseId: dataItem.surveyResponseId, reviewPoll: dataItemEncoded })}>
                                    ${getPollAction(dataItem)}
                            </a>`;
                        break;

                    default:
                        break;
                }
            }
        }

        const getAllCount = () => {
            if (loading || !reviewPolls.data) {
                return;
            }

            if (showHistory) {
                return filteredSource.length;
            } else {
                return filteredSource.filter(p => !p.isExpired).length;
            }
        }

        const getCompleteCount = () => {
            if (loading || !reviewPolls.data) {
                return;
            }

            const completePolls = filteredSource.filter(r => r.surveyReviewType === PollReviewType.Todo);

            if (showHistory) {
                return completePolls.length;
            } else {
                return completePolls.filter(p => !p.isExpired).length;
            }
        }

        const getContributeCount = () => {
            if (loading || !reviewPolls.data) {
                return;
            }

            const contributeCount = filteredSource.filter(r => r.surveyReviewType === PollReviewType.ReceiverShared);

            if (showHistory) {
                return contributeCount.length;
            } else {
                return contributeCount.filter(p => !p.isExpired).length;
            }
        }

        const getViewCount = () => {
            if (loading || !reviewPolls.data) {
                return;
            }

            const viewCount = filteredSource.filter(r => r.surveyReviewType === PollReviewType.CreatorShared);

            if (showHistory) {
                return viewCount.length;
            } else {
                return viewCount.filter(p => !p.isExpired).length;
            }
        }

        const getSurveyReviewCount = () => {
            let sources = [];

            switch(filterType) {
                case PollListStates.All:
                    sources = filteredSource;
                    break;
                case PollListStates.Complete:
                    sources = filteredSource.filter(r => r.surveyReviewType === PollReviewType.Todo);
                    break;
                case PollListStates.Contribute:
                    sources = filteredSource.filter(r => r.surveyReviewType === PollReviewType.ReceiverShared);
                    break;
                case PollListStates.View:
                    sources = filteredSource.filter(r => r.surveyReviewType === PollReviewType.CreatorShared);
                    break;
                default:
                    return 0;
            }

            if (showHistory) {
                return sources.length;
            } else {
                return sources.filter(p => !p.isExpired).length;
            }
        }

        return <Container fullWidth className="poll-history">
            <div className="cp-switch">
                {fmtMsg({ id: SurveyLocale.PollShowHistory })}{" "}
                <Switch
                    loading={loading}
                    checked={showHistory}
                    onChange={onToggleShowHistory}
                    checkedChildren={<Icon type="eye" />}
                    unCheckedChildren={<Icon type="eye-invisible" />}
                ></Switch>
            </div>
            <div className="poll-history__search">
                <Input.Search
                    defaultValue={""}
                    placeholder={GLGlobal.intl.formatMessage({
                        id: GSAdminLocale.Search
                    })}
                    value={filter}
                    onChange={e => setFilterValue(e)}
                    onSearch={value => onFilterChange(value)}
                />
            </div>
            <div className="poll-history__filter">
                <PollStateRadios
                    allCount={getAllCount()}
                    completeCount={getCompleteCount()}
                    contributeCount={getContributeCount()}
                    viewCount={getViewCount()}
                    value={filterType}
                    onChange={(state) => {
                        setFilterType(state as PollListStates);
                    }}></PollStateRadios>
            </div>
            <Card
                className="cp-card"
                type="inner"
                size="small"
                title={`${fmtMsg({ id: SurveyLocale.PollsToReview })} ${loading ? "" : `(${getSurveyReviewCount()})`}`}
            >
                <WijmoGrid
                    columnLangMap={flexGridColumns.map(({ localeId, dataIndex }) => ({ localeId, dataIndex }))}
                    pageSize={0}
                    loading={loading}
                    selectionMode={SelectionMode.ListBox}
                    headersVisibility="Column"
                    itemsSource={getPollsDataSource(filteredSource)}
                    formatItem={renderWijmoRow}
                    grouping
                    groupBarText={fmtMsg({ id: DashboardLocale.LandingTabDragColumnsText })}
                    noDataLocaleId={SurveyLocale.MySurveyNoSurveyToReview}
                    isReadOnly>
                    {flexGridColumns.map(column => <Column
                        allowDragging={column.allowDragging}
                        binding={column.dataIndex}
                        header={fmtMsg({ id: column.localeId })}
                        width={column.width}
                        align={column.align}/>)}
                </WijmoGrid>
            </Card>
           { renderParticipationModal()}
        </Container>;
    })
);
