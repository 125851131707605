import React, { Component } from 'react';
import { Form, Input, Tooltip, Icon } from 'antd-min';
import { FormHelper, ComparisonOperator } from 'gl-commonui';
import { GSAdminLocale } from '@app/locales/localeid';
import { FormattedMessage } from 'react-intl';

export const SchoolNotes = ({ defaultNotes, form }) => {
    return (
        <Form.Item
            className="school-notes"
            label={
                <span>
                    <FormattedMessage id={GSAdminLocale.SchoolModeNotes} />
                    <Tooltip
                        title={<FormattedMessage id={GSAdminLocale.SchoolModeNotesPlaceHolder} />}
                        overlayClassName="school-notes-popover"
                        placement="topLeft"
                        key="school-notes"
                    >
                        <Icon type="info-circle" className="contact-info-icon" />
                    </Tooltip >
                </span>
            }
        >
        {form.getFieldDecorator('notes', {
            initialValue: defaultNotes,
            rules: [FormHelper.ruleForCompareLength(GSAdminLocale.SchoolModeNotes, ComparisonOperator.LessOrEqualsThan, 500)],
            validateTrigger: 'onBlur'
        })(
            <Input.TextArea maxLength={500} />)}
        </Form.Item>   
    )
}