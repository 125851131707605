import {
    GLServiceBase,
    getServiceMethodKey,
    HttpMethod,
    ServerExceptionCode
} from "gl-commonui";
import { injectable } from "../../../util/di";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import {
    IStudentVerificationService,
    IGetStudentListRequestQueryParam,
    StudentListResponseModel,
    StudentDetailsResponseModel,
    IGetStudentInfoWithSuggestionsRequestQueryParam
} from "./index";

@injectable()
export class StudentVerificationService extends GLServiceBase<any, any>
    implements IStudentVerificationService {
    serviceRoute = { prefix: "admin/v1/student/registration/verification" };
    errMsgs = (() => {
        const result = {};
        result[
            getServiceMethodKey(
                this,
                HttpMethod.Post,
                ServerExceptionCode.ArgumentIsNullOrInvalidException
            )
        ] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        result[
            getServiceMethodKey(
                this,
                HttpMethod.Get,
                ServerExceptionCode.ArgumentIsNullOrInvalidException
            )
        ] = GSAdminLocale.RegionManageStudentsErrorInGet;
        result[
            getServiceMethodKey(
                this,
                HttpMethod.Post,
                ServerExceptionCode.TargetIsNullException
            )
        ] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        result[
            getServiceMethodKey(
                this,
                HttpMethod.Post,
                ServerExceptionCode.AggregateException
            )
        ] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        return result;
    })();

    getStudentsList(
        data: IGetStudentListRequestQueryParam
    ): Promise<StudentListResponseModel> {
        return this.get("list", data);
    }

    verifyAllStudents(
        data: IGetStudentListRequestQueryParam
    ): Promise<boolean> {
        return this.get("verify/all", data);
    }

    getStudentDetails(
        data: IGetStudentInfoWithSuggestionsRequestQueryParam
    ): Promise<StudentDetailsResponseModel> {
        return this.get("studentmergeinfo/{studentId}/{currentClassId}", data);
    }
}
