import * as React from 'react'
import { Upload, Icon, message } from 'antd-min';
import { NotificationType, MessageHelper, GLGlobal } from 'gl-commonui';
import { SchoolLocale } from '../../locales/localeid';

interface SchoolAvatarUploadedProps {
    file?: string
    onSubmit: (d) => void,
    onRemoveImage?: () => void
}
interface SchoolAvatarUploadedStates {
    imageUrl?: string
    fileList: any
    loading: boolean
}
export class SchoolAvatarUploaded extends React.Component<SchoolAvatarUploadedProps, SchoolAvatarUploadedStates> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            fileList: [],
            loading: false
        };
    }
    componentWillReceiveProps(nextProps) {
        this.state.imageUrl !== null && !this.state.imageUrl && this.setState({ imageUrl: nextProps.file });
    }
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload(file) {
        const extValid = (file.type == 'image/png' || file.type == 'image/gif' || file.type == 'image/jpeg' || file.type == 'image/jpg');
        if (!extValid) {
            MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.SettingsAvatarType }));
            return false;
        }
        const isLt100K = file.size / 1024 < 100;
        if (!isLt100K) {
            MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.SettingsAvatarSize }));
            return false;
        }
        this.setState(({ fileList }) => ({
            fileList: [...fileList, file],
        }));
        this.getBase64(file, imageUrl => {
            this.props.onSubmit(imageUrl);
            this.setState({ imageUrl: imageUrl });
        });
        return false;
    }
    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{GLGlobal.intl.formatMessage({ id: SchoolLocale.SettingsAvatarUploadButton })}</div>
            </div>
        );
        const imageUrl = this.state.imageUrl;
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action=""
                accept=".jpeg,.jpg,.png,.gif"
                fileList={this.state.fileList}
                beforeUpload={this.beforeUpload.bind(this)}
            >
                {imageUrl ? <>
                    <img src={imageUrl} alt="" />
                    <span className="avatar-uploader-actions" onClick={(e) => e.stopPropagation()}>
                        <Icon type="delete" onClick={() => {
                            this.setState({ imageUrl: null, fileList: [] });
                            this.props.onSubmit(null);
                            this.props.onRemoveImage();
                        }} />
                    </span>
                </> : uploadButton}
            </Upload>
        );
    }
}