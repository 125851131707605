import React, { Component } from 'react';
import { Form, Input, InputNumber, Icon } from 'antd-min';
import { WrappedFormUtils, ValidationRule } from "antd/lib/form/Form";
import { InputProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import './gl-table.less';

export enum EditableCellDatatype {
    string = 'string',
    number = 'number',
    boolean = 'boolean',
    array = 'array'
}

export interface GLEditableCellProps {
    form: WrappedFormUtils
    editable: boolean
    editing: boolean
    dataIndex?: string
    editType?: EditableCellDatatype
    editProps?: InputProps | InputNumberProps
    title?: string
    editorKey?: string
    rules?: ValidationRule[]
    inputElement?: any
    record?: any
    clearValue?: boolean
    onCellValueChanged?: (data) => {valid: boolean, message: string}
}

interface GLEditableCellStates {
    hasError: boolean
    message: string
}

export class GLEditableCell extends Component<GLEditableCellProps, GLEditableCellStates> {
    cell: any

    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            message: ''
        }
    }
  
    componentWillReceiveProps(nextProps) {
        if (this.props.editing != nextProps.editing && !nextProps.editing) {
            //reset state when exit editing
            this.state = { 
                hasError: false,
                message: ''
            }            
        }
    }

    handleCellValueChanged = (e) => {
        const { form, record, editorKey, onCellValueChanged } = this.props;
        form.validateFields([editorKey], (error, values) => {
            if (onCellValueChanged) {
                const result = onCellValueChanged({ ...record, ...values, editorValue: e.target.value });
                if (!result) return;
                this.setState({
                    hasError: (error && error[editorKey]) || !result.valid,
                    message: result.message
                });
            }
        });
    }
  
    getEditElement() {
        const {editType, editProps} = this.props;
        switch (editType) {
            case EditableCellDatatype.number:
                return <InputNumber {...editProps as InputNumberProps}  onBlur={this.handleCellValueChanged} />;
            default:
                return <Input {...editProps as InputProps} onBlur={this.handleCellValueChanged} />;
        }
    }

    render() {
      const { form, editable, editing, dataIndex, editType, editProps, title, editorKey, rules, record, clearValue, inputElement, onCellValueChanged, ...restProps } = this.props;
      const FormItem = Form.Item;
      const validateStatus = rules? undefined : (this.state.hasError ? 'error' : undefined);
      return (
        <td ref={node => (this.cell = node)} {...restProps}>
            {editable ? 
                (
                editing ? (
                    <FormItem validateStatus={validateStatus}>
                        {inputElement? inputElement:
                            form.getFieldDecorator(editorKey, {
                                rules: rules,
                                initialValue: clearValue ? null : record[dataIndex],
                            })(this.getEditElement())
                        }
                        { this.state.hasError && <Icon type="exclamation-circle" title={this.state.message} className='editable-cell-error' />}
                    </FormItem>
                ) : ( restProps.children)) 
                : restProps.children
            }
        </td>
      );
    }
}