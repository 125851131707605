import * as React from 'react'
import { Icon } from 'antd-min';
import { GLGlobal } from 'gl-commonui';
import { IconProps } from 'antd/lib/icon';

export interface GLIconProps extends IconProps {
    type?: string
    labelId?: string
    label?: string
    className?: string;
    onClick?: React.MouseEventHandler<any>;
}
export const GLIcon = (props: GLIconProps) => {
    return renderIcon(props, renderlabel(props))
}
function renderIcon({ type, className = '', onClick, labelId, ...rest }: GLIconProps, title) {
    if (type && type.startsWith('icon-')) {
        return <span className={`gl-icon font-icon ${type} ${className}`} title={title} onClick={onClick} />;
    } else if (type && type.startsWith('img-')) {
        return <span className={`gl-icon gl-icon-img`} />
    } else {
        return <Icon type={type} className={`gl-icon ${className}`} title={title} onClick={onClick} {...rest} />
    }
}
export function renderlabel({ label, labelId }: GLIconProps) {
    if (labelId) {
        return GLGlobal.intl.formatMessage({ id: labelId });
    } else if (label) {
        return label;
    }
    return null;
}