import {GLPagingRequest, GLRequest} from "gl-commonui";
import {RegionModel} from "@app/service/admin/regions";

export interface RegionGenericLinkModel {
    id?: string
    site: string
    location: string
    localText: string
    englishText: string
    link: string
}

export class GetRegionGenericLinkRequest extends GLPagingRequest {
}
export interface RegionGenericLinkRequest extends GLRequest, RegionModel {
}
