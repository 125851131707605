import { GLLocale } from 'gl-commonui';
import { GSAdminLocale } from '../../../locales/localeid';
import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey, customError } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IAccountService, GetAccountRequest, AccountRequest } from './index';
import { GetUserByIdResponseModel, UpdateEmailRequestModel } from './model';

@injectable()
export class AccountService extends GLServiceBase<AccountRequest, GetAccountRequest> implements IAccountService {
    serviceRoute = { prefix: 'account/v1/users', itemSuffix: '{id}' }
    bindingUserByEmailUrl = '{email}/binding';
    redeemUserUrl = "binding"
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.InvitationFailed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidMaterialOrderException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.InvalidNameOrPassword;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.InvitationDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.NoUser;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.InvitationFailed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GSAdminLocale.CodeHasBeenUsed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvitationCodeNotExistException, `${this.serviceRoute.prefix}/${this.redeemUserUrl}`)] = GLLocale.HttpCodeNotExist;

        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceAccountNotifyCount;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException)] = GSAdminLocale.ServiceAccountSubmit;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException)] = GSAdminLocale.ServiceAccountCancel;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidMaterialOrderException)] = GSAdminLocale.ServiceAccountRole;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException)] = GSAdminLocale.ServiceAccountTargetType;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.bindingUserByEmailUrl}`)] = GSAdminLocale.ServiceAccountTarget;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvitationCodeNotExistException)] = GLLocale.HttpCodeNotExist;
        return result;
    })();
    getUsers(params: any) {
        return this.request.get(this.url(''), params);
    }
    getUsersByPost(data: any) {
        return this.create("getUsersByIds", data);
    }
    getUserById(params: any): Promise<GetUserByIdResponseModel> {
        return this.get('{id}', params);
    }
    validateEmail(params: any) {
        return this.get('{email}/confirmation', params);
    }
    bindingUserByEmail(params: any) {
        return this.request.post(this.url(this.bindingUserByEmailUrl), params).catch(customError(this, HttpMethod.Post, `${this.serviceRoute.prefix}/${this.bindingUserByEmailUrl}`));
    }
    bindingUserById(params: any) {
        return this.create('{id}/binding', params);
    }
    bindingUser2Role(params: any) {
        return this.create('{id}/roles/{roleName}', params);
    }
    redeemUser(params: any) {
        return this.create('binding', params);
    }
    resetPassword(userId: string) {
        return this.get('{id}/resetpassword', null, { id: userId });
    }
    editEmail(editEmailRequest: UpdateEmailRequestModel) {
        return this.update('editemail', editEmailRequest);
    }
}