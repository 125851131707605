import { EventStatus, SchoolChangeField } from "@app/util";

export enum StudentFormModelNames {
    student = "student",
    school = "school",
    campus = "campus",
    class = "class"
}

export enum StudentListPropsModel {
    studentId = "studentId",
    student = "studentName",
    school = "schoolName",
    campus = "campusName",
    class = "className",
    schoolClassId = "schoolClassId",
    unit = "unit",
    registrationDate = "registrationDate",
    registrationDateTime = "registrationDateTime"
}

export interface StudentListModel {
    id?: string;
    student?: string;
    school?: string;
    campus?: string;
    class?: string;
    schoolClassId?: string;
    unit?: string;
    registrationDate?: Date;
    registrationDateTime?: Date;
    campusId?: string;
    schoolId?: string;
    isMatched: boolean;
    studentName: string;
    studentEnglishName: string;
    gender?: boolean;
    birthday?: Date;
    studentParentId?: string;
    studentId: string;
}

export interface StudentListResponseModel {
    totalCount: number;
    currentPage: number;
    data?: StudentListModel[];
    pageSize?: string;
    totalPages: number;
    totalRecords: number;
}

export interface IGetStudentListRequestQueryParam {
    regionId?: string;
    keyword?: string;
}

export interface StudentDetailsModel {
    studentId: string;
    name: string;
    englishName: string;
    birthday: Date;
    gender: boolean;
    parentId: string;
    parentEmail?: string;
    parentPhoneNumber?: string;
    className: string;
    schoolName: string;
    campusName: string;
    unit: number;
    schoolId: string;
    campusId: string;
    currentUnit: number;
    unitStartDate: Date;
}

export enum StudentDetailsModelProps {
    studentId = "studentId",
    name = "name",
    englishName = "englishName",
    birthday = "birthday",
    gender = "gender",
    parentId = "parentId",
    parentEmail = "parentEmail",
    className = "className",
    schoolName = "schoolName",
    campusName = "campusName",
    unit = "currentUnit"
}

export interface StudentSuggestionsModel extends StudentDetailsModel {
    currentUnit: number;
    classId: string;
    matchRank: number;
    isExactMatch: boolean;
}

export interface StudentDetailForSuggestionModel {
    studentId?: string;
    name: string;
    englishName: string;
    birthday: Date;
    gender: boolean;
    parentEmail: string;
    isOverwrite: boolean;
    isCheckboxDisable: boolean;
    selectedMatchId?: string;
    isChecked: boolean;
    isExistedStudent: boolean;
    hasParent: boolean;
    invitationCodeId: string;
    isExactMatch: boolean;
    isDirty: boolean;
    className: string;
    schoolName: string;
    campusName: string;
    currentUnit: number;
}

export interface StudentDetailsResponseModel {
    studentDetailForSuggestion: StudentDetailForSuggestionModel;
    studentInfo: StudentDetailsModel;
    matchedSuggestion: StudentSuggestionsModel;
    cimsInfo: VerificationCIMSInfoModel;
}

export interface IGetStudentInfoWithSuggestionsRequestQueryParam {
    studentId?: string;
    currentClassId?: string;
}

export interface VerificationCIMSInfoModel {
    id: string;
    status: EventStatus | number;
}
