import React, { Component } from 'react';
import { Table } from 'antd-min';
import { TableProps, ColumnProps } from 'antd/lib/table';
import { WrappedFormUtils, ValidationRule } from "antd/lib/form/Form";
import { InputProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import { SelectProps } from "antd/lib/select";
import { GLEditableCell, EditableCellDatatype } from './gl-editablecell';
import './gl-table.less';
import { GLForm } from 'gl-commonui';

const GLEditableRow = ({ form, index, ...props }) => (
    <tr {...props} />
);

const GLEditableFormRow = GLForm.create()(GLEditableRow);

export interface GLColumnProps<T> extends ColumnProps<T> {
    editable?: boolean
    editType?: EditableCellDatatype
    editorKeyName?: string
    rules?: ValidationRule[]
    editProps?: InputProps | InputNumberProps | SelectProps
    clearValue?: boolean
}

export interface GLEditableTableProps<T> extends TableProps<T> {
    form: WrappedFormUtils
    columns: GLColumnProps<T>[]
    isEditing: boolean
    onCellValueChanged?: (data) => { valid: boolean, message: string }
}

export class GLEditableTable<T> extends Component<GLEditableTableProps<T>, any> {

    constructor(props) {
        super(props);
    }

    onCellValueChanged = (row) => {
        const { onCellValueChanged } = this.props;
        return onCellValueChanged && onCellValueChanged(row);
    }

    render() {
        const { rowKey, dataSource, form, columns, rowClassName, isEditing } = this.props;
        const components = {
            body: {
                row: GLEditableFormRow,
                cell: GLEditableCell,
            },
        };
        const customizedColumns = columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    editing: isEditing,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editType: col.editType,
                    editProps: col.editProps,
                    editorKey: record[col.editorKeyName],
                    clearValue: col.clearValue,
                    rules: col.rules,
                    form: form,
                    onCellValueChanged: this.onCellValueChanged,
                }),
            };
        });
        return (
            <Table
                components={components}
                rowKey={rowKey}
                pagination={false}
                rowClassName={rowClassName ? rowClassName : () => 'editable-row'}
                dataSource={dataSource}
                columns={customizedColumns}
            />
        );
    }
}