import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { differenceBy } from "lodash";
import moment from "moment";
import {
    Button,
    Card,
    Form,
    Input,
    Divider,
    Row,
    Col,
    message,
    Select,
    Spin,
    Radio,
    Rate,
    Checkbox,
    Icon
} from "antd-min";
import { GLForm, GLGlobal, GLFormComponentProps } from "gl-commonui";
import { TodoSurveyResponseModel, SurveyAnswerModel, SurveyQuestionResponseModel, StatisticsFilterType, QuestionType } from "@app/service/survey";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { Actions, Action } from "@app/components";
import { fmtMsg, isValidEmail, generateGuid, mergeClasses, createSliderMarks, MaxSurveySliderStepCount, isExitSurvey } from "@app/util";
import { GSAdminLocale, SurveyLocale } from "@app/locales/localeid";
import { IUserService } from "@app/service/users";
import { SendEmailRequestModel, ISurveyResponseService, ContactModel } from "@app/service/survey/response";
import RadioGroup from "antd/lib/radio/group";
import { Slider } from "antd";
import DOMPurify from "dompurify";
import { unescape } from "lodash";
import "./email-response.less";

const FormItem = Form.Item;

enum UserType {
    To,
    Cc
}

interface EmailUser {
    id: string;
    key: string;
    name: string;
    email: string;
    label: string;
    type: UserType;
}

interface EmailResponseProps extends GLFormComponentProps {
    surveyResponse: TodoSurveyResponseModel;
    onCancel: () => void;
}

const EResponse = (props: EmailResponseProps) => {
    const { surveyResponse, onCancel, form } = props;

    const [selectedQuestions, setSelectedQuestions] = useState<SurveyQuestionResponseModel[]>([]);
    const [emailFrom, setEmailFrom] = useState({} as EmailUser);
    const [usersLoading, setUsersLoading] = useState(false);
    const [searchedUserList, setSearchedUserList] = useState([] as EmailUser[]);
    const [selectedUsers, setSelectedUsers] = useState([] as EmailUser[]);
    const [includeDescription, setIncludeDescription] = useState(true);
    const userService = useService<IUserService>(TYPES.IUserService);
    const surveyResponseService = useService<ISurveyResponseService>(TYPES.ISurveyResponseService);
    let lastShareFetchId = 0;
    const [regionIds, setRegionId] = useState([] as string[]);

    useEffect(() => {
        setSelectedQuestions(surveyResponse.surveyQuestion);
        document.body.scrollTo(0, 0);
        userService.getUserById({ id: surveyResponse.createdBy }).then(user => {
            const responder = [{
                id: user.id,
                key: user.id,
                email: user.email,
                label: fmtEmail(user),
                type: UserType.To
            }] as EmailUser[];

            setSelectedUsers(responder);
        });
        userService.getById({ id: surveyResponse.createdBy }).then(user => {
            setRegionId(user.regionIds);
        });

        const { profile } = GLGlobal.loginInfo();

        if (profile) {
            const { sub, email, name } = profile;
            setEmailFrom({
                id: sub,
                key: sub,
                name,
                email,
                label: fmtEmail(profile)
            } as EmailUser);
        }

        setIncludeDescription(!!surveyResponse && !!surveyResponse.description);
    }, []);

    const removeQuestion = (questionId: string): void => setSelectedQuestions(selectedQuestions.filter(q => q.id !== questionId));
    const resetQuestions = (): void => {
        setSelectedQuestions(surveyResponse.surveyQuestion);
        setIncludeDescription(true);
    }

    const onUserSearch = debounce((searchKey: string, userType: UserType) => {
        if (searchKey == undefined || searchKey.trim().length < 3) return;
        searchUsers(searchKey.trim(), userType);
    }, 800);

    const searchUsers = (searchKey: string, userType: UserType) => {
        lastShareFetchId += lastShareFetchId;
        const fetchId = lastShareFetchId;
        setSearchedUserList([]);
        setUsersLoading(true);
        regionIds.forEach(regionId => {
            userService
            .getUsers({
                userName: searchKey,
                email: searchKey,
                regionId: regionId,
            })
            .then(d => {
                if (fetchId !== lastShareFetchId) return;
                const probableUsers = d.data
                    .filter(user => {
                        const currentUser = GLGlobal.loginInfo().profile.sub;
                        return user.email && currentUser != user.id && !selectedUsers.some(su => su.id === user.id);
                    })
                    .map(user => ({
                        id: user.id,
                        key: user.id,
                        name: user.name,
                        email: user.email,
                        type: userType,
                    }));

                if (!probableUsers.length && isValidEmail(searchKey)) {
                    const id = generateGuid();
                    probableUsers.push({
                        id,
                        key: id,
                        email: searchKey,
                        type: userType
                    });
                }

                setSearchedUserList(probableUsers);
                setUsersLoading(false);
            });
        })
    };

    const fmtEmail = ({ name, email }) => {
        if (!name) {
            return email || "";
        }

        return `${name || ""} ${email ? `(${email})` : ""}`;
    };

    const onUserChange = (updatedUsers, userType: UserType) => {
        updatedUsers = updatedUsers.map(user => {
            const displayedUser = selectedUsers.find(d => d.key === user.key);

            if (displayedUser) {
                return displayedUser;
            }

            const searchedUser = searchedUserList.find(s => s.key === user.key);
            if (searchedUser) {
                searchedUser.label = fmtEmail(searchedUser);
                return searchedUser;
            }

            user.label = fmtEmail(user);
            return user;
        });

        const currentDisplayedUser = selectedUsers.filter(u => u.type === userType);
        const removedUser = differenceBy(currentDisplayedUser, updatedUsers);

        setSelectedUsers(selectedUsers
            .filter(su => !removedUser.some(u => su.key === u.key)
                && !updatedUsers.some(u => u.key === su.key))
            .concat(updatedUsers));
        setSearchedUserList([]);
        setUsersLoading(false);
        return updatedUsers;
    }

    const getAnswer = (questionTypeId: QuestionType, content, initialValue, question: SurveyQuestionResponseModel, multChoiceAnswers, answers: SurveyAnswerModel[], typeRating, maxRating) => {
        switch (questionTypeId) {
            case QuestionType.Option:
                return (<Row key={content}><RadioGroup disabled={true} defaultValue={initialValue}> {question.surveyQuestionOption.map((option, key) => {
                    return <Col span={24} key={key}><Radio value={option.option} className="questions__option">{isExitSurvey(question.surveyId) ? fmtMsg({ id: option.localeKey }) : option.option}</Radio></Col>
                })} </RadioGroup></Row>);

            case QuestionType.MultChoice:
                return (<Checkbox.Group disabled={true} defaultValue={multChoiceAnswers}>
                    {
                        question.surveyQuestionOption.map((opt, key) => {
                            return <Col span={24} key={opt.id}>
                                <Checkbox value={opt.id} className="questions__option">{isExitSurvey(question.surveyId) ? fmtMsg({ id: opt.localeKey }) : opt.option}</Checkbox>
                            </Col>
                        })
                    }
                </Checkbox.Group>);

            case QuestionType.Rating:
                if (typeRating === 1) {
                    return (<Rate disabled={true} className="questions__rate" count={maxRating} value={initialValue} key={content}/>);
                } else {
                    return (<Rate disabled={true} className="questions__rate" character={<Icon type="like" />} count={maxRating} value={initialValue} key={content}/>);
                }

            case QuestionType.Score:
                const marks = createSliderMarks(question.minScore, question.maxScore, MaxSurveySliderStepCount);
                return (
                    <>
                        <Row className="questions__score__headers" type="flex" justify="space-between" align="middle">
                            <Col><label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeOptions })}</label></Col>
                            <Col><label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeScore })}</label></Col>
                            <Col>
                                <Row type="flex" justify="start">
                                    {question.isNaEnabled && <Col><label>{fmtMsg({ id: SurveyLocale.SurveyNotApplicableLabel })}</label></Col>}
                                </Row>
                            </Col>
                        </Row>
                        {
                            answers.map(answer => {
                                const option = question.surveyQuestionOption.find(opt => opt.id === answer.surveyQuestionOptionId);
                                const score = parseInt(answer.answer);

                                return (<Row className="questions__score" type="flex" justify="space-between" align="middle">
                                    <Col span={6}>{option ? option.option : null}</Col>
                                    <Col span={12}>
                                        <Slider
                                            marks={marks}
                                            min={question.minScore}
                                            max={question.maxScore}
                                            value={score === -1 ? question.minScore : score}
                                            disabled
                                        ></Slider>
                                    </Col>
                                    <Col>
                                        <Row type="flex" justify="start">
                                            {question.isNaEnabled && <Col><Checkbox disabled checked={score === -1}></Checkbox></Col>}
                                        </Row>
                                    </Col>
                                </Row>);
                            })
                        }
                    </>
                );
            case QuestionType.Text:
                    const html = DOMPurify.sanitize(unescape(initialValue || ""), { ADD_ATTR: ["target"] });
                    return <div className="ql__answer__item" key={content} dangerouslySetInnerHTML={{__html: html}}></div>;
            default:
                return <div className="ql__answer__item" key={content}>{initialValue}</div>;
        }
    }

    const renderAnswers = (answers: SurveyAnswerModel[], question: SurveyQuestionResponseModel): React.ReactNode => {
        const questionTypeId = question.surveyQuestionTypeId;
        const multChoiceAnswers = answers ? answers.map(a => a.surveyQuestionOptionId) : []; // Initial value of multi choice questions
        const { answer: content } = answers && answers.length ? answers[0] : {} as SurveyAnswerModel;
        const initialValue = question.surveyQuestionTypeId === QuestionType.Rating
            ? typeof content != "number" && isNaN(parseInt(content)) ? 0 : parseInt(content)
            : content;
        const typeRating = question.surveyQuestionRatingType;
        const maxRating = question.maxRating;
        return (
            <div className="ql__answer">
                {getAnswer(questionTypeId, content, initialValue, question, multChoiceAnswers, answers, typeRating, maxRating)}
            </div>
        );
    };

    const isRecipientsValid = () => selectedUsers.filter(u => u.type === UserType.To).length;

    const onSend = () => {
        form.validateFieldsAndScroll((error, values) => {
            if (error) {
                return;
            }

            if (!isRecipientsValid()) {
                message.error(fmtMsg(SurveyLocale.SurveyEmailToValidation))
                return;
            }
            const { name: senderName, email: senderEmail } = GLGlobal.loginInfo().profile;

            const emailRequest: SendEmailRequestModel = {
                ...values,
                selectedQuestionAnswers: selectedQuestions.map(({ question, surveyQuestionTypeId, surveyQuestionRatingType, maxRating, surveyQuestionOption, surveyResponseAnswer, minScore, maxScore, isNaEnabled, localeKey, id }) => ({
                    question,
                    surveyQuestionTypeId,
                    surveyQuestionRatingType,
                    maxRating,
                    surveyQuestionOption: surveyQuestionOption.map(o => ({ id: o.id, name: o.option, localeKey: o.localeKey })),
                    answer: surveyResponseAnswer.map(a => ({ id: a.surveyQuestionOptionId ? a.surveyQuestionOptionId : a.id, name: a.answer, localeKey: a.surveyQuestionOptionId ? surveyQuestionOption.find(o => o.id === a.surveyQuestionOptionId).localeKey : null })),
                    maxScore,
                    minScore,
                    isNaEnabled,
                    localeKey,
                    id,
                })),
                to: selectedUsers.filter(u => u.type === UserType.To).map(({ name, email }) => ({ name, email } as ContactModel)),
                cc: selectedUsers.filter(u => u.type === UserType.Cc).map(u => u.email),
                replyTo: { name: senderName, email: senderEmail } as ContactModel,
                description: includeDescription && surveyResponse ? surveyResponse.description : null,
                surveyId: selectedQuestions[0].surveyId,
            };

            surveyResponseService.sendEmail(emailRequest)
                .then(res => {
                    if (res.success) {
                        message.success(fmtMsg({ id: SurveyLocale.SurveyEmailSuccess }));
                        onCancel();
                    } else {
                        message.error(fmtMsg({ id: SurveyLocale.SurveyEmailError }));
                    }
                })
                .catch(_ => {
                    message.error(fmtMsg({ id: SurveyLocale.SurveyEmailError }));
                });
        });
    }

    const renderStatFilter = ({ statisticsFilterTypeId, statisticsFilter }: TodoSurveyResponseModel) => {
        switch (statisticsFilterTypeId) {
            case StatisticsFilterType.Region:
                return (<Col>
                    {fmtMsg({ id: SurveyLocale.SurveyFillRegion })}: <b>{statisticsFilter}</b>
                </Col>);

            case StatisticsFilterType.RegionGroup:
                return (<Col>
                    {fmtMsg({ id: SurveyLocale.SurveyFillRegionGroup })}: <b>{statisticsFilter}</b>
                </Col>);

            default:
                return null;
        }
    }

    const onRemoveDescription = () => {
        setIncludeDescription(false);
    }

    const renderQuestions = (description: string): React.ReactNode => {
        return (
            <>
                <Row type="flex" justify="space-between">
                    {renderStatFilter(surveyResponse)}
                    <Col>
                        <Actions className="ql__reset-btn" noHeight>
                            <Action materialIcon="refresh" textLocaleId={SurveyLocale.SurveyEmailQuestionReset} onClick={resetQuestions} />
                        </Actions>
                    </Col>
                </Row>
                {
                    includeDescription ? (<Row type="flex">
                        <div className="ql__item">
                            <Actions className="ql__item__actions--no-height">
                                <Action materialIcon="highlight_off" onClick={onRemoveDescription} />
                            </Actions>
                            <div className="ql__item__content">
                                <div className="ql__answer ql__description">{description}</div>
                            </div>
                        </div>
                    </Row>) : null
                }
                <div className="ql">
                    {selectedQuestions.map((question: SurveyQuestionResponseModel) => {
                        const { question: content, surveyResponseAnswer, id, localeKey, surveyId } = question;
                        return (
                            <div className={"ql__item"} >
                                <Actions className="ql__item__actions">
                                    <Action materialIcon="highlight_off" onClick={() => removeQuestion(id)} />
                                </Actions>
                                <div className="ql__item__content">
                                    <div className="ql__question">{isExitSurvey(surveyId) ? fmtMsg({ id: localeKey }) : content}</div>
                                    {renderAnswers(surveyResponseAnswer, question)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const getCardTitle = ({ name, fillDate }: TodoSurveyResponseModel) => {
        if (emailFrom) {
            return `${name} - ${formatMessage({ id: SurveyLocale.MySurveyFromUser }, { user: emailFrom.label })} - ${moment(fillDate).format('l')}`;
        } else {
            return `${name} - ${moment(fillDate).format('l')}`;
        }
    }

    const { getFieldDecorator } = form;
    const { formatMessage } = GLGlobal.intl;
    const disableSubmitButton = selectedUsers.length === 0 || !isRecipientsValid();
    return (
        <>
            <Card title={getCardTitle(surveyResponse)}>
                <GLForm form={form} className="s-form">
                    <Row type="flex">
                        <Col sm={24} md={16}>
                            <FormItem label={fmtMsg({ id: SurveyLocale.SurveyEmailTo })}>
                                {
                                    getFieldDecorator("to", {
                                        initialValue: selectedUsers.filter(u => u.type === UserType.To),
                                        rules: [{ required: true }]

                                    })(<Select
                                        mode="multiple"
                                        labelInValue
                                        style={{ width: "100%" }}
                                        dropdownClassName={`drop-down ${usersLoading ? "loading" : ""}`}
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={usersLoading ? <Spin size="small" /> : null}
                                        onSearch={key => onUserSearch(key, UserType.To)}
                                        onChange={users => onUserChange(users, UserType.To)}

                                    >
                                        {searchedUserList.map(d => (
                                            <Select.Option key={d.key} title={fmtEmail(d)}>
                                                {fmtEmail(d)}
                                            </Select.Option>
                                        ))}
                                    </Select>)
                                }
                            </FormItem>
                            <FormItem label={fmtMsg({ id: SurveyLocale.SurveyEmailCc })}>
                                {
                                    getFieldDecorator("cc", {
                                        initialValue: selectedUsers.filter(u => u.type === UserType.Cc)
                                    })(<Select
                                        mode="multiple"
                                        labelInValue
                                        style={{ width: "100%" }}
                                        dropdownClassName={`drop-down ${usersLoading ? "loading" : ""}`}
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={usersLoading ? <Spin size="small" /> : null}
                                        onSearch={key => onUserSearch(key, UserType.Cc)}
                                        onChange={users => onUserChange(users, UserType.Cc)}

                                    >
                                        {searchedUserList.map(d => (
                                            <Select.Option key={d.key} title={fmtEmail(d)}>
                                                {fmtEmail(d)}
                                            </Select.Option>
                                        ))}
                                    </Select>)
                                }
                            </FormItem>
                            <FormItem label={fmtMsg({ id: SurveyLocale.SurveyEmailSubject })}>{getFieldDecorator("subject", {
                                initialValue: getCardTitle(surveyResponse),
                                rules: [{
                                    required: true,
                                }]
                            })(<Input />)}</FormItem>
                        </Col>
                    </Row>
                    <Divider />
                    {renderQuestions(surveyResponse.description)}
                    <FormItem>
                        {getFieldDecorator("comments")(
                            <Input.TextArea className="s-form__comments" placeholder={fmtMsg({ id: SurveyLocale.SurveyEmailCommentPlaceholder })} rows={8} />
                        )}
                    </FormItem>
                    <div className="s-form__actions">
                        <Button onClick={onCancel} className="s-btn">{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                        <Button onClick={onSend} className={mergeClasses("s-btn", disableSubmitButton && "s-btn--disabled")} type="primary" disabled={disableSubmitButton}>{fmtMsg({ id: SurveyLocale.SurveyEmailSend })}</Button>
                    </div>
                </GLForm>
            </Card>
        </>
    );
};

export const EmailResponse = GLForm.create()(EResponse);
