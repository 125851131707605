import { ListStatesStore } from '@app/components';
import { PathConfig } from '@app/config/pathconfig';
import { ResourceType, Propagation, MessageHelper, NotificationType, GLGlobal } from 'gl-commonui';
import { CampusModel, CampusService, CampusUpdateStatusModel } from '@app/service/school/campus/index';
import { ResourceService } from '@app/service/school/resource/service';
import { UserService } from '@app/service/users/service';
import { StateType } from '../index'
import { isGuid, clearQuery, setQueryState } from '@app/util/func';
import { change } from './school';
import { SchoolLocale } from '@app/locales/localeid';
import { RoleHelper } from '@app/util/roleHelper';
import { GSAdminAction } from '../../util';

export interface CampusState {
    model: CampusModel
    accountManager: string
    trainer: string
    list: CampusModel[]
    total: number
    activeCnt: number
    loading: boolean
    isCartEmpty: boolean
    campusClasses: {}
    campusClasses4Display: {}
    campusUpdateErrorStatus?: CampusUpdateStatusModel
}
interface Services {
    campus: CampusService
    resource: ResourceService
    user: UserService
}

export default {
    namespace: 'campus',
    state: {
        model: {},
        accountManager: null,
        trainer: null,
        list: [],
        total: 0,
        activeCnt: 0,
        loading: true,
        isCartEmpty: true,
        campusClasses: {},
        campusClasses4Display: {},
        campusUpdateErrorStatus: null,
    },
    reducers: {
        updateCampusLicenseError(state, action) {
            return {
                ...state,
                campusUpdateErrorStatus: action.payload
            };
        },
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setCampusClasses(state, action) {
            return { ...state, campusClasses: {...state.campusClasses, ...action.payload} };
        },
        setCampusClasses4Display(state, action) {
            return { ...state, campusClasses4Display: {...state.campusClasses4Display, ...action.payload} };
        },        
    },
    effects: {
        *getAccountManager({ payload: { resourceType, resourceId } }, { call, put, select }, { resource, user }: Services, { pathParse }) {
            const ids = yield call(resource.getIdsBy, { resourceType }, { resourceId });
            const users = ids.length > 0 ? (yield call(user.getItemsBy, { ids: [ids[0]] })).data : [];
            {/* here use undefined condition to avoid render None text to UI, when request return, the value would be not undefined */ }
            yield put(reload({ accountManager: users.length > 0 ? users[0].name : '' }));
        },
        *getAdmin({ payload: { resourceType, resourceId } }, { call, put, select }, { resource, user }: Services, { pathParse }) {
            const ids = yield call(resource.getIdsBy, { resourceType }, { resourceId });
            const users = ids.length > 0 ? (yield call(user.getItemsBy, { ids: [ids[0]] })).data : [];
            {/* here use undefined condition to avoid render None text to UI, when request return, the value would be not undefined */ }
            yield put(reload({ model: { admin: users.length > 0 ? users[0].name : '' } }));
        },
        *getTrainer({ payload: { resourceType, resourceId } }, { call, put }, { resource, user }: Services, { pathParse }) {
            const ids = yield call(resource.getIdsBy, { resourceType }, { resourceId });
            const users = ids.length > 0 ? (yield call(user.getItemsBy, { ids: [ids[0]] })).data : [];
            yield put(reload({ trainer: users.length > 0 ? users[0].name : '' }));
        },
        *getItemsBy({ payload: { schoolId, query, sorter } }, { call, put, delay }, { campus }: Services, { pathParse }): any {
            schoolId = schoolId || pathParse({ path: PathConfig.Schools }).schoolId;
            if (isGuid(schoolId)) {
                yield put(reload({ loading: true }));
                const { data, totalCount, extraData: { activeCnt } } = yield call(campus.getItemsBy, {...query, ...sorter}, { schoolId });
                yield put(reload({ list: catAddresses(data), total: totalCount, activeCnt, loading: false, campusClasses: formatCampusClassChanges(data), campusClasses4Display: formatCampusClassChanges(data) }));
            }
        },
        *getCampuses({ payload: { schoolId } }, { call, put }, { campus, resource, user }: Services, { pathParse }): any {
            schoolId = schoolId || pathParse({ path: PathConfig.Schools }).schoolId;
            if (isGuid(schoolId)) {
                const data = yield call(campus.getAccessible, null, { schoolId });
                yield put(reload({ list: catAddresses(data) }));
            }
        },
        *getPrimary({ payload: { id: schoolId } }, { call, put }, { campus }: Services, { pathParse }): any {
            schoolId = schoolId || pathParse({ path: PathConfig.Schools }).schoolId;
            const currentCampusId = pathParse({ path: PathConfig.Classes }).campusId;
            if (isGuid(schoolId)) {
                const campuses = yield call(campus.getAccessible, null, { schoolId });
                const currentCampus = campuses.length ? campuses.find((campus) => campus.id === currentCampusId) : null;
                yield put(change(currentCampus ? { address: !currentCampus ? null : catAddress(currentCampus).address, phone: !currentCampus ? null : currentCampus.phone, campusId: currentCampus.id,  annualPrepComplete: currentCampus.annualPrepComplete} : { address: null, phone: null, campusId: null, annualPrepComplete: null }));
            }
        },
        *create({ payload: model }, { select, call, put }, { campus }: Services, { push, pathStringify }): any {
            const schoolId = yield select((state: StateType) => state.school.current.id);
            model.schoolId = schoolId;
            const campusId = yield call(campus.create, model);
            if (model.callback) {
                yield put(getPrimary({ id: schoolId }));
            }
            yield put(goToClasses(campusId));
        },
        *get({ payload }, { select, call, put, all }, { campus }: Services): any {
            const [data, validation] = yield all([
                call(campus.get, payload),
                call(campus.validateCampus, payload)
            ]);
            const state = yield select((state: StateType) => state.campus);
            yield put(reload({ ...state, model: catAddress(data), isCartEmpty: validation.canDisable }));
        },
        *remove({ payload: model }, { select, call, put }, { campus }: Services, { push, pathStringify }): any {
            const { school: { current: { id: schoolId } }, campus: { model: { id: campusId } } } = yield select(state => state);
            yield call(campus.delete, { schoolId, campusId: campusId });
            yield put(goToList());
            if (model.callback) {
                yield put(getPrimary({ id: schoolId }));
            }
        },
        *update({ payload: model }, { select, call, put }, { campus }: Services, { push, pathStringify }): any {
            const schoolId = yield select((state: StateType) => state.school.current.id);
            if(!GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage)){
                var tagIds = model && model.tags ? model.tags.map((x) => x.id) : [];
                model.tags = tagIds;
            }
            const response = yield call(campus.update, model, { schoolId, campusId: model.id });
            const heightestRole = RoleHelper.getMaxRoleOfCurrentUser()
            const roles = ['SystemAdmin', 'RegionAdmin', 'AccountManager']
            if(response.isValid)
            {
                yield put(updateCampusLicenseError(null));
                if (typeof model.disabled === "boolean" && typeof model.previousDisabledState === "boolean") {
                    if(model.previousDisabledState === false && model.disabled === true) {
                        if(roles.includes(heightestRole)){
                            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.CampusDisableWarningInternalRole }));
                        }
                        else{
                            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.CampusDisableWarningOtherRole }));
                        }
                    }
                    else if(model.previousDisabledState === true && model.disabled === false){
                        if(roles.includes(heightestRole)){
                            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.CampusEnableWarningInternalRole }));
                        }
                        else{
                            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.CampusEnableWarningOtherRole }));
                        }                    }
                }

                yield put(goToClasses(model.id));
                if (model.callback) {
                    yield put(getPrimary({ id: schoolId }));
                }
            }
            else{
                yield put(updateCampusLicenseError(response));
            }  
        },
        *goToList(action, { select, put }, services, { push, pathStringify }): any {
            const current = yield select((state: StateType) => state.school.current);
            clearQuery('campuses');
            yield put(push(pathStringify(PathConfig.Schools, { regionId: current.regionId, schoolId: current.id })));
        },
        *goToClasses({ payload: campusId }, { select, put }, services, { push, pathStringify }): any {
            const current = yield select((state: StateType) => state.school.current);
            setQueryState('classes', { current: 1 }, { campusId });
            yield put(push(pathStringify(PathConfig.Classes, { regionId: current.regionId, schoolId: current.id, campusId })));
        },
    },
    services: {
        campus: CampusService,
        resource: ResourceService,
        user: UserService
    }
}

function catAddresses(list: CampusModel[]) {
    list.forEach(data => catAddress(data));
    return list;
}
export function catAddress(data: CampusModel) {
    data.address = `${data.address1 || ''} ${data.address2 || ''}`;
    return data;
}
function formatCampusClassChanges(list: CampusModel[], initialData = true) {
    return list.reduce((pre, cur) => {
        pre[cur.id] = initialData ? [] : false;
        return pre;
    }, {});
}

export function reload(state) {
    return { type: 'campus/reload', payload: state }
}
export function getAccountManager(state) {
    return { type: 'campus/getAccountManager', payload: state }
}
export function getAdmin(state) {
    return { type: 'campus/getAdmin', payload: state }
}
export function getTrainer(state) {
    return { type: 'campus/getTrainer', payload: state }
}
export function getItemsBy(state) {
    return { type: 'campus/getItemsBy', payload: state }
}
export function getPrimary(state?) {
    return { type: 'campus/getPrimary', payload: state }
}
export function create(state, callback?) {
    return { type: 'campus/create', payload: { ...state, callback } }
}
export function update(state, callback?) {
    return { type: 'campus/update', payload: { ...state, callback } }
}
export function remove(state, callback?) {
    return { type: 'campus/remove', payload: { ...state, callback } }
}
export function get(state) {
    return { type: 'campus/get', payload: state }
}
export function goToList() {
    return { type: 'campus/goToList' }
}
export function goToClasses(state) {
    return { type: 'campus/goToClasses', payload: state }
}
export function getCampuses(state) {
    return { type: 'campus/getCampuses', payload: state }
}

export function setCampusClasses(state) {
    return { type: 'campus/setCampusClasses', payload: state }
}
export function setCampusClasses4Display(state) {
    return { type: 'campus/setCampusClasses4Display', payload: state }
}
export function updateCampusLicenseError(state){
    return { type: 'campus/updateCampusLicenseError' , payload: state }
}