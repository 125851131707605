import React, { Component } from 'react';
import "./progress.less";
import { Icon } from 'antd-min';
import { VisitationCoachStage, 
    VisitationType,
    VisitationTeacherStage,
} from '@app/util/coach/enum';
import { fmtMsg, GSAdminAction } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { GLGlobal } from 'gl-commonui';
import { SetUpIcon, SetUpBoldIcon, 
    SupportQuestionnaireIcon, 
    SupportQuestionnaireBoldIcon, 
    VideoManagerIcon, 
    VideoManagerBoldIcon, 
    TeacherFeedbackIcon, 
    TeacherFeedbackBoldIcon, 
    SchoolFeedbackIcon, 
    SchoolFeedbackBoldIcon, 
    SchoolVisitIcon, 
    SchoolVisitBoldIcon, 
    CoachFeedbackIcon,
    CoachFeedbackBoldIcon} from '@app/components/svgicon';

interface ProgressProps {
    isCoach: boolean
    visitation: any
    teacherStage?: number
    selectedStage?: number
    callback?: (stage) => void
    selectedStageCallback?: (stage) => void
}
interface ProgressStates {}

export class Progress extends Component<ProgressProps, ProgressStates> {
    constructor(props) {
        super(props);
        this.state = {}
    }
    progressTypes() {
        return {
            coachOnsite: GLGlobal.isActionValid(GSAdminAction.VisitSchool) ?
            new Set([
                [VisitationCoachStage.ScheduleDate, SetUpIcon, SetUpBoldIcon],
                [VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationCoachStage.VisitSchool, SchoolVisitIcon, SchoolVisitBoldIcon],
                [VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationCoachStage.SchoolReview, SchoolFeedbackIcon, SchoolFeedbackBoldIcon]
            ]) :            
            new Set([
                [VisitationCoachStage.ScheduleDate, SetUpIcon, SetUpBoldIcon],
                [VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationCoachStage.SchoolReview, SchoolFeedbackIcon, SchoolFeedbackBoldIcon]
            ]),
            coachLVA: new Set([
                [VisitationCoachStage.ScheduleDate, SetUpIcon, SetUpBoldIcon],
                [VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationCoachStage.VideoManager, VideoManagerIcon, VideoManagerBoldIcon],
                [VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationCoachStage.SchoolReview, SchoolFeedbackIcon, SchoolFeedbackBoldIcon],
            ]),
            teacherOnsite: new Set([
                [VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, CoachFeedbackBoldIcon],
            ]),
            teacherLVA: new Set([
                [VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationTeacherStage.VideoManager, VideoManagerIcon, VideoManagerBoldIcon],
                [VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, CoachFeedbackBoldIcon],
            ])
        }
    }
    renderCoachStageNames(stage) {
        switch(stage) {
            case VisitationCoachStage.ScheduleDate:
                return fmtMsg({ id: SchoolLocale.VisitationScheduleDate });
            case VisitationCoachStage.PrevisitationForm:
                return fmtMsg({ id: SchoolLocale.VisitationPrevisitationForm });
            case VisitationCoachStage.VisitSchool:
                return fmtMsg({ id: SchoolLocale.VisitationVisitSchool });
            case VisitationCoachStage.VideoManager:
                return fmtMsg({ id: SchoolLocale.VisitationVideoManager });
            case VisitationCoachStage.TeacherReview:
                return fmtMsg({ id: SchoolLocale.VisitationTeacherReview });
            case VisitationCoachStage.SchoolReview:
                return fmtMsg({ id: SchoolLocale.VisitationSchoolReview });
        }
    }
    renderTeacherStageNames(stage) {
        switch(stage) {
            case VisitationTeacherStage.ScheduleDate:
                return fmtMsg({ id: SchoolLocale.VisitationScheduleDate});
            case VisitationTeacherStage.PrevisitationForm:
                return fmtMsg({ id: SchoolLocale.VisitationPrevisitationForm});
            case VisitationTeacherStage.VideoManager:
                return fmtMsg({ id: SchoolLocale.VisitationVideoManager});
            case VisitationTeacherStage.ViewCoachFeedback:
                return fmtMsg({ id: SchoolLocale.VisitationFeedback });
            case VisitationTeacherStage.GiveCoachFeedback:
                return fmtMsg({ id: SchoolLocale.VisitationCoachFeedback });
        }
    }
    renderStages() {
        const { isCoach, visitation: { stage, type }, teacherStage, selectedStage } = this.props;
        const isOnsite = type == VisitationType.OnSite;
        const formatStage = teacherStage ? teacherStage : stage;
        let currentProgress = null;
        if (isCoach && isOnsite) {
            currentProgress = this.progressTypes().coachOnsite;
        } else if (isCoach && !isOnsite) {
            currentProgress = this.progressTypes().coachLVA;
        } else if (!isCoach && isOnsite) {
            currentProgress = this.progressTypes().teacherOnsite;
        } else if (!isCoach && !isOnsite) {
            currentProgress = this.progressTypes().teacherLVA;
        }
        return [...currentProgress].map((v, k) => {
            const color = this.getClassName(formatStage, selectedStage, v[0]);
            const title = isCoach ? this.renderCoachStageNames(v[0]) :
                                        this.renderTeacherStageNames(v[0]);
                return <div key={k} className={`${color} menu-item`} onClick={() => formatStage >= v[0] && this.showTab(formatStage, v[0])}>
                    <div className="icon-container">
                        {typeof v[1] === "string" ? <div className="circle">
                            <Icon type={v[1]} />
                        </div> :
                        (color.includes("green selected") ? React.createElement(v[2], {}) : 
                                                            React.createElement(v[1], {}))}
                    </div>
                    <div className="title">{title}</div>
                </div>
        });
    }
    getClassName(formatStage, selectedStage, state) {
        if (formatStage > selectedStage && selectedStage == state) {
            return `green selected`;
        }
        if (formatStage > state) {
            return `green`;
        } else if (formatStage == state) {
            return `orange`;
        } else {
            return `black`;
        }
    }
    showTab(formatStage, state) {
        formatStage >= state &&
        this.props.selectedStageCallback(state);
    }
    render() {
        return <div className="progress">
            {this.renderStages()}
        </div>;
    }
}