import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";
import { TagListModel } from "../tag-manager";

export interface AccessibleItemModel {
    id: string;
    name: string;
}

export enum SchoolFormModelNames {
    region,
    school
}
// export interface SchoolModel {
//     id?: string
//     gsNumber?: string
//     name?: string
//     regionName?: string
//     subscriptionType?: string
//     previousTotal?: number
//     currentTotal?: boolean
//     license?: number
//     digitalLicense?: number
//     textbookLicense?: number
//     littleSeedLicense?: number
//     hasBillingHistory?: boolean
//     regionLicenseBillingHistoryId?: string
//     disabled?: boolean
// }
// export interface SchoolModel {
//     id?: string
//     name?: string
//     englishName?: string
//     subscriptionType?: number
//     subscriptionTypeUsage?: number
//     gsNumber?: string
//     maxUnit?: number
//     maxUnitsPerYear?: number
//     minDaysPerUnit?: number
//     image?: string
//     regionId?: string
//     regionName?: string
//     disabled?: boolean
//     address?: string
//     phone?: string
//     campusId?: string
//     allowMaterialRequest?: boolean
//     allowSetTeacherContactParent?: boolean
//     allowSetParentContactTeacher?: boolean
//     allowTeacherMailToParent?: boolean
//     allowParentMailToTeacher?: boolean
//     allowSchoolEditUnit?: boolean
//     allowSchoolEditLicense?: boolean
//     allowVirtualClass?: boolean
//     primaryLanguage?: string
//     hasPurchaseProduct?: boolean
// }
export interface SchoolModel {
    id?: string;
    schoolId?: string;
    name?: string;
    englishName?: string;
    subscriptionType?: number;
    subscriptionType4Sort?: number;
    subscriptionTypeText?: string;
    subscriptionTypeUsage?: number;
    gsNumber?: string;
    maxUnit?: number;
    lsMaxUnit?: number;
    maxUnitsPerYear?: number;
    minDaysPerUnit?: number;
    image?: string;
    regionId?: string;
    regionName?: string;
    regionEnglishName?: string;
    regionDisabled?: boolean;

    previousTotal?: number;
    currentTotal?: boolean;
    license?: number;
    digitalLicense?: number;
    textbookLicense?: number;
    littleSeedLicense?: number;
    hasBillingHistory?: boolean;
    regionLicenseBillingHistoryId?: string;

    disabled?: boolean;
    address?: string;
    phone?: string;
    campusId?: string;
    enforceUnitPlanSpacing?: boolean;
    allowMaterialRequest?: boolean;
    allowSetTeacherContactParent?: boolean;
    allowSetParentContactTeacher?: boolean;
    allowTeacherMailToParent?: boolean;
    allowParentMailToTeacher?: boolean;
    allowSchoolEditUnit?: boolean;
    allowSchoolEditLicense?: boolean;
    primaryLanguage?: string;
    hasPurchaseProduct?: boolean;

    studentCount?: number;
    licenses?: any;
    bothCount?: number;

    appUnlockDays?: number;
    defaultAppLockState?: number;

    hasMultipleTrainer?: boolean;

    allowGenerateUnitPlan?: boolean;
    allowPromoteClass?: boolean;

    pendingChangesCount?: number;
    prepComplete?: boolean;
    notes?: string;
    allowGSMaxUnit?: boolean;
    allowLSMaxUnit?: boolean;

    isGSMaxUnit?: boolean;
    isLSMaxUnit?: boolean;

    onSiteVisitCount?: number;
    lvaCount?: number;
    allowLessonPlanView?: boolean;
    annualPrepComplete?: boolean;
    appLockState?: number;
    schoolCode?: number;
    regionEnableCodeLogin: boolean;
    code?: number;
    tags?: number[];
    grapeSEEDLicenses?: number[],
    littleSEEDLicenses?: number[]
}

export enum SchoolModelPropNames {
    id = "id",
    gsNumber = "gsNumber",
    name = "name",
    schoolName = "schoolName",
    regionId = "regionId",
    regionName = "regionName",
    regionEnglishName = "regionEnglishName",
    subscriptionType = "subscriptionType",
    subscriptionType4Sort = "subscriptionType4Sort",
    subscriptionTypeText = "subscriptionTypeText",
    previousTotal = "previousTotal",
    currentTotal = "currentTotal",
    littleSeedLicense = "littleSeedLicense",
    grapeSeedLicense = "grapeSeedLicense",
    littleSEEDCount = "littleSEEDCount",
    license = "license",
    digitalLicense = "digitalLicense",
    digitalLicenseCount = "digitalLicenseCount",
    textbookLicense = "textbookLicense",
    textbookLicenseCount = "textbookLicenseCount",
    hasBillingHistory = "hasBillingHistory",
    regionLicenseBillingHistoryId = "regionLicenseBillingHistoryId",
    studentCount = "studentCount",
    bothCount = "bothCount",
    trainerId = "trainerId",
    trainerName = "trainerName",
    pendingChangesCount = "pendingChangesCount",
    prepComplete = "prepComplete",
    tags = "tags",
    tagsJoined = "tagsJoined",
    maxUnitLS = "maxUnitLS",
    maxUnitGS = "maxUnitGS",
    appLockState = "appLockState",
    SchoolCode = "SchoolCode"
}

export interface SchoolDetailModel {
    id?: string;
    name?: string;
    englishName?: string;
    subscriptionType?: string;
    schoolAdmin?: string;
    regionId?: string;
    gsNumber?: string;
    maxUnit?: number;
    code?: number;
    trainer?: string;
    image?: string;
    disabled?: boolean;
    appLockState?: number;
    fullAddress?: string;
    grapeSEEDLicenses?: number[];
    littleSEEDLicenses?: number[];
}

export interface AccessibleSchool extends AccessibleItemModel{
    regionId: string
}

export enum SchoolDetailModelPropNames {
    id = "id",
    name = "name",
    englishName = "englishName",
    subscriptionType = "subscriptionType",
    subscriptionTypeUsage = "subscriptionTypeUsage",
    schoolAdmin = "schoolAdmin",
    regionId = "regionId",
    gsNumber = "gsNumber",
    maxUnit = "maxUnit",
    trainer = "trainer",
    image = "image",
    disabled = "disabled",
    appLockState = "appLockState"
}
export enum CampusPropNames {
    name = "campusname",
    gsNumber = "campusGSNumber",
    phone = "phone",
    fax = "fax",
    postalCode = "postalCode",
    address1 = "address1",
    fullAddress = "fullAddress"
}
export interface SchoolUserModel {
    id?: string;
    name?: string;
    email?: string;
    state?: string;
    disabled?: boolean;
}
export enum SchoolUserModelPropNames {
    name = "name",
    email = "email"
}
export class GetSchoolRequest extends GLPagingRequest {
    regionId?: string;
}
export interface SchoolRequest extends GLRequest, SchoolModel {}
export interface SchoolResponse extends GLResponse, SchoolModel {}
export class GetSchoolUserRequest extends GLPagingRequest {
    roleType?: string;
    referenceResourceId?: string;
}
export interface SchoolUserRequest extends GLRequest, SchoolUserModel {}
export interface SchoolUserResponse extends GLResponse, SchoolUserModel {}

export enum AttachmentType {
    File = 1,
    Link = 2,
}

export interface UploadingStateModel {
    regionLicenseInvoiceHistoryId: string;
    progress: number;
}

export interface SchoolBillingModel {
    adjustments: number;
    closeDate: string;
    cycleEndDate: string;
    cycleStartDate: string;
    regionLicenseInvoiceHistoryId: string;
    attachmentType: AttachmentType;
    attachmentUrl: string;
    fileName: string;
}
export enum SchoolBillingModelPropNames {
    billingPeriod = "billingPeriod",
    closeDate = "closeDate",
    adjustments = "adjustments",
    fileName = "fileName"
}
export interface SchoolClassInvoiceAdjustmentModel {
    campusId: string;
    schoolClassId: string;
    campus: string;
    schoolClass: string;
    adjustment: number;
    adjustmentDate: string;
    adjustmentByUserId: string;
    adjustmentByUser: string;
}
export enum SchoolClassInvoiceAdjustmentModelPropNames {
    campusId = "campusId",
    schoolClassId = "schoolClassId",
    campus = "campus",
    schoolClass = "schoolClass",
    adjustment = "adjustment",
    adjustmentDate = "adjustmentDate",
    adjustmentByUserId = "adjustmentByUserId",
    adjustmentByUser = "adjustmentByUser"
}
export class SchoolBillingRequest extends GLPagingRequest {}
export interface SchoolBillingResponse extends GLResponse, SchoolBillingModel {}

export interface CampusModel {
    id?: string;
    schoolId?: string;
    name?: string;
    state?: string;
    city?: string;
    address?: string;
    phone?: string;
    fax?: string;
    postalCode?: string;
    disabled?: boolean;
    regionId?: string;
}
export interface CampusRequest extends GLRequest, CampusModel {}
export interface CampusResponse extends GLResponse, CampusModel {}
export interface CampusesPagingRequest extends GLPagingRequest {}

export interface SchoolTeacherModel {
    id?: string;
    name?: string;
    class?: number;
    email?: string;
    isInClass?: boolean;
    teachClass?: SchoolTeacherClassModel[];
}

export interface SchoolTeacherClassModel {
    id?: string;
    name?: string;
}

export enum SchoolUpdateStatus {
    Valid = 0,
    GSMaxUnitInvalid = -1, // When new school's GSMaxUnit is lower than existing Grapeseed class's MaxUnit
    LSMaxUnitInvalid = -2, // When new school's GSMaxUnit is lower than existing Littleseed class's MaxUnit
    BothMaxUnitInvalid = -3, // When both new GSMaxUnit and new LSMaxUnit is lower than existing Grapeseed and Littleseed class's MaxUnit
    GSLicenseTypeInvalid = -4,
    LSLicenseTypeInvalid = -5,
    BothLicenseTypeInvalid = -6,
}

export interface SchoolUpdateStatusModel {
    status: SchoolUpdateStatus;
    littleSeedClasses: ClassInvalidMaxUnitInfoModel[];
    grapeSeedClasses: ClassInvalidMaxUnitInfoModel[];
}
export interface ClassInvalidMaxUnitInfoModel {
    campusId: string;
    campus: string;
    schoolClassId: string;
    schoolClass: string;
    licenseType: any;
}

export interface UpdateCoachSchoolAssociationModel {
    assignedSchools: string[],
    unassignedSchools: string[]
}

export interface SASModel {
    api: string;
    container: string;
    blob: string;
    sourceCountryCode:string;
}

export interface CreateBillingAttachmentRequestModel {
    regionLicenseInvoiceHistoryId: string;
    schoolId: string;
    name: string;
    filePath: string;
    attachmentType: AttachmentType;
    sourceCountryCode:string;
}
