import { GLServiceBase, getServiceMethodKey, HttpMethod } from 'gl-commonui'
import { injectable } from '@app/util/di';
import { IChatService, IContentResourceService, ITrainingResourceService } from './interface';
import { ServerExceptionCode } from '@app/util/coach/enum';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';

@injectable()
export class ChatService extends GLServiceBase<any, any> implements IChatService {

    serviceRoute = { prefix: 'admin/v1/chats', suffix: '{}' }

    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.ChatAttendersNoneSameSchoolException)] = GSAdminLocale.ChatErrorCannot;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ChatAttendersNoneSameSchoolException)] = GSAdminLocale.ChatErrorCannot;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ChatThreadTitleIsInvalidException, `${this.serviceRoute.prefix}/groups/{groupId}/threads`)] = GSAdminLocale.ChatThreadTitleIllegal;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ChatThreadTitleIsInvalidException, `${this.serviceRoute.prefix}/groups/{groupId}/threads/{threadId}`)] = GSAdminLocale.ChatThreadTitleIllegal;
        return result;
    })();

    createNewChat(params: any) {
        return this.createWithoutLoader(params).catch(e => ({ isError: true }));
    }

    createNewVisitationChat(params: any) {
        return this.createWithoutLoader("visitation", params);
    }

    createNewMessage(params: any) {
        return this.createWithoutLoader("messages", params);
    }

    getChatMessages(params: any) {
        return this.get("threads/{threadId}/messages", params);
    }

    makeMessagesRead(params: any) {
        return this.request.put(this.url("messages/{messageId}/read"), params)
    }

    makeAllMessagesRead(params: any) {
        return this.request.put(this.url("messages/reads"), params);
    }

    getUnReadMessagesCount(params: any) {
        return this.get("messages/unreads/number", params);
    }

    getAllUnReadMessagesCount(params: any) {
        return this.request.post(this.url("messages/unreads/numbers"), params);
    }

    getRecentMessages() {
        return this.get("messages/recent");
    }

    getNearByMessages(params: any) {
        return this.get("messages/{messageId}/nearby", params);
    }

    searchMessages(params: any) {
        return this.get("messages/searching", params);
    }

    async getAllUnReadBadges(senderIds, visitationId) {
        const data = await this.getAllUnReadMessagesCount({ senderIds: senderIds });
        // console.log(data);
        // console.log(visitationId);
        return data.reduce((map, v) => {
            map[v.senderId] = v.unReadMessages
                .filter(x => x.visitationId === visitationId)
                .reduce((sum, c) => sum + c.count, 0);
            return map;
        }, {});
    }

    async getBadgeCount(id) {
        const data = this.getUnReadMessagesCount({ senderId: id })
        return data;
    }

    async getChattedUsers(params?: { schoolIds: Array<string> }) {
        return this.get("groups/attenders", params);
    }

    async getChatThreads(params?: { groupId: string, visitationId?: string }) {
        return this.get("groups/{groupId}/threads", params)
    }

    async updateThread(params?: { groupId: string, threadId: string, title: string }) {
        // return this.request.put(this.url("groups/{groupId}/threads/{threadId}"), params).catch(e => ({ isError: true }));
        return this.update("groups/{groupId}/threads/{threadId}", params).catch(e => ({ isError: true }));
    }

    async addNewThread(params?: any) {
        return this.createWithoutLoader("groups/{groupId}/threads", params).catch(e => ({ isError: true }));
    }

    async getChatThreadContext(params?: any) {
        return this.get("groups/{groupId}/threads/{threadId}/build", params).catch(e => ({ isError: true }));
    }

    async searchThreads(params?: any) {
        return this.get("threads/searching", params);
    }

    async getChatThreadByAttenders(params?: any) {
        return this.get("attenders/threads", params);
    }
}

@injectable()
export class ContentResourceService extends GLServiceBase<any, any> implements IContentResourceService {

    serviceRoute = { prefix: 'content/v1/resource' }

    errMsgs = (() => {
        const result = {};
        return result;
    })();

    getResources(params: any) {
        return this.createWithoutLoader("ContentSearch?keyword={keyword}&regionId={regionId}&versionGroupId={versionGroupId}&maxUnit={maxUnit}", params);
    }
    getLocalResources(params: any) {
        return this.get("searchLocalContent", params);
    }
}

@injectable()
export class TrainingResourceService extends GLServiceBase<any, any> implements ITrainingResourceService {
    serviceRoute = { prefix: 'training/v1/teachercourse' }

    errMsgs = (() => {
        const result = {};
        return result;
    })();

    getCourses(params: any) {
        const idsParams = params.ids.map(id => `&ids=${id}`).join("");
        return this.createWithoutLoader(`GetSearchContentByUser?searchText={searchText}${idsParams}`, params);
    }
}
