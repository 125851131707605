import { Action, Actions, ListStatesRadios, ListStatesRadiosProps } from "@app/components";
import { useEffectAfterFirst } from "@app/hooks/useEffectAfterFirst";
import { useWijmoEditingHandler, WijmoCellEditCallback, useWijmoValidators, WijmoGridValidation } from "@app/hooks";
import { GSAdminLocale } from "@app/locales/localeid";
import { deepClone, ListStates } from "@app/util";
import { wijmoGridResponsive } from "@app/util/wijmo";
import { Col, Empty, Input, Pagination, Row } from "antd-min";
import classNames from "classnames";
import { GLGlobal, MessageHelper, NotificationType } from "gl-commonui";
import { cloneDeep, get, isFunction, isNumber, merge } from "lodash";
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CollectionView, EventArgs, IGetError, IPredicate, SortDescription } from "wijmo/wijmo";
import {
    AllowMerging,
    AllowResizing,
    CellRangeEventArgs,
    Column,
    FlexGrid as IFlexGrid,
    FormatItemEventArgs,
    HeadersVisibility,
    KeyAction,
    SelectionMode,
} from "wijmo/wijmo.grid";
import { FlexGridSearch as IFlexGridSearch } from "wijmo/wijmo.grid.search";
import { FlexGrid } from "wijmo/wijmo.react.grid";
import * as wjGroup from "wijmo/wijmo.react.grid.grouppanel";
import { FlexGridSearch } from "wijmo/wijmo.react.grid.search";
import { HAlign, Workbook, WorkbookCell, WorkbookColumn, WorkbookFont, WorkbookRow, WorkbookStyle, WorkSheet } from "wijmo/wijmo.xlsx";
import { Loading } from "../survey";
import { mergeClasses } from "../survey/functions";
import "./grid.less";
import { FlexGridContextMenu } from "./context-menu";

interface GridProps extends WijmoGridProps {
    /** Whether to set local dataSource */
    itemsSource?: CollectionView | any[];

    /** Whether to show a loading indicator over grid */
    loading?: boolean;

    /** Whether to turn the grid into a virtual grid. */
    serviceFunction?: (params: object) => Promise<any>;

    /** Whether to set master query params to send to service with each request */
    serviceParams?: object;

    /** Whether to format data before setting it to grid. */
    serviceFormatData?: (data: any) => any[];

    /** Whether to enable search on the grid. Currently local search is not supported, it only works when serviceFunction is passed. */
    search?: boolean;
    searchOptions?: {
        /** Whether to set a label to search. */
        label?: string;

        /** Whether to set a callback on search. */
        onSearch?: (filter: string) => void;
    };

    /** Whether allow grid to exported to an excel. */
    allowExport?: boolean;
    exportOptions?: {
        buttonLocaleId?: string;
        buttonIcon?: any;
        filename?: string;
        includeColumns?: {
            binding: string;
            header: string;
            width?: number;
        }[];
        excludeColumns?: string[];
        formatData?: (data: any) => any[];
        successMessage?: string;
        errorMessage?: string;
        noDataMessage?: string;
        onSave?: (base64: string) => void;
        onError?: (reason: any) => void;
    };

    /** Whether to enable pagination. */
    pagination?: boolean;

    /** Whether to set pagination size. Default Value: 10 */
    paginationSize?: number;

    /** Whether to set showTotal prop in pagination. */
    paginationShowTotal?: (total: number, range: [number, number]) => React.ReactNode;

    /** Whether to set pagination total explicitly. */
    paginationTotal?: number;

    /** Whether to enable the list states */
    listStateShow?: boolean;

    /** Whether to pass props to list states component */
    listStateOptions?: ListStatesRadiosProps;

    /** Whether to show a title of the grid */
    title?: string | ReactNode;

    /** Whether to make the grid bordered */
    bordered?: boolean;

    /** Whether to add action/link/button to the grid. They will be reandered at the right of the grid above header. */
    actions?: JSX.Element[];

    /** Whether to enable the grouping */
    grouping?: boolean;

    /** Whether to set group bar text. */
    groupBarText?: string;

    /** Whether to set validations into collectionView */
    getError?: IGetError;

    /** Whether to set filter into collectionView */
    filter?: IPredicate;

    /** A callback of refresh the group panel. */
    onRefreshed?: (grid: any) => any;

    /** Whether to add a css class to grid wrapper. */
    className?: string;

    children?: any;

    /** A callback for cellEditEnding supported by Wijmo */
    beginningEdit?: WijmoCellEditCallback;

    /** A callback for cellEditEnding supported by Wijmo */
    cellEditEnding?: WijmoCellEditCallback;

    /** A callback for cellEditEnded supported by Wijmo */
    cellEditEnded?: WijmoCellEditCallback;

    /** Whether to check the grid is valid or not */
    valid?: (isValid: boolean) => void;

    /** Whether to disable cells and marked them as grayed color, It gets called through formatItem function. */
    cellDisableHandler?: WijmoCellEditCallback;

    /** Whether to set default values when a new item is created on the grid */
    newItemCreator?: () => any;

    updatedLayout?: (s, e) => void;

    /** Gets or sets a value that determines whether the grid should automatically resize the rows when the data or grid layout change. */
    autoRowHeights?: boolean;

    /** Whether to skip service call when a column has been sorted  */
    sortInClient?: boolean;

    /** Whether to prevent the function wijmoGridResponsive run automatically  */
    preventWijmoGridResponsive?: boolean;

    /** Handle when change page on grid  */
    onPageSelected?: (page : number) => void;
}

export interface GridRef {
    /** Whether to reload data from service */
    reload: () => void;

    grid: IFlexGrid;

    validation: WijmoGridValidation;

     /** Whether to reload data from service with first currentPage */
    reset: () => void;
}

export const Grid = forwardRef<GridRef, GridProps>((props, ref) => {
    const {
        loading: loadingProp,
        children,
        pagination,
        paginationSize,
        paginationShowTotal,
        grouping,
        groupBarText,
        initialized,
        onRefreshed,
        className,
        itemsSource,
        serviceFunction,
        serviceParams,
        serviceFormatData,
        defaultSort,
        search,
        searchOptions: searchProps,
        actions,
        listStateShow,
        listStateOptions,
        title,
        bordered,
        allowExport,
        exportOptions: exportProps,
        getError,
        filter: collectionViewFilter,
        beginningEdit,
        cellEditEnding,
        cellEditEnded,
        valid: validCallback,
        cellDisableHandler,
        newItemCreator,
        paginationTotal,
        sortInClient,
        onPageSelected: onPageSelectedCallback,
        ...rest
    } = props;

    const defaultProps = cloneDeep(Grid.defaultProps.exportOptions); // creates a whole new object
    const exportOptions = merge(defaultProps, exportProps); // lodash merge, adds properties of arg2 in arg1
    const searchOptions = merge(defaultProps, searchProps);

    const [collectionView, setCollectionView] = useState<CollectionView>(null);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(null);
    const [currentSort, setCurrentSort] = useState<IWijmoSort>(defaultSort);
    const [filter, setFilter] = useState<string>("");

    const [listStateCounts, setListStateCounts] = useState<IListStatesCounts>({
        value: ListStates.Active,
        allCnt: 0,
        activeCnt: 0,
        inActiveCnt: 0,
        pendingCount: 0,
        futureCount: 0,
    });

    const [loadingState, setLoadingState] = useState<boolean>(false);
    const loading = loadingProp || loadingState;

    const noData: boolean = (!collectionView || !collectionView.sourceCollection.length) && !loading && !rest.allowAddNew;
    const noDataMessage = GLGlobal.intl.formatMessage({
        id: GSAdminLocale.ChangeLogNoData,
    });

    const flexGridRef = useRef<{ control: IFlexGrid }>();
    const flexGridSearchRef = useRef<{ control: IFlexGridSearch }>();
    const grid = flexGridRef.current && flexGridRef.current.control;

    const validation = useWijmoValidators(grid, children);
    const editingDataItem = useWijmoEditingHandler(grid, beginningEdit, cellEditEnding, cellEditEnded, cellDisableHandler);

    useEffect(() => {
        if (serviceFunction) {
            getData();
        }
        setFlexGridOnSearchCallback();
    }, []);

    useEffect(() => {
        if (itemsSource instanceof CollectionView) {
            changeCollectionView(deepClone(itemsSource.items));
        } else {
            changeCollectionView(itemsSource);
        }
    }, [itemsSource]);

    useEffectAfterFirst(() => {
        if (serviceFunction) {
            getData();
        } else {
            collectionView.moveToPage(currentPage - 1);
        }
    }, [currentPage, filter, listStateCounts.value]);

    useEffectAfterFirst(() => {
        if (serviceFunction && !sortInClient) {
            getData();
        }
    }, [currentSort]);

    useEffect(() => {
        if (validCallback) {
            validCallback(validation.isValid);
        }
    }, [validation.isValid]);

    useEffect(() => {
        if(isNumber(paginationTotal)) {
            setTotalCount(paginationTotal);
        }
    }, [paginationTotal]);

    useEffect(() => {
        if (onPageSelectedCallback) {
            onPageSelectedCallback(currentPage);
        }
    }, [currentPage])

    useImperativeHandle(ref, () => ({
        reload: getData,
        grid: grid,
        validation,
        reset: () => {
            if(!serviceFunction) {
                return;
            }
            if(currentPage === 1) {
                getData();
            } else {
                setCurrentPage(1);
            }
        }
    }));

    const getData = () => {
        setLoadingState(true);
        const params = getParams();
        const serviceFunctionResponse = serviceFunction(params);
        if(serviceFunctionResponse instanceof Promise) {
            serviceFunctionResponse.then((data) => {
                setDataToCollectionView(data);
                setLoadingState(false);
            })
            .catch(() => setLoadingState(false));
        } else {
            setLoadingState(false);
        }
    };

    const getParams = (): any => {
        const params: any = {
            ...serviceParams,
            limit: paginationSize,
            offset: (currentPage - 1) * paginationSize,
            keyword: filter,
        };
        if (currentSort) {
            params.sortBy = currentSort.column;
            params.isDescending = !currentSort.ascending;
        }
        if (listStateShow) {
            switch (listStateCounts.value) {
                case ListStates.All:
                    params.includePendingInvite = true;
                    break;
                case ListStates.Active:
                    params.disabled = false;
                    break;
                case ListStates.Inactive:
                    params.disabled = true;
                    break;
                case ListStates.Pending:
                    params.includePendingInvite = true;
                    params.includeOnlyPending = true;
                    break;
                case ListStates.Future:
                    // need to work on it
                    break;
            }
        }
        return params;
    };

    const setDataToCollectionView = (data: any) => {
        // Save data to collection
        const records = serviceFormatData(data);
        changeCollectionView(records);

        // Saving total count to state
        setTotalCountWrapper(data);
        // Saving list stated counts to state
        if (data.extraData) {
            const { activeCnt, allCnt, futureCnt: futureCount, inActiveCnt, pendingCount } = data.extraData;
            setListStateCounts({ ...listStateCounts, activeCnt, allCnt, futureCount, inActiveCnt, pendingCount });
        }
    };

    const changeCollectionView = (records: any[]): void => {
        const newCollectionView = new CollectionView(records);
        if (!serviceFunction && pagination) {
            newCollectionView.pageSize = paginationSize;
            newCollectionView.moveToPage(currentPage - 1);
        }
        if (currentSort) {
            newCollectionView.sortDescriptions.push(new SortDescription(currentSort.column, currentSort.ascending));
        }
        if (getError) {
            newCollectionView.getError = getError;
        }
        if (newItemCreator) {
            newCollectionView.newItemCreator = newItemCreator;
        }
        if (isFunction(collectionViewFilter)) {
            newCollectionView.filter = collectionViewFilter;
        }
        setCollectionView(newCollectionView);
    };

    const setTotalCountWrapper = (data: any): void => {
        if (!listStateShow || !data.extraData) {
            return setTotalCount(data.totalCount);
        }
        let count: number;
        switch (listStateCounts.value) {
            case ListStates.All:
                count = data.extraData.allCnt;
                break;
            case ListStates.Active:
                count = data.extraData.activeCnt;
                break;
            case ListStates.Inactive:
                count = data.extraData.inActiveCnt;
                break;
            case ListStates.Pending:
                count = data.extraData.pendingCount;
                break;
            case ListStates.Future:
                count = data.extraData.futureCnt;
                break;
        }
        setTotalCount(count);
    };

    const onGridInit = (grid: IFlexGrid): void => {
        if (initialized) {
            initialized(grid);
        }

        // Adjust row height
        grid.rows.defaultSize = 40;
        grid.columnHeaders.rows.defaultSize = 40;

        // Make the grid responsive with adding horizontal scrollbar
        if(!props.preventWijmoGridResponsive) {
            wijmoGridResponsive(grid);
        }
        // Listen sorting changes
        grid.sortedColumn.addHandler((flex, args) => {
            const sortDescription = grid.collectionView.sortDescriptions[0];
            setCurrentSort({
                column: sortDescription.property,
                ascending: sortDescription.ascending,
            });
        });

        // Render context menu
        new FlexGridContextMenu(grid);
    };

    const setFlexGridOnSearchCallback = () => {
        if (!searchOptions.onSearch || !flexGridSearchRef.current || !flexGridSearchRef.current.control) {
            return null;
        }
        flexGridSearchRef.current.control.inputElement.oninput = () => {
            searchOptions.onSearch(flexGridSearchRef.current.control.inputElement.value);
        };
    };

    const onGroupRefresh = () => {
        onRefreshed && onRefreshed(grid);
    };

    const onListStateChange = (value: ListStates) => {
        setCurrentPage(1);
        setListStateCounts({ ...listStateCounts, value });
    };

    const exportGrid = (): void => {
        if (serviceFunction) {
            return exportFromServiceData();
        }
        return exportLocalGridData();
    };

    const exportLocalGridData = (): void => {
        desingExcelAndDownload(grid.collectionView.items);
    };
    const exportFromServiceData = (): void => {
        const params = getParams();
        params.keyword = "";
        params.offset = 0;
        delete params.limit;
        setLoadingState(true);
        serviceFunction(params)
            .then((data) => {
                desingExcelAndDownload(data);
                setLoadingState(false);
            })
            .catch(() => setLoadingState(false));
    };

    const desingExcelAndDownload = (data): void => {
        const { filename, formatData, includeColumns, excludeColumns, onSave, onError, successMessage, errorMessage, noDataMessage } = exportOptions;
        const records = formatData(data);

        // Skip of no records are available in data
        if (!records.length) {
            return MessageHelper.Message(NotificationType.Warning, noDataMessage);
        }

        // Beginning of creation the workbook
        const workbook = new Workbook();
        const sheet = new WorkSheet();
        const columns = [
            ...grid.columns.filter((column) => !excludeColumns.includes(column.binding) && column.binding),
            ...includeColumns,
        ] as Column[];

        // Adding header row
        const headerRow = new WorkbookRow();
        headerRow.style = new WorkbookStyle();
        headerRow.style.font = new WorkbookFont();
        headerRow.style.font.bold = true;
        headerRow.style.hAlign = HAlign.Center;

        // Adding columns and header cells
        columns.forEach((c, index) => {
            // Sheet Column
            const column = new WorkbookColumn();
            column.width = c.width;
            sheet.columns.push(column);

            // Header cell
            const headerCell = new WorkbookCell();
            headerCell.value = c.header;
            headerRow.cells.push(headerCell);
        });
        sheet.rows.push(headerRow);

        // Adding records
        records.forEach((record) => {
            const row = new WorkbookRow();
            columns.forEach((c) => {
                const cell = new WorkbookCell();
                cell.value = get(record, c.binding);
                row.cells.push(cell);
            });
            sheet.rows.push(row);
        });

        workbook.sheets.push(sheet);

        // Saving the workbook
        workbook.saveAsync(
            `${filename || "Workbook"}.xlsx`,
            (base64) => {
                if (onSave) {
                    onSave(base64);
                }
                if (successMessage) {
                    MessageHelper.Message(NotificationType.Success, successMessage);
                }
            },
            (reason) => {
                if (onError) {
                    onError(reason);
                }
                if (errorMessage) {
                    MessageHelper.Message(NotificationType.Success, errorMessage);
                }
            },
        );
    };

    const renderGroupBar = (): ReactNode => {
        return <wjGroup.GroupPanel className="group-panel" placeholder={groupBarText} grid={grid} onRefreshed={onGroupRefresh} />;
    };

    const renderHead = (): ReactNode => {
        if (!search && !listStateShow && !title && !actions.length && !allowExport) {
            return null;
        }
        return (
            <Row className="wgrid__head" type="flex" align="bottom" justify="space-between">
                <Col className="wgrid__head__left-column">
                    {search && renderSearchBar()}
                    <Row type="flex" align="middle" gutter={15}>
                        {title && (
                            <Col>
                                <h3 className="wgrid__title">{title}</h3>
                            </Col>
                        )}
                        {listStateShow && <Col>{renderListStates()}</Col>}
                    </Row>
                </Col>
                <Col>
                    <Actions actions={[...actions, ...getDefaultActions()]} />
                </Col>
            </Row>
        );
    };

    const getDefaultActions = (): JSX.Element[] => {
        const actions = [];
        if (allowExport) {
            const { buttonIcon, buttonLocaleId } = exportOptions;
            actions.push(<Action key="u0" materialIcon={buttonIcon} onClick={exportGrid} textLocaleId={buttonLocaleId} />);
        }
        return actions;
    };

    const renderSearchBar = (): ReactNode => {
        return (
            <div className="wgrid__search">
                {serviceFunction ? (
                    <Input.Search
                        onSearch={(value) => {
                            setCurrentPage(1);
                            setFilter(value);
                            if (searchOptions.onSearch) {
                                searchOptions.onSearch(value);
                            }
                        }}
                        placeholder={searchOptions.label}
                        allowClear={true}
                    />
                ) : (
                    <FlexGridSearch ref={flexGridSearchRef} grid={grid} placeholder={searchOptions.label} />
                )}
            </div>
        );
    };

    const renderListStates = (): ReactNode => {
        return (
            <ListStatesRadios
                {...listStateCounts}
                {...listStateOptions}
                onChange={onListStateChange}
                className={mergeClasses("wgrid__state", listStateOptions && listStateOptions.className)}
            />
        );
    };

    const renderGrid = (): ReactNode => {
        return (
            <div className="wgrid__wrapper">
                <FlexGrid itemsSource={collectionView} {...rest} ref={flexGridRef} initialized={onGridInit}>
                    {children}
                </FlexGrid>
                {renderLoader()}
            </div>
        );
    };
    const renderPagination = (): ReactNode => {
        return (
            <Pagination
                className="wgrid__pagination"
                current={currentPage}
                total={totalCount || collectionView.sourceCollection.length}
                pageSize={paginationSize}
                onChange={(page) => setCurrentPage(page)}
                hideOnSinglePage={true}
                showTotal={paginationShowTotal}
            />
        );
    };

    const renderLoader = (): ReactNode => {
        return <Loading visible={loading} className="wgrid__loader" />;
    };

    const renderNoData = (): ReactNode => {
        return <Empty description={noDataMessage} />;
    };

    return (
        <div className={classNames("wgrid", className, bordered && "wgrid--bordered")}>
            {renderHead()}
            {grouping && renderGroupBar()}
            {renderGrid()}
            {noData && renderNoData()}
            {pagination && collectionView && !noData && renderPagination()}
        </div>
    );
});

Grid.defaultProps = {
    selectionMode: SelectionMode.ListBox,
    headersVisibility: HeadersVisibility.Column,
    allowResizing: AllowResizing.Columns,
    allowSorting: true,
    stickyHeaders: true,
    pagination: true,
    paginationSize: 10,
    alternatingRowStep: 0,
    serviceFormatData: (data) => data,
    searchOptions: {
        label: "Search",
    },
    actions: [],
    exportOptions: {
        buttonLocaleId: "Export",
        buttonIcon: "save_alt",
        includeColumns: [],
        excludeColumns: [],
        formatData: (data) => data,
    },
};

interface WijmoGridProps {
    allowAddNew?: boolean;
    allowDelete?: boolean;
    allowMerging?: AllowMerging;
    allowResizing?: AllowResizing;
    allowSorting?: boolean;
    headersVisibility?: HeadersVisibility;
    frozenColumns?: number;
    selectionMode?: SelectionMode;
    showMarquee?: boolean;
    showSelectedHeaders?: HeadersVisibility;
    stickyHeaders?: boolean;
    keyActionTab?: KeyAction;
    itemsSource?: any;
    isReadOnly?: boolean;
    alternatingRowStep?: number;
    formatItem?: (s: IFlexGrid, e: FormatItemEventArgs) => any;
    initialized?: (grid: IFlexGrid) => any;
    onSortedColumn?: (e: CellRangeEventArgs) => void;
    defaultSort?: IWijmoSort;
    loadedRows?: (s: IFlexGrid, e: EventArgs) => void;
    pasting?: (grid: IFlexGrid, event: CellRangeEventArgs) => void;
    pasted?: (grid: IFlexGrid, event: CellRangeEventArgs) => void;
    pastingCell?: (grid: IFlexGrid, event: CellRangeEventArgs) => void;
    validateEdits?: boolean;
}

export interface IWijmoSort {
    column: string;
    ascending: boolean;
}

interface IListStatesCounts {
    value?: ListStates;
    allCnt?: number;
    activeCnt?: number;
    inActiveCnt?: number;
    pendingCount?: number;
    futureCount?: number;
}
