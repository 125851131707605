import { useState } from "react";
import { CommonMenuOptions } from "@app/util";


export const enum ContextMenuType {
    Common = 1,
};

export function useContextMenu<TMenu>(type: ContextMenuType): TMenu {
    const [menu, setMenu] = useState({});

    if (menu[type]) {
        return menu[type];
    }

    switch (type) {
        case ContextMenuType.Common:
            const commonMenu = new CommonMenuOptions();
            setMenu({ ...menu, [type]: commonMenu });
            return commonMenu as any as TMenu;

        default:
            return {} as TMenu;
    }
}
