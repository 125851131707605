
import { FlexGrid, CellType, GridPanel, CellRange, GLGridMergeRange, MergeManager } from './index';
import groupBy from 'lodash/groupBy'

export interface GLGridMergeManagerOptions {
    sortInClient?: boolean;
    mergedRowsStruct?: MergedRowsStruct[];
    columns?: any[]
}
export class GLGridMergeManager extends MergeManager {

    private mergeRange: CellRange[];
    private options: GLGridMergeManagerOptions;
    constructor(g: FlexGrid, mergeRange: CellRange[], options?: GLGridMergeManagerOptions) {
        super(g);
        this.mergeRange = mergeRange;
        this.options = options || {}
    }
    rebuildMergeRange(grid) {
        if (this.options.sortInClient) {
            const sortedData = grid.rows.map(r => r.dataItem)
            this.mergeRange = GLGridMergeManager.getMergedRowsRanges(sortedData, this.options.mergedRowsStruct, this.options.columns)
        }
    }

    getMergedRange(panel: GridPanel, row: number, column: number, clip?: boolean): CellRange {
        if (this.mergeRange && panel.cellType == CellType.Cell && row >= 0) {
            for (let index = 0; index <= this.mergeRange.length - 1; index++) {
                if (this.mergeRange[index].contains(row, column)) {
                    return this.mergeRange[index];
                }
            }
        }
        return null;
    };

    static getMergedRanges<T>(dataSource: T[], dataSourceKey: string, mergedColumns: Map<string, number>): CellRange[] {
        let result: CellRange[] = [], mergedColumnRange: GLGridMergeRange[] = [];
        let startRow = 0, count = 0, key = null;
        for (let row = 0; row <= dataSource.length - 1; row++) {
            if (key != dataSource[row][dataSourceKey]) {
                if (key != null) {
                    for (let column of mergedColumns) {
                        const [name, index] = column;
                        mergedColumnRange.push({ row: startRow, columnIndex: index, columnName: name, count: count })
                    }
                }
                key = dataSource[row][dataSourceKey];
                startRow = row, count = 0;
            }
            else {
                count++;
            }
        }
        if (count > 0) {
            for (let column of mergedColumns) {
                const [name, index] = column;
                mergedColumnRange.push({ row: startRow, columnIndex: index, columnName: name, count: count })
            }
        }
        mergedColumnRange.forEach(gmr => {
            result.push(new CellRange(gmr.row, gmr.columnIndex, gmr.row + gmr.count, gmr.columnIndex));
        });
        return result;
    }

    static getMergedRowsRanges<T>(dataSource: T[], mergedRowsStruct: MergedRowsStruct[], columns: any[]): CellRange[] {

        const setColumnIndexes = (mergedRowsStruct: MergedRowsStruct[]) => {
            mergedRowsStruct.forEach(m => {
                m.mergedRowsByColumnIndexes = m.mergedRowsByColumns.map(c => {
                    return {
                        index: columns.findIndex(col => col.dataIndex === c),
                        name: c
                    }
                }) as any[]
            })
        }

        setColumnIndexes(mergedRowsStruct)

        const allCellRanges = mergedRowsStruct.reduce((ranges, struct) => {

            const grouped = groupBy(
                dataSource.map((rowData: any, index) => (rowData.rowIndex = index, rowData)),
                rowData => struct.mergedRowsByKeys.map(key => rowData[key]).join('+-+')
            )

            const rowIndexes = Object.getOwnPropertyNames(grouped)
                .map(key => {
                    return {
                        src: grouped[key][0].rowIndex,
                        dest: grouped[key][
                            grouped[key].length == 1 ? 0 : grouped[key].length - 1
                        ].rowIndex
                    }
                })

            const partialCellRanges = rowIndexes.reduce((cellRanges, rowIndex) => {
                return cellRanges.concat(struct.mergedRowsByColumnIndexes.map((colIndex) => {
                    return new CellRange(rowIndex.src, colIndex.index, rowIndex.dest, colIndex.index)
                }))
            }, [])

            return ranges.concat(partialCellRanges)

        }, [])

        return allCellRanges
    }
}

export interface MergedRowsStruct {
    mergedRowsByKeys: string[]
    mergedRowsByColumns: string[]
    mergedRowsByColumnIndexes?: { name: string, index: number }[]
}