import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IDeviceService, DeviceRequest, DevicesPagingRequest } from './index';

@injectable()
export class DeviceService extends GLServiceBase<DeviceRequest, DevicesPagingRequest> implements IDeviceService {
    serviceRoute = { prefix: 'account/v1/devices', suffix: '{id}' }
    getDeactivationCount(params) {
        this.serviceRoute = { prefix: `account/v2/devices`, suffix: `{userId}/deactivationRequests` }
        return this.get(params);
    }
    getDevices(params) {
        this.serviceRoute = { prefix: `account/v2/devices`, suffix: `` }
        return this.get(params);
    }
    deactivateDevice(params) {
        this.serviceRoute = { prefix: `account/v1/devices`, suffix: `{deviceId}/directDeactivate` }
        return this.update(params);
    }
}