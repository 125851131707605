import React, { Component } from 'react';
import { lazyInject } from '../../../util';
import { TYPES } from '../../../service/types';
import { Spin, Input } from 'antd-min';
import { IContentRevisionHistoryService } from '../../../service/admin/contentRevisionHistory';
import { GLGlobal } from 'gl-commonui';
import * as wjCore from 'wijmo/wijmo';
import * as wjGrid from 'wijmo/wijmo.react.grid';
import './contentRevisionHistory.less';
import moment from 'moment';
import AdminButton from '../../../components/admin/adminButton/adminButton';
import * as wjGroup from "wijmo/wijmo.react.grid.grouppanel";
import { Empty, Card } from 'antd-min';
import {SelectionMode} from "wijmo/wijmo.grid";

export default class ContentRevisionHistory extends Component<any, any> {
    private currentPageStatus = React.createRef();
    previous = React.createRef();
    next = React.createRef();
    view = null;

    constructor(props: any) {
        super(props);
        this.state = {
            contentRevisionHistory: [],
            wijmoData: [],
            contentLoaded: false,
            flex: null,
            disableNextButton: false,
            disablePreviousButton: false,
            wijmoDataSet: false
        };
    }
    @lazyInject(TYPES.IContentRevisionHistoryService)
    private contentRevisionHistoryService: IContentRevisionHistoryService;
    private maxResultsPerPage = 50;

    componentDidMount() {
        // load the content revision history
        if (!this.state.contentLoaded) {
          let user =   GLGlobal.loginInfo();
                    this.contentRevisionHistoryService.getContentChangeLog({
                        userId: user.profile.sub,
                        userRoles: user.profile.roleInfos.map((r, index) => index)
                    })
                    .then(res => {
                            this.setState({contentLoaded: true}, () => {
                                this.setState({ contentRevisionHistory: res, contentLoaded: true, wijmoData: res.length > 0 ? this.returnView(res) : [], wijmoDataSet: true});
                        })
                    })
        }
    }

    componentWillUnmount(){
        // destory listeners
        this.previous.current && this.previous.current.removeEventListener('click', this.backNavigation);
        this.next.current && this.next.current.removeEventListener('click', this.forwardNaviation);
    }

    // returns the view (a wijmo CollectionView)
    // for first time load of data
    returnView = (data) => {
        let _that = this;
        let wijmoData = data.map((d)=> {
            return {
                fileName: d.title,
                category: d.contentType,
                unit: d.unit,
                revision: d.revision,
                updatedOn: moment(d.updateDate).format("YYYY/MM/DD")
            }
        });

        // create a view
        let view = new wjCore.CollectionView(wijmoData, {
            pageSize: this.maxResultsPerPage,
            pageChanged: this.updateCurrentPage,
        });

        view.onPageChanged();
        // update the page count and current page
        this.updateCurrentPage(view);
        // save view
        this.view = view;
        // Event listener when user clicks the previous next button
        this.previous.current.addEventListener('click', this.backNavigation);
        this.next.current.addEventListener('click', this.forwardNaviation);

        return this.view;
    }

    backNavigation = () => {
        this.view.moveToPreviousPage();
        this.enableDisableButtons(this.view);
    }

    forwardNaviation = () => {
        this.view.moveToNextPage();
        this.enableDisableButtons(this.view);
    }

    // updates the status area (Where current page and total page are shown on page)
    updateCurrentPage = (view) => {
        let curr = wjCore.format('Page {index: n0} of {cnt: n0}', {
            index: view.pageIndex + 1,
            cnt: view.pageCount
        });
        this.currentPageStatus.current.value = curr;
        // disable / enable previous back buttons
        this.enableDisableButtons(view);
    }

    // Enable or disable the previous and next buttons of pagination (Navigation)
    enableDisableButtons = (view) => {
        // Previous button enable disable
        if((view.pageIndex+1) === 1){
            this.setState({disablePreviousButton: true}); // disable previous button when on first page
        }
        else if(this.state.disablePreviousButton){
            this.setState({disablePreviousButton: false}); // enable previous button if not on first page and previous button is disabled.
        }
        // Next button enable/disable
        if(view.pageCount === 1 || view.pageCount === (view.pageIndex+1)){
            this.setState({disableNextButton: true}); // disable next button when on last page
        }
        else if(this.state.disableNextButton){
            this.setState({disableNextButton: false}); // enable next button when not on last page and next button is disabled.
        }
    }

    // filter/search the data
    filter = (e) => {
        let searchTerm = e.target.value.trim().toLowerCase();
        let pattern = new RegExp(searchTerm,"i");
        // reinstate the next and previous buttons values when searchTerm is empty
        if(searchTerm.length == 0){
            this.setState({disablePreviousButton: true, disableNextButton:false});
        }
        // filter the collection
        this.state.flex.collectionView.filter = item => {
            return (searchTerm.length == 0 ||
                pattern.test(item.fileName) ||
                pattern.test(item.category) ||
                pattern.test(item.unit) ||
                pattern.test(item.revision));
        };
        // Update the navigation (Pagination)
        this.updateCurrentPage(this.state.flex.collectionView);
    }

    initialized  = (ctl) => {
        // save the flexgrid reference in state.
        this.setState({
            flex: ctl
        });
    }

    render() {
        if(!this.state.contentLoaded){
            return <div className="spinner">
                        <Spin size="large" />
                   </div>
        }

        if(this.state.wijmoDataSet && this.state.wijmoData.length === 0){
            return <Card>
                     <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                     </Card>
        }

        return <div className="revision-history-page-container">
                    <div className="content-revision-history">
                        <div className="content-heading"> Content Revision History </div>
                        <div className="grid-container">
                        <Input.Search
                            className="content-history-search"
                            placeholder="search"
                            onInput={this.filter}
                            onSearch={this.filter}
                            />
                        <wjGroup.GroupPanel className="group-panel" placeholder="Drag columns here to create groups" grid={this.state.flex}>
                         </wjGroup.GroupPanel>

                            <wjGrid.FlexGrid
                                allowAddNew={false}
                                allowMerging='All'
                                allowResizing='Both'
                                headersVisibility='All'
                                selectionMode={SelectionMode.ListBox}
                                showMarquee={true}
                                showSelectedHeaders='None'
                                stickyHeaders={true}
                                itemsSource={this.state.wijmoData}
                                isReadOnly={true}
                                initialized={this.initialized}
                            >
                                <wjGrid.FlexGridColumn binding="fileName" header="File Name" width="44*"></wjGrid.FlexGridColumn>
                                <wjGrid.FlexGridColumn binding="category" header="Category" width="23*" ></wjGrid.FlexGridColumn>
                                <wjGrid.FlexGridColumn binding="unit" header="Unit" width="8*"></wjGrid.FlexGridColumn>
                                <wjGrid.FlexGridColumn binding="revision" header="Revision" width="10*"></wjGrid.FlexGridColumn>
                                <wjGrid.FlexGridColumn binding="updatedOn" header="Updated On" width="15*"></wjGrid.FlexGridColumn>
                            </wjGrid.FlexGrid>
                        </div>
                        <div>
                            <AdminButton reff={this.previous} text="Previous" disabled={this.state.disablePreviousButton}/>
                            <input ref={this.currentPageStatus} disabled={true} type="text" className="wj-form-control"/>
                            <AdminButton reff={this.next} text="Next" disabled={this.state.disableNextButton}/>
                        </div>
                    </div>
                </div>
    }
}
