import { useFilter, useSelector, useService } from "@app/hooks";
import { TagManagerLocale } from "@app/locales/localeid";
import { ITagManagerService, TagEntity, TagListModel } from "@app/service/tag-manager";
import { fmtMsg, TYPES } from "@app/util";
import { Badge, Button, Empty, Popover } from "antd-min";
import { TooltipPlacement } from "antd/lib/tooltip";
import { MatIcon, mergeClasses } from "gl-commonui";
import React, { FC, useState } from "react";
import "./tagging-editor.less";

interface TaggingEditorProps {
    entityId: string;
    entity: TagEntity;
    regionId: string;
    currentTags: TagListModel[];
    onSave: (tags: TagListModel[]) => void;
    popoverPlacement: TooltipPlacement;
}

export const TaggingEditor: FC<TaggingEditorProps> = (props) => {
    const { regionId, entity, entityId, currentTags, onSave, children, popoverPlacement } = props;

    const [visible, setVisible] = useState<boolean>(false);
    const [tagEditorState, setTagEditorState] = useState<{
        [key: string]: boolean;
    }>({});
    const [selectedTagCount, setSelectedTagCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const tags = useSelector((state) => state.tagManager.tagList[`${regionId}-${entity}`]);
    const unselectedTags = useFilter(
        tags,
        (tag) => {
            const isSelected = currentTags.find((currentTag) => currentTag.id === tag.id);
            return !isSelected;
        },
        [currentTags]
    );

    const tagManagerService = useService<ITagManagerService>(TYPES.ITagManagerService);

    const open = () => {
        setVisible(true);
    };
    const close = () => {
        setVisible(false);

        // Wait for the popover to be closed.
        setTimeout(() => {
            setSelectedTagCount(0);
            setTagEditorState({});
        }, 100);
    };
    const toggle = () => {
        if (visible) {
            close();
        } else {
            open();
        }
    };

    const onSelectTag = (tag: TagListModel) => {
        const selected = tagEditorState[tag.id];
        let selectedCount = selectedTagCount;

        // Set state of selected tags
        setTagEditorState({ ...tagEditorState, [tag.id]: !selected });

        // Set state of selected tags count
        if (!selected) {
            selectedCount++;
        } else {
            selectedCount--;
        }
        setSelectedTagCount(selectedCount);
    };

    const save = () => {
        setLoading(true);
        const selectedTags = tags.filter((tag) => tagEditorState[tag.id]);
        const selectedTagIds = selectedTags.map((tag) => tag.id);
        tagManagerService
            .addTagToEntity(entityId, selectedTagIds)
            .then(() => {
                onSave(selectedTags);
                setLoading(false);
                close();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const renderPopoverContent = () => {
        return (
            <div className="tagging-editor">
                <div className="tagging-editor__body">
                    <div className="tagging-list">
                        {unselectedTags.map((tag) => {
                            const isChecked = tagEditorState[tag.id];

                            return (
                                <div
                                    className={mergeClasses("tagging-list__item", isChecked && "tagging-list--selected")}
                                    onClick={() => onSelectTag(tag)}
                                    key={tag.id + entityId}
                                >
                                    <div className="tagging-list__name">{tag.name}</div>
                                    <div className="tagging-list__checkbox">
                                        <MatIcon type="check_circle" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {!unselectedTags.length && (
                        <Empty
                            className="tagging-list__empty"
                            description={fmtMsg({
                                id: TagManagerLocale.TaggingAddPopoverNoData,
                            })}
                        />
                    )}
                </div>
                <div className="tagging-editor__footer">
                    <Button type="default" onClick={close}>
                        {fmtMsg({
                            id: TagManagerLocale.TaggingAddPopoverCancel,
                        })}
                    </Button>
                    <Button type="primary" className="tagging-editor__save" loading={loading} onClick={save} disabled={selectedTagCount === 0}>
                        <span>
                            {fmtMsg({
                                id: TagManagerLocale.TaggingAddPopoverSave,
                            })}
                        </span>
                        <Badge count={selectedTagCount} className="tagging-editor__badge" />
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Popover
            title={fmtMsg({
                id: TagManagerLocale.TaggingAddPopoverTitle,
            })}
            content={renderPopoverContent()}
            overlayClassName="tagging-editor__popover"
            trigger="click"
            placement={popoverPlacement}
            visible={visible}
        >
            <span onClick={toggle}>{children}</span>
            {visible && <div className="tagging-editor__mask" onClick={close}></div>}
        </Popover>
    );
};
