export interface LegendLines {
    solidLine: boolean,
    dottedSolidLine: boolean,
    dashedLine: boolean
}

export function chartOptions(solidLine, dottedSolidLine, dashedLine, media?) {
    const options = {
        grid: {
            "top": "70",
            "right": "20%",
        } as any,
        legend: {
            orient: 'vertical',
            right: 0,
            y: 'center',
        } as any
    }
    switch (media) {
        case "(max-width: 576px)":
            options.grid = { "top": "70" };
            options.legend = { orient: 'vertical' };
            break;
        case "(max-width: 1200px)":
            options.grid = { "top": "70" };
            options.legend = { orient: 'vertical' };
            break;
        case "(max-width: 1600px)":
            options.grid = { "top": "70", "right": "25%", };
            break;
        default:
            break;
    }

    return {
        grid: {
            ...options.grid,
            "width": "auto",
            "height": "auto"
        },
        legend: {
            ...options.legend,
            textStyle: {
                color: '#000',
                fontSize: 14,
                fontWeight: 500,
            },
            itemWidth: 80,
            itemHeight: 6,
            data: [{
                name: solidLine,
                icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE8AAAAECAIAAABAyfV+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAAYSURBVDhPY4jR6x85aNS3wxeNJN/q9QMA25Ba7Labc8QAAAAASUVORK5CYII=',
            }, {
                name: dottedSolidLine,
                icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAAFCAIAAABdMPy+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAArSURBVDhPY4zR62cAgzmnMoFkitl0ZC4QDDMRJgg1csCoh4c7GGEeZmAAAKSqKCMNLd4wAAAAAElFTkSuQmCC',
            }, {
                name: dashedLine,
                icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE8AAAAGCAIAAAANAVR1AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAABiSURBVDhPY4zR62dABXNOZUIY3Zkbb559BmFDQOkMf3UjKSBj0+zTm2afgQhCgF+qiV+qKZBx89yz7oyNEEEIUDeWKp3uD2GnmE2HMOCAntYxQemRAUZ9O3zBqG+HK2BgAABPJislc3pfqgAAAABJRU5ErkJggg==',
            }]
        }
    }
}
export function watchMediaChange(echartStruct: any) {
    if (window.matchMedia) {
        const medias = ["(max-width: 576px)", "(max-width: 1200px)", "(max-width: 1600px)", ''];
        const setOption = (mq, preOption, chart, names) => {
            let legendOpts
            if (mq.matches) {
                legendOpts = chartOptions(names[0], names[1], names[2], mq.media);
            } else {
                legendOpts = chartOptions(names[0], names[1], names[2], medias[medias.indexOf(mq.media) + 1]);
            }
            const option = {
                ...preOption,
                ...legendOpts,
            }
            chart.setOption(option, true);
            return option;
        }
        const widthChange = (mq) => {
            echartStruct.formatData.countChart = setOption(mq, echartStruct.formatData.countChart, echartStruct.countChart, ["Total License", "GrapeSEED License", "LittleSEED License"])
            echartStruct.formatData.adoptionChart = setOption(mq, echartStruct.formatData.adoptionChart, echartStruct.adoptionChart, ["Digital License", "Textbook License", "Digital Users"])
        }
        const setMedia = (media) => {
            const mqxsmax = window.matchMedia(media);
            mqxsmax.addListener(widthChange);
            mqxsmax.matches && widthChange(mqxsmax);
        }
        setMedia(medias[2]);
        setMedia(medias[1]);
        setMedia(medias[0]);
    }
}