import { RcFileExtended } from './../types';
import { StorageURL, AnonymousCredential, ServiceURL, ContainerURL, BlobURL, BlockBlobURL, Aborter, uploadBrowserDataToBlockBlob } from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/ms-rest-js';
import { isSASExpiry } from './func';
import { BlobHTTPHeaders } from '@azure/storage-blob/typings/lib/generated/lib/models';

export enum UploadStatusType {
    None = "none",
    Begin = "begin",
    Progress = "progress",
    Completed = "completed",
    Error = "error"
}
export type UploadStatus = {
    blobFileName: string,
    fileName: string,
    url: string,
    progress: number,
    status: UploadStatusType,
}
export type UploadEventCallback = (status: UploadStatus) => void;
export type UploadEventErrorCallback = (e: any, status: UploadStatus) => void;
export type UploadEventRemoveCallback = (blobFileName: string) => void;
export type UploadEvents = {
    onBegin?: UploadEventCallback[],
    onProgress?: UploadEventCallback[],
    onSuccess?: UploadEventCallback[],
    onError?: UploadEventErrorCallback[],
    onRemove?: UploadEventRemoveCallback[]
};
export type AttachmentManagerOptions = {
    containerName: string
    events?: UploadEvents
    getMediaSas: (params) => Promise<string>
}
export interface IAttachmentStorageManager {
    onBegin(callback: UploadEventCallback): IAttachmentStorageManager
    onProgress(callback: UploadEventCallback): IAttachmentStorageManager
    onSuccess(callback: UploadEventCallback): IAttachmentStorageManager
    onError(callback: UploadEventErrorCallback): IAttachmentStorageManager
    onRemove(callback: UploadEventRemoveCallback): IAttachmentStorageManager
    delete(url: string, folder?: string): Promise<any>
    upload(file: any, folder?: any, additionalBloblHeaders?: BlobHTTPHeaders): Promise<any>
    abort(name?, folder?): void
}
export class AttachmentStorageManager {
    public static readonly fileSizeLimit = 100; // 100 MB
    public static readonly acceptedTypes = ".txt,.pdf,.doc,.docm,.docx,.dot,.dotx";
    public static readonly beforeUpload = (validType: () => void, validSize: () => void) => (file: RcFileExtended) => {
        const splitName = file.name.split(".");
        let isValidType = true;

        if (splitName && splitName.length) {
            isValidType = AttachmentStorageManager.acceptedTypes.indexOf(splitName[splitName.length - 1].toLowerCase()) >= 0;
        }

        const isValidSize = file.size / 1024 / 1024 <= AttachmentStorageManager.fileSizeLimit;
        file.isValid = isValidType && isValidSize;

        if (!isValidType) {
            validType && validType();
        } else if (!isValidSize) {
            validSize && validSize();
        }

        return isValidType && isValidSize;
    }
    private events: UploadEvents
    private sasUrl: string
    private options = {} as AttachmentManagerOptions
    private context = new Map();
    constructor(options: AttachmentManagerOptions) {
        if (!options.getMediaSas) {
            throw new Error("getMediaSas is null");
        }
        if (!options.containerName) {
            throw new Error("containerName is null");
        }
        this.options = options || {} as AttachmentManagerOptions
        this.events = {
            onBegin: [],
            onProgress: [],
            onSuccess: [],
            onError: [],
            onRemove: [],
            ...options.events
        } as UploadEvents;
    }
    private blobToFile(theBlob: Blob, fileName: string): File {
        const b: any = theBlob;
        b.name = fileName;
        return b as File;
    };
    private generateBlockBlobUrl(sasUrl, fileName, folder?) {
        const blobURL = this.generateBlobUrl(sasUrl, fileName, folder);
        return BlockBlobURL.fromBlobURL(blobURL);
    };
    private generateBlobUrl(sasUrl, fileName, folder?) {
        const pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
            retryOptions: { maxTries: 4 }, // Retry options
            telemetry: { value: "HighLevelSample V1.0.0" } // Customized telemetry string
        });
        const serviceURL = new ServiceURL(sasUrl, pipeline);
        const containerURL = ContainerURL.fromServiceURL(serviceURL, this.options.containerName);
        return BlobURL.fromContainerURL(containerURL, `${folder ? `${folder}/` : ''}${fileName}`);
    };
    setMediaSas() {
        return this.getMediaSas({});
    }
    getMediaSas(params) {
        if (this.sasUrl && !isSASExpiry(this.sasUrl)) {
            return Promise.resolve(this.sasUrl);
        }
        return this.options.getMediaSas(params).then(sasUrl => {
            this.sasUrl = sasUrl;
            return Promise.resolve(sasUrl);
        })
            .catch(e => {
                console.error(e)
                return Promise.reject(e)
            })
    }
    private invokeEvents(...rest) {
        const args = [];
        for (let index = 1; index < arguments.length; index++) {
            args.push(arguments[index]);
        }
        const eventCbs = arguments[0] as any[] || [];
        eventCbs.forEach(cb => {
            cb.apply(null, args);
        })
    }
    onBegin(callback: UploadEventCallback) {
        callback && this.events.onBegin.push(callback)
        return this;
    }
    onProgress(callback: UploadEventCallback) {
        callback && this.events.onProgress.push(callback)
        return this;
    }
    onSuccess(callback: UploadEventCallback) {
        callback && this.events.onSuccess.push(callback)
        return this;
    }
    onError(callback: UploadEventErrorCallback) {
        callback && this.events.onError.push(callback)
        return this;
    }
    onRemove(callback: UploadEventRemoveCallback) {
        callback && this.events.onRemove.push(callback)
        return this;
    }
    delete(url, folder?) {
        const file = url.split('?').shift().split('/').pop();
        const blobFileName = decodeURIComponent(folder ? file.replace(`${folder}%2F`, '') : file);
        return this.getMediaSas({ sasFromGloablAccount: true })
            .then(sasUrl => {
                return this.generateBlobUrl(sasUrl, blobFileName, folder)
                    .delete(Aborter.none);
            })
    }
    abort(name, folder?) {
        const contextValue = this.context.get(folder || name);
        if (contextValue.status !== UploadStatusType.None) {
            contextValue.status = UploadStatusType.None;
            contextValue.abortCallback && contextValue.abortCallback();
        }
    }
    upload(file, folder?, additionalBloblHeaders?: BlobHTTPHeaders) {
        return this.getMediaSas({ sasFromGlobalAccount: true })
            .then(sasUrl => {
                return this.uploadWithSas(file, sasUrl, folder, additionalBloblHeaders);
            })
    }
    uploadWithSas(file, sasUrl, folder, additionalBloblHeaders?: BlobHTTPHeaders) {
        let browserFile = null;
        const blobFileName = file.name;
        if (!navigator.msSaveBlob) {
            browserFile = new File([file], blobFileName, { type: file.type });
        } else {
            // workaround for edge browser as it doesn't support File Api constructor.
            browserFile = new Blob([file], { type: file.type });
            browserFile = this.blobToFile(browserFile, blobFileName);
        }

        const uploadAborter = Aborter.none;

        const blockBlobURL = this.generateBlockBlobUrl(sasUrl, browserFile.name, folder);
        const fileSize = browserFile.size;
        const cancelUpload = (blobFileName: string) => {
            uploadAborter.abort();
            this.invokeEvents(this.events.onRemove, blobFileName);
        };
        this.context.set(folder || blobFileName,
            { status: UploadStatusType.None, abortCallback: cancelUpload.bind(null, blobFileName) });
        const begin = {
            blobFileName: blobFileName,
            fileName: file.name,
            progress: 1,
            status: UploadStatusType.Begin,
        } as UploadStatus;
        this.context.get(folder || blobFileName).status = begin.status;
        this.invokeEvents(this.events.onBegin, begin);

        return uploadBrowserDataToBlockBlob(uploadAborter, browserFile, blockBlobURL, {
            blockSize: 4 * 1024 * 1024,
            parallelism: 20, // 20 concurrency,
            blobHTTPHeaders: additionalBloblHeaders ? additionalBloblHeaders : {},
            progress: (ev: TransferProgressEvent) => {
                if(uploadAborter.aborted) return;
                const progress = {
                    blobFileName: blobFileName,
                    fileName: file.name,
                    url: getBlobUrl(blockBlobURL.url),
                    progress: Math.floor((ev.loadedBytes / fileSize) * 100),
                    status: UploadStatusType.Progress,
                } as UploadStatus;
                this.context.get(folder || blobFileName).status = progress.status;
                this.invokeEvents(this.events.onProgress, progress);
            }
        })
            .then(_ => {
                if(uploadAborter.aborted) return;
                const success = {
                    blobFileName: blobFileName,
                    fileName: file.name,
                    url: getBlobUrl(blockBlobURL.url),
                    status: UploadStatusType.Completed,
                } as UploadStatus;
                this.context.get(folder || blobFileName).status = success.status;
                this.invokeEvents(this.events.onSuccess, success);
                return success;
            })
            .catch(e => {
                if(uploadAborter.aborted) return;
                const failed = {
                    blobFileName: blobFileName,
                    fileName: file.name,
                    url: getBlobUrl(blockBlobURL.url),
                    status: UploadStatusType.Error,
                } as UploadStatus;
                this.context.get(folder || blobFileName).status = failed.status;
                this.invokeEvents(this.events.onError, e, failed);
                return { e, failed };
            });


    }
}
function getBlobUrl(url: string) {
    return url.split('?').shift();
}