import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface RedeemModel {
    email?: string;
    password?: string;
}

export enum RedeemProps {
    email = "email",
    password = "password"
}

export interface AccountModel {
    id?: string;
    name?: string;
}

export interface UpdateEmailRequestModel {
    userId: string;
    email: string;
}

export class GetAccountRequest extends GLPagingRequest {
    ids?: string[];
}
export interface AccountRequest extends GLRequest, AccountModel {}
export interface AccountResponse extends GLResponse, AccountModel {}

export interface GetUserByIdResponseModel {
    deviceActivationLimit: null | number;
    disabled: boolean;
    email: string;
    id: string;
    includeTestDataForReport: boolean;
    name: string;
    phone: null | string;
    roles: string[];
    creationDate: string | Date;
    lastLogin: string | Date;
    allowTesting: boolean;
    allowNexus: boolean;
    gsConnectAccess: boolean;
}
