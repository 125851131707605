import React, { useEffect, useRef, useState } from "react";
import { connect, GLRouteComponentProps, withRouter } from "gl-commonui";
import { Card, Icon, Modal, Typography } from "antd-min";
import { Action, Actions, Loading, TableWithPerfectScroll } from "@app/components";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SurveyLocale, } from "@app/locales/localeid";
import { CreatedPollModel, CreatedPollsResponseModel, PollPageParams } from "@app/service/survey";
import { StateType } from "@app/states";
import { getCreatedPolls, getUserPollParticipation, UserPollParticipationState } from "@app/states/survey/survey";
import { CONSTS, DateHelper, fmtMsg, isExitSurvey, isPastDate } from "@app/util";
import { WijmoGrid } from "@app/components/grid";
import { PerfectScrollbar } from "@app/components";
import { FlexGrid } from "wijmo/wijmo.grid";
import "./created-polls.less";

const { Grid, Column } = WijmoGrid;

const { Text } = Typography;

interface CreatedPollProps extends GLRouteComponentProps {
    createdPolls: CreatedPollsResponseModel;
    shouldReloadCreatedSurveys: boolean;
    loading: boolean;
    participationLoading: boolean;
    userParticipation: UserPollParticipationState;
    getCreatedPolls: (params?: PollPageParams) => void;
    getPollParticipation: (surveyId: string, surveyInstanceId: string) => void;
    reviewPollsLoading;
}

export const CreatedPolls = withRouter(
    connect(
        ({ survey: { createdPolls, shouldReloadCreatedSurveys, createdPollsLoading,reviewPollsLoading, participationLoading, userParticipation } }: StateType) => ({
            createdPolls,
            shouldReloadCreatedSurveys,
            loading: createdPollsLoading,
            reviewPollsLoading,
            participationLoading,
            userParticipation
        }),
        {
            getCreatedPolls,
            getPollParticipation: getUserPollParticipation
        }
    )((props: CreatedPollProps) => {
        const [showParticipation, setShowParticipation] = useState(false);
        const [participationPoll, setParticipationPoll] = useState({} as CreatedPollModel);
        const { loading, reviewPollsLoading, participationLoading, createdPolls, userParticipation, getCreatedPolls, getPollParticipation, history, shouldReloadCreatedSurveys } = props;
        const ref = useRef(null);

        useEffect(() => {
            setTimeout(() => {
                if(ref.current && ref.current.grid) {
                    ref.current.grid.refresh(true);
                }
            }, 50)
        }, [loading, reviewPollsLoading])

        useEffect(() => {
            if ((!createdPolls.data && !loading && !shouldReloadCreatedSurveys)
                || (shouldReloadCreatedSurveys && !loading)) {
                getCreatedPolls({ offset: 0, limit: CONSTS.MySurveyItemDisplayCount });
            }
        }, [createdPolls, loading, shouldReloadCreatedSurveys]);

        const goToCreatedPollsHistory = () => {
            history.push(SurveyPathConfig.CreatedHistory);
        };

        const goToUserResponse = (userId: string, name: string, responseId: string) => {
            history.push(`${SurveyPathConfig.Home}/${participationPoll.id}/${participationPoll.surveyInstanceId}/${userId}/review/${responseId}`);
        };

        const onShowMoreClick = () => goToCreatedPollsHistory();
        const onCreatedHistoryClick = () => goToCreatedPollsHistory();

        const getPollsDataSource = (polls: CreatedPollModel[]) => {
            const data = polls.filter(poll => !poll.surveyInstanceId || !isPastDate(poll.dueDate)).slice(0, CONSTS.MySurveyItemDisplayCount);
            return data.map((record) => ({
                ...record,
                participation: `${record.completedCount}/${record.userCount}`
            }))
        };

        const getLandingPageDataCount = (polls: CreatedPollModel[]) => {
            return polls.filter(poll => !poll.surveyInstanceId || !isPastDate(poll.dueDate)).length;
        }

        const onShowParticipationClick = (poll: CreatedPollModel) => {
            setShowParticipation(true);

            // get pollParticipation all times for Exit Survey, as every exit survey has different survey instances
            if (!hasDataOnClient(poll.id) || isExitSurvey(poll.id)) {
                getPollParticipation(poll.id, poll.surveyInstanceId);
            }
            setParticipationPoll(poll);
        };

        const hasDataOnClient = (pollId: string) => {
            const existed = userParticipation[pollId];
            if (!existed) {
                return false;
            }
            const poll = createdPolls.data.find(s => s.id == pollId);
            if (!poll) {
                return false;
            }
            return existed.totalCount == poll.userCount;
        }

        // const onPollNameClick = (e: any, id: string) => {
        //     e.preventDefault();
        //     props.history.push(`${SurveyPathConfig.Home}/${id}`);
        // };

        const renderParticipationModal = () => {
            let modalHeight = window.innerHeight - 275;
            modalHeight = modalHeight <= 250 ? window.innerHeight : modalHeight;
            const columns=[
                {
                    title: fmtMsg({id: GSAdminLocale.NotificationTextName}),
                    dataIndex: "name",
                    key:"name",
                    width: 40,
                    render: (text, { id, name, hasCompleted, surveyResponseId }) =>
                        hasCompleted ? (
                            <Text underline>
                                <a className="cp-action-participation" onClick={() => goToUserResponse(id, name, surveyResponseId)}>
                                    {text}
                                </a>
                            </Text>
                        ) : (
                                text
                            )
                },
                {
                    title: fmtMsg({id: GSAdminLocale.NotificationTextEmail}),
                    dataIndex: "email",
                    width: 40,
                    key:"email",
                },
                {
                    title: fmtMsg({id: SurveyLocale.PollCompletedText}),
                    dataIndex: "hasCompleted",
                    key: "hasCompleted",
                    width: 20,
                    render: (text: boolean) =>
                        text ? (
                            <Icon className="cp-icon" type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                        ) : (
                                <Icon className="cp-icon" type="clock-circle" />
                            )
                }
            ]
            return (
                <Modal
                    title={`${participationPoll.name} - ${participationPoll.completedCount}/${participationPoll.userCount}`}
                    visible={showParticipation}
                    className="notified-users-modal"
                    maskClosable={false}
                    destroyOnClose
                    onOk={() => setShowParticipation(false)}
                    onCancel={() => setShowParticipation(false)}
                    footer={null}
                    width={800}
                    bodyStyle={{ padding: 0 }}
                >
                    <div style={{maxHeight: modalHeight, padding:'10px'}}>
                        <PerfectScrollbar
                            style={{ maxHeight: modalHeight - 20, maxWidth: '100%' }}
                            options={{ wheelPropagation: true, minScrollbarLength: 50 }}
                        >
                            <Grid
                                itemsSource={userParticipation[participationPoll.id] ? userParticipation[participationPoll.id].data : []}
                                loading={participationLoading}
                                stickyHeaders={false}
                                pagination={false}
                            >
                                {
                                    columns.map((column) => {
                                        return <Column
                                                key={column.key}
                                                header={column.title}
                                                binding={column.dataIndex}
                                                render={column.render}
                                                width={column.width}
                                            />
                                    })
                                }
                            </Grid>
                        </PerfectScrollbar>
                    </div>
                </Modal>
            );
        };

        const renderCardHeaderActions = (): React.ReactNode => {
            return (
                <Actions
                    noHeight
                    actions={[
                        <Action
                            textProps={{ underline: true }}
                            key="0"
                            onClick={onCreatedHistoryClick}
                            materialIcon="history"
                            textLocaleId={SurveyLocale.PollCreatedHistory}
                        />
                    ]}
                />
            );
        };

        const renderShowMore = (polls: CreatedPollModel[]): React.ReactNode => {
            if (!polls.length) {
                return null;
            }

            const pollsForLandingPage = polls.filter(poll => !isPastDate(poll.dueDate)).slice(0, CONSTS.MySurveyItemDisplayCount);

            if (!pollsForLandingPage.length || pollsForLandingPage.length < CONSTS.MySurveyItemDisplayCount) {
                return null;
            }

            return (
                <span onClick={onShowMoreClick}>
                    <Text underline type="secondary">{fmtMsg({ id: SurveyLocale.MySurveyShowMore })}</Text>
                </span>
            );
        };

        const columns = [
            {
                titleText: fmtMsg({ id: SurveyLocale.PollNameColumn }),
                dataIndex: "name",
                key: "name",
                render: (text: string, { id }) => (
                    <Text underline>
                        <a href={`${SurveyPathConfig.Home}/${id}`}>{text}</a>
                    </Text>
                ),
                width: "4*"
            },
            {
                titleText: fmtMsg({ id: SurveyLocale.PollDueDate }),
                dataIndex: "dueDate",
                key: "dueDate",
                render: (dueDate: string) => DateHelper.formatDateAsIs(dueDate),
                width: "2*",
                align: 'right'
            },
            {
                titleText: fmtMsg({ id: SurveyLocale.PollPublishedDate }),
                dataIndex: "publishedDate",
                key: "publishedDate",
                render: (publishedDate: Date) => (publishedDate ? DateHelper.formatDate2Local(publishedDate) : null),
                width: "2*",
                align: 'right'
            },
            {
                titleText: fmtMsg({ id: SurveyLocale.PollParticipation }),
                dataIndex: "participation",
                key: "participation",
                render: (completedCount: number, poll: CreatedPollModel) => (
                    <a className="cp-action-participation" onClick={() => onShowParticipationClick(poll)}>
                        {poll.completedCount}/{poll.userCount}
                    </a>
                ),
                width: "1*",
                align: 'right'
            },
        ];

        const getPollsGrid = () => {
            return (
                <div className="cp-grid-container">
                        <Grid
                            itemsSource={getPollsDataSource(createdPolls.data || [])}
                            pagination={true}
                            loading={loading}
                            ref={ref}
                        >
                            {columns.map((column)=>{
                                return (<Column
                                            key={column.key}
                                            header={column.titleText}
                                            binding={column.dataIndex}
                                            render={column.render}
                                            align={column.align}
                                            width={column.width}
                                        />);
                            })}
                        </Grid>
                </div>
            );
        }

        const getCardContent = () => (<>
            {getPollsGrid()}
            {renderParticipationModal()}
        </>);

        const polls = (
            <Card
                className="cp-card"
                type="inner"
                size="small"
                actions={[renderShowMore(createdPolls.data || [])]}
                extra={renderCardHeaderActions()}
                title={`${fmtMsg({ id: SurveyLocale.PollsToView })} ${loading ? "" : `(${createdPolls.totalCount})`}`}
            >
                {
                    loading ? <Loading visible cover height={292} /> : getCardContent()
                }
            </Card>
        );

        return polls;
    })
);
