import { ChangeLogService } from "@app/service/admin/changelog";

interface Services {
    changeLogService: ChangeLogService
}

export enum ChangeLogVisibleTab {
    ContentTab = 1,
    SystemTab = 2
}

export enum ChangeLogVisiblePage {
    ChangeLogPage = 1,
    AddEditPage = 2
}

export interface ChangeLogState {
    selectedTab?: ChangeLogVisibleTab;
    pageViewMode: ChangeLogVisiblePage;
    selectedReleaseId?: number;
}

export default {
    namespace: "changeLog",
    state: {
        selectedTab : null,
        pageViewMode : ChangeLogVisiblePage.ChangeLogPage,
        selectedReleaseId: null,
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setChangeLogState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
    effects: {
    },
    services: {
        changeLogService: ChangeLogService
    }
}

export function reload(state) {
    return { type: 'changeLog/reload', payload: state }
}

export function setChangeLogState(changeLogState: ChangeLogState) {
    return { type: "changeLog/setChangeLogState", payload: changeLogState };
}
