import { injectable } from '../../../util/di'
import {GLServiceBase} from "gl-commonui";
import {
    GetRegionGenericLinkRequest,
    RegionGenericLinkModel,
    RegionGenericLinkRequest
} from "./model";
import {IRegionGenericLinkService} from "./interface";

@injectable()
export class RegionGenericLinkService extends GLServiceBase<RegionGenericLinkRequest, GetRegionGenericLinkRequest> implements IRegionGenericLinkService {
    serviceRoute = { prefix: 'admin/v1/generic-link', itemSuffix: '{id}' }

    getRegionGenericLink(regionId) {
        return this.get("region/{id}", null, {id: regionId});
    }

    getRegionGenericLinkSchool(regionId) {
        return this.get("region/{id}/school", null, {id: regionId});
    }
    getRegionGenericLinkParent(regionId) {
        return this.get("region/{id}/parent", null, {id: regionId});
    }
    getListGenericLinkSchool() {
        return this.get("site/school");
    }
}
