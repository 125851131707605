import { InvitationChannel } from '@app/util';
import { GLResponse, GLRequest, GLPagingRequest, InvitationType } from 'gl-commonui';

export interface InvitationTemplateModel {
    id?: string
    name?: string
    title?: string
    invitationType?: InvitationType
    invitationTypeText?: string
    language?: string
    text?: string
    disabled?: boolean
    regionId?: string
    regionName?: string
    regionEnglishName?: string
    schoolId?: string
    schoolName?: string
    backgroundImage?: string
    backgroundImageLayout?: number
    invitationChannel?: InvitationChannel
    invitationChannelText?: string
}
export interface InvitationTemplateRequest extends GLRequest, InvitationTemplateModel {
}
export interface InvitationTemplateResponse extends GLResponse, InvitationTemplateModel {
}
export interface InvitationTemplatePagingRequest extends GLPagingRequest { }

export enum InvitationTemplateModelPropsName {
    id = "id",
    name = "name",
    title = "title",
    invitationType = "invitationType",
    invitationTypeText = "invitationTypeText",
    language = "language",
    text = "text",
    regionId = 'regionId',
    regionName = 'regionName',
    regionEnglishName = 'regionEnglishName',
    schoolId = 'schoolId',
    schoolName = 'schoolName',
    backgroundImage = 'backgroundImage',
    backgroundImageLayout = 'backgroundImageLayout',
    invitationChannel = "invitationChannel",
    invitationChannelText = "invitationChannelText"
}

export interface InvitationTemplate4PreviewModel extends InvitationTemplateModel {
    backgroundImageRelativeWidth?: number
}

export interface PreviewInvitationTemplateModel extends InvitationTemplateModel {}
