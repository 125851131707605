import {GLGlobal, RoleName} from "gl-commonui";

export class RoleHelper {

    private static RolesFromHighToLow(): string[] {
        return [
            RoleName.systemAdmin,
            RoleName.globalHead,
            RoleName.trainingAdmin,
            RoleName.regionAdmin,
            RoleName.trainingManager,
            RoleName.trainer,
            RoleName.accountManager,
            RoleName.schoolAdmin,
            RoleName.campusAdmin,
            RoleName.teacher,
            RoleName.parent
        ];
    }

    private static getMaxRole(roles: any): string {
        const rolesFromHighToLow = this.RolesFromHighToLow();
        if (!Array.isArray(roles)) {
            roles = [roles];
        }
        let highestRoleIndex = rolesFromHighToLow.length - 1;
        roles.forEach(result => {
            const roleIndex = rolesFromHighToLow.indexOf(result);
            if (highestRoleIndex > roleIndex && roleIndex >= 0) {
                highestRoleIndex = roleIndex;
            }
        });

        return rolesFromHighToLow[highestRoleIndex];
    }

    static getMaxRoleOfCurrentUser(): string {
        return this.getMaxRole(GLGlobal.loginInfo().profile.role);
    }

    static isCurrentUserRolesGreaterThan(roles: string[], checkEqual = false) {
        if (!roles || !roles.length) {
            return true;
        }
        const rolesFromHighToLow = this.RolesFromHighToLow();
        const maxRoleOfCurrentUser = this.getMaxRoleOfCurrentUser();
        const maxRole = this.getMaxRole(roles);
        if (maxRole === RoleName.systemAdmin) {
            return true;
        }
        if (checkEqual)
            return rolesFromHighToLow.indexOf(maxRoleOfCurrentUser) <= rolesFromHighToLow.indexOf(maxRole);

        return rolesFromHighToLow.indexOf(maxRoleOfCurrentUser) < rolesFromHighToLow.indexOf(maxRole);
    }
}
