import * as React from "react";
import { GLRouteComponentProps, withRouter, PathConfig } from "gl-commonui";
import { ResponseTab } from "../create-poll/components/response-tab";
import { Container } from "@app/components/survey/container/container";
import { Loading } from "@app/components";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { ISurveyService } from "@app/service/survey/surveys/index";

interface ReviewerResponseTabWrapperProps extends GLRouteComponentProps { }

export const ReviewerResponseTabWrapper = withRouter((props: ReviewerResponseTabWrapperProps) => {

    const [checkingUserAccess, setCheckingUserAccess] = React.useState(true);
    const surveyService = useService<ISurveyService>(TYPES.ISurveyService);
    React.useEffect(() => {
        const { history, match: { params: { surveyId } } } = props;
        surveyService.isCreatorShared(surveyId).then((isShared) => {
            if (isShared) {
                setCheckingUserAccess(false);
            }
            else {
                history.replace(PathConfig.AccessDenied);
            }
        })
    }, [])

    return <Container>
        {checkingUserAccess ?
            <Loading visible={true}></Loading>
            : <ResponseTab></ResponseTab>
        }
    </Container>

});