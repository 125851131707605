import { update } from '@app/states/school/campus';
import { ServerExceptionCode as ExceptionCode } from '../../../util/coach/enum';
import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode, GLGlobal } from 'gl-commonui'
import { injectable } from '@app/util/di';
import { IVisitationService } from './interface';
import { SchoolLocale } from '@app/locales/localeid';
import { getSASCache, setSASCache } from '@app/util/storage';

@injectable()
export class VisitationService extends GLServiceBase<any, any> implements IVisitationService {
    serviceRoute = { prefix: 'admin/v1/visitations', suffix: '{visitationId}' }
    sendCoachFeedbackUrl= "{visitationId}/coachfeedback";
    uploadVideoUrl = "{visitationId}/teacherlva/videomanagement/videos";
    updatePrevisitationUrl = "{visitationId}/previsitation/teacherresponsing";
    deleteVideoUrl = "{visitationId}/teacherlva/videomanagement/{resourceformId}/videos/{resourceId}";
    coachToTeacherFeedbackUrl = "{visitationId}/teacherreview/coachfilling/teachers/{teacherId}/feedback";
    videoManagerMoveNextUrl = "{visitationId}/videomanagement/coachmovingnext";
    sendPrevisitationFormUrl = "{visitationId}/previsitation";
    reviewCoachToTeacherFeedbackUrl = "{visitationId}/teacherreview/teachers/{teacherId}/reviewed/feedback";
    updateTeachersUrl = "{visitationId}/teachers";
    teacherReviewMoveNextUrl = "{visitationId}/teacherreview/coachmovingnext";
    deleteEventCoachingSupport = "{visitationId}/delete";
    preVisitationMoveNextUrl = "{visitationId}/previsitationform/coachmovingnext";
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.NoPermissionException)] = SchoolLocale.CommonErrorNoPermission;
        result[getServiceMethodKey(this, HttpMethod.Delete, ExceptionCode.NoPermissionDeleteError, `${this.serviceRoute.prefix}/${this.deleteVideoUrl}`)] = SchoolLocale.CommonErrorNoPermission;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.VideoHasExisted, `${this.serviceRoute.prefix}/${this.uploadVideoUrl}`)] = SchoolLocale.CommonErrorVideoExisted;
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.InvalidArgumentException)] = SchoolLocale.CommonErrorInvalidArgument;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.VisitationRequestInvalid)] = SchoolLocale.CommonErrorInvalidVisitation;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.SurveyHasSubmittedError, `${this.serviceRoute.prefix}/${this.updatePrevisitationUrl}`)] = SchoolLocale.CommonErrorSurveyHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.updatePrevisitationUrl}`)] = SchoolLocale.CommonErrorSurveyNotFound;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.SurveyHasSubmittedError, `${this.serviceRoute.prefix}/${this.sendCoachFeedbackUrl}`)] = SchoolLocale.CommonErrorSurveyHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.SurveyHasSubmittedError, `${this.serviceRoute.prefix}/${this.coachToTeacherFeedbackUrl}`)] = SchoolLocale.CommonErrorSurveyHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.VideoIsUploadingError, `${this.serviceRoute.prefix}/${this.videoManagerMoveNextUrl}`)] = SchoolLocale.VisitationVideoUploadFailed;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.ItemHasSubmittedError, `${this.serviceRoute.prefix}/${this.sendPrevisitationFormUrl}`)] = SchoolLocale.CommonErrorItemHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.ItemHasSubmittedError, `${this.serviceRoute.prefix}/${this.coachToTeacherFeedbackUrl}`)] = SchoolLocale.CommonErrorItemHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.ItemHasSubmittedError, `${this.serviceRoute.prefix}/${this.sendCoachFeedbackUrl}`)] = SchoolLocale.CommonErrorItemHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.ItemHasSubmittedError, `${this.serviceRoute.prefix}/${this.reviewCoachToTeacherFeedbackUrl}`)] = SchoolLocale.CommonErrorItemHasSubmitted;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.NoClassesError, `${this.serviceRoute.prefix}`)] = SchoolLocale.VisitationRequestNoClass;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.NoTeachersError, `${this.serviceRoute.prefix}`)] = SchoolLocale.VisitationRequestNoTeacher;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.CanNotDeleteTeachersError, `${this.serviceRoute.prefix}/${this.updateTeachersUrl}`)] = SchoolLocale.VisitationCanNotDeleteTeacher;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.NotExistedTeacherError, `${this.serviceRoute.prefix}/${this.updatePrevisitationUrl}`)] = SchoolLocale.VisitationTeacherNotExist;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.NotExistedTeacherError, `${this.serviceRoute.prefix}/${this.sendCoachFeedbackUrl}`)] = SchoolLocale.VisitationTeacherNotExist;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.NotExistedTeacherError, `${this.serviceRoute.prefix}/${this.uploadVideoUrl}`)] = SchoolLocale.VisitationTeacherNotExist;
        result[getServiceMethodKey(this, HttpMethod.Delete, ExceptionCode.NotExistedTeacherError, `${this.serviceRoute.prefix}/${this.deleteVideoUrl}`)] = SchoolLocale.VisitationTeacherNotExist;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.NotExistedTeacherError, `${this.serviceRoute.prefix}/${this.reviewCoachToTeacherFeedbackUrl}`)] = SchoolLocale.VisitationTeacherNotExist;
        result[getServiceMethodKey(this, HttpMethod.Put, ExceptionCode.NotSendSupportQuestionnaireError, `${this.serviceRoute.prefix}/${this.teacherReviewMoveNextUrl}`)] = SchoolLocale.VisitationNotSendSupportQuestionnaire;
        result[getServiceMethodKey(this, HttpMethod.Delete, ExceptionCode.LVAExceedLimitError, `${this.serviceRoute.prefix}/${this.deleteEventCoachingSupport}`)] = SchoolLocale.VisitationDeleteInvitation;
        result[getServiceMethodKey(this, HttpMethod.Post, ExceptionCode.VisitationUploadVideosExceedLimitException, `${this.serviceRoute.prefix}/${this.uploadVideoUrl}`)] = SchoolLocale.VisitationUploadVideoExceedMaxCount;
        return result;
    })();
    getVisitation(params: any) {
        return this.get("{id}", params);
    }
    getVisitationStage(params: any) {
        return this.get("{visitationId}/stage", params);
    }
    getCoachRelated(params: any) {
        return this.get("{id}/coachrelated", params);
    }
    updateVisitation(params: any) {
        return this.get("{id}", params);
    }
    updateStage(params: any) {
        return this.update('{visitationId}/stage', params);
    }
    createSchoolVisitNote(params: any) {
        return this.create("{visitationId}/schoolvisiting/note", params);
    }
    getSchoolVisitNote(params: any) {
        return this.get("{visitationId}/schoolvisiting/note", params);
    }
    updateSchoolVisitNote(params: any) {
        return this.update("{visitationId}/schoolvisiting/note", params);
    }
    createSchoolReviewNotes(params: any) {
        return this.create("{visitationId}/schoolreviewing/notes", params);
    }
    getSchoolReviewNotes(params: any) {
        return this.get("{visitationId}/schoolreviewing/notes", params);
    }
    createVisitationRequest(params: any) {
        return this.create(params);
    }
    getRequestInfo(params: any) {
        return this.get("{visitationId}/requestinfo", params);
    }
    getCreationLimits(params: any) {
        return this.get("creationlimits/{schoolId}", params);
    }
    getSchoolStatuses() {
        return this.get("schoolstatuses");
    }
    getSchoolStatusesById(schoolId: string) {
        return this.get("schoolstatus", { schoolId } )
    }
    getChannels() {
        return this.get("channels");
    }
    getSchoolCampus(params: any) {
        return this.get("schools/{schoolId}/campuses", params);
    }
    suggestScheduleDate(params: any) {
        return this.update("{visitationId}/datescheduling/suggestion", params);
    }
    acceptScheduleDate(params: any) {
        return this.update("{visitationId}/datescheduling/acception", params);
    }
    sendScheduleDateReminder(params: any) {
        return this.create("{visitationId}/datescheduling/reminder", params);
    }
    deleteVisitation(params: any) {
        return this.update("{id}/cancelling", params);
    }
    getTeacherRelated(params: any) {
        return this.get("teacherrelated", params);
    }
    getTeacherRelatedById(params: any) {
        return this.get("{visitationId}/teacherrelated", params);
    }
    getCoachPrevisitationForm(params: any) {
        return this.get("{visitationId}/previsitation/form", params);
    }
    getPrevisitationLastFeedback(params: any) {
        return this.get("{visitationId}/previsitation/{teacherId}/lastfeedback", params);
    }
    getTeacherPrevisitationForm(params: any) {
        return this.get("{visitationId}/previsitation/teacherform", params);
    }
    postCoachPrevisitation(params: any) {
        return this.create(this.sendPrevisitationFormUrl, params);
    }
    sendReminder(params: any) {
        return this.create("{visitationId}/previsitation/reminder/{teacherId}", params);
    }
    updateTeacherPrevisitation(params: any) {
        return this.update(this.updatePrevisitationUrl, params);
    }
    viewSurveyReponse(params: any) {
        return this.get("{visitationId}/surveyinstances/{surveyInstanceId}/response", params);
    }
    updatePrevisitationTeacherStatus(params: any) {
        return this.create("{visitationId}/previsitation/status", params);
    }
    ignorePrevisitationTeacherStatus(params: any) {
        return this.create("{visitationId}/previsitation/ignoring", params);
    }
    getReviewTeacherForm(params: any) {
        return this.get("{visitationId}/teacherreview/feedbacks", params);
    }
    buildCoachToTeacherFeedback(params: any) {
        return this.request.post(this.url("{visitationId}/teacherreview/coachbuilding/teachers/{teacherId}/feedback"), params);
    }
    fillCoachToTeacherFeedback(params: any) {
        return this.create(this.coachToTeacherFeedbackUrl, params);
    }
    getCoachToTeacherFeedback(params: any) {
        return this.get("{visitationId}/teacherreview/teachers/{teacherId}/feedback", params);
    }
    reviewedCoachToTeacherFeedback(params: any) {
        return this.update(this.reviewCoachToTeacherFeedbackUrl, params);
    }
    getTeacherToCoachFeedback(params: any) {
        return this.get("{visitationId}/coachfeedback/teachers/{teacherId}/feedback", params);
    }
    sendCoachFeedback(params: any) {
        return this.create(this.sendCoachFeedbackUrl, params);
    }
    getSchoolReviewHistory(schoolId) {
        return this.get("schoolreviewhistories/{schoolId}", null, { schoolId });
    }
    getMediaSas(params?: any) {
        let key = `${(GLGlobal.processEnv().containers as any).visitation}${params && params.readOnly ? '-readonly' : ''}`;
        
        if (params && params.readOnly && params.sasFromGlobalAccount) {
            key = `${(GLGlobal.processEnv().containers as any).visitation}${'-global'}`;
        }        
        
        const sas = getSASCache(key);
        
        if (!!sas) {
            return Promise.resolve(sas);
        }
        
        return this.get('medias/sas', params)
            .then(sas => {
                setSASCache(key, sas);
                return Promise.resolve(sas);
            });
    }
    getCoachVisitation(coachId, stage, schoolId) {
        return this.get("coaches/{coachId}/coachrelated", { stage, schoolId }, { coachId });
    }
    getVideoManagementLVAResources(params: any) {
        return this.get("{visitationId}/videomanagement/lva/resources", params);
    }
    getVideoManagerInfos(params: any) {
        return this.get("{visitationId}/teacherlva/videomanagement/infos", params);
    }
    uploadVideos(params: any) {
        return this.create(this.uploadVideoUrl, params);
    }
    deleteVideo(params: any) {
        return this.delete(this.deleteVideoUrl, params);
    }
    sendUploadVideoReminder(params: any) {
        return this.create("{visitationId}/teacherlva/videomanagement/reminder/{teacherId}", params);
    }
    sendCopyright(params: any) {
        return this.create("{visitationId}/teacherlva/{teacherId}/videomanagement/copyright", params);
    }
    completeVideoManager(params: any) {
        return this.create("{visitationId}/teacherlva/videomanagement/resources", params);
    }
    videoManagerMoveNext(params: any) {
        return this.update(this.videoManagerMoveNextUrl, params);
    }
    teacherReviewMoveNext(params: any) {
        return this.update(this.teacherReviewMoveNextUrl, params);
    }
    getTeacherReviewHistory(teacherId, schoolId?) {
        return this.get("teachers/{teacherId}/teacherrelated/reviewhistory", {schoolId}, { teacherId });
    }
    getSchoolClasses(params: any) {
        return this.get("{visitationId}/teachers/{teacherId}/schoolclasses", params);
    }
    reGeneratingScheduleDate(params: any) {
        return this.update("{visitationId}/datescheduling/regenerating", params);
    }
    updateTeachers(params: any) {
        return this.update(this.updateTeachersUrl, params);
    }
    deleteCoachingSupportEvent(params: any) {
        return this.delete(this.deleteEventCoachingSupport, params);
    }
    checkPermissionDeleteCoachingSupportEvent(params: any) {
        return this.get('{visitationId}/HasDeletePermission', params);
    }
    checkTeacherAccessPermission(params: {
        visitationId: string;
    }): Promise<boolean> {
        return this.get("{visitationId}/teacher/accessable", params);
    }
    checkCoachAccessPermission(params: {
        visitationId: string;
    }): Promise<boolean> {
        return this.get("{visitationId}/coach/accessable", params);
    }
    preVisitationFromMoveNext(params: any){
        return this.update(this.preVisitationMoveNextUrl, params);
    }
}
