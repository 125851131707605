import * as React from 'react'
import { Component } from 'react'
import { GLGlobal, RoleName } from "gl-commonui";
import { Row, Skeleton } from 'antd-min';
import * as ReactDOM from 'react-dom';
import moment from 'moment';
import { chartOptions, watchMediaChange, LegendLines } from './echarts-util';
import { lazyInject, TYPES } from '@app/util';
import { ISurveyReportService } from '@app/service/survey/report';
import { TodoSurveyResponseModel, StatisticsFilterType } from '@app/service/survey';
import "./style.less";
import { SurveyLocale } from '@app/locales/localeid';
let echarts = null;

const colors = ['#8E3FC8', '#C7A0E4', '#00C453', '#81E2AA', '#A67C52', '#C1A48A', '#29ABE2', '#928DB7', '#FFC800', '#FFE585',
    '#FF5919', '#FFAE8E', '#FF0000', '#FF7BAC', '#B66BAC', '#5F8A7F', "#44546A", "#5BFFD5", "#ED7D31", "#A5A5A5", "#4472C4",
    "#70AD47", "#000000", "#FFFF00", "#757171", "#8497B0", "#9BFFE6", "#F4B084", "#C9C9C9", "#8EA9DB", "#A9D08E", "#1F4E78",
    "#833C0C", "#525252", "#806000", "#203764", "#375623", "#C00000", "#FFCDCD", "#FF6D6D", "#8083F4", "#3F43EF", "#0D11A3",
    "#B7C31B", "#808812", "#6C044C", "#F852C5", "#A10771", "#076A7B", "#51DEF5", "#0B9DB5", "#065F6E", "#95CFC8", "#726FF5",
    "#ADABB9", "#F8B56C", "#05473F", "#C6D4B4", "#D78591",];

export interface LicenseHistoryReportProps {
    surveyResponse: TodoSurveyResponseModel;
}

interface LicenseHistoryReportStates {
    regioncontainers?: JSX.Element[] | null;
    dataSource: any[];
    error: boolean;
    loading: boolean;
}

export class LicenseHistoryReport extends Component<LicenseHistoryReportProps, LicenseHistoryReportStates> {
    @lazyInject(TYPES.ISurveyReportService)
    surveyReportService: ISurveyReportService

    seriesdataMap = new Map()
    countChart
    option
    xAxisdata = []
    digitalPerMonth = []
    textbookPerMonth = []
    digitalUsersPerMonth = []
    totalPerMonth = []
    grapeseedPerMonth = []
    littleseedPerMonth = []
    hiddenSeriesMap = new Map()
    hiddenSeriesAdoptionMap = new Map()
    regioncolors = []
    regioncontainers?: JSX.Element[] | null
    echartLoaded = null
    formatData = { countChart: { series: [] } }
    legendLines4GrapeCity: LegendLines = { solidLine: true, dottedSolidLine: true, dashedLine: true };
    legendLines4GrapeCitybyRegion: LegendLines = { solidLine: true, dottedSolidLine: true, dashedLine: true };
    isSAorGH = GLGlobal.loginInfo() && GLGlobal.loginInfo().profile &&
        (GLGlobal.loginInfo().profile.roles.indexOf(RoleName.systemAdmin) > -1 ||
            GLGlobal.loginInfo().profile.roles.indexOf(RoleName.globalHead) > -1);
    period = {
        startDate: moment().endOf("month").add(-1, 'years').format('YYYY/MM/DD'),
        endDate: moment().endOf("month").format('YYYY/MM/DD')
    }
    constructor(props) {
        super(props);
        this.state = {
            regioncontainers: [],
            dataSource: [],
            error: false,
            loading: true
        }
    }

    componentDidMount() {
        this.initRegions();
        let self = this;
        let timer;
        window.addEventListener('resize', () => {
            if (!timer) {
                timer = setTimeout(() => {
                    clearTimeout(timer);
                    timer = null;
                    self.countChart && self.countChart.resize();
                }, 300);
            }
        });

        this.echartLoaded = Promise.all([
            import('echarts/lib/echarts'),
            import('echarts/lib/chart/line'),
            import('echarts/lib/component/tooltip'),
            import('echarts/lib/component/legend'),
        ]);

    }

    initRegions() {
        const { statisticsFilterTypeId, statisticsFilterId } = this.props.surveyResponse;
        let regionId: string, regionGroupId: string;
        if (statisticsFilterTypeId) {
            if (statisticsFilterTypeId == StatisticsFilterType.Region) {
                regionId = statisticsFilterId;
            } else {
                regionGroupId = statisticsFilterId;
            }
        }
        const params: any = {
            ...this.period,
            regionId,
            regionGroupId
        };
        this.surveyReportService
            .getLicenseHistoryReport(params)
            .then(data => {
                this.setState({
                    dataSource: data,
                    loading: false
                }, () => this.init(true));
            })
            .catch(() => this.setState({ error: true }));
    }

    init(needInitRegionData) {
        this.initregioncontainers(needInitRegionData);

        this.echartLoaded.then(([d]) => {
            echarts = d;
            this.initCountChart();
            setTimeout(() => watchMediaChange(this), 200)
        })
    }

    generateColor() {
        let colorElements = "0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f";
        let colorArray = colorElements.split(",");
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += colorArray[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    initregioncontainers(needInitRegionData) {
        const delta = this.state.dataSource.length - colors.length;
        if (delta > 0) {
            for (let i = 0; i < delta; i++) {
                colors.push(this.generateColor());
            }
        }
        let regioncontainers = this.state.dataSource.map((item, index) => {
            if (needInitRegionData) {
                item.isRingShow = true;
                item.color = colors[index];
                this.organizeData(item);
            }
            return item;
        });
        this.setState({ regioncontainers: regioncontainers });
    }

    initCountChart() {
        !this.countChart && (this.countChart = echarts.init(ReactDOM.findDOMNode(this.refs.countchart)));
        let seriesdata = [];
        this.seriesdataMap.forEach((item) => {
            const solidItem = item.filter(i => this.legendLines4GrapeCitybyRegion.solidLine && i.lineStyle.normal.type == 'solid').map(formatSeriesData("Total License", this.state.dataSource.length));
            const dottedItem = item.filter(i => this.legendLines4GrapeCitybyRegion.dottedSolidLine && i.lineStyle.normal.type == 'dotted').map(formatSeriesData("GrapeSEED License", this.state.dataSource.length));
            const dashedItem = item.filter(i => this.legendLines4GrapeCitybyRegion.dashedLine && i.lineStyle.normal.type == 'dashed').map(formatSeriesData("LittleSEED License", this.state.dataSource.length));
            seriesdata = [...seriesdata, ...solidItem, ...dottedItem, ...dashedItem];
        });

        const option = {
            ...chartOptions("Total License", "GrapeSEED License", "LittleSEED License"),
            tooltip: {
                trigger: 'axis', //item
                formatter: (data) => this.showTooltip(data)
            },
            xAxis: {
                type: 'category',
                data: this.xAxisdata
            },
            yAxis: {
                minInterval: 2,
                type: 'value'
            },
            series: seriesdata
        };
        this.formatData.countChart = option;
        this.countChart.setOption(option, false, true);
    }

    showTooltip(data) {
        let res = "";
        let flag = false;
        data.forEach((item, index) => {
            let showNames = `${item.name} ${item.seriesName}`;
            if (showNames.length && item.value) {
                if (!flag) {
                    flag = true;
                    res = item.axisValue + '<br/>';
                }
                res += showNames + ': ' + item.value + '<br/>';
            }
        });
        return res;
    }

    organizeData(data) {
        let totalPerMonth = [], grapeseedPerMonth = [], littleseedPerMonth = [],
            digitalPerMonth = [], textbookPerMonth = [], digitalUsersPerMonth = [];

        data.licenses.forEach((item, index) => {
            if (!this.xAxisdata.some(xAxisitem => xAxisitem == item.date)) {
                this.xAxisdata.push(item.date);
            }
            let digitalLicense = item.license.digitalLicense;
            let digitalUsers = item.license.digitalUsers;
            let textbookLicense = item.license.textbookLicense;
            let littleSeedLicense = item.license.littleSeedLicense;
            let studentCount = item.license.studentCount;

            if (index > 0 && index == data.licenses.length - 1
                && digitalLicense == 0
                && digitalUsers == 0
                && textbookLicense == 0
                && littleSeedLicense == 0
                && studentCount == 0) {
                digitalLicense = data.licenses[index - 1].license.digitalLicense;
                digitalUsers = data.licenses[index - 1].license.digitalUsers;
                textbookLicense = data.licenses[index - 1].license.textbookLicense;
                littleSeedLicense = data.licenses[index - 1].license.littleSeedLicense;
                studentCount = data.licenses[index - 1].license.studentCount;
            }
            digitalPerMonth.push(digitalLicense);
            textbookPerMonth.push(textbookLicense);
            digitalUsersPerMonth.push(digitalUsers);

            //let grapeseedLicense = digitalLicense + textbookLicense;
            grapeseedPerMonth.push(studentCount - littleSeedLicense);
            littleseedPerMonth.push(littleSeedLicense);
            totalPerMonth.push(studentCount);
        });

        this.legendLines4GrapeCitybyRegion.solidLine && this.generateSeries(this.seriesdataMap, totalPerMonth, data, " Total License", "solid");
        this.legendLines4GrapeCitybyRegion.dottedSolidLine && this.generateSeries(this.seriesdataMap, grapeseedPerMonth, data, " GrapeSEED License", "dotted");
        this.legendLines4GrapeCitybyRegion.dashedLine && this.generateSeries(this.seriesdataMap, littleseedPerMonth, data, " LittleSEED License", "dashed");
    }

    updateChartData(clickedRegion) {
        let regioncontainers = this.state.dataSource.map((item, index) => {
            if (item.id == clickedRegion.id) {
                item.isRingShow = !item.isRingShow;
            }
            return item;
        });

        this.setState({ regioncontainers: regioncontainers });

        const ringShows = regioncontainers.filter(r => r.isRingShow);
        const countData = this.formatData.countChart.series.filter(d => ringShows.some(s => s.id === d.seriesId)).map(needAnimation(ringShows.length));

        setTimeout(() => {
            this.countChart.setOption({ ...this.formatData.countChart, series: countData }, true, true);
        }, 100)
        return;

    }

    generateSeries(seriesdataMap, data, item, type, linetype) {

        let tempSeries = {
            seriesId: item.id,
            name: item.name,
            data: data,
            type: 'line',
            lineStyle: {
                normal: { width: 4, color: item.color, type: linetype }
            }
        };
        if (seriesdataMap.has(item.id)) {
            seriesdataMap.get(item.id).push(tempSeries);
        } else {
            seriesdataMap.set(item.id, [tempSeries]);
        }
    }

    render() {
        return !this.state.error && <div className="licensebyregion">
            <Row className="license" style={{ marginBottom: "30px" }}>
                {this.state.loading
                    ? <Skeleton active />
                    : <>
                        <Row className="lic-cnt-vw">{GLGlobal.intl.formatMessage({ id: SurveyLocale.SurveyCompleteLicenseCountView })}</Row>
                        <Row className="chart">
                            <div className="chartcontent">
                                <div ref="countchart" className="chartcontainer"></div>
                            </div>
                        </Row>
                    </>
                }
            </Row>
        </div>
    }
}

function formatSeriesData(name, animationThreshold) {
    return (d) => {
        const dataName = d.name;
        d.name = name;
        d.data = d.data.map(d => ({
            name: dataName,
            value: d
        }))
        return needAnimation(animationThreshold)(d);
    }
}
function needAnimation(animationThreshold) {
    return (d) => {
        d.animation = animationThreshold <= 20;
        return d;
    }
}