import { SurveyService } from "@app/service/survey";

interface Services {
    survey: SurveyService
}

export interface SurveyMediaState {
    sasUrl: string;
}

export default {
    namespace: "surveyMedia",
    state: {
        sasUrl: null,
    },
    reducers: {
        setSASUrl(state, { payload }) {
            return { ...state, sasUrl: payload };
        }
    },
    effects: {
        *getSASUrl({ payload }, { call, put }, { survey }: Services) {
            const data = yield call(survey.getSASUrl, payload);
            yield put(setSASUrl(data));
        }
    },
    services: {
        survey: SurveyService
    }
}

export function getSASUrl(state?) {
    return { type: "surveyMedia/getSASUrl", payload: state };
}

export function setSASUrl(state) {
    return { type: "surveyMedia/setSASUrl", payload: state };
}
