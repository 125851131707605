import {INotificationService, NotificationService} from "@app/service/notifications";

interface Services {
    notificationService: INotificationService;
}

export interface NotificationState {
    numUnread: number;
}

export default {
    namespace: 'notification',
    state: {
        numUnread: 0,
    },
    reducers: {
        setNumUnread(state, action) {
            return { ...state, numUnread: action.payload };
        }
    },
    effects: {
        *getNumUnread(_, { call, put }, { notificationService }: Services) {
            const data = yield call(notificationService.countAllNotificationsUnRead);
            yield put(setNumUnread(data));
        },
    },
    services: {
        notificationService: NotificationService,
    },
}

export function setNumUnread(state) {
    return { type: 'notification/setNumUnread', payload: state }
}

export function getNumUnread() {
    return { type: "notification/getNumUnread" };
}
