import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { SchoolHelper } from '../../util';
import { injectable } from '../../util/di'
import {
    ISchoolService,
    SchoolModel,
    GetSchoolRequest,
    SchoolRequest,
    SchoolDetailModel,
    UpdateCoachSchoolAssociationModel,
    SASModel,
    CreateBillingAttachmentRequestModel,
    AccessibleSchool
} from './index';
import { GSAdminLocale } from '../../locales/localeid';
import { TagListModel } from '../tag-manager';

@injectable()
export class SchoolService extends GLServiceBase<SchoolRequest, GetSchoolRequest> implements ISchoolService {
    serviceRoute = { prefix: 'admin/v1/schools', itemSuffix: '{schoolId}', suffix: '{id}' }
    //serviceRoute = { prefix: 'admin/v1/schools', suffix: '{id}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceSchoolNotFound;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = GSAdminLocale.ServiceSchoolNotSave;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = GSAdminLocale.ServiceSchoolNotFound;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = GSAdminLocale.ServiceSchoolNotSave;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceSchoolNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = { id: GSAdminLocale.ErrorDeleteForTargetUsed, values: { name: 'school' } };
        return result;
    })();
    formatSchoolList(schools: any[], toExcelExport?: boolean) {
        const schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType();
        return schools.map(school => {
            school.subscriptionType = schoolSubscriptionType.get(school.subscriptionType.toString());
            school.subscriptionType4Sort = school.subscriptionType;
            school.hasBillingHistory = school.licenses.hasBillingHistory;
            school.previousTotal = school.licenses.previousTotal;
            school.currentTotal = school.licenses.currentTotal;
            school.digitalLicense = school.licenses.digitalLicense;
            school.textbookLicense = school.licenses.textbookLicense;
            school.littleSeedLicense = school.licenses.littleSeedLicense;
            school.regionLicenseBillingHistoryId = school.licenses.regionLicenseBillingHistoryId;
            school.studentCount = school.licenses.studentCount;
            school.trainerName = school.trainerName;
            school.hasMultipleTrainer = school.hasMultipleTrainer;
            school.grapeSeedLicense = school.licenses.grapeSeedLicense;
            school.littleSeedLicense = school.licenses.littleSeedLicense;
            school.tagsJoined = school.tags.map((tag: TagListModel) => tag.name).join();

            return school;
        });
    }
    formatSchoolTotalData(totalData): any[] {
        return [
            {
                id: "1",
                name: "Total",
                gsNumber: "",
                subscriptionType: "",
                previousTotal: totalData.previousTotal,
                currentTotal: totalData.currentTotal,
                digitalLicense: totalData.digitalLicense,
                textbookLicense: totalData.textbookLicense,
                littleSeedLicense: totalData.littleSeedLicense
            }
        ];
    }
    createSchool(school: SchoolDetailModel) {
        return this.create(school);
    }
    getSchool(params: any) {
        return this.get("{id}", params);
    }
    getSchoolLicenseTypes(params: any) {
        return this.get("{schoolId}/SchoolLicenseTypes", null, { schoolId: params });
    }
    editSchool(school: SchoolDetailModel) {
        return this.update(school);
    }
    getSchoolBillingHistory(params: any) {
        return this.get(
            "{schoolId}/region/{regionId}/billingdates",
            params.pagingParams,
            params.routeParams
        );
    }
    formatSchoolBillingHistory(
        billingHistories: any[],
        pagination?: any
    ): any[] {
        return billingHistories.map((billingHistory, index) => {
            billingHistory.billingPeriod = SchoolHelper.formatBillingPeriod(
                billingHistory.billingPeriod
            );
            billingHistory.closeDate = SchoolHelper.formatCloseDate(
                billingHistory.closeDate
            );
            billingHistory.isLatestBilling =
                index == 0 && pagination && pagination.current == 1;
            return billingHistory;
        });
    }
    getAccessible(regionId?) {
        return this.getItemsBy("accessibleschools", { regionId });
    }
    getAccessibleWithoutDisabled(regionId?, disabled?: boolean) {
        return this.getItemsBy("accessibleschools", { regionId, disabled });
    }
    getAccessibleCampuses(schoolId?, disabled?) {
        return this.getItemsBy(
            "{schoolId}/campuses/accessiblecampuses",
            { isDetail: false, disabled },
            { schoolId }
        );
    }

    // getAccessible() {
    //     return this.getItemsBy('accessibleschools');
    // }
    getSchoolUserRoles(route) {
        return this.getItemsBy("{id}/users/{userId}/role", null, route);
    }
    getSchoolTeachers(parmas) {
        return this.get("{id}/teachers", parmas);
    }
    removeSchoolTeacher(params) {
        return this.delete("{id}/teachers/{teacherId}", params);
    }
    getSchoolTeacher(parmas) {
        return this.get<[{ schoolClassId: string; disabled: boolean }]>(
            "{id}/teachers/{teacherId}",
            parmas
        );
    }
    getSchoolAdmins(params) {
        return this.get("{id}/admins", params);
    }
    getSchoolContacts(params) {
        return this.get("{id}/contacts", params);
    }
    getTeacherInfos(params) {
        return this.get("{id}/teacherinfos", params);
    }
    getCampusTeachers(params) {
        return this.get("{schoolId}/campuses/{id}/teachers", params);
    }
    getCampusContacts(params) {
        return this.get("{schoolId}/campuses/{id}/contacts", params);
    }
    getCampusAdmins(params) {
        return this.get("{schoolId}/campuses/{id}/admins", params);
    }
    setSchoolContact(id, data) {
        return this.update("{id}/contacts", data, { id });
    }
    setCampusContact(schoolId, id, data) {
        return this.update("{schoolId}/campuses/{id}/contacts", data, {
            schoolId,
            id
        });
    }
    getSchoolAccountManagers(params) {
        return this.get("{id}/accountmanagers", params);
    }
    removeSchoolAccountManagers(params) {
        return this.delete("{id}/accountmanager/{userId}", null, params);
    }

    getSchoolTrainer(parmas): any {
        return this.get<[{ regionId: string; disabled: boolean }]>(
            "{id}/trainers/{trainerId}",
            parmas
        );
    }
    getTeacherContentPermission(params: any) {
        return this.get(
            "{schoolId}/teachers/{teacherId}/contents/permissions",
            params
        );
    }
    checkSchoolCodeUniqueness(params: any, schoolId: string) {
        return this.get(
            "{code}/unique",
            { schoolId },
            params
        );
    }
    generateSchoolCode(regionId: string) {
        return this.get("~admin/v1/schools/{regionId}/generateCode",
            { regionId },
            null
        )
    };
    getCoachAssociations(params) {
        return this.get("~admin/v1/schools/trainer/{trainerId}", params)
    }
    updateCoachAssociations(params, data: UpdateCoachSchoolAssociationModel) {
        return this.update("~admin/v1/schools/trainer/{trainerId}", data, params)
    }
    removeCoachAssociations(params) {
        return this.delete("~admin/v1/schools/trainer/{trainerId}", params)
    }
    getSASForBillingAttachment(params: { schoolId: string }): Promise<SASModel> {
        return this.get("{schoolId}/billing-attachment/getSAS", params);
    }
    createBillingAttachment(params: CreateBillingAttachmentRequestModel) {
        return this.request.post(this.url("billing-attachment/create"), params);
    }
    deleteBillingAttachment(params: { schoolId: string, regionLicenseInvoiceHistoryId: string }) {
        return this.delete("{schoolId}/billing-attachment/delete/{regionLicenseInvoiceHistoryId}", params);
    }
    getAccessibleSchools4Coach(): Promise<AccessibleSchool[]> {
        return this.get("schoolsbytrainer");
    }
    getGSConnectAccess() {
        return this.get("hasconnectaccess");
    }
}
