import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface GlobalProductModel {
    id?: string;
    name?: string;
    versionGroupId?: string;
    versionGroupName?: string;
    unit?: string;
    disabled?: boolean;
    isLittleSeed?: boolean;
}
export enum GlobalProductModelProps {
    id = "id",
    name = "name",
    versionGroupId = "versionGroupId",
    versionGroupName = "versionGroupName",
    unit = "unit",
    disabled = "disabled"
}
export class GlobalProductPagingRequest extends GLPagingRequest {}
export interface GlobalProductRequest extends GLRequest, GlobalProductModel {}
export interface GlobalProductResponse extends GLResponse, GlobalProductModel {}

export const emptyGlobalProduct: GlobalProductModel = {
    id: null,
    name: "　"
};

export interface LocalProductUnitPrice {
    id?: string;
    startUnit?: number;
    endUnit?: number;
    price?: number;
}
export enum LocalProductUnitPriceProps {
    id = "id",
    startUnit = "startUnit",
    endUnit = "endUnit",
    price = "price"
}
export interface SubProduct {
    id?: string;
    name?: string;
    startUnit?: number;
    endUnit?: number;
}
export enum SubProductProps {
    id = "id",
    name = "name",
    startUnit = "startUnit",
    endUnit = "endUnit"
}
export interface LocalProductModel {
    id?: string;
    name?: string;
    licenseType?: number;
    startUnit?: number;
    endUnit?: number;
    unitPrices?: LocalProductUnitPrice[];
    currency?: string;
    globalProduct?: GlobalProductModel;
    globalProductId?: string;
    isTextbookSubscription?: boolean;
    isTextSubscription?: boolean;
    defaultQuantityToLicenseCount?: boolean;
    isDigitalSubscription?: boolean;
    isPurchase?: boolean;
    subProducts?: SubProduct[];
    disabled?: boolean;
    isPackageSubProduct?: boolean;
    regionId?: string;
    regionName?: string;
    regionEnglishName?: string;
    type?: number;
}
export enum LocalProductModelProps {
    id = "id",
    name = "name",
    licenseType = "licenseType",
    startUnit = "startUnit",
    startUnitText = "startUnitText",
    endUnit = "endUnit",
    endUnitText = "endUnitText",
    unitPrices = "unitPrices",
    currency = "currency",
    globalProductId = "globalProductId",
    globalProductName = "globalProductName",
    isTextSubscription = "isTextSubscription",
    defaultQuantityToLicenseCount = "defaultQuantityToLicenseCount",
    isDigitalSubscription = "isDigitalSubscription",
    isPurchase = "isPurchase",
    disabled = "disabled",
    regionId = "regionId",
    regionName = "regionName",
    regionEnglishName = "regionEnglishName",
    type = "type"
}
export const emptyLocalProductLicenseType: GlobalProductModel = {
    id: null,
    name: "　"
};
export class LocalProductPagingRequest extends GLPagingRequest {}
export interface LocalProductRequest extends GLRequest, LocalProductModel {}
export interface LocalProductResponse extends GLResponse, LocalProductModel {}
