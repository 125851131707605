import React, { Component } from 'react';
import { SaveCancelBtns } from '@app/components';
import { SchoolLocale } from '@app/locales/localeid';
import { Row, Input, Icon, Spin, notification, Tooltip } from 'antd-min';
import { clearErrors, trimPlainText, isCoachAndSelf } from '@app/util/coach/fun';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { fmtMsg, lazyInject, TYPES, textareaValider, GSSchoolAction } from '@app/util';
import { VisitationCoachStage, VisitationSchoolReviewStatus } from '@app/util/coach/enum';
import { GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, GLGlobal } from 'gl-commonui';
import { LazyPortal } from '@app/components/admin/lazy-portal';
import "../component/progress.less";

interface SchoolReviewProps extends GLFormComponentProps{
    visitation: VisitationModel
    callback: (stage) => void
}
interface SchoolReviewStates {
    adminNotes?: string
    schoolNotes?: string
    loading?: boolean
}

@GLForm.create()
export class SchoolReview extends Component<SchoolReviewProps, SchoolReviewStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    constructor(props) {
        super(props);
        this.state = { 
            adminNotes: "", 
            schoolNotes: "",
            loading: false,
        };
    }
    componentWillMount() {
        this.getSchoolReviewNotes();
    }
    getSchoolReviewNotes() {
        const { visitation: { id } } = this.props;
        this.setState({ loading: true });
        this.visitationservice.getSchoolReviewNotes({ visitationId: id }).then(data => {
            if (data) {
                const { adminNotes, schoolNotes, status } = data;
                this.setState({
                    adminNotes,
                    schoolNotes,
                    loading: false
                });
            } else {
                this.setState({ loading: false });
            }
        });
    }
    onSubmit(e, saveAsDraft = false) {
        e.preventDefault();
        const { form } = this.props;
        if (saveAsDraft) {
            clearErrors(form);
            form.validateFields((error, values) => {
                const keys = error && Object.keys(error);
                const valuesKeys = Object.keys(values);
                const { adminNotes, schoolNotes } = values;
                if (!error) {
                    this.updateSchoolReviewNotes(saveAsDraft, values);
                } else if (keys.length == valuesKeys.length && !adminNotes && !schoolNotes) {
                    form.resetFields();
                    form.setFieldsValue(values);
                    this.updateSchoolReviewNotes(saveAsDraft, values);
                } else if (keys.length == valuesKeys.length && !adminNotes && schoolNotes) {
                    form.resetFields(["adminNotes"]);
                    form.setFieldsValue({ adminNotes: values.adminNotes });
                } else if (keys.length == valuesKeys.length && adminNotes && !schoolNotes) {
                    form.resetFields(["schoolNotes"]);
                    form.setFieldsValue({ schoolNotes: values.schoolNotes });
                } else if (keys.length != valuesKeys.length && !adminNotes && schoolNotes) {
                    form.resetFields(["adminNotes"]);
                    form.setFieldsValue({ adminNotes: values.adminNotes });
                    this.updateSchoolReviewNotes(saveAsDraft, values);
                } else if (keys.length != valuesKeys.length && adminNotes && !schoolNotes) {
                    form.resetFields(["schoolNotes"]);
                    form.setFieldsValue({ schoolNotes: values.schoolNotes });
                    this.updateSchoolReviewNotes(saveAsDraft, values);
                }
            });
        } else {
            form.validateFields((error, values) => {
                if (error) return;
                this.updateSchoolReviewNotes(saveAsDraft, values);
            });
        }
    }
    updateSchoolReviewNotes(saveAsDraft, values) {
        const { visitation: { id }, callback } = this.props;
        const status = saveAsDraft ? VisitationSchoolReviewStatus.Draft : 
                                             VisitationSchoolReviewStatus.Completed;
        const params = {
            visitationId: id,
            model: {
                status: status,
                visitationId: id,
                adminNotes: trimPlainText(values.adminNotes),
                schoolNotes: trimPlainText(values.schoolNotes),
            }
        }
        this.visitationservice.createSchoolReviewNotes(params).then(data => {
            if (!saveAsDraft) {
                callback(VisitationCoachStage.Completed);
                this.showCompleteNotification();
            }
        });
    }
    showCompleteNotification() {
        notification.open({
            duration: 5,
            className: "complete-notification",
            message: fmtMsg({ id: SchoolLocale.VisitationCompleteMessage }),
            description: fmtMsg({ id: SchoolLocale.VisitationCompleteDescription }),
            icon: <Icon type="schedule" style={{ color: '#52c41a' }} />,
        });
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form, localeId, fieldName) {
        return textareaValider(form, fieldName).concat(this.lengthValidator(localeId, 2000));
    }
    render() {
        const { form, visitation: { stage, coachId } } = this.props;
        const { renderFormItem } = FormHelper;
        const { adminNotes, schoolNotes, loading } = this.state;
        const disabled = stage > VisitationCoachStage.SchoolReview;
        const options = {
            ...form,
            decoratorOptions: {
                validateFirst: false,
                validateTrigger: null
            }
        }
        return <div className={`review-school info-container ${disabled && "disabled"}`}>
            <Spin spinning={loading}>
            <GLForm>
                <FormItemsLayout>
                {GLGlobal.isActionValid(GSSchoolAction.NotesForAdmin) &&
                    <Row className="admin-notes">
                        {renderFormItem(options, SchoolLocale.VisitationNotesForAdmins, "adminNotes", 
                            disabled ? <Input.TextArea rows={3} disabled /> : <Input.TextArea rows={3} />, adminNotes, true, this.noteValidator(form, SchoolLocale.VisitationNotesForAdmins, "adminNotes"))}
                    </Row>
                }
                {GLGlobal.isActionValid(GSSchoolAction.NotesForSchool) && 
                    <Row className="school-notes">
                        {renderFormItem(options, SchoolLocale.VisitationNotesForSchool, "schoolNotes", 
                            disabled ? <Input.TextArea rows={3} disabled /> : <Input.TextArea rows={3} />, schoolNotes, true, this.noteValidator(form, SchoolLocale.VisitationNotesForSchool, "schoolNotes"))}
                    </Row>
                }
                <LazyPortal selector=".admin-notes .ant-form-item>div:first-child">
                    <Tooltip
                            title={fmtMsg({id: SchoolLocale.VisitationForInternalStaff})}
                            overlayClassName="admin-notes-popover"
                            placement="topLeft"
                            key="admin-external-notes"
                    >
                        <Icon type="info-circle" />
                    </Tooltip>
                </LazyPortal>
                <LazyPortal selector=".school-notes .ant-form-item>div:first-child">
                    <Tooltip
                        title={fmtMsg({id: SchoolLocale.VisitationForExternalStaff})}
                        overlayClassName="school-review-notes-popover"
                        placement="topLeft"
                        key="school-internal-notes"
                    >
                        <Icon type="info-circle" />
                    </Tooltip>
                </LazyPortal>
                </FormItemsLayout>
                {!disabled && isCoachAndSelf(GSSchoolAction.Completed, coachId) &&
                <SaveCancelBtns saveTitle={SchoolLocale.VisitationCompleteText} 
                    onSubmit={(e) => this.onSubmit(e, false)}
                    cancelTitle={SchoolLocale.VisitationSaveDraft} 
                    onCancel={(e) => this.onSubmit(e, true)} />}
            </GLForm>
            </Spin>
        </div>
    }
}