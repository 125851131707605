import React, { Component } from 'react';
import moment from 'moment';
import "../component/progress.less";
import { Link } from 'react-router-dom';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { fmtMsg, lazyInject, TYPES, ContextHelper, DataMeasurementEnum, formatBytesByCeil, DateHelper } from '@app/util';
import { GLForm, GLFormComponentProps, GLUtil, GLGlobal, MessageHelper, NotificationType } from 'gl-commonui';
import { Table, Icon, Spin, Modal, Progress, Button } from 'antd-min';
import { VisitationTeacherStage, LVAResourceStatus, ServerExceptionCode as ExceptionCode, LVAResourceType, VisitationCoachStage } from '@app/util/coach/enum';
import { IVisitationService, TeacherRelatedModel } from '@app/service/coach/visitation';
import { IVideoStorageManager, VideoStorageManager } from '@app/util/storage/video-storage-manager';
import { IAttachmentStorageManager, AttachmentStorageManager } from '@app/util/storage/attachment-storage-manager';
import { VideoModal } from '../component/video-modal';

interface VideoManagerProps extends GLFormComponentProps {
    visitation: TeacherRelatedModel
    callback: (stage) => void
    teacherStage: VisitationTeacherStage
}
interface VideoManagerState {
    spinning?: boolean
    recorder?: any
    lvaResources?: any[]
    visibleModal?: boolean
    lvaResourceFormId?: string
    playVideoVisible?: boolean
    urlVideo?: string
    nameVideo?: string
}

@GLForm.create()
export class VideoManager extends Component<VideoManagerProps, VideoManagerState> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    videoStorageManager: IVideoStorageManager;
    attachmentStorageManager: IAttachmentStorageManager;
    userId = ContextHelper.getUserLoginInfo().profile.sub
    containerName = (GLGlobal.processEnv().containers as any).visitation
    videoMadal
    uploadingVideos = []

    uploadCompletedStatus = {
        video: {
            completed: false,
            successFile: {}
        },
        attachment: {
            completed: false,
            successFile: {}
        }
    };

    constructor(props) {
        super(props);
        this.state = { 
            spinning: false,
            recorder: {},
            lvaResources: [],
            playVideoVisible: false,
            urlVideo: '',
            nameVideo: '',
        }
    }
    initVideoManager() {
        const self = this;
        this.videoStorageManager = new VideoStorageManager({
            containerName: this.containerName,
            getMediaSas: () => this.visitationservice.getMediaSas()
        })
        .onProgress((progress) => {
            self.formatLvaResources(progress, {});
        })
        .onSuccess((success) => {
            self.uploadCompletedStatus.video = {
                completed: true,
                successFile: success
            };
            
            self.confirmCompleted();
        })
        .onError((error) => {
            self.formatLvaResources({}, {});
        })

        this.attachmentStorageManager = new AttachmentStorageManager({
            containerName: this.containerName,
            getMediaSas: (params) => this.visitationservice.getMediaSas(params)
        }).onProgress((progress) => {
            self.formatLvaResources({}, progress);
        }).onSuccess((success) => {
            self.uploadCompletedStatus.attachment = {
                completed: true,
                successFile: success
            };
            self.confirmCompleted();
        }).onError((error) => {
            self.formatLvaResources({}, {});
        })
    }
    componentWillMount() {
        const { visitation: { id }} = this.props;
        this.setState({ spinning: true });
        this.visitationservice.getVideoManagerInfos({ visitationId: id }).then(data => {
            const { lvaResourceInfos, isLVAResourceCopyright, lvaResourceFormId } = data;
            this.setState({ 
                spinning: false,
                lvaResources: lvaResourceInfos,
                lvaResourceFormId: lvaResourceFormId,
            });
        }).catch(() => {
            this.setState({ spinning: false });
        });
    }
    componentDidMount() {
        this.initVideoManager();
    }
    componentWillReceiveProps() {
        this.videoMadal = null;
    }
    uploadVideosToAdminService(videoFile, attachmentFile) {
        const { visitation } = this.props;
        const { lvaResources } = this.state;
        const { url: videoUrl } = videoFile;
        const { url: attachmentUrl } = attachmentFile;

        const url = videoUrl || attachmentUrl;
        const splits = url && url.split(`${this.containerName}/`);
        const lvaResourceId = splits && splits[1].split("%2F")[0];
        const currentInfo = this.uploadingVideos.find(v => v.lvaResourceModel.id == lvaResourceId);

        const {
            schoolClassResource: { schoolClassId }, 
            lvaResourceModel: { 
                id, 
                name,
                externalLink,
                lvaResourceFormId, 
                associatedInfo
            }
        } = currentInfo;

        const lvaResource = (videoFile && videoFile.url) ? {
                            url: videoFile.url,
                            schoolClassId,
                            lvaResourceId: id,
                            lvaResourceFormId,
                            name: videoFile.fileName,
                            visitationId: visitation.id,
                            status: LVAResourceStatus.Completed,
                        } : {
                            url: "", // upload link, then video url will be empty
                            schoolClassId,
                            lvaResourceId: id,
                            lvaResourceFormId,
                            name: name,
                            visitationId: visitation.id,
                            status: LVAResourceStatus.Completed,
                            externalLink: externalLink
                        };

        const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;
        const lvaNote = lvaResourceNote && {
            note: lvaResourceNote.note,
            attachmentName: lvaResourceNote.attachmentName,
            attachmentUrl: attachmentUrl
        }

        const params = {
            lvaResource: lvaResource,
            visitationId: visitation.id,
            note: lvaNote,
        };
        this.visitationservice.uploadVideos(params).then((data) => {
            const index = this.uploadingVideos.findIndex(v => v.lvaResourceModel.id === id);
            const videoResource = `${lvaResource.lvaResourceId}/${lvaResource.name}`;
            this.visitationservice.getMediaSas({ readOnly: true })
            .then((data) => {
                const containerUrl = data.split("?")[0];
                const videoUrl = `${containerUrl}${this.containerName}/${videoResource}`;
                this.uploadingVideos.splice(index, 1);
                const formatResources = lvaResources.map(v => ({
                    ...v,
                    lvaResourceModels: (v.lvaResourceModels || []).map(
                        lvaResourceModel => {
                            if (lvaResourceModel.id === id) {
                                return {
                                    ...lvaResourceModel,
                                    progress: 100,
                                    url: videoUrl,
                                    status: LVAResourceStatus.Completed
                                };
                            }
                            return lvaResourceModel;
                        }
                    )
                }));
                this.setState({ lvaResources: formatResources });
            });
        });
    }
    onAddVideo() {
        this.setState({ visibleModal: true });
        this.renderVideoModal();
    }

    confirmCompleted() {
        const {
            video: { completed: videoCompleted , successFile: videoFile}, 
            attachment: { completed: attachmentCompleted, successFile: attachmentFile }
        } = this.uploadCompletedStatus;

        if (videoCompleted && attachmentCompleted) {
            this.uploadVideosToAdminService(videoFile, attachmentFile)
        }
    }

    deleteBlob(recorder) {
        const { lvaResourceModel: { id, lvaResourceType, url, associatedInfo }} = recorder;
        const attachmentUrl = associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentUrl;

        if (lvaResourceType === LVAResourceType.Video) {
            return Promise.all([
                this.videoStorageManager.delete(url, id),
                attachmentUrl ? this.attachmentStorageManager.delete(attachmentUrl, id) : Promise.resolve()
            ]);
        }
        return attachmentUrl ? this.attachmentStorageManager.delete(attachmentUrl, id): Promise.resolve();
    }
    abortBlob(recorder) {
        const { lvaResourceModel: { id, name, associatedInfo }} = recorder;
        const attachmentName = associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentName;
        
        this.videoStorageManager.abort(name, id);
        if (attachmentName) {
            this.attachmentStorageManager.abort(attachmentName, id);
        }
    }
    showAbortNotification(recorder) {
        Modal.confirm({
            className: "video-manager-stop",
            title: fmtMsg({ id: SchoolLocale.VisitationComfirmTitle }),
            content: fmtMsg({ id: SchoolLocale.VisitationVideoStop}),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                this.abortBlob(recorder);
                this.formatAbortTeacherVideos(recorder);
            }, 
            onCancel: () => {}
        });
    }
    deleteVideo(recorder) {
        const { visitation } = this.props;
        const { lvaResourceModel: { id, lvaResourceFormId, status, progress, lvaResourceType, associatedInfo } } = recorder;
        if (progress == 0 || progress < 100) {
            this.showAbortNotification(recorder);
            return;
        }
        const params = {
            resourceId : id,
            visitationId: visitation.id,
            resourceformId: lvaResourceFormId,
        };
        if (status == LVAResourceStatus.Uploading) {
            this.visitationservice.deleteVideo(params).then(data => {
                this.formatDeletedTeacherVideos(recorder);
            });
        } else if (status == LVAResourceStatus.Completed) {
            this.visitationservice.deleteVideo(params).then(() => {
                const hasAttachment = (associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentName);

                if (lvaResourceType === LVAResourceType.Video
                    || hasAttachment) {
                    this.deleteBlob(recorder).then(() => {
                        this.formatDeletedTeacherVideos(recorder);
                    });
                } else {
                    this.formatDeletedTeacherVideos(recorder);
                }
            }).catch(({error_code}) => {
                if (error_code == ExceptionCode.NotExistedTeacherError) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({id: SchoolLocale.VisitationTeacherNotExist}));
                } else if (error_code == ExceptionCode.NoPermissionDeleteError) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({id: SchoolLocale.CommonErrorNoPermission}));
                }
            });
        }
    }
    formatAbortTeacherVideos(recorder) {
        const { lvaResources } = this.state;
        const { lvaResourceModel: { id } } = recorder;
        const formatLvaResources = lvaResources.map(v => ({
            ...v,
            lvaResourceModels: (v.lvaResourceModels || []).map(
                lvaResourceModel => {
                    if (lvaResourceModel.id === id) {
                        delete lvaResourceModel.pregress;
                        delete recorder.lvaResourceModel.progress;
                    }
                    return lvaResourceModel;
                }
            )
        }));
        this.setState({ 
            recorder: recorder,
            lvaResources: formatLvaResources 
        });
    }
    formatDeletedTeacherVideos(recorder) {
        const { lvaResources } = this.state;
        const { lvaResourceModel: { id } } = recorder;
        const formatLvaResources = lvaResources.map(v => ({
            ...v,
            lvaResourceModels: (v.lvaResourceModels || [])
                .map(lvaResourceModel => {
                    if (lvaResourceModel.id === id) {
                        recorder.lvaResourceModel = null;
                        return null;
                    }
                    return lvaResourceModel;
                })
                .filter(m => m)
        }));
        this.setState({ 
            recorder: recorder,
            lvaResources: formatLvaResources 
        });
    }
    addVideoFallback(file, attachmentFile, selectedData, lvaResourceType: LVAResourceType) {
        this.formatResourcesWithResourceId(selectedData);
        const { lvaResourceModel: { id, associatedInfo } } = selectedData;

        this.resetUploadStatus(file, attachmentFile);

        if (lvaResourceType === LVAResourceType.Video) {
            this.videoStorageManager.upload(file, id, {blobContentDisposition: `attachment; filename*=UTF-8''${encodeURIComponent(file.name)}`});
        }

        if (associatedInfo && attachmentFile && attachmentFile.name) { // has attachment file
            const { lvaResourceNote } = associatedInfo;
            const attachmentId = lvaResourceNote && lvaResourceNote.attachmentUrl;
            this.attachmentStorageManager.upload(attachmentFile, attachmentId, {blobContentDisposition: `attachment; filename*=UTF-8''${encodeURIComponent(attachmentFile.name)}`})
        }
    }
    formatResourcesWithResourceId(selectedData) {
        const { lvaResources } = this.state;
        const {schoolClassResource: { schoolClassId }, lvaResourceModel } = selectedData;
        const formatResources = lvaResources.map(v => {
            if (v.schoolClassResource.schoolClassId == schoolClassId) {
                return {
                    ...v, 
                    lvaResourceModels: [
                        ...(v.lvaResourceModels || []),
                        lvaResourceModel
                    ],
                }
            } else {
                return v;
            }
        });
        this.uploadingVideos.push(selectedData);
        this.setState({
            visibleModal: false,
            lvaResources: formatResources,
        });
    }
    formatLvaResources(videoProgressData, attachmentProgressData) {  
        const isUpdatingVideo = 'progress' in videoProgressData ? true : false;
        const isUpdatingAttachment = 'progress' in attachmentProgressData ? true : false;

        if (!isUpdatingVideo && !isUpdatingAttachment){
            return; // when some error occurs while uploading
        }

        const { lvaResources } = this.state;

        const { url: urlVideo, progress: progressVideo } = videoProgressData;
        const { url: urlAttachment, progress: progressAttachment} = attachmentProgressData;
        const url = isUpdatingVideo ? urlVideo : urlAttachment;

        const splits = url && url.split(`${this.containerName}/`);
        const lvaResourceId = splits && splits[1].split("%2F")[0];
        const formatResources = lvaResources.map(v => ({
            ...v,
            lvaResourceModels: (
                v.lvaResourceModels || []
            ).map(lvaResourceModel => {

                if (lvaResourceModel.id !== lvaResourceId) {
                    return lvaResourceModel;
                }

                const {progress: lastVideoProgress, associatedInfo } = lvaResourceModel;
                const lastAttachmentProgress = associatedInfo && associatedInfo.progress ? associatedInfo.progress : 100;
                const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;

                const overallProgress = isUpdatingVideo ? (progressVideo + lastAttachmentProgress) / 2
                    : (lastVideoProgress + progressAttachment) / 2

                return isUpdatingVideo ?  {
                    ...lvaResourceModel,
                       url: urlVideo,
                       progress: progressVideo,
                       status: overallProgress == 100 ? LVAResourceStatus.Completed : 
                                                 LVAResourceStatus.Uploading
                } : {
                    ...lvaResourceModel,
                    associatedInfo: associatedInfo == null ? null : {
                        lvaResourceNote: {
                            ...lvaResourceNote,
                            attachmentUrl: urlAttachment,
                            progress: progressAttachment
                        }
                    },
                    status: overallProgress == 100 ? LVAResourceStatus.Completed : 
                                                 LVAResourceStatus.Uploading,
                }
            }
                
            )
        }));
        this.setState({ lvaResources: formatResources });
    }

    resetUploadStatus(videoFile, attachmentFile) {
        const hasVideo = videoFile && videoFile.name;
        const hasAttachment = attachmentFile && attachmentFile.name;

        this.uploadCompletedStatus = {
            video: {
                completed: hasVideo ? false : true,
                successFile: {}
            },
            attachment: {
                completed: hasAttachment ? false : true,
                successFile: {}
            }
        }
    }

    renderVideoModal() {
        const { lvaResources, lvaResourceFormId } = this.state;
        this.videoMadal = 
            <VideoModal
                title={`Upload video`}
                lvaResources={lvaResources}
                folder={this.props.visitation.id}
                lvaResourceFormId={lvaResourceFormId}
                addVideoFallback={(file, attachmentFile, selectedData, lvaResourceType) => this.addVideoFallback(file, attachmentFile, selectedData, lvaResourceType)}
            />
    }

    downloadAttachment(id, name) {
        this.visitationservice.getMediaSas({ readOnly: true, sasFromGlobalAccount: false }).then(data => {
            const splits = data.split("?");
            const suffix = splits[1];
            const container = splits[0];
            const downloadableUrl = `${container}${this.containerName}/${id}/${name}?${suffix}`;

            this.download(downloadableUrl, name)
        })
    }

    download(url, name) {
        var element = document.createElement('a'); 
        element.setAttribute('href', `${url}`);
        element.setAttribute('download', `${name}`); 
        document.body.appendChild(element); 
        element.click(); 
        document.body.removeChild(element); 
    }
    playVideo(e, recorder) {
        e.preventDefault();
        const { url, videoName } = recorder;
        this.visitationservice.getMediaSas({ readOnly: true }).then(data => {
            const splits = data.split("?");
            const suffix = splits[1];
            this.setState({
                urlVideo: `${url}?${suffix}`,
                playVideoVisible: true,
                nameVideo: videoName,
            });
        })
    }
    renderProgress(progress, status, format="", showInfo=true) {
        return <div className="status" title={format}>
                <Progress
                    width={30}
                    type="circle"
                    strokeWidth={8}
                    status={status}
                    percent={progress}
                    showInfo={showInfo}
                />
            </div>
    }
    getTableCols() {
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationClasses }),
                dataIndex: "schoolClassResource",
                width: "20%",
                render: (text, recorder, index) => {
                    const { schoolClassResource: { regionId, schoolId, campusId, schoolClassId, schoolClassName }} = recorder;
                    const path = GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: schoolClassId });
                    return <Link to={{ pathname: path }} key={index}>
                            <span className="class-name">{schoolClassName}</span>
                        </Link>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoText }),
                dataIndex: "lvaResourceModel",
                width: "15%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const title = fmtMsg({ id: SchoolLocale.VisitationVideoIsDeleted });
                    let videoName = null;

                    if (lvaResourceModel.isDelete) {
                        videoName = (<span title={title}>{lvaResourceModel.name}</span>);
                    } else if (lvaResourceModel.lvaResourceType === LVAResourceType.Video) {
                        videoName = (<a onClick={(e) => this.playVideo(e, lvaResourceModel)}>
                            {lvaResourceModel.name}
                        </a>);
                    } else {
                        videoName = (<a href={lvaResourceModel.externalLink} title={lvaResourceModel.externalLink} target="_blank">{lvaResourceModel.externalLink}</a>);
                    }
                    
                    return videoName;
                } 
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoTableNote }),
                dataIndex: "associatedInfo",
                width: "17%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    if (lvaResourceModel) {
                        const { associatedInfo } = lvaResourceModel;
                        return <span className="note">{associatedInfo && associatedInfo.lvaResourceNote.note}</span>;
                    }
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoTableAttachment }),
                dataIndex: "",
                width: "17%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel: { associatedInfo } } = recorder;
                    const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;
                    return <a className="attachment" onClick={(e)=> lvaResourceNote && this.downloadAttachment(lvaResourceNote.resourceId, encodeURI(lvaResourceNote.attachmentName))}>{lvaResourceNote && lvaResourceNote.attachmentName}</a>
                    
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoUploadTime }),
                dataIndex: "",
                width: "13%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const uploadTime = lvaResourceModel && 
                                       lvaResourceModel.uploadTime;
                    const formatUploadTime = DateHelper.toLocalStringFromUTC(moment(uploadTime));
                    return <div className="upload-time">{formatUploadTime}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoSize }),
                dataIndex: "",
                width: "12%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const size = lvaResourceModel && lvaResourceModel.size;
                    const formatMb = lvaResourceModel.lvaResourceType === LVAResourceType.Video ? formatBytesByCeil(size, DataMeasurementEnum.MB) : "-";
                    return <div className="video-size">{formatMb}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationStatus }),
                dataIndex: "status",
                width: "12%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    if (lvaResourceModel) {
                        const { status, progress } = lvaResourceModel;
                        if (status == LVAResourceStatus.Uploading) {
                            return progress == null ? this.renderProgress(100, "exception", fmtMsg({id: SchoolLocale.VisitationVideoUploadFailedText})) :
                                                      this.renderProgress(progress, progress === 100 ? "success" : "active");
                        } else if (status == LVAResourceStatus.Completed) {
                            return this.renderProgress(100, "success", fmtMsg({id: SchoolLocale.VisitationVideoUploadSuccessText}));
                        }
                    }
                }
            },
            {
                title: "",
                dataIndex: "",
                width: "5%",
                render: (text, recorder, index) => {
                    return <div className={`action`}>
                        <Icon type="delete" title="delete" onClick={() => this.deleteVideo(recorder) } />
                    </div>
                }
            },
        ]
    }
    formatLVAResources() {
        const { lvaResources } = this.state;
        const sortByClassName = (left, right) => 
            left.schoolClassResource.schoolClassName.localeCompare(right.schoolClassResource.schoolClassName, 'en');
        const thenByFilesName = (left, right) => 
            left.lvaResourceModel.name.localeCompare(right.lvaResourceModel.name, 'en');
        const thenByUploadTime = (left, right) => 
            new Date(left.lvaResourceModel.uploadTime).getTime() - new Date(right.lvaResourceModel.uploadTime).getTime();
        return lvaResources
            .flatMap(({ lvaResourceModels, schoolClassResource }) =>
                (lvaResourceModels || []).map(lvaResourceModel => ({
                    schoolClassResource,
                    lvaResourceModel
                }))
            )
            .sort((left, right) =>
                sortByClassName(left, right) ||
                thenByFilesName(left, right) ||
                thenByUploadTime(left, right)
            );
    }
    render() {
        const { form, teacherStage, visitation } = this.props;
        const { spinning } = this.state;
        const formatedResources = this.formatLVAResources();
        const disabled = teacherStage > VisitationTeacherStage.VideoManager;
        const diableVideo = teacherStage === VisitationTeacherStage.Completed || visitation.stage === VisitationCoachStage.Completed || visitation.stage < VisitationCoachStage.VideoManager;
        let videoWidth = 0;
        let videoHeight = 0;
        const isTablet = 768 < window.innerWidth && window.innerWidth  <= 1224;
        const isMobile = window.innerWidth <= 768;
        if (isTablet) {
            videoWidth = window.innerWidth - 100
            videoHeight = window.innerHeight - 100
        } else if (isMobile) {
            videoWidth = window.innerWidth - 50
            videoHeight = window.innerHeight - 50
        } else {
            videoWidth = window.innerWidth - 300
            videoHeight = window.innerHeight - 300
        }
        return <div className={`video-manager info-container ${disabled&&"disabled"} ${diableVideo&&"disabled-video"}`}>
            <Modal
                centered
                width={videoWidth}
                footer={false}
                closable={true}
                destroyOnClose
                className="modal_play-video--content"
                maskClosable = {false}
                onCancel={()=>{
                    this.setState({
                        playVideoVisible:false
                    })
                }}
                visible={this.state.playVideoVisible}
            >
                <div>
                    <Button
                        className="button--position"
                        type="primary"
                        onClick={() => {this.download(this.state.urlVideo, this.state.nameVideo);}}
                        key="primary">{fmtMsg({ id: SchoolLocale.CoachDownloadVideo })}</Button>
                </div>
                <video controls
                    controlsList="nodownload"
                    preload='metadata'
                    width={videoWidth}
                    height={videoHeight}
                    className="video--auto-size">
                    <source src={this.state.urlVideo+'#t=0.001'} />
                </video>
            </Modal>
            <GLForm form={form}>
                <Spin spinning={spinning}>
                <div className="header">
                    <div>{fmtMsg({ id: SchoolLocale.VisitationVideosText })}</div>
                    <div className="teacher-table-upload" onClick={() => this.onAddVideo()} title={fmtMsg({ id: SchoolLocale.VisitationSupportedVideoType})}>
                        <Icon type="upload" title="upload video" />
                        {fmtMsg({ id: SchoolLocale.SettingsAvatarUploadButton })}
                    </div>
                </div>
                <Table 
                    className="video-list"
                    columns={this.getTableCols()}
                    dataSource={formatedResources}
                    pagination={false}
                />
                </Spin>
            </GLForm>
            {this.videoMadal}
        </div>;
    }
}