import { GLResponse, GLRequest, GLPagingRequest, Role } from 'gl-commonui';
import { StudentRegistrationType, EventInfo, EventCategory, EventType, EventTypeInfo } from '@app/util';

export interface RegionAdminModel {
    id?: string
    name?: string
    email?: string
    template?: string
}

export enum RegionAdminProps {
    name = 'name',
    email = 'email',
    phone = 'phone',
    template = 'template'
}

export interface RegionModel {
    id?: string;
    name?: string;
    englishName?: string;
    disabled?: boolean;
    gsContentversion?: string;
    lsContentversion?: string;
    manager?: string;
    gsVersionGroupId?: string;
    lsVersionGroupId?: string;
    primaryLanguage?: string;
    supportEmail?: string;
    materialOrderEmail?: string;
    timezone?: string;
    billingDay?: number;
    billingPeriod?: number;
    billingStartDay?: number;
    billingStartMonth?: number;
    regionAdmins?: Array<RegionAdminModel>;
    pendingInvitationCodes?: Array<RegionAdminModel>;
    currency?: string;
    countryPhoneCode?: string;
    studentCount?: number;
    teacherCount?: number;
    // defaultAppLockState?: number;
    unitMaxFlag?: boolean;
    billForPartialMonthAdd?: boolean;
    billForPartialMonthRemove?: boolean;
    eorderLink?: string;
    enableCodeLogin: boolean;
    extendedSubscriptionMonths?: number
    studentRegistrationTypeId?: StudentRegistrationType,
    EnablePhoneRegistration: boolean
}

export interface SchoolClassBillingInfoModel {
    isCurrentInvoiceGenerated: boolean;
    skipCurrentInvoiceRun: boolean;
    skipFutureInvoiceRun: boolean;
}

//using for form control
export enum RegionModelPropNames {
    id = 'id',
    name = 'name',
    englishName = "englishName",
    regionEnglishName = "regionEnglishName",
    regionGroupId = "regionGroupId",
    gsContentversion = 'gsContentversion',
    gsVersion = 'gsVersion',
    lsContentversion = 'lsContentversion',
    lsVersion = 'lsVersion',
    manager = 'manager',
    gsVersionGroupId = 'gsVersionGroupId',
    lsVersionGroupId = 'lsVersionGroupId',
    lessonPlanVersion = 'gsLessonPlanVersionId',
    repMethodologyVersion = 'repMethodologyVersionId',
    remoteLessonVersion = 'remoteLessonPlanVersionId',
    digitalLessonVersion = 'digitalLessonPlanVersionId',
    viewLessonPlanTemplate = 'viewLessonPlanTemplateId',
    downloadLessonPlanTemplate ='downloadLessonPlanTemplateId',
    primaryLanguage = 'primaryLanguage',
    supportemail = 'supportEmail',
    materialorderemail = 'materialOrderEmail',
    timezone = 'timezone',
    billingDay = 'billingDay',
    deviceActivationLimit = "deviceActivationLimit",
    deviceDeactivationThreshold = "deviceDeactivationThreshold",
    maxUnitsPerYear = "maxUnitsPerYear",
    minDaysPerUnit = "minDaysPerUnit",
    allowMaterialRequest = "allowMaterialRequest",
    enforceUnitPlanSpacing = "enforceUnitPlanSpacing",
    enableCodeLogin = "enableCodeLogin",
    allowParentDownload = "allowParentDownload",
    allowParentLsdownload = "allowParentLsdownload",
    allowSetTeacherContactParent = "allowSetTeacherContactParent",
    allowSetParentContactTeacher = "allowSetParentContactTeacher",
    allowSchoolEditUnit = "allowSchoolEditUnit",
    allowSchoolEditLicense = "allowSchoolEditLicense",
    resetCampusAnnual = "resetCampusAnnual",
    regionAdmins = 'regionAdmins',
    status = "status",
    allowPhoneSupport = "allowPhoneSupport",
    allowTermsAgreement = "allowTermsAgreement",
    pendingInvitationCodes = 'pendingInvitationCodes',
    currency = "currency",
    // appUnlockDays = "appUnlockDays",
    studentCount = "studentCount",
    teacherCount = "teacherCount",
    // defaultAppLockState = "defaultAppLockState",
    allowGenerateUnitPlan = "allowGenerateUnitPlan",
    allowPromoteClass = "allowPromoteClass",
    unitMaxFlag = "unitMaxFlag",
    billingPeriod = "billingPeriod",
    billingStartDay = "billingStartDay",
    billingStartMonth = "billingStartMonth",
    billForPartialMonthAdd = "billForPartialMonthAdd",
    billForPartialMonthRemove = "billForPartialMonthRemove",
    delayClassBilling = "delayClassBilling",
    eorderLink = "eorderLink",
    extendedSubscriptionMonths = "extendedSubscriptionMonths",
    studentRegistrationSelectedValues = "studentRegistrationSelectedValues",
    studentRegistrationTypeId = "studentRegistrationTypeId",
    countryPhoneCode = "countryPhoneCode",
    enablePhoneRegistration = "enablePhoneRegistration",
    agreeToMaterialRequestLicense = "agreeToMaterialRequestLicense"
}

export interface DurationModel {
    startDate?: string
    endDate?: string
    isDetail?: boolean
}

export class GetRegionRequest extends GLPagingRequest {
}
export interface RegionRequest extends GLRequest, RegionModel {
}
export interface RegionResponse extends GLResponse, RegionModel {
}

export interface RegionSchoolRoleModel {
    id?: string
    name?: string
    englishName?: string
    primaryLanguage?: string
    schools?: any[]
    role?: Role
}

export enum RegionPreferenceSettingsPropName {
    schoolId = "schoolId",
    name = "name",
    schoolName = "schoolName",
    campusId = "campusId",
    campusName = "campusName",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    studentId = "studentId",
    studentName = "studentName",
    viewType = "viewType",
    totalCount = "totalCount",
    tsiEnrolledCount = "tsiEnrolledCount",
    isTsiEnabled = "isTsiEnabled",
    classDisabledDate = "classDisabledDate",
    isAllowedUnitUnlocking = "isAllowedUnitUnlocking",
    isTsiDirty = "isTsiDirty",
    isAllowedUnitUnlockingDirty = "isAllowedUnitUnlockingDirty"

}
export interface EventManagerSettingsModel {
   id: EventInfo;
   name: string;
   eventCategoryId: EventCategory;
   eventTypeId: EventTypeInfo;
   disabled: boolean;
   isEnabled: boolean;
}

