import { unmaskThrottle, MessageHelper, NotificationType } from 'gl-commonui';
import { ClassesModel, ClassTimeModel, SchoolClassService } from '../../service/class/index';
import { UserService } from '../../service/users/service';
import { SchoolClassTimeType, BulkClassEditErrorCode } from '../../util/enum';
import { guid } from 'inversify';
import { BlobHelper, fmtMsg } from '../../util';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolLocale } from '@app/locales/localeid';

export interface ClassesState {
    loading: boolean
    list: ClassesModel[]
    dataSource: ClassesModel[]
    bulkClasses: ClassesModel[]
    total: number
}
interface Services {
    classes: SchoolClassService
    user: UserService
}

export default {
    namespace: 'classes',
    state: {
        loading: true,
        list: [],
        dataSource: [],
        bulkClasses: [],
        total: 0
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        }
    },
    effects: {
        *getClasses({ payload: { query } }, { call, put }, { classes, user }: Services) {
            yield put(reload({ loading: true }));
            const { data, totalCount } = yield call(classes.getClasses, { query });
            //const data = yield call(classes.getClasses, { query });
            const teacherIds = data.filter(d => d.teacherId).map(d => d.teacherId);
            if (teacherIds.length > 0) {
                const { data: teachers } = yield call(user.getUsersByPost, { ids: teacherIds });
                yield put(reload({ list: formatClasses(data, teachers), dataSource: data.map(d => (d.teacherName = getTeacherName(d.teacherId, teachers), d)), total: totalCount, loading: false }));
            }
            else {
                yield put(reload({ list: formatClasses(data, []), dataSource: data, total: totalCount, loading: false }));
            }
        },
        *getClassesSimple({ payload: { query } }, { call, put }, { classes, user }: Services) {
            const { data } = yield call(classes.getClasses, { query });
            yield put(reload({ dataSource: data }));
        },

        *getClassListExcel({ payload: { query } }, { call, put }, { classes }: Services) {
            try {
                const data = yield call(classes.getClassListExcel, query);
                BlobHelper.saveFile(data);
                let message = 'Excel Downloaded Successfully.';
                MessageHelper.Message(NotificationType.Success, message);
                unmaskThrottle();
            }
            catch (err) {
                let message = 'An Error Occurred.';
                MessageHelper.Message(NotificationType.Failed, message);
                unmaskThrottle();
            }
        },
        *getBulkEditClasses({ payload: route }, { call, put }, { classes }: Services) {
            const data = yield call(classes.getBulkEditClasses, route);
            yield put(reload({ bulkClasses: data }));
        },
        *updateBulkEditClasses({ payload: { data, route, query } }, { call, put }, { classes }: Services, { push, pathStringify }) {
            const results = yield call(classes.updateBulkEditClasses, data, route, query);
            if (hasExecutedSuccess(results, data)) {
                yield put(push(pathStringify(PathConfig.Classes, route)));
            }

        }
    },
    services: {
        classes: SchoolClassService,
        user: UserService
    }
}
function hasExecutedSuccess(error, data) {
    if (!error.isSuccess) {
        if (error.exceptionCode == BulkClassEditErrorCode.BulkEditClassNotExistStartUnitPlanException) {
            const localId = SchoolLocale[BulkClassEditErrorCode[error.exceptionCode]];
            const schoolClassIds = error.exceptionDescription.split(':').pop().split(',');
            MessageHelper.Message(NotificationType.Failed, localId ? fmtMsg(localId, {
                classname: data.classes.filter(c => schoolClassIds.some(cid => cid == c.id)).map(c => c.className).join(', ')
            }) : error.exceptionDescription);
        } else if (error.exceptionCode) {
            MessageHelper.Message(NotificationType.Failed, error.exceptionDescription);
        } else {
            error.processResult.forEach((error) => {
                if (!error.isSuccess) {
                    BulkErrorMessage(error, data.classes.find(c => c.id === error.classId))
                }
            })
        }

    }
    return error.isSuccess
}

function BulkErrorMessage(error, data) {
    const localId = SchoolLocale[BulkClassEditErrorCode[error.exceptionCode]]
    MessageHelper.Message(NotificationType.Failed, localId ? fmtMsg(localId, { classname: data && data.className }) : error.exceptionDescription)
}

function formatClasses(list: ClassesModel[], teachers: string[]): ClassesModel[] {
    const result: ClassesModel[] = [];
    list.forEach(item => {
        item.id = guid();
        item.rowSpan = 1;
        if (item.tsi) {
            result.push({
                ...item, ...getTSI(item),
                teacherName: getTeacherName(item.teacherId, teachers),
                rowSpan: 1
            });
        }
        else {
            if (item.tsiTimePerWeek) {
                result.push({
                    ...item, ...getDefaultTSI({ ...item }),
                    teacherName: getTeacherName(item.teacherId, teachers),
                    rowSpan: 1,
                    id: guid()
                });
            } else if (!item.tsiTimePerWeek) {
                result.push({ ...item, ...getDefaultTSI(item), teacherName: getTeacherName(item.teacherId, teachers) });
            }
        }
    });
    return result;
}

function getTSI(classItem: ClassesModel) {
    if (classItem.tsi) {
        return {
            tsi_rep: SchoolClassTimeType[SchoolClassTimeType.TSI],
            duration: classItem.tsiTimePerWeek,
            count: classItem.tsi.count,
            days: classItem.tsi.days
        }
    }
    return null;
}

function getDefaultTSI(classItem: ClassesModel) {
    if (classItem.tsiTimePerWeek) {
        return {
            tsi_rep: SchoolClassTimeType[SchoolClassTimeType.TSI],
            duration: classItem.tsiTimePerWeek,
            count: null,
            days: null
        }
    }
    return null;
}

function getClassTime(classTimeItem: ClassTimeModel, duration: number) {
    return {
        duration: duration,
        count: classTimeItem.count,
        days: classTimeItem.days
    }
}

function getTeacherName(teacherId, teachers) {
    const teacher = teachers.find(teacher => teacher.id == teacherId);
    return teacher ? teacher.name : teacherId;
}

export function reload(state) {
    return { type: 'classes/reload', payload: state }
}
export function getClasses(state) {
    return { type: 'classes/getClasses', payload: state }
}
export function getClassesSimple(state) {
    return { type: 'classes/getClassesSimple', payload: state }
}
export function getClassListExcel(state) {
    return { type: 'classes/getClassListExcel', payload: state }
}
export function getBulkEditClasses(state) {
    return { type: 'classes/getBulkEditClasses', payload: state }
}
export function updateBulkEditClasses(state) {
    return { type: 'classes/updateBulkEditClasses', payload: state }
}