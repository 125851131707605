import { mergeClasses } from "@app/components/survey/functions";
import React, { FC } from "react";
import { InputDate as IInputDate } from "wijmo/wijmo.input";
import { ICellTemplateContext } from "wijmo/wijmo.react.grid";
import { InputDate } from "wijmo/wijmo.react.input";
import "./inputdate.less";

interface GridInputDateProps extends Partial<IInputDate> {
    context: ICellTemplateContext;
    className?: string;
    initialized?: (sender) => void
    onInputDateChanged?: (value) => void
}

export const GridInputDate: FC<GridInputDateProps> = (props) => {
    const { className, context, onInputDateChanged, ...rest } = props;

    const selectedIndexChanged = (ref: IInputDate) => {
        const { binding } = context.col;
        onInputDateChanged && onInputDateChanged(ref.value)
        context.value = ref.value;
        context.item[binding] = ref.value;
    };

    return (
        <InputDate
            {...rest}
            className={mergeClasses("grid-inputdate", className)}
            isRequired={context.col.isRequired}
            value={context.value || new Date()}
            valueChanged={selectedIndexChanged}
            lostFocus={selectedIndexChanged}
        />
    );
};
