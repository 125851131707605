import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";
import { DigitalAdoptionReportType } from "@app/util";

export interface DigitalAdoptionReportModel {
    regionId?: string;
    regionName: string;
    regionAdoptionReport: AdoptionReportChartModel[];
}

export interface AdoptionReportChartModel {
    xAxisLabel: string;
    value: number;
    percentageValue?: number;
    reportType?: DigitalAdoptionReportType;
}

export class GetDigitalAdoptionReportRequest extends GLPagingRequest {
}

export interface DigitalAdoptionReportRequest extends GLRequest, DigitalAdoptionReportModel {
}

export interface DigitalAdoptionReportResponse extends GLResponse, DigitalAdoptionReportModel {
}
