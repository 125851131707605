import { ReactNode } from "react";

export * from "./visit-tracking";

export interface VisitTrackingStep {
    icon: ReactNode;
    title?: string | ReactNode;
    description?: string | ReactNode;
    completed?: boolean;
    active?: boolean;
    link?: string;
    onClick?: () => void;
}
