import React, { Component } from 'react';
import "../component/progress.less";
import { Row, Input, Spin, Icon } from 'antd-min';
import { SaveCancelBtns } from '@app/components';
import { SchoolLocale } from '@app/locales/localeid';
import { clearErrors, trimPlainText, isCoachAndSelf } from "@app/util/coach/fun";
import { lazyInject, TYPES, textareaValider, GSSchoolAction, GSAdminAction } from '@app/util';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { VisitationCoachStage, VisitationSchoolNoteStatus } from '@app/util/coach/enum';
import { GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, GLGlobal } from 'gl-commonui';
import { LazyPortal } from '@app/components/admin/lazy-portal';
import { IconButton } from '../../component/icon-button';

interface VisitSchoolProps extends GLFormComponentProps {
    visitation: VisitationModel
    callback: (stage) => void
}
interface VisitSchoolStates {
    note?: string
    spinning?: boolean
    showIconButton?: boolean
}

@GLForm.create()
export class VisitSchool extends Component<VisitSchoolProps, VisitSchoolStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    userId = GLGlobal.loginInfo().profile.sub
    constructor(props) {
        super(props);
        this.state = { 
            note: "", 
            spinning: false,
            showIconButton: false,
        };
    }
    componentWillMount() {
        this.getSchoolVisitNote();
    }
    getSchoolVisitNote() {
        const { visitation: { id } } = this.props;
        this.setState({ spinning: true });
        this.visitationservice.getSchoolVisitNote({ visitationId: id }).then(data => {
            this.setState({ spinning: false });
            if (!data) {
                return;
            }
            const { status, note } = data;
            this.setState({
                note,
            });
        });
    }
    onSubmit(e, saveAsDraft = false) {
        e.preventDefault();
        const { form } = this.props;
        if (saveAsDraft) {
            clearErrors(form);
            form.validateFields((error, values) => {
                const { schoolnotes } = values;
                if (!error || (error && !schoolnotes)) {
                    form.resetFields();
                    form.setFieldsValue(values);
                    this.setSchoolVisitNote(saveAsDraft, values);
                }
            });
        } else {
            form.validateFields((error, values) => {
                if (error) return;
                this.setSchoolVisitNote(saveAsDraft, values);
            });
        }
    }
    setSchoolVisitNote(saveAsDraft, values) {
        const { visitation: { id }, callback } = this.props;
        const status = saveAsDraft ? VisitationSchoolNoteStatus.Draft : VisitationSchoolNoteStatus.Completed;
        const params = {
            visitationId: id,
            status: status,
            note: trimPlainText(values.schoolnotes),
        }
        this.visitationservice.createSchoolVisitNote(params).then(data => {
            !saveAsDraft && callback(VisitationCoachStage.TeacherReview);
        });
    }
    updateSchoolVisitNote(e) {
        e.preventDefault();
        const { form, visitation: { id } } = this.props;
        form.validateFields((error, values) => {
            if (error) return;
            const params = {
                visitationId: id,
                note: trimPlainText(values.schoolnotes),
            }
            this.setIsShowIconButton(false);
            this.visitationservice.updateSchoolVisitNote(params);
        });
    }
    setIsShowIconButton(showIconButton) {
        this.setState({ showIconButton });
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    render() {
        const { renderFormItem } = FormHelper;
        const { note, spinning, showIconButton } = this.state;
        const { form, visitation: { stage, coachId } } = this.props;
        const disabled = stage > VisitationCoachStage.VisitSchool;
        const disabledInput = (coachId != this.userId) || 
                              (coachId == this.userId && disabled && !showIconButton);
        const showEditIcon = coachId == this.userId && 
                             stage > VisitationCoachStage.VisitSchool && 
                             stage < VisitationCoachStage.Completed;
        const options = {
            ...form,
            decoratorOptions: {
                validateFirst: false,
                validateTrigger: null
            }
        }
        return GLGlobal.isActionValid(GSAdminAction.VisitSchool) ?
        <div className={`visit-school info-container ${disabled&&"disabled"}`}>
            <Spin spinning={spinning}>
            <GLForm>
                <FormItemsLayout>
                <Row className="school-notes">
                    {renderFormItem(options, SchoolLocale.VisitationSchoolNotesNew, "schoolnotes", 
                            disabledInput ? <Input.TextArea rows={6} disabled /> : <Input.TextArea rows={6} />, note, false, this.lengthValidator(SchoolLocale.VisitationSchoolNotes, 2000))}
                </Row>
                <LazyPortal selector=".school-notes .ant-form-item>div:first-child">
                    {showEditIcon && !showIconButton &&
                        <a className="school-notes-edit-button" 
                            onClick={(e) => this.setIsShowIconButton(true)}>
                            <Icon type="edit" />
                        </a>
                    }
                    {showIconButton &&
                        <IconButton
                            onCancel = {(e) => this.setIsShowIconButton(false)}
                            onSave = {(e) => this.updateSchoolVisitNote(e)}
                        />
                    }
                </LazyPortal>
                </FormItemsLayout>
                {!disabled && isCoachAndSelf(GSSchoolAction.MoveNext, coachId) &&
                <SaveCancelBtns saveTitle={SchoolLocale.VisitationMoveNextText} 
                    onSubmit={(e) => this.onSubmit(e, false)}
                    cancelTitle={SchoolLocale.VisitationSaveDraft} 
                    onCancel={(e) => this.onSubmit(e, true)} />}
            </GLForm>
            </Spin>
        </div> :
        null;
    }
}