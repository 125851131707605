import * as React from "react";
import { Spin } from "antd-min";
import "./loading.less";
import { mergeClasses } from "@app/util";
import { SpinProps } from "antd/lib/spin";

interface LoadingProps extends React.Props<LoadingProps> {
    visible: boolean;
    text?: string;
    cover?: boolean;
    height?: number | string;
    className?: string;
    iconClassName?: string;
    maxHeight?: number | string;
    spinProps?: SpinProps;
}

export const Loading: React.FC<LoadingProps> = (props) => {
    const { visible, text, cover, height, iconClassName, className, maxHeight, spinProps } = props;
    const classes = mergeClasses("sw-loader", cover && "sw-loader--cover", className);
    return visible ? (
        <div className={classes} style={{ maxHeight, height }}>
            <Spin spinning={true} className={mergeClasses("sw-loader__icon", iconClassName)} {...spinProps} />
            {text && <p className="sw-loader__text">{text}</p>}
        </div>
    ) : null;
};

Loading.defaultProps = {
    spinProps: {}
}
