import { IChangeLogService } from './interface';
import { GLServiceBase } from 'gl-commonui';
import { injectable } from '../../../util/di'

@injectable()
export class ChangeLogService extends GLServiceBase<any, any> implements IChangeLogService {
    serviceRoute = { prefix: 'admin/v1/ChangeInfo' }

    getAllChangeReleaseInfo(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo' }
        return this.get(param);
    }
    getChangeReleaseInfoById(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo' }
        return this.get('{releaseId}', param);
    }
    addChangeReleaseInfo(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo' }
        return this.create(param);
    }
    editChangeReleaseInfo(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo' }
        return this.update(param);
    }
    deleteThenAddChangeReleaseInfo(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo/DeleteThenAddChangeReleaseInfo' };
        return this.create(param);
    }
    isReleaseNameExists(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo/IsReleaseNameExists' };
        return this.get(param);
    }

    getChangeArea() {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo/GetChangeArea' };
        return this.get();
    }
    getChangeType() {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo/GetChangeType' };
        return this.get();
    }
    getJiraIssues(param) {
        this.serviceRoute = { prefix: 'admin/v1/ChangeInfo/GetJiraIssues' }
        return this.get(param);
    }
}