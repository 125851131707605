import { GLResponse, GLRequest, GLPagingRequest, InvitationType } from 'gl-commonui';
import { InvitationChannel, InvitationListStates } from '@app/util';

export interface InvitationModel {
}
export interface InvitationsRequest {
    referenceId: string
    invitationCodeTypes: InvitationType[]
}
export interface InvitationRequest extends GLRequest, InvitationModel {
    id?: string
    code?: string
    creationDate?: string
    invitationType?: InvitationType[],
    email?: string
    name?: string
    senderId?: string
    acceptedDate?: string
    referenceId?: string
    userId?: string
}
export interface InvitationResponse extends GLResponse, InvitationModel {
    id?: string
    invitationCode?: string
    schoolClass?: IdentityModel,
    school?: IdentityModel,
    teacher?: TeacherIdentityModel[],
    enablePhoneRegistration?: boolean;
    invitationChannel: InvitationChannel;
}

export interface InvitationInfo extends InvitationResponse, InvitationRequest {
    schoolCampus?: IdentityModel,
    language?: string,
    status?: string,
}

export interface IdentityModel {
    id?: string
    name?: string
}
export interface TeacherIdentityModel extends IdentityModel {
    gender?: boolean
}
export interface InvitationCodeValid {
    valid: boolean
}
export interface CodeConsumerId {
    codeId?: string
    consumerId?: string
}
export interface UserModel {
    id?: string
    userName?: string
    password?: string
    email?: string,
    gender?: string
    birthday?: string
    regionId?: string
    roleNames?: string[]
}

export enum RegisterModelProps {
    userName = "userName",
    englishName = "englishName",
    email = "email",
    password = "password",
    confirmPassword = "confirmPassword"
}

export enum InvitationStatus {
    Created,
    Accepted,
    Expired
}

export interface ResourceEntityModel {
    userId: string
    entityTypeId: number
    entityId: string
    roleEnumId: number
}

export interface InvitationSelectModel {
    id: string;
    code: string;
    email?: string;
    phone?: string;
    invitationStatus: InvitationListStates;
    type: InvitationType;
    isEmailed: boolean;
    isPrinted: boolean;
    isSMSSent: boolean;
    referenceId: string;
}

export interface invitationCodeModel {
    codeId: string;
}

interface invitationRequestModel {
    invitationTemplateId: string;
    invitations: invitationCodeModel[];
    isReGenerateOrEmail: boolean;
}
export interface downloadInvitationsRequestModel extends invitationRequestModel {
}

export interface emailInvitationsRequestModel extends invitationRequestModel {
    invitationChannel: InvitationChannel;
}


