import { GLResponse,GLRequest,GLPagingRequest } from 'gl-commonui';

export interface RegionGroupModel {
    id?: string
    name?: string
}

export enum RegionGroupPropNames{
    id = 'id',
    name = 'name'
}

export class GetRegionGroupRequest extends GLPagingRequest {
}
export interface RegionGroupRequest extends GLRequest, RegionGroupModel {
}
export interface RegionGroupResponse extends GLResponse, RegionGroupModel {
}