export interface ClassManagerState {
    moveStudentDisable: boolean;
}

const initialState = {
    moveStudentDisable: false
};

export default {
    namespace: "classManager",
    state: initialState,
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setMoveStudentsStatus(state, { payload }) {
            return { ...state, moveStudentDisable: payload };
        }
    },
    effects: {},
    services: {}
};
export function reload(state) {
    return { type: "classManager/reload", payload: state };
}

export function setMoveStudentsStatus(payload) {
    return { type: "classManager/setMoveStudentsStatus", payload };
}
