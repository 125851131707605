import { GLServiceBase, ServiceRoute } from "gl-commonui";
import { StudentAccessibilityModel, CanEditUserModel } from ".";
import { IPermissionService } from "./interface";

export class PermissionService extends GLServiceBase<any, any> implements IPermissionService  {
    serviceRoute: ServiceRoute = { prefix: 'admin/v1/permissions' }

    isStudentAccessable(params: StudentAccessibilityModel) {
        return this.request.post(this.url('studentaccessable'), params);
    } 

    canEditUser(params: CanEditUserModel) {
        return this.request.get(this.url('canedituser'), params);
    } 

    canMangeStudent(params: any) {
        return this.request.post(this.url('canmanagestudent'), params);
    }
    
    isStudentVerificationAllowed(regionId: string): Promise<boolean> {
        return this.get("{id}/isstudentverificationallowed", {id: regionId});
    }
}