import { useEffect, useState } from "react";
import { EventArgs, Tooltip } from "wijmo/wijmo";
import { CellRangeEventArgs, FlexGrid, FormatItemEventArgs, Row, _NewRowTemplate } from "wijmo/wijmo.grid";
import { toggleClass } from "wijmo/wijmo";
import { last } from "lodash";
import { useWijmoGridValidCheck } from "./useWijmoGridValidCheck";

function flatGridColumns(columns) {
    return Array.isArray(columns)
        ? columns.reduce((flated, columns) => flated.concat(flatGridColumns(columns)), [])
        : [columns];
}

export function useWijmoValidators<T>(grid: FlexGrid, children: any[]): WijmoGridValidation {
    const [validators, setValidators] = useState<Validator>({});
    const tooltip = new Tooltip({ showDelay: 0, cssClass: "wj-error-tip" });

    const validCheck = useWijmoGridValidCheck(grid);

    useEffect(() => {
        const validators: Validator = {};
        flatGridColumns(children)
            .filter((child) => child && child.props && child.props.binding && child.props.validators && child.props.validators.length)
            .forEach((child) => {
                validators[child.props.binding] = child.props.validators;
            });
        setValidators(validators);
    }, [children]);

    useEffect(() => {
        if (grid) {
            grid.cellEditEnded.addHandler(cellEditEnded);
            grid.pastedCell.addHandler(pastedCell);
            grid.formatItem.addHandler(formatItem);
            grid.updatingView.addHandler(updatingView);
            grid.loadedRows.addHandler(loadedRows);
        }
    }, [grid]);

    // Validate when a cell is being edited
    const cellEditEnded = (grid: FlexGrid, event: CellRangeEventArgs) => {
        setError(grid, event.row, event.col);
        grid.invalidate();
    };

    // Validate when a cell being pasted
    const pastedCell = (grid: FlexGrid, event: CellRangeEventArgs) => {
        setError(grid, event.row, event.col);
        grid.invalidate();
    };

    // Vilidate a last row when new row has been created
    const loadedRows = (grid: FlexGrid, event: EventArgs) => {
        const lastRow = getLastRow(grid);
        if (lastRow) {
            setError(grid, lastRow.index, 0);
            grid.invalidate();
        }
    };

    const getLastRow = (grid: FlexGrid): Row => {
        if (grid.rows.length) {
            const lastRow = last(grid.rows);
            if (!(lastRow instanceof _NewRowTemplate)) {
                return lastRow;
            }
            if (grid.rows.length > 1) {
                return grid.rows[grid.rows.length - 2];
            }
        }
        return null;
    };

    // Sets errors to grid based on the validators passed in <Column /> components.
    const setError = (flex: FlexGrid, row: number, col: number): void => {
        const dataItem = grid.collectionView.items[row];
        flex.columns.forEach((column, columnIndex) => {
            const binding = grid.columns[columnIndex].binding;
            const columnValidators = validators[binding];
            if (columnValidators && columnValidators.length) {
                let errorMessage = null;
                columnValidators.every((validator) => {
                    const message = validator(dataItem[binding], dataItem, grid);
                    if (message) {
                        errorMessage = message;
                        return false;
                    }
                    return true;
                });
                if (!dataItem.errors) {
                    dataItem.errors = {};
                }
                if (errorMessage) {
                    dataItem.errors = { ...dataItem.errors, [binding]: errorMessage };
                } else if (dataItem.errors && dataItem.errors[binding]) {
                    delete dataItem.errors[binding];
                }
            }
        });
    };

    // Shows error tooltips
    const formatItem = (flex: FlexGrid, event: FormatItemEventArgs) => {
        if (!Object.keys(validators).length) {
            return false;
        }
        if (event.panel === flex.cells) {
            const dataItem = flex.rows[event.row].dataItem;
            const binding = flex.columns[event.col].binding;
            const error = dataItem && dataItem.errors && dataItem.errors[binding];
            toggleClass(event.cell, "wj-state-invalid", !!error);
            if (error) {
                tooltip.setTooltip(event.cell, error);
            }
        }
        if (event.panel === flex.rowHeaders) {
            const dataItem = flex.rows[event.row].dataItem;
            const hasErrors = dataItem && dataItem.errors && !!Object.keys(dataItem.errors).length;
            toggleClass(event.cell, "wj-state-invalid", !!hasErrors);
        }
    };

    // Disposing error tooltips
    const updatingView = (): void => {
        tooltip.dispose();
    };

    // A public method top trigger validation manually on all of rows.
    const validate = (): void => {
        grid.rows.forEach((row, index) => {
            if (!(row instanceof _NewRowTemplate)) {
                setError(grid, index, 0);
            }
        });
        grid.invalidate();
        validCheck.manualTrigger();
    };

    const validateRow = (rowIndex: number): void => {
        setError(grid, rowIndex, 0);
    };

    return { validate, isValid: validCheck.isValid, validateRow };
}

export interface Validator<T = any> {
    [key: string]: WijmoColumnValidatorFn<T>[];
}

export interface WijmoColumnValidatorFn<T = any> {
    (value: any, dataItem: T, flex: FlexGrid): string | null;
}

export interface WijmoGridValidation {
    validate: () => void;
    validateRow: (rowIndex: number) => void;
    isValid: boolean;
}