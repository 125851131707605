import { useService } from "@app/hooks";
import { MovePromoteStudentsLocale } from "@app/locales/localeid";
import { IRegionService } from "@app/service/admin/regions";
import {
	CampusDropModel,
	ClassDropModel,
	ISchoolClassService,
	MovedPromotedParentClassInfo,
	MovedPromotedStudentInfo,
	movePromoteInfoModel,
	movePromoteSaveModel,
	SchoolClassInfoModel,
	SchoolClassWithStudentsInfoModel,
	StudentsInfoModel,
	TermResponseModel
} from "@app/service/class";
import { ISchoolService } from "@app/service/schools";
import { setFutureAnnualOuter } from "@app/states/school/class";
import { deepClone, fmtMsg, StudentListItemSplitChar, StudentSubscriptionType, TYPES } from "@app/util";
import { Button, Col, Empty, Input, Modal, Row, Select, Switch } from "antd-min";
import {
	connect,
	GLLocale,
	GLRouteComponentProps,
	MessageHelper,
	NotificationType,
	StateType,
	withRouter,
	PathConfig as CommonPath
} from "gl-commonui";
import { Dropdown, Icon, Menu } from "gl-commonui/lib/antd-min";
import { Dictionary } from "lodash";
import groupBy from "lodash/groupBy";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { CampusSelector } from "./components/campus-selector";
import { extractDataFromListId, listIdStrGenerate, StudentDragDropRegion } from "./components/students-drag-drop-region";
import "./index.less";
import { checkboxClassName, checkboxSpanClassName } from "./components/consts";

const { Option } = Select;
interface MovePromoteStudentsProps extends GLRouteComponentProps {
	campuses: any;
	annualPrepComplete: boolean;
	setFutureAnnualOuter: (d) => void;
}

interface indexModel {
	id: string;
	index: number;
}

export enum MovePromotePageOperations {
	SourceCampusChange = 0,
	SourceClassChange = 1,
	DestinationCampusChange = 2,
	ActiveFutureSwitchChange = 3,
	ActiveDisableSwitchChange = 4,
	SourceClassTermChange = 5
}

export const MovePromoteStudents = withRouter(
	connect(
		({
			school: {
				current: { annualPrepComplete }
			}
		}: StateType) => ({ annualPrepComplete }), { setFutureAnnualOuter },
		({ state: { } }) => ({})
	)((props: MovePromoteStudentsProps & RouteComponentProps) => {
		const [isLoading, setIsLoading] = useState(false);
		const schoolService = useService<ISchoolService>(TYPES.ISchoolService);
		const schoolClassService = useService<ISchoolClassService>(TYPES.ISchoolClassService);
		const regionService = useService<IRegionService>(TYPES.IRegionService);
		const [campusList, setCampusList] = useState<CampusDropModel[]>([]);
		const [classList, setClassList] = useState<ClassDropModel[]>([]);
		const [sourceSelectedCampus, setSourceSelectedCampus] = useState<CampusDropModel>(null);
		const [destinationSelectedCampus, setDestinationSelectedCampus] = useState<CampusDropModel>(null);
		const [sourceSelectedClass, setSourceSelectedClass] = useState<ClassDropModel>(null);
		const [classLoading, setClassLoading] = useState<boolean>(false);
		const [notesValue, setNotesValue] = useState<string>(null);
		const [isFuture, setIsFuture] = useState<boolean>(false);
		const [pendingChangesCount, setPendingChangesCount] = useState<number>(0);
		const [masterSourceStudentClassData, setMasterSourceStudentClassData] =
			useState<SchoolClassWithStudentsInfoModel>(null);
		const [masterDestStudentClassData, setMasterDestStudentClassData] = useState<
			SchoolClassWithStudentsInfoModel[]
		>([]);
		const [sourceStudentClassData, setSourceStudentClassData] = useState<SchoolClassWithStudentsInfoModel>(null);
		const [destStudentClassData, setDestStudentClassData] = useState<SchoolClassWithStudentsInfoModel[]>([]);
		const [filteredDestStudentClassData, setFilteredDestStudentClassData] = useState<
			SchoolClassWithStudentsInfoModel[]
		>([]);
		const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
		const [draggingListId, setDraggingListId] = useState<string>(null);
		const [clasFilterKeyword, setClassFilterKeyword] = useState<string>("");
		const [isDestinationCampusRequired, setIsDestinationCampusRequired] = useState(false);
		const [isDisabled, setIsDisabled] = useState(false);
		const [termLoading, setTermLoading] = useState<boolean>(false);
		const [masterTermList, setMasterTermList] = useState<TermResponseModel[]>([]);
		const [termList, setTermList] = useState<TermResponseModel[]>([]);
		const [sourceSelectedClassTerm, setSourceSelectedClassTerm] = useState<TermResponseModel>(null);
		const [masterSourceClassList, setMasterSourceClassList] = useState<ClassDropModel[]>([]);

		useEffect(() => {
			const { annualPrepComplete } = props;
			if (annualPrepComplete) {
				props.history.push({ pathname: CommonPath.AccessDenied });
			}
		}, [props.annualPrepComplete]);

		useEffect(() => {
			window.addEventListener("click", onWindowClick);
			window.addEventListener("keydown", onWindowKeyDown);
			window.addEventListener("touchend", onWindowTouchEnd);

			const {
				match: { params }
			} = props;
			//It will obtain the class information that has been used in the breadcrumb.
			props.setFutureAnnualOuter(params);

			setScroll(true);

			return () => {
				window.removeEventListener("click", onWindowClick);
				window.removeEventListener("keydown", onWindowKeyDown);
				window.removeEventListener("touchend", onWindowTouchEnd);

				setScroll();
			};
		}, []);

		useEffect(() => {

			const {
				match: { params }
			} = props;
			const { regionId, schoolId, campusId, classId } = params;
			getRegionTerms(regionId);
			getAccessibleCampusList(schoolId, campusId, classId);
		}, [isDisabled]);

		useEffect(() => {
			if (isDestinationCampusRequired) {
				const {
					match: { params }
				} = props;
				const { campusId } = params;

				if (campusList.length > 0 && campusList.find((c) => c.id === campusId)) {
					onCampusSelected(false, campusId);
				}
			}
		}, [isDestinationCampusRequired]);

		useEffect(() => {
			let pendingChangesCountVal = 0;
			if (sourceStudentClassData) {
				pendingChangesCountVal = getPendingChangesCountValue(
					sourceStudentClassData.schoolClassInfo.id,
					sourceStudentClassData.students
				);
			}

			destStudentClassData.forEach((dest) => {
				pendingChangesCountVal =
					pendingChangesCountVal + getPendingChangesCountValue(dest.schoolClassInfo.id, dest.students);
			});

			setPendingChangesCount(pendingChangesCountVal);
		}, [sourceStudentClassData, destStudentClassData]);

		useEffect(() => {
			const filteredClasses = destStudentClassData.filter((studentClass) => {
				const { age, currentUnit, name } = studentClass.schoolClassInfo;
				const combinedString = name + age + currentUnit;
				return combinedString.toLocaleLowerCase().indexOf(clasFilterKeyword.toLowerCase()) !== -1;
			});

			setFilteredDestStudentClassData(filteredClasses);
		}, [clasFilterKeyword, destStudentClassData]);

		const setScroll = (mount?: boolean): void => {
			const main = document.getElementsByClassName("gl-main") as any as HTMLElement[];

			if (!main.length) {
				return;
			}

			if (mount) {
				main[0].style.flex = "auto";
				main[0].style.overflow = "hidden";
			} else {
				main[0].style.flex = null;
				main[0].style.overflow = null;
			}
		};

		const onWindowKeyDown = (event: KeyboardEvent) => {
			if (event.defaultPrevented) {
				return;
			}

			if (event.key === "Escape") {
				unselectAll();
			}
		};

		const onWindowClick = (event: any) => {
			let selectAllCheckboxClassName = "";
			if (event.target.labels && event.target.labels[0] && event.target.labels[0].className) {
				selectAllCheckboxClassName = event.target.labels[0].className;
			}
			if (event.defaultPrevented || event.target.dataset.selectAll || event.target.className == checkboxSpanClassName || selectAllCheckboxClassName.includes(checkboxClassName)) {
				return;
			}
			unselectAll();
		};

		const onWindowTouchEnd = (event: TouchEvent) => {
			if (event.defaultPrevented) {
				return;
			}
			unselectAll();
		};

		const unselectAll = () => {
			setSelectedListIds([]);
		};
		const toggleSelection = (listId: string) => {
			const selectedListIdsInfo: string[] = selectedListIds;
			const wasSelected: boolean = selectedListIdsInfo.includes(listId);

			const newListIds: string[] = (() => {
				// Task was not previously selected
				// now will be the only selected item
				if (!wasSelected) {
					return [listId];
				}

				// Task was part of a selected group
				// will now become the only selected item
				if (selectedListIdsInfo.length > 1) {
					return [listId];
				}

				// task was previously selected but not in a group
				// we will now clear the selection
				return [];
			})();

			setSelectedListIds(newListIds);
		};

		const multiSelect = (newListId: string): string[] => {
			// Nothing already selected
			const copyOfselectedListIds = deepClone(selectedListIds);
			if (!copyOfselectedListIds.length) {
				return [newListId];
			}

			const ListIdSplitInfo = newListId.split(StudentListItemSplitChar);
			const newSelectedClassId = ListIdSplitInfo[0];
			const newSelectedstudentId = ListIdSplitInfo[1];

			const classOfNew: SchoolClassWithStudentsInfoModel = getClassInfo(newSelectedClassId);
			const indexOfNew: number = classOfNew.students.findIndex((s) => s.id === newSelectedstudentId);

			const lastSelected: string = copyOfselectedListIds[copyOfselectedListIds.length - 1];
			const lastSelectedSplitInfo = lastSelected.split(StudentListItemSplitChar);
			const lastSelectedClassId = lastSelectedSplitInfo[0];
			const lastSelectedstudentId = lastSelectedSplitInfo[1];

			const classOfLast: SchoolClassWithStudentsInfoModel = getClassInfo(lastSelectedClassId);
			const indexOfLast: number = classOfLast.students.findIndex((s) => s.id === lastSelectedstudentId);

			const classOfNewCopy = deepClone(classOfNew);
			// multi selecting to another class
			// select everything up to the index of the current item
			if (classOfNew.schoolClassInfo.id !== classOfLast.schoolClassInfo.id) {
				return classOfNewCopy.students
					.slice(0, indexOfNew + 1)
					.map((s) => classOfNewCopy.schoolClassInfo.id + StudentListItemSplitChar + s.id);
			}

			// multi selecting in the same class
			// need to select everything between the last index and the current index inclusive

			// nothing to do here
			if (indexOfNew === indexOfLast) {
				return null;
			}

			const isSelectingForwards: boolean = indexOfNew > indexOfLast;
			const start: number = isSelectingForwards ? indexOfLast : indexOfNew;
			const end: number = isSelectingForwards ? indexOfNew : indexOfLast;

			const inBetween = classOfNewCopy.students
				.slice(start, end + 1)
				.map((s) => classOfNewCopy.schoolClassInfo.id + "$" + s.id);

			// everything in between needs to have it's selection toggled.
			// with the exception of the start and end values which will always be selected

			const toAdd = inBetween.filter((listId): boolean => {
				// if already selected: then no need to select it again
				if (copyOfselectedListIds.includes(listId)) {
					return false;
				}
				return true;
			});

			const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
			const combined = [...copyOfselectedListIds, ...sorted];

			return combined;
		};

		// This behaviour matches the MacOSX finder selection
		const multiSelectTo = (newListId: string) => {
			const updated: string[] = multiSelect(newListId);

			if (updated == null) {
				return;
			}

			setSelectedListIds(updated);
		};

		const toggleSelectionInGroup = (listId: string) => {
			const selectedTaskIdsInfo: string[] = selectedListIds;
			const index: number = selectedTaskIdsInfo.indexOf(listId);

			// if not selected - add it to the selected items
			if (index === -1) {
				setSelectedListIds([...selectedTaskIdsInfo, listId]);
				return;
			}

			// it was previously selected and now needs to be removed from the group
			const shallow: string[] = [...selectedTaskIdsInfo];
			shallow.splice(index, 1);
			setSelectedListIds(shallow);
		};

		const resetScroll = () => {
			const wrapper = document.querySelector(".right-drag-region");

			if (wrapper) {
				wrapper.scroll({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
			}
		};

		const onDragStart = (start) => {
			const id: string = start.draggableId;
			const selected = selectedListIds.find((listId): boolean => listId === id);

			// if dragging an item that is not selected - unselect all items
			if (!selected) {
				unselectAll();
			}

			setDraggingListId(start.draggableId);
		};

		const getAccessibleCampusList = (schoolId, campusId, classId) => {
			schoolService.getAccessibleCampuses(schoolId, false).then((campuses) => {
				setCampusList(campuses);
				if (campuses.length > 0) {
					const currentCampus = campuses.find((c) => c.id === campusId);
					const campus = currentCampus ? currentCampus : campuses[0];
					setSourceSelectedCampus(campus);
					getAccessibleClassList(campus.id, classId, isDisabled);
				}
			});
		};

		const getAccessibleClassList = (campusId, classId, isDisabled) => {
			setClassLoading(true);
			schoolClassService.getAccessibleClassesWithoutFuture(campusId, isDisabled).then((classesInfo) => {
				const classes = classesInfo.data;
				setClassList(classes);
				setMasterSourceClassList(classes);
				if (isDisabled) {
					const currentTermList = masterTermList.filter((x) => classes.some((y) => y.termId === x.id));
					setTermList(currentTermList);
					getClassTerms(campusId, currentTermList, classes);
				} else {
					if (classes.length > 0) {
						let currentClass = classes[0];
						if (classId) {
							const selectedClass = classes.find((c) => c.id === classId);
							if (selectedClass) {
								currentClass = selectedClass;
							}
						}
						setSourceSelectedClass(currentClass);
						getClassesAndStudents(campusId, false, isDisabled, currentClass.id);
						// const destCampusId = destinationSelectedCampus ? destinationSelectedCampus.id : null;
						// filterAndDestClasses(masterDestStudentClassData, campusId, destCampusId, currentClass.id);
					} else {
						setSourceSelectedClass(null);
						setSourceStudentClassData(null);
						setMasterSourceStudentClassData(null);
						setDestStudentClassData(deepClone(masterDestStudentClassData));
					}
				}
				setClassLoading(false);

				if (!isDestinationCampusRequired) {
					setIsDestinationCampusRequired(true);
				}
			});
		};

		const getRegionTerms = (regionId) => {
			setTermLoading(true);
			regionService.getRegionTerms(regionId).then((result) => {
				setMasterTermList(result); // set master term list
				setTermLoading(false);
			}).catch((err) => {
				setTermLoading(false);
			});
		};

		const getClassTerms = (campusId, termList, masterSourceClassList) => {
			setTermLoading(true);
			// adding id=null because there are classes with no term
			termList.push({ id: null, name: "None" });
			let currentClassTerm = termList[0];
			setSourceSelectedClassTerm(currentClassTerm);
			if (!masterSourceClassList.length) {
				setSourceSelectedClass(null);
				setSourceStudentClassData(null);
				setTermLoading(false);
				return;
			}
			const filteredClasses = masterSourceClassList.filter((c) => c.termId === currentClassTerm.id);
			setClassList(filteredClasses);
			const currentClass = filteredClasses[0];
			setSourceSelectedClass(currentClass);
			getClassesAndStudents(campusId, false, isDisabled, currentClass.id);
			setTermLoading(false);
		};

		const getClassesAndStudents = (campusId, isFuture, isDisabled, classId?) => {
			schoolClassService
				.getClassesAndStudentsForMoveAndPromote(campusId, isFuture, isDisabled, classId)
				.then((data) => {
					const extendedData = data.map((obj) => {
						obj.students = obj.students.map((stuObj) => ({
							...stuObj,
							isMoved: false,
							fromClassId: null,
							isPromoted: false,
							isInPromotion: false,
							isPending: false,
							isPromotedToClassId: null,
							isPromotedToClassName: null,
							isPromotedToCampusId: null,
							isPromotedToCampusName: null,
							isDisabled: isDisabled
						}));
						return obj;
					});

					if (classId) {
						if (data.length > 0) {
							setMasterSourceStudentClassData(deepClone(extendedData[0]));
							setSourceStudentClassData(deepClone(extendedData[0]));
							const destCampusId = destinationSelectedCampus ? destinationSelectedCampus.id : null;
							filterAndDestClasses(masterDestStudentClassData, campusId, destCampusId, classId);
						}
					} else {
						setMasterDestStudentClassData(deepClone(extendedData));
						const sourceCampusId = sourceSelectedCampus ? sourceSelectedCampus.id : null;
						const sourceClassId = sourceSelectedClass ? sourceSelectedClass.id : null;
						filterAndDestClasses(extendedData, sourceCampusId, campusId, sourceClassId);
					}
				});
		};

		const filterAndDestClasses = (
			masterDestData: SchoolClassWithStudentsInfoModel[],
			sourceCampusId,
			destCampusId,
			sourceSelectedClassId
		) => {
			const clonedMasterData = deepClone(masterDestData);
			if (sourceCampusId && destCampusId && destCampusId === destCampusId) {
				const selectedClassInfoIndex = sourceSelectedClassId
					? clonedMasterData.findIndex((s) => s.schoolClassInfo.id === sourceSelectedClassId)
					: -1;
				if (selectedClassInfoIndex > -1) {
					clonedMasterData.splice(selectedClassInfoIndex, 1);
				}
			}

			if (destCampusId) {
				setDestStudentClassData(clonedMasterData);
			}
		};

		const onCampusSelected = (isSourceClass, campusId) => {
			const campus = campusList.find((c) => c.id === campusId);
			if (campus) {
				if (isSourceClass) {
					setSourceSelectedCampus(campus);
					getAccessibleClassList(campusId, null, isDisabled);
				} else {
					setDestinationSelectedCampus(campus);
					getClassesAndStudents(campusId, isFuture, false);
					resetScroll();
				}
			}
		};

		const onClassSelected = (classId) => {
			const classInfo = classList.find((c) => c.id === classId);
			if (classInfo) {
				setSourceSelectedClass(classInfo);
				if (sourceSelectedCampus) {
					getClassesAndStudents(sourceSelectedCampus.id, false, isDisabled, classId);
					// const destCampusId = destinationSelectedCampus ? destinationSelectedCampus.id : null;
					// const sourceCampusId = sourceSelectedCampus ? sourceSelectedCampus.id : null;
					// filterAndDestClasses(masterDestStudentClassData, sourceCampusId, destCampusId, classId);
				}
			}
		};

		const onNotesChange = (noteVal) => {
			setNotesValue(noteVal.target.value);
		};

		const onDragEnd = (result: DropResult) => {
			const destination = result.destination;
			const source = result.source;

			// nothing to do
			if (!destination || result.reason === "CANCEL") {
				setDraggingListId(null);
				return;
			}

			mutliDragAwareReorder(source, destination);
			setDraggingListId(null);
		};

		const getSelectedStudentIds = (selectedListIdsCopy: string[]) => {
			return selectedListIdsCopy.map((s) => {
				return s.split(StudentListItemSplitChar)[1];
			});
		};

		const mutliDragAwareReorder = (source, dest) => {
			if (selectedListIds.length > 1) {
				reorderMultiDrag(source, dest);
				return;
			}
			reorderSingleDrag(source, dest);
		};

		const getClassInfo = (classId) => {
			return sourceStudentClassData && sourceStudentClassData.schoolClassInfo.id === classId
				? sourceStudentClassData
				: destStudentClassData.find((s) => s.schoolClassInfo.id === classId);
		};

		const isPromotionRestricted = (alreadyPromotedStudents: string[]) => {
			if (alreadyPromotedStudents.length > 0) {
				MessageHelper.Message(
					NotificationType.Warning,
					fmtMsg({ id: MovePromoteStudentsLocale.RestrictAlreadyPromotedMessage })
				);
				return true;
			}
			return false;
		};

		const isPromotionRevertRestricted = (alreadyPromotedMovedStudents: string[]) => {
			if (alreadyPromotedMovedStudents.length > 0) {
				MessageHelper.Message(
					NotificationType.Warning,
					fmtMsg({ id: MovePromoteStudentsLocale.RestrictAlreadyPromotedMessage })
				);
				return true;
			}
			return false;
		};

		const isDragRestricted = (
			finalClassWithStudents: SchoolClassWithStudentsInfoModel,
			sourceClassWithRemovedStudents: SchoolClassWithStudentsInfoModel
		) => {
			const finalClassStudentsFromSource = finalClassWithStudents.students.filter(
				(s) => s.fromClassId === sourceClassWithRemovedStudents.schoolClassInfo.id
			);

			if (
				finalClassStudentsFromSource.length > 0 &&
				!isFutureClass(finalClassWithStudents.schoolClassInfo) &&
				isFutureClass(sourceClassWithRemovedStudents.schoolClassInfo)
			) {
				MessageHelper.Message(
					NotificationType.Warning,
					fmtMsg({ id: MovePromoteStudentsLocale.RestrictFutureCurrentMessage })
				);
				return true;
			}

			const movedFromActive = finalClassWithStudents.students.filter((x) => x.isMoved === true);
			if (movedFromActive.length > 0 && isClassDisbled(finalClassWithStudents.schoolClassInfo)) {
				MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: MovePromoteStudentsLocale.RestrictActiveToDisableWarningMessage }));
				return true;
			}

			return false;
		};

		const reorderMultiDrag = (source, destination) => {
			const start: SchoolClassWithStudentsInfoModel = getClassInfo(source.droppableId);

			const dragged: StudentsInfoModel = start.students[source.index];

			const selectedListIdsCopy: string[] = deepClone(selectedListIds);
			const insertAtIndex: number = (() => {
				const destinationIndexOffset: number = selectedListIdsCopy.reduce(
					(previous: number, current: string): number => {
						const currentSplitValue = current.split(StudentListItemSplitChar);
						const currentClassId = currentSplitValue[0];
						const currentStudentId = currentSplitValue[1];

						if (currentStudentId === dragged.id) {
							return previous;
						}

						const final: SchoolClassWithStudentsInfoModel = getClassInfo(destination.droppableId);

						const currentClassInfo: SchoolClassWithStudentsInfoModel = getClassInfo(currentClassId);

						if (currentClassInfo.schoolClassInfo.id !== final.schoolClassInfo.id) {
							return previous;
						}

						const index: number = currentClassInfo.students.findIndex((s) => s.id === currentStudentId);

						if (index >= destination.index) {
							return previous;
						}

						// the selected item is before the destination index
						// we need to account for this when inserting into the new location
						return previous + 1;
					},
					0
				);

				const result: number = destination.index - destinationIndexOffset;
				return result;
			})();

			// doing the ordering now as we are required to look up classes
			// and know original ordering
			const orderedSelectedListIds: string[] = [...selectedListIdsCopy];
			orderedSelectedListIds.sort((a: string, b: string): number => {
				const aSplitValue = a.split(StudentListItemSplitChar);
				const aClassId = aSplitValue[0];
				const aStudentId = aSplitValue[1];

				const bSplitValue = b.split(StudentListItemSplitChar);
				const bClassId = bSplitValue[0];
				const bStudentId = bSplitValue[1];

				// moving the dragged item to the top of the list
				if (aStudentId === dragged.id) {
					return -1;
				}
				if (bStudentId === dragged.id) {
					return 1;
				}

				// sorting by their natural indexes
				const classInfoForA: SchoolClassWithStudentsInfoModel = getClassInfo(aClassId);
				const indexOfA: number = classInfoForA.students.findIndex((s) => s.id === aStudentId);
				const classInfoForB: SchoolClassWithStudentsInfoModel = getClassInfo(bClassId);
				const indexOfB: number = classInfoForB.students.findIndex((s) => s.id === bStudentId);

				if (indexOfA !== indexOfB) {
					return indexOfA - indexOfB;
				}

				// sorting by their order in the selectedListIds list
				return -1;
			});

			const studentIdsSelected = getSelectedStudentIds(selectedListIdsCopy);

			// we need to remove all of the selected students from their class
			const sourceClassWithRemovedStudents: SchoolClassWithStudentsInfoModel = deepClone(
				getClassInfo(source.droppableId)
			);

			let indexVal = 0;
			const sourceIndexData = sourceClassWithRemovedStudents.students.map((s) => {
				const data: indexModel = {
					id: s.id,
					index: indexVal
				};
				indexVal = indexVal + 1;
				return data;
			});

			const studentsAfterRemove: StudentsInfoModel[] = sourceClassWithRemovedStudents.students.filter(
				(s) => !studentIdsSelected.includes(s.id)
			);
			let removedStudents: StudentsInfoModel[] = sourceClassWithRemovedStudents.students.filter((s) =>
				studentIdsSelected.includes(s.id)
			);
			sourceClassWithRemovedStudents.students = studentsAfterRemove;

			const finalClassWithStudents: SchoolClassWithStudentsInfoModel =
				source.droppableId === destination.droppableId
					? deepClone(sourceClassWithRemovedStudents)
					: deepClone(getClassInfo(destination.droppableId));

			const reversedPromotionStudents = [];
			const alreadyPromotedStudents = [];
			const alreadyPromotedMovedStudents = [];
			const promoteAlreadyPromotedStudents: StudentsInfoModel[] = [];
			const movedPromotedStudents: MovedPromotedStudentInfo[] = [];

			removedStudents = removedStudents.map((st) => {
				if (
					isMovement(
						sourceClassWithRemovedStudents.schoolClassInfo.id,
						finalClassWithStudents.schoolClassInfo.id
					)
				) {
					if (
						isPromotion(
							sourceClassWithRemovedStudents.schoolClassInfo,
							finalClassWithStudents.schoolClassInfo
						)
					) {
						if (isInPromotion(st)) {
							st = moveAlreadyPromotedListItem(st, sourceClassWithRemovedStudents.schoolClassInfo.id);
							promoteAlreadyPromotedStudents.push(deepClone(st));
						} else {
							st = getPromotionListItem(st, sourceClassWithRemovedStudents.schoolClassInfo.id);
						}

						const promotedItem = sourcePromotedListItem(st, finalClassWithStudents.schoolClassInfo);
						const indexAtVal = sourceIndexData.find((s) => s.id === st.id);
						let indexAt = 0;
						if (indexAtVal) {
							indexAt = indexAtVal.index;
						}
						sourceClassWithRemovedStudents.students.splice(indexAt, 0, promotedItem);
					} else {
						if (
							isBackToOriginal(finalClassWithStudents.schoolClassInfo.id, st.fromClassId) ||
							isBackToOriginal(finalClassWithStudents.schoolClassInfo.id, st.promotedFromClassId)
						) {
							if (isResetPromotion(st, finalClassWithStudents.schoolClassInfo.id)) {
								reversedPromotionStudents.push(st.id); // in case of reset promotion
							}
							st = backToOriginalListItem(st);
						} else {
							if (isInPromotion(st)) {
								alreadyPromotedStudents.push(st.id);
							}

							if (
								!isRevertingToOtherCurrent(
									st,
									sourceClassWithRemovedStudents.schoolClassInfo,
									finalClassWithStudents.schoolClassInfo
								)
							) {
								alreadyPromotedMovedStudents.push(st.id);
							}

							st = moveListItem(st, sourceClassWithRemovedStudents.schoolClassInfo.id);

							if (!st.isMoved) {
								movedPromotedStudents.push(
									getMovedPromotedStudentInfo(st, finalClassWithStudents.schoolClassInfo)
								);
							}
						}
					}
				}
				return st;
			});

			if (isPromotionRestricted(alreadyPromotedStudents)) {
				return;
			}

			if (isPromotionRevertRestricted(alreadyPromotedMovedStudents)) {
				return;
			}

			// restrict promotion where already promoted
			if (isPromotionToSame(promoteAlreadyPromotedStudents, finalClassWithStudents.students)) {
				return;
			}

			finalClassWithStudents.students.splice(insertAtIndex, 0, ...removedStudents);

			if (reversedPromotionStudents.length > 0) {
				finalClassWithStudents.students = filterReversePromotion(
					finalClassWithStudents.students,
					reversedPromotionStudents
				);
			}

			updateLicenseInfo(
				sourceClassWithRemovedStudents.schoolClassInfo,
				finalClassWithStudents.schoolClassInfo,
				removedStudents,
				reversedPromotionStudents
			);

			const updatedSourceClassData: SchoolClassWithStudentsInfoModel = {
				schoolClassInfo: sourceClassWithRemovedStudents.schoolClassInfo,
				students: sourceClassWithRemovedStudents.students
			};

			const updatedDestClassData: SchoolClassWithStudentsInfoModel = {
				schoolClassInfo: finalClassWithStudents.schoolClassInfo,
				students: finalClassWithStudents.students
			};

			if (isDragRestricted(updatedDestClassData, updatedSourceClassData)) {
				return;
			}
			updateSelectedListIds(source, destination);
			updateRequiredStudentClassData(
				updatedSourceClassData,
				updatedDestClassData,
				promoteAlreadyPromotedStudents,
				movedPromotedStudents
			);
			showAlreadyPromotedWarning(promoteAlreadyPromotedStudents);
		};

		const updateSelectedListIds = (source, destination) => {
			const sourceSchoolClassId = source.droppableId;
			const destinationSchoolClassId = destination.droppableId;
			if (sourceSchoolClassId === destinationSchoolClassId) return;
			const sourceAndDestinationSelectedListIds = selectedListIds.filter(listId => {
				const { schoolClassId } = extractDataFromListId(listId)
				return schoolClassId === sourceSchoolClassId || schoolClassId === destinationSchoolClassId;
			}).map(listId => {
				const { studentId } = extractDataFromListId(listId);
				return listIdStrGenerate(destinationSchoolClassId, studentId)
			});
			setSelectedListIds([...new Set(sourceAndDestinationSelectedListIds)])
		}

		// a little function to help us with reordering the result
		const reorder = (list: StudentsInfoModel[], startIndex: number, endIndex: number): any[] => {
			const result = deepClone(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);

			return result;
		};

		const isSourceClass = (classId) => {
			if (sourceStudentClassData && sourceStudentClassData.schoolClassInfo.id === classId) {
				return true;
			}

			return false;
		};

		const getUpdatedDestDestClassData = (
			updatedData: SchoolClassWithStudentsInfoModel,
			destData: SchoolClassWithStudentsInfoModel[]
		) => {
			return destData.map((d) => {
				if (d.schoolClassInfo.id === updatedData.schoolClassInfo.id) {
					d = updatedData;
				}
				return d;
			});
		};

		const updateStudentsClassData = (updatedStudentClassData: SchoolClassWithStudentsInfoModel) => {
			if (isSourceClass(updatedStudentClassData.schoolClassInfo.id)) {
				setSourceStudentClassData(updatedStudentClassData);
			} else {
				const updatedDestInfo = destStudentClassData.map((d) => {
					if (d.schoolClassInfo.id === updatedStudentClassData.schoolClassInfo.id) {
						d = updatedStudentClassData;
					}
					return d;
				});

				setDestStudentClassData(updatedDestInfo);
			}
		};

		const getPendingChangesCountValue = (classId: string, studentsInfo: StudentsInfoModel[]) => {
			let pendingChangesCount = 0;

			studentsInfo.forEach((s) => {
				if (
					s.promotedToClassId !== classId &&
					(s.isPromoted ||
						s.isMoved ||
						s.isPending ||
						(s.promotedInClassId && classId !== s.promotedInClassId))
				) {
					pendingChangesCount = pendingChangesCount + 1;
				}
			});

			return pendingChangesCount;
		};

		const isMovement = (sourceClassId: string, destClassId: string) => {
			return sourceClassId !== destClassId;
		};

		const isPromotion = (sourceClass: SchoolClassInfoModel, destClass: SchoolClassInfoModel) => {
			return moment(sourceClass.startDate).isBefore(moment()) && moment(destClass.startDate).isAfter(moment());
		};

		const isFutureClass = (classInfo: SchoolClassInfoModel) => {
			return moment(classInfo.startDate).isAfter(moment());
		};

		const isClassDisbled = (classInfo: SchoolClassInfoModel) => {
			return classInfo.disabled;
		};

		const isBackToOriginal = (destClassId: string, fromClassId: string) => {
			if (!fromClassId) {
				return false;
			} else {
				return destClassId === fromClassId;
			}
		};

		const isFromClassId = (fromClassId: string) => {
			if (fromClassId) {
				return true;
			} else {
				return false;
			}
		};

		const getPromotionListItem = (listItem: StudentsInfoModel, sourceClassId: string) => {
			return {
				...listItem,
				isMoved: false,
				isPromoted: listItem.promotedFromClassId ? false : true,
				isInPromotion: false,
				isPending: false,
				fromClassId: isFromClassId(listItem.promotedFromClassId)
					? listItem.promotedFromClassId
					: !isFromClassId(listItem.fromClassId)
						? sourceClassId
						: listItem.fromClassId,
				isPromotedToClassId: null,
				isPromotedToClassName: null,
				isPromotedToCampusId: null,
				isPromotedToCampusName: null,
				promotedToClassId: null,
				promotedToClassName: null,
				promotedToCampusId: null,
				promotedToCampusName: null
			};
		};

		const isResetPromotion = (listItem: StudentsInfoModel, destClassId: string) => {
			return (
				listItem.isPromoted || (listItem.promotedFromClassId && listItem.promotedFromClassId === destClassId)
			);
		};

		const backToOriginalListItem = (listItem: StudentsInfoModel) => {
			return {
				...listItem,
				isMoved: false,
				isInPromotion: false,
				isPromoted: false,
				fromClassId: null,
				isPending: listItem.promotedInClassId ? true : false,
				isPromotedToClassId: null,
				isPromotedToClassName: null,
				isPromotedToCampusId: null,
				isPromotedToCampusName: null
			};
		};

		const isInPromotion = (listItem: StudentsInfoModel) => {
			return listItem.isInPromotion || listItem.isAlreadyInPromotion;
		};

		const isRevertingToOtherCurrent = (
			listItem: StudentsInfoModel,
			sourceClassInfo: SchoolClassInfoModel,
			destClassInfo: SchoolClassInfoModel
		) => {
			if (isFutureClass(sourceClassInfo) && isFutureClass(destClassInfo)) {
				return true;
			} else if (
				isFutureClass(sourceClassInfo) &&
				!isFutureClass(destClassInfo) &&
				(listItem.isInPromotion || listItem.fromClassId || listItem.promotedFromClassId)
			) {
				const requiredClassId = listItem.fromClassId ? listItem.fromClassId : listItem.promotedFromClassId;
				if (requiredClassId) {
					return requiredClassId === destClassInfo.id;
				} else {
					return true;
				}
			}
			return true;
		};

		const moveListItem = (listItem: StudentsInfoModel, souceClassId: string) => {
			return {
				...listItem,
				isMoved: listItem.isPromoted || listItem.promotedFromClassId ? false : true,
				isPending: false,
				fromClassId: isFromClassId(listItem.promotedFromClassId)
					? listItem.promotedFromClassId
					: !isFromClassId(listItem.fromClassId)
						? souceClassId
						: listItem.fromClassId
			};
		};

		const moveAlreadyPromotedListItem = (listItem: StudentsInfoModel, sourceClassId: string) => {
			return {
				...listItem,
				isMoved: false,
				isPending: false,
				isInPromotion: false,
				isAlreadyInPromotion: false,
				isPromoted: true,
				promotedFromClassId: listItem.promotedToClassId ? sourceClassId : null,
				promotedInClassId: listItem.promotedToClassId ? listItem.promotedToClassId : null,
				fromClassId: isFromClassId(listItem.promotedFromClassId)
					? listItem.promotedFromClassId
					: !isFromClassId(listItem.fromClassId)
						? sourceClassId
						: listItem.fromClassId
			};
		};

		const filterReversePromotion = (
			newForeignClassListItems: StudentsInfoModel[],
			reversedPromotionStudents: string[]
		) => {
			return newForeignClassListItems.filter(
				(s) =>
					!(
						(s.isInPromotion === true || s.isAlreadyInPromotion === true) &&
						reversedPromotionStudents.includes(s.id)
					)
			);
		};

		const sourcePromotedListItem = (listItem: StudentsInfoModel, destinationClass: SchoolClassInfoModel) => {
			return {
				...listItem,
				isMoved: false,
				isPending: false,
				isPromoted: false,
				isAlreadyInPromotion: listItem.isAlreadyInPromotion || listItem.promotedFromClassId ? true : false,
				isInPromotion: listItem.isAlreadyInPromotion ? false : listItem.promotedFromClassId ? false : true,
				fromClassId: null,
				promotedFromClassId: null,
				promotedInClassId: null,
				isPromotedToClassId: destinationClass.id,
				isPromotedToClassName: destinationClass.name,
				isPromotedToCampusId: destinationSelectedCampus.id,
				isPromotedToCampusName: destinationSelectedCampus.name
			};
		};

		const reorderSingleDrag = (source, destination) => {
			// moving in the same list
			if (source.droppableId === destination.droppableId) {
				const classInfo: SchoolClassWithStudentsInfoModel = getClassInfo(source.droppableId);

				const classInfoCopy: SchoolClassWithStudentsInfoModel = deepClone(classInfo);

				const reorderedStudents = reorder(classInfoCopy.students, source.index, destination.index);

				const updatedClassData: SchoolClassWithStudentsInfoModel = {
					schoolClassInfo: classInfoCopy.schoolClassInfo,
					students: reorderedStudents
				};
				updateStudentsClassData(updatedClassData);
			}
			// moving to a new list
			else {
				const homeClassInfo: SchoolClassWithStudentsInfoModel = getClassInfo(source.droppableId);
				const foreignClassInfo: SchoolClassWithStudentsInfoModel = getClassInfo(destination.droppableId);

				const homeClassCopyInfo: SchoolClassWithStudentsInfoModel = deepClone(homeClassInfo);
				const foreignClassCopyInfo: SchoolClassWithStudentsInfoModel = deepClone(foreignClassInfo);

				// the item of the list to be moved
				let listItem: StudentsInfoModel = deepClone(homeClassCopyInfo.students[source.index]);

				const newHomeClassListItems = [...homeClassCopyInfo.students];
				let newForeignClassListItems = [...foreignClassCopyInfo.students];
				const reversedPromotionStudents = [];
				const alreadyPromotedStudents = [];
				const alreadyPromotedMovedStudents = [];
				const promoteAlreadyPromotedStudents: StudentsInfoModel[] = [];
				const movedPromotedStudents: MovedPromotedStudentInfo[] = [];

				if (isMovement(homeClassCopyInfo.schoolClassInfo.id, foreignClassCopyInfo.schoolClassInfo.id)) {
					if (isPromotion(homeClassCopyInfo.schoolClassInfo, foreignClassCopyInfo.schoolClassInfo)) {
						if (isInPromotion(newHomeClassListItems[source.index])) {
							listItem = moveAlreadyPromotedListItem(listItem, homeClassCopyInfo.schoolClassInfo.id);
							promoteAlreadyPromotedStudents.push(deepClone(newHomeClassListItems[source.index]));
						} else {
							listItem = getPromotionListItem(listItem, homeClassCopyInfo.schoolClassInfo.id);
						}
						newHomeClassListItems[source.index] = sourcePromotedListItem(
							newHomeClassListItems[source.index],
							foreignClassCopyInfo.schoolClassInfo
						);
					} else {
						if (
							isBackToOriginal(foreignClassCopyInfo.schoolClassInfo.id, listItem.fromClassId) ||
							isBackToOriginal(foreignClassCopyInfo.schoolClassInfo.id, listItem.promotedFromClassId)
						) {
							if (isResetPromotion(listItem, foreignClassCopyInfo.schoolClassInfo.id)) {
								reversedPromotionStudents.push(listItem.id); // in case of reset promotion
							}
							listItem = backToOriginalListItem(listItem);
						} else {
							if (isInPromotion(listItem)) {
								alreadyPromotedStudents.push(listItem.id);
							}

							if (
								!isRevertingToOtherCurrent(
									listItem,
									homeClassCopyInfo.schoolClassInfo,
									foreignClassCopyInfo.schoolClassInfo
								)
							) {
								alreadyPromotedMovedStudents.push(listItem.id);
							}

							listItem = moveListItem(listItem, homeClassCopyInfo.schoolClassInfo.id);

							if (!listItem.isMoved) {
								movedPromotedStudents.push(
									getMovedPromotedStudentInfo(listItem, foreignClassCopyInfo.schoolClassInfo)
								);
							}
						}

						// remove from home class
						newHomeClassListItems.splice(source.index, 1);
					}
				}

				if (isPromotionRestricted(alreadyPromotedStudents)) {
					return;
				}

				if (isPromotionRevertRestricted(alreadyPromotedMovedStudents)) {
					return;
				}

				// restrict promotion where already promoted
				if (isPromotionToSame(promoteAlreadyPromotedStudents, newForeignClassListItems)) {
					return;
				}

				// add to foreign class
				newForeignClassListItems.splice(destination.index, 0, listItem);

				if (reversedPromotionStudents.length > 0) {
					newForeignClassListItems = filterReversePromotion(
						newForeignClassListItems,
						reversedPromotionStudents
					);
				}

				updateLicenseInfo(
					homeClassCopyInfo.schoolClassInfo,
					foreignClassCopyInfo.schoolClassInfo,
					[listItem],
					reversedPromotionStudents
				);

				const updatedHomeClassData: SchoolClassWithStudentsInfoModel = {
					schoolClassInfo: homeClassCopyInfo.schoolClassInfo,
					students: newHomeClassListItems
				};

				const updatedForeignClassData: SchoolClassWithStudentsInfoModel = {
					schoolClassInfo: foreignClassCopyInfo.schoolClassInfo,
					students: newForeignClassListItems
				};

				if (isDragRestricted(updatedForeignClassData, updatedHomeClassData)) {
					return;
				}

				const studentIdsSelected = getSelectedStudentIds(selectedListIds);

				updateSelectedListIds(source, destination);

				updateRequiredStudentClassData(
					updatedHomeClassData,
					updatedForeignClassData,
					promoteAlreadyPromotedStudents,
					movedPromotedStudents
				);
				showAlreadyPromotedWarning(promoteAlreadyPromotedStudents);
			}
		};

		const getMovedPromotedStudentInfo = (listItem: MovedPromotedStudentInfo, classInfo: SchoolClassInfoModel) => {
			const updatedItem: MovedPromotedStudentInfo = deepClone(listItem);
			updatedItem.movedToCampusId = destinationSelectedCampus.id;
			updatedItem.movedToCampusName = destinationSelectedCampus.name;
			updatedItem.movedToClassId = classInfo.id;
			updatedItem.movedToClassName = classInfo.name;
			return updatedItem;
		};

		const showAlreadyPromotedWarning = (promoteAlreadyPromotedStudents: StudentsInfoModel[]) => {
			const studentInfo: string[] = [];
			promoteAlreadyPromotedStudents.forEach((s) => {
				// const studentName = s.name;
				const campusName = s.isPromotedToCampusName ? s.isPromotedToCampusName : s.promotedToCampusName;
				const className = s.isPromotedToClassName ? s.isPromotedToClassName : s.promotedToClassName;
				studentInfo.push(`${campusName}:${className}`);
			});

			if (studentInfo.length > 0) {
				const studentCampusClass = studentInfo.join();
				MessageHelper.Message(
					NotificationType.Warning,
					fmtMsg(
						{ id: MovePromoteStudentsLocale.PreviousPromotionCanceledWarningMessage },
						{ studentCampusClass }
					)
				);
			}
		};

		const isPromotionToSame = (
			promoteAlreadyPromotedStudents: StudentsInfoModel[],
			newForeignClassListItems: StudentsInfoModel[]
		) => {
			if (promoteAlreadyPromotedStudents.length > 0) {
				const studentIds = promoteAlreadyPromotedStudents.map((y) => y.id);
				const studentsIdsToRestrict: string[] = [];
				newForeignClassListItems.forEach((s) => {
					if (studentIds.includes(s.id)) {
						studentsIdsToRestrict.push(s.id);
					}
				});

				if (studentsIdsToRestrict.length > 0) {
					const names = newForeignClassListItems
						.filter((x) => studentsIdsToRestrict.includes(x.id))
						.map((x) => x.name)
						.join();
					MessageHelper.Message(
						NotificationType.Warning,
						fmtMsg({ id: MovePromoteStudentsLocale.RestrictAlreadyPromotedInSameClassMessage }, { names })
					);
					return true;
				}
			}

			return false;
		};

		const managePromoteAlreadyPromotedStudents = (
			promoteAlreadyPromotedStudents: StudentsInfoModel[],
			destClassInfo: SchoolClassWithStudentsInfoModel[]
		) => {
			const clonedClassInfo: SchoolClassWithStudentsInfoModel[] = deepClone(destClassInfo);

			promoteAlreadyPromotedStudents.forEach((s) => {
				const promotedCampusId = s.isPromotedToCampusId ? s.isPromotedToCampusId : s.promotedToCampusId;
				const promotedClassId = s.isPromotedToClassId ? s.isPromotedToClassId : s.promotedToClassId;
				if (promotedCampusId === destinationSelectedCampus.id) {
					const classInfoIndex = clonedClassInfo.findIndex((x) => x.schoolClassInfo.id === promotedClassId);
					if (classInfoIndex > -1) {
						const classInfo = clonedClassInfo[classInfoIndex].schoolClassInfo;
						const studentIndex = clonedClassInfo[classInfoIndex].students.findIndex((x) => x.id === s.id);
						if (studentIndex > -1) {
							const studentInfo = clonedClassInfo[classInfoIndex].students[studentIndex];
							if (studentInfo.subscriptionType === StudentSubscriptionType.Digital) {
								classInfo.digitalLicense -= 1;
							} else if (studentInfo.subscriptionType === StudentSubscriptionType.TextBook) {
								classInfo.textbookLicense -= 1;
							} else if (studentInfo.subscriptionType === StudentSubscriptionType.Dual) {
								classInfo.textbookLicense -= 1;
								classInfo.digitalLicense -= 1;
							} else {
								return;
							}
							classInfo.studentCount -= 1;
							clonedClassInfo[classInfoIndex].students.splice(studentIndex, 1);
						}
					}
				}
			});

			return clonedClassInfo;
		};

		const updateRequiredStudentClassData = (
			updatedSourceClassData: SchoolClassWithStudentsInfoModel,
			updatedDestClassData: SchoolClassWithStudentsInfoModel,
			promoteAlreadyPromotedStudents: StudentsInfoModel[],
			movedPromotedStudents: MovedPromotedStudentInfo[]
		) => {
			const isHomeSourceClass = isSourceClass(updatedSourceClassData.schoolClassInfo.id);
			const isForeignSourceClass = isSourceClass(updatedDestClassData.schoolClassInfo.id);
			if (!isHomeSourceClass && !isForeignSourceClass) {
				const destWithHome = getUpdatedDestDestClassData(updatedSourceClassData, destStudentClassData);
				const destWithForeign = getUpdatedDestDestClassData(updatedDestClassData, destWithHome);

				setDestStudentClassData(destWithForeign);

				if (movedPromotedStudents.length > 0) {
					const clonedSourceStudentClassData: SchoolClassWithStudentsInfoModel =
						deepClone(sourceStudentClassData);
					movedPromotedStudents.forEach((m) => {
						const stIndex = clonedSourceStudentClassData.students.findIndex((s) => s.id === m.id);
						if (stIndex > -1) {
							clonedSourceStudentClassData.students[stIndex].isPromotedToClassId = m.movedToClassId;
							clonedSourceStudentClassData.students[stIndex].isPromotedToClassName = m.movedToClassName;
							clonedSourceStudentClassData.students[stIndex].isPromotedToCampusId = m.movedToCampusId;
							clonedSourceStudentClassData.students[stIndex].isPromotedToCampusName = m.movedToCampusName;
						}
					});
					setSourceStudentClassData(clonedSourceStudentClassData);
				}
			} else {
				updateStudentsClassData(updatedSourceClassData);

				if (promoteAlreadyPromotedStudents.length > 0) {
					const updatedDestInfo = destStudentClassData.map((d) => {
						if (d.schoolClassInfo.id === updatedDestClassData.schoolClassInfo.id) {
							d = updatedDestClassData;
						}
						return d;
					});
					const updatedDestClassDataWithExtraRemoval = managePromoteAlreadyPromotedStudents(
						promoteAlreadyPromotedStudents,
						updatedDestInfo
					);
					setDestStudentClassData(updatedDestClassDataWithExtraRemoval);
				} else {
					updateStudentsClassData(updatedDestClassData);
				}
			}
		};

		const updateLicenseInfo = (
			sourceClass: SchoolClassInfoModel,
			destinationClass: SchoolClassInfoModel,
			studentsInfo: StudentsInfoModel[],
			reversedPromotionStudents: string[]
		) => {
			const manageMove = (
				studentInfo: StudentsInfoModel,
				sourceClassInfo: SchoolClassInfoModel,
				destinationClassInfo: SchoolClassInfoModel
			) => {
				if (studentInfo.isDisabled) {
					if (studentInfo.previousClassId === destinationClassInfo.id) {
						if (studentInfo.subscriptionType === StudentSubscriptionType.Digital) {
							sourceClassInfo.digitalLicense -= 1;
						} else if (studentInfo.subscriptionType === StudentSubscriptionType.TextBook) {
							sourceClassInfo.textbookLicense -= 1;
						} else if (studentInfo.subscriptionType === StudentSubscriptionType.Dual) {
							sourceClassInfo.textbookLicense -= 1;
							sourceClassInfo.digitalLicense -= 1;
						} else {
							return;
						}
						sourceClassInfo.studentCount -= 1;
					} else {
						if (studentInfo.subscriptionType === StudentSubscriptionType.Digital) {
							destinationClassInfo.digitalLicense += 1;
						} else if (studentInfo.subscriptionType === StudentSubscriptionType.TextBook) {
							destinationClassInfo.textbookLicense += 1;
						} else if (studentInfo.subscriptionType === StudentSubscriptionType.Dual) {
							destinationClassInfo.textbookLicense += 1;
							destinationClassInfo.digitalLicense += 1;
						} else {
							return;
						}
						destinationClassInfo.studentCount += 1;
					}
				} else {
					if (studentInfo.subscriptionType === StudentSubscriptionType.Digital) {
						sourceClassInfo.digitalLicense -= 1;
						destinationClassInfo.digitalLicense += 1;
					} else if (studentInfo.subscriptionType === StudentSubscriptionType.TextBook) {
						sourceClassInfo.textbookLicense -= 1;
						destinationClassInfo.textbookLicense += 1;
					} else if (studentInfo.subscriptionType === StudentSubscriptionType.Dual) {
						sourceClassInfo.textbookLicense -= 1;
						sourceClassInfo.digitalLicense -= 1;
						destinationClassInfo.textbookLicense += 1;
						destinationClassInfo.digitalLicense += 1;
					} else {
						return;
					}
					sourceClassInfo.studentCount -= 1;
					destinationClassInfo.studentCount += 1;
				}
			};

			studentsInfo.forEach((student) => {
				if (student.isMoved) {
					manageMove(student, sourceClass, destinationClass);
				} else if (student.isPromoted) {
					if (isFutureClass(sourceClass) && isFutureClass(destinationClass)) {
						manageMove(student, sourceClass, destinationClass);
					} else {
						if (student.subscriptionType === StudentSubscriptionType.Digital) {
							destinationClass.digitalLicense += 1;
						} else if (student.subscriptionType === StudentSubscriptionType.TextBook) {
							destinationClass.textbookLicense += 1;
						} else if (student.subscriptionType === StudentSubscriptionType.Dual) {
							destinationClass.textbookLicense += 1;
							destinationClass.digitalLicense += 1;
						} else {
							return;
						}
						destinationClass.studentCount += 1;
					}
				} else {
					if (reversedPromotionStudents.includes(student.id)) {
						if (student.subscriptionType === StudentSubscriptionType.Digital) {
							sourceClass.digitalLicense -= 1;
						} else if (student.subscriptionType === StudentSubscriptionType.TextBook) {
							sourceClass.textbookLicense -= 1;
						} else if (student.subscriptionType === StudentSubscriptionType.Dual) {
							sourceClass.textbookLicense -= 1;
							sourceClass.digitalLicense -= 1;
						} else {
							return;
						}
						sourceClass.studentCount -= 1;
					} else {
						manageMove(student, sourceClass, destinationClass);
					}
				}
			});
		};

		const onResetClick = () => {
			resetData();
		};

		const resetData = () => {
			if (pendingChangesCount > 0) {
				setSourceStudentClassData(deepClone(masterSourceStudentClassData));

				if (sourceSelectedCampus && destinationSelectedCampus && sourceSelectedClass) {
					filterAndDestClasses(
						masterDestStudentClassData,
						sourceSelectedCampus.id,
						destinationSelectedCampus.id,
						sourceSelectedClass.id
					);
				} else {
					setDestStudentClassData(deepClone(masterDestStudentClassData));
				}

				setNotesValue(null);
				unselectAll();
				setDraggingListId(null);
			}
		};

		const onSwitchToggle = (isChecked) => {
			setIsFuture(isChecked);
			if (destinationSelectedCampus) {
				getClassesAndStudents(destinationSelectedCampus.id, isChecked, false);
			}
		};

		const onDisableSwitchToggle = (isChecked) => {
			setIsDisabled(!isChecked);
			if (sourceSelectedClass && isChecked) {
				// getClassTerms()
				// getClassesAndStudents(sourceSelectedCampus.id, false, isChecked, sourceSelectedClass.id)
			}
		};

		const onTermChange = (termId) => {
			const termName = termList.find((t) => t.id === termId).name;
			setSourceSelectedClassTerm({ name: termName, id: termId });
			const filteredClasses = masterSourceClassList.filter((c) => c.termId === termId);
			setClassList(filteredClasses);
			const currentClass = filteredClasses[0];
			setSourceSelectedClass(currentClass);
			getClassesAndStudents(sourceSelectedCampus.id, false, isDisabled, currentClass.id);
		};

		const onSaveClick = () => {
			const movePromoteSaveData: movePromoteSaveModel = {
				moveInfo: [],
				promoteInfo: [],
				movedPromotedInfo: [],
				movedPromotedToOriginalInfo: [],
				note: notesValue,
				isDisabled: isDisabled
			};

			const setRequiredSaveData = (destinationClassId: string, students: StudentsInfoModel[]) => {
				const movedStudents = groupBy(
					students.filter((s) => s.fromClassId && s.isMoved),
					"fromClassId"
				);
				const promotedStudents = groupBy(
					students.filter((s) => s.fromClassId && s.isPromoted && !s.promotedToClassId),
					"fromClassId"
				);
				const movedPromotedStudents = groupBy(
					students.filter(
						(s) =>
							!s.isPending &&
							!s.isAlreadyInPromotion &&
							s.promotedFromClassId &&
							s.promotedInClassId &&
							s.promotedInClassId !== destinationClassId
					),
					"promotedInClassId"
				);
				const movedBackPromotedStudents = groupBy(
					students.filter(
						(s) =>
							s.isPending &&
							s.promotedFromClassId &&
							s.promotedInClassId &&
							s.promotedFromClassId === destinationClassId
					),
					"promotedInClassId"
				);

				// Moved students
				movePromoteSaveData.moveInfo = [
					...movePromoteSaveData.moveInfo,
					...getRequiredSaveData(movedStudents, destinationClassId, false)
				];

				// Promoted to future class
				movePromoteSaveData.promoteInfo = [
					...movePromoteSaveData.promoteInfo,
					...getRequiredSaveData(promotedStudents, destinationClassId, false)
				];

				// Move promoted to other future class
				movePromoteSaveData.movedPromotedInfo = [
					...movePromoteSaveData.movedPromotedInfo,
					...getRequiredSaveData(movedPromotedStudents, destinationClassId, true)
				];

				// Back promoted to original
				movePromoteSaveData.movedPromotedToOriginalInfo = [
					...movePromoteSaveData.movedPromotedToOriginalInfo,
					...getRequiredSaveData(movedBackPromotedStudents, destinationClassId, false)
				];
			};

			if (sourceStudentClassData) {
				setRequiredSaveData(sourceStudentClassData.schoolClassInfo.id, sourceStudentClassData.students);
			}

			destStudentClassData.forEach((data) => {
				setRequiredSaveData(data.schoolClassInfo.id, data.students);
			});

			saveChanges(movePromoteSaveData);
		};

		const getRequiredSaveData = (
			studentData: Dictionary<StudentsInfoModel[]>,
			destinationClassId: string,
			movePromotedAdditionalInfoRequired: boolean
		): movePromoteInfoModel[] => {
			const retData: movePromoteInfoModel[] = [];

			for (const [key, value] of Object.entries(studentData)) {
				const requiredData: movePromoteInfoModel = {
					sourceClassId: key,
					students: value.map((s) => {
						return s.id;
					}),
					destinationClassId: destinationClassId,
					movedPromoteAdditionalInfo: movePromotedAdditionalInfoRequired
						? value.map((s) => {
							const additionalInfo: MovedPromotedParentClassInfo = {
								studentId: s.id,
								classId: s.promotedFromClassId
							};
							return additionalInfo;
						})
						: []
				};
				retData.push(requiredData);
			}

			return retData;
		};

		const saveChanges = (movePromoteSaveData: movePromoteSaveModel) => {
			schoolClassService.savemovepromotechanges(movePromoteSaveData).then(() => {
				MessageHelper.Message(
					NotificationType.Success,
					fmtMsg({ id: MovePromoteStudentsLocale.ChangesSaveSucessfullMessage })
				);
				reflectSaveChanges();
			});
			// .catch(() => {

			// });
		};

		const setReflectChangeStudentObject = (s: StudentsInfoModel, classId: string) => {
			return {
				...s,
				isMoved: false,
				fromClassId: null,
				isPromoted: false,
				isPending: false,
				isAlreadyInPromotion: s.isInPromotion || s.isAlreadyInPromotion ? true : false,
				promotedInClassId: s.isPending
					? null
					: s.isPromoted
						? classId
						: s.promotedFromClassId && s.promotedInClassId !== classId
							? classId
							: s.promotedInClassId,
				promotedFromClassId: s.isPending ? null : s.isPromoted ? s.fromClassId : s.promotedFromClassId,
				isInPromotion: false,
				promotedToClassId:
					s.isInPromotion || s.isAlreadyInPromotion
						? s.isPromotedToClassId
							? s.isPromotedToClassId
							: s.promotedToClassId
						: null,
				promotedToClassName:
					s.isInPromotion || s.isAlreadyInPromotion
						? s.isPromotedToClassName
							? s.isPromotedToClassName
							: s.promotedToClassName
						: null,
				promotedToCampusId:
					s.isInPromotion || s.isAlreadyInPromotion
						? s.isPromotedToCampusId
							? s.isPromotedToCampusId
							: s.promotedToCampusId
						: null,
				promotedToCampusName:
					s.isInPromotion || s.isAlreadyInPromotion
						? s.isPromotedToCampusName
							? s.isPromotedToCampusName
							: s.promotedToCampusName
						: null,
				isPromotedToClassId: null,
				isPromotedToClassName: null,
				isPromotedToCampusId: null,
				isPromotedToCampusName: null
			};
		};

		const reflectSaveChanges = () => {
			let updatedSourceCopy = null;
			if (sourceStudentClassData) {
				const sourceCopy: SchoolClassWithStudentsInfoModel = deepClone(sourceStudentClassData);
				sourceCopy.students = sourceCopy.students.map((s) => {
					return setReflectChangeStudentObject(s, sourceCopy.schoolClassInfo.id);
				});
				setSourceStudentClassData(deepClone(sourceCopy));
				setMasterSourceStudentClassData(deepClone(sourceCopy));
				updatedSourceCopy = deepClone(sourceCopy);
			}

			const destCopy: SchoolClassWithStudentsInfoModel[] = deepClone(destStudentClassData);

			destCopy.forEach((dest) => {
				dest.students = dest.students.map((s) => {
					return setReflectChangeStudentObject(s, dest.schoolClassInfo.id);
				});
			});

			if (sourceSelectedCampus && destinationSelectedCampus && sourceSelectedClass) {
				if (
					sourceSelectedCampus.id === destinationSelectedCampus.id &&
					updatedSourceCopy &&
					destCopy.findIndex((s) => s.schoolClassInfo.id === updatedSourceCopy.schoolClassInfo.id) === -1
				) {
					destCopy.push(updatedSourceCopy);
				}
				filterAndDestClasses(
					destCopy,
					sourceSelectedCampus.id,
					destinationSelectedCampus.id,
					sourceSelectedClass.id
				);
			} else {
				setDestStudentClassData(deepClone(masterDestStudentClassData));
			}
			setMasterDestStudentClassData(destCopy);
			setNotesValue(null);
		};

		const confirmChanges = (operation: MovePromotePageOperations, e: any) => {
			if (pendingChangesCount > 0) {
				Modal.confirm({
					title: fmtMsg(MovePromoteStudentsLocale.ChangesLostWarningMessage),
					okText: fmtMsg(GLLocale.Ok),
					cancelText: fmtMsg(GLLocale.Cancel),
					onOk: () => {
						resetData();
						handleOperation(operation, e);
					}
				});
			} else {
				handleOperation(operation, e);
			}
		};

		const handleOperation = (operation: MovePromotePageOperations, e: any) => {
			if (operation === MovePromotePageOperations.SourceCampusChange) {
				onCampusSelected(true, e);
			} else if (operation === MovePromotePageOperations.SourceClassChange) {
				onClassSelected(e);
			} else if (operation === MovePromotePageOperations.DestinationCampusChange) {
				onCampusSelected(false, e);
			} else if (operation === MovePromotePageOperations.ActiveFutureSwitchChange) {
				onSwitchToggle(e);
			} else if (operation === MovePromotePageOperations.ActiveDisableSwitchChange) {
				onDisableSwitchToggle(e);
			} else if (operation === MovePromotePageOperations.SourceClassTermChange) {
				onTermChange(e);
			}
		};

		const menu = (
			<Menu>
				<Menu.Item key="1">1st menu item</Menu.Item>
				<Menu.Item key="2">2nd menu item</Menu.Item>
				<Menu.Item key="3">3rd item</Menu.Item>
			</Menu>
		);

		const renderTargetSection = (): ReactNode => {
			return (
				<div className="mps__target mps-target">
					<div className="mps-target__header">
						<Row className="disablerow">
							<Col>
								{fmtMsg({
									id: MovePromoteStudentsLocale.InactiveClassLabel,
								})}
							</Col>
							<Col>
								<Switch
									checked={!isDisabled}
									onChange={(e) =>
										confirmChanges(
											MovePromotePageOperations.ActiveDisableSwitchChange,
											e
										)
									}
								/>
							</Col>
							<Col>
								{fmtMsg({
									id: MovePromoteStudentsLocale.ActiveClassLabel,
								})}
							</Col>
							{isDisabled ? (
								<div className="term">
									<div className="term-label">
										{fmtMsg({
											id: MovePromoteStudentsLocale.ClassTermDropdownLabel,
										})}
									</div>
									<div className="term-dropdown">
										<Select
											placeholder={fmtMsg({
												id: MovePromoteStudentsLocale.ClassTermDropdownPlaceholder,
											})}
											loading={termLoading}
											value={
												sourceSelectedClassTerm
													? sourceSelectedClassTerm.id
													: undefined
											}
											onChange={(e) =>
												confirmChanges(
													MovePromotePageOperations.SourceClassTermChange,
													e
												)
											}
										>
											{termList.map((data) => (
												<Option
													key={data.id}
													value={data.id}
												>
													<span title={data.name}>
														{data.name}
													</span>
												</Option>
											))}
										</Select>
									</div>
								</div>
							) : null}
						</Row>
						<div className="mps-target__gap"></div>
						<CampusSelector
							isSourceCampus={true}
							schoolCampus={sourceSelectedCampus}
							schoolCampusOptions={campusList}
							onCampusDropDownChange={confirmChanges}
						/>
						<div className="mps-target__gap"></div>
						<Select
						    className="mps-select-class"
							placeholder={fmtMsg({
								id: MovePromoteStudentsLocale.SelectClassMessage,
							})}
							size="large"
							loading={classLoading}
							value={
								sourceSelectedClass
									? sourceSelectedClass.id
									: undefined
							}
							onChange={(e) =>
								confirmChanges(
									MovePromotePageOperations.SourceClassChange,
									e
								)
							}
						>
							{classList.map((data) => (
								<Option key={data.id} value={data.id}>
									<span title={data.name}>{data.name}</span>
								</Option>
							))}
						</Select>
						<div className="mps-target__gap"></div>
					</div>
					<div className="mps-target__content">
						<div className="mps-target__classes">
							{sourceStudentClassData ? (
								<StudentDragDropRegion
									droppableId={
										sourceStudentClassData.schoolClassInfo
											.id
									}
									schoolClassWithStudents={
										sourceStudentClassData
									}
									toggleSelection={toggleSelection}
									toggleSelectionInGroup={
										toggleSelectionInGroup
									}
									multiSelectTo={multiSelectTo}
									selectedListIds={selectedListIds}
									draggingListId={draggingListId}
									handleSelectAllStudentChange={
										handleSelectAllStudentChange
									}
								></StudentDragDropRegion>
							) : (
								<Empty
									className="mps-target__empty"
									description={fmtMsg(
										MovePromoteStudentsLocale.NoRecordsMessage
									)}
								/>
							)}
						</div>
					</div>
				</div>
			);
		};

		const handleSelectAllStudentChange = (isSelectAll: boolean, schoolClassId: string) => {
			const allSchoolClasses = [...filteredDestStudentClassData, sourceStudentClassData];
			const targetClass = allSchoolClasses.find(item => item.schoolClassInfo.id === schoolClassId);
			const targetClassListIds = targetClass.students.map(student => listIdStrGenerate(targetClass.schoolClassInfo.id, student.id));
			let newSelectedListIds = [];
			if (isSelectAll) {
				newSelectedListIds = [...selectedListIds, ...targetClassListIds];
			} else {
				newSelectedListIds = selectedListIds.filter(listId => targetClassListIds.findIndex(targetListId => targetListId === listId) === -1);
			}
			setSelectedListIds([...new Set(newSelectedListIds)]);
		}
		const renderDestinationSection = (): ReactNode => {
			return (
				<div className="mps__destination mps-destination">
					<div className="mps-destination__header">
						<div className="target-selection">
							<CampusSelector
								isSourceCampus={false}
								schoolCampus={destinationSelectedCampus}
								schoolCampusOptions={campusList}
								onCampusDropDownChange={confirmChanges}
							/>
							<div className="target-selection__item">
								<FormattedMessage id={MovePromoteStudentsLocale.MoveClassLabel} />
								<Switch
									checked={isFuture}
									onChange={(e) =>
										confirmChanges(MovePromotePageOperations.ActiveFutureSwitchChange, e)
									}
								/>
								<FormattedMessage id={MovePromoteStudentsLocale.PromoteClassLabel} />
							</div>
							<div className="target-selection__item">
								<Input.Search
									value={clasFilterKeyword}
									onChange={(event) => setClassFilterKeyword(event.currentTarget.value)}
									placeholder={fmtMsg(MovePromoteStudentsLocale.ClassFilterLabel)}
								/>
							</div>
						</div>
						<div className="mps-destination__gap"></div>
						<div className="mps-info">
							<span className="mps-info__label">
								<FormattedMessage id={MovePromoteStudentsLocale.NotesLabel} />
							</span>
							<span className="mps-info__value">
								<Input.TextArea
									className="mps__w100"
									maxLength={500}
									value={notesValue}
									onChange={(e) => onNotesChange(e)}
								/>
							</span>
						</div>
					</div>
					<div className="mps-destination__content">
						{filteredDestStudentClassData.length ? (
							<div className="mps-destination__classes">
								{filteredDestStudentClassData.map((data) => (
									<StudentDragDropRegion
										key={data.schoolClassInfo.id}
										droppableId={data.schoolClassInfo.id}
										schoolClassWithStudents={data}
										toggleSelection={toggleSelection}
										toggleSelectionInGroup={toggleSelectionInGroup}
										multiSelectTo={multiSelectTo}
										selectedListIds={selectedListIds}
										draggingListId={draggingListId}
										handleSelectAllStudentChange={handleSelectAllStudentChange}
									></StudentDragDropRegion>
								))}
							</div>
						) : (
							<Empty
								className="mps-target__empty"
								description={fmtMsg(MovePromoteStudentsLocale.NoRecordsMessage)}
							/>
						)}
					</div>
					<div className="mps-destination__footer">
						<Row type="flex" gutter={15} justify="space-between" align="middle">
							<Col className="mps-destination__footer__col">
								<div className="mps-info">
									<span className="mps-info__label">
										<FormattedMessage id={MovePromoteStudentsLocale.PendingChangesLabel} />
									</span>
									<span className="mps-info__value">{pendingChangesCount}</span>
								</div>
							</Col>
							<Col>
								<Button size="small" onClick={onResetClick}>
									<FormattedMessage id={MovePromoteStudentsLocale.ResetButtonLabel} />
								</Button>
								<Button
									type="primary"
									size="small"
									disabled={pendingChangesCount === 0}
									onClick={onSaveClick}
								>
									<FormattedMessage id={MovePromoteStudentsLocale.SaveButtonLabel} />
								</Button>
							</Col>
						</Row>
					</div>
				</div>
			);
		};

		return (
			<div className="mps">
				<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
					{renderTargetSection()}
					{renderDestinationSection()}
				</DragDropContext>
			</div>
		);
	})
);

MovePromoteStudents.defaultProps = {};
