import React from "react";
import "./prevent-clicking-mask.less";

interface PreventClickingMaskProps {
    onMaskClick?: (e) => void;
    allowClicking?: boolean;
}

class PreventClickingMask extends React.Component<PreventClickingMaskProps> {
    render() {
        const { onMaskClick, allowClicking } = this.props;
        return (
            <div className="prevent-clicking-container">
                {this.props.children}
                {!allowClicking && <div className="prevent-clicking-overlay" onClick={onMaskClick}></div>}
            </div>
        );
    }
}

export default PreventClickingMask;
