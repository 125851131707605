import { Input, Select } from "antd";
import { LoginId, LoginIdType, defaultPhoneCountryCodeList } from "gl-commonui";
import React, { useEffect } from "react";

export interface GLLoginIdInputProps {
	onChange: (loginId: LoginId) => void;
	value?: LoginId;
	disabled: boolean;
	placeholder?: string;
	loginIdType?: LoginIdType;
	hideCountryCode?: boolean;
	prefix?: React.ReactNode;
	suffix?: React.ReactNode;
	dualMode?: boolean;
	forceNumberInput?: boolean;
}

export const GLLoginIdInput: React.FC<GLLoginIdInputProps> = React.forwardRef((props, ref) => {
	let loginId = props.value || {
		type: props.dualMode ? LoginIdType.Email : props.loginIdType,
		country: "US",
		countryCode: "+1",
		phone: "",
		email: "",
	};
	const placeholder = props.placeholder || "Email or phone nunmber";
	const [country, setCountry] = React.useState(loginId.country);
	const [countryCode, setCountryCode] = React.useState(loginId.countryCode);

	const [loginIdType, setloginIdType] = React.useState(loginId.type);
	const [loginIdText, setLoginIdText] = React.useState(
		loginId.type === LoginIdType.Email ? loginId.email : loginId.phone
	);

	useEffect(()=> {
		if (props.dualMode) {
			const loginIdText = props.value.email || props.value.phone;
			let loginIdType = props.dualMode ? (props.value && isNumeric(loginIdText) ? LoginIdType.Phone : LoginIdType.Email) : props.loginIdType;
			setCountry(props.value.country);
			setCountryCode(props.value.countryCode);
			setloginIdType(loginIdType);
			setLoginIdText(loginIdText);
		}
	}, [props.value]);

	const isNumeric = function (value : string) {
		return /^-?\d+$/.test(value);
	}

	const onCountryCodeChange = function(ev: any) {
		const val = ev.split(" ");

		setCountry(val[0]);
		setCountryCode(val[1]);

		let newLoginId: LoginId = {
			type: LoginIdType.Phone,
			phone: loginIdText,
			country: val[0],
			countryCode: val[1],
		};

		props && props.onChange && props.onChange(newLoginId);
	};

	var onLoginIdTextChange = function(ev: any) {
		let val = ev.target.value;

		if (val && val.length && props.forceNumberInput && !isNumeric(val)) {
			return;
		}

		const loginIdType = props.dualMode ? val && isNumeric(val) ? LoginIdType.Phone : LoginIdType.Email : props.value.type;
		setloginIdType(loginIdType);
		setLoginIdText(val);

		var newLoginId: LoginId =
			loginIdType === LoginIdType.Email
				? {
						type: loginIdType,
						email: val,
						countryCode: countryCode,
						country: country
				  }
				: {
						type: loginIdType,
						phone: val,
						country: country,
						countryCode: countryCode,
				  };

		props && props.onChange && props.onChange(newLoginId);
	};

	return (
		<div className="loginId-container">
			{!props.hideCountryCode && (
				<div
					className={
						loginIdType === LoginIdType.Phone ? "phone-code-container" : "hidden-phone-code-container"
					}
				>
					<div className="phone-code-selection-container">
						<Select
							id="phone-code"
							className="phone-code-selection"
							dropdownClassName="phone-code-selection-dropdown"
							onChange={onCountryCodeChange}
							defaultValue={`${props.value.country} ${props.value.countryCode}`}
							disabled={props.disabled}
						>
							{defaultPhoneCountryCodeList.map((phoneCode) => {
								return (
									<Select.Option
										key={phoneCode.code}
										className="phone-code-option"
										value={phoneCode.counrtryAbbr + " " + phoneCode.code}
									>
										{phoneCode.country} ({phoneCode.code})
									</Select.Option>
								);
							})}
						</Select>
					</div>
					<div
						className={
							props.disabled ? "phone-code-label-container readonly" : "phone-code-label-container"
						}
					>
						<span className="countrycode-span">{country}</span>
						<span className="phone-countrycode-span">{countryCode}</span>
					</div>
				</div>
			)}
			<div className={`phone-number-container ${loginIdType === LoginIdType.Phone && "phone-input"}`}>
				<Input
					id="loginid"
					name="loginid"
					placeholder={placeholder}
					onChange={onLoginIdTextChange}
					value={
						(props.value || loginId).type == LoginIdType.Email
							? (props.value || loginId).email
							: (props.value || loginId).phone
					}
					disabled={props.disabled}
					prefix={props.prefix}
					suffix={props.suffix}
				/>
			</div>
		</div>
	);
});
