import {
    GLServiceBase,
    HttpMethod,
    ServerExceptionCode,
    getServiceMethodKey,
    Role,
    RoleName
} from "gl-commonui";
import { injectable } from "@app/util/di";
import {
    IResourceService,
    ResourceModel,
    GetResourceRequest,
    ResourceRequest
} from "./index";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { ContextHelper } from "@app/util";
import { ServerExceptionCode as ExceptionCode } from '@app/util/coach/enum';

@injectable()
export class ResourceService
    extends GLServiceBase<ResourceRequest, GetResourceRequest>
    implements IResourceService {
    serviceRoute = {
        prefix: "admin/v1/resources",
        itemSuffix: "{id}",
        suffix: "{resourceId}"
    };
    removeUserResourcesWithRoleUrl = '{resourceId}/resouceconsumerswithrole/{consumerId}';
    errMsgs = (() => {
        const result = {};
        result[
            getServiceMethodKey(
                this,
                HttpMethod.Delete,
                ServerExceptionCode.StrongAssociationException
            )
        ] = {
            id: GSAdminLocale.ErrorDeleteForTargetUsed,
            values: { name: "user" }
        };
        result[getServiceMethodKey(this, HttpMethod.Delete, ExceptionCode.CanNotRemoveCoachError)] = SchoolLocale.VisitationCanNotRemoveCoach;
        return result;
    })();
    getResourceUserIds(params: any) {
        return this.get("{resourceId}/userIds", params);
    }
    getUserIds(params: any) {
        return this.get("userIds", params);
    }
    getResources(params: any) {
        return this.request.get(this.url(""), params);
    }
    updateResources(params: any) {
        return this.update("consumers/{consumerId}", params);
    }
    removeResources(params: any) {
        return this.delete("consumers/{consumerId}", params);
    }
    getBreadcrumbs(d) {
        return this.get("breadcrumbs", d);
    }
    getUserOwnResources(params: any) {
        return this.get("{userId}/resources", params);
    }
    getResourceNames(params: any) {
        return this.get("names", params);
    }
    getAccessResources(params, routeData) {
        return this.get("{userId}/roleresources", params, routeData);
    }
    deleteResource(params) {
        return this.delete("{userId}/roleresources", params);
    }
    getIdsBy(type, id) {
        return this.get("{resourceId}/userIds", type, id);
    }
    removeBy(data, consumerId) {
        return this.delete("consumers/{consumerId}", data, { consumerId });
    }
    removeUserRole(role: number, userId: string) {
        return this.delete("{userId}/removeUserWithRole", { role }, { userId });
    }
    getLandingResources(params: any) {
        return this.get(
            "users/{userId}/landingresources/{userRole}",
            params.queryData,
            params.routeData
        );
    }
    getResourceId(params) {
        return this.get("{resourceType}/structure/{resourceId}", params);
    }
    getResourceStructure(validateRoles?: RoleName[]) {
        const profile = ContextHelper.getUserLoginInfo().profile;
        const userId = profile.sub;
        const Roles: any[] =
            typeof profile.role == "string" ? [profile.role] : profile.role;
        const userRoles = Roles.filter(
            role =>
                (validateRoles && validateRoles.indexOf(role) > -1) ||
                !validateRoles
        ).map(role => Role[role]);
        return this.get("{userId}/structure", { userRoles }, { userId });
    }
    getRegions(validateRoles?: RoleName[]) {
        const profile = ContextHelper.getUserLoginInfo().profile;
        const Roles: any[] =
            typeof profile.role == "string" ? [profile.role] : profile.role;
        const userRoles = Roles.filter(
            role =>
                (validateRoles && validateRoles.indexOf(role) > -1) ||
                !validateRoles
        ).map(role => Role[role]);
        return this.get("regions", {
            userId: profile.sub,
            userRoles: userRoles
        });
    }
    getUserResourcesForSupport(params: any) {
        return this.get("{userId}/GetUserResourcesForSupport", params);
    }
    deleteConsumerResource(params) {
        return this.delete(
            "{resourceId}/resourceconsumers/{consumerId}",
            params
        );
    }
    removeUserResources(data, resourceId, consumerId) {
        return this.delete(
            "{resourceId}/resourceconsumers/{consumerId}",
            data,
            { resourceId, consumerId }
        );
    }
    removeUserResourcesWithRole(data, resourceId, consumerId) {
        return this.delete(
            this.removeUserResourcesWithRoleUrl,
            data,
            { resourceId, consumerId }
        );
    }
    getTeacherNotes(teacherId) {
        return this.get("teachers/{teacherId}/notes", null, { teacherId });
    }
    updateTeacherNotes(teacherId, notes) {
        return this.create(
            "teachers/{teacherId}/notes",
            { notes },
            { teacherId }
        );
    }
    getChatUsers(params?) {
        return this.get("chats/schools/users", params);
    }
    getSchoolCampusAdmins(params?) {
        return this.get("schools/campuses/admins", params);
    }
    getChatSchools(params?) {
        return this.get("chats/schools", params);
    }
    getChatSchoolsUsers(params?) {
        return this.get("chats/schools/users/search", params);
    }
}
