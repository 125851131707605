import { MovePromoteStudentsLocale } from "@app/locales/localeid";
import { CurriculumType, SchoolClassWithStudentsInfoModel, StudentsInfoModel } from "@app/service/class";
import { DateHelper, fmtMsg, StudentListItemSplitChar } from "@app/util";
import { Col, Row, Checkbox } from "antd-min";
import { mergeClasses } from "gl-commonui";
import React, { FC, ReactNode, useEffect } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import "./students-drag-drop-region.less";
import { checkboxClassName, checkboxSpanClassName } from "./consts";

export const extractDataFromListId = (listId: string) => {
    const arr = listId.split(StudentListItemSplitChar);
    return {
        schoolClassId: arr[0],
        studentId: arr[1]
    }
}
export const listIdStrGenerate = (schoolClassId: string, studentId: string) => schoolClassId + StudentListItemSplitChar + studentId;
interface StudentDragDropRegionProps {
    droppableId: string;
    schoolClassWithStudents: SchoolClassWithStudentsInfoModel;
    toggleSelection: (taskId: string) => void;
    toggleSelectionInGroup: (taskId: string) => void;
    multiSelectTo: (taskId: string) => void;
    selectedListIds: string[];
    draggingListId: string;
    handleSelectAllStudentChange?: (checked: boolean, schoolClassId: string) => void;
}

export const StudentDragDropRegion: FC<StudentDragDropRegionProps> = (props) => {

    const [checked, setChecked] = React.useState(false);

    const isGrapeSEED = props.schoolClassWithStudents.schoolClassInfo.curriculumType === CurriculumType.GrapeSEED.toString();

    const getDroppableClasses = (dragging: boolean, disabled: boolean): string => {
        return `student-dnd-droppable ${dragging ? "student-dnd-dragging" : ""} ${disabled ? "disabled" : ""}`;
    };

    const getListClasses = (dragging: boolean, listId: string, item?: StudentsInfoModel): string => {
        let className = "student-dnd-item";
        switch (true) {
            case !item:
                break;
            case isGhosting(listId):
                className = className + ' ' + 'ghosting';
                break;
            case isSelected(listId):
                className = className + ' ' + 'selected';
                break;
            case item.isMoved:
                className = className + ' ' + 'moved';
                break;
            case item.isPending:
                className = className + ' ' + 'pending';
                break;
            case isPromotedItem(item):
                className = className + ' ' + 'promoted';
                break;
            case isPromotionItem(item):
                className = className + ' ' + 'promotion';
                break;
            default:
                break;
        }
        return className;
    };

    const isSelected = (listId) => {
        return props.selectedListIds.includes(listId);
    };

    const isGhosting = (listId) => {
        return isSelected(listId) && Boolean(props.draggingListId) && props.draggingListId !== listId;
    };

    const isPromotedItem = (item: StudentsInfoModel) => !!(item.isPromoted || item.promotedFromClassId);

    const isPromotionItem = (item: StudentsInfoModel) => !!(item.isInPromotion || item.isAlreadyInPromotion);

    const isPresentInOtherStudents = (id: string): boolean => {
        return false;
        // if (
        //     props.otherStudents &&
        //     props.otherStudents.findIndex(item => item.id == id) > -1
        // ) {
        //     return true;
        // }
        // return false;
    };

    // Determines if the platform specific toggle selection in group key was used
    const wasToggleInSelectionGroupKeyUsed = (event: MouseEvent | KeyboardEvent) => {
        const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    // Determines if the multiSelect key was used
    const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey;

    // Using onClick as it will be correctly
    // preventing if there was a drag
    const onClick = (event: MouseEvent, listId) => {
        if (event.defaultPrevented) {
            return;
        }

        if (event.button !== 0) {
            // primary button
            return;
        }

        // marking the event as used
        event.preventDefault();

        performAction(event, listId);
    };

    const onKeyDown = (event: KeyboardEvent, snapshot, listId) => {
        // already used
        if (event.defaultPrevented) {
            return;
        }

        if (snapshot.isDragging) {
            return;
        }

        if (event.keyCode !== 13) {
            //enter key code
            return;
        }

        // we are using the event for selection
        event.preventDefault();

        performAction(event, listId);
    };

    const performAction = (event: MouseEvent | KeyboardEvent, listId) => {
        const { toggleSelection, toggleSelectionInGroup, multiSelectTo } = props;

        if (wasToggleInSelectionGroupKeyUsed(event)) {
            toggleSelectionInGroup(listId);
            return;
        }

        if (wasMultiSelectKeyUsed(event)) {
            multiSelectTo(listId);
            return;
        }

        toggleSelection(listId);
    };
    const onSelectAllStudentChange = (e: any) => {
        props.handleSelectAllStudentChange(e.target.checked, props.schoolClassWithStudents.schoolClassInfo.id);   
    };
    useEffect(() => {
            let selectedAllChecked = false;
            const numberOfStudents = props.schoolClassWithStudents.students.length;
            const currentSelectedListId = props.selectedListIds.filter(listId => {
                const { schoolClassId } = extractDataFromListId(listId);
                if(schoolClassId === props.schoolClassWithStudents.schoolClassInfo.id) return true;
                return false;
            });
            if(numberOfStudents && currentSelectedListId.length === numberOfStudents) {
                selectedAllChecked = true;
            }
            setChecked(selectedAllChecked);
    }, [
        props.selectedListIds, props.schoolClassWithStudents
    ]);

    const renderHeader = (): ReactNode => {
        return (
            <div className="class-info-section">
                <div className="cis-detail__name">
                    <span className="cis-detail__name__main">{props.schoolClassWithStudents.schoolClassInfo.name}</span>
                    <span className="cis-detail__name__date">
                        ({DateHelper.toLocalStringFromUTC(props.schoolClassWithStudents.schoolClassInfo.startDate)})
                    </span>
                </div>
                <div className="cis-detail">
                    <span className="cis-detail__label">{fmtMsg(MovePromoteStudentsLocale.AgeGradeLabel)}</span>
                    <span className="cis-detail__value">{props.schoolClassWithStudents.schoolClassInfo.age}</span>
                </div>
                <Row type="flex" gutter={5}>
                    <Col>
                        <div className="cis-detail">
                            <span className="cis-detail__label">
                                <FormattedMessage id={MovePromoteStudentsLocale.StudentCountLabel} />
                            </span>
                            <span className="cis-detail__value">{props.schoolClassWithStudents.schoolClassInfo.studentCount}</span>
                        </div>
                    </Col>
                    <Col>•</Col>
                    <Col>
                        <div className="cis-detail">
                            <span className="cis-detail__label">
                                <FormattedMessage id={MovePromoteStudentsLocale.UnitLabel} />
                            </span>
                            <span className="cis-detail__value">{props.schoolClassWithStudents.schoolClassInfo.currentUnit}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="cis-detail">
                        <Checkbox className={checkboxClassName} disabled={!props.schoolClassWithStudents.students.length} checked={checked} onChange={onSelectAllStudentChange}>
                            <span data-select-all="true" style={{color: "white"}} className={checkboxSpanClassName}>
                                {fmtMsg(MovePromoteStudentsLocale.SelectAllLabel)}
                            </span>
                        </Checkbox>
                    </div>
                </Row>
            </div>
        );
    };

    const studentDisplayName = (nativeName: string, englishName: string): string => {
        // Student always has both native name and english name.
        if (nativeName.toLowerCase() === englishName.toLowerCase()) {
            return nativeName;
        } 
        return nativeName + " (" + englishName + ")"
    }

    return (
        <div className={mergeClasses("students-drag-drop-region", isGrapeSEED && "students-drag-drop-region--grapeseed")}>
            {props.schoolClassWithStudents && (
                <>
                    {renderHeader()}
                    <div className="class-student-list">
                        <Droppable droppableId={props.droppableId}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} className={getDroppableClasses(snapshot.isDraggingOver, !props.schoolClassWithStudents)}>
                                    {props.schoolClassWithStudents.students.map((item, index) => {
                                        
                                        const listId = props.schoolClassWithStudents.schoolClassInfo.id + StudentListItemSplitChar + item.id;
                                        return (
                                            <Draggable
                                                key={listId}
                                                draggableId={listId}
                                                index={index}
                                                isDragDisabled={isPresentInOtherStudents(item.id)}
                                            >
                                                {(provided, snapshot) => {
                                                    const selectionCount = props.selectedListIds.length;
                                                    const shouldShowSelection: boolean = snapshot.isDragging && selectionCount > 1;
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            id={listId}
                                                            className={getListClasses(snapshot.isDragging, listId, item)}
                                                            onClick={(event: any) => onClick(event, listId)}
                                                            onKeyDown={(event: KeyboardEvent) => onKeyDown(event, snapshot, listId)}
                                                            title={item.name}
                                                        >
                                                            <span className="class-student-list__displayname">
                                                                {studentDisplayName(item.name, item.englishName)}
                                                            </span>
                                                            {shouldShowSelection && <div className="selection-count">{selectionCount}</div>}
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </>
            )}
        </div>
    );
};
