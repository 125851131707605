import { Loading, StatusType, SurveyRichTextEditor } from "@app/components/survey";
import { DigitalAdoptionReport } from "@app/components/survey/reports/digital-adoption";
import { LicenseHistoryReport } from "@app/components/survey/reports/license-history-report";
import { SchoolAndClassesReport } from "@app/components/survey/reports/school-and-classes";
import { UserStatisticsReport } from "@app/components/survey/reports/user-statistics-report";
import { QLetterIcon } from "@app/components/svgicon";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { useService } from "@app/hooks";
import { GSAdminLocale, SurveyLocale } from "@app/locales/localeid";
import { RegionGroupModel } from "@app/service/admin/regiongroup";
import { RegionModel } from "@app/service/admin/regions";
import {
    ISurveyService,
    QuestionAnswerMap, QuestionType, StatisticsFilterType, SurveyAnswerModel, SurveyAttachmentModel,
    SurveyQuestionResponseModel, TodoSurveyRequestModel, TodoSurveyResponseModel
} from "@app/service/survey";
import { ISurveyResponseService, PollResponseModel } from "@app/service/survey/response";
import { TYPES } from "@app/service/types";
import { IUserService } from "@app/service/users";
import { StateType } from "@app/states";
import { setBreadcrumb } from "@app/states/admin/resource";
import { getAccessibleRegions } from "@app/states/region/region";
import { getRegionGroups } from "@app/states/region/regionGroup";
import { getSASUrl } from "@app/states/survey/media";
import {
    getSurveyName, reload, setResponseAttachments, setSurveyFillState,
    SurveyFillState, toggleFormVisiblility
} from "@app/states/survey/survey";
import { addUploadStatus, removeUploadStatus, updateUploadStatus } from "@app/states/uploadStatus/uploadStatus";
import {
    CONSTS, ContextHelper, createSliderMarks, DateHelper, fmtMsg, isExitSurvey, isPastDate, MaxSurveySliderStepCount, mergeClasses, RcFileExtended, ResponseStatusType, SurveyTodoType
} from "@app/util";
import { TransferProgressEvent } from "@azure/ms-rest-js";
import {
    Aborter, AnonymousCredential, BlobURL, BlockBlobURL,
    ContainerURL,
    ServiceURL,
    StorageURL, uploadBrowserDataToBlockBlob
} from "@azure/storage-blob";
import { Badge, Button, Card, Checkbox, Form, Icon, List, message, Radio, Rate, Select, Slider, Spin, Upload } from "antd-min";
import RadioGroup from "antd/lib/radio/group";
import DOMPurify from "dompurify";
import { connect, GLForm, GLFormComponentProps, GLGlobal, GLRouteComponentProps, GLUtil, PathConfig, RegionInfoModel, RoleName, ServerExceptionCode, withRouter } from "gl-commonui";
import { Col, Modal, Row } from "gl-commonui/lib/antd-min";
import { unescape } from "lodash";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { EmailResponse } from "./email-response/email-response";
import "./fill-poll.less";

const { Option } = Select;
declare type StatisticsFilterModel = RegionGroupModel | RegionModel;
const acceptedImageTypes = ".png,.jpeg,.jpg,";
const acceptedAudioTypes = ".mp3,";
const acceptedVideoTypes = ".mpg,.mpeg,.mp4,.avi,.flv,.wmv,.mo,.mov,.mkv";
const acceptedFileTypes = acceptedImageTypes.concat(acceptedAudioTypes).concat(acceptedVideoTypes);
const fileSizeLimit = 1000; // 1000 MB

interface SaveAttachmentResponse extends SurveyAttachmentModel {
    success: boolean;
}

interface FillPollProps extends GLFormComponentProps, GLRouteComponentProps {
    instanceId: string;
    responseId?: string;
    regions: RegionModel[];
    regionGroups: RegionGroupModel[];
    sasUrl?: string;
    responseAttachments: SurveyAttachmentModel[];
    setSurveyFillState: (surveyFillState: SurveyFillState) => void;
    getRegions: () => void;
    getRegionGroups: () => void;
    getReviewPolls?: (params: any) => void;
    getSASUrl?: () => void;
    addUploadStatus: (params: any) => void;
    updateUploadStatus: (params: any) => void;
    removeUploadStatus: (params: any) => void;
    setResponseAttachments: (parmas: any) => void;
    reload: (partialState: any) => void;
    permissions: string[];
}

const FillPoll = (props: FillPollProps) => {
    const [surveyResponse, setSurveyResponse] = useState({} as TodoSurveyResponseModel);
    const [pollResponse, setPollResponse] = useState({ response: [] } as PollResponseModel);
    const [surveyResponseLoading, setSurveyResponseLoading] = useState(false);
    const [statisticsFilterLoading, setStatisticsFilterLoading] = useState(false);
    const [removedAttachments, setRemovedAttachments] = useState([]);
    const [probablyShareUsers, setProbablyShareUsers] = useState([]);
    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [userShareFetching, setUserShareFetching] = useState(false);
    const [searchTempShareUser, setSearchTempShareUser] = useState([]);
    const [searchShareUser, setSearchShareUser] = useState([]);
    const [isShareModified, setIsShareModified] = useState(false);
    const [showEmailResponse, setShowEmailResponse] = useState(false);
    const service = useService<ISurveyService>(TYPES.ISurveyService);
    const userService = useService<IUserService>(TYPES.IUserService);
    const serviceResponse = useService<ISurveyResponseService>(TYPES.ISurveyResponseService);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [userAccessibleRegions, setUserAccessibleRegions] = useState([] as RegionInfoModel[]);
    let lastShareFetchId = 0;
    const buttonSubmitText = fmtMsg({ id: GSAdminLocale.ButtonSubmit });
    const {
        form,
        regions,
        regionGroups,
        getRegions,
        getRegionGroups,
        sasUrl,
        getSASUrl,
        addUploadStatus,
        updateUploadStatus,
        removeUploadStatus,
        responseAttachments,
        setResponseAttachments,
        history,
        match: { params: { userId, surveyId, surveyInstanceId, responseId }, path }
    } = props;

    useEffect(() => {
        if (userId) {
            userService.getUserById({ id: userId })
                .then(response => {
                    setBreadcrumb({
                        user: { ...response }
                    });
                });
        }
    }, [userId]);

    useEffect(() => {
        if (!surveyResponse || !surveyResponse.statisticsFilterTypeId) {
            return;
        }

        setStatisticsFilterLoading(false);
    }, [regions, regionGroups]);

    useEffect(() => {
        const regions = GLGlobal.loginInfo().profile && GLGlobal.loginInfo().profile.regionInfo ? GLGlobal.loginInfo().profile.regionInfo : [];
        setUserAccessibleRegions(regions);
        setSelectedRegion(regions.length > 0 ? regions[0].id : null);
    },[]);

    useEffect(() => {
        !sasUrl && getSASUrl();
        setSurveyResponseLoading(true);
        // set the type of user accessing response
        let type = (path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
            ? SurveyTodoType.Todo
            : (path === SurveyPathConfig.ContributePollFromLanding || path === SurveyPathConfig.ContributePollFromHistory)
                ? SurveyTodoType.ReceiverShared
                : (path === SurveyPathConfig.ViewPollFromHistory || path === SurveyPathConfig.ViewPollFromLanding)
                    ? SurveyTodoType.CreatorShared
                    : SurveyTodoType.Review

        serviceResponse.getSurveyResponses(surveyId, surveyInstanceId).then(data => {
            setPollResponse(data);
            setSurveyResponseLoading(false);
        })

        service.getSurveyResponse(surveyInstanceId, type, responseId).then(todo => {
            const setResponseData = (responseData) => {
                setSurveyResponse(responseData);
                setSurveyResponseLoading(false);
                setShareUserDetail(responseData);
            }
            if (todo.userId) {
                userService.getUserById({ id: todo.userId }).then(user => {
                    const getCampusName = (campusName) => {
                        return campusName ? ` > campusName` : '';
                    }
                    const todoResponse = {
                        ...todo,
                        displayDescription: getSurveyName(todo.description, todo.schoolName, todo.campusName, user.name)
                    }
                    setResponseData(todoResponse);
                });
            }
            else {
                setResponseData(todo);
            }
        })
            .catch(e => {
                if (e.error_code === ServerExceptionCode.NoPermissionException) {
                    history.push(PathConfig.AccessDenied);
                }
                else {
                    history.push(PathConfig.NotFound);
                }
            })

    }, []);

    useEffect(() => {
        if (!surveyResponse || !surveyResponse.surveyQuestion || !surveyResponse.surveyQuestion.length) {
            return;
        }

        const copyResponse = JSON.parse(JSON.stringify(surveyResponse));
        var todoResponseAnswers = copyResponse.surveyQuestion.flatMap(q =>
            q.surveyResponseAnswer.filter(a => responseAttachments.some(res => res.surveyResponseAnswerId === a.id))
        );
        todoResponseAnswers.forEach(responseAnswer => {
            responseAnswer.surveyResponseAttachment = responseAttachments.filter(att => att.surveyResponseAnswerId === responseAnswer.id);
        });
        setSurveyResponse(copyResponse);
    }, [props.responseAttachments]);

    const setShareUserDetail = (todo: TodoSurveyResponseModel) => {
        let searchedUsers: any[] = [];

        if (todo.surveyResponseShare) {
            searchedUsers = todo.surveyResponseShare.map(share => ({
                key: share.userId
            }));
            setSearchShareUser(searchedUsers);
        }

        let promises = [];
        const users = searchedUsers.map(share => share.key);
        users.length && setUserShareFetching(true);
        while (users.length > 0) {
            promises.push(userService.getItemsBy({ ids: users.splice(0, 150) }));
        }

        Promise.all(promises).then((d: any[]) => {
            searchedUsers = d
                .reduce((acc, item) => {
                    return acc.concat(item.data);
                }, [])
                .map(item => {
                    return {
                        key: item.id,
                        label: `${item.name} (${item.email})`
                    };
                });

            setSearchShareUser(searchedUsers);
            setSearchTempShareUser(searchedUsers);
            setUserShareFetching(false);
        });
    };

    const { getFieldDecorator } = form;

    const onCancel = () => {
        const isTouched = form.isFieldsTouched();
        if (isTouched) {
            Modal.confirm({
                title: fmtMsg({ id: SurveyLocale.SurveyChangesLostMsg }),
                onOk: () => {
                    goBack();
                }
            });
        } else {
            goBack();
        }
    };

    const getRegionAndTenantInfo = () => {
        const regionInfo = userAccessibleRegions.find(x => x.id == selectedRegion);
        let regionId = CONSTS.EmptyGuid;
        let tenantId = null;
        if (regionInfo) {
            regionId = regionInfo.id;
            tenantId = regionInfo.tenantId;
        }

        return { regionId, tenantId };
    }

    const goToEmailResponse = () => {
        setShowEmailResponse(true);
    };

    const goBack = () => {
        switch (path) {
            case SurveyPathConfig.CompletePollFromHistory:
            case SurveyPathConfig.ContributePollFromHistory:
            case SurveyPathConfig.ViewPollFromHistory:
                props.history.push(SurveyPathConfig.ReviewHistory);
                break;

            case SurveyPathConfig.CompletePollFromLanding:
            case SurveyPathConfig.ContributePollFromLanding:
            case SurveyPathConfig.ViewPollFromLanding:
                props.history.push(SurveyPathConfig.Home);
                break;

            case SurveyPathConfig.ReviewPollFromLanding:
                const { tab } = GLUtil.queryParse();
                if (tab === "responses") {
                    props.history.push(`${SurveyPathConfig.Home}/${surveyResponse.surveyId}?tab=responses`);
                } else {
                    props.history.push(SurveyPathConfig.Home);
                }
                break;

            case SurveyPathConfig.ReviewPollFromHistory:
                props.history.push(SurveyPathConfig.CreatedHistory);
                break;

            default:
                props.history.push(SurveyPathConfig.Home);
                break;
        }
    };

    // Few attachments could not be uploaded so, convert to draft if response was submitted.
    const convertToDraftCatchHandler = (isDraft: boolean, responseId: string) => {
        if (!isDraft) {
            service.convertToDraft(responseId).then(() => {
                message.error(fmtMsg({ id: SurveyLocale.SurveyCompleteAttachmentFailure }));
            });
        }
    };

    const getAnswerId = (question: SurveyQuestionResponseModel) => {
        if (question.surveyResponseAnswer.length) {
            return question.surveyResponseAnswer[0].id;
        }
        return null;
    }

    const getOptionId = (question: SurveyQuestionResponseModel, optText: string) => {
        const option = question.surveyQuestionOption.find(opt => opt.option === optText)
        return option ? option.id : null;
    }

    const getOptionText = (question: SurveyQuestionResponseModel, optId: string) => {
        const option = question.surveyQuestionOption.find(opt => opt.id === optId);
        return option ? option.option : null;
    }

    const isAnswerEmpty = (value) => {
        const answerElement = document.createElement("p");
        answerElement.innerHTML = value;
        const answer = answerElement.innerText.trim();
        return !answer.length;
    }

    const onSubmit = isDraft => {
        const fields = Object.getOwnPropertyNames(form.getFieldsValue());
        form.validateFields(isDraft ? surveyResponse.statisticsFilterTypeId ? ["statisticsFilterId"] : []
            : fields,
            (err, values) => {
                if (err && err.statisticsFilterId && err.statisticsFilterId.errors && err.statisticsFilterId.errors[0]) {
                    message.error(err.statisticsFilterId.errors[0].message);
                    return;
                }

                // validate answers when user submits the survey.
                if (!isDraft) {
                    const questionErrorMap = surveyResponse.surveyQuestion
                        .map((question, index) => {
                            if (question.required) {
                                let error = false;

                                switch (question.surveyQuestionTypeId) {
                                    case QuestionType.Score:
                                        error = question.surveyQuestionOption.some(opt => {
                                            const isNotApplicable = form.getFieldValue(`scoreCheckbox[${opt.id}]`);
                                            return !isNotApplicable && form.getFieldValue(`scoreSlider[${opt.id}]`) === 0;
                                        });
                                        break;

                                    case QuestionType.MultChoice:
                                        const selectedAnswers = form.getFieldValue(`answer[${question.id}]`);
                                        error = !selectedAnswers || !selectedAnswers.length;
                                        break;

                                    case QuestionType.Text:
                                        const textAnswer = form.getFieldValue(`answer[${question.id}]`);
                                        error = !textAnswer ? !textAnswer : isAnswerEmpty(textAnswer);
                                        break;

                                    default:
                                        error = !form.getFieldValue(`answer[${question.id}]`);
                                        break;
                                }

                                return {
                                    questionId: question.id,
                                    questionNo: (index + 1),
                                    error
                                };
                            } else {
                                return {
                                    questionId: question.id,
                                    questionNo: (index + 1),
                                    error: false,
                                };
                            }
                        })
                        .filter(errorMap => errorMap.error);

                    if (questionErrorMap.length) {
                        if (questionErrorMap.length === 1) {
                            message.error(fmtMsg({ id: SurveyLocale.SurveyQuestionPendingMessage }, { 0: questionErrorMap.map(e => e.questionNo).join() }));
                        } else {
                            message.error(fmtMsg({ id: SurveyLocale.SurveyQuestionsPendingMessage }, { 0: questionErrorMap.map(e => e.questionNo).join(", ") }));
                        }
                        return;
                    }
                }

                const { answer, statisticsFilterId, attachment, scoreSlider, scoreCheckbox } = isDraft ? form.getFieldsValue() : values;
                if (attachment && !sasUrl) {
                    getSASUrl();
                }
                const responseId = surveyResponse.id === CONSTS.EmptyGuid ? null : surveyResponse.id;
                let surveyResponseAnswer: SurveyAnswerModel[] = [];
                Object.entries(answer || {}).forEach(item => {
                    const ques = surveyResponse.surveyQuestion.find(q => q.id === item[0]);
                    if (ques.surveyQuestionTypeId === QuestionType.MultChoice) {
                        const multiChoices = item[1] as Array<string>;
                        if (multiChoices.length == 0) {
                            surveyResponseAnswer.push({
                                id: null,
                                answer: null,
                                surveyQuestionId: item[0],
                                surveyResponseId: responseId,
                                surveyQuestionOptionId: null,
                                surveyQuestionTypeId: ques.surveyQuestionTypeId
                            } as SurveyAnswerModel);
                        }
                        else {
                            multiChoices.forEach(selChoice => {
                                surveyResponseAnswer.push({
                                    id: null,
                                    answer: getOptionText(ques, selChoice),
                                    surveyQuestionId: item[0],
                                    surveyResponseId: responseId,
                                    surveyQuestionOptionId: selChoice,
                                    surveyQuestionTypeId: ques.surveyQuestionTypeId
                                } as SurveyAnswerModel);
                            });
                        }
                    } else if (ques.surveyQuestionTypeId === QuestionType.Option) {
                        surveyResponseAnswer.push({
                            id: getAnswerId(ques),
                            answer: item[1],
                            surveyQuestionId: item[0],
                            surveyResponseId: responseId,
                            surveyQuestionOptionId: getOptionId(ques, item[1] as string),
                            surveyQuestionTypeId: ques.surveyQuestionTypeId
                        } as SurveyAnswerModel);
                    } else {
                        surveyResponseAnswer.push({
                            id: getAnswerId(ques),
                            answer: item[1],
                            surveyQuestionId: item[0],
                            surveyResponseId: responseId,
                            surveyQuestionTypeId: ques.surveyQuestionTypeId
                        } as SurveyAnswerModel);
                    }
                });

                Object.entries(scoreSlider || {}).forEach(item => {
                    const ques = surveyResponse.surveyQuestion.find(q => q.surveyQuestionOption.findIndex(opt => opt.id === item[0]) >= 0);
                    const answer = surveyResponseAnswer.find(ans => ans.surveyQuestionOptionId === item[0]);
                    const existingAnswer = ques.surveyResponseAnswer.find(ans => ans.surveyQuestionOptionId === item[0]);

                    if (answer) {
                        answer.answer = item[1] as any;
                    } else {
                        surveyResponseAnswer.push({
                            id: existingAnswer ? existingAnswer.id : null,
                            answer: item[1],
                            surveyQuestionId: ques.id,
                            surveyResponseId: responseId,
                            surveyQuestionOptionId: item[0],
                            surveyQuestionTypeId: ques.surveyQuestionTypeId
                        } as SurveyAnswerModel);
                    }

                });

                Object.entries(scoreCheckbox || {}).forEach(item => {
                    const ques = surveyResponse.surveyQuestion.find(q => q.surveyQuestionOption.findIndex(opt => opt.id === item[0]) >= 0);
                    const answer = surveyResponseAnswer.find(ans => ans.surveyQuestionOptionId === item[0]);
                    const existingAnswer = ques.surveyResponseAnswer.find(ans => ans.surveyQuestionOptionId === item[0]);

                    if (answer) {
                        if (item[1]) {
                            answer.answer = -1 as any;
                        }
                    } else {
                        surveyResponseAnswer.push({
                            id: existingAnswer ? existingAnswer.id : null,
                            answer: item[1],
                            surveyQuestionId: ques.id,
                            surveyResponseId: responseId,
                            surveyQuestionOptionId: item[0],
                            surveyQuestionTypeId: ques.surveyQuestionTypeId
                        } as SurveyAnswerModel);
                    }
                });

                const todoRequest: TodoSurveyRequestModel = {
                    id: responseId,
                    surveyInstanceId: surveyInstanceId,
                    statisticsFilterId,
                    responseStatus: isDraft ? ResponseStatusType.Draft : ResponseStatusType.Submitted,
                    surveyResponseAnswer,
                    surveyResponseShare: searchShareUser.map(a => {
                        return { userId: a.key };
                    }),
                    isShareModified
                };
                service.fillSurvey(todoRequest).then(responseMap => {
                    message.success(isDraft ? fmtMsg({ id: SurveyLocale.SurveyFillSaveAsDraftSuccess }) : fmtMsg({ id: SurveyLocale.SurveyFillSuccess }));
                    !isDraft && props.reload({ shouldReloadReviewSurveys: true });
                    if (responseMap && attachment) {
                        const { responseId, questionAnswerMap } = responseMap;

                        const uploadPromises = getUploadPromises(attachment, questionAnswerMap);
                        uploadPromises.length &&
                            Promise.all(uploadPromises)
                                .then(attachmentModels => {
                                    const successAttachments = attachmentModels.filter(a => a.success);
                                    successAttachments.length !== attachmentModels.length && convertToDraftCatchHandler(isDraft, responseId);
                                    successAttachments.length &&
                                        service.saveAttachments(successAttachments).then(attachmentResponse => {
                                            const attachments = surveyResponse.surveyQuestion.flatMap(q =>
                                                q.surveyResponseAnswer.flatMap(a =>
                                                    a.surveyResponseAttachment.filter(at => !removedAttachments.some(r => r.uid === at.id))
                                                )
                                            );
                                            let answerAttachments = attachments.filter(at =>
                                                attachmentResponse.some(r => r.surveyResponseAnswerId === at.surveyResponseAnswerId)
                                            );
                                            answerAttachments = answerAttachments.concat(attachmentResponse);
                                            if ((path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
                                                || path === SurveyPathConfig.ContributePollFromLanding || path === SurveyPathConfig.ContributePollFromHistory) {
                                                setResponseAttachments(answerAttachments);
                                            }
                                        });
                                })
                                .catch(reason => {
                                    convertToDraftCatchHandler(isDraft, responseId);
                                });

                        if (removedAttachments.length) {
                            const request = removedAttachments.map(({ uid, name, surveyResponseAnswerId }) => ({
                                id: uid,
                                name: name,
                                surveyResponseAnswerId: surveyResponseAnswerId
                            }));

                            service.deleteAttachments(request)
                                .then(() => {
                                    const attachments = surveyResponse.surveyQuestion.flatMap(q =>
                                        q.surveyResponseAnswer.flatMap(a =>
                                            a.surveyResponseAttachment.filter(att => !request.some(d => d.id === att.id))
                                        )
                                    );
                                    if (path === SurveyPathConfig.CompletePollFromLanding
                                        || path === SurveyPathConfig.CompletePollFromHistory
                                        || path === SurveyPathConfig.ContributePollFromLanding
                                        || path === SurveyPathConfig.ContributePollFromHistory) {
                                        setResponseAttachments(attachments);
                                    }
                                })
                                .catch(() => {
                                    convertToDraftCatchHandler(isDraft, responseId);
                                });
                        }
                        goBack();
                    }
                    else {
                        goBack();
                    }
                });
            });
    };

    const onReviewSubmit = () => {
        if (!surveyResponse.id) {
            return;
        }

        service
            .review(surveyResponse.id)
            .then(() => {
                reload({ shouldReloadReviewSurveys: true });
                goBack();
                message.success(fmtMsg({ id: SurveyLocale.SurveyReviewSuccess }));
            })
            .catch(() => {
                message.error(fmtMsg({ id: SurveyLocale.SurveyReviewError }));
            });
    };

    const onMarkAsReadClick = () => {
        if (!surveyResponse.id) {
            return;
        }
        const instanceId = props.match.params.surveyInstanceId;
        const responseId = props.match.params.responseId;
        service
            .markAsRead(instanceId, responseId)
            .then(() => {
                props.reload({ shouldReloadReviewSurveys: true });
                goBack();
                message.success(fmtMsg({ id: SurveyLocale.SurveyMarkAsReadSuccess }));
            })
            .catch((e) => {
                if (e.error_code === ServerExceptionCode.NoPermissionException) {
                    history.push(PathConfig.AccessDenied);
                }
                else {
                    message.error(fmtMsg({ id: SurveyLocale.SurveyMarksAsReadError }));
                }
            });
    }

    const getUploadPromises = (attachment, quesAnsMaps: QuestionAnswerMap[]): Promise<SaveAttachmentResponse>[] => {
        return Object.entries(attachment)
            .flatMap(item => {
                const matchRes = item[0].match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/gi);
                const answerId = matchRes.length === 1 ? quesAnsMaps.find(qa => qa.questionId === matchRes[0]).answerId : matchRes[1];

                if (!item[1] || !item[1].length) {
                    return;
                }

                return item[1]
                    .filter(({ originFileObj }) => !!originFileObj)
                    .map(file => {
                        let browserFile = null;
                        const { originFileObj } = file;
                        if (!navigator.msSaveBlob) {
                            browserFile = new File([originFileObj], `${file.name}`, { type: file.type });
                        } else {
                            // workaround for edge browser as it doesn't support File Api constructor.
                            browserFile = new Blob([originFileObj], { type: file.type });
                            browserFile = blobToFile(browserFile, `${file.name}`);
                        }
                        const uploadAborter = Aborter.none;
                        const blockBlobURL = generateBlockBlobUrl(sasUrl, browserFile, answerId);
                        const fileSize = browserFile.size;
                        const cancelUpload = (fileId: string) => {
                            uploadAborter.abort();
                            removeUploadStatus(fileId);
                        };

                        const fileNameSplit = file.name.split(".");
                        const fileType = fileNameSplit[fileNameSplit.length - 1];
                        let materialIcon = "attachment";

                        if (acceptedImageTypes.indexOf(fileType) >= 0) {
                            materialIcon = "image";
                        } else if (acceptedAudioTypes.indexOf(fileType) >= 0) {
                            materialIcon = "audiotrack";
                        } else if (acceptedVideoTypes.indexOf(fileType) >= 0) {
                            materialIcon = "play_circle_fill";
                        }

                        addUploadStatus({
                            id: originFileObj.uid + file.name,
                            title: file.name,
                            subtitle: `0MB/${(file.size / 1000000).toFixed(3)}MB`,
                            materialIcon,
                            progress: 1,
                            status: StatusType.Progress,
                            onCancel: cancelUpload.bind(null, originFileObj.uid + file.name)
                        });

                        return uploadBrowserDataToBlockBlob(uploadAborter, browserFile, blockBlobURL, {
                            blockSize: 4 * 1024 * 1024,
                            parallelism: 20, // 20 concurrency
                            progress: (ev: TransferProgressEvent) => {
                                updateUploadStatus({
                                    id: originFileObj.uid + file.name,
                                    title: file.name,
                                    subtitle: `${(ev.loadedBytes / 1000000).toFixed(3)}MB/${(fileSize / 1000000).toFixed(3)}MB`,
                                    materialIcon,
                                    progress: Math.floor((ev.loadedBytes / fileSize) * 100),
                                    status: StatusType.Progress,
                                    onCancel: cancelUpload.bind(null, originFileObj.uid + file.name)
                                });
                            }
                        })
                            .then(_ => {
                                updateUploadStatus({
                                    id: originFileObj.uid + file.name,
                                    title: file.name,
                                    materialIcon,
                                    status: StatusType.Completed,
                                    onCancel: cancelUpload.bind(null, originFileObj.uid + file.name)
                                });
                                return { surveyResponseAnswerId: answerId, name: file.name, success: true } as SaveAttachmentResponse;
                            })
                            .catch(_ => {
                                updateUploadStatus({
                                    id: originFileObj.uid + file.name,
                                    title: file.name,
                                    materialIcon,
                                    status: StatusType.Error,
                                    onCancel: cancelUpload.bind(null, originFileObj.uid + file.name)
                                });
                                return { surveyResponseAnswerId: answerId, name: file.name, success: false } as SaveAttachmentResponse;
                            });
                    });
            })
            .filter(item => !!item);
    };

    const generateBlockBlobUrl = (sasUrl, browserFile, answerId) => {
        const pipeline = StorageURL.newPipeline(new AnonymousCredential(), {
            retryOptions: { maxTries: 4 }, // Retry options
            telemetry: { value: "HighLevelSample V1.0.0" } // Customized telemetry string
        });
        const serviceURL = new ServiceURL(sasUrl, pipeline);
        const containerURL = ContainerURL.fromServiceURL(serviceURL, CONSTS.SurveyAttachmentContainer);
        const blobURL = BlobURL.fromContainerURL(containerURL, `${answerId}/${browserFile.name}`);
        return BlockBlobURL.fromBlobURL(blobURL);
    };

    const blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob;
        b.name = fileName;
        return b as File;
    };

    const onStatisticsFilterTypeFocus = (filterTypeId: StatisticsFilterType) => {
        switch (filterTypeId) {
            case StatisticsFilterType.Region:
                if (!regions.length) {
                    getRegions();
                    setStatisticsFilterLoading(true);
                }
                break;

            case StatisticsFilterType.RegionGroup:
                if (!regionGroups.length) {
                    getRegionGroups();
                    setStatisticsFilterLoading(true);
                }
            default:
                break;
        }
    };

    const getStatFilterOptions = (filterType: StatisticsFilterType, statisticsFilterId: string, statisticsFilter: string) => {
        let options: StatisticsFilterModel[];

        switch (filterType) {
            case StatisticsFilterType.Region:
                options = regions.length ? regions : (([{ id: statisticsFilterId, name: statisticsFilter }] as any) as RegionModel[]);
                break;

            case StatisticsFilterType.RegionGroup:
                options = regionGroups.length ? regionGroups : (([{ id: statisticsFilterId, name: statisticsFilter }] as any) as RegionGroupModel[]);
                break;

            default:
                break;
        }

        return options.map(({ id, name }: StatisticsFilterModel) => (
            <Option key={id} value={id}>
                {name}
            </Option>
        ));
    };

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("OK");
        });
    };

    const getExistingFileList = (attachments: SurveyAttachmentModel[]) => {
        return attachments.map(({ id, name, url, surveyResponseAnswerId }) => ({
            uid: id,
            name,
            url,
            status: "done",
            data: {
                surveyResponseAnswerId
            },
            isValid: true
        }));
    };

    const normalizeFile = e => {
        if (Array.isArray(e)) {
            return e;
        }

        if (e.file && e.file.status === "removed" && e.file.data && e.file.data.surveyResponseAnswerId) {
            setRemovedAttachments([
                ...removedAttachments,
                {
                    uid: e.file.uid,
                    name: e.file.name,
                    surveyResponseAnswerId: e.file.data.surveyResponseAnswerId
                }
            ]);
        }

        return e.fileList.filter(f => f.isValid);
    };

    const beforeUpload = (file: RcFileExtended) => {
        const splitName = file.name.split(".");
        let isValidType = true;

        if (splitName && splitName.length) {
            // converting the filetype to lowercase before checking it is present in acceptedFileTypes.
            // This is done since the filetype can either be "png" or "PNG".
            isValidType = acceptedFileTypes.indexOf(splitName[splitName.length - 1].toLowerCase()) >= 0;
        }

        const isValidSize = file.size / 1024 / 1024 <= fileSizeLimit;
        file.isValid = isValidType && isValidSize;

        if (!isValidType) {
            message.error(fmtMsg({ id: SurveyLocale.SurveyInvalidFileType }));
        } else if (!isValidSize) {
            message.error(fmtMsg({ id: SurveyLocale.SurveyFileSizeError }));
        }

        return isValidType && isValidSize;
    };

    const showSharedModel = () => {
        setShareModalVisible(true);
    };

    const renderForm = (todo: TodoSurveyResponseModel) => {
        const { isStatisticsRequired, isUserStatisticsReport, dueDate, name, statisticsFilterTypeId, statisticsFilterId, statisticsFilter, isDigitalAdoptionReport, isSchoolAndClassesReport } = todo;
        const statisticsFilterLabel = statisticsFilterTypeId
            ? statisticsFilterTypeId == StatisticsFilterType.Region
                ? fmtMsg({ id: SurveyLocale.SurveyFillRegion })
                : fmtMsg({ id: SurveyLocale.SurveyFillRegionGroup })
            : null;
        const statPlaceHolder = `${fmtMsg({
            id: SurveyLocale.SurveyFillSelect
        })} ${statisticsFilterLabel}`;
        const disabled = (path === SurveyPathConfig.ViewPollFromLanding || path === SurveyPathConfig.ViewPollFromHistory);

        const cardTitle = (
            <>
                <span>{name}</span> -
                <span className={isPastDate(dueDate) ? "error-color" : "text-muted"}>
                    {DateHelper.formatDateAsIs(dueDate as string)}
                </span>
            </>
        );

        const isUserOnlyTeacher = (): boolean => {
            if (ContextHelper.getUserRoles().size === 1 && ContextHelper.isUserMatchRole(RoleName.teacher)) {
                return true;
            }
            return false;
        }

        const goToUserResponse = (surveyIdUpDown: string, userId: string, surveyResponseIdUpDown: string) => {
            if (path === SurveyPathConfig.ReviewPollFromLanding) {
                history.push(`${SurveyPathConfig.Home}/${surveyIdUpDown}/${surveyInstanceId}/${userId}/review/${surveyResponseIdUpDown}`)
            }
            if (path === SurveyPathConfig.ReviewPollFromHistory) {
                history.push(`${SurveyPathConfig.Home}/${surveyIdUpDown}/${surveyInstanceId}/createdhistory/${userId}/review/${surveyResponseIdUpDown}`)
            }
            if (path === SurveyPathConfig.ViewPollFromLanding) {
                history.push(`${SurveyPathConfig.Home}/${surveyIdUpDown}/${surveyInstanceId}/${userId}/view/${surveyResponseIdUpDown}`)
            }
            if (path === SurveyPathConfig.ViewPollFromHistory) {
                history.push(`${SurveyPathConfig.Home}/${surveyIdUpDown}/${surveyInstanceId}/reviewhistory/${userId}/view/${surveyResponseIdUpDown}`)
            }
        };

        const cardExtra = () => {

            const showLink = (path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
                && !isUserOnlyTeacher();

            let listResponseCompleted = [];
            let userNameResponse;
            let userCompleted;
            let indexUser;
            let surveyResponseIdUp;
            let userIdUp;
            let surveyResponseIdDown;
            let userIdDown;

            pollResponse.response.map(item => {
                item.instanceResponse.forEach(data => {
                    listResponseCompleted.push(data);
                })
            })

            userCompleted = listResponseCompleted.length;

            listResponseCompleted.forEach((item, index) => {
                if (item.surveyResponseId === responseId) {
                    userNameResponse = item.responderName;
                    const currentIndex = listResponseCompleted.indexOf(item);
                    const upIndex = currentIndex - 1;
                    const downIndex = currentIndex + 1;
                    indexUser = currentIndex + 1;
                    if (upIndex >= 0) {
                        surveyResponseIdUp = listResponseCompleted[upIndex].surveyResponseId;
                        userIdUp = listResponseCompleted[upIndex].responderId;
                    }
                    if (downIndex < listResponseCompleted.length) {
                        surveyResponseIdDown = listResponseCompleted[downIndex].surveyResponseId;
                        userIdDown = listResponseCompleted[downIndex].responderId;
                    }
                }
            })

            return showLink ? (
                <Badge showZero count={searchShareUser.length}>
                    <Button className="share-survey" size="default" onClick={() => showSharedModel()} icon="share-alt">
                        {fmtMsg({ id: SurveyLocale.SurveyFillShare })}
                    </Button>
                </Badge>
            ) : (pollResponse && responseId && listResponseCompleted.length > 0 && userNameResponse ?
                <div className="review-survey-up-down">
                    <span className="review-survey-up-down__from">{fmtMsg({ id: SurveyLocale.PollFrom })}</span>: <span className="review-survey-up-down__username">{userNameResponse}</span> <span className="review-survey-up-down__count">{indexUser}/{userCompleted}</span>
                    {indexUser === 1 ? <span className="review-survey-up-down__btn"><Icon type="caret-up" /></span> : <a className="review-survey-up-down__btn" onClick={() => goToUserResponse(surveyId, userIdUp, surveyResponseIdUp)} href="javascript:void(0)"><Icon type="caret-up" /></a>}
                    {indexUser === userCompleted ? <span className="review-survey-up-down__btn"><Icon type="caret-down" /></span> : <a className="review-survey-up-down__btn" onClick={() => goToUserResponse(surveyId, userIdDown, surveyResponseIdDown)} href="javascript:void(0)"><Icon type="caret-down" /></a>}
                </div> : null
            );
        };

        const userShareSelectSearch = debounce(searchUser => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            onShareUserSelectSearch(searchUser.trim());
        }, 800);

        const onShareUserSelectSearch = searchedUser => {
            lastShareFetchId += lastShareFetchId;
            const fetchId = lastShareFetchId;
            const regionInfo = getRegionAndTenantInfo();
            setProbablyShareUsers([]);
            setUserShareFetching(true);
            userService
                .getSubordinates({
                    userName: searchedUser,
                    email: searchedUser,
                    includeSameLevel: false,
                    receiverShared: true,
                    regionId: regionInfo.regionId,
                    tenantId: regionInfo.tenantId
                })
                .then(d => {
                    if (fetchId !== lastShareFetchId) return;
                    const probablyShareUsers = d
                        .map(user => ({
                            id: user.id,
                            name: user.name,
                            email: user.email
                        }))
                        .filter(user => GLGlobal.loginInfo().profile.sub != user.id && !searchTempShareUser.some(su => su.key === user.id));
                    setProbablyShareUsers(probablyShareUsers);
                    setUserShareFetching(false);
                });
        };

        const onShareUserSelectChange = searchTempShareUser => {
            const maxNumberOfUserToShare = 10;

            if (searchTempShareUser.length > maxNumberOfUserToShare) {
                const errorMsg = fmtMsg({ id: SurveyLocale.SurveyFillShareModalMaxUserError }).replace(
                    "{maxUsers}",
                    maxNumberOfUserToShare.toString()
                );
                message.error(errorMsg);
                return;
            }
            setSearchTempShareUser(searchTempShareUser);
            setProbablyShareUsers([]);
            setUserShareFetching(false);
        };

        const onRegionChange = (value) => {
           setSelectedRegion(value)
        }

        const renderShareSelectUserModal = () => {
            const isDisabled = (surveyResponse.responseStatus && surveyResponse.responseStatus !== ResponseStatusType.Draft) ||
                !(path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
            const hide = () => {
                setProbablyShareUsers([]);
                setShareModalVisible(false);
                setUserShareFetching(false);
                setSearchTempShareUser(searchShareUser);
                setSelectedRegion(userAccessibleRegions.length > 0 ? userAccessibleRegions[0].id : null)
            };
            const save = () => {
                setProbablyShareUsers([]);
                setShareModalVisible(false);
                setUserShareFetching(false);
                setSearchShareUser(searchTempShareUser);
                setIsShareModified(true);
            };

            const fmtEmail = ({ name, email }) => {
                return `${name} ${email ? `(${email})` : ""}`;
            };
            return (
                <Modal
                    title={fmtMsg({
                        id: SurveyLocale.SurveyFillShareModalTitle
                    })}
                    visible={shareModalVisible}
                    okText={buttonSubmitText}
                    okButtonProps={{ disabled: isDisabled }}
                    className={mergeClasses("notified-user-modal share-survey-modal", isDisabled && "share-survey-modal--readonly")}
                    maskClosable={false}
                    destroyOnClose
                    onOk={() => {
                        save();
                    }}
                    onCancel={() => hide()}
                >
                    <div className="modal-select-section">
                        {fmtMsg({
                            id: SurveyLocale.SurveyFillShareModalText
                        })}
                    </div>

                    {userAccessibleRegions.length > 1 && <div className="region-section">
                        <p>
                            <label htmlFor="Region" className="ant-form-item-required">
                                {fmtMsg({
                                    id: GSAdminLocale.NotificationCreateRegion
                                })}
                            </label>
                        </p>
                        <Select value={selectedRegion} showSearch={true} onChange={onRegionChange}
                            filterOption={
                                (input, option) => {
                                    return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                            }>

                            {userAccessibleRegions.map(d => (
                                <Select.Option key={d.id} value={d.id}>
                                    {d.englishName}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>}

                    <Select
                        disabled={isDisabled}
                        mode="multiple"
                        labelInValue
                        style={{ width: "100%" }}
                        dropdownClassName={`drop-down ${userShareFetching ? "loading" : ""}`}
                        value={searchTempShareUser}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={userShareFetching ? <Spin size="small" /> : null}
                        onSearch={d => userShareSelectSearch(d)}
                        onChange={item => {
                            onShareUserSelectChange(item);
                        }}
                    >
                        {probablyShareUsers.map(d => (
                            <Select.Option key={d.id} title={fmtEmail(d)}>
                                {fmtEmail(d)}
                            </Select.Option>
                        ))}
                    </Select>

                    <span className="modal-note-section">
                        {fmtMsg({
                            id: SurveyLocale.SurveyFillShareModalNote
                        })}
                    </span>
                </Modal>
            );
        };

        const checkboxChange = (optionId: string, checked: boolean, minScore: number) => {
            if (checked) {
                form.setFieldsValue({
                    [`scoreSlider[${optionId}]`]: -1
                });
            } else {
                form.setFieldsValue({
                    [`scoreSlider[${optionId}]`]: minScore
                });
            }
        }

        const onRichTextValueChanged = (val, questionId) => {
            form.setFieldsValue({ [`answer[${questionId}]`]: val });
        }

        const textFieldValidator = (rule, value, callback) => {
            if (isAnswerEmpty(value)) {
                callback(fmtMsg(SurveyLocale.SurveyAnswerRequired));
            } else {
                callback();
            }
        }

        const renderAnswer = (question: SurveyQuestionResponseModel, disabledInput: boolean) => {
            const answers = question.surveyResponseAnswer;
            const singleAnswer = answers.length ? answers[0] : undefined;
            const multAnswer = answers.length ? answers : undefined;
            const singleAnswerText = !!singleAnswer ? singleAnswer.answer : undefined;
            const singleAnswerId = !!singleAnswer ? singleAnswer.id : undefined;
            const multAnswerIds = answers.length ? multAnswer.map(a => a.surveyQuestionOptionId) : [];

            const initialValue = question.surveyQuestionTypeId === QuestionType.Rating
                ? typeof singleAnswerText != "number" && isNaN(parseInt(singleAnswerText)) ? 0 : parseInt(singleAnswerText)
                : question.surveyQuestionTypeId === QuestionType.MultChoice
                    ? multAnswerIds
                    : singleAnswerText;

            const options = question.surveyQuestionTypeId === QuestionType.MultChoice
                ? {
                    valuePropName: "value",
                    initialValue: initialValue,
                    rules: [{ type: "array", required: question.required, message: fmtMsg(SurveyLocale.SurveyAnswerRequired) }]
                }
                : question.surveyQuestionTypeId === QuestionType.Text
                    ? {
                        initialValue: initialValue,
                        rules: [{ required: question.required, message: fmtMsg(SurveyLocale.SurveyAnswerRequired) }, { validator: textFieldValidator }]
                    }
                    : {
                        initialValue: initialValue,
                        rules: [{ required: question.required, message: fmtMsg(SurveyLocale.SurveyAnswerRequired) }]
                    };

            const renderContentResources = (value) => {
                const contents = value.split("\t");

                return contents.length > 1 ?
                    contents.map(content => `<p style="margin-bottom: 0;">${content}</p>`).join("") : value;
            };

            const showAttachment = question.surveyQuestionTypeId === QuestionType.Text;
            const surveyResponseAttachment = !!singleAnswer ? singleAnswer.surveyResponseAttachment : [];
            const marks = question.surveyQuestionTypeId === QuestionType.Score ? createSliderMarks(question.minScore, question.maxScore, MaxSurveySliderStepCount) : {};

            // @ts-ignore
            return <>
                <Form.Item key={question.id}>
                    <Row>
                        <Col>
                            {(question.surveyQuestionTypeId === QuestionType.Option
                                || question.surveyQuestionTypeId === QuestionType.MultChoice
                                || question.surveyQuestionTypeId === QuestionType.Rating
                                || question.surveyQuestionTypeId === QuestionType.Text) &&
                                getFieldDecorator(`answer[${question.id}]`, options)(
                                    question.surveyQuestionTypeId === QuestionType.Option
                                        ? <Row>
                                            <RadioGroup disabled={disabledInput} defaultValue={initialValue}>
                                                {
                                                    question.surveyQuestionOption.map((opt, key) =>
                                                        <Col span={24} key={opt.id}><Radio value={opt.option} className="questions__option">{opt.option}</Radio></Col>)
                                                }
                                            </RadioGroup>
                                        </Row>
                                        : question.surveyQuestionTypeId === QuestionType.MultChoice
                                            ? <Checkbox.Group disabled={disabledInput}>
                                                {
                                                    question.surveyQuestionOption.map((opt, key) => {
                                                        return <Col span={24} key={opt.id}>
                                                            <Checkbox value={opt.id} className="questions__option">{isExitSurvey(question.surveyId) ? `${fmtMsg({ id: opt.localeKey })}` : opt.option}</Checkbox>
                                                        </Col>
                                                    })
                                                }
                                            </Checkbox.Group>
                                            : question.surveyQuestionTypeId === QuestionType.Rating
                                                ? (question.surveyQuestionRatingType === 1 ? <Rate disabled={disabledInput} className="questions__rate" count={question.maxRating} /> : <Rate disabled={disabledInput} className="questions__rate" character={<Icon type="like" />} count={question.maxRating} />)
                                                : disabledInput ?
                                                    <div dangerouslySetInnerHTML={{ __html: initialValue ? DOMPurify.sanitize(unescape(renderContentResources(initialValue + "")), { ADD_ATTR: ["target"] }) : "" }}></div>
                                                    : <SurveyRichTextEditor
                                                        autoShowToolbar={true}
                                                        editorHtml={initialValue ? renderContentResources(initialValue + "") : null}
                                                        onValueChanged={value => onRichTextValueChanged(value, question.id)}>
                                                    </SurveyRichTextEditor>
                                )}
                            {
                                question.surveyQuestionTypeId === QuestionType.Score &&
                                <>
                                    <Row className="questions__score__headers" type="flex" justify="space-between" align="middle">
                                        <Col>
                                            <label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeOptions })}</label>
                                        </Col>
                                        <Col>
                                            <label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeScore })}</label>
                                        </Col>
                                        <Col>
                                            {
                                                question.isNaEnabled &&
                                                <label>{fmtMsg({ id: SurveyLocale.SurveyNotApplicableLabel })}</label>
                                            }
                                        </Col>
                                    </Row>
                                    {question.surveyQuestionOption.map(option => {
                                        const answer = question.surveyResponseAnswer.find(ans => ans.surveyQuestionOptionId === option.id);
                                        const isNotApplicable = form.getFieldValue(`scoreCheckbox[${option.id}]`) || false;
                                        let initialValue = question.minScore;

                                        if (answer) {
                                            initialValue = parseInt(answer.answer);
                                        }

                                        return (<Row className="questions__score" type="flex" justify="space-between" align="middle">
                                            <Col span={6}>
                                                <label>{option.option}</label>
                                            </Col>
                                            <Col span={12}>
                                                {
                                                    getFieldDecorator(`scoreSlider[${option.id}]`, {
                                                        valuePropName: 'value',
                                                        initialValue: initialValue,
                                                        rules: [{ required: question.required, message: fmtMsg(SurveyLocale.SurveyAnswerRequired) }]
                                                    })(
                                                        <Slider
                                                            marks={marks}
                                                            disabled={disabledInput || isNotApplicable}
                                                            defaultValue={!answer || parseInt(answer.answer) === -1 ? question.minScore : parseInt(answer.answer)}
                                                            min={question.minScore}
                                                            max={question.maxScore}>
                                                        </Slider>
                                                    )
                                                }
                                            </Col>
                                            <Col>
                                                {
                                                    question.isNaEnabled &&
                                                    getFieldDecorator(`scoreCheckbox[${option.id}]`, {
                                                        valuePropName: 'checked',
                                                        initialValue: answer && parseInt(answer.answer) === -1,
                                                        rules: [{ required: question.required, message: fmtMsg(SurveyLocale.SurveyAnswerRequired) }]
                                                    })(
                                                        <Checkbox onChange={e => checkboxChange(option.id, e.target.checked, question.minScore)} disabled={disabledInput} checked={answer && parseInt(answer.answer) === -1}></Checkbox>
                                                    )
                                                }
                                            </Col>
                                        </Row>);
                                    })}
                                </>
                            }
                        </Col>
                    </Row>
                </Form.Item>
                {showAttachment &&
                    <Form.Item className="questions__attachment">
                        <Row>
                            <Col>
                                {getFieldDecorator(question.surveyResponseAnswer.length ? `attachment[${question.id}-${singleAnswerId}]` : `attachment[${question.id}]`, {
                                    valuePropName: "fileList",
                                    getValueFromEvent: normalizeFile,
                                    initialValue:
                                        surveyResponseAttachment && surveyResponseAttachment.length && getExistingFileList(surveyResponseAttachment)
                                })(
                                    <Upload
                                        className="questions__upload"
                                        accept={acceptedFileTypes}
                                        beforeUpload={beforeUpload}
                                        customRequest={dummyRequest}
                                        multiple
                                        showUploadList={{ showRemoveIcon: !disabled }}
                                        disabled={
                                            surveyResponse.responseStatus === ResponseStatusType.Submitted ||
                                            surveyResponse.responseStatus === ResponseStatusType.Reviewed ||
                                            disabled
                                        }
                                    >
                                        <Icon type="paper-clip" />
                                    </Upload>
                                )}
                            </Col>
                        </Row>
                    </Form.Item>
                }
            </>
        };

        const onEmailResponseCancel = () => {
            setShowEmailResponse(false);
        }

        const disabledRegionSelect = (surveyResponse.responseStatus === ResponseStatusType.Submitted
            || surveyResponse.responseStatus === ResponseStatusType.Reviewed
            || (path === SurveyPathConfig.ContributePollFromLanding || path === SurveyPathConfig.ContributePollFromHistory)
            || (path == SurveyPathConfig.ViewPollFromLanding || path === SurveyPathConfig.ViewPollFromHistory));
        const disabledAnswerInput = surveyResponse.responseStatus === ResponseStatusType.Submitted ||
            surveyResponse.responseStatus === ResponseStatusType.Reviewed ||
            (path == SurveyPathConfig.ViewPollFromLanding || path == SurveyPathConfig.ViewPollFromHistory);
        const displayDescription = surveyResponse && surveyResponse.description ?
            (surveyResponse.displayDescription ? surveyResponse.displayDescription : surveyResponse.description) :
            null;
        return (
            showEmailResponse ? <EmailResponse surveyResponse={surveyResponse} onCancel={onEmailResponseCancel} /> :
                <Card title={cardTitle} extra={cardExtra()} className="fs" bodyStyle={{ padding: "24px 0px" }} headStyle={{ padding: "0 16px" }}>
                    {
                        displayDescription &&
                        (<Row className="fs__description fs__description--pad-bottom-10">
                            <Col span={24}>
                                {displayDescription}
                            </Col>
                        </Row>)
                    }
                    <GLForm form={form}>
                        {statisticsFilterTypeId ? (
                            <Form.Item
                                className={mergeClasses("fs__region-select", disabledRegionSelect && "fs__region-select--readonly")}
                                label={
                                    <>
                                        <Icon type="select" className="fs__region-select__icon" />
                                        {statisticsFilterLabel}
                                    </>
                                }
                            >
                                <Row type="flex">
                                    <Col xs={24} md={12}>
                                        {getFieldDecorator("statisticsFilterId", {
                                            initialValue: statisticsFilterId,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: fmtMsg({ id: SurveyLocale.SurveyFilleShareRegionRequired })
                                                }
                                            ]
                                        })(
                                            <Select
                                                disabled={disabledRegionSelect}
                                                placeholder={statPlaceHolder}
                                                loading={statisticsFilterLoading}
                                                onFocus={() => onStatisticsFilterTypeFocus(statisticsFilterTypeId)}
                                            >
                                                {getStatFilterOptions(statisticsFilterTypeId, statisticsFilterId, statisticsFilter)}
                                            </Select>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Item>
                        ) : null}
                        {
                            isStatisticsRequired
                            && (todo.responseStatus === ResponseStatusType.Submitted || todo.responseStatus === ResponseStatusType.Reviewed)
                            && isDigitalAdoptionReport
                            && (path === SurveyPathConfig.ReviewPollFromLanding || path === SurveyPathConfig.ReviewPollFromHistory
                                || path === SurveyPathConfig.ViewPollFromHistory || path === SurveyPathConfig.ViewPollFromLanding)
                            && <DigitalAdoptionReport surveyResponse={todo}></DigitalAdoptionReport>
                        }
                        {
                            isStatisticsRequired
                            && (todo.responseStatus === ResponseStatusType.Submitted || todo.responseStatus === ResponseStatusType.Reviewed)
                            && isSchoolAndClassesReport
                            && (path === SurveyPathConfig.ReviewPollFromLanding || path === SurveyPathConfig.ReviewPollFromHistory
                                || path === SurveyPathConfig.ViewPollFromHistory || path === SurveyPathConfig.ViewPollFromLanding) &&
                            <SchoolAndClassesReport surveyResponse={todo}></SchoolAndClassesReport>
                        }
                        {
                            isStatisticsRequired
                            && (todo.responseStatus === ResponseStatusType.Submitted || todo.responseStatus === ResponseStatusType.Reviewed)
                            && isSchoolAndClassesReport
                            && (path === SurveyPathConfig.ReviewPollFromLanding || path === SurveyPathConfig.ReviewPollFromHistory
                                || path === SurveyPathConfig.ViewPollFromHistory || path === SurveyPathConfig.ViewPollFromLanding) &&
                            <LicenseHistoryReport surveyResponse={todo}></LicenseHistoryReport>
                        }
                        {
                            isStatisticsRequired
                            && (todo.responseStatus === ResponseStatusType.Submitted || todo.responseStatus === ResponseStatusType.Reviewed)
                            && isUserStatisticsReport
                            && (path === SurveyPathConfig.ReviewPollFromLanding || path === SurveyPathConfig.ReviewPollFromHistory
                                || path === SurveyPathConfig.ViewPollFromHistory || path === SurveyPathConfig.ViewPollFromLanding) &&
                            <UserStatisticsReport surveyResponse={todo}></UserStatisticsReport>
                        }
                        <div className="ant-collapse-header">{fmtMsg({ id: SurveyLocale.SurveyCreateQuestion })}</div>
                        {
                            <List
                                dataSource={todo.surveyQuestion}
                                renderItem={(item, index) => (
                                    <List.Item key={index} className={mergeClasses("fs__list-item", disabledAnswerInput && "fs__list-item--readonly")}>
                                        {
                                            <Card
                                                className={mergeClasses("questions", item.surveyQuestionTypeId !== QuestionType.Text && "questions--not-text")}
                                                title={
                                                    <div className="questions__card-head">
                                                        <QLetterIcon />
                                                        <span className="questions__number">{index + 1}. </span>
                                                        {item.required && <sup className="questions__required">*</sup>}
                                                        <span className="questions__text">{isExitSurvey(item.surveyId) ? `${fmtMsg({ id: item.localeKey })}` : item.question}</span>
                                                    </div>
                                                }
                                                key={item.id}
                                            >
                                                {renderAnswer(item, disabledAnswerInput)}
                                            </Card>
                                        }
                                    </List.Item>
                                )}
                            />
                        }
                        <Row type="flex" justify="end" className="fs__fill-btn-group" gutter={8}>
                            <Col className="fs__fill-btn-group__buttons">
                                {(path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
                                    || (path === SurveyPathConfig.ContributePollFromLanding || path === SurveyPathConfig.ContributePollFromHistory) ? (
                                    <>
                                        <Button onClick={onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                                        {!(surveyResponse.responseStatus === ResponseStatusType.Submitted ||
                                            surveyResponse.responseStatus === ResponseStatusType.Reviewed) &&
                                            <Button
                                                onClick={_ => onSubmit(true)}
                                            >
                                                {fmtMsg({ id: SurveyLocale.SurveySaveAsDraftText })}
                                            </Button>
                                        }
                                    </>
                                ) : ((path === SurveyPathConfig.ViewPollFromLanding || path === SurveyPathConfig.ViewPollFromHistory)
                                    && !surveyResponse.isRead) ? (
                                    <>
                                        <Button onClick={onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                                        <Button className="fill-btn-primary" type="primary" onClick={onMarkAsReadClick}>
                                            {fmtMsg({ id: SurveyLocale.SurveyMarkAsReadText })}
                                        </Button>
                                    </>
                                ) : (
                                    <Button onClick={onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonClose })}</Button>
                                )}
                                {(path === SurveyPathConfig.CompletePollFromLanding || path === SurveyPathConfig.CompletePollFromHistory)
                                    && !(
                                        surveyResponse.responseStatus === ResponseStatusType.Submitted ||
                                        surveyResponse.responseStatus === ResponseStatusType.Reviewed)
                                    ? (
                                        <Button
                                            className="fill-btn-primary"
                                            type="primary"
                                            onClick={_ => onSubmit(false)}
                                        >
                                            {fmtMsg({ id: GSAdminLocale.ButtonSubmit })}
                                        </Button>
                                    ) : null}
                                {(path === SurveyPathConfig.ReviewPollFromLanding || path === SurveyPathConfig.ReviewPollFromHistory)
                                    ? <>
                                        <Button onClick={() => goToEmailResponse()}>{fmtMsg({ id: SurveyLocale.SurveyReviewEmailResponse })}</Button>
                                        {surveyResponse.responseStatus !== ResponseStatusType.Reviewed &&
                                            <Button className="fill-btn-primary" type="primary" onClick={onReviewSubmit}>
                                                {fmtMsg({ id: SurveyLocale.SurveyReviewText })}
                                            </Button>
                                        }
                                    </>
                                    : null
                                }
                            </Col>
                        </Row>
                    </GLForm>
                    {renderShareSelectUserModal()}
                </Card>
        );
    };

    return surveyResponseLoading ? <Loading visible={true} /> : surveyResponse ? renderForm(surveyResponse) : null;
};

export const FillPollForm = withRouter(connect(
    ({
        survey: {
            surveyFillState: { selectedSurveyInstanceId, selectedSurveyResponseId, fillSurveyViewMode },
            surveyVisibleMode,
            responseAttachments,
        },
        region: { accessibleRegions },
        regionGroup: { regionGroups },
        surveyMedia: { sasUrl }
    }: StateType) => ({
        instanceId: selectedSurveyInstanceId,
        responseId: selectedSurveyResponseId,
        regions: accessibleRegions,
        regionGroups: regionGroups,
        sasUrl,
        viewMode: fillSurveyViewMode,
        surveyVisibleMode,
        responseAttachments,
    }),
    {
        setSurveyFillState,
        getRegions: getAccessibleRegions,
        getRegionGroups,
        getSASUrl,
        addUploadStatus,
        updateUploadStatus,
        removeUploadStatus,
        setResponseAttachments,
        toggleFormVisiblility,
        reload,
    }
)(GLForm.create()(FillPoll)));
