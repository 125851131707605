import { GLGlobal } from "gl-commonui";
import { useEffect, useState } from "react";
import { AnyAction, Dispatch } from "redux";
import { StateType } from "../states";

export const useStore = (): [StateType, Dispatch<AnyAction>] => {
	const { getState, dispatch, subscribe } = GLGlobal.store;
	const [state, setState] = useState<StateType>(getState());

	useEffect(() => {
		return subscribe(() => {
			setState(getState());
		});
	}, []);

	return [state, dispatch];
};
