import { RegionGenericLinkService } from "@app/service/admin/genericLink";
import { SchoolService } from "@app/service/schools";
import { UserService } from "@app/service/users";
import uuidv4 from "uuid/v4";
interface Services {
    genericLink: RegionGenericLinkService;
    userService: UserService
    schoolService: SchoolService
}
export interface ILink {
    englishText: string;
    id: string;
    link: string;
    localText: string;
    location: string;
    site: string;
}

export interface LinkState {
    linksFromServer: ILink[];
    linksSession: ILink[];
    linksSendToServer: ILink[];
    isLoading: boolean;
    isError: any;
    hasChange: boolean;
    isLoadingHeader: boolean;
    isErrorHeader: any;
    isValidateError: boolean;
    addingClicked: boolean;
    canAccessGSConnect: boolean;
}

const initialState = {
    linksFromServer: [],
    linksSession: [],
    linksSendToServer: [],
    combinedLinks: [],
    isLoading: false,
    isError: null,
    isLoadingHeader: false,
    isErrorHeader: null,
    hasChange: false,
    isValidateError: false,
    addingClicked: false,
    canAccessGSConnect: false
};

export default {
    namespace: "link",
    state: initialState,
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        addLink(state, { payload }) {
            const patternLink = {
                englishText: "",
                id: uuidv4(),
                link: "",
                localText: "",
                location: payload.location,
                site: payload.site
            };
            return {
                ...state,
                linksSession: [...state.linksSession, patternLink]
            };
        },
        removeLink(state, { payload }) {
            const newLinksSession = state.linksSession.filter(
                link => link.id !== payload
            );
            return {
                ...state,
                linksSession: newLinksSession
            };
        },
        editLink(state, { payload }) {
            const newData = [...state.linksSession];
            const index = newData.findIndex(item => payload.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...payload.editedData
                });
                return {
                    ...state,
                    linksSession: newData
                };
            }
            return { ...state };
        },
        setNewLinksValue(state, { payload }) {
            const newLinksSendToServer = [...state.linksSession];
            return {
                ...state,
                hasChange: true,
                linksSendToServer: newLinksSendToServer
            };
        },
        cancelNewLinksValue(state, { payload }) {
            const newLinksSession = [...state.linksSendToServer];
            return {
                ...state,
                linksSession: newLinksSession
            };
        },
        setErrorValidate(state, { isValid }) {
            return {
                ...state,
                isValidateError: isValid
            };
        },
        clearAllData(state, { payload }) {
            return {
                ...state,
                ...initialState
            };
        },
        setAddingClicked(state, { payload }) {
            return {
                ...state,
                addingClicked: !state.addingClicked
            };
        }
    },
    effects: {
        *fetchLinksFromServer(
            { payload },
            { call, put, all },
            { genericLink }: Services
        ) {
            try {
                yield put(reload({ isLoading: true }));
                const links = yield call(
                    genericLink.getRegionGenericLink,
                    payload.regionId
                );
                yield put(
                    reload({
                        isLoading: false,
                        linksSession: links,
                        linksSendToServer: links
                    })
                );
            } catch (err) {
                yield put(reload({ isLoading: false, isError: true }));
            }
        },
        *fetchHeaderLinksFromServer(
            { payload: { location } },
            { call, put, all },
            { genericLink, schoolService }: Services
        ) {
            try {
                yield put(reload({ isLoadingHeader: true }));
                const links = yield call(genericLink.getListGenericLinkSchool);

                if (location === 'resource') {
                    const canAccessGSConnect = yield call(schoolService.getGSConnectAccess);
                    yield put(
                        reload({
                            isErrorHeader: null,
                            isLoadingHeader: false,
                            linksFromServer: links,
                            canAccessGSConnect
                        })
                    );
                } else {
                    yield put(
                        reload({
                            isErrorHeader: null,
                            isLoadingHeader: false,
                            linksFromServer: links
                        })
                    );
                }
            } catch (err) {
                yield put(
                    reload({ isLoadingHeader: false, isErrorHeader: true })
                );
            }
        }
    },
    services: {
        genericLink: RegionGenericLinkService,
        userService: UserService,
        schoolService: SchoolService
    }
};
export function reload(state) {
    return { type: "link/reload", payload: state };
}

export function fetchLinksFromServer(payload) {
    return { type: "link/fetchLinksFromServer", payload };
}

export function fetchHeaderLinksFromServer(location: string) {
    return { type: "link/fetchHeaderLinksFromServer", payload: { location } };
}

export function addLink(payload: { location: string; site: string }) {
    return { type: "link/addLink", payload };
}

export function removeLink(payload: string) {
    return { type: "link/removeLink", payload };
}

export function editLink(payload: { editedData: any; id: string }) {
    return { type: "link/editLink", payload };
}

export function setNewLinksValue() {
    return { type: "link/setNewLinksValue" };
}

export function cancelNewLinksValue() {
    return { type: "link/cancelNewLinksValue" };
}

export function setErrorValidate(isValid: boolean) {
    return { type: "link/setErrorValidate", isValid };
}

export function clearAllData() {
    return { type: "link/clearAllData" };
}

export function setAddingClicked() {
    return { type: "link/setAddingClicked" };
}
