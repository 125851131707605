import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode, MessageHelper, NotificationType } from 'gl-commonui'
import { injectable } from '@app/util/di'
import { ICIMSService } from './index';
import { SchoolLocale } from '@app/locales/localeid';
import { CampusEventType, ChangeLogType, EventInfo, EventTypeInfo, fmtMsg } from '@app/util';

@injectable()
export class CIMSService extends GLServiceBase<any, any> implements ICIMSService {
    serviceRoute = { prefix: 'admin/v1/schools/{schoolId}/classes/changes', suffix: '{eventId}' }
    approveByEventTypeUrl = 'admin/v1/schools/{schoolId}/classes/{id}/changes/types/approve';
    denyByEventTypeUrl = 'admin/v1/schools/{schoolId}/classes/{id}/changes/types/deny';
    denySchoolByEventTypeUrl = 'admin/v1/schools/{id}/changes/types/deny';
    InvalidApproveActionException = 4752;
    InvalidDenyActionException = 4753;
    InvalidLicenseDenyActionException = 4754;
    NotEnoughLicenseException = 6008;
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Put, this.InvalidApproveActionException, this.approveByEventTypeUrl)] = SchoolLocale.CIMSInvalidLicenseDenyActionException;
        result[getServiceMethodKey(this, HttpMethod.Put, this.InvalidDenyActionException, this.denyByEventTypeUrl)] = SchoolLocale.CIMSInvalidLicenseDenyActionException;
        result[getServiceMethodKey(this, HttpMethod.Put, this.InvalidDenyActionException, this.denySchoolByEventTypeUrl)] = SchoolLocale.CIMSInvalidMovePromoteDenyActionException;
        result[getServiceMethodKey(this, HttpMethod.Put, this.InvalidLicenseDenyActionException, this.denyByEventTypeUrl)] = SchoolLocale.CIMSInvalidLicenseDenyActionException;
        result[getServiceMethodKey(this, HttpMethod.Put, this.NotEnoughLicenseException, this.denyByEventTypeUrl)] = SchoolLocale.CIMSInvalidLicenseDenyNotEnoughLicenseException;
        return result;
    })();
    getSchoolAuditLog(schoolId, query) {
        return this.getItemsBy(...query, { schoolId });
    }
    getChangeEventData(schoolId, eventId, logType){
        return this.get('~admin/v1/schools/{id}/changes/{eventId}', {logType}, {id: schoolId, eventId});
    }
    getPendingList(query, getAll) {
        const suffix = getAll ?
            '~admin/v1/schools/classes/changes/pending/all' :
            '~admin/v1/schools/classes/changes/pending'
        return this.get(suffix, query);
    }
    getChangeFieldData(schoolId, classId, types, verificationCIMSId?: string) {
        return this.get('~admin/v1/schools/{schoolId}/classes/{id}/changes/pending', {types, verificationCIMSId}, {schoolId, id: classId});
    }
    approveByEvent(schoolId, eventId) {
        return this.update('~admin/v1/schools/{schoolId}/classes/changes/{eventId}/approve', {schoolId, eventId});
    }
    approveBySchool(schoolId) {
        return this.update('~admin/v1/schools/{id}/changes/approve', {id: schoolId});
    }
    approveByClass(schoolId, classId) {
        return this.update('~admin/v1/schools/{schoolId}/classes/{id}/changes/approve', {schoolId, id: classId});
    }
    approveByEventType(schoolId, classId, types) {
        return this.update(`~${this.approveByEventTypeUrl}`, types, {schoolId, id: classId});
    }
    denyByClass(schoolId, classId) {
        return this.update('~admin/v1/schools/{schoolId}/classes/{id}/changes/deny', {schoolId, id: classId});
    }
    denyByEventType(schoolId, classId, types, approveDenyData) {
        return this.update(`~${this.denyByEventTypeUrl}`, {types, ...approveDenyData}, {schoolId, id: classId});
    }
    getCampusClasses(schoolId, campusId) {
        return this.get('~admin/v1/schools/{schoolId}/campuses/{campusId}/classes', null, {schoolId, campusId});
    }
    getChangeLogs(schoolId, query) {
        return this.get('~admin/v1/schools/{id}/changes', ...query, { id: schoolId });
    }
    getPendingChanges(target, query, getAll) {
        let url = null;
        switch (target) {
            case ChangeLogType.Hybrid:
                url = '~admin/v1/changes/pending';
                break;
            case ChangeLogType.Campus:
                url = '~admin/v1/schools/campuses/changes/pending';
                break;
            case ChangeLogType.SchoolClass:
                url = '~admin/v1/schools/classes/changes/pending';
                break;
            case ChangeLogType.School:
            default:
                url = '~admin/v1/schools/changes/pending';
                break;
        }
        return this.get(getAll && target != ChangeLogType.Hybrid ? `${url}/all` : url, query);
    }
    getPendingChangesDetail(target, schoolId, campusId, classId, types, isFutureClassChange) {
        let url = null, routeData = null;
        switch (target) {
            case ChangeLogType.Campus:
                url = '~admin/v1/schools/{schoolId}/campuses/{id}/changes/pending';
                routeData = {schoolId, id: campusId};
                break;
            case ChangeLogType.SchoolClass:
                url = '~admin/v1/schools/{schoolId}/classes/{id}/changes/pending';
                routeData = {schoolId, id: classId};
                break;
            case ChangeLogType.School:
            default:
                url = '~admin/v1/schools/{id}/changes/pending';
                routeData = {id: schoolId};
                break;
        }
        return this.get(url, {types, isFutureClassChange}, routeData);
    }
    approvePendingChanges(target: ChangeLogType, schoolId: string, campusId: string, classId: string, types: number[], eventId?: string) {
        let url = null, routeData = null;
        switch (target) {
            case ChangeLogType.Campus:
				if (types.length == 1 && types[0] === EventInfo.PendingChangesMaterialOrder) {
					url = '~admin/v1/schools/{schoolId}/campuses/{id}/changes/types/approve/{eventId}';
					routeData = {schoolId, id: campusId, eventId};
				} else {
					url = '~admin/v1/schools/{schoolId}/campuses/{id}/changes/types/approve';
					routeData = {schoolId, id: campusId};
				}
                break;
            case ChangeLogType.SchoolClass:
                url = `~${this.approveByEventTypeUrl}`;
                routeData = {schoolId, id: classId};
                break;
            case ChangeLogType.School:
            default:
                url = '~admin/v1/schools/{id}/changes/types/approve';
                routeData = {id: schoolId};
                break;
        }
        return this.update(url, types, routeData);
    }
    denyPendingChanges(target, schoolId, campusId, classId, types, approveDenyData, eventId?: string) {
        let url = null, routeData = null;
        switch (target) {
            case ChangeLogType.Campus:
				if (types.length == 1 && types[0] === EventInfo.PendingChangesMaterialOrder) {
					url = '~admin/v1/schools/{schoolId}/campuses/{id}/changes/types/deny/{eventId}';
					routeData = {schoolId, id: campusId, eventId};
				} else {
					url = '~admin/v1/schools/{schoolId}/campuses/{id}/changes/types/deny';
					routeData = {schoolId, id: campusId};

                    const checkForTypes = [
                        EventInfo.PendingChangesDisableCampus,
                        EventInfo.PendingChangesEnableCampus
                    ];
                    if (types && types.some(t => checkForTypes.includes(t))) {
                        if (types.some(t => [EventInfo.PendingChangesDisableCampus].includes(t))) {
                            MessageHelper.Message(NotificationType.Warning,fmtMsg({id:SchoolLocale.CIMSDisableCampusRevertMessage}));
                        }
                         if (types.some(t => [EventInfo.PendingChangesEnableCampus].includes(t))) {
                            MessageHelper.Message(NotificationType.Warning,fmtMsg({id:SchoolLocale.CIMSEnableCampusRevertMessage}));
                        }
                    }
				}
                break;
            case ChangeLogType.SchoolClass:
                url = `~${this.denyByEventTypeUrl}`;
                routeData = {schoolId, id: classId};
                break;
            case ChangeLogType.School:
            default:
                url = `~${this.denySchoolByEventTypeUrl}`;
                routeData = {id: schoolId};
                break;
        }
        return this.update(url, {types, ...approveDenyData}, routeData);
    }
    getOwners(regionId, schoolId) {
        return this.get('~admin/v1/changes/owners', { regionId, schoolId });
    }
    assignOwner(changesGroupId, ownerId) {
        return this.update('~admin/v1/changes/assign-owner?changesGroupId={changesGroupId}&ownerId={ownerId}', { changesGroupId, ownerId });
    }
    removeOwner(changesGroupId) {
        return this.delete('~admin/v1/changes/remove-owner', { changesGroupId });
    }
}
