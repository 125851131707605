import { ResourceService } from "@app/service/school/resource/service";
import { InvitationTemplateService } from "@app/service/invitation-template";
import { UserService } from "@app/service/users/service";
import { InvitationService } from "@app/service/school/invitation/service";
import { InvitationType, RoleName, GLGlobal } from "gl-commonui";
import { StateType } from "../index";
import { SchoolLocale } from "@app/locales/localeid";
import { SchoolService } from "@app/service/schools/service";
import { SchoolContacts, CampusContacts } from "./adminsModel";

interface Services {
    invitation: InvitationService;
    template: InvitationTemplateService;
    resource: ResourceService;
    user: UserService;
    school: SchoolService;
}

export interface AdminsState {
    admins: any[];
    templates: any[];
    loading: boolean;
    isModalShow: boolean;
    confirmed: any;
    refType: any;
    schoolContacts: SchoolContacts;
    campusContacts: CampusContacts;
}

export default {
    namespace: "admins",
    state: {
        admins: [],
        templates: [],
        loading: true,
        isModalShow: false,
        confirmed: {},
        schoolContacts: {},
        campusContacts: {}
    },
    reducers: {
        setAdmins(state, action) {
            console.log(action);
            return { ...state, admins: action.payload };
        },
        isLoading(state, action) {
            return { ...state, loading: action.payload };
        },
        setTemplate(state, action) {
            return { ...state, templates: action.payload };
        },
        modalShow(state, action) {
            return { ...state, isModalShow: action.payload };
        },
        confirmed(state, action) {
            return { ...state, confirmed: action.payload };
        },
        clearModal(state, action) {
            return { ...state, isModalShow: false, confirmed: {} };
        },
        setSchoolContacts(state, action) {
            return { ...state, schoolContacts: action.payload };
        },
        setCampusContacts(state, action) {
            return { ...state, campusContacts: action.payload };
        }
    },
    effects: {
        *getAdmins(
            { payload: { id, type } },
            { call, put, select },
            { invitation, resource, user }: Services
        ) {
            yield put(loading());
            yield put(setAdmins([]));
            const current = yield select(
                (state: StateType) => state.oidc.loginInfo.profile.sub
            );
            const ids = yield call(
                resource.getIdsBy,
                { resourceType: type },
                { resourceId: id }
            );
            const users =
                ids.length > 0
                    ? (yield call(user.getUsersByPost, { ids })).data
                    : [];
            const pendings = yield call(invitation.getInvitations, {
                referenceId: id,
                invitationCodeTypes: [type]
            });
            yield put(
                setAdmins(
                    canNotDel(
                        users,
                        type === InvitationType.schoolAdmin ? current : ""
                    ).concat(setPendingProp(pendings))
                )
            );
            yield put(loaded());
        },
        *remove(
            {
                payload: {
                    id,
                    user: { id: userId },
                    type
                }
            },
            { call, put },
            { user, resource }: Services
        ) {
            yield call(user.removeBy, RoleName[InvitationType[type]], userId);
            yield call(resource.removeBy, type, userId);
            yield put(getAdmins({ id, type }));
        },
        *removePending(
            {
                payload: {
                    id,
                    user: { id: userId },
                    type
                }
            },
            { call, put },
            { invitation }: Services
        ) {
            yield call(invitation.delete, null, { id: userId });
            yield put(getAdmins({ id, type }));
        },
        *initTemplates(
            { payload: type },
            { call, put },
            { template }: Services
        ) {
            const { data: templates } = yield call(template.getItemsBy, {
                invitationType: type,
                detail: false
            });
            yield put(setTemplate(templates));
        },
        *invite(
            { payload: data },
            { call, put, select },
            { invitation }: Services
        ) {
            data.senderId = yield select(
                (state: StateType) => state.oidc.loginInfo.profile.sub
            );
            yield call(invitation.create, data);
            yield put(hideModal());
            yield put(
                getAdmins({ id: data.referenceId, type: data.invitationType })
            );
        },
        *reSendInvitation(
            { payload: user },
            { call, put },
            { invitation }: Services
        ) {
            yield call(invitation.sendEmail, user.id);
        },
        *emailConfirmBing(
            { payload: { id, type, email } },
            { call, put },
            { user }: Services
        ) {
            const { exist, hasConfirmed } = yield call(user.confirm, email);
            if (exist && hasConfirmed) {
                yield call(user.bindBy, email, {
                    referenceId: id,
                    invitationCodeType: type
                });
                yield put(getAdmins({ id, type }));
            }
            yield put(confirmed({ email, exist, hasConfirmed }));
        },
        *getSchoolContacts(payload, { call, put }, { school }: Services) {
            const data = yield call(school.getSchoolContacts, {
                id: payload.payload
            });
            yield put(setSchoolContacts(data));
        },
        *getCampusContacts(
            { payload: { schoolId, id } },
            { call, put },
            { school }: Services
        ) {
            const data = yield call(school.getCampusContacts, {
                schoolId,
                id
            });
            yield put(setCampusContacts(data));
        }
    },
    services: {
        invitation: InvitationService,
        template: InvitationTemplateService,
        resource: ResourceService,
        user: UserService,
        school: SchoolService
    }
};
function canNotDel(users: any[], current) {
    users
        .filter(user => user.id === current)
        .forEach(user => (user.canNotDel = true));
    return users;
}
function setPendingProp(pendings: any[]) {
    const text = GLGlobal.intl.formatMessage({
        id: SchoolLocale.AdminsPending
    });
    pendings.forEach(pending => {
        pending.name = `${pending.name} ${text}`;
        pending.pending = true;
    });
    return pendings;
}
function isloading(state) {
    return { type: "admins/isLoading", payload: state };
}
function loading() {
    return isloading(true);
}
function loaded() {
    return isloading(false);
}
function modalShow(state) {
    return { type: "admins/modalShow", payload: state };
}
function setSchoolContacts(state) {
    return { type: "admins/setSchoolContacts", payload: state };
}
function setCampusContacts(state) {
    return { type: "admins/setCampusContacts", payload: state };
}
export function setAdmins(state) {
    return { type: "admins/setAdmins", payload: state };
}
export function getAdmins(state) {
    return { type: "admins/getAdmins", payload: state };
}
export function remove(state) {
    return { type: "admins/remove", payload: state };
}
export function removePending(state) {
    return { type: "admins/removePending", payload: state };
}
export function initTemplates(state) {
    return { type: "admins/initTemplates", payload: state };
}
export function invite(state) {
    return { type: "admins/invite", payload: state };
}
export function setTemplate(state) {
    return { type: "admins/setTemplate", payload: state };
}
export function reSendInvitation(state) {
    return { type: "admins/reSendInvitation", payload: state };
}
export function confirmed(state) {
    return { type: "admins/confirmed", payload: state };
}
export function emailConfirmBing(state) {
    return { type: "admins/emailConfirmBing", payload: state };
}
export function showModal() {
    return modalShow(true);
}
export function hideModal() {
    return { type: "admins/clearModal" };
}
export function getSchoolContacts(state: string) {
    return { type: "admins/getSchoolContacts", payload: state };
}
export function getCampusContacts(state: any) {
    return { type: "admins/getCampusContacts", payload: state };
}
