import * as React from "react";
import { Component } from "react";
import { Modal } from "antd-min";
import { ModalProps } from "antd/lib/modal/Modal";
import { GLGlobal, GLLocale } from "gl-commonui";

interface GLModalProps extends ModalProps {
  content?: JSX.Element[];
}

interface ModalStates {}

export class GLModal extends Component<GLModalProps, ModalStates> {
  constructor(props) {
    super(props);
  }
  render() {
    const fmtMsg = GLGlobal.intl.formatMessage;
    return (
        <Modal
            wrapClassName="vertical-center-modal"
            okText={fmtMsg({ id: GLLocale.Ok })}
            cancelText={fmtMsg({ id: GLLocale.Cancel })}
            {...this.props}
        >
            {this.props.content}
        </Modal>
    );
  }
}
