import { mergeClasses } from "@app/components/survey/functions";
import { Drawer } from "antd-min";
import { GLFormProps } from "gl-commonui";
import React, { ReactNode } from "react";
import "./custom-drawer.less";

interface CustomDrawerProps extends GLFormProps {
    visible: boolean;
    drawerTitle?: string | ReactNode;
    className?: string;
    width?: number;
    onClose: () => void;
}

export const CustomDrawer: React.FC<CustomDrawerProps> = ({
    visible,
    drawerTitle = null,
    className = "on-going-drawer",
    width = 400,
    onClose,
    children,
}) => {
    return (
        <Drawer
            width={width}
            placement="right"
            onClose={onClose}
            visible={visible}
            maskClosable={false}
            title={drawerTitle}
            className={mergeClasses("custom-drawer", className)}
        >
            {children}
        </Drawer>
    );
};
