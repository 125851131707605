import React, { Component } from "react";
import { Radio } from "antd-min";
import { fmtMsg, PollListStates } from "../../../util";
import { SurveyLocale } from "@app/locales/localeid";

const RadioGroup = Radio.Group;

interface PollStateRadiosProps {
    value?: any;
    allCount?: number;
    completeCount?: number;
    contributeCount?: number;
    viewCount?: number;
    onChange?: (e) => void;
}

interface PollStatesRadioStates {
}

export class PollStateRadios extends Component<PollStateRadiosProps, PollStatesRadioStates> {
    constructor(props) {
        super(props);
    }

    onChange(e) {
        this.props.onChange(parseInt(e.target.value));
    }

    renderState(text, cnt) {
        return (
            <>
                <span>{fmtMsg(text)}</span>
                <span className="cnt">({cnt || 0})</span>
            </>
        );
    }

    render() {
        return (
            <div className="list-states">
                <RadioGroup value={this.props.value} defaultValue={PollListStates.All} onChange={this.onChange.bind(this)} >
                    <Radio className="all" value={PollListStates.All}>{this.renderState(SurveyLocale.PollReviewAll, this.props.allCount)}</Radio>
                    <Radio className="active" value={PollListStates.Complete}>{this.renderState(SurveyLocale.PollReviewComplete, this.props.completeCount)}</Radio>
                    <Radio className="inactive" value={PollListStates.Contribute}>{this.renderState(SurveyLocale.PollReviewContribute, this.props.contributeCount)}</Radio>
                    <Radio className="pending" value={PollListStates.View}>{this.renderState(SurveyLocale.PollReviewView, this.props.viewCount)}</Radio>
                </RadioGroup>
            </div>
        )
    }
}
