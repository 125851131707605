import * as React from 'react'
import { Component } from 'react';
import { Modal, Button } from 'antd-min';
import { ModalProps } from 'antd/lib/modal/Modal';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { fmtMsg } from '@app/util';
import { Table } from 'antd';
import * as moment from "moment";
import { localeFullDayMap } from '@app/page/survey/components/create-poll/components/helpers';

interface ScheduleModelProps extends ModalProps {
    teachers?: any[]
}
interface ScheduleModelStates {
    visible: boolean
}

export class ScheduleModel extends Component<ScheduleModelProps, ScheduleModelStates> {
    constructor(props) {
        super(props);
        this.state = { 
            visible: true,
        }
    }
    componentWillReceiveProps(nextProps: any, nextContext: any) {
        !this.state.visible && this.setState({ visible: true });
    };
    getTableColumns () {
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeachersTitle }),
                dataIndex: "teacherName",
                width: "26%",
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestDayText }),
                dataIndex: "daysOfWeek",
                width: "20%",
                render(text) {
                    return text && fmtMsg({ id: localeFullDayMap[text-1] });
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestStartText }),
                dataIndex: "start",
                width: "18%",
                render(text) {
                    return text && moment(text, "HH:mm").format("HH:mm")
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestEndText }),
                dataIndex: "end",
                width: "18%",
                render(text) {
                    return text && moment(text, "HH:mm").format("HH:mm")
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.ClassTimeDuration }),
                dataIndex: "duration",
                width: "18%",
            },
        ];
    }
    onCancel(e) {
        this.setState({ visible: false });
    }
    formatScheduleInfos() {
        const { teachers } = this.props;
        return teachers.map(t => {
            return t.schoolClassInfo &&
                t.schoolClassInfo.schoolClassTimes.length ?
                t.schoolClassInfo.schoolClassTimes.map((time) =>
                    {
                        return {
                            name: t.name,
                            ...time,
                        };
                    }
                ) : t;
        });
    }
    render() {
        const { visible } = this.state;
        return (
            <Modal
                width={600}
                title={fmtMsg({ id: SchoolLocale.VisitationRequestTeacherSchedule })}
                className="class-schedule"
                wrapClassName="vertical-center-modal"
                onCancel={(e) => this.onCancel(e)}
                destroyOnClose={true}
                visible={visible}
                footer={[<Button onClick={(e) => this.onCancel(e)}>
                            {fmtMsg({id: GSAdminLocale.ButtonClose})}
                        </Button>]}
            >
                <Table 
                    className="teacher-schedule"
                    bordered
                    pagination={false}
                    dataSource={this.formatScheduleInfos()}
                    columns={this.getTableColumns()}
                />
            </Modal>
        )
    }
}