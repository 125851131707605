import { GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '@app/util/di'
import { IMaterialService, MaterialRequest, ISubscriptionMaterialService, SubscriptionMaterialRequest } from './index';
import { SchoolLocale } from '@app/locales/localeid';

export const InvalidMaterialOrderStatusException = 46401;
export const InvalidMaterialOrderEditException = 46402;

@injectable()
export class MaterialService extends GLServiceBase<MaterialRequest, any> implements IMaterialService {
    serviceRoute = { prefix: 'admin/v1/materialorders', suffix: '{id}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorSubmitProductNull;
        result[getServiceMethodKey(this, HttpMethod.Post, InvalidMaterialOrderStatusException)] = SchoolLocale.MaterialOrderErrorStatus;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorOrderNull;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorOrderNull;
        return result;
    })();
    getMaterialCart(schoolId, campusId) {
        return this.request.get(this.url('carts/{campusId}'), {schoolId}, { campusId });
    }
    createPurchaseItem(data) {
        return this.request.post(this.url('purchaseitems'), data);
    }
    getPurchaseItem({id}) {
        return this.request.get(this.url('purchaseitems/{id}'), null, { id });
    }
    updatePurchaseItem(itemId, data) {
        return this.request.put(this.url('purchaseitems/{itemId}'), data, { itemId });
    }
    deletePurchaseItem(itemId) {
        return this.request.del(this.url('purchaseitems/{itemId}'), null, { itemId });
    }
    getRegionOrders(regionId, query) {
        return this.getItemsBy('~admin/v1/regions/{regionId}/materialorders', { ...query }, {regionId});
    }
    getOrderContacts(id) {
        return this.request.get(this.url('{id}/contacts'), null, { id })
    }
    getConditions(locale) {
        return this.request.get(this.url('conditions/{locale}'), null, { locale });
    }
}

@injectable()
export class SubscriptionMaterialService extends GLServiceBase<SubscriptionMaterialRequest, any> implements ISubscriptionMaterialService {
    serviceRoute = { prefix: 'admin/v1/materialrequests', suffix: '{id}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorOrderNull;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorOrderNull;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.InvalidMaterialOrderException)] = SchoolLocale.MaterialOrderErrorOrderNull;
        return result;
    })();
    getSubscriptionCart({schoolId, campusId, classId, orderId}) {
        return this.request.get(this.url('carts/{classId}'), {schoolId, campusId, orderId}, { classId });
    }
    createSubscription(requestId, data) {
        return this.request.post(this.url('{requestId}/subscriptionitems'), data, { requestId });
    }
    getSubscriptionItem({ requestId, itemId }) {
        return this.request.get(this.url('{requestId}/subscriptionitems/{itemId}'), null, { requestId, itemId });
    }
    updateSubscriptionItem(requestId, itemId, data) {
        return this.request.put(this.url('{requestId}/subscriptionitems/{itemId}'), data, { requestId, itemId });
    }
    deleteSubscriptionItem(requestId, itemId) {
        return this.request.del(this.url('{requestId}/subscriptionitems/{itemId}'), null, { requestId, itemId });
    }
}
