import React, { Component } from 'react';
import "../component/progress.less";
import { Icon, Spin } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { formatResponseAnswer } from '@app/util/coach/fun';
import { SaveCancelBtns, GLRichTextEditor } from '@app/components';
import { fmtMsg, lazyInject, TYPES, ContextHelper, DateHelper } from '@app/util';
import { IVisitationService, TeacherRelatedModel } from '@app/service/coach/visitation';
import { VisitationTeacherStage, SurveyTodoType, ReviewTeacherFormStatus } from '@app/util/coach/enum';
import { renderQuestionList } from '@app/util/coach/common';

interface FeedbacksProps {
    visitation: TeacherRelatedModel
    callback: (stage) => void
    teacherStage: VisitationTeacherStage
}
interface FeedbacksStates {
    disabled?: boolean
    isComing?: boolean
    spinning?: boolean
    reviewForm?: any
    todoSurveyResponse?: any
}

export class Feedbacks extends Component<FeedbacksProps, FeedbacksStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            spinning: false,
            isComing: false,
            reviewForm: {},
            todoSurveyResponse: {}
        }
    }
    componentWillMount() {
        this.getCoachToTeacherFeedback();
    }
    getCoachToTeacherFeedback() {
        const { teacherStage, visitation: { associatedInfo: { reviewTeacherForm } }} = this.props;
        const isCurrentStage = teacherStage == VisitationTeacherStage.ViewCoachFeedback;
        if (reviewTeacherForm.length) {
            const status = reviewTeacherForm[0].status;
            (status == ReviewTeacherFormStatus.Initialized ||
             status == ReviewTeacherFormStatus.Draft) ? this.setState({ isComing: isCurrentStage, disabled: true }) :
                                                        this.getTeacherFeedback(reviewTeacherForm[0]);
        } else {
            this.setState({ isComing: isCurrentStage, disabled: true });
        }
    }
    getTeacherFeedback(reviewForm) {
        const { visitation: { id }} = this.props;
        const { surveyInstanceId, status } = reviewForm;
        const params = {
            surveyInstanceId,
            visitationId: id,
            type: SurveyTodoType.ReadOnlyShared 
        }
        const disabled = status == ReviewTeacherFormStatus.Viewed;
        this.setState({ spinning: true });
        this.visitationservice.viewSurveyReponse(params).then(data => {
            this.setState({ 
                spinning: false,
                disabled: disabled,
                reviewForm: reviewForm,
                todoSurveyResponse: data
            });
        }).catch(() => {
            this.setState({ spinning: false });
        });
    }
    reviewedCoachToTeacherFeedback() {
        const { visitation: { id }, callback } = this.props;
        const { reviewForm } = this.state;
        const userId = ContextHelper.getUserLoginInfo().profile.sub;
        const params = {
            visitationId: id,
            teacherId: userId,
            reviewTeacherFormId: reviewForm.id,
            status: ReviewTeacherFormStatus.Viewed
        }
        this.visitationservice.reviewedCoachToTeacherFeedback(params).then(data => {
            callback(VisitationTeacherStage.GiveCoachFeedback);
        });
    }
    render() {
        const { teacherStage } = this.props;
        const { todoSurveyResponse: { surveyQuestion, fillDate }, spinning, isComing, disabled, reviewForm } = this.state;
        const isViewed = reviewForm && reviewForm.status == ReviewTeacherFormStatus.Viewed;
        const showBtn = teacherStage == VisitationTeacherStage.ViewCoachFeedback && 
                        reviewForm && reviewForm.status == ReviewTeacherFormStatus.Completed;
        const mergeDisabled = disabled || isViewed;
        const formatFillDate = DateHelper.toLocalStringFromUTC(fillDate);
        const isWithoutFeedback = teacherStage == VisitationTeacherStage.CompletedByCoach;
        return <div className={`feedbacks info-container ${mergeDisabled&&"disabled"}`}>
            <Spin spinning={spinning}>
                <div className="feedbacks-container">
                    {fillDate && 
                        <div className="title">{fmtMsg({id: SchoolLocale.VisitationFeedbackText})}({formatFillDate})</div>}
                    {surveyQuestion && renderQuestionList(surveyQuestion, "question-list")}
                    {isComing &&  
                        <div className="no-feedback-message">
                            <div><Icon type="clock-circle" />{fmtMsg({ id: SchoolLocale.VisitationInProgress })}</div>
                        </div>}
                    {isWithoutFeedback && <div className="no-feedback-message">
                        {fmtMsg({ id: SchoolLocale.VisitationNotGiveFeedback })}
                    </div>}
                </div>
                {showBtn && 
                <SaveCancelBtns 
                    noCancelBtn={true}
                    saveTitle={SchoolLocale.VisitationGiveCoachFeedback} 
                    onSubmit={() => this.reviewedCoachToTeacherFeedback()}>
                </SaveCancelBtns>}
            </Spin>
        </div>
    }
}