import React, {ReactNode, useEffect, useState} from 'react';
import {Container, MainTitle} from "@app/components";
import {GSAdminLocale, SchoolLocale, TalkTime} from "@app/locales/localeid";
import {Button, Checkbox, Col, Input, notification, Row} from "antd-min";
import {fmtMsg} from "@app/util";
import {WijmoGrid} from "@app/components/grid";
import {BreadcrumbData} from "@app/page/admin/regions/components/talk-time/navigation-bar";
import {useService} from "@app/hooks";
import {TYPES} from "@app/service/types";
import {SelectionMode} from "wijmo/wijmo.grid";
import {ITalkTimeService, TalkTimeEntitySetting, TalkTimeEntitySettingRequestModel} from "@app/service/admin/talktime";
import {GLUtil, maskThrottle, unmaskThrottle} from 'gl-commonui';
import {Modal} from 'antd';
import {PathConfig} from "@app/config/pathconfig";

const {Grid, Column} = WijmoGrid;

enum EntityType {
    school = 0,
    campus = 1,
    class = 2,
    student = 3,
    externalUser = 4
}

enum ConfirmationType {
    Cancel = 1,
    Item = 2,
    Navigation = 3,
}

enum TalkTimeSettingGridColumns {
    id = "id",
    name = "name",
    enabled = "enabled",
    totalStudents = "totalStudents",
}

const TalkTimeAccess = (props: any) => {
    const [isReadonly, setReadOnly] = useState(false);
    const [masterDataSource, setMasterDataSource] = useState<TalkTimeEntitySetting[]>([]);
    const [dataSource, setDataSource] = useState<TalkTimeEntitySetting[]>([]);
    const [headerIndeterminateStatus, setHeaderIndeterminateStatus] = useState(false);
    const [headerCheckboxStatus, setHeaderCheckboxStatus] = useState(false);
    const [entityNameHeader, setEntityNameHeader] = useState(fmtMsg({id: SchoolLocale.ClassesColumnSchool}));
    const {regionId} = props.match.params;
    const talkTimeService = useService<ITalkTimeService>(TYPES.ITalkTimeService);
    const [isStudentGrid, setStudentGrid] = useState(false);
    const [breadcrumbData, setBreadCrumbData] = useState<BreadcrumbData>({});
    const [isPageModified, setPageModified] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        breadcrumbData.regionId = regionId;
        getData(regionId);
    }, [])

    const getData = (entityId: string) => {
        let promise: Promise<TalkTimeEntitySetting[]> = null;
        setStudentGrid(false);
        promise = talkTimeService.getSchoolsByRegion(entityId);

        if (!promise) {
            return;
        }
        setPageModified(false);
        promise.then(value => {
            const gridData = value;
            if (Array.isArray(value)) {
                setDataSource(gridData);
                setMasterDataSource(gridData);
            } else {
                setDataSource([]);
                setMasterDataSource([]);
            }
            reloadHeaderCheckboxStatus(gridData);
        });
    }

    /**
     * set header checkbox status by status of every row in the data-grid
     * @param gridDatasource
     */
    const reloadHeaderCheckboxStatus = (gridDatasource: TalkTimeEntitySetting[]) => {
        let totalChecked = gridDatasource.filter(x => x.enabled).length;
        setHeaderIndeterminateStatus(false);
        if (totalChecked === gridDatasource.length && totalChecked) {
            setHeaderCheckboxStatus(true);
            return;
        }
        setHeaderCheckboxStatus(false);
        if (totalChecked && totalChecked < gridDatasource.length) {
            setHeaderIndeterminateStatus(true);
            return;
        }
        if (gridDatasource.findIndex(x => x.enabled === null || x.enabled === undefined) >= 0) {
            setHeaderIndeterminateStatus(true);
        }
    }

    const onSave = (event) => {
        const callback = typeof event == "function" ? event : null;
        const updateData = dataSource
            .filter((item) => item.dirty).map(item => {
                return {
                    id: item.id,
                    entityType: EntityType.school,
                    enabled: item.enabled
                }
            })

        maskThrottle();

        if (updateData.length == 0) {
            setTimeout(() => {
                unmaskThrottle();
                notification.success({
                    message: fmtMsg({id: TalkTime.TalkTimeSettingSaveSuccess}),
                    className: "tsc-notif"
                });
            }, 500);
            return;
        }

        let promise = talkTimeService.setTalkTimeSetting(updateData);

        promise.then((data) => {
                callback ? callback() : reloadData();
                notification.success({
                    message: fmtMsg({id: TalkTime.TalkTimeSettingSaveSuccess}),
                    className: "tsc-notif"
                });
            })
            .catch((er) => {
                unmaskThrottle();
                notification.error({
                    message: fmtMsg({id: GSAdminLocale.SchoolTeachingSettingError}),
                    className: "tsc-notif"
                });
            });
    }

    const cancelPageOperations = () => {
        if (isPageModified) {
            triggerConfirmModal(ConfirmationType.Cancel, null);
        } else {
            goToRegion();
        }
    }

    const goToRegion = () => {
        props.history.push({
            pathname: GLUtil.pathStringify(PathConfig.Region, { regionId }),
        });
    }

    const reloadData = () => {
        const {schoolId, campusId, classId} = breadcrumbData;
        getData(regionId);
    };

    const removeBreadCrumbData = () => {
        const breadcrumb = {...breadcrumbData};
        setEntityNameHeader(fmtMsg({id: GSAdminLocale.RegionManageStudentsSchool}));
        removeSchoolInfo(breadcrumb);
        setSearchText("");
        setBreadCrumbData(breadcrumb);
    }
    const removeSchoolInfo = (data: BreadcrumbData) => {
        data.schoolId = null;
        data.schoolName = null;
    };

    const onItemClickConfirmation = (data: TalkTimeEntitySetting) => {
        const breadcrumb = {...breadcrumbData};
        setSearchText("");
        if(data.entityType === EntityType.school) {
            setEntityNameHeader(fmtMsg({id: GSAdminLocale.RegionManageStudentsSchool}));
            getData(data.id);
        }
    };

    const triggerConfirmModal = (navigationType, data) => {
        const content =
            navigationType == ConfirmationType.Item
                ? fmtMsg({id: TalkTime.TalkTimeSaveMsg})
                : navigationType == ConfirmationType.Cancel
                    ? fmtMsg({id: TalkTime.TalkTimeCancelMsg})
                    : fmtMsg({id: TalkTime.TalkTimeNavigationMsg});
        const okText =
            navigationType == ConfirmationType.Item ? fmtMsg({id: GSAdminLocale.ButtonSave}) : fmtMsg({id: GSAdminLocale.ModelButtonYes});
        const cancelText =
            navigationType == ConfirmationType.Item ? fmtMsg({id: GSAdminLocale.ButtonCancel}) : fmtMsg({id: GSAdminLocale.ModelButtonNo});
        Modal.confirm({
            title: fmtMsg({id: GSAdminLocale.ModelTitleConfirmation}),
            content,
            okText,
            okType: "danger",
            cancelText,
            onOk() {
                if (navigationType == ConfirmationType.Cancel) {
                    goToRegion();
                } else if (navigationType == ConfirmationType.Item) {
                    onSave(() => onItemClickConfirmation(data));
                } else if (navigationType == ConfirmationType.Navigation) {
                    if(data)
                    {
                        getData(data.entityId);
                        removeBreadCrumbData();
                    } else
                    {
                        goToRegion();
                    }
                }
            },
            onCancel() {
            },
        });
    };

    const onToggleHeaderCheckbox = (e) => {
        setHeaderIndeterminateStatus(false);
        const newStatus = !headerCheckboxStatus;
        setHeaderCheckboxStatus(newStatus);

        const data = dataSource.map(t => {
            t.enabled = newStatus;
            t.dirty = true; // dirty means this record data is changed
            return t;
        });
        changePageModifiedStatus();
        setDataSource(data);
        setMasterDataSource(data);
    };

    const changePageModifiedStatus = () => {
        if (!isPageModified) {
            setPageModified(true);
        }
    };

    /**
     * Event for checkbox of each row
     */
    const onRowCheckboxChanged = (e) => {
        const entityId = e.target["data-id"];
        if (!entityId) {
            return;
        }

        const data = [...dataSource];
        const item = data.find(x => x.id == entityId);
        if (!item) {
            return;
        }
        item.enabled = e.target.checked;
        item.dirty = true;
        reloadHeaderCheckboxStatus(data);
        changePageModifiedStatus();
        setMasterDataSource(data);
    }

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };
    const onSearch = (e) => {
        if (masterDataSource.length > 0) {
            let dataToRender = masterDataSource;
            if (searchText) {
                const valueToSearch = searchText.toLowerCase();
                dataToRender = masterDataSource.filter((x) => {
                    if (x.name.toLowerCase().includes(valueToSearch)) return x;
                });
            }
            let isAllTalkTimeEnabled = isAllChecked(dataToRender);
            setHeaderIndeterminateStatus(dataToRender.some((x) => x.enabled) && !isAllTalkTimeEnabled);
            setHeaderCheckboxStatus(isAllTalkTimeEnabled);
            setDataSource(dataToRender);
        }
    };

    const isAllChecked = (data: TalkTimeEntitySetting[]) => {
        if (data.length == 0) return false;
        return data.every((x) => x.enabled);
    };

    const getColumns = (): ReactNode => {
        const columns = [
            <Column
                key="0"
                header={entityNameHeader}
                binding={TalkTimeSettingGridColumns.name}
                cssClass="anchor-column"
                render={(text, data) => {
                    if (data.entityType == EntityType.school) {
                        return (
                            <span>{text}</span>
                        )
                    }
                }}
            />,
            <Column
                key="1"
                renderHeader={() => (
                    <span style={{float: 'left'}}>
                        <Checkbox
                            disabled={isReadonly}
                            indeterminate={headerIndeterminateStatus}
                            checked={headerCheckboxStatus}
                            data-column-name={TalkTimeSettingGridColumns.enabled}
                            onChange={onToggleHeaderCheckbox}
                        >
                            {fmtMsg({id: TalkTime.TalkTimeAllow})}
                        </Checkbox>
                    </span>
                )}
                align="left"
                binding={TalkTimeSettingGridColumns.enabled}
                render={(text, data) => {
                    return (
                        <Checkbox
                            disabled={isReadonly}
                            key={data.id}
                            data-column-name={TalkTimeSettingGridColumns.enabled}
                            data-id={data.id}
                            checked={data.enabled}
                            onChange={onRowCheckboxChanged}
                        />
                    );
                }}
            />
        ];
        if (!isStudentGrid) {
            columns.push(<Column
                key="3"
                header={fmtMsg({id: TalkTime.TalkTimeTotal})}
                binding={TalkTimeSettingGridColumns.totalStudents}
                align="center"
            />)
        }
        return columns;
    };

    return (
        <Container fullWidth fullHeight>
            <MainTitle title={GSAdminLocale.RegionTalkTimeSetting}/>
            <Row style={{ marginBottom: 15 }}>
                <Input.Search
                    placeholder={fmtMsg({id: GSAdminLocale.Search})}
                    value={searchText}
                    onChange={onSearchTextChange}
                    onSearch={onSearch}
                    style={{ maxWidth: 350, marginBottom: 15 }}
                />
                <Grid
                    itemsSource={dataSource}
                    pagination={false}
                    selectionMode={SelectionMode.ListBox}
                    defaultSort={{
                        column: TalkTimeSettingGridColumns.name,
                        ascending: true,
                    }}>
                    {getColumns()}
                </Grid>
            </Row>
            <Row type="flex" justify="end" align="middle" className="action-buttons" gutter={20}>
                <Col>
                    {!isReadonly && dataSource.length > 0 && (
                        <Button type="primary" onClick={onSave} title={fmtMsg({id: GSAdminLocale.ButtonSubmit})}>
                            {fmtMsg({id: GSAdminLocale.ButtonSubmit})}
                        </Button>
                    )}
                </Col>
                <Col>
                    <Button type="default" onClick={cancelPageOperations}
                            title={fmtMsg({id: GSAdminLocale.ButtonCancel})}>
                        {fmtMsg({id: GSAdminLocale.ButtonCancel})}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}
export default TalkTimeAccess
