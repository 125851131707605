import DataSet from "@antv/data-set";
import {Loading} from "@app/components";
import {Column} from "@app/components/grid/grid-column";
import {WijmoGrid} from "@app/components/grid/wijmo-grid";
import {DecimalRate} from "@app/components/survey/decimal-rate/decimal-rate";
import {SurveyPathConfig} from "@app/config/pathconfig";
import {useService} from "@app/hooks";
import {DashboardLocale, SurveyLocale} from "@app/locales/localeid";
import {
    ISurveyResponseService,
    OptionQuestionModel,
    PollResponseModel,
    PollResponseStatusCode,
    RatedPollQuestionModel
} from "@app/service/survey/response";
import {BlobHelper, Colors, DateHelper, fmtMsg, isExitSurvey, TYPES} from "@app/util";
import {Badge, Button, Col, Collapse, Row, Typography} from "antd-min";
import {Axis, Chart, Coord, Geom, Tooltip} from "bizcharts";
import {GLGlobal, GLRouteComponentProps, MessageHelper, NotificationType, withRouter} from "gl-commonui";
import React, {useEffect, useState} from "react";
import {CellType, GroupRow, SelectionMode} from "wijmo/wijmo.grid";
import {Result} from "@app/components/result/result";
import "./response-tab.less";

const { Panel } = Collapse;

interface ResponseTabProps extends GLRouteComponentProps {
}

export const ResponseTab = withRouter((props: ResponseTabProps) => {
    const [loading, setLoading] = useState(false);
    const [pollResponse, setPollResponse] = useState({ response: [] } as PollResponseModel);
    const [hasPermissionExport, setHasPermissionExport] = useState(false);
    const service = useService<ISurveyResponseService>(TYPES.ISurveyResponseService);

    const { history, match: { params: { surveyId, surveyInstanceId } } } = props;
    useEffect(() => {
        setLoading(true);
        service
            .getSurveyResponses(surveyId, surveyInstanceId || "null")
            .then(data => {
                setPollResponse(data);
                setLoading(false);
                document.addEventListener("click", onItemClick, false);
            })
            .catch(() => {
                setLoading(false);
            });

        service.hasPermissionToExportSurvey(surveyId).then(data => {
            setHasPermissionExport(data);
            setLoading(false);
        });

        return () => {
            document.removeEventListener("click", onItemClick, false);
        };
    }, []);

    const navigateToFillSurvey = ({ userId, responseId, instanceId }) => {
        if (props.match.path === SurveyPathConfig.DetailsFromLanding) {
            history.push(`${SurveyPathConfig.Home}/${surveyId}/${instanceId}/${userId}/review/${responseId}?tab=responses`);
        }
        else if (props.match.path === SurveyPathConfig.ViewResponseCreatorShared) {
            history.push(`${SurveyPathConfig.Home}/${surveyId}/${instanceId}/${userId}/view/${responseId}`);
        }
        else {
            history.push(`${SurveyPathConfig.Home}/${surveyId}/${instanceId}/createdhistory/${userId}/review/${responseId}?tab=responses`);
        }
    };

    const onItemClick = e => {
        if (e.target && e.target.tagName.toUpperCase() == "A" && e.target.getAttribute("data-response")) {
            const { column, data } = JSON.parse(e.target.getAttribute("data-response"));

            switch (column) {
                case "user":
                    navigateToFillSurvey(data);
                    break;

                default:
                    break;
            }

            e.preventDefault();
            e.stopPropagation();
        }
    };

    const renderWijmoRow = (s, e) => {
        if (e.panel.cellType == CellType.Cell && !(s.rows[e.row] instanceof GroupRow)) {
            switch (s.columns[e.col].binding) {
                case "user":
                    e.cell.innerHTML = `<a
                        data-response =${JSON.stringify({
                        column: "user",
                        data: {
                            responseId: e.panel.rows[e.row].dataItem.responseId,
                            instanceId: e.panel.rows[e.row].dataItem.instanceId,
                            userId: e.panel.rows[e.row].dataItem.userId
                        }
                    })}>
                        ${e.panel.rows[e.row].dataItem.user}
                    </a>`;
                    break;

                default:
                    break;
            }
        }
    };

    const columns = [
        {
            localeId: SurveyLocale.PollResponseSubmitDate,
            dataIndex: "submitDate",
            width: "50*",
            allowDragging: true
        },
        {
            localeId: SurveyLocale.PollResponseUser,
            dataIndex: "user",
            width: "50*",
            allowDragging: true
        }
    ];

    const getInstanceCardTitle = (date: Date | string) => {
        return `${fmtMsg({ id: SurveyLocale.PollResponseInstanceCreationDate })} - ${DateHelper.formatDate2Local(date)}`;
    };

    const getPageCardTitle = (pollResponse: PollResponseModel) => {
        if (loading || !pollResponse.surveyName) {
            return null;
        }

        return `${fmtMsg({ id: SurveyLocale.PollResponsePollName })} - ${pollResponse.surveyName}`;
    };

    const getCharts = (surveyId: string, ratedQuestions: RatedPollQuestionModel[], optionQuestions: OptionQuestionModel[]) => {
        return (
            <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={12}>
                    {
                        optionQuestions.map(questionPollModel => {
                            const { DataView } = DataSet;
                            const dv = new DataView();
                            dv.source(questionPollModel.chartCollection).transform({
                                type: "sort",
                                callback: (x, y) => {
                                    return x.count - y.count > 0;
                                }
                            });

                            return (
                                <Row type="flex" key={questionPollModel.id}>
                                    <Col span={24}>
                                        <div className="response-tab__card__question">
                                            <Typography.Text strong>Q. {isExitSurvey(surveyId) ? fmtMsg({ id: questionPollModel.localeKey }) : questionPollModel.question}</Typography.Text>
                                        </div>
                                        <Chart
                                            height={320}
                                            data={dv}
                                            scale={{
                                                option: { alias: fmtMsg({ id: SurveyLocale.PollResponseOption }) },
                                                count: { alias: fmtMsg({ id: SurveyLocale.PollResponseUserCount }) }
                                            }}
                                            forceFit={true}
                                        >
                                            <Coord scale={[0.7, 1.2]} transpose />
                                            <Axis label={{
                                                textStyle: { rotate: 0 },
                                                autoRotate: false,
                                                formatter: text => (text && text.length > 10) ? `${text.slice(0, 10)}...` : text
                                            }}
                                            title={{
                                                offset: 110,
                                            }}
                                            name="option"></Axis>
                                            <Axis name="count"></Axis>
                                            <Geom
                                                color={["option", Colors.slice(0, questionPollModel.chartCollection.length)]}
                                                type="interval"
                                                position="option*count"
                                                tooltip={[
                                                    "option*count",
                                                    (option) => {
                                                        return {
                                                            name: option,
                                                            value: ""
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Tooltip showTitle={false} />
                                            </Geom>
                                        </Chart>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    {
                        ratedQuestions.map(questionPollModel => {
                            return (
                                <div key={questionPollModel.id} className="response-tab__card__question">
                                    <div>
                                        <Typography.Text strong>Q. {isExitSurvey(surveyId) ? fmtMsg({ id: questionPollModel.localeKey }) : questionPollModel.question}</Typography.Text>
                                    </div>
                                    <Row type="flex" justify="space-between" align="middle">
                                        <Col>
                                            <DecimalRate disabled type={questionPollModel.surveyQuestionRatingType} count={questionPollModel.maxRating} value={questionPollModel.averageRating} allowHalf></DecimalRate>
                                        </Col>
                                        <Col>
                                            {fmtMsg({ id: SurveyLocale.PollResponseAverage })}: <Badge className="response-tab__badge" count={questionPollModel.averageRating} showZero />
                                        </Col>
                                    </Row>

                                </div>
                            );
                        })
                    }
                </Col>
            </Row>
        );
    }

    const exportSurvey = () => {
        let messageId = SurveyLocale.SurveyExportMessagePrepare;
        let notificationType = NotificationType.Warning;
        MessageHelper.Message(notificationType, GLGlobal.intl.formatMessage({ id: messageId }));
        service.exportSurvey({id: surveyId}).then(data => {
            if (BlobHelper.saveFileSurvey(data)) {
                messageId = SurveyLocale.SurveyExportMessageSuccess;
                notificationType = NotificationType.Success;
            }
            MessageHelper.Message(notificationType, GLGlobal.intl.formatMessage({ id: messageId }));
        });
    }

    if (loading) {
        return <Loading visible />;
    }

    switch (pollResponse.statusCode) {
        case PollResponseStatusCode.Unpublished:
            return <Result type="warning" textId={SurveyLocale.PollResponseUnpublishedMsg} />;
        case PollResponseStatusCode.NoResponses:
            return <Result type="info" textId={SurveyLocale.PollResponseNoResponseText} />;

        case PollResponseStatusCode.Success:
            return (
                <>
                    <Row type="flex" gutter={16}>
                        <Col xs={24} md={21}><Typography.Title level={3}>{getPageCardTitle(pollResponse)}</Typography.Title></Col>
                        { hasPermissionExport && (
                            <Col xs={24} md={3}>
                                <Button className="export-survey-btn" onClick={exportSurvey} type="primary">
                                    {fmtMsg({id: SurveyLocale.SurveyExportButtonTitle})}
                                </Button>
                            </Col>
                        )}
                    </Row>
                    {!loading && (
                        <Collapse defaultActiveKey={["0"]}>
                            {pollResponse.response.map(({ surveyInstanceId, pollCreationDate, instanceResponse, ratedQuestions, optionQuestions, multiChoiceQuestions }, index) => {
                                return (
                                    <Panel key={index.toString()} header={getInstanceCardTitle(pollCreationDate)}>
                                        <>
                                            {getCharts(pollResponse.surveyId, ratedQuestions, optionQuestions.concat(multiChoiceQuestions))}
                                            <WijmoGrid
                                                scroll={{ height: 300 }}
                                                columnLangMap={columns.map(({ localeId, dataIndex }) => ({ localeId, dataIndex }))}
                                                loading={loading}
                                                selectionMode={SelectionMode.ListBox}
                                                headersVisibility="Column"
                                                itemsSource={instanceResponse.map(r => ({
                                                    responseId: r.surveyResponseId,
                                                    instanceId: r.surveyInstanceId,
                                                    submitDate: DateHelper.formatDate2Local(r.submitDate),
                                                    user: r.responderName,
                                                    userId: r.responderId,
                                                }))}
                                                formatItem={renderWijmoRow}
                                                grouping
                                                groupBarText={fmtMsg({ id: DashboardLocale.LandingTabDragColumnsText })}
                                                noDataLocaleId={SurveyLocale.PollResponseNoResponseText}
                                                isReadOnly
                                            >
                                                {columns.map(column => (
                                                    <Column
                                                        allowDragging={column.allowDragging}
                                                        binding={column.dataIndex}
                                                        header={fmtMsg({ id: column.localeId })}
                                                        width={column.width}
                                                    />
                                                ))}
                                            </WijmoGrid>
                                        </>
                                    </Panel>
                                );
                            })}
                        </Collapse>
                    )}
                </>
            );

        default:
            return null;
    }
});
