import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IContentVersionService, ContentVersionModel, GetContentVersionRequest, ContentVersionRequest } from './index';
import { LessonPlanTemplateModel, LessonPlanVersionModel } from './model';

@injectable()
export class ContentVersionService extends GLServiceBase<ContentVersionRequest, GetContentVersionRequest> implements IContentVersionService {
    serviceRoute = { prefix: 'content/v1/versions' }
    getContentVersions() {
        this.serviceRoute = { prefix: 'content/v1/versions' }
     }
    getContentCategories() : any {
        this.serviceRoute = { prefix: 'content/v1/Resource' }
        return this.get('GetContentCategories');
     }
    getLessonPlanVersions(params: { 
        versionGroupIds: string[] 
    }): Promise<Array<Partial<LessonPlanVersionModel>>> {
        this.serviceRoute = { prefix: 'content/v1/lessonplanversions' };
        return this.get(params);
    } 
}