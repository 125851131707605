import { ResourceService } from '@app/service/resources/index';
import { isGuid } from '@app/util/func';
import { GLGlobal } from 'gl-commonui';

interface Services {
    resource: ResourceService
}

export enum BreadcrumbTypes {
    schoolClass = 110,
    campus = 111,
    school = 112,
    region = 113,
    order = 114,
    template = 115,
    student = 116,
    globalProduct = 117,
    localProduct = 118,
    survey = 119,
}

export interface ResourceState {
    breadcrumbs?: any
}


export default {
    namespace: 'adminResource',
    state: {
        breadcrumbs: null
    },
    reducers: {
        mergeBreadcrumbs(state, action) {
            return { ...state, breadcrumbs: { ...state.breadcrumbs, ...action.payload } };
        }
    },
    effects: {
        *getBreadcrumbs({ payload }, { call, put }, { resource }: Services) {
            let d = formatBreadcrumbs(payload);
            if (isGuid(d.breadcrumbIds[0])) {
                const data = yield call(resource.getBreadcrumbs, d);
                yield put(mergeBreadcrumbs(data));
            }
        },
    },
    services: {
        resource: ResourceService
    }
}

function formatBreadcrumbs(params) {
    const { regionId, schoolId, campusId, orderId, classId, studentId, templateId, globalProductId, localProductId, surveyId } = params;
    const ids = [];
    const types = [];
    const push = (id, type) => {
        ids.push(id);
        types.push(type);
    }
    regionId && push(regionId, BreadcrumbTypes.region);
    schoolId && push(schoolId, BreadcrumbTypes.school);
    campusId && push(campusId, BreadcrumbTypes.campus);
    classId && push(classId, BreadcrumbTypes.schoolClass);
    studentId && push(studentId, BreadcrumbTypes.student);
    orderId && push(orderId, BreadcrumbTypes.order);
    templateId && push(templateId, BreadcrumbTypes.template);
    globalProductId && push(globalProductId, BreadcrumbTypes.globalProduct);
    localProductId && push(localProductId, BreadcrumbTypes.localProduct);
    surveyId && push(surveyId, BreadcrumbTypes.survey);

    return { breadcrumbIds: ids, breadcrumbTypes: types };
}

export function mergeBreadcrumbs(state) {
    return { type: 'adminResource/mergeBreadcrumbs', payload: state }
}
export function getBreadcrumbs(state) {
    return { type: 'adminResource/getBreadcrumbs', payload: state }
}
export function setBreadcrumb(state) {
    GLGlobal.store.dispatch(mergeBreadcrumbs(state));
}