import { IContextMenuModel, ResponseStatusType } from "@app/util";
import { GLPagingRequest, GLRequest, GLResponse } from "gl-commonui";
// import { SurveyQuestionResponseModel, SurveyAnswerModel } from "../templates";
import {  IdentityModel } from "@app/service/school/invitation";

export interface ICommonModel {
    id: string;
    surveyName: string;
    surveyInstanceId?: string;
    dueDate: Date;
}


export interface SurveyRoleModel {
    id: string;
    name: string;
}

export interface CreatedSurveyModel extends IContextMenuModel {
    id: string;
    name: string;
    isRecurring: boolean;
    roles: SurveyRoleModel[];
    users: string[];
    disabled: boolean;
    creationDate: Date;
    dueDate: Date;
}

export interface MySurveyModel extends ICommonModel {

}

export interface ReviewSurveyModel extends ICommonModel {
    surveyInstanceId: string;
    userName: string;
    submitDate: Date;
    lateDays?: number;
    responseStatus?: number;
}

export interface SharedSurveyModel extends ICommonModel {
    userName: string;
    responseBy?: string;
    isRecieverShared: boolean;
}


export interface MySurveyResponseModel extends ICommonModel {
    data: MySurveyModel[];
    totalCount: number;
}

export interface ReviewSurveyResponseModel extends ICommonModel {
    data: ReviewSurveyModel[];
    totalCount: number;
}

export interface SharedSurveyResponseModel extends ICommonModel {
    data: SharedSurveyModel[];
    totalCount: number;
}

export interface TodoSurveyResponseModel {
    id: string;
    surveyId: string;
    surveyInstanceId: string;
    statisticsFilterId?: string;
    statisticsFilterTypeId?: StatisticsFilterType;
    statisticsFilter?: string;
    createdBy?: string;
    fillDate?: string | Date;
    responseStatus: ResponseStatusType;
    name: string;
    description?: string;
    dueDate: string | Date;
    surveyQuestion: SurveyQuestionResponseModel[];
    surveyResponseShare?: any[];
    submitDate?: string | Date;
    isDigitalAdoptionReport?: boolean;
    isLicenseHistoryReport?: boolean;
    isSchoolAndClassesReport?: boolean;
    isUserStatisticsReport?: boolean;
    isStatisticsRequired?: boolean;
    schoolName?: string;
    campusName?: string;
    userId?: string;
    userName?: string;
    displayDescription?: string;
}

export interface TodoSurveyRequestModel {
    id: string;
    surveyInstanceId: string;
    statisticsFilterId?: string;
    responseStatus: ResponseStatusType;
    surveyResponseAnswer: SurveyAnswerModel[];
    surveyResponseShare?: any[];
    isShareModified?: boolean;
}

export interface SurveyModel {
    id?: string;
    name: string;
    description?: string;
    isRecurring: boolean;
    dueDate?: string | Date;
    recurringTypeId?: SurveyRecurrenceType | number;
    recurringDay?: number;
    createdBy?: string;
    creationDate?: string | Date;
    surveyRoleTarget?: SurveyRoleTargetModel[];
    roleTargets?: SurveyRoleTargetModel[];
    surveyShareTarget?: string[];
    surveyShare: SurveyShareResponseModel[];
    surveyType?: SurveyToTargets | number;
    surveyQuestion: SurveyQuestionModel[];
    statisticsFilterTypeId?: [];
    reportTypeId?: [];
    surveyReportType?: KeyValueResponseModel[];
    isStatisticsRequired?: boolean;
    surveyStatus: SurveyStatus;
    allowDelete: boolean;
    isPaging: boolean;
}

export interface SurveyQuestionModel {
    id?: string;
    lid?: string; // used for validation
    question: string;
    disabled: boolean;
    createdBy?: string;
    creationDate?: string | Date;
    updatedBy?: string;
    updateDate?: string | Date;
    required: boolean;
    surveyQuestionTypeId: QuestionType;
    surveyQuestionOption?: SurveyQuestionOptionModel[];
    surveyResponse?: SurveyResponseAnswerModel[];
    minScore?: number;
    maxScore?: number;
    maxRating?: number;
    surveyQuestionRatingType?: number;
    isNaEnabled?:boolean;
    surveyId: string;
    localeKey: string
}

export interface SurveyQuestionOptionModel {
    id?: string;
    lid?: string; // used for validation
    surveyQuestionId?: string;
    option: string;
    order?: number;
    localeKey: string;
}

export interface SurveyResponseAnswerModel {
    id?: string;
    surveyResponseId?: string;
    surveyQuestionId?: string;
    answer: string;
}

export interface SurveyShareResponseModel {
    userId: string;
    surveyId: string;
}

export interface SurveyRoleTargetModel {
    role: SurveyRoles,
    targetType: SurveyTargetTypes,
    targetId: string,
    targetName: string,
    isCalculated?: boolean
}
export interface SurveyTargetCount {
    roleTargets: SurveyRoleTargetModel[],
    notifiedUserCount: number
}

export interface QuestionAnswerMap {
    questionId: string;
    answerId: string;
}

export interface QuestionAnswerMapResponse {
    responseId: string;
    questionAnswerMap: QuestionAnswerMap[];
}

// export interface SaveAttachmentRequestModel {
//     id?: string;
//     name: string;
//     surveyResponseAnswerId: string;
// }

export class GetSurveyRequest extends GLPagingRequest {
}
export interface SurveyRequest extends GLRequest, SurveyModel {
}
export interface SurveyResponse extends GLResponse, SurveyModel {
}

export enum SurveyRoles {
    All = -1,
    Teacher = 0,
    Parent = 1,
    CampusAdmin = 2,
    SchoolAdmin = 3,
    RegionAdmin = 4,
    TrainingAdmin = 5,
    Trainer = 6,
    GlobalHead = 7,
    AccountManager = 9,
    TrainingManager = 10,
    IndividualUser = 89
}
export enum SurveyRoleNames {
    All = 'All',
    Teacher = 'Teacher',
    Parent = 'Parent',
    CampusAdmin = 'Campus Administrator',
    SchoolAdmin = 'School Administrator',
    RegionAdmin = 'Region Administrator',
    TrainingAdmin = 'Training Administrator',
    Trainer = 'Coach',
    GlobalHead = 'GlobalHead',
    IndividualUser = 'Individual User',
    AccountManager = 'Account Manager',
    TrainingManager = 'Training Manager',
}
export enum SurveyTargetTypes {
    All = 0,
    Region = 1,
    School = 2,
    Campus = 3,
    SchoolClass = 4,
    IndividualUser = 5,
}
export enum SurveyTargetTypeNames {
    All = 'All',
    Region = 'Region',
    School = 'School',
    Campus = 'Campus',
    SchoolClass = 'School Class',
    IndividualUser = 'Individual User',
}
export enum SurveyStatus {
   Draft,
   Published
}


export enum SurveyToTargets {
    Roles = 0,
    Users = 1
}

export enum SurveyRecurrenceType {
    Weekly = 1,
    Monthly = 2
}

export enum WeeklyRecurrenceDay {
    Sun = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thu = 4,
    Fri = 5,
    Sat = 6
}

export enum QuestionType {
    Text = 1,
    Option = 2,
    Rating = 3,
    MultChoice = 4,
    Score = 5,
}

export enum SurveyReportType {
    DigitalAdoptionReport = 1,
    SchoolCountReport = 2,
    LicenseHistory = 3,
    UserStatistics = 4
}

export enum SurveyFilterDataType {
    DataByRegionGroup = 1,
    DataByRegion = 2
}

export class SurveyInstanceResponseModel {
    instanceId: string;
    surveyInstanceName: string;
    dueDate: string | Date;
    surveyResponses: InstanceResponseModel[];
}

export class InstanceResponseModel {
    responseId: string;
    userName: string;
    submitDate: string | Date;
}

export enum StatisticsFilterType {
    RegionGroup = 1,
    Region = 2,
}

export class SubmittedSurveyHistoryModel {
    responseId : string;
    surveyInstanceId: string;
    surveyName: string;
    submitDate: string | Date;
}

export interface CreatedPollModel {
    id: string;
    surveyInstanceId: string;
    name: string;
    isRecurring: boolean;
    dueDate: Date | string;
    publishedDate: Date | string;
    creationDate: Date | string;
    userCount: number;
    completedCount: number;
}

export interface CreatedPollsResponseModel {
    data: CreatedPollModel[];
    totalCount: number;
}

export enum PollReviewType {
    Todo = 0,
    CreatorShared = 1,
    ReceiverShared = 2
}

export interface KeyValueResponseModel {
    id: number;
    name: string;
}

export interface ReviewPollModel {
    id: string;
    surveyId: string;
    surveyResponseId?: string;
    name: string;
    dueDate: Date | string;
    publishedDate: Date | string;
    submitDate?: Date | string;
    surveyReviewType: PollReviewType;
    creator: IdentityModel | string;
    respondedBy: string;
    isExpired: boolean;
    isRecurring: boolean;
    completeByCount?: number;
    totalTodoCount?: number;
    schoolName?: string;
    userName?: string;
    surveyDisplayName?: string;
}

export interface ReviewPollsResponseModel {
    data: ReviewPollModel[];
    totalCount: number;
}

export interface PollParticipationResponse {
    data: PollUserModel[];
    totalCount: number;
}

export interface PollUserModel extends IdentityModel {
    email: string;
    hasCompleted: boolean;
    surveyResponseId: string;
}

export interface SurveyQuestionResponseModel {
    id: string;
    question?: string;
    required: boolean;
    surveyQuestionTypeId: number;
    surveyResponseAnswer?: SurveyAnswerModel[];
    surveyQuestionOption: SurveyQuestionOptionModel[];
    minScore?: number;
    maxScore?: number;
    maxRating?: number;
    surveyQuestionRatingType?: number;
    isNaEnabled?: boolean;
    surveyId: string;
    localeKey: string;
}

export interface SurveyAnswerModel {
    id: string;
    surveyResponseId: string;
    surveyQuestionId: string;
    surveyQuestionTypeId?: number;
    surveyQuestionOptionId?: string;
    answer?: string;
    surveyResponseAttachment?: SurveyAttachmentModel[];
    localeKey: string;
}

export interface SurveyAttachmentModel {
    id?: string;
    name: string;
    surveyResponseAnswerId: string;
    url?: string;
}

export interface SchoolAndClassesReportModel {
    classCount: number;
    schoolCount: number;
    studentCount: number;
    teacherCount: number;
    gsSchoolCount: number;
    gsClassCount: number;
    gsStudentCount: number;
    gsTeacherCount: number;
    lsSchoolCount: number;
    lsClassCount: number;
    lsStudentCount: number;
    lsTeacherCount: number;
    lastSchoolCount: number;
    lastClassCount: number;
    lastStudentCount: number;
    lastGsSchoolCount: number;
    lastGsClassCount: number;
    lastGsStudentCount: number;
    lastLsSchoolCount: number;
    lastLsClassCount: number;
    lastLsStudentCount: number;
}
