import { TagEntity, TagListModel, TagManagerService, TagTypeModel } from "@app/service/tag-manager";

export interface TagManagerState {
	types: TagTypeModel[];
	tagList: {
		/**
		 * @key is combination of regionId and entityId. Example: `${regionId}-${entityId}`
		 */
		[key: string]: TagListModel[];
	};
	tagListLoading: boolean;
	tagListUpdated: boolean;
}

interface Services {
	tagManagerService: TagManagerService;
}

export default {
	namespace: "tagManager",
	state: {
		types: [],
		tagList: {},
		tagListLoading: false,
		tagListUpdated: false
	} as TagManagerState,
	reducers: {
		setTypes(state: TagManagerState, action: { payload: TagTypeModel[] }): TagManagerState {
			return { ...state, types: action.payload };
		},
		setTagListLoading(state: TagManagerState, action: { payload: boolean }): TagManagerState {
			return { ...state, tagListLoading: action.payload };
		},
		setTagList(state: TagManagerState, action: { payload: { tagList: TagListModel[]; regionId: string; entity: TagEntity } }): TagManagerState {
			const { tagList, regionId, entity } = action.payload;
			return { ...state, tagList: { ...state.tagList, [`${regionId}-${entity}`]: tagList } };
		},
		setTagListUpdated(state: TagManagerState, action: { payload: boolean }): TagManagerState {
			return { ...state, tagListUpdated: action.payload };
		},
	},
	effects: {
		*fetchTypes(_, { call, put }, { tagManagerService }: Services) {
			const data = yield call(tagManagerService.getTypes);
			yield put(setTagTypes(data));
		},
		*fetchTagList({ payload: { regionId, entity } }, { call, put }, { tagManagerService }: Services) {
			yield put(setTagListLoading(true));
			try {
				const data: TagListModel[] = yield call(tagManagerService.getTagsList, regionId, entity);
				yield put(setTagList(data, regionId, entity));
				yield put(setTagListLoading(false));
				yield put(setTagListUpdated(false));
			} catch (err) {
				yield put(setTagListLoading(false));
			}
		},
	},
	services: {
		tagManagerService: TagManagerService,
	},
};

function setTagTypes(tagTypes: TagTypeModel[]) {
	return { type: "tagManager/setTypes", payload: tagTypes };
}
function setTagListLoading(loading: boolean) {
	return { type: "tagManager/setTagListLoading", payload: loading };
}
function setTagList(tagList: TagListModel[], regionId: string, entity: TagEntity) {
	return { type: "tagManager/setTagList", payload: { tagList, regionId, entity } };
}

export function fetchTagTypes() {
	return { type: "tagManager/fetchTypes" };
}
export function fetchTagListAction({ regionId, entity }: { regionId: string; entity: TagEntity }) {
	return { type: "tagManager/fetchTagList", payload: { regionId, entity } };
}
export function setTagListUpdated(isUpdated: boolean) {
	return { type: "tagManager/setTagListUpdated", payload: isUpdated };
}
