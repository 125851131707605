import { PathConfig, SchoolPathConfig } from "@app/config/pathconfig";
import { StateType } from "../index";
import {
    SchoolService,
    SchoolModel,
    SchoolUpdateStatus,
    SchoolUpdateStatusModel
} from "@app/service/schools";
import { isGuid, fmtMsg } from "@app/util/func";
import {
    MergeDocHelper,
    GLGlobal,
    PathConfig as CommonPath,
    OidcProxy,
    signin,
    maskThrottle,
    unmaskThrottle,
    maskMain,
    RoleName,
    MessageHelper,
    NotificationType
} from "gl-commonui";
import { CampusService } from "@app/service/school/campus";
import { getPrimary } from "./campus";
import { RoleService } from "@app/service/school/role";
import { SchoolClassService } from "@app/service/class";
import { GSAdminAction, GSSchoolAction } from "@app/util/enum";
import { Modal, Table, Row, Col } from "antd-min";
import { GSAdminLocale } from "@app/locales/localeid";
import content from "*.svg";
import { ReactNode } from "react";
import React from "react";
import { isArray } from "lodash";

interface Services {
    campus: CampusService;
    school: SchoolService;
    role: RoleService;
    schoolClass: SchoolClassService;
}

export interface SchoolState {
    current?: SchoolModel;
    list?: SchoolModel[];
    isModalShow?: boolean;
    userRoles?: string[];
    roleChanged?: number;
    accessPage?: boolean;
    schoolUpdateErrorStatus?: SchoolUpdateStatusModel;
}

export default {
    namespace: "school",
    state: {
        current: {},
        list: [],
        isModalShow: false,
        userRoles: null,
        roleChanged: 0,
        accessPage: false,
        schoolUpdateErrorStatus: null
    },
    reducers: {
        updateSchoolMaxUnitError(state, action) {
            return {
                ...state,
                schoolUpdateErrorStatus: action.payload
            };
        },
        change(state, action) {
            return {
                ...state,
                current: { ...state.current, ...action.payload }
            };
        },
        fillList(state, action) {
            return { ...state, list: action.payload };
        },
        showModal(state, action) {
            return { ...state, isModalShow: action.payload };
        },
        setUserRoles(state, action) {
            return {
                ...state,
                userRoles: action.payload.state,
                roleChanged: action.payload.changed
            };
        },
        accessPage(state, action) {
            return { ...state, accessPage: action.payload };
        },
        reloadCurrent(state, { payload }) {
            return { ...state, current: { ...state.current, ...payload } };
        }
    },
    effects: {
        *get(
            { payload: { id } },
            { call, put, select },
            { school }: Services,
            { pathParse }
        ) {
            id = id || pathParse({ path: PathConfig.Schools }).schoolId;
            if (isGuid(id)) {
                const schoolModel = yield call(school.get, { id });
                schoolModel.isGSMaxUnit = schoolModel.maxUnit && schoolModel.maxUnit != 0;
                schoolModel.isLSMaxUnit = schoolModel.lsMaxUnit && schoolModel.lsMaxUnit != 0;
                yield put(change(schoolModel));
                yield put(setSchoolUserPermission(id));
            }
        },
        *update({ payload }, { call, put }, { school }: Services, { push }) {
            const { id, name, englishName, image, allowTeacherMailToParent, allowParentMailToTeacher, allowLessonPlanView, gsNumber, maxUnit, lsMaxUnit, subscriptionType, subscriptionTypeUsage, notes, disabled, onSiteVisitCount, lvaCount, appLockState, regionEnableCodeLogin, code, tags, previousDisabledState, grapeSEEDLicenses, littleSEEDLicenses } = payload;
            let data;
            if (GLGlobal.isActionValid(GSSchoolAction.EditSchool)) {
                data = MergeDocHelper.replace({ name, englishName, image, allowTeacherMailToParent, allowParentMailToTeacher, allowLessonPlanView, gsNumber, maxUnit, lsMaxUnit, subscriptionType, subscriptionTypeUsage, notes, disabled, onSiteVisitCount, lvaCount, appLockState, regionEnableCodeLogin, code });
                if (isArray(tags)) {
                    const tagRequestModel = MergeDocHelper.add({ tags });
                    data = [...data, ...tagRequestModel];
                }
                if (isArray(grapeSEEDLicenses)) {
                    const grapeSEEDLicensesModel = MergeDocHelper.add({ grapeSEEDLicenses });
                    data = [...data, ...grapeSEEDLicensesModel];
                }
                if (isArray(littleSEEDLicenses)) {
                    const littleSEEDLicensesModel = MergeDocHelper.add({ littleSEEDLicenses });
                    data = [...data, ...littleSEEDLicensesModel];
                }
            } else {
                data = MergeDocHelper.replace({
                    name,
                    englishName,
                    image,
                    allowTeacherMailToParent,
                    allowParentMailToTeacher,
                    appLockState,
                    code
                });
            }
            const response = yield call(school.merge, data, { id });
            if (response.status == SchoolUpdateStatus.Valid) {
                // successfully updated
                yield put(updateSchoolMaxUnitError(null));
                yield put(change(payload));

                if(typeof previousDisabledState === "boolean" && typeof disabled === "boolean"){
                    if(previousDisabledState === false && disabled === true) {
                       MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: GSAdminLocale.SchoolDisabledMessage }));
                       }
                       else if(previousDisabledState === true && disabled === false){
                         MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: GSAdminLocale.SchoolEnabledMessage }));
                       }
                }

                yield put(goHome());
            } else {
                // Error: there are some existing class having max unit more than selected max unit, so can't update
                yield put(updateSchoolMaxUnitError(response));
            }
        },
        *goHome(
            { payload: { id: schoolId } },
            { select, put },
            services,
            { push, pathStringify, pathParse }
        ) {
            const regionId = pathParse({ path: PathConfig.Schools }).regionId;
            schoolId =
                schoolId ||
                (yield select((state: StateType) => state.school.current.id));
            yield put(
                push(pathStringify(PathConfig.Schools, { regionId, schoolId }))
            );
        },
        *getSchools(
            action,
            { call, put, select },
            { school }: Services,
            { pathParse, replace }
        ) {
            const userId = yield select(
                (state: StateType) => state.oidc.loginInfo.profile.sub
            );
            const regionId = pathParse({ path: PathConfig.Schools }).regionId;
            const schools: any[] = yield call(school.getAccessible, regionId);
            if (schools.length === 0) {
                yield put(replace(CommonPath.AccessDenied));
            } else {
                yield put(fillList(schools));
                const schoolId = pathParse({ path: PathConfig.Schools })
                    .schoolId;
                if (!isGuid(schoolId)) {
                    const id = { id: schools[0].id };
                    yield put(get(id));
                    yield put(getPrimary(id));
                    yield put(setSchoolUserPermission(id));
                    schoolId != undefined && (yield put(goHome(id)));
                } else if (!schools.some(school => school.id === schoolId)) {
                    yield put(replace(CommonPath.AccessDenied));
                }
            }
        },
        *getSchoolsWhenSelect(
            action,
            { call, put, select },
            { school }: Services,
            { pathParse, replace }
        ) {
            const regionId = pathParse({ path: PathConfig.Schools }).regionId;
            const schools: any[] = yield call(school.getAccessible, regionId);
            if (schools.length === 0) {
                yield put(showModal(false));
                const current = yield select(
                    (state: StateType) => state.school.current
                );
                yield put(
                    change({
                        ...current,
                        name: "",
                        address: "",
                        phone: "",
                        image: null
                    })
                );
                yield put(replace(CommonPath.AccessDenied));
            } else {
                yield put(fillList(schools));
            }
        },
        *getSchool(
            action,
            { call, put, select },
            { school }: Services,
            { pathParse, replace }
        ) {
            const regionId = pathParse({ path: PathConfig.Schools }).regionId;
            const schools: any[] = yield call(school.getAccessible, regionId);
            if (schools.length === 0) {
                yield put(showModal(false));
                const current = yield select(
                    (state: StateType) => state.school.current
                );
                yield put(
                    change({
                        ...current,
                        name: "",
                        address: "",
                        phone: "",
                        image: null
                    })
                );
                yield put(replace(CommonPath.AccessDenied));
            } else {
                const school = schools[0];
                yield put(setSchoolUserPermission({ id: school.id }));
                yield put(change(school));
                yield put(fillList(schools));
            }
        },
        *setSchoolUserPermission(
            { payload: { id } },
            { call, put, select },
            { school, role }: Services,
            { pathParse, replace }
        ) {
            id = id || pathParse({ path: PathConfig.Schools }).schoolId;
            if (isGuid(id)) {
                maskThrottle();
                let loginInfo = yield select(
                    (state: StateType) => state.oidc.loginInfo
                );
                const userId = loginInfo.profile.sub;
                const roles = yield call(school.getSchoolUserRoles, {
                    id,
                    userId
                });
                // loginInfo.profile.roles = roles.map((role) => role.name);
                // loginInfo.profile.roleInfos = roles;
                // loginInfo = yield call(OidcProxy.setPermissionInfo, loginInfo);
                // OidcProxy.setLoginInfo(signin(loginInfo));
                yield put(
                    setUserRoles(
                        roles.map(role => role.name),
                        Date.now()
                    )
                );
                unmaskThrottle();
            }
        },
        *switchSchool({ payload: { id } }, { put }) {
            try {
                maskThrottle();
                yield put(showModal(false));
                yield put(get({ id }));
                yield put(getPrimary({ id }));
                yield put(setSchoolUserPermission({ id }));
                yield put(getSchools());
                unmaskThrottle();
            } catch (error) {
                console.log(error);
                unmaskThrottle();
            }
        },
        *preLoadSchool(
            { payload: { id, location } },
            { call, put },
            { school }: Services,
            { pathParse, replace, pathStringify, parsePath }
        ) {
            try {
                maskThrottle();
                const schoolPathWithoutRegion =
                    pathParse({ path: SchoolPathConfig.Schools }).schoolId &&
                    !pathParse({ path: PathConfig.Schools }).schoolId;
                if (isGuid(id)) {
                    const schoolModel = yield call(school.get, { id });
                    const isNormalPath =
                        !(window.location.pathname == CommonPath.NotFound) &&
                        !(window.location.pathname == CommonPath.AccessDenied);
                    if (
                        schoolPathWithoutRegion &&
                        isNormalPath &&
                        schoolModel &&
                        schoolModel.regionId &&
                        location &&
                        location.pathname
                    ) {
                        const pathName = `/regions/${schoolModel.regionId}${location.pathname}`;
                        yield put(replace(pathName));
                    } else {
                        yield put(change(schoolModel));
                    }
                }
                unmaskThrottle();
            } catch (error) {
                unmaskThrottle();
            }
        },
        *updateSchoolNotes(
            { payload: { id, notes } },
            { call, put, select },
            { school }: Services
        ) {
            const data = MergeDocHelper.replace({ notes });
            const current = yield select(
                (state: StateType) => state.school.current
            );
            maskThrottle();
            try {
                yield call(school.merge, data, { id });
                yield put(change({ ...current, notes }));
            } catch (error) { }
            unmaskThrottle();
        }
    },
    services: {
        school: SchoolService,
        role: RoleService,
        campus: CampusService,
        schoolClass: SchoolClassService
    }
};

export function routerParamsChanged(pre: object, cur: object) {
    const preKeys = Object.getOwnPropertyNames(pre);
    const curKeys = Object.getOwnPropertyNames(cur);
    return (
        preKeys.length !== curKeys.length ||
        preKeys.some(key => pre[key] !== cur[key])
    );
}
export function change(state) {
    return { type: "school/change", payload: state };
}
export function get(state) {
    return { type: "school/get", payload: state };
}
export function update(state) {
    return { type: "school/update", payload: state };
}
export function reloadCurrent(state) {
    return { type: "school/reloadCurrent", payload: state };
}
export function goHome(state?) {
    return { type: "school/goHome", payload: state || {} };
}
export function fillList(state) {
    return { type: "school/fillList", payload: state };
}
export function getSchool(state?) {
    return { type: "school/getSchool", payload: state };
}
export function getSchools(state?) {
    return { type: "school/getSchools", payload: state };
}
export function getSchoolsWhenSelect(state?) {
    return { type: "school/getSchoolsWhenSelect", payload: state };
}
export function setSchoolUserPermission(state?) {
    return { type: "school/setSchoolUserPermission", payload: state };
}
export function showModal(state?) {
    return { type: "school/showModal", payload: state };
}
export function setUserRoles(state?, changed?) {
    return { type: "school/setUserRoles", payload: { state, changed } };
}
export function switchSchool(state?) {
    return { type: "school/switchSchool", payload: state };
}
export function accessPage(state?) {
    return { type: "school/accessPage", payload: state };
}
export function preLoadSchool(state?) {
    return { type: "school/preLoadSchool", payload: state };
}
export function updateSchoolNotes(state?) {
    return { type: "school/updateSchoolNotes", payload: state };
}
export function updateSchoolMaxUnitError(state) {
    return { type: "school/updateSchoolMaxUnitError", payload: state };
}
