import { GLServiceBase, GLGlobal, MessageHelper, NotificationType, HttpMethod, getServiceMethodKey, customError } from 'gl-commonui'
import { injectable } from '../../../util/di'
import { IStatisticsService, StatisticModel, StatisticsPagingRequest, StudentStatisticsRequestModel } from './index';

@injectable()
export class StatisticService extends GLServiceBase<StatisticModel, StatisticsPagingRequest> implements IStatisticsService {
    serviceRoute = { prefix: 'report/v1/student-statistic' }

    getLastPlayedUnit(studentId: string): Promise<number> {
        return this.get('LastUnitPlayed', {studentId})
    }

    getStatisticsByPost(model: StudentStatisticsRequestModel, isWithLoader: boolean) {
        if (isWithLoader) {
            return this.create(model);
        }
        else {
            return this.createWithoutLoader(model);
        }
    }
}