import React, { Component, RefObject } from "react";
import "./adminButton.less";

interface ButtonProps {
    text: string;
    onClick?: () => void;
    className?: string;
    disabledButtonClassName?: string;
    reff?: any;
    disabled?: boolean;
}

export default class AdminButton extends Component<ButtonProps, any> {
    render() {
        return (
            <button
                ref={this.props.reff}
                className={`${this.props.className ? this.props.className : "custom-button"} ${
                    !!this.props.disabled ? (this.props.disabledButtonClassName ? this.props.disabledButtonClassName : "disabled-button") : ""
                }`}
                disabled={!!this.props.disabled}
            >
                {this.props.text}
            </button>
        );
    }
}
