import {
    CoachFeedbackIcon,
    SchoolFeedbackIcon,
    SchoolVisitIcon,
    SetUpIcon,
    SupportQuestionnaireIcon,
    TeacherFeedbackIcon,
    VideoManagerIcon,
} from "@app/components/svgicon";
import { SchoolLocale } from "@app/locales/localeid";
import { fmtMsg, GSAdminAction } from "@app/util";
import { VisitationCoachStage, VisitationTeacherStage, VisitationType } from "@app/util/coach/enum";
import { GLGlobal } from "gl-commonui";
import React, { FC, useEffect, useState } from "react";
import { VisitTracking, VisitTrackingStep } from "./visit-tracking";

interface VisitProgressProps {
    isCoach: boolean;
    visitation: any;
    teacherStage?: number;
    selectedStage?: number;
    selectedStageCallback?: (stage: VisitationCoachStage | VisitationTeacherStage) => void;
}
export const VisitProgress: FC<VisitProgressProps> = (props) => {
    const {
        isCoach,
        visitation: { stage, type },
        teacherStage,
        selectedStage,
        selectedStageCallback,
    } = props;

    const [currentStages, setCurrentStages] = useState<VisitTrackingStep[]>([]);

    useEffect(() => {
        setStages();
    }, [selectedStage, type, isCoach]);

    const setStages = () => {
        const isOnsite = type == VisitationType.OnSite;
        let stages: VisitTrackingStep[] = [];
        if (isCoach && isOnsite) {
            if (GLGlobal.isActionValid(GSAdminAction.VisitSchool)) {
                stages = [
                    generateStage(VisitationCoachStage.ScheduleDate, SetUpIcon, SchoolLocale.VisitationScheduleDate),
                    generateStage(VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SchoolLocale.VisitationPrevisitationForm),
                    generateStage(VisitationCoachStage.VisitSchool, SchoolVisitIcon, SchoolLocale.VisitationVisitSchool),
                    generateStage(VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, SchoolLocale.VisitationTeacherReview),
                    generateStage(VisitationCoachStage.TeacherReview, SchoolFeedbackIcon, SchoolLocale.VisitationSchoolReview),
                ];
            } else {
                stages = [
                    generateStage(VisitationCoachStage.ScheduleDate, SetUpIcon, SchoolLocale.VisitationScheduleDate),
                    generateStage(VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SchoolLocale.VisitationPrevisitationForm),
                    generateStage(VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, SchoolLocale.VisitationTeacherReview),
                    generateStage(VisitationCoachStage.SchoolReview, SchoolFeedbackIcon, SchoolLocale.VisitationSchoolReview),
                ];
            }
        } else if (isCoach && !isOnsite) {
            stages = [
                generateStage(VisitationCoachStage.ScheduleDate, SetUpIcon, SchoolLocale.VisitationScheduleDate),
                generateStage(VisitationCoachStage.PrevisitationForm, SupportQuestionnaireIcon, SchoolLocale.VisitationPrevisitationForm),
                generateStage(VisitationCoachStage.VideoManager, VideoManagerIcon, SchoolLocale.VisitationVideoManager),
                generateStage(VisitationCoachStage.TeacherReview, TeacherFeedbackIcon, SchoolLocale.VisitationTeacherReview),
                generateStage(VisitationCoachStage.SchoolReview, SchoolFeedbackIcon, SchoolLocale.VisitationSchoolReview),
            ];
        } else if (!isCoach && isOnsite) {
            stages = [
                generateStage(VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SchoolLocale.VisitationPrevisitationForm),
                generateStage(VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, SchoolLocale.VisitationFeedback),
                generateStage(VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, SchoolLocale.VisitationCoachFeedback),
            ];
        } else if (!isCoach && !isOnsite) {
            stages = [
                generateStage(VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SchoolLocale.VisitationPrevisitationForm),
                generateStage(VisitationTeacherStage.VideoManager, VideoManagerIcon, SchoolLocale.VisitationVideoManager),
                generateStage(VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, SchoolLocale.VisitationFeedback),
                generateStage(VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, SchoolLocale.VisitationCoachFeedback),
            ];
        }
        setCurrentStages(stages);
    };

    const generateStage = (
        id: VisitationCoachStage | VisitationTeacherStage,
        IconComponent: (props: any) => JSX.Element,
        titleKey: string
    ): VisitTrackingStep => {
        let teacherOrCoachStage = teacherStage ? teacherStage : stage;

        //Below check has been introduced in order to set the color of all the buttons to green once the status is completed.
        //Since the last stage for teacher view is always "GiveCoachFeedback", we are setting it to "GiveCoachFeedback" whenever we recceive status as completed or greater than it.
        if (!isCoach && teacherOrCoachStage >= VisitationTeacherStage.Completed) {
            teacherOrCoachStage = VisitationTeacherStage.GiveCoachFeedback;
        }
        const canClick = teacherOrCoachStage >= id;
        const completed = teacherOrCoachStage === id;
        const active = selectedStage ? selectedStage === id : completed;
        return {
            icon: <IconComponent />,
            title: fmtMsg(titleKey),
            completed,
            active,
            onClick: canClick
                ? () => {
                      selectedStageCallback(id);
                  }
                : undefined,
        };
    };

    return <VisitTracking steps={currentStages} />;
};
