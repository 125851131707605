import * as React from 'react'
import { Link, RouteComponentProps } from "react-router-dom";
import { Dropdown, Icon, Menu } from 'antd-min';
import { alignPop, connect, GLGlobal, GLUtil, RouterContextComponent, withRouter } from 'gl-commonui';
import { getBreadcrumbs, mergeBreadcrumbs } from '@app/states/resource';
import { StateType } from '@app/states';
import {
    clearQuery,
    fmtMsg as formatMessage,
    GSSchoolAction,
    isGuid,
    isRegisterManualDisable,
    isStudentVerificationAllowed,
    isFutureClass,
    lazyInject,
    registerStudentVisibilityCondition,
    TYPES, isInternalUser, hasConnectLicense
} from '@app/util';
import { ClassHelper } from '@app/util/helper';
import { GSAdminLocale, MovePromoteStudentsLocale, RewardPointsLocale, SchoolLocale } from '@app/locales/localeid';
import { GSAdminAction, LicenseTypes, subscriptionTypeUsage } from '@app/util/enum';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolModel } from '@app/service/schools';
import classNames from "classnames";
import { isEmpty } from 'lodash';
import {
    BreadcrumbContainerProps,
    BreadcrumbDescExtraInfo,
    BreadcrumbMenuProps,
    BreadcrumbParams,
    ComboContentProps,
    LinkTitleProps
} from "@app/components/breadcrumb/models";
import { StaticContext } from 'react-router';
import { setPermissionGSConnect } from '@app/states/school/class';

@withRouter
@connect(
    ({
        oidc: { loginInfo },
        intl: { langLoaded },
        resource: { breadcrumbs, refreshBreadcrumbs },
        school: { current: school, userRoles },
        schoolClass: { futureAnnualOuter, model, hasGSConnectPermission },
        classManager: { moveStudentDisable }
    }: StateType) => ({
        isSignin: loginInfo && loginInfo.loggedin,
        langLoaded,
        breadcrumbs,
        school,
        schoolClass: model,
        userRoles,
        futureAnnualOuter,
        refreshBreadcrumbs,
        moveStudentDisable,
        hasGSConnectPermission
    }),
    {
        getBreadcrumbs,
        mergeBreadcrumbs,
        setPermissionGSConnect
    }
)
export class BreadcrumbContainer extends React.Component<RouteComponentProps<any> & BreadcrumbContainerProps> {
    builder: BreadcrumbItemsBuilder;
    params: BreadcrumbParams;
    componentWillMount() {
        this.builder = new BreadcrumbItemsBuilder();
        this.props.isSignin &&
            this.props.getBreadcrumbs(this.props.match.params);
    }
    componentWillUnmount() {
        this.clearBreadcrumbs();
    }
    componentWillReceiveProps({
        isSignin: nextIsSignin,
        langLoaded: nextLangLoaded,
        school: nextSchool,
        refreshBreadcrumbs: nextRefreshBreadcrumbs,
        match: { params: nextParams },
        moveStudentDisable: nextMoveStudentDisable
    }: any) {
        const {
            isSignin,
            langLoaded,
            school,
            getBreadcrumbs,
            refreshBreadcrumbs,
            match: { params },
            moveStudentDisable
        } = this.props;
        (nextIsSignin !== isSignin ||
            nextMoveStudentDisable !== moveStudentDisable ||
            nextLangLoaded !== langLoaded ||
            (nextSchool.id && nextSchool.id !== school.id) ||
            this.routerParamsChanged(nextParams, params) ||
            (nextRefreshBreadcrumbs &&
                nextRefreshBreadcrumbs !== refreshBreadcrumbs)) &&
            nextIsSignin &&
            getBreadcrumbs(nextParams);
    }
    routerParamsChanged(pre: object, cur: object) {
        const preKeys = Object.getOwnPropertyNames(pre);
        const curKeys = Object.getOwnPropertyNames(cur);
        return (
            preKeys.length !== curKeys.length ||
            preKeys.some(key => pre[key] !== cur[key])
        );
    }
    clearBreadcrumbs() {
        mergeBreadcrumbs({
            school: {},
            schoolClass: {},
            campus: {},
            visitation: {},
            user: {},
            history: {},
            region: {},
            trainer: {},
            regionTrainer: {},
            teacher: {},
            mergestudents: {},
            verifystudents: {},
            "reward-points": {},
            "bulk-add-points": {}
        });
    }
    clearStaleBreadcrumbs(breacrumbs, params) {
        if (breacrumbs == null) return breacrumbs;
        const newBreadcrumbs = { ...breacrumbs };
        const breadcrumbKeys = Object.getOwnPropertyNames(breacrumbs);
        const paramKeys = Object.getOwnPropertyNames(params);
        breadcrumbKeys
            .filter(
                name =>
                    !paramKeys.some(
                        key =>
                            key.startsWith(name) ||
                            "schoolClass" === name ||
                            "regionTrainer" === name ||
                            "mergestudents" === name ||
                            "verifystudents" === name ||
                            "reward-points" === name ||
                            "bulk-add-points" === name
                    )
            )
            .forEach(name => delete newBreadcrumbs[name]);
        return newBreadcrumbs;
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<any, StaticContext, any> & BreadcrumbContainerProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.breadcrumbs !== this.props.breadcrumbs && this.props.breadcrumbs && 'schoolClass' in this.props.breadcrumbs) {
            this.props.setPermissionGSConnect()
        }
    }

    render() {
        const {
            breadcrumbs,
            school,
            userRoles,
            location: { pathname },
            match: { params },
            ...rest
        } = this.props;
        const pathnameWithQuery = `${pathname}${new QueriesToAppend().appendQueries(
            location
        )}`;
        return (
            <div className="school-breadcrumb-container">
                <ul className="school-breadcrumb">
                    {this.builder.createBreadcrumbItems(
                        pathnameWithQuery,
                        this.builder.appendHome(
                            this.clearStaleBreadcrumbs(breadcrumbs, params)
                        ),
                        school,
                        userRoles,
                        rest
                    )}
                </ul>
            </div>
        );
    }
}

// if needs to add querystring to path then add that query string name and corresponding key
class QueriesToAppend {
    private map = {
        'isPromote': GLGlobal.intl.formatMessage({ id: SchoolLocale.BreadTextPromoteClass })
    }
    public appendQueries = (location: Location) => {
        const queryObj = GLUtil.queryParse(location.search);
        if (queryObj) {
            let toAppend = "/";
            Object.keys(queryObj).map((key) => {
                toAppend = `${toAppend}${this.map[key] ? `/${this.map[key]}` : ""}`;
            });
            if (toAppend.length > 1) {
                return toAppend;
            }
        }
        return "";
    }
}

class BreadcrumbItemsBuilder {
    contentMap = {
        'home': {
            path: () => new PlainTextPathBuilder(""),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        'regions': {
            path: () => new RegionsPathBuilder(),
            content: (title, path) => {
                const urlPath = path.getBreadcrumbPath();
                return <ComboContent key={urlPath} titleComponent={RegionsTitle} title={title} path={urlPath} action={GSAdminAction.ListRegion} />;
            }
        },
        'region': {
            path: (id, builder) => new RegionPathBuilder(id, builder),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} menuComponent={RegionMenu} title={title} path={path} action={GSAdminAction.ListRegion} />;
            }
        },
        'regiontrainers': {
            path: () => new LinkTextPathBuilder(PathConfig.RegionCoaches),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={RegionTrainersTitle} title={title} path={path} action={GSAdminAction.ListRegionCoach} />;
            }
        },
        'managestudents': {
            path: () => new LinkTextPathBuilder(PathConfig.ManageStudents),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={ManageStudentsTitle} title={title} path={path} action={GSAdminAction.ManageStudents} />;
            }
        },
        'regionTrainer': {
            path: (id, builder) => new RegionTrainerPathBuilder(id, builder),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        'mergestudents': {
            path: (id, builder, extraInfo) => {
                return extraInfo ? new MergeStudentsPathBuilder(id, builder, extraInfo) : new PlainTextPathBuilder("")
            },
            content: (title, path, params) => {
                return params && params.verifystudents && path && path.split('/').indexOf("mergestudents") !== -1 ? <ComboContent key={path + 'mergestudents'} titleComponent={MergeStudentsTitle} title={title} path={path} action={GSAdminAction.VerificationManageStudents} /> :
                    <PlainTextContent isCurrent key={path + 'mergestudents'} title={title} titleComponent={PlainTitle} />
            }
        },
        'verifystudents': {
            path: () => new PlainTextPathBuilder(""),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path + 'verifystudents'} title={title} titleComponent={PlainTitle} />
            }
        },
        'school': {
            path: (id, builder) => new SchoolPathBuilder(id, builder),
            content: (title, path, breadcrumbs, school?: SchoolModel, userRoles?: string[]) => {
                return <ComboContent key={path} titleComponent={SchoolTitle} menuComponent={SchoolMenu} title={title} path={path} action={GSSchoolAction.None} breadcrumbs={breadcrumbs} school={school} userRoles={userRoles} />;
            }
        },
        'campus': {
            path: (id, builder) => new CampusPathBuilder(id, builder),
            content: (title, path, breadcrumbs, school?: SchoolModel) => {
                return <ComboContent key={path} titleComponent={CampusTitle} menuComponent={CampusMenu} title={title} path={path} action={GSSchoolAction.Classes} breadcrumbs={breadcrumbs} school={school} />;
            }
        },
        'schoolClass': {
            path: (id, builder) => new ClassPathBuilder(id, builder),
            content: (title, path, breadcrumbs, school?: SchoolModel, userRoles?, data?) => {
                return <ComboContent key={path} titleComponent={SchoolClassTitle} menuComponent={ClassMenu} title={title} path={path} breadcrumbs={breadcrumbs} school={school} data={data} />;
            }
        },
        'student': {
            path: (id, builder) => new StudentPathBuilder(id, builder),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        'order': {
            path: (id, builder) => new StudentPathBuilder(id, builder),
            content: (title, path) => {
                return <PlainTextContent key={path} titleComponent={PlainTitle} title={title} />;
            }
        },
        'licenses': {
            path: () => new LinkTextPathBuilder(PathConfig.SchoolLicenses),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LicenseTitle} title={title} path={path} action={GSAdminAction.ListSchoolBillingHistory} />;
            }
        },
        'history': {
            path: (id, builder) => new HisotryPathBuilder(id, builder),
            content: (title, path) => {
                return <PlainTextContent key={path} titleComponent={PlainTitle} title={title} />;
            }
        },
        'trainers': {
            path: () => new LinkTextPathBuilder(PathConfig.SchoolTrainers),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={TrainersTitle} title={title} path={path} action={GSAdminAction.ListSchoolTrainer} />;
            }
        },
        'trainer': {
            path: (id, builder) => new TrainerPathBuilder(id, builder),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        'teachers': {
            path: () => new LinkTextPathBuilder(PathConfig.SchoolTeachers),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={TeachersTitle} title={title} path={path} action={GSSchoolAction.ListSchoolTeacher} />;
            }
        },
        'teacher': {
            path: (id, builder) => new TeacherPathBuilder(id, builder),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        'current': {
            path: () => new PlainTextPathBuilder(""),
            content: (pathname, params) => {
                return <PlainTextContent isCurrent key='current' title={CurrentTitleBuilder.build(pathname, params)} titleComponent={PlainTitle} />
            }
        },
        'reward-points': {
            path: () => new LinkTextPathBuilder(PathConfig.RegionRewardPoints),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={ManagePointsTitle} title={fmtMsg(SchoolLocale.BreadTextRewardPoints)()} path={path} action={GSAdminAction.ManagePointsView} />;
            }
        },
        'bulk-add-points': {
            path: () => new PlainTextPathBuilder(""),
            content: (title) => {
                return <PlainTextContent isCurrent key='bulk-add-points' title={title} titleComponent={PlainTitle} />;
            }
        },
    }
    breadcrumbsOrder = {
        'home': 0,
        'region': 1,
        'school': 2,
        'licenses': 3,
        'history': 4,
        'campus': 5,
        'schoolclass': 6,
        'visitation': 7,
        'trainers': 8,
        'trainer': 9,
        'teachers': 10,
        'teacher': 11,
        'regiontrainers': 12,
        'regionTrainer': 13,
        'managestudents': 14,
        'mergestudents': 15,
        'verifystudents': 16,
        'reward-points': 17,
        'bulk-add-points': 18,

    }
    appendHome(params: BreadcrumbParams) {
        return {
            home: {
                id: '/',
                name: fmtMsg(SchoolLocale.BreadTextHome)()
            },
            ...params
        };
    }
    getChain(params: BreadcrumbParams) {
        return Object.getOwnPropertyNames(params).filter(key => params[key] && params[key].id).sort((keyA, keyB) => {
            return this.breadcrumbsOrder[keyA] - this.breadcrumbsOrder[keyB]
        });
    }
    buildPath(params: BreadcrumbParams, self) {
        let chain = this.getChain(params);
        if (self !== 'home') chain.shift();
        return chain.slice(0, chain.indexOf(self) + 1)
            .map(key => {
                return { id: params[key].id, path: this.contentMap[key].path, build: null, extraInfo: params[key].extraInfo };
            })
            .reduceRight((pre, cur) => {
                cur.build = () => cur.path(cur.id, pre.build(), cur.extraInfo);
                return cur;
            }, { build: () => null })
            .build()
            .getBreadcrumbPath();
    }
    formatSpecialCrumb(snippet, pathname: string) {
        // if (snippet == 'region') {
        //     return [`${snippet}s`, snippet];
        // } else {
        //     return snippet;
        // }
        return snippet;
    }
    neededCrumb(pathname, newKeys) {
        const snippets = pathname.split('/');
        if (snippets.length > 2) {
            const manageStudentsCrumb = snippets[3]; // get manage students key
            if ((newKeys.indexOf('mergestudents') != -1 || newKeys.indexOf('verifystudents') != -1) && !isGuid(manageStudentsCrumb) && manageStudentsCrumb === 'managestudents' && this.contentMap[manageStudentsCrumb]) {
                newKeys.push(manageStudentsCrumb);
            }
            else if (pathname.indexOf('bulk-add-points') != -1) {
                newKeys.push("reward-points");
            }
            else {
                const crumb = snippets[snippets.length - 2];
                if ((newKeys.indexOf('region') == -1 || newKeys.indexOf('school') != -1 || newKeys.indexOf('regionTrainer') != -1) && !isGuid(crumb) && this.contentMap[crumb]) {
                    newKeys.push(crumb);
                }
            }
        }
        return newKeys;
    }
    appendSnippets(keys: string[], pathname: string) {
        const newKeys = keys.reduce((pre, cur) => pre.concat(this.formatSpecialCrumb(cur, pathname)), []);
        return this.neededCrumb(pathname, newKeys).sort((keyA, keyB) => {
            return this.breadcrumbsOrder[keyA] - this.breadcrumbsOrder[keyB]
        });
    }
    getSnippetTitle(type, params) {
        return params[type] ? params[type].name : PlainTitle.toUpperWordFirstChar(type);
    }
    getSnippetPath(type, params, item) {
        const pathBuilder = item.path();
        const pathBuilded = pathBuilder.build ? pathBuilder.build() : null;
        return params[type] ? this.buildPath(params, type) : pathBuilded ? pathBuilded : pathBuilder;
    }
    buildBreadcrumbItems(pathname: string, params: BreadcrumbParams, school?: SchoolModel, userRoles?: string[], data?) {
        return this.appendSnippets(this.getChain(params), pathname)
            .map(type => {
                const item = this.contentMap[type];
                return item.content(this.getSnippetTitle(type, params), this.getSnippetPath(type, params, item), params, school, userRoles, data)
            }).concat([
                this.contentMap.current.content(pathname, params)
            ]);
    }
    hasCurrent(pathname: string, params: BreadcrumbParams, arr: any[]) {
        let snippet = pathname.split('/').pop();
        if (isGuid(snippet)) {
            return arr.slice(0, arr.length - 1);
        }
        return arr;
    }
    createBreadcrumbItems(pathname: string, params?: BreadcrumbParams, school?: SchoolModel, userRoles?: string[], data?) {
        return this.hasCurrent(pathname, params, this.buildBreadcrumbItems(pathname, params, school, userRoles, data));
    }
}
//--- BreadcrumbContent ---
abstract class BreadcrumbContent<TProps> extends React.Component<PlainTextContentProps & TProps> {
}
interface PlainTextContentProps {
    titleComponent: any
}
class PlainTextContent extends BreadcrumbContent<PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, title, isCurrent } = this.props;
        return <li className={isCurrent ? 'current' : ''}>
            <BreadcrumbTitle title={title}></BreadcrumbTitle>
        </li>
    }
}

class ComboContent extends BreadcrumbContent<ComboContentProps & LinkTitleProps & PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, menuComponent: BreadcrumbMenu, breadcrumbs, school, userRoles, path, title, action, data } = this.props;
        return (
            <li>
                <BreadcrumbTitle title={title} path={path} action={action}>
                    {BreadcrumbMenu && <BreadcrumbMenu path={path} breadcrumbs={breadcrumbs} school={school} userRoles={userRoles} data={data}>
                        <span className="bread-icon icon-dropdown"><Icon type="down" /></span>
                    </BreadcrumbMenu>}
                </BreadcrumbTitle>
            </li>
        )
    }
}
//--- BreadcrumbTitle ---
abstract class BreadcrumbTitle<TProps> extends React.Component<TProps>{ }
interface PlainTitleProps {
    title: string
    isCurrent?: boolean
}
class PlainTitle extends BreadcrumbTitle<PlainTitleProps> {
    static toUpperWordFirstChar(title: string) {
        return title.replace(/\b\w/g, (c) => c.toUpperCase());
    }
    static getPlainTitle(snippet, type, { region, school, campus, schoolClass, order, student, visitation }: BreadcrumbParams) {
        let title = '';
        const getTitle = (key, isType, type) => {
            if (isType && type[snippet]) {
                title = type[snippet]();
            } else if (key && PlainTitle.map[key] && PlainTitle.map[key][snippet]) {
                title = PlainTitle.map[key][snippet]();
            }
        }
        getTitle(school, type === 'schools', PlainTitle.map.school);
        getTitle(campus, type === 'campuses', PlainTitle.map.campus);
        getTitle(schoolClass, type === 'classes', PlainTitle.map.schoolClass);
        getTitle(order, type === 'orders', PlainTitle.map.order);
        getTitle(student, type === 'students', PlainTitle.map.student);

        return title;
    }
    static map = {
        regions: {
            'regions': fmtMsg(GSAdminLocale.BreadcrumbRegions),
            'edit': fmtMsg(SchoolLocale.BreadTextEdit),
            'new': fmtMsg(SchoolLocale.BreadTextNewRegion),
            'orders': fmtMsg(SchoolLocale.BreadTextRegionOrders),
            'admins': fmtMsg(SchoolLocale.BreadTextAdmins),
            'regiontrainers': fmtMsg(SchoolLocale.BreadTextTrainers),
            'talk-time': fmtMsg(SchoolLocale.BreadTextTalkTimeAccess),
            'reward-points': fmtMsg(SchoolLocale.BreadTextRewardPoints),
            'bulk-add-points': fmtMsg(RewardPointsLocale.BulkAddPointTitle),
            'tag-manager': fmtMsg(SchoolLocale.BreadTextTagManager),
            'managestudents': fmtMsg(SchoolLocale.BreadTextManageStudents),
            'term-manager': fmtMsg(SchoolLocale.BreadTextTermManager),
        },
        school: {
            'new': fmtMsg(SchoolLocale.BreadTextNewSchool),
            'home': fmtMsg(SchoolLocale.BreadTextHome),
            'settings': fmtMsg(SchoolLocale.BreadTextSettings),
            'accountmanagers': fmtMsg(SchoolLocale.BreadTextAccountManagers),
            'admins': fmtMsg(SchoolLocale.BreadTextAdmins),
            'trainers': fmtMsg(SchoolLocale.BreadTextTrainers),
            'search': fmtMsg(SchoolLocale.BreadTextSearch),
            'help': fmtMsg(SchoolLocale.BreadTextHelp),
            'campuses': fmtMsg(SchoolLocale.BreadTextCampuses),
            'classes': fmtMsg(SchoolLocale.BreadTextClasses),
            'orders': fmtMsg(SchoolLocale.BreadTextOrders),
            'invitations': fmtMsg(SchoolLocale.BreadTextInvitations),
            'teachers': fmtMsg(SchoolLocale.BreadTextSchoolTeachers),
            'licenses': fmtMsg(GSAdminLocale.SchoolLicenseTitle),
            'changehistory': fmtMsg(SchoolLocale.BreadTextSchoolChangeHistory),
            'visitationhistory': fmtMsg(SchoolLocale.BreadTextSchoolVisitationHistory),
        },
        campus: {
            'campuses': fmtMsg(SchoolLocale.BreadTextCampuses),
            'classes': fmtMsg(SchoolLocale.BreadTextClasses),
            'new': fmtMsg(SchoolLocale.BreadTextNewCampuse),
            'admins': fmtMsg(SchoolLocale.BreadTextAdmins),
            "cart": fmtMsg(SchoolLocale.BreadTextCart),
            'orders': fmtMsg(SchoolLocale.BreadTextOrders),
            'edit': fmtMsg(SchoolLocale.BreadTextEdit),
            'bulkedit': fmtMsg(SchoolLocale.ClassBulkEdit),
            'unitplanmanager': fmtMsg(SchoolLocale.UnitPlanBulkTitle),
            'changehistory': fmtMsg(SchoolLocale.BreadTextSchoolChangeHistory),
            'movepromotestudents': fmtMsg(MovePromoteStudentsLocale.BreadcrumbTitle),
            'reward-points': fmtMsg(SchoolLocale.BreadTextRewardPoints),
        },
        schoolClass: {
            'edit': fmtMsg(SchoolLocale.BreadTextEdit),
            'classes': fmtMsg(SchoolLocale.BreadTextClasses),
            'new': fmtMsg(SchoolLocale.ClassNewEditTitle),
            'license': fmtMsg(SchoolLocale.BreadTextLicense),
            'history': fmtMsg(SchoolLocale.BreadTextLicenseHistory),
            'teachers': fmtMsg(SchoolLocale.BreadTextTeacher),
            'unitplan': fmtMsg(SchoolLocale.BreadTextUnitPlan),
            'groupmanager': fmtMsg(SchoolLocale.BreadTextGroupManager),
            'managestudentlogin': fmtMsg(SchoolLocale.BreadMenuManageStudentLogin),
            'materialrequest': fmtMsg(SchoolLocale.BreadTextMaterialRequest),
            'schoolclasses': fmtMsg(SchoolLocale.BreadTextClasses),
            'promotestudents': fmtMsg(SchoolLocale.BreadTextPromoteStudents),
            //'movestudents': fmtMsg(SchoolLocale.BreadTextMoveStudents),
            'invitations': fmtMsg(SchoolLocale.BreadTextInvitations),
            'register': fmtMsg(SchoolLocale.BreadTextStudentManualRegistration),
            'movepromotestudents': fmtMsg(MovePromoteStudentsLocale.BreadcrumbTitle)
        },
        visitation: {
            'new': fmtMsg(SchoolLocale.BreadMenuNewVisitation),
            'coach': fmtMsg(SchoolLocale.BreadMenuVisitation),
            'teacher': fmtMsg(SchoolLocale.BreadMenuVisitation),
        },
        notification: {
            'notifications': fmtMsg(SchoolLocale.BreadTextNotifications)
        },
        order: {
            'orders': fmtMsg(SchoolLocale.BreadTextOrders)
        },
        student: {
            'students': fmtMsg(SchoolLocale.BreadTextStudents)
        },
        myactivity: {
            'myactivity': fmtMsg(SchoolLocale.BreadTextMyActivity)
        }
    }
    render() {
        return <span>{this.props.title}</span>;
    }
}
class CurrentTitleBuilder {
    titles
    match = false
    static build(pathname: string, params: BreadcrumbParams) {
        const snippets = pathname.split('/');
        const snippet = snippets.pop();
        const type = snippets.pop();
        const title = {
            snippet,
            intl: snippet
        };

        new SchoolClassesCurrentTitleBuilder(snippet, type).notMatch(title)
            && new MyActivityCurrentTitleBuilder(snippet, type).notMatch(title)
            && new VisitationCurrentTitleBuilder(snippet, params as any, type).notMatch(title)
            && new NotificationsCurrentTitleBuilder(snippet, type).notMatch(title)
            && new StudentCurrentTitleBuilder(params as any, type).notMatch(title)
            && new SchoolClassCurrentTitleBuilder(params as any, type).notMatch(title)
            && new OrderCurrentTitleBuilder(params as any, type).notMatch(title)
            && new CampusCurrentTitleBuilder(params as any, type).notMatch(title)
            && new SchoolCurrentTitleBuilder(params as any, type).notMatch(title)
            && new RegionsCurrentTitleBuilder(snippet, params as any, type).notMatch(title);
        return title.intl;
    }
    notMatch(title) {
        if (this.match) {
            const fn = this.titles[title.snippet];
            fn && (title.intl = fn());
        }
        return !this.match;
    }
}
class NotificationsCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.notification
    constructor(snippet, type) { super(); this.match = !type && snippet === 'notifications'; }
}
class SchoolClassesCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.schoolClass
    constructor(snippet, type) { super(); this.match = !type && snippet === 'schoolclasses'; }
}
class SchoolCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.school
    constructor({ school }, type) { super(); this.match = !!school || type === 'schools'; }
    notMatch(title) {
        if (this.match) {
            const fn = this.titles[isGuid(title.snippet) ? 'home' : title.snippet];
            fn && (title.intl = fn());
        }
        return !this.match;
    }
}

class CampusCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.campus
    constructor({ school, campus }, type) { super(); this.match = !!school && !!campus || type === 'campuses'; }
}
class OrderCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.order
    constructor({ school, campus, order }, type) { super(); this.match = !!school && !!campus && !!order || type === 'orders'; }
}
class SchoolClassCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.schoolClass
    constructor({ school, campus, schoolClass }, type) { super(); this.match = !!school && !!campus && !!schoolClass || type === 'classes'; }
}

class VisitationCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.visitation
    constructor(snippet, { school, visitation }, type) { super(); this.match = (!!school && !!visitation || type === 'visitations') || snippet === 'coach' || snippet === 'teacher'; }
}

class StudentCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.student
    constructor({ school, campus, schoolClass, student }, type) { super(); this.match = !!school && !!campus && !!schoolClass && !!student || type === 'students'; }
}

class RegionsCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.regions;
    constructor(snippet, { region }, type) { super(); this.match = (!type && snippet === 'regions') || (type && region) || (!region && type === 'regions'); }
}

class MyActivityCurrentTitleBuilder extends CurrentTitleBuilder {
    titles = PlainTitle.map.myactivity
    constructor(snippet, type) { super(); this.match = !type && snippet === 'myactivity'; }
}

class LinkTitle extends BreadcrumbTitle<LinkTitleProps & PlainTitleProps> {
    getPath() {
        return this.props.path;
    }
    getTitle() {
        return this.props.title;
    }
    getAction() {
        return this.props.action;
    }
    onClick(e) { }
    getLink() {
        const title = this.getTitle();
        const path = this.getPath();
        const route = getCurrentUrlLocation();
        if (path === route
            || (this.props.action && this.props.action != GSSchoolAction.None
                && !GLGlobal.isActionValid(this.props.action))) {
            return <a className='link-plain' href="javascript:void(0)" title={title}><span>{title}</span></a>
        } else {
            return <Link to={this.getPath()} title={title} onClick={this.onClick.bind(this)}><span>{title}</span></Link>
        }
    }
    render() {
        return (
            <>
                {this.getLink()}
                {this.props.children}
            </>
        );
    }
}
class RegionsTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(GSAdminLocale.BreadcrumbRegions)();
    }
    getAction() {
        return GSAdminAction.ListRegion;
    }
}
class SchoolTitle extends LinkTitle {
}
class CampusTitle extends LinkTitle {
    getAction() {
        return GSSchoolAction.Classes;
    }
    onClick(e) {
        clearQuery('classes');
    }
}
class SchoolClassTitle extends LinkTitle {
    getAction() {
        return GSSchoolAction.None;
    }
}
class VisitationTitle extends LinkTitle {
    getAction() {
        return GSSchoolAction.None;
    }
}
class StudentTitle extends LinkTitle {
}
class LicenseTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(GSAdminLocale.SchoolLicenseTitle)();
    }
}
class TrainersTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(GSAdminLocale.BreadcrumbCoaches)();
    }
    getAction() {
        return GSAdminAction.ListSchoolTrainer;
    }
}
class TeachersTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(SchoolLocale.BreadTextTeachers)();
    }
    getAction() {
        return GSSchoolAction.ListSchoolTeacher;
    }
}
class RegionTrainersTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(GSAdminLocale.BreadcrumbCoaches)();
    }
    getAction() {
        return GSAdminAction.ListRegionCoach;
    }
}
class ManageStudentsTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(SchoolLocale.BreadTextManageStudents)();
    }
    getAction() {
        return GSAdminAction.ManageStudents;
    }
}
class MergeStudentsTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(SchoolLocale.BreadTextMergeStudents)();
    }
    getAction() {
        return GSAdminAction.VerificationManageStudents;
    }
}
class ManagePointsTitle extends LinkTitle {
    getTitle() {
        return fmtMsg(SchoolLocale.BreadTextRewardPoints)();
    }
    getAction() {
        return GSAdminAction.ManagePointsView;
    }
}

//--- BreadcrumbMenu ---
type menuItemsType = {
    key: string;
    title: () => string;
    action: string;
    afterDivider?: boolean;
    dividerDependentActions?: string[];
    breadcrumbsKey?: string;
    regionSettingKey?: string;
    customHide?: (school: SchoolModel, userRoles?: string[], data?: any) => boolean | Promise<boolean>;
    customDisable?: (school: SchoolModel, userRoles?: string[], data?: any) => boolean;
    hasPermission?: (school: SchoolModel, userRoles?: string[], data?: any) => boolean;
    /* Params for path. Works with hasQueryPath ( defined on same path without query ). */
    queryParams?: string[];
    /* denotes that there exists same path but with query params.  It should be defined on the menu item without query params. */
    hasQueryPath?: boolean;
    onClick?
    redirectTo?: "region" | "school"
}[]

interface IBreadcrumbMenuState {
    menuItems: null | React.ReactNode[]
}

// @connect(
//     ({
//         schoolClass: { hasGSConnectPermission },
//     }: StateType) => ({
//         hasGSConnectPermission
//     }),
// )
class BreadcrumbMenu extends RouterContextComponent<BreadcrumbMenuProps, IBreadcrumbMenuState> {
    constructor(props, context) {
        super(props, context)
        this.state = {
            menuItems: null
        }
    }

    componentDidMount() { this.getMenuItems() }

    componentDidUpdate(prevProps: Readonly<BreadcrumbMenuProps>, prevState: Readonly<IBreadcrumbMenuState>, snapshot?: any): void {
        if(prevProps.data !== this.props.data) {
            this.getMenuItems()
        }
    }

    static map = {
        region: [
            { key: 'edit', title: fmtMsg(SchoolLocale.BreadMenuEdit), action: GSAdminAction.ViewRegion },
            { key: 'admins', title: fmtMsg(SchoolLocale.AdminsAdmin), action: GSAdminAction.EditRegionAdminRegion },
            { key: 'regiontrainers', title: fmtMsg(GSAdminLocale.RegionCoaches), action: GSAdminAction.ListRegionCoach },
            { key: 'schools/new', title: fmtMsg(GSAdminLocale.RegionSchoolsAdd), action: GSAdminAction.CreateSchool },
            { key: 'orders', title: fmtMsg(SchoolLocale.BreadTextRegionOrders), action: GSSchoolAction.ListRegionOrders },
            { key: 'talk-time', title: fmtMsg(SchoolLocale.BreadTextTalkTimeAccess), action: GSSchoolAction.ListRegionSchoolTalkTimeSettings },
            { key: 'tag-manager', title: fmtMsg(GSAdminLocale.RegionTagManager), action: GSSchoolAction.ListRegionSchoolTagView },
            { key: 'managestudents', title: fmtMsg(GSAdminLocale.RegionManageStudentsAction), action: GSAdminAction.ManageStudents, customHide: (school, userRoles, data) => isStudentVerification() },
            { key: 'term-manager', title: fmtMsg(GSAdminLocale.RegionTermManager), action: GSAdminAction.ManageTerm },
            { key: 'reward-points', title: fmtMsg(RewardPointsLocale.EntryText), action: GSAdminAction.ManagePointsView }
        ],
        school: [
            { key: 'settings', title: fmtMsg(SchoolLocale.BreadMenuEdit), action: GSSchoolAction.EditSchool },
            { key: 'admins', title: fmtMsg(SchoolLocale.AdminsAdmin), action: GSSchoolAction.EditSchoolAdmins },
            { key: 'accountmanagers', title: fmtMsg(SchoolLocale.AdminsAccountManager), action: GSSchoolAction.EditSchoolAccountManager },
            { key: 'trainers', title: fmtMsg(GSAdminLocale.SchoolCardTitleTrainer), action: GSAdminAction.EditSchoolTrainer },
            { key: 'campuses/new', title: fmtMsg(SchoolLocale.CampusAdd), action: GSSchoolAction.AddCampus, breadcrumbsKey: 'school' },
            { key: 'teachers', title: fmtMsg(SchoolLocale.BreadMenuSchoolTeacher), action: GSSchoolAction.ListSchoolTeacher }, 
            { key: 'Cart', title: fmtMsg(SchoolLocale.BreadMenuMaterialCart), action: GSSchoolAction.CampusCart, breadcrumbsKey: 'school', regionSettingKey: 'allowMaterialRequest' },
            { key: 'invitations', title: fmtMsg(SchoolLocale.BreadTextInvitations), action: GSSchoolAction.None },
            { key: 'visitationhistory', title: fmtMsg(GSAdminLocale.SchoolVisitationTitle), action: GSAdminAction.ListSchoolVisitationHistory },
            { key: 'licenses', title: fmtMsg(GSAdminLocale.SchoolLicenseTitle), action: GSAdminAction.ListSchoolBillingHistory },
            { key: 'changehistory', title: fmtMsg(SchoolLocale.CIMSSchoolLogTitle), action: GSAdminAction.ListSchoolChangeHistory },
        ],
        campus: [
            { key: 'edit', title: fmtMsg(SchoolLocale.BreadMenuEdit), action: GSSchoolAction.EditCampus, breadcrumbsKey: 'campus' },
            { key: 'admins', title: fmtMsg(SchoolLocale.AdminsAdmin), action: GSSchoolAction.CampusAdmins, breadcrumbsKey: 'campus' },
            { key: 'classes/new', title: fmtMsg(SchoolLocale.ClassAdd), action: GSSchoolAction.AddClass, breadcrumbsKey: 'campus', hasQueryPath: true, customHide: (school, userRoles, data) => !showAddClass(school, userRoles, data) },
            { key: 'classes/new?isPromote=true', title: fmtMsg(SchoolLocale.ClassPromote), action: GSSchoolAction.ClassPromotion, queryParams: ['isPromote'], breadcrumbsKey: 'campus', customHide: (school, userRoles, data) => !showPromoteClass(school, userRoles, data) },
            { key: 'bulkedit', title: fmtMsg(SchoolLocale.ClassBulkEdit), action: GSSchoolAction.BulkEditClass, breadcrumbsKey: 'campus' },
            { key: 'unitplanmanager', title: fmtMsg(SchoolLocale.UnitPlanBulkTitle), action: GSSchoolAction.EditUnitPlan, breadcrumbsKey: 'campus' },
            { key: 'cart', title: fmtMsg(SchoolLocale.BreadMenuMaterialCart), action: GSSchoolAction.CampusCart, breadcrumbsKey: 'campus', regionSettingKey: 'allowMaterialRequest' },
            { key: 'orders', title: fmtMsg(SchoolLocale.BreadMenuMaterialOrder), action: GSSchoolAction.MaterialOrder, breadcrumbsKey: 'campus', regionSettingKey: 'allowMaterialRequest' },
            { key: 'changehistory', title: fmtMsg(SchoolLocale.CIMSSchoolLogTitle), action: GSAdminAction.ListSchoolChangeHistory, breadcrumbsKey: 'campus' },
            { key: 'movepromotestudents', title: fmtMsg(MovePromoteStudentsLocale.CampusFeatureTitle), action: GSSchoolAction.MovePromoteStudents, breadcrumbsKey: 'campus', customDisable: (school, userRoles, data) => isMovePromoteStudentsDisabled(school) },
            { key: 'reward-points', title: fmtMsg(RewardPointsLocale.EntryText), action: GSAdminAction.ManagePointsView, redirectTo: "region"}
        ],
        schoolClass: [
            { key: 'edit', title: fmtMsg(SchoolLocale.BreadMenuEdit), action: GSSchoolAction.EditClass, breadcrumbsKey: 'schoolClass' },
            { key: 'teachers', title: fmtMsg(SchoolLocale.BreadMenuTeacher), action: GSSchoolAction.EditTeacher, breadcrumbsKey: 'schoolClass' },
            { key: 'materialrequest', title: fmtMsg(SchoolLocale.BreadMenuMaterialRequest), action: GSSchoolAction.Cart, breadcrumbsKey: 'schoolClass', regionSettingKey: 'allowMaterialRequest', hasPermission: (school, userRoles, data) => !isFutureAnnualOuter(data) },
            { key: 'invitations', title: fmtMsg(SchoolLocale.StudentRegistrationInvitationLinkText), action: GSSchoolAction.InviteStudents, breadcrumbsKey: 'schoolClass' },
            { key: 'register', title: fmtMsg(SchoolLocale.StudentRegistrationRegisterLinkText), action: GSSchoolAction.InviteStudents, breadcrumbsKey: 'schoolClass', customHide: (school, userRoles, data) => isRegisterStudentNotAllowed(data), customDisable: (school, userRoles, data) => isRegisterStudentDisabled(data) },
            { key: 'license', title: fmtMsg(SchoolLocale.BreadMenuLicense), action: GSSchoolAction.EditLicenseEntry, breadcrumbsKey: 'schoolClass' },
            { key: 'history', title: fmtMsg(SchoolLocale.BreadMenuLicenseHistory), action: GSSchoolAction.EditLicenseEntry, breadcrumbsKey: 'schoolClass', regionSettingKey: 'allowSchoolEditLicense', hasPermission: (school, userRoles) => hasLicenseHistoryPermission(school) },
            { key: 'unitplan', title: fmtMsg(SchoolLocale.BreadMenuUnitPlan), action: GSSchoolAction.EditUnitPlan, breadcrumbsKey: 'schoolClass' },
            { key: 'groupmanager', title: fmtMsg(SchoolLocale.BreadMenuGroupManager), action: GSSchoolAction.EditClass, breadcrumbsKey: 'schoolClass', customHide: (_school, _userRoles, data) => isGroupManagerVisible(data) },
            // { key: 'movestudents', title: fmtMsg(SchoolLocale.BreadTextMoveStudents), action: GSSchoolAction.ClassMoveStudent, breadcrumbsKey: 'schoolClass', customHide: (_school, _userRoles, data) => isMoveStudentsVisible(data) },
            { key: 'managestudentlogin', title: fmtMsg(SchoolLocale.BreadMenuManageStudentLogin), action: null, breadcrumbsKey: 'schoolClass', customHide: (school, userRoles, data) => !showManageLogin(school) },
            { key: 'movepromotestudents', title: fmtMsg(SchoolLocale.BreadTextMoveStudents), action: GSSchoolAction.MovePromoteStudents, breadcrumbsKey: 'schoolClass', customHide: (_school, _userRoles, data) => isMovePromoteStudentsVisible(data), customDisable: (school, userRoles, data) => isMovePromoteStudentsDisabled(school) },
        ],
    };

    menuItems: menuItemsType
    async selectMenu(params) {
        const item = this.menuItems.find(item => item.key === params.key);
        if (item.afterDivider && item.onClick) {
            item.onClick(params);
            return;
        }
        let path = `${this.props.path}/${params.key}`;
        if(item.redirectTo === 'region') {
            const { regionId } = GLUtil.pathParse(PathConfig.Region);
            if(regionId) {
                path =  `${GLUtil.pathStringify(PathConfig.Region, {
                    regionId
                })}/${params.key}`
            }
        }
        const route = getCurrentUrlLocation();
        if (route.endsWith(path)) {
            return;
        }
        this.historyPush(path);
    }

    historyPush = (path: string) => {
        (this.context.router || this.context).history.push(path);
    }

    isMenuItemDisabled(menuItem) {
        const { school, userRoles, data } = this.props;
        return (
            this.menuitemIsDisabledCandidate(menuItem)
            && this.props.breadcrumbs
            && menuItem.breadcrumbsKey
            && this.props.breadcrumbs[menuItem.breadcrumbsKey]
            && this.props.breadcrumbs[menuItem.breadcrumbsKey].disabled)
            || (school && menuItem.regionSettingKey
                && (!school[menuItem.regionSettingKey] || (menuItem.hasPermission && !menuItem.hasPermission(school, userRoles, data))));
    }
    menuitemIsDisabledCandidate(menuItem) {
        return menuItem.key != 'edit' && menuItem.key != 'orders' && menuItem.key != 'teachers' && menuItem.key != 'history' && menuItem.key != 'unitplan';
    }
    async getMenuItems() {
        const menuItems = [];
        for (const item of this.menuItems) {
            const needDivider = item.afterDivider && (!item.dividerDependentActions
                || (item.dividerDependentActions
                    && item.dividerDependentActions.filter((action) => GLGlobal.isActionValid(action)).length > 0));
            if (needDivider) {
                menuItems.push(<Menu.Divider key={`divider${item.key}`} />)
            }
            if (!this.isMenuItemDisabled(item) && (!item.action || item.action == GSSchoolAction.None || GLGlobal.isActionValid(item.action))) {
                const path = `${this.props.path}/${item.key}`;
                const route = getCurrentUrlLocation();
                const queries = GLUtil.queryParse();
                let isActive = false;
                if (queries && Object.keys(queries).length && item.queryParams && item.queryParams.length && path.split('?')[0] == route &&
                    Object.keys(queries).length == item.queryParams.length) {
                    isActive = true;
                } else if (item.hasQueryPath) {
                    isActive = path == route && !Object.keys(queries).length;
                } else {
                    isActive = path == route;
                }
                const menuItemClassName = {
                    'menu-item-active': isActive,
                }

                const { school, userRoles, data } = this.props;
                let isCustomHide = item.customHide && item.customHide(school, userRoles, data)

                if (typeof isCustomHide === 'object' && Object.getPrototypeOf(isCustomHide).then) {
                    isCustomHide = await isCustomHide;
                }

                if (!isCustomHide) {
                    const isCustomDisable = item.customDisable && item.customDisable(school, userRoles, data);
                    menuItems.push(<Menu.Item key={item.key} disabled={isCustomDisable} className={classNames(menuItemClassName)} >{item.title()}</Menu.Item>)
                }
            }
        };
        this.setState({ menuItems });// menuItems;
    }
    renderMenu(menuItems) {
        return <Menu onClick={this.selectMenu.bind(this)}>
            {menuItems}
        </Menu>;
    }
    render() {
        const { menuItems } = this.state;
        if (!menuItems || menuItems.length == 0) { return null; }
        return <Dropdown trigger={['click']} overlay={this.renderMenu(menuItems)} {...alignPop({ querySelector: '.school-breadcrumb-container' })} >
            {this.props.children}
        </Dropdown>;
    }
}
class RegionMenu extends BreadcrumbMenu {
    menuItems: menuItemsType = BreadcrumbMenu.map.region
}
class SchoolMenu extends BreadcrumbMenu {
    menuItems: menuItemsType = BreadcrumbMenu.map.school
}
class CampusMenu extends BreadcrumbMenu {
    menuItems: menuItemsType = BreadcrumbMenu.map.campus
}
class ClassMenu extends BreadcrumbMenu {
    menuItems: menuItemsType = BreadcrumbMenu.map.schoolClass
}

//--- BreadcrumbPathBuilder ---
abstract class BreadcrumbPathBuilder {
    constructor(protected id?: string, protected pathBuilder?: BreadcrumbPathBuilder) { }
    protected getPathWithId(def) {
        return `${this.id || ''}${this.pathBuilder ? this.pathBuilder.getBreadcrumbPath() : def}`;
    }
    abstract getBreadcrumbPath()
}
class RegionsPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/regions`;
    }
}
class RegionPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/regions/${this.getPathWithId('')}`;
    }
}
class SchoolPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/schools/${this.getPathWithId('')}`;
    }
}
class CampusPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/campuses/${this.getPathWithId('')}`;
    }
}
class ClassPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/classes/${this.getPathWithId('')}`;
    }
}

class VisitationPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/visitations/${this.getPathWithId('')}`;
    }
}
class StudentPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/students/${this.getPathWithId('')}`;
    }
}
class OrderPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/orders/${this.getPathWithId('')}`;
    }
}
class HisotryPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/licenses/${this.getPathWithId('')}`;
    }
}
class TrainerPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/trainers/${this.getPathWithId('')}`;
    }
}
class TeacherPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/teachers/${this.getPathWithId('')}`;
    }
}
class RegionTrainerPathBuilder extends BreadcrumbPathBuilder {
    getBreadcrumbPath() {
        return `/regiontrainers/${this.getPathWithId('')}`;
    }
}

class MergeStudentsPathBuilder {
    constructor(protected id?: string, protected pathBuilder?: BreadcrumbPathBuilder, protected extraInfo?: BreadcrumbDescExtraInfo) { }

    getBreadcrumbPath() {
        if (this.extraInfo) {
            return `/managestudents/mergestudents/${this.extraInfo.classId}/${this.id}`;
        }
        else {
            return null;
        }
    }
}
abstract class BreadcrumbPathBuilderEx {
    constructor(protected pathConfig?: string, protected params: any = null) { }
    getPath(path, params) {
        const pathParams = GLUtil.pathParse({ path });
        return Object.keys(pathParams).length == 0 ? null : GLUtil.pathStringify(path, params ? params : pathParams);
    }
    abstract build()
}
class LinkTextPathBuilder extends BreadcrumbPathBuilderEx {
    build() {
        return this.getPath(this.pathConfig, this.params);
    }
}
class PlainTextPathBuilder extends BreadcrumbPathBuilder {
    constructor(protected title: string) { super() }
    getBreadcrumbPath() {
        return `/${this.title}`;
    }
}
function fmtMsg(id) {
    return () => formatMessage({ id });
}
function getCurrentUrlLocation() {
    if (window.location.hash == '') {
        if (window.location.search.toString().length > 0) {
            return (window.location.pathname + window.location.search.toString());
        }
        else {
            return window.location.pathname;
        }
    }
    else {
        return window.location.hash.substring(1);
    }
}
function hasApprovePermission(school: SchoolModel, userRoles: string[]) {
    return school && school.subscriptionTypeUsage == subscriptionTypeUsage.Standard && ClassHelper.canEditLicense(userRoles, school.allowSchoolEditLicense);
}
function hasLicenseHistoryPermission(school: SchoolModel) {
    return true;
}
function isFutureAnnualOuter(data) {
    return (data || {}).futureAnnualOuter
}
async function isStudentVerification() {
    const regionId = GLUtil.pathParse(PathConfig.Region).regionId;
    if (regionId) {
        const isVerificationAllowed = await isStudentVerificationAllowed(regionId);
        return !isVerificationAllowed;
    }

    return true;

}
function isRegisterStudentNotAllowed(data) {
    if (!isEmpty(data.schoolClass)) {
        return !(!data.schoolClass.disabled && GLGlobal.isActionValid(GSSchoolAction.InviteStudents) && registerStudentVisibilityCondition(data.schoolClass.studentRegistrationTypeId));
    }
    else {
        return true;
    }
}

function isRegisterStudentDisabled(data) {
    if (!isEmpty(data.schoolClass)) {
        return isRegisterManualDisable(data.schoolClass.studentRegistrationTypeId)
    }
    else {
        return false;
    }
}

function isGroupManagerVisible(data) {
    if (!isEmpty(data.schoolClass)) {
        return  !(hasConnectLicense(data.schoolClass.licenseType) && !data.schoolClass.disabled && GLGlobal.isActionValid(GSSchoolAction.EditClass));
    } else {
        return true;
    }
}

async function isMovePromoteStudentsVisible(data) {
    const classId = GLUtil.pathParse(PathConfig.Students).classId;
    if (classId) {
        const isfuture = await isFutureClass(classId);
        if (isfuture) {
            return true;
        }
    }
    return data.moveStudentDisable;
}

function isMovePromoteStudentsDisabled(school) {
    if (school.annualPrepComplete) {
        return true;
    }

    return false;
}

function isMoveStudentsVisible(data) {
    return data.moveStudentDisable
}

function showPromoteClass(school, userRoles, data) {
    let isAllowPromoteClassEnabled = false;
    if (school) {
        if (school.allowPromoteClass) {
            isAllowPromoteClassEnabled = true;
        }
        else if (!school.allowPromoteClass) {
            isAllowPromoteClassEnabled = false;
        }

        // The condition is same as used in campus feature menu (i.e to show or hide Promote Class option in campus breadcrumb menu).
        if (isAllowPromoteClassEnabled && GLGlobal.isActionValid(GSSchoolAction.AddClass) && !(school.annualPrepComplete && !GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete))) {
            return true;
        } else {
            return false;
        }
    }

    return true;
}

function showAddClass(school, userRoles, data) {
    if (school) {
        // The condition is same as used in campus feature menu (i.e to show or hide Promote Class option in campus breadcrumb menu).
        if (GLGlobal.isActionValid(GSSchoolAction.AddClass) && !(school.annualPrepComplete && !GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete))) {
            return true;
        } else {
            return false;
        }
    }

    return true;
}

function showManageLogin(school) {
    if (school) {
        return school.regionEnableCodeLogin
    }

    return false;
}
