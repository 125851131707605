import { GLServiceBase, ServerExceptionCode, GLGlobal, customErrMsgs, HttpMethod, getServiceMethodKey, customError } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IInvitationService, InvitationRequest, InvitationsRequest, InvitationResponse, InvitationCodeValid, CodeConsumerId, UserModel } from './index';
import { SchoolLocale } from '../../../locales/localeid';
import { isUndefined } from 'util';

@injectable()
export class InvitationService extends GLServiceBase<InvitationRequest, any> implements IInvitationService {
    serviceRoute = { prefix: 'admin/v1/invitationcodes', suffix: '{id}' }
    // errMsgs = customErrMsgs({
    //     UnsuportedOperationException: SchoolLocale.InvitationSame
    // });
    getInfoByCodeUrl = '{invitationCode}';
    attachChildToClassUrl = '{codeId}/consumers/{consumerId}';
    addParentUrl = '{codeId}/consumers';
    sendEmailUrl = '{id}/email/send';
    downloadUrl = 'students';
    downloadManualInvitationUrl = 'printinvitation';
    sendInvitationEmailUrl = 'emailinvitation';
    previewParentEmailUrl = 'manualemailpreview';
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.getInfoByCodeUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.getInfoByCodeUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.InvitationCodeNotExistException, `${this.serviceRoute.prefix}/${this.getInfoByCodeUrl}`)] = SchoolLocale.InvitationHasBeenRemoved;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException)] = SchoolLocale.InvitationValidErrMsgTemplateNone;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException)] = SchoolLocale.InvitationValidErrMsgClassTeacherInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ResourceNotAvailableException)] = SchoolLocale.InvitationValidErrMsgTemplateType;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException)] = SchoolLocale.InvitationValidErrMsgGenerateCode;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.sendEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.sendEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.previewParentEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.previewParentEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.previewParentEmailUrl}`)] = SchoolLocale.StudetnInviteFull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.sendInvitationEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.sendInvitationEmailUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.sendInvitationEmailUrl}`)] = SchoolLocale.StudetnInviteFull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.addParentUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.downloadUrl}`)] = SchoolLocale.StudetnInviteFull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.downloadUrl}`)] = SchoolLocale.InvitationValidErrMsgClassInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.downloadUrl}`)] = SchoolLocale.InvitationValidErrMsgTemplateNone;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException, `${this.serviceRoute.prefix}/${this.downloadUrl}`)] = SchoolLocale.StudetnInviteUnkownError;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.downloadManualInvitationUrl}`)] = SchoolLocale.StudetnInviteFull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.downloadManualInvitationUrl}`)] = SchoolLocale.InvitationValidErrMsgClassInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.downloadManualInvitationUrl}`)] = SchoolLocale.InvitationValidErrMsgTemplateNone;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException, `${this.serviceRoute.prefix}/${this.downloadManualInvitationUrl}`)] = SchoolLocale.StudetnInviteUnkownError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.attachChildToClassUrl}`)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.CodeHasBeenUsedException)] = SchoolLocale.InvitationCodeInvalidConsumed;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = SchoolLocale.InvitationHasBeenRemoved;
        return result;
    })();

    validate(invitation: InvitationRequest) {
        return this.request.post<InvitationCodeValid, InvitationRequest>(this.url('validation'), invitation);
    }
    getInfoByCode(params: any) {
        return this.request.get<any, InvitationRequest>(this.url(this.getInfoByCodeUrl), params)
            .catch(customError(this, HttpMethod.Get, `${this.serviceRoute.prefix}/${this.getInfoByCodeUrl}`));
    }
    getInvitations(params: InvitationsRequest) {
        return this.getItemsBy<InvitationResponse, InvitationsRequest>(params);
    }
    attachChildToClass(id: CodeConsumerId) {
        return this.request.put<InvitationCodeValid>(this.url(this.attachChildToClassUrl), null, id)
            .catch(customError(this, HttpMethod.Put, `${this.serviceRoute.prefix}/${this.attachChildToClassUrl}`));
    }
    addParent(user: UserModel, id: CodeConsumerId) {
        return this.request.post(this.url(this.addParentUrl), user, id)
            .catch(customError(this, HttpMethod.Put, `${this.serviceRoute.prefix}/${this.addParentUrl}`));
    }
    sendEmail(id) {
        return this.create(this.sendEmailUrl, null, { id });
    }
    downloadStudentInvitations(data) {
        return this.download({ suffix: this.downloadUrl, data, method: 'post' });
    }
    consumeCode(params: any) {
        return this.request.put(this.url('{codeId}/consumers/{consumerId}'), params);
    }
    getInvitationCodeDetails(params: any, route: any) {
        return this.request.get(this.url('resources/{resourceId}'), params, route);
    }
    downloadManualInvitations(data) {
        return this.download({ suffix: this.downloadManualInvitationUrl, data, method: 'post' });
    }
    previewParentInvitations(data) {
        return this.createWithoutLoader(this.previewParentEmailUrl, data);
    }
    sendInvitations(data, withLoader) {
        if (withLoader === undefined || withLoader) {
            return this.create(this.sendInvitationEmailUrl, data);
        } else {
            return this.createWithoutLoader(this.sendInvitationEmailUrl, data);
        }
    }
}