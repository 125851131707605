const numberRegex = /\d+/;
const lowerCaseRegex = /[a-z]+/;
const upperCaseRegex = /[A-Z]+/;
const symbolRegex = /[~!@#$%^&*_+=|?<>\/\\]+/;

const regexes = [numberRegex, lowerCaseRegex, upperCaseRegex, symbolRegex];

const PASSWORD_MIN_LENGTH = 8;

const MIN_SATISFY_REGEX_COUNT = 2;

export const isPassordValid = (password: string) => {
    password = password.trim();

    if (password.length < PASSWORD_MIN_LENGTH) {
        return false;
    }

    const count = regexes.map(regex => regex.test(password)).filter(result => result).length;

    return count >= MIN_SATISFY_REGEX_COUNT;
}
