export * from "gl-commonui/lib/antd-icons";
// export what you need
export { default as PlusCircleOutline } from "@ant-design/icons/lib/outline/PlusCircleOutline";
export { default as PushpinOutline } from "@ant-design/icons/lib/outline/PushpinOutline";
export { default as DownloadOutline } from "@ant-design/icons/lib/outline/DownloadOutline";
export { default as EditOutline } from "@ant-design/icons/lib/outline/EditOutline";
export { default as ShoppingCartOutline } from "@ant-design/icons/lib/outline/ShoppingCartOutline";
export { default as MailOutline } from "@ant-design/icons/lib/outline/MailOutline";
export { default as InfoCircleOutline } from "@ant-design/icons/lib/outline/InfoCircleOutline";
export { default as InfoCircleFill } from "@ant-design/icons/lib/fill/InfoCircleFill";
export { default as AuditOutline } from "@ant-design/icons/lib/outline/AuditOutline";
export { default as FlagFill } from "@ant-design/icons/lib/fill/FlagFill";
export { default as SettingOutline } from "@ant-design/icons/lib/outline/SettingOutline";
export { default as SolutionOutline } from "@ant-design/icons/lib/outline/SolutionOutline";
export { default as FileDoneOutline } from "@ant-design/icons/lib/outline/FileDoneOutline";
export { default as BorderOutline } from "@ant-design/icons/lib/outline/BorderOutline";
export { default as CheckSquareOutline } from "@ant-design/icons/lib/outline/CheckSquareOutline";
export { default as LinkOutline } from "@ant-design/icons/lib/outline/LinkOutline";
export { default as ClockCircleOutline } from "@ant-design/icons/lib/outline/ClockCircleOutline";
export { default as LineChartOutline } from "@ant-design/icons/lib/outline/LineChartOutline";
export { default as TeamOutline } from "@ant-design/icons/lib/outline/TeamOutline";
export { default as SwitcherOutline } from "@ant-design/icons/lib/outline/SwitcherOutline";
export { default as SelectOutline } from "@ant-design/icons/lib/outline/SelectOutline";
export { default as ArrowLeftOutline } from "@ant-design/icons/lib/outline/ArrowLeftOutline";
export { default as ShareAltOutline } from "@ant-design/icons/lib/outline/ShareAltOutline";
export { default as EllipsisOutline } from "@ant-design/icons/lib/outline/EllipsisOutline";
export { default as ContainerOutline } from "@ant-design/icons/lib/outline/ContainerOutline";
export { default as ContactsOutline } from "@ant-design/icons/lib/outline/ContactsOutline";
export { default as ReloadOutline } from "@ant-design/icons/lib/outline/ReloadOutline";
export { default as QuestionCircleOutline } from "@ant-design/icons/lib/outline/QuestionCircleOutline";
export { default as UnorderedListOutline } from "@ant-design/icons/lib/outline/UnorderedListOutline";
export { default as NotificationOutline } from "@ant-design/icons/lib/outline/NotificationOutline";
export { default as LikeOutline } from "@ant-design/icons/lib/outline/LikeOutline";
export { default as FileTextOutline } from "@ant-design/icons/lib/outline/FileTextOutline";
export { default as ArrowDownOutline } from "@ant-design/icons/lib/outline/ArrowDownOutline";
export { default as ArrowUpOutline } from "@ant-design/icons/lib/outline/ArrowUpOutline";
export { default as CheckCircleTwoTone } from "@ant-design/icons/lib/twotone/CheckCircleTwoTone"
export { default as CopyOutline } from "@ant-design/icons/lib/outline/CopyOutline"
export { default as EyeInvisibleOutline } from "@ant-design/icons/lib/outline/EyeInvisibleOutline";
export { default as WarningOutline } from "@ant-design/icons/lib/outline/WarningOutline";
export { default as ArrowRightOutline } from '@ant-design/icons/lib/outline/ArrowRightOutline';
export { default as MenuOutline } from '@ant-design/icons/lib/outline/MenuOutline';
export { default as UpCircleOutline } from '@ant-design/icons/lib/outline/UpCircleOutline';
export { default as DownCircleOutline } from '@ant-design/icons/lib/outline/DownCircleOutline';
export { default as FormOutline } from '@ant-design/icons/lib/outline/FormOutline';
export { default as SlidersOutline } from '@ant-design/icons/lib/outline/SlidersOutline';
export { default as BellOutline } from '@ant-design/icons/lib/outline/BellOutline';
export { default as EnvironmentOutline } from '@ant-design/icons/lib/outline/EnvironmentOutline';
export { default as VideoCameraFill } from '@ant-design/icons/lib/fill/VideoCameraFill';
export { default as UploadOutline } from '@ant-design/icons/lib/outline/UploadOutline';
export { default as InboxOutline } from '@ant-design/icons/lib/outline/InboxOutline';
export { default as CaretDownOutlined } from '@ant-design/icons/lib/outline/CaretDownOutline';
export { default as CaretUpOutlined } from '@ant-design/icons/lib/outline/CaretUpOutline';
export { default as CaretDownFill } from '@ant-design/icons/lib/fill/CaretDownFill';
export { default as CaretUpFill } from '@ant-design/icons/lib/fill/CaretUpFill';
export { default as VideoCameraOutline } from '@ant-design/icons/lib/outline/VideoCameraOutline';
export { default as ScheduleOutline } from '@ant-design/icons/lib/outline/ScheduleOutline';
export { default as UserOutline } from '@ant-design/icons/lib/outline/UserOutline';
export { default as PlusCircleFill } from "@ant-design/icons/lib/fill/PlusCircleFill";
export { default as PrinterOutline } from "@ant-design/icons/lib/outline/PrinterOutline";
export { default as WechatOutline } from "@ant-design/icons/lib/outline/WechatOutline";
export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";
export { default as FileSearchOutline } from "@ant-design/icons/lib/outline/FileSearchOutline";
export { default as PlayCircleOutline } from "@ant-design/icons/lib/outline/PlayCircleOutline";
export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";
export { default as MinusCircleOutline } from "@ant-design/icons/lib/outline/MinusCircleOutline";
export { default as StarFill } from "@ant-design/icons/lib/fill/StarFill";
export { default as MessageOutline } from "@ant-design/icons/lib/outline/MessageOutline";
export { default as ShrinkOutline  } from "@ant-design/icons/lib/outline/ShrinkOutline";
export { default as TagsOutline  } from "@ant-design/icons/lib/outline/TagsOutline";
export { default as TagOutline  } from "@ant-design/icons/lib/outline/TagOutline";
 