import * as React from 'react'
import { RouteComponentProps } from "react-router-dom";
import { Link } from 'react-router-dom';
import { withRouter, RouterContextComponent, connect, GLGlobal, GLUtil } from 'gl-commonui';
import { getBreadcrumbs, setBreadcrumb } from '@app/states/admin/resource';
import { StateType } from '@app/states';
import { isGuid, fmtMsg } from '@app/util/func';
import { AdminPathConfig, PathConfig, SurveyPathConfig } from '@app/config/pathconfig';
import { GSAdminAction, GSSchoolAction, SurveyAction } from '@app/util/enum';
import { GSAdminLocale, SchoolLocale, SurveyLocale } from '@app/locales/localeid';

interface BreadcrumbDesc {
    id?: string
    name?: string
}
interface BreadcrumbParams {
    home?: BreadcrumbDesc
    school?: BreadcrumbDesc
    user?: BreadcrumbDesc
    template?: BreadcrumbDesc
    history?: BreadcrumbDesc
}

interface BreadcrumbContainerProps {
    isSignin?: boolean
    breadcrumbs: BreadcrumbParams
    getBreadcrumbs: (d) => void
}

@withRouter
@connect(({ oidc: { loginInfo }, adminResource: { breadcrumbs } }: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin,
    breadcrumbs,
}), {
        getBreadcrumbs
    })
export class BreadcrumbContainer extends React.Component<RouteComponentProps<any> & BreadcrumbContainerProps> {
    builder: BreadcrumbItemsBuilder
    params: BreadcrumbParams
    componentWillMount() {
        this.builder = new BreadcrumbItemsBuilder();
        this.props.isSignin && this.props.getBreadcrumbs(this.props.match.params);
    }
    componentWillUnmount() {
        this.clearBreadcrumbs();
    }
    clearBreadcrumbs() {
        setBreadcrumb({
            school: {},
            user: {},
            template: {},
            history: {},
            region: {},
            globalproduct: {},
            localproduct: {},
            notification: {},
        });
    }
    componentWillReceiveProps({ isSignin: nextIsSignin, match: { params: nextParams } }: any) {
        const { isSignin, getBreadcrumbs, match: { params } } = this.props;
        (nextIsSignin !== isSignin || this.routerParamsChanged(nextParams, params)) && nextIsSignin && getBreadcrumbs(nextParams);
    }
    routerParamsChanged(pre: object, cur: object) {
        const preKeys = Object.getOwnPropertyNames(pre);
        const curKeys = Object.getOwnPropertyNames(cur);
        return preKeys.length !== curKeys.length || preKeys.some(key => pre[key] !== cur[key]);
    }
    clearStaleBreadcrumbs(breacrumbs, params) {
        if (breacrumbs == null) return breacrumbs;
        const newBreadcrumbs = { ...breacrumbs };
        const breadcrumbKeys = Object.getOwnPropertyNames(breacrumbs);
        const paramKeys = Object.getOwnPropertyNames(params);
        breadcrumbKeys.filter(name => !paramKeys.some(key => key.toLowerCase().startsWith(name.toLowerCase())))
            .forEach(name => delete newBreadcrumbs[name])
        return newBreadcrumbs;
    }
    render() {
        const { breadcrumbs, location: { pathname }, match: { params } } = this.props;
        return <div className='breadcrumb-container'>
            <ul className="breadcrumb">
                {this.builder.createBreadcrumbItems(pathname, params, this.builder.appendHome(this.clearStaleBreadcrumbs(breadcrumbs, params)))}
            </ul>
        </div>
    }
}
class BreadcrumbItemsBuilder {
    contentMap = {
        'home': {
            path: () => new PlainTextPathBuilder(""),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        // 'region': {
        //     path: (ids) => new LinkTextPathBuilder(AdminPathConfig.Region, ids),
        //     content: (title, path) => {
        //         return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.ListSchool} />;
        //     }
        // },
        // 'regions': {
        //     path: () => new LinkTextPathBuilder(AdminPathConfig.Regions),
        //     content: (title, path) => {
        //         return <ComboContent key={path} titleComponent={RegionsTitle} title={title} path={path} action={GSAdminAction.ListRegion} />;
        //     }
        // },
        // 'school': {
        //     path: (ids) => new LinkTextPathBuilder(AdminPathConfig.School, ids),
        //     content: (title, path) => {
        //         return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.ListSchool} />;
        //     }
        // },
        'user': {
            path: (ids) => new LinkTextPathBuilder(AdminPathConfig.UserEdit, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.EditUser} />;
            }
        },
        'users': {
            path: () => new LinkTextPathBuilder(AdminPathConfig.Users),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={UsersTitle} title={title} path={path} action={GSAdminAction.ListUser} />;
            }
        },
        'template': {
            path: (ids) => new LinkTextPathBuilder(PathConfig.InvitationTemplateEdit, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSSchoolAction.EditTemplate} />;
            }
        },
        'templates': {
            path: () => new LinkTextPathBuilder(PathConfig.InvitationTemplates),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={TemplatesTitle} title={title} path={path} action={GSSchoolAction.InvitationTemplate} />;
            }
        },
        'history': {
            path: () => new PlainTextPathBuilder("history"),
            content: (title, path) => {
                return <PlainTextContent key={path} titleComponent={PlainTitle} title={title} />;
            }
        },
        'globalproduct': {
            path: (ids) => new LinkTextPathBuilder(PathConfig.GlobalProductsEdit, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.EditGlobalProduct} />;
            }
        },
        'globalproducts': {
            path: () => new LinkTextPathBuilder(PathConfig.GlobalProducts),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={GlobalProductsTitle} title={title} path={path} action={GSAdminAction.ListGlobalProduct} />;
            }
        },
        'localproduct': {
            path: (ids) => new LinkTextPathBuilder(PathConfig.LocalProductsEdit, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.EditLocalProduct} />;
            }
        },
        'localproducts': {
            path: (ids) => new LinkTextPathBuilder(PathConfig.LocalProducts, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LocalProductsTitle} title={title} path={path} action={GSAdminAction.ListLocalProduct} />;
            }
        },
        'notification': {
            path: (ids, _, pathname) => new LinkTextPathBuilder(pathname.includes('review') ? PathConfig.NotificationReviewOne : PathConfig.NotificationEdit, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={GSAdminAction.ReviewNotification} />;
            }
        },
        'notifications': {
            path: () => new LinkTextPathBuilder(PathConfig.Notifications),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={NotificationsTitle} title={title} path={path} action={GSAdminAction.CreateNotification} />;
            }
        },
        'upcomingevents': {
            path: () => new PlainTextPathBuilder("upcomingevents"),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle}/>;
            }
        },
        'coachdashboard': {
            path: () => new PlainTextPathBuilder("coachdashboard"),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle}/>;
            }
        },
        'teacherdashboard': {
            path: () => new PlainTextPathBuilder("teacherdashboard"),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle}/>;
            }
        },
        'review': {
            path: () => new LinkTextPathBuilder(PathConfig.NotificationReview),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={ReviewNotificationsTitle} title={title} path={path} action={GSAdminAction.CreateNotification} />;
            }
        },
        'created': {
            path: () => new LinkTextPathBuilder(PathConfig.NotificationCreated),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={CreatedNotificationsTitle} title={title} path={path} action={GSAdminAction.CreateNotification} />;
            }
        },
        'current': {
            path: () => new PlainTextPathBuilder("current"),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle} />
            }
        }
    }
    breadcrumbOrder = {
        'home': 0,
        'region': 1,
        'school': 2,
        'history': 3,
    }
    appendHome(params: BreadcrumbParams) {
        return {
            home: {
                id: '/',
                name: 'Home'
            },
            ...params
        };
    }
    getChain(params: BreadcrumbParams) {
        return Object.getOwnPropertyNames(params).filter(key => params[key] && params[key].id).sort((keyA, keyB) => {
            return this.breadcrumbOrder[keyA] - this.breadcrumbOrder[keyB]
        });
    }
    neededCrumb(pathname, newKeys) {
        const snippets = pathname.split('/');
        if (snippets.length > 2) {
            const crumb = snippets[snippets.length - 2];
            if (!isGuid(crumb) && this.contentMap[crumb]) {
                newKeys.push(crumb);
            }
        }
        return newKeys;
    }
    formatSpecialCrumb(snippet, pathname: string) {
        if (snippet == 'history' || snippet == 'school') {
            return snippet;
        } else {
            const keys = [`${snippet}s`, snippet];
            if (pathname.includes('review') && keys[0].includes('notification')) {
                return [keys[0], 'review', keys[1]];
            }
            if (pathname.includes('created') && keys[0].includes('notification')) {
                return [keys[0], 'created', keys[1]];
            }
            return keys;
        }
    }
    appendSnippets(keys: string[], pathname: string) {
        const newKeys = keys.reduce((pre, cur) => pre.concat(this.formatSpecialCrumb(cur, pathname)), []);
        newKeys.shift();
        return this.neededCrumb(pathname, newKeys);
    }
    getSnippetTitle(type, params) {
        return params[type] ? params[type].name : PlainTitle.toUpperWordFirstChar(type);
    }
    buildBreadcrumbItems(ids, pathname: string, params: BreadcrumbParams) {
        return this.appendSnippets(this.getChain(params), pathname)
            .map(type => {
                const item = this.contentMap[type];
                return item.content(this.getSnippetTitle(type, params), item.path(ids, params, pathname).build())
            })
            .concat([
                this.contentMap.current.content(pathname.split('/').pop(), this.contentMap.current.path().build())
            ]);
    }
    hasCurrent(pathname: string, params: BreadcrumbParams, arr: any[]) {
        let snippets = pathname.split('/');
        const snippet = snippets.pop();
        if (isGuid(snippet) || snippet === '' || snippet === 'school' || snippet === 'region' || snippet === 'notification') {
            return arr.slice(0, arr.length - 1);
        }
        return arr;
    }
    createBreadcrumbItems(pathname: string, ids, params?: BreadcrumbParams) {
        return this.hasCurrent(pathname, params, this.buildBreadcrumbItems(ids, pathname, params));
    }
}
//--- BreadcrumbContent ---
abstract class BreadcrumbContent<TProps> extends React.Component<PlainTextContentProps & TProps> {
}
interface PlainTextContentProps {
    titleComponent: any
}
class PlainTextContent extends BreadcrumbContent<PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, title, isCurrent } = this.props;
        return <li className={isCurrent ? 'current' : ''}>
            <BreadcrumbTitle title={title}></BreadcrumbTitle>
        </li>
    }
}
interface ComboContentProps {
    menuComponent?: any
}
class ComboContent extends BreadcrumbContent<ComboContentProps & LinkTitleProps & PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, menuComponent: BreadcrumbMenu, path, title, action } = this.props;
        return (
            <li>
                <BreadcrumbTitle title={title} path={path} action={action}>
                    {BreadcrumbMenu && <BreadcrumbMenu path={path}>
                        <span className="bread-icon icon-open_breadcrumb" />
                    </BreadcrumbMenu>}
                </BreadcrumbTitle>
            </li>
        )
    }
}
//--- BreadcrumbTitle ---
abstract class BreadcrumbTitle<TProps> extends React.Component<TProps>{ }
interface PlainTitleProps {
    title: string
    isCurrent?: boolean
}
class PlainTitle extends BreadcrumbTitle<PlainTitleProps> {
    static toUpperWordFirstChar(title: string) {
        if (title) {
            return title.replace(/\b\w/g, (c) => c.toUpperCase());
        }
        return '';
    }
    render() {
        return <span>{PlainTitle.toUpperWordFirstChar(this.props.title)}</span>;
    }
}
class CurrentTitle extends PlainTitle {
    getTitle(title) {
        if (title === 'admins') {
            return 'Administrators';
        } else if (title === 'billing') {
            return 'License History';
        } else if (title === 'templates') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbTemplates});
        } else if (title === 'globalproducts') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbGlobalProducts});
        } else if (title === 'localproducts') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbLocalProducts});
        } else if (title === 'created') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbManageNotifications});
        } else if (title === 'review') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbReview});
        } else if (title === 'supportinfo') {
            return fmtMsg({id: SchoolLocale.BreadTextSupportInfo});
        } else if (title === 'codelookup') {
            return fmtMsg({ id: GSAdminLocale.BreadcrumbCodeLookup });
        } else if (title === 'pendingchanges') {
            return fmtMsg({id: GSAdminLocale.BreadcrumbPendingChanges});
        } else if (title === 'upcomingevents') {
            return fmtMsg({id: SchoolLocale.HomeUpcomingEvents});
        } else if (title === 'coachdashboard') {
            return fmtMsg({id: SchoolLocale.HomeCoachDashboard});
        } else if (title === 'teacherdashboard') {
            return fmtMsg({id: SchoolLocale.HomeTeacherDashboard});
        } else if (title === 'mychat') {
            return fmtMsg({id: GSAdminLocale.MyChat});
        } else if (title === 'chathistory') {
            return fmtMsg({id: GSAdminLocale.ChatHistory});
        }
        else {
            return PlainTitle.toUpperWordFirstChar(title);
        }
    }
    render() {
        return <span>{this.getTitle(this.props.title.toLowerCase())}</span>;
    }
}

interface LinkTitleProps {
    path: string
    action?: string
}
class LinkTitle extends BreadcrumbTitle<LinkTitleProps & PlainTitleProps> {
    getPath() {
        return this.props.path;
    }
    getTitle() {
        return this.props.title;
    }
    onClick(e) { }
    getLink() {
        const title = this.getTitle();
        const path = this.getPath();
        const route = window.location.hash == '' ? window.location.pathname : window.location.hash.substring(1);
        if (path === route
            || (this.props.action && this.props.action != GSAdminAction.None
                && !GLGlobal.isActionValid(this.props.action))) {
            return <a className='link-plain' href="javascript:void(0)" title={title}><span>{title}</span></a>
        } else {
            return <Link to={path} onClick={this.onClick.bind(this)}><span>{title}</span></Link>
        }
    }
    render() {
        return (
            <>
                {this.getLink()}
                {this.props.children}
            </>
        );
    }
}
class NotificationsTitle extends LinkTitle {
    getPath() {
        return `/notifications`;
    }
}
class ReviewNotificationsTitle extends LinkTitle {
    getPath() {
        return `/notifications/review`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbReview});
    }
}
class CreatedNotificationsTitle extends LinkTitle {
    getPath() {
        return `/notifications/created`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbManageNotifications});
    }
}
class RegionsTitle extends LinkTitle {
    getPath() {
        return `/regions`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbRegions});
    }
}
class TemplatesTitle extends LinkTitle {
    getPath() {
        return `/templates`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbTemplates});
    }
}
class UsersTitle extends LinkTitle {
    getPath() {
        return `/users`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbUsers});
    }
}
class GlobalProductsTitle extends LinkTitle {
    getPath() {
        return `/globalproducts`;
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbGlobalProducts});
    }
}
class LocalProductsTitle extends LinkTitle {
    getPath() {
        return '/localproducts';
    }
    getTitle() {
        return fmtMsg({id: GSAdminLocale.BreadcrumbLocalProducts});
    }
}
//--- BreadcrumbPathBuilder ---
abstract class BreadcrumbPathBuilder {
    constructor(protected pathConfig?: string, protected params: any = {}) { }
    getPath(path, params) {
        return GLUtil.pathStringify(path, params);
    }
    abstract build()
}
class LinkTextPathBuilder extends BreadcrumbPathBuilder {
    build() {
        return this.getPath(this.pathConfig, this.params);
    }
}

class PlainTextPathBuilder extends BreadcrumbPathBuilder {
    constructor(private title: string) { super() }
    build() {
        return `/${this.title}`;
    }
}