import * as React from "react";
import { List as AntdList, Icon, Checkbox } from "antd-min";
import { ListProps as AntdListProps } from "antd/lib/list";
// import InfiniteScroll from "react-infinite-scroller";
import { Actions, Loading } from "../index";
import "./list.less";

interface ListProps extends AntdListProps<any> {
    // lazyload?: { loadMore(): void; totalCount: number; loading: boolean };
}

export const List: React.StatelessComponent<ListProps> = (props: ListProps) => {
    const { /** lazyload, */ dataSource } = props;
    const renderList = <AntdList {...props} />;
    // const hasMore = lazyload ? dataSource.length < lazyload.totalCount : null;
    // const loadMore = () => {
    //     return lazyload && !lazyload.loading && lazyload.loadMore();
    // };
    return renderList;
    // return !lazyload ? (
    //     renderList
    // ) : (
    //     <InfiniteScroll
    //         pageStart={0}
    //         loadMore={loadMore}
    //         hasMore={hasMore}
    //         initialLoad={false}
    //         loader={<Loading key="1" visible={lazyload.loading} />}
    //     >
    //         {renderList}
    //     </InfiniteScroll>
    // );
};

interface ListItemProps extends React.Props<ListItemProps> {
    id?: string;
    children?: any;
    icon?: string;
    materialIcon?: string;
    iconImage?: string;
    onSelect?(id: string, checked: boolean): void;
    selected?: boolean;
    actions?: JSX.Element[];
    className?: string;
    actionOnHover?: boolean;
    disableSelect?: boolean;
    loading?: boolean;
}

export const ListItem: React.StatelessComponent<ListItemProps> = (props: ListItemProps) => {
    const Item = AntdList.Item;
    const { id, icon, materialIcon, iconImage, children, actions, onSelect, selected, className, actionOnHover, loading } = props;
    return (
        <Item
            className={`tw-list-item ${className ? className : ""} ${selected ? "checked" : ""} ${onSelect ? "tw-list-item-selectable" : ""} ${
                actionOnHover ? "tw-action-hover" : ""
            }`}
        >
            <figure>
                {materialIcon && <i className="material-icons">{materialIcon}</i>}
                {icon && <Icon type={icon} />}
                {iconImage && <img className="icon-image" src={iconImage} />}
            </figure>
            {loading && (
                <div className="tw-list-item-loading">
                    <Icon type="loading" />
                </div>
            )}
            {onSelect && (
                <div className="tw-list-item-checkbox">
                    <Checkbox disabled={props.disableSelect === true} checked={selected} onChange={e => onSelect(id, e.target.checked)} />
                </div>
            )}
            <div className="tw-list-item-content">{children}</div>
            {actions && <Actions actions={actions} />}
        </Item>
    );
};
