import moment from 'moment';
import { SurveyQuestionType, VisitationTeacherStage } from '@app/util/coach/enum';
import { CampusModel } from "@app/service/schools";
import { ClassesModel } from "@app/service/class";
import { VideoViewedModel } from "@app/service/training";

export interface VisitationModel {
    id?: string,
    startDate?: string,
    endDate?: string,
    expireDate?: string,
    stageDate?: string,
    createAt?: string,
    createBy?: string,
    type?: number,
    stage?: number,
    regionId?: string,
    schoolId?: string,
    informSchool?: boolean,
    completedDate?: string,
    scheduleMode?: number,
    campusId?: string,
    note?: string,
    coachId?: string,
    isCancelled?: boolean,
    associatedInfo?: any,
    schoolName?: string,
    campusName?: string
}

export interface SurveyResponseAnswerModel {
    id: string,
    answer: string,
    surveyQuestionId: string,
    surveyResponseId: string,
    surveyQuestionTypeId: string,
}

export interface surveyQuestionModel {
    id: string,
    question: string,
    required?: boolean,
    surveyQuestionTypeId: SurveyQuestionType,
    minScore?: null,
    maxScore?: null,
    isNaEnabled?: null,
    surveyId: string,
    creationDate: string,
    surveyResponseAnswer: SurveyResponseAnswerModel[],
    surveyQuestionOption: []
}

export interface surveyQuestionOptionModel {
    id: string,
    surveyQuestionId: string,
    option: string,
    order: number
}

export interface TeacherDashboardTitleModel {
    stageName?: string,
    coachName?: string
    formattedStartDate?: string
    statusText?: string
}

export interface AssociatedInfoModel {
    visitationSchoolNote: VisitationSchoolNoteModel
    visitationSchoolReview: VisitationSchoolReviewModel
    visitationAlternateDate: VisitationAlternateDateModel
    preVisitationForm: PreVisitationFormModel[]
    upcomingEvent?: any
    reviewTeacherForm: ReviewTeacherFormModel[]
    reviewCoachForm: ReviewCoachFormModel[]
    visitationTeacher: VisitationTeacher[]
    lvaResourceForm: LVAResourceModel[]
    visitationTeacherSchoolClass: VisitationTeacherSchoolClassModel[]
}

export interface VisitationTeacherSchoolClassModel {
    id: string;
    name: string;
    age: number;
    studentCount: number;
    classTime: ClassTimeModel[]
    currentUnit?: number;
    regionId: string;
    schoolId: string;
    campusId: string;
    lessonNumber: number;
}

export interface ClassTimeModel {
    schoolClassId: string;
    daysOfWeek: number;
    start: string;
    end: string;
    duration: number;
    type: string;
}

export interface VisitationSchoolNoteModel {
    visitationId: string
    note: string
    status: number
    associatedInfo?: any
}

export interface VisitationSchoolReviewModel {
    visitationId: string
    adminNotes: string
    schoolNotes: string
    status: number
    associatedInfo: any
}

export interface VisitationAlternateDateModel {
    visitationId: string
    createdBy: string
    startDate: Date
    endDate: Date
    note: string
    createAt: Date
    status: number
    updatedBy: string
    updatedAt: string
    alternateRole: string
    associatedInfo: any
}

export interface PreVisitationFormModel {
    includeLastFeedback: boolean
    status: number
    surveyId: string
    surveyInstanceId: string
    note: string
    visitationId: string
    teacherId: string
    associatedInfo: any
    id: string
}

export interface ReviewTeacherFormModel {
    status: number
    surveyId: string
    surveyInstanceId: string
    visitationId: string
    teacherId: string
    associatedInfo: any
    id: string
}

export interface ReviewCoachFormModel {
    status: number
    surveyId: string
    surveyInstanceId: string
    visitationId: string
    teacherId: string
    associatedInfo: any
    id: string
}

export interface VisitationTeacher {
    teacherName: string
    teacherLink: string
    teacherId: string
    visitationId: string
    associatedInfo?: any
    stage: VisitationTeacherStage
}

export interface LVAResourceModel {
    schoolClassId: string
    type: string
    name: string
    url: string
    uploadTime: string
    uploadBy: string
    status: number
    visitationId: string
    submitTime: string
    associatedInfo: any
    id: string
    teacherId: string
}

export interface TeacherRelatedModel {
    id: string
    startDate: string,
    endDate: string,
    expireDate: string,
    stageDate: string,
    createAt: string,
    createBy: string,
    type: number,
    stage: number,
    regionId: string,
    schoolId: string,
    informSchool: boolean,
    completedDate: string,
    campusId: string,
    note: string,
    coachId: string,
    cancel: boolean,
    associatedInfo: AssociatedInfoModel
}

export interface SchoolVisitationStatus {
    regionId: string,
    schoolId: string,
    schoolName: string,
    dateSinceLastReview: string,
    isCoachRelated: boolean,
    onSiteTotal: number,
    onSiteRemaining: number,
    lvaTotal: number,
    lvaRemaining: number
}

export interface SchoolVisitationHistory {
    id: string,
    regionId: string,
    schoolId: string,
    schoolName: string,
    campusId: string,
    campusName: string,
    coachId: string,
    coachName: string,
    visitationTeacherHistories: VisitationTeacher[],
    startDate: string,
    endDate: string,
    expireDate: string,
    stageDate: string,
    createAt: string,
    createBy: string,
    type: number,
    stage: number,
    informSchool: boolean,
    completedDate: string,
    note: string,
    isCancelled: boolean
}

export enum SchoolVisitationHistoryPropsModel {
    id = 'id',
    regionId = 'regionId',
    schoolId = 'schoolId',
    schoolName = 'schoolName',
    campusId = 'campusId',
    campusName = 'campusName',
    coachId = 'coachId',
    coachName = 'coachName',
    visitationTeacherHistories= 'visitationTeacherHistories',
    visitationTeacherHistoriesCount= 'visitationTeacherHistoriesCount',
    teacherCount = 'teacherCount',
    startDate = 'startDate',
    endDate = 'endDate',
    expireDate = 'expireDate',
    stageDate = 'stageDate',
    createAt = 'createAt',
    createBy = 'createBy',
    type = 'type',
    visitationTypeText='visitationTypeText',
    stage = 'stage',
    informSchool = 'informSchool',
    completedDate = 'completedDate',
    note = 'note',
    isCancelled = 'isCancelled'
}

export interface SchoolVisitationHistories {
    status: SchoolVisitationStatus,
    histories: SchoolVisitationHistory[]
}

export interface CampusVisitationHistories {
    campus: CampusModel,
    histories: SchoolVisitationHistory[]
}

export interface SchoolCoachVisitation {
    coachId: string;
    coachName: string;
    coachAvatar: string;
    visitations: SchoolVisitationHistory[];
    feedbacks: SchoolCoachFeedback[];
    videoViewed: VideoViewedModel;
}

export interface SchoolTeacherVisitation {
    teacherId: string,
    teacherName: string,
    teacherAvatar: string,
    videoViewed: VideoViewedModel,
    reviewHistories: SchoolTeacherReviewHistory[]
}

export interface SchoolTeacherTrainingInfo {
    foundationTraining: moment.Moment,
    refresherTraining: moment.Moment,
}

export interface SchoolTeacherReviewHistory {
    coachId: string
    coachName: string
    reviewDate: string
    schoolId: string
    schoolName: string
    type: number
    visitationId: string
    surveyInstanceId: string
}

export enum SchoolTeacherReviewHistoryPropsModel {
    coachId = 'coachId',
    coachName = 'coachName',
    reviewDate = 'reviewDate',
    schoolId = 'schoolId',
    schoolName = 'schoolName',
    type = 'type',
    typeText = 'typeText',
    visitationId = 'visitationId',
    surveyInstanceId = 'surveyInstanceId',
}

export interface SchoolCoachFeedback {
    id: string,
    regionId: string,
    schoolId: string,
    campusId: string,
    teacherName: string,
    schoolName: string,
    campusName: string,
    reviewDate: string,
    coachId: string,
    coachName: string,
    startDate: string,
    endDate: string,
    expireDate: string,
    stageDate: string,
    createAt: string,
    createBy: string,
    type: number,
    stage: number,
    informSchool: boolean,
    completedDate: string,
    note: string,
}

export enum SchoolCoachFeedbackModel {
    id = "id",
    regionId = 'regionId',
    schoolId = 'schoolId',
    campusId = 'campusId',
    teacherName = "teacherName",
    schoolName = "schoolName",
    campusName = "campusName",
    reviewDate = "reviewDate",
    coachId = 'coachId',
    coachName = 'coachName',
    startDate = 'startDate',
    endDate = 'endDate',
    expireDate = 'expireDate',
    stageDate = 'stageDate',
    createAt = 'createAt',
    createBy = 'createBy',
    type = 'type',
    stage = 'stage',
    informSchool = 'informSchool',
    completedDate = 'completedDate',
    note = 'note',
}

export const MAX_ALLOWED_UPLOAD_VIDEO_SIZE = 10 * 1024 * 1024 * 1024;
