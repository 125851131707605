import React, { Component } from 'react';
import moment from 'moment';
import "../component/progress.less";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import { SaveCancelBtns } from '@app/components';
import { IChatService, MESSAGE_REFRESH_TIME } from '@app/service/chat';
import { clearErrors, isCoachAndSelf } from '@app/util/coach/fun';
import { PathConfig } from '@app/config/pathconfig';
import { Table, Row, Button, Spin, Modal, Badge } from 'antd-min';
import { ChatBadge } from '@app/components/coach/chat/chat-badge';
import { IAccountService } from '@app/service/admin/accountservice';
import { ChatDrawer } from '@app/components/coach/chat/chat-drawer';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { fmtMsg, lazyInject, TYPES, isQuestionEmpty, GSSchoolAction, DateHelper, CONSTS } from '@app/util';
import { VisitationCoachStage, SurveyTodoType, ReviewTeacherFormStatus, SurveyQuestionType } from '@app/util/coach/enum';
import { GLForm, GLFormComponentProps, MessageHelper, NotificationType, GLUtil, GLGlobal, RegionInfoModel } from 'gl-commonui';
import { SurveyQuestions } from '../component/survey-questions';
import { CustomDrawer } from '../../component/custom-drawer';
import { Col } from 'gl-commonui/lib/antd-min';
import { Select } from 'antd';
import { IUserService } from '@app/service/users';
import { ISurveyService } from '@app/service/survey';
import { debounce } from 'lodash';
interface TeacherReviewPrps extends GLFormComponentProps {
    visitation: VisitationModel
    callback: (stage) => void
}
interface TeacherReviewStates {
    visible?: boolean
    status?: number
    recorder?: any
    loading?: boolean
    spinning?: boolean
    isSending?: boolean
    todoSurveyResponse?: any
    reviewTeachersInfos?: any[]
    receiver?: any
    messageVisible: boolean
    chatBadgeCounts?: any
    submitDate?: Date
    modal: {
        sharedUser: { visible: boolean };
    };
    searchUser: any[];
    probablyShareUsers: any[],
    userShareFetching: boolean,
    notifyUsers: any[];
    formatRoleTargets: any[];
    checkNotifyList: boolean;
    searchShareUser: any[];
    searchTempShareUser: any[];
    selectedRegion: string;
}
@GLForm.create()
export class TeacherReview extends Component<TeacherReviewPrps, TeacherReviewStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IChatService)
    chatservice: IChatService
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    @lazyInject(TYPES.ISurveyService)
    surveyService: ISurveyService;
    flushMessages: any
    creatorSharedUsers = { initValueSaved: false, value: [] };
    userShareSelectSearch;
    lastShareFetchId = 0;
    loggedInUserId: string;
    accessibleRegions: RegionInfoModel[];

    constructor(props) {
        super(props);
        const debounceTime = 800;
        this.state = {
            recorder: {},
            visible: false,
            loading: false,
            spinning: false,
            isSending: false,
            todoSurveyResponse: {},
            reviewTeachersInfos: [],
            messageVisible: false,
            modal: {
                sharedUser: { visible: false },
            },
            searchUser: [],
            probablyShareUsers: [],
            notifyUsers: [],
            formatRoleTargets: [],
            checkNotifyList: false,
            searchShareUser: [],
            searchTempShareUser: [],
            userShareFetching: false,
            selectedRegion: GLGlobal.loginInfo().profile.regionInfo && GLGlobal.loginInfo().profile.regionInfo.length > 0 ? GLGlobal.loginInfo().profile.regionInfo[0].id : null
        }
        this.userShareSelectSearch = debounce(searchUser => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            this.onShareUserSelectSearch(searchUser.trim());
        }, debounceTime);
        this.accessibleRegions = GLGlobal.loginInfo().profile.regionInfo || [];
        this.onRegionChange = this.onRegionChange.bind(this);
    }

    componentWillMount() {
        this.getReviewTeacherForm();
    }

    onRegionChange(value) {
        this.setState({ selectedRegion: value });
    }


    getRegionAndTenantInfo() {
        const regionInfo = this.accessibleRegions.find(x => x.id == this.state.selectedRegion);
        let regionId = CONSTS.EmptyGuid;
        let tenantId = null;
        if (regionInfo) {
            regionId = regionInfo.id;
            tenantId = regionInfo.tenantId;
        }

        return { regionId, tenantId };
    }

    getTeacherNames(infos) {
        const ids = infos.map(v => v.teacherId);
        if (!ids.length) {
            this.setState({ loading: false });
            return;
        }
        this.accountservice.getUsersByPost({ ids }).then(data => {
            const formatInfos = infos.map((info, key) => {
                const teacher = data && data.data && data.data.find(t => t.id == info.teacherId);
                const teacherName = teacher && teacher.name;
                return {
                    key,
                    ...info,
                    teacherName,
                }
            }).sort((a, b) => {
                return a.teacherName.localeCompare(b.teacherName);
            });
            this.setState({
                loading: false,
                reviewTeachersInfos: formatInfos
            });
        });
    }
    getReviewTeacherForm() {
        const { visitation: { id } } = this.props;
        const params = {
            visitationId: id
        }
        this.setState({ loading: true });
        this.visitationservice.getReviewTeacherForm(params).then(data => {
            this.getTeacherNames(data.reviewTeacherInfos);

            const teacherIds = data.reviewTeacherInfos.map(v => v.teacherId);
            this.getAllUnReadBadges(teacherIds, id);
            this.flushMessages = setInterval(() => this.getAllUnReadBadges(teacherIds, id), MESSAGE_REFRESH_TIME);
        });
    }
    getTableCols() {
        const { visitation: { regionId, schoolId, stage, coachId } } = this.props;
        const isLastStageOrCompleted = stage == VisitationCoachStage.Completed ||
            stage == VisitationCoachStage.SchoolReview;
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeacher }),
                dataIndex: "teacherName",
                width: "30%",
                render: (text, recorder, index) => {
                    const { teacherId } = recorder;
                    const count = this.state.chatBadgeCounts && this.state.chatBadgeCounts[teacherId] ? this.state.chatBadgeCounts[teacherId] : 0;
                    return <div>
                        {isCoachAndSelf(GSSchoolAction.TeacherLink, coachId) ?
                            <Link to={{ pathname: GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId }) }} >
                                <span className="teacher-name">{text}</span>
                            </Link> : <span>{text}</span>
                        }
                        {isCoachAndSelf(GSSchoolAction.Chat, coachId) &&
                            stage !== VisitationCoachStage.Completed &&
                            <ChatBadge
                                label="teacher-review-chat"
                                count={count}
                                handleClick={() => this.sendMessage(recorder)}
                            />
                        }
                    </div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationClasses }),
                dataIndex: "schoolClasses",
                width: "30%",
                render: (text, recorder, index) => {
                    return text.map((v, k) => {
                        const { regionId, schoolId, campusId, schoolClassId } = v;
                        return <div key={k} title={v.schoolClassName} className="class-name">
                            {isCoachAndSelf(GSSchoolAction.ClassLink, coachId) ?
                                <Link to={{ pathname: GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: schoolClassId }) }} >
                                    <div className="class-list">{v.schoolClassName}</div>
                                </Link> :
                                <div className="class-list">{v.schoolClassName}</div>
                            }
                        </div>
                    });
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationStatus }),
                dataIndex: "",
                width: "20%",
                render: (text, recorder, index) => {
                    const { reviewTeacherForm } = recorder;
                    const status = !reviewTeacherForm ? ReviewTeacherFormStatus.Initialized : reviewTeacherForm.status;
                    return <div>{this.formatReviewTeacherFormStatusTips(status)}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationAction }),
                dataIndex: "",
                width: "20%",
                render: (text, recorder, index) => {
                    const { reviewTeacherForm } = recorder;
                    const status = !reviewTeacherForm ? ReviewTeacherFormStatus.Initialized : reviewTeacherForm.status;
                    const isReviewCompleted = status != ReviewTeacherFormStatus.Completed &&
                        status != ReviewTeacherFormStatus.Viewed;
                    const action = !reviewTeacherForm ? GSSchoolAction.SendFeedback :
                        this.formatAction(reviewTeacherForm.status);
                    return <div className={`form-action ${isLastStageOrCompleted && isReviewCompleted && "disabled"}`}>
                        {isCoachAndSelf(action, coachId) &&
                            <div onClick={() => this.showTeacherFeedback(status, recorder)}>
                                {this.formatReviewTeacherFormStatusText(status)}
                            </div>
                        }
                    </div>
                }
            },
        ]
    }
    formatAction(status) {
        switch (status) {
            case ReviewTeacherFormStatus.Initialized:
            case ReviewTeacherFormStatus.Draft:
                return GSSchoolAction.SendFeedback;
            case ReviewTeacherFormStatus.Completed:
            case ReviewTeacherFormStatus.Viewed:
                return GSSchoolAction.ViewFeedback;
        }
    }
    formatReviewTeacherFormStatusText(status) {
        switch (status) {
            case ReviewTeacherFormStatus.Initialized:
            case ReviewTeacherFormStatus.Draft:
                return fmtMsg({ id: SchoolLocale.VisitationSendFeedback });
            case ReviewTeacherFormStatus.Completed:
            case ReviewTeacherFormStatus.Viewed:
                return fmtMsg({ id: SchoolLocale.VisitationViewFeedback });
        }
    }
    formatReviewTeacherFormStatusTips(status) {
        switch (status) {
            case ReviewTeacherFormStatus.Initialized:
            case ReviewTeacherFormStatus.Draft:
                return fmtMsg({ id: SchoolLocale.VisitationYetToSend });
            case ReviewTeacherFormStatus.Completed:
                return fmtMsg({ id: SchoolLocale.VisitationHasSent });
            case ReviewTeacherFormStatus.Viewed:
                return fmtMsg({ id: SchoolLocale.VisitationHasViewed });
        }
    }
    showTeacherFeedback(status, recorder) {
        this.showDrawer(status, recorder);
        this.setState({ spinning: true });
        recorder.reviewTeacherForm ? this.getTeacherFeedback(recorder) :
            this.buildTeacherFeedback(recorder);

    }
    buildTeacherFeedback(recorder) {
        const { teacherId } = recorder;
        const { visitation: { id } } = this.props;
        const { reviewTeachersInfos } = this.state;
        const params = {
            teacherId,
            visitationId: id,
        }

        this.visitationservice.buildCoachToTeacherFeedback(params).then(data => {
            if (data && data.todoSurveyResponse) {
                const infos = reviewTeachersInfos.map(t => {
                    if (t.teacherId == data.reviewTeacherForm.teacherId) {
                        return {
                            ...t,
                            reviewTeacherForm: data.reviewTeacherForm
                        }
                    }
                    return t;
                });
                this.setState({
                    spinning: false,
                    reviewTeachersInfos: infos,
                    todoSurveyResponse: data.todoSurveyResponse,
                    recorder: { ...recorder, reviewTeacherForm: data.reviewTeacherForm },
                });
            }
        });
    }

    getSurveyInstanceDetail(selectedSurveyInstance: string) {
        this.surveyService
            .surveyInstanceDetail({ surveyInstanceId: selectedSurveyInstance })
            .then(({ surveyInstance, surveyInstanceCount }: { surveyInstance: any; surveyInstanceCount: number }) => {
                this.setState(
                    {
                        searchShareUser: surveyInstance.surveyShare.map(share => ({ key: share.userId }))
                    }
                );
            });
    }

    getTeacherFeedback(recorder) {
        const { visitation: { id } } = this.props;
        const { reviewTeacherForm: { surveyInstanceId } } = recorder;
        const params = {
            surveyInstanceId,
            visitationId: id,
            type: SurveyTodoType.ReadOnlyShared
        }
        this.visitationservice.viewSurveyReponse(params).then(data => {
            this.setState({
                spinning: false,
                todoSurveyResponse: data
            }, () => this.getSurveyInstanceDetail(data.surveyInstanceId));
        });
    }

    formatQuestionAnswer(values) {
        const { todoSurveyResponse: { id, surveyQuestion } } = this.state;
        const entries = Object.entries(values);
        return surveyQuestion.map((que, key) => {
            const answersValue = entries.find(e => e[0].slice(9) == que.id);
            const answerId = que &&
                que.surveyResponseAnswer.length ?
                que.surveyResponseAnswer[0].id : "";
            const answers = answersValue ? (Array.isArray(answersValue[1]) ? answersValue[1].join("\t") : answersValue[1]) : "";
            return {
                id: answerId,
                answer: answers,
                surveyResponseId: id,
                surveyQuestionId: que.id,
                surveyQuestionTypeId: SurveyQuestionType.Text
            }
        });
    }
    fillTeacherFeedback(saveAsDraft = false) {
        const { form } = this.props;
        if (saveAsDraft) {
            clearErrors(form);
            this.fillCoachToTeacherFeedback(saveAsDraft, form.getFieldsValue());
        } else {
            form.validateFields((error, values) => {
                if (error || isQuestionEmpty(form, values)) return;
                this.fillCoachToTeacherFeedback(saveAsDraft, values);
            });
        }
    }
    fillCoachToTeacherFeedback(saveAsDraft, values) {
        const { form, visitation } = this.props;
        const { recorder: { teacherId, reviewTeacherForm }, reviewTeachersInfos,
            todoSurveyResponse: { id, surveyInstanceId } } = this.state;
        const status = saveAsDraft ? ReviewTeacherFormStatus.Draft :
            ReviewTeacherFormStatus.Completed;
        const params = {
            visitationId: visitation.id,
            model: {
                teacherId,
                status: status,
                surveyInstanceId,
                reviewTeacherFormId: reviewTeacherForm &&
                    reviewTeacherForm.id,
            },
            todoSurveyRequestModel: {
                id: id,
                surveyInstanceId,
                responseStatus: status,
                surveyResponseAnswer: this.formatQuestionAnswer(values),
            }
        }
        this.setState({ isSending: true });
        this.visitationservice.fillCoachToTeacherFeedback(params).then(data => {
            const infos = reviewTeachersInfos.map(t => {
                if (t.teacherId == teacherId) {
                    t.reviewTeacherForm.status = status;
                    t.reviewTeacherForm.surveyAnswerModel = data;
                    return t;
                }
                return t;
            });
            form.resetFields();
            this.setState({
                visible: false,
                isSending: false,
                reviewTeachersInfos: infos,
                submitDate: data.submitDate
            });
        }).catch(() => {
            this.setState({ isSending: false });
        });
    }
    showDrawer = (status, recorder) => {
        this.setState({
            visible: true,
            status,
            recorder
        });
    }
    onClose = () => {
        const { form } = this.props;
        form && form.resetFields();
        this.setState({ visible: false, messageVisible: false });
    }
    sendMessage(recorder) {
        this.setState({
            receiver: recorder,
            messageVisible: true
        });
    }
    getAllUnReadBadges(senderIds, visitationId) {
        this.chatservice.getAllUnReadBadges(senderIds, visitationId).then(data => {
            this.setState({
                chatBadgeCounts: data
            });
        });
    }
    getBadgeCount(id) {
        this.chatservice.getBadgeCount(id).then(data => {
            let counts = this.state.chatBadgeCounts;
            counts[id] = data;
            this.setState({
                chatBadgeCounts: counts
            });
        });
    }
    renderContent() {
        const { visitation: { stage, coachId } } = this.props;
        const { loading, reviewTeachersInfos } = this.state;
        const disabled = stage > VisitationCoachStage.TeacherReview;
        return <div className={disabled ? "disabled" : ""}>
            <div>{fmtMsg({ id: SchoolLocale.VisitationPostForm })}</div>
            <Table
                loading={loading}
                className="visitation-form"
                columns={this.getTableCols()}
                dataSource={reviewTeachersInfos}
                pagination={false}
            />
            {!disabled && isCoachAndSelf(GSSchoolAction.MoveNext, coachId) &&
                <SaveCancelBtns
                    noCancelBtn={true}
                    saveTitle={SchoolLocale.VisitationMoveNextText}
                    onSubmit={() => this.completeNext()}>
                </SaveCancelBtns>}
        </div>;
    }
    renderDrawerContent() {
        const { form, visitation: { regionId, schoolId } } = this.props;
        const { status, recorder: { teacherId }, visible, spinning, isSending, todoSurveyResponse: { surveyQuestion } } = this.state;
        const disabled = status == ReviewTeacherFormStatus.Completed ||
            status == ReviewTeacherFormStatus.Viewed;
        const questionListClassName = {
            'question-list': true,
            'read-only': disabled
        }
        const teacherInfo = {
            regionId,
            schoolId,
            teacherId,
        }
        return <>
            <div className={`send-feedback`}>
                <Spin spinning={spinning}>
                    <div className={classNames(questionListClassName)}>
                        {surveyQuestion &&
                            <SurveyQuestions
                                form={form}
                                isDisabled={disabled}
                                forceRender={!visible}
                                isTeacherReview={true}
                                teacherInfo={teacherInfo}
                                surveyQuestions={surveyQuestion}
                            />
                        }
                    </div>
                </Spin>
            </div>
            {!disabled && <div className={`send-feedback-btns ${isSending && "disabled"}`}>
                <SaveCancelBtns saveTitle={SchoolLocale.VisitationTeacherFeedbackSendText}
                    onSubmit={() => this.fillTeacherFeedback(false)}
                    onCancel={() => this.fillTeacherFeedback(true)}
                    cancelTitle={SchoolLocale.VisitationSaveDraft} />
            </div>}
            {disabled &&
                <div className='submit-btns view-response-btn'>
                    <Button onClick={() => this.onClose()} >
                        {fmtMsg({ id: GSAdminLocale.ButtonClose })}
                    </Button>
                </div>
            }
        </>;
    }
    completeNext() {
        const { reviewTeachersInfos } = this.state;
        const isAllFinished = reviewTeachersInfos.some(t => t.reviewTeacherForm &&
            (t.reviewTeacherForm.status == ReviewTeacherFormStatus.Completed ||
                t.reviewTeacherForm.status == ReviewTeacherFormStatus.Viewed));
        const showTips = reviewTeachersInfos.some(t => !t.reviewTeacherForm || (
            t.reviewTeacherForm && (
                t.reviewTeacherForm.status == ReviewTeacherFormStatus.Initialized ||
                t.reviewTeacherForm.status == ReviewTeacherFormStatus.Draft
            )));
        if (!isAllFinished) {
            MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SchoolLocale.VisitationSendFeedbackTips }));
        } else if (showTips) {
            Modal.confirm({
                className: "teacher-review-confirm",
                title: fmtMsg({ id: SchoolLocale.VisitationComfirmTitle }),
                content: fmtMsg({ id: SchoolLocale.VisitationNotAllSendFeedbacks }),
                okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
                cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
                onOk: () => {
                    this.TeacherReviewMoveNext();
                },
                onCancel: () => { }
            });
        } else {
            this.TeacherReviewMoveNext();
        }
    }
    TeacherReviewMoveNext() {
        const { visitation: { id }, callback } = this.props;
        const stage = VisitationCoachStage.SchoolReview;
        const params = {
            visitationId: id,
        }
        this.visitationservice.teacherReviewMoveNext(params).then(data => {
            callback(stage);
        });
    }

    onShareUserSelectSearch(searchShareUser) {
        this.lastShareFetchId += 1;
        const fetchId = this.lastShareFetchId;
        const regionInfo = this.getRegionAndTenantInfo();
        this.setState({ probablyShareUsers: [], userShareFetching: true });
        this.userService.getSubordinates({ userName: searchShareUser, email: searchShareUser, regionId: regionInfo.regionId, tenantId: regionInfo.tenantId }).then(d => {
            if (fetchId !== this.lastShareFetchId) return;
            const probablyShareUsers = d
                .map(user => ({
                    id: user.id,
                    name: user.name,
                    email: user.email
                }))
                .filter(user => this.loggedInUserId != user.id && !this.state.searchShareUser.some(su => su.key === user.id));
            this.setState({
                probablyShareUsers,
                userShareFetching: false
            });
        });
    }

    onShareUserSelectChange(searchTempShareUser) {
        this.setState({
            searchTempShareUser,
            probablyShareUsers: [],
            userShareFetching: false
        });
    }

    renderSelectUserModal() {
        const hide = () => {
            this.creatorSharedUsers = { initValueSaved: false, value: [] };
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchTempShareUser: [...searchShareUser],
                selectedRegion: this.accessibleRegions.length > 0 ? this.accessibleRegions[0].id : null
            });
        }
        const save = () => {
            const userIds = this.state.searchTempShareUser.map(shareUser => shareUser.key);
            const surveyId = this.state.todoSurveyResponse.surveyId;
            const surveyInstanceId = this.state.todoSurveyResponse.surveyInstanceId;
            const params = {
                surveyId: surveyId,
                shareUserIds: userIds,
                surveyInstanceId: surveyInstanceId
            }
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchShareUser: [...searchTempShareUser]
            }, () => this.surveyService.shareSurvey(params));
        }
        const { userShareFetching, probablyShareUsers, searchShareUser, searchTempShareUser } = this.state;
        if (!this.creatorSharedUsers.initValueSaved && searchTempShareUser.length) {
            this.creatorSharedUsers.value = JSON.parse(JSON.stringify(searchTempShareUser));
        }

        const fmtEmail = ({ name, email }) => `${name} ${email ? `(${email})` : ""}`;
        return (
            <Modal
                title={fmtMsg({
                    id: GSAdminLocale.NotificationCreateSearchName
                })}
                visible={this.state.modal.sharedUser.visible}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSubmit })}
                okButtonProps={{ disabled: (searchTempShareUser.length === 0 && !this.creatorSharedUsers.value.length) }}
                maskClosable={false}
                className="notified-user-modal"
                destroyOnClose
                onOk={() => {
                    save();
                }}
                onCancel={() => hide()}
            >
                {this.accessibleRegions.length > 1 && <div className="region-section">
                    <p>
                        <label htmlFor="Region" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRegion
                            })}
                        </label>
                    </p>
                    <Select value={this.state.selectedRegion} showSearch={true} onChange={this.onRegionChange}
                        filterOption={
                            (input, option) => {
                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                        }>

                        {this.accessibleRegions.map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.englishName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>}
                <Select
                    mode="multiple"
                    labelInValue
                    style={{ width: "100%" }}
                    value={searchTempShareUser}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={userShareFetching ? <Spin size="small" /> : null}
                    onSearch={d => this.userShareSelectSearch(d)}
                    onChange={this.onShareUserSelectChange.bind(this)}
                >
                    {probablyShareUsers.map(d => (
                        <Select.Option key={d.id} title={fmtEmail(d)}>
                            {fmtEmail(d)}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
        );
    }


    visibleModal(key) {
        if (key === "sharedUser" && !this.state.searchShareUser.some(x => !!x.label)) {
            let promises = [];
            const users = JSON.parse(JSON.stringify(this.state.searchShareUser.map(share => share.key)));
            while (users.length > 0) {
                promises.push(this.userService.getItemsBy({ ids: users.splice(0, 150) }));
            }
            Promise.all(promises).then((d: any[]) => {
                this.setState({
                    searchShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        }),
                    searchTempShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        }),
                });
            });
        }

        const modal = { ...this.state.modal };
        modal[key].visible = true;
        this.setState({ modal });
    }

    renderDrawerTitle() {
        const { status, reviewTeachersInfos, recorder: { teacherId, teacherName } } = this.state;
        let badgeCount = this.state.searchShareUser.length ? this.state.searchShareUser.length : 0
        let teacherInfo = reviewTeachersInfos.find((reviewTeacherInfo) => {
            if (reviewTeacherInfo.reviewTeacherForm) {
                return reviewTeacherInfo.reviewTeacherForm.teacherId == teacherId;
            }
        });
        let formatSubmitDate = teacherInfo && teacherInfo.reviewTeacherForm && teacherInfo.reviewTeacherForm.reviewDate ? DateHelper.toLocalStringFromUTC(moment(teacherInfo.reviewTeacherForm.reviewDate)) : DateHelper.toLocalStringFromUTC(moment());
        switch (status) {
            case ReviewTeacherFormStatus.Initialized:
            case ReviewTeacherFormStatus.Draft:
                return <Row><h3>{fmtMsg({ id: SchoolLocale.VisitationTeacher })}: {teacherName}</h3></Row>;
            case ReviewTeacherFormStatus.Completed:
            case ReviewTeacherFormStatus.Viewed:
                return <>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <h3>{fmtMsg({ id: SchoolLocale.VisitationTeacher })}: {teacherName}</h3>
                        </Col>
                        <Col style={{ marginRight: '25px' }}>
                            <Badge count={badgeCount} showZero>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        this.visibleModal("sharedUser");
                                    }}
                                >
                                    <Button type="primary" icon="share-alt" size="default" className="sharebtn">
                                        Share
                                    </Button>
                                </a>
                            </Badge>
                        </Col>
                    </Row>
                    <Row><h5>{fmtMsg({ id: SchoolLocale.VisitationTeacherReviewSurveySubmit })}: {formatSubmitDate}</h5></Row>
                </>
        }
    }
    render() {
        const { visitation: { id, startDate } } = this.props;
        const { visible, recorder: { teacherName }, receiver, messageVisible } = this.state;
        const formatStartDate = DateHelper.toLocalStringFromUTC(moment(startDate));
        return <div className="post-visitation info-container">
            {this.renderContent()}
            <CustomDrawer
                width={670}
                visible={visible}
                drawerTitle={this.renderDrawerTitle()}
                onClose={() => this.onClose()}>
                <div>{this.renderDrawerContent()}</div>
            </CustomDrawer>
            {
                receiver &&
                <ChatDrawer
                    visitationId={id}
                    receiver={{ id: receiver.teacherId, name: receiver.teacherName }}
                    searchable={true}
                    title={fmtMsg({ id: GSAdminLocale.ChatHeader }, { name: receiver.teacherName })}
                    visible={messageVisible}
                    onClose={() => this.onClose()}
                    getBadgeCount={this.getBadgeCount.bind(this)}
                />
            }
            {this.renderSelectUserModal()}
        </div>
    }
}
