import {
    SurveyService,
    CreatedSurveyModel,
    ReviewSurveyResponseModel,
    SurveyModel,
    TodoSurveyResponseModel,
    PollPageParams,
    CreatedPollsResponseModel,
    PollParticipationResponse,
    SurveyAttachmentModel
} from "@app/service/survey";
import { UserService } from '@app/service/users';
import { ContextHelper } from "@app/util";
import { StateType } from "..";

interface Services {
    survey: SurveyService;
    user: UserService
}

export enum SurveyDisplayMode {
    Create,
    Edit,
    Readonly,
    ReadonlyDelete, // readonly button but only delete is allowed
    EditReadonly // can be edited but currently in readonly
}

export enum SurveyVisibleMode {
    SurveyList,
    SurveyPage,
    Responses,
    FillSurveyPage,
}

export interface SurveyDetailState {
    [surveyId: string]: SurveyModel;
}

export interface SurveyResponseState {
    [instanceId: string]: TodoSurveyResponseModel;
}

export enum MySurveyVisibleMode {
    MySurveyPage,
    HistoryPage,
    FillSurveyPage,
    ResponsePage,
    ShowMoreToDoPage,
    ShowMoreReviewPage,
    ShowMoreSharePage,
}

export enum FillSurveyViewMode {
    ToDo,
    Review,
    ShareContribute,
    ShareView,
    History,
    ShowMoreToDo,
    ShowMoreReview,
    ShowMoreShareContribute,
    ShowMoreShareView
}

export interface SurveyFillState {
    mySurveyTabSection?: MySurveyVisibleMode;
    selectedSurveyInstanceId?: string;
    selectedSurveyResponseId?: string;
    fillSurveyViewMode?: FillSurveyViewMode;
}

export interface UserPollParticipationState {
    [surveyId: string]: PollParticipationResponse;
}

export interface SurveyState {
    surveyVisibleMode?: SurveyVisibleMode;
    createdPollsLoading?: boolean;
    reviewPollsLoading?: boolean;
    participationLoading?: boolean;
    surveyFillState?: SurveyFillState;
    surveyDisplayMode?: SurveyDisplayMode;
    createdSurveys?: CreatedSurveyModel[];
    createdPolls?: CreatedPollsResponseModel;
    reviewPolls?: ReviewSurveyResponseModel;
    userParticipation?: UserPollParticipationState;
    surveyDetails?: SurveyDetailState;
    selectedSurvey?: string;
    shouldReloadCreatedSurveys?: boolean;
    shouldReloadReviewSurveys?: boolean;
    responseAttachments?: SurveyAttachmentModel[];
    reportTypes?: [];
    statisticsFilterType?: [];
    questionsWithError: string[]
}

export default {
    namespace: "survey",
    state: {
        surveyVisibleMode: SurveyVisibleMode.SurveyList,
        createdPollsLoading: false,
        reviewPollsLoading: false,
        participationLoading: false,
        surveyFillState: { mySurveyTabSection: MySurveyVisibleMode.MySurveyPage },
        surveyDisplayMode: SurveyDisplayMode.Create,
        createdSurveys: null,
        createdPolls: { data: null, totalCount: 0 },
        reviewPolls: { data: null, totalCount: 0 },
        userParticipation: {},
        surveyDetails: {},
        selectedSurvey: "",
        shouldReloadCreatedSurveys: true,
        shouldReloadReviewSurveys: true,
        responseAttachments: [],
        reportTypes: [],
        statisticsFilterType: [],
        questionsWithError: []
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setSurveyDetail(state, { payload }) {
            return { ...state, surveyDetails: { ...state.surveyDetails, ...{ [payload.surveyInstanceId]: payload } } };
        },
        removeSurvey(state, { payload }) {
            const tempSurveyDetails = state.createdSurveys;
            const index = tempSurveyDetails.findIndex(a => a.id == payload);
            tempSurveyDetails.pop(tempSurveyDetails[index]);
            return { ...state, createdSurveys: tempSurveyDetails, surveyDetails: {} };
        },
        removeReviewPoll(state, { payload }) {
            return {
                ...state, 
                reviewPolls: {
                    data: state.reviewPolls.data.filter(r => r.id !== payload),
                    totalCount: state.reviewPolls.totalCount ? state.reviewPolls.totalCount - 1 : 0
                }
            };
        },
        setSurveyFillState(state, { payload }) {
            return { ...state, surveyFillState: payload };
        },
        setResponseAttachments(state, { payload }) {
            return { ...state, responseAttachments: payload };
        },
        setUserParticipation(state, { payload }) {
            return { ...state, participationLoading: false, userParticipation: { ...state.userParticipation, ...{ [payload.surveyId]: payload } } };
        },
        setSurveyDisplayMode(state, {payload}) {
            return {...state, surveyDisplayMode: payload}
        }
    },
    effects: {
        *getCreatedPolls({ payload }, { call, put }, { survey }: Services) {
            yield put(reload({ createdPollsLoading: true }));
            const data = yield call(survey.getCreatedPollsByUser, payload);
            yield put(reload({ createdPolls: data, shouldReloadCreatedSurveys: false, createdPollsLoading: false }));
        },
        *getReviewPolls({ payload }, { call, put, all }, { survey, user }: Services) {
            yield put(reload({ reviewPollsLoading: true }));
            let data = yield call(survey.getSurveysToReview, payload);
            const userIds = data.data.filter(d => d.userId != null).map(d => d.userId);
            let users = [];
            if (userIds.length > 0) {
                users = ContextHelper.getUsers(yield all(ContextHelper.getUsersActions(userIds, user)));
            }
            const getUserName = (userId, users) => {
                const user = users.find(user => user.id == userId);
                return user ? user.name : null;
            }
            const getCampusName = (campusName) => {
                return campusName ? ` > campusName` : '';
            }
            const formatedData = userIds.length > 0 ? data.data.map(d => {
                d.userName = getUserName(d.userId, users);
                d.surveyDisplayName = getSurveyName(d.name, d.schoolName, d.campusName, d.userName);
                return d;
            }) : data.data;
            data = {...data, data: formatedData};
            yield put(reload({ reviewPolls: data, shouldReloadReviewSurveys: false, reviewPollsLoading: false }));
        },
        *getUserPollParticipation({ payload }, { call, put }, { survey }: Services) {
            yield put(reload({ participationLoading: true }));
            const data = yield call(survey.getUserPollParticipation, payload);
            yield put(setUserParticipation({ surveyId: payload.id, ...data }));
        },
        *toggleFormVisiblility({ payload }, { put }, { survey }: Services) {
            yield put(reload(payload));
        },
        *getAllStatisticsFilterType({ payload }, { call, put }, { survey }: Services) {
            const data = yield call(survey.getAllStatisticsFilterType, payload);
            yield put(reload({ statisticsFilterType: data }));
        },
        *getAllReportType({ payload }, { call, put }, { survey }: Services) {
            const data = yield call(survey.getAllReportType, payload);
            yield put(reload({ reportTypes: data }));
        },
        *addRemoveQuestionsWithError({payload: {id, add}}, {put, select}) {
            const questionsWithError = yield select((state: StateType) => state.survey.questionsWithError);
            if(add) {
                yield put(reload({questionsWithError: [...questionsWithError, id]}));
            } else {
                yield put(reload({questionsWithError: questionsWithError.filter(q => q !== id)}));
            }
        }
    },
    services: {
        survey: SurveyService,
        user: UserService
    }
};

export function addRemoveQuestionsWithError(state: {id: string, add: boolean}) {
    return {type: "survey/addRemoveQuestionsWithError", payload: state}
}

export function getCreatedPolls(state?: PollPageParams) {
    return { type: "survey/getCreatedPolls", payload: state };
}

export function getReviewPolls(state: PollPageParams) {
    return { type: "survey/getReviewPolls", payload: state };
}

export function getUserPollParticipation(surveyId: string, surveyInstanceId: string) {
    return { type: "survey/getUserPollParticipation", payload: { id: surveyId, surveyInstanceId: surveyInstanceId} };
}

export function setUserParticipation(state: UserPollParticipationState) {
    return { type: "survey/setUserParticipation", payload: state };
}

// poll id will be instance id if it is submitted or responseId if it is reviewed.
export function removeReviewPoll(pollId: string) {
    return { type: "survey/removeReviewPoll", payload: pollId };
}

export function reload(state) {
    return { type: "survey/reload", payload: state };
}

export function toggleFormVisiblility(state) {
    return { type: "survey/toggleFormVisiblility", payload: state };
}

export function setSurveyDetail(state) {
    return { type: "survey/setSurveyDetail", payload: state };
}

export function removeSurvey(surveyId) {
    return { type: "survey/removeSurvey", payload: surveyId };
}

export function setSurveyFillState(surveyFillState: SurveyFillState) {
    return { type: "survey/setSurveyFillState", payload: surveyFillState };
}

export function setResponseAttachments(attachments: SurveyAttachmentModel[]) {
    return { type: "survey/setResponseAttachments", payload: attachments };
}
export function getAllStatisticsFilterType(state) {
    return { type: "survey/getAllStatisticsFilterType", payload: state };
}
export function getAllReportType(state) {
    return { type: "survey/getAllReportType", payload: state };
}
export function setSurveyDisplayMode(state: SurveyDisplayMode) {
    return { type: "survey/setSurveyDisplayMode", payload: state };
}

export function getSurveyName(surveyName, schoolName, campusName, userName) {
    const getCampusName = (campusName) => {
        return campusName ? ` > ${campusName}` : '';
    }    
    return schoolName && userName ? `${surveyName} (${schoolName}${getCampusName(campusName)} > ${userName})` : surveyName;
}