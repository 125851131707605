import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";
import { InvitationChannel, InvitationType } from "@app/util";

export interface InvitationModel {
    id?: string
    code?: string
    invitationType?: number[],
    region?: string,
    schoolname?: string
}
export enum InvitationModelProps {
    code = "code",
    invitationtype = "invitationtype"
}

export interface templateParams {
    codeId?: string
    consumerId?: string
}

export interface RegisterModel {
    userName?: string;
    englishName?: string;
    email?: string;
    phone?: string;
    password?: string;
    confirmPassword?: string;
    roles?: string[];
}

export enum RegisterProps {
    userName = "userName",
    englishName = "englishName",
    email = "email",
    password = "password",
    confirmPassword = "confirmPassword"
}

export interface InvitationCodeValid {
    valid: boolean
}

export enum InvitationCodeStatusCode {
    Success = 2001,
    DoesNotBelongToCampus = 4091,
    DoesNotBelongToSchool = 4092,
    DoesNotBelongToRegion = 4093,
    DoesNotExists = 4631,
    Invalid = 4001
}

export enum InvitationStatus {
    Init = 0,
    Activated = 1,
    ChildrenAdded = 2,
    Expire = 3
}

export interface ResourceResponseModel {
    id: string;
    name: string;
}

export interface InvitationCodeLookupResponseModel {
    id: string;
    code: string;
    statusCode: InvitationCodeStatusCode;
    invitationType: InvitationType;
    invitationStatus?: InvitationStatus;
    issueDate: Date | string;
    registrationDate: Date | string;
    region?: ResourceResponseModel;
    school: ResourceResponseModel;
    campus: ResourceResponseModel;
    schoolClass: ResourceResponseModel;
    userId: string,
    email: string,
    phone: string
}


export class GetInvitationRequest extends GLPagingRequest {
    invitationType?: string
    senderId?: string
    referenceId?: string
    templateId?: string
}
export interface InvitationRequest extends GLRequest, InvitationModel {
    invitationCode?: string
}
export interface InvitationResponse extends GLResponse, InvitationModel {
    enablePhoneRegistration: boolean;
    invitationChannel: InvitationChannel;
}