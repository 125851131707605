import { getNumUnread } from "@app/states/notification";
import { StateType } from "@app/states";
import { Badge } from "antd";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Notifications } from "./components";
import "./style.less";

interface HeaderNotificationState {
    visible: boolean;
}

@connect(
    ({ oidc: { loginInfo }, notification: { numUnread } }: StateType) => ({
        actionLoaded:
            loginInfo &&
            loginInfo.loggedin &&
            loginInfo.profile &&
            loginInfo.profile.actions &&
            true,
        numUnread
    }),
    {
        getNumUnread,
    }
)
export class HeaderNotification extends Component<
    any,
    HeaderNotificationState
> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            visible: false,
        };
    }

    componentDidMount(): void {
        if (this.props.actionLoaded) {
            this.props.getNumUnread();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (
            this.props.actionLoaded !== prevProps.actionLoaded &&
            this.props.actionLoaded
        ) {
            this.props.getNumUnread();
        }
    }

    render() {
        return (
            <div
                className="gl-header-right-item-dropdown"
                style={{
                    position: "relative"
                }}
            >
                <Badge count={this.props.numUnread}>
                    <div
                        className="gl-header-right-item"
                        onClick={() =>
                            this.setState({
                                visible: !this.state.visible
                            })
                        }
                    >
                        <i
                            id="notification-icon-header"
                            className="material-icons"
                        >
                            circle_notifications
                        </i>
                    </div>
                </Badge>
                {this.state.visible && (
                    <Notifications
                        onHide={() =>
                            this.setState({
                                visible: false
                            })
                        }
                        onGetNumUnread={() => this.props.getNumUnread()}
                    ></Notifications>
                )}
            </div>
        );
    }
}
