import { IProductService } from './interface';
import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode } from 'gl-commonui';
import { injectable } from '../../../util/di'
import { GSAdminLocale } from '../../../locales/localeid';

@injectable()
export class ProductService4Global extends GLServiceBase<any, any> {
    serviceRoute = { prefix: 'admin/v1/products/global', suffix: '{id}' };
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.GlobalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.GlobalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.GlobalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.GlobalProductCanNotBeDelete;
        return result;
    })();
}

enum ServerExceptionCode2 {
    ProductUnitPriceOverflowException = 4901,
    ProductUnitPriceUnitException = 4902,
    ProductUnitPriceUnitUsedException = 4903,
    ProductNameDuplicateException = 4904,
    ProductDisableException = 4905
}

@injectable()
export class ProductService4Local extends GLServiceBase<any, any> implements IProductService {
    serviceRoute = { prefix: 'admin/v1/products/local', suffix: '{id}' };
    productIdUrl = '{id}';
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.LocalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode2.ProductNameDuplicateException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.LocalProductNameDuplicate;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.LocalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode2.ProductUnitPriceOverflowException, `${this.serviceRoute.prefix}/${this.productIdUrl}`)] = GSAdminLocale.LocalProductPricesCountError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode2.ProductUnitPriceUnitException, `${this.serviceRoute.prefix}/${this.productIdUrl}`)] = GSAdminLocale.LocalProductPricesUnitError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode2.ProductUnitPriceUnitUsedException, `${this.serviceRoute.prefix}/${this.productIdUrl}`)] = GSAdminLocale.LocalProductPricesUnitError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode2.ProductNameDuplicateException, `${this.serviceRoute.prefix}/${this.productIdUrl}`)] = GSAdminLocale.LocalProductNameDuplicate;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode2.ProductDisableException, `${this.serviceRoute.prefix}/${this.productIdUrl}`)] = GSAdminLocale.LocalProductCanNotBeDisabled;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.LocalProductNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.LocalProductCanNotBeDelete;
        return result;
    })();
    getCandidateLocalProducts(regionId) {
        return this.get('region/{regionId}/candidates', null, { regionId });
    }
    getRegions() {
        return this.request.get('admin/v1/products/regions');
    }
}