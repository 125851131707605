import React, { Component } from 'react';
import "../component/progress.less";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IChatService, MESSAGE_REFRESH_TIME } from '@app/service/chat';
import { PathConfig } from '@app/config/pathconfig';
import { CustomDrawer } from '../../component/custom-drawer';
import { ChatBadge } from '@app/components/coach/chat/chat-badge';
import { SaveCancelBtns, GLRichTextEditor } from '@app/components';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { IAccountService } from '@app/service/admin/accountservice';
import { ChatDrawer } from '@app/components/coach/chat/chat-drawer';
import { Table, Row, Input, Checkbox, Button, Spin, Col, Modal, Select, Badge, Icon } from 'antd-min';
import { formatResponseAnswer, trimPlainText, isCoachAndSelf } from '@app/util/coach/fun';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { fmtMsg, lazyInject, TYPES, extendForm, textareaValider, GSSchoolAction, DateHelper, CONSTS } from '@app/util';
import { PreVisitationFormStatus, VisitationCoachStage, VisitationType, SurveyTodoType } from '@app/util/coach/enum';
import { GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, GLUtil, GLGlobal, RegionInfoModel } from 'gl-commonui';
import { renderQuestionList } from '@app/util/coach/common';
import { ISurveyService, SurveyToTargets } from '@app/service/survey/surveys';
import { debounce } from 'lodash';
import { IUserService } from "@app/service/users/index";
import { TeacherResponse } from "./teacher-response";
import { Collapse } from 'antd';
interface PrevisitationFormProps extends GLFormComponentProps {
    visitation: VisitationModel
    callback: (stage) => void
}
interface PrevisitationFormStates {
    visible?: boolean
    spinning?: boolean
    loading?: boolean
    isSending?: boolean
    status?: number
    recorder?: any
    teachers?: any[]
    lastFeedback?: any[]
    viewQuestions?: any[]
    messageVisible?: boolean
    receiver?: any
    chatBadgeCounts?: any
    submitDate?: Date
    modal: {
        sharedUser: { visible: boolean };
    };
    searchUser: any[];
    probablyShareUsers: any[],
    userShareFetching: boolean,
    notifyUsers: any[];
    formatRoleTargets: any[];
    checkNotifyList: boolean;
    searchShareUser: any[];
    searchTempShareUser: any[];
    surveyInstanceId: string | any,
    teacherResponseVisible: boolean;
    collapse: string[];
    selectedRegion: string;
}

@GLForm.create()
export class PrevisitationForm extends Component<PrevisitationFormProps, PrevisitationFormStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IChatService)
    chatservice: IChatService
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    @lazyInject(TYPES.ISurveyService)
    surveyService: ISurveyService
    userShareSelectSearch;
    lastShareFetchId = 0;
    loggedInUserId: string;
    creatorSharedUsers = { initValueSaved: false, value: [] };
    teachersResponded: { [teacherId: string]: { status: PreVisitationFormStatus; record: any; } } = {};
    accessibleRegions: RegionInfoModel[];

    constructor(props) {
        super(props);
        const debounceTime = 800;
        this.state = {
            visible: false,
            spinning: false,
            loading: false,
            lastFeedback: [],
            viewQuestions: [],
            teachers: [],
            isSending: false,
            messageVisible: false,
            modal: {
                sharedUser: { visible: false },
            },
            searchUser: [],
            probablyShareUsers: [],
            notifyUsers: [],
            formatRoleTargets: [],
            checkNotifyList: false,
            searchShareUser: [],
            searchTempShareUser: [],
            userShareFetching: false,
            surveyInstanceId: null,
            teacherResponseVisible: false,
            collapse: ["1"],
            selectedRegion: GLGlobal.loginInfo().profile.regionInfo && GLGlobal.loginInfo().profile.regionInfo.length > 0 ? GLGlobal.loginInfo().profile.regionInfo[0].id : null
        }
        this.userShareSelectSearch = debounce(searchUser => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            this.onShareUserSelectSearch(searchUser.trim());
        }, debounceTime);
        this.accessibleRegions = GLGlobal.loginInfo().profile.regionInfo || [];
        this.onRegionChange = this.onRegionChange.bind(this);
    }

    flushMessages: any;
    componentDidMount() {
        this.loggedInUserId = GLGlobal.loginInfo().profile.sub;
    }
    componentWillMount() {
        this.getPrevisitationForm();
    }
    componentWillUnmount() {
        clearInterval(this.flushMessages);
    }
    showDrawer(status, recorder) {
        this.setState({
            status,
            recorder,
            visible: true,
        });
        this.getInfosShowDrawer(status, recorder);
    }
    showTeacherResponse(status, recorder) {
        this.setState({
            status,
            recorder,
            collapse: [],
            teacherResponseVisible: true,
        });
        this.getInfosTeacherResponse(recorder);
    }
    getInfosTeacherResponse(recorder) {
        this.viewResponse(recorder);
    }
    onRegionChange(value) {
        this.setState({ selectedRegion: value });
    }
    getRegionAndTenantInfo() {
        const regionInfo = this.accessibleRegions.find(x => x.id == this.state.selectedRegion);
        let regionId = CONSTS.EmptyGuid;
        let tenantId = null;
        if (regionInfo) {
            regionId = regionInfo.id;
            tenantId = regionInfo.tenantId;
        }

        return { regionId, tenantId };
    }
    getInfosShowDrawer(status, recorder) {
        switch (status) {
            case PreVisitationFormStatus.Initialized:
                this.getLastFeedback(recorder);
                break;
            case PreVisitationFormStatus.Completed:
                this.viewResponse(recorder);
                break;
        }
    }
    getPrevisitationForm() {
        const { visitation: { id } } = this.props;
        this.setState({ loading: true });
        this.visitationservice
            .getCoachPrevisitationForm({ visitationId: id })
            .then(data => {
                if (data && data.preVisitationInfos) {
                    this.getTeacherNames(data.preVisitationInfos);
                    const teacherIds = data.preVisitationInfos.map(v => v.teacherId);
                    this.getAllUnReadBadges(teacherIds, id);

                    this.flushMessages = setInterval(() => this.getAllUnReadBadges(teacherIds, id), MESSAGE_REFRESH_TIME);
                }
            });
    }
    getTeacherNames(infos) {
        const ids = infos.map(v => v.teacherId);
        this.accountservice.getUsersByPost({ ids }).then(data => {
            const formatInfos = infos.map((info, key) => {
                const teacher = data && data.data && data.data.find(t => t.id == info.teacherId);
                const name = teacher && teacher.name;
                return {
                    key,
                    name,
                    ...info,
                }
            }).sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            this.setState({
                loading: false,
                teachers: formatInfos
            });
        });
    }
    getLastFeedback(recorder) {
        const { visitation } = this.props;
        const params = {
            visitationId: visitation.id,
            teacherId: recorder && recorder.teacherId
        }
        this.setState({ spinning: true });
        return this.visitationservice.getPrevisitationLastFeedback(params)
            .then(data => {
                this.setState({
                    spinning: false,
                    lastFeedback: data ? data.surveyQuestion : []
                });
            });
    }
    viewResponse(recorder) {
        const { preVisitationForm: { surveyInstanceId } } = recorder;
        const { visitation: { id } } = this.props;
        this.setState({ spinning: true });
        const params = {
            surveyInstanceId,
            visitationId: id,
            type: SurveyTodoType.ReadOnlyShared
        }
        this.visitationservice.viewSurveyReponse(params).then(data => {
            this.setState({
                spinning: false,
                viewQuestions: data && data.surveyQuestion,
                submitDate: data && data.submitDate,
                surveyInstanceId: data && data.surveyInstanceId
            }, () => this.getSurveyInstanceDetail(surveyInstanceId));
        });
    }
    ignoreTeacher(e, recorder) {
        const { visitation: { id, type } } = this.props;
        const { teacherId, preVisitationForm } = recorder;
        const status = e.target.checked ? PreVisitationFormStatus.IgnoreInitialized :
            PreVisitationFormStatus.Initialized;
        const params = {
            status,
            teacherId,
            visitationId: id,
            visitationType: type,
            previsitationFormId: preVisitationForm && preVisitationForm.id,
        }
        this.visitationservice.ignorePrevisitationTeacherStatus(params).then(data => {
            const tempTableData = this.state.teachers.map((v, k) => {
                if (v.teacherId == recorder.teacherId) {
                    v.preVisitationForm = {
                        status: status
                    };
                }
                return v;
            });
            this.setState({
                teachers: tempTableData
            });
        });
    }
    getPreTableCols() {
        const { visitation: { id, stage, regionId, schoolId, coachId } } = this.props;
        const isLastStageOrCompleted = stage == VisitationCoachStage.Completed ||
            stage == VisitationCoachStage.SchoolReview;
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeacher }),
                dataIndex: "name",
                width: "30%",
                render: (text, recorder, index) => {
                    const { teacherId } = recorder;
                    const { chatBadgeCounts } = this.state;
                    const count = chatBadgeCounts && chatBadgeCounts[teacherId] ? chatBadgeCounts[teacherId] : 0;
                    return <div>
                        {isCoachAndSelf(GSSchoolAction.TeacherLink, coachId) ?
                            <Link to={{ pathname: GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId }) }} >
                                <span className="teacher-name">{text}</span>
                            </Link> : <span>{text}</span>
                        }
                        {isCoachAndSelf(GSSchoolAction.Chat, coachId) &&
                            stage !== VisitationCoachStage.Completed &&
                            <ChatBadge
                                count={count}
                                handleClick={() => this.sendMessage(recorder)}
                                label="previsitation-chat"
                            />
                        }
                    </div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationClasses }),
                dataIndex: "schoolClasses",
                width: "30%",
                render: (text, recorder, index) => {
                    return text.map((v, k) => {
                        const { regionId, schoolId, campusId, schoolClassId } = v;
                        return <div key={k} title={v.schoolClassName} className="class-name">
                            {isCoachAndSelf(GSSchoolAction.ClassLink, coachId) ?
                                <Link to={{ pathname: GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: schoolClassId }) }} >
                                    <div className="class-list">{v.schoolClassName}</div>
                                </Link> :
                                <div>{v.schoolClassName}</div>
                            }
                        </div>
                    });
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationStatus }),
                dataIndex: "",
                width: "20%",
                render: (text, recorder, index) => {
                    const status = !recorder.preVisitationForm ? PreVisitationFormStatus.Initialized :
                        recorder.preVisitationForm.status;
                    return <div>{this.preVisitationFormStatusTips(status)}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationAction }),
                dataIndex: "preVisitationForm",
                width: "20%",
                render: (text, recorder, index) => {
                    const status = !text ? PreVisitationFormStatus.Initialized : text.status;
                    const isPreformCompleted = status != PreVisitationFormStatus.Completed;
                    const action = !text ? GSSchoolAction.SendPrevisitationForm : this.formatAction(text.status);
                    const isCoach = isCoachAndSelf(action, coachId);
                    const isDrawer = (status != PreVisitationFormStatus.IgnorePending) &&
                        (status != PreVisitationFormStatus.IgnoreInitialized) &&
                        (status != PreVisitationFormStatus.Completed) && isCoach;
                    const isResponse =
                        (status === PreVisitationFormStatus.Completed) && isCoach;
                    const teacherId = text ? text.teacherId : null;
                    return <div className={`form-status ${isLastStageOrCompleted && isPreformCompleted && "disabled"}`}>
                        {isDrawer &&
                            <div onClick={() => this.showDrawer(status, recorder)}>
                                {this.previsitationFormstatusText(status, recorder, teacherId)}
                            </div>
                        }
                        {isResponse &&
                            <div onClick={() => this.showTeacherResponse(status, recorder)}>
                                <a href="#response">{this.previsitationFormstatusText(status, recorder, teacherId)}</a>
                            </div>
                        }
                    </div>
                }
            },
        ]
    }

    formatAction(status) {
        switch (status) {
            case PreVisitationFormStatus.Pending:
                return GSSchoolAction.SendReminder;
            case PreVisitationFormStatus.Completed:
                return GSSchoolAction.ViewResponse;
            case PreVisitationFormStatus.Initialized:
            case PreVisitationFormStatus.IgnoreInitialized:
            case PreVisitationFormStatus.IgnorePending:
                return GSSchoolAction.SendPrevisitationForm;
        }
    }
    previsitationFormstatusText(status, record, teacherId) {
        switch (status) {
            case PreVisitationFormStatus.Initialized:
                return fmtMsg({ id: SchoolLocale.VisitationSendForm });
            case PreVisitationFormStatus.Pending:
                return fmtMsg({ id: SchoolLocale.VisitationSendReminder });
            case PreVisitationFormStatus.Completed:
                // save completed, to show records on up and down
                teacherId ? this.teachersResponded[teacherId] = { status, record } : null;
                return fmtMsg({ id: SchoolLocale.VisitationViewResponseText });
        }
    }
    preVisitationFormStatusTips(status) {
        switch (status) {
            case PreVisitationFormStatus.Initialized:
                return fmtMsg({ id: SchoolLocale.VisitationYetToSend });
            case PreVisitationFormStatus.Pending:
                return fmtMsg({ id: SchoolLocale.VisitationPending });
            case PreVisitationFormStatus.IgnoreInitialized:
            case PreVisitationFormStatus.IgnorePending:
                return fmtMsg({ id: SchoolLocale.VisitationIgnoredText });
            case PreVisitationFormStatus.Completed:
                return fmtMsg({ id: SchoolLocale.VisitationReplied });
        }
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "note").concat(this.lengthValidator(SchoolLocale.MaterialRequestFieldNote, 500));
    }

    drawerContent() {
        const { status, spinning, isSending, recorder, lastFeedback, viewQuestions } = this.state;
        const { form } = this.props;
        const { renderFormItem } = FormHelper;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: 'checked' }
        }
        const reminder = recorder && fmtMsg({ id: SchoolLocale.VisitationPrevisitReminderContent }, { name: recorder.name });
        switch (status) {
            case PreVisitationFormStatus.Initialized:
                return <>
                    <GLForm form={form}>
                        <div className="previsitation-content">
                            <Row className="last-feedback">
                                <Spin spinning={spinning}>
                                    <Row>{fmtMsg({ id: SchoolLocale.VisitationLastFeedback })}</Row>
                                    <div className="question-list">
                                        {!!lastFeedback.length && renderQuestionList(lastFeedback)}
                                    </div>
                                </Spin>
                            </Row>
                            <FormItemsLayout>
                                {renderFormItem({ ...form, ...options }, SchoolLocale.VisitationFormStatus, "IncludeLastVisitsFeedback",
                                    <Checkbox>{fmtMsg({ id: SchoolLocale.VisitationIncludeLastVisit })}</Checkbox>, true)}
                            </FormItemsLayout>
                            <Row className="note">
                                {renderFormItem(extendForm(form), SchoolLocale.VisitationQuestionnaireNote, "note",
                                    <Input.TextArea rows={5} />, "", false, this.lengthValidator(SchoolLocale.MaterialRequestFieldNote, 2000))}
                            </Row>
                        </div>
                        <div className={`send-form-btns ${isSending && "disabled"}`}>
                            <SaveCancelBtns saveTitle={SchoolLocale.VisitationSendText}
                                onSubmit={() => this.postPrevisitation()}
                                onCancel={() => this.onClose()} />
                        </div>
                    </GLForm>
                </>;
            case PreVisitationFormStatus.Pending:
                return <>
                    <GLForm form={form}>
                        <div className="send-reminder">
                            <Row className="note">
                                {renderFormItem(extendForm(form), SchoolLocale.VisitationNoteText, "note",
                                    <Input.TextArea rows={6} />, reminder, true, this.noteValidator(form))}
                            </Row>
                        </div>
                        <SaveCancelBtns saveTitle={SchoolLocale.VisitationSendText}
                            onSubmit={() => this.SendReminder()}
                            onCancel={() => this.onClose()} />
                    </GLForm>
                </>;
        }
    }
    postPrevisitation() {
        const { visitation: { id } } = this.props;
        const { recorder, teachers } = this.state;
        const { form } = this.props;
        form.validateFields((error, values) => {
            if (error) return;
            const params = {
                includeLastFeedback: values.IncludeLastVisitsFeedback,
                status: PreVisitationFormStatus.Pending,
                note: trimPlainText(values.note),
                visitationId: id,
                teacherId: recorder.teacherId
            }
            this.setState({ isSending: true });
            this.visitationservice.postCoachPrevisitation(params).then(data => {
                form.resetFields();
                const formatTeachers = teachers.map(v => {
                    if (v.teacherId == recorder.teacherId) {
                        v.preVisitationForm = {
                            id: data && data.id,
                            status: PreVisitationFormStatus.Pending
                        };
                    }
                    return v;
                });
                this.setState({
                    visible: false,
                    isSending: false,
                    teachers: formatTeachers
                });
            }).catch(() => {
                this.setState({ isSending: false });
            });
        });
    }
    SendReminder() {
        const { recorder: { teacherId } } = this.state;
        const { form, visitation: { id } } = this.props;
        form.validateFields((error, values) => {
            if (error) return;
            values.note = trimPlainText(values.note);
            const params = {
                teacherId,
                visitationId: id,
                ...values
            }
            this.visitationservice.sendReminder(params).then(data => {
                form.resetFields();
                this.setState({
                    visible: false,
                });
            });
        });
    }
    onClose() {
        const { form } = this.props;
        const { viewQuestions } = this.state;
        const clearAnswers = viewQuestions.map(v => {
            v.surveyResponseAnswer = [];
            return v;
        });
        form.resetFields();
        this.setState({
            visible: false,
            lastFeedback: [],
            viewQuestions: clearAnswers,
            messageVisible: false
        });
    }
    onTeacherResponseClose = () => {
        const { form } = this.props;
        const { viewQuestions } = this.state;
        const clearAnswers = viewQuestions.map(v => ({ ...v, surveyResponseAnswer: [] }));
        form.resetFields();
        this.setState({
            viewQuestions: clearAnswers,
            messageVisible: false,
            teacherResponseVisible: false,
            collapse: ["1"]
        });
    }
    goToNextStage() {
        const { visitation: { id, type }, callback } = this.props;
        const stage = type == VisitationType.OnSite ?
            VisitationCoachStage.VisitSchool : VisitationCoachStage.VideoManager;
        const params = {
            visitationId: id,
            visitationStage: {
                id,
                stage
            }
        }
        this.visitationservice.preVisitationFromMoveNext(params).then(data => {
            callback(stage);
        });
    }
    sendMessage(recorder) {
        this.setState({
            receiver: recorder,
            messageVisible: true
        });
    }
    getAllUnReadBadges(senderIds, visitationId) {
        this.chatservice.getAllUnReadBadges(senderIds, visitationId).then(data => {
            this.setState({
                chatBadgeCounts: data
            });
        });
    }
    getBadgeCount(id) {
        this.chatservice.getBadgeCount(id).then(data => {
            let counts = this.state.chatBadgeCounts;
            counts[id] = data;
            this.setState({
                chatBadgeCounts: counts
            });
        });
    }

    getSurveyInstanceDetail(selectedSurveyInstance: string) {
        this.surveyService
            .surveyInstanceDetail({ surveyInstanceId: selectedSurveyInstance })
            .then(({ surveyInstance, surveyInstanceCount }: { surveyInstance: any; surveyInstanceCount: number }) => {
                this.setState(
                    {
                        searchShareUser: surveyInstance.surveyShare.map(share => ({ key: share.userId }))
                    }
                );
            });
    }

    checkNotifyList(surveyType) {
        var pass = true;
        if (surveyType === SurveyToTargets.Roles && this.state.formatRoleTargets.length == 0) {
            pass = false;
        } else if (surveyType === SurveyToTargets.Users && this.state.notifyUsers.length == 0) {
            pass = false;
        }
        this.setState({ checkNotifyList: !pass });
        return pass;
    }

    checkNotifyListCallBack() {
        this.checkNotifyList({
            surveyType: this.props.form.getFieldValue("surveyType")
        });
    }

    onShareUserSelectSearch(searchShareUser) {
        this.lastShareFetchId += 1;
        const fetchId = this.lastShareFetchId;
        const regionInfo = this.getRegionAndTenantInfo();
        this.setState({ probablyShareUsers: [], userShareFetching: true });
        this.userService.getSubordinates({ userName: searchShareUser, email: searchShareUser, regionId: regionInfo.regionId, tenantId: regionInfo.tenantId }).then(d => {
            if (fetchId !== this.lastShareFetchId) return;
            const probablyShareUsers = d
                .map(user => ({
                    id: user.id,
                    name: user.name,
                    email: user.email
                }))
                .filter(user => this.loggedInUserId != user.id && !this.state.searchShareUser.some(su => su.key === user.id));
            this.setState({
                probablyShareUsers,
                userShareFetching: false
            });
        });
    }

    onShareUserSelectChange(searchTempShareUser) {
        this.setState({
            searchTempShareUser,
            probablyShareUsers: [],
            userShareFetching: false
        });
    }

    renderSelectUserModal() {
        const hide = () => {
            this.creatorSharedUsers = { initValueSaved: false, value: [] };
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchTempShareUser: [...searchShareUser],
                selectedRegion: this.accessibleRegions.length > 0 ? this.accessibleRegions[0].id : null
            });
        }
        const save = () => {
            const userIds = this.state.searchTempShareUser.map(shareUser => shareUser.key);
            const surveyId = this.state.viewQuestions[0].surveyId;
            const surveyInstanceId = this.state.surveyInstanceId;
            const params = {
                surveyId: surveyId,
                shareUserIds: userIds,
                surveyInstanceId: surveyInstanceId
            }
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchShareUser: [...searchTempShareUser]
            }, () => this.surveyService.shareSurvey(params));
        }
        const { userShareFetching, probablyShareUsers, searchShareUser, searchTempShareUser, selectedRegion } = this.state;
        if (!this.creatorSharedUsers.initValueSaved && searchTempShareUser.length) {
            this.creatorSharedUsers.value = JSON.parse(JSON.stringify(searchTempShareUser));
        }

        const fmtEmail = ({ name, email }) => `${name} ${email ? `(${email})` : ""}`;
        return (
            <Modal
                title={fmtMsg({
                    id: GSAdminLocale.NotificationCreateSearchName
                })}
                visible={this.state.modal.sharedUser.visible}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSubmit })}
                okButtonProps={{ disabled: (searchTempShareUser.length === 0 && !this.creatorSharedUsers.value.length) }}
                maskClosable={false}
                className="notified-user-modal"
                destroyOnClose
                onOk={() => {
                    save();
                }}
                onCancel={() => hide()}
            >
                {this.accessibleRegions.length > 1 && <div className="region-section">
                    <p>
                        <label htmlFor="Region" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRegion
                            })}
                        </label>
                    </p>
                    <Select value={selectedRegion} showSearch={true} onChange={this.onRegionChange}
                        filterOption={
                            (input, option) => {
                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                        }>

                        {this.accessibleRegions.map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.englishName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>}
                <Select
                    mode="multiple"
                    labelInValue
                    style={{ width: "100%" }}
                    value={searchTempShareUser}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={userShareFetching ? <Spin size="small" /> : null}
                    onSearch={d => this.userShareSelectSearch(d)}
                    onChange={this.onShareUserSelectChange.bind(this)}
                >
                    {probablyShareUsers.map(d => (
                        <Select.Option key={d.id} title={fmtEmail(d)}>
                            {fmtEmail(d)}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
        );
    }
    visibleModal = (key) => {
        const modal = { ...this.state.modal };
        modal[key].visible = true;

        if (key === "sharedUser" && !this.state.searchShareUser.some(x => !!x.label)) {
            let promises = [];
            const users = JSON.parse(JSON.stringify(this.state.searchShareUser.map(share => share.key)));

            while (users.length > 0) {
                promises.push(this.userService.getItemsBy({ ids: users.splice(0, 150) }));
            }
            Promise.all(promises).then((d: any[]) => {
                this.setState({
                    searchShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        }),
                    searchTempShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        }), modal
                });
            });
        }
        else {
            this.setState({ modal });
        }
    }

    onPanelChange = () => {
        const activeKey = this.state.collapse.length ? [] : ["1"];
        this.setState({ collapse: activeKey });
    }

    moveToResponse = (up: boolean, move: boolean = true) => {
        const { teachers, recorder } = this.state;
        const completedTeachers = Object.keys(this.teachersResponded);
        const completedTeacherOrdered = teachers.filter(t => completedTeachers.some(s => s === t.teacherId));
        const currentTeacherIndex = completedTeacherOrdered.findIndex(f => f.teacherId === recorder.teacherId);

        if (up && currentTeacherIndex > 0) {
            if (move) {
                const nextTeacher = completedTeacherOrdered[currentTeacherIndex - 1];
                const nextRecord = this.teachersResponded[nextTeacher.teacherId];
                this.showTeacherResponse(nextRecord.status, nextRecord.record);
            }
            return true;
        } else if (!up && currentTeacherIndex < completedTeachers.length - 1) {
            if (move) {
                const nextTeacher = completedTeacherOrdered[currentTeacherIndex + 1];
                const nextRecord = this.teachersResponded[nextTeacher.teacherId];
                this.showTeacherResponse(nextRecord.status, nextRecord.record);
            }
            return true;
        }
        return false;
    }

    renderDrawerTitle() {
        const { status, submitDate, recorder } = this.state;
        const formatSubmitDate = DateHelper.toLocalStringFromUTC(moment(submitDate));
        let badgeCount = this.state.searchShareUser.length ? this.state.searchShareUser.length : 0
        switch (status) {
            case PreVisitationFormStatus.Initialized:
                return <h3>{fmtMsg({ id: SchoolLocale.VisitationSendPrevisitationForm })}</h3>;
            case PreVisitationFormStatus.Pending:
                return <h3>{fmtMsg({ id: SchoolLocale.VisitationPrevisitReminderTitle })}</h3>;
        }
    }
    render() {
        const Panel = Collapse.Panel;
        const { visible, loading, messageVisible, receiver, teachers, teacherResponseVisible, collapse } = this.state;
        const { visitation: { id, stage, coachId, startDate, schoolName, campusName } } = this.props;
        const visitationStartDate = startDate && DateHelper.toLocalStringFromUTC(startDate);
        const disabled = stage > VisitationCoachStage.PrevisitationForm;
        return <div className={`previsitation-form info-container ${disabled && "disabled"}`}>
            <div>{fmtMsg({ id: SchoolLocale.VisitationSendPrevisitationForm })}</div>
            <Collapse
                accordion={true}
                activeKey={collapse}
                onChange={this.onPanelChange}
                defaultActiveKey={["1"]}
                className='previsitation-form-trcollapse'
            >
                <Panel header={fmtMsg({ id: SchoolLocale.VisitationTeacherResponseHeader })} key="1" className="view-collapse-panel">
                    <Table
                        loading={loading}
                        className="coach-previsitation"
                        columns={this.getPreTableCols()}
                        dataSource={teachers}
                        pagination={false}
                    />
                </Panel>
            </Collapse>
            {!disabled && isCoachAndSelf(GSSchoolAction.MoveNext, coachId) &&
                <SaveCancelBtns
                    noCancelBtn={true}
                    saveTitle={SchoolLocale.VisitationMoveNextText}
                    onSubmit={() => this.goToNextStage()}>
                </SaveCancelBtns>}

            {teacherResponseVisible &&
                <TeacherResponse
                    {...this.state}
                    onTeacherResponseClose={this.onTeacherResponseClose}
                    renderDrawerTitle={this.renderDrawerTitle}
                    moveToResponse={this.moveToResponse}
                    visibleModal={this.visibleModal}
                    startDate={visitationStartDate}
                    schoolName={schoolName}
                    campusName={campusName}
                />
            }
            {visible &&
                <CustomDrawer
                    width={670}
                    visible={visible}
                    drawerTitle={this.renderDrawerTitle()}
                    onClose={() => this.onClose()}>
                    <div>{this.drawerContent()}</div>
                </CustomDrawer>}

            {
                receiver &&
                <ChatDrawer
                    visitationId={id}
                    receiver={{ id: receiver.teacherId, name: receiver.name }}
                    searchable={true}
                    title={fmtMsg({ id: GSAdminLocale.ChatHeader }, { name: receiver.name })}
                    visible={messageVisible}
                    onClose={() => this.onClose()}
                    getBadgeCount={this.getBadgeCount.bind(this)}
                />
            }
            {this.renderSelectUserModal()}
        </div>
    }
}
