import { ElementType } from 'react';

interface BaseMessage {
  type: string;
  component: ElementType;
  sender: string;
  from: string;
  showProfile: boolean;
  showTimeStamp: boolean;
  isSeries: boolean;
  timestamp: Date;
  unread: boolean;
  props?: any;
}

export interface Message extends BaseMessage {
  text: string;
}

export interface Link extends BaseMessage {
  title: string;
  link: string;
  target: string;
}

export interface LinkParams {
  link: string;
  title: string;
  target?: string;
}

export const MESSAGE_SENDER = {
  CLIENT: 'client',
  RESPONSE: 'response'
};

export const MESSAGES_TYPE = {
  MESSAGE: 'message',
  RESOURCE: 'resource'
}

export const MESSAGE_BOX_SCROLL_DURATION = 400;

export const createNewMessage = (
  id: string,
  text: string,
  sender: string,
  from: string,
  isSeries: boolean,
  sendAt?: Date
) => ({
  id,
  type: MESSAGES_TYPE.MESSAGE,
  text,
  sender,
  timestamp: sendAt ? sendAt : new Date(),
  from: from,
  showProfile: sender === MESSAGE_SENDER.RESPONSE,
  unread: sender === MESSAGE_SENDER.RESPONSE,
  isSeries: isSeries
});

export const createResource = (
  link: LinkParams,
  sender: string,
  from: string,
  isSeries: boolean,
  sendAt?: Date,
) => ({
  type: MESSAGES_TYPE.RESOURCE,
  title: link.title,
  link: link.link,
  target: link.target || '_blank',
  sender,
  timestamp: sendAt ? sendAt : new Date(),
  from,
  showProfile: sender === MESSAGE_SENDER.RESPONSE,
  unread: sender === MESSAGE_SENDER.RESPONSE,
  isSeries
});

export interface ReceiveMessage {
  id: number;
  sendAt: Date,
  isRead: boolean;
  message: string;
  chatGroupId: number;
  chatContextId: number;
  senderId: string;
  receiverId: string;
}

export interface UserMessagesInfo {
  userId: string;
  avatar?: string;
  userName: string;
  recentMessage: ReceiveMessage
  isSearched?: boolean
}