import {GLServiceBase, ServiceRoute} from "gl-commonui";
import {ITalkTimeService} from "@app/service/admin/talktime/interface";
import {TalkTimeEntitySetting, TalkTimeEntitySettingRequestModel} from "@app/service/admin/talktime/models";

export class TalkTimeService extends GLServiceBase<any, any> implements ITalkTimeService {
    serviceRoute: ServiceRoute = {prefix: 'admin/v1/talktime'}

    setTalkTimeSetting(entities: TalkTimeEntitySettingRequestModel[]): Promise<boolean> {
        return this.update("update", entities, null);
    }

    getSchoolsByRegion(regionId: any) {
        return this.get("schools-by-region/{regionId}", null, {regionId: regionId});
    }

    getCampusesBySchoolId(schoolId: any): Promise<TalkTimeEntitySetting[]> {
        return this.get("campuses-by-school/{schoolId}", null, {schoolId: schoolId});
    }

    getClassesByCampusId(campusId: any): Promise<TalkTimeEntitySetting[]> {
        return this.get("classes-by-campus/{campusId}", null, {campusId: campusId});
    }

    getStudentsByClassId(classId: any): Promise<TalkTimeEntitySetting[]> {
        return this.get("students-by-class/{classId}", null, {classId: classId});
    }
}
