import { GLServiceBase, getServiceMethodKey, HttpMethod, HttpStatusCode, ServerExceptionCode } from 'gl-commonui'
import { injectable } from '@app/util/di'
import { NotificationRequest, GetNotificationRequest, INotificationService, NotificationRoleTargetModel, NotificationModel } from './index';
import { GSAdminLocale } from '@app/locales/localeid';

@injectable()
export class NotificationService extends GLServiceBase<NotificationRequest, GetNotificationRequest> implements INotificationService {
    serviceRoute = { prefix: 'admin/v1/notifications', itemSuffix: '{id}' }
    //serviceRoute = { prefix: 'admin/v1/notifications', suffix: '{id}' }
    CanNotEditApprovedNotificationException = 4471;
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Put, this.CanNotEditApprovedNotificationException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.NotificationMsgUpdateError;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.UnAuthenticated, `${this.serviceRoute.prefix}`)] = GSAdminLocale.NotificationDeleteUnauthorizedError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.UnAuthenticated, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.NotificationUpdateUnauthorizedError;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.InvalidArgumentException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.NotificationRoleTargetsError;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidArgumentException, `${this.serviceRoute.prefix}`)] = GSAdminLocale.NotificationRoleTargetsError;
        return result;
    })();
    getTargetCount(targets: NotificationRoleTargetModel[]): any {
        return this.create('targetcount', targets);
    }
    review(targets: NotificationModel): Promise<void> {
        return this.update('{id}/reviewed', targets);
    }
    getDetail(id): Promise<any> {
        return this.get('{id}/detail', id);
    }
    createArchived(id): Promise<void> {
        return this.create('{id}/archived', { id });
    }
    getArchived(params): Promise<GetNotificationRequest> {
        return this.getItemsBy('archived', params)
    }
    getNotifications(params) {
        return this.request.get(this.url(""), params);
    }
    getRegions(params?): Promise<any> {
        return this.getItemsBy('regions', params)
    }
    countAllNotificationsUnRead(params) {
        return this.get('CountNotifications', params);
    }
    markAsReadForAll() {
        return this.create('MarkAsRead');
    }
    markAsReadForOne(notificationId) {
        return this.create('MarkAsRead', [notificationId]);
    }
    markAsReadForMultiple(notificationIds: Array<string>) {
        return this.create('MarkAsRead', notificationIds);
    }
    markAsArchived() {
        return this.create('MarkNotificationsAsArchived');
    }
    manageNotifications(params) {
        return this.get("managenotifications", params);
    }
    getNotificationStatistics(id) {
        return this.get("{id}/statistics", null, { id });
    }
}
