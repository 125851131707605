import { GLServiceBase, RoleName, HttpMethod, ServerExceptionCode, getServiceMethodKey, customError, GLLocale } from 'gl-commonui'
import { CONSTS, ContextHelper } from '@app/util';
import { injectable } from '@app/util/di'
import { IUserService, UserModel, GetUserRequest, UserRequest, UniquenessEmailAndPhoneRequestModel,
	UniquenessEmailAndPhoneResponseModel, VerifyCodeRequestModel, VerificationCodeValidationResult, CreateUserRequestModel } from './index';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { ServiceExceptionCode } from '@app/util/enum';
import { LoginIdType, VerificationCodeUsage } from '@app/util/enum';

@injectable()
export class UserService extends GLServiceBase<UserRequest, GetUserRequest> implements IUserService {
    serviceRoute = { prefix: 'account/v1/users', itemSuffix: '{id}' }
    adminServiceRoute = {prefix: 'admin/v1/schoolclass'}
    bindingUserByEmailUrl = '{email}/binding';
    bindingUserByIdUrl = '{id}/binding';
    redeemCodeUrl = "binding";
    InvalidVerificationCodeException = 800;
    //serviceRoute = { prefix: 'account/v1/users', suffix: '{id}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceUserNotFound;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = GSAdminLocale.ServiceUserEmailInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetHasExistedException)] = GSAdminLocale.ServiceUserEmailExist;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException)] = GSAdminLocale.ServiceUserCodeUsed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidArgumentException)] = GSAdminLocale.PasswordInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException)] = GSAdminLocale.ServiceUserPwdSix;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.itemSuffix}`)] = GSAdminLocale.ServiceUserNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceUserNotFound;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = { id: GSAdminLocale.ErrorDeleteForTargetUsed, values: { name: 'user' } };
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvitationCodeNotExistException)] = { id: GLLocale.HttpCodeNotExist };

        result[getServiceMethodKey(this, HttpMethod.Post, this.InvalidVerificationCodeException)] = GSAdminLocale.InvalidVerificationCode;

        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = SchoolLocale.InvitationAccountNull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.bindingUserByIdUrl}`)] = SchoolLocale.InvitationAccountNull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.bindingUserByIdUrl}`)] = SchoolLocale.InvitationDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException)] = SchoolLocale.InvitationValidErrMsgCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.bindingUserByEmailUrl}`)] = SchoolLocale.InvitationDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.bindingUserByEmailUrl}`)] = SchoolLocale.InvitationDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServiceExceptionCode.TeacherAlreadyExistsInClassException, `${this.serviceRoute.prefix}/${this.bindingUserByEmailUrl}`)] = SchoolLocale.TeaherAlreadyExists;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = SchoolLocale.EmailInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetHasExistedException)] = SchoolLocale.EmailHasExisted;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException)] = SchoolLocale.CodeHasBeenUsed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidArgumentException)] = SchoolLocale.PasswordInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.InvitationFailed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvalidMaterialOrderException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.InvalidNameOrPassword;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.InvitationDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.NoUser;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.CodeHasBeenUsed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.InvitationFailed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvitationCodeNotExistException, `${this.serviceRoute.prefix}/${this.redeemCodeUrl}`)] = SchoolLocale.InvitationHasBeenRemoved;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.InvitationCodeNotExistException)] = { id: SchoolLocale.InvitationHasBeenRemoved };
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = SchoolLocale.InvitationAccountNull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetDisabledException)] = GSAdminLocale.ServiceRegionDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServiceExceptionCode.SchoolDisabledException)] = GSAdminLocale.ServiceInviteSchoolDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServiceExceptionCode.CampusDisabledException)] = GSAdminLocale.ServiceInviteCampusDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServiceExceptionCode.ClassDisabledException)] = GSAdminLocale.ServiceInviteClassDisabled;
        return result;
    })();
    formatUserList(users: UserModel[], regionName: string) {
        return users.map(user => {
            user.role = ContextHelper.sortUserRoles(user.roles.map(role => role as RoleName))
                .map(sortedRole => CONSTS.Role.get(sortedRole)).join(', ');
            user.region = regionName
            user.childrenList = user.children != null ? user.children.map(c => c).join(', ') : null
            return user;
        });
    }
    getUserById(params: any) {
        return this.get('{id}', params);
    }
    getById(params:any) {
        return this.get('{id}', params);
    }
    getUserAdmins(params: any) {
        return this.get('admins', params);
    }
    updateUser(params: any) {
        return this.update('{id}', params);
    }
    registerUser(params: any) {
        return this.request.post(this.url(''), params).catch(customError(this, HttpMethod.Post));
    }
    addRole(params: any) {
        return this.request.post(this.url('{id}/roles/{rolename}'), params);
    }
    removeRole(params: any) {
        return this.delete('{id}/roles/{rolename}', params);
    }
    removeBy(rolename, id) {
        return this.delete('{id}/roles/{rolename}', null, { id, rolename })
    }
    confirm(email, data) {
        return this.get('{email}/confirmation', data, { email })
    }
    bindBy(email, data, callback?) {
        return this.create(this.bindingUserByEmailUrl, data, { email }).catch(response => {
            callback && callback();
            throw response;
        })
    }
    bindById(params: any) {
        return this.create(this.bindingUserByIdUrl, params);
    }
    newUser(params: CreateUserRequestModel) {
        return this.request.post(this.url(''), params).catch(customError(this, HttpMethod.Post));
    }
    redeemCode(params: any) {
        return this.create(this.redeemCodeUrl, params);
    }
    getUsers(params: any) {
        return this.request.get(this.url(''), params);
    }
    getUsersByPost(data: any) {
        return this.create("getUsersByIds", data);
    }
    getUsersByPostWithoutLoader(data: any) {
        return this.createWithoutLoader("getUsersByIds", data);
    }
    getSubordinates(params: any) {
        return this.request.get(this.url('GetSubordinates'), params);
    }
    getUserAvatarSasUri(params: any) {
        return this.request.get(this.url('{id}/getuseravatarsasuri/{expirationminutes}'), params);
    }
    isAllowedToImpersonate(userId: any) {
        return this.get('IsAllowedToImpersonate', { userId });
    }
    isUserAccessible(userId: string) {
        return this.get('IsUserAccessible', { userId })
    }
    getVerificationCodes(receiver: string, receiverType: LoginIdType, invitationCode: string): Promise<number> {
        const email = receiverType == LoginIdType.Email ? receiver : null;
        const phone = receiverType == LoginIdType.Phone ? receiver : null;
        const usage = VerificationCodeUsage.Registration;
		return this.request.post("account/v1/verificationcodes", {email, phone, receiverType, usage, invitationCode});
	}
    getUsersList(params:any) {
        return this.request.get(this.url('list'), params);
    }
    getParentChildrenList(params:any) {
        return this.request.get(this.url('getParentsAndChildren'), params);
    }
	checkEmailOrPhoneUniqeness (data: UniquenessEmailAndPhoneRequestModel): Promise<UniquenessEmailAndPhoneResponseModel> {
        return this.createWithoutLoader("registration/validation", data, null);
    }
    verifyVerificationCodes(data: VerifyCodeRequestModel): Promise<VerificationCodeValidationResult> {
        return this.createWithoutLoader("~account/v1/verificationcodes/{codeId}/validation", data, {codeId: data.codeId});
    }
    getRemoteTsiPermission(): Promise<boolean> {
        return this.request.get(this.adminServiceRoute.prefix+`/HasConnectAccess`);
    }
    grantGSAccess(params: any): any {
        return this.request.put(this.url(`GrantGSAccess/${params.userId}`), params.gsConnectAccess);
    }
    savePrivacyPolicyAcceptanceInfo(params: any): any {
        return this.createWithoutLoader('SavePrivacyPolicyAcceptanceInfo', params);
    }
}
