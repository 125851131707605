import { GLServiceBase, PrimaryLanguageLocale } from "gl-commonui";
import request from "superagent";

export class LocaleService extends GLServiceBase<any, any> {
    serviceRoute = null;

    getLocaleJson(locale: PrimaryLanguageLocale): Promise<any> {
        if (process.env.translationsFromLocalizer) {
            const url = process.env.translationsFromLocalizer.replace("{locale}", locale) + "?v=" + new Date().getTime();
            return request.get(url).then(res => JSON.parse(res.text));
        }
        /** fallback to load the local JSON */
        return Promise.reject();

    }

}