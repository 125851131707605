import * as React from "react";
import { Icon, Progress, Tooltip, Typography } from "antd-min";
import { TooltipProps } from "antd/lib/tooltip";
import { fmtMsg, mergeClasses, IconFont } from "@app/util";
import { TypographyProps } from "antd/lib/typography/Typography";
import { GLGlobal, MatIcon } from "gl-commonui";
import { GLLink } from "@app/components/gl-link";
import "./action.less";
import { NavLinkProps } from "react-router-dom";

const { Text } = Typography;

interface ActionsProps extends React.Props<ActionsProps> {
    actions?: JSX.Element[];
    noHeight?: boolean;
    className?: string;
    action?: string;
}

export const Actions: React.FunctionComponent<ActionsProps> = (props: ActionsProps) => {
    const { actions, children, noHeight, className, action } = props;

    const hasPermission = !action || GLGlobal.isActionValid(action);
    if (!hasPermission) {
        return null;
    }

    return (
        <div className={mergeClasses("tw-actions", noHeight && "no-height", className)}>
            {actions && actions}
            {children && children}
        </div>
    );
};

interface ActionProps {
    materialIcon?: string;
    icon?: string;
    iconFont?: string;
    textLocaleId?: string;
    onClick?(event: React.FormEvent): void;
    tooltip?: string | JSX.Element;
    tooltipOptions?: TooltipProps;
    disabled?: boolean;
    className?: string;
    progress?: number;
    textProps?: TypographyProps;
    action?: string;
    linkProps?: NavLinkProps
}

export const Action: React.FunctionComponent<ActionProps> = (props) => {
    const { materialIcon, icon, iconFont, textLocaleId, onClick, tooltip, tooltipOptions, disabled, textProps, className, progress, action, children, linkProps } = props;
    const renderActionChildren = () => <>
        {materialIcon && <MatIcon type={materialIcon} />}
        {icon && <Icon type={icon} />}
        {iconFont && <IconFont type={iconFont} />}
        {progress && (
            <Progress
                type="circle"
                width={25}
                strokeWidth={10}
                percent={progress}
                showInfo={progress === 100}
                status={progress === 100 ? "success" : "active"}
            />
        )}
        {textLocaleId && (
            <Text className="tw-action-text" {...textProps}>
                {fmtMsg({ id: textLocaleId })}
            </Text>
        )}
        {children && children}
    </>
    const renderAction = () => (
        linkProps
            ? <GLLink
                {...linkProps}
                className={mergeClasses(
                    "tw-action-item",
                    className,
                    disabled && "tw-action-item-disabled",
                    onClick && "cursor-pointer",
                    textLocaleId && "has-text"
                )}
            >
                {renderActionChildren()}
            </GLLink>
            : <div
                className={mergeClasses(
                    "tw-action-item",
                    className,
                    disabled && "tw-action-item-disabled",
                    onClick && "cursor-pointer",
                    textLocaleId && "has-text"
                )}
                onClick={onClick}
            >
                {renderActionChildren()}
            </div>
    );

    const hasPermission = !action || GLGlobal.isActionValid(action);
    if (!hasPermission) {
        return null;
    }

    return tooltip ? (
        <Tooltip title={tooltip} {...tooltipOptions}>
            {renderAction()}
        </Tooltip>
    ) : (
            renderAction()
        );
};
