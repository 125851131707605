import { RoleName, PrimaryLanguage, maskMain, maskThrottle, unmaskThrottle, Role } from 'gl-commonui';
import { PathConfig } from '../config/pathconfig';
import { InvitationTemplateModel, InvitationTemplateService, InvitationTemplate4PreviewModel } from '@app/service/invitation-template/index';
import { InvitationHelper, ContextHelper, CONSTS, InvitationType, getImageDimensions, TemplateBackgroundImageLayout, TemplateTagCode, InvitationChannel } from '@app/util';
import { RegionSchoolRoleModel, RegionService } from '@app/service/admin/regions';
import { ResourceService } from '@app/service/resources';

export interface InvitationTemplateState {
    model: InvitationTemplateModel
    list: InvitationTemplateModel[]
    total: number
    activeCnt: number
    regions: RegionSchoolRoleModel[]
    template4Preview: InvitationTemplate4PreviewModel
}
interface Services {
    invitationTemplate: InvitationTemplateService
    resource: ResourceService
    region: RegionService
}

export default {
    namespace: 'invitationTemplate',
    state: {
        model: {},
        list: [],
        total: 0,
        activeCnt: 0,
        regions: [],
        template4Preview: {}
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        resetTemplate4Preview(state, action) {
            return { ...state, template4Preview: {} };
        }
    },
    effects: {
        *getItemsBy({ payload: { query } }, { call, put }, { invitationTemplate }: Services, { pathParse }): any {
            maskThrottle();
            const schoolId = pathParse({ path: PathConfig.InvitationTemplates }).schoolId;
            query.schoolId = schoolId;
            try {
                const { data: invitationTemplates, totalCount: total, extraData: { activeCnt } } = yield call(invitationTemplate.getItemsBy, { ...query });
                const list = invitationTemplate.formatInvitationTemplateList(invitationTemplates);
                yield put(reload({ list, total, activeCnt }));
            } catch (response) {
            }
            unmaskThrottle();
        },
        *create({ payload: model }, { call, put }, { invitationTemplate }: Services, { pathParse }): any {
            maskThrottle();
            try {
                yield call(invitationTemplate.create, model);
                yield put(goToList());
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *get({ payload: id }, { call, put }, { invitationTemplate }: Services): any {
            maskThrottle();
            try {
                const model = yield call(invitationTemplate.get, { id });
                let mergedRegions = yield call(invitationTemplate.getRegionSchools);
                const defaultRegionId = ContextHelper.isUserMatchRole(RoleName.systemAdmin) && !model.regionId ? CONSTS.InvitationTemplateRegion.DefaultRegion.id : model.regionId
                const invitationTypeText = InvitationHelper.invitationType2Text.get(model.invitationType.toString());
                const invitationType2Roles = InvitationHelper.invitationType2RegionRole.get(model.invitationType.toString());
                const InvitationChannelText = InvitationHelper.invitationChannel2Text.get(model.invitationChannel);
                mergedRegions = filterRegion(invitationType2Roles, mergedRegions);
                if (ContextHelper.isUserMatchRole(RoleName.systemAdmin)) {
                    mergedRegions.unshift(CONSTS.InvitationTemplateRegion.DefaultRegion);
                }
                yield put(reload({ 
                    model: {...model, regionId: defaultRegionId, invitationTypeText, InvitationChannelText},
                    regions: mergedRegions
                }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *getNew(action, { all, call, put }, { invitationTemplate, region }: Services): any {
            maskThrottle();
            try {
                const invitationTypes = InvitationHelper.role2InvitationType[ContextHelper.getUserRoleForInvitationType()] as Map<string, string>;
                const invitationType =  invitationTypes? invitationTypes.keys().next().value : null;
                const invitationTypeN =  invitationType? parseInt(invitationType, 10) : null;
                let mergedRegions = yield call(invitationTemplate.getRegionSchools);
                const invitationType2Roles = InvitationHelper.invitationType2RegionRole.get(`${invitationType}` as InvitationType);
                mergedRegions = filterRegion(invitationType2Roles, mergedRegions);
                const defaultRegionId = ContextHelper.isUserMatchRole(RoleName.systemAdmin) ? CONSTS.InvitationTemplateRegion.DefaultRegion.id : null;
                if (ContextHelper.isUserMatchRole(RoleName.systemAdmin)) {
                    mergedRegions.unshift(CONSTS.InvitationTemplateRegion.DefaultRegion);
                }
                yield put(reload({ 
                    model: {region: defaultRegionId, invitationType: invitationTypeN, language: PrimaryLanguage.English, invitationChannel: InvitationChannel.Email},
                    regions: mergedRegions
                }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *remove({ payload: id }, { call, put }, { invitationTemplate }: Services, { pathParse }): any {
            maskThrottle();
            try {
                yield call(invitationTemplate.delete, { id: id });
                yield put(goToList());
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *update({ payload: model }, { call, put }, { invitationTemplate }: Services, { pathParse }): any {
            maskThrottle();
            const id = pathParse({ path: PathConfig.InvitationTemplateEdit }).templateId;
            try {
                yield call(invitationTemplate.update, { ...model }, { id: id });
                yield put(goToList());
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *goToList(action, { put }, services, { push, pathStringify }): any {
            yield put(push(pathStringify(PathConfig.InvitationTemplates)));
        },
        *getRegions({ payload: invitationType }, { call, put }, { invitationTemplate }: Services): any {
            let mergedRegions = yield call(invitationTemplate.getRegionSchools);
            const invitationType2Roles = InvitationHelper.invitationType2RegionRole.get(`${invitationType}` as InvitationType);
            mergedRegions = filterRegion(invitationType2Roles, mergedRegions);
            if (ContextHelper.isUserMatchRole(RoleName.systemAdmin)) {
                mergedRegions.unshift(CONSTS.InvitationTemplateRegion.DefaultRegion);
            }
            yield put(reload({ regions: mergedRegions }));
        },
        *getTemplate4Preview({ payload: id }, { call, put }, { invitationTemplate }: Services): any {
            try {
                const model = yield call(invitationTemplate.get, { id });
                const {relativeWidth} = yield call(getImageDimensions, model.backgroundImage);
                const pageBreakTagPatternA = new RegExp(`<span data-template-tag='{${TemplateTagCode.PageBreak}}'>\s*<span .*?>.*?{${TemplateTagCode.PageBreak}}.*?<\/span>\s*<\/span>`, 'gmi');
                const pageBreakTagPatternB = new RegExp(`<span data-template-tag='{${TemplateTagCode.PageBreak}}'>.*?{${TemplateTagCode.PageBreak}}.*?<\/span>`, 'gmi');
                yield put(reload({ 
                    template4Preview: {
                        ...model, 
                        text: model.text.replace(pageBreakTagPatternA, "").replace(pageBreakTagPatternB, "<p style='height:1em;'></p>"),
                        backgroundImageRelativeWidth: (!model.backgroundImage || model.backgroundImageLayout == TemplateBackgroundImageLayout.stretch) ? '100%' :  `${relativeWidth}%`
                    }
                }));
            } catch (response) {
                //console.log(response);
            }
        }
    },
    services: {
        invitationTemplate: InvitationTemplateService,
        resource: ResourceService,
        region: RegionService
    }
}

function filterRegion(roles: any[], regions) {
    return regions.filter(region => roles.indexOf(region.role) > -1);
}

export function showLoading() {
    return { type: 'invitationTemplate/showLoading' }
}
export function hideLoading() {
    return { type: 'invitationTemplate/hideLoading' }
}
export function reload(state) {
    return { type: 'invitationTemplate/reload', payload: state }
}
export function getItemsBy(state) {
    return { type: 'invitationTemplate/getItemsBy', payload: state }
}
export function create(state) {
    return { type: 'invitationTemplate/create', payload: state }
}
export function update(state) {
    return { type: 'invitationTemplate/update', payload: state }
}
export function remove(state) {
    return { type: 'invitationTemplate/remove', payload: state }
}
export function get(state) {
    return { type: 'invitationTemplate/get', payload: state }
}
export function getNew(state) {
    return { type: 'invitationTemplate/getNew', payload: state }
}
export function goToList() {
    return { type: 'invitationTemplate/goToList' }
}
export function getSchoolsByRegion(state?) {
    return { type: 'invitationTemplate/getSchoolsByRegion', payload: state }
}
export function getRegions(state?) {
    return { type: 'invitationTemplate/getRegions', payload: state }
}
export function getTemplate4Preview(state) {
    return { type: 'invitationTemplate/getTemplate4Preview', payload: state }
}
export function resetTemplate4Preview() {
    return { type: 'invitationTemplate/resetTemplate4Preview' }
}