import { PermissionService, GLGlobal } from 'gl-commonui';

interface Services {
    permission: PermissionService
}

export interface PermissionState {
    permissions?: string[]
}

export default {
    namespace: 'permission',
    state: {
        permissions: []
    },
    reducers: {
        setPermissions(state, action) {
            return { ...state, permissions: action.payload };
        }
    },
    effects: {
        *propagationPermissions({ payload: { resourceType, resourceKey }}, { call, put }, { permission }: Services) {
            const permissions = yield call(permission.propagation, {resourceType, resourceKey});
            yield put(setPermissions(permissions));
        }
    },
    services: {
        permission: PermissionService
    }
}

function setPermissions(state) {
    return { type: 'permission/setPermissions', payload: state }
}

export function propagationPermissions(state) {
    GLGlobal.store.dispatch({ type: 'permission/propagationPermissions', payload: state });
}
