import * as React from 'react'
import { withRouter, connect } from 'gl-commonui';
import { StateType } from '@app/states';
import { preLoadSchool } from '@app/states/school/school';

interface SchoolPageContainerProps {
    isSignin?: boolean
    schoolId: string
    location: any
    preLoadSchool?: (id) => void
}

@withRouter
@connect(({ oidc: { loginInfo }}: StateType) => ({ isSignin: loginInfo && loginInfo.loggedin}), { preLoadSchool })
export class SchoolPageContainer extends React.Component<SchoolPageContainerProps> {
    componentWillMount() {
        const { schoolId, location, preLoadSchool } = this.props;
        preLoadSchool({id: schoolId, location});
    }

    render() {
        return this.props.children;
    }
}