import { useActionCreator, useSelector, useService } from "@app/hooks";
import { TagManagerLocale } from "@app/locales/localeid";
import { ITagManagerService, TagEntity, TagListModel } from "@app/service/tag-manager";
import { TYPES } from "@app/service/types";
import { fetchTagListAction } from "@app/states/tag-manager/tag-manager";
import { fmtMsg } from "@app/util";
import { Icon, Popover, Tag, Tooltip } from "antd-min";
import { TooltipPlacement } from "antd/lib/tooltip";
import { sortBy } from "lodash";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { GridICellTemplateContext } from "../grid/grid-column";
import { TaggingEditor } from "./tagging-editor/tagging-editor";
import "./tagging.less";

interface TaggingComponentProps {
    selectedTags?: TagListModel[];
    entityId: string;
    entity: TagEntity;
    regionId: string;
    wijmoGridContext?: GridICellTemplateContext;
    isViewOnly?: boolean;
    popoverPlacement?: TooltipPlacement;
    isGridTagging?: boolean
    onSave?: () => void;
}

export const Tagging: FC<TaggingComponentProps> = (props) => {
    const { selectedTags, entityId, entity, regionId, wijmoGridContext, isViewOnly, popoverPlacement } = props;

    const [currentTags, setCurrentTags] = useState<TagListModel[]>(selectedTags || []);

    const tags = useSelector((state) => state.tagManager.tagList[`${regionId}-${entity}`]);
    const loading = useSelector((state) => state.tagManager.tagListLoading);
    const tagListUpdated = useSelector((state) => state.tagManager.tagListUpdated);
    const fetchTagList = useActionCreator(fetchTagListAction);

    const tagManagerService = useService<ITagManagerService>(TYPES.ITagManagerService);
    const ref = useRef<HTMLDivElement>();

    const tagCountToShowUpfront = 2;
    const hideAddLabelAfter = 2;

    /**
     * In the Wijmo grid, we need to set the maxWidth for the tagging container element as we calculate its height and then resize the row height.
     */
    const maxWidth = wijmoGridContext ? wijmoGridContext.col.maxWidth : undefined;

    useEffect(() => {
        if (!loading && (!tags || tagListUpdated)) {
            fetchTagList({ regionId, entity });
        }
        if (!selectedTags && !props.isGridTagging) {
            loadSelectedTags();
        }
    }, []);

    useEffect(() => {
        setCurrentTags(selectedTags || []);
    }, [selectedTags]);

    useEffect(() => {
        if (wijmoGridContext) {
            const grid = wijmoGridContext.row.grid;
            const rowIndex = wijmoGridContext.row.index;
            const padding = 16;
            const element = ref.current ? ref.current : { clientHeight: 24 };
            const elementHeight = Math.max(element.clientHeight, 24);
            grid.rows[rowIndex].height = elementHeight + padding;
        }
    }, [currentTags]);

    const loadSelectedTags = () => {
        tagManagerService.getTeacherTagsByRole(entityId, regionId).then((tags) => {
            if (tags) {
                setCurrentTags(tags);
            }
        });
    };

    const remove = (tagId: number) => {
        tagManagerService.deleteUsage(tagId, entityId).then(() => {
            setCurrentTags(currentTags.filter((tag) => tag.id !== tagId));
        });
        if (props.onSave) {
            props.onSave();
        }
    };

    const onAddTags = (tags: TagListModel[]) => {
        const newTags = sortBy([...currentTags, ...tags], "name");
        setCurrentTags(newTags);
        if (props.onSave) {
            props.onSave();
        }
    };

    const renderPopover = () => {
        return <div className="tagging__popover">{renderRestOfTwoTags()}</div>;
    };

    const renderTag = (tag: TagListModel) => {
        return (
            <Tag className="tagging__tag" title={tag.name} key={entityId + tag.id} closable={!isViewOnly} onClose={() => remove(tag.id)}>
                <span className="ellipseText">{tag.name}</span>
            </Tag>
        );
    };

    const renderTopTwoTags = () => {
        return currentTags.slice(0, tagCountToShowUpfront).map(renderTag);
    };
    const renderRestOfTwoTags = () => {
        return currentTags.slice(tagCountToShowUpfront, currentTags.length).map(renderTag);
    };

    const renderAddAction = (): ReactNode => {
        const showLabel = currentTags.length < hideAddLabelAfter;
        const actionWithLabel = (
            <Tag className="tagging__add-btn">
                <Icon type="plus" />
                {showLabel && <span> {fmtMsg(TagManagerLocale.TaggingAddButtonLabel)}</span>}
            </Tag>
        );
        const actionWithTooltip = <Tooltip title={fmtMsg(TagManagerLocale.TaggingAddButtonLabel)}>{actionWithLabel}</Tooltip>;

        if (showLabel) {
            return actionWithLabel;
        }

        return actionWithTooltip;
    };

    const renderActions = () => {
        return (
            <React.Fragment>
                {!isViewOnly && (
                    <TaggingEditor
                        regionId={regionId}
                        entityId={entityId}
                        entity={entity}
                        currentTags={currentTags}
                        onSave={onAddTags}
                        popoverPlacement={popoverPlacement}
                    >
                        {renderAddAction()}
                    </TaggingEditor>
                )}
                {currentTags.length > tagCountToShowUpfront && (
                    <Popover
                        content={renderPopover()}
                        title={fmtMsg({
                            id: TagManagerLocale.TaggingViewPopoverTitle,
                        })}
                        trigger="click"
                        placement={popoverPlacement}
                        overlayClassName="tagging__popover__overlay"
                    >
                        <Tooltip title={fmtMsg(TagManagerLocale.TaggingViewShowMoreTooltip)}>
                            <Tag className="tagging__add-btn">
                                <Icon type="tags" />
                            </Tag>
                        </Tooltip>
                    </Popover>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className="tagging" ref={ref} style={{ maxWidth }}>
            {renderTopTwoTags()}
            {renderActions()}
        </div>
    );
};

Tagging.defaultProps = {
    popoverPlacement: "bottomRight",
};
