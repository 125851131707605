import * as React from "react";
import { ListItem, Actions, Action } from "..";
import "./status-panel.less";

interface StatusPanelProps extends React.Props<StatusPanelProps> {
    title?: string;
    subtitle?: string;
    actions: JSX.Element[];
    isCollapsible?: boolean;
    onCollapse?(event: React.FormEvent): void;
}
interface StatusPanelState {
    collapsed?: boolean;
}
export class StatusPanel extends React.Component<StatusPanelProps, StatusPanelState> {
    state = {
        collapsed: false
    };

    onCollapse(event: React.FormEvent): void {
        const { onCollapse } = this.props;
        onCollapse && onCollapse(event);
        this.setState(prevState => ({ collapsed: !prevState.collapsed }));
    }

    render() {
        const { title, subtitle, actions, children, isCollapsible } = this.props;
        const { collapsed } = this.state;
        return (
            <div className={`tw-status-panel${collapsed ? " collapsed" : ""}`}>
                <div className="tw-status-panel-header">
                    <div className="tw-status-panel-meta">
                        {title && <h4>{title}</h4>}
                        {subtitle && <p>{subtitle}</p>}
                    </div>
                    {(actions || isCollapsible) && (
                        <Actions
                            actions={[
                                isCollapsible && (
                                    <Action
                                        className="tw-status-collpase-trigger"
                                        key="0"
                                        materialIcon="keyboard_arrow_down"
                                        onClick={this.onCollapse.bind(this)}
                                    />
                                ),
                                ...actions
                            ]}
                        />
                    )}
                </div>
                <div className="tw-status-panel-body">{children}</div>
            </div>
        );
    }
}

export interface StatusItemProps extends React.Props<StatusItemProps> {
    id: string;
    icon?: string;
    materialIcon?: string;
    title: string;
    subtitle?: string;
    status?: StatusType;
    progress?: number;
    response?: any;
    onCancel?(id?: string): void;
    onRetry?(id: string): void;
}
export enum StatusType {
    Progress = "progress",
    Completed = "completed",
    Error = "error"
}
export const StatusItem: React.StatelessComponent<StatusItemProps> = (props: StatusItemProps) => {
    const { id, icon, materialIcon, title, subtitle, progress, status, onCancel, onRetry } = props;
    const cancelHandler = () => {
        onCancel && onCancel(id);
    };
    const retryHandler = () => {
        onRetry && onRetry(id);
    };
    const getProgress = (): number => {
        if (progress !== 0) {
            return progress - progress / 10;
        }
        return progress;
    };

    return (
        <ListItem
            className="tw-status-item"
            key={id}
            materialIcon={materialIcon}
            icon={icon}
            actions={[
                progress && (status === StatusType.Progress || status === StatusType.Completed || !status) ? (
                    <Action key="0" progress={status === StatusType.Completed ? 100 : getProgress()} />
                ) : null,
                onRetry && status === StatusType.Error ? <Action key="1" materialIcon="replay" onClick={retryHandler} /> : null,
                status === StatusType.Error && !onRetry && <Action key="2" materialIcon="error" className="action-error" />,
                onCancel ? <Action key="3" onClick={cancelHandler} materialIcon="close" /> : null
            ]}
        >
            <div className="tw-status-title">{title}</div>
            {subtitle && <div className="tw-status-subtitle">{subtitle}</div>}
        </ListItem>
    );
};
