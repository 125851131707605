import { GLResponse, GLRequest } from "gl-commonui";

export interface RequestProductItemModel {
    id?: string;
    classId?: string;
    className?: string;
    requestId?: string;
    unit?: number;
    unitText?: string;
    quantity?: string;
    productId?: string;
    productName?: string;
    price?: string;
    status?: number;
    requestDate?: string;
    requestDateFormatted?: string;
    requestedBy?: string;
    requestedBy2Name?: string;
    shippedQuantity?: any;
    productType?: number;
    totalPrice?: number;
    note?: string;
}

export interface CumulativeGridItemModel {
    unit?: string;
    product?: string;
    quantity?: number;
    priceEach?: number;
    total?: number
}

export interface CumulativeDataItemModel{
    type: string;
    unitText: string;
    productName: any;
    quantity: any;
    totalCost: any;
}

export enum RequestProductItemPropsModel {
    classId = "classId",
    unit = "unit",
    quantity = "quantity",
    productId = "productId",
    productName = "productName",
    price = "price",
    shippedQuantity = "shippedQuantity",
    note = "note"
}

export interface MaterialModel {
    id?: string;
    schoolId?: string;
    campusId?: string;
    campusName?: string;
    submissionUser?: string;
    submissionEmail?: string;
    submissionPhone?: string;
    submissionDate?: string;
    submissionDateFormatted?: string;
    address?: string;
    shippingName?: string;
    shippingPhone?: string;
    shippingEmail?: string;
    postalCode?: string;
    shipDate?: string;
    shippedDate?: string;
    shippedNotes?: string;
    shipDateFormatted?: string;
    notes?: string;
    address1?: string;
    address2?: string;
    cityState?: string;
    agreeToMaterialRequestLicense?: boolean;
    regionRequireAgreement?: boolean;
}
export enum MaterialPropsModel {
    id = "id",
    schoolId = "schoolId",
    campusId = "campusId",
    campusName = "campusName",
    submissionUser = "submissionUser",
    submissionEmail = "submissionEmail",
    submissionPhone = "submissionPhone",
    submissionDate = "submissionDate",
    submissionDateFormatted = "submissionDateFormatted",
    address = "address",
    shippingName = "shippingName",
    shippingPhone = "shippingPhone",
    shippingEmail = "shippingEmail",
    postalCode = "postalCode",
    shipDate = "shipDate",
    shipDateFormatted = "shipDateFormatted",
    notes = "notes",
    shippedDate = "shippedDate",
    shippedNotes = "shippedNotes",
    agreeToMaterialRequestLicense = "agreeToMaterialRequestLicense",
    regionRequireAgreement = "regionRequireAgreement"
}
export interface MaterialRequest extends GLRequest, MaterialModel {}
export interface MaterialResponse extends GLResponse, MaterialModel {}

export interface MaterialOrdersModel {
    id?: string;
    orderId?: string;
    submitDate?: string;
    status?: string;
    statusText?: string;
    notes?: string;
    shippedNotes?: string;
    shippedDate?: string;
    regionId?: string;
    regionName?: string;
    schoolId?: string;
    schoolName?: string;
    campusId?: string;
    campusName?: string;
    submissionDate?: string;
    materialOrderProduct?: MaterialOrderProduct[];
    productName?: string;
    productUnit?: number;
    quantity?: number;
    materialRequestId?: number;
    submissionPhone?: string;
    submissionEmail?: string;
    maxWidth?: number;
}

export interface MaterialOrderModel extends MaterialModel {
    id?: string;
    materialRequestId?: number;
    orderId?: string;
    status?: string;
    purchaseItems?: RequestProductItemModel[];
    subscriptionRequests?: SubscriptionItemModel[];
}
export const enum MaterialOrdersPropsModel {
    id = "id",
    orderId = "orderId",
    submitDate = "submitDate",
    status = "campusId",
    statusText = "statusText",
    notes = "notes",
    shippedNotes = "shippedNotes",
    shippedDate = "shippedDate",
    regionId = "regionId",
    regionName = "regionName",
    schoolId = "schoolId",
    schoolName = "schoolName",
    campusId = "campusId",
    campusName = "campusName",
    submissionDate = "submissionDate",
    materialOrderProduct = "materialOrderProduct",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    productName = "productName",
    productUnit = "productUnit",
    productUnitText = "productUnitText",
    quantity = "quantity",
    materialRequestIdText = "materialRequestIdText",
}

export interface SubscriptionMaterialRequest
    extends GLRequest,
        SubscriptionItemModel {}
export interface SubscriptionMaterialResponse
    extends GLResponse,
        SubscriptionItemModel {}

export interface SubscriptionPurchaseRequest {
    purchaseRequestDetails?: RequestProductItemModel[];
    subscriptionRequestDetails?: SubscriptionRequestModel;
}

export interface SubscriptionRequestModel {
    id?: string;
    licenseType?: string;
    subscriptionItems?: RequestProductItemModel[];
}
export interface SubscriptionItemModel {
    id?: string;
    classId?: string;
    className?: string;
    requestDate?: string;
    requestDateFormatted?: string;
    requestedBy?: string;
    requestedBy2Name?: string;
    details?: RequestProductItemModel[];
    details2PlainText?: string;
}
export enum SubscriptionItemPropsModel {
    className = "className",
    requestDate = "requestDate",
    requestDateFormatted = "requestDateFormatted",
    requestedBy = "requestedBy",
    requestedBy2Name = "requestedBy2Name",
    details = "details",
    details2PlainText = "details2PlainText"
}

export interface MaterialRequestItemDisplayModel {
    id?: string;
    classId?: string;
    className?: string;
    requestId?: string;
    unit?: number;
    unitText?: string;
    quantity?: string;
    shippedQuantity?: string;
    productId?: string;
    productName?: string;
    price?: string;
    requestDate?: string;
    requestDateFormatted?: string;
    requestedBy?: string;
    requestedBy2Name?: string;
    status?: number;
    rowId?: string;
    rowSpan?: number;
    currency?: string;
}
export enum MaterialRequestItemDisplayPropsModel {
    id = "id",
    classId = "classId",
    className = "className",
    unit = "unit",
    unitText = "unitText",
    quantity = "quantity",
    shippedQuantity = "shippedQuantity",
    productId = "productId",
    productName = "productName",
    price = "price",
    requestId = "requestId",
    requestDate = "requestDate",
    requestDateFormatted = "requestDateFormatted",
    requestedBy = "requestedBy",
    requestedBy2Name = "requestedBy2Name",
    totalPrice = "totalPrice",
    note = "note",
    licenseHistory = "licenseHistory",
}

export interface SubscriptionItemDisplayModel
    extends MaterialRequestItemDisplayModel {}
export interface PurchaseItemDisplayModel
    extends MaterialRequestItemDisplayModel {}

export interface MaterialOrderProduct {
    productId?: string;
    productName?: string;
    productUnit?: number;
    quantity?: number;
}

export enum CumulativeProductListDisplayPropsModel {
    unitText = "unitText",
    productName = "productName",
    quantity = "quantity",
    price = "price",
    totalCost = "totalCost"
}