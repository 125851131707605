import React, {Component, useEffect} from 'react';
import { GLUtil, GLGlobal, PathConfig as GlobalPath } from 'gl-commonui';
import { Progress } from './component/progress';
import { MainTitle } from '@app/components/school/school-page-title';
import './index.less';
import { lazyInject, TYPES, fmtMsg, DateHelper, GSAdminAction, GSSchoolAction } from '@app/util';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { SchoolPathConfig, PathConfig } from '@app/config/pathconfig';
import { VisitationType } from '@app/util/coach/enum';
import { Row, Col, Icon, Modal } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { ICampusService } from '@app/service/school/campus';
import { ISchoolService } from '@app/service/schools';
import { IAccountService } from '@app/service/admin/accountservice';
import { Link } from 'react-router-dom';
import { CoachTabs } from './coach/coach-tabs';
import { isCoachAndSelf } from '@app/util/coach/fun';

interface CoachVisitationProps {
    history?: any
}
interface CoachVisitationStates {
    visitation?: VisitationModel
    selectedStage?: number
    coachName?: string
    schoolName?: string
    campusName?: string
    visible?: boolean
    hasPermission?: boolean
}

export class CoachVisitation extends Component<CoachVisitationProps, CoachVisitationStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentWillMount(){
        this.checkAccessPermission().then((accessable) => {
            if (accessable) {
                this.getVisitationInfo().then(data => {
                    if (data.type === VisitationType.OnSite) {
                        this.getCampusName(data);
                    }
                    this.getSchoolName(data);
                });
                this.checkPermissionUser().then(data => {
                    this.setState({hasPermission: data});
                })
            }
        });
    }
    checkAccessPermission() {
        const { visitationId } = GLUtil.pathParse(SchoolPathConfig.CoachVisitation);
        return this.visitationservice
            .checkCoachAccessPermission({ visitationId })
            .then(accessable => {
                !accessable && this.props.history.push(GlobalPath.AccessDenied);
                return accessable;
            });
    }
    getVisitationInfo() {
        const id = GLUtil.pathParse(SchoolPathConfig.CoachVisitation).visitationId;
        return this.visitationservice.getCoachRelated({ id });
    }
    checkPermissionUser() {
        const visitationId = GLUtil.pathParse(SchoolPathConfig.CoachVisitation).visitationId;
        return this.visitationservice.checkPermissionDeleteCoachingSupportEvent({visitationId: visitationId});
    }
    getCoachInfo(coachId) {
        return this.accountservice.getUserById({ id: coachId });
    }
    getSchoolName(data) {
        const { coachId, schoolId } = data;
        const actions = [this.getCoachInfo(coachId), this.schoolService.get({ id: schoolId })];
        Promise.all(actions).then(([coach, school]) => {
            this.setState({
                visitation: {...this.state.visitation, ...data, schoolName: school.name},
                coachName: coach.name,
                schoolName: school.name,
            });
        });
    }
    getCampusName(data) {
        const { coachId, schoolId, campusId  } = data;
        const params = {
            schoolId,
            campusId
        }
        const actions = [this.getCoachInfo(coachId), this.campusService.get(params)];
        Promise.all(actions).then(([coach, campus]) => {
            this.setState({
                visitation: {...this.state.visitation, ...data, campusName: campus.name},
                coachName: coach.name,
                campusName: campus.name,
            });
        });
    }
    setStageCallback(stage, isUpdate=false) {
        this.getVisitationInfo().then(data => {
            this.setState({
                visitation: data,
                selectedStage: isUpdate ? stage : null,
            });
        })
    }
    selectedStageCallback(stage) {
        this.setState({
            selectedStage: stage,
        });
    }
    showModalCancelEvent = () => {
        this.setState({
            visible: true
        })
    }
    handleClickYesCancelEvent = e => {
        this.setState({
            visible: false
        })
        const visitationId = GLUtil.pathParse(SchoolPathConfig.CoachVisitation).visitationId;
        const regionId = GLUtil.pathParse(SchoolPathConfig.CoachVisitation).regionId;
        const schoolId = GLUtil.pathParse(SchoolPathConfig.CoachVisitation).schoolId;
        this.visitationservice.deleteCoachingSupportEvent({visitationId: visitationId}).then(() => {
            this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.SchoolVisitationHistory, {regionId: regionId, schoolId: schoolId}) });
        });
    }
    handleClickNoCancelEvent = e => {
        this.setState({
            visible: false
        })
    }
    render() {
        const { visitation, coachName, schoolName, campusName, selectedStage, hasPermission } = this.state;
        const isOnSiteVisit = visitation && visitation.type == VisitationType.OnSite;
        const startDate = visitation && visitation.startDate && DateHelper.toLocalStringFromUTC(visitation.startDate);
        const completedDate = visitation &&
                              visitation.completedDate
                              && DateHelper.toLocalStringFromUTC(visitation.completedDate);

        const schoolUrl = visitation && !isOnSiteVisit && {pathname: GLUtil.pathStringify(PathConfig.Schools, {regionId: visitation.regionId, schoolId: visitation.schoolId})};
        const campusUrl = isOnSiteVisit && {pathname: GLUtil.pathStringify(PathConfig.Classes, {regionId: visitation.regionId, schoolId: visitation.schoolId, campusId: visitation.campusId})};
        return <div className="ongoing content-layout step-support-questionnaire">
            <MainTitle plain={fmtMsg({ id: SchoolLocale.VisitationRequestVisitation })} />
            <div className="page-content">
                <Row className="info">
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationCoachName})}:
                        {visitation && isCoachAndSelf(GSSchoolAction.CoachLink, visitation.coachId) ?
                        <Link to={{ pathname: GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId: visitation.regionId, schoolId: visitation.schoolId, trainerId: visitation.coachId }) }} >
                            <span className="coach-name">{coachName}</span>
                        </Link> : <span className="coach-name">{coachName}</span>}
                    </Col>
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationDateOfVisit})}: {startDate}</Col>
                </Row>
                <Row className="info">
                    {   visitation && (isOnSiteVisit ?
                            <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationCampusText})}:
                                {isCoachAndSelf(GSSchoolAction.CampusLink, visitation.coachId) ?
                                    <>
                                        <Link to={campusUrl}><span className="campus-name">{campusName}</span></Link>
                                        <Icon type="environment" />
                                    </>
                                    : <span className="coach-name">{campusName}</span>
                                }
                            </Col>
                             :
                            <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.CoachSchool})}:
                                {isCoachAndSelf(GSSchoolAction.SchoolLink, visitation.coachId) ?
                                    <>
                                        <Link to={schoolUrl}><span className="school-name">{schoolName}</span></Link>
                                        <Icon type="video-camera" />
                                    </>
                                    : <span className="coach-name">{schoolName}</span>
                                }
                            </Col>
                            )
                    }
                    {completedDate && <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationDateOfCompletion})}: {completedDate}</Col>}
                    {(hasPermission && visitation && visitation.stage < 6) &&
                        <Col sm={24} xl={12}>
                            <a className="cancel-event-btn" onClick={this.showModalCancelEvent}>{fmtMsg({id: SchoolLocale.SchoolCancelEventBtn})}</a>
                        </Col>
                    }
                </Row>
                {visitation && <div className="visitation">
                    <Progress isCoach={true}
                                visitation={visitation}
                                selectedStage={selectedStage}
                                selectedStageCallback={(stage) => this.selectedStageCallback(stage)}
                                callback={(stage) => this.setStageCallback(stage)}></Progress>
                    <CoachTabs visitation={visitation}
                                selectedStage={selectedStage}
                                callback={(stage, isUpdate) => this.setStageCallback(stage, isUpdate)}/>
                </div>}
                <Modal
                    title={fmtMsg({id: SchoolLocale.SchoolCancelEventModalTitle})}
                    visible={this.state.visible}
                    onOk={this.handleClickYesCancelEvent}
                    onCancel={this.handleClickNoCancelEvent}
                    okText={fmtMsg({id: SchoolLocale.SchoolCancelEventModalYes})}
                    cancelText={fmtMsg({id: SchoolLocale.SchoolCancelEventModalNo})}
                >
                    <p>{fmtMsg({id: SchoolLocale.SchoolCancelEventModalMessage})}</p>
                </Modal>
            </div>
        </div>;
    }
}
