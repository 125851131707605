import * as React from 'react'
import { Button, Row, Col, Modal } from 'antd-min';
import { ModalFuncProps } from 'antd/lib/modal';
import { GLLocale, GLGlobal, GLAction } from 'gl-commonui';
import { SchoolLocale } from '../../locales/localeid';
import classNames from 'classnames';

export interface SubmitBtnsProps {
    submitTitle?: string
    previewTitle?: string
    cancelTitle?: string
    onPreview?: (e) => void
    onCancel?: (e) => void
    submitAction?: string
    isDisabled?: boolean
    extraClassName?: string
    hideSubmitButton?: boolean
}
export const SubmitBtns: React.StatelessComponent<SubmitBtnsProps> = ({ submitTitle, previewTitle, cancelTitle, onPreview, onCancel, children, submitAction, isDisabled, extraClassName, hideSubmitButton }) => {
    const submitTip = GLGlobal.intl.formatMessage({ id: submitTitle || SchoolLocale.BtnSave });
    const previewTip = GLGlobal.intl.formatMessage({ id: previewTitle || SchoolLocale.BtnSave });
    const cancelTip = GLGlobal.intl.formatMessage({ id: cancelTitle || GLLocale.Cancel });
    const primaryButtonClassName = {
        'three': previewTitle,
        'disabled': !submitAction && isDisabled
    };
    let firstChildDivClassName = { };
    firstChildDivClassName[extraClassName] = extraClassName != null;
    return (
        <div className='submit-btns'>
            <div className={classNames(firstChildDivClassName)}>
                {!submitAction && !hideSubmitButton &&
                    <div><Button type="primary" disabled={isDisabled} htmlType="submit" className={classNames(primaryButtonClassName)} title={submitTip}>{submitTip}</Button></div>
                }
                {submitAction && GLGlobal.isActionValid(submitAction) && !hideSubmitButton &&
                    <div><Button type="primary" disabled={isDisabled} htmlType="submit" className={classNames(primaryButtonClassName)} title={submitTip}>{submitTip}</Button></div>
                }
                {!hideSubmitButton && <div><Button onClick={onPreview} className={previewTitle ? "three" : "two"} title={previewTip}>{previewTip}</Button></div>}
                {children || onCancel && <div><Button onClick={onCancel} className={previewTitle ? "three" : ""} title={cancelTip}>{cancelTip}</Button></div>}
            </div>
        </div>
    )
}

interface EditDelBtnsProps {
    delete: (d?) => void
    localeId: string,
    confirmModalProps?: ModalFuncProps
    deleteAction: string
}
export const EditDelBtns: React.StatelessComponent<SubmitBtnsProps & EditDelBtnsProps> = (props) => {
    const deleteTip = GLGlobal.intl.formatMessage({ id: SchoolLocale.BtnDelete });
    return (
        <SubmitBtns {...props}></SubmitBtns>
    )
}

export interface SaveSubmitBtnsProps {
    saveTitle?: string
    submitTitle?: string
    onSave?: (e) => void
    onSubmit?: (e) => void
    isSubmitDisabled?: boolean
}
export const SaveSubmitBtns: React.StatelessComponent<SaveSubmitBtnsProps> = ({ saveTitle, submitTitle, children, onSave, onSubmit, isSubmitDisabled }) => {
    const saveCartTip = GLGlobal.intl.formatMessage({ id: saveTitle || SchoolLocale.BtnSaveCampusCart });
    const submitCartTip = GLGlobal.intl.formatMessage({ id: submitTitle || SchoolLocale.BtnSubmitCampusCart });
    return (
        <div className='submit-btns'>
            <div>
                <div><Button type="primary" onClick={onSave} title={saveCartTip}>{saveCartTip}</Button></div>
                {<div><Button type="primary" onClick={onSubmit} title={submitCartTip} disabled={isSubmitDisabled}>{submitCartTip}</Button></div> || children}
            </div>
        </div>
    )
}

export interface SaveCancelBtnsProps {
    saveTitle?: string
    cancelTitle?: string
    noSaveBtn?: boolean
    noCancelBtn?: boolean
    onSubmit?: (e) => void
    onCancel?: (e) => void
}
export const SaveCancelBtns: React.StatelessComponent<SaveCancelBtnsProps> = ({ saveTitle, cancelTitle, children, onSubmit, onCancel, noSaveBtn, noCancelBtn }) => {
    const saveTip = GLGlobal.intl.formatMessage({ id: saveTitle || SchoolLocale.BtnSaveCampusCart });
    const cancelTip = GLGlobal.intl.formatMessage({ id: cancelTitle || GLLocale.Cancel });
    return (
        <div className='submit-btns'>
            <div>
                {!noSaveBtn && <div><Button type="primary" onClick={onSubmit} title={saveTip} key="primary">{saveTip}</Button></div>}
                {!noCancelBtn && <div><Button onClick={onCancel} title={cancelTip}>{cancelTip}</Button></div>}
            </div>
        </div>
    )
}
