import React, { Component } from 'react';
import { GLUtil, withRouter, RoleName, PathConfig as CommonPath, GLGlobal } from 'gl-commonui';
import { Progress } from './component/progress';
import { MainTitle } from '@app/components/school/school-page-title';
import './index.less';
import { lazyInject, TYPES, fmtMsg,  GSSchoolAction, ContextHelper } from '@app/util';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { PathConfig } from '@app/config/pathconfig';
import { Row, Col } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { ICampusService } from '@app/service/school/campus';
import { ISchoolService } from '@app/service/schools';
import { IAccountService } from '@app/service/admin/accountservice';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CoachTabs } from './coach/coach-tabs';
import { isCoachAndSelf } from '@app/util/coach/fun';
import { VisitationCoachStage, VisitationType } from '@app/util/coach/enum';

interface VisitationNewProps extends RouteComponentProps<any> {}
interface VisitationNewStates {
    visitation?: VisitationModel
    visitationType: number
    selectedStage?: number
    schoolName?: string
}

@withRouter
export class VisitationNew extends Component<VisitationNewProps, VisitationNewStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    constructor(props) {
        super(props);
        this.state = {
            visitationType: VisitationType.OnSite,
            selectedStage: VisitationCoachStage.ScheduleDate
        }
    }
    componentWillMount(){
        GLGlobal.isActionValid(GSSchoolAction.CoachNewSupport) ? this.getSchoolName() :
                        this.props.history.push({ pathname: CommonPath.AccessDenied });
    }
    getCoachInfo(coachId) {
        return this.accountservice.getUserById({ id: coachId });
    }
    getSchoolName() {
        const { schoolId } = this.props.match.params;
        this.schoolService.get({ id: schoolId }).then((school) => {
            this.setState({ 
                schoolName: school.name,
            });
        });
    }
    setVisitationType(type) {
        this.setState({ visitationType: type });
    }
    render() {
        const { regionId, schoolId, visitationId } = this.props.match.params;
        const { schoolName, selectedStage, visitationType } = this.state;
        const schoolUrl = {pathname: GLUtil.pathStringify(PathConfig.Schools, {regionId: regionId, schoolId: schoolId})};
        const visitation = visitationId ? this.state.visitation : 
                        {
                            regionId,
                            schoolId,
                            coachId: null,
                            type: visitationType,
                            stage: VisitationCoachStage.ScheduleDate,
                        }
        return <div className="ongoing content-layout">
            <MainTitle plain={fmtMsg({ id: SchoolLocale.VisitationRequestVisitation })} />
            <div className="page-content">
                {/* <Row className="info">
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.VisitationCoachName})}: 
                        {visitation && isCoachAndSelf(GSSchoolAction.CoachLink, visitation.coachId) ?
                        <Link to={{ pathname: GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId: visitation.regionId, schoolId: visitation.schoolId, trainerId: visitation.coachId }) }} >
                            <span className="coach-name">{coachName}</span>
                        </Link> : <span className="coach-name">{coachName}</span>}
                    </Col>
                </Row> */}
                <Row className="info">
                    <Col sm={24} xl={12}>{fmtMsg({id: SchoolLocale.CoachSchool})}: 
                        {!visitationId || isCoachAndSelf(GSSchoolAction.SchoolLink, visitation.coachId) ?
                            <Link to={schoolUrl}><span className="school-name">{schoolName}</span></Link>
                            : <span className="coach-name">{schoolName}</span>
                        }
                    </Col>
                </Row>
                {(!visitationId || visitation) && <div className="visitation">
                    <Progress isCoach={true} 
                                visitation={visitation} 
                                selectedStage={selectedStage} />
                    <CoachTabs visitation={visitation} 
                                history={this.props.history}
                                selectedStage={selectedStage}
                                callback={(d) => this.setVisitationType(d)} />
                </div>}
            </div>
        </div>;
    }
}