
/** url definition */
export class PathConfig {
    static Dashboard = "/";
    static Notifications = "/notifications";
    static NotificationEdit = "/notifications/created/:notificationId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/edit";
    static NotificationCreate = "/notifications/new";
    static NotificationCreated = "/notifications/created";
    static NotificationReview = "/notifications/review";
    static NotificationReviewOne = "/notifications/review/:notificationId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/notification";
    static UpcomingEvents = "/upcomingevents";
    static MyChat = "/mychat";
    static ChatHistory = "/chathistory";

    static InvitationTemplates = "/templates";
    static InvitationTemplateCreate = "/templates/new";
    static InvitationTemplateEdit = "/templates/:templateId/edit";

    static GlobalProducts = "/globalproducts";
    static GlobalProductsCreate = "/globalproducts/new";
    static GlobalProductsEdit = "/globalproducts/:globalProductId/edit";
    static LocalProducts = "/localproducts";
    static LocalProductsCreate = "/localproducts/new";
    static LocalProductsEdit = "/localproducts/:localProductId/edit";

    static PendingChanges = "/pendingchanges";
    static StudentAccessDenied = "/warning/student/accessdenied";

    static MyActivity = "/myactivity";

    static Regions = "/regions";
    static Region = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static RegionInvite = "/regions/:regionId/admins";
    static RegionCoachInvite = "/regions/:regionId/regiontrainers";
    static RegionCreate = "/regions/new";
    static RegionEdit = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/edit";
    static RegionRewardPoints = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/reward-points";
    static RegionBulkAddPoints = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/reward-points/bulk-add-points";
    static RegionOrders = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/orders";
    static RegionTalkTimeSetting = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/talk-time";
    static RegionTagManager = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/tag-manager";
    static RegionTermManager = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/term-manager";
    static Schools = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static SchoolManageStudentLogin = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/managestudentlogin";
    static SchoolCreate = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/new";
    static SchoolAdmin = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/admins";
    static SchoolBilling = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/billing";
    static SchoolLicenses = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/licenses";
    static SchoolLicenseHistory = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/licenses/:historyId";
    static SchoolChangeHistory = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/changehistory";
    static SchoolVisitationHistory = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/visitationhistory";
    static SchoolTrainers = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/trainers";
    static SchoolTrainer = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/trainers/:trainerId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static RegionCoaches = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/regiontrainers";
    static RegionCoach = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/regiontrainers/:regiontrainerId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static CampusNew = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/new";
    static CampusEdit = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/edit";
    static CampusAdmins = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/admins";
    static CampusCart = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/cart";
    static SchoolCart = "/regions/:regionId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/schools/:schoolId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/Cart";
    static CampusOrders = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/orders";
    static CampusOrdersItem = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/orders/:orderId";
    static CampusOrdersDraftItem = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/draftorders/:orderId";
    static CampusChangeHistory = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/changehistory";
    static Classes = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static ClassNew = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/new";
    static ClassBulkEdit = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/bulkedit";
    static ClassStudentsPromote = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/promotestudents";
    static ClassEdit = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/edit";
    static ClassUnitPlan = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/unitplan";
    static ClassUnitPlanBulk = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/unitplanmanager";
    static ClassGroupManager = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/groupmanager";
    static ClassLicense = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/license";
    static ClassLicenseHistory = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/history";
    static ClassCart = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/materialrequest";
    static Students = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static RegisterStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/register";
    static StudentReport = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/students/:studentId";
    static Teachers = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/teachers";
    static Settings = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/settings";
    static AccountManagers = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/accountmanagers";
    static Admins = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/admins";
    static SchoolTeachers = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/teachers";
    static SchoolTeacher = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/teachers/:teacherId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static Invitations = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/invitations";
    static ClassInvitations = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/invitations";
    static SchoolClasses = "/schoolclasses";
    static MoveStudent = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/movestudents";
    static ManageStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/managestudents";
    static MergeStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/managestudents/mergestudents/:mergedClassId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/:mergedStudentId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static MergeThenVerifyStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/managestudents/mergestudents/verifystudents/:mergedClassId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/:mergedStudentId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/:potentialMatchId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static VerifyStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/managestudents/verifystudents/:mergedClassId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/:mergedStudentId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/:potentialMatchId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static MovePromoteStudents = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/movepromotestudents";
    static MovePromoteStudentsFromClass = "/regions/:regionId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/schools/:schoolId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/movepromotestudents";
}

/** url definition */
export class SchoolPathConfig {
    static Home = "/";
    static CoachDashboard = "/coachdashboard";
    static TeacherDashboard = "/teacherdashboard";
    static VisitationNew = "/regions/:regionId/schools/:schoolId/visitations/new";
    static CoachVisitation = "/regions/:regionId/schools/:schoolId/visitations/:visitationId/coach";
    static TeacherVisitation = "/regions/:regionId/schools/:schoolId/visitations/:visitationId/teacher";
    static Schools = "/schools/:schoolId";
    static CampusNew = "/schools/:schoolId/campuses/new";
    static CampusEdit = "/schools/:schoolId/campuses/:campusId/edit";
    static CampusAdmins = "/schools/:schoolId/campuses/:campusId/admins";
    static CampusCart = "/schools/:schoolId/campuses/:campusId/cart";
    static CampusOrders = "/schools/:schoolId/campuses/:campusId/orders";
    static CampusOrdersItem = "/schools/:schoolId/campuses/:campusId/orders/:orderId";
    static CampusOrdersDraftItem = "/schools/:schoolId/campuses/:campusId/draftorders/:orderId";
    static Classes = "/schools/:schoolId/campuses/:campusId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static ClassNew = "/schools/:schoolId/campuses/:campusId/classes/new";
    static ClassStudentsPromote = "/schools/:schoolId/campuses/:campusId/classes/:classId/promotestudents";
    static ClassEdit = "/schools/:schoolId/campuses/:campusId/classes/:classId/edit";
    static ClassUnitPlan = "/schools/:schoolId/campuses/:campusId/classes/:classId/unitplan";
    static ClassGroupManager = "/schools/:schoolId/campuses/:campusId/classes/:classId/groupmanager";
    static ClassLicense = "/schools/:schoolId/campuses/:campusId/classes/:classId/license";
    static ClassLicenseHistory = "/schools/:schoolId/campuses/:campusId/classes/:classId/history";
    static ClassCart = "/schools/:schoolId/campuses/:campusId/classes/:classId/materialrequest";
    static Students = "/schools/:schoolId/campuses/:campusId/classes/:classId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})";
    static StudentReport = "/schools/:schoolId/campuses/:campusId/classes/:classId/students/:studentId";
    static Teachers = "/schools/:schoolId/campuses/:campusId/classes/:classId/teachers";
    static Settings = "/schools/:schoolId/settings";
    static Admins = "/schools/:schoolId/admins";
    static SchoolTeachers = "/schools/:schoolId/teachers";
    static ValidateCode = "/invitations/accept-invitation";
    static Inviting = "/invitations/select-user";
    static Invited = "/invitations/invitation-result";
    static Register = "/invitations/register";
    static RegisterSuccess = "/invitations/register-success";
    static RedeemExisting = "/invitations/redeem";
    static Invitations = "/schools/:schoolId/invitations";
    // static InvitationTemplates = "/schools/:schoolId/templates";
    // static InvitationTemplateNew = "/schools/:schoolId/templates/new";
    // static InvitationTemplateEdit = "/schools/:schoolId/templates/:templateId/edit";
    static SchoolSearch = "/schools/:schoolId/search";
    static SchoolClasses = "/schoolclasses";
    static Notifications = "/notifications";
    static MoveStudent = "/schools/:schoolId/campuses/:campusId/classes/:classId/movestudents";
}

export class AdminPathConfig {
    static Home = "/";

    static CodeLookup = "/codelookup";

    static Regions = "/regions";
    static Region = "/regions/:regionId/region";
    static RegionInvite = "/regions/:regionId/admins";
    static RegionCreate = "/regions/new";
    static RegionEdit = "/regions/:regionId/edit";

    static School = "/regions/:regionId/schools/:schoolId/school";
    static SchoolEdit = "/regions/:regionId/schools/:schoolId/edit";

    static AcceptInvitation = "/accept-invitation";
    static AcceptInvitationCode = "/select-user";
    static AcceptInvitationSuccess = "/invitation-result";
    static Register = "/register";
    static RegisterSuccess = "/register-success";
    static RedeemExisting = "/redeem";
    static Users = "/users";
    static SupportInfo = "/users/:userId/supportinfo";
    static UserEdit = "/users/:userId/edit";
    static Materials = "/materials";
    static Invitations = "/invitations";
    static Preview = "/preview";
    static UnAuthorized = "/unauthorized";
    static GSAdminSearch = "/search";   
    static ContentRevisionHistory = "/content-revision-history";
}

/**
 * Route paths are ordered in decreasing order of specificity for consistency.
 * Maintain same order in router.
 */
export class SurveyPathConfig {
    static ReviewPollFromHistory = "/surveys/:surveyId/:surveyInstanceId/createdhistory/:userId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/review/:responseId";
    static ContributePollFromHistory = "/surveys/:surveyId/:surveyInstanceId/reviewhistory/contribute/:responseId";
    static ViewPollFromHistory = "/surveys/:surveyId/:surveyInstanceId/reviewhistory/:userId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/view/:responseId";
    static ViewResponseCreatorShared = "/surveys/:surveyId/:surveyInstanceId/responses";
    static ReviewPollFromLanding = "/surveys/:surveyId/:surveyInstanceId/:userId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/review/:responseId";
    static ContributePollFromLanding = "/surveys/:surveyId/:surveyInstanceId/contribute/:responseId";
    static CompletePollFromHistory = "/surveys/:surveyId/:surveyInstanceId/reviewhistory/complete";
    static ViewPollFromLanding = "/surveys/:surveyId/:surveyInstanceId/:userId([0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12})/view/:responseId";
    static CompletePollFromLanding = "/surveys/:surveyId/:surveyInstanceId/complete";
    static DetailsFromHistory = "/surveys/createdhistory/:surveyId";
    static CreatedHistory = "/surveys/createdhistory";
    static ReviewHistory = "/surveys/reviewhistory";
    static Create = "/surveys/create";
    static DetailsFromLanding = "/surveys/:surveyId";
    static Home = "/surveys";
}
