import React, { Component } from 'react'
import { Radio } from 'antd-min';
import { fmtMsg, MaterialHelper } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import './order-radios.less';

const RadioGroup = Radio.Group;

interface OrderStatusRadiosProps {
    value?: any,
    statusCounts?: any,
    onChange?: (e) => void
}

export const OrderListStatesAllValue = 0;
export const OrderListStatesSubmittedValue = 1;

export class OrderStatusRadios extends Component<OrderStatusRadiosProps, any> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.props.onChange(parseInt(e.target.value, 10));
    }
    renderState(text, cnt) {
        return (
            <span>
                <span>{fmtMsg(text)}</span>
                <span className="cnt">({cnt || 0})</span>
            </span>
        )
    }
    render() {
        const { value, statusCounts } = this.props;
        const statusCountMap = MaterialHelper.materialOrderStatusCountMap;
        return (
            <div className="list-states order-list-states">
                <RadioGroup value={value} defaultValue={OrderListStatesSubmittedValue} onChange={this.onChange} >
                    <Radio className="status-allCnt" value={OrderListStatesAllValue}>
                        {this.renderState(SchoolLocale.InvitationSchoolInvitationHistoryStatusAll, statusCounts['allCnt'])}
                    </Radio>
                    {
                        MaterialHelper.getMaterialOrderStatus().map(orderStatus=> {
                            const value = parseInt(orderStatus.key, 10);
                            return (
                                <Radio className={`status-${statusCountMap[orderStatus.key]}`} key={orderStatus.key} value={value}>
                                    {this.renderState(orderStatus.value, statusCounts[statusCountMap[orderStatus.key]])}
                                </Radio>
                            )
                        })
                    }
                </RadioGroup>
            </div>
        )
    }
}