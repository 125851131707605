export enum CodeValidateStatus {
    Valid = 1,
    NaN = 2,
    Duplicate = 3,
    InvalidLength = 4
}

export enum PasswordValidateStatus {
    Valid = 1,
    Invalid = 2
}

export enum StudentCodeBinding {
    StudentId = "studentId",
    EnglishName = "englishName",
    Name = "name",
    Password = "password",
    StudentCode = "studentCode",
    UserName = "userName"
}

export interface StudentCodeUIModel {
    studentId: string;
    englishName: string;
    name: string;
    password: string;
    studentCode?: string;
    userName: string;
    codeStatus?: CodeValidateStatus;
    passwordStatus?: PasswordValidateStatus;
}

export enum SaveStudentErrorCode {
    StudentCodeInvalidUserName = 6010,
    StudentCodeAlreadyExists = 6011,
    StudentCodeUserNameAlreadyExists = 6012
}
