import { injectable } from "@app/util/di";
import { GLServiceBase } from "gl-commonui";
import { IRewardPointsService } from "./index";
import {
    CampusPointModel,
    GetPointModelParams,
    CreatePointModelParams,
    GetPointsResponse,
    EditPointModelParams,
    DeletePointModelParams,
    DownloadPointTemplateParams,
    CampusPointExcelModel,
    CreateBulkPointModelParams,
    ValidateBulkModelParams,
    ValidateBulkModelResponse,
    ExportGridModelParams
} from "./model";

@injectable()
export class RewardPointService extends GLServiceBase<any, any>
    implements IRewardPointsService {
    serviceRoute = {
        prefix: "admin/v1/campus"
    };

    public getCampusPoints(params: GetPointModelParams) {
        const {
            regionId,
            schoolId,
            campusId,
            limit,
            offset,
            sortBy,
            isDescending
        } = params;
        const queryStringObj = {
            schoolId,
            campusId,
            limit,
            offset,
            sortBy,
            isDescending
        };
        return this.get<GetPointsResponse>(
            `points/region/${regionId}`,
            queryStringObj
        );
    }

    public createPoint(params: CreatePointModelParams) {
        return this.create("points", params);
    }
    public editPoint(params: EditPointModelParams): Promise<any> {
        return this.create("points", params);
    }

    public deletePoint(params: DeletePointModelParams): Promise<any> {
        return this.delete(`points/id/${params.id}`);
    }

    public downloadFormat(params: DownloadPointTemplateParams): Promise<any> {
        const { language, regionId } = params;
        const queryStringObj = {
            language
        };
        return this.download({
            suffix: `points/template/region/${regionId}`,
            data: queryStringObj,
            method: "get"
        });
    }

    public createBulkPoint(params: CreateBulkPointModelParams): Promise<any> {
        return this.update("points/bulk", params.bulk);
    }

    public validateBulk(
        params: ValidateBulkModelParams
    ): Promise<ValidateBulkModelResponse[]> {
        const { regionId, campusIds } = params;
        return this.create(`points/validate/region/${regionId}`, campusIds);
    }
    public exportGrid(params: ExportGridModelParams): Promise<any> {
        const {
            regionId,
            schoolId,
            campusId,
            language,
            isDescending,
            sortBy
        } = params;
        const queryStringObj = {
            schoolId,
            campusId,
            language,
            isDescending,
            sortBy
        };
        return this.download({
            suffix: `points/export/region/${regionId}`,
            data: queryStringObj,
            method: "get"
        });
    }
}
