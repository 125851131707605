import * as React from 'react'
import { Component } from 'react';
import { Radio } from 'antd-min';
import { ListStates, fmtMsg, mergeClasses } from '../util';
import { DashboardLocale, SchoolLocale } from '../locales/localeid';
import { GLUtil, GLGlobal, OidcProxy } from 'gl-commonui';
import ReactDOM from 'react-dom';
import { RadioChangeEvent } from 'antd/lib/radio';

const RadioGroup = Radio.Group;

export interface ListStatesRadiosProps {
    value?: any;
    allCnt?: number;
    activeCnt?: number;
    inActiveCnt?: number;
    pendingCount?: number;
    futureCount?: number;
    onChange?: (value: ListStates) => void;
    openGroupMode?: (e) => void;
    showGroupingButton?: boolean
    showInActiveRadio?: boolean;
    className?: string;
    activeTextLocaleId?: string;
    inActiveTextLocaleId?: string;
    hideCnts?: boolean;
    showPendingRadio?: boolean;
    showAllRadio?: boolean;
    showActiveRadio?: boolean;
}

interface ListStatesRadiosStates {
}

export class ListStatesRadios extends Component<ListStatesRadiosProps, ListStatesRadiosStates> {
    static defaultProps = {
        showInActiveRadio: true,
        showAllRadio: true,
        showActiveRadio: true,
        showPendingRadio: false,
    };
    constructor(props: ListStatesRadiosProps) {
        super(props);
    }
    onChange(e: RadioChangeEvent) {
        this.props.onChange(parseInt(e.target.value));
    }
    renderState(text: string, cnt: number) {
        return (
            <span>
                <span>{fmtMsg(text)}</span>
                {!this.props.hideCnts && <span className="cnt">({cnt || 0})</span>}
            </span>
        )
    }
    render() {
        const { allCnt, activeCnt, futureCount,
            inActiveCnt: inActiveCntProp, inActiveTextLocaleId: inActiveTextLocaleIdProp,
            activeTextLocaleId: activeTextLocaleIdProp, showAllRadio, showActiveRadio, showInActiveRadio, showPendingRadio } = this.props;
        let inActiveCnt = typeof inActiveCntProp === "number" && inActiveCntProp > 0 ? inActiveCntProp : allCnt - activeCnt - (futureCount || 0);
        const activeTextLocaleId = activeTextLocaleIdProp ? activeTextLocaleIdProp : DashboardLocale.ListStatesActive;
        const inActiveTextLocaleId = inActiveTextLocaleIdProp ? inActiveTextLocaleIdProp : DashboardLocale.ListStatesInactive;
        return (
            <div className={mergeClasses("list-states", this.props.className)}>
                <RadioGroup value={this.props.value} defaultValue={ListStates.Active} onChange={this.onChange.bind(this)} >
                    {showAllRadio && <Radio className="all" value={ListStates.All}>{this.renderState(DashboardLocale.ListStatesAll, this.props.allCnt)}</Radio>}
                    {showActiveRadio && <Radio className="active" value={ListStates.Active}>{this.renderState(activeTextLocaleId, this.props.activeCnt)}</Radio>}
                    {this.props.showInActiveRadio && <Radio className="inactive" value={ListStates.Inactive}>{this.renderState(inActiveTextLocaleId, inActiveCnt)}</Radio>}
                    {showPendingRadio && typeof this.props.pendingCount === "number" && <Radio className="pending" value={ListStates.Pending}>{this.renderState(SchoolLocale.TeacherPending, this.props.pendingCount)}</Radio>}
                    {typeof this.props.futureCount === "number" && <Radio className="pending" value={ListStates.Future}>{this.renderState(SchoolLocale.ListStatesFuture, this.props.futureCount)}</Radio>}
                </RadioGroup>
                {this.props.showGroupingButton &&
                    <a href="javascript:" style={{ marginLeft: 'auto' }} onClick={this.props.openGroupMode}>
                        {fmtMsg({ id: DashboardLocale.LandingTabGroupSwitch })}
                    </a>
                }
            </div>
        )
    }
}
export class ListStatesStore {
    constructor(private storeKey, private stateKey, private userKey?) {
        var userSession = OidcProxy.getSessionFromStorage()
        this.userKey = userKey || (userSession ? JSON.parse(userSession).profile.sub : storeKey);
    }
    private get unionKey(): string {
        return `${this.userKey}_${this.storeKey}`;
    }

    setStateKey(key) {
        if (!GLUtil.isNullOrUndefined(key)) {
            this.stateKey = key;
        }
    }
    getStates(id?) {
        id = id || this.stateKey;
        const states = JSON.parse(sessionStorage.getItem(this.unionKey) || "{}");
        return { id, states };
    }
    getState(id?, defaultState?) {
        const { id: key, states } = this.getStates(id);
        return GLUtil.isNullOrUndefined(states[key]) ? (defaultState || ListStates.Active) : states[key];
    }
    setState(state) {
        const { id, states } = this.getStates();
        states[id] = state
        sessionStorage.setItem(this.unionKey, JSON.stringify(states));
    }
    getUrlQuery(page) {
        page.disabled = ListStatesStore.toDisabled(this.getState());
        page.future = this.getState() === ListStates.Future ? true : null;
        page.includePendingInvite = this.getState() === ListStates.All || this.getState() === ListStates.Pending ? true : null;
        page.includeOnlyPending = this.getState() === ListStates.Pending ? true : null;
        return page;
    }
    getUrlQueryParams(page, showPendingUser) {
        page.disabled = ListStatesStore.toDisabled(this.getState());
        page.future = this.getState() === ListStates.Future ? true : null;
        page.includePendingInvite = showPendingUser ? true : null;
        page.includeOnlyPending = showPendingUser ? true : null;
        return page;
    }
    static getStateCnt(total, active, { listState: state }, futureCount = 0, pendingCount = 0, inActiveCnt = null) {
        switch (state) {
            case ListStates.Active:
                return active;
            case ListStates.Inactive:
                return inActiveCnt && typeof inActiveCnt === "number" ? inActiveCnt : total - active;
            case ListStates.Pending:
                return pendingCount;
            case ListStates.Future:
                return futureCount;
            default:
                return total;
        }
    }
    static toDisabled(state) {
        return state === ListStates.All || GLUtil.isNullOrUndefined(state) || /*state === ListStates.Promotion ||*/ state === ListStates.Pending || state === ListStates.Future ? null : !!state;
    }
}
export class ListStatesPortal extends Component<{ selector }, { didMount }> {
    container: any
    constructor(props) {
        super(props);
        this.state = {
            didMount: false
        }
    }

    componentDidMount() {
        const lazyload = () => {
            this.container = document.querySelector(this.props.selector);
            if (!this.container) {
                setTimeout(() => lazyload(), 300)
            } else {
                this.setState({ didMount: true });
            }
        }
        lazyload();
    }

    render() {
        return this.state.didMount ? ReactDOM.createPortal(
            this.props.children,
            this.container,
        ) : null;
    }
}