import wijmoEn from './wijmo-en.js';
import wijmoJa from './wijmo-ja.js';
import wijmoRu from './wijmo-ru.js';
import wijmoZh from './wijmo-zh.js';
import wijmoKo from './wijmo-ko.js';
import wijmoTh from './wijmo-th.js';
import wijmoVi from './wijmo-vi.js';
import wijmoMn from './wijmo-mn.js';
import wijmoMs from './wijmo-ms.js';
import wijmoEs from './wijmo-es.js';
import wijmoAr from './wijmo-ar.js';
import wijmoMy from './wijmo-my.js';
import { LocaleService } from "@app/service/locale";
import { PrimaryLanguageLocale } from "gl-commonui";

export function intlLoaded(locale) {
    const service = new LocaleService();

    return new Promise((resolve, reject) => {
        switch (locale.locale) {
            case PrimaryLanguageLocale.Spanish:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./es.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Spanish)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "es.json"
                );
                wijmoGlobalize(wijmo, wijmoEs);
                break;
            case PrimaryLanguageLocale.Arabic:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./ar-sa.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Arabic)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "ar-sa.json"
                );
                wijmoGlobalize(wijmo, wijmoAr);
                break;
            case PrimaryLanguageLocale.Chinese:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./zh-cn.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Chinese)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "zh-cn.json"
                );
                wijmoGlobalize(wijmo, wijmoZh);
                break;
            case PrimaryLanguageLocale.Russian:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./ru.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Russian)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "ru.json"
                );
                wijmoGlobalize(wijmo, wijmoRu);
                break;
            case PrimaryLanguageLocale.Vietnamese:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./vi.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Vietnamese)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "vi.json"
                );
                wijmoGlobalize(wijmo, wijmoVi);
                break;
            case PrimaryLanguageLocale.Mongolian:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./mn.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Mongolian)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "mn.json"
                );
                wijmoGlobalize(wijmo, wijmoMn);
                break;
            case PrimaryLanguageLocale.Malaysia:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./ms.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Malaysia)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "ms.json"
                );
                wijmoGlobalize(wijmo, wijmoMs);
                break;
            case PrimaryLanguageLocale.Korean:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./ko.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Korean)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "ko.json"
                );
                wijmoGlobalize(wijmo, wijmoKo);
                break;
            case PrimaryLanguageLocale.Japanese:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./ja.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Japanese)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "ja.json"
                );
                wijmoGlobalize(wijmo, wijmoJa);
                break;
            case PrimaryLanguageLocale.Thai:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./th.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Thai)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "th.json"
                );
                wijmoGlobalize(wijmo, wijmoTh);
                break;
            case PrimaryLanguageLocale.Myanmar:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./my.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.Myanmar)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "my.json"
                );
                wijmoGlobalize(wijmo, wijmoMy);
                break;
            default:
                require.ensure(
                    [],
                    () => {
                        const localLocales = require("./en.json");
                        service
                            .getLocaleJson(PrimaryLanguageLocale.English)
                            .then(locales => resolve({...localLocales, ...locales}))
                            .catch(() => resolve(localLocales));
                    },
                    "en.json"
                );
                wijmoGlobalize(wijmo, wijmoEn);
                break;
        }
        localeUISupport(locale);
    })
}

function localeUISupport(locale) {
    const container = document.body;
    const classes = container.className.split(' ');
    classes.forEach(className => {
        if (className && className.indexOf("lang-") === 0) {
            container.classList.remove(className);
        }
    });
    container.classList.add(`lang-${locale.locale}`);
}


/*
 *
 * Wijmo Library 5.20183.568
 * http://wijmo.com/
 *
 * Copyright(c) GrapeCity, Inc.  All rights reserved.
 *
 * Licensed under the GrapeCity Commercial License.
 * sales@wijmo.com
 * wijmo.com/products/wijmo-5/license/
 *
 */
var wijmo = {};
function wijmoGlobalize(e, culture) {
    window.wijmo || (window.wijmo = e), e.culture = window.wijmo.culture = culture;
    var o = window.wijmo._updateCulture;
    o && o(culture)
}
