import { GLServiceBase } from 'gl-commonui'
import { injectable } from '@app/util/di';
import { IUpcomingEventService } from './interface';

@injectable()
export class UpcomingEventService extends GLServiceBase<any, any> implements IUpcomingEventService {
    serviceRoute = { prefix: 'admin/v1/upcomingevents', suffix: '{}' }
    errMsgs = (() => {
        const result = {};
        return result;
    })();
    createUpcomingEvents(params: any) {
        this.create(params);
    }
    getAdminContacts(params: any) {
        return this.get("admincontacts", params);
    }
}