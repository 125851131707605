import { StateType as BaseStateType, States } from "gl-commonui";
import dashboard, { DashboardState } from "./dashboard";
import resource, { ResourceState } from './resource';
import permission, { PermissionState } from './permission';
import cims, { CIMSState } from "./cims";

import adminResource, { ResourceState as AdminResource } from "./admin/resource";
import product, { ProductState } from "./admin/product";

import campus, { CampusState } from "./school/campus";
import school, { SchoolState } from "./school/school";
import schoolClass, { SchoolClassState } from "./school/class";
import admins, { AdminsState } from "./school/admins";
import invitationTemplate, { InvitationTemplateState } from "./invitation-template";
import material, { MaterialState } from "./material";
import invitation, { InvitationState } from "./school/invitation";
import acceptinvitation, { AcceptInvitationState } from './school/acceptinvitation';
import inviting, { InvitingState } from './school/inviting';
import classes, { ClassesState } from './school/classes';
import studentSubscription, { StudentSubscriptionState } from "./school/student-subscription-request";
import survey, { SurveyState } from "./survey/survey";
import surveyMedia, { SurveyMediaState } from "./survey/media";
import region, { RegionState } from "./region/region";
import regionGroup, { RegionGroupState } from "./region/regionGroup";
import uploadStatus, { UploadStatusState } from "./uploadStatus/uploadStatus";
import changeLog, { ChangeLogState } from "./admin/changeLog";
import chatMessage, { ChatMessagesState } from './coach/chatMessage';
import drawer, { DrawerState } from "./drawer";
import link, {LinkState} from './link';
import eventManager, {EventManagerState} from './event-manager';
import tagManager, { TagManagerState } from "./tag-manager/tag-manager";
import classManager, {ClassManagerState} from './school/class-manager'
import notification, {NotificationState} from "@app/states/notification";
export interface StateType extends BaseStateType {
    dashboard: DashboardState
    permission: PermissionState
    resource: ResourceState
    adminResource: AdminResource
    product: ProductState
    campus: CampusState
    school: SchoolState
    schoolClass: SchoolClassState
    admins: AdminsState
    invitationTemplate: InvitationTemplateState
    invitation: InvitationState
    material: MaterialState
    acceptinvitation: AcceptInvitationState
    inviting: InvitingState
    classes: ClassesState,
    studentSubscription: StudentSubscriptionState,
    // template: TemplateState,
    survey: SurveyState,
    surveyMedia: SurveyMediaState,
    region: RegionState,
    regionGroup: RegionGroupState,
    uploadStatus: UploadStatusState,
    changeLog: ChangeLogState,
    cims: CIMSState,
    chatMessage: ChatMessagesState,
    drawer: DrawerState,
    link: LinkState,
    eventManager: EventManagerState,
    tagManager: TagManagerState,
    classManager: ClassManagerState,
    notification: NotificationState,
}

export default [
    dashboard,
    permission,
    resource,
    adminResource,
    product,
    campus,
    school,
    schoolClass,
    admins,
    invitationTemplate,
    invitation,
    material,
    acceptinvitation,
    inviting,
    classes,
    studentSubscription,
    survey,
    surveyMedia,
    region,
    regionGroup,
    uploadStatus,
    changeLog,
    cims,
    chatMessage,
    drawer,
    link,
    eventManager,
    tagManager,
    classManager,
    notification,
]

States.clear({
    dashboard: States.default,
    permission: States.default,
    resource: States.default,
    adminResource: States.default,
    product: States.default,
    campus: States.default,
    school: States.default,
    schoolClass: States.default,
    admins: States.default,
    invitationTemplate: States.default,
    invitation: States.default,
    material: States.default,
    acceptinvitation: States.default,
    inviting: States.default,
    classes: States.default,
    studentSubscription: States.default,
    // survey: States.default,
    surveyMedia: States.default,
    region: States.default,
    regionGroup: States.default,
    // uploadStatus: States.default,
    changeLog: States.default,
    cims: States.default,
    chatMessage: States.default,
    drawer: States.default,
    // link: States.default,
    // notification: States.default,
})
