import React, { Component } from 'react';
import { AutoComplete, Input, Icon, Empty } from 'antd-min';
import { connect } from 'react-redux'
import { HeaderIcon, GLLocale, ResourceType, GLGlobal, StateType, alignPop, RoleName } from 'gl-commonui';
import { SearchService } from '@app/service/school/search';
import { SchoolLocale } from '@app/locales/localeid';
import { htmlEncodeByRegExp, fmtMsg, GSSchoolAction, regExpEscape, ContextHelper } from '@app/util';

import './header-search.less';

interface HeaderSearchState {
    onQuery?: boolean;
    dataSource?: any[];
    searchResult?: JSX.Element[];
    cancelSearch?: boolean;
    canSearch?: boolean;
    hidePlaceHolder: boolean;
}

enum SearchResultCategory {
    School = "School",
    Campus = "Campus",
    SchoolClass = "Class",
    Region = "Region",
    Order = "Order",
    User = "User",
    Student = "Student",
    //Parent = "Parent",
    //Content = 'Content',
    //LessonVideo = 'Lesson/Video',
}

const searchCategoryIntlMap = new Map<string, string>([
    [SearchResultCategory.Region, SchoolLocale.SearchRegions],
    [SearchResultCategory.School, SchoolLocale.SearchSchools],
    [SearchResultCategory.Campus, SchoolLocale.SearchCampuses],
    [SearchResultCategory.SchoolClass, SchoolLocale.SearchClasses],
    [SearchResultCategory.User, SchoolLocale.SearchUsers],
    // [SearchResultCategory.Parent, SchoolLocale.SearchParents],
    [SearchResultCategory.Student, SchoolLocale.SearchStudents],
    [SearchResultCategory.Order, SchoolLocale.SearchOrders],
    // ['Content', SchoolLocale.SearchContents],
    // ['Lesson/Video', SchoolLocale.SearchLessonVideo],
]);

@connect(({ oidc: { loginInfo } }: StateType) => ({
    actionLoaded: loginInfo && loginInfo.loggedin && loginInfo.profile && loginInfo.profile.actions && true,
}))
export class HeaderSearch extends Component<any, HeaderSearchState> {
    private searchService: SearchService;
    private searchId;
    private searchElement;
    private roleText: Map<RoleName, string>;
    private nonClickableClicked: boolean = false;
    constructor(props) {
        super(props);
        this.searchService = new SearchService();
        this.searchId = null;
        this.roleText = ContextHelper.getRoleTextMap();
        this.state = {
            onQuery: false,
            dataSource: [],
            searchResult: null,
            cancelSearch: false,
            canSearch: false,
            hidePlaceHolder: false
        };
        this.beginSearch = this.beginSearch.bind(this);
        this.endSearch = this.endSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.search = this.search.bind(this);
        this.getSearchResultElements = this.getSearchResultElements.bind(this);
        this.getHighlightedText = this.getHighlightedText.bind(this);
        this.renderItemTitle = this.renderItemTitle.bind(this);
        this.renderHitItemContent = this.renderHitItemContent.bind(this);
        this.getRequiredResult = this.getRequiredResult.bind(this);
    }

    beginSearch(e) {
        e.preventDefault();
        this.setState({
            canSearch: true
        });
    }

    endSearch(e) {
        this.setState({
            dataSource: [],
            searchResult: null,
            cancelSearch: true
        });
        if (this.searchElement && this.searchElement.props && this.searchElement.props.value) {
            e.preventDefault();
            return;
        }
        this.setState({
            canSearch: false
        });
    }

    onChange(value) {
        this.setState({
            dataSource: [],
            searchResult: null,
            cancelSearch: true,
            hidePlaceHolder: this.nonClickableClicked
        });
        this.nonClickableClicked = false;
    }

    onMouseUp(e) {
        this.searchElement && this.searchElement.props && this.searchElement.props.value && this.onSearch(this.searchElement.props.value, e);
    }

    onSearch(value, e) {
        e.preventDefault();
        const keyword = value.trim();
        if (!keyword || keyword.length < 2) return;
        var self = this;
        clearTimeout(this.searchId);
        this.searchId = setTimeout(() => self.search(keyword), 20);
        this.setState({ cancelSearch: false });
    }

    onSelect(value, option) {
        if (!value) {
            if (option.props.className === "pending-invite-result") {
                this.nonClickableClicked = true;
                this.setState({ hidePlaceHolder: true });
            }
            return;
        }
        window.open(value, '_self');
    }

    search(keyword) {
        try {
            this.setState({ onQuery: true });
            const searchRequest = [
                this.searchService.searchStructure({ keyword })
            ];
            Promise.all(searchRequest).then(data => {
                const schoolSiteData = data[0];
                this.state.cancelSearch && this.setState({
                    onQuery: false,
                    dataSource: [],
                    searchResult: null
                });
                !this.state.cancelSearch && this.setState({
                    onQuery: false,
                    dataSource: data,
                    searchResult: this.getSearchResultElements(schoolSiteData, keyword)
                });
            });
        } catch (error) {
            console.log(error);
            this.setState({ onQuery: false });
        }
    }

    getRequiredResult(item, searchText) {
        if (item.isCategoryLinksVisible) {
            return item.hits.map((opt, index) => this.renderHitItemContent(item.category, opt, searchText, index));
        }
        else {
            return <AutoComplete.Option key={item.category + 'empty'} value={item.category + 'empty'} title='' disabled={true}>
                {fmtMsg({ id: SchoolLocale.UserSearchMessage })}</AutoComplete.Option>
        }
    }

    getSearchResultElements(resultData?: any[], searchText?: string): JSX.Element[] {
        const elements = resultData.map(item => {
            return (
                <AutoComplete.OptGroup key={item.category} label={this.renderItemTitle(item)}>
                    {this.getRequiredResult(item, searchText)}
                </AutoComplete.OptGroup>
            )
        });
        if (elements.length == 0) {
            return ([
                <AutoComplete.Option key={'empty'} value={'empty'} title='' disabled={true}>
                    <Empty key={'empty'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </AutoComplete.Option>
            ])
        }
        return elements;
    }

    getHighlightedText(text: string, keyword: string, pending: boolean = false) {
        let highlighted = text;
        if (keyword && text) {
            const regExp = new RegExp(regExpEscape(keyword), "i");
            const parts = text.split(regExp);
            let result = '', charIndex = 0;
            for (let index = 0; index < parts.length; index++) {
                result += `<span>${htmlEncodeByRegExp(parts[index])}</span>`;
                charIndex += parts[index].length;
                if (index < parts.length - 1) {
                    let orginalKeywordText = text.substr(charIndex, keyword.length)
                    result += `<mark class='search-mark'>${htmlEncodeByRegExp(orginalKeywordText)}</mark>`;
                }
                charIndex += keyword.length;
            }
            return `<span>${result} ${pending ? fmtMsg({ id: SchoolLocale.AdminsPending }) : ""}</span>`;
        }
        return highlighted;
    }

    renderItemTitle(item): JSX.Element {
        const title = fmtMsg({ id: searchCategoryIntlMap.get(item.category) });
        return (
            <span>{`${title} (${item.hits.length})`}</span>
        );
    }

    renderHitItemContent(category, hitItem, searchText, index: number): JSX.Element {
        const text = hitItem.links && hitItem.links.length > 0 ? hitItem.links[0].title : hitItem.hit;
        const href = hitItem.links && hitItem.links.length > 0 ? hitItem.links[0].url : null;
        const description = this.buildDescription(category, hitItem);
        const pendingInvite = hitItem.extraInfo && hitItem.extraInfo.pendingInvite;

        return (
            <AutoComplete.Option key={hitItem.id ? hitItem.id : index} value={href} title={text} className={pendingInvite ? "pending-invite-result" : ""}>
                {
                    (href &&
                        <div className='item'>
                            <a
                                className='item-link'
                                href={href}
                                target='_self'
                                rel='noopener noreferrer'
                            >
                                <div dangerouslySetInnerHTML={{ __html: this.getHighlightedText(text, searchText) }} />
                            </a>
                            {
                                description &&
                                <p className='description' title={description}>
                                    {description}
                                </p>
                            }
                        </div>)
                }
                {pendingInvite &&
                    <div className='item'>
                        <div dangerouslySetInnerHTML={{ __html: this.getHighlightedText(text, searchText, true) }} />
                        {
                            description &&
                            <p className='description' title={description}>
                                {description}
                            </p>
                        }
                    </div>
                }
                {!href && !pendingInvite && text}
            </AutoComplete.Option>
        )
    }

    getUserRoleNames(roles: RoleName[]) {
        return roles.map(roleName => {
            return fmtMsg({ id: this.roleText.get(roleName) })
        })
    }

    buildDescription(category, hitItem) {
        if (!hitItem.extraInfo || hitItem.extraInfo.resourceType == ResourceType.Region) return null;
        const extraInfo = hitItem.extraInfo;
        const type = fmtMsg({ id: SchoolLocale.SearchResultUserType });
        let descriptions = [];
        switch (category) {
            case SearchResultCategory.Order:
                descriptions.push(...[extraInfo.regionName, extraInfo.schoolName, extraInfo.campusName]);
                break;
            case SearchResultCategory.User:
                descriptions.push(`${type}: ${extraInfo.roles ? this.getUserRoleNames(ContextHelper.sortUserRoles(extraInfo.roles)).join(', ') : ''}`)
                break;
            case SearchResultCategory.Student:
                descriptions.push(...[extraInfo.regionName, extraInfo.schoolName, extraInfo.campusName, extraInfo.schoolClassName]);
                break;
            default:
                extraInfo.resourceType != ResourceType.Region && extraInfo.region && descriptions.push(extraInfo.region.name);
                extraInfo.resourceType != ResourceType.School && extraInfo.school && descriptions.push(extraInfo.school.name);
                extraInfo.resourceType != ResourceType.Campus && extraInfo.campus && descriptions.push(extraInfo.campus.name);
                extraInfo.resourceType != ResourceType.SchoolClass && extraInfo.schoolClass && descriptions.push(extraInfo.schoolClass.name);
                break;
        }
        return `( ${descriptions.join(' > ')} )`
    }

    render() {
        const canSearch = this.state.canSearch;
        const placeHolder = this.state.hidePlaceHolder ? " " : fmtMsg({ id: GLLocale.Search });
        const iconType = this.state.onQuery ? 'loading' : 'search';
        if (!this.props.actionLoaded || ContextHelper.userIsParent(this.props.actionLoaded)) return null;
        return (
            canSearch ?
                <AutoComplete
                    autoFocus={true}
                    className='gl-header-right-item gl-header-right-item-wrapper certain-category-search'
                    defaultOpen={true}
                    dropdownClassName='certain-category-search-dropdown'
                    dataSource={this.state.searchResult}
                    placeholder={placeHolder}
                    optionLabelProp='title'
                    onChange={this.onChange}
                    onSelect={this.onSelect}
                    {...alignPop()}
                >
                    <Input.Search
                        ref={(input) => this.searchElement = input}
                        suffix={
                            <Icon
                                type={iconType}
                                className='certain-category-icon'
                                onMouseUp={this.onMouseUp}
                            />}
                        onSearch={this.onSearch}
                        onBlur={this.endSearch}
                    />
                </AutoComplete> :
                <div className='gl-header-right-item-dropdown'>
                    <HeaderIcon icon='search' matIcon='search' labelId={GLLocale.Search} onClick={this.beginSearch} />
                </div>
        )
    }
}
