
export interface VideoMessageModel {
    courseName: string
    groupName: string
    seriesName: string
    videoName: string
}

export interface VideoViewedModel {
    lastMonthTime: string
    lastMonthVideos: number
    totalTime: string
    totalVideos: number
    videoMessage: VideoMessageModel
}

export interface CourseHistoryModel {
    index: number
    itemId: string
    itemName: string
    courseVersionId: string
    courseName: string
    seriesId: string
    seriesName: string
    date: string
    type: number
}

export enum CourseHistoryPropsModel {
    index = 'index',
    itemId = 'itemId',
    itemName = 'itemName',
    courseVersionId = 'courseVersionId',
    courseName = 'courseName',
    seriesId = 'seriesId',
    seriesName = 'seriesName',
    date = 'date',
    type = 'type'
}


export interface TaskModel {
    id: string;
    description?: string;
    name?: string;
    order?: number;
    version?: string;
    imageUrl?: string;
    updateDate?: string;
    creationDate?: string;
    contentTypeId?: number;
}

export interface AnswerModel {
    id: string;
    answer: string;
    questionId: string;
    isCorrect: boolean;
    isSelected: boolean;
    order: number;
    canEdit: boolean;
}

export interface QuestionModel extends TaskModel {
    question: string;
    answers: AnswerModel[];
    order: number;
    canEdit: boolean;
    questionTypeId: number;
}

export interface QuestionnaireModel extends TaskModel {
    questions: QuestionModel[];
    mandatory: boolean;
    unit?: number;
    isImported?: boolean;
}
