import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IParentService, ParentRequest, GetParentChildrenRequest, ParentChildrenRequest } from './index';

@injectable()
export class ParentService extends GLServiceBase<ParentRequest, any> implements IParentService {
    serviceRoute = { prefix: 'admin/v1/parents/{parentId}', suffix: 'children' }
    addChildren(data: ParentChildrenRequest, routeData: GetParentChildrenRequest) {
        this.serviceRoute = { prefix: 'admin/v1/parents/{parentId}', suffix: 'children' }
        return this.request.post(this.url('child'), data, routeData);
    }
    unlinkChild = (data: {childId: string}): Promise<void> => {
        this.serviceRoute = { prefix: 'admin/v1/parents/{childId}', suffix: 'unlink' }
        return this.request.del(this.url('unlink'), null, data);
    }
    getStudentsByParent(params: any) {
        this.serviceRoute = { prefix: 'admin/v1/parents/{parentId}', suffix: 'GetStudents' }
        return this.get(params);
    }
    editChild(data: any) {
        this.serviceRoute = { prefix: "admin/v1/parents", suffix: "" };
        return this.createWithoutLoader("editchild", data);
    }    
}