import { GLServiceBase } from 'gl-commonui'
import { injectable } from '@app/util/di';
import { ITrainingChatsService, IChatResourceService, IChatTrainingCourseService } from './interface';

@injectable()
export class TrainingChatsService extends GLServiceBase<any, any> implements ITrainingChatsService {

    serviceRoute = { prefix: 'admin/v1/trainingchats', suffix: '{}' }

    errMsgs = (() => {
        const result = {};
        return result;
    })();

    getChatMessages(params: any) {
        return this.get("messages", params);
    }

    createNewMessage(params: any) {
        return this.createWithoutLoader("messages", params);
    }

    makeMessagesRead(params: any) {
        return this.request.put(this.url("messages/{messageId}/read"), params)
    }

    makeAllMessagesRead(params: any) {
        return this.update("messages/reads", params);
    }

    getUnReadMessagesCount(params: any) {
        return this.get("messages/unreads/number", params);
    }

    getAllUnReadMessagesCount(params: any) {
        return this.get("messages/unreads/numbers", params);
    }

    async getAllUnReadBadges(senderIds) {
        const data = await this.getAllUnReadMessagesCount({senderIds: senderIds});
        return data.reduce((map, v) => {
            map[v.senderId] = v.unReadNumber;
            return map;
        }, {});
    }

    async getBadgeCount(id) {
        const data = this.getUnReadMessagesCount({ senderId: id })
        return data;
    }
}

@injectable()
export class ChatResourceService extends GLServiceBase<any, any> implements IChatResourceService {

    serviceRoute = { prefix: 'content/v1/resource' }

    errMsgs = (() => {
        const result = {};
        return result;
    })();

    getAllowVersion(params: any) {
        return this.get("GetAllowedVersion", params);
    }

    getUserSetting(params: any) {
        return this.get("GetUserSettings", params);
    }

    getResources(params: any) {
        return this.get(params);
    }
}

@injectable()
export class ChatTrainingCourseService extends GLServiceBase<any, any> implements IChatTrainingCourseService {
    serviceRoute = { prefix: 'admin/v1/Content' }

    errMsgs = (() => {
        const result = {};
        return result;
    })();


    getAllowVersion() {
        this.serviceRoute = { prefix: 'admin/v1/Content' }
        return this.get("GetAllowedVersionGroups");
    }

    getCourses(params: any) {
        this.serviceRoute = { prefix: 'training/v1/teachercourse' }
        return this.get(params);
    }
}