import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Pagination, Empty, Spin } from "antd-min";
import { TableProps, ColumnProps, PrepareParamsArgumentsReturn } from "antd/lib/table";
import { PaginationConfig, PaginationProps } from "antd/lib/pagination";
import { CollectionView, SortDescription, toggleClass, setCss } from "wijmo/wijmo";
import { FlexGridXlsxConverter } from "wijmo/wijmo.grid.xlsx";
import {
    FlexGrid,
    GridPanel,
    CellRange,
    CellType,
    MergeManager,
    GroupRow,
    SelectionMode,
    AllowDragging,
    AllowMerging,
    AllowResizing,
    AutoSizeMode,
    HeadersVisibility,
    FormatItemEventArgs,
    CellRangeEventArgs,
} from "wijmo/wijmo.grid";
import { FlexGrid as ReactFlexGrid } from "wijmo/wijmo.react.grid";
import { GroupPanel as GridGroupPanel } from "wijmo/wijmo.grid.grouppanel";
import { GroupPanel } from "wijmo/wijmo.react.grid.grouppanel";
import { FlexGridFilter } from "wijmo/wijmo.react.grid.filter";
import { GLGridMergeManager, MergedRowsStruct } from "./mergemanger";
import { fmtMsg, htmlEncodeByRegExp } from "@app/util";
import { StateType } from "@app/states";
import { SchoolLocale } from "@app/locales/localeid";
import { diagnosticLogError, GLGlobal } from "gl-commonui";
import "./gl-grid.less";
import { wijmoGridResponsive } from "@app/util/wijmo";
import { FilterType } from "wijmo/wijmo.grid.filter";
import { withRouter } from 'gl-commonui';
import {FlexGridContextMenu} from "@app/components/grid/context-menu";

export { FlexGrid, CellType, GridPanel, CellRange, MergeManager, FlexGridXlsxConverter, toggleClass };

export { GLGridMergeManager } from "./mergemanger";

export type GLGridMergeRange = { row: number; columnIndex: number; columnName?: string; count: number };

export type GLGridSorter = { columnName: string; columnSortName: string; ascending: boolean };

export interface GLGridColumnProps<T> extends ColumnProps<T> {
    titleText?: string;
    visible?: boolean;
    allowMerging?: boolean;
    allowDragging?: boolean;
    dataMap?: any;
    render?: (text: any, record: T, index: number) => string;
    wordWrap?: boolean;
    minWidth?: number;
    maxWidth?: number;
}

interface GLGridProps<T> extends TableProps<T> {
    keyValue?: string;
    allowSorting?: boolean;
    allowDragging?: AllowDragging;
    allowGrouping?: boolean;
    allowMerging?: AllowMerging;
    allowResizing?: AllowResizing;
    autoSizeMode?: AutoSizeMode;
    filterType?: FilterType;
    filterChange?: number;
    sortInClient?: boolean;
    pagingInClient?: boolean;
    frozenColumns?: number;
    frozenRows?: number;
    headersVisibility?: HeadersVisibility;
    isReadOnly?: boolean;
    selectionMode?: SelectionMode;
    defaultSorter?: GLGridSorter;
    columns?: GLGridColumnProps<T>[];
    mergeDataKey?: string;
    mergingColumns?: Map<string, number>;
    mergedRowsStruct?: MergedRowsStruct[];
    langLoaded?: boolean;
    loading: boolean;
    autoResponsive?: boolean;
    style?: any;
    onColumnSorting?: (sorter: GLGridSorter) => void;
    onLoadingRows?: (event) => void;
    onLoadedRows?: (event) => void;
    onGridLoaded?: (grid) => void;
    onFilter?: (item) => boolean;
    onFormatGroupPanel?: (groupHtml, groupData) => string;
    onGridClick?: (event) => void;
    onFormatCell?: (grid, record, event) => void;
    onInitializeGrid?: (grid) => void;
    onFormatColumnHeader?: (grid, event) => void;
    onGridViewChanged?: (changedView) => void;
    allowAddNew?: boolean;
    allowDelete?: boolean;
    onPasting?: (grid, event) => void;
    onDeletedRow?: (event) => void;
    onRowEditStarted?: (event) => void;
    overrideResize?: (s: FlexGrid,e, resize: () => void) => void;
    gridId?: string;
    columnComponents?: any;
    preserveSort?: boolean;
}

interface GLGridStates {
    view?: CollectionView;
    pagination?: PaginationProps | false;
    sorter?: GLGridSorter;
    mergeManager?: GLGridMergeManager;
}

function noop() {}

const defaultPagination = {
    onChange: noop,
    onShowSizeChange: noop,
};

const emptyObject = {};
@connect(({ intl: { langLoaded } }: StateType) => ({ langLoaded }))
export class GLGrid<T> extends Component<GLGridProps<T>, GLGridStates> {
    static defaultProps = {
        allowSorting: true,
        allowDragging: AllowDragging.None,
        allowGrouping: false,
        allowMerging: AllowMerging.None,
        allowResizing: AllowResizing.None,
        autoSizeMode: AutoSizeMode.Both,
        filterType: FilterType.None,
        filterVersion: 0,
        sortInClient: false,
        frozenColumns: 0,
        frozenRows: 0,
        headersVisibility: HeadersVisibility.Column,
        isReadOnly: true,
        selectionMode: SelectionMode.ListBox,
        allowAddNew: false,
        allowDelete: false,
        autoResponsive: true,
    };

    private filterApplied: () => void;
    grid: FlexGrid;
    groupPanel: GridGroupPanel;
    private resizingRows;

    constructor(props: GLGridProps<T>, context: GLGridStates) {
        super(props, context);

        const defaultPagination = this.getDefaultPagination(props);
        const view =
            props.pagination && props.pagingInClient
                ? new CollectionView([], { pageSize: defaultPagination.pageSize })
                : new CollectionView([]);
        view.collectionChanged.addHandler(() => {
            const { onGridViewChanged } = this.props;
            onGridViewChanged && onGridViewChanged(view);
        });
        this.state = {
            view: view,
            pagination: defaultPagination,
            mergeManager: null,
        };
        this.resizingRows = false;
        this.grid = null;
        this.filterApplied = () => {
            let view = this.state.view;
            this.setState({ view: view });
        };
        this.onSortingColumn = this.onSortingColumn.bind(this);
        this.initGrid = this.initGrid.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.autoSizeRows = this.autoSizeRows.bind(this);
        this.itemFormatter = this.itemFormatter.bind(this);
        this.loadDataFromExcel = this.loadDataFromExcel.bind(this);
    }

    getDefaultPagination(props: TableProps<T>): PaginationProps {
        const pagination: PaginationConfig = typeof props.pagination === "object" ? props.pagination : {};
        let current: number;
        if ("current" in pagination) {
            current = pagination.current;
        } else if ("defaultCurrent" in pagination) {
            current = pagination.defaultCurrent;
        }
        let pageSize: number;
        if ("pageSize" in pagination) {
            pageSize = pagination.pageSize;
        } else if ("defaultPageSize" in pagination) {
            pageSize = pagination.defaultPageSize;
        }
        return this.hasPagination(props)
            ? {
                  ...defaultPagination,
                  ...pagination,
                  current: current || 1,
                  pageSize: pageSize || 30,
              }
            : {};
    }

    hasPagination(props?: any) {
        return (props || this.props).pagination !== false;
    }

    componentWillReceiveProps(nextProps: GLGridProps<T>) {
        const { mergeDataKey, mergingColumns } = nextProps;
        const defaultPagination = this.getDefaultPagination(nextProps);
        if ("pagination" in nextProps || "pagination" in this.props) {
            this.setState((previousState) => {
                const newPagination = {
                    ...defaultPagination,
                    ...previousState.pagination,
                    ...nextProps.pagination,
                };
                newPagination.current = newPagination.current || 1;
                newPagination.pageSize = newPagination.pageSize || 30;
                return { pagination: nextProps.pagination !== false ? newPagination : emptyObject };
            });
        }
        if (
            "dataSource" in nextProps &&
            (nextProps.dataSource !== this.props.dataSource ||
                nextProps.dataSource != this.state.view.sourceCollection ||
                nextProps.pagingInClient != this.props.pagingInClient ||
                nextProps.defaultSorter != this.props.defaultSorter)
        ) {
            let view = this.state.view;
            view.sourceCollection = nextProps.dataSource;
            if (nextProps.pagingInClient != this.props.pagingInClient) {
                view.pageSize = defaultPagination.pageSize;
            }
            if (nextProps.onFilter) {
                view.filter = nextProps.onFilter;
            }
            const defaultSorter = nextProps.defaultSorter;
            const isDataSourceLengthDifferent = nextProps.dataSource.length != this.props.dataSource.length;
            if (defaultSorter && nextProps.sortInClient && (!nextProps.preserveSort || isDataSourceLengthDifferent)) {
                //when sort data in client, we should set sortDescription on view after load datasource from server.
                view.sortDescriptions.clear();
                view.sortDescriptions.push(new SortDescription(defaultSorter.columnSortName, defaultSorter.ascending));
            }
            if (
                defaultSorter &&
                (!this.state.sorter ||
                    (this.state.sorter != defaultSorter && nextProps.pagingInClient != this.props.pagingInClient))
            ) {
                this.setState({
                    sorter: { ...defaultSorter },
                });
            }
            let mergeManager =
                mergeDataKey && mergingColumns
                    ? new GLGridMergeManager(
                          this.grid,
                          GLGridMergeManager.getMergedRanges(nextProps.dataSource, mergeDataKey, mergingColumns)
                      )
                    : null;
            mergeManager = nextProps.mergedRowsStruct
                ? new GLGridMergeManager(
                      this.grid,
                      GLGridMergeManager.getMergedRowsRanges(
                          nextProps.dataSource,
                          nextProps.mergedRowsStruct,
                          nextProps.columns
                      ),
                      {
                          sortInClient: nextProps.sortInClient,
                          mergedRowsStruct: nextProps.mergedRowsStruct,
                          columns: nextProps.columns,
                      }
                  )
                : null;
            this.setState({
                view: view,
                mergeManager: mergeManager,
            });
        }
        if (
            "filterChange" in nextProps &&
            nextProps.filterType === FilterType.None &&
            nextProps.filterChange !== this.props.filterChange &&
            nextProps.onFilter
        ) {
            let view = this.state.view;
            view.filter = nextProps.onFilter;
            view.refresh();
        }
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.groupPanel && this.groupPanel.invalidate(true);
            this.grid && this.grid.invalidate(true);
        }
    }

    renderItem(s: FlexGrid, e: FormatItemEventArgs) {
        const flex = s;
        const {
            columns,
            rowClassName,
            allowGrouping,
            onFormatGroupPanel,
            onFormatCell,
            onFormatColumnHeader,
        } = this.props;
        if (allowGrouping && e.panel.rows[e.row] instanceof GroupRow && e.panel.cellType != CellType.RowHeader) {
            if (onFormatGroupPanel) {
                const result = onFormatGroupPanel(e.cell.innerHTML, e.panel.rows[e.row]);
                if (result) {
                    e.cell.innerHTML = result;
                }
            }
            if (flex.hostElement && e.col <= flex.collectionView.groupDescriptions.length && e.cell.innerHTML.length) {
                toggleClass(e.cell, "wj-wrap", false);
                const root = flex.hostElement.querySelector("[wj-part=root]");
                root && setCss(e.cell, { width: root.clientWidth, zIndex: 100 });
            }
        } else {
            if (e.panel.cellType == CellType.Cell) {
                const column = columns ? columns.find((column) => column.dataIndex == flex.columns[e.col].binding) : null;
                if (column && column.render) {
                    const cellData = flex.rows[e.row].dataItem[column.dataIndex];
                    if (typeof cellData === "string") {
                        e.cell.innerHTML = column.render(
                            htmlEncodeByRegExp(cellData),
                            flex.rows[e.row].dataItem,
                            e.row
                        );
                    } else {
                        e.cell.innerHTML = column.render(cellData, flex.rows[e.row].dataItem, e.row);
                    }
                }
                if (rowClassName) {
                    flex.rows[e.row].cssClass = rowClassName(flex.rows[e.row].dataItem, e.row);
                }
                if (onFormatCell) {
                    onFormatCell(flex, flex.rows[e.row].dataItem, e);
                }
            }
        }
        if (e.panel.cellType == CellType.ColumnHeader) {
            e.cell.innerHTML = '<span class="v-transform">' + e.cell.innerHTML + "</span>";
            if (onFormatColumnHeader) {
                onFormatColumnHeader(flex, e);
            }
        }
    }

    initGrid(grid: FlexGrid) {

        // Adjust row height
        grid.rows.defaultSize = 40;
        grid.columnHeaders.rows.defaultSize = 40;

        const { autoResponsive, onGridClick, onInitializeGrid } = this.props;
        // responsive the grid
       autoResponsive && wijmoGridResponsive(grid);

        if (onGridClick) {
            grid.addEventListener(grid.hostElement, "click", onGridClick);
        }
        if (onInitializeGrid) {
            onInitializeGrid(grid);
        }

        // Render context menu
        new FlexGridContextMenu(grid);
    }

    autoSizeRows(s, e) {
        const resize = () => {
            if (!this.props.autoResponsive && !this.resizingRows) {
                this.resizingRows = true;
                s.autoSizeRows(null, null, true); // header
                s.autoSizeRows(null, null, false); // data
                setTimeout(() => {
                    this.resizingRows = false;
                }, 100);
            }
        }
        if (this.props.overrideResize) {
            this.props.overrideResize(s,e,resize);
            return;
        } else {
            resize();
        }
    }

    getGridColumns(props: GLGridProps<T>) {
        const groupedColumns = this.state.view.groupDescriptions.map((gd: any) => gd.propertyName);
        return (
            props.columns &&
            props.columns.map((column) => {
                const width = typeof column.width == "string" ? column.width.replace("%", "*") : column.width; // : `${column.width}*`;
                return {
                    binding: column.dataIndex,
                    header: column.titleText ? column.titleText : fmtMsg({ id: column.title as string }),
                    width: width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    cssClass: column.className,
                    align: column.align,
                    wordWrap: (column.wordWrap === undefined) ? true : column.wordWrap,
                    allowSorting: column.sorter == undefined ? true : column.sorter,
                    visible: column.visible == undefined ? !groupedColumns.includes(column.dataIndex) : column.visible,
                    allowMerging: column.allowMerging == undefined ? false : column.allowMerging,
                    // dataMap: column.dataMap, // done because of version update
                    allowDragging:
                        column.allowDragging === undefined
                            ? [AllowDragging.Columns, AllowDragging.Both].includes(props.allowDragging)
                            : column.allowDragging,
                };
            })
        );
    }

    handlePageChange = (current: number, ...otherArguments: any[]) => {
        const props = this.props;
        const pagination: PaginationProps = { ...this.state.pagination };
        if (current) {
            pagination.current = current;
        } else {
            pagination.current = pagination.current || 1;
        }
        pagination.onChange!(pagination.current, ...otherArguments);

        const newState = {
            pagination,
        };
        // Controlled current prop will not respond user interaction
        if (props.pagination && typeof props.pagination === "object" && "current" in props.pagination) {
            newState.pagination = {
                ...pagination,
                current: (this.state.pagination as PaginationProps).current,
            };
        }
        this.setState(newState);

        const { onChange } = this.props;
        if (onChange) {
            onChange.apply(
                null,
                this.prepareParamsArguments({
                    ...this.state,
                    selectionDirty: false,
                    pagination,
                })
            );
        }

        const { pagination: paging, pagingInClient } = this.props;
        if (paging && pagingInClient) {
            let view = this.state.view;
            const pageIndex = pagination.current - 1;
            if (view.moveToPage(pageIndex)) {
                this.setState({ view: view });
            }
        }
    };

    // Get pagination, filters, sorter
    prepareParamsArguments(state: any): PrepareParamsArgumentsReturn<T> {
        const pagination = { ...state.pagination };
        // remove useless handle function in Table.onChange
        delete pagination.onChange;
        delete pagination.onShowSizeChange;
        const filters = state.filters;
        const sorter: any = {};

        if (state.sorter && state.sorter.columnName) {
            sorter.columnName = state.sorter.columnName;
            sorter.ascending = state.sorter.ascending;
        }

        const extra = {
            currentDataSource: this.state.view.sourceCollection,
        };

        return [pagination, filters, sorter, extra];
    }

    getMaxCurrent(total: number) {
        const { current, pageSize } = this.state.pagination as PaginationProps;
        if ((current! - 1) * pageSize! >= total) {
            return Math.floor((total - 1) / pageSize!) + 1;
        }
        return current;
    }

    renderPagination(prefixCls: string, paginationPosition: string) {
        if (!this.hasPagination()) {
            return null;
        }
        let size = "default";
        const pagination = this.state.pagination as PaginationConfig;
        if (pagination.size) {
            size = pagination.size;
        } else if (this.props.size === "middle" || this.props.size === "small") {
            size = "small";
        }
        const position = pagination.position || "bottom";
        const total =
            pagination.total ||
            (this.state.view.filter ? this.state.view.totalItemCount : this.state.view.sourceCollection.length);
        return total > 0 && (position === paginationPosition || position === "both") ? (
            <Pagination
                key={`pagination-${paginationPosition}`}
                {...pagination}
                className={classNames("gl-grid__pagination", pagination.className, `${prefixCls}-pagination`)}
                onChange={this.handlePageChange}
                hideOnSinglePage={true}
                total={total}
                size={size}
                current={this.getMaxCurrent(total)}
            />
        ) : null;
    }

    onSortingColumn(s: FlexGrid, e: CellRangeEventArgs) {
        const { sortInClient, onColumnSorting } = this.props;
        const flexGrid = s;
        const columnName = flexGrid.columns[e.col].binding;
        const ascending =
            this.state.sorter && columnName == this.state.sorter.columnSortName ? !this.state.sorter.ascending : true;
        this.setState({
            sorter: { columnName, columnSortName: columnName, ascending },
        });
        if (sortInClient) return;
        //cancel grid sort behavior, but need keep sorting column ui style
        e.cancel = true;
        //call server sorting process
        onColumnSorting && onColumnSorting({ columnName, columnSortName: columnName, ascending });
    }
    onSortedColumn(s, e) {
        s.mergeManager && s.mergeManager.rebuildMergeRange && s.mergeManager.rebuildMergeRange(s);
    }

    itemFormatter(panel: GridPanel, row: number, col: number, cell: HTMLElement) {
        if (panel.cellType == CellType.ColumnHeader) {
            const column = panel.columns[col];
            if (this.state.sorter && this.state.sorter.columnName == column.binding) {
                cell.innerHTML = `${column.header} <span class="${
                    this.state.sorter.ascending ? "wj-glyph-up" : "wj-glyph-down"
                }"></span>`;
            }
        }
    }

    loadDataFromExcel = () => {};

    renderGrid(gridProps: GLGridProps<T>) {
        const {
            keyValue,
            allowSorting,
            allowDragging,
            allowGrouping,
            allowMerging,
            allowResizing,
            autoSizeMode,
            className,
            headersVisibility,
            isReadOnly,
            selectionMode,
            frozenColumns,
            frozenRows,
            loading,
            onLoadingRows,
            onLoadedRows,
            onGridLoaded,
            allowAddNew,
            allowDelete,
            onPasting,
            onDeletedRow,
            onRowEditStarted,
            style,
            columnComponents
        } = gridProps;
        const gridDataSource = this.state.view;
        gridDataSource.sourceCollection = gridProps.dataSource;
        return (
            <div className="gl-grid wgrid">
            <Spin spinning={loading}>
                {allowGrouping && (
                    <GroupPanel
                        ref={(groupPanel) => {
                            if (groupPanel && groupPanel.control) {
                                this.groupPanel = groupPanel.control;
                            }
                        }}
                        className="group-panel"
                        grid={this.grid}
                        placeholder={fmtMsg({ id: SchoolLocale.WijmoGroupPanelDragColumnsText })}
                    />
                )}
                <CatchWijmoErrorBoundary>
                    <ReactFlexGrid
                        key={keyValue}
                        ref={(grid) => {
                            if (grid && grid.control) {
                                this.grid = grid.control;
                                if (onGridLoaded) {
                                    onGridLoaded(this.grid);
                                }
                            }
                        }}
                        id={gridProps.gridId ? gridProps.gridId : "gs-wj-grid-wrapper"}
                        allowSorting={allowSorting}
                        allowDragging={allowDragging}
                        allowMerging={allowMerging}
                        allowResizing={allowResizing}
                        autoSizeMode={autoSizeMode}
                        selectionMode={selectionMode}
                        alternatingRowStep={0}
                        isReadOnly={isReadOnly}
                        headersVisibility={headersVisibility}
                        autoGenerateColumns={false}
                        frozenColumns={frozenColumns}
                        frozenRows={frozenRows}
                        style={style ? style : {}}
                        className={className}
                        columns={!columnComponents ? this.getGridColumns(this.props) : undefined}
                        itemsSource={gridDataSource}
                        initialized={this.initGrid}
                        formatItem={this.renderItem}
                        updatedLayout={this.autoSizeRows}
                        loadingRows={onLoadingRows}
                        loadedRows={onLoadedRows}
                        sortingColumn={this.onSortingColumn}
                        sortedColumn={this.onSortedColumn}
                        itemFormatter={this.itemFormatter}
                        mergeManager={this.state.mergeManager}
                        allowAddNew={allowAddNew}
                        allowDelete={allowDelete}
                        pasting={onPasting}
                        onDeletedRow={onDeletedRow}
                        onRowEditStarted={onRowEditStarted}
                    >
                        <FlexGridFilter filterApplied={this.filterApplied} defaultFilterType={this.props.filterType} />
                        {columnComponents}
                    </ReactFlexGrid>
                </CatchWijmoErrorBoundary>
            </Spin>
            </div>
        );
    }

    renderEmptyText(emptyText) {
        const { dataSource, allowAddNew } = this.props;
        if (dataSource.length > 0 || allowAddNew) {
            return null;
        }
        return (
            <div className="ant-table-placeholder" key="emptyText">
                {typeof emptyText === "function" ? emptyText() : emptyText}
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderPagination("ant-table", "top")}
                {this.renderGrid(this.props)}
                {this.renderPagination("ant-table", "bottom")}
                {this.renderEmptyText(<Empty />)}
            </React.Fragment>
        );
    }
}
class CatchWijmoErrorBoundary extends React.Component<any, { hasError }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        diagnosticLogError({ error });
    }

    render() {
        return this.state.hasError ? null : this.props.children;
    }
}
function getCatchWijmoErrorCount() {
    return parseInt(sessionStorage.getItem("catch-wijmo-error") || "0");
}
function setCatchWijmoErrorCount(cnt?) {
    sessionStorage.setItem("catch-wijmo-error", `${cnt || 1 + getCatchWijmoErrorCount()}`);
}
