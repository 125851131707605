import * as React from "react";
import { Actions } from "../action/action";
import "./page-header.less";

interface PageHeaderProps {
    title: string;
    subtitle?: string;
    actions?: JSX.Element[];
    materialIcon?: string;
}

export const PageHeader: React.StatelessComponent<PageHeaderProps> = (props: PageHeaderProps) => {
    const { title, subtitle, actions, materialIcon } = props;
    return (
        <div className="page-header">
            <div className="page-header__content">
                <h2 className="page-header__title">{materialIcon && <i className="material-icons">{materialIcon}</i>}{title}</h2>
                {subtitle && <p className="page-header__subtitle">{subtitle}</p>}
            </div>
            {actions && <Actions actions={actions} />}
        </div>
    );
};
