import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface SchoolLicenseModel {
    id: string
    campus: string
    schoolClassId: string
    schoolClassName: string
    billingDate: number
    licenseType: number
    digitalLicenses: number
    textbookLicenses: number
    studentCount: number
    subscriptionType: number
}

export enum SchoolLicenseModelPropNames{
    id = 'id',
    campus = 'campus',
    schoolClassId = 'schoolClassId',
    schoolClassName = 'schoolClassName',
    billingDate = 'billingDate',
    licenseType = 'licenseType',
    digitalLicenses = 'digitalLicenses',
    textbookLicenses = 'textbookLicenses',
    studentCount = 'studentCount',
    subscriptionType = 'subscriptionType'
}

export interface SchoolInvoiceModel {
    invoiceStartDate: string;
    invoiceEndDate: string;
    invoiceDate: string;
    licenseBillingHistory: LicenseBillingHistoryModel[];
}

export interface LicenseBillingHistoryModel {
    schoolClassId: string;
    schoolClass: string;
    campusId: string;
    campus: string;
    licenseType?: string;
    licenseTypeId?: number;
    digitalLicenseCount: number;
    textbookLicenseCount: number;
    studentCount: number;
}

export interface AdjustmentGroupModel {
    id: string; // some unique id.
    billingDate: Date | string;
    cannotModify?: boolean;
    details: AdjustmentDetailGroupModel[];
}

export interface AdjustmentDetailGroupModel {
    id: string;
    adjustmentId: string;
    billingDate?: Date | string;
    adjustment: number;
    updateDate: Date | string;
    enabled: boolean;
    updatedBy: string;
    type: AdjustmentType;
}

export enum AdjustmentType {
    System = 0,
    Manual = 1
}

export interface InvoiceRuntimeRegenerationModel {
    actualBefore: number;
    actualAfter: number;
    manualAdjustmentBefore: number;
    manualAdjustmentAfter: number;
    autoAdjustmentBefore: number;
    autoAdjustmentAfter: number;
    totalBilledBefore: number;
    totalBilledAfter: number;
    schoolClassId: string;
    campusId: string;
    schoolClass: string;
    campus: string;
}

export enum LicenseBillingHistoryModelPropNames {
    schoolClassId = "schoolClassId",
    schoolClass = "schoolClass",
    campusId =  "campusId",
    campus = "campus",
    licenseType = "licenseType",
    licenseTypeId = "licenseTypeId",
    digitalLicenseCount = "digitalLicenseCount",
    textbookLicenseCount = "textbookLicenseCount",
    studentCount = "studentCount",
    updateDate = "updateDate"
}

export interface UpdateAdjustmentResultModel {
    isSuccess: boolean;
    processResults: UpdateAdjustmentResultCode[];
}

export enum UpdateAdjustmentResultCode {
    AdjustmentUpdated = 200201,
    InvoiceUpdated = 200202,
    InvoiceNotFound = 404400
}

export interface getClassInvoiceInfoParmas { regionId: string, schoolId: string, classId: string }
export interface SchoolLicenseEditModel {
    id:string
    studentCount: number
    textbookLicenses?: number
    digitalLicenses?: number
    updatedBy: string
}

export enum SchoolLicenseEditModelPropNames {
    studentCount = "studentCount",
    textbookLicenses = "textbookLicenses",
    digitalLicenses = "digitalLicenses",
    updatedBy = "updatedBy"
}

export class SchoolLicensePagingRequest extends GLPagingRequest {
    schoolId?: string
    regionBillingHistoryId?: string
}
export interface SchoolLicenseRequest extends GLRequest, SchoolLicenseModel { }
export interface SchoolResponse extends GLResponse, SchoolLicenseModel { }