import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface NotificationModel {
    id?: string,
    title?: string,
    description?: string,
    startDate?: string,
    endDate?: string,
    notificationType?: number,
    sticky?: boolean,
    channel?: number,
    createdBy?: string,
    creationDate?: string,
    status?: NotificationStatus,
    roleTargets?: NotificationRoleTargetModel[],
    isAdvanced?: boolean,
    advancedDescription?: string
}

export interface NotificationRoleTargetModel {
    role: NotificationRoles,
    targetType: NotificationTargetTypes,
    targetId: string,
    targetName: string,
    isCalculated?: boolean
}
export interface NotificationTargetCount {
    roleTargets: NotificationRoleTargetModel[],
    notifiedUserCount: number
}
export class GetNotificationRequest extends GLPagingRequest {
}
export interface NotificationRequest extends GLRequest, NotificationModel {
}
export interface NotificationResponse extends GLResponse, NotificationModel {
}

export enum NotificationRoles {
    All = -1,
    Teacher = 0,
    Parent = 1,
    CampusAdmin = 2,
    SchoolAdmin = 3,
    RegionAdmin = 4,
    TrainingAdmin = 5,
    Trainer = 6,
    GlobalHead = 7,
    AccountManager = 9,
    TrainingManager = 10,
    IndividualUser = 89
}
export enum NotificationRoleNames {
    All = 'All',
    Teacher = 'Teacher',
    Parent = 'Parent',
    CampusAdmin = 'Campus Administrator',
    SchoolAdmin = 'School Administrator',
    RegionAdmin = 'Region Administrator',
    TrainingAdmin = 'Training Administrator',
    Trainer = 'Coach',
    GlobalHead = 'GlobalHead',
    AccountManager = 'Account Manager',
    IndividualUser = 'Individual User',
    TrainingManager = 'Training Manager',
}
export enum NotificationTargetTypes {
    All = 0,
    Region = 1,
    School = 2,
    Campus = 3,
    SchoolClass = 4,
    IndividualUser = 5,
}
export enum NotificationTargetTypeNames {
    All = 'All',
    Region = 'Region',
    School = 'School',
    Campus = 'Campus',
    SchoolClass = 'School Class',
    IndividualUser = 'Individual User',
}
export enum NotificationStatus {
    Submitted = 0,
    Approved = 1,
    Rejected = 2
}
