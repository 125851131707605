export const mergeClasses = (...classes: string[]) => {
    return classes.filter(c => !!c).join(" ");
};

export const GetDocumentHeight = () => {
    const body = document.body;
    const html = document.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
};
