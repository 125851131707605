import { GLServiceBase } from "gl-commonui";
import { injectable } from "../../../util/di";
import { ISurveyReportService } from "./interface";
import { DigitalAdoptionReportRequest, GetDigitalAdoptionReportRequest } from "./model";

@injectable()
export class SurveyReportService extends GLServiceBase<DigitalAdoptionReportRequest, GetDigitalAdoptionReportRequest> implements ISurveyReportService {
    prefix = "admin/v1/reports";
    serviceRoute = { prefix: this.prefix, itemSuffix: "" };
    getDigitalAdoptionReport(params: any) {
        this.serviceRoute = { prefix: `${this.prefix}/surveydigitaladoptionreport`, itemSuffix: "" };
        return this.get(params);
    }

    getSchoolAndClassesReport(params: any) {
        this.serviceRoute = { prefix: `${this.prefix}/pollschoolclassv2`, itemSuffix: "" };
        return this.get(params);
    }

    getLicenseHistoryReport(params: any) {
        this.serviceRoute = {prefix: `admin/v1/regions/dashboard`, itemSuffix: ""};
        return this.get(params);
    }
}
