export enum TagEntity {
    School = 1,
    Teacher = 2,
    Campus = 3,
    SchoolClass = 4
}
export interface TagTypeModel {
    id: TagEntity;
    name: string;
}

export interface TagModel {
    id: number;
    name: string;
    tagEntityTypeId: TagEntity;
    usage: number;
}
export interface TagListModel {
    id: number;
    name: string;
}
export interface TagUsageModel {
    id: string;
    name: string;
}

export interface TagCreateRequestModel {
    tagEntityTypeId: TagEntity;
    name: string;
    regionId: string;
}

export interface TagUpdateRequestModel extends TagCreateRequestModel {
    id: number;
}

export interface EntityTagsModel {
    entityId: string;
    tags: TagListModel[];
}
