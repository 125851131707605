import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface ResourceModel {

}

export class GetResourceRequest extends GLPagingRequest {
    ids?: string[]
}

export interface ResourceRequest extends GLRequest, ResourceModel {}

export interface ResourceResponse extends GLResponse, ResourceModel {}