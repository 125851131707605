import { Container, GLIcon, SecondaryTitle, SubmitBtns } from "@app/components";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import "@app/components/register/index.less";
import { PathConfig } from "@app/config/pathconfig";
import { useService } from "@app/hooks";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { IRegionService } from "@app/service/admin/regions";
import { ResourceService } from "@app/service/school/resource";
import { ISchoolService, SchoolService } from "@app/service/schools";
import { TagEntity, TagListModel } from "@app/service/tag-manager";
import { IUserService, UserModel } from "@app/service/users";
import { StateType } from "@app/states";
import {
    clearPreview, emailConfirmBing, getInvitations, grantGSAccess, hideModal, initTemplates, invite, previewShow, refreshData, remove, removePending, reSendInvitation, setContact, setMainTeacher, showModal
} from "@app/states/school/invitation";
import { GLRegistrationLocalizationHelper, isPhoneRegistrationEnable } from "@app/util";
import { lazyInject } from "@app/util/di";
import { ContactType, GSAdminAction, GSSchoolAction, InvitationChannel, LoginIdType, ManageAssociationModalType } from "@app/util/enum";
import { fmtMsg, onSubmit } from "@app/util/func";
import { TYPES } from "@app/util/types";
import { Select } from "antd"; //to solve ref propery not work for [Select] component in "antd-min"
import { Checkbox, Form, Input, Modal, Spin, Table } from "antd-min";
import {
    connect, defaultPhoneCountryCodeList, FormHelper, GLForm,
    GLFormComponentProps, GLGlobal,
    GLLocale, GLRouteComponentProps, GLUtil, InvitationType, LoginId, maskThrottle, MessageHelper,
    NotificationType, RoleName, unmaskThrottle, validateLoginId, withRouter
} from "gl-commonui";
import * as React from "react";
import { Component, useEffect, useState } from "react";
import { PreviewModal } from "../gl-preview";
import { GLLoginIdInput } from "../register/gl-login-input";
import { Action } from "../survey";
import { Tagging } from "../tagging/tagging";
import AssociationModal from "./AssociationModal";
import "./invitation.less";

const { Grid, Column } = WijmoGrid;

export interface InvitationsComPageProps {
    typeId: InvitationType;
    refId: string;
    typeTitle: string;
    intl: any;
    addInvitationAction: string;
    deleteInvitationAction: string;
    sendEmailAction?: string;
    emailConfirmBindForm?: (d, invitations) => void;
    clickedBtns: string[];
    removeHandler?: (record, removeHandle, removePendingHandle, refreshDataHandle) => void;
    disableInvitation?: boolean;
    setMainTeacher?: (d) => void;
    grantGSAccess: (d) => void;
}

export const InvitationsComPage = withRouter((props: InvitationsComPageProps & GLRouteComponentProps) => {
    const regionService = useService<IRegionService>(TYPES.IRegionService);
    const [enablePhoneRegistrationState, setEnablePhoneRegistrationState] = useState(false);
    const [regionPhoneCode, setRegionPhoneCode] = useState("+1");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRegionSettings();
    }, []);

    const getRegionSettings = async () => {
        if (props.match.params && props.match.params.regionId) {
            const actions = [];
            actions.push(
                new Promise<void>((res) => {
                    regionService
                        .getRegionPhoneRegistrationSetting(props.match.params.regionId)
                        .then((data) => {
                            res();
                            setEnablePhoneRegistrationState(data);
                        })
                        .catch((resp) => {
                            res();
                        });
                }),
            );
            actions.push(
                new Promise<void>((res) => {
                    regionService
                        .getRegionCountryPhoneCode(props.match.params.regionId)
                        .then((data) => {
                            res();
                            data && setRegionPhoneCode(data);
                        })
                        .catch((resp) => {
                            res();
                        });
                }),
            );
            await Promise.all(actions);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return loading ? (
        <Spin spinning={loading}></Spin>
    ) : (
        <>
            <Invitations {...props} enablePhoneRegistration={enablePhoneRegistrationState} />
            <InviteModal {...props} enablePhoneRegistration={enablePhoneRegistrationState} countryPhoneCode={regionPhoneCode} />
        </>
    );
});

interface InvitationsProps {
    dataSource?: any;
    loading?: boolean;
    schoolClass?: any;
    tagList: { [key: string]: TagListModel[] };
    getInvitations?: (d) => void;
    setContact?: (d) => void;
    remove?: (d) => void;
    removePending?: (d) => void;
    reSendInvitation?: (d) => void;
    showModal?: () => void;
    refreshData?: (d) => void;
    emailConfirmBing?: (d) => void;
    enablePhoneRegistration: boolean;
    userRoles: string[]
}

export const Invitations = (props: InvitationsProps & InvitationsComPageProps) => {
    return (
        <Container fullWidth>
            <SecondaryTitle plain={props.typeTitle} />
            <InvitationsContent {...props} />
        </Container>
    );
};

class InvitationsTable extends Table<any> {}

const emailFieldName = "email";
@withRouter
@connect(
    ({ invitation: { invitations, loading, schoolClass }, tagManager,
        school: { userRoles }
    }: StateType) => ({
        dataSource: invitations,
        loading,
        schoolClass,
        tagList: tagManager.tagList,
        userRoles
    }),
    {
        getInvitations,
        setContact,
        remove,
        showModal,
        reSendInvitation,
        removePending,
        refreshData,
        emailConfirmBing,
        setMainTeacher,
        grantGSAccess
    },
    ({ own: { typeId, refId }, dispatch: { remove, removePending, showModal, emailConfirmBing } }) => ({
        remove: removeInvitationBy(typeId, refId, remove),
        removePending: removeInvitationBy(typeId, refId, removePending),
        showModal: (e) => {
            e.preventDefault();
            showModal();
        },
        emailConfirmBing: inviteTrainngManager(emailConfirmBing),
    }),
)
class InvitationsContent extends Component<GLRouteComponentProps & InvitationsProps & InvitationsComPageProps & GLFormComponentProps> {

    associationModalref = null;

    componentWillMount() {
        const { getInvitations, refId, typeId } = this.props;
        getInvitations({ id: refId, type: typeId });
    }

    private reloadGrid = () => {
        const { getInvitations, refId, typeId } = this.props;
        getInvitations({ id: refId, type: typeId });
    }

    render() {
        const {
            dataSource,
            refId,
            typeId,
            setContact,
            loading,
            reSendInvitation,
            remove,
            removePending,
            showModal,
            intl,
            addInvitationAction,
            sendEmailAction,
            deleteInvitationAction,
            clickedBtns,
            removeHandler,
            refreshData,
            schoolClass,
            emailConfirmBing,
            history,
            enablePhoneRegistration,
            match: { params: { regionId } },
            tagList,
            userRoles
        } = this.props;
        const enableInvitation = schoolClass == null || (schoolClass != null && !schoolClass.disabled);
        const canDoPhoneRegistration = isPhoneRegistrationEnable(enablePhoneRegistration);
        const wijmoGridColumns = getColumns(
            [],
            refId,
            typeId,
            setContact,
            reSendInvitation,
            remove,
            removePending,
            sendEmailAction,
            deleteInvitationAction,
            clickedBtns,
            removeHandler,
            refreshData,
            emailConfirmBing,
            history,
            enablePhoneRegistration,
        );

        const isSchoolTeacherPage = typeId === InvitationType.schoolTeacher;
        const hasTagViewPermission = GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherTagView);
        const hasTagManagerPermission = GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherTagManage);

        const grantGSAccessPermission = (userRoles && userRoles.indexOf(RoleName.systemAdmin) > -1)
                                        ||( userRoles && userRoles.indexOf(RoleName.regionAdmin) > -1)
                                        || (userRoles && userRoles.indexOf(RoleName.accountManager) > -1)

        return (
            <>
                <div className="invitation-crud__content">
                    <Grid
                        itemsSource={dataSource}
                        loading={loading}
                        pagination={false}
                        loadedRows={(flex) => {
                            flex.rows.forEach((row) => {
                                if (row.dataItem.disabled) {
                                    row.cssClass = "list-disabled";
                                }
                            });
                        }}
                        actions={[
                            <Action
                                key="0"
                                materialIcon="add"
                                textLocaleId={intl.Invite}
                                onClick={enableInvitation ? showModal : null}
                                disabled={!enableInvitation}
                                action={addInvitationAction}
                            />,
                        ]}
                    >
                        <Column
                            header={fmtMsg({ id: SchoolLocale.InvitationName })}
                            binding="name"
                            render={(text, record) => {
                                if(!record) return null;
                                const { pending, id, name } = record;
                                const value = pending ? `${name || id} ${GLGlobal.intl.formatMessage({ id: SchoolLocale.AdminsPending })}` : name;
                                const {
                                    match: {
                                        params: { regionId, schoolId },
                                    },
                                } = this.props;
                                if (typeId == InvitationType.schoolTeacher || typeId == InvitationType.classTeacher) {
                                    const teacherName = record.pending
                                        ? `${record.name || record.id} (${GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherPending })})`
                                        : name;
                                    const pathSchoolTeacher =
                                        regionId && schoolId
                                            ? GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId: record.id })
                                            : null;
                                    return pathSchoolTeacher &&
                                        GLGlobal.isActionValid(GSSchoolAction.SchoolTeacherHome) &&
                                        teacherName &&
                                        !record.pending ? (
                                        <ColumnLink history={this.props.history} className="navigation-link" url={pathSchoolTeacher}>
                                            {teacherName}
                                        </ColumnLink>
                                    ) : (
                                        teacherName
                                    );
                                } else if (typeId == InvitationType.regionTrainer) {
                                    const pathRegionCoach = regionId
                                        ? GLUtil.pathStringify(PathConfig.RegionCoach, { regionId, regiontrainerId: id })
                                        : null;

                                    return pathRegionCoach && GLGlobal.isActionValid(GSAdminAction.RegionCoachHome) && !pending ? (
                                        <ColumnLink history={this.props.history} className="navigation-link" url={pathRegionCoach}>
                                            {value}
                                        </ColumnLink>
                                    ) : (
                                        value
                                    );
                                } else if (typeId == InvitationType.trainer) {
                                    const pathSchoolCoach =
                                        regionId && schoolId
                                            ? GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId, schoolId, trainerId: id })
                                            : null;
                                    return pathSchoolCoach && GLGlobal.isActionValid(GSAdminAction.SchoolTrainerHome) && !pending ? (
                                        <ColumnLink history={this.props.history} className="navigation-link" url={pathSchoolCoach}>
                                            {name}
                                        </ColumnLink>
                                    ) : (
                                        name
                                    );
                                } else {
                                    return name;
                                }
                            }}
                        />
                        {isSchoolTeacherPage && hasTagViewPermission && (
                            <Column
                                binding="tagsJoined"
                                header={fmtMsg(SchoolLocale.SchoolTeacherTags)}
                                allowSorting={false}
                                minWidth={370}
                                maxWidth={370}
                                wordWrap={true}
                                render={(value: TagListModel[], record, context) => {
                                    if (record.pending) {
                                        return null;
                                    }
                                    return (
                                        <Tagging
                                            selectedTags={record.tags}
                                            entityId={record.id}
                                            regionId={regionId}
                                            entity={TagEntity.Teacher}
                                            wijmoGridContext={context}
                                            isViewOnly={!hasTagManagerPermission}
                                            popoverPlacement="bottomLeft"
                                            onSave={this.reloadGrid}
                                        />
                                    );
                                }}
                            />
                        )}
                        {typeId == InvitationType.schoolTeacher && (
                            <Column
                                header={fmtMsg({ id: SchoolLocale.SchoolTeacherClass })}
                                binding="class"
                                render={(record, item) => {
                                    return (
                                        <a onClick={() => this.associationModalref.open(item, ManageAssociationModalType.Teacher)}>{record}</a>
                                    )
                                }}
                            />
                        )}
                        <Column header={fmtMsg({ id: SchoolLocale.InvitationEmail })} binding="email" />
                        {typeId == InvitationType.regionTrainer && (
                            <Column
                                header={fmtMsg({ id: SchoolLocale.RegionCoachSchools })}
                                binding="schoolCount"
                                render={(record, item) => {
                                    return (
                                        <a onClick={() => this.associationModalref.open(item, ManageAssociationModalType.Coach)}>{record}</a>
                                    )
                                }}
                            />
                        )}
                        {canDoPhoneRegistration && <Column header={fmtMsg({ id: SchoolLocale.InvitationPhone })} binding="phone" />}
                        {typeId == InvitationType.classTeacher && (
                            <Column
                                header={fmtMsg({ id: SchoolLocale.TeacherPrimary })}
                                binding="primary"
                                render={(text, record, index) => {
                                    return record.primary || record.disabled || record.pending ? (
                                        <Checkbox disabled checked={record.primary} />
                                    ) : (
                                        <Checkbox
                                            key={record.id}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.props.setMainTeacher({ teacherId: record.id });
                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                        )}
                        {wijmoGridColumns.map((column, index) => (
                            <Column
                                key={index.toString()}
                                header={column.title}
                                binding={column.dataIndex}
                                render={column.render}
                                align={column.align}
                            />
                        ))}
                    </Grid>
                    <AssociationModal ref={r => this.associationModalref = r} regionId={this.props.match.params.regionId} refId={refId} typeId={typeId}/>
                </div>
            </>
        );
    }
}

function removeInvitationBy(type, id, remove) {
    return (user, typeId) => {
        remove({ id, user, type: typeId ? typeId : type });
    };
}

function inviteTrainngManager(emailConfirmBing) {
    return (id, email, invitationChannel, phoneCountryCode) => {
        emailConfirmBing({ id, email, type: InvitationType.trainingManager, isTrainingManager: true, invitationChannel, phoneCountryCode });
    };
}

function emailConfirmBingBy(type, id, emailConfirmBing) {
    return ({
        email,
        primary,
        isMainShippingContact,
        isMainSupportContact,
        isMainBillingContact,
        isMainContact,
        invitationChannel,
        phoneCountryCode,
    }) => {
        emailConfirmBing({
            id,
            type,
            email,
            primary,
            isMainShippingContact,
            isMainSupportContact,
            isMainBillingContact,
            isMainContact,
            invitationChannel,
            phoneCountryCode,
        });
    };
}

function getColumns(
    columns,
    refId,
    typeId,
    setContact,
    reSendInvitation,
    remove,
    removePending,
    sendEmailAction,
    deleteInvitationAction,
    clickedBtns,
    removeHandler,
    refreshData,
    emailConfirmBing,
    history,
    enablePhoneRegistration,
): any[] {
    const fmtMsg = GLGlobal.intl.formatMessage;
    const canEditContact =
        typeId == InvitationType.campusAdmin ||
        (typeId == InvitationType.schoolAdmin && GLGlobal.isActionValid(GSSchoolAction.UpdateSchoolAdminContact));
    const shippingColumn = [
        {
            title: fmtMsg({ id: SchoolLocale.ShippingContact }),
            dataIndex: "isMainShippingContact",
            align: "center",
            render(text, record) {
                return record.pending || record.disabled || !canEditContact ? (
                    <Checkbox disabled checked={record.isMainShippingContact} />
                ) : (
                    <Checkbox
                        checked={record.isMainShippingContact}
                        onChange={(e) => {
                            const contactId = e.target.checked ? record.id : "";
                            setContact({ id: refId, type: typeId, contactType: ContactType.ShippingContact, contactId: contactId });
                        }}
                    />
                );
            },
        },
    ];
    const supportColumn = [
        {
            title: fmtMsg({ id: SchoolLocale.SupportContact }),
            dataIndex: "isMainSupportContact",
            align: "center",
            render(text, record) {
                return record.pending || record.disabled || !canEditContact ? (
                    <Checkbox disabled checked={record.isMainSupportContact} />
                ) : (
                    <Checkbox
                        checked={record.isMainSupportContact}
                        onChange={(e) => {
                            const contactId = e.target.checked ? record.id : "";
                            setContact({ id: refId, type: typeId, contactType: ContactType.SupportContact, contactId: contactId });
                        }}
                    />
                );
            },
        },
    ];
    const mainContactColumn = [
        {
            title: fmtMsg({ id: SchoolLocale.MainContact }),
            dataIndex: "isMainContact",
            align: "center",
            render(text, record) {
                return record.pending || record.disabled || !canEditContact ? (
                    <Checkbox disabled checked={record.isMainContact} />
                ) : (
                    <Checkbox
                        checked={record.isMainContact}
                        onChange={(e) => {
                            const contactId = e.target.checked ? record.id : "";
                            setContact({ id: refId, type: typeId, contactType: ContactType.MainContact, contactId: contactId });
                        }}
                    />
                );
            },
        },
    ];
    const billingColumn = [
        {
            title: fmtMsg({ id: SchoolLocale.BillingContact }),
            dataIndex: "isMainBillingContact",
            align: "center",
            render(text, record) {
                return record.pending || record.disabled || !canEditContact ? (
                    <Checkbox disabled checked={record.isMainBillingContact} />
                ) : (
                    <Checkbox
                        checked={record.isMainBillingContact}
                        onChange={(e) => {
                            const contactId = e.target.checked ? record.id : "";
                            setContact({ id: refId, type: typeId, contactType: ContactType.BillingContact, contactId: contactId });
                        }}
                    />
                );
            },
        },
    ];
    const actionColumn = [
        {
            title: "",
            render: (text, record) => {
                let clicked = clickedBtns.indexOf(record.id) > -1 ? "clicked" : "";
                return (
                    <span className="table-item-actions">
                        {record.pending &&
                            GLGlobal.isActionValid(sendEmailAction) &&
                            (record.invitationChannel == InvitationChannel.Email ? (
                                <GLIcon
                                    type="mail"
                                    className={clicked}
                                    labelId={SchoolLocale.InvitationIconEmail}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        clickedBtns.push(record.id);
                                        reSendInvitation(record);
                                    }}
                                />
                            ) : enablePhoneRegistration ? (
                                <GLIcon
                                    type="message"
                                    className={clicked}
                                    labelId={GSAdminLocale.InvitationSendSMSTip}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        clickedBtns.push(record.id);
                                        reSendInvitation(record);
                                    }}
                                />
                            ) : (
                                <></>
                            ))}
                        {record.canNotDel && GLGlobal.isActionValid(deleteInvitationAction) && (
                            <GLIcon type="icon-delete" labelId={SchoolLocale.IconDelete} className="delete disabled"></GLIcon>
                        )}
                        {!record.canNotDel && GLGlobal.isActionValid(deleteInvitationAction) && (
                            <GLIcon
                                type="icon-delete"
                                labelId={SchoolLocale.IconDelete}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    removeHandler && removeHandler(record, remove, removePending, refreshData);
                                    !removeHandler &&
                                        Modal.confirm(
                                            record.pending
                                                ? {
                                                      title: fmtMsg({ id: SchoolLocale.InvitationRemoveInvitation }),
                                                      okText: fmtMsg({ id: GLLocale.Ok }),
                                                      cancelText: fmtMsg({ id: GLLocale.Cancel }),
                                                      onOk: () => {
                                                          record.pending ? removePending(record) : remove(record);
                                                      },
                                                  }
                                                : {
                                                      title: fmtMsg({ id: SchoolLocale.InvitationRemoveUser }),
                                                      okText: fmtMsg({ id: GLLocale.Ok }),
                                                      cancelText: fmtMsg({ id: GLLocale.Cancel }),
                                                      onOk: () => {
                                                          if (typeId == InvitationType.regionTrainer) {
                                                              handleRegionTrainerRemove(record);
                                                          } else if (typeId == InvitationType.trainer) {
                                                              handleSchoolTrainerRemove(record);
                                                          } else {
                                                              remove(record);
                                                          }
                                                      },
                                                  },
                                        );
                                }}
                            ></GLIcon>
                        )}
                    </span>
                );
            },
            align: "right",
        },
    ];

    function handleRegionTrainerRemove(record) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const { regionId } = GLUtil.pathParse(PathConfig.RegionCoaches);
        maskThrottle();
        const schoolService = new SchoolService();
        schoolService
            .getSchoolTrainer({ id: regionId, trainerId: record.id })
            .then((trainerSchools) => {
                unmaskThrottle();
                if (!trainerSchools || trainerSchools.length == 0) {
                    // if no school is associated to this trainer within this region then remove silently
                    remove(record);
                } else {
                    Modal.error({
                        title: fmtMsg({ id: SchoolLocale.SchoolTrainerDeleteModelTitle }),
                        content: fmtMsg(
                            { id: SchoolLocale.RegionTrainerSchoolAssociationError },
                            {
                                coachName: record.name,
                                schoolName: trainerSchools
                                    .map((school) => {
                                        return school.name;
                                    })
                                    .join(", "),
                            },
                        ),
                        okText: fmtMsg({ id: GLLocale.Ok }),
                        onOk: () => {},
                    });
                }
            })
            .catch((response) => {
                unmaskThrottle();
            });
    }

    function canDeleteFromRegionList() {
        let roles = GLGlobal.loginInfo().profile.roles;
        return (
            (roles && roles.indexOf(RoleName.systemAdmin) > -1) ||
            roles.indexOf(RoleName.trainingAdmin) > -1 ||
            roles.indexOf(RoleName.regionAdmin) > -1
        );
    }

    function handleSchoolTrainerRemove(record) {
        if (canDeleteFromRegionList()) {
            const fmtMsg = GLGlobal.intl.formatMessage;
            const { regionId } = GLUtil.pathParse(PathConfig.SchoolTrainers);
            maskThrottle();
            const resourceService = new ResourceService();
            resourceService
                .canCompletelyRemove({ regionId: regionId, trainerId: record.id })
                .then((data) => {
                    unmaskThrottle();
                    if (data.canCompletelyRemove) {
                        Modal.confirm({
                            title: fmtMsg({ id: SchoolLocale.RegionTrainerDeleteModelTitle }),
                            content: (
                                <span>
                                    {fmtMsg({ id: SchoolLocale.SchoolTrainerLastDeleteMessage })}
                                    {data.isTrainingManager && (
                                        <>
                                            <br />
                                            <span style={{ fontSize: 10, fontWeight: "bold" }}>
                                                {fmtMsg({ id: SchoolLocale.SchoolTrainerLastDeleteNote })}
                                            </span>
                                        </>
                                    )}
                                </span>
                            ),
                            okText: fmtMsg({ id: SchoolLocale.SchoolTrainerDeleteModalYesText }),
                            cancelText: fmtMsg({ id: SchoolLocale.SchoolTrainerDeleteModalNoText }),
                            maskClosable: true,
                            className: "class-teacher-delete-modal",
                            width: 540,
                            onOk: () => {
                                remove({ ...record, iscompleteDelete: true });
                            }, //Remove Completely
                            onCancel: (e) => {
                                //Keep in Region List
                                if (typeof e == "object" && e.triggerCancel) {
                                    //do nothing, closed dialog by click [X]
                                } else if (typeof e == "function") {
                                    remove(record);
                                    e();
                                }
                            },
                        });
                    } else {
                        //if multiple schools are associated to this trainer within this region then remove only from this school
                        remove(record);
                    }
                })
                .catch((response) => {
                    unmaskThrottle();
                });
        } else {
            // in case of account manager and not (system admin/training admin/ region admin/ globalhead), can not delte region coach
            remove(record);
        }
    }

    const trainingManagerColumn = [
        {
            title: fmtMsg({ id: SchoolLocale.RegionTrainingManager }),
            dataIndex: "isTrainingManager",
            align: "center",
            render(text, record) {
                const schoolService = new SchoolService();
                return !record.pending && canDeleteFromRegionList() ? (
                    <Checkbox
                        checked={record.isTrainingManager}
                        onChange={(e) => {
                            if (e.target.checked) {
                                // add training manager
                                    emailConfirmBing(refId, record.email);
                            } else {
                                // remove
                                Modal.confirm({
                                    title: fmtMsg({ id: SchoolLocale.RegionTrainingManagerDeleteModelTitle }),
                                    okText: fmtMsg({ id: GLLocale.Ok }),
                                    cancelText: fmtMsg({ id: GLLocale.Cancel }),
                                    onOk: () => {
                                        // training-manager-remove
                                        record.isTrainingManager = false;
                                        remove(record, InvitationType.trainingManager);
                                    },
                                });
                            }
                        }}
                    />
                ) : (
                    <Checkbox checked={record.isTrainingManager} disabled={true} />
                );
            },
        },
    ];

    switch (typeId) {
        case InvitationType.schoolAdmin:
            return columns.concat(shippingColumn, supportColumn, billingColumn, actionColumn);
        case InvitationType.campusAdmin:
            return columns.concat(shippingColumn, supportColumn, mainContactColumn, actionColumn);
        case InvitationType.regionTrainer:
            return columns.concat(trainingManagerColumn, actionColumn);
        case InvitationType.accountManager:
        case InvitationType.classTeacher:
        case InvitationType.schoolTeacher:
        case InvitationType.trainer:
            return columns.concat(actionColumn);
    }
}

interface InviteModalProps {
    confirmed?: any;
    emails?: any[];
    templates?: any[];
    defaultTemplate?: string;
    isModalShow?: boolean;
    isPreviewShow?: boolean;
    values?: any;
    loading?: boolean;
    invitationChannel?: number;
    emailConfirmBing?: (d) => void;
    handleSubmit?: (d) => void;
    onCancel?: () => void;
    initTemplates?: (d) => void;
    formatBy?: (d) => void;
    format?: any;
    invitations?: any[];
    enablePhoneRegistration: boolean;
    countryPhoneCode?: string;
}

@GLForm.create()
@connect(
    ({
        invitation: { invitations, templates, defaultTemplate, isModalShow, isPreviewShow, values, confirmed, loading, invitationChannel },
    }: StateType) => ({
        emails: invitations
            .filter((invitation) => !invitation.pending)
            .map((invitation) => invitation.email)
            .concat(invitations.filter((invitation) => !invitation.pending && invitation.phone).map((invitation) => invitation.phone)),
        templates,
        defaultTemplate,
        isModalShow,
        isPreviewShow,
        values,
        confirmed,
        loading,
        invitationChannel,
        invitations,
    }),
    {
        handleSubmit: invite,
        onCancel: hideModal,
        emailConfirmBing,
        initTemplates,
    },
    ({ own: { typeId, refId }, dispatch: { emailConfirmBing } }) => ({
        emailConfirmBing: emailConfirmBingBy(typeId, refId, emailConfirmBing),
        formatBy: formatBy(typeId, refId),
    }),
)
class InviteModal extends Component<InviteModalProps & InvitationsComPageProps & GLFormComponentProps> {
    // componentWillReceiveProps({ isModalShow: nextIsModalShow }: any) {
    //     const { initTemplates, typeId, isModalShow } = this.props;
    //     nextIsModalShow && nextIsModalShow !== isModalShow && initTemplates(typeId);
    // }
    render() {
        const {
            form,
            emails,
            onCancel,
            confirmed,
            emailConfirmBing,
            isModalShow,
            isPreviewShow,
            formatBy,
            intl,
            emailConfirmBindForm,
            loading,
            typeId,
            values,
            invitations,
            invitationChannel,
            enablePhoneRegistration,
            countryPhoneCode,
        } = this.props;
        const renderFormItem = FormHelper.renderFormItem;

        const isPhoneNumber = function (value : string) {
            const phone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return value.match(phone);
        }

        if (confirmed.email && confirmed.phoneCountryCode == "+84" && isPhoneNumber(confirmed.email) && confirmed.email.startsWith("0", 3)) {
            const indexZero = 4;
            confirmed.email = confirmed.email.substring(0, indexZero - 1) + confirmed.email.substring(indexZero, confirmed.length);
        }

        // const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            isModalShow && (
                <Modal title={fmtMsg({ id: intl.InviteTitle })} closable={true} footer={null} visible={true} className="invitation-modal" onCancel={onCancel}>
                    <Spin spinning={loading}>
                        {showBy(
                            { ...confirmed, resetFields: form.resetFields },
                            <EmailConfirmBinding
                                {...{
                                    form,
                                    emails,
                                    onCancel,
                                    handleSubmit: emailConfirmBing,
                                    emailConfirmBindForm,
                                    typeId,
                                    invitations,
                                    enablePhoneRegistration,
                                    countryPhoneCode,
                                }}
                            />,
                            <AccountBinded {...{ ...confirmed, onCancel }} />,
                            <InvitationSending {...this.props} format={formatBy({ ...confirmed })} />,
                        )}
                        {showPreviewBy(values, isPreviewShow, invitationChannel)}
                    </Spin>
                </Modal>
            )
        );
    }
}
function showBy({ email, exist, hasConfirmed, resetFields }, confirming, binding, inviting) {
    if (!email) {
        return confirming;
    } else if (exist && hasConfirmed) {
        resetFields && resetFields(["email"]);
        return binding;
    }
    resetFields && resetFields(["email"]);
    return inviting;
}

function showPreviewBy(values, isPreviewShow, invitationChannel) {
    if(!values) return null;
    if (!values.name || !values.templateId) {
        return null;
    }
    let modalparams = {
        type: values.invitationType,
        refid: values.referenceId,
        tepid: values.templateId,
        name: values.name,
        email: values[emailFieldName],
        invitationChannel: invitationChannel,
    };
    return (
        <PreviewModal
            modalparams={modalparams}
            visible={isPreviewShow}
            onCancel={() => {
                GLGlobal.store.dispatch(clearPreview({ values: {}, isPreviewShow: false }));
            }}
        />
    );
}
function onPreview(component, format?) {
    const { form, model } = component;
    form.validateFields((err, values) => {
        if (!err) {
            format && format(values, model);
            GLGlobal.store.dispatch(previewShow({ values: values, isPreviewShow: true }));
        }
    });
}

interface EmailConfirmBindingState {
    direction?: string;
    options?: string[];
    loadingCandidates?: boolean;
    candidates4Teacher?: UserModel[];
    currentCandidateNeedValidate?: boolean;
}

class EmailConfirmBinding extends React.Component<any, EmailConfirmBindingState> {
    isClicked = false;
    autoCompleteNode;
    candidates4TeacherRef = React.createRef<Select>();
    schoolId = GLUtil.pathParse(PathConfig.Schools).schoolId;
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    constructor(props, context) {
        super(props);
        this.state = {
            direction: "down",
            options: [],
            loadingCandidates: props.typeId == InvitationType.classTeacher,
            candidates4Teacher: [],
            currentCandidateNeedValidate: true,
        };
        props.typeId == InvitationType.classTeacher && this.getTeacherEmails();
    }
    async getTeacher() {
        return await this.schoolService.getSchoolTeachers({ id: this.schoolId });
    }
    async getUsers(ids) {
        return await this.userService.getUsersByPost({ ids: ids, disabled: false });
    }
    async getTeacherEmails() {
        let { teachers, pendingTeachers } = await this.getTeacher();
        let ids =
            teachers &&
            teachers.length != 0 &&
            teachers.map((teacher) => {
                return teacher.id;
            });
        let users = ids && (await this.getUsers(ids));
        let options = [],
            candidates = [];
        users &&
            users.data.forEach((user) => {
                if (!this.props.emails.includes(user.email) && !user.disabled) {
                    const optionEle = React.createElement(Select.Option, { className: "tdi", key: user.id, value: user.email }, [
                        React.createElement("span", { className: "tdi__name", key: user.name }, user.name),
                        React.createElement("span", { className: "tdi__email", key: user.email }, user.email),
                    ]);
                    options.push(optionEle);
                    const { id, name, email, phone, phoneCountryCode, invitationChannel } = user;
                    candidates.push({ id, name, email, phone, phoneCountryCode, invitationChannel });
                }
            });
        let emails = pendingTeachers.map((pt) => {
            const optionEle = React.createElement(Select.Option, { className: "tdi", key: pt.id, value: pt.email }, [
                React.createElement("span", { className: "tdi__name", key: pt.name }, pt.name),
                React.createElement("span", { className: "tdi__email", key: pt.email }, pt.email),
            ]);
            const { id, name, email, phone, phoneCountryCode, invitationChannel } = pt;
            if (invitationChannel == null || invitationChannel == InvitationChannel.Email) {
                candidates.push({ id, name, email, phone, phoneCountryCode, invitationChannel });
            } else {
                candidates.push({ id, name, email: null, phone: email, phoneCountryCode, invitationChannel });
            }
            return optionEle;
        });
        options = options.concat(emails);
        this.setState({ options: options.filter((x, i, a) => a.indexOf(x) == i), loadingCandidates: false, candidates4Teacher: candidates });
    }
    onBlur() {
        !this.isClicked && this.setState({ direction: "down" });
    }
    onFocus() {
        !this.isClicked && this.setState({ direction: "up" });
    }
    onClick(e) {
        this.isClicked = true;
        if (this.state.direction == "up") {
            this.setState({ direction: "down" });
            this.autoCompleteNode.blur();
            e && e.stopPropagation ? e.stopPropagation() : (window.event.cancelBubble = true);
        } else {
            this.setState({ direction: "up" });
        }
    }
    onLoginIdChanged(value: LoginId) {
        // this.invitationChannel = value.type == LoginIdType.Email ? InvitationChannel.Email : InvitationChannel.Phone;
        this.setState({ currentCandidateNeedValidate: true });
    }
    getTeacherColumns(enablePhoneRegistration) {
        if (isPhoneRegistrationEnable(enablePhoneRegistration)) {
            return [
                {
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherEmail }),
                    dataIndex: "email",
                    width: 210,
                },
                {
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherPhone }),
                    dataIndex: "phone",
                    width: 130,
                },
                {
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherName }),
                    dataIndex: "name",
                    width: 100,
                },
            ];
        } else {
            return [
                {
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherEmail }),
                    dataIndex: "email",
                    width: 210,
                },
                {
                    title: fmtMsg({ id: SchoolLocale.SchoolTeacherName }),
                    dataIndex: "name",
                    width: 100,
                },
            ];
        }
    }
    onSelectRow(record) {
        const {
            form: { setFieldsValue },
        } = this.props;
        this.candidates4TeacherRef.current.blur();
        if (record.email || this.props.enablePhoneRegistration) {
            this.setState({ currentCandidateNeedValidate: false });
            setFieldsValue({
                [emailFieldName]: {
                    type: record.email ? LoginIdType.Email : LoginIdType.Phone,
                    country: "US",
                    countryCode: record.email ? "+1" : record.phoneCountryCode,
                    phone: record.email ? "" : record.phone,
                    email: record.email ? record.email : "",
                },
            });
        }
    }
    render() {
        const {
            form,
            handleSubmit,
            emails,
            onCancel,
            emailConfirmBindForm,
            typeId,
            invitations,
            enablePhoneRegistration,
            countryPhoneCode,
        } = this.props;
        const { loadingCandidates, candidates4Teacher, currentCandidateNeedValidate } = this.state;
        const renderFormItem = FormHelper.renderFormItem;
        const props = this.props;
        const isTeachers = typeId == InvitationType.classTeacher;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" },
        };
        const codeFromList = defaultPhoneCountryCodeList.find((f) => f.code === countryPhoneCode);
        const initLoginId = {
            type: LoginIdType.Email,
            country: countryPhoneCode && countryPhoneCode.length ? (codeFromList ? codeFromList.counrtryAbbr : "") : "US",
            countryCode: countryPhoneCode && countryPhoneCode.length ? countryPhoneCode : "+1",
            phone: "",
            email: "",
        };
        const formatValues = (values, model) => {
            values.invitationChannel = values[emailFieldName].type == LoginIdType.Email ? InvitationChannel.Email : InvitationChannel.Phone;
            values.phoneCountryCode = values[emailFieldName].type === LoginIdType.Phone ? values[emailFieldName].countryCode : null;
            values[emailFieldName] =
                values[emailFieldName].type == LoginIdType.Email
                    ? values[emailFieldName].email
                    : `${values[emailFieldName].countryCode ? values[emailFieldName].countryCode : ""}${values[emailFieldName].phone}`;
        };
        return (
            <Spin spinning={loadingCandidates}>
                <GLForm form={form} onSubmit={onSubmit({ props }, handleSubmit, formatValues)}>
                    {!isTeachers &&
                        renderFormItem(
                            form,
                            enablePhoneRegistration ? SchoolLocale.InvitationEmailPhone : SchoolLocale.InvitationEmail,
                            emailFieldName,
                            <GLLoginIdInput
                                onChange={this.onLoginIdChanged.bind(this)}
                                disabled={false}
                                placeholder={fmtMsg({
                                    id: enablePhoneRegistration
                                        ? GSAdminLocale.RegistrationFormEmailPhonePlaceHolder
                                        : GSAdminLocale.RegistrationFormEmailPlaceHolder,
                                })}
                                dualMode={enablePhoneRegistration ? true : false}
                            />,
                            initLoginId,
                            true,
                            emailValider(form, emails, typeId, enablePhoneRegistration),
                        )}
                    {isTeachers &&
                        renderFormItem(
                            form,
                            enablePhoneRegistration ? SchoolLocale.InvitationEmailPhone : SchoolLocale.InvitationEmail,
                            emailFieldName,
                            // <AutoComplete
                            //     ref={(node) => {
                            //         this.autoCompleteNode = node;
                            //     }}
                            //     className="teacher-email"
                            //     filterOption={(inputValue, option) => option.props.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            //     dataSource={this.state.options}
                            //     placeholder={fmtMsg(SchoolLocale.InvitationPlaceHoldEmail)}
                            //     onFocus={this.onFocus.bind(this)}
                            //     onBlur={this.onBlur.bind(this)}
                            //     optionLabelProp="value"
                            // >
                            //     {this.state.options.length != 0 && (
                            //         <Input suffix={<Icon type={this.state.direction} onClick={this.onClick.bind(this)} />} />
                            //     )}
                            // </AutoComplete>,
                            <GLLoginIdInput
                                disabled={false}
                                placeholder={fmtMsg({
                                    id: enablePhoneRegistration
                                        ? GSAdminLocale.RegistrationFormEmailPhonePlaceHolder
                                        : GSAdminLocale.RegistrationFormEmailPlaceHolder,
                                })}
                                dualMode={enablePhoneRegistration ? true : false}
                                suffix={
                                    <div
                                        className="teacher-selector-container"
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                    >
                                        <Select
                                            className="teacher-selector"
                                            ref={this.candidates4TeacherRef}
                                            dropdownMatchSelectWidth={true}
                                            dropdownRender={(menu, props) => (
                                                <Table
                                                    dataSource={candidates4Teacher}
                                                    rowKey="id"
                                                    columns={this.getTeacherColumns(enablePhoneRegistration)}
                                                    pagination={false}
                                                    scroll={{ y: 140 }}
                                                    className="teacher-selector-candidates-table"
                                                    onRow={(record) => ({
                                                        onClick: () => {
                                                            this.onSelectRow(record);
                                                        },
                                                    })}
                                                />
                                            )}
                                        >
                                            {this.state.options}
                                        </Select>
                                    </div>
                                }
                                onChange={this.onLoginIdChanged.bind(this)}
                            />,
                            initLoginId,
                            true,
                            currentCandidateNeedValidate ? emailValider(form, emails, typeId, enablePhoneRegistration) : null,
                        )}
                    {emailConfirmBindForm && emailConfirmBindForm(form, invitations)}
                    <div className="admin-contact">
                        {(typeId == InvitationType.schoolAdmin || typeId == InvitationType.campusAdmin) &&
                            renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.MainShippingContact,
                                "isMainShippingContact",
                                <Checkbox>{fmtMsg({ id: SchoolLocale.MainShippingContact })}</Checkbox>,
                                null,
                                false,
                            )}

                        {(typeId == InvitationType.schoolAdmin || typeId == InvitationType.campusAdmin) &&
                            renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.MainSupportContact,
                                "isMainSupportContact",
                                <Checkbox>{fmtMsg({ id: SchoolLocale.MainSupportContact })}</Checkbox>,
                                null,
                                false,
                            )}

                        {typeId == InvitationType.campusAdmin &&
                            renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.MainContact,
                                "isMainContact",
                                <Checkbox>{fmtMsg({ id: SchoolLocale.MainContact })}</Checkbox>,
                                null,
                                false,
                            )}

                        {typeId == InvitationType.schoolAdmin &&
                            renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.MainBillingContact,
                                "isMainBillingContact",
                                <Checkbox>{fmtMsg({ id: SchoolLocale.MainBillingContact })}</Checkbox>,
                                null,
                                false,
                            )}
                    </div>
                    <Form.Item>
                        <SubmitBtns
                            submitTitle={SchoolLocale.InvitationInviteButton}
                            onCancel={(e) => {
                                form.resetFields();
                                onCancel();
                            }}
                        />
                    </Form.Item>
                </GLForm>
            </Spin>
        );
    }
}

class AccountBinded extends Component<{ onCancel; email }> {
    componentWillMount() {
        const { onCancel, email } = this.props;
        MessageHelper.Message(NotificationType.Success, fmtMsg(SchoolLocale.InvitationMsgSuccess, { email: email }));
        onCancel();
    }
    render() {
        return null;
    }
}

class InvitationSending extends React.Component<InviteModalProps & GLFormComponentProps> {
    isDisabled = false;
    constructor(props, context) {
        super(props, context);
    }
    callback(isDisable) {
        this.isDisabled = isDisable;
    }
    render() {
        const { form, handleSubmit, templates, defaultTemplate, emails, onCancel, format } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const props = this.props;
        return (
            <GLForm form={form} layout="horizontal" onSubmit={onSubmit({ props }, handleSubmit, format, this.callback.bind(this))}>
                {renderFormItem(
                    form,
                    SchoolLocale.InvitationName,
                    "name",
                    <Input placeholder={fmtMsg(SchoolLocale.InvitationPlaceHoldName)} />,
                    null,
                    true,
                )}
                {renderFormItem(
                    form,
                    SchoolLocale.InvitationInviteTemp,
                    "templateId",
                    <TemplatesSelect templates={templates} />,
                    defaultTemplate,
                    true,
                )}
                <Form.Item>
                    <SubmitBtns
                        submitTitle={SchoolLocale.InvitationInviteButton}
                        previewTitle={SchoolLocale.Preview}
                        onPreview={() => onPreview(this.props, format)}
                        onCancel={onCancel}
                        isDisabled={this.isDisabled}
                    />
                </Form.Item>
            </GLForm>
        );
    }
}

function formatBy(typeId, refId) {
    return ({
        email,
        primary,
        isMainShippingContact,
        isMainSupportContact,
        isMainBillingContact,
        isMainContact,
        invitationChannel,
        phoneCountryCode,
    }) => (values) => {
        let contacts = [];
        isMainShippingContact && contacts.push(ContactType.ShippingContact);
        isMainSupportContact && contacts.push(ContactType.SupportContact);
        isMainBillingContact && contacts.push(ContactType.BillingContact);
        isMainContact && contacts.push(ContactType.MainContact);
        values.primary = primary;
        values.email = email;
        values.referenceId = refId;
        values.invitationType = typeId;
        values.invitationCodeContact = contacts;
        values.invitationChannel = invitationChannel;
        values.phoneCountryCode = phoneCountryCode;
    };
}

const emailValider = ({ getFieldValue }, emails: any[], typeId, enablePhoneRegistration) => [
    {
        validator: (rule, value: string, callback) => {
            if (
                value[emailFieldName] && emails.some((email) => email === value[emailFieldName] || email === `${value["countryCode"]}${value["phone"]}`) &&
                typeId !== InvitationType.classTeacher
            ) {
                const fmtMsg = GLGlobal.intl.formatMessage;
                callback(fmtMsg({ id: SchoolLocale.InvitationHasInvited }, { name: fmtMsg({ id: SchoolLocale.InvitationEmailPhone }) }));
            }
            //inviteEmailValidation(value, callback);
            callback();
        },
    },
    validateLoginId(GLRegistrationLocalizationHelper.getGLRegistrationLocalization(enablePhoneRegistration)),
];

interface TemplatesSelectProps {
    templates: any[];
}
class TemplatesSelect extends Component<TemplatesSelectProps> {
    render() {
        return (
            <Select {...this.props} size="large">
                {this.props.templates.map((template) => (
                    <Select.Option key={template.id} value={template.id}>
                        {template.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}
