export class SurveyResponseModel {
}

export class SurveyResponseRequestModel {
}

export interface SendEmailRequestModel {
    to: ContactModel[];
    cc: string[];
    replyTo: ContactModel;
    subject: string;
    comments: string;
    selectedQuestionAnswers: EmailQuestionModel[];
    description?: string;
    surveyId: string;
}

export interface ContactModel {
    name: string;
    email: string;
}

export interface EmailQuestionModel {
    question: string;
    maxScore?: number;
    minScore?: number;
    isNaEnabled?: boolean;
    answer: OptionAnswerRequestModel[];
    surveyQuestionTypeId: number;
    surveyQuestionOption: OptionAnswerRequestModel[]
    id: string;
}

export interface OptionAnswerRequestModel {
    id: string;
    name: string;
}

export interface SendEmailResponseModel {
    success: boolean;
    statusCode: number;
}

export enum PollResponseStatusCode {
    Success = 200200,
    NoResponses = 200201,
    Unpublished = 200202,
}

export interface PollResponseModel {
    statusCode: PollResponseStatusCode;
    surveyId: string;
    surveyName: string;
    response: PollGroupResponseModel[];
}

export interface PollGroupResponseModel {
    surveyInstanceId: string;
    pollCreationDate: Date | string;
    ratedQuestions: RatedPollQuestionModel[];
    optionQuestions: OptionQuestionModel[];
    multiChoiceQuestions: OptionQuestionModel[];
    instanceResponse: PollInstanceResponseModel[];
}

export interface PollInstanceResponseModel {
    surveyResponseId: string;
    surveyInstanceId: string;
    responderId: string;
    responderName: string;
    submitDate: Date | string;
}

export interface OptionQuestionModel {
    id: string; // question id
    question: string;
    localeKey: string;
    chartCollection: OptionQuestionChartModel[];
}

export interface OptionQuestionChartModel {
    count: number;
    option: string;
}

export interface RatedPollQuestionModel {
    id: string; // question id
    question: string;
    averageRating: number;
    surveyQuestionRatingType: number;
    maxRating: number;
    localeKey: string;
}
