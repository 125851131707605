import * as React from "react";
import { Select } from "antd-min";
import { MovePromoteStudentsLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
import { CampusDropModel } from "@app/service/schools";
import { MovePromotePageOperations } from "..";

const { Option } = Select;
interface CampusSelectorProps {
    isSourceCampus: boolean;
    schoolCampus?: CampusDropModel;
    schoolCampusOptions: CampusDropModel[];
    onCampusDropDownChange: (pageOperation: MovePromotePageOperations, e: any) => void;
}

export const CampusSelector = (props: CampusSelectorProps) => {
    const { isSourceCampus, schoolCampus, schoolCampusOptions, onCampusDropDownChange } = props;
    return (
        <div>

            <Select
                className="mps-select-campus"
                placeholder={fmtMsg({
                    id: MovePromoteStudentsLocale.SelectCampusMessage
                })}
                value={schoolCampus ? schoolCampus.id : undefined}
                size="large"
                onChange={e => onCampusDropDownChange(isSourceCampus ? MovePromotePageOperations.SourceCampusChange : MovePromotePageOperations.DestinationCampusChange, e)}
            >
                {schoolCampusOptions.map(data => (
                    <Option key={data.id} value={data.id}>
                        <span title={data.name}>{data.name}</span>
                    </Option>
                ))}
            </Select>

        </div>
    );
};
