import {InvitationCodeLookupResponseModel, InvitationCodeValid} from './models';
import {customError, getServiceMethodKey, GLLocale, GLServiceBase, HttpMethod, ServerExceptionCode} from 'gl-commonui'
import {injectable} from '../../../util/di'
import {
    GetInvitationRequest,
    IInvitationService,
    InvitationModel,
    InvitationRequest,
    RegisterModel,
    templateParams
} from './index';
// import { UserPendingModel } from "../users/index";
import {GSAdminLocale} from '../../../locales/localeid';

@injectable()
export class InvitationService extends GLServiceBase<InvitationRequest, GetInvitationRequest> implements IInvitationService {
    serviceRoute = { prefix: 'admin/v1/invitationcodes', suffix: '{id}' }
    sendEmailUrl = '{id}/email/send';
    addConsumerUrl = '{codeId}/consumers';
    getInvitationInfoUrl = '{invitationCode}';
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.getInvitationInfoUrl}`)] = GSAdminLocale.ServiceInviteCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceInviteTemNotFound;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.UnsuportedOperationException)] = GSAdminLocale.ServiceInviteTypeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ResourceNotAvailableException)] = GSAdminLocale.ServiceInviteSchoolDisabled;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException)] = GSAdminLocale.ServiceInviteNotGenerate;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.sendEmailUrl}`)] = GSAdminLocale.ServiceInviteUsed;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.sendEmailUrl}`)] = GSAdminLocale.ServiceInviteCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.addConsumerUrl}`)] = GSAdminLocale.ServiceInviteCodeInvalid;
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.CodeHasBeenUsedException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = GSAdminLocale.ServiceInviteUsed;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = GSAdminLocale.ServiceInviteRemoved;
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.InvitationCodeNotExistException, `${this.serviceRoute.prefix}/${this.getInvitationInfoUrl}`)] = GLLocale.HttpCodeNotExist;
        return result;
    })();
    validate(invitation: InvitationRequest) {
        return this.request.post<InvitationCodeValid, InvitationRequest>(this.url('validation'), invitation);
    }
    formatInvitationList(invitations: InvitationModel[]) {
        return invitations;
    }
    generateInvitationCode(params: any) {
        return this.create(params);
    }
    deleteInvitationCode(params: any) {
        return this.delete(params);
    }
    sendInvitationMail(params: any) {
        return this.create(this.sendEmailUrl, params);
    }
    addConsumer(user: RegisterModel, templateParams: templateParams) {
        return this.create(this.addConsumerUrl, user, templateParams);
    }
    getInvitationInfoByCode(params: any) {
        return this.request.get<any, InvitationRequest>(this.url(this.getInvitationInfoUrl), params).catch(customError(this, HttpMethod.Get, `${this.serviceRoute.prefix}/${this.getInvitationInfoUrl}`));
    }
    comsumeInvitationCode(templateParams: templateParams) {
        return this.request.put(this.url('{codeId}/consumers/{consumerId}'), templateParams);
    }
    getPendingUsers(pendingParams: any) {
        return this.request.get(this.url(''), pendingParams);
    }
    getUserCreationDate(userId: string) {
        return this.request.get(this.url('getUserCreationDate'), { userId });
    }
    lookupInvitationCode(code: string): Promise<InvitationCodeLookupResponseModel> {
        return this.get("Lookup/{invitationCode}", { invitationCode: code });
    }
    extendExpirationDate(code: string) {
        return this.request.put(this.url(`ExtendNewExpirationDate/${code}`));
    }
}
