import * as React from 'react'
import { Component } from 'react'
import * as ReactDOM from "react-dom";
import { Icon, Dropdown } from "antd-min";


interface GLArrowDropDownProps {
    menu?: React.ReactNode
}
interface GLArrowDropDownStates { }
export class GLArrowDropDown extends Component<GLArrowDropDownProps, GLArrowDropDownStates> {

    render() {
        return <div onClick={(e) => {
            e.preventDefault();
        }}>
            <Dropdown
                overlay={this.props.menu} trigger={['click']} >
                <Icon type="down" />
            </Dropdown>
        </div>
    }
}
