import { ServiceRoute, GLServiceBase } from  'gl-commonui';
import { ISearchService } from './interface';
import { SearchRequest, SearchModel } from './model';

export class SearchService extends GLServiceBase<SearchRequest, any> implements ISearchService {
    serviceRoute: ServiceRoute = { prefix: 'admin/v1/resources/hybrid' }
    search(params: any) {
        return this.request.get(this.url(''), params) as Promise<SearchModel[]>;
    }
    searchStructure(params: any) {
        return this.get('aggregation', params) as Promise<SearchModel[]>;
    }
}