import { GLServiceBase, GLGlobal, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '@app/util/di';
import { InvitationHelper } from '@app/util';
import { IInvitationTemplateService, InvitationTemplateRequest, InvitationTemplatePagingRequest, InvitationTemplateModel } from './index';
import { SchoolLocale } from '@app/locales/localeid';

@injectable()
export class InvitationTemplateService extends GLServiceBase<InvitationTemplateRequest, InvitationTemplatePagingRequest> implements IInvitationTemplateService {
    serviceRoute = { prefix: 'admin/v1/invitationTemplates', suffix: '{id}' }
    UpdateInvitationTemplateTypeException = 46201;
    UpdateInvitationTemplateRegionException = 46202;
    UpdateInvitationTemplateSchoolException = 46203;
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Get, ServerExceptionCode.TargetIsNullException)] = {id: SchoolLocale.CommonErrorNoTarget, values: {target: {id:SchoolLocale.InvitationTemplatePageTitleList}}};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.NoPermissionException)] = {id: SchoolLocale.CommonErrorNoPermissionNew, values: {target: {id:SchoolLocale.InvitationTemplatePageTitleList}}};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.NoPermissionException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = {id: SchoolLocale.CommonErrorNoPermissionUpdate, values: {target: {id:SchoolLocale.InvitationTemplatePageTitleList}}};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = {id: SchoolLocale.CommonErrorNoTarget, values: {target: {id:SchoolLocale.InvitationTemplatePageTitleList}}};
        result[getServiceMethodKey(this, HttpMethod.Put, this.UpdateInvitationTemplateTypeException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.InvitationTemplateErrorCannotUpdateInvitationType;
        result[getServiceMethodKey(this, HttpMethod.Put, this.UpdateInvitationTemplateRegionException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.InvitationTemplateErrorCannotUpdateRegion;
        result[getServiceMethodKey(this, HttpMethod.Put, this.UpdateInvitationTemplateSchoolException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = SchoolLocale.InvitationTemplateErrorCannotUpdateSchool;
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = {id: SchoolLocale.CommonErrorNoTarget, values: {target: {id:SchoolLocale.InvitationTemplatePageTitleList}}};
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = SchoolLocale.InvitationTemplateErrorCannotDelete;
        return result;
    })();
    formatInvitationTemplateList(templates: InvitationTemplateModel[]) {
        return templates.map(template => {
            template.invitationTypeText = InvitationHelper.invitationType2Text.get(template.invitationType.toString());
            template.invitationChannelText = InvitationHelper.invitationChannel2Text.get(template.invitationChannel);
            return template;
        });
    }
    getCandidates(data){
        return this.getItemsBy('candidates',data)
    }
    previewInvitationTemplate(params: any) {
        return this.get('preview', params);
    }
    getInvitationTemplateById(params: any) {
        return this.get('{id}', params);
    }
    getTemplateCandidates(params: any) {
        return this.get('candidates', params);
    }
    getRegionSchools(params: any) {
        return this.get('resources', params);
    }
}