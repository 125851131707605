import { push } from "connected-react-router";
import { GLGlobal } from "gl-commonui";
import { LocationDescriptorObject, LocationState } from "history";

export const useHistory = () => {
    const pushFn = (location: LocationDescriptorObject<LocationState>): void => {
        GLGlobal.store.dispatch(push(location));
    };

    return {
        push: pushFn,
    };
};
