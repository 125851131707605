import {GSAdminLocale} from "@app/locales/localeid";
import {Button, Empty, Input, Pagination} from "antd-min";
import {connect, GLGlobal} from "gl-commonui";
import React, {ReactNode, useState} from "react";
import {
    AllowMerging,
    AllowResizing,
    CellRangeEventArgs,
    CellType,
    FlexGrid as FlexGridRef,
    FormatItemEventArgs,
    HeadersVisibility,
    SelectionMode
} from "wijmo/wijmo.grid";
import {FlexGridXlsxConverter, XlsxFormatItemEventArgs} from "wijmo/wijmo.grid.xlsx";
import {FlexGrid} from "wijmo/wijmo.react.grid";
import {GroupPanel} from "wijmo/wijmo.react.grid.grouppanel";
import {IWorkbookCell} from "wijmo/wijmo.xlsx";
import {CollectionView, PropertyGroupDescription, SortDescription} from "wijmo/wijmo";
import {Loading} from "..";
import {StateType} from "@app/states";
import {fmtMsg} from "@app/util";
import "./grid.less";
import {wijmoGridResponsive} from "@app/util/wijmo";
import {FlexGridContextMenu} from "@app/components/grid/context-menu";

interface WijmoDescription {
    groupDescriptions?: string[];
    sortDescriptions?: SortDescription[];
    onSortedColumn?: (e: CellRangeEventArgs) => void;
}

interface ColumnLangMap {
    localeId: string;
    dataIndex: string;
}

interface GridScroll {
    height: number | boolean;
}

interface GridProps extends React.Props<GridProps>, WijmoGridProps {
    loading?: boolean;
    pagination?: boolean;
    grouping?: boolean;
    groupBarText?: string;
    excelExport?: boolean;
    pageSize?: number;
    fileName?: string;
    title?: ReactNode;
    searching?: boolean;
    searchOnColumns?: string[];
    defaultSortedColumns?: string[];
    noDataLocaleId?: string;
    langLoaded?: string;
    columnLangMap?: ColumnLangMap[];
    scroll: GridScroll;
    onRefreshed?: (grid: any) => any;
}

export const WijmoGrid = connect(({ intl: { langLoaded } }: StateType) => ({ langLoaded }))((props: GridProps) => {
    const [columnLayout, setColumnLayout] = useState(null as string);
    const [filter, setFilter] = useState("");
    const defaultPageSize = 50;
    const defaultExportFileName = "report.xlsx";
    const [wijmoCollectionView, setWijmoCollectionView] = useState(null as CollectionView);
    const [descriptions, setDescriptions] = useState({ groupDescriptions: [], sortDescriptions: [] } as WijmoDescription);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [grid, setGrid] = React.useState<FlexGridRef>(null); //FlexGrid

    const {
        loading,
        children,
        pagination,
        grouping,
        groupBarText,
        excelExport,
        itemsSource,
        pageSize,
        title,
        searching,
        searchOnColumns,
        defaultSortedColumns,
        noDataLocaleId,
        langLoaded,
        scroll,
        ...rest
    } = props;

    React.useEffect(() => {
        if (itemsSource && itemsSource.length && !loading) {
            const collection = new CollectionView(itemsSource, {
                pageSize: pageSize || 0,
                ...descriptions,
                filter: collectionFilter,
            });
            collection.onPageChanged();
            setWijmoCollectionView(collection);
        } else {
            setWijmoCollectionView(
                new CollectionView([], {
                    pageSize: pageSize || 0,
                    ...descriptions,
                    filter: collectionFilter,
                })
            );
        }

        if (!loading && columnLayout && columnLayout.length && grid) {
            grid.columnLayout = columnLayout;
        }
    }, [itemsSource, loading]);

    React.useEffect(() => {}, [wijmoCollectionView]);

    React.useEffect(() => {
        updateColumnHeaders();
    }, [langLoaded]);

    const collectionView = wijmoCollectionView;

    const noData: boolean = (!collectionView || !collectionView.sourceCollection.length) && !loading;
    const onPaginationChange = (page: number, pageSize?: number) => {
        collectionView.moveToPage(page - 1);
        setCurrentPage(page);
    };

    const initGrid = (grid: FlexGridRef): void => {
        setGrid(grid);

        // Adjust row height
        grid.rows.defaultSize = 40;
        grid.columnHeaders.rows.defaultSize = 40;

        // execute parent initialize callback
        if (props.initialized) {
            props.initialized(grid);
        }

        // Make the grid responsive with adding horizontal scrollbar
        wijmoGridResponsive(grid);

        // Render context menu
        new FlexGridContextMenu(grid);
    };

    const updateColumnHeaders = () => {
        if (!grid) {
            return;
        }

        let columnLayout = JSON.parse(grid.columnLayout);
        columnLayout.columns.forEach((col) => {
            const dataCol = props.columnLangMap.find((map) => map.dataIndex === col.binding);

            if (dataCol && dataCol.localeId) {
                col.header = fmtMsg({ id: dataCol.localeId });
            }
        });

        let newcolumnLayout = JSON.stringify(columnLayout);

        if (grid.columnLayout != newcolumnLayout) {
            grid.columnLayout = newcolumnLayout;
            setColumnLayout(newcolumnLayout);
        }
    };

    const setFilterValue = (e: any) => {
        const currentFilterValue = e.target.value.trim();
        if (currentFilterValue.length === 0) {
            onFilterChange("");
        }
        // update filter
        setFilter(e.target.value);
    };

    const collectionFilter = (item) => {
        const currentFilterValue = filter.trim().toLowerCase();
        if (currentFilterValue.length === 0) return true;
        return searchOnColumns.some((element) => {
            return (
                item[element] &&
                item[element]
                    .toString()
                    .toLowerCase()
                    .indexOf(currentFilterValue) > -1
            );
        });
    };

    const onFilterChange = (value: any) => {
        collectionView.filter = collectionFilter;
        collectionView.onPageChanged();
    };

    const onGroupRefreshed = () => {
        if (!grid || !grid.collectionView) {
            return;
        }

        const groupDesc = [...Array(grid.collectionView.groupDescriptions.length).keys()].map((key) => {
            const description = grid.collectionView.groupDescriptions[key] as PropertyGroupDescription;
            return description.propertyName;
        });

        // group desc is not same or there exists some value in sort desc which is not present in state.
        if (groupDesc.length !== descriptions.groupDescriptions.length) {
            setColumnLayout(grid.columnLayout);
            setDescriptions({ groupDescriptions: groupDesc });
        }
    };

    const renderSearch = () => {
        return (
            <div className="search-section">
                <Input.Search
                    defaultValue={""}
                    placeholder={GLGlobal.intl.formatMessage({
                        id: GSAdminLocale.Search,
                    })}
                    value={filter}
                    onChange={(e) => setFilterValue(e)}
                    onSearch={(value) => onFilterChange(value)}
                />
            </div>
        );
    };

    const renderGroupBar = (): React.ReactNode => {
        return <GroupPanel className="group-panel" placeholder={groupBarText} onRefreshed={onGroupRefreshed} grid={grid} />;
    };

    const renderActions = () => {
        return (
            <div className="wgrid__actions">
                {excelExport && (
                    <Button icon="download" onClick={onExportGrid}>
                        {GLGlobal.intl.formatMessage({
                            id: GSAdminLocale.Export,
                        })}
                    </Button>
                )}
            </div>
        );
    };

    const renderHeader = (): React.ReactNode => {
        const isHeaderVisible = title || excelExport;
        return (
            isHeaderVisible && (
                <div className="wgrid__header">
                    <div className="wgrid__title">{title}</div>
                    {renderActions()}
                </div>
            )
        );
    };

    const onExportGrid = () => {
        const currentFlex = grid;
        const collectionView = currentFlex.collectionView as CollectionView;
        collectionView.pageSize = 0;
        FlexGridXlsxConverter.saveAsync(
            currentFlex,
            {
                includeColumnHeaders: true,
                includeCellStyles: false,
                formatItem: props.formatExportItem ? props.formatExportItem : onFormatExport,
            },
            defaultExportFileName
        );
        collectionView.pageSize = pagination ? pageSize || defaultPageSize : 0;
    };

    const onFormatExport = (args) => {
        let cell: IWorkbookCell = args.xlsxCell;
        if (args.panel.cellType === CellType.ColumnHeader) {
            cell.style.font = { bold: true };
        }
    };

    const renderGrid = (): React.ReactNode => {
        return (
            <FlexGrid
                className="wgrid__grid"
                style={{ maxHeight: scroll ? scroll.height : false }}
                itemsSource={wijmoCollectionView}
                selectionMode={SelectionMode.ListBox}
                {...rest}
                initialized={initGrid}
            >
                {children}
            </FlexGrid>
        );
    };

    const renderPagination = (): React.ReactNode => {
        const totalItemCount = (collectionView && collectionView.sourceCollection && collectionView.sourceCollection.length) || 0;
        return (
            <Pagination
                className="wgrid__pagination"
                current={currentPage}
                total={totalItemCount}
                pageSize={pagination ? pageSize || defaultPageSize : 0}
                onChange={onPaginationChange}
            />
        );
    };

    const renderLoader = (): React.ReactNode => {
        return <Loading visible={loading} />;
    };

    const renderNoData = (): React.ReactNode => {
        return (
            <Empty
                className="wgrid__nodata"
                description={GLGlobal.intl.formatMessage({
                    id: noDataLocaleId || GSAdminLocale.ChangeLogNoData,
                })}
            />
        );
    };

    return (
        <div className="wgrid">
            {renderHeader()}
            {searching && renderSearch()}
            {grouping && renderGroupBar()}
            {renderGrid()}
            {renderLoader()}
            {noData && renderNoData()}
            {pagination && !noData && renderPagination()}
        </div>
    );
});

interface WijmoGridProps {
    allowAddNew?: boolean;
    allowMerging?: AllowMerging;
    allowResizing?: AllowResizing;
    headersVisibility?: HeadersVisibility;
    selectionMode?: SelectionMode;
    showMarquee?: boolean;
    showSelectedHeaders?: string;
    stickyHeaders?: boolean;
    itemsSource?: any;
    isReadOnly?: boolean;
    formatItem?: (s: FlexGridRef, e: FormatItemEventArgs) => any;
    formatExportItem?: (args: XlsxFormatItemEventArgs) => any;
    initialized?: (grid: FlexGridRef) => any;
    onSortedColumn?: (e: CellRangeEventArgs) => void;
}
