import { getUnitTextForLocalProduct } from "@app/page/product/components/unitprice";

export const getUnitText = (value: number) =>
	`${
		value
			? value > 0
				? getUnitTextForLocalProduct(value, false)
				: getUnitTextForLocalProduct(Math.abs(value), true)
			: ""
	}`;
