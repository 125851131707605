import groupBy from "lodash/groupBy";
import {
    GLGlobal,
    HttpStatusCode,
    maskThrottle,
    MessageHelper,
    NotificationType,
    PaginationParams,
    unmaskThrottle
} from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { StateType } from "./index";
import { CampusModel, CampusService } from "@app/service/school/campus/index";
import { UserService } from "@app/service/users";
import { SchoolModel, SchoolService } from "@app/service/schools";
import {
    CumulativeGridItemModel,
    InvalidMaterialOrderEditException,
    InvalidMaterialOrderStatusException,
    MaterialModel,
    MaterialOrderModel,
    MaterialOrdersModel,
    MaterialService,
    RequestProductItemModel,
    SubscriptionItemDisplayModel,
    SubscriptionMaterialService,
    SubscriptionRequestModel
} from "@app/service/material";
import { ContextHelper, MaterialHelper } from "@app/util/helper";
import { clearQuery, stringCompare } from "@app/util/func";
import { SchoolLocale } from "@app/locales/localeid";
import { MaterialOrderStatus } from "@app/util";
import { isUndefined } from "lodash";

interface Services {
    school: SchoolService;
    material: MaterialService;
    campus: CampusService;
    subscription: SubscriptionMaterialService;
    user: UserService;
}

export interface MaterialState {
    model?: MaterialModel;
    loading?: boolean;
    school?: SchoolModel[];
    campus?: CampusModel;
    campuses?: CampusModel[];
    subscriptionRequest?: SubscriptionRequestModel;
    subscriptions?: SubscriptionItemDisplayModel[];
    purchases?: RequestProductItemModel[];
    cumulativeData?: CumulativeGridItemModel[];
    orders?: MaterialOrdersModel[];
    order?: MaterialOrderModel;
    error?: any;
    statusCounts?: {
        allCnt: number;
        initializedCnt: number;
        draftCnt: number;
        submittedCnt: number;
        inProgressCnt: number;
        shippedCnt: number;
        closedCnt: number;
    };
    totalCount?: number;
    conditions?: string;
    conditionsLocale?: string;
}

export default {
    namespace: "material",
    state: {
        model: {},
        loading: false,
        school: {},
        campus: {},
        campuses: [],
        subscriptionRequest: {},
        subscriptions: [],
        purchases: [],
        cumulativeData: [],
        orders: [],
        order: {},
        error: null,
        statusCounts: {
            allCnt: 0,
            submittedCnt: 0,
            draftCnt: 0,
            inProgressCnt: 0,
            shippedCnt: 0,
            closedCnt: 0
        },
        totalCount: 0,
        conditions: "",
        conditionsLocale: "",
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        showLoading(state, action) {
            return { ...state, loading: true };
        },
        hideLoading(state, action) {
            return { ...state, loading: false };
        }
    },
    effects: {
        *create(
            { payload },
            { call, put },
            { material }: Services,
            { push, pathParse, pathStringify }
        ) {
            const path = payload.id
                ? PathConfig.CampusOrdersDraftItem
                : PathConfig.CampusCart;
            const { regionId, schoolId, campusId } = pathParse({ path });
            try {
                const response: MaterialOrderModel = yield call(material.create, { ...payload, schoolId, campusId });
                clearQuery("classes");
                const returnPath = payload.id
                    ? pathStringify(PathConfig.CampusOrders, {
                          campusId,
                          schoolId,
                          regionId
                      })
                    : pathStringify(PathConfig.Classes, {
                          campusId,
                          schoolId,
                          regionId
                      });
                yield put(push(returnPath));
                if (payload.isSubmit) {
                    const detailPath = pathStringify(PathConfig.CampusOrdersItem, {
                        campusId,
                        schoolId,
                        regionId,
                        orderId: response.id,
                    });
                    if (payload.showToast) {
                        payload.showToast(response.materialRequestId, detailPath);
                    }
                }
            } catch (response) {
                //console.log(response);
            }
        },
        *createMaterialRequest(
            { payload },
            { call, put },
            { material }: Services,
            { push, pathParse, pathStringify }
        ) {
            const data = payload.data;
            const path = PathConfig.SchoolCart;
            const { regionId, schoolId } = pathParse({ path });
            try {
                const response: MaterialOrderModel = yield call(material.create, { ...data });
                clearQuery("classes");
                if (data.isSubmit) {
                    const detailPath = pathStringify(PathConfig.CampusOrdersItem, {
                        campusId: payload.data.campusId,
                        schoolId,
                        regionId,
                        orderId: response.id,
                    });
                    if (payload.showToast) {
                        payload.showToast(response.materialRequestId, detailPath);
                    }
                    yield put(payload.reloadCampus());
                } else if (!data.isSubmit) {
                    const returnPath = pathStringify(PathConfig.Schools, {
                        schoolId,
                        regionId
                    });
                    yield put(push(returnPath));
                }
            } catch (response) {
                console.error("createMaterialRequest exception", response);
            }
        },
        *getMaterialCart(
            { payload: campusId },
            { all, call, put },
            { school, campus, material }: Services,
            { pathParse }
        ) {
            maskThrottle();
            let schoolId = pathParse({ path: PathConfig.CampusCart }).schoolId;
            if (!schoolId) {
                schoolId = pathParse({ path: PathConfig.SchoolCart }).schoolId;
            }
            const userProfile = ContextHelper.getUserLoginInfo().profile;
            try {
                const [schools, campuses, materialCart] = yield all([
                    call(school.get, null, { id: schoolId }),
                    call(campus.getItemsBy, new PaginationParams(), {
                        schoolId
                    }),
                    call(material.getMaterialCart, schoolId, campusId)
                ]);
                yield put(formatMaterialCart({ materialCart }));
                const currentCampus = campuses.data.find(
                    campus => campus.id == campusId
                );
                const address = currentCampus.fullAddress
                const cityState = currentCampus.state
                    ? `${currentCampus.city}, ${currentCampus.state}`
                    : currentCampus.city;
                const mergedAddress = materialCart.address
                    ? materialCart.address
                    : address;
                yield put(
                    reload({
                        school: schools,    
                        campuses: campuses,
                        model: {
                            ...materialCart,
                            submissionUser:
                                materialCart.submissionUser || userProfile.name,
                            submissionEmail:
                                materialCart.submissionEmail ||
                                userProfile.email,
                            postalCode: currentCampus.postalCode,
                            address: mergedAddress, //can not delete, this field is used to save address when update campus cart
                            address1: currentCampus.address1, //can not delete, this field is used to display
                            address2: currentCampus.address2, //can not delete, this field is used to display
                            cityState: cityState, //can not delete, this field is used to display
                            shippingPhone:
                                materialCart.shippingPhone ||
                                currentCampus.phone
                        }
                    })
                );
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : null;
                MessageHelper.ShowError(error);
            }
            unmaskThrottle();
        },
        *formatMaterialCart(
            { payload: { materialCart } },
            { call, put, select },
            { user }: Services
        ) {
            const subscriptionRequest = materialCart
                ? materialCart.subscriptionRequests
                : yield select(
                      (state: StateType) => state.material.subscriptionRequest
                  );
            const purchaseItems = materialCart
                ? materialCart.purchaseItems
                : yield select((state: StateType) => state.material.purchases);
            const requestUserIds = [
                ...MaterialHelper.getSubscriptionUserIds(subscriptionRequest),
                ...MaterialHelper.getPurchaseUserIds(purchaseItems)
            ];
            let requestUsers;
            if (requestUserIds.length > 0) {
                const data = yield call(user.getUsersByPost, {
                    ids: requestUserIds
                });
                requestUsers = data.data;
            }
            const subscriptions = MaterialHelper.formatSubscriptions(
                subscriptionRequest,
                requestUsers
            );
            const purchases = MaterialHelper.formatPurchases(
                purchaseItems,
                requestUsers
            )
            const cumulativeData = MaterialHelper.formatCumulativeData(subscriptions, purchases);
            yield put(
                reload({
                    subscriptionRequest: subscriptionRequest,
                    subscriptions:subscriptions,
                    purchases: purchases,
                    cumulativeData:cumulativeData,
                    error: null
                })
            );
        },
        *createPurchaseItem(
            { payload: { schoolId, campusId, orderId, ...rest } },
            { call, put },
            { material }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.createPurchaseItem, {
                    ...rest,
                    campusId,
                    materialOrderId: orderId
                });
                let materialCart;
                if (orderId) {
                    materialCart = yield call(material.get, null, {
                        id: orderId
                    });
                } else {
                    materialCart = yield call(
                        material.getMaterialCart,
                        schoolId,
                        campusId
                    );
                }
                yield put(formatMaterialCart({ materialCart }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *updatePurchaseItem(
            { payload: { schoolId, campusId, id, orderId, ...rest } },
            { call, put },
            { material }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.updatePurchaseItem, id, rest);
                let materialCart;
                if (orderId) {
                    materialCart = yield call(material.get, null, {
                        id: orderId
                    });
                } else {
                    materialCart = yield call(
                        material.getMaterialCart,
                        schoolId,
                        campusId
                    );
                }

                if (!isUndefined(rest.shippedQuantity) && rest.updateShippingQuantity) {
                    let purchaseItems = materialCart.purchaseItems.map(item => {
                        if (item.id === id) {
                            item.shippedQuantity = rest.shippedQuantity;
                        }
                        return item;
                    });

                    materialCart.purchaseItems = purchaseItems;
                }

                yield put(formatMaterialCart({ materialCart }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *deletePurchaseItem(
            { payload: { schoolId, campusId, id, orderId } },
            { call, put },
            { material }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.deletePurchaseItem, id);
                let materialCart;
                if (orderId) {
                    materialCart = yield call(material.get, null, {
                        id: orderId
                    });
                } else {
                    materialCart = yield call(
                        material.getMaterialCart,
                        schoolId,
                        campusId
                    );
                }
                yield put(formatMaterialCart({ materialCart }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                processError(error);
            }
            unmaskThrottle();
        },
        *deleteSubscriptionItem(
            { payload: { schoolId, campusId, requestId, id, orderId } },
            { call, put },
            { subscription, material }: Services
        ): any {
            try {
                maskThrottle();
                yield call(subscription.deleteSubscriptionItem, requestId, id);
                //yield call(subscription.delete, id);
                let materialCart;
                if (orderId) {
                    materialCart = yield call(material.get, null, {
                        id: orderId
                    });
                } else {
                    materialCart = yield call(
                        material.getMaterialCart,
                        schoolId,
                        campusId
                    );
                }
                yield put(formatMaterialCart({ materialCart }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                processError(error);
            }
            unmaskThrottle();
        },
        *getClassCart({ payload }, { call, put }, { subscription }: Services) {
            maskThrottle();
            try {
                const subscriptionCart = yield call(
                    subscription.getSubscriptionCart,
                    payload
                );
                yield put(formatSubscriptionCart({ subscriptionCart }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : null;
                MessageHelper.ShowError(error);
            }
            unmaskThrottle();
        },
        *formatSubscriptionCart(
            { payload: { subscriptionCart } },
            { call, put, select },
            { user }: Services
        ) {
            const subscriptionRequest = subscriptionCart
                ? subscriptionCart.subscriptionRequestDetails
                : yield select(
                      (state: StateType) => state.material.subscriptionRequest
                  );
            const purchaseRequestDetails = subscriptionCart
                ? subscriptionCart.purchaseRequestDetails
                : yield select((state: StateType) => state.material.purchases);
            const requestUserIds = [
                ...MaterialHelper.getSubscriptionUserIds(
                    subscriptionRequest.subscriptionItems
                ),
                ...MaterialHelper.getPurchaseUserIds(purchaseRequestDetails)
            ];
            let requestUsers;
            if (requestUserIds.length > 0) {
                const data = yield call(user.getUsersByPost, {
                    ids: requestUserIds
                });
                requestUsers = data.data;
            }
            yield put(
                reload({
                    subscriptionRequest: subscriptionRequest,
                    subscriptions: MaterialHelper.formatSubscriptions4Class(
                        subscriptionRequest.subscriptionItems,
                        requestUsers
                    ),
                    purchases: MaterialHelper.formatPurchases(
                        purchaseRequestDetails,
                        requestUsers
                    )
                })
            );
        },
        *createSubscriptionItem(
            {
                payload: {
                    schoolId,
                    campusId,
                    classId,
                    requestItemId,
                    orderId,
                    ...rest
                }
            },
            { call, select, put },
            { subscription, material, user }: Services
        ): any {
            const { id: requestId } = yield select(
                (state: StateType) => state.material.subscriptionRequest
            );
            try {
                maskThrottle();
                yield call(
                    subscription.createSubscription,
                    requestId || requestItemId,
                    rest
                );
                if (requestId) {
                    const subscriptionCart = yield call(
                        subscription.getSubscriptionCart,
                        { schoolId, campusId, classId }
                    );
                    yield put(formatSubscriptionCart({ subscriptionCart }));
                    MessageHelper.Message(
                        NotificationType.Success,
                        GLGlobal.intl.formatMessage({
                            id: SchoolLocale.MaterialRequestMessageSubmitted
                        })
                    );
                }
                if (requestItemId) {
                    let materialCart;
                    if (orderId) {
                        materialCart = yield call(material.get, null, {
                            id: orderId
                        });
                    } else {
                        materialCart = yield call(
                            material.getMaterialCart,
                            schoolId,
                            campusId
                        );
                    }
                    yield put(formatMaterialCart({ materialCart }));
                }
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *updateSubscriptionItem(
            { payload: { schoolId, campusId, classId, id, orderId, ...rest } },
            { call, put },
            { subscription, material }: Services
        ): any {
            try {
                maskThrottle();
                yield call(
                    subscription.updateSubscriptionItem,
                    rest.requestId || rest.requestItemId,
                    id,
                    rest
                );
                if (rest.requestId) {
                    yield put(reload({ loading: true }));
                    const subscriptionCart = yield call(
                        subscription.getSubscriptionCart,
                        { schoolId, campusId, classId }
                    );
                    yield put(formatSubscriptionCart({ subscriptionCart }));
                }
                if (rest.requestItemId) {
                    let materialCart;
                    if (orderId) {
                        materialCart = yield call(material.get, null, {
                            id: orderId
                        });
                    } else {
                        materialCart = yield call(
                            material.getMaterialCart,
                            schoolId,
                            campusId
                        );
                    }
                    yield put(formatMaterialCart({ materialCart }));
                }
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *deleteSubscriptionItem4Class(
            {
                payload: { schoolId, campusId, classId, requestId, id, orderId }
            },
            { call, put },
            { subscription }: Services
        ): any {
            try {
                maskThrottle();
                yield call(subscription.deleteSubscriptionItem, requestId, id);
                yield put(reload({ loading: true }));
                const subscriptionCart = yield call(
                    subscription.getSubscriptionCart,
                    { schoolId, campusId, classId }
                );
                yield put(formatSubscriptionCart({ subscriptionCart }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *createPurchaseItem4Class(
            { payload: { schoolId, campusId, classId, ...rest } },
            { call, put },
            { material, subscription }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.createPurchaseItem, {
                    ...rest,
                    campusId,
                    classId
                });
                const subscriptionCart = yield call(
                    subscription.getSubscriptionCart,
                    { schoolId, campusId, classId }
                );
                yield put(formatSubscriptionCart({ subscriptionCart }));
                MessageHelper.Message(
                    NotificationType.Success,
                    GLGlobal.intl.formatMessage({
                        id: SchoolLocale.MaterialRequestMessageSubmitted
                    })
                );
            } catch (response) {
                //console.log(response);
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *updatePurchaseItem4Class(
            { payload: { schoolId, campusId, id, classId, ...rest } },
            { call, put },
            { material, subscription }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.updatePurchaseItem, id, {
                    classId,
                    ...rest
                });
                yield put(reload({ loading: true }));
                const subscriptionCart = yield call(
                    subscription.getSubscriptionCart,
                    { schoolId, campusId, classId }
                );
                yield put(formatSubscriptionCart({ subscriptionCart }));
            } catch (response) {
                //console.log(response);
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : unexpectError;
                yield put(reload({ error: error }));
                processError(error);
            }
            unmaskThrottle();
        },
        *deletePurchaseItem4Class(
            { payload: { schoolId, campusId, classId, id } },
            { call, put },
            { material, subscription }: Services
        ): any {
            try {
                maskThrottle();
                yield call(material.deletePurchaseItem, id);
                yield put(reload({ loading: true }));
                const subscriptionCart = yield call(
                    subscription.getSubscriptionCart,
                    { schoolId, campusId, classId }
                );
                yield put(formatSubscriptionCart({ subscriptionCart }));
            } catch (response) {
                //console.log(response);
            }
            unmaskThrottle();
        },
        *getMaterialOrders(
            { payload: { schoolId, campusId } },
            { all, call, put },
            { campus, material }: Services
        ) {
            maskThrottle();
            try {
                const [campuses, orders] = yield all([
                    call(campus.getItemsBy, new PaginationParams(), {
                        schoolId
                    }),
                    call(material.getItemsBy, {
                        ...new PaginationParams(),
                        campusId
                    })
                ]);
                yield put(
                    reload({
                        campuses: campuses,
                        orders: orders.data //MaterialHelper.FormatMaterialOrders(orders.data)
                    })
                );
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : null;
                MessageHelper.ShowError(error);
            }
            unmaskThrottle();
        },
        *getMaterialOrder(
            { payload: { schoolId, campusId, orderId } },
            { all, call, put },
            { school, campus, material }: Services
        ) {
            maskThrottle();
            try {
                const [schoolData, campusData, order] = yield all([
                    call(school.get, null, { id: schoolId }),
                    call(campus.get, null, { campusId, schoolId }),
                    call(material.get, null, { id: orderId })
                ]);
                yield put(
                    reload({
                        school: schoolData,
                        campus: campusData,
                        order: order
                    })
                );
                yield put(formatMaterialCart({ materialCart: order }));
            } catch (response) {
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : null;
                MessageHelper.ShowError(error);
            }
            unmaskThrottle();
        },
        *updateMaterialOrder(
            { payload: { orderId, from, ...rest } },
            { call, put },
            { material }: Services,
            { push, pathParse, pathStringify }
        ): any {
            try {
                maskThrottle();
                const { regionId, schoolId, campusId } = pathParse({
                    path: PathConfig.CampusOrdersItem
                });
                yield call(material.update, rest, { id: orderId });
                if (from) {
                    yield put(
                        push(
                            pathStringify(PathConfig.RegionOrders, { regionId })
                        )
                    );
                } else {
                    yield put(
                        push(
                            pathStringify(PathConfig.CampusOrders, {
                                regionId,
                                schoolId,
                                campusId
                            })
                        )
                    );
                }
            } catch (response) {}
            unmaskThrottle();
        },
        *getRegionOrders(
            { payload: { query } },
            { call, put },
            { material }: Services,
            { pathParse }
        ) {
            maskThrottle();
            try {
                const { regionId } = pathParse({
                    path: PathConfig.RegionOrders
                });
                const { data: orders, totalCount, extraData } = yield call(
                    material.getRegionOrders,
                    regionId,
                    {
                        ...query
                    }
                );
                const allCnt =
                    extraData["allCnt"] - extraData["initializedCnt"];
                const statusCounts = { ...extraData, allCnt };
                const mergeTotalCount =
                    query.status == null
                        ? totalCount - extraData["initializedCnt"]
                        : totalCount;
                yield put(
                    reload({
                        orders: formatOrders(orders),
                        totalCount: mergeTotalCount,
                        statusCounts
                    })
                );
            } catch (response) {
                console.log(response);
            }
            unmaskThrottle();
        },
        *getMaterialOrder4Cart(
            { payload: { schoolId, campusId, orderId } },
            { all, call, put },
            { school, campus, material }: Services
        ) {
            maskThrottle();
            try {
                const [schools, campuses, order] = yield all([
                    call(school.get, null, { id: schoolId }),
                    call(campus.getItemsBy, new PaginationParams(), {
                        schoolId
                    }),
                    call(material.get, null, { id: orderId })
                ]);
                yield put(formatMaterialCart({ materialCart: order }));
                const currentCampus = campuses.data.find(
                    campus => campus.id == campusId
                );
                const addresses = order.address.split("\n");
                const cityState = currentCampus.state
                    ? `${currentCampus.city}, ${currentCampus.state}`
                    : currentCampus.city;
                yield put(
                    reload({
                        school: schools,
                        campuses: campuses,
                        model: {
                            ...order,
                            address1: addresses[0], //can not delete, this field is used to display
                            address2: addresses.length > 1 ? addresses[1] : "", //can not delete, this field is used to display
                            cityState: cityState //can not delete, this field is used to display
                        }
                    })
                );
            } catch (response) {
                console.log(response);
                const error =
                    response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                        ? JSON.parse(response.text)
                        : null;
                MessageHelper.ShowError(error);
            }
            unmaskThrottle();
        },
        *getConditions(
            { payload },
            { call, put, select },
            { material }: Services,
        ) {
            const locale = GLGlobal.intl.locale;
            const loadedConditionsLocale = yield select(
                (state: StateType) => state.material.conditionsLocale
            );
            if (locale !== loadedConditionsLocale) {
                try {
                    const conditions = yield call(material.getConditions, locale);
                    yield put(reload({ conditions, conditionsLocale: locale }));
                } catch (response) {
                    const error =
                        response.statusCode == HttpStatusCode.BadRequest &&
                        response.text
                            ? JSON.parse(response.text)
                            : unexpectError;
                    yield put(reload({ error: error }));
                    processError(error);
                }
                unmaskThrottle();
            }
        },
    },
    services: {
        school: SchoolService,
        campus: CampusService,
        subscription: SubscriptionMaterialService,
        user: UserService,
        material: MaterialService
    }
};

export function showLoading() {
    return { type: "material/showLoading" };
}
export function hideLoading() {
    return { type: "material/hideLoading" };
}
export function reload(state) {
    return { type: "material/reload", payload: state };
}
export function create(state) {
    return { type: "material/create", payload: state };
}
export function createMaterialRequest(state) {
    return { type: "material/createMaterialRequest", payload: state };
}
export function getMaterialCart(state) {
    return { type: "material/getMaterialCart", payload: state };
}
export function formatMaterialCart(state) {
    return { type: "material/formatMaterialCart", payload: state };
}
export function createPurchaseItem(state) {
    return { type: "material/createPurchaseItem", payload: state };
}
export function updatePurchaseItem(state) {
    return { type: "material/updatePurchaseItem", payload: state };
}
export function deletePurchaseItem(state) {
    return { type: "material/deletePurchaseItem", payload: state };
}
export function deleteSubscriptionItem(state) {
    return { type: "material/deleteSubscriptionItem", payload: state };
}
export function getClassCart(state) {
    return { type: "material/getClassCart", payload: state };
}
export function formatSubscriptionCart(state) {
    return { type: "material/formatSubscriptionCart", payload: state };
}
export function createSubscriptionItem(state) {
    return { type: "material/createSubscriptionItem", payload: state };
}
export function updateSubscriptionItem(state) {
    return { type: "material/updateSubscriptionItem", payload: state };
}
export function deleteSubscriptionItem4Class(state) {
    return { type: "material/deleteSubscriptionItem4Class", payload: state };
}
export function createPurchaseItem4Class(state) {
    return { type: "material/createPurchaseItem4Class", payload: state };
}
export function updatePurchaseItem4Class(state) {
    return { type: "material/updatePurchaseItem4Class", payload: state };
}
export function deletePurchaseItem4Class(state) {
    return { type: "material/deletePurchaseItem4Class", payload: state };
}
export function getMaterialOrders(state) {
    return { type: "material/getMaterialOrders", payload: state };
}
export function getMaterialOrder(state) {
    return { type: "material/getMaterialOrder", payload: state };
}
export function updateMaterialOrder(state) {
    return { type: "material/updateMaterialOrder", payload: state };
}
export function getRegionOrders(state) {
    return { type: "material/getRegionOrders", payload: state };
}
export function getMaterialOrder4Cart(state) {
    return { type: "material/getMaterialOrder4Cart", payload: state };
}
export function getConditions() {
    return { type: "material/getConditions" };
}

function formatOrders(orders: any[]): MaterialOrdersModel[] {
    const result = [];
    orders.forEach(order => {
        if (
            !order.schoolClassMaterialOrderProduct ||
            (order.schoolClassMaterialOrderProduct &&
                order.schoolClassMaterialOrderProduct.length == 0)
        ) {
            result.push({ ...order });
        } else {
            order.schoolClassMaterialOrderProduct
                .sort(d => (d.schoolClassId ? 1 : -1))
                .forEach(schoolClass => {
                    if (
                        !schoolClass.materialOrderProduct ||
                        (schoolClass.materialOrderProduct &&
                            schoolClass.materialOrderProduct.length == 0)
                    ) {
                        result.push({
                            ...order,
                            ...schoolClass
                        });
                    } else {
                        const schoolClassProducts = groupBy(
                            schoolClass.materialOrderProduct.sort(
                                (productA, productB) =>
                                    stringCompare(
                                        productA.productName,
                                        productB.productName
                                    )
                            ),
                            "productId"
                        );
                        Object.keys(schoolClassProducts).forEach(productId => {
                            if (schoolClassProducts[productId].length > 0) {
                                result.push({
                                    ...order,
                                    ...schoolClass,
                                    ...schoolClassProducts[productId][0],
                                    quantity: schoolClassProducts[
                                        productId
                                    ].reduce((pre, cur) => {
                                        return pre + cur.quantity;
                                    }, 0)
                                });
                            }
                        });
                    }
                });
        }
    });
    return result;
}

const unexpectError = "unexpect";
export function processError(error) {
    const errorCode = error.error_code ? error.error_code : error;
    switch (errorCode) {
        case InvalidMaterialOrderStatusException:
            MessageHelper.Message(
                NotificationType.Failed,
                GLGlobal.intl.formatMessage({
                    id: SchoolLocale.MaterialOrderErrorStatus
                })
            );
            break;
        case InvalidMaterialOrderEditException:
            MessageHelper.Message(
                NotificationType.Failed,
                GLGlobal.intl.formatMessage({
                    id: SchoolLocale.MaterialOrderErrorEdit
                })
            );
            break;
        case unexpectError:
            //do nothing
            break;
        default:
            MessageHelper.ShowError(error);
            break;
    }
}
