import { WeeklyRecurrenceDay } from "@app/service/survey";
import { SurveyLocale } from "@app/locales/localeid";

export const localeDayMap = {
    [WeeklyRecurrenceDay.Sun]: SurveyLocale.RecurrenceDaySun,
    [WeeklyRecurrenceDay.Mon]: SurveyLocale.RecurrenceDayMon,
    [WeeklyRecurrenceDay.Tue]: SurveyLocale.RecurrenceDayTues,
    [WeeklyRecurrenceDay.Wed]: SurveyLocale.RecurrenceDayWed,
    [WeeklyRecurrenceDay.Thu]: SurveyLocale.RecurrenceDayThur,
    [WeeklyRecurrenceDay.Fri]: SurveyLocale.RecurrenceDayFri,
    [WeeklyRecurrenceDay.Sat]: SurveyLocale.RecurrenceDaySat
};

export const localeFullDayMap = {
    [WeeklyRecurrenceDay.Sun]: SurveyLocale.RecurrenceDaySunday,
    [WeeklyRecurrenceDay.Mon]: SurveyLocale.RecurrenceDayMonday,
    [WeeklyRecurrenceDay.Tue]: SurveyLocale.RecurrenceDayTuesday,
    [WeeklyRecurrenceDay.Wed]: SurveyLocale.RecurrenceDayWednesday,
    [WeeklyRecurrenceDay.Thu]: SurveyLocale.RecurrenceDayThursday,
    [WeeklyRecurrenceDay.Fri]: SurveyLocale.RecurrenceDayFriday,
    [WeeklyRecurrenceDay.Sat]: SurveyLocale.RecurrenceDaySaturday
};