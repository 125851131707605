import * as React from 'react'
import ReactDOM from 'react-dom';

export class LazyPortal extends React.Component<{ selector }, { didMount }> {
    container: any
    constructor(props) {
        super(props);
        this.state = {
            didMount: false
        }
    }

    componentDidMount() {
        const lazyload = () => {
            this.container = document.querySelector(this.props.selector);
            if (!this.container) {
                setTimeout(() => lazyload(), 300)
            } else {
                this.setState({ didMount: true });
            }
        }
        lazyload();
    }

    render() {
        return this.state.didMount ? ReactDOM.createPortal(
            this.props.children,
            this.container,
        ) : null;
    }
}