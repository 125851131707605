import React, { Component } from 'react';
import "../component/progress.less";
import { Feedbacks } from './feedback';
import { VideoManager } from './video-manager';
import { CoachFeedbacks } from './coach-feedback';
import { PrevisitationForm } from './previsitation-form';
import { GLForm, GLFormComponentProps } from 'gl-commonui';
import { VisitationTeacherStage } from '@app/util/coach/enum';
import { TeacherRelatedModel } from '@app/service/coach/visitation';

interface TeacherTabsProps extends GLFormComponentProps {
    visitation: TeacherRelatedModel
    teacherStage: number
    selectedStage: number
    callback: (stage) => void
}
interface TeacherTabsStates {
    status?: number
    visibleModal?: boolean
}

@GLForm.create()
export class TeacherTabs extends Component<TeacherTabsProps, TeacherTabsStates> {
    constructor(props) {
        super(props);
        this.state = { 
            visibleModal: false,
        }
    }
    renderTabPane() {
        const { visitation, teacherStage, selectedStage, callback } = this.props;
        const stage = selectedStage ? selectedStage : teacherStage;
        switch(stage) {
            case VisitationTeacherStage.PrevisitationForm:
                return <PrevisitationForm 
                            visitation={visitation} 
                            callback={callback} 
                            teacherStage={teacherStage} />;
            case VisitationTeacherStage.VideoManager:
                return <VideoManager 
                            visitation={visitation} 
                            callback={callback} 
                            teacherStage={teacherStage} />;
            case VisitationTeacherStage.ViewCoachFeedback:
                return <Feedbacks 
                            visitation={visitation} 
                            callback={callback} 
                            teacherStage={teacherStage} />;
            case VisitationTeacherStage.GiveCoachFeedback:
            case VisitationTeacherStage.Completed:
            case VisitationTeacherStage.CompletedByCoach:
                return <CoachFeedbacks 
                            visitation={visitation} 
                            callback={callback} 
                            teacherStage={teacherStage} />;
        }
    }
    render() {
        return <div className="teacher-tabs">
            {this.renderTabPane()}
        </div>
    }
}