import { GLResponse,GLRequest,GLPagingRequest } from "gl-commonui";

export interface UserModel {
    id?: string
    name?: string
    email?: string
    phone?: string
    phoneCountryCode?: string
    region?: string
    gender?: string
    city?: string
    disabled?: boolean
    roles?: string[]
    role?: string
    invitationChannel?: number
    children?: string[]
    childrenList?: string
}

export interface UserRoleModel {
    id?: string
    name?: string
    accessResource?: string
}

export enum UserRoleProps {
    name = 'name',
    accessResource = 'accessResource'
}

export enum UserFormModelNames {
    region = 'region',
    school = 'school',
    role = 'role',
    deviceActivationLimit = 'deviceActivationLimit',
    name = 'name',
    phone = 'phone'
}
export enum UserModelPropNames{
    id = 'id',
    name = 'name',
    email = 'email',
    phone = 'phone',
    region = 'region',
    gender = 'gender',
    city = 'city',
    disabled = 'disabled',
    deviceActivationLimit = 'deviceActivationLimit',
    role = 'role',
    childrenList = 'childrenList',
}
export interface UserInvitationModel {
    id?: string
    name?: string
    email?: string
    invitationtype?: string
    template?: string
}

export interface UserPendingModel {
    id?: string
    name?: string
    email?: string
    type?: string
    creationDate?: string
}

export enum UserPendingProps{
    name = 'name',
    email = 'email',
    type = 'type',
    creationDate = 'creationDate',
}

export class GetUserRequest extends GLPagingRequest {
    id?: string
    role?: string
    userName?: string
    email?: string
    roleType?: number
    referenceResourceId?: string
}
export interface UserRequest extends GLRequest, UserModel {
}
export interface UserResponse extends GLResponse, UserModel {
}
export interface UsersPagingRequest extends GLPagingRequest { }

export interface RedeemModel {
    email?: string
    password?: string
}

export enum RedeemProps {
    email = 'email',
    password = 'password'
}

export enum VerificationCodeValidationResult {
	Valid = 0,
	InvalidCode = 1,
	ExpiredCode = 2,
}

export interface UniquenessEmailAndPhoneRequestModel {
	email?: string;
	phone?: string;
}

export interface UniquenessEmailAndPhoneResponseModel {
	isValidEmail: boolean;
	isValidPhone: boolean;
}


export interface VerifyCodeRequestModel {
	codeId: number;
	receiver: string;
	code: string;
}

export interface CreateUserRequestModel {
	userName: string;
	englishName: string;
	password: string;
	email: string;
	phone: string;
	phoneCountryCode: string;
	invitationCodeType: number;
	invitationCode: string;
	invitationCodeId: string;
	emailVerificationCodeId: number;
	emailVerificationCode: string;
	phoneVerificationCodeId: number;
	phoneVerificationCode: string;
}