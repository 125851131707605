import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface ContentVersionModel {
    id?: string;
    curriculumType?: number;
    name?: string;
}

export interface LessonPlanVersionModel {
    id: string;
    versionGroupId: string;
    name: string;
    description: string;
    status: number;
    methodology: number;
    createdTime: Date;
    updatedTime: Date;
    lessonPlan: any[];
}

export interface LessonPlanTemplateModel {
    id?: string;
    name?: string;
    type?: number;
    default?: boolean;
    headerRowRange?: string;
    teachingPlanRowRange?: string;
    transitionRowRange?: string;
    templateFileName?: string;
    templateFile?: File;
    status?: number;
}

export type LessonPlanVersionModelPick<
    K extends keyof LessonPlanVersionModel
> = Pick<LessonPlanVersionModel, K>;

export type LessonPlanTemplateModelPick<
    K extends keyof LessonPlanTemplateModel
> = Pick<LessonPlanTemplateModel, K>;

export const enum MethodologyType {
    All = -1,
    Classic = 0,
    Digital = 1,
    Remote = 2,
    Independent = 3,
    REP = 4
}

export const enum LessonPlanVersionStatus {
    Draft = 0,
    Published = 1,
    Internal = 2
}

export const enum LessonPlanTemplateStatus {
    Draft = 0,
    Published = 1
}

export const enum LessonPlanTemplateType {
    Web = 0,
    Download = 1,
    Export = 2
}

export class GetContentVersionRequest extends GLPagingRequest {}
export interface ContentVersionRequest extends GLRequest, ContentVersionModel {}
export interface ContentVersionResponse
    extends GLResponse,
        ContentVersionModel {}
