import * as React from 'react';
import { Component } from 'react';
import { WrappedFormUtils } from "antd/lib/form/Form";
import { Select, Menu, Dropdown, Button, Icon } from 'antd-min';
import * as bowser from 'bowser';
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { SchoolLocale } from "@app/locales/localeid";
import { alignPop, loadable } from 'gl-commonui';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './gl-richtexteditor.less';
import { TemplateTagCode } from '@app/util';

let EditorState, convertToRaw, ContentState, Modifier = null;
let htmlToDraft = null;
let draftToHtml = null;
const Editor = loadable({
    loader: () => import("react-draft-wysiwyg"),
    export: ({ Editor }) => Editor,
    forwardRef: true
})

export enum GLRichTextEditorToolbarOptions {
    All = 0,
    History = 1
}

interface GLRichTextEditorProps {
    readOnly?: boolean
    editorHtml?: string
    toolbarOptions?: GLRichTextEditorToolbarOptions
    toolbarCustomButtons?: JSX.Element[]
    customDecorators?: RichTextDecorator[]
    forceRender?: boolean
    form?: WrappedFormUtils
    fieldName?: string
    maxLength?: number
    autoShowToolbar?: boolean
    placeholder?: string
    onValueChanged?: (value: string, fieldName?: string, maxLength?: number, form?: WrappedFormUtils) => void
    onFocusHandle?: (event) => void
    onBlurHandle?: (event) => void
}

interface GLRichTextEditorStates {
    editorState?: any
    textState: string;
    wrapperClassName?: any
    editorClassName?: any
    toolbarClassName?: any
    toolbarStyle?: any
    componentLoaded?: boolean
}

export interface RichTextDecorator {
    strategy: (contentBlock, callback, contentState) => void
    component: Function
}

export class GLRichTextEditor extends Component<GLRichTextEditorProps, GLRichTextEditorStates> {
    domEditor: any
    loadComponent: Promise<any>
    constructor(props) {
        super(props);
        this.state = { 
            textState: "",
            toolbarStyle: {display: 'none'},
            ...this.getEditorClassName(false),
            componentLoaded: false,
        };
        this.getEditorClassName = this.getEditorClassName.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onFocusHandle = this.onFocusHandle.bind(this);
        this.onBlurHandle = this.onBlurHandle.bind(this);
        this.setDomEditorRef = this.setDomEditorRef.bind(this);
    }

    private setDomEditorRef(ref) {
        this.domEditor = ref;
    }

    componentDidMount() {
        this.loadComponent = Promise.all([import("../../util/html2draft/library/index"), import("draftjs-to-html"), import("draft-js")]).then(([html2draft, draftjstohtml, draftjs]) => {
            htmlToDraft = html2draft.default;
            draftToHtml = draftjstohtml.default;
            EditorState = draftjs.EditorState;
            convertToRaw = draftjs.convertToRaw;
            ContentState = draftjs.ContentState;
            Modifier = draftjs.Modifier;
            this.setState({ componentLoaded: true });
        })
    }

    focusEditor(editorState) {
        if (this.domEditor) {
            this.domEditor.focusEditor();
        }
    }

    getEditorClassName(hasFocus) {
        const { autoShowToolbar } = this.props;
        const focus = hasFocus && autoShowToolbar;
        const blur = !hasFocus && autoShowToolbar;
        return {
            wrapperClassName: {
                'gl-richeditor-wrapper-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            editorClassName: {
                'gl-richeditor-editor-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            toolbarClassName: {
                'gl-richeditor-toolbar-class': true,
                'auto-show-toolbar': autoShowToolbar,
                'auto-show-toolbar-focus': focus,
                'auto-show-toolbar-blur': blur
            },
            toolbarStyle: hasFocus ? {display: 'flex'} : {display: 'none'}
        }
    }

    getToolbarOptions(options: GLRichTextEditorToolbarOptions): string[] {
        switch (options) {
            case GLRichTextEditorToolbarOptions.History:
                return ['history'];
            case GLRichTextEditorToolbarOptions.All:
            default:
                return ['inline', 'fontSize', 'textAlign', 'colorPicker', 'link', 'history'];
        }
    }

    onFocusHandle(event) {
        this.setState(this.getEditorClassName(true));
    }

    onBlurHandle(event) {
        this.setState(this.getEditorClassName(false));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.editorHtml != prevState.textState || nextProps.forceRender) && prevState.componentLoaded) {
                const contentBlock = nextProps.editorHtml
                    ? htmlToDraft(nextProps.editorHtml)
                    : null;
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks,
                        contentBlock.entityMap
                    );
                    const editorState = EditorState.createWithContent(
                        contentState
                    );
                    return { editorState: editorState, textState: nextProps.editorHtml };
                } else {
                    return { editorState: EditorState.createEmpty(), textState: nextProps.editorHtml };
                }
        }

        return {};
    }    

    setEditorState(templateText) {
        const contentBlock = templateText ? htmlToDraft(templateText) : null;
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState: editorState });
        }
        else {
            this.setState({ editorState: EditorState.createEmpty() });
        }
    }

    onEditorStateChange(editorState) {
        this.setState({ editorState: editorState });
        const { onValueChanged, fieldName, maxLength, form } = this.props;
        if (onValueChanged) {
            let value = draftToHtml(convertToRaw(editorState.getCurrentContent()), null, null, InvitationTemplateTagHelper.TransformInvitationTemplateTag);
            onValueChanged(value, fieldName, maxLength, form);
        }
    };

    render() {
        const { editorState, wrapperClassName, editorClassName, toolbarClassName, toolbarStyle } = this.state;
        const { readOnly, autoShowToolbar, toolbarCustomButtons, customDecorators, placeholder, toolbarOptions, onFocusHandle, onBlurHandle } = this.props;
        // the [Editor] component can not change toolbar options after it has been created
        // so if we want to change toolbar options, we need load different [Editor] component in cosumer page.
        return <Editor readOnly={readOnly}
            editorState={editorState}
            wrapperClassName={classNames(wrapperClassName)}
            editorClassName={classNames(editorClassName)}
            toolbarClassName={classNames(toolbarClassName)}
            toolbar={{
                options: readOnly ? [] : this.getToolbarOptions(toolbarOptions),
                inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] }
            }}
            toolbarStyle={
                readOnly || autoShowToolbar ? toolbarStyle : {}
            }
            placeholder={placeholder}
            onEditorStateChange={this.onEditorStateChange}
            onFocus={onFocusHandle ? onFocusHandle : (autoShowToolbar ? this.onFocusHandle : null)}
            onBlur={onBlurHandle ? onBlurHandle : (autoShowToolbar ? this.onBlurHandle : null)}
            toolbarCustomButtons={toolbarCustomButtons}
            customDecorators={customDecorators}
            ref={this.setDomEditorRef}
        />
    }
}

export class InvitationTemplateTagHelper {

    static TemplateTagEnity = 'InvitationTemplateTag';
    static TemplateTagDataField = 'data-template-tag';
    static TemplateTagPrefixes = ' ';
    static TemplateTagSuffixes = ' ';
    static TemplateTagPattern = /{\w+}/mgi;
    static TemplateTagGroupPattern = `<span ${InvitationTemplateTagHelper.TemplateTagDataField}='\{\\w+\}'>(.*?)</span>`;
    static TemplateTagGroupRegex = new RegExp(InvitationTemplateTagHelper.TemplateTagGroupPattern, 'mgi');
    static HtmlTagPattern = /<\/?[\w\s="/.':;#-\/]+>/mgi;

    static TransformInvitationTemplateTag(entity, text) {
        if (entity.type === InvitationTemplateTagHelper.TemplateTagEnity) {
            return `<span ${InvitationTemplateTagHelper.TemplateTagDataField}='${entity.data[InvitationTemplateTagHelper.TemplateTagDataField]}'>`
                + `${InvitationTemplateTagHelper.TemplateTagPrefixes + text.trim() + InvitationTemplateTagHelper.TemplateTagSuffixes}</span>`
        }
    }

    static FindTemplateTagEntities(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === InvitationTemplateTagHelper.TemplateTagEnity
            );
        },
            callback
        );
    }

    static InsertCharacters(editorState, characters, entityKey?) {
        let newEditorState = editorState;
        let selection = editorState.getSelection();
        let contentState = Modifier.replaceText(
            newEditorState.getCurrentContent(),
            selection,
            characters,
            newEditorState.getCurrentInlineStyle(),
            entityKey
        );
        return EditorState.push(newEditorState, contentState, 'insert-characters');
    }

    static TemplateTagChangeHandle(event, templateTagProps) {
        const { editorState, isSelectedValid, convertSelectedValue, onChange, onSelectChange } = templateTagProps;
        if (isSelectedValid && !isSelectedValid(event)) { return; }
        // insert a blank space before template tag entity
        let newEditorState = InvitationTemplateTagHelper.InsertCharacters(editorState, InvitationTemplateTagHelper.TemplateTagPrefixes);
        // insert template tag entity
        const templateTagKey = typeof event === 'string' ? event : (event.key ? event.key : event.toString())
        const insertingText = (convertSelectedValue ? convertSelectedValue(templateTagKey) : templateTagKey)
        const templateTagData = {};
        templateTagData[InvitationTemplateTagHelper.TemplateTagDataField] = insertingText;
        const templateTagEntityKey = newEditorState
            .getCurrentContent()
            .createEntity(InvitationTemplateTagHelper.TemplateTagEnity, 'IMMUTABLE', templateTagData)
            .getLastCreatedEntityKey();
        newEditorState = InvitationTemplateTagHelper.InsertCharacters(newEditorState, insertingText, templateTagEntityKey);
        // insert a blank space after template tag entity
        newEditorState = InvitationTemplateTagHelper.InsertCharacters(newEditorState, InvitationTemplateTagHelper.TemplateTagSuffixes);
        if (onChange) {
            onChange(newEditorState);
        }
        if (onSelectChange) {
            onSelectChange(newEditorState);
        }
    };

    static GetInvitationTemplateTagTextMap(): Map<string, string> {
        const OneCharacter = "W";
        return new Map([
            [`{${TemplateTagCode.TargetName}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.TargetEmail}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.InvitationCode}}`, OneCharacter.repeat(8)],
            [`{${TemplateTagCode.InvitationQRCode}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.InvitationLink}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.AcceptInvitationLink}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.Region}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.School}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.Campus}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.Class}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.ExpirationDate}}`, OneCharacter.repeat(23)],
            [`{${TemplateTagCode.PageBreak}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.StudentEnglishName}}`, OneCharacter.repeat(50)],
            [`{${TemplateTagCode.StudentNativeName}}`, OneCharacter.repeat(50)],
        ]);
    }

}

interface InvitationTemplateTagProps {
    editorState?: any
    isSelectedValid?: (e: string) => boolean
    convertSelectedValue?: (e: string) => string
    onChange?: (e: string) => void
    onSelectChange?: (editorState: any) => void
}

interface InvitationTemplateTagSelectProps extends InvitationTemplateTagProps {
    options: JSX.Element[]
    defaultValue?: string
}

interface InvitationTemplateTagSelectStates {

}

export class InvitationTemplateTagSelect extends Component<InvitationTemplateTagSelectProps, InvitationTemplateTagSelectStates> {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        InvitationTemplateTagHelper.TemplateTagChangeHandle(e, this.props);
    };

    render() {
        return (
            <Select className='invitation-Template-Tag-Select' defaultValue={this.props.defaultValue} onChange={this.handleChange}>{...this.props.options}</Select>
        );
    }
}

export const InvitationTemplateTagComponent = function (props) {
    if (bowser.msie) {
        return (
            <span className='invitation-Template-Tag-Component'>
                {props.children}
            </span>
        )
    }
    else {
        return (
            <span contentEditable={false} className='invitation-Template-Tag-Component'>
                {props.children}
            </span>
        )
    }
}

interface InvitationTemplateTagDropDownProps extends InvitationTemplateTagProps {
    menus?: JSX.Element[]
}

interface InvitationTemplateTagDropDownStates {
    menu?: JSX.Element
}

export class InvitationTemplateTagDropDown extends Component<InvitationTemplateTagDropDownProps, InvitationTemplateTagDropDownStates> {
    constructor(props) {
        super(props);
        this.state = { menu: null };
        this.getTagMenu = this.getTagMenu.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.state.menu == null || this.state.menu != nextProps.menus) {
            this.setState({ menu: this.getTagMenu(nextProps.menus) });
        }
    };

    getTagMenu(menuItems) {
        return <Menu onClick={this.handleMenuClick}>{...menuItems}</Menu>
    }

    handleMenuClick(menu) {
        InvitationTemplateTagHelper.TemplateTagChangeHandle(menu, this.props);
    }

    render() {

        return (
            this.state.menu &&
            <Dropdown overlay={this.state.menu} trigger={['click']} {...alignPop()}>
                <Button className='invitation-Template-Tag-Dropdown'>
                    <FormattedMessage id={SchoolLocale.InvitationTemplateFieldContentTag} /> <Icon type="down" />
                </Button>
            </Dropdown>
        );
    }
}    