
import * as React from 'react'
import { Row, Modal } from "antd-min";
import { lazyInject } from '@app/util/di';
import { TYPES } from '@app/util/types';
import { GLRichTextEditor } from './gl-richtexteditor/gl-richtexteditor';
import { IInvitationTemplateService } from '@app/service/invitation-template';
import { fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { formatRegionalUrl } from 'gl-commonui';
export interface PreviewParams {
    title?: string
    width?: string
    height?: string
    type?: number
    refid?: string
    tepid?: string
    name?: string
    email?: string
    phone?: string;
}

interface PreviewProps {
    modalparams?: PreviewParams
    visible?: boolean
    onCancel?: (value: any) => void
}

interface PreviewStates {
    subject?: string
    htmlstr?: string
    visible?: boolean
}

export class PreviewModal extends React.Component<PreviewProps, PreviewStates> {
    @lazyInject(TYPES.IInvitationTemplateService)
    service: IInvitationTemplateService
    modalprops
    constructor(props) {
        super(props);
        this.state = { visible: this.props.visible };
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        if (this.props.visible != nextProps.visible) {
            this.setState({ visible: nextProps.visible });
            nextProps.visible && this.renderInvitationTemplate();
        }
    };

    componentDidMount() {
        this.renderInvitationTemplate();
    }

    renderInvitationTemplate() {
        let params = {
            invitationType: this.props.modalparams.type,
            referenceId: this.props.modalparams.refid,
            templateId: this.props.modalparams.tepid,
            name: this.props.modalparams.name,
            email: this.props.modalparams.email
        }
        this.service.previewInvitationTemplate(params).then(data => {
            const title = data && data.title;
            const text = data && data.text;
            this.setState({
                subject: title ? formatRegionalUrl(title): title,
                htmlstr: text ? formatRegionalUrl(text) : text
            });
        });
    }

    onCancel() {
        this.setState({
            visible: false,
            subject: "",
            htmlstr: ""
        });
        this.props.onCancel(false);
    }

    render() {
        this.modalprops = {
            visible: this.state.visible,
            title: this.props.modalparams.title || this.props.modalparams.phone
                                ? fmtMsg({ id: SchoolLocale.InvitationHistoryPreModalPhoneTitle })
                                : fmtMsg({ id: SchoolLocale.InvitationHistoryPreModalEmailTitle }),
            onCancel: this.onCancel.bind(this),
            wrapClassName: "vertical-center-modal",
            width: this.props.modalparams.width || "750px",
            height: this.props.modalparams.height || "",
            footer: null
        }
        return <Modal className="preview" {...this.modalprops}>
            <Row><span className="title">Subject:</span>{this.state.subject}</Row>
            <Row><span className="title">To:</span>{this.props.modalparams.email ? this.props.modalparams.email : this.props.modalparams.phone}</Row>
            <GLRichTextEditor readOnly={true} editorHtml = { this.state.htmlstr } />
        </Modal>;
    }
}
