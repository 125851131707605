import React, { Component } from "react";
import { surveyQuestionModel } from "@app/service/coach/visitation";
import { Row, Form, Input, Checkbox, Radio, List, Icon } from "antd-min";
import { SurveyQuestionType } from "@app/util/coach/enum";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { GLRichTextEditor } from "@app/components";
import { SchoolLocale } from "@app/locales/localeid";
import { richTextEditorValueChanged, fmtMsg } from "@app/util";
import { formatResponseAnswer } from "@app/util/coach/fun";
import { SearchResource } from "@app/components/coach/chat/components/search-resource";
import { formatRegionalUrl, mergeClasses } from "gl-commonui";

interface SurveyQuestionsProps {
    teacherInfo?: any
    form: WrappedFormUtils
    isDisabled?: boolean
    forceRender?: boolean
    isTeacherReview?: boolean
    surveyInstanceId?: string
    surveyQuestions: surveyQuestionModel[]
    renderNodeUnderQue?: {qId: string; node: React.ReactNode}[];
}
interface SurveyQuestionsStates {
    visible?: boolean
    resources?: any[]
    formatSurveyQuestions?: any[]
    queOneNodeVisible?: boolean;
}

export class SurveyQuestions extends Component<SurveyQuestionsProps, SurveyQuestionsStates> {
    constructor(props) {
        super(props);
        this.state = {
            resources: [],
            visible: false,
            formatSurveyQuestions: [],
            queOneNodeVisible: false
        }
    }
    componentWillMount() {
        const { surveyQuestions } = this.props;
        this.formatSurveyQuestions(surveyQuestions);
    }
    componentWillReceiveProps(nextProps) {
        const { forceRender } = this.props;
        const { surveyQuestions, forceRender: nextForceRender } = nextProps;
        forceRender && this.setState({ visible: false });
        if (forceRender != nextForceRender && !nextForceRender ||
            this.props.surveyQuestions != surveyQuestions) {
            this.formatSurveyQuestions(surveyQuestions);
        }
    }
    formatSurveyQuestions(surveyQuestions) {
        const { isTeacherReview } = this.props;
        if (isTeacherReview) {
            const question = surveyQuestions.find((s, key) => surveyQuestions.length == key+1);
            const { surveyResponseAnswer } = question;
            if (surveyResponseAnswer.length) {
                const answers = surveyResponseAnswer[0].answer ?
                                surveyResponseAnswer[0].answer.split("\t") : [];
                this.setState({ resources: answers });
            } else {
                this.setState({ resources: [] });
            }
        }
    }
    formatMultiChoiceAnswer(surveyResponseAnswer) {
        return surveyResponseAnswer.map((answer, key) => {
            return "opt-" + answer.surveyQuestionOptionId;
        });
    }
    renderTextQuestion(que, key) {
        const { form, isDisabled, forceRender, renderNodeUnderQue } = this.props;
        const { queOneNodeVisible } = this.state;
        const showNode = renderNodeUnderQue ? renderNodeUnderQue.find(s => s.qId === que.id) : null;

        return <Row className="question text" key={key}>
            <Row className="title">{key+1}. {que.question}
            {showNode && !isDisabled && (
                <>
                    &nbsp;&nbsp;
                    <a
                        onClick={() => {
                            this.setState({
                                queOneNodeVisible: !queOneNodeVisible
                            });
                        }}
                    >
                        (
                        {fmtMsg({
                            id:
                                SchoolLocale.VisitationTeacherClassTeachingClass
                        })}
                        )
                    </a>
                </>
            )}
            </Row>
            {
                <div
                    className={mergeClasses(
                        "que-extra",
                        showNode && queOneNodeVisible && "que-extra--show"
                    )}
                >
                    {showNode && queOneNodeVisible && showNode.node}
                </div>
            }
            <Form.Item>
                {form.getFieldDecorator(`question-${que.id}`, {
                    initialValue: formatRegionalUrl(formatResponseAnswer(que.surveyResponseAnswer)),
                })(
                    <GLRichTextEditor
                        readOnly = {isDisabled}
                        editorHtml = {formatRegionalUrl(formatResponseAnswer(que.surveyResponseAnswer))}
                        fieldName = {`question-${que.id}`}
                        forceRender = {forceRender}
                        form = {form}
                        autoShowToolbar = {!isDisabled}
                        placeholder = {!isDisabled ? fmtMsg({id: SchoolLocale.VisitationSurveyAnswerPlaceHolder}) : null}
                        onValueChanged = {richTextEditorValueChanged}
                    />
                )}
            </Form.Item>
        </Row>;
    }
    renderMultiChoice(que, key) {
        const { form, isDisabled } = this.props;
        const { id, question, surveyQuestionOption, surveyResponseAnswer } = que;
        const defaultAnswerIds = surveyResponseAnswer && this.formatMultiChoiceAnswer(surveyResponseAnswer);
        return <Row className="question multiChoice" key={key}>
            <Row className="title">{key+1}. {question}</Row>
            <Form.Item>
                {form.getFieldDecorator(`checkbox-group-${id}`, {
                    initialValue: defaultAnswerIds,
                    validateTrigger: null,
                    rules: [{ required: true, message: fmtMsg({ id: SchoolLocale.VisitationQuestionIsRequired }) }]
                })(
                    <Checkbox.Group>
                        {
                            surveyQuestionOption.map((opt, k) => {
                                return <Row key={`opt-${k}`} className="option-item">
                                    <Checkbox value={`opt-${opt.id}`} disabled={isDisabled}>{opt.option}</Checkbox>
                                </Row>
                            })
                        }
                    </Checkbox.Group>
                )}
            </Form.Item>
        </Row>;
    }
    renderRadioQuestion(que, key) {
        const { form, isDisabled } = this.props;
        const { id, question, surveyQuestionOption, surveyResponseAnswer } = que;
        const defaultAnswerIds = surveyResponseAnswer && this.formatMultiChoiceAnswer(surveyResponseAnswer);
        return <Row className="question radio" key={key}>
            <Row className="title">{key+1}. {question}</Row>
            <Form.Item>
                {form.getFieldDecorator(`radio-group-${id}`, {
                    initialValue: defaultAnswerIds,
                    validateTrigger: null,
                    rules: [{ required: true, message: fmtMsg({ id: SchoolLocale.VisitationQuestionIsRequired }) }]
                })(
                    <Radio.Group>
                        {
                            surveyQuestionOption.map((opt, k) => {
                                return <Row key={`opt-${k}`} className="option-item">
                                    <Radio value={`opt-${opt.id}`} disabled={isDisabled}>{opt.option}</Radio>
                                </Row>
                            })
                        }
                    </Radio.Group>
                )}
            </Form.Item>
        </Row>;
    }
    toggleSearchVisible(e) {
        const { visible } = this.state;
        this.setState({ visible: !visible });
    }
    innerHTML(html, key) {
        const { isDisabled } = this.props;
        return <>
            <span dangerouslySetInnerHTML={{ __html: formatRegionalUrl(html) }} />
            {!isDisabled && html && <Icon type="close-circle" onClick={() => this.deleteResource(key)} />}
        </>
    }
    deleteResource(key) {
        const { resources } = this.state;
        resources.splice(key, 1);
        this.setState({ resources });
    }
    renderContentCourseResources(que, key) {
        const { form, isDisabled } = this.props;
        const { id, question } = que;
        const { resources } = this.state;
        return <Row className="question-resources text" key={key}>
            {(!isDisabled || (isDisabled && !!resources.length)) &&
            <Row className="resources-title">
                <div>{question}</div>
                {!isDisabled && <Icon type="paper-clip" onClick={(e) => this.toggleSearchVisible(e)} />}
            </Row>
            }
            {!!resources.length &&
            <div className={`resources-container ${isDisabled&&"disabled"}`}>
                <Form.Item>
                    {form.getFieldDecorator(`question-${id}`, {
                        initialValue: resources,
                    })(
                        <List
                            dataSource={resources}
                            renderItem={(item, key) => (
                                <List.Item key={key}>
                                    <List.Item.Meta
                                        title={this.innerHTML(item, key)}
                                    />
                                </List.Item>
                            )}
                        >
                        </List>
                    )}
                </Form.Item>
            </div>
            }
        </Row>;
    }
    handleOnResourceSelect = (resource: any) => {
        const { resources } = this.state;
        const { resourceName, resourceUrl } = resource;
        const aTag = `<a href=${resourceUrl}>${resourceName}</a>`;
        const resourceToAnswer = [aTag];
        this.setState({ resources: resources.concat(resourceToAnswer)});
    }
    render() {
        const { visible } = this.state;
        const { surveyQuestions, isTeacherReview, isDisabled, teacherInfo } = this.props;
        return <>
            {
                surveyQuestions.map((que, key) => {
                    switch(que.surveyQuestionTypeId) {
                        case SurveyQuestionType.Text:
                            return isTeacherReview &&
                                   surveyQuestions.length == key+1 ?
                                   this.renderContentCourseResources(que, key) :
                                   this.renderTextQuestion(que, key);
                        case SurveyQuestionType.Option:
                            return this.renderRadioQuestion(que, key);
                        case SurveyQuestionType.MultChoice:
                            return this.renderMultiChoice(que, key);
                        default:
                            return;
                    }
                })
            }
            <SearchResource
                resourceModel={teacherInfo}
                resourcePanelVisible={!isDisabled && visible}
                onResourceSelect={this.handleOnResourceSelect}
            />
        </>;
    }
}
