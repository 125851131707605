import { EventManagerSettingsModel, RegionService } from "@app/service/admin/regions";

interface Services {
    regionService: RegionService;
}

export interface EventManagerState {
    eventSettings: EventManagerSettingsModel[];
    isLoading: boolean;
    isError: any;
    hasEventSettingsChanged: boolean;
}

const initialState = {
    eventSettings: [],
    isLoading: false,
    isError: null,
    hasEventSettingsChanged: false
};

export default {
    namespace: "eventManager",
    state: initialState,
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
        setEventSettings(state, { eventSettings }) {
            return {
                ...state,
                eventSettings: eventSettings,
                hasEventSettingsChanged: true
            };
        },
    },
    effects: {
        *fetchRegionEventSettings(
            { payload },
            { call, put, all },
            { regionService }: Services
        ) {
            try {
                yield put(reload({ isLoading: true }));
                const eventSettings = yield call(
                    regionService.getEventManagerSettings,
                    payload.regionId
                );
                yield put(
                    reload({
                        isLoading: false,
                        eventSettings: eventSettings.filter(x => !x.disabled)
                    })
                );
            } catch (err) {
                yield put(reload({ isLoading: false, isError: true }));
            }
        }
    },
    services: {
        regionService: RegionService
    }
};

export function reload(state) {
    return { type: "eventManager/reload", payload: state };
}

export function fetchRegionEventSettings(payload) {
    return { type: "eventManager/fetchRegionEventSettings", payload };
}

export function setEventSettings(eventSettings: EventManagerSettingsModel[]) {
    return { type: "eventManager/setEventSettings", eventSettings };
}

