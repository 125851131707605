import { ResourceService } from '@app/service/school/resource/service';
import { InvitationTemplateService } from '@app/service/invitation-template';
import { UserService } from '@app/service/users/service';
import { InvitationService } from '@app/service/school/invitation/service';
import { InvitationType, RoleName, GLGlobal, NotificationType, MessageHelper, ServerExceptionCode, GLUtil, Role, ResourceType } from 'gl-commonui';
import { StateType } from '../index';
import { isGuid, guid, canNotDelete, sortByDisabled } from '@app/util/func';
import { ServerExceptionCode as ExceptionCode } from '@app/util/coach/enum';
import { PathConfig } from '@app/config/pathconfig';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { BlobHelper, SortHelper, ContextHelper } from '@app/util/helper';
import { SchoolClassService } from '@app/service/class/service';
import { SchoolService } from '@app/service/schools/service';
import { SchoolTeacherModel } from '@app/service/schools';
import { isUndefined } from 'util';
import { InvitationChannel, InvitationHelper } from '@app/util';
import { EntityTagsModel, ITagManagerService, TagListModel, TagManagerService } from '@app/service/tag-manager';
import { UserModel } from '@app/service/users';

interface Services {
    invitation: InvitationService
    template: InvitationTemplateService
    resource: ResourceService
    user: UserService
    schoolClass: SchoolClassService
    school: SchoolService;
    tagManagerService: ITagManagerService
}

export interface InvitationState {
    invitations: any[]
    contactAdmins: any[]
    templates: any[]
    defaultTemplate?: string
    schoolClass: any
    loading: boolean
    isModalShow: boolean
    isPreviewShow: boolean
    values: any
    confirmed: any
    refType: any
    cnts?: { all, pending, accepted, expired }
    isProcessingEmail: boolean
    invitationChannel: number
}


export default {
    namespace: 'invitation',
    state: {
        invitations: [],
        contactAdmins: [],
        templates: [],
        defaultTemplate: null,
        schoolClass: null,
        loading: false,
        isModalShow: false,
        isPreviewShow: false,
        values: {},
        confirmed: {},
        cnts: { all: 0, pending: 0, accepted: 0, expired: 0 },
        isProcessingEmail: false
    },
    reducers: {
        setInvitations(state, action) {
            return { ...state, invitations: action.payload };
        },
        isLoading(state, action) {
            return { ...state, loading: action.payload };
        },
        setTemplate(state, action) {
            return { ...state, templates: action.payload.templates, defaultTemplate: action.payload.defaultTemplate };
        },
        setSchoolClass(state, action) {
            return { ...state, schoolClass: action.payload };
        },
        modalShow(state, action) {
            return { ...state, isModalShow: action.payload };
        },
        previewShow(state, action) {
            return { ...state, ...action.payload }
        },
        clearPreview(state, action) {
            return { ...state, ...action.payload }
        },
        confirmed(state, action) {
            return { ...state, confirmed: action.payload };
        },
        clearModal(state, action) {
            return { ...state, isModalShow: false, confirmed: {} };
        },
        reload(state, { payload }) {
            return { ...state, ...payload };
        }
    },
    effects: {
        *getInvitations({ payload: { id, type } }, { call, put, select, all }, { invitation, resource, user, schoolClass, school, tagManagerService }: Services, { pathParse }) {
            yield put(loading());
            yield put(setInvitations([]));
            const current = yield select((state: StateType) => state.oidc.loginInfo.profile.sub);
            let teachers;
            let ids;
            let res;
            if (InvitationType.classTeacher === type) {
                const schoolId = (yield select((state: StateType) => state.school.current.id)) || pathParse({ path: PathConfig.Schools }).schoolId;
                let teachClass;
                [teachers, teachClass] = yield all([
                    call(schoolClass.getTeachersBy, null, { id, schoolId }),
                    call(schoolClass.get, null, { schoolId, id }),
                ]);
                ids = teachers.map(teacher => teacher.id);
                yield put(setSchoolClass(teachClass));
            } else if (InvitationType.schoolTeacher === type) {
                const schoolId = (yield select((state: StateType) => state.school.current.id)) || pathParse({ path: PathConfig.SchoolTeachers }).schoolId;
                const { teachers, pendingTeachers } = yield call(school.getSchoolTeachers, { id: schoolId, hasDisabled: false });
                ids = teachers.map(teacher => teacher.id);
                // GL-12360: preventing long url
                const users: UserModel[] = ids.length > 0 ? (yield call(user.getUsersByPost, { ids, includeGSAccess: true })).data : [];
                const userIds = users.map(user => user.id);
                const regionId = pathParse({ path: PathConfig.SchoolTeachers }).regionId;
                const tags: EntityTagsModel[] = yield tagManagerService.getEntityTags(userIds, regionId);
                yield put(setInvitations(formatSchoolTeachers(users, teachers, pendingTeachers, tags)));
                yield put(loaded());
                return;
            } else if (InvitationType.accountManager === type) {
                ids = yield call(school.getSchoolAccountManagers, { id: id });
            } else if (InvitationType.schoolAdmin === type) {
                res = yield call(school.getSchoolAdmins, { id: id });
                ids = res.admins ? res.admins.map(admin => admin.id) : [];
            } else if (InvitationType.campusAdmin === type) {
                const schoolId = pathParse(PathConfig.CampusAdmins).schoolId;
                res = yield call(school.getCampusAdmins, { schoolId: schoolId, id: id });
                ids = res.admins ? res.admins.map(admin => admin.id) : [];
            } else if (InvitationType.trainer == type) {
                ids = yield call(resource.getIdsBy, { resourceType: type }, { resourceId: id });
            } else if (InvitationType.regionTrainer == type || InvitationType.trainingManager == type) {
                res = yield call(resource.getRegionCoach, { regionId: id });
                ids = res.map((a) => a.userId);
            }
            let users = ids.length > 0 ? (yield call(user.getUsersByPost, { ids })).data : [];
            const pendings = yield call(invitation.getInvitations, { referenceId: id, invitationCodeTypes: InvitationType.classTeacher === type ? [InvitationType.classTeacher, InvitationType.subTeacher] : [type] });

            if (InvitationType.regionTrainer == type || InvitationType.trainingManager == type) {
                users = formatCoach(res, users);
            }
            else {
                users = formatAdmins(res && res.admins, users);
            }
            const admins = yield canNotDelete(userByInviteType(type)(users, teachers), current, type);
            users = admins.concat(pendingByInviteType(type)(sortByDisabled(pendings))).map(user => (user.index = guid(), user));
            yield put(setInvitations(users));
            yield put(loaded());
        },
        *setContact({ payload: { id, type, contactType, contactId } }, { call, put, select }, { school, invitation, user }: Services, { pathParse }) {
            const current = yield select((state: StateType) => state.oidc.loginInfo.profile.sub);
            let res;
            if (InvitationType.schoolAdmin === type) {
                res = yield call(school.setSchoolContact, id, [{ contactType, contactId }]);
            } else if (InvitationType.campusAdmin === type) {
                const schoolId = pathParse(PathConfig.CampusAdmins).schoolId;
                res = yield call(school.setCampusContact, schoolId, id, [{ contactType, contactId }]);
            }

            let ids = res.admins ? res.admins.map(admin => admin.id) : [];
            let users = ids.length > 0 ? (yield call(user.getUsersByPost, { ids })).data : [];
            const pendings = yield call(invitation.getInvitations, { referenceId: id, invitationCodeTypes: InvitationType.classTeacher === type ? [InvitationType.classTeacher, InvitationType.subTeacher] : [type] });
            users = formatAdmins(res && res.admins, users);

            const admins = yield canNotDelete(users, current, type);
            users = admins.concat(pendingByInviteType(type)(sortByDisabled(pendings))).map(user => (user.index = guid(), user));
            yield put(setInvitations(users));
        },
        *remove({ payload: { id, user: { id: userId, primary, name, iscompleteDelete, regionId, forceDelete }, type } }, { call, put, select }, { user, resource, schoolClass, school }: Services, { pathParse }) {
            try {
                if (InvitationType.classTeacher === type) {
                    const { schoolId, classId } = pathParse({ path: PathConfig.Teachers });
                    const currentRegionId = pathParse(PathConfig.Teachers).regionId;
                    yield call(schoolClass.deleteTeacherBy, primary, iscompleteDelete, { schoolId, id: classId, teacherId: userId }, currentRegionId);
                } else if (InvitationType.schoolTeacher === type) {
                    const { schoolId, regionId } = pathParse({ path: PathConfig.SchoolTeachers });
                    yield call(school.removeSchoolTeacher, { id: schoolId, teacherId: userId, isForceDelete: forceDelete, regionId });
                } else if (InvitationType.accountManager === type) {
                    const schoolId = pathParse({ path: PathConfig.AccountManagers }).schoolId;
                    yield call(school.removeSchoolAccountManagers, { id: schoolId, userId });
                } else if (InvitationType.trainer === type || InvitationType.regionTrainer === type || InvitationType.trainingManager === type) {
                    let schoolRegionId = null;
                    if (InvitationType.trainer == type) {
                        const { regionId } = pathParse({ path: PathConfig.SchoolTrainers });
                        schoolRegionId = regionId;
                    }
                    yield call(resource.removeUserResourcesWithRole, { invitationType: type, isCompleteRemove: iscompleteDelete, regionId: schoolRegionId }, id, userId);
                }
                else {
                    yield call(resource.removeBy, { roleType: type, resourceIds: [id] }, userId);
                }

                if (InvitationType.trainingManager === type) {
                    // not to re-load list and not to hide model bacause, it's being called on check box checked
                    const existingInvitations = yield select((state: StateType) => state.invitation.invitations);

                    // marked un-checked user as training manager
                    existingInvitations.map(item => {
                        if (item.userId == userId) {
                            item.isTrainingManager = false;
                        }
                    });
                    yield put(reload({ invitations: [...existingInvitations] }));
                }
                else {
                    yield put(refreshData({ userId, id, type }));
                }
            } catch (resp) {
                if (resp.body.error_code == ServerExceptionCode.StrongAssociationException) {
                    MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.SchoolTeacherCannotDeleteMessage }, { name: name }));
                }
                else if (resp.body.error_code == ServerExceptionCode.TargetIsNullException && InvitationType.classTeacher === type) {
                    yield put(refreshData({ userId, id, type }));
                } else if (resp.body.error_code == ExceptionCode.CanNotRemoveCoachError) {
                    MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.VisitationCanNotRemoveCoach }));
                }
            }
        },
        *removePending({ payload: { id, user: { id: userId }, type } }, { call, put }, { invitation }: Services) {
            try {
                yield call(invitation.delete, null, { id: userId });
                yield put(getInvitations({ id, type }));
            } catch (resp) {
                if (resp.body.error_code == ServerExceptionCode.InvitationCodeNotExistException) {
                    //pending record does not exists.
                    yield put(getInvitations({ id, type }));
                }
            }
        },
        *initTemplates({ payload: {type, invitationChannel} }, { call, put, select }, { template }: Services) {
            const { id: schoolId, regionId } = yield select((state: StateType) => state.school.current);
            const mergedType = type == InvitationType.schoolTeacher ? InvitationType.classTeacher : type == InvitationType.regionTrainer ? InvitationType.trainer : type;
            const templates = yield call(template.getCandidates, { invitationType: mergedType, schoolId, regionId, invitationChannel });
            const sortedTemplates = templates.sort(sortTemplates());
            const defaultTemplate = getDefaultTemplate(sortedTemplates, schoolId, regionId);
            yield put(setTemplate({ templates: sortedTemplates, defaultTemplate }));
        },
        *invite({ payload: data }, { call, put, select }, { invitation }: Services) {
            yield put(loading());
            data.senderId = yield select((state: StateType) => state.oidc.loginInfo.profile.sub);
            if (data.invitationType === InvitationType.classTeacher && !data.primary) {
                data.invitationType = InvitationType.subTeacher;
            }
            try {
                const result = yield call(invitation.create, data);
                const successMessage = data.invitationChannel == InvitationChannel.Email ?
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendEmail }) :
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendPhone });
                const failedMessage = data.invitationChannel == InvitationChannel.Email ?
                    GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveFail }) :
                    GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveMessageFail });
                if (!result.sendEmailSuccess && !result.sendSMSSuccess) {
                    InvitationHelper.sendEmailFail(failedMessage);
                }
                else {
                    InvitationHelper.sendEmailSuccess(successMessage);
                    yield put(getInvitations({ id: data.referenceId, type: data.invitationType === InvitationType.subTeacher ? InvitationType.classTeacher : data.invitationType }));
                    yield put(hideModal());
                }
            } catch (resp) {
                data.callback && data.callback(false);
            }
            yield put(loaded());
        },
        *reSendInvitation({ payload: user }, { call, put }, { invitation }: Services) {
            const result = yield call(invitation.sendEmail, user.id);
            const message = user.invitationChannel == InvitationChannel.Email ?
                GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendEmail }) :
                GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendPhone });
            const messageFail = GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendFail });
            if (result.success === true) {
                MessageHelper.Message(NotificationType.Success, message);
            } else {
                MessageHelper.Message(NotificationType.Failed, messageFail);
            }
        },

        *emailConfirmBing({ payload: { id, type, email, primary, isMainShippingContact, isMainSupportContact, isMainBillingContact, isMainContact, invitationChannel, phoneCountryCode } }, { call, put, select }, { user }: Services) {
            if (type == InvitationType.trainingManager) {
                yield call(user.bindBy, email, { referenceId: id, invitationCodeType: getInvitationType(type, primary), isMainShippingContact, isMainSupportContact, isMainBillingContact, isMainContact, invitationChannel }, () => GLGlobal.store.dispatch(loaded()));
                const message = invitationChannel == InvitationChannel.Email ?
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendEmail }) :
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendPhone });
                MessageHelper.Message(NotificationType.Success, message);

                // not to re-load list and not to hide model bacause, it's being called on check box checked
                const existingInvitations = yield select((state: StateType) => state.invitation.invitations);

                // marked checked after inviting user as training manager
                existingInvitations.map(item => {
                    if (item.email == email) {
                        item.isTrainingManager = true;
                    }
                });
                yield put(reload({ invitations: [...existingInvitations] }));
                yield put(getInvitations({ id, type }));
            } else {
                yield put(loading());
                const { exist, hasConfirmed } = yield call(user.confirm, email, {invitationChannel});

                if (exist && hasConfirmed) {
                    yield call(user.bindBy, email, { referenceId: id, invitationCodeType: getInvitationType(type, primary), isMainShippingContact, isMainSupportContact, isMainBillingContact, isMainContact, invitationChannel }, () => GLGlobal.store.dispatch(loaded()));
                    yield put(getInvitations({ id, type }));
                }
                yield put(initTemplates({type, invitationChannel}));
                yield put(loaded());
                yield put(confirmed({ email, exist, hasConfirmed, primary, isMainShippingContact, isMainSupportContact, isMainBillingContact, isMainContact, invitationChannel, phoneCountryCode }));
            }
        },
        *validTeacher({ payload: { primary, callback } }, { call, put, select }, { schoolClass }: Services, { pathParse }) {
            const { schoolId, classId } = pathParse({ path: PathConfig.Teachers });
            const teachers = yield call(schoolClass.getTeachersBy, { isPrimary: primary }, { id: classId, schoolId });
            if (teachers.length > 0 && primary) {
                callback(GLGlobal.intl.formatMessage({ id: primary ? SchoolLocale.InvitationHasPrimary : SchoolLocale.InvitationHasSubstitue }));
            } else {
                callback();
            }
        },
        *grantGSAccess({ payload: { userId, gsConnectAccess } }, { call, put, select }, { user }: Services) {
            yield put(loading());
            yield call(user.grantGSAccess, { userId, gsConnectAccess });
            const existingInvitations = yield select((state: StateType) => state.invitation.invitations);

            existingInvitations.map(item => {
                if (item.id == userId) {
                    item.gsConnectAccess = gsConnectAccess;
                }
            });
            yield put(setInvitations(existingInvitations));
            yield put(loaded());
        },
        *setMainTeacher({ payload: { teacherId } }, { call, put, select }, { schoolClass, user, invitation }: Services, { pathParse }) {
            const { schoolId, classId } = GLUtil.pathParse(PathConfig.Teachers);
            const current = yield select((state: StateType) => state.oidc.loginInfo.profile.sub);
            let data = yield call(schoolClass.setMainTeacher, { schoolId, id: classId, teacherId, substitute: false });

            let ids = data.result ? data.result.map(res => res.id) : Array.isArray(data) ? data.map(res => res.id) : [];
            let users = ids.length > 0 ? (yield call(user.getUsersByPost, { ids })).data : [];
            const pendings = yield call(invitation.getInvitations, { referenceId: classId, invitationCodeTypes: [InvitationType.classTeacher, InvitationType.subTeacher] });

            const admins = yield canNotDelete(userByInviteType(InvitationType.classTeacher)(users, data.result ? data.result : Array.isArray(data) ? data : []), current, InvitationType.classTeacher);
            users = admins.concat(pendingByInviteType(InvitationType.classTeacher)(sortByDisabled(pendings))).map(user => (user.index = guid(), user));
            yield put(setInvitations(users));
        },
        *downloadStudentInvitations({ payload: { count: quantity, templateId: invitationTemplateId, classId: referenceId } }, { call, put }, { invitation }: Services) {
            yield put(loading());
            try {
                const data = yield call(invitation.downloadStudentInvitations, { quantity, invitationTemplateId, referenceId });
                isMobile() ? BlobHelper.saveFileOnMobile(data) : BlobHelper.saveFile(data);
                yield put(loaded());
            }
            catch (err) {
                MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentRegistrationInviteDownloadErrorMsg }));
                yield put(loaded());
            }
        },
        *getInvitationCodeDetails({ payload: { query } }, { call, put }, { invitation }: Services, { push, pathStringify }) {
            yield put(reload({ loading: true }));
            const { data, extraData: { all, active, applied, expire } } = yield call(invitation.getInvitationCodeDetails, query, { resourceId: query.resourceId });
            yield put(reload({
                loading: false,
                invitations: data,
                cnts: { all, pending: active, accepted: applied, expired: expire }
            }));
        },
        *refreshData({ payload: { userId, id, type } }, { put }) {
            if (ContextHelper.getUserLoginInfo().profile.sub == userId) {
                window.location.reload();
            }
            else {
                yield put(getInvitations({ id, type }));
            }
        },
        *downloadManualInvitations(payload, { call, put }, { invitation }: Services) {
            yield put(loading());
            try {
                const data = yield call(invitation.downloadManualInvitations, payload.payload);
                isMobile() ? BlobHelper.saveFileOnMobile(data) : BlobHelper.saveFile(data);
                yield put(loaded());
            }
            catch (err) {
                MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentRegistrationInviteDownloadErrorMsg }));
                yield put(loaded());
            }
        },
        *sendInvitations(payload: { emailData, withLoader }, { call, put }, { invitation }: Services) {
            yield put(reload({ isProcessingEmail: true }));
            try {
                const data = yield call(invitation.sendInvitations, payload.payload.emailData, payload.payload.withLoader);
                payload.payload.emailData.invitationChannel === InvitationChannel.Email
                    ? MessageHelper.Message(NotificationType.Success, GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendEmail }))
                    :  MessageHelper.Message(NotificationType.Success, GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSendPhone }))
                yield put(reload({ isProcessingEmail: false }));
            }
            catch (err) {
                yield put(reload({ isProcessingEmail: false }));
                //  MessageHelper.Message(NotificationType.Failed, GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentRegistrationInviteEmailErrorMsg }));
            }
        },
    },
    services: {
        invitation: InvitationService,
        template: InvitationTemplateService,
        resource: ResourceService,
        user: UserService,
        schoolClass: SchoolClassService,
        school: SchoolService,
        tagManagerService: TagManagerService
    }
}
export function isMobile() {
    return navigator.userAgent.match(/AppleWebKit.*Mobile.*/) != null;
}

function formatAdmins(admins, users) {
    admins && admins.map(admin => {
        let user = users.filter(user => user.id == admin.id);
        admin.name = user && user[0].name;
        admin.email = user && user[0].email;
        admin.disabled = user && user[0].disabled;
        admin.phone = user && user[0].phone;
        return admin;
    });
    return admins ? admins : users;
}

function formatCoach(coaches, users) {
    if(!coaches) return users;
    const formattedCoaches = coaches.filter(coach => users.findIndex(user => user.id === coach.userId) > -1).map(coach => {
        const user = users.find(user => user.id == coach.userId);
        return {
            id: coach.userId,
            name: user.name,
            email: user.email,
            disabled: user.disabled,
            phone: user.phone,
            isTrainingManager: coach.isTrainingManager,
            schoolCount: coach.schoolCount
        }
    });
    return formattedCoaches;
}

function getInvitationType(type, primary) {
    if (primary) {
        return InvitationType.classTeacher
    } else if (InvitationType.classTeacher === type && !primary) {
        return InvitationType.subTeacher
    }
    return type;
}
function toNumber(bool) {
    return bool ? 1 : 0;
}
function sortByPrimary(pre, cur) {
    return toNumber(cur.isPrimary) - toNumber(pre.isPrimary);
}
function userByInviteType(type): any {
    if (type === InvitationType.classTeacher) {
        return (users: any[], teachers: any[]) => {
            const status = GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherAccepted });
            return teachers.sort(sortByPrimary).map(teacher => {
                const user = { ...users.filter(user => user.id === teacher.id)[0] };
                user.id = user.id || teacher.id;
                user.name = user.name || teacher.id;
                user.status = status;
                user.primary = teacher.isPrimary;
                user.email = user.email || user.phone;
                return user;
            });
        }
    } else {
        return user => user;
    }
}
function pendingByInviteType(type) {
    if (type === InvitationType.classTeacher) {
        return (pendings: any[]) => {
            const status = GLGlobal.intl.formatMessage({ id: SchoolLocale.TeacherPending });
            pendings.forEach(pending => {
                pending.pending = true;
                pending.status = status;
                pending.primary = pending.invitationType === InvitationType.classTeacher;
            });
            return pendings;
        }
    } else {
        return setPendingProp
    }
}
function canNotDel(users: any[], current) {
    users.filter(user => user.id === current).forEach(user => user.canNotDel = true);
    return users;
}
function setPendingProp(pendings: any[]) {
    pendings.forEach(pending => {
        pending.pending = true;
    });
    return pendings;
}
function formatSchoolTeachers(users, teachers, pendingTeachers, tags: EntityTagsModel[]): SchoolTeacherModel[] {
    return teachers.map((teacher) => {
        let linkedTags: TagListModel[] = [];
        const user = users.find(user => user.id == teacher.id);
        if(user) {
            const hasTags = tags.find(tag => tag.entityId === user.id);
            if(hasTags) {
                linkedTags = hasTags.tags;
            }
        }
        return {
            ...teacher,
            name: user ? user.name : '',
            email: user ? (user.email ? user.email : user.phone) : '',
            class: teacher.teachClass.length,
            tags: linkedTags,
            phone: user ? user.phone : '',
            tagsJoined: linkedTags.map((tag) => tag.name).join(),
            gsConnectAccess: user ? (user.gsConnectAccess || false) : false
        }
    })
        .sort(SortHelper.sortbyString())
        .concat(pendingTeachers.map(pendingTeacher => {
            return { ...pendingTeacher, pending: true, name: `${pendingTeacher.name}` }
        })
            .sort(SortHelper.sortbyString()));
}
function getDefaultTemplate(templates, schoolId, regionId) {
    const result = templates.find(template => template.schoolId == schoolId && template.regionId == regionId);
    return result ? result.id : (templates.length > 0 ? templates[0].id : null);
}
function sortTemplates() {
    return (a, b) => {
        if (a.schoolId && !b.schoolId || (a.regionId && !b.regionId && !b.schoolId)) {
            return -1;
        }
        else if (!a.schoolId && b.schoolId || (!a.schoolId && !a.regionId && b.regionId)) {
            return 1;
        }
        else {
            return a.name.localeCompare(b.name, 'en');
        }
    };
}
export function setInvitations(state) {
    return { type: 'invitation/setInvitations', payload: state }
}
export function getInvitations(state) {
    return { type: 'invitation/getInvitations', payload: state }
}
export function setContactAdmins(state) {
    return { type: 'invitation/setContactAdmins', payload: state }
}
export function setContact(state) {
    return { type: 'invitation/setContact', payload: state }
}
export function remove(state) {
    return { type: 'invitation/remove', payload: state }
}
export function removePending(state) {
    return { type: 'invitation/removePending', payload: state }
}
export function initTemplates(state: {type: InvitationType, invitationChannel?: InvitationChannel}) {
    return { type: 'invitation/initTemplates', payload: state }
}
export function invite(state) {
    return { type: 'invitation/invite', payload: state }
}
export function setTemplate(state) {
    return { type: 'invitation/setTemplate', payload: state }
}
export function reSendInvitation(state) {
    return { type: 'invitation/reSendInvitation', payload: state }
}
export function confirmed(state) {
    return { type: 'invitation/confirmed', payload: state }
}
export function emailConfirmBing(state) {
    return { type: 'invitation/emailConfirmBing', payload: state }
}
function isloading(state) {
    return { type: 'invitation/isLoading', payload: state }
}
function loading() {
    return isloading(true);
}
function loaded() {
    return isloading(false);
}
function modalShow(state) {
    return { type: 'invitation/modalShow', payload: state }
}
export function previewShow(state) {
    return { type: 'invitation/previewShow', payload: state }
}
export function clearPreview(state) {
    return { type: 'invitation/clearPreview', payload: state }
}
export function showModal() {
    return modalShow(true);
}
export function hideModal() {
    return { type: 'invitation/clearModal' };
}
export function downloadStudentInvitations(state) {
    return { type: 'invitation/downloadStudentInvitations', payload: state };
}
export function validTeacher(primary, callback) {
    GLGlobal.store.dispatch({ type: 'invitation/validTeacher', payload: { primary, callback } });
}
export function setMainTeacher(state) {
    return { type: 'invitation/setMainTeacher', payload: state };
}
export function grantGSAccess (state) {
    return { type: 'invitation/grantGSAccess', payload: state };
}
export function reload(state) {
    return { type: 'invitation/reload', payload: state }
}
export function getInvitationCodeDetails(state) {
    return { type: 'invitation/getInvitationCodeDetails', payload: state }
}
export function refreshData(state) {
    return { type: 'invitation/refreshData', payload: state }
}
function setSchoolClass(state) {
    return { type: 'invitation/setSchoolClass', payload: state }
}
export function downloadManualInvitations(state) {
    return { type: 'invitation/downloadManualInvitations', payload: state };
}
export function sendInvitations(state) {
    return { type: 'invitation/sendInvitations', payload: state };
}
