import React, { useEffect, useState } from "react";
import { connect } from "gl-commonui";
import { Row, Col, Card, Table } from "antd-min";
import { TYPES, fmtMsg } from "../../../util/index";
import { ISurveyReportService } from "../../../service/survey/report";
import {
    TodoSurveyResponseModel,
    StatisticsFilterType,
    SchoolAndClassesReportModel
} from "@app/service/survey";
import { StateType } from "@app/states";
import { useService } from "@app/hooks";
import { SurveyLocale } from "@app/locales/localeid";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import "./school-and-classes.less";

interface SchoolAndClassesReportProps {
    surveyResponse: TodoSurveyResponseModel;
}

const SchoolAndClasses = (props: SchoolAndClassesReportProps) => {
    const [loading, setLoading] = useState(false);
    const [surveyResponse, setSurveyResponse] = useState(
        null as TodoSurveyResponseModel
    );
    const reportService = useService<ISurveyReportService>(
        TYPES.ISurveyReportService
    );
    const [reportData, setReportData] = useState(
        null as SchoolAndClassesReportModel
    );

    useEffect(() => {
        if (!surveyResponse) {
            setSurveyResponse(props.surveyResponse);
        } else {
            setLicenseHistoryReportData();
        }
    }, [surveyResponse]);

    useEffect(() => { }, [reportData]);

    const setLicenseHistoryReportData = () => {
        setLoading(true);
        const { statisticsFilterTypeId, statisticsFilterId } = surveyResponse;
        let regionId = null;
        let regionGroupId = null;

        if (statisticsFilterTypeId) {
            if (statisticsFilterTypeId == StatisticsFilterType.Region) {
                regionId = statisticsFilterId;
            } else {
                regionGroupId = statisticsFilterId;
            }
        }
        const selectedDate = moment().subtract(1, "month").toDate().toJSON();
        const params: any = {
            regionId,
            regionGroupId,
            selectedDate
        };
        reportService
            .getSchoolAndClassesReport(params)
            .then(data => {
                setReportData(data);
                setLoading(false);
            })
            .catch(er => {
                setLoading(false);
            });
    };

    const getSign = (num: number) => {
        if (typeof num === "number") {
            if (num > 0) return "+";
            return "";
        }
        return "";
    }

    const renderItem = (count, diff, className) => {
        if (isNaN(diff)) {
            return "";
        }
        return <div className={className}>
            <div>{count}</div>
            <div>{getSign(diff)}{diff}</div>
        </div>;
    }

    const getRenderer = (propName, diffPropName, className?: string) => {
        return (text, record) => {
            return renderItem(record[propName], record[propName] - record[diffPropName], className);
        }
    }

    const columns: ColumnProps<SchoolAndClassesReportModel>[] = [
        {
            title: fmtMsg({ id: SurveyLocale.SurveyCreateTotal }),
            width: "33%",
            children: [
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateSchoolClassesReport }),
                    dataIndex: "schoolCount",
                    align: "right",
                    render: getRenderer("schoolCount", "lastSchoolCount"),
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateCampusCount }),
                    dataIndex: "campusCount",
                    align: "right",
                    render: getRenderer("campusCount", "lastCampusCount"),
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateClassCount }),
                    dataIndex: "classCount",
                    align: "right",
                    render: getRenderer("classCount", "lastClassCount")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateStudentCount }),
                    dataIndex: "studentCount",
                    align: "right",
                    render: getRenderer("studentCount", "lastStudentCount")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateTeacherCount }),
                    dataIndex: "teacherCount",
                    align: "right"
                }
            ]
        },
        {
            title: fmtMsg({ id: SurveyLocale.SurveyCreateGrapeseed }),
            width: "33%",
            className: "school-class-section__table__gs--color",
            children: [
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateSchoolClassesReport }),
                    dataIndex: "gsSchoolCount",
                    className: "school-class-section__table__gs--color",
                    align: "right",
                    render: getRenderer("gsSchoolCount", "lastGsSchoolCount", "school-class-section__table__gs--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateCampusCount }),
                    dataIndex: "gsCampusCount",
                    className: "school-class-section__table__gs--color",
                    align: "right",
                    render: getRenderer("gsCampusCount", "lastGsCampusCount", "school-class-section__table__gs--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateClassCount }),
                    dataIndex: "gsClassCount",
                    className: "school-class-section__table__gs--color",
                    align: "right",
                    render: getRenderer("gsClassCount", "lastGsClassCount", "school-class-section__table__gs--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateStudentCount }),
                    dataIndex: "gsStudentCount",
                    className: "school-class-section__table__gs--color",
                    align: "right",
                    render: getRenderer("gsStudentCount", "lastGsStudentCount", "school-class-section__table__gs--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateTeacherCount }),
                    dataIndex: "gsTeacherCount",
                    align: "right",
                    className: "school-class-section__table__gs--color"
                }
            ]
        },
        {
            title: fmtMsg({ id: SurveyLocale.SurveyCreateLittleseed }),
            className: "school-class-section__table__ls--color",
            children: [
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateSchoolClassesReport }),
                    dataIndex: "lsSchoolCount",
                    className: "school-class-section__table__ls--color",
                    align: "right",
                    render: getRenderer("lsSchoolCount", "lastLsSchoolCount", "school-class-section__table__ls--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateCampusCount }),
                    dataIndex: "lsCampusCount",
                    className: "school-class-section__table__ls--color",
                    align: "right",
                    render: getRenderer("lsCampusCount", "lastLsCampusCount", "school-class-section__table__ls--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateClassCount }),
                    dataIndex: "lsClassCount",
                    align: "right",
                    className: "school-class-section__table__ls--color",
                    render: getRenderer("lsClassCount", "lastLsClassCount", "school-class-section__table__ls--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateStudentCount }),
                    dataIndex: "lsStudentCount",
                    className: "school-class-section__table__ls--color",
                    align: "right",
                    render: getRenderer("lsStudentCount", "lastLsStudentCount", "school-class-section__table__ls--color")
                },
                {
                    title: fmtMsg({ id: SurveyLocale.SurveyCreateTeacherCount }),
                    dataIndex: "lsTeacherCount",
                    align: "right",
                    className: "school-class-section__table__ls--color",
                }
            ]
        }
    ];

    const renderReport = () => {
        return (
            <Row type="flex" gutter={30} className="license-history">
                {
                    <Col className="school-class-section" xs={24} xl={24}>
                        <span>
                            {fmtMsg({
                                id: SurveyLocale.SurveyCreateSchoolClassesReport
                            })}
                        </span>
                        <Card loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={reportData ? [reportData] : []}
                                pagination={false}
                            >
                            </Table>
                        </Card>
                    </Col>
                }
            </Row>
        );
    };
    return renderReport();
};

export const SchoolAndClassesReport = connect(
    ({ }: StateType) => ({}),
    {}
)(SchoolAndClasses);
