import { mergeClasses } from "gl-commonui";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VisitTrackingStep } from ".";
import "./visit-tracking.less";

interface VisitTrackinProps {
    steps: VisitTrackingStep[];
}

export const VisitTracking: FC<VisitTrackinProps> = (props) => {
    const { steps } = props;

    const [completedIndex, setCompletedIndex] = useState<number>(0);

    useEffect(() => {
        const completedIndex = steps.findIndex((step) => step.completed);
        if (completedIndex !== -1) {
            setCompletedIndex(completedIndex);
        }
    }, [steps]);

    const renderStep = (step: VisitTrackingStep, index: number) => {
        const isCompleted = index <= completedIndex;
        return React.createElement(step.link ? Link : "div", {
            className: mergeClasses("vt__item", isCompleted && "vt--completed", step.active && "vt--active", step.onClick && "vt--clickable"),
            key: index,
            to: step.link,
            onClick: step.onClick,
            children: (
                <>
                    <div className="vt__icon">{step.icon}</div>
                    <div className="vt__content">
                        {step.title && <h4 className="vt__title">{step.title}</h4>}
                        {step.description && <span className="vt__description">{step.description}</span>}
                    </div>
                </>
            ),
        });
    };

    const renderSteps = () => {
        return steps.map(renderStep);
    };

    return <div className="vt">{renderSteps()}</div>;
};
