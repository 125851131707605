import * as React from "react";
import { Modal } from "antd-min";
import { connect } from "gl-commonui";
import { StatusPanel, StatusItem, StatusItemProps, StatusType } from ".";
import { Action } from "..";
import { fmtMsg } from "@app/util";
import { SurveyLocale } from "@app/locales/localeid";

interface AttachmentStatusProps {
    uploadStatus?: StatusItemProps[];
    loginInfo?: any;
}
interface AttachmentStatusState {
    showModal: boolean;
}

@connect(({ oidc: { loginInfo }, uploadStatus: { uploadStatus } }) => ({ uploadStatus, loginInfo }))
export class UploadStatus extends React.Component<AttachmentStatusProps, AttachmentStatusState> {
    constructor(props: AttachmentStatusProps) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    findProgessItem = () => {
        return this.props.uploadStatus.find(item => item.status === StatusType.Progress);
    }

    // show in progress modal
    showModal = () => {
        this.setState({ showModal: true });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    onCloseUploadClick() {
        if (this.findProgessItem()) {
            this.showModal();
        } else {
            this.props.uploadStatus.forEach(item => {
                item.onCancel();
            });
        }
    }

    renderModal = () => {
        return <Modal visible={this.state.showModal}
            title={fmtMsg({ id: SurveyLocale.SurveyCancelUploadConfirm })}
            onOk={() => {
                this.props.uploadStatus.forEach(item => {
                    item.onCancel();
                });
                this.hideModal();
            }}
            onCancel={this.hideModal}></Modal>
    }

    countNumberOfItemsInProgress = () => {
        let count = 0;
        this.props.uploadStatus.map(item => item.status === StatusType.Progress ? count++ : null);
        return count;
    }

    render() {
        const { uploadStatus } = this.props;
        const itemsBeingUploaded = this.countNumberOfItemsInProgress();
        return <> {uploadStatus.length ? (
            <StatusPanel
                title={itemsBeingUploaded ? `Uploading ${itemsBeingUploaded} Items...` : "All items uploaded"}
                isCollapsible={true}
                actions={[<Action materialIcon="close" key={1} onClick={this.onCloseUploadClick.bind(this)} />]}
            >
                {uploadStatus.map((status, index) => (
                    <StatusItem key={status.id} {...status} />
                ))}
            </StatusPanel>
        ) : null
        }
            {this.renderModal()}
        </>
    }
}
