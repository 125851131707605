import { ResourceService } from "./../service/resources/service";
import {
    GLGlobal,
    PermissionService,
    InvitationType,
    GLUtil,
    ResourceType,
    LanguageDateFormat,
    FormHelper,
    ComparisonOperator,
    GLLocale,
    RoleName,
    IPhoneCountryCode,
    defaultPhoneCountryCodeList
} from "gl-commonui";
import { PermissionService as LocalPermissionService } from "@app/service/permission";
import { FormattedMessage } from "react-intl";
import { PathConfig, AdminPathConfig } from "@app/config/pathconfig";
import {
    CampusEventType,
    ChangeLogType,
    DataMeasurementEnum,
    StudentRegistrationType,
    UserResourceRelation,
    EventInfo,
    LicenseTypes
} from "@app/util/enum";
import * as moment from "moment";
import { DateHelper } from "./helper";
import uuidv4 from "uuid/v4";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { registerLocale } from "react-datepicker";
import enUs from "date-fns/locale/en-US";
import { monthsBG, daysBG, EmailRegex, ExitSurveyId } from "./consts";
import { InvitationTemplateTagHelper } from "@app/components";
import {
    CurriculumType,
    SchoolClassService
} from "@app/service/class";
import XLSX, {WorkSheet} from "xlsx";

export function isGuid(str: string) {
    return (
        str &&
        str.match(
            /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/
        ) !== null
    );
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function getDate(dateString: string) {
    let today = new Date();
    let datePart = dateString.split("-");
    let year = parseInt(datePart[0]);
    let month = parseInt(datePart[1]) - 1; // new Date() takes month = actual month-1
    let date = parseInt(datePart[2]);
    return new Date(
        year,
        month,
        date,
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
        today.getMilliseconds()
    );
}

export function disableDateFromNextWeek(current) {
    let today = new Date();
    let disableDate: any = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds()
    );
    disableDate.setDate(disableDate.getDate() + 7);
    return current > disableDate;
}

export function toLocalDate(dateString) {
    return new Date(dateString + "Z"); // The correct format for UTC would be like 2013-02-27T17:00:00Z (Z is for Zulu Time)
}

export function toFormatedDate(inputDate: Date) {
    var month = inputDate.getMonth() + 1; // + 1, because getMonth() returns date's last month
    var date = inputDate.getDate();
    var formatedDate =
        inputDate.getFullYear() +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (date < 10 ? "0" : "") +
        date;
    return formatedDate;
}

export function getFilterDateFrom(dateString: string) {
    let today = new Date();
    let datePart = dateString.split("-");
    let year = parseInt(datePart[0]);
    let month = parseInt(datePart[1]) - 1;
    let date = parseInt(datePart[2]);
    return new Date(year, month, date, 0, 0, 0, 0);
}
export function getFilterDateTo(dateString: string) {
    let today = new Date();
    let datePart = dateString.split("-");
    let year = parseInt(datePart[0]);
    let month = parseInt(datePart[1]) - 1;
    let date = parseInt(datePart[2]);
    return new Date(year, month, date, 23, 59, 59, 999);
}

export function contentVersionIsLittleSeed(versionId, contentVersions) {
    const contentVersion = contentVersions.find(
        version => version.id == versionId
    );
    return contentVersion
        ? contentVersion.curriculumType === CurriculumType.LittleSEED
        : false;
}
export function distinct<T>(
    arr: T[],
    keySelector: (_: T) => number | string | T | any,
    retKey: boolean = false
): T[] {
    keySelector = keySelector || (_ => _);
    var set = {};
    return arr.reduce((retArr, arrItem) => {
        var arrKey = keySelector(arrItem);
        var key = arrKey.toString();
        if (!set[key]) {
            var value = retKey ? arrKey : arrItem;
            set[key] = value;
            retArr.push(value);
        }
        return retArr;
    }, []);
}
export function urlValider(msg) {
    return [
        {
            validator: (rule, value, callback) => {
                if (
                    typeof value === "string" &&
                    value.trim() !== "" &&
                    !isUrl(value)
                ) {
                    callback(msg);
                }
                callback();
            }
        }
    ];
}
export function isUrl(str: string) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi.test(
        str
    );
}

export function onSubmit(component, handleSubmit, format?, callback?) {
    return e => {
        e.preventDefault();
        const {
            props: { form, model }
        } = component;
        form.validateFields((err, values) => {
            if (!err) {
                callback && callback(true);
                format && format(values, model);
                handleSubmit({ ...model, ...values, callback });
            }
        });
    };
}

// check user has a specific role or not
export function userIsInRoles(...roles): boolean {
    const userRoles = GLGlobal.loginInfo().profile.roles;
    if (!userRoles) return false;

    return !!roles.find(x => userRoles.indexOf(x) > -1);
}

export function getTableProps<T = any>(props) {
    return {
        rowKey: "id",
        bordered: false,
        loading: true,
        pagination: false,
        ...props
    } as T;
}
export function extendForm(form) {
    return {
        ...form,
        decoratorOptions: { validateFirst: true, validateTrigger: "onBlur" }
    };
}
export function textareaValider({ setFieldsValue, validateFields }, propName) {
    return [
        {
            validator: (rule, value, callback) => {
                if (typeof value === "string" && value.trim() === "") {
                    setFieldsValue({ [propName]: null });
                    validateFields([propName], {}, () => {});
                }
                callback();
            }
        }
    ];
}
export function fixPrecise(num, precision = 0) {
    return Number(Number(num).toFixed(Math.abs(precision)));
}

export function setQuery(type, paging, val?) {
    sessionStorage.setItem(
        `${type}-query`,
        JSON.stringify({
            current: paging.current || 1,
            ...val
        })
    );
}
export function getQuery(type) {
    const query = JSON.parse(sessionStorage.getItem(`${type}-state`)) || {};
    sessionStorage.removeItem(`${type}-state`);
    const current = Number(query.current);
    query.current = Number.isNaN(current) ? 1 : current;
    return query;
}
export function clearQuery(type) {
    const state = `${type}-state`;
    const query = `${type}-query`;
    sessionStorage.setItem(state, sessionStorage.getItem(query));
    sessionStorage.removeItem(query);
}
export function setQueryState(type, paging, val?) {
    setQuery(type, paging, val);
    clearQuery(type);
}
export function guid(options?, buffer?, offset?) {
    return uuidv4(options, buffer, offset);
}
export function fmtMsg(
    messageDescriptor: string | FormattedMessage.MessageDescriptor,
    values?: { [key: string]: string | number | boolean | Date }
) {
    return GLGlobal.intl.formatMessage(
        typeof messageDescriptor === "string"
            ? { id: messageDescriptor }
            : messageDescriptor,
        values
    );
}
export function parseUrlToAnchor(text: string) {
    if (
        /Your notification \(<\i>[\s\S]*?<\/\i>\) has been \b(denied|published)\b/gi.test(
            text
        )
    )
        return text;
    if (
        !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi.test(
            text
        )
    )
        return escapeHtml(text);

    if (/#\[.+?\]\(.+?\)/gi.test(text)) return convertMarkUrlToAnchor(text);

    if (/\{url: ?.+?, ?text: ?.+?\}/gi.test(text))
        return convertUrlToAnchor(text);

    return convertTextToAnchor(text);
}
export function convertTextToAnchor(text: string) {
    if (
        !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi.test(
            text
        )
    )
        return escapeHtml(text);

    var regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi;

    var html = "";
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var href = match[0];

        html +=
            "<span>" + escapeHtml(text.substring(lastindex, index)) + "</span>";
        html += buildAnchor(href, href);
        lastindex = index + href.length;
    }
    text.substring(lastindex).length > 0 &&
        (html += convertTextToAnchor(text.substring(lastindex)));
    return html;
}

/** https://stackoverflow.com/questions/15458876/check-if-a-string-is-html-or-not */
export function isHtml(text): boolean {
    var doc = new DOMParser().parseFromString(text, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function getSheetHeaders(sheet: WorkSheet) {
    const headerRegex = new RegExp('^([A-Za-z]+)1=\'(.*)$');

    const cells = XLSX.utils.sheet_to_formulae(sheet);
    return cells.filter(item => headerRegex.test(item)).map(item => item.split("='")[1]);
}

export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/\n+/g, "<br />");
}
export function convertUrlToAnchor(text: string) {
    var regex = /\{url: ?.+?, ?text: ?.+?\}/gi;
    var formated = [];
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var matchAnchor = /\{url: ?(.+), ?text: ?(.+)\}/gi.exec(match[0]);
        var href = matchAnchor[1];
        formated.push(convertTextToAnchor(text.substring(lastindex, index)));
        formated.push(buildAnchor(href, matchAnchor[2]));
        lastindex = index + match[0].length;
    }
    text.substring(lastindex).length > 0 &&
        formated.push(convertTextToAnchor(text.substring(lastindex)));
    return formated.join("");
}
export function convertMarkUrlToAnchor(text: string) {
    var regex = /#\[.+?\]\(.+?\)/gi;
    var formated = [];
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var matchAnchor = /#\[(.+?)\]\((.+?)\)/gi.exec(match[0]);
        var href = matchAnchor[2];
        formated.push(convertTextToAnchor(text.substring(lastindex, index)));
        formated.push(buildAnchor(href, matchAnchor[1]));
        lastindex = index + match[0].length;
    }
    text.substring(lastindex).length > 0 &&
        formated.push(convertTextToAnchor(text.substring(lastindex)));
    return formated.join("");
}
export async function canNotDelete(users, current, type) {
    let resourceId, resourceType;
    const permission = new PermissionService();
    const currentUser = users.filter(user => user.id == current);
    if (!currentUser.length) {
        return sortByDisabled(users);
    }
    switch (type) {
        case InvitationType.regionAdmin:
            resourceId = GLUtil.pathParse(AdminPathConfig.RegionInvite)
                .regionId;
            resourceType = UserResourceRelation.RegionAdmin;
            break;
        case InvitationType.trainer:
            resourceId = GLUtil.pathParse(PathConfig.SchoolTrainers).schoolId;
            resourceType = UserResourceRelation.SchoolTrainer;
            break;
        case InvitationType.schoolTeacher:
            resourceId = GLUtil.pathParse(PathConfig.SchoolTeachers).schoolId;
            resourceType = UserResourceRelation.SchoolTeacher;
            break;
        case InvitationType.schoolAdmin:
            resourceId = GLUtil.pathParse(PathConfig.Admins).schoolId;
            resourceType = UserResourceRelation.SchoolAdmin;
            break;
        case InvitationType.campusAdmin:
            resourceId = GLUtil.pathParse(PathConfig.CampusAdmins).campusId;
            resourceType = UserResourceRelation.CampusAdmin;
            break;
        case InvitationType.classTeacher:
            resourceId = GLUtil.pathParse(PathConfig.Teachers).classId;
            resourceType = UserResourceRelation.ClassTeacher;
            break;
    }
    const params = {
        adminId: current,
        resourceId: resourceId,
        userResourceRelation: resourceType
    };
    return await permission.canDeletePermission(params).then(data => {
        return sortByDisabled(
            users.map(user => {
                user.canNotDel =
                    user.id == current ? data && !data.canDelete : false;
                return user;
            })
        );
    });
}
export function sortByDisabled(users) {
    return sortByName(users.filter(user => !user.disabled)).concat(
        sortByName(users.filter(user => user.disabled))
    );
}
function sortByName(users) {
    return users.sort((A, B) => {
        return A.name.localeCompare(B.name);
    });
}
export async function getHref(type, id) {
    const service = new ResourceService();
    const params = {
        resourceType: type,
        resourceId: id
    };
    return await service.getResourceId(params).then(data => {
        if (!data) {
            return;
        }
        switch (type) {
            case ResourceType.Region:
                return `regions/${data.regionId}`;
            case ResourceType.School:
            case ResourceType.Campus:
                return `regions/${data.regionId}/schools/${data.schoolId}`;
        }
    });
}
function buildAnchor(href, text) {
    return `<a onclick='
    (function(e){
        if (e && e.stopPropagation){
            e.stopPropagation()
        }else{
            e.cancelBubble = true
        }
    })(event)'
    href='${href}'>
    ${text}
    </a>`;
}

export const mergeClasses = (...classes: string[]) => {
    return classes.filter(c => !!c).join(" ");
};

export function htmlEncodeByRegExp(str) {
    let s = "";
    if (!str || str.length == 0) return "";
    s = str.replace(/&/g, "&amp;");
    s = s.replace(/</g, "&lt;");
    s = s.replace(/>/g, "&gt;");
    s = s.replace(/ /g, "&nbsp;");
    s = s.replace(/\'/g, "&#39;");
    s = s.replace(/\"/g, "&quot;");
    return s;
}

export function formatToPadNumber(number: Number, digit: Number) {
    return number ? number.toString().padStart(digit, 0) : "";
}

export function formatToMaterialRequestId(number: Number) {
    return formatToPadNumber(number, 5);
}
export function regExpEscape(string) {
    return string ? string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") : string;
}

export function rangeDateValider(
    { getFieldsValue, getFieldError, resetFields, setFieldsValue },
    start,
    end,
    msg
) {
    return [
        {
            validator: (rule, value, callback) => {
                const dates = getFieldsValue([start, end]);
                if (dates[start] && dates[end] && dates[start] > dates[end]) {
                    callback(msg);
                } else {
                    const reset = field => {
                        const errors = getFieldError(field);
                        if (errors && errors.length > 0) {
                            resetFields([field]);
                            setFieldsValue({ [field]: dates[field] });
                        }
                    };
                    reset(start);
                    reset(end);
                }
                callback();
            }
        }
    ];
}

export function rangeDateValiderEx(
    { getFieldError, resetFields, setFieldsValue },
    getStartDate,
    getEndDate,
    startField,
    endField,
    msgId
) {
    return [
        {
            validator: (rule, value, callback) => {
                const dates = {
                    start: getStartDate(),
                    end: getEndDate()
                };
                if (
                    dates["start"] &&
                    dates["end"] &&
                    dates["start"] > dates["end"]
                ) {
                    callback(
                        fmtMsg(
                            { id: msgId },
                            {
                                startDate: DateHelper.formatDate2Local(
                                    dates["start"]
                                ),
                                endDate: DateHelper.formatDate2Local(
                                    dates["end"]
                                )
                            }
                        )
                    );
                } else {
                    const reset = field => {
                        const errors = getFieldError(field);
                        if (errors && errors.length > 0) {
                            resetFields([field]);
                            setFieldsValue({ [field]: dates[field] });
                        }
                    };
                    reset(startField);
                    reset(endField);
                }
                callback();
            }
        }
    ];
}

export function stripHtmlTags(input) {
    if (!input) return input;

    return input.replace(/(<([^>]+)>)/gi, "");
}

export function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

export function sliceMoment(
    date: moment.MomentInput | string | Date | number | Object | Number[],
    objectLiteral: moment.MomentSetObject = {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    }
) {
    return date ? moment(date as any).set(objectLiteral) : null;
}

export const isNotBetweenDate = (min: moment.MomentInput = moment(), max: moment.MomentInput = moment()) => current => moment(current, 'YYYY/MM/DD').isBefore(moment(min, 'YYYY/MM/DD')) ||  moment(current, 'YYYY/MM/DD').isAfter(moment(max, 'YYYY/MM/DD'));

export function isPastDate(date, units: moment.unitOfTime.Diff = "days") {
    return (
        date && moment(date).diff(sliceMoment(moment(new Date())), units) < 0
    );
}

export function isFutureDate(date) {
    return (
        date && moment(date).diff(sliceMoment(moment(new Date())), "days") > 0
    );
}

export function getWidth() {
    return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
}

const conversion_getDPI = function() {
    var arrDPI = new Array();
    if (window.screen["deviceXDPI"]) {
        arrDPI[0] = window.screen["deviceXDPI"];
        arrDPI[1] = window.screen["deviceYDPI"];
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText =
            "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = tmpNode["offsetWidth"];
        arrDPI[1] = tmpNode["offsetHeight"];
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI;
};

const pxConversionMm = function(value) {
    var inch = value / conversion_getDPI()[0];
    var c_value = inch * 25.4;
    return c_value;
};

const mmConversionPx = function(value) {
    var inch = value / 25.4;
    var c_value = inch * conversion_getDPI()[0];
    return c_value;
};

export function getA4Width() {
    return 595; //mmConversionPx(210);
}

export function getA4Height() {
    return 842; //mmConversionPx(297);
}

export async function getImageDimensions(file) {
    return new Promise(function(resolved, rejected) {
        if (!file) resolved({ width: null, relativeWidth: null });
        let image = new Image();
        image.onload = function() {
            resolved({
                width: image.width,
                relativeWidth: (image.width * 100) / getA4Width(),
                height: image.height
            });
        };
        image.src = file;
    });
}
export function isValidEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._-a-zA-Z0-9.\+0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

export function generateGuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

export function generateBillingStartMonths() {
    return [
        { value: "1", text: fmtMsg({ id: GSAdminLocale.MonthJan }) },
        { value: "2", text: fmtMsg({ id: GSAdminLocale.MonthFeb }) },
        { value: "3", text: fmtMsg({ id: GSAdminLocale.MonthMar }) },
        { value: "4", text: fmtMsg({ id: GSAdminLocale.MonthApr }) },
        { value: "5", text: fmtMsg({ id: GSAdminLocale.MonthMay }) },
        { value: "6", text: fmtMsg({ id: GSAdminLocale.MonthJun }) },
        { value: "7", text: fmtMsg({ id: GSAdminLocale.MonthJul }) },
        { value: "8", text: fmtMsg({ id: GSAdminLocale.MonthAug }) },
        { value: "9", text: fmtMsg({ id: GSAdminLocale.MonthSep }) },
        { value: "10", text: fmtMsg({ id: GSAdminLocale.MonthOct }) },
        { value: "11", text: fmtMsg({ id: GSAdminLocale.MonthNov }) },
        { value: "12", text: fmtMsg({ id: GSAdminLocale.MonthDec }) }
    ];
}

export function stringCompare(a, b, locales?, options?) {
    if (a && b) {
        return a.localeCompare(b, locales, options);
    } else if (!a && b) {
        return -1;
    } else if (a && !b) {
        return 1;
    } else {
        return 0;
    }
}

export function stringCompare4Date(a, b) {
    if (a && b) {
        return new Date(a).getTime() - new Date(b).getTime();
    } else if (!a && b) {
        return -1;
    } else if (a && !b) {
        return 1;
    } else {
        return 0;
    }
}

export function numberCompare(a, b) {
    if (a && b) {
        return a - b;
    } else if (!a && b) {
        return -1;
    } else if (a && !b) {
        return 1;
    } else {
        return 0;
    }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function setMask(self) {
    return isMask => self.context[1]({ type: isMask ? "mask" : "unmask" });
}

export function replaceEnterWithBr(inputString: string) {
    return inputString.replace(/\n/g, "<br>");
}

const polyfillNodeListForEach = () => {
    if ((window as any).NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function(callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
};

polyfillNodeListForEach();

export function createSliderMarks(min: number, max: number, stepCount: number) {
    if (!max) {
        return {};
    }

    min = min || 0;

    const factor = Math.floor((max - min) / stepCount);

    const marks = [...Array(stepCount).keys()]
        .map(key => min + (key + 1) * factor)
        .filter(mark => mark <= max)
        .reduce((acc, item) => ((acc[item] = item), acc), {});

    return marks;
}

export const registerDatePickerLocale = () => {
    Object.keys(LanguageDateFormat).forEach(lang => {
        registerLocale(lang, {
            ...enUs,
            code: lang,
            localize: {
                month: n => monthsBG()[n],
                day: n => daysBG()[n]
            }
        });
    });
};

export function lengthValider(localeId, rigthVal) {
    return [
        FormHelper.ruleForCompareLength(
            localeId,
            ComparisonOperator.LessOrEqualsThan,
            rigthVal
        )
    ];
}

export function removeLeadingZerosFromDate(dateString: string) {
    return dateString.replace(/\b0(?=\d)/g, "");
}

export function richTextEditorValueChanged(value, fieldName, maxLength, form) {
    const oldValue = form.getFieldValue(fieldName);
    if (!oldValue && !value) {
        return;
    }
    if (value) {
        let fieldValue = {};
        const overflowLengthMsg = fmtMsg(
            { id: GLLocale.FormCprLessThan },
            {
                name: fmtMsg(
                    { id: GLLocale.FormFormatLength },
                    { name: "note" }
                ),
                value: maxLength
            }
        );
        if (maxLength && value.toString().length > maxLength) {
            fieldValue[fieldName] = {
                value: value,
                errors: [new Error(overflowLengthMsg)]
            };
            form.setFields(fieldValue);
        } else {
            fieldValue[fieldName] = value;
            form.setFieldsValue(fieldValue);
        }
    }
}

export function richTextEditorValueIsEmpty(richTextValue: string) {
    const plainText = richTextValue
        ? richTextValue
              .replace(InvitationTemplateTagHelper.HtmlTagPattern, "")
              .replace(/&nbsp;/g, "")
              .replace(/\n/g, "")
              .trim()
        : "";
    return plainText == "";
}

export function isQuestionEmpty(
    form,
    values,
    optionalQuestionId: string[] = []
) {
    let isEmpty = false;
    const requiredMsg = fmtMsg(SchoolLocale.VisitationAnswerIsRequired);
    Object.keys(values)
        .filter(vk => vk.indexOf("question") >= 0)
        .forEach(vk => {
            if (!Array.isArray(values[vk])) {
                const questionId = vk.split("question-")[1];
                if (
                    richTextEditorValueIsEmpty(values[vk]) &&
                    !optionalQuestionId.some(s => s === questionId)
                ) {
                    form.setFields({
                        [vk]: {
                            value: values[vk],
                            errors: [new Error(requiredMsg)]
                        }
                    });
                    isEmpty = true;
                }
            }
        });
    return isEmpty;
}

export const isOnlySpecificRole = (role: RoleName) => {
    const info = GLGlobal.loginInfo();
    const userRoles = info ? info.profile.roles : null;
    return userRoles && userRoles.length == 1 && userRoles.indexOf(role) > -1;
};

export const userHasRole = role =>
    (GLGlobal.loginInfo().profile || { roles: [] }).roles.some(r => r == role);

export const isInternalUser = () => {
    return (
        userHasRole(RoleName.regionAdmin) ||
        userHasRole(RoleName.trainingAdmin) ||
        userHasRole(RoleName.trainer) ||
        userHasRole(RoleName.globalHead) ||
        userHasRole(RoleName.systemAdmin) ||
        userHasRole(RoleName.accountManager) ||
        userHasRole(RoleName.trainingManager) ||
        userHasRole(RoleName.contentAdmin)
    );
};

export const isInternalUserWithoutAM = () => {
    return (
        userHasRole(RoleName.regionAdmin) ||
        userHasRole(RoleName.trainingAdmin) ||
        userHasRole(RoleName.trainer) ||
        userHasRole(RoleName.globalHead) ||
        userHasRole(RoleName.systemAdmin) ||
        userHasRole(RoleName.trainingManager)
    );
};

export const isSystemAdmin = () => {
    return userHasRole(RoleName.systemAdmin);
};

export async function isStudentVerificationAllowed(regionId) {
    const permission = new LocalPermissionService();
    return await permission
        .isStudentVerificationAllowed(regionId)
        .then(isAllowed => {
            return isAllowed;
        });
}

export async function isFutureClass(classId) {
    const classService = new SchoolClassService();
    return await classService.isFutureClass(classId).then(isFuture => {
        return isFuture;
    });
}

export const registerStudentVisibilityCondition = studentRegistrationTypeId => {
    if (
        isInternalUser() ||
        studentRegistrationTypeId === StudentRegistrationType.Both ||
        studentRegistrationTypeId == StudentRegistrationType.Manual
    ) {
        return true;
    }

    return false;
};

export const isPhoneRegistrationEnable = (isEnable: boolean): boolean => {
    if (isEnable) {
        return true;
    } else {
        let roles = GLGlobal.loginInfo().profile.roles;
        if (
            roles &&
            (roles.indexOf(RoleName.systemAdmin) > -1 ||
                roles.indexOf(RoleName.trainingAdmin) > -1 ||
                roles.indexOf(RoleName.contentAdmin) > -1 ||
                roles.indexOf(RoleName.globalHead) > -1)
        ) {
            return true;
        } else {
            return false;
        }
    }
};

export const isRegisterManualDisable = studentRegistrationTypeId => {
    if (
        !isInternalUser() &&
        studentRegistrationTypeId === StudentRegistrationType.Generic
    ) {
        return true;
    }
    return false;
};

export function formatBytes(
    bytes: number,
    convertTo: DataMeasurementEnum,
    decimals = 2,
    format = false
): number | string {
    const convertData = (bytes / Math.pow(1024, convertTo)).toFixed(decimals);
    return format
        ? `${convertData}(${DataMeasurementEnum[convertTo]})`
        : Number(convertData);
}

export function formatBytesByCeil(
    bytes: number,
    convertTo: DataMeasurementEnum
): number {
    return Math.ceil(Number(formatBytes(bytes, convertTo, 2)));
}

export function inviteEmailValidation(
    value: string,
    callback: (msg?: string) => void,
    invalidEmailMsg: string = GLGlobal.intl.formatMessage({
        id: GLLocale.FormEmail
    })
) {
    if (!value) {
        callback && callback();
        return;
    }
    const lValue = value.toLocaleLowerCase();
    if (lValue.length > 128) {
        callback &&
            callback(
                GLGlobal.intl.formatMessage({
                    id: GSAdminLocale.RegistrationFormEmailLengthError
                })
            );
    }
    if (lValue.match(/^_/)) {
        callback &&
            callback(
                GLGlobal.intl.formatMessage({
                    id: SchoolLocale.InvitationUnderscoreEmailWarn
                })
            );
    }
    if (lValue.length && !lValue.match(EmailRegex)) {
        callback && callback(invalidEmailMsg);
    }
}

export function phoneValidation(
    value: string | number,
    validateWithCode: boolean = false,
    callback?: (msg?: string) => void
) {
    if (!value) {
        callback && callback();
        return;
    }
    value = value.toString().trim();
    if (validateWithCode) {
        if (!/^\+/g.test(value)) {
            callback && callback("The phone must start with '+'");
            return;
        }
        if (!/^\+[0-9]{8}/g.test(value)) {
            callback && callback("Phone format is not correct.");
            return;
        }
    } else {
        if (!/^[0-9]{8}[0-9]*$/g.test(value)) {
            callback && callback("Phone format is not correct.");
            return;
        }
    }
}

export interface LicenseDisabled {
    isLicenseEditDisabled: boolean;
    isCurrentCyclePeriodDisabled: boolean;
    isFutureCyclePeriodDisabled: boolean;
}

const isClassEndDatePastRegionBillingDay = (
    regionBillingInfo,
    classEndDate,
    invoiceInfo
): LicenseDisabled => {
    if (regionBillingInfo && classEndDate && invoiceInfo) {
        const regionBillingDate =
            regionBillingInfo && regionBillingInfo.billingDay;
        const formattedClassEndDate = moment(classEndDate, "YYYY/MM/DD");

        const classEndDay = Number(formattedClassEndDate.format("D"));

        const currentDay = new Date().getDate();

        const isClassEndDateBetweenLastCycle =
            formattedClassEndDate.isBetween(
                moment(
                    invoiceInfo.lastCycle.billingCycleStart ||
                        invoiceInfo.currentLicensePeriodStart,
                    "YYYY/MM/DD"
                ),
                moment(
                    invoiceInfo.lastCycle.billingCycleEnd ||
                        invoiceInfo.currentLicensePeriodEnd,
                    "YYYY/MM/DD"
                )
            ) ||
            formattedClassEndDate.isSame(
                moment(
                    invoiceInfo.lastCycle.billingCycleStart ||
                        invoiceInfo.currentLicensePeriodStart,
                    "YYYY/MM/DD"
                )
            ) ||
            formattedClassEndDate.isSame(
                moment(
                    invoiceInfo.lastCycle.billingCycleEnd ||
                        invoiceInfo.currentLicensePeriodEnd,
                    "YYYY/MM/DD"
                )
            );

        const isClassEndDateBetweenCurrentCycle =
            formattedClassEndDate.isBetween(
                moment(
                    invoiceInfo.currentCycle.billingCycleStart,
                    "YYYY/MM/DD"
                ),
                moment(invoiceInfo.currentCycle.billingCycleEnd, "YYYY/MM/DD")
            ) ||
            formattedClassEndDate.isSame(
                moment(invoiceInfo.currentCycle.billingCycleStart, "YYYY/MM/DD")
            ) ||
            formattedClassEndDate.isSame(
                moment(invoiceInfo.currentCycle.billingCycleEnd, "YYYY/MM/DD")
            );

        const isClassEndDatePastBillingDate = moment(
            classEndDate,
            "YYYY/MM/DD"
        ).isAfter(moment(invoiceInfo.currentCycleInvoiceDate, "YYYY/MM/DD"));
        const isCurrentDatePastBillingDate = moment(
            moment(),
            "YYYY/MM/DD"
        ).isAfter(moment(invoiceInfo.currentCycleInvoiceDate, "YYYY/MM/DD"));

        if (
            isClassEndDateBetweenCurrentCycle &&
            !isClassEndDatePastBillingDate
        ) {
            return {
                isLicenseEditDisabled: false,
                isCurrentCyclePeriodDisabled: false,
                isFutureCyclePeriodDisabled: true
            };
        } else if (
            isClassEndDateBetweenCurrentCycle &&
            isClassEndDatePastBillingDate &&
            isCurrentDatePastBillingDate
        ) {
            return {
                isLicenseEditDisabled: true,
                isCurrentCyclePeriodDisabled: false,
                isFutureCyclePeriodDisabled: true
            };
        } else if (
            isClassEndDateBetweenCurrentCycle &&
            isClassEndDatePastBillingDate &&
            !isCurrentDatePastBillingDate
        ) {
            return {
                isLicenseEditDisabled: false,
                isCurrentCyclePeriodDisabled: false,
                isFutureCyclePeriodDisabled: true
            };
        } else if (
            isClassEndDateBetweenLastCycle &&
            classEndDay > regionBillingDate &&
            currentDay > regionBillingDate
        ) {
            return {
                isLicenseEditDisabled: true,
                isCurrentCyclePeriodDisabled: true,
                isFutureCyclePeriodDisabled: true
            };
        } else if (
            isClassEndDateBetweenLastCycle &&
            classEndDay > regionBillingDate &&
            currentDay < regionBillingDate
        ) {
            return {
                isLicenseEditDisabled: false,
                isCurrentCyclePeriodDisabled: true,
                isFutureCyclePeriodDisabled: true
            };
        } else if (isClassEndDateBetweenLastCycle) {
            return {
                isLicenseEditDisabled: true,
                isCurrentCyclePeriodDisabled: true,
                isFutureCyclePeriodDisabled: true
            };
        }
    }
    return {
        isLicenseEditDisabled: false,
        isCurrentCyclePeriodDisabled: false,
        isFutureCyclePeriodDisabled: false
    };
};

export const disableFutureLicenseEdit = (
    regionBillingInfo,
    classEndDate,
    invoiceInfo
) => {
    if (isInternalUser()) {
        return {
            isLicenseEditDisabled: false,
            isCurrentCyclePeriodDisabled: false,
            isFutureCyclePeriodDisabled: false
        };
    } else {
        return isClassEndDatePastRegionBillingDay(
            regionBillingInfo,
            classEndDate,
            invoiceInfo
        );
    }
};

export const isUserGlobalHead = () => {
    // return true only if user has GlobalHead as highest role.
    return (
        userHasRole(RoleName.globalHead) && !userHasRole(RoleName.systemAdmin)
    );
};

export function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function getPhoneCountryCode(
    phoneWithCountryCode: string
): IPhoneCountryCode {
    return defaultPhoneCountryCodeList.filter(dpcc =>
        phoneWithCountryCode.startsWith(dpcc.code)
    )[0];
}

export function getCountryAbbr(phoneWithCountryCode: string) {
    return getPhoneCountryCode(phoneWithCountryCode).counrtryAbbr;
}

export function getCountryCode(phoneWithCountryCode: string) {
    return getPhoneCountryCode(phoneWithCountryCode).code;
}

export function getPhoneWithoutCountryCode(phoneWithCountryCode: string) {
    return phoneWithCountryCode.replace(
        getCountryCode(phoneWithCountryCode),
        ""
    );
}

export const formatName = (englishName: string, nativeName: string, noFormatting?: boolean) => {
    if (!englishName && !nativeName) {
        return "";
    }
    if (!englishName) {
        return nativeName;
    }
    if (nativeName && nativeName.length > 0) {

        //TODO: it's a hot fix for production, not a solution
        if (nativeName.indexOf("(") > 0 && nativeName.indexOf(")") > nativeName.indexOf("(")) {
            return nativeName;
        }

        if (noFormatting) {
            return nativeName.concat(" (" + englishName + ")");
        }

        return nativeName.concat(" <i>(" + englishName + ")</i>");
    }
    if (englishName.toLowerCase() == nativeName.toLowerCase() || englishName) {
        return englishName;
    }
};

export const formatDate = (date: moment.Moment, format: string): string => {
    return moment(date).format(format);
}

export const newLineRegex = /(?:\r\n|\r|\n)/g;

export const hasConnectLicense = (licenseType : number) => {
    return licenseType == LicenseTypes.Connect || licenseType == LicenseTypes.ClassicConnectHybrid || licenseType == LicenseTypes.ConnectNexusHybrid
}
export const isExitSurvey = (surveyId: string) => {
    if (!surveyId || surveyId == "") {
        return false;
    }

    return surveyId.toLowerCase() === ExitSurveyId.toLowerCase();
}
