import { ElementType } from "react";

interface BaseMessage {
    type: string;
    component: ElementType;
    sender: string;
    from: string;
    showProfile: boolean;
    showTimeStamp: boolean;
    isSeries: boolean;
    timestamp: Date;
    unread: boolean;
    props?: any;
}

export const MESSAGE_REFRESH_TIME = 45000;

export interface Message extends BaseMessage {
    text: string;
}

export const MESSAGE_SENDER = {
    CLIENT: "client",
    RESPONSE: "response"
};

export const MESSAGE_BOX_SCROLL_DURATION = 400;

export const createNewMessage = (
    text: string,
    sender: string,
    from: string,
    isSeries: boolean,
    sendAt?: Date
) => ({
    text,
    sender,
    timestamp: sendAt ? sendAt : new Date(),
    from: from,
    showProfile: sender === MESSAGE_SENDER.RESPONSE,
    unread: sender === MESSAGE_SENDER.RESPONSE,
    isSeries: isSeries
});

export interface UserMessagesInfo {
    userId: string;
    avatar?: string;
    userName: string;
    messageList: Message[];
}

export interface UserChatMessageModel {
    id: number;
    sendAt: Date;
    isRead: boolean;
    message: string;
    chatGroupId: number;
    chatContextId: number;
    senderId: string;
    receiverId: string;
}

export interface ChatThreadModel {
    id: string;
    avatar: string;
    title: string;
    lastMessage: UserChatMessageModel;
}

export interface ChatUserContact {
    id: string;
    name?: string;
}

export interface UserRelatedResource {
    schoolId: string;
    schoolName: string;
    roles?: Array<number>;
}

export interface ChatThread {
    id: string;
    title?: string;
    messageId?: string;
    createAt?: Date;
    createBy?: string;
    visitationId?: string;
}

export interface ChatSchoolDataContract {
    id: string;
    name: string;
}

export interface ChatGroupModel {
    groupId: string;
    sender: ChatUserContact;
    receiver: ChatUserContact;
}

export interface ChatUnreadMessageModel {
    senderId: string;
    senderName: string;
    relatedSchools: string[];
    unReadMessages: [
        {
            threadId: string;
            threadTitle: string;
            createAt: Date;
            visitationId: string;
            count: number;
        }
    ];
}

export interface ChatMessageModel {
    id: string;
    message: string;
    isRead: boolean;
    senderId: string;
    senderAt: Date;
    chatGroupId: string;
    chatContextId: string;
    chatThreadId?: string;
    chatThreadTitle?: string;
}

export interface ChatThreadMessage {
    chatThreadId: string;
    chatThreadTitle: string;
    messages: Array<ChatMessageModel>;
}

export const MESSAGE_PER_PAGE_COUNT = 20;
