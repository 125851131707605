import { LazyPortal } from "@app/components/admin/lazy-portal";
import { WijmoGrid } from "@app/components/grid";
import PreventClickingMask from "@app/components/prevent-clicking-mask";
import { Action, Actions, Container, Loading, TableWithPerfectScroll } from "@app/components/survey";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { GSAdminLocale, SurveyLocale } from "@app/locales/localeid";
import { IRegionService } from "@app/service/admin/regions";
import { ISchoolClassService } from "@app/service/class/index";
import { ISchoolService } from "@app/service/schools/index";
import {
    ISurveyService,
    KeyValue,
    QuestionType,
    SurveyModel,
    SurveyRoleNames,
    SurveyRoleTargetModel,
    SurveyRoles,
    SurveyTargetTypeNames,
    SurveyTargetTypes
} from "@app/service/survey/surveys/index";
import {
    KeyValueResponseModel,
    SurveyFilterDataType,
    SurveyQuestionModel,
    SurveyQuestionOptionModel,
    SurveyRecurrenceType,
    SurveyReportType,
    SurveyStatus,
    SurveyToTargets,
    WeeklyRecurrenceDay
} from "@app/service/survey/surveys/models";
import { IUserService } from "@app/service/users/index";
import {
    SurveyDisplayMode,
    addRemoveQuestionsWithError,
    getAllReportType,
    getAllStatisticsFilterType,
    reload,
    removeSurvey,
    setSurveyDetail,
    setSurveyDisplayMode,
    toggleFormVisiblility
} from "@app/states/survey/survey";
import { ServiceExceptionCode as ExceptionCode } from "@app/util/enum";
import {
    CONSTS,
    DateHelper,
    EmailParameter,
    MaxSurveyOptionCount,
    MaxSurveySliderStepCount,
    TYPES,
    createSliderMarks,
    deepClone,
    distinct,
    fmtMsg,
    isExitSurvey,
    isPastDate,
    lazyInject,
    lengthValider,
    mergeClasses,
    urlValider
} from "@app/util/index";
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Empty,
    Form,
    Icon,
    Input,
    Modal,
    Popover,
    Radio,
    Rate,
    Row,
    Select,
    Slider,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    message,
    notification
} from "antd-min";
import RadioGroup from "antd/lib/radio/group";
import {
    FormHelper,
    GLForm,
    GLFormComponentProps,
    GLGlobal,
    GLLocale,
    GLUtil,
    LanguageDateFormat,
    MessageHelper,
    NotificationType,
    PathConfig,
    RegionInfoModel,
    RoleName,
    ServerExceptionCode,
    StateType,
    alignPop,
    connect
} from "gl-commonui";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    QuestionTypes,
    SurveyProps,
    SurveyStates,
    TargetSelectModel,
    localeDayMap,
    localeFullDayMap
} from "./components";
import { ResponseTab } from "./components/response-tab";
import "./create-poll.less";
import { GridICellTemplateContext } from "@app/components/grid/grid-column";

const { Grid, Column } = WijmoGrid;

const { TextArea } = Input;

@GLForm.create()
@connect(
    ({ survey: { surveyDisplayMode, surveyDetails, selectedSurvey, reportTypes, statisticsFilterType, questionsWithError }, }: StateType) => ({
        surveyDisplayMode,
        surveyDetails,
        selectedSurvey,
        reportTypes,
        statisticsFilterType,
        questionsWithError
    }),
    {
        toggleFormVisiblility,
        setSurveyDetail,
        removeSurvey,
        getAllReportType,
        getAllStatisticsFilterType,
        setSurveyDisplayMode,
        reload,
        addRemoveQuestionsWithError
    })
export class CreatePollPage extends Component<
    SurveyProps & RouteComponentProps<any> & GLFormComponentProps,
    SurveyStates
> {
    @lazyInject(TYPES.ISurveyService)
    surveyService: ISurveyService;
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;

    originRoleTargets: SurveyRoleTargetModel[] = [];
    lastFetchId = 0;
    lastShareFetchId = 0;
    lastFetchUsers = null;
    continue = false;
    enableDebounce = false;
    userSelects = [];
    userSelectSearch;
    userShareSelectSearch;
    usersFilter;
    activeTab: string;
    creatorSharedUsers = { initValueSaved: false, value: [] };
    constructor(props, context) {
        super(props, context);
        const debounceTime = 800;
        this.state = {
            modal: {
                roleTarget: { visible: false, disabled: false, loading: false },
                user: { visible: false },
                sharedUser: { visible: false },
                userNotified: { visible: false },
                recurring: { visible: false },
                stats: { visible: false },
                previewSurvey: { visible: false }
            },
            surveyModel: {
                name: "",
                description: "",
                isRecurring: false,
                surveyType: SurveyToTargets.Roles,
                surveyShare: [],
                surveyQuestion: [],
                reportTypeId: [],
                statisticsFilterTypeId: [],
                surveyStatus: SurveyStatus.Draft,
                allowDelete: true,
                isPaging: false
            },
            loading: false,
            selectRole: SurveyRoles.All,
            selectTargetType: SurveyTargetTypes.All,
            showTargetSelect: this.getInitTargetSelect(),
            notifyChanged: SurveyToTargets.Roles,
            formatRoleTargets: [],
            notifiedUserCount: 0,
            targetCountHash: '',
            notifyUsers: [],
            notifiedUsers: [],
            notifiedFilterUsers: [],
            checkNotifyList: false,
            searchUser: [],
            searchShareUser: [],
            searchTempShareUser: [],
            probablyUsers: [],
            probablyShareUsers: [],
            userFetching: false,
            userShareFetching: false,
            recurrenceError: false,
            isRegionSpecific: false,
            includeStats: false,
            addButtonExpanded: false,
            displayDeleteButtonIndex: -1,
            displayDeleteButtonOptionIndex: { questionIndex: -1, optionIndex: -1 },
            disablePublish: false,
            isReadonly: true,
            selectedRegion: GLGlobal.loginInfo().profile.regionInfo && GLGlobal.loginInfo().profile.regionInfo.length > 0 ? GLGlobal.loginInfo().profile.regionInfo[0].id : null
        };
        this.userSelectSearch = debounce(searchUser => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            this.onUserSelectSearch(searchUser.trim());
        }, debounceTime);
        this.usersFilter = debounce(key => {
            this.setNotifiedFilterUsers(key);
        }, debounceTime);

        this.isRecurringOnChange = this.isRecurringOnChange.bind(this);
        this.onSurveyTypeChange = this.onSurveyTypeChange.bind(this);
        this.onRecurringTypeChange = this.onRecurringTypeChange.bind(this);
        this.userShareSelectSearch = debounce(searchUser => {
            if (searchUser == undefined || searchUser.trim().length < 3) return;
            this.onShareUserSelectSearch(searchUser.trim());
        }, debounceTime);
        this.onTabChange = this.onTabChange.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmClone = this.confirmClone.bind(this);
        this.activeTab = GLUtil.queryParse().tab || "questions";
        this.accessibleRegions = GLGlobal.loginInfo().profile.regionInfo || [];
        this.onRegionChange = this.onRegionChange.bind(this);
    }

    loggedInUserId: string;
    accessibleRegions: RegionInfoModel[];
    surveyModelCopy: any = null;
    isRegionSpecificCopy: boolean;
    questionTypeDivRef: any;
    surveyTitleRef: any;
    dueDateRef: any;
    checkNotifyListRef: any;
    questionsRef: { id: string, ref: any, optionsIds: string[] }[] = [];

    QuestionTypes: QuestionTypes[] = [
        { text: fmtMsg({ id: SurveyLocale.SurveyQuestionTypeOptions }), icon: "unordered-list", type: QuestionType.Option },
        { text: fmtMsg({ id: SurveyLocale.SurveyQuestionTypeRating }), icon: "like", type: QuestionType.Rating },
        { text: fmtMsg({ id: SurveyLocale.SurveyQuestionTypeText }), icon: "file-text", type: QuestionType.Text },
        { text: fmtMsg({ id: SurveyLocale.SurveyQuestionTypeCheckbox }), icon: "check-square", type: QuestionType.MultChoice },
        { text: fmtMsg({ id: SurveyLocale.SurveyQuestionTypeScore }), icon: "sliders", type: QuestionType.Score }
    ];
    async componentDidMount() {
        this.loggedInUserId = GLGlobal.loginInfo().profile.sub;

        if (this.activeTab === "questions") {
            this.props.getAllReportType();
            this.props.getAllStatisticsFilterType();
            if (this.props.match.path === SurveyPathConfig.DetailsFromLanding || this.props.match.path === SurveyPathConfig.DetailsFromHistory) {
                this.getSurveyDetail(this.props.match.params.surveyId);
            }
        }
        document.addEventListener("click", this.toggleButtonVisibility);
        await this.getRegionsAsRegionAdmin();
    }

    isSuperSurveyUser() {
        const roles = GLGlobal.loginInfo().profile.roles;
        return roles.indexOf(RoleName.systemAdmin) >= 0 || roles.indexOf(RoleName.globalHead) >= 0;
    }

    async getRegionsAsRegionAdmin() {
        let roles = GLGlobal.loginInfo().profile.roles;
        if (!roles) {
            return;
        }
        const isSuperUser = roles.indexOf(RoleName.systemAdmin) || roles.indexOf(RoleName.globalHead) || roles.indexOf(RoleName.trainingAdmin);
        if (isSuperUser > 0 || roles.indexOf(RoleName.regionAdmin) < 0) {
            return;
        }

        const regionIds = await this.regionService.getRegionsAsRegionAdmin();
        if (regionIds && regionIds.length) {
            this.setState({
                regionIdsAsRegionAdmin: regionIds
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.toggleButtonVisibility);
        // reset surveyState
        this.props.setSurveyDisplayMode(SurveyDisplayMode.Create);
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        let isReadonly: boolean = nextProps.surveyDisplayMode === SurveyDisplayMode.Readonly || nextProps.surveyDisplayMode === SurveyDisplayMode.EditReadonly || nextProps.surveyDisplayMode === SurveyDisplayMode.ReadonlyDelete
        if (currentState.isReadonly != isReadonly) {
            return { isReadonly }
        }
        return {}
    }

    onRegionChange(value) {
        this.setState({ selectedRegion: value });
    }

    getSurveyDetail(selectedSurvey: string) {
        this.setState({ loading: true });
        this.surveyService
            .getDetail({ id: selectedSurvey })
            .then(({ survey, surveyCount }: { survey: SurveyModel; surveyCount: number }) => {
                // if survey is published and non recurring then disable editing
                if (survey.surveyStatus == SurveyStatus.Published && !survey.isRecurring) {
                    this.props.setSurveyDisplayMode(SurveyDisplayMode.ReadonlyDelete);
                }
                else {
                    this.props.setSurveyDisplayMode(SurveyDisplayMode.EditReadonly)
                }
                if (survey.surveyType === SurveyToTargets.Roles) {
                    this.setState(
                        {
                            surveyModel: { ...survey },
                            formatRoleTargets: survey.roleTargets.map(this.formatRoleTarget).map(d => ((d.isCalculated = true), d)),
                            notifiedUserCount: surveyCount,
                            notifyChanged: SurveyToTargets.Roles,
                            searchShareUser: survey.surveyShare.map(share => ({ key: share.userId }))
                        },
                        () => {
                            this.getTargetCount(this.state.formatRoleTargets.map(fr => ((fr.isCalculated = true), fr)));
                            this.setSurveyDetail(survey);
                        }
                    );
                } else {
                    this.setState(
                        {
                            surveyModel: { ...survey },
                            notifyUsers: survey.roleTargets.map(rt => ({
                                ...rt,
                                rowKey: rt.targetId,
                                id: rt.targetId,
                                ...JSON.parse(rt.targetName)
                            })),
                            notifiedUserCount: surveyCount,
                            notifyChanged: SurveyToTargets.Users,
                            searchShareUser: survey.surveyShare.map(share => ({ key: share.userId }))
                        },
                        () => this.setSurveyDetail(survey)
                    );
                }
            })
            .catch(({ error_code }) => {
                if (error_code == ServerExceptionCode.TargetIsNullException) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationDetail }));
                    this.goToPollsHome();
                }
                if (error_code == ServerExceptionCode.NoPermissionException) {
                    this.props.history.push(PathConfig.AccessDenied);
                }
            });
    }

    setSurveyDetail = (survey: SurveyModel) => {
        const { surveyModel } = this.state;
        let isRegionSpecific = false;
        if (survey.isStatisticsRequired) {
            setTimeout(() => this.props.form.setFieldsValue({ includeStatistics: true }), 50);
            surveyModel.statisticsFilterTypeId = survey.statisticsFilterTypeId ? [survey.statisticsFilterTypeId.toString()] as unknown as [] : [];
            surveyModel.reportTypeId = survey.surveyReportType ? survey.surveyReportType.map(type => type.reportTypeId.toString()) as [] : [];
            survey.statisticsFilterTypeId ? isRegionSpecific = true : null;
        }
        else {
            this.props.form.setFieldsValue({ includeStatistics: false });
            surveyModel.statisticsFilterTypeId = [];
        }
        if (survey.isRecurring) {
            this.props.form.setFieldsValue({ isRecurring: true })
        }
        this.setState({ surveyModel, loading: false, isRegionSpecific });
    };

    getInitTargetSelect() {
        return {
            region: {
                selected: false,
                loaded: false,
                selectedId: undefined,
                resources: []
            },
            school: {
                selected: false,
                loaded: false,
                selectedId: undefined,
                resources: []
            },
            campus: {
                selected: false,
                loaded: false,
                selectedId: undefined,
                resources: []
            },
            schoolClass: {
                selected: false,
                loaded: false,
                selectedId: undefined,
                resources: []
            }
        } as TargetSelectModel;
    }
    exceptTargetSelect(selectdId, includeTargetType) {
        let nextTargetSelect = { ...this.state.showTargetSelect };
        nextTargetSelect[SurveyTargetTypes[includeTargetType].toLowerCase()].selectedId = selectdId;
        const exceptSelected = excepts =>
            excepts.forEach(except => {
                var target = nextTargetSelect[except];
                target.loaded = false;
                target.selectedId = undefined;
                target.resources = [];
            });
        switch (includeTargetType) {
            case SurveyTargetTypes.All:
                nextTargetSelect = this.getInitTargetSelect();
            case SurveyTargetTypes.Region:
                exceptSelected(["school", "campus", "schoolClass"]);
                break;
            case SurveyTargetTypes.School:
                exceptSelected(["campus", "schoolClass"]);
                break;
            case SurveyTargetTypes.Campus:
                exceptSelected(["schoolClass"]);
                break;
        }
        this.setState({ showTargetSelect: nextTargetSelect });
    }
    showTargetSelects(targetType, showTargetSelect) {
        showTargetSelect.region.selected = targetType >= SurveyTargetTypes.Region;
        showTargetSelect.school.selected = targetType >= SurveyTargetTypes.School;
        showTargetSelect.campus.selected = targetType >= SurveyTargetTypes.Campus;
        showTargetSelect.schoolClass.selected = targetType >= SurveyTargetTypes.SchoolClass;
    }

    onSubmit = (e, surveyStatus = SurveyStatus.Published) => {
        // disable publish
        this.setState({ disablePublish: true });
        e && e.preventDefault();
        var fields = Object.getOwnPropertyNames(this.props.form.getFieldsValue()).filter(field => field !== "text" && field !== "url");
        this.props.form.validateFields(fields, (err, values) => {
            // for scrolling to the error which is at the top
            if (this.scrolledToTopError(err, values.surveyType)) {
                return;
            }
            if (!err) {
                if (!this.state.surveyModel.surveyQuestion.length && surveyStatus != SurveyStatus.Draft) {
                    message.error(fmtMsg({ id: SurveyLocale.MinQuestionError }));
                    return;
                }
                const { surveyModel } = this.state;
                values.surveyRoleTarget = this.state.formatRoleTargets;
                if (values.surveyType == SurveyToTargets.Users) {
                    values.surveyRoleTarget = this.state.notifyUsers;
                }
                values.surveyShareTarget = this.state.searchShareUser.map(a => {
                    return a.key;
                });

                if (this.props.surveyDisplayMode === SurveyDisplayMode.Edit) {
                    values.id = this.state.surveyModel.id;
                }
                if (values.isRecurring) {
                    values.recurringTypeId = surveyModel.recurringTypeId;
                    values.recurringDay = surveyModel.recurringDay;
                    values.dueDate = null;
                } else {
                    const momentDueDate = (values.dueDate as moment.Moment);
                    const year = momentDueDate.get("year");
                    const month = momentDueDate.get("month") + 1;
                    const date = momentDueDate.get("date");

                    values.dueDate = `${year}/${month}/${date}`;
                }
                if (values.includeStatistics) {
                    values.statisticsFilterTypeId = surveyModel.statisticsFilterTypeId[0];
                    values.reportTypeId = surveyModel.reportTypeId;
                }
                values.surveyQuestion = surveyModel.surveyQuestion;
                values.surveyStatus = surveyStatus;
                values.id = surveyModel.id;
                const notifyRole = values.surveyType == SurveyToTargets.Roles;
                if (notifyRole) values.targetCountHash = this.state.targetCountHash;
                this.surveyService.submitSurvey(values).then(d => {
                    surveyStatus === SurveyStatus.Published
                        ? message.success(fmtMsg({ id: SurveyLocale.NotificationSaveSuccessMsg }))
                        : message.success(fmtMsg({ id: SurveyLocale.SurveyFillSaveAsDraftSuccess }))

                    const notifyCount = notifyRole ? this.state.notifiedUserCount : this.state.notifyUsers.length;
                    if (notifyCount > EmailParameter.EmailsLimited && surveyStatus != SurveyStatus.Draft) {
                        MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: GSAdminLocale.NotificationSendEmailToMore3k }));
                    }

                    // redirect to polls home
                    this.goToPollsHome(true);
                }).catch(({ error_code }) => {
                    if (error_code == ExceptionCode.EmailsLimitException) {
                        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationEmailLimited }, { limitNumberEmails: process.env.limitNumberEmails }));
                        this.setState({ disablePublish: true });
                    }
                    else if (error_code == ExceptionCode.EmailsTargetLimitException) {
                        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: GSAdminLocale.NotificationTargetEmailLimited }));
                    }
                });
            }
        });
        this.setState({ disablePublish: false });
    }

    scrolledToTopError = (err: {}, surveyType: SurveyToTargets): boolean => {
        // User to be notified error is handled manually
        if (!this.checkNotifyList(surveyType)) {
            this.checkNotifyListRef && this.checkNotifyListRef.scrollIntoView();
            return true;
        }
        if (!err) {
            return false;
        }
        const errorKeys = Object.keys(err);
        const isSurveyNameError = errorKeys.indexOf("name") > -1;
        const isDueDateError = errorKeys.indexOf("dueDate") > -1;
        const quesError = errorKeys.filter(key => /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/g.test(key));

        if (isSurveyNameError) {
            // scroll to survey title error
            this.surveyTitleRef && this.surveyTitleRef.scrollIntoView();
            return true;
        } else if (isDueDateError) {
            this.dueDateRef && this.dueDateRef.scrollIntoView();
            return true;
        } else if (quesError.length > 0) {
            // take first id and check if question is not filled.
            const firstGuidKey = quesError[0];
            const quesRef = this.questionsRef.find(ques => {
                if (ques.id === firstGuidKey || ques.optionsIds.filter(o => o === firstGuidKey).length) {
                    return ques;
                }
            });
            if (quesRef) {
                quesRef.ref.scrollIntoView();
            }
            return true;
        }

        return false;
    }

    goToPollsHome = (reloadHomePolls: boolean = false) => {
        reloadHomePolls && this.setReloadPollsTrue();
        if (this.props.match.path === SurveyPathConfig.Create) {
            this.props.history.push(SurveyPathConfig.Home);
        }
        else {
            this.props.history.push(SurveyPathConfig.CreatedHistory);
        }
    };

    setReloadPollsTrue = () => {
        this.props.reload({ shouldReloadCreatedSurveys: true });
    }

    checkNotifyList(surveyType) {
        var pass = true;
        if (surveyType === SurveyToTargets.Roles && this.state.formatRoleTargets.length == 0) {
            pass = false;
        } else if (surveyType === SurveyToTargets.Users && this.state.notifyUsers.length == 0) {
            pass = false;
        }
        this.setState({ checkNotifyList: !pass });
        return pass;
    }
    checkNotifyListCallBack() {
        this.checkNotifyList({
            surveyType: this.props.form.getFieldValue("surveyType")
        });
    }
    onRolesSelect(selectRole) {
        const modal = { ...this.state.modal };
        modal.roleTarget.disabled = false;
        this.setState({
            selectRole,
            selectTargetType: SurveyTargetTypes.All,
            showTargetSelect: this.getInitTargetSelect(),
            modal
        });
    }
    onTargetTypesSelect(targetType) {
        let showTargetSelect = this.getInitTargetSelect();
        let modal = { ...this.state.modal };
        if (targetType != SurveyTargetTypes.All) {
            modal.roleTarget.disabled = true;

            this.showTargetSelects(targetType, showTargetSelect);

            this.regionService
                .getItemsBy({
                    disabled: false,
                    includeTraining: userHasRole(RoleName.trainer)
                })
                .then(d => {
                    showTargetSelect = { ...this.state.showTargetSelect };
                    showTargetSelect.region.loaded = true;
                    showTargetSelect.region.resources = d.data;
                    this.setState({ showTargetSelect });
                });
        } else {
            modal.roleTarget.disabled = false;
            showTargetSelect = this.getInitTargetSelect();
        }

        this.setState({
            selectTargetType: targetType,
            showTargetSelect,
            modal
        });
    }
    targetCompletedSelect(targetType) {
        let lastTargetType = SurveyTargetTypes.All;
        if (this.state.showTargetSelect.schoolClass.selected) {
            lastTargetType = SurveyTargetTypes.SchoolClass;
        } else if (this.state.showTargetSelect.campus.selected) {
            lastTargetType = SurveyTargetTypes.Campus;
        } else if (this.state.showTargetSelect.school.selected) {
            lastTargetType = SurveyTargetTypes.School;
        } else if (this.state.showTargetSelect.region.selected) {
            lastTargetType = SurveyTargetTypes.Region;
        }
        const modal = { ...this.state.modal };
        modal.roleTarget.disabled = !(lastTargetType == targetType);
        this.setState({ modal });
        return lastTargetType == targetType;
    }
    onTargetSelect(selectdId, targetType: SurveyTargetTypes) {
        switch (targetType) {
            case SurveyTargetTypes.Region:
                this.exceptTargetSelect(selectdId, SurveyTargetTypes.Region);
                if (this.targetCompletedSelect(SurveyTargetTypes.Region)) return;
                this.schoolService.getAccessibleWithoutDisabled(selectdId, false).then((d: { id; name }[]) => {
                    const showTargetSelect = {
                        ...this.state.showTargetSelect
                    };
                    showTargetSelect.region.selectedId = selectdId;
                    showTargetSelect.school.loaded = true;
                    showTargetSelect.school.resources = distinct(d, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case SurveyTargetTypes.School:
                this.exceptTargetSelect(selectdId, SurveyTargetTypes.School);
                if (this.targetCompletedSelect(SurveyTargetTypes.School)) return;
                this.schoolService.getAccessibleCampuses(selectdId, false).then((d: { id; name }[]) => {
                    const showTargetSelect = {
                        ...this.state.showTargetSelect
                    };
                    showTargetSelect.school.selectedId = selectdId;
                    showTargetSelect.campus.loaded = true;
                    showTargetSelect.campus.resources = distinct(d, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case SurveyTargetTypes.Campus:
                const schoolId = this.state.showTargetSelect.school.selectedId;
                this.exceptTargetSelect(selectdId, SurveyTargetTypes.Campus);
                if (this.targetCompletedSelect(SurveyTargetTypes.Campus)) return;
                this.schoolClassService.getAccessibleClasses(schoolId, selectdId, false).then(d => {
                    const showTargetSelect = {
                        ...this.state.showTargetSelect
                    };
                    showTargetSelect.campus.selectedId = selectdId;
                    showTargetSelect.schoolClass.loaded = true;
                    showTargetSelect.schoolClass.resources = distinct(d.data, d => d.id);
                    this.setState({ showTargetSelect });
                });
                break;
            case SurveyTargetTypes.SchoolClass:
                this.state.showTargetSelect.schoolClass.selectedId = selectdId;
                this.targetCompletedSelect(SurveyTargetTypes.SchoolClass);
                this.setState({
                    showTargetSelect: { ...this.state.showTargetSelect }
                });
                break;
            default:
                break;
        }
    }
    getTargetId(targetType) {
        switch (Number(targetType)) {
            case SurveyTargetTypes.Region:
                return this.state.showTargetSelect.region.selectedId;
            case SurveyTargetTypes.School:
                return this.state.showTargetSelect.school.selectedId;
            case SurveyTargetTypes.Campus:
                return this.state.showTargetSelect.campus.selectedId;
            case SurveyTargetTypes.SchoolClass:
                return this.state.showTargetSelect.schoolClass.selectedId;
            default:
                return undefined;
        }
    }
    addOriginRoleTargets() {
        const roleTargt = {
            role: this.state.selectRole,
            targetType: this.state.selectTargetType,
            targetId: this.getTargetId(this.state.selectTargetType)
        } as SurveyRoleTargetModel;
        this.originRoleTargets.push(roleTargt);
        return this.originRoleTargets;
    }
    getOption(value, text) {
        text = fmtMsg(text);
        return (
            <Select.Option key={value} value={value.toString()} title={text}>
                {text}
            </Select.Option>
        );
    }
    renderRoleOptions() {
        let options = [this.getOption(SurveyRoles.All, GSAdminLocale.NotificationCreateAll)];
        if (userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead) || userHasRole(RoleName.regionAdmin)) {
            if (userHasRole(RoleName.systemAdmin)) {
                options = options.concat([
                    this.getOption(SurveyRoles.GlobalHead, GSAdminLocale.NotificationCreateGlobalHead),
                ]);
            }
            if (userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead)) {
                options = options.concat([
                    this.getOption(SurveyRoles.TrainingAdmin, GSAdminLocale.NotificationCreateTrainingAdministrator),
                    this.getOption(SurveyRoles.RegionAdmin, GSAdminLocale.NotificationCreateRegionAdministrator)
                ]);
            }
            if (!(userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead)) && userHasRole(RoleName.regionAdmin)) {
                options = options.concat([
                    this.getOption(SurveyRoles.RegionAdmin, GSAdminLocale.NotificationCreateRegionAdministrator)
                ]);
            }
            options = options.concat([
                this.getOption(SurveyRoles.SchoolAdmin, GSAdminLocale.NotificationCreateSchoolAdministrator),
                this.getOption(SurveyRoles.CampusAdmin, GSAdminLocale.NotificationCreateCampusAdministrator),
                this.getOption(SurveyRoles.AccountManager, SurveyLocale.CreateSurveyRoleAccountManager),
                this.getOption(SurveyRoles.TrainingManager, GSAdminLocale.NotificationCreateTrainingManager),
                this.getOption(SurveyRoles.Trainer, GSAdminLocale.NotificationCreateTrainer),
                this.getOption(SurveyRoles.Teacher, GSAdminLocale.NotificationCreateTeacher),
            ]);
        }
        return options;
    }
    removeRegionByTrainer(options: any[]) {
        return hasOneRole(RoleName.trainer) ? options.filter(op => op.key != SurveyTargetTypes.Region) : options;
    }
    renderTargetTypeOptions() {
        switch (Number(this.state.selectRole)) {
            case SurveyRoles.All:
                return this.getTargetTypeOptionsByUserRole();
            case SurveyRoles.AccountManager:
            case SurveyRoles.RegionAdmin:
                return [
                    this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion)
                ];
            case SurveyRoles.TrainingAdmin:
            case SurveyRoles.GlobalHead:
                return [this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll)];
            case SurveyRoles.Trainer:
            case SurveyRoles.SchoolAdmin:
                return [
                    this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool)
                ];
            case SurveyRoles.CampusAdmin:
                return [
                    this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                    this.getOption(SurveyTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus)
                ];
            case SurveyRoles.TrainingManager:
                return [
                    this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                ];
            case SurveyRoles.Teacher:
                return [
                    this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                    this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                    this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                    this.getOption(SurveyTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                    this.getOption(SurveyTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass)
                ];
        }
        return [this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll)];
    }
    getTargetTypeOptionsByUserRole() {
        var options = [];
        if (userHasRole(RoleName.systemAdmin) || userHasRole(RoleName.globalHead)) {
            options = options.concat([
                this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(SurveyTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(SurveyTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass)
            ]);
        }
        if (userHasRole(RoleName.regionAdmin)) {
            options = options.concat([
                this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(SurveyTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(SurveyTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass)
            ]);
        }
        if (userHasRole(RoleName.trainingAdmin)) {
            options = options.concat([
                this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
                this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool)
            ]);
        }
        if (userHasRole(RoleName.trainer)) {
            options = options.concat([
                this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(SurveyTargetTypes.School, GSAdminLocale.NotificationCreateSchool),
                this.getOption(SurveyTargetTypes.Campus, GSAdminLocale.NotificationCreateCampus),
                this.getOption(SurveyTargetTypes.SchoolClass, GSAdminLocale.NotificationCreateSchoolClass)
            ]);
        }

        if (userHasRole(RoleName.trainingManager)) {
            options = options.concat([
                this.getOption(SurveyTargetTypes.All, GSAdminLocale.NotificationCreateAll),
                this.getOption(SurveyTargetTypes.Region, GSAdminLocale.NotificationCreateRegion),
            ]);
        }
        return distinct(options, op => op.key);
    }
    setTargetOptionWidth(className) {
        const { region, school, campus, schoolClass } = this.state.showTargetSelect;
        if (schoolClass.selected) return className;
        if (campus.selected) return `${className} three-options`;
        if (school.selected) return `${className} two-options`;
        if (region.selected) return `${className} one-options`;
        return className;
    }
    renderTargetOptions(show, value, placeholder, resources, loaded, type) {
        return show ? (
            <Select
                dropdownClassName={`drop-down ${loaded ? "" : "loading"}`}
                className={this.setTargetOptionWidth("target-select")}
                placeholder={placeholder}
                value={value}
                onChange={value => this.onTargetSelect(value, type)}
                notFoundContent={loaded ? undefined : <Spin size="small" />}
            >
                {resources && resources.map(r => this.getOption(r.id, r.name))}
            </Select>
        ) : null;
    }

    onModalButtonClick() {
        this.setState({
            modal: {
                ...this.state.modal,
                roleTarget: {
                    visible: false,
                    disabled: false,
                    loading: false
                }
            },
            showTargetSelect: this.getInitTargetSelect(),
            selectRole: SurveyRoles.All,
            selectTargetType: SurveyTargetTypes.All
        });
    }
    formatRoleTarget(rt: SurveyRoleTargetModel, rowKey) {
        return {
            ...rt,
            rowKey,
            roleName: SurveyRoleNames[SurveyRoles[rt.role]],
            targetTypeName: SurveyTargetTypeNames[SurveyTargetTypes[rt.targetType]]
        };
    }
    getTargetCount(roleTargets, callback?) {
        this.surveyService.getTargetCount(roleTargets).then((d: { roleTargets; notifiedUserCount; targetCountHash; users }) => {
            this.originRoleTargets = [];
            this.setState(
                {
                    formatRoleTargets: d.roleTargets.map(this.formatRoleTarget),
                    notifiedUserCount: d.notifiedUserCount,
                    targetCountHash: d.targetCountHash
                },
                this.checkNotifyListCallBack.bind(this)
            );
            callback && callback();

            let promises = [];
            const users = d.users as any[];
            while (users.length > 0) {
                promises.push(
                    this.userService.getItemsBy({
                        ids: users.splice(0, 150)
                    })
                );
            }
            Promise.all(promises).then((d: any[]) => {
                this.setState(
                    {
                        notifiedUsers: d.reduce((pre, cur) => pre.concat(cur.data), [])
                    },
                    () => {
                        this.setNotifiedFilterUsers();
                    }
                );
            });
        });
    }
    getTargetCountFromModal() {
        this.setState({
            modal: {
                ...this.state.modal,
                roleTarget: { ...this.state.modal.roleTarget, loading: true }
            }
        });
        var roleTargets = distinct(
            this.state.formatRoleTargets.map(frt => ((frt.isCalculated = true), frt)).concat(this.addOriginRoleTargets()),
            rt => `${rt.role}${rt.targetType}${rt.targetId || CONSTS.EmptyGuid}`
        );
        this.getTargetCount(roleTargets, this.onModalButtonClick.bind(this));
    }
    renderSelectRoleTargetModalFooter() {
        return (
            <div>
                <Button
                    type="primary"
                    disabled={this.state.modal.roleTarget.disabled}
                    loading={this.state.modal.roleTarget.loading}
                    onClick={() => {
                        if (this.state.disablePublish) this.setState({ disablePublish: false })
                        this.continue = false;
                        this.getTargetCountFromModal();
                    }}
                >
                    {fmtMsg({ id: GSAdminLocale.NotificationCreateSubmit })}
                </Button>
                <Button
                    onClick={() => {
                        if (this.continue) {
                            this.continue = false;
                            this.getTargetCountFromModal();
                        } else {
                            this.onModalButtonClick();
                        }
                    }}
                >
                    {fmtMsg(GLLocale.Cancel)}
                </Button>
            </div>
        );
    }
    renderSelectRoleTargetModal() {
        const { region, school, campus, schoolClass } = this.state.showTargetSelect;
        const regionIdsAsAdmin = this.state.regionIdsAsRegionAdmin;
        let regionsView = region.resources;
        if (!this.isSuperSurveyUser()) {
            regionsView = regionsView.filter(resource => regionIdsAsAdmin.indexOf(resource.id) >= 0)
        }
        regionsView = regionsView.map(resource => {
            resource.name = resource.englishName;
            return resource;
        });
        return (
            <Modal
                className="poll-modal-target"
                title={fmtMsg({
                    id: SurveyLocale.SurveyCreateSelectRoles
                })}
                visible={this.state.modal.roleTarget.visible}
                maskClosable={false}
                destroyOnClose
                onCancel={this.onModalButtonClick.bind(this)}
                footer={this.renderSelectRoleTargetModalFooter()}
                width="396px"
            >
                <div>
                    <p>
                        <label htmlFor="Role" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRole
                            })}
                        </label>
                    </p>
                    <Select className="role-target-select" value={this.state.selectRole.toString()} onChange={this.onRolesSelect.bind(this)}>
                        {this.renderRoleOptions()}
                    </Select>
                </div>
                <div>
                    <p>
                        <label htmlFor="Target Type" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateTargetType
                            })}
                        </label>
                    </p>
                    <Select className="role-target-select" value={this.state.selectTargetType.toString()} onChange={this.onTargetTypesSelect.bind(this)}>
                        {/* {this.removeRegionByTrainer(this.renderTargetTypeOptions())} */}
                        {this.renderTargetTypeOptions()}
                    </Select>
                </div>
                <div>
                    {region.selected && (
                        <p>
                            <label htmlFor="Target" className="ant-form-item-required">
                                {fmtMsg({
                                    id: GSAdminLocale.NotificationCreateTarget
                                })}
                            </label>
                        </p>
                    )}
                    {this.renderTargetOptions(region.selected, region.selectedId, "Select Region", regionsView, region.loaded, SurveyTargetTypes.Region)}
                    {this.renderTargetOptions(school.selected, school.selectedId, "Select School", school.resources, school.loaded, SurveyTargetTypes.School)}
                    {this.renderTargetOptions(campus.selected, campus.selectedId, "Select Campus", campus.resources, campus.loaded, SurveyTargetTypes.Campus)}
                    {this.renderTargetOptions(
                        schoolClass.selected,
                        schoolClass.selectedId,
                        "Select School Class",
                        schoolClass.resources,
                        schoolClass.loaded,
                        SurveyTargetTypes.SchoolClass
                    )}
                </div>
            </Modal>
        );
    }
    onUserSelectChange(searchUser) {
        this.setState({
            searchUser,
            probablyUsers: [],
            userFetching: false
        });
    }

    onUserSelectSearch(searchUser) {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ probablyUsers: [], userFetching: true });
        const regionInfo = this.getRegionAndTenantInfo();
        this.userService.getSubordinates({
            userName: searchUser,
            email: searchUser,
            includeParent: false,
            includeSameRole: true,
            regionId: regionInfo.regionId,
            tenantId: regionInfo.tenantId
        }).then(d => {
            if (fetchId !== this.lastFetchId) return;
            const probablyUsers = d
                .map(user => ({
                    id: user.id,
                    name: user.name,
                    email: user.email
                }))
                .filter(user => this.loggedInUserId != user.id && !this.state.searchUser.some(su => su.key === user.id));
            this.setState({
                probablyUsers,
                userFetching: false
            });
        });
    }

    onShareUserSelectChange(searchTempShareUser) {
        this.setState({
            searchTempShareUser,
            probablyShareUsers: [],
            userShareFetching: false
        });
    }

    getRegionAndTenantInfo() {
        const regionInfo = this.accessibleRegions.find(x => x.id == this.state.selectedRegion);
        let regionId = CONSTS.EmptyGuid;
        let tenantId = null;
        if (regionInfo) {
            regionId = regionInfo.id;
            tenantId = regionInfo.tenantId;
        }

        return { regionId, tenantId };
    }

    onShareUserSelectSearch(searchShareUser) {
        this.lastShareFetchId += 1;
        const fetchId = this.lastShareFetchId;
        this.setState({ probablyShareUsers: [], userShareFetching: true });
        const regionInfo = this.getRegionAndTenantInfo();
        this.userService.getSubordinates({
            userName: searchShareUser, email: searchShareUser, includeSameRole: true, regionId: regionInfo.regionId,
            tenantId: regionInfo.tenantId
        }).then(d => {
            if (fetchId !== this.lastShareFetchId) return;
            const probablyShareUsers = d
                .map(user => ({
                    id: user.id,
                    name: user.name,
                    email: user.email
                }))
                .filter(user => this.loggedInUserId != user.id && !this.state.searchShareUser.some(su => su.key === user.id));
            this.setState({
                probablyShareUsers,
                userShareFetching: false
            });
        });
    }
    renderSelectUserModal() {
        const hide = () =>
            this.setState({
                modal: {
                    ...this.state.modal,
                    user: { ...this.state.modal.user, visible: false }
                },
                searchUser: [],
                probablyUsers: [],
                userFetching: false,
                selectedRegion: this.accessibleRegions.length > 0 ? this.accessibleRegions[0].id : null
            });
        const { userFetching, probablyUsers, searchUser, selectedRegion } = this.state;
        const fmtEmail = ({ name, email }) => `${name} ${email ? `(${email})` : ""}`;
        return (
            <Modal
                title={fmtMsg({
                    id: GSAdminLocale.NotificationCreateSearchName
                })}
                visible={this.state.modal.user.visible}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSubmit })}
                className="notified-user-modal poll-modal"
                maskClosable={false}
                destroyOnClose
                confirmLoading={searchUser.length == 0}
                onOk={() => {
                    hide();
                    if (searchUser.length > 0) {
                        this.setState(
                            {
                                notifyUsers: distinct(
                                    this.state.notifyUsers.concat(
                                        searchUser.map(({ key, label }: { key: string; label: string }) => {
                                            var pos = label.lastIndexOf(" (");
                                            if (pos == -1) {
                                                label = `${label}()`;
                                                pos = label.lastIndexOf(" (");
                                            }
                                            return {
                                                role: SurveyRoles.IndividualUser,
                                                targetType: SurveyTargetTypes.IndividualUser,
                                                targetId: key,
                                                rowKey: key,
                                                name: label.substring(0, pos),
                                                email: label.substr(pos + 1).replace(/[()]/g, "")
                                            };
                                        })
                                    ),
                                    (u: any) => u.rowKey
                                )
                            },
                            this.checkNotifyListCallBack.bind(this)
                        );
                    }
                }}
                onCancel={() => hide()}
            >
                {this.accessibleRegions.length > 1 && <div className="region-section">
                    <p>
                        <label htmlFor="Region" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRegion
                            })}
                        </label>
                    </p>
                    <Select value={selectedRegion} showSearch={true} onChange={this.onRegionChange}
                        filterOption={
                            (input, option) => {
                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                        }>

                        {this.accessibleRegions.map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.englishName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>}

                <Select
                    mode="multiple"
                    labelInValue
                    style={{ width: "100%" }}
                    dropdownClassName={`drop-down ${userFetching ? "loading" : ""}`}
                    value={searchUser}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={userFetching ? <Spin size="small" /> : null}
                    onSearch={d => this.userSelectSearch(d)}
                    onChange={this.onUserSelectChange.bind(this)}
                >
                    {probablyUsers.map(d => (
                        <Select.Option key={d.id} title={fmtEmail(d)}>
                            {fmtEmail(d)}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
        );
    }

    renderShareSelectUserModal() {
        const hide = () => {
            this.creatorSharedUsers = { initValueSaved: false, value: [] };
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchTempShareUser: [...searchShareUser],
                selectedRegion: this.accessibleRegions.length > 0 ? this.accessibleRegions[0].id : null
            });
        }
        const save = () => {
            this.creatorSharedUsers = { initValueSaved: false, value: [] };
            const userIds = this.state.searchTempShareUser.map(shareUser => shareUser.key);
            const surveyId = this.state.surveyModel.id;
            const params = {
                surveyId: surveyId,
                shareUserIds: userIds
            }
            this.setState({
                modal: {
                    ...this.state.modal,
                    sharedUser: {
                        ...this.state.modal.sharedUser,
                        visible: false
                    }
                },
                probablyShareUsers: [],
                userShareFetching: false,
                searchShareUser: [...searchTempShareUser]
            }, () => {
                params.surveyId && this.surveyService.shareSurvey(params);
            });
        }
        const { userShareFetching, probablyShareUsers, searchShareUser, searchTempShareUser, isReadonly, selectedRegion } = this.state;
        if (!this.creatorSharedUsers.initValueSaved && searchTempShareUser.length) {
            this.creatorSharedUsers.value = JSON.parse(JSON.stringify(searchTempShareUser));
        }

        const fmtEmail = ({ name, email }) => `${name} ${email ? `(${email})` : ""}`;
        return (
            <Modal
                title={fmtMsg({
                    id: GSAdminLocale.NotificationCreateSearchName
                })}
                visible={this.state.modal.sharedUser.visible}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSubmit })}
                className="notified-user-modal poll-modal"
                maskClosable={false}
                destroyOnClose
                onOk={() => {
                    save();
                }}
                onCancel={() => hide()}
            >
                {this.accessibleRegions.length > 1 && <div className="region-section">
                    <p>
                        <label htmlFor="Region" className="ant-form-item-required">
                            {fmtMsg({
                                id: GSAdminLocale.NotificationCreateRegion
                            })}
                        </label>
                    </p>
                    <Select value={selectedRegion} showSearch={true} onChange={this.onRegionChange}
                        filterOption={
                            (input, option) => {
                                return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                        }>

                        {this.accessibleRegions.map(d => (
                            <Select.Option key={d.id} value={d.id}>
                                {d.englishName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>}
                <Select
                    mode="multiple"
                    labelInValue
                    style={{ width: "100%" }}
                    dropdownClassName={`drop-down ${userShareFetching ? "loading" : ""}`}
                    value={searchTempShareUser}
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={userShareFetching ? <Spin size="small" /> : null}
                    onSearch={d => this.userShareSelectSearch(d)}
                    onChange={this.onShareUserSelectChange.bind(this)}
                >
                    {probablyShareUsers.map(d => (
                        <Select.Option key={d.id} title={fmtEmail(d)}>
                            {fmtEmail(d)}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>
        );
    }

    renderRecurringModalFooter = (closeFunc: (isSubmit: boolean) => void) => {
        return (
            <div>
                <Button onClick={() => closeFunc(false)}>{fmtMsg(GLLocale.Cancel)}</Button>
                <Button type="primary" onClick={() => closeFunc(true)}>
                    {fmtMsg({ id: GSAdminLocale.NotificationCreateSubmit })}
                </Button>
            </div>
        );
    };

    renderIsRecurringModal = () => {
        const { surveyModel } = this.state;
        const hide = (isSubmit = true) => {
            if (surveyModel.recurringTypeId === SurveyRecurrenceType.Monthly && this.checkDayRange(surveyModel.recurringDay) && isSubmit) {
                // if user doesn't fill the month field
                return;
            }
            if (!this.state.recurrenceError && isSubmit) {
                this.setState({
                    modal: {
                        ...this.state.modal,
                        recurring: { visible: false }
                    }
                });
            } else {
                // reset values of surveyModel recurrence day and type
                surveyModel.recurringTypeId = this.surveyModelCopy.recurringTypeId;
                surveyModel.recurringDay = this.surveyModelCopy.recurringDay;
                if (!surveyModel.recurringTypeId) {
                    const { setFieldsValue } = this.props.form;
                    surveyModel.isRecurring = false;
                    setFieldsValue({ isRecurring: surveyModel.isRecurring });
                }
                this.setState({
                    modal: {
                        ...this.state.modal,
                        recurring: { visible: false }
                    },
                    surveyModel
                });
            }
        };
        // used for both week and month changes
        const onWeeklyRecurrenceChange = e => {
            const { surveyModel } = this.state;
            surveyModel.recurringDay = e.target.value;
            if (surveyModel.recurringTypeId === SurveyRecurrenceType.Monthly) {
                this.checkDayRange(e.target.value);
            } else {
                this.setState({ surveyModel, recurrenceError: false });
            }
        };
        return (
            <Modal
                className="poll-modal poll-modal--recurring"
                title={fmtMsg({
                    id: SurveyLocale.RecurrenceLinkText
                })}
                visible={this.state.modal.recurring.visible}
                maskClosable={false}
                destroyOnClose
                onCancel={() => hide(true)}
                footer={this.renderRecurringModalFooter(hide)}
            >
                <div className="poll-modal--recurring__day">
                    <Radio.Group disabled={this.state.isReadonly} onChange={this.onRecurringTypeChange} value={surveyModel.recurringTypeId}>
                        <Radio value={SurveyRecurrenceType.Weekly}>
                            {fmtMsg({
                                id: SurveyLocale.RecurrenceWeeklyLabel
                            })}
                        </Radio>
                        <div className="poll-modal--recurring__day__week">
                            <Radio.Group
                                disabled={this.state.isReadonly || surveyModel.recurringTypeId != SurveyRecurrenceType.Weekly}
                                onChange={onWeeklyRecurrenceChange}
                                value={surveyModel.recurringTypeId === SurveyRecurrenceType.Weekly ? surveyModel.recurringDay : null}
                            >
                                {Object.values(WeeklyRecurrenceDay)
                                    .filter(day => typeof day === "number")
                                    .map(day => (
                                        <Radio key={day} value={day}>
                                            {fmtMsg({ id: localeDayMap[day] })}
                                        </Radio>
                                    ))}
                            </Radio.Group>
                        </div>
                        <Radio value={SurveyRecurrenceType.Monthly}>
                            {fmtMsg({
                                id: SurveyLocale.RecurrenceMonthlyLabel
                            })}
                        </Radio>
                        <Form.Item
                            validateStatus={this.state.recurrenceError ? "error" : null}
                            help={this.state.recurrenceError ? fmtMsg({ id: SurveyLocale.RecurrenceMonthDayRangeError }) : null}
                            className="poll-modal--recurring__day__month"
                        >
                            <Input
                                disabled={this.state.isReadonly || surveyModel.recurringTypeId == SurveyRecurrenceType.Weekly}
                                onChange={onWeeklyRecurrenceChange}
                                value={surveyModel.recurringTypeId !== SurveyRecurrenceType.Weekly ? surveyModel.recurringDay : null}
                            />
                        </Form.Item>
                    </Radio.Group>
                </div>
            </Modal>
        );
    };

    renderStatsModalFooter = (closeFunc: () => void, submitFunc: () => void) => {
        return (
            <div>
                <Button onClick={closeFunc}>{fmtMsg(GLLocale.Cancel)}</Button>
                <Button type="primary" onClick={submitFunc}>
                    {fmtMsg({ id: GSAdminLocale.NotificationCreateSubmit })}
                </Button>
            </div>
        );
    };

    getReportName = (id: number) => {
        switch (id) {
            case SurveyReportType.SchoolCountReport: return fmtMsg({ id: SurveyLocale.SurveyCreateSchoolClassesReport });
            case SurveyReportType.LicenseHistory: return fmtMsg({ id: SurveyLocale.SurveyCreateLicenseHistoryReport });
            case SurveyReportType.UserStatistics: return fmtMsg({ id: SurveyLocale.SurveyCreateDigitalUserStatistics });
        }
    }

    getFilterName = (id: number) => {
        switch (id) {
            case SurveyFilterDataType.DataByRegionGroup: return fmtMsg({ id: SurveyLocale.SurveyCreateDataByRegionGroup });
            case SurveyFilterDataType.DataByRegion: return fmtMsg({ id: SurveyLocale.SurveyCreateDataByRegion });
        }
    }

    renderStatsModal = () => {
        let props = this.props;
        const { surveyModel, isRegionSpecific, includeStats } = this.state;
        const submit = () => {
            if (surveyModel.reportTypeId && !surveyModel.reportTypeId.length && !surveyModel.statisticsFilterTypeId.length) {
                message.error(fmtMsg({ id: SurveyLocale.SelectOptionMessage }));
                includeStats && this.setState({ includeStats: false });
            } else {
                this.setState({
                    modal: { ...this.state.modal, stats: { visible: false } },
                    includeStats: true
                });
            }
        };
        const hide = () => {
            const { setFieldsValue } = this.props.form;
            if (!this.isRegionSpecificCopy && !surveyModel.reportTypeId.length) {
                setFieldsValue({ includeStatistics: false });
                this.setState({ includeStats: false });
            }
            surveyModel.reportTypeId = this.surveyModelCopy.reportTypeId;
            surveyModel.statisticsFilterTypeId = this.surveyModelCopy.statisticsFilterTypeId;
            this.setState({
                modal: { ...this.state.modal, stats: { visible: false } },
                isRegionSpecific: this.isRegionSpecificCopy,
                surveyModel
            });
        };
        const setStatisticsFilterType = e => {
            if (e.target.checked) {
                surveyModel.statisticsFilterTypeId[0] = this.props.statisticsFilterType && this.props.statisticsFilterType[0].id.toString();
                this.setState({ isRegionSpecific: true, surveyModel });
            } else {
                surveyModel.statisticsFilterTypeId = [];
                this.setState({ isRegionSpecific: false, surveyModel });
            }
        };
        const regionChange = e => {
            surveyModel.statisticsFilterTypeId[0] = e.target.value;
            this.setState({ surveyModel });
        };
        const reportChanged = e => {
            surveyModel.reportTypeId = e;
            this.setState({ surveyModel });
        };
        const hasAdoption = surveyModel.reportTypeId && surveyModel.reportTypeId.findIndex(type => type == SurveyReportType.DigitalAdoptionReport) > -1;
        const reportTypes = props.reportTypes ? hasAdoption ? props.reportTypes : props.reportTypes.filter(type => type.id != SurveyReportType.DigitalAdoptionReport) : [];
        return (
            <Modal
                visible={this.state.modal.stats.visible}
                title={GLGlobal.intl.formatMessage({ id: SurveyLocale.IncludeStatistics })}
                onOk={submit}
                okText={GLGlobal.intl.formatMessage({ id: GSAdminLocale.ButtonSave })}
                onCancel={hide}
                wrapClassName="stats-modal"
                width="596px"
                footer={this.renderStatsModalFooter(hide, submit)}
            >
                <Checkbox onChange={e => setStatisticsFilterType(e)} checked={isRegionSpecific}>
                    {" "}
                    {GLGlobal.intl.formatMessage({ id: SurveyLocale.IsRegionSpecific })}
                </Checkbox>
                <Radio.Group style={{ width: "100%" }} onChange={regionChange} value={surveyModel.statisticsFilterTypeId[0]} className="stats-modal__region">
                    {props.statisticsFilterType.map((item: KeyValueResponseModel, index: number) => {
                        return (
                            <Radio key={index} value={item.id.toString()} disabled={!this.state.isRegionSpecific}>
                                {this.getFilterName(item.id)}
                            </Radio>
                        );
                    })}
                </Radio.Group>
                <Checkbox.Group style={{ width: "100%" }} onChange={reportChanged} value={surveyModel.reportTypeId}>
                    {reportTypes.map((item: KeyValueResponseModel, i) => {
                            return (
                                <Row key={i}>
                                    <Col span={8}>
                                        <Checkbox value={item.id.toString()}>{this.getReportName(item.id)}</Checkbox>
                                    </Col>
                                </Row>
                            );
                        })}
                </Checkbox.Group>
            </Modal>
        );
    };

    setNotifiedFilterUsers(filterKey?) {
        this.setState({
            notifiedFilterUsers: filterKey
                ? this.state.notifiedUsers.filter(u => (u.name && u.name.includes(filterKey)) || (u.email && u.email.includes(filterKey)))
                : this.state.notifiedUsers
        });
    }
    renderUserNotifiedModal() {
        const hide = () =>
            this.setState({
                modal: {
                    ...this.state.modal,
                    userNotified: { visible: false }
                },
                notifiedFilterUsers: this.state.notifiedUsers
            });
        return (
            <Modal
                title={fmtMsg(GSAdminLocale.NotificationCreateCheckUsers)}
                visible={this.state.modal.userNotified.visible}
                className="notified-users-modal poll-modal"
                maskClosable={false}
                destroyOnClose
                onOk={() => hide()}
                onCancel={() => hide()}
                footer={null}
            >
                <Input
                    onChange={e => {
                        this.usersFilter(((e.target.value as string) || "").trim());
                    }}
                />
                <TableWithPerfectScroll
                    loading={!this.state.notifiedFilterUsers.length}
                    dataSource={this.state.notifiedFilterUsers}
                    columns={[
                        {
                            title: fmtMsg(GSAdminLocale.NotificationTextName),
                            dataIndex: "name",
                            width: "40%"
                        },
                        {
                            title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                            dataIndex: "email",
                            width: "60%"
                        }
                    ]}
                    scroll={{ y: 220 }}
                    rowKey="id"
                    pagination={false}
                />
            </Modal>
        );
    }
    removeNotifise(notifise, key, index, target?: SurveyToTargets) {
        var next = [...notifise];
        next.splice(index, 1);
        var state = { [key]: next } as any;
        if (target == SurveyToTargets.Roles) {
            if (next.length == 0) {
                state.notifiedUserCount = 0;
            } else {
                this.getTargetCount(next);
            }
        }
        this.setState(state);
    }
    getRoleTargetColumns() {
        return [
            {
                title: fmtMsg(GSAdminLocale.NotificationTextRoleName),
                dataIndex: "roleName",
                width: "31%"
            },
            {
                title: fmtMsg(GSAdminLocale.NotificationTextTargetTypeName),
                dataIndex: "targetTypeName",
                width: "19%"
            },
            {
                title: fmtMsg(GSAdminLocale.NotificationTextTargetName),
                dataIndex: "targetName",
                width: "41%"
            },
            {
                align: "center",
                width: "9%",
                render: (_, record, context: GridICellTemplateContext) =>
                    this.state.isReadonly ? null : (
                        <Icon
                            type="delete"
                            onClick={() =>
                                Modal.confirm({
                                    title: fmtMsg({
                                        id: GSAdminLocale.NotificationCreateSureDelete
                                    }),
                                    onOk: () => {
                                        if (this.state.disablePublish) this.setState({ disablePublish: false })
                                        this.removeNotifise(this.state.formatRoleTargets, "formatRoleTargets", context.row.index, SurveyToTargets.Roles)
                                    }
                                })
                            }
                        />
                    )
            }
        ];
    }
    getUserColumns() {
        return [
            {
                title: fmtMsg(GSAdminLocale.NotificationTextName),
                dataIndex: "name",
                sorter: true,
                width: "40%"
            },
            {
                title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                dataIndex: "email",
                sorter: true,
                width: "40%"
            },
            {
                align: "center",
                width: "10%",
                render: (_, record, context: GridICellTemplateContext) =>
                    this.state.isReadonly ? null : (
                        <Icon
                            type="delete"
                            onClick={() =>
                                Modal.confirm({
                                    title: fmtMsg({
                                        id: GSAdminLocale.UsersEditDelete
                                    }),
                                    onOk: () => {
                                        if (this.state.disablePublish) this.setState({ disablePublish: false })
                                        this.removeNotifise(this.state.notifyUsers, "notifyUsers", context.row.index)
                                    }
                                })
                            }
                        />
                    )
            }
        ];
    }
    visibleModal(key) {
        if (key === "sharedUser" && !this.state.searchShareUser.some(x => !!x.label)) {
            let promises = [];
            const users = JSON.parse(JSON.stringify(this.state.searchShareUser.map(share => share.key)));

            while (users.length > 0) {
                promises.push(this.userService.getItemsBy({ ids: users.splice(0, 150) }));
            }
            Promise.all(promises).then((d: any[]) => {
                this.setState({
                    searchShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        }),
                    searchTempShareUser: d
                        .reduce((acc, item) => {
                            return acc.concat(item.data);
                        }, [])
                        .map(item => {
                            return {
                                key: item.id,
                                label: `${item.name} (${item.email})`
                            };
                        })
                });
            });
        }

        const modal = { ...this.state.modal };
        modal[key].visible = true;
        this.setState({ modal });
    }
    renderLink(selector, field, visible: string, length) {
        const { form } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        return (
            <LazyPortal selector={selector}>
                <Popover
                    {...alignPop()}
                    visible={this.state[visible]}
                    onVisibleChange={linkVisible => {
                        this.setState({ [visible]: linkVisible } as any);
                        if (!linkVisible) {
                            form.resetFields(["text", "url"]);
                        }
                    }}
                    overlayClassName="home-notification"
                    content={
                        <div>
                            <GLForm
                                className="link-form"
                                form={form}
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.props.form.validateFields(["text", "url"], (err, values) => {
                                        if (!err) {
                                            const origin = form.getFieldValue(field);
                                            form.setFieldsValue({
                                                [field]: `${origin ? `${origin} ` : ""}#[${values.text}](${values.url}) `
                                            });
                                            form.resetFields(["text", "url"]);
                                            this.setState({
                                                [visible]: false
                                            } as any);
                                        }
                                    });
                                }}
                            >
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.NotificationCreateLinkText,
                                    "text",
                                    <Input />,
                                    null,
                                    true,
                                    lengthValider(GSAdminLocale.NotificationCreateTitle, length)
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.NotificationCreateLinkUrl,
                                    "url",
                                    <Input />,
                                    null,
                                    true,
                                    urlValider(fmtMsg(GSAdminLocale.NotificationCreateLinkUrlLegal)).concat(lengthValider(GSAdminLocale.NotificationCreateTitle, length))
                                )}
                                <div>
                                    <Button type="primary" htmlType="submit">
                                        {fmtMsg({ id: GLLocale.Ok })}
                                    </Button>
                                </div>
                            </GLForm>
                        </div>
                    }
                    title={fmtMsg(GSAdminLocale.NotificationCreateLinkTitle)}
                    trigger="click"
                >
                    <Icon type="link" title={fmtMsg(GSAdminLocale.NotificationCreateLink)} />
                </Popover>
            </LazyPortal>
        );
    }

    isRecurringOnChange(e) {
        const { surveyModel } = this.state;
        let modalVisible = false;
        if (e.target.checked) {
            this.surveyModelCopy = JSON.parse(JSON.stringify(this.state.surveyModel));
            surveyModel.isRecurring = true;
            surveyModel.recurringTypeId = SurveyRecurrenceType.Weekly;
            surveyModel.recurringDay = WeeklyRecurrenceDay.Mon;
            modalVisible = true;
        } else {
            surveyModel.isRecurring = false;
            surveyModel.recurringTypeId = null;
            surveyModel.recurringDay = null;
            surveyModel.dueDate = new Date();
        }
        this.setState({ surveyModel, modal: { ...this.state.modal, recurring: { visible: modalVisible } } });
    }

    isRecurringEdit = () => {
        const { surveyModel } = this.state;
        this.surveyModelCopy = JSON.parse(JSON.stringify(this.state.surveyModel));
        if (!surveyModel.recurringDay && (!surveyModel.recurringTypeId || surveyModel.recurringTypeId === SurveyRecurrenceType.Weekly)) {
            surveyModel.recurringTypeId = SurveyRecurrenceType.Weekly;
            surveyModel.recurringDay = WeeklyRecurrenceDay.Mon;
        }
        this.setState({ modal: { ...this.state.modal, recurring: { visible: true } }, surveyModel });
    };

    checkDayRange = value => {
        value = parseInt(value);
        const { recurrenceError, surveyModel } = this.state;
        if (value <= 28 && value >= 1) {
            surveyModel.recurringDay = value;
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ recurringDay: surveyModel.recurringDay });
            this.setState({ surveyModel, recurrenceError: false });
        } else {
            surveyModel.recurringDay = null;
            recurrenceError ? null : this.setState({ recurrenceError: true, surveyModel });
            return true;
        }
    };

    checkSurveyNameUnique = (rule, value, callback) => {
        if (!value || value.length === 0) {
            callback("");
            return;
        }
        this.surveyService.checkIfSurveyNameUnique(this.state.surveyModel.id, value).then(isUnique => {
            if (isUnique) {
                callback();
                return;
            } else {
                callback(fmtMsg({ id: SurveyLocale.UniqueSurveyNameError }));
            }
        });
    };

    disablePreviousDates(current) {
        let today = new Date();
        let disableDate: any = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds());
        disableDate.setDate(disableDate.getDate());
        return current < disableDate;
    }

    onSurveyTypeChange(e) {
        const { surveyModel } = this.state;
        surveyModel.surveyType = e.target.value;
        const { setFieldsValue } = this.props.form;
        setFieldsValue({ surveyType: surveyModel.surveyType });
        if (this.state.disablePublish) this.setState({ disablePublish: false })
        this.setState({
            surveyModel,
            notifyChanged: Number(e.target.value),
            notifyUsers: [],
            notifiedUserCount: 0,
            formatRoleTargets: []
        });
    }

    showSwitchTargetToast = () => {
        const messageId = this.state.surveyModel.surveyType == SurveyToTargets.Roles
            ? GSAdminLocale.NotificationSwitchTypeErrorRole
            : GSAdminLocale.NotificationSwitchTypeErrorUser;
        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: messageId }));
    }

    isRadioButtonEnabled = (type: SurveyToTargets) => {
        const notifyCount = this.state.surveyModel.surveyType == SurveyToTargets.Roles
            ? this.state.notifiedUserCount
            : this.state.notifyUsers.length;
        return (!notifyCount || this.state.surveyModel.surveyType == type);
    }

    onRecurringTypeChange(e) {
        const { surveyModel } = this.state;
        surveyModel.recurringTypeId = e.target.value;
        surveyModel.recurringDay = surveyModel.recurringTypeId === SurveyRecurrenceType.Weekly ? WeeklyRecurrenceDay.Mon : null;
        this.setState({ surveyModel });
    }

    onIncludeStatistics = e => {
        if (e.target.checked) {
            this.surveyModelCopy = JSON.parse(JSON.stringify(this.state.surveyModel));
            this.isRegionSpecificCopy = this.state.isRegionSpecific;
            this.setState({ modal: { ...this.state.modal, stats: { visible: true } } });
        } else {
            const { surveyModel } = this.state;
            surveyModel.reportTypeId = [];
            surveyModel.statisticsFilterTypeId = [];
            this.setState({
                surveyModel,
                isRegionSpecific: false
            });
        }
    };

    onEditStatistics = e => {
        this.surveyModelCopy = JSON.parse(JSON.stringify(this.state.surveyModel));
        this.isRegionSpecificCopy = this.state.isRegionSpecific;
        this.setState({ modal: { ...this.state.modal, stats: { visible: true } } });
    };

    renderUserShareContent(searchShareUser) {
        const { surveyDisplayMode } = this.props;
        let badgeCount =
            surveyDisplayMode === SurveyDisplayMode.Create || !(this.state.isReadonly && searchShareUser.length === 0)
                ? searchShareUser.length
                : 0;
        return (<Badge count={badgeCount} showZero className="pc-qtab__share">
            <a
                href="javascript:void(0)"
                onClick={() => {
                    this.visibleModal("sharedUser");
                }}
            >
                <Button type="primary" icon="share-alt" className="pc-qtab__share-btn" size="default">
                    {fmtMsg({ id: SurveyLocale.ShareLinkText })}
                </Button>
            </a>
        </Badge>
        );
    }

    renderPreviewSurveyQuestions() {
        const { surveyModel, isReadonly } = this.state;
        const { questionsWithError } = this.props;
        return surveyModel.surveyQuestion.map((question, index) => {
            const optionOrMultipleChoType = question.surveyQuestionTypeId === QuestionType.Option || question.surveyQuestionTypeId === QuestionType.MultChoice || question.surveyQuestionTypeId === QuestionType.Score;
            const questionId = question.id ? question.id : question.lid;
            const doesQuestionHaveError = questionsWithError.indexOf(questionId) > -1;
            const optionsIds = Array.isArray(question.surveyQuestionOption) ? question.surveyQuestionOption.map(o => o.id ? o.id : o.lid) : [];
            const marks = question.surveyQuestionTypeId === QuestionType.Score ? createSliderMarks(question.minScore, question.maxScore, MaxSurveySliderStepCount) : {};
            return (
                <Row
                    key={index}
                >
                    <Col span={24}>
                        <Card
                            className={
                                mergeClasses("poll-create__questions__card",
                                    isReadonly && "poll-create__questions__card--readonly",
                                    doesQuestionHaveError && "poll-create__questions__card--error")
                            }
                            title={
                                <Row type="flex" className="poll-create__questions__title">
                                    <Col className="poll-create__questions__title__index">
                                        Q{index + 1}.
                                    </Col>
                                    <Col className="poll-create__questions__title__question">
                                        {question.required && <sup className="questions__required">*</sup>}
                                        {isExitSurvey(question.surveyId) ? `${fmtMsg({ id: question.localeKey })}` : question.question}
                                    </Col>
                                </Row>
                            }
                        >
                            {question.surveyQuestionTypeId === QuestionType.Score && (
                                <>
                                    <Row type="flex" justify="space-between" align="middle">
                                        <Col>
                                            <label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeOptions })}</label>
                                        </Col>
                                        <Col>
                                            <label>{fmtMsg({ id: SurveyLocale.SurveyQuestionTypeScore })}</label>
                                        </Col>
                                        <Col>
                                            {
                                                question.isNaEnabled &&
                                                <label>{fmtMsg({ id: SurveyLocale.SurveyNotApplicableLabel })}</label>
                                            }
                                        </Col>
                                    </Row>
                                    {question.surveyQuestionOption.map(option => {
                                        return (
                                            <Row type="flex" justify="space-between" align="middle">
                                                <Col span={6}>
                                                    <label>{option.option}</label>
                                                </Col>
                                                <Col span={12}>
                                                    <Slider
                                                        marks={marks}
                                                        disabled={true}
                                                        defaultValue={question.minScore}
                                                        min={question.minScore}
                                                        max={question.maxScore}>
                                                    </Slider>
                                                </Col>
                                                <Col>
                                                    {
                                                        question.isNaEnabled && (
                                                            <Checkbox disabled={true}></Checkbox>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </>
                            )}
                            {question.surveyQuestionTypeId === QuestionType.Option && (
                                <Row>
                                    <RadioGroup disabled={isReadonly}>
                                        {
                                            question.surveyQuestionOption.map((opt, key) =>
                                                <Col span={24} key={opt.id}>
                                                    <Radio disabled={true} value={opt.option}>{opt.option}</Radio>
                                                </Col>
                                            )
                                        }
                                    </RadioGroup>
                                </Row>
                            )}
                            {question.surveyQuestionTypeId === QuestionType.MultChoice && (
                                <Checkbox.Group disabled={true}>
                                    {
                                        question.surveyQuestionOption.map((opt, key) => {
                                            return (
                                                <Col span={24} key={opt.id}>
                                                    <Checkbox defaultChecked={false} disabled value={opt.id}>{isExitSurvey(question.surveyId) ? `${fmtMsg({ id: opt.localeKey })}` : opt.option}</Checkbox>
                                                </Col>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            )}
                            {question.surveyQuestionTypeId === QuestionType.Text && (
                                <Input disabled={true} value={fmtMsg({ id: SurveyLocale.SurveyCreateEnterYourAnswer })}></Input>
                            )}
                            {question.surveyQuestionTypeId === QuestionType.Rating && (
                                question.surveyQuestionRatingType === 1 ? <Rate disabled={true} count={question.maxRating} />
                                    : <Rate disabled={true} count={question.maxRating} character={<Icon type="like" />} />
                            )}
                        </Card>
                    </Col>
                </Row>
            )
        })
    }

    renderPreviewSurvey() {
        const hide = () => {
            this.setState({
                modal: {
                    ...this.state.modal,
                    previewSurvey: {
                        ...this.state.modal.previewSurvey,
                        visible: false
                    }
                },
            });
        }

        const { surveyModel } = this.state;

        const cardTitle = (
            <>
                <span>{surveyModel.name}</span> -
                <span className={isPastDate(surveyModel.dueDate) ? "error-color" : "text-muted"}>
                    {DateHelper.formatDateAsIs(surveyModel.dueDate as string)}
                </span>
            </>
        );
        const cardExtra = () => {
            return (
                <Badge showZero count={0}>
                    <Button className="share-survey" size="default" icon="share-alt">
                        {fmtMsg({ id: SurveyLocale.SurveyFillShare })}
                    </Button>
                </Badge>
            );
        };
        const descSurvey = surveyModel.description;

        return (
            <Modal
                title={'Preview Survey'}
                visible={this.state.modal.previewSurvey.visible}
                className={'preview-survey-modal'}
                maskClosable={false}
                destroyOnClose
                onCancel={() => hide()}
                footer={null}
            >
                {surveyModel.name.length > 0 && (
                    <Card title={cardTitle} extra={cardExtra()} className="fs" style={{ margin: "0" }} bodyStyle={{ padding: "24px 0px" }} headStyle={{ padding: "0 16px" }}>
                        {
                            descSurvey &&
                            (<Row className="fs__description fs__description--pad-bottom-10">
                                <Col span={24}>
                                    {descSurvey}
                                </Col>
                            </Row>)
                        }
                    </Card>
                )}
                {this.renderPreviewSurveyQuestions()}
            </Modal>
        )
    }

    confirmDelete() {
        Modal.confirm({
            title: fmtMsg({ id: SurveyLocale.SurveyDeleteConfirmMsg }),
            onOk: () => {
                this.deletePoll();
            }
        });
    }

    confirmClone() {
        Modal.confirm({
            title: fmtMsg({ id: SurveyLocale.PollCloneConfirmMessage }),
            onOk: () => {
                this.clonePoll();
            }
        });
    }

    renderActionButtons = () => {
        const { surveyDisplayMode, setSurveyDisplayMode } = this.props;
        const showButtons = surveyDisplayMode !== SurveyDisplayMode.Readonly;
        const showEditButton = surveyDisplayMode === SurveyDisplayMode.EditReadonly && surveyDisplayMode !== SurveyDisplayMode.Create;
        const showCloneButton = this.state.surveyModel.allowDelete && surveyDisplayMode !== SurveyDisplayMode.Edit && surveyDisplayMode !== SurveyDisplayMode.Create;
        const showDeleteButton = this.state.surveyModel.allowDelete && surveyDisplayMode !== SurveyDisplayMode.Create;
        return (
            showButtons && <div className="pc-qtab__op-container"> <div className="pc-qtab__operation-div">
                {showEditButton && this.renderToolTip(<Icon onClick={() => setSurveyDisplayMode(SurveyDisplayMode.Edit)} type="edit"></Icon>, SurveyLocale.SurveyCommonEdit)}
                {showCloneButton && this.renderToolTip(<Icon onClick={this.confirmClone} type="copy"></Icon>, SurveyLocale.SurveyCommonClone)}
                {showDeleteButton && this.renderToolTip(<Icon onClick={this.confirmDelete} type="delete"></Icon>, SurveyLocale.SurveyCommonDelete)}
            </div>
            </div>
        )
    }

    renderToolTip = (innerContent: ReactNode | string, toolTipMessageId: string) => {
        return <Tooltip title={fmtMsg({ id: toolTipMessageId })}> {innerContent} </Tooltip>
    }

    deletePoll = () => {
        let surveyId = this.state.surveyModel.id;
        if (!surveyId || surveyId === CONSTS.EmptyGuid) {
            return;
        }
        this.surveyService
            .deletePoll({ id: surveyId })
            .then((res: boolean) => {
                if (res) {
                    // show successfully deleted and redirect to poll home
                    message.success(fmtMsg({ id: SurveyLocale.SurveyDeleteSuccessMsg }));
                    this.goToPollsHome(true);
                }
                else {
                    message.success(fmtMsg({ id: SurveyLocale.SurveyDeleteErrorMsg }));
                }
            });
    }

    clonePoll = () => {
        let surveyId = this.state.surveyModel.id;
        if (!surveyId || surveyId === CONSTS.EmptyGuid) {
            return;
        }
        // call api to clone
        this.surveyService
            .duplicatePoll(surveyId)
            .then((res: KeyValue) => {
                // show toast
                this.setReloadPollsTrue();
                this.openNotification(res.id);
            });
    }

    openNotification = (id: string) => {
        const editDuplicate = (e: any) => {
            e.preventDefault();
            if (this.props.match.path === SurveyPathConfig.DetailsFromLanding) {
                this.props.history.push(SurveyPathConfig.DetailsFromLanding.replace(":surveyId", id));
            } else {
                this.props.history.push(SurveyPathConfig.DetailsFromHistory.replace(":surveyId", id));
            }
        }

        const args = {
            message: <Actions
                noHeight
                actions={[
                    <Action
                        className="poll-duplicate-success"
                        textProps={{ underline: true }}
                        key="0"
                        onClick={editDuplicate}
                        progress={100}
                        textLocaleId={SurveyLocale.PollClonedSuccessfully}
                    />
                ]}
            />,
            duration: 5
        }
        notification.open(args);
    };

    addSurveyQuestionOption = (questionType: QuestionType) => {
        if (QuestionType.Option === questionType || QuestionType.MultChoice === questionType) {
            return [{ option: "", lid: this.generateGuid() }, { option: "", lid: this.generateGuid() }];
        } else if (QuestionType.Score === questionType) {
            return [{ option: "", lid: this.generateGuid() }];
        } else {
            return [];
        }
    }

    addQuestion = (questionType: QuestionType) => {
        const { surveyModel } = this.state;
        surveyModel.surveyQuestion.push({
            lid: this.generateGuid(),
            question: null,
            disabled: false,
            required: false,
            surveyQuestionTypeId: questionType,
            minScore: questionType === QuestionType.Score ? 0 : null,
            maxScore: questionType === QuestionType.Score ? 10 : null,
            maxRating: questionType === QuestionType.Rating ? 5 : null,
            surveyQuestionRatingType: 1,
            surveyQuestionOption: this.addSurveyQuestionOption(questionType),
        });
        this.setState({ surveyModel }, () => this.questionTypeDivRef.scrollIntoView());
    };

    toggleButtonVisibility = e => {
        let typeDiv = this.questionTypeDivRef;
        // check if click is outside the questionTypeDivRef
        if (typeDiv && this.state.addButtonExpanded && typeof e.target.dataset.add !== "string") {
            this.setState({ addButtonExpanded: false });
        }
    };

    renderAddNewButton = () => {
        return !this.state.addButtonExpanded ? (
            <Button icon="plus-circle-o" type="primary" onClick={() => this.setState({ addButtonExpanded: true })} data-add="">
                {fmtMsg({ id: SurveyLocale.SurveyCreateSurvey })}
            </Button>
        ) : (
            <div ref={c => (this.questionTypeDivRef = c)} className="poll-create__add-button__type">
                <span className="poll-create__add-button__add">
                    <Button
                        type="primary"
                        icon="plus-circle-o"
                        className="poll-create__add-button__type__button"
                        onClick={() => this.setState({ addButtonExpanded: true })}
                    ></Button>
                </span>
                {this.QuestionTypes.map((type, index) => {
                    return (
                        <Button className="poll-create__add-button__type__button" onClick={() => this.addQuestion(type.type)} key={index} type="primary" icon={type.icon}>
                            {type.text}
                        </Button>
                    );
                })}
            </div>
        );
    };

    questionChanged = (e: any, index: number) => {
        const { surveyModel } = this.state;
        surveyModel.surveyQuestion[index].question = e.target.value;
        this.setState({ surveyModel });
    };

    deleteQuestion = index => {
        const { surveyModel } = this.state;
        surveyModel.surveyQuestion.splice(index, 1);
        this.setState({ surveyModel });
    };

    optionChanged = (e: any, optionIndex: number, questionIndex: number) => {
        const { surveyModel } = this.state;
        surveyModel.surveyQuestion[questionIndex].surveyQuestionOption[optionIndex].option = e.target.value;
        this.setState({ surveyModel });
    };

    deleteOption = (optionIndex: number, questionIndex: number) => {
        const { surveyModel } = this.state;
        if (surveyModel.surveyQuestion[questionIndex].surveyQuestionOption.length > 2) {
            surveyModel.surveyQuestion[questionIndex].surveyQuestionOption.splice(optionIndex, 1);
            this.setState({ surveyModel });
        }
    };

    optionDeleteVisible = (questionIndex: number, optionIndex: number) => {
        if (this.state.isReadonly) return;
        this.setState({ displayDeleteButtonOptionIndex: { questionIndex, optionIndex } });
    };

    addOption = (questionIndex: number) => {
        const { surveyModel } = this.state;
        if (surveyModel.surveyQuestion[questionIndex].surveyQuestionOption.length < MaxSurveyOptionCount) {
            surveyModel.surveyQuestion[questionIndex].surveyQuestionOption.push({ option: "", lid: this.generateGuid() });
            this.setState({ surveyModel });
        }
    };

    questionRequiredChanged = (questionIndex: number, isChecked: boolean) => {
        if (this.state.isReadonly) return;
        const { surveyModel } = this.state;
        surveyModel.surveyQuestion[questionIndex].required = isChecked;
        this.setState({ surveyModel });
    };

    questionValidation = (rule, value, callback, id) => {
        if (!value || !value.trim().length) {
            this.props.addRemoveQuestionsWithError({ id, add: true });
            callback(fmtMsg({ id: SurveyLocale.RequiredQuestionMessage }));
            return;
        }

        const question = this.state.surveyModel.surveyQuestion.find(q => (q.id || q.lid) === id);

        if (question && question.surveyQuestionTypeId === QuestionType.Score && question.maxScore === 0) {
            this.props.addRemoveQuestionsWithError({ id, add: true });
            callback(fmtMsg({ id: SurveyLocale.SurveyMaxScoreMessage }));
            return;
        }

        // remove index from error list
        this.props.addRemoveQuestionsWithError({ id, add: false });
        callback();
    };

    optionValidation = (rule, value, callback, allOptions: SurveyQuestionOptionModel[]) => {
        if (!value || !value.trim().length) {
            callback(fmtMsg({ id: SurveyLocale.SurveyCreateOptionRequired }));
        }
        // if two options are same then show error
        if ((allOptions.filter(v => v.option.trim().toLowerCase() == value.trim().toLowerCase())).length > 1) {
            callback(fmtMsg({ id: SurveyLocale.SurveyCreateTwoOptionsSame }));
        }
        callback();
    };

    generateGuid = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    };

    onMaxValueChange = (question: SurveyQuestionModel, value: number) => {
        const questionState = this.state.surveyModel.surveyQuestion;
        const questions = deepClone(questionState) as SurveyQuestionModel[];
        const questionId = question.id || question.lid;

        const questionModel = questions.find(q => (q.id || q.lid) === questionId);
        questionModel.maxScore = value;

        this.setState({
            surveyModel: {
                ...this.state.surveyModel,
                surveyQuestion: questions
            }
        });
    }

    onMaxValueRatingChange = (question: SurveyQuestionModel, value: number) => {
        const questionState = this.state.surveyModel.surveyQuestion;
        const questions = deepClone(questionState) as SurveyQuestionModel[];
        const questionId = question.id || question.lid;

        const questionModel = questions.find(q => (q.id || q.lid) === questionId);
        questionModel.maxRating = value;

        this.setState({
            surveyModel: {
                ...this.state.surveyModel,
                surveyQuestion: questions
            }
        });
    }

    afterMaxValueChange = (question: SurveyQuestionModel, value: number) => {
        const isQuestionTextInvalid = !question.question || !question.question.trim().length;
        const isMaxScoreInvalid = value === 0;
        let errors: Error[] = null;
        const questionId = question.id || question.lid;

        if (isMaxScoreInvalid) {
            errors = [new Error(fmtMsg({ id: SurveyLocale.SurveyMaxScoreMessage }))];
        }

        if (isQuestionTextInvalid) {
            errors = [new Error(fmtMsg({ id: SurveyLocale.RequiredQuestionMessage }))];
        }

        if (errors) {
            this.props.addRemoveQuestionsWithError({ id: questionId, add: true });
        } else {
            this.props.addRemoveQuestionsWithError({ id: questionId, add: false });
        }

        this.props.form.setFields({
            [questionId]: {
                value: question.question,
                errors
            }
        });
    }

    afterMaxValueRatingChange = (question: SurveyQuestionModel, value: number) => {
        const isQuestionTextInvalid = !question.question || !question.question.trim().length;
        const isMaxRatingInvalid = value === 0;
        let errors: Error[] = null;
        const questionId = question.id || question.lid;

        if (isMaxRatingInvalid) {
            errors = [new Error(fmtMsg({ id: SurveyLocale.SurveyMaxScoreMessage }))];
        }

        if (isQuestionTextInvalid) {
            errors = [new Error(fmtMsg({ id: SurveyLocale.RequiredQuestionMessage }))];
        }

        if (errors) {
            this.props.addRemoveQuestionsWithError({ id: questionId, add: true });
        } else {
            this.props.addRemoveQuestionsWithError({ id: questionId, add: false });
        }

        this.props.form.setFields({
            [questionId]: {
                value: question.question,
                errors
            }
        });
    }

    onNAEnabledChange = (question: SurveyQuestionModel, value: boolean) => {
        const questionId = question.id || question.lid;

        const questionState = this.state.surveyModel.surveyQuestion;
        const questions = deepClone(questionState) as SurveyQuestionModel[];

        const questionModel = questions.find(q => (q.id || q.lid) === questionId);
        questionModel.isNaEnabled = value;

        this.setState({
            surveyModel: {
                ...this.state.surveyModel,
                surveyQuestion: questions
            }
        });
    }

    selectTypeRating = (question: SurveyQuestionModel, value) => {
        const questionState = this.state.surveyModel.surveyQuestion;
        const questions = deepClone(questionState) as SurveyQuestionModel[];
        const questionId = question.id || question.lid;

        const questionModel = questions.find(q => (q.id || q.lid) === questionId);
        questionModel.surveyQuestionRatingType = value;

        this.setState({
            surveyModel: {
                ...this.state.surveyModel,
                surveyQuestion: questions
            }
        });
    }

    renderSurveyQuestions = () => {
        const { surveyModel, displayDeleteButtonIndex, displayDeleteButtonOptionIndex, isReadonly } = this.state;
        const { form, questionsWithError } = this.props;
        const options = {
            formItemProps: { label: null }
        };
        const { renderFormItem } = FormHelper;
        return surveyModel.surveyQuestion.map((question, index) => {
            const optionOrMultipleChoType = question.surveyQuestionTypeId === QuestionType.Option || question.surveyQuestionTypeId === QuestionType.MultChoice || question.surveyQuestionTypeId === QuestionType.Score;
            const questionId = question.id ? question.id : question.lid;
            const doesQuestionHaveError = questionsWithError.indexOf(questionId) > -1;
            const optionsIds = Array.isArray(question.surveyQuestionOption) ? question.surveyQuestionOption.map(o => o.id ? o.id : o.lid) : [];
            return (
                <div ref={c => { this.questionsRef.push({ id: questionId, ref: c, optionsIds }) }}>
                    <Row
                        key={index}
                        onMouseOver={() => {
                            if (this.state.isReadonly) return;
                            this.setState({ displayDeleteButtonIndex: index })
                        }}
                        onMouseLeave={() => {
                            if (this.state.isReadonly) return;
                            this.setState({ displayDeleteButtonIndex: -1 })
                        }}
                    >
                        <Col span={24}>
                            <Card
                                className={mergeClasses("poll-create__questions__card",
                                    isReadonly && "poll-create__questions__card--readonly",
                                    doesQuestionHaveError && "poll-create__questions__card--error")}
                                title={
                                    <Row type="flex" className="poll-create__questions__title">
                                        <Col className="poll-create__questions__title__index">
                                            Q{index + 1}.
                                        </Col>
                                        <Col className="poll-create__questions__title__question">
                                            {renderFormItem(
                                                { ...form, ...options },
                                                SurveyLocale.AddQuestionText,
                                                `${questionId}`,
                                                <TextArea
                                                    maxLength={5000}
                                                    rows={4}
                                                    disabled={isReadonly}
                                                    autosize={true}
                                                    onChange={e => this.questionChanged(e, index)}
                                                ></TextArea>,
                                                isExitSurvey(question.surveyId) ? `${fmtMsg({ id: question.localeKey })}` : question.question,
                                                false,
                                                [
                                                    {
                                                        validator: (rule, value, callback) => this.questionValidation(rule, value, callback, questionId)
                                                    }
                                                ]
                                            )}
                                        </Col>
                                    </Row>
                                }
                                extra={
                                    this.state.isReadonly ? null : (
                                        this.renderToolTip(
                                            <Button
                                                onClick={() => this.deleteQuestion(index)}
                                                icon="delete"
                                                className={mergeClasses(
                                                    "poll-create__questions__delete",
                                                    displayDeleteButtonIndex === index && "poll-create__questions__delete--visible"
                                                )}
                                            ></Button>,
                                            SurveyLocale.SurveyCommonDelete)
                                    )
                                }
                                actions={[
                                    optionOrMultipleChoType &&
                                        question.surveyQuestionOption.length < MaxSurveyOptionCount &&
                                        !isReadonly ? (
                                        <Button icon="plus-circle-o" onClick={() => this.addOption(index)} className="poll-create__questions__add-option">
                                            {fmtMsg({ id: SurveyLocale.SurveyCreateAddOption })}
                                        </Button>
                                    ) : null,
                                    <span
                                        className={mergeClasses(
                                            "poll-create__questions__required",
                                            this.state.isReadonly && "poll-create__questions__required--disabled"
                                        )}
                                    >
                                        <Switch checked={question.required} onChange={isChecked => this.questionRequiredChanged(index, isChecked)} />
                                        <span className="poll-create__questions__required--text">{fmtMsg({ id: SurveyLocale.SurveyCreateRequired })}</span>
                                    </span>
                                ]}
                            >
                                {question.surveyQuestionTypeId === QuestionType.Score && (
                                    <>
                                        <Row type="flex" justify="space-between" align="middle">
                                            <Col>
                                                <label>{fmtMsg({ id: SurveyLocale.SurveyMaxScoreLabel })}:</label>
                                            </Col>
                                            <Col lg={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                                                <Slider
                                                    marks={{ 5: 5, 10: 10, 20: 20, 50: 50, 100: 100 }}
                                                    step={null}
                                                    defaultValue={question.maxScore || 10}
                                                    value={question.maxScore || 10}
                                                    disabled={isReadonly}
                                                    onChange={value => this.onMaxValueChange(question, value as number)}
                                                    onAfterChange={value => this.afterMaxValueChange(question, value as number)}
                                                ></Slider>
                                            </Col>
                                            <Row type="flex" justify="start" gutter={5}>
                                                <Col>
                                                    <label>{fmtMsg({ id: SurveyLocale.SurveyNotApplicableLabel })}</label>
                                                </Col>
                                                <Col>
                                                    <Checkbox
                                                        checked={question.isNaEnabled}
                                                        disabled={isReadonly}
                                                        onChange={e => this.onNAEnabledChange(question, e.target.checked)}></Checkbox>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </>
                                )}
                                {optionOrMultipleChoType &&
                                    question.surveyQuestionOption.map((option, key) => {
                                        return (
                                            <div
                                                key={key}
                                                className={mergeClasses("ant-card-head-wrapper poll-create__questions__option", isReadonly && "poll-create__questions__option--readonly")}
                                                onMouseOver={() => this.optionDeleteVisible(index, key)}
                                                onMouseLeave={() => this.optionDeleteVisible(-1, -1)}
                                            >
                                                <Row className="surveyque">
                                                    <div className="surveyque__sno">{key + 1}.</div>
                                                    <div className={mergeClasses("surveyque__optwrap",
                                                        question.surveyQuestionOption.length === 2 ? "surveyque__optwrap--reducewidth" : "surveyque__optwrap--fullwidth")}>
                                                        {question.surveyQuestionTypeId === QuestionType.Option && <Radio className="surveyque__opt" checked disabled></Radio>}
                                                        {question.surveyQuestionTypeId === QuestionType.MultChoice && <Checkbox className="surveyque__opt" checked disabled></Checkbox>}
                                                        {renderFormItem(
                                                            { ...form, ...options },
                                                            SurveyLocale.AddQuestionText,
                                                            `${option.id ? option.id : option.lid}`,
                                                            <Input
                                                                maxLength={500}
                                                                onChange={e => this.optionChanged(e, key, index)}
                                                                disabled={this.state.isReadonly}
                                                            />,
                                                            isExitSurvey(question.surveyId) ? `${fmtMsg({ id: option.localeKey })}` : option.option,
                                                            false,
                                                            [
                                                                {
                                                                    validator: (rule, value, callback) => this.optionValidation(rule, value, callback, question.surveyQuestionOption)
                                                                }
                                                            ]
                                                        )}
                                                    </div>
                                                </Row>
                                                <div className="ant-card-extra">
                                                    {this.state.isReadonly ? null : question.surveyQuestionOption.length > 2 ? (
                                                        this.renderToolTip(
                                                            <Button
                                                                onClick={() => this.deleteOption(key, index)}
                                                                icon="delete"
                                                                className={mergeClasses(
                                                                    "poll-create__questions__delete",
                                                                    displayDeleteButtonOptionIndex.questionIndex === index &&
                                                                    displayDeleteButtonOptionIndex.optionIndex === key &&
                                                                    "poll-create__questions__delete--visible"
                                                                )}
                                                            ></Button>,
                                                            SurveyLocale.SurveyCommonDelete)
                                                    ) : null}
                                                </div>
                                            </div>
                                        );
                                    })}
                                {question.surveyQuestionTypeId === QuestionType.Rating && (
                                    <div className={mergeClasses("poll-create__questions__rating", isReadonly && "poll-create__questions__rating--readonly")}>
                                        <Row type="flex" justify="space-between" align="middle">
                                            <Col>
                                                <label>Select type:</label>
                                            </Col>
                                            <Col>
                                                <Radio.Group disabled={isReadonly} onChange={e => this.selectTypeRating(question, e.target.value)} value={question.surveyQuestionRatingType}>
                                                    <Radio value={1}>
                                                        <Rate disabled={true} count={question.maxRating} />
                                                    </Radio>
                                                    <Radio value={2}>
                                                        <Rate disabled={true} character={<Icon type="like" />} count={question.maxRating} />
                                                    </Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row type="flex" justify="space-between" align="middle">
                                            <Col>
                                                <label>Select max value:</label>
                                            </Col>
                                            <Col span={12}>
                                                <Slider
                                                    marks={{ 3: 3, 5: 5, 10: 10 }}
                                                    min={3}
                                                    max={10}
                                                    step={null}
                                                    defaultValue={question.maxRating || 5}
                                                    value={question.maxRating || 5}
                                                    disabled={isReadonly}
                                                    onChange={value => this.onMaxValueRatingChange(question, value as number)}
                                                    onAfterChange={value => this.afterMaxValueRatingChange(question, value as number)}
                                                ></Slider>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                {question.surveyQuestionTypeId === QuestionType.Text && (
                                    <div className={mergeClasses("poll-create__questions__text", isReadonly && "poll-create__questions__text--readonly")}>
                                        <Input disabled={true} value={fmtMsg({ id: SurveyLocale.SurveyCreateEnterYourAnswer })}></Input>
                                    </div>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        });
    };

    renderSelectedStatstics = () => {
        const { surveyModel } = this.state;
        let toReturn = [];
        if (surveyModel.statisticsFilterTypeId.length) {
            surveyModel.statisticsFilterTypeId.forEach(filterId => {
                this.props.statisticsFilterType.forEach(type => {
                    if (type.id.toString() === filterId) {
                        toReturn.push(this.getFilterName(type.id));
                    }
                });
            });
        }
        if (surveyModel.reportTypeId && surveyModel.reportTypeId.length) {
            surveyModel.reportTypeId.forEach(filterId => {
                this.props.reportTypes.forEach(type => {
                    if (type.id.toString() === filterId) {
                        toReturn.push(this.getReportName(type.id));
                    }
                });
            });
        }
        return toReturn.length
            ? toReturn.map((item, index) => {
                if (index < toReturn.length - 1) {
                    return (
                        <span key={index}>
                            {item} <span className="poll-create__pipe">|</span>{" "}
                        </span>
                    );
                }
                return <span key={index}>{item}</span>;
            })
            : null;
    };

    renderRecurringInfo = () => {
        const { surveyModel } = this.state;
        const id = surveyModel.recurringTypeId === SurveyRecurrenceType.Weekly ? SurveyLocale.RecurrenceWeeklyLabel : SurveyLocale.RecurrenceMonthlyLabel;
        if (surveyModel.recurringTypeId) {
            return (
                <span className="">
                    {fmtMsg({ id })}
                    <span className="poll-create__pipe"> | </span>
                    {surveyModel.recurringTypeId === SurveyRecurrenceType.Weekly ? fmtMsg({ id: localeFullDayMap[surveyModel.recurringDay] }) : surveyModel.recurringDay}
                </span>
            );
        }
    };

    onCancel = () => {
        const { setSurveyDisplayMode, surveyDisplayMode } = this.props;
        if (surveyDisplayMode === SurveyDisplayMode.Edit) {
            setSurveyDisplayMode(SurveyDisplayMode.EditReadonly);
        }
        else {
            this.goToPollsHome();
        }
    }

    renderResponses = () => {
        return (<>
            <Row>
                <ResponseTab />
            </Row>
            <Row type="flex" justify="end">
                <Col className="pc-rtab">
                    <Button className="pc-rtab__cancel" onClick={this.onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                </Col>
            </Row>
        </>)
    };

    private getSurveyParticipants(params: Object): Promise<any> {
        return new Promise((resolve, reject) => {
            const service = this.surveyService.getSurveyParticipants.bind(this.surveyService);
            service(this.props.match.params.surveyId, params)
                .then((res) => {
                    resolve(res);
                })
                .catch(reject);
        });
    }

    renderQuestions() {
        const { form, surveyDisplayMode } = this.props;
        const { surveyModel, searchShareUser, disablePublish, isReadonly } = this.state;
        const renderFormItem = FormHelper.renderFormItem;
        const notifyRole = this.state.notifyChanged == SurveyToTargets.Roles;
        let notifyCount = 0;
        const isPaging = surveyModel.isPaging
                        && surveyModel.surveyStatus == SurveyStatus.Published
                        && (this.props.match.path === SurveyPathConfig.DetailsFromLanding || this.props.match.path === SurveyPathConfig.DetailsFromHistory);
        if (isPaging) {
            notifyCount = this.state.notifiedUserCount;
        }
        else {
            notifyCount = notifyRole ? this.state.notifiedUserCount : this.state.notifyUsers.length;
        }
        const isEn = GLGlobal.store.getState().intl.langLoaded === "en";
        const itemLayout = {
            labelCol: { className: "pc-qtab__name-label" },
            wrapperCol: { span: 24 }
        };
        const Panel = Collapse.Panel;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" }
        };
        const dateFormat: string = LanguageDateFormat[GLGlobal.intl.locale];
        const userNotifyBadge = <Badge count={notifyCount} style={{ backgroundColor: "#52c41a" }} showZero overflowCount={99999999} />;
        const countOfQuestions = surveyModel.surveyQuestion.length;
        return (
            <div className="pc-qtab">
                <GLForm form={form} onSubmit={e => this.onSubmit(e, SurveyStatus.Published)}>
                    <div className="pc-qtab__head">
                        <Row type="flex" justify="space-between" gutter={15}>
                            <Col className="pc-qtab__name-wrapper" xs={24} lg={14}>
                                <div className="pc-qtab__name-control" ref={c => this.surveyTitleRef = c}>
                                    {renderFormItem({
                                        decoratorOptions: { validateTrigger: "onBlur" },
                                        form,
                                        localeId: SurveyLocale.SurveyNameLabel,
                                        fieldName: "name",
                                        formControl: <Input disabled={isReadonly} placeholder={fmtMsg({ id: SurveyLocale.SurveyNameLabel })} />,
                                        initialValue: surveyModel.name,
                                        required: true,
                                        rules: [
                                            { max: 100 },
                                            {
                                                validator: this.checkSurveyNameUnique
                                            }
                                        ],
                                        formItemLayout: itemLayout
                                    })}
                                </div>
                            </Col>
                            <Col xs={24} lg={10} className="pc-qtab__OpAndShare">
                                {countOfQuestions > 0 && (
                                    <Button type="primary" className="preview-survey-btn" onClick={() => this.visibleModal('previewSurvey')}>Preview Survey</Button>
                                )}
                                {surveyModel.id != null && !isExitSurvey(surveyModel.id) && this.renderUserShareContent(searchShareUser)}
                            </Col>
                        </Row>
                    </div>
                    {this.renderActionButtons()}
                    <div className="pc-qtab__head pc-qtab--margin-bottom-15">
                        <Row type="flex" justify="space-between" gutter={15}>
                            <Col className="pc-qtab__description-wrapper" xs={24} lg={17}>
                                <div className="pc-qtab__description-control">
                                    {isReadonly && surveyModel.description ?
                                        <div className="pc-qtab__description-readonly">
                                            {surveyModel.description}
                                        </div> :
                                        renderFormItem({
                                            form,
                                            localeId: SurveyLocale.SurveyDescriptionLabel,
                                            fieldName: "description",
                                            formControl: (<TextArea placeholder={GLGlobal.intl.formatMessage({ id: SurveyLocale.SurveyDescriptionLabel })} rows={4} disabled={isReadonly} ></TextArea>),
                                            initialValue: surveyModel.description,
                                            // Upto until implementation of this feature,
                                            // gl-commonui introduces default rule of max 50 characters if rules are not provided, hence pass empty rules.
                                            rules: [],
                                            required: false,
                                            formItemLayout: {
                                                wrapperCol: { span: 24 }
                                            }
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="left" bordered={true} className="pc-qtab__collapse">
                        <Panel header="Settings" key="1">
                            <div className="pc-settings" ref={c => this.checkNotifyListRef = c}>
                                <Row type="flex" gutter={20}>
                                    <Col lg={12} className="pc-settings__left">
                                        <Row type="flex">
                                            <Col className="pc-settings__scope">
                                                {renderFormItem(
                                                    form,
                                                    GSAdminLocale.NotificationCreateNotificationType,
                                                    "surveyType",
                                                    <>
                                                        <Radio.Group disabled={isReadonly} onChange={this.onSurveyTypeChange} value={surveyModel.surveyType}>
                                                            <PreventClickingMask onMaskClick={this.showSwitchTargetToast}
                                                                allowClicking={this.isRadioButtonEnabled(SurveyToTargets.Roles)}>
                                                                <Radio value={SurveyToTargets.Roles}>
                                                                    {fmtMsg({
                                                                        id: GSAdminLocale.NotificationCreateRoles
                                                                    })}
                                                                </Radio>
                                                            </PreventClickingMask>
                                                            <PreventClickingMask onMaskClick={this.showSwitchTargetToast}
                                                                allowClicking={this.isRadioButtonEnabled(SurveyToTargets.Users)}>
                                                                <Radio value={SurveyToTargets.Users}>
                                                                    {fmtMsg({
                                                                        id: GSAdminLocale.NotificationCreateUsers
                                                                    })}
                                                                </Radio>
                                                            </PreventClickingMask>
                                                        </Radio.Group>
                                                    </>,
                                                    surveyModel.surveyType,
                                                    true,
                                                    [],
                                                    {
                                                        labelCol: { span: 4 },
                                                        wrapperCol: { span: 20 }
                                                    }
                                                )}
                                            </Col>
                                            <Col>
                                                <Actions
                                                    noHeight
                                                    actions={[
                                                        isReadonly ? null : notifyRole ? (
                                                            <Action
                                                                materialIcon="add"
                                                                onClick={() => this.visibleModal("roleTarget")}
                                                                textLocaleId={GSAdminLocale.NotificationCreateNotifyAddRole}
                                                            />
                                                        ) : (
                                                            <Action
                                                                materialIcon="add"
                                                                onClick={() => this.visibleModal("user")}
                                                                textLocaleId={GSAdminLocale.NotificationCreateNotifyAddUser}
                                                            />
                                                        )
                                                    ]}
                                                />
                                            </Col>
                                        </Row>
                                        <div className="pc-settings__left__grid">
                                            {notifyRole ? (
                                                <Grid
                                                    itemsSource={this.state.formatRoleTargets}
                                                    pagination={false}
                                                >
                                                    {this.getRoleTargetColumns().map((column, index) => {
                                                        return (<Column
                                                            key={index.toString()}
                                                            header={column.title}
                                                            binding={column.dataIndex}
                                                            render={column.render}
                                                            align={column.align}
                                                        />);
                                                    })}
                                                </Grid>
                                            ) : null}
                                            {this.state.notifyChanged == SurveyToTargets.Users ? (
                                                isPaging ?
                                                    <Grid
                                                        serviceFunction={this.getSurveyParticipants.bind(this)}
                                                        serviceFormatData={({ data }) => data.map(x => JSON.parse(x.surveyTargetName))}
                                                        allowSorting={false}
                                                    >
                                                        {this.getUserColumns().map((column, index) => {
                                                            return (<Column
                                                                key={index.toString()}
                                                                header={column.title}
                                                                binding={column.dataIndex}
                                                                render={column.render}
                                                                align={column.align}
                                                            />);
                                                        })}
                                                    </Grid>
                                                    : <Grid
                                                        itemsSource={this.state.notifyUsers}
                                                        pagination={true}
                                                    >
                                                        {this.getUserColumns().map((column, index) => {
                                                            return (<Column
                                                                key={index.toString()}
                                                                header={column.title}
                                                                binding={column.dataIndex}
                                                                render={column.render}
                                                                align={column.align}
                                                            />);
                                                        })}
                                                    </Grid>
                                            ) : null}
                                        </div>
                                        <div className="poll-create__settings__notification-count">
                                            <div className="notify-list has-error">
                                                <span>
                                                    {this.state.checkNotifyList ? (
                                                        <span className="ant-form-explain">
                                                            {fmtMsg({
                                                                id: GSAdminLocale.NotificationCreateNotifyValidate
                                                            })}
                                                        </span>
                                                    ) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pc-settings__notify">
                                            <Icon type="notification" />{" "}
                                            {notifyCount === 0 || !notifyRole ? (
                                                userNotifyBadge
                                            ) : (
                                                <a href="javascript:void(0)" onClick={() => this.visibleModal("userNotified")}>
                                                    {userNotifyBadge}
                                                </a>
                                            )}
                                            {isEn && ` user${notifyCount <= 1 ? "" : "s"}`}{" "}
                                            {fmtMsg({
                                                id: GSAdminLocale.NotificationReviewNotified
                                            })}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="pc-statistic">
                                            <Row type="flex">
                                                <Col>
                                                    {renderFormItem(
                                                        { ...form, ...options },
                                                        SurveyLocale.IncludeStatistics,
                                                        "includeStatistics",
                                                        <Checkbox onChange={e => this.onIncludeStatistics(e)} disabled={isReadonly}>
                                                            {GLGlobal.intl.formatMessage({ id: SurveyLocale.IncludeStatistics })}
                                                        </Checkbox>,
                                                        this.state.includeStats
                                                    )}
                                                </Col>
                                                <Col className="pc-statistic__edit">
                                                    {this.props.form.getFieldsValue(["includeStatistics"]).includeStatistics &&
                                                        surveyDisplayMode !== SurveyDisplayMode.Readonly && (
                                                            <span>
                                                                {!isReadonly && <Icon type="edit" onClick={this.onEditStatistics}></Icon>}
                                                            </span>
                                                        )}
                                                </Col>
                                            </Row>
                                            <div className="pc-statistic__value">{this.renderSelectedStatstics()}</div>
                                        </div>
                                        <div className="pc-statistic">
                                            <Row type="flex">
                                                <Col>
                                                    {renderFormItem(
                                                        {
                                                            ...form,
                                                            formItemProps: { label: "" },
                                                            decoratorOptions: { valuePropName: "checked" },
                                                            initialValue: surveyModel.isRecurring,
                                                            className: ""
                                                        },
                                                        SurveyLocale.IsRecurringLabel,
                                                        "isRecurring",
                                                        <Checkbox disabled={isReadonly} onChange={this.isRecurringOnChange}>
                                                            {fmtMsg({ id: SurveyLocale.IsRecurringLabel })}
                                                        </Checkbox>,
                                                        surveyModel.isRecurring,
                                                        true
                                                    )}
                                                </Col>
                                                <Col className="pc-statistic__edit">
                                                    {surveyModel.isRecurring && surveyDisplayMode !== SurveyDisplayMode.Readonly && (
                                                        <span>
                                                            {!isReadonly && <Icon type="edit" onClick={this.isRecurringEdit}></Icon>}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                            <div className="pc-statistic__value">{this.renderRecurringInfo()}</div>
                                        </div>
                                        <div ref={c => this.dueDateRef = c}>

                                            {!surveyModel.isRecurring && !(surveyModel.surveyStatus == SurveyStatus.Published && !surveyModel.dueDate) &&
                                                renderFormItem(
                                                    {
                                                        ...form,
                                                        formItemProps: { className: mergeClasses("pc-statistic__due-date", isReadonly && "pc-statistic__due-date--readonly") },
                                                        initialValue: surveyModel.id ? moment(new Date(surveyModel.dueDate), "MM-DD-YYYY") : null,
                                                    },
                                                    SurveyLocale.DueDateLabel,
                                                    "dueDate",
                                                    <DatePicker
                                                        format={dateFormat}
                                                        disabled={isReadonly}
                                                        disabledDate={this.disablePreviousDates.bind(this)}
                                                        {...alignPop({ type: "DatePicker" })}
                                                        getCalendarContainer={triggerNode => document.body}
                                                    />,
                                                    null,
                                                    true
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                    <Collapse defaultActiveKey={["2"]} expandIconPosition="left" bordered={true} className={mergeClasses("pc-qtab__collapse", !countOfQuestions && "pc-qtab--collapse-uniform")}>
                        <Panel header={fmtMsg({ id: SurveyLocale.SurveyCreateQuestion })} key="2" className="poll-create__settings">
                            <Row>
                                <Col span={24} className="poll-create__questions">
                                    {surveyModel.surveyQuestion.length === 0 && isReadonly ? <Empty></Empty> : this.renderSurveyQuestions()}
                                </Col>
                            </Row>
                            {isReadonly ? null
                                : <Row>
                                    <Col span={24} className="poll-create__add-button">
                                        {this.renderAddNewButton()}
                                    </Col>
                                </Row>
                            }
                        </Panel>
                    </Collapse>
                    <Row type="flex" justify="end">
                        <Col className="poll-create__submit">
                            <Button onClick={this.onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                            {isReadonly ? null : (
                                <>
                                    {surveyModel.surveyStatus === SurveyStatus.Draft && (
                                        <Button onClick={() => this.onSubmit(null, SurveyStatus.Draft)} disabled={disablePublish}>{fmtMsg({ id: SurveyLocale.SurveyCreateSaveDraft })}</Button>
                                    )}
                                    <Button type="primary" htmlType="submit" disabled={disablePublish}>
                                        {fmtMsg({ id: GSAdminLocale.NotificationReviewPublish })}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </GLForm>
                {this.renderSelectRoleTargetModal()}
                {this.renderUserNotifiedModal()}
                {this.renderSelectUserModal()}
                {this.renderShareSelectUserModal()}
                {this.renderIsRecurringModal()}
                {this.renderStatsModal()}
                {this.renderPreviewSurvey()}
            </div>
        );
    }

    onTabChange(activeKey: string) {
        this.activeTab = activeKey;

        switch (activeKey) {
            case "questions":
                if (!this.props.reportTypes.length) {
                    this.props.getAllReportType();
                }
                if (!this.props.statisticsFilterType.length) {
                    this.props.getAllStatisticsFilterType();
                }
                if ((this.props.match.path === SurveyPathConfig.DetailsFromLanding || this.props.match.path === SurveyPathConfig.DetailsFromHistory)
                    && !this.state.surveyModel.id) {
                    this.getSurveyDetail(this.props.match.params.surveyId);
                }
                break;

            default:
                break;
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { surveyModel } = this.state;
        const { match } = this.props;
        return (
            <Container>
                <Tabs onChange={this.onTabChange} defaultActiveKey={this.activeTab} className="pc-tab poll-create">
                    <TabPane
                        tab={
                            <span>
                                <Icon type="question-circle" />
                                {fmtMsg({ id: SurveyLocale.SurveyCreateQuestion })}
                            </span>
                        }
                        key="questions"
                    >
                        {this.state.loading ? <Loading visible={true} /> : this.renderQuestions()}
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icon type="unordered-list"></Icon>
                                {fmtMsg({ id: SurveyLocale.SurveyCommonResponses })}
                            </span>
                        }
                        key="responses"
                        disabled={surveyModel.surveyStatus === SurveyStatus.Draft
                            || (match.path !== SurveyPathConfig.DetailsFromLanding && match.path !== SurveyPathConfig.DetailsFromHistory)}
                    >
                        {this.state.loading ? <Loading visible={true} /> : this.renderResponses()}
                    </TabPane>
                </Tabs>
            </Container>
        );
    }
}
const userHasRole = role => (GLGlobal.loginInfo().profile || { roles: [] }).roles.some(r => r == role);
const hasOneRole = role => (GLGlobal.loginInfo().profile || { roles: [] }).roles.length == 1 && userHasRole(role);
