import * as React from "react";
import { FC } from "react";
import { History } from "history";
import { mergeClasses } from "../survey/functions";
import "./grid-column-link.less";
import { useHistory } from "@app/hooks";

interface ColumnLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    url: string;
    /**
     * Due to context issue with Wijmo we can not get history inside Wijmo cell template
     * Currently we don not have latest version of react-router-dom so we can't even use useHistory hook.
     * Maybe we should enhance this component by call the history object from the component itself rather getting through props.
     * 
     * Solved at (9/8/2021): Created custom useHistory hook, although it is not a pure useHistory hook. It only has push function to navigate through app without router context.
     * @deprecated: Now since we have a histoy without being under router context, I am deprecating this `history` props. It is not needed anymore.
     */
    history?: History;
    underline?: boolean;
}

export const ColumnLink: FC<ColumnLinkProps> = (props) => {
    const { url, children, className, underline, ...rest } = props;

    const history = useHistory();

    const onClickLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (event.ctrlKey) {
            window.open(url, "_blank");
            return null;
        }
        history.push({ pathname: url });
    };

    return (
        <a {...rest} className={mergeClasses("wgrid-link", underline && "wgrid-link--underline", className)} href={url} onClick={onClickLink}>
            {children}
        </a>
    );
};
