import React, { Component } from 'react';
import moment from 'moment';
import "../component/progress.less";
import { Link } from 'react-router-dom';
import { SaveCancelBtns } from '@app/components';
import { IChatService, MESSAGE_REFRESH_TIME } from '@app/service/chat';
import { PathConfig } from '@app/config/pathconfig';
import { trimPlainText, isCoachAndSelf } from '@app/util/coach/fun';
import { VideoModal } from '../component/video-modal';
import { CustomDrawer } from '../../component/custom-drawer';
import { ChatBadge } from '@app/components/coach/chat/chat-badge';
import { ChatDrawer } from '@app/components/coach/chat/chat-drawer';
import { IAccountService } from '@app/service/admin/accountservice';
import { VisitationCoachStage, LVAResourceStatus, ServerExceptionCode as ExceptionCode, LVAResourceType } from '@app/util/coach/enum';
import { SchoolLocale, GSAdminLocale, SurveyLocale } from '@app/locales/localeid';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import { Table, Row, Form, Input, Icon, Spin, DatePicker, Progress, Modal, Button } from 'antd-min';
import { fmtMsg, lazyInject, TYPES, extendForm, textareaValider, GSSchoolAction, DataMeasurementEnum, formatBytesByCeil, DateHelper } from '@app/util';
import { GLForm, GLFormComponentProps, FormHelper, ComparisonOperator, MessageHelper, NotificationType, GLUtil, LanguageDateFormat, alignPop, GLGlobal } from 'gl-commonui';
import { IVideoStorageManager, VideoStorageManager } from '@app/util/storage/video-storage-manager';
import { IAttachmentStorageManager, AttachmentStorageManager } from '@app/util/storage/attachment-storage-manager';
import './play-video-modal.less';

interface VideoManagerProps extends GLFormComponentProps {
    visitation: VisitationModel
    callback: (stage) => void
}

interface VideoManagerStates {
    status?: number
    visible?: boolean
    loading?: boolean
    recorder: any
    teacherVideos?: any[]
    visibleModal?: boolean
    messageVisible: boolean
    receiver?: any
    chatBadgeCounts?: any
    playVideoVisible?: boolean
    urlVideo?: string
    nameVideo?: string
}

@GLForm.create()
export class VideoManager extends Component<VideoManagerProps, VideoManagerStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IChatService)
    chatservice: IChatService
    videoStorageManager: IVideoStorageManager;
    attachmentStorageManager: IAttachmentStorageManager;
    flushMessages: any
    containerName = (GLGlobal.processEnv().containers as any).visitation
    videoMadal
    uploadingVideos = []

    uploadCompletedStatus = {
        video: {
            completed: false,
            successFile: {}
        },
        attachment: {
            completed: false,
            successFile: {}
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            recorder: {},
            teacherVideos: [],
            messageVisible: false,
            playVideoVisible: false,
            urlVideo: '',
            nameVideo: '',
        }
        this.videoStorageManager = new VideoStorageManager({
            containerName: this.containerName,
            getMediaSas: () => this.visitationservice.getMediaSas()
        })
        this.attachmentStorageManager = new AttachmentStorageManager({
            containerName: this.containerName,
            getMediaSas: (params) => this.visitationservice.getMediaSas(params)
        })
    }
    componentWillMount() {
        this.setState({ loading: true });
        this.getVideoResources().then(data => {
            this.getTeacherNames(data && data.lvaResourceFormInfos);
            if (data && data.visitation && data.lvaResourceFormInfos) {
                const teacherIds = data.lvaResourceFormInfos.map(v => v.teacherId);
                this.getAllUnReadBadges(teacherIds, this.props.visitation.id);
                this.flushMessages = setInterval(() => this.getAllUnReadBadges(teacherIds, this.props.visitation.id), MESSAGE_REFRESH_TIME);
            }
        });
    }
    componentWillReceiveProps() {
        this.videoMadal = null;
    }
    componentDidMount() {
        this.initVideoManager();
    }
    componentWillUnmount() {
        clearInterval(this.flushMessages);
    }
    initVideoManager() {
        const self = this;
        this.videoStorageManager = new VideoStorageManager({
            containerName: this.containerName,
            getMediaSas: () => this.visitationservice.getMediaSas()
        })
        .onProgress((progress) => {
            self.formatLvaResources(progress, {});
        })
        .onSuccess((success) => {

            self.uploadCompletedStatus.video = {
                completed: true,
                successFile: success
            };
            
            self.confirmCompleted();
        })
        .onError((error) => {
            self.formatLvaResources({}, {});
        })

        this.attachmentStorageManager = new AttachmentStorageManager({
            containerName: this.containerName,
            getMediaSas: (params) => this.visitationservice.getMediaSas(params)
        }).onProgress((progress) => {
            self.formatLvaResources({}, progress);
        }).onSuccess((success) => {
            self.uploadCompletedStatus.attachment = {
                completed: true,
                successFile: success
            };
            self.confirmCompleted();
        }).onError((error) => {
            self.formatLvaResources({}, {});
        })
    }
    getVideoResources() {
        const { visitation: { id }} = this.props;
        return this.visitationservice.getVideoManagementLVAResources({ visitationId: id });
    }
    getTeacherNames(infos) {
        const ids = infos && infos.map(v => v.teacherId);
        !ids.length && this.setState({ 
            loading: false,
            teacherVideos: [] 
        });
        ids.length && this.accountservice.getUsersByPost({ids}).then(data => {
            const formatInfos = infos.map((info, key) => {
                const { lvaResourceInfos, teacherId, lvaResourceFormId } = info;
                const teacher = data && 
                                data.data && 
                                data.data.find(t => t.id == teacherId);
                const name = teacher && 
                             teacher.name;
                if (lvaResourceInfos) {
                    const notShowVideos = lvaResourceInfos.every(lri => !(lri.lvaResourceModels || []).length);
                    const notShowReminder = lvaResourceInfos.some(lri => !!(lri.lvaResourceModels || []).length);
                    return {
                        key,
                        name: name,
                        notShowVideos,
                        notShowReminder,
                        teacherId: teacherId,
                        lvaResourceInfos,
                        lvaResourceFormId: lvaResourceFormId,
                    }
                } else {
                    return {
                        key,
                        name,
                        notShowVideos: true,
                        lvaResourceInfos: [],
                        notShowReminder: false,
                        teacherId: info.teacherId,
                        lvaResourceFormId: lvaResourceFormId,
                    }
                }
            });
            this.setState({
                loading: false,
                teacherVideos: formatInfos 
            });
        });
    }
    showDrawer = (recorder) => {
        this.setState({ 
            visible: true,
            recorder
        });
    }
    onClose = () => {
        const { form } = this.props;
        form.resetFields();
        this.setState({ 
            visible: false,
            messageVisible: false,
        });
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "note").concat(this.lengthValidator(SchoolLocale.MaterialRequestFieldNote, 500));
    }
    disableDates(current) {
        let disableDate = moment().add(-1, 'day').toDate();
        return current < disableDate;
    }
    getAllUnReadBadges(senderIds, visitationId) {
        this.chatservice.getAllUnReadBadges(senderIds, visitationId).then(data => {
            this.setState({
                chatBadgeCounts: data 
            });
        });
    }
    getBadgeCount(id) {
        this.chatservice.getBadgeCount(id).then(data => {
            let counts = this.state.chatBadgeCounts;
            counts[id] = data; 
            this.setState({
                chatBadgeCounts: counts
            });
        });
    }
    renderDrawerContent() {
        const { form } = this.props;
        const { recorder } = this.state;
        const { renderFormItem } = FormHelper;
        const options = {
            formItemProps: { label: null },
        }
        const reminder = fmtMsg({ id: SchoolLocale.VisitationVideoReminderContent }, { name: recorder && recorder.name });
        return <>
                <div className="teacher-name">
                    <span>{fmtMsg({ id: SchoolLocale.VisitationTeachersTitle })}:</span>
                    <span>{recorder.name}</span>
                </div>
                <GLForm form={form}>
                <div className="send-reminder">
                    <div className="due-date">
                        <div>{fmtMsg({ id: SurveyLocale.DueDateLabel })}</div>
                        {renderFormItem(
                            {...form, ...options},
                            SurveyLocale.DueDateLabel,
                            "dueDate",
                            <DatePicker
                                {...alignPop({ type: "DatePicker" })}
                                format={
                                    LanguageDateFormat[GLGlobal.intl.locale]
                                }
                                disabledDate={this.disableDates.bind(this)}
                            />,
                            null,
                            true
                        )}
                    </div>
                    <Row className="note">
                        {renderFormItem(extendForm(form), SchoolLocale.VisitationNoteText, "note", 
                            <Input.TextArea rows={6} />, reminder, true, this.noteValidator(form))}
                    </Row>
                </div>
                <SaveCancelBtns saveTitle={SchoolLocale.VisitationSendText} 
                    onSubmit={() => this.SendReminder()}
                    onCancel={() => this.onClose()}/>
            </GLForm>
        </>;
    }
    formatDate(date, h, m, s, ms) {
        return moment.utc(date.hours(h).minutes(m).seconds(s).milliseconds(ms));
    }
    SendReminder() {
        const { visitation: { id }} = this.props;
        const { recorder: { teacherId }} = this.state;
        const { form } = this.props;
        form.validateFields((error, values) => {
            if (error) return;
            values.note = trimPlainText(values.note);
            values.dueDate = this.formatDate(values.dueDate, 23, 59, 59, 0);
            const params = {
                teacherId,
                visitationId: id,
                ...values
            }
            this.visitationservice.sendUploadVideoReminder(params).then(data => {
                form.resetFields();
                this.setState({ 
                    visible: false,
                });
            });
        });
    }
    onAddVideo(teacherInfo) {
        this.setState({ visibleModal: true });
        this.renderVideoModal(teacherInfo);
    }

    confirmCompleted() {
        const {
            video: { completed: videoCompleted , successFile: videoFile}, 
            attachment: { completed: attachmentCompleted, successFile: attachmentFile }
        } = this.uploadCompletedStatus;

        if (videoCompleted && attachmentCompleted) {
            this.uploadVideosToAdminService(videoFile, attachmentFile)
        }
    }

    uploadVideosToAdminService(videoFile, attachmentFile) {
        const { visitation } = this.props;
        const { teacherVideos } = this.state;
        const { url: videoUrl } = videoFile;
        const { url: attachmentUrl } = attachmentFile;

        const url = videoUrl || attachmentUrl;
        const splits = url && url.split(`${this.containerName}/`);
        const lvaResourceId = splits && splits[1].split("%2F")[0];
        const currentInfo = this.uploadingVideos.find(v => v.lvaResourceModel.id == lvaResourceId);

        const { teacherId, 
            schoolClassResource: { schoolClassId }, 
            lvaResourceModel: { 
                id, 
                name,
                externalLink,
                lvaResourceFormId, 
                associatedInfo
            }
        } = currentInfo;

        const lvaResource = (videoFile && videoFile.url) ? {
                            url: videoUrl,
                            schoolClassId,
                            lvaResourceId: id,
                            lvaResourceFormId,
                            name: videoFile.fileName,
                            visitationId: visitation.id,
                            status: LVAResourceStatus.Completed,
        } : {
                            url: "", // upload link, then video url will be empty
                            schoolClassId,
                            lvaResourceId: id,
                            lvaResourceFormId,
                            name: name,
                            visitationId: visitation.id,
                            status: LVAResourceStatus.Completed,
                            externalLink: externalLink
                        };

        const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;
        const lvaNote = lvaResourceNote && {
            note: lvaResourceNote.note,
            attachmentName: lvaResourceNote.attachmentName,
            attachmentUrl: attachmentUrl
        }
        const params = {
            teacherId,
            lvaResource: lvaResource,
            visitationId: visitation.id,
            note: lvaNote,
        };
        this.visitationservice.uploadVideos(params).then((data) => {
            const index = this.uploadingVideos.findIndex(v => v.lvaResourceModel.id === id);
            this.uploadingVideos.splice(index, 1);
            const videoResource = `${lvaResource.lvaResourceId}/${lvaResource.name}`;
            this.visitationservice.getMediaSas({ readOnly: true })
            .then((data) => {
                const containerUrl = data.split("?")[0];
                const videoUrl = `${containerUrl}${this.containerName}/${videoResource}`;
                const formatResources = teacherVideos.map(v => ({
                    ...v,
                    lvaResourceInfos: v.lvaResourceInfos.map(l => ({
                        ...l,
                        lvaResourceModels: (l.lvaResourceModels || []).map(
                            lvaResourceModel => {
                                if (lvaResourceModel.id === lvaResourceId) {
                                    return {
                                        ...lvaResourceModel,
                                        progress: 100,
                                        url: videoUrl,
                                        status: LVAResourceStatus.Completed
                                    };
                                }
                                return lvaResourceModel;
                            }
                        )
                    }))
                }));
                this.setState({ teacherVideos: formatResources });
            });
        });
    }

    renderProgress(progress, status, format="", showInfo=true) {
        return <div className="status" title={format}>
                <Progress
                    width={30}
                    type="circle"
                    strokeWidth={8}
                    status={status}
                    percent={progress}
                    showInfo={showInfo}
                />
            </div>
    }
    getLVATableCols() {
        const { visitation: { coachId, stage } } = this.props;
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationClasses }),
                dataIndex: "schoolClassResource",
                width: "20%",
                render: (text, recorder, index) => {
                    const { regionId, schoolId, campusId, schoolClassId, schoolClassName } = text;
                    return <div title={schoolClassName} className="class-name">
                        {isCoachAndSelf(GSSchoolAction.ClassLink, coachId) ? 
                            <Link to={{ pathname: GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: schoolClassId }) }} >
                                <div className="class-list">{schoolClassName}</div>
                            </Link> :
                            <div className="class-list">{schoolClassName}</div>
                        }
                    </div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoText }),
                dataIndex: "",
                width: "15%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const title = fmtMsg({id: SchoolLocale.VisitationVideoIsDeleted});
                    let videoName = null;

                    if (isCoachAndSelf(GSSchoolAction.DownloadVideo, coachId)) {
                        if (lvaResourceModel.isDelete) {
                            videoName = (<span title={title}>{lvaResourceModel.name}</span>);
                        } else if (lvaResourceModel.lvaResourceType === LVAResourceType.Video) {
                            videoName = (<a className="video-name" onClick={(e) => this.playVideo(e, lvaResourceModel)}>
                                {lvaResourceModel.name}
                            </a>);
                        } else {
                            videoName = (<a className="video-name" href={lvaResourceModel.externalLink} title={lvaResourceModel.externalLink} target="_blank">{lvaResourceModel.externalLink}</a>);
                        }
                    } else {
                        videoName = (<span>{lvaResourceModel.name}</span>);
                    }

                    return videoName;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoTableNote }),
                dataIndex: "",
                width: "17%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel: { associatedInfo } } = recorder;
                    const note = associatedInfo && 
                                 associatedInfo.lvaResourceNote && 
                                 associatedInfo.lvaResourceNote.note;
                    return <div className="note">{note}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoTableAttachment }),
                dataIndex: "",
                width: "17%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel: { associatedInfo } } = recorder;
                    const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;
                    return <a className="attachment" onClick={(e)=> lvaResourceNote && this.downloadAttachment(lvaResourceNote.resourceId, encodeURI(lvaResourceNote.attachmentName))}>{lvaResourceNote && lvaResourceNote.attachmentName}</a>
                    
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoUploadTime }),
                dataIndex: "",
                width: "13%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const uploadTime = lvaResourceModel && 
                                       lvaResourceModel.uploadTime;
                    const formatUploadTime = DateHelper.toLocalStringFromUTC(moment(uploadTime));
                    return <div className="upload-time">{formatUploadTime}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationVideoSize }),
                dataIndex: "",
                width: "12%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    const size = lvaResourceModel && lvaResourceModel.size;
                    const formatMb = lvaResourceModel.lvaResourceType === LVAResourceType.Video ? formatBytesByCeil(size, DataMeasurementEnum.MB) : "-";
                    return <div className="video-size">{formatMb}</div>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationStatus }),
                dataIndex: "",
                width: "12%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    if (lvaResourceModel) {
                        const { status, progress } = lvaResourceModel;
                        if (status == LVAResourceStatus.Uploading) {
                            return progress == null ? this.renderProgress(100, "exception", fmtMsg({id: SchoolLocale.VisitationVideoUploadFailedText})) :
                                                      this.renderProgress(progress, progress === 100 ? "success" : "active")
                        } else if (status == LVAResourceStatus.Completed) {
                            return this.renderProgress(100, "success", fmtMsg({id: SchoolLocale.VisitationVideoUploadSuccessText}));
                        }
                    }
                }
            },
            {
                title: "",
                dataIndex: "url",
                width: "5%",
                render: (text, recorder, index) => {
                    const { lvaResourceModel } = recorder;
                    if (lvaResourceModel) {
                        const { status, progress } = lvaResourceModel;
                        let disabled = "";
                        const isCompleted = stage === VisitationCoachStage.Completed;
                        if (status == LVAResourceStatus.Uploading) {
                            disabled = progress == null && "disabled";
                        } else if (status == LVAResourceStatus.Completed) {
                            disabled = "disabled";
                        }
                        return <div className={`action ${isCompleted && disabled}`}>
                                {isCoachAndSelf(GSSchoolAction.DeleteVideo, coachId) &&
                                <Icon type="delete" title="delete" onClick={() => this.deleteVideo(recorder) } />}
                        </div>
                    }
                }
            }
        ]
    }
    downloadAttachment(id, name) {
        this.visitationservice.getMediaSas({ readOnly: true, sasFromGlobalAccount: false }).then(data => {
            const splits = data.split("?");
            const suffix = splits[1];
            const container = splits[0];
            const downloadableUrl = `${container}${this.containerName}/${id}/${name}?${suffix}`;

            this.download(downloadableUrl, name)
        })
    }
    download(url, name) {
        var element = document.createElement('a'); 
        element.setAttribute('href', `${url}`);
        element.setAttribute('download', `${name}`); 
        document.body.appendChild(element); 
        element.click(); 
        document.body.removeChild(element); 
    }
    playVideo(e, recorder) {
        e.preventDefault();
        const { url, videoName } = recorder;
        this.visitationservice.getMediaSas({ readOnly: true }).then(data => {
            const splits = data.split("?");
            const suffix = splits[1];
            this.setState({
                urlVideo: `${url}?${suffix}`,
                playVideoVisible: true,
                nameVideo: videoName,
            });
        })
    }
    deleteBlob(recorder) {
        const { lvaResourceModel: { id, lvaResourceType, url, associatedInfo }} = recorder;
        const attachmentUrl = associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentUrl;

        if (lvaResourceType === LVAResourceType.Video) {
            return Promise.all([
                this.videoStorageManager.delete(url, id),
                attachmentUrl ? this.attachmentStorageManager.delete(attachmentUrl, id) : Promise.resolve()
            ]);
        }
        return attachmentUrl ? this.attachmentStorageManager.delete(attachmentUrl, id): Promise.resolve();
    }
    abortBlob(recorder) {
        const { lvaResourceModel: { id, name, associatedInfo }} = recorder;
        const attachmentName = associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentName;
        
        this.videoStorageManager.abort(name, id);
        if (attachmentName) {
            this.attachmentStorageManager.abort(attachmentName, id);
        }
    }
    showAbortNotification(recorder) {
        Modal.confirm({
            className: "video-manager-stop",
            title: fmtMsg({ id: SchoolLocale.VisitationComfirmTitle }),
            content: fmtMsg({ id: SchoolLocale.VisitationVideoStop}),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                this.abortBlob(recorder);
                this.formatAbortTeacherVideos(recorder);
            }, 
            onCancel: () => {}
        });
    }
    deleteVideo(recorder) {
        const { visitation } = this.props;
        const { teacherId, lvaResourceModel: { id, lvaResourceFormId, status, progress, lvaResourceType, associatedInfo } } = recorder;
        if (progress == 0 || progress < 100) {
            this.showAbortNotification(recorder);
            return;
        }
        const params = {
            teacherId,
            resourceId : id,
            visitationId: visitation.id,
            resourceformId: lvaResourceFormId,
        };
        if (status == LVAResourceStatus.Uploading) {
            this.visitationservice.deleteVideo(params).then(data => {
                this.formatDeletedTeacherVideos(recorder);
            });
        } else if (status == LVAResourceStatus.Completed) {
            this.visitationservice.deleteVideo(params).then(() => {

                const hasAttachment = (associatedInfo && associatedInfo.lvaResourceNote && associatedInfo.lvaResourceNote.attachmentName);
                if (lvaResourceType === LVAResourceType.Video
                    || hasAttachment) { // delete attachment
                    this.deleteBlob(recorder);
                }

                this.formatDeletedTeacherVideos(recorder);
            }).catch(({error_code}) => {
                if (error_code == ExceptionCode.NotExistedTeacherError) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({id: SchoolLocale.VisitationTeacherNotExist}));
                } else if (error_code == ExceptionCode.NoPermissionDeleteError) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({id: SchoolLocale.CommonErrorNoPermission}));
                }
            });
        }
    }
    formatAbortTeacherVideos(recorder) {
        const { teacherVideos } = this.state;
        const { lvaResourceModel: { id } } = recorder;
        const formatLvaResources = teacherVideos.map(v => {
            v.lvaResourceInfos = v.lvaResourceInfos.map(l => ({
                ...l,
                lvaResourceModels: (l.lvaResourceModels || []).map(lvaResourceModel => {
                    if (lvaResourceModel.id === id) {
                        delete lvaResourceModel.progress;
                        delete recorder.lvaResourceModel.progress;
                    }
                    return lvaResourceModel;
                })
            }));
            return v;
        });
        this.setState({ 
            recorder: recorder,
            teacherVideos: formatLvaResources 
        });
    }
    formatDeletedTeacherVideos(recorder) {
        const { teacherVideos } = this.state;
        const { lvaResourceModel: { id } } = recorder;
        const formatLvaResources = teacherVideos.map(v => {
            v.lvaResourceInfos = v.lvaResourceInfos.map(lvaResourceInfo => ({
                ...lvaResourceInfo,
                lvaResourceModels: (lvaResourceInfo.lvaResourceModels || [])
                    .map(lvaResourceModel => {
                        if (lvaResourceModel.id === id) {
                            recorder.lvaResourceModel = null;
                            return null;
                        }
                        return lvaResourceModel;
                    })
                    .filter(m => m)
            }));
            v.notShowVideos = v.lvaResourceInfos.every(lri => !(lri.lvaResourceModels || []).length);
            v.notShowReminder = v.lvaResourceInfos.some(lri => !!(lri.lvaResourceModels || []).length);
            return v;
        });
        this.setState({ 
            recorder: recorder,
            teacherVideos: formatLvaResources 
        });
    }
    addVideoFallback(file, attachmentFile, selectedData, lvaResourceType: LVAResourceType) {
        this.formatResourcesWithResourceId(selectedData);
        const { lvaResourceModel: { id, associatedInfo } } = selectedData;
        
        this.resetUploadStatus(file, attachmentFile);

        if (lvaResourceType === LVAResourceType.Video) {
            this.videoStorageManager.upload(file, id, {blobContentDisposition: `attachment; filename*=UTF-8''${encodeURIComponent(file.name)}`});
        }

        if (associatedInfo && attachmentFile && attachmentFile.name) { // has attachment file
            const {lvaResourceNote} = associatedInfo;
            const attachmentId = lvaResourceNote && lvaResourceNote.attachmentUrl;
            this.attachmentStorageManager.upload(attachmentFile, attachmentId, {blobContentDisposition: `attachment; filename*=UTF-8''${encodeURIComponent(attachmentFile.name)}`});
        }
    }
    formatResourcesWithResourceId(selectedData) {
        const { teacherVideos } = this.state;
        const { teacherId, schoolClassResource: { schoolClassId }, lvaResourceModel, lvaResourceFormId } = selectedData;
        const formatResources = teacherVideos.map(v => {
            if (v.teacherId == teacherId) {
                v.notShowVideos = false;
                v.notShowReminder = true;
                v.lvaResourceFormId = lvaResourceFormId;
                v.lvaResourceInfos = v.lvaResourceInfos.map(l => {
                    if (l.schoolClassResource.schoolClassId == schoolClassId) {
                        return {
                            ...l, 
                            lvaResourceModels: [
                                ...(l.lvaResourceModels || []),
                                lvaResourceModel
                            ],
                        }
                    } else {
                        return l;
                    }
                });
            }
            return v;
        });
        this.uploadingVideos.push(selectedData);
        this.setState({
            visibleModal: false,
            teacherVideos: formatResources,
        });
    }

    formatLvaResources(videoProgressData, attachmentProgressData) {        
        const isUpdatingVideo = 'progress' in videoProgressData ? true : false;
        const isUpdatingAttachment = 'progress' in attachmentProgressData ? true : false;

        if (!isUpdatingVideo && !isUpdatingAttachment){
            return; // when some error occurs while uploading
        }

        const { teacherVideos } = this.state;
        const { url: urlVideo, progress: progressVideo } = videoProgressData;
        const { url: urlAttachment, progress: progressAttachment} = attachmentProgressData;

        const url = isUpdatingVideo ? urlVideo : urlAttachment;
        const splits = url && url.split(`${this.containerName}/`);
        const lvaResourceId = splits && splits[1].split("%2F")[0];
        const currentInfo = this.uploadingVideos.find(v => v.lvaResourceModel.id == lvaResourceId);
        const { teacherId } = currentInfo;
        const updateUploadProgress = lvaResourceInfo => {
            return ({
                ...lvaResourceInfo,
                lvaResourceModels: (lvaResourceInfo.lvaResourceModels || []).map(lvaResourceModel => {

                    if (lvaResourceModel.id !== lvaResourceId) {
                        return lvaResourceModel;
                    }

                    const {progress: lastVideoProgress, associatedInfo } = lvaResourceModel;
                    const lastAttachmentProgress = associatedInfo && associatedInfo.progress ? associatedInfo.progress : 100;
                    const lvaResourceNote = associatedInfo ? associatedInfo.lvaResourceNote : null;

                    const overallProgress = isUpdatingVideo ? (progressVideo + lastAttachmentProgress) / 2
                        : (lastVideoProgress + progressAttachment) / 2

                    return isUpdatingVideo ? {
                          ...lvaResourceModel,
                          url: urlVideo,
                          progress: progressVideo,
                          status: overallProgress === 100
                                  ? LVAResourceStatus.Completed
                                  : LVAResourceStatus.Uploading
                      } : {
                        ...lvaResourceModel,
                        status: overallProgress === 100
                                  ? LVAResourceStatus.Completed
                                  : LVAResourceStatus.Uploading,
                        associatedInfo: associatedInfo == null ? null : {
                            lvaResourceNote: {
                                ...lvaResourceNote,
                                attachmentUrl: urlAttachment,
                                progress: progressAttachment
                            }
                        }
                      }
                })
            });
        };

        const formatResources = teacherVideos.map(v => {
            if (v.teacherId == teacherId) {
                v.notShowVideos = false;
                v.notShowReminder = true;
                v.lvaResourceInfos = v.lvaResourceInfos.map(updateUploadProgress);
            }
            return v;
        });
        this.setState({ teacherVideos: formatResources });
    }

    resetUploadStatus(videoFile, attachmentFile) {
        const hasVideo = videoFile && videoFile.name;
        const hasAttachment = attachmentFile && attachmentFile.name;

        this.uploadCompletedStatus = {
            video: {
                completed: hasVideo ? false : true,
                successFile: {}
            },
            attachment: {
                completed: hasAttachment ? false : true,
                successFile: {}
            }
        }
    }

    renderVideoModal(recorder) {
        const { teacherId, lvaResourceFormId, lvaResourceInfos } = recorder;
        this.videoMadal = 
            <VideoModal
                title={`Upload video`}
                teacherId={teacherId}
                lvaResources={lvaResourceInfos}
                folder={this.props.visitation.id}
                lvaResourceFormId={lvaResourceFormId}
                addVideoFallback={(file, attachmentFile, selectedData, lvaResourceType) => this.addVideoFallback(file, attachmentFile, selectedData, lvaResourceType)}
            />
    }
    showNotification() {
        Modal.confirm({
            className: "video-manager-confirm",
            title: fmtMsg({ id: SchoolLocale.VisitationComfirmTitle }),
            content: fmtMsg({ id: SchoolLocale.VisitationNotAllClassesHasVideos}),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                this.videoManagerMoveNext();
            }, 
            onCancel: () => {}
        });
    }
    goToNextStage() {
        const { teacherVideos } = this.state;
        const canNotMoveNext = teacherVideos.every(t => t.lvaResourceInfos.every(l => !(l.lvaResourceModels || []).length));
        const showTips = teacherVideos.some(t => t.lvaResourceInfos.every(l => !(l.lvaResourceModels || []).length));
        const videosFailed = teacherVideos.some(t => 
                                t.lvaResourceInfos.some(l => !!(l.lvaResourceModels || []).length && 
                                    (l.lvaResourceModels || []).some(m => m.status === LVAResourceStatus.Uploading)));
        if (canNotMoveNext) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationUploadAtLeastOneVideo }));
        } else if (videosFailed) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationVideoUploadFailed }));
        } else if (showTips) {
            this.showNotification();
        } else {
            this.videoManagerMoveNext();
        }
    }
    videoManagerMoveNext() {
        const { visitation: { id }, callback} = this.props;
        const stage = VisitationCoachStage.TeacherReview;
        const params = {
            visitationId: id,
        }
        this.visitationservice.videoManagerMoveNext(params).then(data => {
            callback(stage);
        });
    }
    sendMessage(recorder) {
        this.setState({
            receiver: recorder,
            messageVisible: true
        });
    }
    
    render() {
        const { visitation: { id, stage, coachId } } = this.props;
        const { visible, loading, teacherVideos, receiver, messageVisible, chatBadgeCounts } = this.state;
        const disabled = stage > VisitationCoachStage.VideoManager;
        const isLastStageOrCompleted = stage == VisitationCoachStage.Completed ||
                                       stage == VisitationCoachStage.SchoolReview;
        const isCompleted = stage == VisitationCoachStage.Completed;
        const sortByClassName = (left, right) => 
            left.schoolClassResource.schoolClassName.localeCompare(right.schoolClassResource.schoolClassName, 'en');
        const thenByFilesName = (left, right) => 
            left.lvaResourceModel.name.localeCompare(right.lvaResourceModel.name, 'en');
        const thenByUploadTime = (left, right) => 
            new Date(left.lvaResourceModel.uploadTime).getTime() - new Date(right.lvaResourceModel.uploadTime).getTime();
        
        let videoWidth = 0;
        let videoHeight = 0;
        const isTablet = 768 < window.innerWidth && window.innerWidth  <= 1224;
        const isMobile = window.innerWidth <= 768;
        if (isTablet) {
            videoWidth = window.innerWidth - 100
            videoHeight = window.innerHeight - 100
        } else if (isMobile) {
            videoWidth = window.innerWidth - 50
            videoHeight = window.innerHeight - 50
        } else {
            videoWidth = window.innerWidth - 300
            videoHeight = window.innerHeight - 300
        }
        return <div className={`coach-video-manager info-container ${isLastStageOrCompleted && "disabled"} ${isCompleted && "disabled-video"}`}>
            <Modal
                centered
                width={videoWidth}
                footer={false}
                closable={true}
                destroyOnClose
                className="modal_play-video--content"
                maskClosable = {false}
                onCancel={()=>{
                    this.setState({
                        playVideoVisible:false
                    })
                }}
                visible={this.state.playVideoVisible}
            >
                <div>
                    <Button
                        className="button--position"
                        type="primary"
                        onClick={() => {this.download(this.state.urlVideo, this.state.nameVideo);}}
                        key="primary">{fmtMsg({ id: SchoolLocale.CoachDownloadVideo })}</Button>
                </div>
                <video controls
                    controlsList="nodownload"
                    preload='metadata'
                    width={videoWidth}
                    height={videoHeight}
                    className="video--auto-size">
                    <source src={this.state.urlVideo+'#t=0.001'} />
                </video>
            </Modal>
            <div>
            <div className="tab-title">{fmtMsg({ id: SchoolLocale.VisitationVideosText })}</div>
            <Spin spinning={loading}>
            {
                teacherVideos
                .sort((a, b) => {
                    return a.name.localeCompare(b.name, 'en');
                })
                .map((v, k) => {
                    const formatResourceInfos = v.lvaResourceInfos
                        .flatMap(({ lvaResourceModels, schoolClassResource }) =>
                            (lvaResourceModels || []).map(lvaResourceModel => ({
                                schoolClassResource,
                                lvaResourceModel,
                                teacherId: v.teacherId
                            }))
                        )
                        .sort((left, right) => 
                            sortByClassName(left, right) ||
                            thenByFilesName(left, right) ||
                            thenByUploadTime(left, right)
                        );
                    const count = chatBadgeCounts && chatBadgeCounts[v.teacherId] ? chatBadgeCounts[v.teacherId] : 0;
                    return (<div className="teacher-table" key={k}>
                        <div>
                            <div>
                                <div className="title">{fmtMsg({id: SchoolLocale.VisitationTeacher})}:</div>
                                {isCoachAndSelf(GSSchoolAction.TeacherLink, coachId) ?
                                    <Link to={{ pathname: "" }}>
                                        <div className="link">{v.name}</div>
                                    </Link> :
                                    <div>{v.name}</div>
                                }
                                {isCoachAndSelf(GSSchoolAction.Chat, coachId) && 
                                stage !== VisitationCoachStage.Completed &&
                                    <ChatBadge 
                                        count={count}
                                        handleClick={() => this.sendMessage(v)}
                                        label="video-manager-chat"
                                    />
                                }
                            </div>
                            {isCoachAndSelf(GSSchoolAction.UploadVideo, coachId) && 
                            <div className="teacher-table-upload" onClick={() => this.onAddVideo(v)} title={fmtMsg({ id: SchoolLocale.VisitationSupportedVideoType})}>
                                <Icon type="upload" title="upload video" />
                                {fmtMsg({ id: SchoolLocale.SettingsAvatarUploadButton })}
                            </div>}
                        </div>
                        {
                            !v.notShowReminder  && isCoachAndSelf(GSSchoolAction.RemindSendVideo, coachId) &&
                            <div className="send-reminder" onClick={() => this.showDrawer(v)}>
                                {fmtMsg({ id: SchoolLocale.VisitationRemindSendVideo })}
                            </div>
                        }
                        {!v.notShowVideos && <>
                            <Table 
                                rowKey={"index"}
                                className="teacher-videos-list"
                                columns={this.getLVATableCols()}
                                dataSource={formatResourceInfos}
                                pagination={false}
                            />
                        </>}
                    </div>)
                })
            }
            </Spin>
            </div>
            {!disabled && isCoachAndSelf(GSSchoolAction.MoveNext, coachId) &&
            <SaveCancelBtns 
                noCancelBtn={true}
                saveTitle={SchoolLocale.VisitationMoveNextText} 
                onSubmit={() => this.goToNextStage()}>
            </SaveCancelBtns>}
            <CustomDrawer 
                width={670}
                visible={visible} 
                drawerTitle={<Row>{fmtMsg({ id: SchoolLocale.VisitationSendReminder })}</Row>}
                onClose={() => this.onClose()}>
                <div>{this.renderDrawerContent()}</div>
            </CustomDrawer>
            {this.videoMadal}
            {
                receiver &&
                <ChatDrawer 
                    visitationId={id}
                    receiver={{id: receiver.teacherId, name: receiver.name}}
                    searchable={true}
                    title={fmtMsg({id: GSAdminLocale.ChatHeader}, {name: receiver.name})}
                    visible={messageVisible}
                    onClose={() => this.onClose()}
                    getBadgeCount={this.getBadgeCount.bind(this)}
                />
            }
        </div>;
    }
}