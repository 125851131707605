import * as gltypes from 'gl-commonui';
import * as service from '../service/types';
import { RcFile } from 'antd/lib/upload/interface';

export const TYPES = {
    ...gltypes.TYPES,
    ...service.TYPES
};
export const StoreTypes = {

    SAVE_SELECTED_CHANGE: "SAVE_SELECTED_CHANGE",
    SAVE_CHANGE_AREA: "SAVE_CHANGE_AREA",
    SAVE_CHANGE_TYPE: "SAVE_CHANGE_TYPE",
    SAVE_CONTENT_CHANGE_AREA: "SAVE_CONTENT_CHANGE_AREA"
};

export interface RcFileExtended extends RcFile {
    isValid: boolean;
}

export type IOptional<T> = { [P in keyof T]?: T[P] };

export type FnReturnPromise<T> = (...args: any[]) => Promise<Array<T> | T>;