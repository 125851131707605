import moment from "moment";
import React from "react";
import { Badge, Button, Col, Divider, Icon, Row, Spin } from "antd-min";
import { GLRichTextEditor } from "@app/components";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import {DateHelper, fmtMsg, stripHtmlTags, htmlDecode} from "@app/util";
import { formatResponseAnswer } from "@app/util/coach/fun";
import "./teacher-response.less";
import {GLPdf, GLPdfColumn, GLPdfInfo, GLPdfLinebreak, GLPdfRow, GLPdfTitle} from "gl-pdf";
import {GLGlobal, PrimaryLanguageLocale, formatRegionalUrl} from "gl-commonui";

interface TeacherResponseProps {
    visible?: boolean;
    spinning?: boolean;
    loading?: boolean;
    isSending?: boolean;
    status?: number;
    recorder?: any
    teachers?: any[];
    lastFeedback?: any[];
    viewQuestions?: any[];
    messageVisible?: boolean;
    receiver?: any;
    chatBadgeCounts?: any;
    submitDate?: Date;
    modal: {
        sharedUser: { visible: boolean };
    };
    searchUser: any[];
    probablyShareUsers: any[];
    userShareFetching: boolean;
    notifyUsers: any[];
    formatRoleTargets: any[];
    checkNotifyList: boolean;
    searchShareUser: any[];
    searchTempShareUser: any[];
    surveyInstanceId: string | any;
    teacherResponseVisible: boolean;
    renderDrawerTitle: () => void;
    onTeacherResponseClose: () => void;
    moveToResponse: (up: boolean, move?: boolean) => boolean;
    visibleModal: (key: any) => void;
    startDate: string;
    schoolName?: string;
    campusName?: string;
}

export const TeacherResponse = ((props: TeacherResponseProps) => {
    const renderTeacherResponse = () => {
        const { spinning, viewQuestions } = props;
        return (
            <Spin spinning={spinning}>
                <div className="view-teacher-response-questions">
                    {viewQuestions && viewQuestions.map((v, k) => {
                        return <Row className="questions read-only" key={k}>
                            <div>{k + 1}. {v.question}</div>
                            <GLRichTextEditor
                                readOnly={true}
                                autoShowToolbar={false}
                                editorHtml={formatRegionalUrl(formatResponseAnswer(v.surveyResponseAnswer))}
                            />
                        </Row>;
                    })}
                </div>
                <div className="hide-btns view-teacher-response-btn">
                    <Button onClick={props.onTeacherResponseClose} >
                        {fmtMsg({ id: GSAdminLocale.ButtonClose })}
                    </Button>
                </div>
            </Spin>
        )
    }

    const exportResponse = () => {
        const { recorder, startDate, schoolName, campusName, viewQuestions } = props;
        const columnOptions = { width: 33 };
        const langLoaded = GLGlobal.store.getState().intl.langLoaded;
        const pdf = new GLPdf({ title: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfTeacherSupport }), locale: langLoaded as PrimaryLanguageLocale });
        pdf.build(
            new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfSupportDetails }) }),
            new GLPdfLinebreak(),
            new GLPdfRow(
                new GLPdfColumn(columnOptions, [
                    new GLPdfInfo({
                        label: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfStartDate}),
                        value: startDate,
                    })
                ]),
                new GLPdfColumn(columnOptions, [
                    new GLPdfInfo({
                        label: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfSchool}),
                        value: schoolName,
                    })
                ]),
                new GLPdfColumn(columnOptions, [
                    new GLPdfInfo({
                        label: campusName ? fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfCampus}) : "",
                        value: campusName,
                    })
                ]),
            ),
            new GLPdfRow(
                new GLPdfColumn(columnOptions, [
                    new GLPdfInfo({
                        label: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfClass}),
                        value: recorder.schoolClasses[0].schoolClassName,
                    })
                ]),
                new GLPdfColumn(columnOptions, [
                    new GLPdfInfo({
                        label: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfTeacherName}),
                        value: recorder.name,
                    })
                ]),
            ),
            new GLPdfLinebreak(),
            new GLPdfTitle({ title: fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfSupportQuestionnaire }) }),
            new GLPdfLinebreak(),
            ...(viewQuestions && viewQuestions.map((v, k) => {
                return new GLPdfRow(
                    new GLPdfColumn({width: 100}, [
                        new GLPdfInfo({
                            label: `${k + 1}. ${v.question}`,
                            value: htmlDecode(stripHtmlTags(formatResponseAnswer(v.surveyResponseAnswer)))
                        })
                    ])
                )
            }))
        ).download(`${fmtMsg({ id: SchoolLocale.VisitationTeacherResponsePdfTeacherSupport })} - ${recorder.name}`)
    }

    const renderTitle = () => {
        const { submitDate, recorder } = props;
        const formatSubmitDate = DateHelper.toLocalStringFromUTC(moment(submitDate));
        let badgeCount = props.searchShareUser.length ? props.searchShareUser.length : 0
        const isDisabledIcon = !props.moveToResponse(true, false) ? "up-down__disable" : null;
        return <>
            <Row type="flex" justify="space-between">
                <Col>
                    <h2>{fmtMsg({ id: SchoolLocale.VisitationSendPrevisitationForm })} ({recorder.name})</h2>
                    <h5>{fmtMsg({ id: SchoolLocale.VisitationPrevisitationSurveySubmit })}: {formatSubmitDate}</h5>
                </Col>
                <Col className="share-btn">
                    <Row type="flex" justify="space-between" gutter={8} className="share-btn-row">
                        <Col className="icons">
                            <Icon
                                type="caret-up"
                                onClick={() =>
                                    props.moveToResponse(true)
                                }
                                className={
                                    `icon ${isDisabledIcon}`
                                }
                            />
                            <Icon
                                type="caret-down"
                                onClick={() =>
                                    props.moveToResponse(false)
                                }
                                className={
                                    !props.moveToResponse(
                                        false,
                                        false
                                    ) && "up-down__disable"
                                }
                            />
                        </Col>
                        <Col>
                            <Badge count={badgeCount} showZero>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        props.visibleModal("sharedUser");
                                    }}
                                >
                                    <Button type="primary" icon="share-alt" size="default" className="sharebtn sharebtn-teacher">
                                        {fmtMsg({ id: SchoolLocale.VisitationCoachShare })}
                                    </Button>
                                </a>
                            </Badge>
                        </Col>
                        <Col>
                            <Button icon="download" size="default" className="export-btn" onClick={exportResponse}>
                                {fmtMsg({ id: SchoolLocale.VisitationTeacherResponseExport })}
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    }

    return (
        <div id="response">
            <Divider></Divider>
            {renderTitle()}
            {renderTeacherResponse()}
        </div>
    )
})
