import { SchoolLocale } from "@app/locales/localeid";
import { ResourceService } from '@app/service/resources/index';
import { isGuid, GSSchoolAction } from '@app/util';
import { PathConfig as CommonPath, ServerExceptionCode, ResourceType, GLGlobal, PrimaryLanguageLocale, RoleName } from 'gl-commonui';

interface Services {
    resource: ResourceService
}

export enum BreadcrumbTypes {
    schoolClass = 110,
    campus = 111,
    school = 112,
    region = 113,
    order = 114,
    template = 115,
    student = 116,
    globalProduct = 117,
    localProduct = 118
}

export interface ResourceState {
    breadcrumbs?: any
    extraMenuActions?: []
    refreshBreadcrumbs?: boolean
}


export default {
    namespace: 'resource',
    state: {
        breadcrumbs: null,
        extraMenuActions: null,
        refreshBreadcrumbs: false
    },
    reducers: {
        mergeBreadcrumbs(state, action) {
            return { ...state, breadcrumbs: { ...state.breadcrumbs, ...action.payload }, refreshBreadcrumbs: false };
        },
        setExtraMenuActions(state, action) {
            return { ...state, extraMenuActions: action.payload };
        },
        reloadBreadcrumbs(state, action) {
            return { ...state, refreshBreadcrumbs: true};
        }
    },
    effects: {
        *getBreadcrumbs({ payload }, { call, put }, { resource }: Services, { push, pathStringify }) {
            let d = formatBreadcrumbs(payload);
            if (isGuid(d.breadcrumbIds[0])) {
                try {
                    const data = yield call(resource.getBreadcrumbs, d);
                    if (data.campus === null || data.schoolClass === null) {
                        yield put(push(pathStringify(CommonPath.NotFound)));
                        return;
                    }
                    if (data.order) {
                        data.order.name = GLGlobal.intl.formatMessage({ id: SchoolLocale.MaterialOrderPageTitle });
                    }
                    if (data.region && GLGlobal.intl.locale == PrimaryLanguageLocale.English) {
                        data.region.name = data.region.englishName;
                    }
                    yield put(mergeBreadcrumbs(data));
                } catch (error) {
                    if (error.isError && error.error_code === ServerExceptionCode.InvalidArgumentException) {
                        yield put(push(pathStringify(CommonPath.NotFound)));
                    }
                }
            }
        },
        *getExtraMenuActions({ payload }, { call, put }, { resource }: Services) {
            try {
                const regions = yield call(resource.getRegions, 
                    [RoleName.regionAdmin, RoleName.accountManager, RoleName.trainer, 
                        RoleName.schoolAdmin, RoleName.campusAdmin]);
                let extraMenuActions = [];
                const regionsWithUrl = regions.filter(region => region.eorderLink);
                if (regionsWithUrl.length > 0) {
                    extraMenuActions.push({action: GSSchoolAction.Navigate2KoreaOrder, url: regionsWithUrl[0].eorderLink});
                }
                yield put(setExtraMenuActions(extraMenuActions));
            } catch (response) {
            }
        }
    },
    services: {
        resource: ResourceService
    }
}

function formatBreadcrumbs(params) {
    const { regionId, schoolId, campusId, orderId, classId, studentId, templateId, globalProductId, localProductId } = params;
    const ids = [];
    const types = [];
    const push = (id, type) => {
        ids.push(id);
        types.push(type);
    }
    regionId && push(regionId, BreadcrumbTypes.region);
    schoolId && push(schoolId, BreadcrumbTypes.school);
    campusId && push(campusId, BreadcrumbTypes.campus);
    classId && push(classId, BreadcrumbTypes.schoolClass);
    studentId && push(studentId, BreadcrumbTypes.student);
    orderId && push(orderId, BreadcrumbTypes.order);
    templateId && push(templateId, BreadcrumbTypes.template);
    globalProductId && push(globalProductId, BreadcrumbTypes.globalProduct);
    localProductId && push(localProductId, BreadcrumbTypes.localProduct);

    return { breadcrumbIds: ids, breadcrumbTypes: types };
}

function getResourceType(breadcrumbKey) {
    switch (breadcrumbKey) {
        case BreadcrumbTypes[BreadcrumbTypes.school]:
            return ResourceType.School;
        case BreadcrumbTypes[BreadcrumbTypes.campus]:
            return ResourceType.Campus;
        case BreadcrumbTypes[BreadcrumbTypes.schoolClass]:
            return ResourceType.SchoolClass;
        default:
            return ResourceType.None;
    }
}

export function mergeBreadcrumbs(state) {
    return { type: 'resource/mergeBreadcrumbs', payload: state }
}
export function getBreadcrumbs(state) {
    return { type: 'resource/getBreadcrumbs', payload: state }
}
export function setBreadcrumbs(state) {
    GLGlobal.store.dispatch(mergeBreadcrumbs(state));
}
export function getExtraMenuActions(state) {
    return { type: 'resource/getExtraMenuActions', payload: state }
}
export function setExtraMenuActions(state) {
    return { type: 'resource/setExtraMenuActions', payload: state  }
}
export function reloadBreadcrumbs() {
    return { type:'resource/reloadBreadcrumbs' }
}