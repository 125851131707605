import { injectable } from "@app/util/di";
import { GLServiceBase } from "gl-commonui";
import { ITagManagerService } from "./index";
import { TagCreateRequestModel, TagEntity, TagModel, TagTypeModel, TagUpdateRequestModel } from "./model";

@injectable()
export class TagManagerService extends GLServiceBase<any, any> implements ITagManagerService {
	serviceRoute = {
		prefix: "admin/v1/tags",
	};

	public getTypes() {
		return this.get<TagTypeModel[]>("tagtypes");
	}

	public getTags(regionId: string, tagType: TagEntity) {
		return this.get<TagModel[]>(`${regionId}/tags`, { tagType });
	}

	public getTagsList(regionId: string, tagType: TagEntity) {
		return this.get<TagModel[]>(`${regionId}/taglist`, { tagType });
	}

	public createTag(data: TagCreateRequestModel) {
		return this.create("save", data);
	}

	public updateTag(data: TagUpdateRequestModel) {
		return this.update(`${data.id}/update`, data);
	}

	public deleteTag(id: number) {
		return this.delete(`${id}/delete`);
	}

	public getUsage(tagId: number, typeId: number) {
		return this.get(`${tagId}/usage/${typeId}`);
	}

	public deleteUsage(tagId: number, entityId: string) {
		return this.delete(`${tagId}/removetagentity/${entityId}`);
	}
	public getEntityTags(entityIds: string[], regionId?:string) {
		// fix GL-12360, change get request to post for preventing long url
		return this.request.post(this.url("entitytags"), { entityIds, regionId });
	}

	public addTagToEntity(entityId: string, tagIds: number[]) {
		return this.createWithoutLoader(`${entityId}/addentitytag`, { entityId, tagIds });
	}

	public getTeacherTagsByRole(entityId: string, regionId: string) {
		return this.get("teachertagsbyrole", { entityId, regionId });
	}
}
