import { SchoolLocale, SurveyLocale } from '@app/locales/localeid';
import { IAccountService } from '@app/service/admin/accountservice';
import { IResourceService } from "@app/service/resources";
import { IParentService } from '@app/service/school/parent';
import { TodoSurveyResponseModel } from '@app/service/survey';
import { lazyInject, TYPES } from '@app/util';
import { Col, Row, Skeleton } from 'antd-min';
import { GLGlobal, Role, RoleName } from "gl-commonui";
import * as React from 'react';
import { Component } from 'react';
import "./style.less";

export interface UserStatisticsProps {
    surveyResponse: TodoSurveyResponseModel;
}

interface UserStatisticsStates {
    error: boolean;
    loading: boolean;
    children: any[];
    internalData: any[];
    externalData: any[];
}

export class UserStatisticsReport extends Component<UserStatisticsProps, UserStatisticsStates> {
    @lazyInject(TYPES.IParentService)
    parentService: IParentService
    @lazyInject(TYPES.IAccountService)
    accountService: IAccountService
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService

    userId: string = null;
    internalRoles = [
        RoleName.regionAdmin,
        RoleName.trainingAdmin,
        RoleName.trainer,
        RoleName.globalHead,
        RoleName.systemAdmin,
        RoleName.accountManager,
        RoleName.trainingManager,
        RoleName.contentAdmin
    ];
    externalRoles = [
        RoleName.teacher,
        RoleName.parent,
        RoleName.campusAdmin,
        RoleName.schoolAdmin
    ]

    constructor(props) {
        super(props);
        this.userId = props.surveyResponse.createdBy;

        this.state = {
            error: false,
            loading: true,
            children: [],
            internalData: [],
            externalData: []
        }
    }

    getInternalData(data) {
        let optData = []
        for (let role of this.internalRoles) {
            const roleData = data[role] || [];
            optData = [...optData, ...roleData];
        }
        optData = optData.map(x => {
            return {
                regionId: x.regionId,
                region: x.region
            }
        });
        return [...new Map(optData.map(item => [item['regionId'], item])).values()]
    }

    getExternalData(data) {
        let optData = []
        for (let role of this.externalRoles) {
            const roleData = data[role] || [];
            optData = [...optData, ...roleData];
        }
        optData = optData.map(x => {
            return {
                schoolId: x.schoolId,
                school: x.school,
                region: x.region
            }
        });
        return [...new Map(optData.map(item => [item['schoolId'], item])).values()]
    }

    componentDidMount() {
        this.accountService.getUserById({ id: this.userId }).then((data) => {
            const userRoles = data.roles;
            let roleIds = userRoles.map(role => Role[role]);

            Promise.all([
                this.resourceService.getUserResourcesForSupport({
                    userId: this.userId,
                    userRoles: roleIds,
                    isFromEditUser: true
                }),
                this.parentService.getStudentsByParent({ parentId: this.userId })])
                .then(([data, children]) => {
                    const internalData = this.getInternalData(data);
                    const externalData = this.getExternalData(data);
                    this.setState({
                        internalData,
                        externalData
                    });

                    this.setState({ children });
                })
                .catch(() => this.setState({ error: false }))
                .finally(() => this.setState({ loading: false }))
        })
    }

    renderInternalData(internalData) {
        return !!internalData.length &&
            <Row style={{ padding: 10 }}>
                <span>{GLGlobal.intl.formatMessage({ id: SchoolLocale.Location })}: {internalData.map(x => x.region).join(', ')}</span>
            </Row>;
    }

    renderExternalData(externalData) {
        return !!externalData.length &&
            externalData.map(x =>
                <Row style={{ padding: 10, borderBottom: '1px solid #e8e8e8' }}>
                    <Col span={24}><span>{GLGlobal.intl.formatMessage({ id: SchoolLocale.ClassesColumnSchool })}: {x.school}</span>
                    </Col>
                    <Col span={24}><span>{GLGlobal.intl.formatMessage({ id: SchoolLocale.Location })}: {x.region}</span>
                    </Col>
                </Row>
            )
    }

    renderChildren(children) {
        return !!children.length &&
            <Row style={{ padding: 10 }}>
                <span>{GLGlobal.intl.formatMessage({ id: SchoolLocale.Children })}: </span>
                <ul>
                    {children.map(c => <li key={c.id}>{`Name: ${c.name} (${c.englishName}) | Class: ${c.schoolClassName} | School: ${c.schoolName}`}</li>)}
                </ul>
            </Row>
    }

    render() {
        const { internalData, externalData, children } = this.state
        return !this.state.error && <div className="licensebyregion">
            <Row className="license" style={{ marginBottom: "30px" }}>
                {this.state.loading
                    ? <Skeleton active />
                    : <>
                        <Row className="lic-cnt-vw">{GLGlobal.intl.formatMessage({ id: SurveyLocale.SurveyCreateDigitalUserStatistics })}</Row>
                        <Row className="chart">
                            <div className="chartcontent" style={{ padding: '10px 20px', fontSize: 16 }}>
                                {
                                    this.renderInternalData(internalData)
                                }
                                {
                                    this.renderExternalData(externalData)
                                }
                                {
                                    this.renderChildren(children)
                                }
                            </div>
                        </Row>
                    </>
                }
            </Row>
        </div>
    }
}
