import { GLUtil } from "gl-commonui";

export function isSASExpiry(sas: string) {
    var now = new Date();
    now.setMinutes(now.getMinutes() + 5)
    return now >= new Date(decodeURIComponent(sas.match(/se=(.*Z)&/)[1]));
}
export function setSASCache(key: string, sas: string) {
    if (GLUtil.isNullOrUndefined(sas) || isSASExpiry(sas)) {
        return false;
    }
    sessionStorage.setItem(key, sas);
    return true;
}
export function getSASCache(key) {
    const sas = sessionStorage.getItem(key);
    if (GLUtil.isNullOrUndefined(sas) || isSASExpiry(sas)) {
        return null;
    }
    return sas;
}