import React from "react";
import { Drawer } from "antd-min";
import { ChatWidget } from "./components/chat-widget";
import { GLGlobal } from "gl-commonui";
import { GSAdminLocale } from "@app/locales/localeid";
import { fmtMsg, lazyInject } from "@app/util";
import { TYPES } from "@app/service/types";
import { IChatService } from "@app/service/chat";
import { useService } from "@app/hooks";
import { useAsyncFn } from "@app/hooks/useAsyncFn";

interface IChatDrawerProps {
    visitationId?: string;
    title: string;
    receiver: any;
    visible: boolean;
    onClose: () => void;
    searchable: boolean;
    getBadgeCount: (id) => void;
}


export const ChatDrawer: React.FunctionComponent<IChatDrawerProps> = props => {
    const userProfile = GLGlobal.loginInfo().profile;
    const chatservice = useService<IChatService>(TYPES.IChatService);
    const fetchChatEnvironment = async (senderId, receiverId, visitationId) =>
        await chatservice.createNewVisitationChat({
            attenders: [senderId, receiverId],
            visitationId
        });
    
    const [chatInfo, asyncFetchChatEnvironment, setChatInfo] = useAsyncFn<any>(
        fetchChatEnvironment,
        [
            userProfile.sub,
            props.receiver && props.receiver.id,
            props.visitationId
        ]
    );

    React.useEffect(() => {
        props.visible &&
            props.visitationId &&
            asyncFetchChatEnvironment(
                userProfile.sub,
                props.receiver && props.receiver.id,
                props.visitationId
            );
    }, [asyncFetchChatEnvironment, props.visible]);

    React.useEffect(() => {
       !props.visible && setChatInfo({ data: null });
    }, [props.visible]);

    return (
        <div>
            <Drawer
                title={props.title}
                placement="right"
                closable={true}
                visible={props.visible}
                width={500}
                onClose={props.onClose}
                maskClosable={false}
                destroyOnClose={true}
                className="chat-drawer"
            >
                {chatInfo.data && (
                    <ChatWidget
                        chatGroupId={chatInfo.data && chatInfo.data.groupId}
                        chatThreadId={chatInfo.data && chatInfo.data.threadId}
                        chatContextId={chatInfo.data && chatInfo.data.contextId}
                        visitationId={props.visitationId}
                        senderPlaceHolder={fmtMsg({
                            id: GSAdminLocale.SenderPlaceHolder
                        })}
                        autofocus={false}
                        senderId={userProfile.sub}
                        receiver={props.receiver}
                        searchable={props.searchable}
                        getBadgeCount={props.getBadgeCount}
                    />
                )}
            </Drawer>
        </div>
    );
};
