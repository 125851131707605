import { Action, Actions, Loading, TableWithPerfectScroll } from "@app/components/survey";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { SurveyLocale, GSAdminLocale } from "@app/locales/localeid";
import { SurveyAction, fmtMsg, CONSTS, isPastDate, DateHelper } from "@app/util";
import { GLRouteComponentProps, connect } from "gl-commonui";
import React, { useEffect, useState } from "react";
import { usePermissions, PermissionType } from "@app/hooks/usePermissions";
import { getReviewPolls, SurveyState, UserPollParticipationState, getUserPollParticipation } from "@app/states/survey/survey";
import { StateType } from "@app/states";
import { ReviewPollsResponseModel, PollPageParams, ReviewPollModel, PollReviewType } from "@app/service/survey";
import { IdentityModel } from "@app/service/school/invitation";
import { WijmoGrid } from "@app/components/grid";
import { PerfectScrollbar } from "@app/components";
import { Card, Typography, Modal, Icon } from "antd-min";
import "./review-polls.less";

const { Grid, Column } = WijmoGrid;

interface ReviewPollsProps extends GLRouteComponentProps {
    loading: boolean;
    shouldReloadReviewSurveys: boolean;
    reviewPolls: ReviewPollsResponseModel;
    participationLoading: boolean;
    userParticipation: UserPollParticipationState;
    getReviewPolls: (params?: PollPageParams) => void;
    getPollParticipation: (surveyId: string) => void;
    reload: (partialState: SurveyState) => void;
}

const { Text } = Typography;

export const ReviewPolls = connect(
    ({ survey: { reviewPolls, reviewPollsLoading, shouldReloadReviewSurveys, participationLoading, userParticipation } }: StateType) => ({
        reviewPolls,
        loading: reviewPollsLoading,
        shouldReloadReviewSurveys,
        participationLoading,
        userParticipation
    }),
    {
        getReviewPolls,
        getPollParticipation: getUserPollParticipation
    }
)((props: ReviewPollsProps) => {

    const [showParticipation, setShowParticipation] = useState(false);
    const [participationPoll, setParticipationPoll] = useState({} as ReviewPollModel);
    const permissions = usePermissions(PermissionType.Poll);
    const { loading, reviewPolls, getReviewPolls, history, shouldReloadReviewSurveys, participationLoading, userParticipation, getPollParticipation } = props;

    useEffect(() => {
        if ((!reviewPolls.data && !loading && !shouldReloadReviewSurveys)
            || (shouldReloadReviewSurveys && !loading)) {
               getReviewPolls({ offset: 0, limit: CONSTS.MySurveyItemDisplayCount });
        }
    }, [reviewPolls, loading]);

    const canCreate = permissions.some(p => p === SurveyAction.CreateSurvey);

    const getPollAction = (review: ReviewPollModel) => {
        const type = review.surveyReviewType;
        switch (type) {
            case PollReviewType.Todo:
                return fmtMsg({ id: SurveyLocale.MySurveyComplete });
            case PollReviewType.CreatorShared:
                return (review.completeByCount ? review.completeByCount : 0).toString() + "/" + review.totalTodoCount;
            case PollReviewType.ReceiverShared:
                return fmtMsg({ id: SurveyLocale.MySurveyContribute });
            default:
                return "";
        }
    };

    const goToUserResponse = (userId: string, name: string, responseId: string) => {
        history.push(`${SurveyPathConfig.Home}/${participationPoll.surveyId}/${participationPoll.id}/${userId}/view/${responseId}`);
    };

    const onShowParticipationClick = (poll: ReviewPollModel) => {
        setShowParticipation(true);
        if (!userParticipation[poll.surveyId]) {
            getPollParticipation(poll.surveyId);
        }
        setParticipationPoll(poll);
    };

    const renderParticipationModal = () => {
        const modalHeight = window.innerHeight - 300;
        return (
            <Modal
                title={`${participationPoll.name} - ${participationPoll.completeByCount}/${participationPoll.totalTodoCount}`}
                visible={showParticipation}
                className="notified-users-modal"
                maskClosable={false}
                destroyOnClose
                onOk={() => setShowParticipation(false)}
                onCancel={() => setShowParticipation(false)}
                footer={null}
                width={800}
                bodyStyle={{ padding: 0 }}
            >
                <Loading visible={participationLoading} cover height={modalHeight} />
                {!participationLoading && (
                    <TableWithPerfectScroll
                        className="cp-table-participation"
                        dataSource={userParticipation[participationPoll.surveyId] ? userParticipation[participationPoll.surveyId].data : []}
                        columns={[
                            {
                                title: fmtMsg(GSAdminLocale.NotificationTextName),
                                dataIndex: "name",
                                width: "40%",
                                render: (text, { id, name, hasCompleted, surveyResponseId }) =>
                                    hasCompleted ? (
                                        <Text underline>
                                            <a className="cp-action-participation" onClick={() => goToUserResponse(id, name, surveyResponseId)}>
                                                {text}
                                            </a>
                                        </Text>
                                    ) : (
                                            text
                                        )
                            },
                            {
                                title: fmtMsg(GSAdminLocale.NotificationTextEmail),
                                dataIndex: "email",
                                width: "40%"
                            },
                            {
                                title: fmtMsg(SurveyLocale.PollCompletedText),
                                dataIndex: "hasCompleted",
                                width: "20%",
                                render: (text: boolean) =>
                                    text ? (
                                        <Icon className="cp-icon" type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                                    ) : (
                                            <Icon className="cp-icon" type="clock-circle" />
                                        )
                            }
                        ]}
                        perfectScroll={{ y: modalHeight - 38 }}
                        rowKey="id"
                        pagination={false}
                    />
                )}
            </Modal>
        );
    };

    const goToReviewedPollsHistory = () => {
        history.push(SurveyPathConfig.ReviewHistory);
    };
    const onShowMoreClick = () => goToReviewedPollsHistory();
    const onReviewHistoryClick = () => goToReviewedPollsHistory();

    const navigateToFillSurvey = (reviewPoll: ReviewPollModel, isAction: boolean = false) => {
        const { id, surveyId, surveyResponseId, surveyReviewType, respondedBy } = reviewPoll;
        switch (surveyReviewType) {
            case PollReviewType.Todo:
                history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/complete`);
                break;
            case PollReviewType.CreatorShared:
                if (!isAction) {
                    history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/responses`);
                }
                else {
                    onShowParticipationClick(reviewPoll);
                }
                break;
            case PollReviewType.ReceiverShared:
                history.push(`${SurveyPathConfig.Home}/${surveyId}/${id}/contribute/${surveyResponseId || ""}`);
                break;

            default:
                break;
        }
    };

    const getFillSurveyHref = (reviewPoll: ReviewPollModel) => {
        const { id, surveyId, surveyResponseId, surveyReviewType } = reviewPoll;
        switch (surveyReviewType) {
            case PollReviewType.Todo:
                return `${SurveyPathConfig.Home}/${surveyId}/${id}/complete`;
            case PollReviewType.CreatorShared:
                return `${SurveyPathConfig.Home}/${surveyId}/${id}/responses`;
            case PollReviewType.ReceiverShared:
                return `${SurveyPathConfig.Home}/${surveyId}/${id}/contribute/${surveyResponseId || ""}`;
            default:
                return "javascript:void(0)";
        }
    }

    const getPollsDataSource = (polls: ReviewPollModel[]) => {
        return polls
            .filter(poll => !poll.isExpired).slice(0, CONSTS.MySurveyItemDisplayCount)
            .map(poll => ({
                ...poll,
                creatorName: typeof poll.creator === "string" ? poll.creator : poll.creator.name,
            }));
    };

    const getLandingPageDataCount = (polls: ReviewPollModel[]) => {
        return polls.filter(poll => !poll.isExpired).slice(0, CONSTS.MySurveyItemDisplayCount).length;
    };

    const renderCardHeaderActions = (): React.ReactNode => {
        return (
            <Actions
                noHeight
                actions={[
                    <Action
                        textProps={{ underline: true }}
                        key="0"
                        onClick={onReviewHistoryClick}
                        materialIcon="history"
                        textLocaleId={SurveyLocale.PollReviewHistory}
                    />
                ]}
            />
        );
    };

    const renderShowMore = (polls: ReviewPollModel[]): React.ReactNode => {
        if (!polls.length) {
            return null;
        }

        const pollsForLandingPage = polls.filter(poll => !poll.isExpired).slice(0, CONSTS.MySurveyItemDisplayCount);

        if (!pollsForLandingPage.length || pollsForLandingPage.length < CONSTS.MySurveyItemDisplayCount) {
            return null;
        }

        return (
            <span onClick={onShowMoreClick}>
                <Text underline type="secondary">{fmtMsg({ id: SurveyLocale.MySurveyShowMore })}</Text>
            </span>
        );
    };

    const columns = [
        {
            title: fmtMsg({ id: SurveyLocale.PollNameColumn }),
            dataIndex: "name",
            key: "name",
            render: (text: string, poll: ReviewPollModel) => (
                <Text underline>
                    <a href={getFillSurveyHref(poll)}>
                        {poll.surveyDisplayName ? poll.surveyDisplayName : text}
                    </a>
                </Text>
            ),
            width: "40%"
        },
        {
            title: fmtMsg({ id: SurveyLocale.PollDueDate }),
            dataIndex: "dueDate",
            key: "dueDate",
            render: (dueDate: Date, poll: ReviewPollModel) => {
                const isDue = isPastDate(poll.dueDate) && poll.surveyReviewType === PollReviewType.Todo;
                return <Text type={isDue && !poll.isExpired ? "danger" : null}>{DateHelper.formatDateAsIs(poll.dueDate as string)}</Text>;
            },
            width: "15%",
            align:'right'
        },
        {
            title: fmtMsg({ id: SurveyLocale.PollPublishedDate }),
            dataIndex: "publishedDate",
            key: "publishedDate",
            render: (publishedDate: Date) => DateHelper.formatDate2Local(publishedDate),
            width: "15%",
            align:'right'
        },
        {
            title: fmtMsg({ id: SurveyLocale.PollFrom }),
            dataIndex: "creatorName",
            key: "creator",
            width: "20%"
        },
        {
            title: fmtMsg({ id: SurveyLocale.PollAction }),
            // dataIndex: "surveyReviewType",
            key: "surveyReviewType",
            render: (surveyReviewType: PollReviewType, poll: ReviewPollModel) => (
                <Text underline>
                    <a onClick={() => navigateToFillSurvey(poll, true)} href="javascript:void(0)">
                        {getPollAction(poll)}
                    </a>
                </Text>
            ),
            width: "10%",
            align:'left'
        }
    ];

    const getReviewPollsGrid = () => {
        return (
            <Grid
                itemsSource={getPollsDataSource(reviewPolls.data || [])}
                pagination={true}
                loading={loading}
            >
                {columns.map((column)=>{
                    return (<Column
                                key={column.key}
                                header={column.title}
                                binding={column.dataIndex}
                                render={column.render}
                                align={column.align}
                            />);
                })}
            </Grid>
        );
    }

    return (
        <Card
            className="cp-card"
            type="inner"
            size="small"
            extra={renderCardHeaderActions()}
            actions={[renderShowMore(reviewPolls.data || [])]}
            title={`${fmtMsg({ id: SurveyLocale.PollsToReview })} ${loading ? "" : `(${getLandingPageDataCount(reviewPolls.data || [])})`}`}
        >
            <div className="rp-grid-container">
                {getReviewPollsGrid()}
            </div>
            {renderParticipationModal()}
        </Card>
    );
})
