import React from "react";
import { Table as AntdTable } from "antd-min";
import { PerfectScrollbar } from "@app/components";
import { TableComponents, TableProps } from "antd/lib/table";
import { mergeClasses } from "@app/util";
import "./table.less";

interface Table<T> extends TableProps<T> {
    perfectScroll?: TablePerfectScroll;
    className?: string;
}

export type TablePerfectScroll = {
    /** width */
    x?: number | boolean | string;
    /** height */
    y?: number | boolean | string;
    /** max-width */
    xMax?: number | boolean | string;
    /** max-height */
    yMax?: number | boolean | string;
};

export const TableWithPerfectScroll: React.FC<Table<any>> = props => {
    const { perfectScroll, className, ...rest } = props;

    const noPerfectScroll = !perfectScroll || (!perfectScroll.y && !perfectScroll.x && !perfectScroll.xMax && !perfectScroll.yMax);
    const components: TableComponents = noPerfectScroll ? {} : {
        table: TableWithPerfectScrollBar.bind(perfectScroll)
    }

    const classes = mergeClasses('gs-table', perfectScroll && 'gs-table--perfect-scrollbar', className);

    return <AntdTable scroll={perfectScroll} components={components} {...rest} className={classes} />;
};

const TableWithPerfectScrollBar: React.StatelessComponent<any> = function (this: any, props) {
    const { children } = props;
    const { x, y, xMax, yMax } = this;
    return (
        <PerfectScrollbar
            style={{ height: y, width: x, maxHeight: yMax, maxWidth: xMax }}
            options={{ wheelPropagation: true, minScrollbarLength: 50 }}>
            <table>{children}</table>
        </PerfectScrollbar>
    );
};
