import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Menu } from "antd-min";
import { UserAvatarProps, PathConfig as CommonPath, GLGlobal, GLUtil, OidcProxy, HeaderMenuArrowNavigatorIcon, GLLocale } from 'gl-commonui';
import { GSAdminAction, ContextHelper } from '../util/index';
import { PathConfig, SchoolPathConfig } from '../config/pathconfig';
import { FormattedMessage } from 'react-intl';

export function warppedUserAvatar(UserAvatar) {
    return class extends React.Component<UserAvatarProps, any> {
        menuDictionary: { [key: string]: string; } = {
            'dashboard': PathConfig.Dashboard,
            'invitationtemplates': PathConfig.InvitationTemplates,
            'invitations': SchoolPathConfig.Invitations,
            '/': PathConfig.Dashboard
        }
        constructor(props) {
            super(props);
            this.state = {
                menus: this.getMenus({ pathname: CommonPath.AccessDenied })
            };
        }
        redirectToPage(value) {
            if (value == 'signout') {
                OidcProxy.signoutRedirect();
            }
        }
        redirectProfile() {
            window.open(`${GLGlobal.authorityUrl()}/Account/Profile`);
        }
        getMenus(schoolUrl) {
            const needDisplayFirstDivider = GLGlobal.isActionValid(GSAdminAction.Dashboard || GSAdminAction.ListUser || GSAdminAction.Invitation);
            const needDisplaySecondDivider = GLGlobal.isActionValid(GSAdminAction.ListRegion || GSAdminAction.ListSchool || GSAdminAction.ListInvitationTemplate || GSAdminAction.ListGlobalProduct);
            return <Menu className="header-menu">
                {GLGlobal.isActionValid(GSAdminAction.Dashboard) && <Menu.Item><Link to={this.menuDictionary['dashboard']}>Dashboard</Link></Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ListUser) && <Menu.Item><Link to={this.menuDictionary['users']}>Users</Link></Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.Invitation) && <Menu.Item><Link to={this.menuDictionary['invitations']}>Invitations</Link></Menu.Item>}
                {needDisplayFirstDivider && <Menu.Divider />}
                {GLGlobal.isActionValid(GSAdminAction.ListRegion) && <Menu.Item><Link to={this.menuDictionary['regions']}>Regions</Link></Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ListSchool) && <Menu.Item><Link to={schoolUrl}>Schools</Link></Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ListInvitationTemplate) && <Menu.Item><Link to={this.menuDictionary['invitationtemplates']}>Invitation Templates</Link></Menu.Item>}
                {GLGlobal.isActionValid(GSAdminAction.ListGlobalProduct) && <Menu.Item><Link to={this.menuDictionary['globalproducts']}>Products</Link></Menu.Item>}
                {needDisplaySecondDivider && <Menu.Divider />}
                <Menu.Item className="navigator"><a href="javascript:void(0)" onClick={() => this.redirectProfile()}><span><FormattedMessage id={GLLocale.EditProfile} /></span><HeaderMenuArrowNavigatorIcon /></a></Menu.Item>
                <Menu.Item><div onClick={() => this.redirectToPage('signout')}><FormattedMessage id={GLLocale.Logout} /></div></Menu.Item>
            </Menu>
        }
        render() {
            return <UserAvatar {...this.props} />
        }
    }
}