import React, { Component, useState, useRef } from "react";
import { connect, formatRegionalUrl, GLGlobal, GLUtil, Role, RoleName } from "gl-commonui";
import { Message as MessageType, IChatService, MESSAGE_SENDER, IContentResourceService, ITrainingResourceService, MESSAGE_PER_PAGE_COUNT } from "@app/service/chat";
import {
    toggleChat,
    addUserMessage,
    addResponseMessage,
    resetMessages,
    unShiftResponseMessage,
    unShiftUserMessage,
    concatResponseMessage,
    concatUserMessage
} from "@app/states/coach/chatMessage";
import { DateHelper, lazyInject, TYPES, LanguageTimeFormatWithoutSecond } from "@app/util";
import { StateType } from "@app/states";
import { Icon, Spin, Skeleton, Avatar, List, message, Select, Input, Row, Col, Tabs } from "antd-min";
import classNames from "classnames";
import { SchoolPathConfig } from "@app/config/pathconfig";
import { SearchResource } from "./search-resource";
import Peer from "peerjs";
import "./chat-widget.less";
import { IUserService } from "@app/service/users";

interface ILoaderProps {
    typing: boolean;
}

export const Loader: React.FC<ILoaderProps> = ({ typing }: ILoaderProps) => {
    return (
        <div className={classNames("chat-loader", { active: typing })}>
            <div className="chat-loader-container">
                <span className="chat-loader-dots"></span>
                <span className="chat-loader-dots"></span>
                <span className="chat-loader-dots"></span>
            </div>
        </div>
    );
};

interface IMessageProps {
    message: MessageType;
    viewOnly?: boolean;
}

export const Message: React.FC<IMessageProps> = ({ message, viewOnly }: IMessageProps) => {
    return (
        <div className={`chat-rcw-${message.sender}`}>
            <div className="chat-rcw-message-text">
                {(viewOnly || message.showProfile) && !message.isSeries && <span className="chat-rcw-profile">{message.from}</span>}
                {!message.isSeries && (
                    <span className="chat-rcw-timestamp">{DateHelper.formatDateTime2Local(message.timestamp.toISOString(), false, null, true)}</span>
                )}
                <pre dangerouslySetInnerHTML={{ __html: formatRegionalUrl(message.text) }}></pre>
            </div>
        </div>
    );
};

interface ISenderProps {
    placeholder: string;
    autofocus: boolean;
    sendMessage: (event: any) => void;
    onTextInputChange?: (event: any) => void;
    searchable: boolean;
    receiver: any;
}

export const Sender: React.FC<ISenderProps> = ({ sendMessage, placeholder, autofocus, searchable, receiver }: ISenderProps) => {
    const [rows, setRows] = useState(1);
    const [value, setValue] = useState<string>();
    const [resourcePanelVisible, setResourcePanelVisible] = useState<boolean>(false);

    const messageRef = useRef(null);

    const handleChange = (event: any) => {
        setValue(event.target.value);

        const textareaLineHeight = 24;
        const minRows = 1;
        const maxRows = 5;
        const previousRows = event.target.rows;
        event.target.rows = minRows;

        const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }
        setRows(currentRows < maxRows ? currentRows : maxRows);
    };

    const handleOnResourceSelect = (resource: any) => {
        messageRef.current.value = resource.resourceName;
        setValue(`<a href=${resource.resourceUrl}>${resource.resourceName}</a>`);
    };

    const handleSubmit = () => {
        sendMessage(value);
        messageRef.current.value = "";
        messageRef.current.rows = 1;
        setValue("");
    };

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const accessResource = {
        regionId: GLUtil.pathParse(SchoolPathConfig.CoachVisitation).regionId,
        schoolId: GLUtil.pathParse(SchoolPathConfig.CoachVisitation).schoolId,
        teacherId: receiver.id,
    };

    return (
        <>
            <div className="chat-rcw-sender-block">
                <div className="chat-rcw-sender-block-header">
                    <div className={searchable ? "chat-rcw-sender-with-search" : "chat-rcw-sender"}>
                        <textarea
                            rows={rows}
                            className="chat-rcw-new-message"
                            name="message"
                            placeholder={placeholder}
                            autoFocus={autofocus}
                            autoComplete="off"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            spellCheck={false}
                            ref={messageRef}
                        />
                        <button className="chat-rcw-send" onClick={handleSubmit}>
                            <Icon type="right" />
                        </button>
                    </div>
                    {searchable && (
                        <button onClick={() => setResourcePanelVisible(!resourcePanelVisible)} className="chat-resource-open">
                            <Icon type="file-search" />
                        </button>
                    )}
                </div>
                {searchable && (
                    <SearchResource
                        onResourceSelect={handleOnResourceSelect}
                        resourceModel={accessResource}
                        resourcePanelVisible={resourcePanelVisible}
                    />
                )}
            </div>
        </>
    );
};

interface IChatMessageProps {
    chatMessageId?: string;
    chatGroupId: string;
    chatThreadId: string;
    messages?: any;
    senderId: string;
    receiver: any;
    handleNewUserMessage: any;
    handleMessage: any;
    hanldeConcatMessage: any;
    hanldeConcatNewUserMessage: any;
    avatarLink?: { sender: string; receiver: string };
    getBadgeCount: (id) => void;
    onMessageRefresh: (ref: any, messageRef: any) => void;
    messageOffset?: number;
    viewOnly?: boolean;
}

interface IChatMessageStates {
    offset: number | null;
    frontOffset: number;
    loading: boolean;
}

@connect(({ chatMessage: { messages } }: StateType) => ({ messages }))
export class ChatMessage extends Component<IChatMessageProps, IChatMessageStates> {
    @lazyInject(TYPES.IChatService)
    chatService: IChatService;
    messagesEnd: any;
    messageLooked: any;
    messagesContent: any;
    constructor(props: IChatMessageProps) {
        super(props);

        this.state = {
            offset: props.messageOffset || (1 * MESSAGE_PER_PAGE_COUNT),
            loading: false,
            frontOffset: props.messageOffset && props.messageOffset - (1 * MESSAGE_PER_PAGE_COUNT) > 0
                ? props.messageOffset - (1 * MESSAGE_PER_PAGE_COUNT) : 0
        };
    }

    componentDidMount() {
        this.props.onMessageRefresh(this.messagesEnd, this.messageLooked);
    }

    getComponentToRender = (message) =>
        <div
            className={message.sender === MESSAGE_SENDER.RESPONSE ?
                'chat-rcw-sender-message-container' :
                'chat-rcw-receiver-message-container'}
        >
            {!message.isSeries && (this.props.viewOnly || message.sender === MESSAGE_SENDER.RESPONSE) && <Avatar
                className="school-user-header-avatar-cover"
                shape="circle"
                icon="user"
                src={message.sender === MESSAGE_SENDER.RESPONSE ?
                        this.props.avatarLink && this.props.avatarLink.receiver :
                        this.props.avatarLink && this.props.avatarLink.sender
                    }
            />}
            {message.isSeries && <div className="chat-user-series-avatar" />}
            <Message message={message} viewOnly={this.props.viewOnly} />
            {/* {!message.unread && <Icon type="eye" />}
            {message.unread && <Icon type="checkcircle" />} */}
        </div>

    onScrollEvent = async () => {
        const { receiver, handleMessage, handleNewUserMessage, hanldeConcatMessage, hanldeConcatNewUserMessage, getBadgeCount, chatThreadId } = this.props;
        if (this.messagesContent.scrollTop === 0 && this.state.offset !== null) {
            this.setState({ loading: true });
            const message = await this.chatService.getChatMessages({
                threadId: chatThreadId,
                offset: this.state.offset,
                limit: MESSAGE_PER_PAGE_COUNT,
            });

            if (message && message.data.length !== 0 && receiver) {
                message.data.forEach((v) => {
                    if (v.senderId === receiver.id) {
                        handleNewUserMessage(v.id, v.message, v.senderName, false, new Date(v.sendAt));
                        v.isRead || this.chatService.makeMessagesRead({ messageId: v.id });
                    } else {
                        handleMessage(v.id, v.message, v.senderName, false, new Date(v.sendAt));
                    }
                });

                this.setState({
                    offset: this.state.offset + MESSAGE_PER_PAGE_COUNT,
                });
                this.messagesContent.scrollTop = this.messagesContent.scrollHeight / 4;
            } else {
                this.setState({
                    offset: null
                });
            }
            getBadgeCount(receiver.id);
            this.setState({ loading: false });
        }

        if (this.props.messageOffset &&
            this.state.frontOffset > 0 &&
            this.messagesContent.scrollTop + this.messagesContent.clientHeight === this.messagesContent.scrollHeight
        ) {
            this.setState({ loading: true });
            const message = await this.chatService.getChatMessages({
                threadId: chatThreadId,
                offset: this.state.frontOffset - MESSAGE_PER_PAGE_COUNT > 0 ? this.state.frontOffset - MESSAGE_PER_PAGE_COUNT : 0,
                limit: this.state.frontOffset >= MESSAGE_PER_PAGE_COUNT ? MESSAGE_PER_PAGE_COUNT : this.state.frontOffset,
            });

            if (message && message.data.length !== 0 && receiver) {
                message.data.reverse().forEach((v) => {
                    if (v.senderId === receiver.id) {
                        hanldeConcatNewUserMessage(v.id, v.message, v.senderName, false, new Date(v.sendAt));
                        v.isRead || this.chatService.makeMessagesRead({ messageId: v.id });
                    } else {
                        hanldeConcatMessage(v.id, v.message, v.senderName, false, new Date(v.sendAt));
                    }
                });

                this.setState({
                    frontOffset: this.state.frontOffset - MESSAGE_PER_PAGE_COUNT > 0 ? this.state.frontOffset - MESSAGE_PER_PAGE_COUNT : 0,
                });
                this.messagesContent.scrollTop = (this.messagesContent.scrollHeight / 4) * 3;
            }
            getBadgeCount(receiver.id);
            this.setState({ loading: false });
        }
    };

    render() {
        const { messages } = this.props;
        return (
            <div
                id="messages"
                className="chat-rcw-messages-container"
                ref={(el) => {
                    this.messagesContent = el;
                }}
                onScrollCapture={() => this.onScrollEvent()}
            >
                <Spin spinning={this.state.loading}>
                    {messages.map((message, index) => (
                        <div
                            className={message.isSeries ? "chat-rcw-message-series" : "chat-rcw-message" }
                            key={`${index}-${DateHelper.formatDateTime2Local(message.timestamp.toISOString())}`}
                        >
                            {this.getComponentToRender(message)}
                            {this.props.chatMessageId && message.id === this.props.chatMessageId && <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                    this.messageLooked = el;
                                }}
                            ></div>}
                        </div>
                    ))}
                    <div
                        style={{ float: "left", clear: "both" }}
                        ref={(el) => {
                            this.messagesEnd = el;
                        }}
                    ></div>
                    <Loader typing={true} />
                </Spin>
            </div>
        );
    }
}

interface IConversationProps {
    isInputable: boolean;
    chatMessageId?: string;
    chatGroupId: string;
    chatThreadId: string;
    senderPlaceHolder: string;
    autofocus: boolean;
    sendMessage: any;
    senderId: string;
    receiver: any;
    handleNewUserMessage: any;
    handleMessage: any;
    hanldeConcatMessage: any,
    hanldeConcatNewUserMessage: any,
    searchable: boolean;
    avatarLink?: { sender: string; receiver: string };
    getBadgeCount: (id) => void;
    onMessageRefresh: (ref: any, messageRef: any) => void;
    viewOnly?: boolean;
    messageOffset?: number;
}

export const Conversation: React.FC<IConversationProps> = ({
    isInputable,
    chatMessageId,
    chatGroupId,
    chatThreadId,
    sendMessage,
    senderPlaceHolder,
    autofocus,
    senderId,
    receiver,
    handleNewUserMessage,
    handleMessage,
    hanldeConcatMessage,
    hanldeConcatNewUserMessage,
    searchable,
    getBadgeCount,
    onMessageRefresh,
    avatarLink,
    viewOnly = false,
    messageOffset
}: IConversationProps) => {
    return (
        <>
            <div className="chat-rcw-widget-content">
                <ChatMessage
                    chatMessageId={chatMessageId}
                    chatGroupId={chatGroupId}
                    chatThreadId={chatThreadId}
                    senderId={senderId}
                    receiver={receiver}
                    handleMessage={handleMessage}
                    handleNewUserMessage={handleNewUserMessage}
                    hanldeConcatMessage={hanldeConcatMessage}
                    hanldeConcatNewUserMessage={hanldeConcatNewUserMessage}
                    getBadgeCount={getBadgeCount}
                    onMessageRefresh={onMessageRefresh}
                    avatarLink={avatarLink}
                    messageOffset={messageOffset}
                    viewOnly={viewOnly}
                />
            </div>
            {isInputable && !viewOnly ? (
                <Sender sendMessage={sendMessage} placeholder={senderPlaceHolder} autofocus={autofocus} searchable={searchable} receiver={receiver} />
            ) : null}
        </>
    );
};

interface IChatWidgetProps {
    visitationId?: string;
    chatGroupId?: string;
    chatThreadId: string;
    // chatContextId?: string;
    chatMessageId?: string;
    senderPlaceHolder?: string;
    autofocus: boolean;
    senderId?: string;
    receiver?: any;
    searchable: boolean;
    getBadgeCount: (id) => void;
    viewOnly?: boolean;
}

interface IChatWidgetStates {
    loading: boolean;
    isInputable: boolean;
    avatarLink?: { sender: string; receiver: string };
    chatContextId?: string;
    messageOffset?: number;
}

export class ChatWidget extends Component<IChatWidgetProps, IChatWidgetStates> {
    @lazyInject(TYPES.IChatService)
    chatService: IChatService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    peer: Peer;
    conn: Peer.DataConnection;
    dispatch: any;
    lastSendTime: Date;
    lastReceiveTime: Date;
    lastMessageType: string;
    scrollRef: any;
    messageRef: any;
    constructor(props: IChatWidgetProps) {
        super(props);

        const receiverId = this.props.receiver && this.props.receiver.id;
        this.dispatch = GLGlobal.store.dispatch;
        this.peer = new Peer(`${this.props.senderId}-${props.chatThreadId}-${receiverId ? receiverId : ""}`, GLGlobal.processEnv().peerJSOption as any);
        this.lastReceiveTime = new Date();
        this.state = { loading: true, isInputable: true  };

        if (this.peer && receiverId && this.props.senderId) {
            this.peer.on("open", () => {
                const conn = this.peer.connect(`${receiverId}-${props.chatThreadId}-${this.props.senderId}`);
                conn.on("open", () => {
                    this.conn = conn;
                });
            });

            this.peer.on("connection", (c) => {
                if (!this.conn) {
                    const conn = this.peer.connect(`${receiverId}-${props.chatThreadId}-${this.props.senderId}`);
                    conn.on("open", () => {
                        this.conn = conn;
                    });
                }

                c.on("data", (data) => {
                    const receiveMessage = JSON.parse(data);
                    this.lastReceiveTime = this.zipMessage(
                        receiveMessage.id,
                        this.lastReceiveTime,
                        new Date(receiveMessage.sendAt),
                        receiveMessage.message,
                        props.receiver.name,
                        addResponseMessage,
                        MESSAGE_SENDER.RESPONSE
                    );
                    this.lastMessageType = MESSAGE_SENDER.RESPONSE;
                    this.scrollIntoView();
                    this.chatService.makeMessagesRead({ messageId: receiveMessage.id });
                });
                c.on("close", () => {
                    this.conn = null;
                });
            });
        }
    }

    toggleConversation = () => {
        toggleChat();
    };

    async componentDidMount() {
        if (this.peer) {
            this.peer.reconnect();
        }

        const { receiver, senderId } = this.props;

        let messages;

        if (!this.props.chatMessageId) {
            const dataSource = await this.chatService.getChatMessages({
                threadId: this.props.chatThreadId,
                offset: 0,
                limit: MESSAGE_PER_PAGE_COUNT,
            });

            messages = dataSource.data;
        } else {
            const dataSource = await this.chatService.getNearByMessages({
                attenders: [this.props.senderId, this.props.receiver && this.props.receiver.id],
                messageId: this.props.chatMessageId,
                chatThreadId: this.props.chatThreadId,
                limit: MESSAGE_PER_PAGE_COUNT
            });

            messages = dataSource.messages;

            this.setState({
                messageOffset: dataSource.offset
            });
        }

        messages &&
            receiver &&
            messages.reverse().forEach((v) => {
                if (v.senderId === receiver.id) {
                    this.lastReceiveTime = this.zipMessage(
                        v.id,
                        this.lastReceiveTime,
                        new Date(v.sendAt),
                        v.message,
                        v.senderName,
                        addResponseMessage,
                        MESSAGE_SENDER.RESPONSE
                    );
                    this.lastMessageType = MESSAGE_SENDER.RESPONSE;
                    v.isRead || this.chatService.makeMessagesRead({ messageId: v.id });
                } else {
                    this.lastSendTime = this.zipMessage(
                        v.id,
                        this.lastSendTime,
                        new Date(v.sendAt),
                        v.message,
                        v.senderName,
                        addUserMessage,
                        MESSAGE_SENDER.CLIENT
                    );
                    this.lastMessageType = MESSAGE_SENDER.CLIENT;
                }
            });

        if (!this.props.viewOnly) {
            await this.chatService.getChatThreadContext({
                groupId: this.props.chatGroupId, threadId: this.props.chatThreadId
            }).then(data => {
                typeof data === 'number' && this.setState({
                    loading: false,
                    chatContextId: data.toString()
                });
            }).catch(error => {
                console.log(error);
            })
        } else {
            this.setState({
                loading: false,
            });
        }

        receiver && this.props.getBadgeCount(receiver.id);
        !this.props.chatMessageId && this.scrollIntoView();
        this.props.chatMessageId && this.scrollIntoMessage();

        if (receiver || senderId) {
            const receverAvatar = await this.userService.getUserAvatarSasUri({
                id: receiver.id,
                expirationminutes: 120
            });
            const senderAvatar = await this.userService.getUserAvatarSasUri({
                id: senderId,
                expirationminutes: 120
            });

            this.setState({
                avatarLink: { sender: senderAvatar, receiver: receverAvatar }
            });
        }
    }

    componentWillUnmount() {
        if (this.peer) {
            this.peer.destroy();
        }
        this.dispatch(resetMessages());
    }

    handleSubmit = async (messageText: string) => {
        const { chatGroupId } = this.props;
        const { chatContextId } = this.state;
        return this.chatService.createNewMessage({
            chatGroupId: chatGroupId,
            chatContextId: chatContextId,
            receiverId: this.props.receiver.id,
            message: messageText,
        });
    };

    handleUnshiftMessage = (id: string, message: any, from: any, isSeries: boolean, sendAt?: Date) => {
        this.dispatch(unShiftUserMessage(id, message, from, isSeries, sendAt));
    };

    handleUnshiftNewUserMessage = (id: string, newMessage: any, from: string, isSeries: boolean, sendAt?: Date) => {
        this.dispatch(unShiftResponseMessage(id, newMessage, from, isSeries, sendAt));
    };

    handleConcatMessage = (id: string, message: any, from: any, isSeries: boolean, sendAt?: Date) => {
        this.dispatch(concatUserMessage(id, message, from, isSeries, sendAt));
    };

    handleConcatNewUserMessage = (id: string, newMessage: any, from: string, isSeries: boolean, sendAt?: Date) => {
        this.dispatch(concatResponseMessage(id, newMessage, from, isSeries, sendAt));
    };

    handleMessageSubmit = async (userInput: string) => {
        if (!userInput.trim()) {
            return;
        }

        const sendDate = new Date();
        const messageId = await this.handleSubmit(userInput);
        this.lastSendTime = this.zipMessage(messageId, this.lastSendTime, sendDate, userInput, "", addUserMessage, MESSAGE_SENDER.CLIENT);

        if (messageId) {
            if (this.conn) {
                this.conn.send(
                    JSON.stringify({
                        id: messageId,
                        message: userInput,
                        sendAt: sendDate,
                    })
                );
            }
            this.lastMessageType = MESSAGE_SENDER.CLIENT;
            this.scrollIntoView();
        }
    };

    zipMessage(id: string, recordTime: Date, sendTime: Date, message: string, from: string, sendMessage: any, type: string): Date {
        const isSeriesMessage = this.minutesTimeDiff(recordTime, sendTime, 3);

        if (!recordTime || !isSeriesMessage) {
            this.dispatch(sendMessage(id, message, from, false, sendTime));
            return sendTime;
        }

        if (this.lastMessageType === type) {
            this.dispatch(sendMessage(id, message, from, true, sendTime));
        } else {
            this.dispatch(sendMessage(id, message, from, false, sendTime));
        }
        return recordTime;
    }

    minutesTimeDiff(firstDate: Date, secondDate: Date, time: number) {
        if (firstDate && secondDate) {
            return Math.round((secondDate.getTime() - firstDate.getTime()) / 60000) < time;
        }
        return false;
    }

    onMessageRefresh = (ref: any, messageRef: any) => {
        this.scrollRef = ref;
        this.messageRef = messageRef;
    };

    scrollIntoView = () => {
        if (this.scrollRef) {
            this.scrollRef.scrollIntoView({ behavior: "smooth" });
        }
    };

    scrollIntoMessage = () => {
        console.log(this.messageRef);
        if (this.messageRef) {
            this.messageRef.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        const { senderPlaceHolder, autofocus, senderId, receiver, searchable, getBadgeCount } = this.props;

        return (
            <div className="chat-rcw-widget-container">
                {this.state.loading ?
                    <div>
                        {[...Array(3).keys()].map(i => (
                            <Skeleton
                                key={i}
                                className="chat-rcw-widget-container-spin"
                                paragraph={{ rows: 3, width: 0 }}
                                loading={true}
                                active
                                avatar
                            />
                        ))}
                    </div> :
                    <Conversation
                        isInputable={this.state.isInputable}
                        chatMessageId={this.props.chatMessageId}
                        chatGroupId={this.props.chatGroupId}
                        chatThreadId={this.props.chatThreadId}
                        sendMessage={this.handleMessageSubmit}
                        senderPlaceHolder={senderPlaceHolder}
                        autofocus={autofocus}
                        senderId={senderId}
                        receiver={receiver}
                        handleNewUserMessage={this.handleUnshiftNewUserMessage}
                        handleMessage={this.handleUnshiftMessage}
                        hanldeConcatMessage={this.handleConcatMessage}
                        hanldeConcatNewUserMessage={this.handleConcatNewUserMessage}
                        avatarLink={this.state.avatarLink}
                        searchable={searchable}
                        getBadgeCount={getBadgeCount}
                        onMessageRefresh={this.onMessageRefresh}
                        viewOnly={this.props.viewOnly}
                        messageOffset={this.state.messageOffset}
                    />
                }
            </div>
        );
    }
}
