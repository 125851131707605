import {
    EventType,
    PendingChangeField,
    LicenseEditNoteType,
    ChangeLogType,
    SchoolChangeField,
    CampusChangeField,
    HybridChangeField,
    EventInfo,
    MovePromoteOperationType
} from "@app/util";
import { SchoolModel, CampusModel } from "../schools";

export interface ChangeLogModel {
    id?: string;
    regionId?: string;
    regionName?: string;
    schoolId?: string;
    schoolName?: string;
    campusId?: string;
    campusName?: string;
    schoolClassId?: string;
    schoolClassName?: string;
    type?: number;
    type4Display?: string;
    description?: string;
    status?: number;
    createdBy?: string;
    createdDate?: string;
    createdDate4Display?: string;
    subscriptionTypeUsage?: number;
    target?: number;
    materialOrderId?: string;
    materialRequestId?: string;
}
export enum ChangeLogPropsModel {
    regionId = "regionId",
    regionName = "regionName",
    schoolId = "schoolId",
    schoolName = "schoolName",
    campusId = "campusId",
    campusName = "campusName",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    type = "type",
    type4Display = "type4Display",
    status = "status",
    statusText = "statusText",
    createdBy = "createdBy",
    createdDate = "createdDate",
    createdDate4Display = "createdDate4Display"
}

export interface SchoolAuditLogModel {
    id?: string;
    campusId?: string;
    campusName?: string;
    schoolClassId?: string;
    schoolClassName?: string;
    type?: number;
    type4Display?: string;
    description?: string;
    status?: number;
    createdBy?: string;
    createdDate?: string;
    createdDate4Display?: string;
    subscriptionTypeUsage?: number;
}
export enum SchoolAuditLogPropsModel {
    campusId = "campusId",
    campusName = "campusName",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    type = "type",
    type4Display = "type4Display",
    status = "status",
    createdBy = "createdBy",
    createdDate = "createdDate",
    createdDate4Display = "createdDate4Display"
}

export interface EventModalContentProps<T> {
    target?: ChangeLogType;
    type?: EventInfo;
    visible: boolean;
    loading: boolean;
    subTitle: string;
    schoolClassName: string;
    item: any;
    onCancel: (event) => void;
    onApprove: (event) => void;
    onDeny: (event) => void;
    verificationCIMSId?: string;
    history?: History;
    showResolvedEvent: boolean;
    lastChangeItem: any;
}

export interface SchoolClassModel {
    id?: string;
    name?: string;
    age?: string;
    licenseType?: number;
    curriculumType?: number;
    tsiLessonsPerWeek?: number;
    startUnit?: number;
    endUnit?: number;
    startDate?: string;
    endDate?: string;
}

export interface ChangeEventModel {
    schoolClassId?: string;
    schoolClassName?: string;
    subscriptionTypeUsage?: number;
    age?: string;
    licenseType?: number;
    curriculumType?: number;
    tsiLessonsPerWeek?: number;
    startUnit?: number;
    endUnit?: number;
    startDate?: string;
    endDate?: string;
    createdBy?: string;
    createdDate?: string;
}
export enum ChangeEventPropsModel {
    schoolClassName = "schoolClassName",
    createdBy = "createdBy",
    createdDate = "createdDate"
}

export enum MovePromoteEventPropsModel {
    id = "id",
    currentClassId = "currentClassId",
    currentClassName = "currentClassName",
    previousClassId = "previousClassId",
    previousClassName = "previousClassName",
    parentClassId = "parentClassId",
    parentClassName = "parentClassName",
    name = "name",
    englishName = "englishName",
    operationType = "operationType",
    note = "note",
    createdDate = "createdDate"
}

export interface LicenseCountModel {
    newDigitalLicenses?: number;
    newStudentCount?: number;
    newTextbookLicenses?: number;
}
export interface UnitPlanModel {
    unit?: number;
    startDate?: string;
    endDate?: string;
}
export interface StudentModel {
    id?: string;
    name?: string;
    englishName?: string;
}
export interface EventModel {
    id?: string;
    creationDate?: string;
}
export enum EventPropsModel {
    creationDate = "creationDate"
}
export interface ClassAddedEventModel extends ChangeEventModel {}
export interface LicenseUpdatedEventModel extends ChangeEventModel {
    currentLicenses?: LicenseCountModel;
    previousLicenses?: LicenseCountModel;
}
export enum LicenseChangePropsModel {
    eventType = "eventType",
    eventType4Display = "eventType4Display",
    detail = "detail",
    digitalLicenseEditNoteType = "digitalLicenseEditNoteType",
    digitalLicenseEditNote = "digitalLicenseEditNote",
    textbookLicenseEditNoteType = "textbookLicenseEditNoteType",
    textbookLicenseEditNote = "textbookLicenseEditNote"
}
export interface UnitPlanChangeEventModel extends ChangeEventModel {
    currentPlans?: UnitPlanModel[];
    previousPlans?: UnitPlanModel[];
}
export interface SchoolMandatoryAttributeModel extends ChangeEventModel {
    previousSchool?: SchoolModel;
    currentSchool?: SchoolModel;
}

export interface MovePromoteStudentsModel extends ChangeEventModel {
    students: MovePromoteStudentInfo[];
}
export interface MovePromoteStudentInfo {
    id: string;
    currentClassId?: string;
    currentCampusId?: string;
    currentSchoolId?: string;
    currentRegionId?: string;
    currentClassName?: string;
    previousClassId?: string;
    previousCampusId?: string;
    previousSchoolId?: string;
    previousRegionId?: string;
    previousClassName?: string;
    parentClassId?: string;
    parentCampusId?: string;
    parentSchoolId?: string;
    parentRegionId?: string;
    parentClassName?: string;
    name: string;
    englishName: string;
    operationType: MovePromoteOperationType;
    note: string;
    createdDate: Date;
}
export interface CampusMandatoryAttributeModel extends ChangeEventModel {
    previousCampus?: CampusModel;
    currentCampus?: CampusModel;
}
export interface MandatoryAttributeModel extends ChangeEventModel {
    previousClass?: SchoolClassModel;
    currentClass?: SchoolClassModel;
}
export interface StudentMovementModel extends ChangeEventModel {
    currentClassId?: string;
    currentClassName?: string;
    previousClassId?: string;
    previousClassName?: string;
    students?: StudentModel[];
    licenseCount?: number;
}
export interface StudentRemovedModel extends ChangeEventModel {
    student?: StudentModel;
}
export interface StudentSubscriptionChangedModel extends ChangeEventModel {
    currentSubscriptionType?: number;
    previousSubscriptionType?: number;
    student?: StudentModel;
}

export interface ChangeModel {
    id?: string;
    createdBy?: string;
    creationDate?: string;
}

export enum ChangePropsModel {
    createdBy = "createdBy",
    creationDate = "creationDate"
}

export interface PendingChangeFieldModel {
    changeField?:
        | PendingChangeField
        | SchoolChangeField
        | CampusChangeField
        | HybridChangeField;
    events?: EventModel[];
}

export interface PendingChangeModel {
    regionId?: string;
    regionName?: string;
    schoolId?: string;
    schoolName?: string;
    campusId?: string;
    campusName?: string;
    schoolClassId?: string;
    schoolClassName?: string;
    changeFields?: PendingChangeFieldModel[];
    curriculumType?: number;
    curriculumType4Display?: string;
    grade?: string;
    studentCount?: number;
    startDate?: string;
    startDate4Display?: string;
    startDate4ClientSort?: Date;
    tsiLessonsPerWeek?: number;
    startUnit?: number;
    startUnit4Display?: number;
    endUnit?: number;
    endUnit4Display?: number;
    plans?: string[];
    changeType: number;
    changeType4Group: number;
    resourceType?: number;
    changes?: ChangeModel[];
    updateBy?: string;
    updateTime?: string;
    updateTime4Display?: string;
    updateTime4ClientSort?: Date;
    materialOrderId?: string;
    materialRequestId?: string;
    isFutureClassChange?: boolean;
    isInCurrentAndFuture?: boolean;
    ownerId: string;
    ownerName: string;
    changesGroupId: string;
    caseNo: string;
}

export enum PendingChangePropsModel {
    regionId = "regionId",
    regionName = "regionName",
    schoolId = "schoolId",
    schoolName = "schoolName",
    schoolEnglishName = "schoolEnglishName",
    campusId = "campusId",
    campusName = "campusName",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    licenseType = "licenseType",
    licenseType4Display = "licenseType4Display",
    curriculumType = "curriculumType",
    curriculumType4Display = "curriculumType4Display",
    grade = "grade",
    age = "age",
    studentCount = "studentCount",
    startDate = "startDate",
    startDate4Display = "startDate4Display",
    startDate4ClientSort = "startDate4ClientSort",
    endDate = "endDate",
    endDate4Display = "endDate4Display",
    tsiLessonsPerWeek = "tsiLessonsPerWeek",
    startUnit = "startUnit",
    startUnit4Display = "startUnit4Display",
    endUnit = "endUnit",
    endUnit4Display = "endUnit4Display",
    plans = "plans",
    plansIndexed1 = "plansIndexed1",
    plansIndexed2 = "plansIndexed2",
    plansIndexed3 = "plansIndexed3",
    plansIndexed4 = "plansIndexed4",
    plansIndexed5 = "plansIndexed5",
    plansIndexed6 = "plansIndexed6",
    plansIndexed7 = "plansIndexed7",
    plansIndexed8 = "plansIndexed8",
    changeType = "changeType",
    changeType4Group = "changeType4Group",
    resourceType = "resourceType",
    updateBy = "updateBy",
    updateTime = "updateTime",
    updateTime4Display = "updateTime4Display",
    updateTime4ClientSort = "updateTime4ClientSort",
    name = "name",
    englishName = "englishName",
    phone = "phone",
    city = "city",
    postalCode = "postalCode",
    fullAddress = "fullAddress",
    licenseList = "nexusLicenseType",
    caseNo = "caseNo",
    caseNo4ClientSort = "caseNo4ClientSort",
    ownerId = "ownerId",
    ownerName = "ownerName",
    changesGroupId = "changesGroupId"
}

export interface ApproveDenyModalContentProps<T>
    extends EventModalContentProps<T> {
    //changeField: PendingChangeField
    changeType: number;
    lastChangeItem?: T;
    onApprove: (event) => void;
    onDeny: (event) => void;
    onCancel: (event) => void;
    verificationCIMSId?: string;
    showCloseButton?: boolean;
    history?: History;
}

export interface LicenseChangeApproveDenyEventModel extends ChangeEventModel {
    currentStatus?: LicenseCountModel;
    previousStatus?: LicenseCountModel;
    eventType?: number;
    digitalLicenseEditNoteType?: LicenseEditNoteType;
    digitalLicenseEditNote?: string;
    textbookLicenseEditNoteType?: LicenseEditNoteType;
    textbookLicenseEditNote?: string;
    shouldCombine?: boolean;
}

export interface LicenseChangeTypeContentProps<T> {
    item: T;
}

export interface UnitPlanApproveDenyModel extends ChangeEventModel {
    changeData?: any[];
    unitColumns?: { displayName: string; columnName: string }[];
    previousUnits?: any[];
    currentUnits?: any[];
}

export interface CampusClassChanges {
    campusId?: string;
    classesChanges?: PendingChangeModel[];
}

export interface PendingChangeOwnerModel {
    userId: string;
    name: string;
}
