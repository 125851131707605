export interface DrawerState {
    isDrawerVisible?: boolean
}

export default {
    namespace: 'drawer',
    state: {
        isDrawerVisible: false
    },
    reducers: {
        setIsDrawerVisible(state, action) {
            return { ...state, isDrawerVisible: action.payload}
        }
    }
}

export function setIsDrawerVisible(isVisible: boolean) {
    return {type: 'drawer/setIsDrawerVisible', payload: isVisible}
}
