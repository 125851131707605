import * as React from 'react';
import {useEffect, useState} from 'react';
import {Select} from 'antd-min';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import MobileDetect from "mobile-detect";
import * as FileSaver from 'file-saver';
import {
    GLGlobal,
    LanguageDateFormat,
    LanguageTimeFormat,
    LoginInfo,
    MessageHelper,
    NotificationType,
    PaginationParams,
    PrimaryLanguage,
    Role,
    RoleName
} from 'gl-commonui';
import {DashboardLocale, GSAdminLocale, SchoolLocale} from '../locales/localeid';
import * as moment from 'moment';
import {
    CumulativeDataItemModel,
    MaterialOrdersModel,
    PurchaseItemDisplayModel,
    RequestProductItemModel,
    SubscriptionItemDisplayModel,
    SubscriptionItemModel
} from '../service/material/model';
import {UserModel} from '../service/users/model';
import {fmtMsg, guid, sliceMoment} from './func';
import {CONSTS} from './consts';
import {
    AppLockState,
    GSSchoolAction,
    InvitationChannel,
    InvitationLanguageText,
    InvitationType,
    InvitationTypeText,
    LanguageTimeFormatWithoutSecond,
    LicenseEditNoteType,
    ProductType,
    SchoolClassSubscriptionType,
    SchoolClassSubscriptionTypeText,
    SchoolClassSubscriptionTypeUsage,
    SchoolClassSubscriptionTypeUsageText,
    SchoolCurriculumType,
    SessionStorageKeys,
    TemplateTagCode,
    TemplateTagText
} from './enum';
import {RegionModel, RegionSchoolRoleModel} from '@app/service/admin/regions';
import uniqBy from 'lodash/uniqBy';
import {UserService} from '@app/service/users';
import * as wjcXlsx from 'wijmo/wijmo.xlsx';
import {ColumnProps} from "antd/lib/table";
import {DataType} from 'wijmo/wijmo';
import {isNull, isUndefined} from 'lodash';
import {GLRegistrationLocalization} from '@app/components/register/Model';
import {getUnitText} from "@app/page/school/material/util";

export class SchoolHelper {

    static generateSchoolSubscriptionType(includedTextbook = true) {
        let subscriptionTypes = new Map<string, string>();
        subscriptionTypes.set(SchoolClassSubscriptionType.Digital, SchoolClassSubscriptionTypeText.Digital);
        if (includedTextbook) {
            subscriptionTypes.set(SchoolClassSubscriptionType.Textbook, SchoolClassSubscriptionTypeText.Textbook);
        }
        return subscriptionTypes;
    }

    static generateSchoolSubscriptionTypeUsage() {
        let subscriptionTypesUsage = new Map<string, string>();
        subscriptionTypesUsage.set(SchoolClassSubscriptionTypeUsage.Standard, SchoolClassSubscriptionTypeUsageText.Standard);
        subscriptionTypesUsage.set(SchoolClassSubscriptionTypeUsage.Dual, SchoolClassSubscriptionTypeUsageText.Dual);
        subscriptionTypesUsage.set(SchoolClassSubscriptionTypeUsage.Single, SchoolClassSubscriptionTypeUsageText.Single);
        return subscriptionTypesUsage;
    }

    static generateSchoolCurriculumType(addNoneType = false) {
        let curriculumTypes = new Map<string, string>();
        if (addNoneType) {
            curriculumTypes.set(SchoolCurriculumType.None, "　");
        }
        curriculumTypes.set(SchoolCurriculumType.GrapeSEED, "GrapeSEED");
        curriculumTypes.set(SchoolCurriculumType.LittleSEED, "LittleSEED");
        return curriculumTypes;
    }

    static generateProductType() {
        let productTypes = new Map<ProductType, string>();
        productTypes.set(ProductType.General, fmtMsg({ id: GSAdminLocale.ProductProductTypeGeneral }));
        productTypes.set(ProductType.GrapeSeed, fmtMsg({ id: GSAdminLocale.ProductProductTypeGrapeseed }));
        productTypes.set(ProductType.LittleSeed, fmtMsg({ id: GSAdminLocale.ProductProductTypeLittleseed }));
        return productTypes;
    }

    static generateSchoolUnit() {
        let units = new Map<string, string>();
        const maxUnit = 40;
        [...Array(maxUnit).keys()].forEach(unitIndex => {
            units.set((unitIndex + 1).toString(), (unitIndex + 1).toString());
        });
        return units;
    }

    static generateLSSchoolUnit() {
        let units = new Map<string, string>();
        const maxUnit = 8;
        [...Array(maxUnit).keys()].forEach(unitIndex => {
            units.set((-(unitIndex + 1)).toString(), (unitIndex + 1).toString());
        });
        return units;
    }

    static schoolPortalUrl = () => process.env.schoolPortalUrl

    static formatBillingPeriod(billingPeriod: string) {
        return DateHelper.formatDate(billingPeriod, "YYYYMM");
    }

    static formatCloseDate(closeDate: string) {
        return DateHelper.formatDate(closeDate, "YYYY-MM-DD HH:mm:ss");
    }

    static getDefaultRegion() {
        const data = sessionStorage.getItem(SessionStorageKeys.SchoolsSelectedRegion);
        return data ? JSON.parse(data) : null;
    }

    static saveDefaultRegion(region) {
        sessionStorage.setItem(SessionStorageKeys.SchoolsSelectedRegion, JSON.stringify(region));
    }

    static getSchoolListPagination(loadFirstPage?) {
        const data = sessionStorage.getItem(SessionStorageKeys.SchoolsPagination);
        const result = new PaginationParams(1, 20);
        if (data && !loadFirstPage) {
            result.current = JSON.parse(data).current;
        }
        return result;
    }

    static saveSchoolListPagination(pagination) {
        sessionStorage.setItem(SessionStorageKeys.SchoolsPagination, JSON.stringify(pagination));
    }

    static getDefaultAppLockStateOptions() {
        return Object.keys(AppLockState).filter(state => state.length > 1).map(state => {
            return CommonHelper.getOption(state, AppLockState[state], fmtMsg({ id: CONSTS.LockState.get(AppLockState[state]) }));
        });
    }
}

export class ErrorHelper {

    static showCanNotDeleteError(target: string) {
        const formatMessage = GLGlobal.intl.formatMessage;
        const message = formatMessage({ id: GSAdminLocale.ErrorDeleteForTargetUsed }, { name: target });
        ErrorHelper.showError(message);
    }

    static showError(message: string, error?: { error: string, error_description: string, error_code: string }) {
        MessageHelper.Message(NotificationType.Failed, message);
    }

}

export class ContextHelper {

    static getUserLoginInfo(): LoginInfo {
        //return GLGlobal.store.getState().oidc.loginInfo
        return GLGlobal.loginInfo();
    }

    static getUserRoles(fromOidc: boolean = false): Set<string> {
        const profile = ContextHelper.getUserLoginInfo().profile;
        return profile ? new Set(fromOidc ? (typeof profile.role == 'string' ? [profile.role] : profile.role) : profile.roles) : new Set();
    }

    static getUserRoleForInvitationType(): string {
        const userRoles = ContextHelper.getUserRoles();
        if (userRoles.has(RoleName.systemAdmin)) return RoleName.systemAdmin;
        if (userRoles.has(RoleName.regionAdmin)) return RoleName.regionAdmin;
        if (userRoles.has(RoleName.accountManager)) return RoleName.accountManager;
        if (userRoles.has(RoleName.schoolAdmin)) return RoleName.schoolAdmin;
        //add temp permission code
        if (userRoles.has(RoleName.campusAdmin) ||
            userRoles.has(RoleName.teacher)) return RoleName.schoolAdmin;
        //add test permission code
        return RoleName.schoolAdmin;
    }

    static isUserMatchRole(role: RoleName, roles?: string[]): boolean {
        return roles ? roles.indexOf(role) >= 0 : ContextHelper.getUserRoles().has(role);
    }

    static sortUserRoles(roles: RoleName[]): RoleName[] {
        return [...CONSTS.Role.keys()].filter((role) => {
            return roles.find((source) => source.toLowerCase() === role.toLowerCase());
        });
    }

    static setSessionPagination(paginationKey: SessionStorageKeys, pagination) {
        sessionStorage.setItem(paginationKey, JSON.stringify({ current: pagination.current || 1 }));
    }
    static getSessionPagination(paginationKey: SessionStorageKeys) {
        const pagination = JSON.parse(sessionStorage.getItem(paginationKey)) || { current: 1 };
        return pagination;
    }

    static getUsersActions(userIds: any[], user: UserService) {
        const uniqueUserIds = uniqBy(userIds);
        let actions = [];
        while (uniqueUserIds.length > 0) {
            actions.push(user.getUsersByPost({ ids: uniqueUserIds.splice(0, 100) }))
        }
        return actions;
    }

    static getUsers(actionsData: { data: any[], totalCount: number }[]) {
        return actionsData.reduce((pre, cur) => pre.concat(cur.data), []);
    }

    static getRoleTextMap(): Map<RoleName, string> {
        return new Map([
            [RoleName.globalHead, DashboardLocale.LandingTabGlobalHeadText],
            [RoleName.systemAdmin, DashboardLocale.LandingTabSystemAdminText],
            [RoleName.trainingAdmin, DashboardLocale.LandingTabTrainingAdminText],
            [RoleName.regionAdmin, DashboardLocale.LandingTabRegionAdminText],
            [RoleName.accountManager, DashboardLocale.LandingTabAccountManagerText],
            [RoleName.trainer, DashboardLocale.LandingTabTrainerAdminText],
            [RoleName.trainingManager, DashboardLocale.LandingTabTrainingManagerText],
            [RoleName.schoolAdmin, DashboardLocale.LandingTabSchoolAdminText],
            [RoleName.campusAdmin, DashboardLocale.LandingTabCampusAdminText],
            [RoleName.teacher, DashboardLocale.LandingTabTeacherText],
            [RoleName.parent, SchoolLocale.InvitationTemplateFieldTypeParent],
            [RoleName.contentAdmin, SchoolLocale.InvitationTemplateFieldTypeContentAdmin]
        ]);
    }

    static userIsParent(actionLoaded) {
        return actionLoaded && ContextHelper.isUserMatchRole(RoleName.parent) && ContextHelper.getUserRoles().size == 1;
    }
}

export class CommonHelper {

    static getImageBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    static getOption(key, value, text) {
        return <Select.Option key={key} value={value}>{text}</Select.Option>
    }

    static groupBy(array, keyIterator) {
        var groups = {};
        array.forEach((item) => {
            var group = JSON.stringify(keyIterator(item));
            groups[group] = groups[group] || [];
            groups[group].push(item);
        });
        return Object.keys(groups).map((group) => groups[group]);
    }

    static formatNumber(number) {
        const numberString = `${number}`;
        const numberParts = numberString.split('.');
        let integerPart = numberParts[0];
        const decimalPart = numberParts.length > 1 ? '.' + numberParts[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(integerPart)) {
            integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
        }
        return integerPart + decimalPart;
    }

    static isMobile() {
        const md = new MobileDetect(window.navigator.userAgent);
        return md.mobile() || md.tablet();
    }

    static getRangeArray(start, end) {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    }

    static fixRounding(value, precision = 0) {
        const power = Math.pow(10, precision);
        return Math.round(value * power) / power;
    }

    static getLicenseTypeOptions(licenseTypes: number[]) {
        return licenseTypes.map(lt => {
            return CommonHelper.getOption(lt, lt, fmtMsg({ id: CONSTS.LicenseType.get(lt) }));
        });
    }
}

export class InvitationHelper {

    static invitationType2Text: Map<string, string> = new Map([
        [InvitationType.GlobalHead, InvitationTypeText.GlobalHead],
        [InvitationType.SystemAdmin, InvitationTypeText.SystemAdmin],
        [InvitationType.RegionAdmin, InvitationTypeText.RegionAdmin],
        [InvitationType.SchoolAdmin, InvitationTypeText.SchoolAdmin],
        [InvitationType.CampusAdmin, InvitationTypeText.CampusAdmin],
        [InvitationType.TrainingAdmin, InvitationTypeText.TrainingAdmin],
        [InvitationType.Trainer, InvitationTypeText.Trainer],
        [InvitationType.ClassTeacher, InvitationTypeText.Teacher],
        [InvitationType.Parent, InvitationTypeText.Parent],
        [InvitationType.AccountManager, InvitationTypeText.AccountManager],
        [InvitationType.ContentAdmin, InvitationTypeText.ContentAdmin]
    ]);

    static invitationChannel2Text: Map<InvitationChannel, string> = new Map([
        [InvitationChannel.Email, SchoolLocale.InvitationTemplateFieldChannelEmail],
        [InvitationChannel.Phone, SchoolLocale.InvitationTemplateFieldChannelPhone],
    ]);

    static invitationType2InvitationTag = {
        "0": InvitationHelper.generateTemplateTagForTeacher(),
        "1": InvitationHelper.generateTemplateTagForParent(),
        "2": InvitationHelper.generateTemplateTagForCampusAdmin(),
        "3": InvitationHelper.generateTemplateTagForSchoolAdmin(),
        "4": InvitationHelper.generateTemplateTagForRegionAdmin(),
        "5": InvitationHelper.generateTemplateTagForTrainingAdmin(),
        "6": InvitationHelper.generateTemplateTagForTrainer(),
        "7": InvitationHelper.generateTemplateTagForGlobalHead(),
        "8": InvitationHelper.generateTemplateTagForSystemAdmin(),
        "11": InvitationHelper.generateTemplateTagForAccountManager(),
        "14": InvitationHelper.generateTemplateTagForContentAdmin(),
        "15": InvitationHelper.generateTemplateTagForTranlationManager()
    }

    static role2InvitationType = {
        "SystemAdmin": InvitationHelper.generateInvitationTypeForSystemAdmin(),
        "RegionAdmin": InvitationHelper.generateInvitationTypeForRegionAdmin(),
        "SchoolAdmin": InvitationHelper.generateInvitationTypeForSchoolAdmin(),
        "AccountManager": InvitationHelper.generateInvitationTypeForAccountManager(),
        "Undefined": new Map()
    }

    static invitationType2RegionRole: Map<InvitationType, Role[]> = new Map([
        [InvitationType.GlobalHead, [Role.SystemAdmin]],
        [InvitationType.SystemAdmin, [Role.SystemAdmin]],
        [InvitationType.RegionAdmin, [Role.SystemAdmin, Role.RegionAdmin]],
        [InvitationType.TrainingAdmin, [Role.SystemAdmin]],
        [InvitationType.ContentAdmin, [Role.SystemAdmin]],
        [InvitationType.AccountManager, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager]],
        [InvitationType.Trainer, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager]],
        [InvitationType.SchoolAdmin, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager, Role.SchoolAdmin]],
        [InvitationType.CampusAdmin, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager, Role.SchoolAdmin, Role.CampusAdmin]],
        [InvitationType.ClassTeacher, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager, Role.SchoolAdmin, Role.CampusAdmin, Role.Teacher]],
        [InvitationType.Parent, [Role.SystemAdmin, Role.RegionAdmin, Role.AccountManager, Role.SchoolAdmin, Role.CampusAdmin, Role.Teacher]]
    ]);
    static generateTemplateTag(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.Region, TemplateTagText.Region],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.Campus, TemplateTagText.Campus],
            [TemplateTagCode.Class, TemplateTagText.Class],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForGlobalHead(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForSystemAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForRegionAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.Region, TemplateTagText.Region],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForTrainingAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForContentAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }
    static generateTemplateTagForTranlationManager(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForTrainer(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForAccountManager(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }

    static generateTemplateTagForSchoolAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }
    static generateTemplateTagForCampusAdmin(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.Campus, TemplateTagText.Campus],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }
    static generateTemplateTagForTeacher(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.TargetName, TemplateTagText.TargetName],
            [TemplateTagCode.TargetEmail, TemplateTagText.TargetEmail],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.Campus, TemplateTagText.Campus],
            [TemplateTagCode.Class, TemplateTagText.Class],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate]
        ]);
    }
    static generateTemplateTagForParent(): Map<string, string> {
        return new Map([
            [TemplateTagCode.InvitationCode, TemplateTagText.InvitationCode],
            [TemplateTagCode.InvitationQRCode, TemplateTagText.InvitationQRCode],
            [TemplateTagCode.InvitationLink, TemplateTagText.InvitationLink],
            [TemplateTagCode.AcceptInvitationLink, TemplateTagText.AcceptInvitationLink],
            [TemplateTagCode.School, TemplateTagText.School],
            [TemplateTagCode.Campus, TemplateTagText.Campus],
            [TemplateTagCode.Class, TemplateTagText.Class],
            [TemplateTagCode.ExpirationDate, TemplateTagText.ExpirationDate],
            [TemplateTagCode.PageBreak, TemplateTagText.PageBreak],
            [TemplateTagCode.StudentEnglishName, TemplateTagText.StudentEnglishName],
            [TemplateTagCode.StudentNativeName, TemplateTagText.StudentNativeName],
        ]);
    }

    static generateInvitationTypeForSystemAdmin(): Map<string, string> {
        return new Map([
            [InvitationType.GlobalHead, SchoolLocale.InvitationTemplateFieldTypeGlobalHead],
            [InvitationType.SystemAdmin, SchoolLocale.InvitationTemplateFieldTypeSystemAdmin],
            [InvitationType.RegionAdmin, SchoolLocale.InvitationTemplateFieldTypeRegionAdmin],
            [InvitationType.TrainingAdmin, SchoolLocale.InvitationTemplateFieldTypeTrainingAdmin],
            [InvitationType.Trainer, SchoolLocale.InvitationTemplateFieldTypeTrainer],
            [InvitationType.AccountManager, SchoolLocale.InvitationTemplateFieldTypeAccountManager],
            [InvitationType.SchoolAdmin, SchoolLocale.InvitationTemplateFieldTypeSchoolAdmin],
            [InvitationType.CampusAdmin, SchoolLocale.InvitationTemplateFieldTypeCampusAdmin],
            [InvitationType.ClassTeacher, SchoolLocale.InvitationTemplateFieldTypeTeacher],
            [InvitationType.ContentAdmin, SchoolLocale.InvitationTemplateFieldTypeContentAdmin],
            [InvitationType.Parent, SchoolLocale.InvitationTemplateFieldTypeParent]
        ]);
    }

    static generateInvitationTypeForRegionAdmin(): Map<string, string> {
        return new Map([
            [InvitationType.RegionAdmin, SchoolLocale.InvitationTemplateFieldTypeRegionAdmin],
            [InvitationType.Trainer, SchoolLocale.InvitationTemplateFieldTypeTrainer],
            [InvitationType.AccountManager, SchoolLocale.InvitationTemplateFieldTypeAccountManager],
            [InvitationType.SchoolAdmin, SchoolLocale.InvitationTemplateFieldTypeSchoolAdmin],
            [InvitationType.CampusAdmin, SchoolLocale.InvitationTemplateFieldTypeCampusAdmin],
            [InvitationType.ClassTeacher, SchoolLocale.InvitationTemplateFieldTypeTeacher],
            [InvitationType.Parent, SchoolLocale.InvitationTemplateFieldTypeParent]
        ]);
    }

    static generateInvitationTypeForAccountManager(): Map<string, string> {
        return new Map([
            [InvitationType.Trainer, SchoolLocale.InvitationTemplateFieldTypeTrainer],
            [InvitationType.AccountManager, SchoolLocale.InvitationTemplateFieldTypeAccountManager],
            [InvitationType.SchoolAdmin, SchoolLocale.InvitationTemplateFieldTypeSchoolAdmin],
            [InvitationType.CampusAdmin, SchoolLocale.InvitationTemplateFieldTypeCampusAdmin],
            [InvitationType.ClassTeacher, SchoolLocale.InvitationTemplateFieldTypeTeacher],
            [InvitationType.Parent, SchoolLocale.InvitationTemplateFieldTypeParent]
        ]);
    }

    static generateInvitationTypeForSchoolAdmin(): Map<string, string> {
        return new Map([
            [InvitationType.SchoolAdmin, SchoolLocale.InvitationTemplateFieldTypeSchoolAdmin],
            [InvitationType.CampusAdmin, SchoolLocale.InvitationTemplateFieldTypeCampusAdmin],
            [InvitationType.ClassTeacher, SchoolLocale.InvitationTemplateFieldTypeTeacher],
            [InvitationType.Parent, SchoolLocale.InvitationTemplateFieldTypeParent]
        ]);
    }

    static generateInvitationTypeForTrainingAdmin(): Map<string, string> {
        return new Map();
    }

    static generateInvitationTypeForTrainer(): Map<string, string> {
        return new Map();
    }

    static generateInvitationTypeForGlobalHead(): Map<string, string> {
        return new Map();
    }

    static generateTemplateLanguage(language: string[]): Map<string, string> {
        const result = new Map();
        Object.keys(PrimaryLanguage).filter((item) => language.find((lang) => item == lang)).forEach((item) => {
            result.set(item, SchoolLocale[InvitationLanguageText[item]]); //LanguageLocale[PrimaryLanguageLocale[item]]);
        });
        return result;
    }

    static getRegionRoleModel(region: RegionModel, role?: Role): RegionSchoolRoleModel {
        return {
            id: region.id,
            name: region.name,
            primaryLanguage: region.primaryLanguage,
            schools: [],
            role: role
        }
    }

    static sendEmailSuccess(description: string = GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationSendSuccess })) {
        MessageHelper.Message(NotificationType.Success, description);
    }

    static sendEmailFail(description: string = GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationRemoveFail })) {
        MessageHelper.Message(NotificationType.Failed, description);
    }
}

export class SortHelper {

    static sortbyString() {
        return (a, b) => a.name.localeCompare(b.name);
    }

    static capitalizeSortColumn(columnName: string) {
        return columnName.replace(/^[a-z]/, (char) => { return char.toUpperCase(); })
    }
}

export class ClassHelper {

    static canEditUnit(action: string, classRoles: string[], allowSchoolEditUnit: boolean, isEdit: boolean) {
        return (ContextHelper.isUserMatchRole(RoleName.systemAdmin, classRoles)
            || ContextHelper.isUserMatchRole(RoleName.regionAdmin, classRoles)
            || ContextHelper.isUserMatchRole(RoleName.accountManager, classRoles)
            || ContextHelper.isUserMatchRole(RoleName.trainer, classRoles))
            || (isEdit && allowSchoolEditUnit)
            || !isEdit;
    }

    static canEditLicense(classRoles: string[], allowSchoolEditLicense: boolean, withoutRole?: boolean) {
        if (withoutRole) {
            return GLGlobal.isActionValid(GSSchoolAction.ClassIgnoreRegionEditLicense) || allowSchoolEditLicense
        }

        return GLGlobal.isActionValid(GSSchoolAction.ClassIgnoreRegionEditLicense)
            || (allowSchoolEditLicense &&
                (ContextHelper.isUserMatchRole(RoleName.schoolAdmin, classRoles) ||
                    ContextHelper.isUserMatchRole(RoleName.campusAdmin, classRoles)));
    }

    static canEditRequiredFieldAnnualPrepComplete(annualPrepComplete: boolean) {
        const userRoles = ContextHelper.getUserLoginInfo().profile.roles;
        return (ContextHelper.isUserMatchRole(RoleName.campusAdmin, userRoles) || ContextHelper.isUserMatchRole(RoleName.schoolAdmin, userRoles) || ContextHelper.isUserMatchRole(RoleName.teacher, userRoles))
            && annualPrepComplete && !(
                ContextHelper.isUserMatchRole(RoleName.systemAdmin, userRoles)
                || ContextHelper.isUserMatchRole(RoleName.regionAdmin, userRoles)
                || ContextHelper.isUserMatchRole(RoleName.accountManager, userRoles)
                || ContextHelper.isUserMatchRole(RoleName.trainer, userRoles)
            )
    }

    static canEditUnitUnlockState(schoolClass) {
        return GLGlobal.isActionValid(GSSchoolAction.ClassIgnoreUnitPlanUnlock)
            || !schoolClass.preventUnitPlanUnlock;
    }

    static getUnitAppContentStatus(appLockState: number, days2UnlockApp: number, startDate: string | moment.Moment) {
        const today = sliceMoment(moment(new Date()));
        const unlockAppDay = sliceMoment(moment(startDate).add(days2UnlockApp, 'days'));
        const pastUnlockAppDays = moment(today).diff(moment(unlockAppDay), 'days');
        const accessibleStatus = () => fmtMsg({ id: SchoolLocale.UnitPlanAppContentStatusAccessible });
        const inaccessibleStatus = () => fmtMsg({ id: SchoolLocale.UnitPlanAppContentStatusInAccessible });
        const waitingStatus = () => fmtMsg({ id: SchoolLocale.UnitPlanAppContentStatusWaiting }, { unlockDate: DateHelper.formatDate2Local(unlockAppDay) });
        switch (appLockState) {
            case AppLockState.Unlock:
                return pastUnlockAppDays >= 0 ? accessibleStatus : waitingStatus;
            case AppLockState.Lock:
            default:
                return inaccessibleStatus;
        }
    }

    static getLicenseEditNoteLocalization(editNoteType, editNote, studentName?) {
        if (editNoteType == LicenseEditNoteType.System && editNote) {
            const note = JSON.parse(editNote);
            const key = note['key'] || note['Key'];
            let param = note['parameter'] || note['Parameter'];
            param = studentName ? { ...param, name: studentName } : param;
            return key ? param ? fmtMsg({ id: key }, mapKeys(param, (v, k) => camelCase(k))) : fmtMsg({ id: key }) : "";
        }
        else {
            return editNote;
        }
    }

    static getLicenseEditNoteStudentList(editNoteType, editNote): any[] {
        if (editNoteType == LicenseEditNoteType.System && editNote) {
            const note = JSON.parse(editNote);
            const param = note['parameter'] || note['Parameter'];
            const students = param ? param['students'] || param['Students'] || [] : [];
            return students.map((student) => mapKeys(student, (v, k) => camelCase(k)));
        }
        return [];
    }
}

export class MaterialHelper {

    static getSubscriptionUserIds(subscriptions: SubscriptionItemModel[]) {
        let result = [];
        subscriptions.forEach(subscription => {
            subscription.details ? result.push(...subscription.details.map(detail => detail.requestedBy)) : result.push(subscription.requestedBy);
        });
        return result;
    }
    static getPurchaseUserIds(purchases: RequestProductItemModel[]) {
        return purchases.map(purchase => purchase.requestedBy);
    }

    static formatSubscriptions(subscriptions: SubscriptionItemModel[], users?: UserModel[]): SubscriptionItemDisplayModel[] {
        const result = [];
        subscriptions.filter((subscription) => subscription.details.length > 0)
            .sort((subscriptionA, subscriptionB) => {
                return subscriptionA.className.localeCompare(subscriptionB.className);
            })
            .forEach(subscription => {
                subscription.details.forEach(detail => {
                    const user = users && users.find((user) => user.id == detail.requestedBy);
                    detail.requestedBy2Name = user ? user.name : '';
                    detail.requestDateFormatted = DateHelper.formatDate2Local(detail.requestDate); // DateHelper.FormatDate(subscription.requestDate, 'YYYY-MM-DD HH:mm:ss')
                });
                result.push(...MaterialHelper.subscriptions2SubscriptionsDisplay(subscription));
            });
        return result;
    }
    static subscriptions2SubscriptionsDisplay(subscription: SubscriptionItemModel): SubscriptionItemDisplayModel[] {
        return subscription.details.map((sub, index) => {
            return {
                id: subscription.id,
                classId: subscription.classId,
                className: subscription.className,
                requestId: sub.id,
                unit: sub.unit,
                unitText: getUnitText(sub.unit),
                quantity: sub.quantity,
                shippedQuantity: sub.shippedQuantity,
                productId: sub.productId,
                productName: sub.productName,
                requestDate: sub.requestDate,
                requestDateFormatted: sub.requestDateFormatted,
                requestedBy: sub.requestedBy,
                requestedBy2Name: sub.requestedBy2Name,
                rowSpan: index == 0 ? subscription.details.length : 0,
                rowId: guid()
            }
        });
    }
    static formatSubscriptions4Class(subscriptions: RequestProductItemModel[], users?: UserModel[]): SubscriptionItemDisplayModel[] {
        const result = [];
        [...subscriptions].sort((subscriptionA, subscriptionB) => {
            if (subscriptionA.status != subscriptionB.status) { return subscriptionB.status - subscriptionA.status; }
            else { return subscriptionA.requestDate.localeCompare(subscriptionB.requestDate); }
        })
            .forEach(subscription => {
                const user = users && users.find((user) => user.id == subscription.requestedBy);
                subscription.requestedBy2Name = user ? user.name : '';
                subscription.requestDateFormatted = DateHelper.formatDate2Local(subscription.requestDate); // DateHelper.FormatDate(subscription.requestDate, 'YYYY-MM-DD HH:mm:ss')
                subscription.unitText = getUnitText(subscription.unit);
                result.push(subscription);
            });
        return result;
    }
    static formatPurchases(purchases: RequestProductItemModel[], users?: UserModel[]): PurchaseItemDisplayModel[] {
        const result = [];
        [...purchases].sort((purchaseA, purchaseB) => {
            if (purchaseA.status != purchaseB.status) { return purchaseB.status - purchaseA.status; }
            else if (!purchaseA.classId && purchaseB.classId) { return -1; }
            else if (purchaseA.classId && !purchaseB.classId) { return 1; }
            else if (purchaseA.classId && purchaseB.classId && purchaseA.classId != purchaseB.classId && purchaseA.className != purchaseB.className) { return purchaseA.className.localeCompare(purchaseB.className); }
            else if (purchaseA.classId != purchaseB.classId && purchaseA.className == purchaseB.className) { return purchaseA.classId.localeCompare(purchaseB.classId); }
            else { return purchaseA.requestDate.localeCompare(purchaseB.requestDate); }
        })
            .forEach((purchase, index) => {
                const user = users && users.find((user) => user.id == purchase.requestedBy);
                purchase.requestedBy2Name = user ? user.name : '';
                purchase.requestDateFormatted = DateHelper.formatDate2Local(purchase.requestDate); // DateHelper.FormatDate(purchase.requestDate, 'YYYY-MM-DD HH:mm:ss')
                const classPurchases = purchases.filter(cp => purchase.classId && cp.classId == purchase.classId);
                /*
                // The purchase.price property comes as a string.
                // So we have replace any special character like comma(,) other than digits and then calculate the total price of the product.
                */
                const price = isUndefined(purchase.price) || isNull(purchase.price) ? null : Number(purchase.price.replace(/[^0-9.]/g, ''));
                purchase.totalPrice = typeof price === "number" && purchase.shippedQuantity !== null ? price * Number(purchase.shippedQuantity) : null;
                purchase.unitText = getUnitText(purchase.unit);
                result.push(MaterialHelper.purchase2PurchaseDisplay(purchase,
                    classPurchases.length > 1 ?
                        (classPurchases[0].id == purchase.id ? classPurchases.length : 0) :
                        1)
                );
            });
        return result;
    }
    static purchase2PurchaseDisplay(purchase: RequestProductItemModel, rowSpan: number): PurchaseItemDisplayModel {
        return {
            ...purchase,
            rowSpan: rowSpan,
            rowId: guid()
        }
    }

    static formatCumulativeData (subscriptions: SubscriptionItemDisplayModel[], purchases: PurchaseItemDisplayModel[] ) : CumulativeDataItemModel[] {

        const materialTypes = {
            "subscription": "subscription",
            "purchase": "purchase" 
        };

        const subscriptionMaterialMap= new Map();
        subscriptions && subscriptions.forEach(({ unit, productName, quantity}) => {
              const key = `${unit}_!_${productName}`;
              subscriptionMaterialMap.set(key, (subscriptionMaterialMap.get(key) || 0) + quantity);
         });
          
        const subscriptionMaterials: CumulativeDataItemModel[] = Array.from(subscriptionMaterialMap.entries()).map(([key, quantity]) => {
            const [unit, productName] = key.split('_!_');
            return { type: materialTypes.subscription, unitText: getUnitText(parseInt(unit)), productName, quantity, totalCost: null };
        });
          

        const purchaseMaterialsMap = new Map();
        purchases && purchases.forEach(({ unit, productName, quantity, price , currency, unitText}) => {
            const key= `${unit}_!_${productName}_!_${price}`;
            const numericPart = price.match(/[\d,.]+/);
            let numericValue = numericPart ? parseFloat(numericPart[0].replace(/,/g, '')) : null;
            if (purchaseMaterialsMap.has(key)) {
              const existing = purchaseMaterialsMap.get(key);
              purchaseMaterialsMap.set(key, {
                quantity: existing.quantity + quantity,
                price: price,
                totalCost: existing.totalCost + (parseInt(quantity) * numericValue) ,
                unitText: unitText,
                currency: currency
              });
            } else {
                purchaseMaterialsMap.set(key, { quantity, price: price, totalCost: (parseInt(quantity) * numericValue), unitText: unitText, currency: currency });
            }
        });
          
        const hasCurrency = purchases.some(obj => obj.hasOwnProperty('currency'));
        let purchaseMaterials: CumulativeDataItemModel[];
        if(hasCurrency)
        {
            purchaseMaterials = Array.from(purchaseMaterialsMap.entries()).map(([key, values]) => {
                const [unit, productName, price] = key.split('_!_');
                let {totalCost, currency} = values;
                totalCost = Number(totalCost) === totalCost && totalCost % 1 !== 0 ? parseFloat((totalCost).toFixed(2)) : totalCost;
                const totalCostWithCurrency = `${totalCost} ${currency}`;
                return { type: materialTypes.purchase, unit: parseInt(unit), productName, ...values, price: `${price} ${currency}`, totalCost: totalCostWithCurrency };
            });
        }
        else{
            purchaseMaterials = Array.from(purchaseMaterialsMap.entries()).map(([key, values]) => {
                const [unit, productName, priceWithCurrency] = key.split('_!_');
                let {totalCost, price} = values
                let currency=price.match(/[A-Z]{3}/)[0];
                totalCost = Number(totalCost) === totalCost && totalCost % 1 !== 0 ? parseFloat((totalCost).toFixed(2)) : totalCost;
                const totalCostWithCurrency = `${totalCost} ${currency}`
                return { type: materialTypes.purchase, unit: parseInt(unit), productName, price: price, ...values, totalCost: totalCostWithCurrency };
            });
        }
        const combinedData: CumulativeDataItemModel[] = [...subscriptionMaterials, ...purchaseMaterials];        
        return combinedData;
    }

    static MaterialOrderStatus = {
        "5": SchoolLocale.MaterialOrderStatusDraft,
        "1": SchoolLocale.MaterialOrderStatusSubmitted,
        "2": SchoolLocale.MaterialOrderStatusInProgress,
        "3": SchoolLocale.MaterialOrderStatusShipped,
        "4": SchoolLocale.MaterialOrderStatusClosed
    };

    static materialOrderStatusCountMap = {
        "0": 'initializedCnt',
        "1": 'submittedCnt',
        "2": 'inProgressCnt',
        "3": 'shippedCnt',
        "4": 'closedCnt',
        "5": 'draftCnt'
    };

    static formatMaterialOrders(orders: MaterialOrdersModel[]): MaterialOrdersModel[] {
        return orders.map((order) => {
            order.statusText = GLGlobal.intl.formatMessage({ id: MaterialHelper.MaterialOrderStatus[order.status] });
            return order;
        });
    }

    static draftKey = '5';
    static getMaterialOrderStatus(excludeDraft?) {
        let status = Object.keys(MaterialHelper.MaterialOrderStatus).filter(status => status != MaterialHelper.draftKey).map(status => { return { key: status, value: MaterialHelper.MaterialOrderStatus[status] } });
        if (excludeDraft) return status;
        status.unshift({
            key: MaterialHelper.draftKey,
            value: MaterialHelper.MaterialOrderStatus[MaterialHelper.draftKey]
        });
        return status;
    }
    // static GetPurchaseProductsTotal(products: RequestProductItemModel[]): string {
    //     const convertedProducts = products.map((product) => {
    //         product.subTotalCurrency = product.subTotal.replace(/[\d,]+/g, '');
    //         const regx = new RegExp(`[${product.subTotalCurrency},]`, 'g');
    //         product.subTotalValue = Number.parseFloat(product.subTotal.replace(regx, ''));
    //         return product;
    //     });
    //     const groupedProducts = CommonHelper.GroupBy(convertedProducts, (product) => product.subTotalCurrency);
    //     const sumedProducts = groupedProducts.map((groupedProduct) => {
    //         const total = groupedProduct.reduce((accumulator, currentValue) => {
    //             return accumulator + currentValue.subTotalValue
    //         }, 0);
    //         return `${groupedProduct[0].subTotalCurrency}${CommonHelper.FormatNumber(total)}`
    //     });
    //     return sumedProducts.join(',');
    // }
}

export type momentOptionType = { format?: moment.MomentFormatSpecification, strict?: boolean };
export class DateHelper {

    static toLocal(inp: moment.MomentInput, { format, strict }: momentOptionType = {}) {
        if (!inp) return null;
        const dateTime = moment(inp, format, strict);
        return dateTime.isUTC()
            ? dateTime.local()
            : dateTime;
    }

    static toLocalFromUTC(inp: moment.MomentInput, { format, strict }: momentOptionType = {}) {
        if (!inp) return null;
        return moment.utc(inp, format, strict).local();
    }

    static toLocalDateFromUTC(inp: moment.MomentInput, momentOptions?: momentOptionType) {
        const localMoment = DateHelper.toLocalFromUTC(inp, momentOptions);
        return localMoment ? localMoment.toDate() : null;
    }

    static toLocalStringFromUTC(inp: moment.MomentInput, format?: string, momentOptions?: momentOptionType) {
        const localMoment = DateHelper.toLocalFromUTC(inp, momentOptions);
        return localMoment ? localMoment.format(format || LanguageDateFormat[GLGlobal.intl.locale]) : '';
    }

    static toLocalTimeStringFromUTC(inp: moment.MomentInput, withSecond: boolean = false, momentOptions?: momentOptionType) {
        const localMoment = DateHelper.toLocalFromUTC(inp, momentOptions);
        const timeFormat = withSecond ? LanguageTimeFormat : LanguageTimeFormatWithoutSecond;
        return localMoment ? localMoment.format(`${LanguageDateFormat[GLGlobal.intl.locale]} ${timeFormat[GLGlobal.intl.locale]}`) : '';
    }

    static toLocalString(inp: moment.MomentInput, format?: string, momentOptions?: momentOptionType) {
        const localMoment = DateHelper.toLocal(inp, momentOptions);
        return localMoment ? localMoment.format(format || LanguageDateFormat[GLGlobal.intl.locale]) : '';
    }

    static toLocalTimeString(inp: moment.MomentInput, withSecond: boolean = false, momentOptions?: momentOptionType) {
        const localMoment = DateHelper.toLocal(inp, momentOptions);
        const timeFormat = withSecond ? LanguageTimeFormat : LanguageTimeFormatWithoutSecond;
        return localMoment ? localMoment.format(`${LanguageDateFormat[GLGlobal.intl.locale]} ${timeFormat[GLGlobal.intl.locale]}`) : '';
    }

    /**
     * For display date literally when db column type be date.
     */
    static toLocalDateLiteral(inp: moment.MomentInput) {
        if (!inp) return '';
        if (typeof inp === 'string') {
            return moment.utc(inp).format(LanguageDateFormat[GLGlobal.intl.locale]);
        } else {
            return moment(inp).format(LanguageDateFormat[GLGlobal.intl.locale]);
        }
    }

    static toStartOfDay(inp: moment.MomentInput, { format, strict }: momentOptionType = {}) {
        return inp ? moment(inp, format, strict).startOf('day') : null;
    }

    static isStartOfDay(inp: moment.MomentInput, { format, strict }: momentOptionType = {}) {
        const date = moment(inp, format, strict);
        return date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0 && date.milliseconds() === 0;
    }

    static toUTC(inp: moment.MomentInput, objectLiteral?: moment.MomentSetObject) {
        if (!inp) return null;
        const dateTime = moment(inp);

        if (DateHelper.isStartOfDay(dateTime) || objectLiteral) {
            dateTime.set(objectLiteral || (now => ({
                hour: now.getHours(),
                minute: now.getMinutes(),
                second: now.getSeconds(),
                millisecond: now.getMilliseconds(),
            }))(new Date()));
        }

        return dateTime.utc();
    }

    static toUTCString(inp: moment.MomentInput, objectLiteral?: moment.MomentSetObject) {
        const utcMoment = DateHelper.toUTC(inp, objectLiteral);
        return utcMoment ? utcMoment.toISOString() : '';
    }

    /**
     * The start of the day to utc.
     */
    static toUTCStartString(inp: moment.MomentInput) {
        return DateHelper.toUTCString(inp, {
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
    }

    /**
     * The end of the day to utc.
     *
     * @remarks
     * The datetime in mssql has a {@link https://docs.microsoft.com/en-us/sql/t-sql/data-types/datetime-transact-sql?view=sql-server-2017#rounding-of-datetime-fractional-second-precision second precision} issue.
     *
     */
    static toUTCEndString(inp: moment.MomentInput) {
        return DateHelper.toUTCString(inp, {
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 997,
        })
    }

    /**
     * For display date literally when db column type be date.
     * 2020-10-28T01:33:58+08:00 -> 2020-10-28T00:00:00.000Z
     */
    static toUTCDateLiteral(inp: moment.MomentInput) {
        if (!inp) return '';
        return moment(inp).startOf('day').utcOffset(0, true).toISOString();
    }

    /**
     * keep offset for ISO format.
     *
     * @remarks
     * store client datetime offset to mssql datetimeoffset
     */
    static toDateTimeOffsetString(
        inp: moment.MomentInput,
        { keepOffset = true, dayPos }: { keepOffset?: boolean, dayPos?: 'start' | 'end' },
        { format, strict }: momentOptionType = {}
    ) {
        if (!inp) return '';

        let datetime = moment(inp, format, strict);

        if (dayPos === 'start') {
            datetime = datetime.startOf('day');
        } else if (dayPos === 'end') {
            datetime = datetime.endOf('day');
        }

        return datetime.toISOString(keepOffset);
    }

    /**
     * @deprecated Use toLocalStringFromUTC instead.
     */
    static formatDate2Local(date: string | moment.Moment | Date) {
        if (isCompatibleTest()) return DateHelper.toLocalStringFromUTC(date);
        if (!date) return '';
        const localTime = moment.utc(date).toDate();
        return moment(localTime).format(LanguageDateFormat[GLGlobal.intl.locale]);
    }

    /**
     * @deprecated Use toLocalDateFromUTC instead.
     */
    static formatDate2LocalDate(date: string | moment.Moment | Date) {
        if (isCompatibleTest()) return DateHelper.toLocalDateFromUTC(date);
        if (!date) return null;
        const localTime = moment.utc(date).toDate();
        return moment(localTime).toDate();
    }

    /**
     * format is for dates which are not ISO formatted. So define the format of date by using symbols in moment.
     *
     * @deprecated Use toLocalTimeStringFromUTC instead.
     */
    static formatDateTime2Local(date: string | moment.Moment, toUtc: boolean = false, format: string = null, removeSecond: boolean = false) {
        if (isCompatibleTest()) {
            const fmt = toUtc ? DateHelper.toLocalTimeStringFromUTC : DateHelper.toLocalTimeString;
            return fmt(date, !removeSecond, { format });
        }

        const localTime = toUtc ? moment.utc(date, format).toDate() : moment(date, format).toDate();
        const timeFormat = removeSecond ? LanguageTimeFormatWithoutSecond[GLGlobal.intl.locale] : LanguageTimeFormat[GLGlobal.intl.locale]
        return moment(localTime).format(`${LanguageDateFormat[GLGlobal.intl.locale]} ${timeFormat}`);
    }

    /**
     * @deprecated Use toLocalStringFromUTC instead.
     */
    static formatDate(date: string, format) {
        if (isCompatibleTest()) return DateHelper.toLocalStringFromUTC(date, format);
        const localTime = moment.utc(date).toDate();
        return moment(localTime).format(format);
    }

    /**
     * @deprecated Use toUTCStartString instead.
     */
    static formatDate2ISO(date: string | moment.Moment) {
        if (isCompatibleTest()) return DateHelper.toUTCStartString(date);
        var d = moment(date);
        d.set("hour", 0)
        d.set("minute", 0)
        d.set("second", 0)
        d.set("millisecond", 0)
        return d.toISOString();
    }

    /**
     * @deprecated Use toLocalFromUTC instead.
     */
    static toLocalMoment(date: string | moment.Moment, format?: moment.MomentFormatSpecification, strict?: boolean) {
        if (isCompatibleTest()) return DateHelper.toLocalFromUTC(date);
        const localTime = moment.utc(date).toDate();
        return moment(localTime, format, strict);
    }

    /**
     * @deprecated Use toLocalString instead.
     */
    static toLocalDate(date: string | moment.Moment | Date) {
        if (isCompatibleTest()) return DateHelper.toLocalString(date);
        if (!date) {
            return "";
        }

        const localTime = moment(date).toDate();
        return moment(localTime).format(LanguageDateFormat[GLGlobal.intl.locale]);
    }

    /**
     * @deprecated Use toLocalString instead.
     */
    static formatDateAsIs(date: string, format?: string) {
        if (isCompatibleTest()) return DateHelper.toLocalString(date, format)
        if (!date) {
            return "";
        }

        if (format) {
            return moment(new Date(date)).format(format);
        } else {
            return moment(new Date(date)).format(LanguageDateFormat[GLGlobal.intl.locale]);
        }
    }
}

function isCompatibleTest() {
    return !!sessionStorage.getItem('compatible');
}

export class BlobHelper {
    static getFileInfo(response, fileName?) {
        if (!response || !response.data || !response.data.size
            || (!fileName && !response.header["content-disposition"])) return { has: false };
        const file = !fileName && response.header["content-disposition"].split(";")[1].trim().split('=');
        const name = fileName ? fileName : (file.length > 1 ? file[1].replace(/["]/g, '') : "file");
        const type = response.type;
        const blob = new Blob([response.data]);
        return { has: true, name, type, blob };
    }

    static saveFile(response, fileName?): boolean {
        const { has, blob, name } = BlobHelper.getFileInfo(response, fileName);
        if (!has) return false;
        FileSaver.saveAs(blob, name);
        return true;
    }

    static saveFileSurvey(response, fileName?): boolean {
        if (!response || ((!response.body || !response.body.size) && (!response.data || !response.data.size))
            || (!fileName && !response.header["content-disposition"])) {
            return false;
        }
        const file = !fileName && response.header["content-disposition"].split(";")[1].trim().split("=");
        const name = fileName ? fileName : (file.length > 1 ? file[1].replace(/["]/g, "") : "file");
        const blob = new Blob([response.data.size ? response.data : response.body]);
        FileSaver.saveAs(blob, name);
        return true;
    }

    static saveFileOnMobile(response, fileName?) {
        const { has, blob, name, type } = BlobHelper.getFileInfo(response, fileName);
        if (!has) return false;
        downloadURI(blob, name, type)
        return true;
    }
}
//https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
function downloadURI(blob, name, type) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: type })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data.replace('data:;', `data:${type};fileName=${name};`);
    link.download = name;
    // link.target = "_blank";
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100)
}

export class Wijmo2ExcelHelper {

    static export2Excel(data: any[], columns: ColumnProps<any>[], fileName: string,
        frozenPanel: { rows?: number, columns?: number } = null,
        onFormatCell?: (dataItem: any, column: ColumnProps<any>, cellData: any, cell: wjcXlsx.WorkbookCell) => string,
        onSave?: (base64?) => void, onError?: (reason?) => void) {
        let workbook = new wjcXlsx.Workbook();
        let sheet = new wjcXlsx.WorkSheet(), rows = sheet.rows, panel = new wjcXlsx.WorkbookFrozenPane();
        if (frozenPanel) {
            panel.rows = frozenPanel.rows == undefined ? 0 : frozenPanel.rows;
            panel.columns = frozenPanel.columns == undefined ? 0 : frozenPanel.columns;
            sheet.frozenPane = panel;
        }
        workbook.sheets.push(sheet);
        sheet.name = fileName.replace('.xlsx', '');
        let tableHeaderStyle = new wjcXlsx.WorkbookStyle();
        tableHeaderStyle.font = new wjcXlsx.WorkbookFont();
        tableHeaderStyle.font.bold = true;
        const headerRowIndex = 0;
        rows[headerRowIndex] = new wjcXlsx.WorkbookRow();
        rows[headerRowIndex].style = new wjcXlsx.WorkbookStyle();
        rows[headerRowIndex].style.hAlign = wjcXlsx.HAlign.Center;
        columns.forEach((column, columnIndex) => {
            sheet.columns[columnIndex] = new wjcXlsx.WorkbookColumn();
            sheet.columns[columnIndex].width = column.title ? column.width : column['renderWidth'];
            rows[headerRowIndex].cells[columnIndex] = new wjcXlsx.WorkbookCell();
            rows[headerRowIndex].cells[columnIndex].value = column.title ? fmtMsg({ id: column.title as string }) : column['header'];
            rows[headerRowIndex].cells[columnIndex].style = tableHeaderStyle;
        });
        for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
            let item = data[dataIndex], rowIndex = headerRowIndex + 1 + dataIndex;
            rows[rowIndex] = new wjcXlsx.WorkbookRow();
            columns.forEach((column, columnIndex) => {
                rows[rowIndex].cells[columnIndex] = new wjcXlsx.WorkbookCell();
                const cellData = column.title ? item[column.dataIndex] : item[column['binding']];
                rows[rowIndex].cells[columnIndex].value = onFormatCell ? onFormatCell(item, column, cellData, rows[rowIndex].cells[columnIndex]) : cellData;
                let align = null;
                if (column.align) {
                    switch (column.align) {
                        case 'center':
                            align = wjcXlsx.HAlign.Center;
                            break;
                        case 'right':
                            align = wjcXlsx.HAlign.Right;
                            break;
                        case 'left':
                        default:
                            align = wjcXlsx.HAlign.Left;
                            break;
                    }
                }
                else if (column['dataType']) {
                    switch (column['dataType']) {
                        case DataType.Boolean:
                            align = wjcXlsx.HAlign.Center;
                            break;
                        case DataType.Number:
                            align = wjcXlsx.HAlign.Right;
                            break;
                        case DataType.String:
                        default:
                            align = wjcXlsx.HAlign.Left;
                            break;
                    }
                }
                if (align) {
                    rows[rowIndex].cells[columnIndex].style = new wjcXlsx.WorkbookStyle();
                    rows[rowIndex].cells[columnIndex].style.hAlign = align;
                }
            });
        }
        workbook.saveAsync(fileName, onSave, onError);
    }
}

// It helps in providing the current values of data in a function of component,
// when the function is passed to external library, which is not react friendly.

export const useWrap = (func: (...funcArgs: any) => void) => {
    const [args, setArgs] = useState(null);
    const [funcExecuted, setFuncExecuted] = useState(false);

    useEffect(() => {
        if (funcExecuted) {
            if (!!args) {
                func(...args);
            } else {
                func();
            }
        }
        args && setArgs(null);
        funcExecuted && setFuncExecuted(false);
    }, [funcExecuted]);

    return (...callbackArgs) => {
        setArgs(callbackArgs);
        setFuncExecuted(true);
    };
};

export class WijmoEditHelper {

    static startEditing(flex) {
        let index = flex.selection.col, col = index > -1 ? flex.columns[index] : null;
        if (col && !col.isReadOnly && col.dataType != DataType.Boolean) {
            setTimeout(() => {
                flex.startEditing(false); // quick mode
            }, 50); // let the grid update first
        }
    }

}

export class GLRegistrationLocalizationHelper {

    static getGLRegistrationLocalization(enablePhoneRegistration?: boolean): GLRegistrationLocalization {
        return {
            userNameLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormUserName }),
            userNameRequired: fmtMsg({ id: GSAdminLocale.RegistrationFormUserNameRequired }),
            englishNameLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormEnglishName }),
            emailPhoneLabel: fmtMsg({ id: GSAdminLocale.RegistrationEmailLabel }),
            emailPhonePlaceHolder: fmtMsg({ id: GSAdminLocale.RegistrationEmailLabel }),
            emailPhoneRequired: fmtMsg({ id: typeof enablePhoneRegistration === 'boolean' && enablePhoneRegistration ? GSAdminLocale.RegistrationFormEmailPhoneRequired : GSAdminLocale.RegistrationFormEmailRequired }),
            emailFormatError: fmtMsg({ id: GSAdminLocale.RegistrationFormEmailFormatError }),
            emailStartError: fmtMsg({ id: SchoolLocale.InvitationUnderscoreEmailWarn }),
            emailLengthError: fmtMsg({ id: GSAdminLocale.RegistrationFormEmailLengthError }),
            phoneFormatError: fmtMsg({ id: GSAdminLocale.RegistrationFormPhoneFormatError }),
            getVerificationCodeLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormGetVerificationCode }),
            verificationCountingDownLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormVerificationCountingDownLabel }, { "0": "{0}" }),
            sendingVerificationCodeHint1: fmtMsg({ id: GSAdminLocale.RegistrationFormSendingVerificationCodeHint1 }),
            sendingVerificationCodeHint2: fmtMsg({ id: GSAdminLocale.RegistrationFormSendingVerificationCodeHint2 }),
            verificationCodeLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormVerificationCode }),
            verificationCodeRequired: fmtMsg({ id: GSAdminLocale.RegistrationFormVerificationCodeRequired }),
            passwordLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormPassword }),
            passwordRequired: fmtMsg({ id: GSAdminLocale.RegistrationFormPasswordRequired }),
            passwordInvalid: fmtMsg({ id: GSAdminLocale.RegistrationFormPasswordFormatError }),
            confirmPasswordLabel: fmtMsg({ id: GSAdminLocale.RegistrationFormConfirmPassword }),
            confirmPasswordRequired: fmtMsg({ id: GSAdminLocale.RegistrationFormPasswordRequired }),
            passwordInconsistent: fmtMsg({ id: GSAdminLocale.RegistrationFormPasswordInConsistent }),
            submitButtonText: fmtMsg({ id: GSAdminLocale.RegistrationFormSubmitButton }),
        }
    };
}
