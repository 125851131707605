import { RegionGroupService, RegionGroupModel } from "@app/service/admin/regiongroup";

interface Services {
    regionGroup: RegionGroupService;
}

export interface RegionGroupState {
    regionGroups: RegionGroupModel[];
}

export default {
    namespace: "regionGroup",
    state: {
        regionGroups: [],
    },
    reducers: {
        setRegionGroups(state, { payload }) {
            return { ...state, regionGroups: payload };
        },
    },
    effects: {
        *getRegionGroups({ payload }, { call, put }, { regionGroup }: Services) {
            const data = yield call(regionGroup.getRegionGroups, payload);
            yield put(setRegionGroups(data));
        }
    },
    services: {
        regionGroup: RegionGroupService
    }
}

export function getRegionGroups(state) {
    return { type: "regionGroup/getRegionGroups", payload: state };
}

export function setRegionGroups(regionGroups: RegionGroupModel[]) {
    return { type: "regionGroup/setRegionGroups", payload: regionGroups };
}