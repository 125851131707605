import { Checkbox, Col, Modal, Row, Input, Divider, Button, Icon } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import React, { forwardRef, useEffect, useState } from "react";
import { fmtMsg, ManageAssociationModalType, TYPES } from "@app/util";
import { useService } from "@app/hooks/useService";
import { SchoolLocale } from "@app/locales/localeid";
import { ISchoolClassService, UpdateSchoolTeacherClassAssociationModel } from "@app/service/class";
import { ISchoolService, UpdateCoachSchoolAssociationModel } from "@app/service/schools";
import { Loading } from "../survey";
import { connect, GLGlobal, InvitationType, MessageHelper, NotificationType, RoleName, StateType } from "gl-commonui";
import { getInvitations } from "@app/states/school/invitation";
import "./AssociationModal.less";

interface AssociationModalProps {
	getInvitations: (args: any) => void;
	refId: string;
	regionId: string;
	typeId: InvitationType;
}

const AssociationModal = connect(
	({ schoolClass: { model }, invitation: { loading } }: StateType) => ({
		model,
		loading
	}),
	{
		getInvitations
	},
	null,
	{ forwardRef: true }
)(
	forwardRef((props: AssociationModalProps, ref) => {
		const { getInvitations, regionId, refId, typeId } = props;
		const schoolService = useService<ISchoolService>(TYPES.ISchoolService);
		const schoolClassService = useService<ISchoolClassService>(TYPES.ISchoolClassService);
		const [isClassAssociationModal, setClassAssociationModal] = useState<boolean>();
		const [isModalVisible, setIsModalVisible] = useState(false);
		const [newClassesAssigned, setNewClassesAssigned] = useState<string[]>();
		const [newSchoolsAssigned, setNewSchoolsAssigned] = useState<string[]>();
		const [indeterminate, setIndeterminate] = useState(false);
		const [selectAll, setSelectAll] = useState(false);
		const [currentlyAssignedClasses, setCurrentlyAssignedClasses] = useState([]);
		const [currentlyUnassignedClasses, setCurrentlyUnassignedClasses] = useState([]);
		const [currentlyAssignedSchools, setCurrentlyAssignedSchools] = useState([]);
		const [currentlyUnassignedSchools, setCurrentlyUnassignedSchools] = useState([]);
		const [loading, setLoading] = useState(true);
		const [originalClasses, setOriginalClasses] = useState([]);
		const [originalSchools, setOriginalSchools] = useState([]);
		const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
		const [filteredUnassignedClasses, setFilteredUnassignedClasses] = useState([]);
		const [filteredUnassignedSchools, setFilteredUnassignedSchools] = useState([]);
		const [teacherId, setTeacherId] = useState<string>();
		const [trainerId, setTrainerId] = useState<string>();
		const [isButtonDisabled, setButtonDisabled] = useState(true);
		const [isGlobalHead, setGlobalHead] = useState(false);

		useEffect(() => {
			let roles = GLGlobal.loginInfo().profile.roles;
			setGlobalHead(roles && roles.length == 1 && roles.indexOf(RoleName.globalHead) > -1);
		}, []);

		useEffect(() => {
			setFilteredUnassignedClasses(currentlyUnassignedClasses);
		}, [currentlyAssignedClasses, currentlyUnassignedClasses]);

		useEffect(() => {
			setFilteredUnassignedSchools(currentlyUnassignedSchools);
		}, [currentlyAssignedSchools, currentlyUnassignedSchools]);

		const getSchoolClasses = (teacherId: any) => {
			const param = {
				regionId: regionId,
				schoolId: refId,
				teacherId: teacherId
			};
			schoolClassService.getTeacherAssociations(param).then((result) => {
				setCurrentlyAssignedClasses(
					result.assignedClasses.map((x) => {
						const { schoolClassId, schoolClassName, campusName } = x;
						return { schoolClassId, schoolClassName, campusName };
					})
				);
				setCurrentlyUnassignedClasses(
					result.unAssignedClasses.map((x) => {
						const { schoolClassId, schoolClassName, campusName } = x;
						return { schoolClassId, schoolClassName, campusName };
					})
				);
				setTeacherId(teacherId);
				setLoading(false);
			});
		};

		const getRegionCoaches = (trainerId: any) => {
			const param = {
				regionId: refId,
				trainerId: trainerId
			};
			schoolService.getCoachAssociations(param).then((result) => {
				setCurrentlyAssignedSchools(
					result.assignedSchools.map((x) => {
						const { schoolId, schoolName } = x;
						return { schoolId, schoolName };
					})
				);
				setCurrentlyUnassignedSchools(
					result.notAssignedSchools.map((x) => {
						const { schoolId, schoolName } = x;
						return { schoolId, schoolName };
					})
				);
				setTrainerId(trainerId);
				setLoading(false);
			});
		};

		const clearAllStates = () => {
			setCurrentlyAssignedClasses([]);
			setCurrentlyUnassignedClasses([]);
			setCurrentlyAssignedSchools([]);
			setCurrentlyUnassignedSchools([]);
			setLoading(true);
			setOriginalClasses([]);
			setOriginalSchools([]);
			setNewClassesAssigned([]);
			setNewSchoolsAssigned([]);
			setSelectAll(false);
			setIsCheckboxClicked(false);
			setIndeterminate(false);
			setButtonDisabled(true);
			setIsModalVisible(false);
		};

		const showModal = (item: any, modalType: ManageAssociationModalType) => {
			modalType == ManageAssociationModalType.Teacher
				? setClassAssociationModal(true)
				: setClassAssociationModal(false);
			modalType == ManageAssociationModalType.Teacher ? getSchoolClasses(item.id) : getRegionCoaches(item.id);

			setIsModalVisible(true);
		};

		const updateClassesOfTeacher = (newClassesAssigned = [], removedClassIds) => {
			const routeData = {
				schoolId: refId,
				teacherId: teacherId
			};
			const param: UpdateSchoolTeacherClassAssociationModel = {
				assignedClasses: newClassesAssigned,
				removedClasses: removedClassIds
			};
			schoolClassService
				.updateTeacherAssociations(routeData, param)
				.then((result) => {
					MessageHelper.Message(
						NotificationType.Success,
						fmtMsg({ id: SchoolLocale.ClassAssociationSuccessful })
					);
					getInvitations({ id: refId, type: typeId });
				})
				.catch((e) => {
					MessageHelper.Message(
						NotificationType.Failed,
						fmtMsg({ id: SchoolLocale.ClassAssociationUnsuccessful })
					);
				});

			clearAllStates();
		};

		const handleRemovalAndAssigningOfClasses = () => {
			let classesRemoved = [];

			// If no assigned class is unassigned and clicked the assign button
			if (originalClasses.length == 0 && isCheckboxClicked) {
				classesRemoved = currentlyAssignedClasses;
			}

			// If any assigned class is unassigned
			if (originalClasses.length > 0 && originalClasses.length != currentlyAssignedClasses.length) {
				classesRemoved = currentlyAssignedClasses.filter(
					(value: any) =>
						!originalClasses.some((val) => {
							return value.schoolClassId === val;
						})
				);
			}

			const removedClassIds = classesRemoved.map((x) => {
				return x.schoolClassId;
			});

			updateClassesOfTeacher(newClassesAssigned, removedClassIds);
		};

		const updateSchoolsOfCoach = (newSchoolsAssigned = [], removedSchoolIds) => {
			const routeData = {
				trainerId: trainerId
			};
			const param: UpdateCoachSchoolAssociationModel = {
				assignedSchools: newSchoolsAssigned,
				unassignedSchools: removedSchoolIds
			};
			schoolService
				.updateCoachAssociations(routeData, param)
				.then((result) => {
					MessageHelper.Message(
						NotificationType.Success,
						fmtMsg({ id: SchoolLocale.SchoolAssociationSuccessful })
					);
					getInvitations({ id: refId, type: typeId });
				})
				.catch((e) => {
					MessageHelper.Message(
						NotificationType.Failed,
						fmtMsg({ id: SchoolLocale.SchoolAssociationUnsuccessful })
					);
				});

			clearAllStates();
		};

		const handleRemovalAndAssigningOfSchools = () => {
			let schoolsRemoved = [];

			// If no assigned school is unassigned and clicked the assign button
			if (originalSchools.length == 0 && isCheckboxClicked) {
				schoolsRemoved = currentlyAssignedSchools;
			}

			// If any assigned school is unassigned
			if (originalSchools.length > 0 && originalSchools.length != currentlyAssignedSchools.length) {
				schoolsRemoved = currentlyAssignedSchools.filter(
					(value: any) =>
						!originalSchools.some((val) => {
							return value.schoolId === val;
						})
				);
			}

			const removedSchoolIds = schoolsRemoved.map((x) => {
				return x.schoolId;
			});

			updateSchoolsOfCoach(newSchoolsAssigned, removedSchoolIds);
		};

		const handleOk = () => {
			isClassAssociationModal ? handleRemovalAndAssigningOfClasses() : handleRemovalAndAssigningOfSchools();
		};

		const handleCancel = () => {
			clearAllStates();
		};

		const removeClassCheck = (value) => {
			setIsCheckboxClicked(true);
			setOriginalClasses(value);
		};

		const modifyClassList = (value: string) => {
			const modifiedUnassignedClassList = currentlyUnassignedClasses.filter((element) => {
				return element.schoolClassName.toLowerCase().includes(value.toLowerCase());
			});

			setFilteredUnassignedClasses(modifiedUnassignedClassList);
		};

		const masterListOfClass = () => {
			setFilteredUnassignedClasses(currentlyUnassignedClasses);
		};

		const onSearchForClass = (e) => {
			// search feature for class
			e.target.value.length < 3 ? masterListOfClass() : modifyClassList(e.target.value);
		};

		const onChangeForClass = (list: string[]) => {
			setNewClassesAssigned(list);
			setIndeterminate(!!list.length && list.length < currentlyUnassignedClasses.length);
			setSelectAll(list.length === currentlyUnassignedClasses.length);
		};

		const onCheckAllChangeForClass = (e: CheckboxChangeEvent) => {
			const defaultUnassignedClasses = filteredUnassignedClasses.map((x) => {
				return x.schoolClassId;
			});

			setNewClassesAssigned(e.target.checked ? defaultUnassignedClasses : []);
			setIndeterminate(false);
			setSelectAll(e.target.checked);
			setButtonDisabled(!e.target.checked);
		};

		const removeSchoolCheck = (value) => {
			setIsCheckboxClicked(true);
			setOriginalSchools(value);
		};

		const modifySchoolList = (value: string) => {
			const modifiedUnassignedSchoolList = currentlyUnassignedSchools.filter((element) => {
				return element.schoolName.toLowerCase().includes(value.toLowerCase());
			});

			setFilteredUnassignedSchools(modifiedUnassignedSchoolList);
		};

		const masterListOfSchool = () => {
			setFilteredUnassignedSchools(currentlyUnassignedSchools);
		};

		const onSearchForSchool = (e) => {
			// search feature for school
			e.target.value.length < 3 ? masterListOfSchool() : modifySchoolList(e.target.value);
		};

		const onChangeForSchool = (list: string[]) => {
			setNewSchoolsAssigned(list);
			setIndeterminate(!!list.length && list.length < currentlyUnassignedSchools.length);
			setSelectAll(list.length === currentlyUnassignedSchools.length);
		};

		const onCheckAllChangeForSchool = (e: CheckboxChangeEvent) => {
			const defaultUnassignedSchools = filteredUnassignedSchools.map((x) => {
				return x.schoolId;
			});

			setNewSchoolsAssigned(e.target.checked ? defaultUnassignedSchools : []);
			setIndeterminate(false);
			setSelectAll(e.target.checked);
			setButtonDisabled(!e.target.checked);
		};

		const handleClassesOutputOptions = () => {
			return (
				<>
					{loading ? null : currentlyAssignedClasses.length > 0 || filteredUnassignedClasses.length > 0 ? (
						<>
							<br />
							<br />
						</>
					) : null}
					{loading ? (
						<Loading visible={loading} />
					) : currentlyAssignedClasses.length ? (
						<>
							<Checkbox.Group
								style={{ width: "100%" }}
								disabled={isGlobalHead}
								onChange={removeClassCheck}
								defaultValue={currentlyAssignedClasses.map((x) => {
									return x.schoolClassId;
								})}
							>
								{currentlyAssignedClasses.map((x) => (
									<Row key={x.schoolClassId}>
										<Col>
											<Checkbox
												className="checkbox-list"
												disabled={isGlobalHead}
												value={x.schoolClassId}
											>{`${x.schoolClassName} (${x.campusName})`}</Checkbox>
										</Col>
									</Row>
								))}
							</Checkbox.Group>
							{currentlyAssignedClasses.length > 0 && currentlyUnassignedClasses.length ? <Divider /> : null}
						</>
					) : null}

					{loading ? null : (
						<>
							{filteredUnassignedClasses.length === currentlyUnassignedClasses.length &&
							currentlyUnassignedClasses.length ? (
								<Checkbox
									className="checkbox-list checkbox-select-all"
									indeterminate={indeterminate}
									disabled={isGlobalHead}
									onChange={onCheckAllChangeForClass}
									checked={selectAll}
								>
									{fmtMsg({ id: SchoolLocale.AssociationModalSelectAll })}
								</Checkbox>
							) : null}
							{filteredUnassignedClasses.length ? (
								<Checkbox.Group
									style={{ width: "100%" }}
									disabled={isGlobalHead}
									onChange={onChangeForClass}
									value={newClassesAssigned}
								>
									{filteredUnassignedClasses.map((x) => (
										<Row key={x.schoolClassId}>
											<Col>
												<Checkbox
													className="checkbox-list"
													disabled={isGlobalHead}
													value={x.schoolClassId}
												>{`${x.schoolClassName} (${x.campusName})`}</Checkbox>
											</Col>
										</Row>
									))}
								</Checkbox.Group>
							) : null}
						</>
					)}

					{loading ? null : displayNoResult()}

					{loading ? null : (
						<>
							<br />
							<br />
							<div className="manage-class-association-note">
								{fmtMsg({ id: SchoolLocale.ClassAssociationModalNote })}
							</div>
						</>
					)}
				</>
			);
		};

		const handleSchoolsOutputOptions = () => {
			return (
				<>
					{loading ? null : currentlyAssignedSchools.length > 0 || filteredUnassignedSchools.length > 0 ? (
						<>
							<br />
							<br />
						</>
					) : null}
					{loading ? (
						<Loading visible={loading} />
					) : currentlyAssignedSchools.length ? (
						<>
							<Checkbox.Group
								style={{ width: "100%" }}
								disabled={isGlobalHead}
								onChange={removeSchoolCheck}
								defaultValue={currentlyAssignedSchools.map((x) => {
									return x.schoolId;
								})}
							>
								{currentlyAssignedSchools.map((x) => (
									<Row key={x.schoolId}>
										<Col>
											<Checkbox
												className="checkbox-list"
												disabled={isGlobalHead}
												value={x.schoolId}
											>
												{x.schoolName}
											</Checkbox>
										</Col>
									</Row>
								))}
							</Checkbox.Group>
							{currentlyAssignedSchools.length > 0 && currentlyUnassignedSchools.length ? <Divider /> : null}
						</>
					) : null}

					{loading ? null : (
						<>
							{filteredUnassignedSchools.length === currentlyUnassignedSchools.length &&
							currentlyUnassignedSchools.length ? (
								<Checkbox
									className="checkbox-list checkbox-select-all"
									indeterminate={indeterminate}
									disabled={isGlobalHead}
									onChange={onCheckAllChangeForSchool}
									checked={selectAll}
								>
									{fmtMsg({ id: SchoolLocale.AssociationModalSelectAll })}
								</Checkbox>
							) : null}
							{filteredUnassignedSchools.length ? (
								<Checkbox.Group
									style={{ width: "100%" }}
									disabled={isGlobalHead}
									onChange={onChangeForSchool}
									value={newSchoolsAssigned}
								>
									{filteredUnassignedSchools.map((x) => (
										<Row key={x.schoolId}>
											<Col>
												<Checkbox
													className="checkbox-list"
													disabled={isGlobalHead}
													value={x.schoolId}
												>
													{x.schoolName}
												</Checkbox>
											</Col>
										</Row>
									))}
								</Checkbox.Group>
							) : null}
						</>
					)}

					{loading ? null : displayNoResult()}
				</>
			);
		};

		const displayNoResult = () => {
			if (isClassAssociationModal) {
				if (!currentlyUnassignedClasses.length) {
					return null;
				}

				if (currentlyAssignedClasses.length) {
					if (!filteredUnassignedClasses.length) {
						return <>{fmtMsg({ id: SchoolLocale.AssociationModalNoResult })}</>;
					}
				} else {
					if (!filteredUnassignedClasses.length) {
						return (
							<>
								<br />
								<br />
								{fmtMsg({ id: SchoolLocale.AssociationModalNoResult })}
							</>
						);
					}
				}
			} else {
				if (!currentlyUnassignedSchools.length) {
					return null;
				}

				if (currentlyAssignedSchools.length) {
					if (!filteredUnassignedSchools.length) {
						return <>{fmtMsg({ id: SchoolLocale.AssociationModalNoResult })}</>;
					}
				} else {
					if (!filteredUnassignedSchools.length) {
						return (
							<>
								<br />
								<br />
								{fmtMsg({ id: SchoolLocale.AssociationModalNoResult })}
							</>
						);
					}
				}
			}
		};

		React.useImperativeHandle(ref, () => ({
			open: showModal,
			close: handleOk
		}));

		const disableAssignButton = () => {
			var stateFromUnassignedList, stateFromAssignedList;
			stateFromUnassignedList = indeterminate || selectAll ? false : true;
			if (isClassAssociationModal) {
				stateFromAssignedList =
					!isCheckboxClicked || originalClasses.length === currentlyAssignedClasses.length ? true : false;
			} else {
				stateFromAssignedList =
					!isCheckboxClicked || originalSchools.length === currentlyAssignedSchools.length ? true : false;
			}
			return stateFromUnassignedList && stateFromAssignedList;
		};

		return (
			<>
				<Modal
					className="association-modal"
					title={
						isClassAssociationModal
							? fmtMsg({ id: SchoolLocale.ManageClassAssociation })
							: fmtMsg({ id: SchoolLocale.ManageSchoolAssociation })
					}
					closable={false}
					visible={isModalVisible}
					footer={[
						<Button
							className="button"
							key="assign"
							type="primary"
							disabled={isButtonDisabled && disableAssignButton()}
							onClick={handleOk}
						>
							{fmtMsg({ id: SchoolLocale.AssociationModalAssignButton })}
						</Button>,
						<Button className="button" key="cancel" onClick={handleCancel}>
							{fmtMsg({ id: SchoolLocale.AssociationModalCancelButton })}
						</Button>
					]}
					destroyOnClose={true}
				>
					<Input
						prefix={<Icon type="search" />}
						placeholder={
							isClassAssociationModal
								? fmtMsg({ id: SchoolLocale.AssociationModalSearchClass })
								: fmtMsg({ id: SchoolLocale.AssociationModalSearchSchool })
						}
						allowClear
						onChange={isClassAssociationModal ? (e) => onSearchForClass(e) : (e) => onSearchForSchool(e)}
					/>

					{isClassAssociationModal ? handleClassesOutputOptions() : handleSchoolsOutputOptions()}
				</Modal>
			</>
		);
	})
);

export default AssociationModal;
