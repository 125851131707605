import { GLServiceBase, customErrMsgs, HttpMethod, ServerExceptionCode, getServiceMethodKey } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { ICampusService, CampusRequest, CampusesPagingRequest } from './index';
import { SchoolLocale } from '../../../locales/localeid';

@injectable()
export class CampusService extends GLServiceBase<CampusRequest, CampusesPagingRequest> implements ICampusService {
    serviceRoute = { prefix: 'admin/v1/schools/{schoolId}/campuses', suffix: '{campusId}' }
    errMsgs = (() => {
        const result = {};
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = {id: SchoolLocale.CommonErrorTargetSaveFailed, values: {target: {id:SchoolLocale.CampusTitle}}};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.TargetIsNullException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = {id: SchoolLocale.CommonErrorNoTarget, values: {target: {id:SchoolLocale.CampusTitle}}};
        result[getServiceMethodKey(this, HttpMethod.Put, ServerExceptionCode.ArgumentIsNullOrInvalidException, `${this.serviceRoute.prefix}/${this.serviceRoute.suffix}`)] = {id: SchoolLocale.CommonErrorTargetSaveFailed, values: {target: {id:SchoolLocale.CampusTitle}}};
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.TargetIsNullException)] = {id: SchoolLocale.CommonErrorNoTarget, values: {target: {id:SchoolLocale.CampusTitle}}};
        result[getServiceMethodKey(this, HttpMethod.Delete, ServerExceptionCode.StrongAssociationException)] = SchoolLocale.CampusDeleteError;
        return result;
    })(); 
    getAccessible(count, route) {
        return this.getItemsBy('accessiblecampuses', { count }, route);
    }
    validateCampus(route) {
        return this.create('{campusId}/validation', null, route);
    }
    getCampusLicenseTypes(schoolId: string, campusId: string): Promise<any> {
        const route = {
            schoolId: schoolId,
            campusId: campusId
        };
        return this.get("{campusId}/CampusLicenseTypes", null, route);
    }
}