export const enum TemplateTagCode {
    TargetName = "TargetName",
    TargetEmail = "TargetEmail",
    Region = "Region",
    School = "School",
    Campus = "Campus",
    Class = "Class",
    InvitationCode = "InvitationCode",
    InvitationQRCode = "InvitationQRCode",
    AcceptInvitationLink = "AcceptInvitationLink",
    InvitationLink = "InvitationLink",
    ExpirationDate = "ExpirationDate",
    PageBreak = "PageBreak",
    StudentEnglishName = "StudentEnglishName",
    StudentNativeName = "StudentNativeName"
}

export const enum TemplateTagText {
    TargetName = "Target Name",
    TargetEmail = "Target Email",
    Region = "Region",
    School = "School",
    Campus = "Campus",
    Class = "Class",
    InvitationCode = "Invitation Code",
    InvitationQRCode = "Invitation QR Code",
    AcceptInvitationLink = "Accept Invitation Link",
    InvitationLink = "Invitation Link",
    ExpirationDate = "Expiration Date",
    PageBreak = "Page Break",
    StudentEnglishName = "Student English Name",
    StudentNativeName = "Student Native Name"
}

export const enum InvitationTypeText {
    Teacher = "Teacher",
    Parent = "Parent",
    CampusAdmin = "Campus Admin",
    SchoolAdmin = "School Admin",
    RegionAdmin = "Region Admin",
    TrainingAdmin = "Training Admin",
    Trainer = "Coach",
    GlobalHead = "Global Head",
    SystemAdmin = "System Admin",
    SubTeacher = "Substitute Teacher",
    AccountManager = "Account Manager",
    TrainingManager = "Training Manager",
    ContentAdmin = "Content Administrator"
}

export enum InvitationLanguageText {
    // Burmese = "Burmese",
    Chinese = "InvitationTemplateFieldLanguageChinese",
    English = "InvitationTemplateFieldLanguageEnglish",
    Japanese = "InvitationTemplateFieldLanguageJapanese",
    Korean = "InvitationTemplateFieldLanguageKorean",
    Malaysia = "InvitationTemplateFieldLanguageMalaysia",
    // Mandarin = "Mandarin",
    Mongolian = "InvitationTemplateFieldLanguageMongolian",
    Russian = "InvitationTemplateFieldLanguageRussian",
    Spanish = "InvitationTemplateFieldLanguageSpanish",
    Vietnamese = "InvitationTemplateFieldLanguageVietnamese",
    Arabic = "InvitationTemplateFieldLanguageArabic",
    Thai = "InvitationTemplateFieldLanguageThailand"
}

export const enum ContactType {
    ShippingContact = 0,
    SupportContact = 1,
    BillingContact = 2,
    MainContact = 3
}

export enum UserResourceRelation {
    RegionAdmin = 0,
    SchoolTrainer = 1,
    SchoolTeacher = 2,
    SchoolAdmin = 3,
    CampusAdmin = 4,
    ClassTeacher = 5
}

export const enum RoleNameText {
    Teacher = "Teacher",
    Parent = "Parent",
    CampusAdmin = "Campus Admin",
    SchoolAdmin = "School Admin",
    RegionAdmin = "Region Admin",
    TrainingAdmin = "Training Admin",
    Trainer = "Coach",
    GlobalHead = "Global Head",
    SystemAdmin = "System Admin",
    AccountManager = "Account Manager",
    TrainingManager = "Training Manager",
    ContentAdmin = "Content Administrator"
}

export const enum InvitationType {
    ClassTeacher = "0",
    Parent = "1",
    CampusAdmin = "2",
    SchoolAdmin = "3",
    RegionAdmin = "4",
    TrainingAdmin = "5",
    Trainer = "6",
    GlobalHead = "7",
    SystemAdmin = "8",
    SubTeacher = "9",
    SchoolTeacher = "10",
    AccountManager = "11",
    RegionTrainer = "12",
    TrainingManager = "13",
    ContentAdmin = "14"
}

export const enum PurchaseTarget {
    School = 0,
    Class = 1,
    SchoolClass = 2,
    ClassSchool = 3
}

export enum SchoolClassTimeType {
    Other = 0,
    TSI = 1,
    REP = 2,
    GrapeSEEDConnect = 3
}
export enum SchoolClassTimeTypeText {
    Other = "class_editing.time.typeother",
    TSI = "class_editing.time.typetsi",
    REP = "class_editing.time.typerep",
    GrapeSEEDConnect = "class_editing.time.typeremotetsi"
}
export enum SchoolClassDayName {
    Su = "classlist.column.dayssunday",
    M = "classlist.column.daysmonday",
    Tu = "classlist.column.daystuesday",
    W = "classlist.column.dayswednesday",
    Th = "classlist.column.daysthursday",
    F = "classlist.column.daysfriday",
    Sa = "classlist.column.dayssaturday"
}
export enum subscriptionTypeUsage {
    Standard = 0,
    Dual = 1,
    Single = 2
}
export enum StudentSubscriptionType {
    TextBook = 0,
    Digital = 1,
    Dual = 2
}
export enum SubscriptionActionType {
    Approve = 1,
    Deny = 2
}
export enum GSSchoolAction {
    None = "GS_Home_None",

    Navigate2TrainingPortal = "GS_Home_Training",
    Navigate2ReportPortal = "GS_Home_Report",
    Navigate2KoreaOrder = "GS_Home_KoreaOrder",

    ListRegionOrders = "GS_Region_Orders",
    ListRegionSchoolTsiSettings = "GS_School_TSI_Setting",
    ListRegionSchoolTalkTimeSettings = "GS_School_TalkTime_Setting",
    ListRegionSchoolTagView = "GS_School_Tag_View",
    ListRegionSchoolTagManage = "GS_School_Tag_Manage",
    AllowExternalUserVTSI = "GS_Region_AllowExternalUserVTSI",

    Campuses = "GS_Home_Campues",
    ListSchoolAccountManager = "GS_School_AccountManager",
    EditSchoolAccountManager = "GS_School_AccountManager_Edit",
    AddSchoolAccountManager = "GS_AccountManagers_Add",
    DeleteSchoolAccountManager = "GS_AccountManagers_Delete",
    ResendSchoolAccountManager = "GS_AccountManagers_Send",

    EditSchoolInternalNotes = "GS_School_EditInternalNotes",
    EditSchoolAdmins = "GS_Campuses_EditSchoolAdmin",
    EditSchoolCode = "GS_SchoolCode_Edit",
    AddSchoolAdmin = "GS_SchoolAdmins_Add",
    DeleteSchoolAdmin = "GS_SchoolAdmins_Delete",
    UpdateSchoolAdminContact = "GS_SchoolAdmins_UpdateContact",
    ResendSchoolAdminInvitationEmail = "GS_SchoolAdmins_Send",
    AddCampus = "GS_Campuses_AddCampus",
    EditCampus = "GS_Campus_EditCampus",
    EditCampusGSNumber = "GS_Campus_EditCampusGSNumber",
    SaveCampus = "GS_Campus_SaveCampus",
    DeleteCampus = "GS_Campus_DeleteCampus",
    Invitations = "GS_List_InvitationCode",
    MovePromoteStudents = "GS_Campus_MovePromoteStudents",

    AcknowledgeSchool = "GS_Acknowledge_School",
    AcknowledgeClass = "GS_Acknowledge_Class",
    AcknowledgeClassUnitPlan = "GS_Acknowledge_ClassUnitPlan",

    Classes = "GS_Home_Classes",
    AddClass = "GS_Campus_AddClass",
    EditClass = "GS_Class_EditClass",
    SaveClass = "GS_Class_SaveClass",
    DeleteClass = "GS_class_DeleteClass",
    ClassPromotion = "GS_Class_Promotion",
    BulkEditClass = "GS_Campus_BulkEditClass",
    AddClassStartUnit = "GS_Class_AddStartUnit",
    AddClassMaxUnit = "GS_Class_AddMaxUnit",
    EditClassStartUnit = "GS_Class_EditStartUnit",
    EditClassMaxUnit = "GS_Class_EditMaxUnit",
    ClassMoveStudent = "GS_Class_MoveStudent",
    ClassIgnoreRegionEditLicense = "GS_Class_IgnoreRegionEditLicense",
    ClassIgnoreRegionEditUnit = "GS_Class_IgnoreRegionEditUnit",
    ClassIgnoreUnitPlanUnlock = "GS_Class_IgnoreUnitPlanUnlock",
    CampusAdmins = "GS_Campus_EditCampusAdmin",
    AddCampusAdmin = "GS_CampusAdmins_Add",
    DeleteCampusAdmin = "GS_CampusAdmins_Delete",
    ResendCampusAdminInvitationEmail = "GS_CampusAdmins_Send",
    CampusCart = "GS_Campus_Cart",
    CampusOrders = "GS_Campus_Orders",
    EditOrder = "GS_Campus_EditOrder",
    EditTeacher = "GS_Class_EditTeacher",
    AddTeacher = "GS_ClassTeachers_Add",
    DeleteTeacher = "GS_ClassTeachers_Delete",
    ResendEmail = "GS_ClassTeachers_Send",
    EditLicenseEntry = "GS_Class_EditLicenseEntry",
    EditLicense = "GS_Class_EditLicense",
    SaveLicense = "GS_ClassLicense_Save",
    EditUnitPlan = "GS_Class_EditUnitPlan",
    SavePlan = "GS_ClassUnitPlan_Save",
    Cart = "GS_Class_EditCart",
    AddProduct = "GS_ClassCart_AddProduct",
    StudentList = "GS_Class_ShowStudentList",
    InviteStudents = "GS_Class_InviteStudent",
    RemoveStudents = "GS_ClassLicense_DeleteStudent",
    EditStudentName = "GS_Class_EditStudent",
    UncheckMindaysPerUnit = "GS_Class_UncheckMindaysPerUnit",
    UncheckMaxUnitPerYear = "GS_Class_UncheckMaxUnitPerYear",
    EditClassInternalNotes = "GS_Class_EditInternalNotes",
    IgnoreCampusAnnualPrepComplete = "GS_Campus_IgnoreCampusAnnualPrepComplete",
    CampusAnnualPrepComplete = "GS_Campus_CampusAnnualPrepComplete",

    MaterialOrder = "GS_Home_Orders",

    InvitationTemplate = "GS_Home_Templates",
    NewTemplate = "GS_Templates_New",
    EditTemplate = "GS_Template_Edit",
    DeleteTemplate = "GS_Template_Delete",

    ListSchoolTeacher = "GS_School_ListSchoolTeachers",
    AddSchoolTeacher = "GS_SchoolTeacher_Add",
    DeleteSchoolTeacher = "GS_SchoolTeacher_Delete",
    ResendSchoolTeacherEmail = "GS_SchoolTeacher_Send",
    SchoolTeacherHome = "GS_SchoolTeacher_Home",
    SchoolTeacherTagManage = "GS_SchoolTeacher_Tag_Manage",
    SchoolTeacherTagView = "GS_SchoolTeacher_Tag_View",
    TeacherMyActivity = "GS_Teacher_MyActivity",

    SchoolSetting = "GS_Home_Settings",
    SchoolResources = "GS_Home_Resource",
    SchoolLMS = "GS_Home_LMS",
    SaveSetting = "GS_SchoolSetting_Save",
    EditSchool = "GS_Edit_School_AdvanceSetting",
    EditAllowLessonPlan = "GS_SchoolSetting_Edit_AllowLessonPlanView",
    ReadOnlyAllowLessonPlan = "GS_SchoolSetting_ReadOnly_AllowLessonPlanView",

    Search = "GS_Home_Search",
    Navigate2Curriculum = "GS_Home_Curriculum",
    SubscriptionRequest = "GS_Subscription_Request",

    ContentRevisionHistory = "GS_ContentRevisionHistoty",
    ChangeLogContentTab = "GS_ChangeLog_Content",
    ChangeLogSystemTab = "GS_ChangeLog_System",

    CoachLink = "GS_Coach_CoachLink",
    SchoolLink = "GS_Coach_SchoolLink",
    CampusLink = "GS_Coach_CampusLink",
    SendPrevisitationForm = "GS_Coach_SendPrevisitationForm",
    IgnoreTeacher = "GS_Coach_IgnoreTeacher",
    SendReminder = "GS_Coach_SendReminder",
    ViewResponse = "GS_Coach_ViewResponse",
    MoveNext = "GS_Coach_MoveNext",
    TeacherLink = "GS_Coach_TeacherLink",
    ClassLink = "GS_Coach_ClassLink",
    Chat = "GS_Coach_Chat",
    ChatHistory = "GS_Chat_History",
    RemindSendVideo = "GS_Coach_RemindSendVideo",
    DeleteVideo = "GS_Coach_DeleteVideo",
    DownloadVideo = "GS_Coach_DownloadVideo",
    UploadVideo = "GS_Coach_UploadVideo",
    SaveAsDraft = "GS_Coach_SaveAsDraft",
    SendFeedback = "GS_Coach_SendFeedback",
    ViewFeedback = "GS_Coach_ViewFeedback",
    Completed = "GS_Coach_Completed",
    NotesForSchool = "GS_Coach_NotesForSchool",
    NotesForAdmin = "GS_Coach_NotesForAdmin",
    CoachMyActivity = "GS_Coach_MyActivity",
    CoachNewSupport = "GS_Coach_NewSupport",
    Navigate2StudentPortal = "GS_Home_Student",
}

export enum ListStates {
    Active,
    Inactive,
    All,
    Pending,
    Future
}

export enum PollListStates {
    All,
    Complete,
    Contribute,
    View
}

export enum InvitationListStates {
    Pending,
    Accepted,
    ChildrenAdded,
    Expired,
    All
}

export const enum SchoolCurriculumType {
    None = "-1",
    GrapeSEED = "0",
    LittleSEED = "1"
}

export const enum SchoolClassSubscriptionType {
    Textbook = "0",
    Digital = "1"
}

export const enum SchoolClassSubscriptionTypeText {
    Digital = "Digital",
    Textbook = "Textbook"
}

export const enum SchoolClassSubscriptionTypeUsage {
    Standard = "0",
    Dual = "1",
    Single = "2"
}

export const enum SchoolClassSubscriptionTypeUsageText {
    Standard = "Standard",
    Dual = "Force Dual",
    Single = "Force Single"
}

export enum SessionStorageKeys {
    SchoolsSelectedRegion = "schools.selected.region",
    SchoolsPagination = "schools.pagination",
    LocalProductsPagination = "products.local.pagination",
    GlobalProductsPagination = "products.global.pagination"
}

export enum Currency {
    EUR = "EUR",
    JPY = "JPY",
    KRW = "KRW",
    MNT = "MNT",
    RM = "RM",
    RUB = "RUB",
    USD = "USD"
}

export enum GSAdminAction {
    None = "GA_HomePage_None",

    Dashboard = "GA_HomePage_Dashboard",

    RegionGroup = "GA_Create_RegionGroup",
    EditRegionGroup = "GA_Edit_Region_RegionGroup",
    EditRegionDisabled = "GA_Edit_Region_Disabled",

    Search = "GA_Search",
    ClosePeriod = "GA_Close_Period",

    ListSchool = "GA_HomePage_School",
    CreateSchool = "GA_Create_School",
    EditSchool = "GA_Edit_School",
    DeleteSchool = "GA_Delete_School",
    ListSchoolTrainer = "GA_List_SchoolTrainer",
    EditSchoolTrainer = "GA_Edit_SchoolTrainer",
    InviteSchoolTrainer = "GA_Invite_SchoolTrainer",
    InviteRegionTrainer = "GA_Invite_RegionCoach",
    DeleteSchoolTrainer = "GA_Delete_SchoolTrainer",
    DeleteRegionTrainer = "GA_Delete_RegionCoach",
    SendSchoolTrainer = "GA_Send_SchoolTrainer",
    SendRegionTrainer = "GA_Send_RegionCoach",
    SchoolTrainerHome = "GA_SchoolTrainer_Home",
    EditTrainingPath = "GA_Edit_TrainingPath",
    ListTrainingCourseHistory = "GA_List_TrainingCourse_History",
    VisitSchool = "GA_Visit_School",
    EditTeacherNotes = "GA_Edit_TeacherNotes",
    ListSchoolAdmin = "GA_List_SchoolAdmin",
    InviteSchoolAdmin = "GA_Invite_SchoolAdmin",
    DeleteSchoolAdmin = "GA_Delete_SchoolAdmin",
    ListSchoolBillingHistory = "GA_List_SchoolBillingHistory",
    ListSchoolChangeHistory = "GA_List_SchoolChangeHistory",
    ListSchoolVisitationHistory = "GA_List_SchoolVisitationHistory",
    ListPendingChanges = "GA_List_PendingChanges",
    SchoolTagsView = "GA_School_Manager_Tags_View",
    SchoolTagsManage = "GA_School_Manager_Tags_Manage",

    ListUser = "GA_HomePage_User",
    AddTrainerRole = "GA_Add_TrainerRole",
    DeleteTrainerRole = "GA_Delete_TrainerRole",
    EditUser = "GA_Edit_User",
    EditRegionAdminRegion = "GA_Edit_RegionAdminRegion",
    DeleteUser = "GA_Delete_User",
    AddUserRole = "GA_Add_UserRole",
    RemoveUserRole = "GA_Delete_UserRole",

    Invitation = "GA_List_Invitation",
    CreateInvitaion = "GA_Create_Invitation",
    DeleteInvitaion = "GA_Delete_Invitation",
    SendInvitaionEmail = "GA_Send_InvitationEmail",

    ListInvitationTemplate = "GA_HomePage_InvitationTemplate",
    CreateInvitationTemplate = "GA_Create_InvitationTemplate",
    EditInvitationTemplate = "GA_Edit_InvitationTemplate",
    DeleteInvitationTemplate = "GA_Delete_InvitationTemplate",

    GetRegion = "GA_Get_Region",
    GetSchool = "GA_Get_School",

    ListRegion = "GA_HomePage_Region",
    CreateRegion = "GA_Create_Region",
    EditRegion = "GA_Edit_Region",
    ViewRegion = "GA_View_Region_Settings",
    DeleteRegion = "GA_Delete_Region",
    ListRegionAdmin = "GA_List_RegionAdmin",
    InviteRegionAdmin = "GA_Invite_RegionAdmin",
    DeleteRegionAdmin = "GA_Delete_RegionAdmin",
    ExportRegionLicense = "GA_Export_RegionLicense",
    ListRegionLicense = "GA_Region_LicenseTotal",
    EditExtendedSubscription = "GA_Edit_ExtendedSubscription",

    ListGlobalProduct = "GA_HomePage_GlobalProduct",
    CreateGlobalProduct = "GA_Create_GlobalProduct",
    EditGlobalProduct = "GA_Edit_GlobalProduct",
    DeleteGlobalProduct = "GA_Delete_GlobalProduct",
    ListLocalProduct = "GA_List_LocalProduct",
    CreateLocalProduct = "GA_Create_LocalProduct",
    EditLocalProduct = "GA_Edit_LocalProduct",
    DeleteLocalProduct = "GA_Delete_LocalProduct",

    CreateNotification = "GA_Create_Notification",
    ReviewNotification = "GA_Review_Notification",
    InvitationCodeLookup = "GS_InvitationCode_Lookup",
    ClassManualAdjustment = "GA_Class_ManualAdjustment",
    ListRegionCoach = "GA_List_RegionCoach",
    RegionCoachHome = "GA_RegionCoach_Home",

    ViewCoachFeedback = "GA_View_CoachFeedback",

    ListPortalInvitation = "GA_List_PortalInvitation",
    ManageStudents = "GA_Manage_Students",
    VerificationManageStudents = "GA_Manage_Students_Verification",

    ManageTerm = "GA_Manage_Term",

    ManagePoints = "GA_Manage_Points",
    ManagePointsView = "GA_Manage_Points_View",
    ManagePointsEdit = "GA_Manage_Points_Edit"

}
export enum ReleaseType {
    Public = 1,
    Private,
    Internal
}

export enum PublishStatus {
    Unpublished = 0,
    PublishToTest,
    PublishToLive
}

// jira area to change-log change area
//***jira area key should be in lowercase and one word***
export enum JiraToChangeAreaEnum {
    "school" = "School Site",
    "content" = "Content Site",
    "parent" = "Parent Portal",
    "student" = "Student App/Site",
    "global" = "Global Website",
    "general" = "General",
    "studentapp" = "Student App/Site",
    "training" = "Training Site",
    "report" = "Report Site",
    "help" = "Help Site"
}

//jira type to change-log change type
//***jira type keys should be in lowercase and one word***
export enum JiraToChangeTypeEnum {
    "bug" = "Defect",
    "defect" = "Defect",
    "story" = "Feature"
}

export enum SubscriptionCodes {
    Change = 4801,
    TypeChange = 4802
}

export enum SurveyAction {
    CreateTemplate = "Survey_Create_Template",
    EditTemplate = "Survey_Edit_Template",
    DeleteTemplate = "Survey_Delete_Template",
    ListTemplate = "Survey_List_Template",
    CreateSurvey = "Create_Survey",
    EditSurvey = "Edit_Survey",
    DeleteSurvey = "Delete_Survey",
    ListSurvey = "List_Survey",
    ListMySurvey = "List_MySurvey",
    ListSurveyResponse = "List_SurveyResponses"
}

export enum SurveyTodoType {
    Todo = 1,
    Review,
    CreatorShared,
    ReceiverShared
}

export enum ResponseStatusType {
    Draft = 1,
    Submitted,
    Reviewed
}

export enum Weekday4Backend {
    Sunday = 1,
    Monday = 2,
    Tuesday = 3,
    Wednesday = 4,
    Thursday = 5,
    Friday = 6,
    Saturday = 7
}

export enum Weekday4UnitPlan {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5
}

export enum Weekday4Momentjs {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum AppLockState {
    Unlock = 0,
    Lock = 1
}

export enum SchoolAdminContacts {
    MainShippingContact = "mainShippingContact",
    MainSupportContact = "mainSupportContact",
    MainBillingContact = "mainBillingContact"
}

export enum CampusAdminContacts {
    MainShippingContact = "mainShippingContact",
    MainSupportContact = "mainSupportContact",
    MainContact = "mainContact"
}

export enum TemplateBackgroundImageLayout {
    auto = 0,
    stretch = 1
}

export enum PollSectionKeys {
    View = 0,
    Review = 1
}

export enum NotificationChannel {
    BulletinBoard = 0,
    Email = 1,
    BulletinBoardAndEmail = 2
}

export enum LicenseEditNoteType {
    User = 0,
    System = 1
}

export enum EventType {
    AddClass = 0,
    DisableClass = 1,
    ClassUnitPlanChange = 2,
    ClassLicenseChange = 3,
    ClassMandatoryDataChange = 4,
    RemoveStudent = 5,
    MoveStudent = 6,
    StudentSubscriptionChange = 7,
    EnableClass = 8,
    MoveUnregisteredStudent = 9,
    PromoteClass = 10
}

export enum EventStatus {
    Pending = 0,
    Approved = 1,
    Denied = 2
}

export enum PendingChangeField {
    AddClass,
    PromoteClass,
    DisableClass,
    EnableClass,
    Grade,
    StudentCount,
    TSILessonsPerWeek,
    StartUnit,
    EndUnit,
    Plans,
    RemoveStudent,
    MoveStudent,
    ChangeStudentSubscription,
    StartDate,
    EndDate,
    MoveUnregisteredStudent,
    TermId,
    LicenseType,
}

export enum BulkClassEditErrorCode {
    BulkEditClassSchoolNotExistException = 4601,
    BulkEditClassCampusNotExistException = 4602,
    BulkEditClassNotExistException = 4603,
    BulkEditClassNameIsNullException = 4604,
    BulkEditClassInvalidLicenseTypeException = 4605,
    BulkEditClassInvalidUnitRangeException = 4606,
    BulkEditClassInvalidAgeException = 4607,
    BulkEditClassInvalidREPMinutesPerWeekException = 4608,
    BulkEditClassInvalidTSIMinutesPerWeekException = 4609,
    BulkEditClassInvalidLicenseTypeChangeException = 4610,
    BulkEditClassInvalidCloseDateException = 4611,
    BulkEditClassCampusAnnualPreparationCompleteException = 4614,
    BulkEditClassInvalidLicenseCountException = 4617,
    BulkEditClassNotExistStartUnitPlanException = 4618
}

export enum LanguageTimeFormatWithoutSecond {
    en = "HH:mm",
    "zh-cn" = "HH:mm",
    ms = "HH:mm",
    ja = "HH:mm",
    ko = "HH:mm",
    ru = "HH:mm",
    vi = "HH:mm",
    mn = "HH:mm",
    es = "HH:mm",
    "ar-sa" = "HH:mm",
    th = "HH:mm",
    my = "HH:mm"
}

export enum DigitalAdoptionReportType {
    GrapeseedLicenses,
    DigitalLicenses,
    InvitationsPrinted,
    AccountsLinked,
    TabletLogin,
    REPPlayback
}

export enum Gender {
    None = 0,
    Male = 1,
    Female = 2
}

export enum RegionType {
    Standard,
    Japan
}

export enum TSISettingEntityType {
    School = 0,
    Campus = 1,
    Class = 2,
    Student = 3
}

export enum ChangeLogType {
    School = 0,
    Campus = 1,
    SchoolClass = 2,
    Hybrid = 3
}

export enum StudentRegistrationMode {
    None = "None",
    Manual = "Manual",
    Generic = "Generic"
}

export enum StudentRegistrationType {
    Both,
    Generic,
    Manual
}

export enum StudentInvitationType {
    PrintInvitation,
    ManualRegistration
}

export enum SchoolEventType {
    MandatoryDataChange = 0
}

export enum SchoolChangeField {
    Name,
    EnglishName
}

export enum CampusEventType {
    AddCampus = 0,
    DisableCampus = 1,
    MandatoryDataChange = 2,
    EnableCampus = 3,
    MaterialOrder = 4
}

export enum CampusChangeField {
    AddCampus,
    DisableCampus,
    EnableCampus,
    Name,
    Phone,
    City,
    PostalCode,
    FullAddress,
    LicenseList
}

export enum HybridChangeField {
    Change
}

export enum HybridEventType {
    SchoolMandatoryDataChange = 0,
    AddCampus = 1,
    DisableCampus = 2,
    CampusMandatoryDataChange = 3,
    EnableCampus = 4,
    AddClass = 5,
    DisableClass = 6,
    ClassUnitPlanChange = 7,
    ClassLicenseChange = 8,
    ClassMandatoryDataChange = 9,
    RemoveStudent = 10,
    MoveStudent = 11,
    StudentSubscriptionChange = 12,
    EnableClass = 13,
    MoveUnregisteredStudent = 14,
    PromoteClass = 15,
    CampusMaterialOrder = 16,
    SchoolMovePromoteStudents = 17
}

export enum TrainingCourseType {
    Video = 0,
    Questionnaire = 1,
    Link = 2
}

export enum TrainingQuestionType {
    Option = 1,
    MultiChoice = 2,
    Text = 3
}

export enum TrainingCourseStatus {
    RefresherTraining = 1,
    FoundationTraining = 2,
    TrainingPath = 3
}

export enum VersionGroupLicenseType {
    GrapeSEED = 0,
    LittleSEED = 1
}

export enum MonthType {
    Jan = 1,
    Feb = 2,
    Mar = 3,
    Apr = 4,
    May = 5,
    Jun = 6,
    Jul = 7,
    Aug = 8,
    Sep = 9,
    Oct = 10,
    Nov = 11,
    Dec = 12
}

export enum MaterialOrderStatus {
    Initialized = 0,
    Submitted = 1,
    InProgress = 2,
    Shipped = 3,
    Closed = 4,
    Draft = 5
}

export enum ManualRegistrationPageStep {
    Registration = 1,
    Suggestion = 2,
    Invitation = 3
}

export enum RegistrationStepTriggerType {
    None = 1,
    Single = 2,
    Bulk = 3
}

export enum ManualRegistrationType {
    Single = 1,
    Bulk = 2
}

export enum ExecuteState {
    Idle = 0,
    Success = 1,
    Pending = 2,
    Error = 3
}

export enum RegionStatus {
    Enabled = 1,
    Disabled = 2,
    Test = 3
}

export const enum KeyCode {
    Enter = 13,
    Escape = 27,
    PageUp = 33,
    PageDown = 34,
    Left = 37,
    Up = 38,
    Right = 39,
    Down = 40
}

export const enum KeyText {
    Enter = "Enter",
    Escape = "Escape",
    PageUp = "PageUp",
    PageDown = "PageDown",
    Left = "Left",
    Up = "Up",
    Right = "Right",
    Down = "Down"
}

export const enum ProductType {
    General = -1,
    GrapeSeed = 0,
    LittleSeed = 1
}

export enum DataMeasurementEnum {
    Byte = 0,
    KB = 1,
    MB = 2,
    GB = 3,
    TB = 4
}

export enum ServiceExceptionCode {
    TeacherAlreadyExistsInClassException = 4843,
    SchoolDisabledException=4844,
    CampusDisabledException=4845,
    ClassDisabledException = 4846,
    EmailsLimitException = 473,
    EmailsTargetLimitException = 474,
}

export enum LoginIdType {
    Email = 0,
    Phone = 1
}

export enum StudentVerificationCase {
    AllMatches = 0,
    OnlyAdditionalMatches = 1,
    NoMatch = 2
}

export enum VerificationCodeUsage {
    Registration = 0,
    ForgotPassword = 1,
    UpdateEmail = 2,
    UpdatePhone = 3
}

export enum EventCategory {
    School = 1,
    Campus = 2,
    Class = 3,
    License = 4,
    MaterialRequest = 5,
    StudentVerification = 6
}

export enum EventTypeInfo {
    PendingChanges = 1,
    StudentVerification = 2
}

export enum EventInfo {
    PendingChangesAddCampus = 1,
    PendingChangesDisableCampus = 2,
    PendingChangesChangeCampusMandatoryData = 3,
    PendingChangesEnableCampus = 4,
    PendingChangesMaterialOrder = 5,
    PendingChangesChangeSchoolMandatoryData = 6,
    StudentVerificationAllowStudentVerification = 7,
    PendingChangesAddClass = 8,
    PendingChangesDisableClass = 9,
    PendingChangesChangeClassUnitPlan = 10,
    PendingChangesChangeLicense = 11,
    PendingChangesChangeClassMandatoryData = 12,
    PendingChangesRemoveStudent = 13,
    PendingChangesMoveStudent = 14,
    PendingChangesChangeStudentSubscription = 15,
    PendingChangesEnableClass = 16,
    PendingChangesMoveUnregisteredStudent = 17,
    PendingChangesPromoteClass = 18,
    PendingChangesMovePromoteStudents = 19
}

export enum MovePromoteOperationType {
    Move = 0,
    Promote = 1,
    MovePromoted = 2,
    MovePromotedToOriginal = 3
}

export enum InvitationChannel {
    Email = 0,
    Phone
}

export enum ClassCurriculumType {
    GrapeSeedLicense = 0,
    LittleSeedLicense = 1,
}

export const enum EmailParameter {
    EmailsLimited = 3000,
}

export enum ManageAssociationModalType {
    Teacher = 0,
    Coach = 1
}

export enum CheckboxState {
    Unchecked = "unchecked",
    Indeterminate = "indeterminate",
    Checked =  "checked"
}

export enum LicenseTypes {
    Classic = 1,
    Connect = 2,
    Nexus = 3,
    ClassicConnectHybrid = 4,
    ClassicNexusHybrid = 5,
    ConnectNexusHybrid = 6
}