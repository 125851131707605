import React, { Component } from 'react';
import "../component/progress.less";
import { SaveCancelBtns } from '@app/components';
import { clearErrors } from '@app/util/coach/fun';
import { Spin, notification, Icon } from 'antd-min';
import { GLForm, GLFormComponentProps } from 'gl-commonui';
import { SurveyQuestions } from '../component/survey-questions';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { fmtMsg, lazyInject, TYPES, ContextHelper, isQuestionEmpty } from '@app/util';
import { IVisitationService, TeacherRelatedModel } from '@app/service/coach/visitation';
import { VisitationTeacherStage, SurveyQuestionType, SurveyTodoType, ReviewCoachFormStatus } from '@app/util/coach/enum';

interface CoachFeedbacksProps extends GLFormComponentProps {
    visitation: TeacherRelatedModel
    callback: (stage) => void
    teacherStage: VisitationTeacherStage
}
interface CoachFeedbacksStates {
    showBtns?: boolean
    spinning?: boolean
    isSending?: boolean
    reviewCoachForm?: any
    surveyAnswerModel?: any
    todoSurveyResponse?: any
}

@GLForm.create()
export class CoachFeedbacks extends Component<CoachFeedbacksProps, CoachFeedbacksStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    constructor(props) {
        super(props);
        this.state = {
            showBtns: false,
            spinning: false,
            isSending: false,
            reviewCoachForm: {},
            surveyAnswerModel: {},
            todoSurveyResponse: {}
        }
    }
    componentWillMount() {
        this.getTeacherToCoachFeedback();
    }
    getTeacherToCoachFeedback() {
        const { visitation: { associatedInfo: { reviewCoachForm } }} = this.props;
        reviewCoachForm.length && this.getCoachFeedback(reviewCoachForm[0]);
    }
    getCoachFeedback(reviewCoachForm) {
        const { visitation: { id }} = this.props;
        const { surveyInstanceId, status } = reviewCoachForm;
        const params = {
            surveyInstanceId,
            visitationId: id,
            type: SurveyTodoType.Todo,
        }
        this.setState({ spinning: true });
        this.visitationservice.viewSurveyReponse(params).then(data => {
            this.setState({ 
                spinning: false,
                todoSurveyResponse: data,
                reviewCoachForm: reviewCoachForm,
                showBtns: status != ReviewCoachFormStatus.Completed,
            });
        }).catch(() => {
            this.setState({ spinning: false });
        });
    }
    sendCoachFeedback(saveAsDraft=false) { 
        const { form } = this.props;
        if (saveAsDraft) {
            clearErrors(form);
            this.sendCoachFeedbackApi(saveAsDraft, form.getFieldsValue());
        } else {
            form.validateFields((err, values) => {
                if (!isQuestionEmpty(form, values) && !err) {
                    this.sendCoachFeedbackApi(saveAsDraft, values);
                }
            });
        }
    }
    sendCoachFeedbackApi(saveAsDraft, values) {
        const { form, visitation, callback } = this.props;
        const { todoSurveyResponse: { id, surveyInstanceId }, reviewCoachForm } = this.state;
        const status = saveAsDraft ? ReviewCoachFormStatus.Draft : ReviewCoachFormStatus.Completed;
        const userId = ContextHelper.getUserLoginInfo().profile.sub;
        const params = {
            visitationId: visitation.id,
            todoSurveyRequestModel: {
                id: id,
                surveyInstanceId,
                responseStatus: status,
                surveyResponseAnswer: this.formatQuestionAnswer(values),
            },
            model: {
                status,
                surveyInstanceId,
                teacherId: userId,
                visitationId: visitation.id,
                reviewCoachFormId: reviewCoachForm.id,
            }
        }
        this.setState({ isSending: true });
        this.visitationservice.sendCoachFeedback(params).then(data => {
            if (saveAsDraft) {
                this.setState({ 
                    showBtns: true,
                    isSending: false,
                    surveyAnswerModel: data, 
                });
            } else {
                callback(VisitationTeacherStage.Completed);
                this.setState({ 
                    showBtns: false,
                    isSending: false, 
                });
                this.showCompleteNotification();
            }
        });
    }
    showCompleteNotification() {
        notification.open({
            duration: 5,
            className: "complete-notification",
            message: fmtMsg({ id: SchoolLocale.VisitationCompleteMessage }),
            description: fmtMsg({ id: SchoolLocale.VisitationCompleteDescription }),
            icon: <Icon type="schedule" style={{ color: '#52c41a' }} />,
        });
    }
    formatQuestionAnswer(values) {
        const { todoSurveyResponse: { id, surveyQuestion } } = this.state;
        const entries = Object.entries(values) as [string, any][];
        const questionAnswers = [];
        entries
            .forEach((v, k) => {
                if (v[0].indexOf("checkbox-group-") > -1) {
                    const questionId = v[0].slice(15);
                    const question = surveyQuestion.find(q => q.id == questionId);
                    if (v[1].length) {
                        v[1].forEach((opt, key) => {
                            const optionId = opt.slice(4);
                            const option = question.surveyQuestionOption.find(s => s.id == optionId);
                            const answer = question.surveyResponseAnswer.length && 
                                           question.surveyResponseAnswer.find(a => a.surveyQuestionOptionId == optionId);
                            questionAnswers.push({
                                surveyResponseId: id,
                                answer: option.option,
                                surveyQuestionId: questionId,
                                id: answer ? answer.id : null,
                                surveyQuestionOptionId: optionId,
                                surveyQuestionTypeId: SurveyQuestionType.MultChoice,
                            });
                        });
                    } else {
                        questionAnswers.push({
                            id: null,
                            answer: null,
                            surveyResponseId: id,
                            surveyQuestionOptionId: null,
                            surveyQuestionId: questionId,
                            surveyQuestionTypeId: SurveyQuestionType.MultChoice,
                        });
                    }
                } else if (v[0].indexOf("question-") > -1) {
                    const questionId = v[0].slice(9);
                    const question = surveyQuestion.find(q => q.id == questionId);
                    questionAnswers.push({
                        answer: v[1],
                        surveyResponseId: id,
                        id: question.surveyResponseAnswer.length ? 
                            question.surveyResponseAnswer[0].id : null,
                        surveyQuestionId: questionId,
                        surveyQuestionTypeId: SurveyQuestionType.Text
                    });
                }
            });
        return questionAnswers;
    }
    render() {
        const { form, teacherStage } = this.props;
        const { spinning, isSending, showBtns, todoSurveyResponse: { surveyInstanceId, surveyQuestion } } = this.state;
        const isWithoutFeedback = teacherStage == VisitationTeacherStage.CompletedByCoach;
        const mergeShowBtns = showBtns && !isWithoutFeedback;
        return <div className={`coach-feedbacks info-container ${(!showBtns || isSending)&&"disabled"}`}>
            <Spin spinning={spinning}>
                <GLForm form={form}>
                    <div className="question-list">
                        {surveyQuestion && <>
                            <SurveyQuestions 
                                form={form}
                                isDisabled={!showBtns}
                                surveyQuestions={surveyQuestion}
                                surveyInstanceId={surveyInstanceId}
                            />
                            {mergeShowBtns &&
                                <div className="warn-tips">{fmtMsg({ id: SchoolLocale.VisitationCoachFeedbackWarnTips})}</div>}
                            </>
                        }
                        {isWithoutFeedback && <div className="no-coach-feedback-message">
                            {fmtMsg({ id: SchoolLocale.VisitationNotGiveCoachFeedback })}
                        </div>}
                    </div>
                    {mergeShowBtns && 
                    <SaveCancelBtns saveTitle={SchoolLocale.VisitationCoachExperienceSurveySendText} 
                        onSubmit={() => this.sendCoachFeedback(false)}
                        onCancel={() => this.sendCoachFeedback(true)}
                        cancelTitle={SchoolLocale.VisitationSaveDraft} />}
                </GLForm>
            </Spin>
        </div>
    }
}