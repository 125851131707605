import { mergeClasses } from "@app/components/survey/functions";
import React, { FC, useRef, useEffect } from "react";
import { InputNumber as IInputNumber } from "wijmo/wijmo.input";
import { ICellTemplateContext } from "wijmo/wijmo.react.grid";
import { InputNumber } from "wijmo/wijmo.react.input";
import { Key } from "wijmo/wijmo";
import "./input-number.less";

interface GridInputNumberProps extends Partial<IInputNumber> {
	context: ICellTemplateContext;
	className?: string;
}

export const GridInputNumber: FC<GridInputNumberProps> = (props) => {
	const { className, context, ...rest } = props;

	const selectedIndexChanged = (ref: IInputNumber) => {
		const { binding } = context.col;
		context.value = ref.value;
		context.item[binding] = ref.value;
	};

	return (
		<InputNumber {...rest} className={mergeClasses("grid-input-number", className)} value={context.value} valueChanged={selectedIndexChanged} />
	);
};
