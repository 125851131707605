import React, { Component } from 'react';
import "../component/progress.less";
import { SaveCancelBtns } from '@app/components';
import {
    ContextHelper,
    DateHelper,
    fmtMsg,
    GSSchoolAction,
    lazyInject,
    rangeDateValider,
    textareaValider,
    TYPES
} from '@app/util';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { IVisitationService, VisitationModel } from '@app/service/coach/visitation';
import {
    ServerExceptionCode,
    VisitationCoachStage,
    VisitationRequestTargetType,
    VisitationScheduleMode,
    VisitationType
} from '@app/util/coach/enum';
import {
    alignPop,
    ComparisonOperator,
    FormHelper,
    FormItemsLayout,
    GLForm,
    GLFormProps,
    GLGlobal,
    GLUtil,
    LanguageDateFormat,
    MessageHelper,
    NotificationType,
    Role,
    PathConfig,
    RoleName,
    withRouter
} from 'gl-commonui';
import { Button, Checkbox, Col, DatePicker, Icon, Input, Modal, Radio, Row, Spin, Table, Tag } from 'antd-min';
import { IAccountService } from '@app/service/admin/accountservice';
import { IUpcomingEventService } from '@app/service/coach/upcomingevent';
import { trimPlainText } from '@app/util/coach/fun';
import { ISchoolService } from '@app/service/schools';
import { SchoolPathConfig } from '@app/config/pathconfig';
import { IResourceService } from '@app/service/resources';
import { ScheduleModel } from '../component/schedule-model';
import { orderBy } from 'lodash';
import moment from 'moment';

interface SetUpProps extends GLFormProps {
    history?: any
    callback?: (d, isUpdate) => void;
    visitation: VisitationModel
}
interface SetUpStates {
    admins?: any[];
    campuses?: any[];
    teachers?: any[];
    selectedTo: any[];
    selectedCC: any[];
    loading?: boolean;
    isReviewSetupLoading: boolean;
    error_code?: number;
    scheduleMode: number;
    isCreating?: boolean;
    visibleModal: boolean;
    visitationtype: number;
    selectedTeachers?: any[];
    isInformChecked: boolean;
    selectedCampusId?: string;
    selectedCampusKey?: number;
    selectedTeacherKeys: number[];
}

@withRouter
@GLForm.create()
export class SetUp extends Component<SetUpProps, SetUpStates> {
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService;
    @lazyInject(TYPES.ISchoolService)
    schoolservice: ISchoolService;
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService;
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    @lazyInject(TYPES.IUpcomingEventService)
    upcomingeventservice: IUpcomingEventService;
    mainTeachers: string[] = [];
    scheduleModel;
    constructor(props) {
        super(props);
        this.state = {
            selectedTo: [],
            selectedCC: [],
            isCreating: false,
            visibleModal: false,
            isReviewSetupLoading: false,
            selectedTeachers: [],
            isInformChecked: true,
            selectedTeacherKeys: [],
            visitationtype: VisitationType.OnSite,
            scheduleMode: VisitationScheduleMode.Force,
        };
    }
    componentWillMount() {
        const { visitation: { stage, isCancelled } } = this.props;
        stage > VisitationCoachStage.ScheduleDate ? this.reviewSetUpInfo() : this.getCampuses();

        if (this.isAccessingUndeterminedVisitation()) {
            this.reviewSetUpInfo();
        }

        if (isCancelled) {
            this.props.history.push({ pathname: PathConfig.NotFound });
        }
    }

    isAccessingUndeterminedVisitation = (): boolean => {
        const visitationScheduleMode = this.getVisitationScheduleMode();
        return visitationScheduleMode === VisitationScheduleMode.Schedule &&
            this.props.visitation && this.props.visitation.stage === 0 &&
            this.props.visitation.id && this.props.visitation.id.length > 0;
    }

    reviewSetUpInfo() {
        const { visitation: { id, campusId, type, scheduleMode } } = this.props;
        this.setState({ loading: true, isReviewSetupLoading: true });
        Promise.all([this.getTeacherInfos(), this.getRequestInfo(id)])
            .then(([teachers, requestinfo]) => {
                const selectedTeachers = [];
                const mergeTeacherInfos = teachers.map(t => {
                    const teacher = requestinfo.teachers.find(st => st.teacherId == t.id);
                    teacher && selectedTeachers.push({ ...teacher, ...t });
                    return teacher ? { ...teacher, ...t } : t;
                });
                this.setState({
                    scheduleMode,
                    loading: false,
                    isReviewSetupLoading: false,
                    selectedTeachers,
                    visitationtype: type,
                    selectedCampusId: campusId,
                    teachers: mergeTeacherInfos,
                    selectedTo: requestinfo.requestTargets.filter(t => t.sendType == VisitationRequestTargetType.To),
                    selectedCC: requestinfo.requestTargets.filter(t => t.sendType == VisitationRequestTargetType.CC),
                });
                const formatTeachers = this.formatTeachers();
                const selectedTeacherKeys = requestinfo.teachers.length &&
                    requestinfo.teachers.map((selectedTeacher, key) => {
                        return formatTeachers.findIndex(t => t.id == selectedTeacher.teacherId);
                    });
                this.setState({ selectedTeacherKeys });
            });

    }
    getRequestInfo(id) {
        return this.visitationservice.getRequestInfo({ visitationId: id });
    }
    async getCampuses() {
        const { visitation: { schoolId } } = this.props;
        const campuses = await this.visitationservice.getSchoolCampus({ schoolId });
        const selectedCampus = campuses.length && campuses[0];
        this.setState({
            campuses,
            selectedCampusKey: 0,
            selectedCampusId: selectedCampus && selectedCampus.campusId,
        });
        this.getCampusesTeachers();
    }
    getCampusesTeachers() {
        this.setState({ loading: true });
        const actions = [this.getTeacherInfos(), this.getSchoolCampusAdmins()];
        Promise.all(actions).then(async ([teachers, admins]) => {
            this.setState({
                admins,
                teachers,
                loading: false,
            }, () => {
                this.setDefaultSelectedTeachers();
            });
        });
    }
    getTeacherInfos() {
        const { visitation: { schoolId } } = this.props;
        return this.schoolservice.getTeacherInfos({ id: schoolId });
    }
    getSchoolCampusAdmins() {
        const { visitation: { schoolId } } = this.props;
        return this.resourceService.getSchoolCampusAdmins({ schoolId });
    }
    onChange(e) {
        const { form } = this.props;
        const { campuses } = this.state;
        form && form.resetFields();
        const selectedCampus = campuses.length && campuses[0];
        this.setState({
            selectedTo: [],
            selectedCC: [],
            selectedCampusKey: 0,
            isInformChecked: true,
            visitationtype: e.target.value,
            scheduleMode: VisitationScheduleMode.Force,
            selectedCampusId: e.target.value == VisitationType.OnSite ?
                selectedCampus && selectedCampus.campusId : null,
        }, () => {
            this.setDefaultSelectedTeachers();
        });
    }
    onSetDateChange(e) {
        this.setState({ scheduleMode: e.target.value }, () => {
            this.setDefaultToAdmin();
        });
    }
    changeInformSchool(e) {
        const { selectedCC } = this.state;
        this.setState({
            isInformChecked: e.target.checked,
            selectedCC: e.target.checked ? selectedCC : []
        });
    }
    setDefaultToAdmin() {
        this.setState({ selectedTo: [] });
        const { selectedCampusId, scheduleMode } = this.state;
        if (scheduleMode == VisitationScheduleMode.Schedule) {
            const admins = this.formatAdmins();

            const mainCampusAdmin = admins.find(admin => {
                const existed = admin.relatedInfos.find(info => info.userRole == Role.CampusAdmin &&
                    info.isMainContact && info.id == selectedCampusId);
                return existed && admin;
            });
            const mainSchoolAdmin = admins.find(admin => {
                const existed = admin.relatedInfos.find(info => info.userRole == Role.SchoolAdmin && info.isMainContact);
                return existed && admin;
            });

            const toAdmin = mainCampusAdmin || mainSchoolAdmin ||
                !mainCampusAdmin && !mainSchoolAdmin && admins.length && admins[0];

            this.setState({ selectedTo: toAdmin ? [toAdmin] : [] });
        }
    }
    setDefaultSelectedTeachers() {
        if (this.isAccessingUndeterminedVisitation()) {
            return;
        }
        const formatTeachers = this.formatTeachers();
        const teacherKeys = formatTeachers.map((st, key) => key);
        this.setState({
            selectedTeachers: formatTeachers,
            selectedTeacherKeys: teacherKeys,
        });
    }
    disableDates(current) {
        let disableDate = moment()
            .add(-1, "day")
            .toDate();
        return current < disableDate;
    }
    rowSelectionForCampus = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedCampusKey: selectedRowKeys[0],
            });
        },
        onSelect: (record, selected, selectedRows) => {
            this.setState({
                selectedCampusId: record.campusId,
            }, () => {
                this.setDefaultToAdmin();
                this.setDefaultSelectedTeachers();
            });
        },
    };
    rowSelectionForTeachers = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedTeacherKeys: selectedRowKeys,
            });
        },
        onSelect: (record, selected, selectedRows) => {
            this.setState({ selectedTeachers: selectedRows });
        },
        getCheckboxProps: record => {
            const { visitation: { stage } } = this.props;
            const isCompleted = stage >= VisitationCoachStage.SchoolReview;
            return GLGlobal.isActionValid(GSSchoolAction.CoachNewSupport) ?
                (isCompleted ? { ...record, disabled: true } : record) : { ...record, disabled: true };
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            this.setState({ selectedTeachers: selectedRows });
        },
    }
    getCampusTableColumns() {
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestCampus }),
                dataIndex: "campusName",
                width: "40%",
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestLastVisited }),
                dataIndex: "lastVisited",
                width: "40%",
                render(text, recorder) {
                    return DateHelper.formatDate2Local(text);
                },
            },
        ];
    }
    getTeacherTableColumns() {
        const self = this;
        return [
            {
                title: fmtMsg({ id: SchoolLocale.VisitationTeachersTitle }),
                dataIndex: "name",
                width: "40%",
                render(text, recorder) {
                    const { id } = recorder;
                    return !self.mainTeachers.includes(id) ? text :
                        <>
                            <span>{text}</span>
                            <Tag color="#00591c" className="main-tag">{fmtMsg({ id: SchoolLocale.VisitationTeacherMainTag })}</Tag>
                        </>;
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestCampus }),
                dataIndex: "campusInfos",
                width: "40%",
                render(text, recorder) {
                    const { isInClass, campusInfos } = recorder;
                    const { visitationtype, selectedCampusId } = self.state;
                    if (!isInClass) {
                        return null;
                    }
                    const campuses = campusInfos && campusInfos
                        .reduce((all, next) => all.some((atom) => atom.id == next.id) ? all : [...all, next], []);
                    if (visitationtype == VisitationType.OnSite) {
                        const campus = campuses.find(c => c.id == selectedCampusId);
                        return <span>{campus && campus.name}</span>;
                    }
                    return campuses.map((i, k) => {
                        return k == 0 ? <span key={k}>{i.name}</span> :
                            <span key={k}>, {i.name}</span>;
                    });
                }
            },
        ];
    }
    getAdminTableColumnsOnsite() {
        const self = this;
        return [
            {
                title: fmtMsg({ id: GSAdminLocale.NotificationTextName }),
                dataIndex: "userName",
                width: "40%",
            },
            {
                title: fmtMsg({ id: GSAdminLocale.NotificationTextRoleName }),
                dataIndex: "relatedInfos",
                className: "admin-role",
                width: "40%",
                render(text, recorder) {
                    const { relatedInfos } = recorder;
                    const infos = relatedInfos && relatedInfos.filter(info => info.userRole == Role.SchoolAdmin ||
                        info.userRole == Role.CampusAdmin)
                        .reduce((all, next) => all.some((atom) => atom.userRole == next.userRole) ? all : [...all, next], []);
                    return infos && infos.map((info, key) => {
                        switch (info.userRole) {
                            case Role.SchoolAdmin:
                                return <span key={key}>{RoleName.schoolAdmin}</span>;
                            case Role.CampusAdmin:
                                return <span key={key}>{RoleName.campusAdmin}</span>;
                        }
                    });
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestToText }),
                dataIndex: "",
                width: "10%",
                render(text, recorder) {
                    const { selectedTo } = self.state;
                    const existedTo = selectedTo.find(t => t.userId == recorder.userId);
                    return existedTo ? <Icon type="minus-circle" onClick={(e) => self.removeSelectedAdmins(recorder)} /> :
                        <Icon type="plus-circle" onClick={(e) => self.addToCCSection(recorder)} />;
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestCCText }),
                dataIndex: "",
                width: "10%",
                render(text, recorder) {
                    const { selectedCC, isInformChecked } = self.state;
                    const existedCC = selectedCC.find(t => t.userId == recorder.userId);
                    return existedCC ? <Icon className={!isInformChecked && "disabled"} type="minus-circle" onClick={(e) => self.removeSelectedAdmins(recorder, false)} /> :
                        <Icon className={!isInformChecked && "disabled"} type="plus-circle" onClick={(e) => self.addToCCSection(recorder, false)} />;
                },
            },
        ];
    }
    getAdminTableColumnsLVA() {
        const self = this;
        return [
            {
                title: fmtMsg({ id: GSAdminLocale.NotificationTextName }),
                dataIndex: "userName",
                width: "40%",
            },
            {
                title: fmtMsg({ id: GSAdminLocale.NotificationTextRoleName }),
                dataIndex: "relatedInfos",
                className: "admin-role",
                width: "40%",
                render(text, recorder) {
                    const { relatedInfos } = recorder;
                    const infos = relatedInfos && relatedInfos.filter(info => info.userRole == Role.SchoolAdmin ||
                        info.userRole == Role.CampusAdmin)
                        .reduce((all, next) => all.some((atom) => atom.userRole == next.userRole) ? all : [...all, next], []);
                    return infos && infos.map((info, key) => {
                        switch (info.userRole) {
                            case Role.SchoolAdmin:
                                return <span key={key}>{RoleName.schoolAdmin}</span>;
                            case Role.CampusAdmin:
                                return <span key={key}>{RoleName.campusAdmin}</span>;
                        }
                    });
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.VisitationRequestCCText }),
                dataIndex: "",
                width: "20%",
                render(text, recorder) {
                    const { selectedCC, isInformChecked } = self.state;
                    const existedCC = selectedCC.find(t => t.userId == recorder.userId);
                    return existedCC ? <Icon className={!isInformChecked && "disabled"} type="minus-circle" onClick={(e) => self.removeSelectedAdmins(recorder, false)} /> :
                        <Icon className={!isInformChecked && "disabled"} type="plus-circle" onClick={(e) => self.addToCCSection(recorder, false)} />;
                },
            },
        ];
    }
    showNotification(admin) {
        Modal.confirm({
            className: "add-to-confirm",
            title: fmtMsg({ id: SchoolLocale.VisitationComfirmTitle }),
            content: fmtMsg({ id: SchoolLocale.VisitationRequestToSectionTip }),
            okText: fmtMsg({ id: GSAdminLocale.ButtonOk }),
            cancelText: fmtMsg({ id: GSAdminLocale.ButtonCancel }),
            onOk: () => {
                const { selectedTo } = this.state;
                selectedTo.splice(0, 1, admin);
                this.setState({ selectedTo });
            },
            onCancel: () => { }
        });
    }
    addToCCSection(admin, isTo = true) {
        const { selectedTo, selectedCC } = this.state;
        if (isTo) {
            selectedTo.length < 1 ? selectedTo.push(admin) : this.showNotification(admin);
        } else {
            const index = selectedCC.findIndex(t => t.userId == admin.userId);
            index < 0 && selectedCC.push(admin);
        }
        this.setState({
            selectedTo,
            selectedCC,
        });
    }
    removeSelectedAdmins(admin, isTo = true) {
        const { selectedTo, selectedCC } = this.state;
        if (isTo) {
            const index = selectedTo.findIndex(t => t.userId == admin.userId);
            selectedTo.splice(index, 1);
        } else {
            const index = selectedCC.findIndex(c => c.userId == admin.userId);
            selectedCC.splice(index, 1);
        }
        this.setState({
            selectedTo,
            selectedCC,
        });
    }
    viewClassSchedule() {
        const teachers = this.formatScheduleTeachers();
        this.setState({ visibleModal: true });
        this.scheduleModel =
            <ScheduleModel
                teachers={teachers}
            />
    }
    goBackHome() {
        this.props.history.push({ pathname: SchoolPathConfig.Home });
    }
    onSubmit(e) {
        e.preventDefault();
        const { selectedTeachers, visitationtype, selectedCampusId, selectedTo, selectedCC, scheduleMode, isInformChecked } = this.state;
        const { form, history, visitation: { regionId, schoolId } } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                const isOnsite = visitationtype == VisitationType.OnSite;
                const isSchedule = scheduleMode == VisitationScheduleMode.Schedule;
                if (!selectedTeachers.length) {
                    MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationRequestSelectTeachersRequireMsg }));
                    return;
                }
                if (isOnsite && !selectedCampusId) {
                    return MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationRequestNotSelectCmapus }));
                }

                if (isOnsite && isSchedule && !selectedTo.length) {
                    return MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationRequestNoToAdminTip }));
                }

                if (isInformChecked && !selectedCC.length) {
                    return MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationRequestNoCCAdminTip }));
                }

                const userId = ContextHelper.getUserLoginInfo().profile.sub;
                values.startDate = moment.utc(values.startDate);
                values.endDate = moment.utc(values.endDate);
                values.expireDate = moment.utc(values.expireDate);
                values.note = trimPlainText(values.note);
                values.visitationRequestTeachers = selectedTeachers.map(v => {
                    return {
                        teacherId: v.id,
                        isInClass: v.isInClass,
                    }
                });
                values.visitationRequestTargets =
                    selectedTo.map(t => {
                        return {
                            userId: t.userId,
                            sendType: VisitationRequestTargetType.To,
                            userRole: t.relatedInfos &&
                                t.relatedInfos.length &&
                                t.relatedInfos[0].userRole,
                        }
                    }).concat(selectedCC.map(t => {
                        return {
                            userId: t.userId,
                            sendType: VisitationRequestTargetType.CC,
                            userRole: t.relatedInfos &&
                                t.relatedInfos.length &&
                                t.relatedInfos[0].userRole,
                        }
                    }));

                const params = {
                    regionId,
                    schoolId,
                    coachId: userId,
                    campusId: selectedCampusId,
                    stageDate: moment.utc().toDate(),
                    stage: VisitationCoachStage.ScheduleDate,
                    ...values,
                };
                this.setState({ isCreating: true });
                this.visitationservice.createVisitationRequest(params).then(data => {
                    this.setState({ isCreating: false });
                    if (isOnsite && isSchedule) {
                        history.push(SchoolPathConfig.Home);
                    } else {
                        history.push(GLUtil.pathStringify(SchoolPathConfig.CoachVisitation, { regionId, schoolId, visitationId: data && data.id }));
                    }
                }).catch(({ error_code, error_description }) => {
                    this.setState({
                        error_code,
                        isCreating: false,
                    });
                });;
            }
        });
    }
    onSave(e) {
        const { selectedTeachers } = this.state;
        const { visitation: { id }, callback } = this.props;
        if (!selectedTeachers.length) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationRequestSelectTeachersRequireMsg }));
            return;
        }
        const teachers = selectedTeachers.map(t => {
            return {
                teacherId: t.id,
                isInClass: t.isInClass,
            }
        });
        this.visitationservice.updateTeachers({
            teachers,
            visitationId: id,
        }).then(data => {
            callback(VisitationCoachStage.PrevisitationForm, true);
        });
    }
    formatErrMsg() {
        const { error_code } = this.state;
        switch (error_code) {
            case ServerExceptionCode.OnsiteExceedLimitError:
                return fmtMsg({ id: SchoolLocale.VisitationRequestOnsiteExceedLimit });
            case ServerExceptionCode.LVAExceedLimitError:
                return fmtMsg({ id: SchoolLocale.VisitationRequestLVAExceedLimit });
        }
    }
    formatScheduleTeachers() {
        const { visitationtype, teachers, selectedCampusId } = this.state;
        const schoolClassTimes = [];
        switch (visitationtype) {
            case VisitationType.OnSite:
                teachers && teachers.
                    filter(t => t.campusInfos && t.campusInfos.some(info => info.id == selectedCampusId))
                    .map(t => {
                        const campus = t.campusInfos && t.campusInfos.find(info => info.id == selectedCampusId);
                        if (campus) {
                            campus.schoolClassInfos.map(sci => {
                                sci.schoolClassTimes.map(sct => {
                                    const item = {
                                        ...sct,
                                        teacherId: t.id,
                                        teacherName: t.name,
                                        isInClass: t.isInClass,
                                    }
                                    schoolClassTimes.push(item);
                                });
                            });
                        }
                    });
                return orderBy(schoolClassTimes, ["teacherName", "daysOfWeek", "start", "end"], ["asc", "asc", "asc", "asc"]);
            case VisitationType.LVA:
                teachers.forEach(t => {
                    t.campusInfos &&
                        t.campusInfos.forEach(info => {
                            info.schoolClassInfos.forEach(sci => {
                                sci.schoolClassTimes.forEach(sct => {
                                    const item = {
                                        ...sct,
                                        teacherId: t.id,
                                        teacherName: t.name,
                                        isInClass: t.isInClass,
                                    }
                                    schoolClassTimes.push(item);
                                });
                            });
                        })
                });
                return orderBy(schoolClassTimes, ["teacherName", "daysOfWeek", "start", "end"], ["asc", "asc", "asc", "asc"]);
        }
    }
    formatTeachers() {
        const { visitationtype, teachers, selectedCampusId } = this.state;
        this.mainTeachers = [];
        switch (visitationtype) {
            case VisitationType.OnSite:
                teachers && teachers.forEach(t => t.campusInfos && t.campusInfos.forEach(ci => {
                    if (ci.id == selectedCampusId) {
                        if (ci.schoolClassInfos.some(sc => sc.schoolClassTeachers.some(sct => !sct.substitute))) {
                            this.mainTeachers.push(t.id);
                        }
                    }
                }));
                return teachers && teachers.
                    filter(t => t.campusInfos && t.campusInfos.find(info => info.id == selectedCampusId) || !t.isInClass);
            case VisitationType.LVA:
                teachers && teachers.forEach(t => t.campusInfos && t.campusInfos.forEach(ci => {
                    if (ci.schoolClassInfos.some(sc => sc.schoolClassTeachers.some(sct => !sct.substitute))) {
                        this.mainTeachers.push(t.id);
                    }
                }));
                return teachers;
        }
    }
    formatAdmins() {
        const { admins, selectedCampusId, visitationtype } = this.state;
        switch (visitationtype) {
            case VisitationType.OnSite:
                return admins && admins.
                    filter(t => {
                        return t.relatedInfos.find(info => info.userRole == Role.SchoolAdmin ||
                            (info.userRole == Role.CampusAdmin && info.id == selectedCampusId))
                    });
            case VisitationType.LVA:
                return admins;
        }
    }
    lengthValidator(localeId, rightVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rightVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "note").concat(this.lengthValidator(SchoolLocale.VisitationNoteText, 500));
    }

    getVisitationScheduleMode = () => {
        let visitationScheduleMode = this.props.visitation.scheduleMode;
        if (visitationScheduleMode != VisitationScheduleMode.Force && visitationScheduleMode != VisitationScheduleMode.Schedule) {
            visitationScheduleMode = this.state.scheduleMode;
        }
        return visitationScheduleMode;
    }

    render() {
        const { form, visitation: { id, stage, startDate, endDate, expireDate, note, informSchool } } = this.props;
        const { visitationtype, selectedCampusKey, selectedTeacherKeys, isCreating, campuses, loading, selectedTo, selectedCC, isReviewSetupLoading } = this.state;
        const visitationScheduleMode = this.getVisitationScheduleMode();
        const isAccessingUndetermined = this.isAccessingUndeterminedVisitation();
        const disabled = stage > VisitationCoachStage.ScheduleDate || isAccessingUndetermined;
        const isCompleted = stage >= VisitationCoachStage.SchoolReview;
        const disabledNotCompleted = disabled && !isCompleted;
        const renderFormItem = FormHelper.renderFormItem;
        const isOnsite = visitationtype == VisitationType.OnSite;
        const isSchedule = isOnsite && visitationScheduleMode == VisitationScheduleMode.Schedule;
        const adminColumns = isSchedule ? this.getAdminTableColumnsOnsite() : this.getAdminTableColumnsLVA();
        const LVAContent = fmtMsg({ id: SchoolLocale.VisitationLVAContent });
        const onsiteContent = fmtMsg({ id: SchoolLocale.VisitationOnsiteContent });
        const dateValider = (msg) => {
            return rangeDateValider(form, "startDate", "endDate", msg);
        };
        const expiryDateValider = (msg) => {
            return isOnsite ? rangeDateValider(form, "expireDate", "startDate", msg) : rangeDateValider(form, "startDate", "expireDate", msg);
        };
        const options = {
            formItemProps: { label: null },
        };
        const expiryDateName = isOnsite ? SchoolLocale.VisitationRequestExpiryDate : SchoolLocale.VisitationRequestRespondBy;
        const startDatefieldRule = isOnsite ? SchoolLocale.UpcomingEventStartLaterExpiry : SchoolLocale.UpcomingEventStartEarlierRespondBy;
        const expiryDatefieldRule = isOnsite ? SchoolLocale.UpcomingEventExpiryEarlierStart : SchoolLocale.UpcomingEventRespondByLaterStart;

        return <div className={`setup info-container ${(disabled || isCreating) && "disabled"}`}>
            <GLForm form={form}>
                <FormItemsLayout>
                    <div className="visitation-type-radios">
                        <div>{fmtMsg({ id: SchoolLocale.VisitationRequestTypeText })}</div>
                        {renderFormItem(
                            { ...form, ...options },
                            SchoolLocale.VisitationRequestTypeText,
                            "type",
                            <Radio.Group
                                className="class-max__radio-group"
                                onChange={e => this.onChange(e)}
                            >
                                <Row type="flex">
                                    <Col span={12}>
                                        <Radio value={0} disabled={disabled}>
                                            {fmtMsg({ id: SchoolLocale.VisitationRequestOnsite })}
                                        </Radio>
                                    </Col>
                                    <Col span={12}>
                                        <Radio value={1} disabled={disabled || loading}>
                                            {fmtMsg({ id: SchoolLocale.VisitationRequestLVA })}
                                        </Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>,
                            this.state.visitationtype,
                            true
                        )}
                    </div>
                    <div className="visitation-dates">
                        <div className="start-date">
                            <div>{fmtMsg({ id: SchoolLocale.ClassNewStartDate })}</div>
                            {renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.ClassNewStartDate,
                                "startDate",
                                <DatePicker
                                    disabled={disabled}
                                    {...alignPop({ type: "DatePicker" })}
                                    format={
                                        LanguageDateFormat[GLGlobal.intl.locale]
                                    }
                                    disabledDate={this.disableDates.bind(this)}
                                />,
                                startDate && DateHelper.toLocalMoment(startDate),
                                true,
                                dateValider(
                                    fmtMsg({ id: GSAdminLocale.NotificationCreateEarlierDate })
                                ).concat(expiryDateValider(
                                    fmtMsg({ id: startDatefieldRule })
                                ))
                            )}
                        </div>
                        <div className="end-date">
                            <div>{fmtMsg({ id: SchoolLocale.ClassNewEndDate })}</div>
                            {renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.ClassNewEndDate,
                                "endDate",
                                <DatePicker
                                    disabled={disabled}
                                    {...alignPop({ type: "DatePicker" })}
                                    format={
                                        LanguageDateFormat[GLGlobal.intl.locale]
                                    }
                                    disabledDate={this.disableDates.bind(this)}
                                />,
                                endDate && DateHelper.toLocalMoment(endDate),
                                true,
                                dateValider(
                                    fmtMsg({ id: GSAdminLocale.NotificationCreateLaterDate })
                                )
                            )}
                        </div>
                        {(!isOnsite || isSchedule) && <div className="expiry-date">
                            <div>{fmtMsg({ id: expiryDateName })}</div>
                            {renderFormItem(
                                { ...form, ...options },
                                expiryDateName,
                                "expireDate",
                                <DatePicker
                                    disabled={disabled}
                                    {...alignPop({ type: "DatePicker" })}
                                    format={LanguageDateFormat[GLGlobal.intl.locale]}
                                    disabledDate={this.disableDates.bind(this)}
                                />,
                                expireDate && DateHelper.toLocalMoment(expireDate),
                                true,
                                expiryDateValider(fmtMsg({ id: expiryDatefieldRule }))
                            )}
                        </div>}
                    </div>
                    {!isReviewSetupLoading && isOnsite &&
                        <div className="visitation-set-date">
                            <div>{fmtMsg({ id: SchoolLocale.VisitationRequestSetDate })}</div>
                            {renderFormItem(
                                { ...form, ...options },
                                SchoolLocale.VisitationRequestSetDate,
                                "scheduleMode",
                                <Radio.Group
                                    className="class-max__radio-group"
                                    onChange={e => this.onSetDateChange(e)}
                                >
                                    <Row type="flex">
                                        <Col sm={24} md={12}>
                                            <Radio value={0} disabled={disabled}>
                                                {fmtMsg({ id: SchoolLocale.VisitationRequestFixDate })}
                                            </Radio>
                                        </Col>
                                        <Col sm={24} md={12}>
                                            <Radio value={1} disabled={disabled || loading}>
                                                {fmtMsg({ id: SchoolLocale.VisitationRequestSendInvitations })}
                                            </Radio>
                                        </Col>
                                    </Row>
                                </Radio.Group>,
                                visitationScheduleMode,
                                true
                            )}
                        </div>
                    }
                    {isOnsite && !disabled && (
                        <div className="campus-list">
                            <div className="campus-text">{fmtMsg({ id: SchoolLocale.VisitationRequestCampusText })}</div>
                            <Spin spinning={loading}>
                                <Table
                                    className="campuses"
                                    rowSelection={{
                                        type: "radio",
                                        ...this.rowSelectionForCampus,
                                        selectedRowKeys: [selectedCampusKey],
                                        columnTitle: fmtMsg({ id: SchoolLocale.VisitationRequestSelect }),
                                    }}
                                    pagination={false}
                                    columns={this.getCampusTableColumns()}
                                    dataSource={campuses}
                                    bordered
                                />
                            </Spin>
                        </div>
                    )}
                    <div className="select-teachers">
                        <div>
                            <div>{fmtMsg({ id: SchoolLocale.VisitationRequestSelectTeachers })}</div>
                            <div onClick={() => this.viewClassSchedule()}>
                                {fmtMsg({ id: SchoolLocale.VisitationRequestViewClassSchedule })}
                            </div>
                        </div>
                        <Spin spinning={loading}>
                            <Table
                                className="teachers"
                                rowSelection={{
                                    type: "checkbox",
                                    ...this.rowSelectionForTeachers,
                                    selectedRowKeys: selectedTeacherKeys,
                                }}
                                pagination={false}
                                columns={this.getTeacherTableColumns()}
                                dataSource={this.formatTeachers()}
                                bordered
                            />
                        </Spin>
                    </div>
                    <div className="visitation-inform-school">
                        <div>{fmtMsg({ id: SchoolLocale.VisitationRequestInformSchool })}</div>
                        {form.getFieldDecorator('informschool', {
                            valuePropName: 'checked',
                            initialValue: id ? informSchool : true,
                        })(
                            <Checkbox disabled={id ? true : false} onChange={(e) => this.changeInformSchool(e)}></Checkbox>,
                        )}
                    </div>
                    <div className="visitation-to-cc">
                        {isSchedule &&
                            <div className="to">
                                <div>{fmtMsg({ id: SchoolLocale.VisitationRequestToText })}</div>
                                <div className="selected-to">
                                    {selectedTo.map((s, k) => {
                                        return <div className="admin-item" key={k}>
                                            {!disabled && <Icon type="close" onClick={() => this.removeSelectedAdmins(s, true)} />}
                                            <span>{s.userName}</span>
                                        </div>
                                    })}
                                </div>
                            </div>}
                        <div className="cc">
                            <div>{fmtMsg({ id: SchoolLocale.VisitationRequestCCText })}</div>
                            <div className="selected-cc">
                                {selectedCC.map((s, k) => {
                                    return <div className="admin-item" key={k}>
                                        {!disabled && <Icon type="close" onClick={() => this.removeSelectedAdmins(s, false)} />}
                                        <span>{s.userName}</span>
                                    </div>
                                })}
                            </div>
                        </div>
                        {!disabled && <div className="admin-list">
                            <Table
                                className="admins"
                                pagination={false}
                                columns={adminColumns}
                                dataSource={this.formatAdmins()}
                                bordered
                            />
                        </div>}
                    </div>
                    <div className="note">
                        {renderFormItem(
                            form,
                            isOnsite ? SchoolLocale.VisitationOnsiteNoteText : SchoolLocale.VisitationLVANoteText,
                            "note",
                            <Input.TextArea rows={5} disabled={disabled} />,
                            note ? note : (isOnsite ? onsiteContent : LVAContent),
                            true,
                            this.noteValidator(form)
                        )}
                    </div>
                </FormItemsLayout>
                <div className="error">{this.formatErrMsg()}</div>
                {!disabled && <SaveCancelBtns
                    onSubmit={(e) => this.onSubmit(e)}
                    onCancel={() => this.goBackHome()}
                    saveTitle={SchoolLocale.VisitationRequestVisit} />
                }
                {
                    isAccessingUndetermined &&
                    <div className='submit-btns'>
                        <Button onClick={() => this.goBackHome()} title={GLGlobal.intl.formatMessage({ id: SchoolLocale.VisitationUndeterminedBack })}>
                            {GLGlobal.intl.formatMessage({ id: SchoolLocale.VisitationUndeterminedBack })}
                        </Button>
                    </div>
                }
                {disabledNotCompleted && GLGlobal.isActionValid(GSSchoolAction.CoachNewSupport) && !isAccessingUndetermined &&
                    <SaveCancelBtns
                        noCancelBtn={true}
                        onSubmit={(e) => this.onSave(e)}
                        saveTitle={GSAdminLocale.ButtonSave} />
                }
                {this.scheduleModel}
            </GLForm>
        </div>;
    }
}
