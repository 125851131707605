import { GLRequest, GLResponse, ITGLService, GLPagingRequest } from "gl-commonui";
import { SurveyResponseModel, SurveyResponseRequestModel, SendEmailResponseModel, SendEmailRequestModel, PollGroupResponseModel, PollResponseModel } from ".";

export class GetSurveyResponseRequest extends GLPagingRequest {
}

export interface SurveyResponseRequest extends GLRequest, SurveyResponseRequestModel {
}

export interface ResponseSurveyResponse extends GLResponse, SurveyResponseModel {
}

export interface ISurveyResponseService extends ITGLService<SurveyResponseRequest, GetSurveyResponseRequest> {
    sendEmail(emailRequest: SendEmailRequestModel): Promise<SendEmailResponseModel>;
    getSurveyResponses(id: string, surveyInstanceId: string | null): Promise<PollResponseModel>
    exportSurvey(params: any)
    hasPermissionToExportSurvey(surveyId: any)
}
