export interface IContextMenu {
    id: number;
    text: string;
    isVisible: boolean;
}

export interface IContextMenuModel {
    contextMenus?: IContextMenu[];
}

export class MenuOptions {
    public static EditId = 1;
    public static DeleteId = 2;
    public static ArchiveId = 3;
    public static ResponsesId = 4;
}

export * from "./common";