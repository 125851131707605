import { StatusItemProps } from "@app/components";

export interface UploadStatusState {
    uploadStatus: StatusItemProps[];
}

export default {
    namespace: "uploadStatus",
    state: {
        uploadStatus: [],
    },
    reducers: {
        addUploadStatus(state, { payload }) {
            return { ...state, uploadStatus: [...state.uploadStatus, payload] };
        },
        updateUploadStatus(state, { payload }) {
            return { ...state, uploadStatus: state.uploadStatus.reduce((acc, u) => (u.id === payload.id ? acc.push({ ...u, ...payload }) : acc.push(u), acc), []) };
    },
        removeUploadStatus(state, { payload }) {
            return { ...state, uploadStatus: state.uploadStatus.filter(u => u.id !== payload) };
        }
    },
    effects: {

    },
    services: {

    }
}

export function addUploadStatus(state) {
    return { type: "uploadStatus/addUploadStatus", payload: state };
}

export function updateUploadStatus(state) {
    return { type: "uploadStatus/updateUploadStatus", payload: state };
}

export function removeUploadStatus(state) {
    return { type: "uploadStatus/removeUploadStatus", payload: state };
}
