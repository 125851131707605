import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Dropdown, Menu, Icon, Spin } from "antd-min";
import {
    HeaderIcon,
    GLGlobal,
    RoleName,
    alignPop,
    HeaderMenuArrowNavigatorIcon,
    PrimaryLanguageLocale
} from "gl-commonui";
import { StateType } from "@app/states";
import { ILink } from "@app/states/link";
import { getExtraMenuActions } from "@app/states/resource";
import {GSAdminAction, ContextHelper, GSSchoolAction, fmtMsg} from "@app/util";
import "./header-menu.less";
import { GLLink } from "../gl-link";
import { setIsDrawerVisible } from "@app/states/drawer";
import { fetchHeaderLinksFromServer } from "@app/states/link";
import {DashboardLocale} from "@app/locales/localeid";
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

interface MenuItem {
    textLocaleId: string;
    color?: string;
    arrow?: boolean;
    url: string;
    action: string | string[];
    iconType?: string;
    iconClassName?: string;
    icon?: React.ReactNode;
}
export interface HeaderMenuProps {
    actionLoaded?: boolean;
    textLocaleId: string;
    textIcon: string;
    menuItems: MenuItem[];
    extraMenuActions?: [{ action: string; url: string }];
    getExtraMenuActions?: (query) => void;
    matIcon: string;
    setIsDrawerVisible?: (isVisible: boolean) => void;
    linksFromServer: ILink[];
    location: "resource" | "administrator";
    fetchHeaderLinksFromServer: (payload: string) => void;
    isLoadingHeader: boolean;
    isErrorHeader: any;
    canAccessGSConnect: boolean;
}

export interface HeaderMenuState {
    isVisible: boolean;
}

@connect(
    ({
        oidc: { loginInfo },
        resource: { extraMenuActions },
        link: { linksFromServer, isLoadingHeader, isErrorHeader, canAccessGSConnect }
    }: StateType) => ({
        actionLoaded:
            loginInfo &&
            loginInfo.loggedin &&
            loginInfo.profile &&
            loginInfo.profile.actions &&
            true,
        extraMenuActions,
        linksFromServer,
        isLoadingHeader,
        isErrorHeader,
        canAccessGSConnect
    }),
    {
        getExtraMenuActions,
        setIsDrawerVisible,
        fetchHeaderLinksFromServer
    }
)
export class HeaderMenu extends Component<HeaderMenuProps, HeaderMenuState> {
    state = {
        isVisible: false
    };

    // componentWillReceiveProps(nextProps) {
    //     if (!nextProps.extraMenuActions) {
    //         this.props.getExtraMenuActions([
    //             GSSchoolAction.Navigate2KoreaOrder
    //         ]);
    //     }
    // }

    handleClickOutside = () => {
        this.setState({ isVisible: false });
    };

    componentDidMount() {
        window.addEventListener("click", this.handleClickOutside);
    }
    componentWillUnmount() {
        window.removeEventListener("click", this.handleClickOutside);
    }

    componentDidUpdate(_, prevState) {
        if (
            prevState.isVisible !== this.state.isVisible &&
            this.state.isVisible
        ) {
            this.props.fetchHeaderLinksFromServer(this.props.location);
        }
    }

    handleClickCloseDrawer = () => {
        this.setState({ isVisible: false });
        this.props.setIsDrawerVisible(false);
    };

    displayLinksFromServer = () => {
        const {
            location,
            linksFromServer,
            isLoadingHeader,
            isErrorHeader
        } = this.props;
        const filteredLinksFromServer = linksFromServer.filter(
            link => link.site === "school" && link.location === location
        );
        if (isLoadingHeader)
            return (
                <div
                    style={{
                        padding: "5px 12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Spin size="small" />
                </div>
            );
        if (!isLoadingHeader && isErrorHeader) return null;
        return (
            <div>
                {filteredLinksFromServer.map(
                    ({ id, localText, englishText, link }) => {
                        if (this.props.location === "resource") {
                            return (
                                <Menu.Item
                                    style={{ padding: "5px 12px" }}
                                    key={id}
                                    className={`navigator ant-dropdown-menu-item`}
                                >
                                    <LinkTo
                                        link={link}
                                        action={""}
                                        onClickEvent={
                                            this.handleClickCloseDrawer
                                        }
                                        isLocalLink
                                    >
                                        <span className="link-text">
                                            {capitalizeFirstLetter(
                                                GLGlobal.intl.locale ==
                                                    PrimaryLanguageLocale.English
                                                    ? englishText
                                                    : localText
                                            )}
                                        </span>
                                        <HeaderMenuArrowNavigatorIcon
                                            style={{ marginLeft: 5 }}
                                        />
                                    </LinkTo>
                                </Menu.Item>
                            );
                        }
                        if (this.props.location === "administrator") {
                            return (
                                <Menu.Item
                                    style={{ padding: "5px 12px" }}
                                    key={id}
                                    className={`navigator ant-dropdown-menu-item`}
                                >
                                    <LinkTo
                                        link={link}
                                        action={link}
                                        onClickEvent={
                                            this.handleClickCloseDrawer
                                        }
                                        isLocalLink
                                    >
                                        <Icon
                                            type="link"
                                            className="svg-icon"
                                            style={{ marginRight: 5 }}
                                        />
                                        <span className="link-text">
                                            {capitalizeFirstLetter(
                                                GLGlobal.intl.locale ==
                                                    PrimaryLanguageLocale.English
                                                    ? englishText
                                                    : localText
                                            )}
                                        </span>
                                    </LinkTo>
                                </Menu.Item>
                            );
                        }
                    }
                )}
            </div>
        );
    };

    handleVisible = () => {
        this.setState(prevState => ({ isVisible: !prevState.isVisible }));
    };

    filterItem = (item: MenuItem) => {
        const {actionLoaded, extraMenuActions, location, canAccessGSConnect} = this.props;
        const hasParentRole = ContextHelper.isUserMatchRole(RoleName.parent);
        if(location === 'resource' && item.textLocaleId === DashboardLocale.HeaderMenuConnect) {
            if (hasParentRole) return true;
           return canAccessGSConnect;
        }
        return item.action == GSAdminAction.None ||
            (item.action != GSAdminAction.None &&
                actionLoaded &&
                ((typeof item.action == "string" &&
                    GLGlobal.isActionValid(item.action)) ||
                    (typeof item.action == "object" &&
                        item.action.some(action =>
                            GLGlobal.isActionValid(action)
                        )))) ||
            (extraMenuActions &&
                extraMenuActions.some(
                    maction => maction.action == item.action
                ))
    }

    sortItems = (menus) => {
        if (this.props.location === "resource") {
            return menus.sort((a, b) => {
                return fmtMsg(a.textLocaleId).localeCompare(fmtMsg(b.textLocaleId));
            });
        }
        return menus;
    }

    render() {
        const {
            actionLoaded,
            textLocaleId,
            textIcon,
            menuItems,
            extraMenuActions,
            matIcon
        } = this.props;
        const validMenuItems =
            menuItems &&
            this.sortItems(menuItems.filter(this.filterItem));
        const menus = (
            <Menu className="header-menu">
                {validMenuItems &&
                    validMenuItems.map((item, index) => {
                        const emActions =
                            extraMenuActions &&
                            extraMenuActions.filter(
                                maction => maction.action == item.action
                            );
                        const url =
                            emActions && emActions.length > 0
                                ? emActions[0].url
                                : item.url;
                        return (
                            <Menu.Item
                                key={index}
                                className={`navigator ${item.color}`}
                            >
                                <LinkTo
                                    link={url}
                                    action={item.action}
                                    onClickEvent={this.handleClickCloseDrawer}
                                >
                                    {item.iconType && (
                                        <Icon
                                            type={item.iconType}
                                            className={item.iconClassName}
                                        />
                                    )}
                                    {item.icon && item.icon}
                                    <FormattedMessage id={item.textLocaleId} />
                                    {item.arrow && (
                                        <HeaderMenuArrowNavigatorIcon />
                                    )}
                                </LinkTo>
                            </Menu.Item>
                        );
                    })}
                {this.displayLinksFromServer()}
            </Menu>
        );
        if (!actionLoaded || !validMenuItems || validMenuItems.length == 0)
            return null;
        return (
            !ContextHelper.userIsParent(actionLoaded) && (
                <Dropdown overlay={menus} trigger={["click"]} {...alignPop()}>
                    <div
                        onClick={this.handleVisible}
                        className="gl-header-right-item gl-header-right-item-wrapper"
                    >
                        <div className="gl-header-right-item-dropdown">
                            <HeaderIcon
                                icon={textIcon}
                                matIcon={matIcon}
                                labelId={textLocaleId}
                            />
                            <i className="material-icons">
                                keyboard_arrow_down
                            </i>
                        </div>
                    </div>
                </Dropdown>
            )
        );
    }
}

function LinkTo({ link, action, children, onClickEvent, isLocalLink = false }) {
    if(isLocalLink) {
        let latestLink: string;
        if (
            link.startsWith("http://") ||
            link.startsWith("https://") ||
            link.startsWith("//")
        ) {
            latestLink = link;
        } else {
            latestLink = `http://${link}`
        }
        return (
            <a href={latestLink} target="_blank">
                {children}
            </a>
        );
    }
    if (!link)
        return (
            <GLLink to={""} onClick={onClickEvent}>
                {children}
            </GLLink>
        );
    if (action == GSSchoolAction.Navigate2KoreaOrder) {
        const userInfo = ContextHelper.getUserLoginInfo();
        const email =
            userInfo && userInfo.profile ? userInfo.profile.email : null;
        const roles =
            userInfo && userInfo.profile && userInfo.profile.roleInfos
                ? userInfo.profile.roleInfos
                      .map(role => `&roles=${role.name}`)
                      .join("")
                : null;
        const convertedLink =
            email && roles ? `${link}?email=${email}${roles}` : link;

        if (
            convertedLink.startsWith("http://") ||
            convertedLink.startsWith("https://") ||
            convertedLink.startsWith("//")
        ) {
            return (
                <a href={convertedLink} onClick={onClickEvent} target="_blank">
                    {children}
                </a>
            );
        } else {
            return (
                <a
                    href={`http://${convertedLink}`}
                    onClick={onClickEvent}
                    target="_blank"
                >
                    {children}
                </a>
            );
        }
    } else if (link.indexOf("http") > -1) {
        return (
            <a href={link} onClick={onClickEvent} target="_blank">
                {children}
            </a>
        );
    } else {
        return (
            <GLLink to={link} onClick={onClickEvent}>
                {children}
            </GLLink>
        );
    }
}
