import * as React from "react";

const PageContainer: React.StatelessComponent<{}> = props => {
    return (
        <div className="page-container" style={{ height: "100%" }}>
            {props.children}
        </div>
    );
};
export { PageContainer };
