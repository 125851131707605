import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di';
import {IReportService, ReportRequest, ReportPagingRequest, PlayLogsParams, StudentProgressRequestModel} from './index';

@injectable()
export class ReportService extends GLServiceBase<ReportRequest, ReportPagingRequest> implements IReportService {
    serviceRoute = { prefix: "report/v1", suffix: "" }

    getPlayLogs(data: PlayLogsParams) {
        const {studentId, mode} = data;
        return this.get(`student-statistic/${studentId}/PlayLogs`, { mode });
    }
    getRecentLogs(data: any) {
        return this.get("student-statistic/GetChildReport", data);
    }
    getStudentProgress(data: StudentProgressRequestModel) {
        return this.get("student-statistic/PlaylistProgress", data);
    }
    getCourseHistory(teacherId, offset, limit) {
        return this.get("teachercourse/{teacherId}/courseviewhistory", {offset, limit}, {teacherId});
    }
    getReviewedSummary(teacherId) {
        return this.get('teachercourse/teachers/{teacherId}/videoviewed/message', null, {teacherId});
    }
}
