import { GLServiceBase, getServiceMethodKey, HttpMethod, ServerExceptionCode, customError } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IStudentRegistrationService, StudentRegistrationModel, StudentMatchResponseModel, StudentMatchResponseWithLicenseInfoModel } from './index';
import { SchoolLocale } from '@app/locales/localeid';
import { InvitationChannel } from '@app/util';

@injectable()
export class StudentRegistrationService extends GLServiceBase<any, any> implements IStudentRegistrationService {
    serviceRoute = { prefix: 'admin/v1/student/registration', suffix: '{id}' }
    downloadUrl = 'studentuploadformat/{langAttr}/{invitationChannel}';
    errMsgs = (() => {
        const result = {};
        //result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ExceedStudentLicenseLimitationException)] = SchoolLocale.StudetnInviteFull;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.ArgumentIsNullOrInvalidException)] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.TargetIsNullException)] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        result[getServiceMethodKey(this, HttpMethod.Post, ServerExceptionCode.AggregateException)] = SchoolLocale.StudentRegistrationSuggestionSaveErrorMsg;
        return result;
    })();
    getSuggestions(regionId:string,data: StudentRegistrationModel[]) {
        return this.createWithoutLoader("{regionId}/suggestions", data, {regionId});
    }
    saveUpdateStudents(schoolClassId: string, regionId:string, data: StudentMatchResponseWithLicenseInfoModel) {
        return this.createWithoutLoader("{schoolClassId}/{regionId}/save", data, { schoolClassId, regionId })
            .catch(customError(this, HttpMethod.Post));
    }
    downloadFormat(locale: string, invitationChannel: InvitationChannel) {
        return this.download({ suffix: this.downloadUrl, data: null, routeData: { langAttr: locale, invitationChannel }, method: 'post' });
    }
}