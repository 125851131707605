import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../../util/di';
import { IStudentService, StudentModel } from './index';
import { IGetStudentInfoWithSuggestionsRequestQueryParam } from './model';

@injectable()
export class StudentService extends GLServiceBase<any, any> implements IStudentService {
    serviceRoute = { prefix: 'admin/v1/students', suffix: '{id}' }
    relateChildToClass(data: string, routeData: StudentModel) {
        return this.request.post(this.url('{id}/schoolClasses/{schoolClassId}'), data, routeData);
    }
    getParent(data) {
        return this.get('{id}/parents', null, data);
    }
    getStudentAvatar(params) {
        return this.get('avatars', params);
    }
    getStudentInfo(params: { id: string, includeParentName?: boolean }) {
        return this.get('{id}/information', params);
    }
    updateStudentSubscriptionEndDate(studentId, endDate) {
        return this.update("{id}/extends/subscription/enddate", {endDate}, {id: studentId});
    }
    getStudentGracePeriodEnd(studentId: string) {
        return this.get('getStudentGracePeriodEnd', {studentId});
    }
    updateLeaveDate(studentId, schoolClassId, leaveDate) {
        return this.update("{id}/leavedate", {schoolClassId, leaveDate}, {id: studentId});
    }
    updateMaxUnit(studentId, unit) {
        return this.update("{id}/extends/subscription/maxunit/{unit}", null, {id: studentId, unit});
    }

    approveStudentMerge(studentId, currentClassId, mergeStudentId): Promise<any> {
        return this.create("approvemerge/{studentId}/{currentClassId}", {mergeStudentId}, {studentId: studentId, currentClassId: currentClassId});
    }

    undoStudentMerge(
        data: IGetStudentInfoWithSuggestionsRequestQueryParam
    ): Promise<any> {
        return this.create("undomerge/{studentId}/{currentClassId}", data);
    }
    getStudent(params: any) {
        return this.get('GetStudent', params);
    }
}
