import * as React from "react"
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter, connect, GLGlobal, GLUtil } from "gl-commonui";
import { getBreadcrumbs, setBreadcrumb } from "@app/states/admin/resource";
import { StateType } from "@app/states";
import { isGuid, fmtMsg } from "@app/util/func";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { GSAdminAction, SurveyAction } from "@app/util/enum";
import { SurveyLocale } from "@app/locales/localeid";
import { PollBreadcrumbHelper, PollBreadcrumbKeys } from "./poll-breadcrumb-helper";

interface BreadcrumbDesc {
    id?: string;
    name?: string;
}
interface BreadcrumbParams {
    home?: BreadcrumbDesc;
    polls?: BreadcrumbDesc;
    poll?: BreadcrumbDesc;
    reviewhistory?: BreadcrumbDesc;
    createdhistory?: BreadcrumbDesc;
    user?: BreadcrumbDesc;
}

interface PollBreadcrumbContainerProps {
    isSignin?: boolean;
    breadcrumbs: BreadcrumbParams;
    getBreadcrumbs: (d) => void;
}

@withRouter
@connect(({ oidc: { loginInfo }, adminResource: { breadcrumbs } }: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin,
    breadcrumbs,
}), {
    getBreadcrumbs
})
export class PollBreadcrumbContainer extends React.Component<RouteComponentProps<any> & PollBreadcrumbContainerProps> {
    builder: BreadcrumbItemsBuilder
    params: BreadcrumbParams
    componentWillMount() {
        this.builder = new BreadcrumbItemsBuilder();
        this.props.isSignin && this.props.getBreadcrumbs(this.props.match.params);
    }
    componentWillUnmount() {
        this.clearBreadcrumbs();
    }
    clearBreadcrumbs() {
        setBreadcrumb({
            polls: {},
            poll: {},
            reviewhistory: {},
            createdhistory: {},
            user: {},
        });
    }
    componentWillReceiveProps({ isSignin: nextIsSignin, match: { params: nextParams } }: any) {
        const { isSignin, getBreadcrumbs, match: { params } } = this.props;
        (nextIsSignin !== isSignin || this.routerParamsChanged(nextParams, params)) && nextIsSignin && getBreadcrumbs(nextParams);
    }
    routerParamsChanged(pre: object, cur: object) {
        const preKeys = Object.getOwnPropertyNames(pre);
        const curKeys = Object.getOwnPropertyNames(cur);
        return preKeys.length !== curKeys.length || preKeys.some(key => pre[key] !== cur[key]);
    }
    clearStaleBreadcrumbs(breacrumbs, params) {
        if (breacrumbs == null) return breacrumbs;
        const newBreadcrumbs = { ...breacrumbs };
        const breadcrumbKeys = Object.getOwnPropertyNames(breacrumbs);
        const paramKeys = Object.getOwnPropertyNames(params);
        breadcrumbKeys.filter(name => !paramKeys.some(key => key.toLowerCase().startsWith(name.toLowerCase())))
            .forEach(name => delete newBreadcrumbs[name])
        return newBreadcrumbs;
    }
    render() {
        const { breadcrumbs, location: { pathname }, match: { params } } = this.props;
        return <div className="breadcrumb-container">
            <ul className="breadcrumb">
                {this.builder.createBreadcrumbItems(
                    pathname,
                    params,
                    this.builder.appendHome(this.clearStaleBreadcrumbs(breadcrumbs, params)))}
            </ul>
        </div>
    }
}
class BreadcrumbItemsBuilder {
    contentMap = {
        "home": {
            path: (ids, ) => new PlainTextPathBuilder(""),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} />;
            }
        },
        [PollBreadcrumbKeys.surveys]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.Home, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />;
            }
        },
        [PollBreadcrumbKeys.survey]: {
            path: (ids, params, pathname) => {
                if (pathname.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/review/)
                    && !pathname.includes(PollBreadcrumbKeys.reviewhistory)) {
                    if (pathname.includes("createdhistory")) {
                        return new LinkTextPathBuilder(`${SurveyPathConfig.DetailsFromHistory}??tab=responses`, ids);
                    } else {
                        return new LinkTextPathBuilder(`${SurveyPathConfig.DetailsFromLanding}??tab=responses`, ids);
                    }
                } else {
                    return new PlainTextPathBuilder(SurveyPathConfig.Home);
                }
            },
            content: (title, path) => {
                if (path === SurveyPathConfig.Home || path === `/${SurveyPathConfig.Home}`) {
                    return <PlainTextContent key={path} titleComponent={PlainTitle} title={title} />
                } else {
                    return <ComboContent key={path} titleComponent={LinkTitle} title={title} path={path} action={SurveyAction.EditSurvey} />
                }
            }
        },
        [PollBreadcrumbKeys.reviewhistory]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.ReviewHistory, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={ReviewHistoryTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />;
            }
        },
        [PollBreadcrumbKeys.createdhistory]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.CreatedHistory, ids),
            content: (title, path) => {
                return <ComboContent key={path} titleComponent={CreatedHistoryTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />;
            }
        },
        [PollBreadcrumbKeys.user]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.Home, ids),
            content: (title, path) => {
                return <PlainTextContent key={path} titleComponent={PlainTitle} title={title} />;
            }
        },
        [PollBreadcrumbKeys.view]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.ViewPollFromHistory, ids),
            content: (title, path) => {
                const split = path.split("/");
                const isCurrent = split[split.length - 2] === PollBreadcrumbKeys.view;
                return isCurrent
                    ? <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle} />
                    : <ComboContent key={path} isCurrent={isCurrent} titleComponent={LinkTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />
            }
        },
        [PollBreadcrumbKeys.contribute]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.ContributePollFromHistory, ids),
            content: (title, path) => {
                const split = path.split("/");
                const isCurrent = split[split.length - 2] === PollBreadcrumbKeys.contribute;
                return isCurrent
                    ? <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle} />
                    : <ComboContent key={path} isCurrent={isCurrent} titleComponent={LinkTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />
            }
        },
        [PollBreadcrumbKeys.review]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.ReviewPollFromHistory, ids),
            content: (title, path) => {
                const split = path.split("/");
                const isCurrent = split[split.length - 2] === PollBreadcrumbKeys.review;
                return isCurrent
                    ? <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle} />
                    : <ComboContent key={path} isCurrent={isCurrent} titleComponent={LinkTitle} title={title} path={path} action={SurveyAction.ListMySurvey} />
            }
        },
        [PollBreadcrumbKeys.sharedSurvey]: {
            path: (ids) => new LinkTextPathBuilder(SurveyPathConfig.ViewResponseCreatorShared, ids),
            content: (title, path) => {
                const split = path.split("/");
                const isCurrent = split[split.length - 2] === PollBreadcrumbKeys.sharedSurvey;
                return <PlainTextContent isCurrent={isCurrent} key={path} title={title} titleComponent={CurrentTitle} />
            }
        },
        "current": {
            path: () => new PlainTextPathBuilder("current"),
            content: (title, path) => {
                return <PlainTextContent isCurrent key={path} title={title} titleComponent={CurrentTitle} />
            }
        }
    }
    breadcrumbOrder = {
        "home": 0,
        [PollBreadcrumbKeys.surveys]: 1,
        [PollBreadcrumbKeys.createdhistory]: 2,
        [PollBreadcrumbKeys.reviewhistory]: 3,
        [PollBreadcrumbKeys.sharedSurvey]: 4,
        [PollBreadcrumbKeys.survey]: 5,
        [PollBreadcrumbKeys.user]: 6,
        [PollBreadcrumbKeys.view]: 7,
        [PollBreadcrumbKeys.contribute]: 8,
        [PollBreadcrumbKeys.review]: 9,
    }
    appendHome(params: BreadcrumbParams) {
        return {
            home: {
                id: "/",
                name: "Home"
            },
            ...params
        };
    }
    getChain(params: BreadcrumbParams) {
        return Object.getOwnPropertyNames(params).filter(key => params[key] && params[key].id).sort((keyA, keyB) => {
            return this.breadcrumbOrder[keyA] - this.breadcrumbOrder[keyB]
        });
    }
    neededCrumb(pathname, newKeys: string[]) {
        const snippets = pathname.split("/");
        if (snippets.length > 2) {
            const crumb = snippets[snippets.length - 2];
            if (!isGuid(crumb) && this.contentMap[crumb] && !newKeys.includes(crumb)) {
                newKeys.push(crumb);
            }
        }
        return newKeys.sort((keyA, keyB) => {
            return this.breadcrumbOrder[keyA] - this.breadcrumbOrder[keyB]
        });
    }
    formatSpecialCrumb(snippet, pathname: string) {
        const keys = [`${snippet}s`, snippet];

        if (snippet === "home") {
            return keys;
        }
        if (PollBreadcrumbHelper.isReviewHistoryPath(pathname)) {
            const newKeys = [...keys];
            return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys, PollBreadcrumbKeys.reviewhistory]);
        }
        if (PollBreadcrumbHelper.isLandingPageActionPath(pathname)) {
            const newKeys = [...keys];
            return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys]);
        }
        if (PollBreadcrumbHelper.isCreatedHistoryReviewPath(pathname)) {
            if (snippet === PollBreadcrumbKeys.survey) {
                const newKeys = [...keys];
                return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys, PollBreadcrumbKeys.createdhistory]);
            }

            if (snippet === PollBreadcrumbKeys.user) {
                return PollBreadcrumbKeys.user;
            }
        }
        if (PollBreadcrumbHelper.isCreatedHistoryDetailsPath(pathname)) {
            if (snippet === PollBreadcrumbKeys.survey) {
                const newKeys = [...keys];
                return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys, PollBreadcrumbKeys.createdhistory]);
            }
        }
        if (PollBreadcrumbHelper.isReviewHistoryViewPath(pathname)) {
            if (snippet === PollBreadcrumbKeys.survey) {
                const newKeys = [...keys];
                return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys, PollBreadcrumbKeys.reviewhistory]);
            }

            if (snippet === PollBreadcrumbKeys.user) {
                return PollBreadcrumbKeys.user;
            }
        }
        if (PollBreadcrumbHelper.isLandingPageReviewPath(pathname) || PollBreadcrumbHelper.isLandingPageViewPath(pathname)) {
            if (snippet === PollBreadcrumbKeys.survey) {
                const newKeys = [...keys];
                return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys]);
            }

            if (snippet === PollBreadcrumbKeys.user) {
                return PollBreadcrumbKeys.user;
            }
        }
        if (PollBreadcrumbHelper.isCreatorResponseViewPath(pathname)) {
            if (snippet === PollBreadcrumbKeys.survey) {
                const newKeys = [...keys];
                return PollBreadcrumbHelper.includeKeys(newKeys, [PollBreadcrumbKeys.surveys, PollBreadcrumbKeys.reviewhistory, PollBreadcrumbKeys.sharedSurvey]);
            }
        }
        if (snippet === PollBreadcrumbKeys.survey || snippet === PollBreadcrumbKeys.surveys) {
            return snippet;
        }

        return keys;
    }
    appendSnippets(keys: string[], pathname: string) {
        const newKeys = keys.reduce((pre, cur) => pre.concat(this.formatSpecialCrumb(cur, pathname)), []);
        newKeys.shift();
        return this.neededCrumb(pathname, newKeys);
    }
    getSnippetTitle(type, params) {
        return params[type] ? params[type].name : PlainTitle.toUpperWordFirstChar(type);
    }
    buildBreadcrumbItems(ids, pathname: string, params: BreadcrumbParams) {
        return this.appendSnippets(this.getChain(params), pathname)
            .map(type => {
                const item = this.contentMap[type];
                return item.content(this.getSnippetTitle(type, params), item.path(ids, params, pathname).build())
            })
            .concat([
                this.contentMap.current.content(pathname.split("/").pop(), this.contentMap.current.path().build())
            ]);
    }
    hasCurrent(pathname: string, params: BreadcrumbParams, arr: any[]) {
        let snippets = pathname.split("/");
        const snippet = snippets.pop();
        if (isGuid(snippet) || snippet === "" || snippet === PollBreadcrumbKeys.survey) {
            return arr.slice(0, arr.length - 1);
        }
        return arr;
    }
    createBreadcrumbItems(pathname: string, ids, params: BreadcrumbParams) {
        return this.hasCurrent(pathname, params, this.buildBreadcrumbItems(ids, pathname, params));
    }
}
//--- BreadcrumbContent ---
abstract class BreadcrumbContent<TProps> extends React.Component<PlainTextContentProps & TProps> {
}
interface PlainTextContentProps {
    titleComponent: any
}
class PlainTextContent extends BreadcrumbContent<PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, title, isCurrent } = this.props;
        return <li className={isCurrent ? "current" : ""}>
            <BreadcrumbTitle title={title}></BreadcrumbTitle>
        </li>
    }
}
interface ComboContentProps {
    menuComponent?: any
}
class ComboContent extends BreadcrumbContent<ComboContentProps & LinkTitleProps & PlainTitleProps> {
    render() {
        const { titleComponent: BreadcrumbTitle, menuComponent: BreadcrumbMenu, path, title, action, isCurrent } = this.props;
        return (
            <li className={isCurrent ? "current" : ""}>
                <BreadcrumbTitle title={title} path={path} action={action}>
                    {BreadcrumbMenu && <BreadcrumbMenu path={path}>
                        <span className="bread-icon icon-open_breadcrumb" />
                    </BreadcrumbMenu>}
                </BreadcrumbTitle>
            </li>
        )
    }
}
//--- BreadcrumbTitle ---
abstract class BreadcrumbTitle<TProps> extends React.Component<TProps>{ }
interface PlainTitleProps {
    title: string
    isCurrent?: boolean
}
class PlainTitle extends BreadcrumbTitle<PlainTitleProps> {
    static toUpperWordFirstChar(title: string) {
        if (title) {
            return title.replace(/\b\w/g, (c) => c.toUpperCase());
        }
        return "";
    }
    render() {
        return <span>{PlainTitle.toUpperWordFirstChar(this.props.title)}</span>;
    }
}
class CurrentTitle extends PlainTitle {
    getTitle(title) {
        if (title === PollBreadcrumbKeys.createdhistory) {
            return fmtMsg({ id: SurveyLocale.PollCreatedHistory });
        } else if (title === PollBreadcrumbKeys.reviewhistory) {
            return fmtMsg({ id: SurveyLocale.PollReviewHistory });
        } else if(title === PollBreadcrumbKeys.sharedSurvey.toLocaleLowerCase()){
            return fmtMsg({ id : SurveyLocale.PollSharedSurvey });
        }
        else {      
            return PlainTitle.toUpperWordFirstChar(title);
        }
    }
    render() {
        return <span>{this.getTitle(this.props.title.toLowerCase())}</span>;
    }
}

interface LinkTitleProps {
    path: string
    action?: string
}
class LinkTitle extends BreadcrumbTitle<LinkTitleProps & PlainTitleProps> {
    getPath() {
        return this.props.path;
    }
    getTitle() {
        return this.props.title;
    }
    onClick(e) { }
    getLink() {
        const title = this.getTitle();
        const path = this.getPath();
        const route = window.location.hash == "" ? window.location.pathname : window.location.hash.substring(1);
        if (path === route
            || (this.props.action && this.props.action != GSAdminAction.None
                && !GLGlobal.isActionValid(this.props.action))) {
            return <a className="link-plain" href="javascript:void(0)"><span>{title}</span></a>
        } else {
            return <Link to={path} onClick={this.onClick.bind(this)}><span>{title}</span></Link>
        }
    }
    render() {
        return (
            <>
                {this.getLink()}
                {this.props.children}
            </>
        );
    }
}

class ReviewHistoryTitle extends LinkTitle {
    getPath() {
        return SurveyPathConfig.ReviewHistory;
    }
    getTitle() {
        return fmtMsg({ id: SurveyLocale.PollReviewHistory });
    }
}

class CreatedHistoryTitle extends LinkTitle {
    getPath() {
        return SurveyPathConfig.CreatedHistory;
    }
    getTitle() {
        return fmtMsg({ id: SurveyLocale.PollCreatedHistory });
    }
}

class SharedSurveyTitle extends LinkTitle {
    getPath() {
        return SurveyPathConfig.ViewResponseCreatorShared;
    }
    getTitle() {
        return fmtMsg({ id: SurveyLocale.PollSharedSurvey });
    }
}
//--- BreadcrumbPathBuilder ---
abstract class BreadcrumbPathBuilder {
    constructor(protected pathConfig?: string, protected params: any = {}) { }
    getPath(path, params) {
        return GLUtil.pathStringify(path, params);
    }
    abstract build()
}
class LinkTextPathBuilder extends BreadcrumbPathBuilder {
    build() {
        return this.getPath(this.pathConfig, this.params);
    }
}

class PlainTextPathBuilder extends BreadcrumbPathBuilder {
    constructor(private title: string) { super() }
    build() {
        return `/${this.title}`;
    }
}