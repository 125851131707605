export * from "gl-commonui/lib/antd-min";
import {
    /* sync-react */
    Avatar,
    /* sync-react */
    Radio,
    Card,
    Table,
    /* sync-react */
    Input,
    /* async-react:Checkbox.Group */
    Checkbox,
    /* async-react:Upload.Dragger */
    Upload,
    Col,
    Row,
    Button,
    InputNumber,
    DatePicker,
    TimePicker,
    Icon,
    Dropdown,
    Pagination,
    Popconfirm,
    /* async-react:AutoComplete.Option, AutoComplete.OptGroup */
    AutoComplete,
    /* async-react:List.Item.Meta */
    List,
    Popover,
    Spin,
    Divider,
    Tooltip,
    Badge,
    /* async-func:message.error,message.success,message.info,message.warning */
    message,
    /* sync-react */
    Tabs,
    Empty,
    Switch,
    Progress,
    /* async-react:Collapse.Panel */
    Collapse,
    Rate,
    Tag,
    /* async-react:Typography.Text,Typography.Title */
    Typography,
    Drawer,
    Skeleton,
    Slider,
    Breadcrumb,
    Alert
} from "antd";
export {
    Card,
    Col,
    Row,
    Avatar,
    Button,
    Input,
    InputNumber,
    DatePicker,
    TimePicker,
    Radio,
    Checkbox,
    Icon,
    Table,
    Upload,
    Dropdown,
    Pagination,
    Popconfirm,
    AutoComplete,
    List,
    Popover,
    Spin,
    Divider,
    Tooltip,
    Badge,
    message,
    Tabs,
    Empty,
    Switch,
    Progress,
    Collapse,
    Rate,
    Tag,
    Typography,
    Drawer,
    Skeleton,
    Slider,
    Breadcrumb,
    Alert
};
