import { BulkAction } from "@app/page/school/class/bulkedit-utill";
import {
    CodeValidateStatus,
    PasswordValidateStatus
} from "@app/page/school/class/manage-student-login/models";
import { StudentRegistrationType } from "@app/util";
import { GLPagingRequest, GLRequest, GLResponse } from "gl-commonui";
import { TagListModel } from "../tag-manager";

export interface SchoolClassPromotion {
    selectionSchoolClassId?: string;
    startDate?: Date;
}

export interface SchoolClassMoveStudentModel {
    campusName: string;
    digitalLicense: number;
    disabled: boolean;
    id: string;
    curriculumType: string;
    name: string;
    studentCount: number;
    textbookLicense: number;
}

export interface SchoolClassModel {
    id?: string;
    schoolId?: string;
    campusId?: string;
    isPromotion?: string;
    schoolClassPromotion?: SchoolClassPromotion;
    name?: string;
    term?: string;
    termId?: string;
    startDate?: string;
    endDate?: string;
    tsiLessonsPerWeek?: number;
    tsiLessonsPerYear?: number;
    classTime?: any;
    daysOfWeek?: string | string[];
    age?: string | number;
    startUnit?: number;
    maxUnit?: number;
    currentUnit?: number;
    textbookLicense?: number;
    curriculumType?: string;
    digitalLicense?: number;
    teacher?: string;
    teacherId?: string;
    subTeacher?: string;
    substituteTeacherId?: string;
    meetingCode?: string;
    // internal notes
    notes?: string;
    schoolNotes?: string;
    dayTimes?: any[];
    schoolClassTime?: SchoolClassTimeModel[];
    tsiTimePerWeek?: number;
    disabled?: boolean;
    nextUnit?: number;
    nextUnitStartDate?: string;
    substituteTeacherIds?: string[];
    studentCount?: number;
    unitPlanStartDate?: string;
    campusName?: string;
    campusDisabled?: boolean;
    isPromoted?: boolean;
    preventUnitPlanUnlock?: boolean;
    studentRegistrationTypeId: StudentRegistrationType;
    licenseType?: number;
    tags?: TagListModel[];
}
export enum SchoolClassPropNames {
    id = "id",
    name = "name",
    age = "age",
    studentcount = "studentCount",
    currentunit = "currentUnit",
    classtime = "classTime",
    startdate = "startDate"
}

export enum SchoolClassAPIStatus {
    None,
    MoveStudentFailed,
    MoveStudentSuccess
}
export interface SchoolClassTimeModel {
    id?: string;
    schoolClassId?: string;
    type?: string;
    typeText?: string;
    start?: string;
    end?: string;
    duration?: number;
    daysOfWeek?: number;
    daysOfWeekText?: string;
}

export interface CampusRequest extends GLRequest, SchoolClassModel {}
export interface CampusResponse extends GLResponse, SchoolClassModel {}
export interface CampusesPagingRequest extends GLPagingRequest {}

export enum SubscriptionType {
    Textbook = 0,
    Digital = 1
}

export enum ChildSubscriptionType {
    Textbook = 0,
    Digital = 1,
    Dual = 2,
}

export enum SubscriptionTypeText {
    Textbook = "class_license.item.subscriptiontypetextbook",
    Digital = "class_license.item.subscriptiontypedigital"
}

export enum CurriculumType {
    GrapeSEED = 0,
    LittleSEED = 1
}

export enum DaysOfWeek {
    Sunday = "1",
    Monday = "2",
    Tuesday = "3",
    Wednesday = "4",
    Thursday = "5",
    Friday = "6",
    Saturday = "7"
}

export interface ClassesModel {
    id?: string;
    schoolId?: string;
    schoolName?: string;
    campusId?: string;
    campusName?: string;
    schoolClassId?: string;
    schoolClassName?: string;
    teacherId?: string;
    teacherName?: string;
    age?: number;
    studentCount?: number;
    unit?: number;
    other?: any;
    tsi?: ClassTimeModel;
    rep?: ClassTimeModel;
    tsi_rep?: string;
    duration?: number;
    count?: number;
    days?: string;
    tsiTimePerWeek?: number;
    tsiDuration?: number;
    repDuration?: number;
    tsiCount?: number;
    repCount?: number;
    tsiDays?: string;
    repDays?: string;
    rowSpan?: number;
    licenseType?: number;
    licenseTypeText?: string;
    curriculumType?: number;
    curriculumTypeText?: string;
    currentUnit?: number;
    regionId?: string;
    regionName?: string;
}

export interface ClassTimeModel {
    duration?: number;
    count?: number;
    days?: string;
}

export enum ClassesPropsModel {
    schoolId = "schoolId",
    schoolName = "schoolName",
    campusId = "campusId",
    campusName = "campusName",
    schoolClassId = "schoolClassId",
    schoolClassName = "schoolClassName",
    schoolClassAge = "schoolClassAge",
    teacherId = "teacherId",
    teacherName = "teacherName",
    age = "age",
    studentCount = "studentCount",
    unit = "unit",
    other = "other",
    tsi = "tsi",
    rep = "rep",
    tsi_rep = "tsi_rep",
    duration = "duration",
    count = "count",
    days = "days",
    tsiTimePerWeek = "tsiTimePerWeek",
    tsiDuration = "tsiDuration",
    repDuration = "repDuration",
    tsiCount = "tsiCount",
    repCount = "repCount",
    tsiDays = "tsiDays",
    repDays = "repDays",
    licenseType = "licenseType",
    licenseTypeText = "licenseTypeText",
    curriculumType = "curriculumType",
    curriculumTypeText = "curriculumTypeText",
    currentUnit = "currentUnit",
    tsiLessonsPerWeek = "tsiLessonsPerWeek"
}

export interface SchoolClassPromoteHistoryModel {
    schoolClass?: SchoolClassModel;
    licenseHistory?: any[];
}

export interface UnitPlanProgressionHistoryModel {
    schoolClassId?: string;
    schoolClassName?: string;
    schoolClassStartDate?: string;
    schoolClassDisabled?: boolean;
    campusId?: string;
    campusName?: string;
    campusDisabled?: boolean;
    unitPlanId?: string;
    unit?: number;
    unitStartDate?: string;
}

export class GetSchoolClassRequest extends GLPagingRequest {
    schoolId?: string;
}
export interface SchoolClassRequest extends GLRequest, SchoolClassModel {}
export interface SchoolClassResponse extends GLResponse, SchoolClassModel {}

export interface SchoolBulkClassModel
    extends Omit<SchoolClassModel, "curriculumType" | "term"> {
    teachers: SchoolClassTeacher[];
    students: SchoolClassStudent[];
    action: BulkAction;
    exist: boolean;
    hasNextPromoted: boolean;
    className: string;
    closeDate: string;
    keepTeachers: boolean;
    keepStudents: boolean;
    studentAgeGrade: string | number;
    keepClassTimes: boolean;
    classTimes: string;
    tsiMinutesPerWeek: number;
    promptClassID: string;
    licenses: number;
    curriculumType: CurriculumType;
    duplicateClassID: string;
    dirty: boolean;
    term: string | number;
    lastMaxUnitPrompt: number;
    fromLittleSEEDToGrapeSEED: boolean;
    sourceClassId?: string;
    hasStartUnitPlan?: boolean;
}

export interface SchoolClassTeacher {
    id: string;
    name: string;
    substitute: boolean;
    isMainTeacher: boolean;
}
export interface SchoolClassStudent {
    id: string;
    name: string;
    englishName: string;
}
export interface StudentCodeResponseModel {
    schoolCode: number;
    data: StudentCodeModel[];
}

export interface StudentCodeModel {
    studentId: string;
    englishName: string;
    name: string;
    password: string;
    studentCode?: number;
    userName: string;
    codeStatus?: CodeValidateStatus;
    passwordStatus?: PasswordValidateStatus;
}

export interface StudentCodeValidateModel {
    studentId: string;
    duplicatedStudentId?: string;
    code: number;
    validationStatus: StudentCodeValidateStatus;
}
export interface CampusDropModel {
    id: string;
    name: string;
}
export interface ClassDropModel {
    id: string;
    name: string;
    termId?: string;
}
export interface SchoolClassInfoModel {
    id: string;
    name: string;
    campusName: string;
    age: string;
    studentCount: number;
    textbookLicense: number;
    digitalLicense: number;
    curriculumType: string;
    disabled: boolean;
    startDate: Date;
    currentUnit?: number;
}
export interface StudentsInfoModel {
    id: string;
    name: string;
    englishName: string;
    gender?: boolean;
    birthday?: Date;
    lastLoginDate?: Date;
    subscriptionType: number;
    fromClassId: string;
    isMoved: boolean;
    isPromoted: boolean;
    isInPromotion: boolean;
    isAlreadyInPromotion: boolean;
    promotedToClassId?: string;
    promotedToClassName: string;
    promotedToCampusId?: string;
    promotedToCampusName: string;
    isPromotedToClassId?: string;
    isPromotedToClassName?: string;
    isPromotedToCampusId?: string;
    isPromotedToCampusName?: string;
    promotedFromClassId?: string;
    promotedInClassId?: string;
    isPending: boolean;
    previousClassId?: string;
    previousClassName: string;
    isDisabled?: boolean;
}

export interface MovedPromotedStudentInfo extends StudentsInfoModel {
    movedToClassId?: string;
    movedToClassName?: string;
    movedToCampusId?: string;
    movedToCampusName?: string;
}

export interface SchoolClassWithStudentsInfoModel {
    schoolClassInfo: SchoolClassInfoModel;
    students: StudentsInfoModel[];
}

export interface movePromoteInfoModel {
    parentClassId?: string;
    sourceClassId: string;
    destinationClassId: string;
    students: string[];
    movedPromoteAdditionalInfo: MovedPromotedParentClassInfo[];
}

export interface MovedPromotedParentClassInfo {
    studentId: string;
    classId: string;
}
export interface movePromoteSaveModel {
    moveInfo: movePromoteInfoModel[];
    promoteInfo: movePromoteInfoModel[];
    movedPromotedInfo: movePromoteInfoModel[];
    movedPromotedToOriginalInfo: movePromoteInfoModel[];
    note: string;
    isDisabled: boolean;
}

export enum StudentCodeValidateStatus {
    InvalidLength = 4001,
    Duplicate = 4002,
    Valid = 2001
}

export interface SaveStudentCodeRequestModel {
    studentId: string;
    studentCode: number;
    userName: string;
    password: string;
}

export class Constants {
    static readonly MIN_STUDENT_CODE: number = 1000;
    static readonly MAX_STUDENT_CODE: number = 9999;
}

export interface UpdateSchoolTeacherClassAssociationModel{
    assignedClasses: string[],
    removedClasses: string[]
}

export interface TermResponseModel {
    id: string;
    name: string;
}

export interface RegionInfoModel {
    id: string;
    name: string;
    englishName: string;
    tenantId: number;
}